/* @ngInject */
function ScrollService($anchorScroll, $timeout) {
	const service = {};

	service.scroll = function (elementId, delay, $scope) {
		$timeout(function () {
			$anchorScroll(elementId);
			if ($scope) {
				$scope.scrollComplete = true;
			}
		}, delay);
	};

	return service;
}

module.exports = ScrollService;
