"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiInputLabelOverrides = void 0;
exports.muiInputLabelOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                position: 'static',
                transform: 'none',
                textTransform: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'none' : 'uppercase',
                fontWeight: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 500 : theme.typography.tsFontWeight.bold,
                fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
                whiteSpace: 'nowrap',
                wordWrap: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: theme.palette.common.black,
                paddingBottom: theme.tsGoUnit.tsUnitHalf,
            };
        },
    },
};
