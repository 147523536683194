"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiCheckboxOverrides = void 0;
exports.muiCheckboxOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                color: `${theme.palette.tsGoColor.greenDark} !important`,
            };
        },
    },
};
