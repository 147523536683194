const angular = require('angular');

/* @ngInject */
function avatarFileModel($parse) {
	return {
		restrict: 'A',
		link: function ($scope, $element, $attrs) {
			function onChange() {
				const reader = new FileReader();
				const avatarCurrent = angular.element(document.getElementsByClassName('avatar-current'));
				let avatarUpload;

				reader.onload = function (event) {
					avatarUpload = event.target.result;
				};

				reader.onloadend = function () {
					const container = angular.element(document.getElementById($attrs.previewContainer));
					const mediaElement = angular.element(document.createElement('img'));
					container.css({
						'background-image': 'url(' + avatarUpload + ')',
					});
					mediaElement.attr('src', avatarUpload);
					avatarCurrent.attr('class', 'hide-avatar');
					$scope.$apply(function () {
						const model = $parse($attrs.avatarFileModel);
						const modelSetter = model.assign;
						modelSetter($scope, $element[0].files[0]);
					});
				};

				reader.readAsDataURL($element[0].files[0]);
			}

			$element.bind('change', onChange);

			$scope.$on('$destroy', function () {
				$element.off('change', onChange);
			});
		},
	};
}

module.exports = avatarFileModel;
