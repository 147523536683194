import { cloneDeep, isFunction, isInteger, isNil } from 'lodash';

const TSTableComponent = {
	bindings: {
		busy: '<?',
		columns: '<',
		customSearch: '&?',
		maxRows: '<',
		onButtonClick: '&?',
		onCellClick: '&?',
		onEdit: '&?',
		onLink: '&?',
		onPageSelect: '&?',
		onSwitch: '&?',
		pages: '<?',
		page: '<?',
		readOnly: '<',
		rows: '<',
		status: '<?',
	},
	template: `
		<div class="table-container">
			<div data-ts="Table"></div>
		</div>
	`,
	controller: class TSTableController {
		/* @ngInject */
		constructor($element, $timeout, EventEmitter, LexicoService) {
			this.EventEmitter = EventEmitter;
			this.lexico = LexicoService.getLexico();
			this.$element = $element;
			this.$timeout = $timeout;
		}

		$onInit() {
			ts.ui.get(this.$element.find('div')[1], element => {
				this.table = element;

				// the table is initialized here since it is not available when $onChanges is called before $onInit
				// and the table is not available at that time
				this.onButtonClick && this.setOnButtonClick(this.onButtonClick);
				this.onCellClick && this.setOnCellClick(this.onCellClick);
				this.onEdit && this.setOnEdit(this.onEdit);
				this.onLink && this.setOnLink(this.onLink);
				this.onSwitch && this.setOnSwitch(this.onSwitch);
				this.setColumns(this.columns);
				this.setRows(this.rows);
				this.setPagination(this.onPageSelect);
				this.status && this.setStatus(this.status);
			});
		}

		$onChanges(changes) {
			if (!this.table) {
				return; // $onChanges is called before $onInit so need to make sure we have the element
			}
			if (changes.busy && changes.busy.currentValue) {
				this.setBusy(changes.busy.currentValue);
			}
			if (changes.columns && changes.columns.currentValue) {
				this.setColumns(changes.columns.currentValue);
			}
			if (changes.onButtonClick && changes.onButtonClick.currentValue) {
				this.setOnButtonClick(changes.onButtonClick.currentValue);
			}
			if (changes.onCellClick && changes.onCellClick.currentValue) {
				this.setOnCellClick(changes.onCellClick.currentValue);
			}
			if (changes.onEdit && changes.onEdit.currentValue) {
				this.setOnEdit(changes.onEdit.currentValue);
			}
			if (changes.onLink && changes.onLink.currentValue) {
				this.setOnLink(changes.onLink.currentValue);
			}
			if (changes.onSwitch && changes.onSwitch.currentValue) {
				this.setOnSwitch(changes.onSwitch.currentValue);
			}
			if (changes.rows && changes.rows.currentValue) {
				this.setRows(changes.rows.currentValue);
			}
			if (changes.status && changes.status.currentValue) {
				this.setStatus(changes.status.currentValue);
			}

			if (changes.pages && isInteger(changes.pages.currentValue)) {
				this.setPagination(this.onPageSelect, changes.pages.currentValue, null);
			}
			if (changes.page && isInteger(changes.page.currentValue)) {
				this.setPagination(this.onPageSelect, null, changes.page.currentValue);
			}
		}

		setBusy(string) {
			if (string === 'true') {
				this.table.busy();
			} else {
				this.table.done();
			}
		}

		setColumns(columns = []) {
			if (isFunction(columns)) {
				this.setColumns(columns(this.table));
				return;
			}

			this.columns = cloneDeep(columns);

			this.columns.forEach((column, index) => {
				if (column.search) {
					if (column.search.customSearch) {
						column.search.onidle = value => {
							this.customSearch(this.EventEmitter({ value }));
						};
					} else {
						column.search.onidle = value => {
							this.table.search(index, value);
						};
					}
				}
			});

			this.table.cols(this.columns);
		}

		setOnButtonClick(callback) {
			this.table.onbutton = (name, value) => {
				callback(this.EventEmitter({ name, value }));
			};
		}

		setOnCellClick(callback) {
			this.table.clickable((rowIndex, columnIndex) => {
				// $timeout forces digest cycle so changes in parent method are propagated to children
				// pass back row and column index so source item can be retrieved by parent, e.g. to get the object UUID
				this.$timeout(() => callback(this.EventEmitter({ rowIndex, columnIndex })));
			});
		}

		setOnEdit(callback) {
			if (this.readOnly) {
				return;
			}
			this.table.editable((rowIndex, columnIndex, value) => {
				this.$timeout(() => {
					const event = this.EventEmitter({ columnIndex, rowIndex, value });
					const { valid } = callback(event);
					if (valid) {
						this.table.cell(rowIndex, columnIndex, value);
					} else {
						this.table.invalid(rowIndex, columnIndex, value);
					}
				});
			});
		}

		setOnLink(callback) {
			this.table.linkable(value => {
				this.$timeout(() => callback(this.EventEmitter({ value })));
			});
		}

		setOnSwitch(callback) {
			this.table.onswitch = (name, value, checked, rowIndex, columnIndex) => {
				this.$timeout(() => callback(this.EventEmitter({ name, value, checked, rowIndex, columnIndex })));
			};
		}

		setPagination(callback, pages, page) {
			if (isInteger(pages)) {
				this.pages = pages;
			}
			if (isInteger(page)) {
				this.page = page;
			}
			if (!isNil(this.page) && !isNil(this.pages)) {
				this.table.pager({
					pages: this.pages,
					page: this.page,
					onselect: index => {
						this.$timeout(() => callback(this.EventEmitter({ index })));
					},
				});
			}
		}

		setRows(rows = []) {
			this.rows = cloneDeep(rows);
			if (this.rows.length === 0) {
				this.table.status(this.lexico.trc('Empty data table message', 'There is no data to show'));
			} else {
				this.table.status('');
			}

			if (this.maxRows && this.rows.length > Number(this.maxRows)) {
				this.table.rows(this.rows).max(this.maxRows);
			} else {
				this.table.rows(this.rows);
			}
		}

		setStatus(status) {
			this.table.status(status);
		}
	},
};

export default TSTableComponent;
