'use strict';

const _ = require('lodash');

const validator = {};

validator.errors = {
	INVALID_FORMAT: 'INVALID_FORMAT',
	EXCEEDED_AMOUNT: 'EXCEEDED_AMOUNT',
};

validator.isApprovalLimitFormatValid = limit => {
	if (limit === '') {
		return true;
	}
	if (Number(limit) === 0) {
		return false;
	}
	const positiveIntegerRegex = '^[1-9][0-9]*$';
	const positiveInteger = new RegExp(positiveIntegerRegex);
	return !_.isNull(positiveInteger.exec(limit));
};

validator.validateApprovalLimit = (approvalLimit, maxApprovalLimit) => {
	if (!validator.isApprovalLimitFormatValid(approvalLimit)) {
		return validator.errors.INVALID_FORMAT;
	}
	if (maxApprovalLimit && Number(approvalLimit) > Number(maxApprovalLimit)) {
		return validator.errors.EXCEEDED_AMOUNT;
	}
	return '';
};

module.exports = validator;
