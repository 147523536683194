"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSingleFlight = createSingleFlight;
function createSingleFlight(promiseSupplier) {
    let currentPromise = null;
    const cache = new Map();
    return (args) => {
        const key = args === undefined ? 'no-args' : JSON.stringify(args);
        if (cache.has(key)) {
            return Promise.resolve(cache.get(key));
        }
        if (!currentPromise) {
            currentPromise = promiseSupplier(args)
                .then(result => {
                cache.set(key, result);
                return result;
            })
                .finally(() => {
                currentPromise = null;
                cache.delete(key); // Optional: remove from cache when promise is settled
            });
        }
        return currentPromise;
    };
}
