const _ = require('lodash');

/* @ngInject */
const CodingListTable = ($state, CodingHelper) => {
	const factory = ({
		confirmDeleteEntryAside,
		costCenterOptions,
		entries: _entries,
		listId,
		selectCostCenterAside,
		showCostCentersColumn,
	}) => {
		const entries = _.cloneDeep(_entries);
		const rowsWithInvalidId = new Set([]);
		const rowsWithInvalidPath = new Set([]);
		let newEntry = {};

		const columns = CodingHelper.getCodingListTableColumns(showCostCentersColumn);
		const columnMap = CodingHelper.codingListTableColumnMap;

		const table = {
			addCostCenters: ({ columnIndex, rowIndex, values }) => {
				if (rowIndex === columnMap.NEW_ENTRY) {
					newEntry = CodingHelper.getUpdatedEntry(newEntry, columnIndex, values);
				} else {
					const entry = entries[rowIndex - 1];
					const updatedEntry = CodingHelper.getUpdatedEntry(entry, columnIndex, values);
					entries[rowIndex - 1] = updatedEntry;
					CodingHelper.saveEntry({
						entry: updatedEntry,
						listId,
						replacesEntry: entry,
					});
				}
				resetRows();
			},
			columns,
			onCellClick: ({ columnIndex, rowIndex }) => {
				if (![columnMap.ACTIONS, columnMap.COST_CENTERS, columnMap.SELECT].includes(columnIndex)) {
					return;
				}
				if (columnIndex === columnMap.COST_CENTERS || columnIndex === columnMap.SELECT) {
					onCostCentersClick(columnIndex, rowIndex);
				} else if (rowIndex === columnMap.NEW_ENTRY) {
					onAddEntryClick();
				} else {
					onDeleteEntryClick(rowIndex);
				}
			},
			onEdit: ({ columnIndex: fieldType, rowIndex, value }) => {
				if (rowIndex === columnMap.NEW_ENTRY) {
					newEntry = CodingHelper.getUpdatedEntry(newEntry, fieldType, value);
					return { valid: true };
				} // else -> editing an existing entry
				const entry = entries[rowIndex - 1];
				const fieldValidation = CodingHelper.validateEntryField({ entries, entry, fieldType, value });
				if (fieldValidation.isValid) {
					fieldType === columnMap.ID ? rowsWithInvalidId.delete(rowIndex) : rowsWithInvalidPath.delete(rowIndex);
				} else {
					fieldType === columnMap.ID ? rowsWithInvalidId.add(rowIndex) : rowsWithInvalidPath.add(rowIndex);
				}
				if (!fieldValidation.isValid || !fieldValidation.isDirty) {
					return { valid: fieldValidation.isValid };
				}
				const updatedEntry = CodingHelper.getUpdatedEntry(entry, fieldType, value);
				entries[rowIndex - 1] = updatedEntry;
				if (!rowsWithInvalidId.has(rowIndex) && !rowsWithInvalidPath.has(rowIndex)) {
					CodingHelper.saveEntry({
						entry: updatedEntry,
						listId,
						replacesEntry: entry,
					});
				}
				return { valid: true };
			},
			removeEntry: entry => {
				const entryIndex = _.findIndex(entries, ({ ID: id }) => id === entry.ID);
				entries.splice(entryIndex, 1);
				resetRows();
			},
			showCostCentersColumn,
			updateNewEntry: (fieldType, value) => {
				newEntry = CodingHelper.getUpdatedEntry(newEntry, fieldType, value);
			},
		};

		const addEntry = entry => {
			entries.push(entry);
			_.sortBy(entries, 'ID');
			resetRows();
		};

		const resetRows = () => {
			table.rows = CodingHelper.getCodingListTableRows(costCenterOptions, entries, newEntry, showCostCentersColumn);
		};

		const onAddEntryClick = () => {
			if (!CodingHelper.entryHasData(newEntry, showCostCentersColumn)) {
				return;
			}
			if (!CodingHelper.isNewEntryId(entries, newEntry.ID)) {
				resetRows();
				return;
			}
			const entry = newEntry;
			CodingHelper.saveEntry({ entry, listId }).then(() => {
				newEntry = {};
				addEntry(entry);
			});
		};

		const onCostCentersClick = (columnIndex, rowIndex) => {
			const entry = rowIndex === columnMap.NEW_ENTRY ? newEntry : entries[rowIndex - 1];
			if (_.has(entry, 'filteredBy')) {
				entry.id = entry.filteredBy
					.filter(entryFilter => {
						// Don't allow to save cost centers that don't exist
						const costCenter = _.find(costCenterOptions, ['id', entryFilter.entryID]);
						return entryFilter.listID === 'costcentercode' && costCenter;
					})
					.map(costCenterEntry => costCenterEntry.entryID);
			} else {
				entry.id = [];
			}

			entry.columnIndex = columnIndex;
			entry.rowIndex = rowIndex;
			selectCostCenterAside.model = entry;
			selectCostCenterAside.isOpen = true;
		};

		const onDeleteEntryClick = rowIndex => {
			const entry = entries[rowIndex - 1];
			confirmDeleteEntryAside.entry = entry;
			confirmDeleteEntryAside.message = `Delete coding ID: ${entry.ID}, Entry: ${entry.path}?`;
			confirmDeleteEntryAside.isOpen = true;
		};

		resetRows();
		return table;
	};

	return factory;
};

module.exports = CodingListTable;
