"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiAppBarOverrides = void 0;
function getNavigationPanelStyle(theme) {
    return {
        zIndex: '1',
        width: '100%',
        color: theme.palette.tsGoColor.black,
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        paddingBottom: '1px',
        justifyContent: 'center',
        backgroundColor: theme.palette.tsGoColor.white,
        borderBottom: `1px solid ${theme.palette.tsGoColor.grayLight()}`,
        boxShadow: 'none',
    };
}
function getMainHeaderStyle(theme) {
    return {
        width: '100%',
        display: 'flex',
        position: 'relative',
        backgroundColor: theme.palette.tsGoColor.white,
        flexDirection: 'row',
        fontWeight: theme.typography.tsFontWeight.default,
        color: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'black' : theme.palette.tsGoColor.slateLighter,
        fontSize: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? '16px' : theme.typography.tsFontSize.default,
        lineHeight: theme.tsGoUnit.tsUnit,
        padding: theme.tsGoUnit.tsUnitHalf,
        boxSizing: 'border-box',
        boxShadow: 'none',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    };
}
exports.muiAppBarOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            ...(ownerState['ts-variant'] === 'navigation-panel' && getNavigationPanelStyle(theme)),
            ...(ownerState['ts-variant'] === 'main-header' && getMainHeaderStyle(theme)),
        }),
    },
};
