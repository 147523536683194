import { cloneDeep } from 'lodash';

const ForwardRequestAsideComponent = {
	bindings: {
		approval: '<',
		isOpen: '<',
		onClose: '&',
		onSuccess: '&?',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.forwardRequestTitleText}}">
			<forward-request-panel-v2
					form-options="$ctrl.formOptions"
					is-submitting="$ctrl.isSubmitting"
					on-cancel="$ctrl.onClose()"
					on-submit="$ctrl.onSubmit($event)"></forward-request-panel-v2>
		</ts-aside>
	`,
	controller: class ForwardRequestAsideController {
		/* @ngInject */
		constructor(EventEmitter, LexicoService, ReassignRequestService, forwardRequestHelperV2) {
			this.EventEmitter = EventEmitter;
			this.forwardRequestHelperV2 = forwardRequestHelperV2;
			this.lexico = LexicoService.getLexico();
			this.forwardRequestTitleText = this.lexico.trc('Component title', 'Forward request');
			this.ReassignRequestService = ReassignRequestService;
		}

		$onChanges(changes) {
			if (changes.approval && changes.approval.currentValue) {
				this.approval = cloneDeep(changes.approval.currentValue);
			}
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}
		}

		onOpen() {
			this.isLoading = true;
			return this.forwardRequestHelperV2
				.getFormOptions({
					approverId: this.approval.getAssignedUserId(),
					teamId: this.approval.getTeamId(),
				})
				.then(formOptions => {
					this.formOptions = formOptions;
				})
				.catch(() => {
					const warningMessage = this.lexico.trc(
						'Warning message',
						'There was a problem forwarding the request. Please try again.',
					);
					ts.ui.Notification.warning(warningMessage);
					this.onClose();
				})
				.finally(() => {
					this.isLoading = false;
				});
		}

		onSubmit({ assignee, reason }) {
			this.isSubmitting = true;
			const approval = this.approval;
			this.ReassignRequestService.reassignRequest({ assignee, approval, reason })
				.then(() => {
					const successMessage = this.lexico.trc('Success message', 'Request forwarded.');
					ts.ui.Notification.success(successMessage);
					this.onSuccess(this.EventEmitter());
				})
				.catch(() => {
					const warningMessage = this.lexico.trc(
						'Warning message',
						'There was a problem forwarding the request. Please try again.',
					);
					ts.ui.Notification.warning(warningMessage);
				})
				.finally(() => {
					this.isSubmitting = false;
				});
		}
	},
};

export default ForwardRequestAsideComponent;
