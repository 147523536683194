const commonConstants = require('../../common/lib/constants');
const moreValues = {
	moment: {
		datesInclusive: '[]',
	},
	errorStrings: {
		suspectedVirus: 'We checked your upload for viruses and your file was suspicious.',
	},
	cache: {
		defaultSettings: {
			deleteOnExpire: 'aggressive',
			recycleFreq: 60000,
			// TODO: make this work https://github.com/Tradeshift/tradeshift-go/pull/248
			// storeOnReject: true,
		},
		ids: {
			accessCache: 'accessCache',
			userCache: 'userCache',
			userSettingsCache: 'userSettingsCache',
			walletCache: 'walletCache',
			teamCache: 'teamCache',
		},
		maxAge: 15 * 60 * 1000, // 15 Minutes
	},
	cookies: {
		preLoginRouterState: 'TS_GO_PRE_LOGIN_ROUTER_STATE',
	},
	datePickerFormat: 'YYYY-MM-DD',
	emptyBillingAddress: {
		addressLine1: '',
		city: '',
		countryCode: '',
		region: '',
		zipCode: '',
	},
	goTypingEventId: 'event9999999',
	scopeEvents: {
		approvalsUpdate: 'approvalsUpdate',
		dataEvent: 'dataEvent',
		goTyping: 'goIsTyping',
		loggedOut: 'loggedOut',
		newVirtualCard: 'virtualCardAdded',
		removeApproval: 'removeApproval',
		approvalRemoved: 'approvalRemoved',
	},
	tasks: {
		outcomes: {
			approved: 'approved',
			canceled: 'card-canceled',
			completed: 'completed',
			reassigned: 'reassigned',
			rejected: 'rejected',
			selfReassigned: 'self-reassigned',
		},
		states: {
			assigned: 'assigned',
			completed: 'completed',
		},
	},
	approvals: {
		oooChangeEvent: 'OOO::SETTINGS_CHANGE',
		stateLabels: {
			approved: 'Approved',
			'card-canceled': 'Card canceled',
			rejected: 'Declined',
		},
		sortTypes: {
			mostRecent: 'Most recent',
			mostRecentlyActive: 'Most recently active',
			cardType: 'Card type',
			requestedAmount: 'Requested amount',
			requester: 'Requester',
		},
	},
	features: {
		ENABLE_CHROME_EXTENSION_LINK: 'Enable chrome extension link',
		ENABLE_COMPANY_PEOPLE_VIEW: 'Enable people view for company admin users',
		ENABLE_DOMAIN_WHITELISTING: 'Enable email domain whitelisting for invited users',
		ENABLE_FILTERED_BY_COSTCENTER: 'Enable filtering by costcenter',
		ENABLE_GLOBALIZATION: 'Enable globalization option in user aside',
		ENABLE_MULTIUSE: 'Enable multi use virtual cards',
		ENABLE_OUT_OF_OFFICE_FORWARDING: 'Enable automatic forwarding of approval requests',
		ENABLE_SETTLEMENT_RECEIPT_UPLOAD: 'Enable receipt upload in settlements table',
		ENABLE_SINGLEUSE: 'Enable single use virtual cards',
		ENABLE_SUBSCRIPTIONS: 'Enable subscription virtual cards',
		ENABLE_TRANSACTION_RECEIPT_UPLOAD: 'Enable receipt upload for transactions',
		ENABLE_USER_APPROVAL_LIMITS: 'Approvers have a maximum approval limit',
		ENABLE_VIRTUAL_CARD_EXP_RANGE: 'Enable maximum expiration date for virtual card',
		PAUSE_VIRTUAL_CARD_CREATION: 'Pause new virtual cards from being issued',
		ENABLE_ALTERNATE_GET_USER_FUNCTIONS: 'Enable master branch link',
		ENABLE_API_INTEGRATION: 'Enable API integration',
	},
	userPropertyKeys: {
		costcenter: 'costcenter',
		manager: 'manager',
		approvallimit: 'go-virtual-card-approval-limit',
	},
	regexPatterns: {
		// amexProviderFields contains the list of special chars from the amex doc, excluding '+' which we have seen is
		// not actually supported
		amexProviderFields: "^(?!\\s+$)[a-zA-Z0-9\\s.\\-?,='{}`;()/!|$:_@*%~#^£¢¤¬]+$",
		currencyZeroInvalid: '^\\$?0\\.(0[1-9]|[1-9][0-9]){1,2}$|^\\$?[1-9]\\d{0,2}((,\\d{3})*|(\\d{3})*)(\\.\\d{2})?$',
		currencyZeroValid: '^\\$?0(\\.[0-9][0-9])?$|^\\$?[1-9]\\d{0,2}((,\\d{3})*|(\\d{3})*)(\\.\\d{2})?$',
		cardNumber: '^[0-9]{0,16}$',
		zipCodeNumber: '^\\d{0,5}(-\\d{0,4})?$',
	},
	maxCompanyApprovalLimit: 50000.0,
	request: {
		menuIcons: {
			approved: 'ts-icon-approve',
			created: 'ts-icon-drafts',
			default: 'ts-icon-drafts',
			new: 'ts-icon-drafts',
			rejected: 'ts-icon-reject',
			submitted: 'ts-icon-activity',
		},
		states: {
			approved: 'approved',
			created: 'created',
			deleted: 'deleted',
			new: 'new',
			rejected: 'rejected',
			requested: 'requested',
			submitted: 'submitted',
			sequenceNumber: {
				approved: 3,
				created: 1,
				deleted: -1,
				new: 0,
				rejected: 3,
				requested: 2,
				submitted: 2,
			},
		},
		status: {
			approved: 'Approved',
			created: 'Created',
			deleted: 'Deleted',
			default: 'Requested',
			new: 'Created',
			rejected: 'Declined',
			requested: 'Requested',
			submitted: 'Pending',
		},
		tags: {
			archive: 'archived',
		},
	},
	requestList: {
		keys: {
			active: 'active',
			archive: 'archive',
		},
		options: {
			defaults: {},
			active: {
				archived: false,
			},
			archive: {
				archived: true,
			},
		},
	},
	cardsourceStatus: {
		ACTIVE: 'Active',
		IN_PROGRESS: 'Pending',
		INACTIVE: 'Inactive',
		INVALID: 'Invalid',
	},
	userStateToStatus: {
		ACTIVE: 'Active',
		CREATED: 'Invited',
		LOCKED: 'Removed',
	},
	userStatus: {
		ACTIVE: 'ACTIVE',
		CREATED: 'CREATED',
		LOCKED: 'LOCKED',
	},
	virtualCards: {
		AMEX: {
			cardDuration: 28,
			errorCodes: {
				missingVCardDetails: 'SYS001',
			},
		},
	},
	creditCards: [
		{
			isSupported: true,
			key: 'AECC',
			label: 'American Express Corporate Card',
		},
		{
			isSupported: false,
			key: 'AEOC',
			label: 'American Express Open Card',
		},
		{
			isSupported: false,
			key: 'BA',
			label: 'Bank of America',
		},
		{
			isSupported: false,
			key: 'B',
			label: 'Barclays',
		},
		{
			isSupported: false,
			key: 'BMOH',
			label: 'BMO Harris',
		},
		{
			isSupported: false,
			key: 'CO',
			label: 'Capital One',
		},
		{
			isSupported: false,
			key: 'CG',
			label: 'Citigroup',
		},
		{
			isSupported: false,
			key: 'HSBC',
			label: 'HSBC',
		},
		{
			isSupported: false,
			key: 'JPMC',
			label: 'JPMorgan Chase',
		},
		{
			isSupported: false,
			key: 'PNCB',
			label: 'PNC Bank',
		},
		{
			isSupported: false,
			key: 'SVB',
			label: 'SVB',
		},
		{
			isSupported: false,
			key: 'USB',
			label: 'U.S. Bank',
		},
		{
			isSupported: false,
			key: 'WF',
			label: 'Wells Fargo',
		},
	],
	eventsDelayMs: 2000,
	eventsDelayLimitCounter: 5,
	frequencies: {
		DAILY: {
			timePeriodDifference: 1,
			unit: 'day',
		},
		MONTHLY: {
			timePeriodDifference: 1,
			unit: 'month',
		},
		QUARTERLY: {
			timePeriodDifference: 3,
			unit: 'month',
		},
		WEEKLY: {
			timePeriodDifference: 1,
			unit: 'week',
		},
		YEARLY: {
			timePeriodDifference: 1,
			unit: 'year',
		},
	},
	// the id in this options pertains to the number of days in a month. Every month is equal to 30 days.
	// Using Id as the key, since ts-select expects the id to be the value
	virtualCardExpRanges: [
		{
			label: '1 month',
			id: 30,
		},
		{
			label: '3 months',
			id: 90,
		},
		{
			label: '6 months',
			id: 180,
		},
		{
			label: '1 year',
			id: 360,
		},
	],
	virtualCardDefaultExpDate: 1460,
	roles: {
		admin: {
			description: 'Manage members, company settings, access to all requests',
			id: 'a6a3edcd-00d9-427c-bf03-4ef0112ba16d',
			label: 'Admin',
		},
		basic: {
			description: 'Login and view company',
			id: 'ad298300-ea11-11e4-b571-0800200c9a66',
			label: 'Basic',
		},
		finance: {
			description: 'Access to all requests',
			id: 'bcd0f0d1-a4d3-4981-9ba2-568a960a09fd',
			label: 'Finance',
		},
		member: {
			description: 'Access to own requests',
			id: 'fe888fbb-172f-467c-b9ad-efe0720fecf9',
			label: 'Member',
		},
		support: {
			description: 'Tradeshift support user',
			id: '771ea47c-334c-4a9d-981a-494a1bdcd27e',
			label: 'Support',
		},
	},
};
const constants = commonConstants.clone({ moreValues });

module.exports = constants;
