"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiMenuItemOverrides = exports.hasUnreadMessagesClassName = void 0;
const tsGoThemeVars_1 = require("../tsGoThemeVars");
function getCheckedStyle(ownerState) {
    if (ownerState.disabled) {
        return {
            backgroundColor: '#e6ecee',
            cursor: 'not-allowed',
        };
    }
    return { backgroundColor: '#0084cc', color: '#fff', '&:hover': { backgroundColor: '#0084cc', color: '#fff' } };
}
function getDefaultStyle() {
    return {
        fontSize: tsGoThemeVars_1.tsGoTypography.tsFontSize.default,
        fontWeight: tsGoThemeVars_1.tsGoTypography.tsFontWeight.bold,
        lineHeight: 2,
    };
}
exports.hasUnreadMessagesClassName = 'hasUnreadMessages';
function getSideMenuPanelOptionStyle(theme) {
    return {
        '&::before': {
            bottom: '0',
            content: "''",
            left: '0',
            position: 'absolute',
            right: 'auto',
            top: '0',
            width: '5px',
            backgroundColor: theme.palette['tsGoColor'].yellowDarker,
            opacity: 0,
        },
        [`&.${exports.hasUnreadMessagesClassName}::after`]: {
            content: "''",
            position: 'absolute',
            right: theme.tsGoUnit.tsUnit,
            top: '50%',
            width: theme.tsGoUnit.tsUnitHalf,
            backgroundColor: 'hsl(0, 100%, 37%)',
            borderRadius: '100%',
            height: theme.tsGoUnit.tsUnitHalf,
            transform: 'translateY(-50%)',
        },
        a: {
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flexWrap: 'wrap',
            svg: {
                top: '50%',
                transform: 'translateY(-50%)',
            },
            '& > :not(svg)': {
                paddingLeft: '15px',
            },
        },
        '&:hover, &.active': {
            background: 'rgba(34, 46, 51, 1)',
            '&::before': {
                opacity: 1,
            },
            a: {
                background: 'rgba(34, 46, 51, 1)',
                textDecoration: 'none',
            },
        },
        '&.active-option': {
            '&::before': {
                marginLeft: '0 !important',
            },
        },
        '&.link__collapsed': {
            '&:hover': {
                '.tooltip': {
                    opacity: 1,
                },
            },
        },
    };
}
exports.muiMenuItemOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            ...(ownerState.className === 'ts-checked' && getCheckedStyle(ownerState)),
            ...getDefaultStyle(),
            ...(ownerState['ts-variant'] === 'sideMenuPanelOption' && getSideMenuPanelOptionStyle(theme)),
        }),
    },
};
