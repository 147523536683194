const _ = require('lodash');

/* @ngInject */
function aside($parse) {
	function safeDigest(scope) {
		if (!_.get(scope, '$root.$$phase')) {
			scope.$digest();
		}
	}
	function setupOpeningAndClosing(scope, element, attrs) {
		const goShowGetter = $parse(attrs.goShow);
		const goShowSetter = goShowGetter.assign;

		const openPanelFunction = function () {
			if (goShowSetter) {
				goShowSetter(scope, true);
			}
		};

		let closePanelFunction;

		if (Object.prototype.hasOwnProperty.call(attrs, 'goClose')) {
			closePanelFunction = function () {
				scope.$eval(attrs.goClose);
			};
		} else {
			closePanelFunction = function () {
				if (goShowSetter) {
					goShowSetter(scope, false);
				}
			};
		}

		scope.$watch(attrs.goAsideBusy, function (busy) {
			element.ready(function () {
				if (busy) {
					element.attr('data-ts.busy', 'Loading...');
				} else {
					element.attr('data-ts.busy', 'false');
				}
			});
		});

		scope.$watch(attrs.goShow, function (show) {
			element.ready(function () {
				if (show) {
					element.attr('data-ts.open', 'true');
				} else {
					element.attr('data-ts.open', 'false');
				}
			});
		});

		element.on(ts.ui.EVENT_ASIDE_WILL_OPEN, function () {
			openPanelFunction();
			safeDigest(scope);
		});
		element.on(ts.ui.EVENT_ASIDE_DID_CLOSE, function () {
			closePanelFunction();
			safeDigest(scope);
		});
	}

	return {
		restrict: 'E',
		link: function ($scope, $element, $attrs) {
			const appElement = document.querySelectorAll('[ng-app]')[0];
			appElement.appendChild($element[0]);

			setupOpeningAndClosing($scope, $element, $attrs);

			$attrs.$observe('goTitle', function () {
				$element.attr('data-ts.title', $element.attr('go-title'));
			});
			$scope.$on('$destroy', function () {
				$element.remove();
			});
		},
	};
}

module.exports = aside;
