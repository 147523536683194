const _ = require('lodash');

/* @ngInject */
function CodingService($q, CodingEntryStateService, HttpService, LoadingService, TeamsService) {
	const service = {};

	const addEntriesToLists = (entriesForLists, lists) => {
		return _.chain(lists)
			.map(({ ID: listId, name: label }, index) => {
				return {
					listId,
					label,
					entries: _.map(entriesForLists[index], ({ ID: entryId, filteredBy, path }) => {
						return {
							entryId,
							filteredBy,
							label: path,
							listId,
						};
					}),
				};
			})
			.keyBy('listId')
			.value();
	};
	const filterEmptyLists = lists => {
		return _.reduce(
			Object.entries(lists),
			(result, [listId, list]) => {
				if (list.entries.length > 0) {
					result[listId] = list;
				}
				return result;
			},
			{},
		);
	};
	service.addEntry = async ({ entryId, entryLabel, filteredBy, listId, replaceEntryId, teams }) => {
		const replaceEntryIdQuery = replaceEntryId === undefined ? '' : `?replaceEntryId=${replaceEntryId}`;
		LoadingService.startLoading();
		await HttpService.tradeshiftGo()
			.path(`/rest/coding/lists/${listId}/entries/${entryId}${replaceEntryIdQuery}`)
			.put({ entryLabel, filteredBy });
		if (teams) {
			const allTeams = await TeamsService.getTeams();
			_.each(allTeams, async team => {
				const entryState = {
					entryId,
					value: _.includes(teams, team.id),
				};
				const options = {
					entryId,
					entryState,
					listId,
					teamId: team.id,
				};
				await CodingEntryStateService.setCodingEntryState(options);
			});
		}
		LoadingService.stopLoading();
	};
	service.saveList = ({ code, description, group, id, name, ordering, target }) => {
		return HttpService.tradeshiftGo()
			.path('/rest/coding/lists/')
			.post({ code, description, group, id, name, ordering, target });
	};
	service.deleteEntry = ({ listId, entryId }) => {
		return HttpService.tradeshiftGo().path(`/rest/coding/lists/${listId}/entries/${entryId}`).delete();
	};
	service.deleteList = ({ listId }) => {
		return HttpService.tradeshiftGo().path(`/rest/coding/lists/${listId}`).delete();
	};
	service.disableList = ({ listId }) => {
		return HttpService.tradeshiftGo().path(`/rest/coding/lists/${listId}/disable`).post();
	};
	service.enableList = ({ listId }) => {
		return HttpService.tradeshiftGo().path(`/rest/coding/lists/${listId}/enable`).post();
	};
	service.getListEntries = ({ listId }) => {
		return HttpService.tradeshiftGo()
			.path(`/rest/coding/lists/${listId}/entries`)
			.get()
			.catch(error => {
				// If the list doesn't exist a 404 is returned and enters this catch block
				// The error is delivered to Sentry before entering this catch block.
				if (error.status === 404) {
					return [];
				}
				throw error;
			});
	};
	service.getList = ({ listId }) => {
		return HttpService.tradeshiftGo().path(`/rest/coding/lists/${listId}`).get();
	};
	service.getLists = ({ includeDisabled = false } = {}) => {
		return HttpService.tradeshiftGo().path(`/rest/coding/lists?includeDisabled=${includeDisabled}`).get();
	};
	service.getListsAndEntries = ({ includeDisabledLists = false, includeEmptyLists = true } = {}) => {
		return service.getLists({ includeDisabled: includeDisabledLists }).then(lists => {
			const promises = _.map(lists, ({ ID: listId }) => service.getListEntries({ listId }));
			return $q
				.all(promises)
				.then(entriesForLists => addEntriesToLists(entriesForLists, lists))
				.then(listsWithEntries => (includeEmptyLists ? listsWithEntries : filterEmptyLists(listsWithEntries)));
		});
	};
	service.getProviderFieldsMap = () => {
		return HttpService.tradeshiftGo().path('/rest/coding/providerFields').get();
	};
	service.findById = (list, id) => {
		return _.find(list, { ID: id });
	};
	service.findByPath = (list, path) => {
		return _.find(list, { path });
	};
	service.saveProviderFieldsMap = map => {
		return HttpService.tradeshiftGo().path('/rest/coding/providerFields').put(map);
	};
	service.mapTeamCodingLists = lists => {
		return lists.reduce((output, { entries: rawEntries, listId, name: label }) => {
			const entries = rawEntries.map(({ entryId, entryLabel, filteredBy }) => {
				return { entryId, filteredBy, listId, label: entryLabel };
			});
			output[listId] = { entries, label, listId };
			return output;
		}, {});
	};
	return service;
}

module.exports = CodingService;
