const HttpClient = require('../helpers/HttpClient');

/* @ngInject */
function HttpService($http) {
	const service = {};

	service.getConfig = () => window.config;

	service.tradeshiftGoFrontend = () => new HttpClient($http, '');

	service.tradeshiftGo = () =>
		new HttpClient($http, service.getConfig().TRADESHIFT_GO_HOST, { withCredentials: true }).external();

	service.payments = () => new HttpClient($http, service.getConfig().PAYMENTS_EXTERNAL_HOST);

	return service;
}

module.exports = HttpService;
