'use strict';

const _ = require('lodash');

const roles = {
	companyAdmin: {
		id: 'company_admin',
		label: 'Company admin',
		mappedTSRoleId: 'a6a3edcd-00d9-427c-bf03-4ef0112ba16d',
	},
	teamAdmin: {
		id: 'team_admin',
		label: 'Team owner',
		mappedTSRoleId: 'a6a3edcd-00d9-427c-bf03-4ef0112ba16d',
	},
	manager: {
		id: 'manager',
		label: 'Manager',
		mappedTSRoleId: 'fe888fbb-172f-467c-b9ad-efe0720fecf9',
	},
	teamMember: {
		id: 'team_member',
		label: 'Team member',
		mappedTSRoleId: 'fe888fbb-172f-467c-b9ad-efe0720fecf9',
	},
	tradeshiftSupportAdmin: {
		id: 'tradeshift_support_admin',
		label: 'Tradeshift support admin',
		mappedTSRoleId: 'a6a3edcd-00d9-427c-bf03-4ef0112ba16d',
	},
};

const getRoleById = id => _.find(roles, { id });

// For selects in which we are displaying a role we want to return the whole object
// so the `<ts-select` can display it correctly. This requires us to have something that looks like
// { companyAdmin: { id: 'company_admin', ... } } so we have to build the object from the entries
const getRoleOptionsObject = id => {
	try {
		const roleEntryPair = Object.entries(roles).find(entry => entry[1].id === id);
		return {
			[roleEntryPair[0]]: roleEntryPair[1],
		};
	} catch (error) {
		return new Error('There was no role for the given id');
	}
};

module.exports = Object.assign(roles, { getRoleById, getRoleOptionsObject });
