"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledBox = void 0;
const material_1 = require("@mui/material");
exports.StyledBox = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    margin: '0 auto',
    position: 'relative',
    padding: '0',
    zIndex: '2',
    display: 'block',
    backgroundColor: 'transparent',
    width: '100%',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        width: '100%',
        height: '1px',
        zIndex: '-1',
        backgroundColor: theme?.palette.tsGoColor.grayLight(),
        display: 'inline-block',
        left: '50%',
        transform: 'translateX(-50%)',
        paddingLeft: theme?.tsGoUnit.tsUnitHalf,
        paddingRight: theme?.spacing(0.5),
    },
    '> p': {
        fontWeight: theme?.typography.tsFontWeight.default,
        textTransform: 'uppercase',
        backgroundColor: theme.palette.tsGoColor.white,
        fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.mini)}`,
    },
}));
