const _ = require('lodash');
const LIMIT = 50;
const START_PAGE = 0;

/* @ngInject */
function UsersController(
	$scope,
	_approvers,
	_costCenters,
	_defaultManager,
	_manageHeader,
	_user,
	_usersInfo,
	AsideService,
	CodingService,
	FooterService,
	constants,
	RoleService,
	UserService,
) {
	const FOOTER_BUTTONS = [
		{
			label: 'Invite user',
			onclick: function () {
				AsideService.open('inviteUser', {
					onSuccess: addUser,
				});
				$scope.$digest();
			},
			type: 'ts-primary tst-invite-user-button',
		},
		{
			label: 'Set default manager',
			onclick: function () {
				AsideService.open('defaultManager', {
					model: {
						defaultManager: $scope.defaultManager,
						managers: $scope.managers,
					},
					onSuccess: function (manager) {
						$scope.defaultManager = manager;
					},
				});
				$scope.$digest();
			},
			type: 'ts-secondary',
		},
	];
	let _table;

	function addUser(user) {
		if (user.roleId !== RoleService.getRole('support').id) {
			$scope.users.push(user);
			addUserToTable(_table.rows(), user);
			setTableStatusMessage(_table, $scope.users);
		}
	}

	$scope.asides = AsideService.get(['defaultManager', 'inviteUser']);

	$scope.componentAsides = {
		editUser: { isOpen: false },
	};

	$scope.loadMoreUsers = () => {
		$scope.isLoading = true;
		return UserService.getUsers({ page: $scope.pageId + 1, limit: LIMIT, query: $scope.query })
			.then(result => {
				$scope.users.push(...mapUsersToCostCenters(result.users));
				$scope.pageId++;
				initializeTable(_table, $scope.users, getColumns());
			})
			.catch(() => {
				ts.ui.Notification.warning('There was a problem loading more users. Please try again.');
			})
			.finally(() => {
				$scope.isLoading = false;
			});
	};

	$scope.onAsideClosed = key => {
		$scope.componentAsides[key].isOpen = false;
	};

	$scope.onUserSaved = ({ user }) => {
		$scope.componentAsides.editUser.isOpen = false;
		const userIndex = _.findIndex($scope.users, item => item.userId === user.userId);
		$scope.users[userIndex] = user;
		initializeTable(_table, $scope.users, getColumns());
	};

	$scope.onUserRemoved = ({ user }) => {
		$scope.componentAsides.editUser.isOpen = false;
		$scope.users = _.reject($scope.users, ['userId', user.userId]);
		initializeTable(_table, $scope.users, getColumns());
		setTableStatusMessage(_table, $scope.users);
	};

	$scope.searchUsers = ({ query }) => {
		$scope.isLoading = true;
		$scope.pageId = START_PAGE;
		$scope.query = query;
		return UserService.getUsers({ query, limit: LIMIT, page: START_PAGE })
			.then(result => {
				$scope.numPages = result.numPages;
				$scope.users = mapUsersToCostCenters(result.users);
				initializeTable(_table, $scope.users, getColumns());
			})
			.catch(() => {
				ts.ui.Notification.warning('There was a problem searching users. Please reload to try again.');
			})
			.finally(() => {
				$scope.isLoading = false;
			});
	};

	(function init() {
		$scope.costCenters = _costCenters;
		$scope.defaultManager = _defaultManager;
		$scope.managers = _approvers;
		$scope.numPages = _usersInfo.numPages;
		$scope.pageId = START_PAGE;
		$scope.query = '';
		$scope.user = _user;
		$scope.users = _.isEmpty($scope.costCenters) ? _usersInfo.users : mapUsersToCostCenters(_usersInfo.users);
		_manageHeader.selectTab({ selectedTab: 'users' });
		FooterService.create({ $scope, buttons: FOOTER_BUTTONS });
		ts.ui.get('#js-manage-users', table => {
			_table = table;
			initializeTable(_table, $scope.users, getColumns());
		});
	})();

	function initializeTable(table, users, columns) {
		table.rows([]);
		table.clickable(function (rowIndex) {
			$scope.$evalAsync(scope => {
				scope.componentAsides.editUser.user = $scope.users[rowIndex];
				scope.componentAsides.editUser.isOpen = true;
			});
		});
		initTableColumns(table, columns);
		addUsersToTable(table, users);
		setTableStatusMessage(table, users);
	}

	function getColumns() {
		return [
			{
				editable: false,
				label: 'Name',
				flex: 0.75,
			},
			{
				editable: false,
				label: 'Email',
			},
			{
				editable: true,
				label: 'Role',
				flex: 0.5,
			},
			{
				editable: true,
				label: 'Manager',
				propertyKey: 'manager',
			},
			{
				label: 'Cost Center',
				propertyKey: constants.userPropertyKeys.costcenter,
				flex: 0.5,
			},
			{
				editable: false,
				label: 'Status',
				flex: 0.5,
			},
		];
	}

	function initTableColumns(table, columns) {
		table
			.cols(
				_.map(columns, function (column) {
					return {
						editable: column.editable,
						label: column.label,
						type: column.type,
						flex: column.flex,
					};
				}),
			)
			.sortable(function (index, ascending) {
				table.sort(index, ascending);
			});
		table.sort(0, true);
	}

	function addUsersToTable(table, users) {
		const rows = table.rows();
		_.map(users, function (user) {
			addUserToTable(rows, user);
		});
	}

	function addUserToTable(rows, user) {
		rows.push([
			user.fullName,
			user.email,
			RoleService.getRoleLabel(user.roleId),
			_.get(user, 'properties.manager', ''),
			_.get(user, 'costCenter.path', ''),
			getStatus(user),
		]);
	}

	function getStatus(user) {
		return constants.userStateToStatus[user.state];
	}

	function mapUsersToCostCenters(users) {
		return _.map(users, function (user) {
			const costCenterId = _.get(user.properties, constants.userPropertyKeys.costcenter);
			user.costCenter = CodingService.findById($scope.costCenters, costCenterId);
			return user;
		});
	}

	function setTableStatusMessage(table, users) {
		users.length === 0 ? table.status('No users found') : table.status(`Showing users 1-${users.length}`);
	}
}

UsersController.resolve = {
	_approvers: /* @ngInject */ PaymentsService => PaymentsService.getApprovers(),
	_costCenters: /* @ngInject */ (constants, CodingService) => {
		return CodingService.getListEntries({ listId: 'costcentercode' });
	},
	_defaultManager: /* @ngInject */ CompanyService => CompanyService.getDefaultManager(),
	_usersInfo: /* @ngInject */ UserService => UserService.getUsers({ page: START_PAGE, limit: LIMIT }),
};

module.exports = UsersController;
