import { get, range } from 'lodash';
import moment from 'moment';

import MONTHS from '../../../config/Months';

const DownloadReportAsideComponent = {
	bindings: {
		isCompanyAdmin: '<',
		isOpen: '<',
		isTeamAdmin: '<',
		onClose: '&',
		defaultToSettlements: '<?',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.downloadReportCSVText}}">
			<ts-form
					is-submitting="$ctrl.isSubmitting"
					on-cancel="$ctrl.onClose()"
					on-submit="$ctrl.onFormSubmit($ctrl.cardSourceOption.id, $ctrl.reportType, $ctrl.month, $ctrl.year)"
					submit-label="{{$ctrl.downloadFieldText}}">
				<ts-form-fields>
					<ts-radio
							label="{{$ctrl.reportTypeText}}"
							is-required="true"
							ng-model="$ctrl.reportType"
							options="$ctrl.formOptions.reportTypes"></ts-radio>
					<ts-select
							is-required="true"
							label="{{$ctrl.parentCardOptionText}}"
							ng-if="$ctrl.reportType === 'settlements'"
							ng-model="$ctrl.cardSourceOption"
							options="$ctrl.formOptions.cardSourceOptions"
							placeholder="Select card"></ts-select>
					<fieldset class="ts-multi">
							<label class="tst-cardsource-expirationmonth">
								<span ng-bind="$ctrl.monthFieldLabelText"></span>
								<select
										ng-model="$ctrl.month"
										ng-options="month as month.name for month in $ctrl.options.months"
										placeholder="{{$ctrl.selectMonthText}}"
										required>
									<option></option>
								</select>
							</label>
							<label class="tst-cardsource-expirationyear">
								<span ng-bind="$ctrl.yearFieldText"></span>
								<select
										ng-model="$ctrl.year"
										ng-options="year for year in $ctrl.options.years"
										placeholder="{{$ctrl.selectYearFieldText}}"
										required>
									<option></option>
								</select>
							</label>
					</fieldset>
				</ts-form-fields>
			</ts-form>
		</ts-aside>
	`,
	controller: class DownloadReportAsideController {
		/* @ngInject */
		constructor($q, constants, downloadReportService, LexicoService, TeamsService) {
			const date = new Date();
			this.DATE_PICKER_FORMAT = constants.datePickerFormat;
			this.maxDateRange = constants.settlements.maxDateRange;
			this.$q = $q;
			this.lexico = LexicoService.getLexico();
			this.downloadReportCSVText = this.lexico.trc('Aside component label', 'Download report CSV');
			this.downloadFieldText = this.lexico.trc('Form field label', 'Download');
			this.reportTypeText = this.lexico.trc('Form field label', 'Report type');
			this.parentCardOptionText = this.lexico.trc('Select option label', 'Parent card for report');
			this.monthFieldLabelText = this.lexico.trc('Field input label', 'Month');
			this.selectMonthText = this.lexico.trc('Placeholder text', 'Select a month');
			this.yearFieldText = this.lexico.trc('Field input label', 'Year');
			this.selectYearFieldText = this.lexico.trc('Placeholder text', 'Select a year');
			this.downloadReportService = downloadReportService;
			this.TeamsService = TeamsService;
			this.createdBefore = moment().format(this.DATE_PICKER_FORMAT);
			this.createdAfter = moment().subtract(1, 'month').format(this.DATE_PICKER_FORMAT);
			this.maxEndDate = this.createdBefore;
			this.currentYear = date.getFullYear();
			this.month = MONTHS[date.getMonth()];
			this.year = this.currentYear;
		}

		$onChanges(changes) {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}
		}

		async onOpen() {
			this.isLoading = true;
			let formOptions;

			this.options = {
				months: MONTHS,
				years: range(2015, this.currentYear + 1),
			};

			try {
				if (this.isCompanyAdmin) {
					formOptions = await this.downloadReportService.getFormOptions();
				} else {
					const { id: teamId } = await this.TeamsService.getCurrentTeam();
					formOptions = await this.downloadReportService.getFormOptions(teamId);
				}

				this.formOptions = formOptions;
				if (this.defaultToSettlements === true) {
					const settlementsReport = formOptions.reportTypes.find(report => report.label === 'Settlements');
					this.reportType = get(settlementsReport, 'value');
				} else {
					const requestsReport = formOptions.reportTypes.find(report => report.label === 'Requests');
					this.reportType = get(requestsReport, 'value');
				}
				const { cardSourceOptions } = formOptions;
				this.cardSourceOption = cardSourceOptions[cardSourceOptions.length - 1];
			} catch (e) {
				const warningMessage = this.lexico.trc('Warning message', 'there was an error getting your card sources');
				ts.ui.Notification.warning(warningMessage);
			} finally {
				this.isLoading = false;
			}
		}

		onFormSubmit(cardSourceId, reportType, month, year) {
			const firstOfMonth = moment({ month: month.value - 1, year });
			const createdAfter = firstOfMonth.format('YYYY-MM-DD');
			const createdBefore = firstOfMonth.endOf('month').add(1, 'days').format('YYYY-MM-DD');
			this.isSubmitting = true;
			return this.downloadReportService
				.downloadReport({ cardSourceId, reportType, createdBefore, createdAfter })
				.finally(() => {
					this.isSubmitting = false;
					this.onClose();
				});
		}
	},
};

export default DownloadReportAsideComponent;
