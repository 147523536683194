"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.conversationApprovalQuery = void 0;
const react_query_1 = require("@tanstack/react-query");
const Root_1 = require("../../router/Root");
const teamQuery_1 = require("../teamQuery");
const userQuery_1 = require("../userQuery");
const approvalQuery_1 = require("./approvalQuery");
const findApprovalById = async (id) => {
    if (!id) {
        return null;
    }
    const user = await Root_1.queryClient.ensureQueryData(userQuery_1.userQuery);
    const teamIdQuery = (0, teamQuery_1.selectedTeamIdQuery)('selectedTeamID');
    const teamId = await Root_1.queryClient.ensureQueryData(teamIdQuery);
    const approvalsQuery = (0, approvalQuery_1.getCombinedApprovalsData)(user, teamId?.[0] || '', false);
    const approvalsData = await Root_1.queryClient.ensureQueryData({
        queryKey: approvalsQuery.queryKey,
        queryFn: approvalsQuery.queryFn,
    });
    for (const group of Object.keys(approvalsData.groupedApprovals)) {
        const found = approvalsData?.groupedApprovals?.[group].approvals?.find(approval => approval.id === id);
        if (found) {
            return found;
        }
    }
    const dashboardFound = approvalsData?.approvalsDashBoardCollection?.find(approval => approval.id === id);
    if (dashboardFound) {
        return dashboardFound;
    }
    const approvalInOtherTeamFound = approvalsData?.assignedApprovals?.find(approval => approval.id === id && approval.assignees.includes(user?.userId));
    if (approvalInOtherTeamFound) {
        return approvalInOtherTeamFound;
    }
    return null;
};
const conversationApprovalQuery = (approvalId) => {
    return (0, react_query_1.queryOptions)({
        queryKey: ['approval', approvalId],
        queryFn: () => findApprovalById(approvalId),
        enabled: Boolean(approvalId),
    });
};
exports.conversationApprovalQuery = conversationApprovalQuery;
