const _ = require('lodash');

function LoadingService() {
	const service = {};
	let isLoading = false;

	const setLoadingAttribute = value => {
		const elements = document.querySelectorAll('[data-ts="App"]');
		if (!_.isEmpty(elements)) {
			elements[0].setAttribute('data-ts.blocking', value);
		}
		if (value === 'false') {
			const cover = document.querySelectorAll('[data-ts-spirit="ts.ui.CoverSpirit"]');
			if (!_.isEmpty(cover)) {
				cover[0].remove();
			}
		}
	};

	service.startLoading = () => {
		isLoading = true;
		setLoadingAttribute('Loading...');
	};

	service.stopLoading = () => {
		isLoading = false;
		setLoadingAttribute('false');
	};

	service.isLoading = () => isLoading;
	window.$state.loadingService = service; // so we know on react when we show the angular loading

	return service;
}

module.exports = LoadingService;
