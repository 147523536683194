const _ = require('lodash');

/* @ngInject */
function eventRequestSubmitted(Request, RequestRegistry) {
	return {
		restrict: 'E',
		template: require('./requestSubmitted.html'),
		link: function ($scope) {
			const request = RequestRegistry.getRequest($scope.event.requestId);
			$scope.request = !_.isEmpty(request) ? request : new Request($scope.event.data);
			// not sure why we need to update here, but copied from PurchaseRequestController
			// - the RequestRegistry is populated by requisitionInfos rather than requsitions
			// the event data contains the requisition, and it has a necessary and missing
			// piece of data: profileId.
			$scope.request.update($scope.event.data);
		},
	};
}

module.exports = eventRequestSubmitted;
