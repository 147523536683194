import _ from 'lodash';

const approvalLimitValidator = require('../../../../../common/validators/approvalLimitValidator');

const TeamInfoModalView = {
	bindings: {
		features: '<',
		isCurrentStep: '<',
		isModalOpen: '<',
		onChangeButtons: '&',
		onCloseModal: '&',
		onNextStep: '&',
		onTeamAdded: '&',
		teams: '<',
	},
	template: `<div ng-if="$ctrl.featureDisabled" data-ts="Note" class="purple-note">
		<i class="ts-icon-warning"></i>
		<p ng-bind="$ctrl.warningMessage"></p>
	</div>
	<form data-ts="Form">
		<h3
				class="tst-basic-settings"
				ng-bind="$ctrl.basicSettingsText"></h3>
		<section>
			<ts-input
					class="tst-team-name"
					change="$ctrl.updateModalButtons()"
					label="{{$ctrl.teamNameText}}"
					placeholder="{{$ctrl.placeHolderText}}"
					ng-model="$ctrl.teamName"
					validate="$ctrl.validateUniqueTeamName(value)"></ts-input>
		</section>
		<hr />
		<h3 ng-bind="$ctrl.virtualCardText"></h3>
		<section class="card-options">
			<div class="card-types">
				<ts-input
						is-disabled="feature.isLocked"
						change="$ctrl.updateModalButtons()"
						label="{{feature.label}}"
						ng-model="feature.isEnabled"
						ng-repeat="feature in $ctrl.cardFeatures"
						type="checkbox"></ts-input>
			</div>
			<div class="limit">
				<ts-input
						info-messages="$ctrl.approvalLimitInfo"
						label="{{$ctrl.defaultLimitText}}"
						currency="{{$ctrl.currency}}"
						ng-model="$ctrl.requestLimit"
						single-error="true"
						is-required="false"
						validate="$ctrl.validateApprovalLimit(value)"></ts-input>
			</div>
		</section>
		<hr />
		<h3 ng-bind="$ctrl.sourceCardText"></h3>
		<section
				class="create-team-sourcecard"
				ng-class="{'has-cardsources': $ctrl.cardSourceOptions.length > 0}">
			<p
					ng-bind="$ctrl.enrollSourceCardText"
					ng-if="$ctrl.cardSourceOptions.length === 0"></p>
			<div ng-if="$ctrl.cardSourceOptions.length > 0">
				<ts-select
						class="tst-select-card"
						label="{{$ctrl.selectCardText}}"
						ng-change="$ctrl.updateModalButtons()"
						ng-model="$ctrl.cardSourceOption"
						options="$ctrl.cardSourceOptions"
						placeholder="Select card"></ts-select>
			</div>
			<button
					class="tst-enroll-source-card"
					data-ts="Button"
					ng-bind="$ctrl.enrollCardButton"
					ng-class="{
						'ts-secondary ts-micro': $ctrl.cardSourceOptions.length > 0,
						'ts-primary': $ctrl.cardSourceOptions.length === 0
					}"
					ng-click="$ctrl.openAddCardSourceAside()"
					ng-disabled="!$ctrl.teamName">
			</button>
		</section>
	</form>
	<go-add-card-source-aside
			is-open="$ctrl.asides.addCardSource.isOpen"
			on-card-source-added="$ctrl.onCardSourceAdded($event)"
			on-close="$ctrl.onAsideClosed('addCardSource')"></go-add-card-source-aside>`,

	controller: /* @ngInject */ function (
		FeatureService,
		TeamsService,
		PaymentsService,
		LexicoService,
		LoadingService,
		constants,
		teamInfoModalViewHelper,
		CompanyService,
	) {
		this.lexico = LexicoService.getLexico();
		this.basicSettingsText = this.lexico.trc('Component title', 'Basic settings');
		this.teamNameText = this.lexico.trc('Team name input label', 'Team name');
		this.placeHolderText = this.lexico.trc('Team name input placeholder', 'Enter a unique team name');
		this.virtualCardText = this.lexico.trc('Form fieldset label', 'Virtual card options');
		this.sourceCardText = this.lexico.trc('Form fieldset label', 'Source cards');
		this.enrollSourceCardText = this.lexico.trc('Empty corporate card list', 'Enroll your first corporate card to GO.');
		this.selectCardText = this.lexico.trc('Form input label', 'Select card');
		this.enrollCardButton = this.lexico.trc('button', 'Enroll source card');
		this.defaultLimitText = this.lexico.tr('Team approval limit');
		this.invalidNumberText = this.lexico.tr('Please enter a positive integer or leave empty.');
		this.exceedsCompanyLimitText = this.lexico.tr('This amount exceeds the company approval limit.');
		this.warningMessage = this.lexico.trc('Warning message', 'At least one card option should be enabled.');
		this.featureDisabled = false;
		this.changeCardOptions = false;

		this.requestLimitValidationText = maxRequestLimit =>
			`${this.lexico.tr('Should be less than or equal to')} ${maxRequestLimit}`;

		const helper = teamInfoModalViewHelper;
		const REQUEST_LIMIT_PROPERTY = constants.teamProperties.requestLimit;
		const CARD_FEATURES = ['ENABLE_SINGLEUSE', 'ENABLE_MULTIUSE', 'ENABLE_SUBSCRIPTIONS'];
		const LOCKED_CARD_FEATURES = [''];

		const saveAndContinue = async () => {
			if (!this.teamId) {
				this.teamId = await helper.createTeam({
					onTeamAdded: this.onTeamAdded,
					teamName: this.teamName,
				});
			}

			const promises = [
				helper.addCardSourceToTeam({
					cardSourceId: this.cardSourceOption.id,
					teamId: this.teamId,
				}),
				Promise.all(
					this.cardFeatures.map(feature =>
						TeamsService.setTeamProperty(this.teamId, feature.name, !!feature.isEnabled),
					),
				),
				TeamsService.setTeamProperty(this.teamId, REQUEST_LIMIT_PROPERTY, this.requestLimit),
			];
			try {
				await Promise.all(promises);
				this.onNextStep();
			} catch (error) {
				const errorMessage = this.lexico.trc('Error message', 'Something went wrong. Please try again.');
				ts.ui.Notification.error(errorMessage);
			}
		};

		const onModalClosed = () => {
			this.asides = {
				addCardSource: {
					isOpen: false,
				},
			};
			this.cardSourceOption = null;
			this.requestLimit = '';
			this.teamId = '';
			this.teamName = '';
		};

		const onModalOpen = async () => {
			try {
				const features = await FeatureService.getFeatures();
				const mappedFeatures = _.map(features, (isEnabled, name) => ({
					isEnabled,
					name,
					label: constants.features[name],
				}));
				const filteredFeatures = mappedFeatures.filter(feature => CARD_FEATURES.indexOf(feature.name) !== -1);

				this.cardFeatures = filteredFeatures.map(feature => {
					const cardFeature = _.assign(feature, { isEnabled: true });
					if (LOCKED_CARD_FEATURES.includes(cardFeature.name)) {
						cardFeature.isLocked = true;
					}
					return cardFeature;
				});

				this.cardSourceOptions = await PaymentsService.getCardSources();
				if (this.cardSourceOptions.length > 0) {
					this.cardSourceOption = this.cardSourceOptions[0];
				}
				this.requestLimit = '';
				this.currency = _.get(this.cardSourceOption, 'billingCurrency', 'N/A');

				this.companyApprovalLimit = await CompanyService.getRequestLimit();
				this.approvalLimitInfo = [];
				if (this.companyApprovalLimit) {
					this.approvalLimitInfo.push(this.requestLimitValidationText(this.companyApprovalLimit));
				}

				this.updateModalButtons();
			} catch (error) {
				const errorMessage = this.lexico.trc('Error message', 'There was an error please try again.');
				ts.ui.Notification.error(errorMessage);
				this.onCloseModal();
			} finally {
				LoadingService.stopLoading();
			}
		};

		const onStepSelected = () => {
			this.updateModalButtons();
		};

		this.providerApiMap = { label: 'AMEX' };

		this.$onChanges = async changes => {
			if (changes.isModalOpen) {
				if (changes.isModalOpen.currentValue) {
					await onModalOpen();
				} else {
					onModalClosed();
				}
			}
			if (changes.isCurrentStep && changes.isCurrentStep.currentValue) {
				onStepSelected();
			}
		};

		this.onAsideClosed = key => {
			this.asides[key].isOpen = false;
		};

		// TODO: should be the same as what GET cardsources returns?
		this.onCardSourceAdded = ({ cardSource }) => {
			this.cardSourceOptions = [...this.cardSourceOptions, _.cloneDeep(cardSource)];
			this.cardSourceOption = _.find(this.cardSourceOptions, { id: cardSource.id });
			this.updateModalButtons();
		};

		this.updateModalButtons = () => {
			const isInvalidTeamName = _.isEmpty(this.teamName);
			const isInvalidApprovalLimit = !this.isValidApprovalLimit;
			const cardSourceNotAdded = _.isEmpty(this.cardSourceOption);
			if (this.cardFeatures && this.cardFeatures.length) {
				this.changeCardOptions = true;
				for (const obj of this.cardFeatures) {
					this.featureDisabled = !obj.isEnabled;
					if (obj.isEnabled) {
						break;
					}
				}
			}
			const disabled = this.featureDisabled || isInvalidTeamName || isInvalidApprovalLimit || cardSourceNotAdded;
			const onChangeButtons = this.onChangeButtons;
			helper.updateSaveAndContinueButton({ disabled, onChangeButtons, saveAndContinue });
		};

		this.openAddCardSourceAside = async () => {
			this.asides.addCardSource.isOpen = true;
		};

		this.validateUniqueTeamName = teamName => {
			if (!teamName) {
				return;
			}
			const valid = !_.includes(
				this.teams.map(team => team.name.toLowerCase()),
				teamName.toLowerCase(),
			);
			const errorMessage = valid ? '' : this.lexico.trc('Error message', 'team with this name already exists.');
			return { valid, errorMessage };
		};

		this.validateApprovalLimit = limit => {
			const error = approvalLimitValidator.validateApprovalLimit(limit, this.companyApprovalLimit);
			this.isValidApprovalLimit = !error;

			let errorMessage = '';
			if (error === approvalLimitValidator.errors.INVALID_FORMAT) {
				errorMessage = this.invalidNumberText;
			} else if (error === approvalLimitValidator.errors.EXCEEDED_AMOUNT) {
				errorMessage = this.exceedsCompanyLimitText;
			}
			this.updateModalButtons();
			return { valid: this.isValidApprovalLimit, errorMessage };
		};
	},
};

export default TeamInfoModalView;
