/* @ngInject */
const ManageController = ($scope, HeaderService, _manageHeader) => {
	$scope.$on('$destroy', () => {
		HeaderService.reset();
	});
	_manageHeader.create();
};

ManageController.resolve = {
	_manageHeader: /* @ngInject */ ($transition$, ManageHeaderHelper) => {
		return ManageHeaderHelper.getManageHeader($transition$.$to().name);
	},
	_user: /* @ngInject */ UserService => UserService.getUser(),
	/* Allow only company_admin and tradeshift_support_admin access to /manage/..
	others will be re-directed to error page */
	_hasAccess: /* @ngInject */ (_user, $state, UserServiceV2) => {
		return (async () => {
			const isSupportAdmin = UserServiceV2.isSupportAdmin(_user);
			if (!isSupportAdmin) {
				$state.go('main.error');
			}
			/*
				GO-2311 VULN-3260 Dropped the TS Admin access to support pages because
				of a vulnerability, the request to backend to check if the user is
				TS Admin can be intercepted and faked to return true (has access) and
				thus a regular user can have support features by accessing these pages.
				Existing support users still have access to these pages. Also the
				TS Admin login was disabled/dropped some time ago also by a vulnerability
				ticket GO-1922 VULN-2110, so this does not affect TS Admin users because
				they can no longer login.
			const isTsAdmin = await UserServiceV2.isTSAdmin();
			if (!isSupportAdmin && !isTsAdmin) {
				$state.go('main.error');
			}
			*/
		})();
	},
};

module.exports = ManageController;
