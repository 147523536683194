module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# de transações",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minuto",
"ce09c436b49af331a32f1152ede7dd18":"1. Pedir fundos",
"d2226752d3065fbad4744924b4a0ee73":"2. Rever e aprovar",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Rever e aprovar",
"82bab92f1c8030888550b51521266c53":"3. Pagar com cartão virtual",
"2ecd62e02e507f8e839a55eac0a04886":"Já existe uma entrada de codificação com a ID '{entryId}'.",
"5dfd25849740fbce8147838b0d5094eb":"Uma alcunha simpática, por exemplo Cartão de Marketing",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Um membro da nossa equipa de êxito do cliente irá contactá-lo em breve.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Uma pessoa que não procuramos",
"976b50e70e4433e2786233979d243445":"Já existe uma equipa com este nome.",
"35b61d21ac11d5fc31ebe58541263445":"Aceitar o convite para aderir",
"a372cb2d4204654f52cd9170fb10d374":"Códigos Contabilísticos",
"110fc66010b6b91cebee7a59a2067aab":"Códigos contabilísticos",
"db862bb04dbdc6d04e47063f33be8865":"Os códigos contabilísticos são geridos através de listas de códigos e entradas em listas.",
"d6e3848e824f077396be4351eb58edd2":"Ação necessária - aprovar/rejeitar novo pedido por {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Ações",
"476e728ea824229f52438e543818bcdd":"Ações",
"3117746cb7d4ab815ec98274ec4398d5":"Ações",
"3ab09b66a7f16219d8135977a2a76d10":"Ações",
"ffafa66eaab0409a2e90aaeb43796def":"Ativa",
"0447e0aa5d00dbdab11b5546793c4479":"Ativa",
"8ee51d21b05466f7d962d4ff5a0b384e":"Cartões Ativos",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Cartões Virtuais Ativos",
"89c6ec05861aa914304ba35bc6744124":"Cartões ativos",
"5280659bcecaf8344c57790f9962a9a2":"Atividade",
"8a15368ae286fa64e1cb7624cd410fc6":"Adicionar Lista de Codificação",
"8ded7e09e86be231b1165308329ac76d":"Adicionar Lista",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Adicionar administrador",
"695bb4bc29e2df769ddf7427ab71012a":"Adicionar aprovador",
"4a1d4516cab00b7c5ecdb150e346e5db":"Adicionar aprovador",
"56869851b27c2915f5a7fe6a33b91ac7":"Adicionar lista de codificação",
"8f8691057c3994aa61f9994202680c87":"Adicionar lista de codificação",
"69f7a406b79d19bf8082ed245ab29123":"Adicionar lista de codificação",
"48a92ca1cc63018707be99b13196369f":"Adicionar administrador da empresa",
"a679db9ff80d0e5769ae1ba311c7916f":"Adicionar domínio",
"8f5e8078be77146ba7f3be49ef948906":"Adicionar entrada",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Adicionar mensagem",
"f8a4b14162e19995074e63135b65c84d":"Adicionar cartão novo",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Adicionar pessoas",
"c670549d0b898bb6a571eb2cd7804a6e":"Adicionar recibo",
"eb937098e77d8a6b0565ed72357dcaae":"Adicionar recibo",
"1e154f31c31b63a459f3956e380b3ef9":"Adicionar selecionado",
"ba24db9956f156a18b32e653552ff682":"Adicionar algumas pessoas a esta equipa",
"52bd93fb5489b25167bb541f7c6504ae":"Adicionar cartão de origem",
"21ab3f08eb507dbc76df166eafeb393a":"Adicionado {fullName} como um aprovador!",
"f879c06507d3076bbf52ea4fd2df850e":"Detalhes de cartão adicionais",
"21e934ae30eece4a894337701f497638":"Todas",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Todos os Cartões",
"8fac71195e2b506e029752e4864b6a5d":"Todos os Membros",
"f9708417df16227742e0545a8642eea7":"Todos os utilizadores disponíveis já foram adicionados a este cartão.",
"cb5abf070f8cba7982205038baab77bb":"Todos os cartões",
"dae7d602eec2c84dea0d80ae25e56e6d":"Todos os cartões",
"0b4bdfde6578668b6018d3615a1ad30f":"Todos os centros de custo",
"97b875b81f54b4432d998a3e47d949cc":"Todas as equipas",
"f0ae83969f7c20a1964caf5156dae966":"Todas as equipas",
"d87455601a00282078582b36a3825b7d":"Todas as equipas",
"24c7b7f55882ea94a525392ca8218fa0":"Todas as equipas",
"8f860e35b64a8f447bded1e5ea66a2a8":"Todos os cartões virtuais",
"e403956ecaa6fb33ec1fef5c49d71042":"Todos os cartões virtuais",
"4e6f4e8f25f61d40e1eba77de94f6458":"Todas as suas informações financeiras estão encriptadas e em servidores em locais seguros.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Valor",
"a4d099d0dbe3889b0995546e6ed28932":"Valor",
"6c0ef32aae76bc31a61e2124f542bdf1":"Valor",
"adec89c93525f1a8d1c64d856f405e27":"Montante: alto para baixo",
"18e632a170203894f40deed1d33ee552":"Montante: baixo para alto",
"a7944575cafef9126a499a1b06999fe3":"Ocorreu um erro, por favor tente novamente mais tarde.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Aplicar",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Opções de Aprovação",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Definições de Aprovação",
"492eedd88cdd7bb45f39c6594af748c4":"Limite de aprovação",
"437d490afc8d226f8c7d544f4e35d011":"Limite de aprovação ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Aprovações",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Aprovações",
"17adb7fe866e32055b1469fdb7e82795":"Definições de aprovações",
"7125aa13db437084e1d27d3ff38f22e2":"Aprovar",
"84df787ff5746f9dc3cf0146becef4fd":"Aprovar Pedido",
"7a568654d00245b0bab6c90a1b67d196":"Aprovado",
"179c3e187ad8edeb8348cff222b938bf":"Aprovado",
"ad058d17725870df9abbd4f5ec217636":"Aprovado ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Montante Aprovado",
"4d58ba5c0e03fec1556c243be106e4a3":"Aprovado por",
"7d3d697fc99f6c4641b326623743f7b7":"Aprovado { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Aprovador",
"8c45e443fc6748190719d79a092e2528":"Os aprovadores têm um limite de aprovação máximo",
"4fe56aa6456c108cd288a4ec8acf435f":"Arquivar",
"a9b6f93de9630064be9809130aa1e195":"Está pronto para assumir o controlo nos gastos do seu cartão?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Tem a certeza de que pretende cancelar esta solicitação?",
"301bfc2ce058f49e0704fec3d162b5b5":"Tem a certeza de que pretende voltar a atribuir esta solicitação de volta para si?",
"2181faf8d9973d1f4138e401a762086e":"Tem a certeza que pretende remover ${firstName} ${lastName} de ser um administrador de empresa?",
"b982277f29055d0ef6e295fe2006d9e0":"Tem a certeza de que pretende remover {firstName} da equipa?",
"456c0ccff14acb808583cd9dece857b6":"Atribuído a",
"bde315b9cf7fd55f4b65aabbbf38d462":"Pelo menos um gestor ou um responsável pela equipa deve estar disponível para usar fora do escritório.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Autenticação",
"1961ec69dd0c8b5718c831312e7f3d0f":"Cobrança de receitas automatizada",
"e39f2477cf34d726936a5b5c2b48a4ad":"Crie automaticamente relatórios de reconciliação precisos, que incluem informações sobre compradores, aprovadores, recibos, códigos de conta e muito mais.",
"434ca07dfde0eaee14f094b7c75a6eae":"Disponível",
"eb85d8f50f2c89871b794f7d54279b22":"Balanço Disponível",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Disponível { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Anterior",
"c8258f8cf9e20f6bbb89d957de40e820":"Segurança de nível bancário",
"d46c22a3f36dce568c07fcea4368b231":"Definições básicas",
"fb481c8e517016094b4fdf618c5fa088":"Endereço de faturação",
"79a9f821923c5a04c076902fef684281":"Endereço de faturação",
"877a08b8347052c83aa0b689baea81e7":"Endereço de faturação",
"97f018458e2627fac37d24846c7b8922":"Ao criar a sua conta e usar o Tradeshift Go, você concorda com a nossa",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Ao criar a sua conta e usar o Tradeshift Go, você concorda com a nossa",
"912d1864f0068c764bf4784020795d49":"NOME DO TITULAR DO CARTÃO",
"d53474c7fd99fbeb69481ce9d358aa09":"Pode Pedir cartões e ter uma carteira para todos os cartões. Cada membro obtém um aprovador predefinido (gestor) para os seus pedidos.",
"f30df2cab105ed3f8b4264aeb9800abd":"Pode aprovar pedidos e criar cartões para os membros que eles gerem. Os gestores têm um painel para seguir os gastos dos membros que gerem.",
"03ed9eccc71ef59165292eac542d3050":"Pode visualizar todos os pedidos e a atividade de gastos na equipa. Os responsáveis pela equipa também recebem um painel da equipa para seguir gastos e a carteira da equipa, onde podem ver todos os cartões ativos.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Impossível remover este utilizador. Precisa de pelo menos um proprietário da equipa ou gestor numa equipa.",
"a0b70642629a55ea0ab6fa3414d342e0":"Impossível remover utilizador com pedidos pendentes.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Cancelar",
"5446624d3c364ac05ddf8d6663da2796":"Cancelar Cartão",
"53f3815c4fa3340b29efd81e627d1e6b":"Cancelar Cartão",
"12257ea2a871b7fab72245b5aafe6424":"Cancelar pedido",
"2aafb7eb4d56a516c4bb0754f0812df1":"Cancelar cartão",
"caae12601b01e2325161e95107f9e332":"Cancelar cartão",
"6ad1e57b1e4c7accbe166c948883077d":"Cancelar com um clique se comprometido",
"f6527e49e6d5c989624507a583e23f39":"Cancelado ({count})",
"1ad3bf5121641dda511f42942af05a90":"Cancelar este cartão não irá reverter quaisquer custos pendentes. Bloqueie a liquidação",
"aebd3826a195afad0a93050a7cc5d73d":"Cancelar este cartão não irá reverter quaisquer custos pendentes. Procure os registos de liquidação relativos aos custos no extrato do cartão de crédito enviados ao seu fornecedor do cartão.",
"a5b5136947da562f91b75daf8156f50e":"Detalhes do Cartão",
"9ed31ad23a1578ce480d31207decf1d3":"Definições do Cartão",
"def15f2e007fc69886230fdb2511305c":"Detalhes do cartão",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Detalhes do cartão",
"67cfd260078ca32027c2fa4a4a2d29f4":"Titular do cartão",
"4458819ea60c68c46e551a8f5cc163cd":"Nome do cartão",
"0858bcbd506fa3240b165e59d2f18aae":"Nome do cartão",
"5212736dd18ee2c31f3650ad2c2c7130":"Nome do cartão atualizado.",
"c96d62fa510c3356dc73a91d1a80e262":"Número do cartão",
"41307fee4c32aefb5383a98fe3d6e45e":"Tipo de cartão",
"3f8045c3358621dad4346a44de0c05a8":"Nome do titular do cartão",
"879a6b452033cf5f3a4bb3e1837a0386":"Cidade",
"593334553ef852024e172505ec3c5f21":"Clique Aqui",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Codificação",
"df931751d5790d74fa46707aaf7a26ae":"Codificação 1",
"7ac7154bc776aff52a71b0d83b860561":"Codificação 2",
"586fcf19136ae5e26795e9055b2e2995":"Codificação 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Codificação 4",
"379bb8e71bb090badebbb0751de86f11":"Codificação 5",
"3e84d13627b3d090ab2b8470af98340a":"Codificação 6",
"33b94b581f3792049992663396e51cb8":"Codificação 7",
"b73fbc32e439655833ee99126dd0e55f":"Codificação 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Lista de códigos",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Entrada de codificação",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Entrada de codificação eliminada.",
"cada62a39ee72d790dcdbb25160f8783":"Entrada de codificação guardada.",
"f645bf50888943ee1384693e56a0f475":"Nome da lista de códigos",
"a999182acbcd77c15299f79181b630be":"Lista de codificação guardada.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Listas de códigos",
"d0b96596ce6baec5aabb8a0690ab30e1":"Comentários",
"ff9cb8842f832d21fb160b3d3784ce79":"Empresa",
"715b59a64e686eb91a21114644ffe39e":"Admin. Empresa foi removido",
"8758e9a01253c097a42393fda9610715":"Dashboard da Empresa",
"e118b41009918b326db6746856831cbc":"Id da empresa",
"4dba88b05b3fa8875128e19e88c512b3":"Definições da Empresa",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Definições da Empresa",
"f2ea6d33de8182d97bf2f13354af4893":"Definições da Empresa: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Administradores da empresa",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Limite de aprovação da empresa",
"7e2f778da14a726b53e05926a3483f2b":"Cartão de crédito da empresa",
"7313fd1db3d63858586d1c0f89251d6e":"Nome da empresa",
"07721e91dd10ff8831f0d24e47cc1eab":"Nome da empresa",
"203221610ca4d347063c037e1a732ae8":"Definições da empresa guardadas.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Ação de confirmação",
"d694cec09cdbc02b1ffe22642a63faa1":"Confirmar palavra-passe",
"512801312a133f29974f2259a455f39b":"Confirmar palavra-passe",
"f54c38ed0b9869d929ffc0bd5310131f":"Confirmação - A sua conta foi criada em Equipa {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Entre em contacto com o administrador da empresa para criar códigos de contabilidade.",
"7a6a4de077398e8e138aec36e3b3d305":"Continuar com SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Todos os direitos reservados.",
"51f0d8e04f6ee626b866b37833dc2b61":"Centro de custo",
"e043f60a02f157d0f31a42f0db67cc87":"Impossível convidar {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Impossível enviar a sua mensagem, tente mais tarde.",
"ae28083f22a0b2fc5637614bce0976e0":"Criar Lista Personalizada",
"738078dc992208e05406f935995dc0c3":"Criar Lista Personalizada",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Criar Nova Lista",
"0f5783c3921b3d7398d281644bbe5bde":"Criar um pedido",
"6d62f934f2304701f88bdcfbc688de7e":"Criar conta",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Criar uma conta",
"f6b7d0d8531ea268f988005dd2147b2b":"Criar cartão",
"1a083675d1453448e4645403bbe52dd9":"Criar lista personalizada",
"c10ab11bdfb001da74850ed89c45482f":"Criar novo pedido",
"43a9c06398408bfe77d06ffc2c1c3b87":"Criar novo pedido",
"68bf17fd3f54a89c0a84224e2f9a971c":"Criar uma nova equipa",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Criar equipa",
"3b8e665101b9b3657f8a68c5305947b7":"Criado",
"40e91f2907181ffa8e62567b3a407e60":"Criado por",
"8929f7aacb969ef6353aba9903eb362d":"Cartão de crédito adicionado.",
"d86688c26e8ac5eb9facc971b6fe5155":"Moeda",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Moeda",
"500ded53f64e737a5e4337675ac9b7ce":"Nome da Lista Personalizada",
"b79a95f04fd829fb2e8d0313e522a94d":"Diariamente",
"fb7add662cf7042355319a975fab6ae3":"Encriptação de Dados",
"6bded249dbfafd14490eea8dd37e1f51":"Data",
"46e908a57cb09ef947af8c4a93a09716":"Data",
"e4213104a55eceb60e00408699438dcd":"Desativar equipa",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Desativar uma equipa cancelará todos os cartões virtuais ativos e nenhum dos membros, gestores, ou responsáveis pela equipa poderá aceder à equipa. Apenas você ou outro administrador da empresa pode reativar uma equipa. Tem a certeza?",
"0d0d5677bee2b99884802638e507160a":"Recusar",
"b5e3483aae9d36ce1440fe02904d6da1":"Recusar Pedido",
"00f3db97287cb17ee9c2a131f2141a65":"Rejeitado",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Rejeitado ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Aprovador Predefinido",
"b77589ac3ac2317cfc96d7013658d5c9":"Eliminar ID de codificação: { entryId }, Entrada: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Extratos e relatórios detalhados",
"5234dfc4220ccd18a8b62fe84e372248":"Detalhes",
"37929896817a6744718004374bb42fd8":"Detalhes",
"dd0a6194e8a6ae14f3e1379a1640de15":"Pretendeu remover {fullName} ({email}) da empresa?",
"1a98048b34090da8b199135ccb4c1186":"Número distinto e CVC ocultam detalhes reais do cartão",
"3cf132b60fe16b816ca227bbcad60537":"Não cancelar",
"dee57797e14ccf852aa2bc545bafdf6d":"Descarregar",
"375ae8224d7d35393974775cebe8605a":"Transferir Lista de Utilizador",
"54b3bfd45a293b441d441aab1a50e644":"Descarregar relatório",
"60cf3b94e5df14a504825068928fc118":"Transferir relatório CSV",
"7341a4c73c0b688484304c37da4a6d8e":"DATA DE EXPIRAÇÃO",
"f628f8ad44fbd6c70c99fc778407b666":"Pesquise facilmente transações do histórico e explore as tendências nos gastos dos funcionários a qualquer período.",
"94bb409d034ea20697ae09d812e55365":"Editar Lista Personalizada",
"2974e704676cb79666bc236d8658fa97":"Editar perfil",
"9ff205f99ed4002d049f855f965ca6d8":"Editar utilizador",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-mail",
"0534c00af758d42ebd8b0bd772eab074":"E-mail",
"0c9aa1fbdf925065d25161eab5e51d95":"E-mail",
"ea78fac68766a48b1b0f41305b7e9898":"E-mail",
"dc6a8f782e00c1bf05c48f3a8758e26c":"O funcionário pede fundos do cartão da empresa para fazer a compra. Os fundos pedidos podem ter limitações de gastos definidas.",
"829cff16ce0b13810c914c161b2a9fba":"Ativar obter funções de utilizador alternativas",
"741387c7bd71e2b4a9601af9a6b9490e":"Ativar encaminhamento automático de pedidos de aprovação",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Ativar data de validade predefinida para o cartão virtual",
"bb319710dad93e78cc6f58c615bdf210":"Ativar lista de permissões de domínio de e-mail para utilizadores convidados",
"4700ced24cd278c3149ffa983006035c":"Ativar filtragem por centro de custos",
"3fe06ef8f551c4cb4c0375362e25521d":"Ativar opção de globalização no utilizador de lado",
"2787d4bc9752272ac36b499ce7ad93ab":"Ativar cartões virtuais de múltiplas utilizações",
"6ee44e2280c9ce5009568846cbc3dd14":"Ativar fora do escritório",
"1941ca87053df0cebc6091ede92c18ea":"Ativar visualização de pessoas para utilizadores administradores da empresa",
"e50282c2ec7afb948d2c0b428db5d5ff":"Ativar carregar recibo para transações",
"268694cb2172918fe60f835919bf20ec":"Ativar carregar recibo no quadro de liquidações",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Ativar autoaprovação",
"d17c7acf598b569cce8a2bb97e0bb215":"Ativar cartões virtuais de uma utilização",
"cd731ca218c9e14caabefafe003cfa6d":"Ativar subscrição de cartões virtuais",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Terminar encaminhamento em",
"c1f665a814894b17d603b7052b631d90":"Registar um cartão novo",
"6b6cea93e07e596be70f7b76801e1516":"Inscrever novo cartão",
"7d6ac1ac9cba1620b61908842ebb106b":"Registar cartão de origem",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Registar cartão de origem",
"21a642b339c3eabf2789c0b05dcf828b":"Registe o seu primeiro cartão da empresa no GO.",
"6efa8dca4fba23a190838746879fd704":"Introduzir uma id numérica",
"02c3457577da9dc8dba6e6f9380a248e":"Inserir um nome de equipa exclusivo",
"40feb3fd1fa529cb599aeac9607664a4":"Introduzir nova entrada",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Exemplo: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Mês de Expiração",
"b8bc74aeeb2de3492891c97361de107d":"Ano de Expiração",
"6a0b8d521500014d99dd192d8ce8403d":"Data de expiração",
"8cf274169a732340430fe7cc5236e0e7":"O tamanho do ficheiro deve ser inferior a {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Nome",
"7458b55e967e51791d98e77458631e44":"Nome",
"3bbe52e71bc5c212cea77983f1a5b748":"Nome",
"9b49c88fa20cc006baaac15c857c8781":"Para uma compra ou múltiplas compras",
"bb7a2907073bd760f4e82097132637b1":"Esqueceu-se da Palavra-passe",
"26bdfaa592f3629273f13c22435b323d":"Esqueceu a sua senha?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Reencaminhar",
"3f9fe6adc14ce5776097170653a24462":"Reencaminhar Pedido",
"b774e35683f5737c36bf0cf3d6785973":"Reencaminhar pedido",
"628faa5054a912b1e470414f77057e6b":"Reencaminhar pedido",
"852b4065e1837fdc77940b6411a6d902":"Proteção Contra Fraude",
"616551bd10d95aca439401d6a7769d96":"Montante do frete",
"a65a230918e9c11e1ef8824745e26a3d":"Começar",
"7d4c4466b7716347ce3673acaa1983f7":"Obter mais de Go",
"11415a07658cfef80eb166896d77ad98":"Prepare-se para uma ainda melhor perceção de gastos. A Tradeshift Go irá também agora alinhar melhor com a forma como as equipas de negócios gostam de trabalhar.",
"ac0ff5d14286ef74614a9c6021f08f69":"Obtenha uma monitorização de gastos e rastreio de orçamento, perceção de ganhos e aprofunde em todas as atividades de compras.",
"db9071ab833054307252a936e7f60e1b":"Começar",
"24dc4bb00841b004031fca3a9bb9bf51":"Pedido de cartão Go cancelado por {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"O Go lembra os funcionários de todos os recibos em falta, que podem ser anexados simplesmente respondendo à notificação ou, no caso de um recibo por e-mail, encaminhando-o para o Go.",
"a73603f0bc610ecea9ef9c793b802538":"O Go trabalha com a sua rede de cartões de crédito para garantir os mesmos padrões de prevenção contra fraude que esperaria ao trabalhar com as instituições financeiras de maior elite.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Boas notícias! O seu pedido de compra foi aprovado em Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Tem dúvidas ou comentários? Contacte-nos em",
"9df1d3de68d663b848101df3c7b58e39":"Como funciona",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Tenho a certeza",
"84074732eb8d1b4094404cd750baf949":"Se não houver nenhum valor, aplica-se o limite da empresa",
"138e8bd6102a7eea70a123ff03b1cd79":"Se não houver nenhum valor, aplica-se o limite da equipa",
"e5756af4d7e5ad1236ccfbb858428421":"Se não se registou em Tradeshift Go com este endereço de e-mail",
"4f004f75f47dbfecdb6d09990596e530":"Se o seu e-mail ainda não estiver registado, weâ€™ll irá enviar-lhe um e-mail de ativação com detalhes sobre como proceder.",
"123837f267de602fb05361b3b1197c1e":"Se {username} coincidir com uma conta existente, weâ€™ll irá enviar-lhe um e-mail com detalhes sobre como proceder. Se após alguns minutos continuar a não ver o e-mail na sua caixa de entrada, verifique a sua pasta de spam.",
"45efd96cd93525a9c17222217a37d324":"Formatos de ficheiro de imagem ou PDF suportados",
"fa6ae823f9ab8bf91c246efe2517956c":"Importe a configuração da sua conta do QuickBooks, NetSuite ou Xero diretamente para o Go. Exporte as últimas transações para a sua solução contabilística com um único clique.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inativa",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"A entrada contém um caráter inválido.",
"62c7be09da43f8f96a60ef3967eddc2c":"Introduzir um Tradeshift Go totalmente novo.",
"19746bcbed8b8bcb1a9b83efca722077":"Domínio de e-mail inválido",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Convidar Pessoas",
"3809b41b14c55c3fcf54cd813d8e21f5":"Convidar para Equipa",
"fc90a11805961b0d7b5a5545e4d9bc05":"Convidar Utilizador",
"6f7f4acde92d267417f5d8a60d32400a":"Convidar mais pessoas",
"09ecd2ca15bb5c38dc10f653118346cd":"Convidar novo utilizador administrador",
"3f576ce504f53f972442a63a170c5418":"Convidar novo {role}",
"0215e052437ceea4e5cd562431b34ced":"Convidar pessoas",
"8aaf2c77df644b7e8524120f66da7d5f":"Montante da fatura",
"1b7dc8290f1205f092cc9133e0cde385":"Data da fatura",
"613da3d0cd586ef0360e0d93eace9508":"Emitido em",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Demora",
"3322b671105c65511fbab1966b7fdcf6":"Idioma",
"b1d946192795a58aea6a61f4f923acb7":"Últimos 4 Dígitos",
"b3c6b8db4ef6dcdcb451303e65548816":"Últimos 4 Dígitos",
"0382123b9c847000fceff2484c665e39":"Últimos quatro dígitos",
"19f7c0055e150e9e3e66e85c2993799c":"Sobrenome",
"662c84bede8a7bf91ab15bcb73b48b49":"Sobrenome",
"cff8fbef5aac2523344b9535bd56973c":"Sobrenome",
"c3510b2184a92ff12e07a2d1b9a461e6":"Permita que os funcionários peçam acesso ao seu cartão da empresa sem expor os seus detalhes do cartão, enquanto fazem orçamentos e acompanham as compras.",
"90c2664b0f771d6ffb1c3241fb8abe44":"O limite pode ser sobrescrito num nível de equipa e de aprovador individual",
"9bf5d56627e500cad9f7ad21c3e7966d":"Limite para determinadas categoria de despesas",
"e80a6d4232e52f6bfabee02e17403b59":"Vincule um destes cartões de origem a esta equipa ou registe um novo.",
"803570ab9f0fba5ff35ae2749200501f":"A carregar cartões...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Iniciar sessão",
"ad96c1ca962a883652dadb845f439415":"Entrar",
"072ca67e735adbed54acf41321370701":"Entrar",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Entrar",
"b246fe8836c906f1b56342378ca88895":"Fazer, aprovar e monitorizar compras em equipas que espelham a sua estrutura organizacional.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Gerir Liquidações",
"95d672ab621e531ae1a69798e0e4b56c":"Gerir todos os seus cartões virtuais num só local.",
"96f2e88a167f476413b35aa711709e6e":"Gerir acordos",
"151d8e6ceec242d482fff60da8311449":"Gestor",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Gestor",
"ef1b3b3627418a5e8e5cceb814a67c8d":"O gestor recebe todos os pedidos para revisão e aprovação As limitações de gastos também podem ser geridas.",
"4f449985e83c1f9ca2e8f82b0707a371":"O gestor recebe todos os pedidos para revisão e aprovação As limitações de gastos também podem ser geridas.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Gestores",
"27f8111a955975ea6fd04f748d24cb4a":"Limite de aprovação máximo",
"5b1d9cfb78c55e75b47c62435d9299ca":"Membro",
"41e307c1874e2e82296b226e9bae4a5f":"Membro",
"6f9612aa1582373c2c1ba4e144132100":"Membros",
"590429a0fcc42af85648d0fd70601dcc":"Membros",
"3f50e62c8488d83ad84f69ebda853897":"Membros",
"e0fc0acda5b74b93e797756dbbba67bc":"Membros",
"c7ef9eda38b7eaee1f20031387a8bde7":"Mês",
"40dd08b507752bef4e3c4b36838317d5":"Mensal",
"d712b91f7498f212047aad4c6d0f8088":"Mais Recente",
"12110d95b3d7a495afa4f28ac190f63e":"Mais recente",
"2f11ce3865f79ee7e5ee64d728082264":"Mais recente",
"eadc93865655cffc6a9863fdda5693e1":"Tem de ter no mínimo 10 caracteres, e consistir em caracteres maiúsculos e minúsculos.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Tem de ter no mínimo 10 caracteres, e consistir em caracteres maiúsculos e minúsculos.",
"50d0a69aa59df5201018771a06ea12e7":"Deve ser único",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Deve ser único.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Meus Cartões",
"c36602f50d35c2816f0ba8a196b70876":"Os Meus Pedidos",
"ff6e372aa3eb685a39a701963db35b2f":"Os Meus Pedidos",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Nome",
"1c7514b561a2591a914225813d30d642":"Nome",
"d621826c1ceade262589af04e655e824":"Nova senha solicitada",
"35dc3304b61a9e69dc3a393123658837":"Novos pedidos serão reencaminhadas automaticamente durante este período.",
"e788124801b4727d121f60d059a2a4a7":"Avançar",
"eca804f1d53dfd984ffd93e2d00fc284":"Nenhum Aprovador atribuído, por favor entre em contacto com o seu administrador do programa",
"abdf7e93f8509399a63108a2b1a25546":"Sem atividade para mostrar",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Sem cartões na sua carteira",
"87127e1e9f5bf922d0a4f948b68697c8":"Sem limite",
"40512efa6b75ef0c7924b1ee3070fca9":"Sem membros para mostrar",
"dcda493afcb2415d5ca49a17d1af6f50":"Ainda não foram publicadas liquidações.",
"f0efb26e623bb990532e375d5c8875ec":"Não disponível",
"bc0cf602d1978e814070bb552e576a32":"Não mapeado",
"09e126c317429ccb52ad323c32293648":"Número de pagamentos",
"75b00b969b9d3b520929f1e652637b70":"Número de pagamentos",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Número de pagamentos",
"bd44ba180aa356c5755fdedd32adf03c":"Oh não! Houve um problema ao criar um pedido. Tente novamente.",
"c396b3728dd7393aa9e5427990c132a7":"Oh não! Houve um problema ao transferir o relatório.",
"42c07764b07dea1ee7de7605721c3f5f":"Oh não! Não fomos capazes de criar uma nova equipa.",
"e3a385878ec94bac12a99490d0a8e85a":"Oh não! Não fomos capazes de criar a sua conta.",
"17f2dae79677017e2c2e879578c4e09a":"Oops! Ocorreu um erro. Tente novamente!",
"78c135de545c1663c9768226492a44a3":"Ou utilizar o início de sessão único (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Fora do escritório",
"5d7e45f169e2f6968dc58f43b5267353":"Fora do escritório",
"ee589a20c262527db970671fb2c4f6bc":"Fora do escritório",
"4fd0c753fce49a016b98d988a3895223":"Descrição geral",
"2789c5d5c9c042858f45aadc4e0de058":"Descrição geral",
"083b7090b66ebd0307a18499ac7b676e":"A página não existe",
"a21d205920893cf579a7655a1430fe30":"Cartão principal",
"fd35b7ce938e404fdb65de3bb749c528":"Cartão principal para relatório",
"e8c7d2eea7db66ecb44751091a582304":"Palavra-passe",
"bb79e316e00fd21138750b6986e44455":"Palavra-passe",
"6cae2279088580976d84119d95097cae":"Senhas não são iguais",
"4baec0159d3074f84c0eebcc09ba4cd8":"Senhas não são iguais",
"b676587115243921602742cb8f696a6a":"Criação de Cartão em Pausa",
"db4a26c871ef0b970b74e1f71763098b":"Colocar em pausa cartões virtuais novos para não serem emitidos",
"33fc1e6d01b785e3a815c28ef7d56667":"Pendente",
"6ea62e4811b68df48b467e8d367aac2f":"Pedidos Pendentes ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Pessoas",
"d91798f74d3344b720d1ddc0a7c8ebf6":"As pessoas numa equipa podem ter 3 funções diferentes",
"e2d34cb1409b9778d34c4b74ecee1df6":"Introduza um número inteiro positivo ou deixe em branco.",
"8da247d5d736c87122404eff1e5c0c73":"Introduza um limite válido.",
"65e154267ac283d963cbc7be832969d9":"Introduza um valor válido",
"28b4450d417065a69a395f567967d46c":"Pré-definir com limitações de gastos pedidas",
"7bbf151d8493170f206a23c2ecc6acd0":"Política de Privacidade",
"51602962f2d1e7515943b4a6c66562ce":"Imagem de perfil",
"a51a17940c70a3123da4d68bed562c40":"Perfil guardado!",
"4d398249d01ab72d697a7e219493c637":"Fornecedor",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Mapeamento do Provedor",
"89b1deb2ffe12a13941c362abaeab01b":"Publicado",
"d8d806d48052159e3ff3b224f3f53586":"Objetivo",
"089917619c78af2efb30be0bb7ce076c":"Reativar equipa",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Motivo",
"e8484c78fda9f453eeb942ba4945f040":"Reatribuir",
"ff2fbc1172ba26776ad0982624cd2713":"Recibo",
"588e690db177ee0c7a7cadb39cbd5efc":"Atualizar fundos todas as semanas, meses, anos",
"32955937daca05b2084556f248692baa":"Registar-se",
"f15bb755ba24abe6ab106637292175ec":"Restante",
"579782461133a8b8cc6bd77798961d82":"Remover Cartão",
"e475edc165372f8c8deb532aa85d9950":"Remover Lista",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Remover Lista",
"f9769561315b964d0b3b4887ec208977":"Remover da equipa",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Remover recibo",
"ee181cef581fc8e8217e66b5762db898":"Remover uma lista remove-a de ser utilizada e elimina todas as entradas existentes. Se pretender remover temporariamente uma lista e manter as entradas, pode desativar a definição de publicar. Tem a certeza de que pretende remover esta lista?",
"feea41d0bc1d577a07c8b6e1297abbde":"A remoção de um cartão de origem de uma equipa não desativará os cartões virtuais existentes.",
"d09483824a2e0d6194a4c3f32311e752":"Remover um cartão de origem de uma equipa não irá desativar os cartões virtuais existentes. Tem a certeza de que pretende remover este cartão desta equipa?",
"7ca4b1bf1346e179a29da97f260a93a4":"Mudar o nome",
"db195ec9c818b8e92da142374e20d1bc":"Renomear Lista de Codificação",
"2fd93e229fe9ea754808805d3fef9c13":"Renomear lista de codificação",
"aa1e74d32805823f802a11b33c60c38b":"Tipo de relatório",
"6ac78d97a8cb7f060e43e596e3e23535":"Pedido cancelado.",
"25deccb1980291a0fa9997109c731440":"Pedido de demonstração",
"9f2754d87686903b1500ce8e8a55b377":"Pedido de demonstração",
"92ac924f72679f897e26f3e615be8c22":"Pedido encaminhado.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Pedido de informação",
"270185007853cd779011ae0e8433f723":"Pedido novamente atribuído.",
"e5a059c9ba2691910321339cce989f9d":"Pedido enviado.",
"77c88a231edfa7075114d91d2397e42d":"Solicitar título",
"cee91b833c4358acf0c554f746557e71":"Pedido foi rejeitado.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Pedido foi rejeitado.",
"9e56abbbf87420eeedd625ce786515c7":"Solicitante",
"b4cb4426e44fee9580af71912a764536":"Pedidos",
"df309e888c29fc447d0309329b39ce93":"Redefinir senha",
"5daf278da19ecbaeb7b96554cb4db10f":"Repetir ligação agora",
"31e9501526d2114e4a01fdbf076de097":"Função",
"6fbceb787429f6f81117a1d5a58b4610":"Função",
"473b814ca86841ef672ccf9f7dac28d5":"Função",
"f1e16d5fd643e039d865b6aec7c986f9":"Função",
"ec78345a5975bc0f8efaee5256b43c2c":"Guardar",
"2cecea57b47d8f4a45c706287ff7d64e":"Guardar Alterações",
"15774be5c338a39169917c7d32781dd0":"Guardar e continuar",
"844095b4b8f580737b8717672501048e":"Diga algo...",
"7e66f676c2e7b04514aca3a0935530f0":"Procurar por informação de codificação",
"5293ce9335ec2d176a6e838bf76404c6":"Pesquisar por descrição",
"efab3b0c95f263cdd0a8466329a387eb":"Pesquisar por descrição",
"d227b38dcbc4548c092b0836a7101506":"Procurar por nome de comerciante ou ID de transação",
"3629383abf1d5f85efd759f718159b40":"Procurar por nome",
"a034fcd6719228b28a6fc22d3a46ea8a":"Procurar por nome de equipa",
"8ac7e0d63f343b548482d5f367f5b3d9":"Procurar por nome de equipa",
"858323eb52cb45446597ee46330a0540":"Procurar por proprietário de cartão virtual",
"111bfcbff671e13de117ed5195fb6773":"Selecione",
"bcfecd59eb93e5314873d84ddf35aa58":"Selecione",
"909a4414ea573218bff2d533eaab46ea":"Selecionar Data Final",
"114b206ca1ff1c0bc52abef342059b2a":"Selecionar Opções de Equipa",
"81a02e424615fb41a20b8d0f13441c88":"Selecionar equipas",
"b135b36495618e6692e7fbaf0a0aa83e":"Selecionar um mês",
"677e90855e164c00a28b5c4763ac67e0":"Selecionar uma função",
"d01570f3774738395b8ba3c64ce54c76":"Selecionar um estado",
"7dee3f06c70626881efb0cd6af6b63e1":"Selecionar um utilizador para encaminhar o pedido para",
"4c7bb810835a1540ddc1f6501da5a51b":"Selecionar um ano",
"3ab198d2678af2b5b32180e4b39602ba":"Selecionar aprovador",
"f502ba79f2bd010c5e77891812400958":"Selecionar cartão",
"936027182d446e27ba209ea921ef7502":"Selecionar intervalo de data de expiração predefinido",
"b22c893254ccf3bd34faaee511cf7ca8":"Selecionar duração",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Selecione das predefinições da lista de códigos ou crie a sua própria nova lista.",
"42cce5fa376b45bf290f86c684d69d96":"Campo selecionar fornecedor",
"b761773d46699b60e8d6874b85593374":"Selecionar equipas",
"3a9396756942ede98d0cb6afc1032b15":"Selecionar o seu aprovador",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Próprio",
"03e9d2c128af288126d94f631c5396be":"Enviar instruções",
"299a7e961804e2d649f06b5dc4362c29":"Enviar convite",
"155e7b3f7db7bf10b03b5f41de54eae1":"Enviar mensagem",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Definir uma data de expiração",
"f89ec96a4b5bb639488ab742efa76a6a":"Definir uma palavra-passe nova",
"7d5377ad70d57728a45d3c0639de5544":"Definições guardadas",
"14313f11dee735f2144953e720776c28":"O montante da liquidação pode ser diferente se forem aplicadas gorjetas ou outras taxas à compra",
"199000fe0cda0adaaa9b864aa7447cb9":"Liquidações",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Liquidações",
"6e6ac6ff3bd722e37874fb1ea7115818":"Configurar a palavra-passe",
"9b4ecf052980e80bf5c65a415d470a43":"Partilhar e controlar acesso ao seu cartão da empresa",
"ba746d78772d6be26cc508ba989becb0":"Deve ser inferior ou igual a {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Mostrar transações com recibos",
"7d474b60aeb7cc25b519e806fca71e12":"Iniciar sessão com as suas existentes \n\n credenciais da Tradeshift",
"16080cd4e8319ef3e7de21b6c19d1437":"Alguma coisa está errada",
"8f358a370224baf849f9652810c68db2":"Ocorreu um erro",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Ocorreu um erro",
"99af87c475a4d40ef732deed96f11ed2":"Ocorreu um erro. Tente novamente.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Lamentamos! Estamos a ter problemas de ligação de momento - repita dentro de {retryConnectionPeriod} segundos.",
"84debc8e41646cd49ac2b14708ca6d73":"Lamentamos, houve um problema ao eliminar a entrada de codificação. Tente novamente.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Lamentamos, houve um problema ao guardar a entrada de codificação. Tente novamente.",
"689d3418ee8f996ad25e152e80de63fe":"Lamentamos, não conseguimos terminar a sua sessão de momento.",
"b48efd4521ecd6428987d24636a4349f":"Ordenar",
"776a95003d3ec1dd58af69aedbc08579":"Ordenar",
"1b783238028c0ae085ca69ed823f4d1a":"Ordenar por",
"be70d19bf13c935d653b2c4fe7d36224":"Nome do Cartão de Origem",
"564f8c942e7d1bdda5251545612fa348":"Nome do cartão de origem",
"aa284602c746aad4a6de6366c24221b8":"Cartões de origem",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Cartões de origem",
"389d471e79a43440b3278b0fc7187d9e":"Cartões de origem",
"106342f689b57145817b00d6fe383f91":"Data Final Específica",
"2a5e19387ff300509876e3bae99b7ab9":"Acelerar a sua contabilidade",
"ca34d586bbdb7f368f1009305bccbe76":"Dashboard de Gastos",
"4583120998b44c0a5982cf85dd938d2f":"Gastar ao Longo do Tempo",
"244d724806247953233a0bbfcbf8da4c":"Limite de gastos",
"d39b83dd151c8df6370460a458294ccf":"Gasto",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Montante Gasto",
"5de2859528e030ac1f2af115ebdf209c":"Gasto { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Iniciar reencaminhamento em",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Estado",
"b089930f1f6ffac09438cc19590f8f6b":"Estado",
"f174d20592156eb4b10a7cf1144c973e":"Estado",
"ecf342482d1b6ab932c2000cd7dc38c4":"Estado",
"1f1fe111548158ec842761eca7425ff3":"Morada",
"31a0a748b1097a9e0aa95a811802bdd0":"Reconciliação sem stress",
"111b782f3f0794c2294aefb6693b2c50":"Enviar",
"013e2555920b51ad2a3d2c3dc482c471":"Enviar pedido",
"1270cd8eb69ad64cc71e5de979423b3b":"Subscrição Termina",
"2802336ad3d8ff658257b88a93f4e50a":"Subscrição Termina",
"b28dd68454d7acbefafeefb42981fe16":"Frequência de Subscrição",
"907793bc80cf94d560e5f1a0250fdaee":"Frequência de Subscrição",
"29229e153308e4747edef1144de35a55":"Êxito!",
"503eb46b80f374f39266e7a9aea8e63e":"Êxito!",
"4f20e29cadadb9fbc7455bd4d80de567":"Êxito!",
"601d04f10d558f436c78c10e90b44175":"Suporte",
"62558c185ea2c06c5472d1cd534f6e56":"Sincronizações com a sua solução de contabilidade",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Montante do imposto",
"0cb5d74f7a56fca30930616feb6e2f47":"Equipa",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Equipa",
"bf23b2fd889e3b12e0f0acf99f913eca":"Cartões de Equipa",
"b90a650376782a9e43ed6be6135bd6f2":"Painel da Equipa",
"6adbec395fe1c4bf776f9fe5fa021052":"Painel da Equipa",
"6f2695eeb68501dbcfddbb4d265b846b":"Gestores da Equipa",
"3b779ade9c960ac95a670db0cc12e56b":"Membros da Equipa",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Definições da Equipa",
"dc5235e1b88320d3ba7501e4c30985a3":"Definições da Equipa",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Definições da Equipa",
"a2eb92bfd7502f47b3cd170bc68ac638":"Definições da Equipa",
"f1ec84f051f19f0d0e301a709b13dc45":"Gastos da Equipa",
"fbacde3a262ea8696e30135e8f987a44":"Comutador de Equipa",
"74b9578ea5a620f5fef102188e42c02f":"Comutador de Equipa",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Ações da equipa",
"3aa978fa7ef886621285b93257d428c3":"Limite de aprovação de equipa",
"a41f9df131c221a99926de7e2138653a":"Informação da equipa",
"941c7da8f256f91ba3819f991315a100":"Nome da equipa",
"c5d04e10cc2f0b53c2bda893eae09178":"Nome da equipa",
"504a8ba642ba37c9a743d0fabb2bd371":"Nome da equipa",
"e768a8e1e3d6a6bf470587dad75423fe":"Proprietário da equipa",
"e2d824067f8bd871756688c58c382196":"Responsáveis pela Equipa",
"dca232ccc849c5ff5611255eb82baf53":"Responsáveis pela Equipa",
"3b61bd586225790cf132591383d15448":"Comutador de equipa",
"bc05b40509f0271c12cfaaef8e5319b1":"Equipas",
"39e73773d07a800673ec6600422952d6":"Equipas",
"daabb564bb3dcfb41de69492f854d573":"Equipas",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Os limites de equipas e individuais nunca excederão o limite da empresa",
"4b4fe189a48d8eb06d836afa8fb6317a":"Equipas atualizadas.",
"2a17f9a8c610db29fec63fd530c29fdc":"Equipas que usam o cartão",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Equipas que vêm isto",
"317868f3804ba4ed5d99dd9919c798ba":"Termos de Serviço",
"e8c9a9847db2ccd97524aa06f1af9d82":"Termos de Serviço e Política de Privacidade",
"38852078519c5c46715d8a034fc4901b":"Termos de Serviço e Política de Privacidade.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Obrigado pelo seu interesse na Tradeshift Go. O suporte para os cartões de crédito {cardLabel} será lançado em breve. Um membro da nossa equipa de êxito do cliente irá informá-lo assim que estiver disponível.",
"f7047b35a069d0790a0a348a29c846a2":"Obrigado por juntar a sua equipa a {companyName} para fazer compras de negócios com os cartões virtuais Go.",
"133b0a70b1a956889164621380c378f9":"A ação youâ€™re está a tentar realizar não pode ser concluída. Em alguns casos, basta tentar novamente ajuda.",
"18f5a1872e156f1aee84ae321780d952":"O montante solicitado estava acima do limite de pedido da empresa de ${requestLimit}. Tente novamente com um montante de pedido inferior.",
"867e3d4d3c78990c6852fc5348706ab2":"O valor pedido estava acima do limite do pedido do fornecedor de ${providerLimit}. Tente novamente com um valor de pedido mais baixo.",
"570f5f4658b03dda7a910f56c86a3bd3":"O cartão para este pedido foi cancelado.",
"3075c56105863546e3368ffead7a146b":"O cartão foi cancelado.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"A lista de codificação para a qual está a tentar cancelar a publicação está ligada a uma integração. Se tem a certeza sobre como remover isto da integração, entre em contacto com o suporte que pode concluir esta ação.",
"756ade8d71ec487a4b48d7c4277ea239":"A lista de codificação para a qual está a tentar cancelar a publicação está ligada a uma integração. Primeiro precisa remover o mapeamento antes de cancelar a publicação desta lista de codificação.",
"4f40335f7c018986c95458183bc0c136":"O link para ativar a sua conta é inválido ou expirou. Verifique junto da pessoa que o convidou para obter outro convite.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"O link para repor a sua palavra-passe é inválido ou expirou. Solicite novamente a reposição da palavra-passe para obter um novo link.",
"ded30f0e288c12125ca1a77f8d8fd600":"O motivo será visível para o aprovador e requerente",
"f294897b38fc23dd8f5de52b54ceae22":"O pedido será rejeitado e o solicitador notificado. Pretende prosseguir?",
"e8c7200dc985df0a94a9d996968b4cc0":"O pedido será rejeitado e o solicitador notificado. Pretende prosseguir?",
"5f3d196a2ade3f97a2a48b773fec5861":"O pedido será rejeitado e o solicitador notificado. Pretende prosseguir?",
"b04c191cd6b4f27669a4f390311f156c":"Não existem responsáveis pela equipa ou gestores a serem selecionados.",
"e25045557efbad7b52997b94a1fe6817":"Não existem {type} para este período de tempo",
"7260690577c795e999acae63bb7c51ae":"Houve um problema ao guardar as definições de equipa. Tente novamente.",
"f2103024f2a9c6c0335a71c60567d6c9":"Houve um erro ao carregar a sua carteira.",
"9776cb7eab9fa0d318a8144cd17de745":"Houve um erro ao carregar a sua carteira.",
"77d28a14bc900be4360b570a82eb75a9":"Não existem dados para mostrar",
"2b6feba67722337f88c1443909fe446f":"Houve um problema ao adicionar o aprovador!",
"24b7212afb97c5d23faafa2ca4559095":"Houve um problema ao adicionar o cartão de crédito. Tente novamente.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Houve um problema ao adicionar o cartão de origem da equipa. Tente novamente",
"5a8e1b922d1cee746ac6794599b10709":"Houve um problema ao cancelar este cartão. Tente novamente.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Houve um problema ao cancelar este cartão. Tente novamente.",
"22361c5d4df61ad7ae9792a3baf5729f":"Houve um problema ao criar a sua lista. Tente novamente",
"098755bfddeaf0428c7feb6b69af9b6f":"Houve um problema ao rejeitar o pedido. Tente novamente.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Houve um problema ao rejeitar o pedido. Tente novamente.",
"7d461b6b7b54dbfa9c83243217cd1053":"Houve um problema ao eliminar a entrada de codificação. Tente novamente.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Houve um problema ao eliminar a sua lista. Tente novamente.",
"beb43d7800cfff47e08cd61842d953bd":"Houve um problema ao encaminhar o pedido. Tente novamente.",
"f7775350f35b8365fb9803232d9b792c":"Houve um problema ao obter os detalhes do cartão. Tente novamente.",
"95abaa922c6c1648ca9c39ac4776896f":"Houve um problema ao obter os detalhes do utilizador. Tente novamente.",
"9d7e4ead60137041ae8a845aa6cf9979":"Houve um problema ao carregar o perfil do utilizador. Tente novamente.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Houve um problema ao carregar estes utilizadores de equipas. Tente novamente.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Houve um problema ao carregar as suas definições. Tente novamente.",
"b2f48028c400befad5f1d57f769ef7cd":"Houve um problema ao reatribuir o pedido. Tente novamente.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Houve um problema ao remover o cartão de origem da equipa. Tente novamente.",
"19dfcd761006496c1d20351c04643e45":"Houve um problema ao remover este domínio, tente novamente.",
"149651b5784481c68aaf4ac27bb1f907":"Houve um problema ao guardar a lista de codificação. Tente novamente.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Houve um problema ao guardar o perfil. Tente novamente.",
"749ddc235744b6b371668ec0bb049176":"Houve um problema ao guardar o utilizador. Tente novamente.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Ocorreu um problema ao guardar este domínio, tente novamente.",
"04cf86909808f430b8b1904fb422e0d3":"Houve um problema ao carregar as suas definições de indisponível. Tente novamente.",
"2eaf6ee97d984598c95e0494d72aa492":"Houve um problema ao submeter o pedido de compra.",
"148f847d3beccaa67fcd5096ae3602d3":"Houve um problema ao atualizar o nome do cartão. Tente novamente.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Houve um problema ao atualizar o nome do cartão. Tente novamente.",
"de8cc976f7af9edf189904239e6240da":"Houve um problema ao atualizar as equipas. Tente novamente.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Houve um problema ao adicionar o admin., tente novamente.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Ocorreu um erro ao desativar a sua equipa. Por favor, tente novamente.",
"3f686a97a1b9fcdd73a40d2005693105":"Houve um erro, tente novamente.",
"2f38263789163cf8ee30c8ae88852c82":"Houve um erro ao publicar a sua lista.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Houve um problema ao remover o admin., tente novamente.",
"007a647254e1f85b6c1e789004e05df5":"Houve um erro ao guardar as definições.",
"c3072d080b587d70f85d68ec089f028f":"Houve um erro ao guardar as definições para a sua equipa. Tente novamente.",
"608cd0e109c3e51b0425df8921e4181d":"Houve um erro nas definições do mapeamento do fornecedor. Tente novamente.",
"177cefc68dd318ea75673326e77d8206":"Houve um erro ao remover a publicação da sua lista.",
"255d22dc4f0e338a4cf53d5b7256e695":"Houve um problema ao recolher o nome da pessoa para a qual está a encaminhar os pedidos de aprovação.",
"39ac4ec73fed8a981ad1857d989edfcb":"Este valor excede o limite de aprovação da empresa.",
"494ce3f598cbd97afe99774b5975a4bc":"Este montante excede o limite de aprovação da equipa.",
"f2a42214e7c480c5596439becfd0b7bf":"Este campo é obrigatório.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Esta definição apenas afetará os cartões novos",
"881b7596cf985d9c246b05fcab930632":"O cartão de origem foi adicionado à equipa.",
"a1b1aa354d06e7815b78c6abb7448e84":"O cartão de origem foi removido da equipa.",
"b348b24fc4839ee15ed0aa424689bae3":"Esta equipa deve ter um administrador ou gestor antes de poder convidar um membro.",
"188b9ed447a43916f9e323623ea3a993":"Para aceitar o convite",
"30286e57da108ee7dbc0f8fbb62d4fff":"Total gasto ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Registe o gasto entre equipas, projetos e subscrições.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Registe a atividade dos cartões ativos da sua teamâ€™s, pause os gastos e desative os cartões que não são mais necessários à equipa.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Redefinir palavra-passe do Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Tente novamente",
"80196bc616d4a44a4997a2ea127f7b83":"Tente novamente",
"f96bbe81618b5f4a1807989eb4f69e24":"Tente novamente",
"d498d7d261b7ca56e07218d1a0dbdd49":"Uh oh! Houve um erro. Tente novamente.",
"ce2e475291003852c71b01959698c496":"Impossível obter mensagens sobre o pedido {title}, Tente novamente",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Desarquivar",
"d2e50be5287fd8fb436d0acbeb811864":"Identificador único",
"0e2fbce967f4a209215b6f48f8eddac3":"Não Colocar em Pausa Criação de Cartão",
"f2315dec5dd07802b77af3a3a5414a7d":"Até 4 anos no futuro.",
"d2f30a2f0c955271e9af123dceca501a":"Até {label} no futuro",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Atualizar cartão de origem",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Atualizar cartão de origem",
"c8f6bc61a8982158e408194e48a274d9":"Carregamento falhou, tente novamente.",
"8a86a60cb836bdb5225cad20c5151078":"Carregar receita",
"e448a1ce42de0cf526200331b4ad8b23":"Utilizador",
"75edcc8fc27f41c99e28b48ff250c7f8":"Detalhes do Utilizador",
"d1651c2afdb6c92621adbb707e4753d0":"Utilizador guardado.",
"5fa55883b7413526b441e999cb8089b3":"Nome de utilizador",
"7a892b81fe2aae295f1b2d38bb67360f":"Nome de utilizador",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Utilizadores com cartões ativos não podem ser removidos. Cancele os cartões para remover este utilizador.",
"d40fa5935f1614925cf46259c886814a":"Ver Atividade do Cartão",
"625419857efc7e72d162c9f8204109df":"Ver Cartão na Carteira",
"85a5e817826415651ed4991a76bb5385":"Ver Conversas",
"09fc124dc9b8b14d6227a92bb0a4566f":"Ver conversas",
"d26cfc6d8f420f259f434b79593e9eb4":"Ver receção",
"c7d0394c3206a3b74afbeabc41cc639c":"Ver receção",
"01650aa52c5e5a22d3f38afd2c7f9411":"Ver receção",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Ver receção",
"b2d141897f73fc2a198ee1a7a438f89b":"Exibir solicitação",
"02fa2f56ef72845a9dfeabea22948061":"Ver detalhes da equipa",
"2d9c55650b17dd605a1388f46c81ba38":"Cartão virtual",
"d0ec076e4600e854588f8da265208c0b":"Opções de cartão virtual",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Carteira",
"5279a2220e4cfb0d8b8b357311251cbe":"Carteira",
"b1746b5806659e3189f837d2dd54e08c":"Carteira",
"ff306d5b34ee993f9618c1f3c0209a39":"Não somos capazes de arquivar o pedido neste momento. Tente novamente mais tarde.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Não somos capazes de cancelar o pedido neste momento. Tente novamente mais tarde.",
"a813d44f6bb26c5da09671e532abe022":"Não somos capazes de desarquivar o pedido neste momento. Tente novamente mais tarde.",
"d4c7310eafc0d0da89f20596a22a3abe":"Não somos capazes de criar um pedido neste momento. Tente novamente!",
"dca679c10accb1db481a57a0e1ba08e2":"Não somos capazes de criar um pedido neste momento. Tente novamente!",
"db5584a2ef29b04959bce7f93db54c56":"Não somos capazes de mudar de equipa neste momento. Tente novamente mais tarde.",
"f2af2c261fabddb01766f4f974b85a62":"Semanal",
"e181ed0723a00ca7558b9d5d5258732b":"Bem-vindo ao Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Weâ€™ll em contacto!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Que cartões de crédito a sua empresa usa?",
"968d94729053e166a76ea577ce3022e2":"E-mail de trabalho",
"588a075e8694ff5cc86815697e1586bc":"Trabalhar da forma que as suas equipas de negócios trabalham.",
"182ae1eb5226e0646b4033883cc0900c":"Ano",
"fe88fe7094c4ced427874b3d22326c8d":"Sim",
"9a2d4e3b22ad0567165eb0f661f952ad":"Sim, Cancelar Cartão",
"96b88e530375237cbc08138d9fb4109c":"Sim, Remover esta Lista",
"fd3780f468c531f2bc1b47b8748b5a66":"Pode criar códigos contabilísticos nas definições da empresa.",
"ab3ac6f945d9f756763d49257fd924dc":"Pode gerir códigos contabilísticos nas definições da empresa.",
"c919dae93fe780061629c255eb358140":"Não pode remover o único cartão de origem ligado a esta equipa. Adicione outro cartão para poder remover este.",
"11de97e873b66d91a77db7a05da32202":"Pode promover um dos responsável pela equipa listados abaixo para ser um administrador da empresa ou convidar um novo utilizador.",
"0ab790f08b2780be001b79d2ccd75c4e":"Não tem códigos contabilísticos criados para esta conta.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Não tem quaisquer listas de códigos criadas.",
"44e470218124a50c117870467530752e":"Introduziu um endereço de e-mail ou palavra-passe incorretos.",
"4b687d0730822fdbeea27c174e47f227":"Não tem aprovações aprovadas.",
"020f03c17b79af66f335b6af868c66c0":"Não tem aprovações canceladas.",
"def0b76f03e7c622341a7d8e08aaaae7":"Não tem aprovações recusadas.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Não tem aprovações pendentes.",
"7c034d6104f80a523b90bc607fc06608":"Não tem cartões virtuais na sua carteira. Criar um pedido",
"0aed9198d61d161468d541dbbc9f477a":"Tem encaminhamento de indisponibilidade para {oooForwardToFullName} agendado até {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Recebeu um cartão virtual novo:",
"04eeaf814767c30aa41577f3b11f916a":"Solicitou recentemente uma reposição da palavra-passe da sua conta Go. Para concluir o processo:",
"09ddf249810b9af7d3e1f4e537da500d":"Tentou aceder a uma página que não existe. Certifique-se de que tem o link correto.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"É convidado a juntar-se à sua equipa em",
"02c6f3a7cf31a87f4f39f992509f8c44":"Foi adicionado como um administrador para a sua empresa",
"cf121a14590f109dafcae138265e353b":"Foi adicionado a uma nova Equipa!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Foi adicionado à equipa",
"d3cef66a026f776903b342d450e5a832":"O seu pedido de cartão Go foi negado",
"003f87c21a29220e661fc302b885fd5a":"A sua conta foi desativada, contacte o seu administrador da empresa ou da equipa.",
"a52b1f0da15dce68007f524e48238143":"O seu banco devolveu um erro ao adicionar este cartão. Contacte o seu banco para resolver e tente novamente.",
"4d883b173167297463aeac86957c26fe":"O seu endereço de e-mail foi utilizado para criar uma conta em Tradeshift Go. Para verificar o seu endereço de e-mail e concluir o registo",
"3776a5b34e9c0844f7f5bff6ab1cd535":"As suas definições de indisponibilidade foram guardadas.",
"542f08554110a12de0c23afd78f38e0e":"A sua palavra-passe foi reposta. Inicie sessão.",
"49b2dcbe5d461f35c37986962b5bd07a":"O seu pedido foi rejeitado",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Youâ€™re está na lista!",
"32f87c5ddeaee35b9178785ef4eb9313":"Código postal",
"c6974a3d5311374c697625f2a3234bce":"uma data",
"f86980f5ef00b645a6e4a0b59738a954":"uma pessoa que não procuramos",
"7066f854917b39bafe0bbecbb8bd6377":"aprova os pedidos",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"para",
"49b171230ab9bcdd8b631c70cf397efb":"para <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"para a sua empresa",
"35ad86f0b035d8156390670d60801e7f":"para {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"para {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"de",
"c1407aa5424f339dedf67bcac597cf71":"definiram os seus pedidos de aprovação para serem encaminhados para si durante",
"67a948b8af3284a401ebefcb1b4723ab":"está a aguardar pela sua aprovação de",
"9ca64449d64b98fef7859e22bc23c4dc":"faz pedidos",
"ac850ab84428e9eb6a57e5d813de5e45":"gere equipa",
"2168839a1f4c73ef5c89c9e930148e7d":"ignore este e-mail. Não é necessária qualquer ação",
"85431c325faab98e02daa60151465144":"enviou-lhe este convite",
"81c504fd54119e7e580eb05dedef46d0":"enviou-lhe este convite",
"d74545060b38445ef4cc49fbb8d79876":"enviou-lhe este convite.",
"2d96c53ee7ee0a54aadad19bfce0697d":"houve um erro ao obter as suas origens de cartão",
"b2fda295788d579fffd3f43b7737fd63":"para registar o cartão da sua equipa com o Go, para garantir aos seus funcionários acesso seguro e fácil ao cartão",
"d35bd5c5d74af765d45cef3c4e78f52a":"Para fazer compras de negócios com os cartões virtuais Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"Para fazer compras de negócios com os cartões virtuais Go",
"9546989e681a7e25fbdfe7631e9ebc03":"Para fazer compras de negócios com os cartões virtuais Go.",
"6174e0a3d26409dd6e410052e2805660":"para começar a usar cartões no Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"desligar fora do escritório",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} é responsável por aprovar pedidos de outros membros da equipa. Atribua um novo aprovador para os membros afetados para prosseguir.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} foi convidado com êxito${this.teamId ? '.' : ' to the team.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} foi removido",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} cancelado o pedido para",
"9567a2d4cacd58fb4f096132d26c1660":"{username} é responsável por aprovar pedidos de outros membros da equipa. Atribua um novo aprovador para os membros afetados para prosseguir.",
};
