const _ = require('lodash');

function submitAsync() {
	return {
		restrict: 'A',
		scope: {
			submitAsync: '&',
		},
		link: function ($scope, $element) {
			const button = $element[0].querySelector('button[type=submit]');

			$element.on('submit', function () {
				const promise = $scope.submitAsync();
				if (promise && _.isFunction(promise.finally)) {
					if (button) {
						button.setAttribute('data-ts.busy', true);
						promise.finally(() => {
							button.setAttribute('data-ts.busy', '');
						});
					}
				}
			});
		},
	};
}

module.exports = submitAsync;
