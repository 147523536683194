import _ from 'lodash';

const TeamBoxComponent = {
	bindings: {
		currency: '@',
		teams: '<',
		dashboardMetrics: '<',
	},
	template: `
		<ts-table
				class="teams-box-table"
				columns="$ctrl.columns"
				max-rows="5"
				on-cell-click="$ctrl.onCellClick($event)"
				rows="$ctrl.rows"></ts-table>
		<go-context-menu
				is-visible="$ctrl.isContextMenuVisible"
				links="$ctrl.contextMenuLinks"
				on-hide="$ctrl.onHideContextMenu($event)"></go-context-menu>
		<go-select-team-actions-aside
				go-to-team-dashboard="$ctrl.goToTeamDashboard()"
				go-to-team-settings="$ctrl.goToTeamSettings()"
				is-open="$ctrl.openSelectTeamActionsAside"
				on-close="$ctrl.onCloseTeamActionsAside()"
				title="$ctrl.selectTeamsOptionsText"></go-select-team-actions-aside>
	`,
	controller: /* @ngInject */ function ($filter, $timeout, $state, $window, i18nService, LexicoService, TeamsService) {
		this.isContextMenuVisible = false;
		this.lexico = LexicoService.getLexico();
		this.selectTeamsOptionsText = this.lexico.trc('Component title', 'Select Team Options');
		this.numberOfCardsNotAvailableText = this.lexico.trc('Data not available', 'N/A');
		this.openSelectTeamActionsAside = false;

		this.onCellClick = ({ columnIndex, rowIndex }) => {
			this.rowIndex = rowIndex;
			if (columnIndex === 4) {
				if (ts.ui.isMobilePoint()) {
					this.openSelectTeamActionsAside = true;
				} else {
					this.contextMenuLinks = [
						{
							icon: 'team-dashboard-icon',
							label: this.lexico.trc('Context menu label', 'Team Dashboard'),
							onClick: () => {
								this.goToTeamDashboard();
							},
						},
						{
							icon: 'team-settings-icon',
							label: this.lexico.trc('Context menu label', 'Team Settings'),
							onClick: async () => {
								await this.goToTeamSettings();
							},
						},
					];
					this.isContextMenuVisible = true;
				}
			}
		};

		this.goToTeamDashboard = () => {
			$state.go('main.approverDashboard', {
				teamId: this.teams[this.rowIndex].id,
			});
		};

		this.goToTeamSettings = async () => {
			await TeamsService.setCurrentTeamById(this.teams[this.rowIndex].id);
			$state.go('main.teamSettings.overview');
		};

		this.onHideContextMenu = () => {
			this.isContextMenuVisible = false;
		};

		this.onCloseTeamActionsAside = () => {
			this.openSelectTeamActionsAside = false;
		};

		this.columns = table => {
			const currencySymbol = i18nService.getCurrencySymbol(this.currency);
			table.sortable((index, ascending) => {
				table.sort(index, ascending);
			});

			return [
				{
					flex: 3,
					label: this.lexico.trc('Table component title', 'Team'),
					search: {
						tip: this.lexico.trc('Table component title', 'Search by team name'),
					},
				},
				{
					label: `${this.lexico.tr('Approved')} ${currencySymbol}`,
					flex: 2,
				},
				{
					label: `${this.lexico.tr('Spent')} ${currencySymbol}`,
					flex: 2,
				},
				{
					flex: 2,
					label: `${this.lexico.tr('Available')} ${currencySymbol}`,
				},
				{
					flex: 2,
					label: '',
				},
			];
		};

		this.generateRows = teams => {
			return teams.map(team => {
				const numberOfCards = team.isCardLimitExceeded ? this.numberOfCardsNotAvailableText : team.numberOfCards;
				return [
					{
						text: `${_.get(team, 'name', '')} \n ** *${team.numberOfMembers || 0}* • *${numberOfCards}* **`,
						value: `${_.get(team, 'name', '')}`,
					},
					{
						text: `${$filter('currency')(_.get(team, 'metrics[0].approvedAmount', 0), this.currency, true)}`,
						value: Number(_.get(team, 'metrics[0].approvedAmount', 0)),
					},
					{
						text: `${$filter('currency')(_.get(team, 'metrics[0].spentAmount', 0), this.currency, true)}`,
						value: Number(_.get(team, 'metrics[0].spentAmount', 0)),
					},
					{
						text: `${$filter('currency')(_.get(team, 'metrics[0].availableBalance', 0), this.currency, true)}`,
						value: Number(_.get(team, 'metrics[0].availableBalance', 0)),
					},
					{
						item: 'Icon',
						type: 'ts-icon-other',
						color: 'black',
						value: false,
					},
				];
			});
		};

		this.$onChanges = () => {
			this.teams.forEach(team => {
				team.metrics = [
					{
						approvedAmount: 0,
						spentAmount: 0,
						availableBalance: 0,
						currency: this.currency,
					},
				];
				this.dashboardMetrics.teams.forEach(t => {
					if (t.teamId === team.id && t.metrics.length) {
						team.metrics = t.metrics;
					}
				});
			});
			this.rows = this.generateRows(this.teams);

			// This function is being added in to allow the ts-table to resize correctly once the data has been loaded in
			// There is an issue with ts-table not rendering correctly on the intial load of the app.
			$timeout(function () {
				$window.dispatchEvent(new Event('resize'));
			}, 0);
		};
	},
};

export default TeamBoxComponent;
