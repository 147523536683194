const _ = require('lodash');

export default class DownloadReportService {
	/* @ngInject */
	constructor(FileSaver, PaymentsService, ReportService, LexicoService) {
		this.lexico = LexicoService.getLexico();
		this.FileSaver = FileSaver;
		this.PaymentsService = PaymentsService;
		this.ReportService = ReportService;
	}

	getFormOptions(teamId) {
		return this.PaymentsService.getCardSources({ teamId }).then(cardSources => {
			const reportTypes = [
				{ label: this.lexico.trc('Label title', 'Requests'), value: 'requests' },
				{ label: this.lexico.trc('Label title', 'Settlements'), value: 'settlements' },
			];
			const cardSourceOptions = cardSources
				.map(cardSource => ({
					id: cardSource.id,
					label: `${cardSource.name} (${cardSource.lastDigits})`,
				}))
				.concat([
					{
						id: '',
						label: this.lexico.trc('Label title', 'All'),
					},
				]);
			return { reportTypes, cardSourceOptions };
		});
	}

	downloadReport({ cardSourceId, reportType: type, teamId, createdBefore, createdAfter }) {
		return this.ReportService.getReport({
			cardSourceId,
			format: 'csv',
			teamId,
			type,
			createdBefore,
			createdAfter,
		})
			.then(result => {
				if (!result.data) {
					const warningMessage = this.lexico.tr('There are no entries in this time period');
					ts.ui.Notification.warning(warningMessage);
					return;
				}
				const headers = result.headers();
				const fileName = _.chain(headers['content-disposition'])
					.split('attachment; filename=')
					.last()
					.replace(/"/g, '')
					.value();
				const data = new Blob([result.data], { type: headers['content-type'] });
				this.FileSaver.saveAs(data, fileName);
			})
			.catch(() => {
				const warningMessage = this.lexico.trc(
					'Warning message',
					'Oh snap! There was a problem downloading the report.',
				);
				ts.ui.Notification.warning(warningMessage);
			});
	}
}
