"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiMenuListOverrides = void 0;
function getSideMenuTeamSwitcherMenuStyle() {
    return {
        height: '100vh',
        maxHeight: 'calc(100vh - 60px)',
        overflowY: 'auto',
    };
}
exports.muiMenuListOverrides = {
    styleOverrides: {
        root: ({ ownerState }) => ({
            ...(ownerState['ts-variant'] === 'sideMenuTeamSwitcherMenu' && getSideMenuTeamSwitcherMenuStyle()),
        }),
    },
};
