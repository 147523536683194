const ContextMenuComponent = {
	bindings: {
		isVisible: '<?',
		links: '<?',
		onHide: '&',
	},
	template: `
		<div
				class="invisible-overlay"
				ng-click="$ctrl.destroyContextMenu()"
				ng-if="$ctrl.isVisible"></div>
		<div
				class="menu-wrapper"
				ng-if="$ctrl.isVisible"
				style="top:{{$ctrl.top}};left:{{$ctrl.left}}">
			<ul>
				<li
						ng-click="link.onClick()"
						ng-repeat="link in $ctrl.links">
					<i class="{{ link.icon }}"></i>
					<span ng-bind="link.label"></span></li>
			</ul>
		</div>
	`,
	controller: /* @ngInject */ function ($window) {
		document.onmousemove = e => {
			this.cursorX = e.pageX;
			this.cursorY = e.pageY;
		};

		this.$onChanges = changes => {
			if (changes.isVisible && changes.isVisible.currentValue) {
				const browserHeight = $window.innerHeight;
				const browserWidth = $window.innerWidth;
				const widthOfMenu = 200;
				// Note: This value may need to be tweaked
				// if the height of the list item changes
				const heightOfMenuItem = 35;
				const heightOfMenu = this.links.length * heightOfMenuItem;

				const hitBoxWidth = widthOfMenu + this.cursorX;
				const hitBoxHeight = heightOfMenu + this.cursorY;

				if (hitBoxWidth > browserWidth) {
					this.left = this.cursorX - (hitBoxWidth - browserWidth) - 10 + 'px';
				} else {
					this.left = this.cursorX + 'px';
				}

				if (hitBoxHeight > browserHeight) {
					this.top = this.cursorY - (hitBoxHeight - browserHeight) - 10 + 'px';
				} else {
					this.top = this.cursorY + 'px';
				}
			}
		};

		this.$onDestory = () => {
			this.onHide();
		};

		this.destroyContextMenu = () => {
			this.onHide();
		};
	},
};

export default ContextMenuComponent;
