module.exports = {
	access: /* @ngInject */ ($state, UserServiceV2) => {
		return (async () => {
			let hasViewAccess;
			try {
				hasViewAccess = await UserServiceV2.verifyAccess({
					objectId: 'company_settings',
					operationId: 'view',
				});
			} finally {
				if (!hasViewAccess) {
					$state.go('main.wallet');
				}
			}
		})();
	},
	teams: /* @ngInject */ TeamsService => TeamsService.getActiveTeams(),
};
