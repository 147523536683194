/* @ngInject */
function RequestProfilesService(HttpService) {
	const service = {};

	service.getProfiles = function () {
		return HttpService.tradeshiftGo().path('/rest/requestProfiles').get();
	};

	service.getProfile = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/requestProfiles/' + options.profileId)
			.get();
	};

	service.getProfileForCompany = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/company/' + options.companyId + '/requestProfiles')
			.get();
	};

	service.setProfileForCompany = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/company/' + options.companyId + '/requestProfiles/' + options.profileId)
			.put();
	};

	service.removeProfileFromCompany = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/company/' + options.companyId + '/requestProfiles')
			.delete();
	};

	return service;
}

module.exports = RequestProfilesService;
