const RemoveListAsideComponent = {
	bindings: {
		currentList: '<',
		isOpen: '<',
		onClose: '&',
		title: '<',
		updateTableAfterListRemoval: '&',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{ $ctrl.title }}">
			<ts-note
					icon="'ts-icon-warning'"
					message="$ctrl.message"></ts-note>
			<menu data-ts="Buttons">
				<li>
					<button
							class="ts-primary"
							ng-click="$ctrl.removeList()">
						<span ng-bind="$ctrl.removeListButtonText"></span>
					</button>
				</li>
				<li>
					<button
							class="ts-secondary"
							ng-click="$ctrl.onClose()">
						<span ng-bind="$ctrl.cancelButtonText"></span>
					</button>
				</li>
        	</menu>
		</ts-aside>
	`,
	controller: class RemoveListAsideController {
		/* @ngInject */
		constructor(CodingService, LexicoService) {
			this.lexico = LexicoService.getLexico();
			this.removeListButtonText = this.lexico.trc('Button', 'Yes, Remove This List');
			this.cancelButtonText = this.lexico.trc('Button', 'Cancel');
			this.isLoading = false;
			this.CodingService = CodingService;
			this.message = this.lexico.trc(
				'Warning message',
				'Removing a list removes it from being used and deletes all existing entries. If you want to temporarily remove a' +
					'list and keep the entries you can turn off the publish setting. Are you sure want to remove this list?',
			);
		}

		async removeList() {
			this.isLoading = true;
			try {
				const { id } = this.currentList;
				await this.CodingService.deleteList({ listId: id });
				this.isLoading = false;
				this.updateTableAfterListRemoval();
				this.onClose();
			} catch (e) {
				this.isLoading = false;
				const warningMessage = this.lexico.trc(
					'Warning message',
					'There was a problem deleting your list. Please try again.',
				);
				ts.ui.Notification.warning(warningMessage);
			}
		}
	},
};

export default RemoveListAsideComponent;
