function realCard() {
	return {
		restict: 'E',
		scope: {
			card: '=',
		},
		template: require('./realCard.html'),
	};
}

module.exports = realCard;
