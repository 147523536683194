const SvgAddNewCompany = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M5 8a5 5 0 1 0 5 5 5 5 0 0 0-5-5m0 8.8A3.8 3.8 0 1 1 8.8 13 3.8 3.8 0 0 1 5 16.8m13-2V16h-7.58a6.1 6.1 0 0 0 .78-3 6.3 6.3 0 0 0-.33-2h1.43v3.8h2.5V1.2H5.2v5.61H5a6.6 6.6 0 0 0-1 .08V0h12v14.8ZM9 4H7V2.79h2ZM7 5.3h2v1.2H7ZM13 4h-2V2.79h2Zm-2 1.3h2v1.2h-2Zm0 2.51h2V9h-2Zm-5.5 4.52h1.83v1.2H5.5v1.84H4.3v-1.84H2.47v-1.2H4.3V10.5h1.2Z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

export default SvgAddNewCompany;
