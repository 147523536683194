/* @ngInject */
function ReceiptService(HttpService) {
	return {
		getReceiptInfo: settlementId =>
			HttpService.tradeshiftGo().path(`/rest/payments/settlements/${settlementId}/receipt`).get(),
		removeReceipt: settlementId =>
			HttpService.tradeshiftGo().path(`/rest/payments/settlements/${settlementId}/receipt`).delete(),
	};
}

module.exports = ReceiptService;
