const _ = require('lodash');

/* @ngInject */
function ApprovalService(HttpService) {
	return {
		updateApprovalItems: (oldApprovalItems, newApprovals, teamId, userId) => {
			return _.chain(newApprovals)
				.filter(approval => approval.getTeamId() === teamId)
				.filter(approval => (userId ? approval.isUserAssigned(userId) : true))
				.map(function (approval) {
					return {
						approval,
					};
				})
				.union(oldApprovalItems)
				.uniqBy(function (approvalItem) {
					return approvalItem.approval.getRequestId();
				})
				.sortBy(function (approvalItem) {
					return approvalItem.approval.getAssignedOn();
				})
				.reverse()
				.value();
		},
		getApprovals: state => {
			return HttpService.tradeshiftGo().path('/rest/approvals').params({ state }).get();
		},
		closeStuckPendingApproval: stuckPendingApprovalId => {
			return HttpService.tradeshiftGo().path(`/rest/requests/${stuckPendingApprovalId}/close`).post();
		},
	};
}

module.exports = ApprovalService;
