const _ = require('lodash');

/* @ngInject */
function RequestListBuilder(constants, RequestList) {
	return {
		getRequestLists: function () {
			const requestLists = {};
			_.map(constants.requestList.keys, function (key) {
				const options = _.defaults({}, constants.requestList.options[key]);
				requestLists[key] = new RequestList(key, options);
			});
			return requestLists;
		},
	};
}

module.exports = RequestListBuilder;
