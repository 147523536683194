const i18n = require('@tradeshift/i18n').default;

const i18nService = () => {
	const service = {};

	service.importLocale = async locale => {
		await i18n.importLocale(locale);
		return i18n;
	};

	service.formatMonetaryAmount = (amount, currency) => {
		return i18n.numbers.formatMonetaryAmount(amount, currency);
	};

	service.getCurrencySymbol = currency => {
		return i18n.currencies.getCurrencySymbol(currency);
	};

	service.formatNumberShortDecimal = number => {
		return i18n.numbers.formatNumberShortDecimal(number);
	};

	return service;
};

module.exports = i18nService;
