const _ = require('lodash');
const Sentry = require('@sentry/browser');

/* @ngInject */
function ApprovalController(
	$scope,
	_approval,
	ApprovalLists,
	AsideService,
	ConfigService,
	HeaderService,
	LexicoService,
	RequestService,
) {
	let header;

	$scope.lexico = LexicoService.getLexico();

	const APPROVAL_ACTIONS = [
		{
			id: 'forward',
			isVisible: approval => approval.isReassignable(),
			label: $scope.lexico.trc('Button', 'Forward request'),
			onclick: () => {
				openForwardRequestAside($scope.approval);
				$scope.$digest();
			},
			type: 'ts-secondary',
		},
		{
			id: 'approve',
			isVisible: approval => approval.isActionable() && !approval.isApprovalLimitExceeded(),
			label: $scope.lexico.trc('Button', 'Create card'),
			onclick: () => {
				openCreateCardAside($scope.approval);
				$scope.$digest();
			},
			type: 'ts-primary',
		},
		{
			id: 'decline',
			isVisible: approval => {
				return approval.isActionable();
			},
			label: $scope.lexico.trc('Button', 'Decline'),
			onclick() {
				openConfirmDeclineAside($scope.approval);
				$scope.$digest();
			},
			type: 'ts-tertiary',
		},
	];

	$scope.componentAsides = {
		forwardRequest: { isOpen: false },
	};

	$scope.onAsideClosed = key => {
		$scope.componentAsides[key].isOpen = false;
	};

	$scope.onRequestForwarded = () => {
		$scope.componentAsides.forwardRequest.isOpen = false;
		header.buttons(getHeaderButtons($scope.approval));
	};

	function getHeaderButtons(approval) {
		return _.filter(APPROVAL_ACTIONS, action => action.isVisible(approval));
	}
	function openConfirmDeclineAside(approval) {
		AsideService.open('confirmAction', {
			model: {
				message: $scope.lexico.trc(
					'Decline action message',
					'The request will be declined and the requester will be notified. Would you like to proceed?',
				),
			},
			onSuccess: () => {
				const button = header.buttons().get('decline');
				button.busy();
				const request = approval.getRequest();
				RequestService.reject({
					taskId: approval.getId(),
					requestId: request.getId(),
				})
					.then(() => {
						ts.ui.Notification.success('Request was declined.');
						ApprovalLists.completeApproval(approval.getId(), ConfigService.get('TASK_OUTCOMES').rejected);
						header.buttons(getHeaderButtons(approval));
					})
					.catch(() => {
						const warningMessage = $scope.lexico.trc(
							'Warning message',
							'There was a problem declining the request. Please try again.',
						);
						ts.ui.Notification.warning(warningMessage);
					})
					.finally(() => {
						button.done();
					});
			},
		});
	}
	function openCreateCardAside(approval) {
		AsideService.open('createCardV2', {
			model: {
				approval,
			},
			onSuccess: () => {
				header.buttons(getHeaderButtons(approval));
			},
		});
	}
	function openForwardRequestAside(approval) {
		$scope.$evalAsync(scope => {
			scope.componentAsides.forwardRequest.approval = approval;
			scope.componentAsides.forwardRequest.isOpen = true;
		});
	}

	$scope.$on('$destroy', () => {
		HeaderService.reset(header);
	});

	(function init() {
		$scope.approval = _approval;
		$scope.asides = AsideService.get(['confirmAction', 'createCardV2']);
		$scope.request = $scope.approval.getRequest();
		header = HeaderService.create({
			buttons: getHeaderButtons($scope.approval),
			title: 'Conversation',
			type: 'lite',
		});
	})();
}

ApprovalController.resolve = {
	_approval: /* @ngInject */ ($q, $transition$, _currentTeam, ApprovalRegistry, TeamsService) =>
		(async () => {
			const approval = ApprovalRegistry.getApproval($transition$.params().approvalId);
			if (_.isEmpty(approval)) {
				// return $q.reject({ redirectTo: 'main.approvals' });
				return $q.resolve({});
			}
			if (approval.getTeamId() !== _.get(_currentTeam, 'id')) {
				const teamId = approval.getTeamId();
				if (teamId === undefined) {
					Sentry.captureMessage(`ApprovalController.resolve setCurrentTeamById(${teamId})`, { level: 'info' });
				}
				await TeamsService.setCurrentTeamById(approval.getTeamId());
			}
			return approval;
		})(),
	_currentTeam: /* @ngInject */ TeamsService => TeamsService.getCurrentTeam(),
};

module.exports = ApprovalController;
