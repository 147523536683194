"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiAlertOverrides = void 0;
function getWarningStyle(theme) {
    return {
        border: theme.palette.tsGoBorder.dottedBorder,
    };
}
function getTSNoteStyle(theme) {
    return {
        color: theme.palette.tsGoColor.white,
        backgroundColor: theme.palette.tsGoColor.pinkDark,
    };
}
function getNotificationStyle(theme) {
    return {
        width: 300,
        marginRight: '10px',
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: theme.palette.tsGoColor.white,
    };
}
exports.muiAlertOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            ...(ownerState.severity === 'warning' && getWarningStyle(theme)),
            ...(ownerState['ts-variant'] === 'purple-note' && getTSNoteStyle(theme)),
            ...(ownerState['ts-variant'] === 'notification' && getNotificationStyle(theme)),
            border: theme.palette.tsGoBorder.noBorder,
        }),
    },
};
