const _ = require('lodash');

/* @ngInject */
function ManageHeaderHelper($q, $state, HeaderService, UserService) {
	const ALL_TABS = [
		{
			sref: 'main.manage.coding',
			label: 'Coding',
			isVisible: UserService.isAdminOrFinance,
		},
		{
			sref: 'main.manage.payments',
			label: 'Payments',
			isVisible: () => $q.resolve(true),
		},
		{
			sref: 'main.manage.users',
			label: 'Users',
			isVisible: UserService.isAdminOrFinance,
		},
		{
			sref: 'main.manage.report',
			label: 'Reports',
			isVisible: () => $q.resolve(true),
		},
		{
			sref: 'main.manage.settings',
			label: 'Settings',
			isVisible: transitionToName => $q.resolve(transitionToName === 'main.manage.settings'),
		},
	];

	const helper = {};
	const header = {};
	let tabs;
	let linksEnabled;

	header.create = () => {
		linksEnabled = false;
		HeaderService.create({
			tabs,
			title: 'Manage',
			type: 'lite',
			buttons: [],
		});
	};

	helper.getManageHeader = transitionToName => {
		return $q
			.all(
				_.map(ALL_TABS, tab => {
					return tab.isVisible(transitionToName).then(isVisible => {
						return {
							id: tab.sref,
							isVisible,
							label: tab.label,
							onselect: () => {
								// avoid triggering $state.go on page load
								if (linksEnabled) {
									$state.go(tab.sref);
								}
							},
						};
					});
				}),
			)
			.then(allTabs => _.filter(allTabs, 'isVisible'))
			.then(visibleTabs => {
				tabs = visibleTabs;
				return header;
			});
	};

	header.selectTab = ({ selectedTab }) => {
		const tabIndex = _.findIndex(tabs, ({ id }) => `main.manage.${selectedTab}` === id);
		HeaderService.selectTab({ tabIndex });
		linksEnabled = true;
	};

	return helper;
}

module.exports = ManageHeaderHelper;
