/* @ngInject */
function ErrorController($scope, _content, HeaderService) {
	HeaderService.hideUnderMain();
	$scope.heading = _content.heading;
	$scope.message = _content.message;
}

ErrorController.resolve = {
	lexico: /* @ngInject */ LexicoService => LexicoService.getLexico(),
	_content: /* @ngInject */ ($transition$, lexico) => {
		// if (window.location.pathname.includes('signin')) {
		// 	$transition$.preventDefault();
		// 	return null;
		// }

		const HEADING_DEFAULT = lexico.trc('Error message', 'Something is not right');
		const MESSAGE_DEFAULT = lexico.trc(
			'Try again error message',
			'The action you’re trying to perform cannot be completed. In some cases simply trying again helps.',
		);
		const HEADING_NOT_FOUND = lexico.trc('No page found message', 'Page does not exist');
		const MESSAGE_NOT_FOUND = lexico.trc(
			'No page found message',
			'You tried to access a page that does not exist. Please make sure you have the correct link.',
		);

		const error = $transition$.params().error || {};
		if (error.statusCode === 404) {
			const heading = error.heading || HEADING_NOT_FOUND;
			const message = error.message || MESSAGE_NOT_FOUND;
			return { heading, message };
		}
		const heading = error.heading || HEADING_DEFAULT;
		// using the default message for now since error.message is too technical to display to the user
		const message = MESSAGE_DEFAULT;
		return { heading, message };
	},
};

module.exports = ErrorController;
