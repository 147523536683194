"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCodingPageState = exports.useAsideState = exports.useCompanyState = exports.useCodingEntryState = exports.useCodingListState = void 0;
const zustandStore_1 = require("../../../../../zustandStore");
const useCodingListState = () => {
    const { currentCodingListSelected, selectedCodingEntries } = (0, zustandStore_1.useZustand)((state) => state.codingList);
    return { currentCodingListSelected, selectedCodingEntries };
};
exports.useCodingListState = useCodingListState;
const useCodingEntryState = () => {
    const { currentSelectedCodingEntry, currentCodingEntryTeams } = (0, zustandStore_1.useZustand)((state) => state.codingEntry);
    return { currentSelectedCodingEntry, currentCodingEntryTeams };
};
exports.useCodingEntryState = useCodingEntryState;
const useCompanyState = () => {
    const { company } = (0, zustandStore_1.useZustand)((state) => state);
    return { company };
};
exports.useCompanyState = useCompanyState;
const useAsideState = () => {
    const { removeCodingEntryAside: { isOpen: isRemoveCodingEntryAsideOpen }, renameCustomCodingList: { isOpen: isRenameCustomCodingListAsideOpen }, customCodingList: { isOpen: isCustomCodingListAsideOpen }, createCodingList: { isOpen: isCreateCodingListAsideOpen }, codingListSelectorAside: { isOpen: isOpenCodingListSelectorAside, isNewEntryAsideOpen }, } = (0, zustandStore_1.useZustand)((state) => state.aside);
    return {
        isRemoveCodingEntryAsideOpen,
        isRenameCustomCodingListAsideOpen,
        isOpenCodingListSelectorAside,
        isNewEntryAsideOpen,
        isCustomCodingListAsideOpen,
        isCreateCodingListAsideOpen,
    };
};
exports.useAsideState = useAsideState;
const useCodingPageState = () => {
    const { setFooterButtons, setCurrentCodingListSelected, setIsCodingListSelectorAsideOpen, setNewCodingEntryTeams, updateSelectedCodingEntryTeamByTeamId, setIsRemoveCodingEntryAsideOpen, setNotification, setIsCreateCodinglistAsideOpen, setIsCustomCodingListAsideOpen, setIsRenameCustomCodingListAsideOpen, setIsRemoveCodingListAsideOpen, setCurrentCodingListSelectedName, } = (0, zustandStore_1.useZustand)();
    const { currentCodingListSelected, selectedCodingEntries } = (0, exports.useCodingListState)();
    const { currentSelectedCodingEntry, currentCodingEntryTeams } = (0, exports.useCodingEntryState)();
    const { company } = (0, exports.useCompanyState)();
    const { isRemoveCodingEntryAsideOpen, isRenameCustomCodingListAsideOpen, isOpenCodingListSelectorAside, isNewEntryAsideOpen, isCustomCodingListAsideOpen, isCreateCodingListAsideOpen, } = (0, exports.useAsideState)();
    return {
        setFooterButtons,
        setCurrentCodingListSelected,
        setIsCodingListSelectorAsideOpen,
        setNewCodingEntryTeams,
        updateSelectedCodingEntryTeamByTeamId,
        setIsRemoveCodingEntryAsideOpen,
        setNotification,
        setIsCreateCodinglistAsideOpen,
        setIsCustomCodingListAsideOpen,
        setIsRenameCustomCodingListAsideOpen,
        setIsRemoveCodingListAsideOpen,
        setCurrentCodingListSelectedName,
        currentCodingListSelected,
        isOpenCodingListSelectorAside,
        isNewEntryAsideOpen,
        currentSelectedCodingEntry,
        company,
        selectedCodingEntries,
        currentCodingEntryTeams,
        isRemoveCodingEntryAsideOpen,
        isRenameCustomCodingListAsideOpen,
        isCustomCodingListAsideOpen,
        isCreateCodingListAsideOpen,
    };
};
exports.useCodingPageState = useCodingPageState;
