"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledText = void 0;
const material_1 = require("@mui/material");
exports.StyledText = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    position: 'relative',
    textAlign: 'center',
    zIndex: '1',
    display: 'inline-block',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: theme?.palette.tsGoColor.grayLightest,
    paddingLeft: theme?.tsGoUnit.tsUnitHalf,
    paddingRight: theme?.tsGoUnit.tsUnitHalf,
    color: theme?.palette.tsGoColor.grayDark,
}));
