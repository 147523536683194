"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArticleSection = void 0;
const material_1 = require("@mui/material");
exports.ArticleSection = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    h3: {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.big),
        fontWeight: `${theme.typography.tsFontWeight.bold} !important`,
        marginBottom: theme.tsGoUnit.tsUnit,
    },
    section: {
        h3: {
            fontWeight: `${theme.typography.tsFontWeight.bold} !important`,
            fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.medium),
            marginBottom: theme.tsGoUnit.tsUnitHalf,
        },
        ul: {
            paddingTop: 0,
            li: {
                marginLeft: theme.tsGoUnit.tsUnitDouble,
                paddingBottom: '4px',
            },
        },
    },
}));
