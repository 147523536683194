"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiInputBaseOverrides = void 0;
function getSearchInputCss() {
    return {
        '&:before, &:after': {
            border: 'none !important',
        },
    };
}
exports.muiInputBaseOverrides = {
    defaultProps: {
        autoComplete: 'off',
    },
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            boxSizing: 'content-box !important',
            width: '100%',
            fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
            color: theme.palette.tsGoColor.black,
            ...(ownerState['ts-variant'] === 'searchTeamNameInput' && getSearchInputCss()),
        }),
    },
};
