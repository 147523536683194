const Sentry = require('@sentry/browser');

const TeamSwitcherAsideComponent = {
	bindings: {
		isOpen: '<',
		onClose: '&',
		refreshTeams: '&',
		teams: '<',
		currentTeam: '=',
		user: '<',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.teamSwitcherText}}">
			<menu data-ts="Menu">
				<ts-menu-button
						label="team.name"
						ng-class="{ 'ts-checked': $ctrl.currentTeam.id == team.id  }"
						ng-repeat="team in $ctrl.teams"
						on-click="$ctrl.selectTeam(team)"></ts-menu-button>
			</menu>
		</ts-aside>
	`,
	controller: /* @ngInject */ function ($state, $transitions, constants, LexicoService, TeamsService) {
		/* @ngInject */
		this.teamProperties = constants.teamProperties;
		this.isTransitioning = false;
		this.lexico = LexicoService.getLexico();
		this.teamSwitcherText = this.lexico.trc('Component title', 'Team switcher');

		$transitions.onStart({}, () => {
			this.isTransitioning = true;
		});

		$transitions.onFinish({}, () => {
			this.isTransitioning = false;
		});

		this.canViewCurrentPage = async ({ id: teamId }) => {
			let page;

			if ($state.$current.includes['main.approvals']) {
				page = 'approvals';
			} else if ($state.$current.includes['main.team-manageSettlements']) {
				page = 'settlements';
			} else if ($state.$current.includes['main.teamSettings']) {
				page = 'team_settings';
			} else if ($state.$current.includes['main.approverDashboard']) {
				page = 'dashboard';
			}

			const hasViewAccess = page ? await TeamsService.verifyViewAccess({ teamId, page }) : false;

			if (!hasViewAccess && !this.isTransitioning) {
				$state.go('main.wallet');
			}
		};

		this.selectTeam = async team => {
			this.onClose();
			try {
				if (team.id === undefined) {
					Sentry.captureMessage(`selectTeam setCurrentTeamById(${team.id})`, { level: 'info' });
				}
				await TeamsService.setCurrentTeamById(team.id);
				this.currentTeam = team;
				this.canViewCurrentPage(team);
			} catch (error) {
				const errorMessage = this.lexico.trc(
					'Error message',
					"We're unable to switch team at this time. Please try again later.",
				);
				ts.ui.Notification.error(errorMessage);
			}
		};
	},
};

export default TeamSwitcherAsideComponent;
