/* @ngInject */
function VirtualCardAddedController($scope, UserService, VirtualCard) {
	(function init() {
		$scope.card = new VirtualCard($scope.event.data.task);
		UserService.getUser().then(function (user) {
			if ($scope.card.isUserAssigned(user.userId)) {
				$scope.isMyCard = true;
				return $scope.card.getVirtualCard();
			}
		});
	})();
}

module.exports = VirtualCardAddedController;
