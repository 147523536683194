const TSNoteComponent = {
	bindings: {
		icon: '<',
		message: '<?',
	},
	transclude: true,
	template: `
		<div data-ts="Note">
			<i class="{{$ctrl.icon}}"></i>
			<p ng-if="$ctrl.message" ng-bind="$ctrl.message"></p>
			<div ng-if="!$ctrl.message" ng-transclude></div>
		</div>
	`,
	controller: class TSNoteController {},
};

export default TSNoteComponent;
