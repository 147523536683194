"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columns = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const walletDetails_1 = require("../../wallet/component/walletDetails");
const columns = (isAmexCardProvider) => [
    {
        id: 'date',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, flex: 1, children: (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "Date" }) }));
        },
        width: '8%',
    },
    {
        id: 'amount',
        ui() {
            return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { sx: { textAlign: 'right' }, children: "Amount" });
        },
        width: '10%',
    },
    {
        id: 'details',
        ui() {
            return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "Details" });
        },
        width: '20%',
    },
    {
        id: 'user',
        ui() {
            return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "User" });
        },
        width: '15%',
    },
    {
        id: 'coding',
        ui() {
            return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "Coding" });
        },
        width: '20%',
    },
    ...(isAmexCardProvider
        ? [
            {
                id: 'receipt',
                ui() {
                    return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "Receipt" });
                },
                width: '14%',
            },
        ]
        : []),
    {
        id: 'Actions',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, direction: "row", flex: 0.5, justifyContent: "flex-start", alignItems: "flex-end", children: (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: "Actions" }) }));
        },
        width: '12%',
    },
];
exports.columns = columns;
