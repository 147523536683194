const _ = require('lodash');

const AddApproverAsideComponent = {
	bindings: {
		isOpen: '<',
		onApproverAdded: '&',
		onClose: '&',
		teamId: '<',
	},
	template: `
		<go-select-aside
				empty-message="$ctrl.noOwnersOrTeamManagersText"
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose($event)"
				on-select="$ctrl.onSelect($event)"
				options="$ctrl.approvers"
				title="'Select approver'"></go-select-aside>
	`,
	controller: /* @ngInject */ function (EventEmitter, LexicoService, UserService) {
		this.lexico = LexicoService.getLexico();
		this.noOwnersOrTeamManagersText = this.lexico.trc(
			'Empty list message',
			'There are no team owners or managers to be selected.',
		);

		this.getAdminsAndManagers = users => {
			const admins = UserService.filterUsersByRole({ role: 'teamAdmin', users });
			const managers = UserService.filterUsersByRole({ role: 'manager', users });
			return _.uniqBy([...admins, ...managers], 'userId').map(approver => {
				approver.label = _.escape(approver.fullName);
				return approver;
			});
		};

		this.onOpen = async () => {
			this.isLoading = true;
			const { users } = await UserService.getUsers({ teamId: this.teamId });
			this.approvers = this.getAdminsAndManagers(users);
			this.isLoading = false;
		};

		this.$onChanges = changes => {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}
		};

		this.onSelect = ({ option }) => this.onApproverAdded(EventEmitter({ approver: option }));
	},
};

export default AddApproverAsideComponent;
