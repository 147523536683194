const _ = require('lodash');

/* @ngInject */
function RequestListFactory($q, constants, RequestService) {
	function RequestList(key, options) {
		this._key = key;
		this._items = [];
		this._itemsByTeam = [];
		this._options = _.defaults(options || {}, constants.requestList.options.defaults);
	}

	RequestList.prototype._appendItems = function (items) {
		Array.prototype.push.apply(this._items, items);
		this._itemCount = this._items.length;
	};
	RequestList.prototype._filterLoadedItems = function (items) {
		const loadedKeys = _.map(this._items, function (item) {
			return item.getId();
		});
		return _.filter(items, function (item) {
			return !_.includes(loadedKeys, item.getId());
		});
	};
	RequestList.prototype._getItems = function () {
		return this._items;
	};
	RequestList.prototype._getLoadedCount = function () {
		return _.size(this._items);
	};
	RequestList.prototype._hasItems = function () {
		return this.getTotalCount() > 0;
	};
	RequestList.prototype._isItemInList = function (itemId) {
		return _.some(this._items, function (item) {
			return item.getId() === itemId;
		});
	};
	RequestList.prototype._removeItem = function (itemId) {
		this._itemCount--;
		return _.remove(this._items, item => {
			return item.getId() === itemId;
		});
	};
	RequestList.prototype._setTotalCount = function (totalCount) {
		this._itemCount = totalCount;
	};
	RequestList.prototype._updatePage = function () {
		this._options.page = Math.floor(this._getLoadedCount() / this._options.limit);
	};

	RequestList.prototype.getTotalCount = function () {
		return this._itemCount || 0;
	};
	RequestList.prototype.getTotalCountForTeam = function (teamId) {
		if (this._itemsByTeam[teamId] === undefined) {
			return 0;
		}
		return _.size(this._itemsByTeam[teamId]);
	};
	RequestList.prototype.hasMore = function () {
		return _.isUndefined(this._itemCount) || this._itemCount > this._getLoadedCount();
	};
	RequestList.prototype.load = function () {
		return this._getLoadedCount() === 0 && this.hasMore() ? this._fetchItems() : $q;
	};
	RequestList.prototype.loadMore = function () {
		this._updatePage();
		return this._fetchItems();
	};
	// TODO: above was ItemList - _.smash() with below

	RequestList.prototype._fetchItems = function () {
		const that = this;
		return RequestService.getRequests(that._options).then(function (requests) {
			that._itemCount = _.size(requests) || 0;
			return requests;
		});
	};

	RequestList.prototype.addNewRequest = function (request) {
		this.addRequest(request, true);
	};
	RequestList.prototype.addRequest = function (request, doUnshift) {
		const requestId = request.getId();
		if (!this._isItemInList(requestId)) {
			const teamId = request.getTeamId();
			if (teamId && this._itemsByTeam[teamId] === undefined) {
				this._itemsByTeam[teamId] = [];
			}
			if (doUnshift) {
				this._items.unshift(request);
				teamId && this._itemsByTeam[teamId].unshift(request);
				this._itemCount++;
			} else {
				this._items.push(request);
				teamId && this._itemsByTeam[teamId].push(request);
			}
		}
	};
	RequestList.prototype.getKey = function () {
		return this._key;
	};
	RequestList.prototype._getLabel = function (count) {
		// NO SONAR
		switch (this.getKey()) {
			case constants.requestList.keys.active:
				return ['Requests (', count, ')'].join('');
			case constants.requestList.keys.archive:
				return ['Archived (', count, ')'].join('');
			default:
				return ['Requests (', count, ')'].join('');
		}
	};
	RequestList.prototype.getLabel = function () {
		const count = this.getTotalCount();
		return this._getLabel(count);
	};
	RequestList.prototype.getLabelForTeam = function (teamId) {
		const count = this.getTotalCountForTeam(teamId);
		return this._getLabel(count);
	};
	RequestList.prototype.getRequests = function () {
		return this._getItems();
	};
	RequestList.prototype.hasRequests = function () {
		return this._hasItems();
	};
	RequestList.prototype.getRequestsForTeam = function (teamId) {
		// NO SONAR
		if (this._itemsByTeam[teamId] === undefined) {
			this._itemsByTeam[teamId] = [];
		}
		return this._itemsByTeam[teamId];
	};
	RequestList.prototype.hasRequestsForTeam = function (teamId) {
		if (this._itemsByTeam[teamId] === undefined) {
			return false;
		}
		return this._itemsByTeam[teamId].size > 0;
	};
	RequestList.prototype.removeRequest = function (requestId) {
		if (!this._isItemInList(requestId)) {
			return;
		}
		const [removedItem] = this._removeItem(requestId);
		if (removedItem.getTeamId()) {
			_.remove(this._itemsByTeam[removedItem.getTeamId()], item => {
				return item.getId() === requestId;
			});
		}
		return [removedItem];
	};

	return RequestList;
}

module.exports = RequestListFactory;
