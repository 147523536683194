const _ = require('lodash');
const angular = require('angular');

/* @ngInject */
function objectEvent($compile) {
	return {
		restrict: 'E',
		scope: {
			event: '=',
			isAuthor: '=',
		},
		template: require('./objectEvent.html'),
		link: function ($scope, $element) {
			$scope.event.type = _.kebabCase($scope.event.type);
			const elmName = 'event-' + $scope.event.type;
			const template = '<' + elmName + '>';
			const childElement = $compile(template)($scope);
			const eventContainer = angular.element($element[0].querySelector('.js-event-content'));
			eventContainer.append(childElement);
		},
	};
}

module.exports = objectEvent;
