"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const pendo_1 = require("@tradeshift/pendo");
const react_1 = require("react");
const userQuery_1 = require("../../../queries/userQuery");
const configRepository_1 = require("../../../repositories/configRepository");
const Pendo = () => {
    const { data: userData } = (0, userQuery_1.useGetUserQuery)();
    (0, react_1.useEffect)(() => {
        /*
            If we need to use pendo for users that are not logged-in yet (with no user id and company id),
            make sure to use static strings for visitor and account ids,
            otherwise Pendo will assign random uuids and we will get charged more,
            as we are charged per number of visitor ids.
         */
        if (configRepository_1.ConfigRepository.get('IS_PENDO_ENABLED') && userData?.userId && userData?.companyAccountId) {
            pendo_1.pendo.initialize({
                visitor: { id: userData.userId },
                account: { id: userData.companyAccountId },
            });
        }
    }, [userData]);
    return null;
};
exports.default = Pendo;
