"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.teamsPeopleLoader = void 0;
const teamQuery_1 = require("../../../queries/teamQuery");
const userQuery_1 = require("../../../queries/userQuery");
const stopAngularjsLoader_1 = require("../stopAngularjsLoader");
const teamsPeopleLoader = async ({ queryClient }) => {
    const teamIdQuery = (0, teamQuery_1.selectedTeamIdQuery)('selectedTeamID');
    const teamId = await queryClient.ensureQueryData(teamIdQuery);
    const user = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const userData = await queryClient.ensureQueryData((0, teamQuery_1.getTeamUsersQuery)({ teamId: teamId?.[0] || '' }));
    (0, stopAngularjsLoader_1.stopAngularJsLoader)();
    return {
        teamId,
        user,
        userData,
        team: await queryClient.ensureQueryData((0, teamQuery_1.teamQuery)({ teamId: teamId?.[0] || '' })),
    };
};
exports.teamsPeopleLoader = teamsPeopleLoader;
