module.exports = {
"d447f4a1755a0025dddd2f736255879f":"nº de transações",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minuto",
"ce09c436b49af331a32f1152ede7dd18":"1. Solicitar fundos",
"d2226752d3065fbad4744924b4a0ee73":"2. Revisar e aprovar",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Revisar e aprovar",
"82bab92f1c8030888550b51521266c53":"3. Pagar com cartão virtual",
"2ecd62e02e507f8e839a55eac0a04886":"Uma entrada de codificação com ID '{entryId}' já existe.",
"5dfd25849740fbce8147838b0d5094eb":"Um apelido amigável, por exemplo Cartão de Marketing",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Um membro da nossa equipe de sucesso do cliente entrará em contato com você em breve.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Uma pessoa que não conseguimos analisar",
"976b50e70e4433e2786233979d243445":"Já existe uma equipe com esse nome.",
"35b61d21ac11d5fc31ebe58541263445":"Aceite seu convite para participar",
"a372cb2d4204654f52cd9170fb10d374":"Códigos contábeis",
"110fc66010b6b91cebee7a59a2067aab":"Códigos contábeis",
"db862bb04dbdc6d04e47063f33be8865":"Os códigos contábeis são gerenciados por meio de listas de codificação e entradas em listas.",
"d6e3848e824f077396be4351eb58edd2":"Ação necessária – aprovar/rejeitar nova solicitação de {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Ações",
"476e728ea824229f52438e543818bcdd":"Ações",
"3117746cb7d4ab815ec98274ec4398d5":"Ações",
"3ab09b66a7f16219d8135977a2a76d10":"Ações",
"ffafa66eaab0409a2e90aaeb43796def":"Ativa",
"0447e0aa5d00dbdab11b5546793c4479":"Ativa",
"8ee51d21b05466f7d962d4ff5a0b384e":"Cartões ativos",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Cartões virtuais ativos",
"89c6ec05861aa914304ba35bc6744124":"Cartões ativos",
"5280659bcecaf8344c57790f9962a9a2":"Atividade",
"8a15368ae286fa64e1cb7624cd410fc6":"Adicionar lista de codificação",
"8ded7e09e86be231b1165308329ac76d":"Adicionar lista",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Adicionar administrador",
"695bb4bc29e2df769ddf7427ab71012a":"Adicionar aprovador",
"4a1d4516cab00b7c5ecdb150e346e5db":"Adicionar aprovador",
"56869851b27c2915f5a7fe6a33b91ac7":"Adicionar lista de codificação",
"8f8691057c3994aa61f9994202680c87":"Adicionar lista de codificação",
"69f7a406b79d19bf8082ed245ab29123":"Adicionar lista de codificação",
"48a92ca1cc63018707be99b13196369f":"Adicionar administrador da empresa",
"a679db9ff80d0e5769ae1ba311c7916f":"Adicionar domínio",
"8f5e8078be77146ba7f3be49ef948906":"Adicionar entrada",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Adicionar mensagem",
"f8a4b14162e19995074e63135b65c84d":"Adicionar novo cartão",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Adicionar pessoas",
"c670549d0b898bb6a571eb2cd7804a6e":"Adicionar recibo",
"eb937098e77d8a6b0565ed72357dcaae":"Adicionar recibo",
"1e154f31c31b63a459f3956e380b3ef9":"Adicionar selecionado",
"ba24db9956f156a18b32e653552ff682":"Adicione algumas pessoas a esta equipe",
"52bd93fb5489b25167bb541f7c6504ae":"Adicionar cartão de origem",
"21ab3f08eb507dbc76df166eafeb393a":"{fullName} foi adicionado como aprovador!",
"f879c06507d3076bbf52ea4fd2df850e":"Adicionar detalhes do cartão",
"21e934ae30eece4a894337701f497638":"Todas",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Todos os cartões",
"8fac71195e2b506e029752e4864b6a5d":"Todos os membros",
"f9708417df16227742e0545a8642eea7":"Todos os usuários disponíveis já foram adicionados a este cartão.",
"cb5abf070f8cba7982205038baab77bb":"Todos os cartões",
"dae7d602eec2c84dea0d80ae25e56e6d":"Todos os cartões",
"0b4bdfde6578668b6018d3615a1ad30f":"Todos os centros de custo",
"97b875b81f54b4432d998a3e47d949cc":"Todas as equipes",
"f0ae83969f7c20a1964caf5156dae966":"Todas as equipes",
"d87455601a00282078582b36a3825b7d":"Todas as equipes",
"24c7b7f55882ea94a525392ca8218fa0":"Todas as equipes",
"8f860e35b64a8f447bded1e5ea66a2a8":"Todos os cartões virtuais",
"e403956ecaa6fb33ec1fef5c49d71042":"Todos os cartões virtuais",
"4e6f4e8f25f61d40e1eba77de94f6458":"Todas as suas informações financeiras são criptografadas e em servidores em locais seguros.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Valor",
"a4d099d0dbe3889b0995546e6ed28932":"Valor",
"6c0ef32aae76bc31a61e2124f542bdf1":"Valor",
"adec89c93525f1a8d1c64d856f405e27":"Quantidade: decrescente",
"18e632a170203894f40deed1d33ee552":"Quantidade: crescente",
"a7944575cafef9126a499a1b06999fe3":"Ocorreu um erro. Tente novamente mais tarde.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Aplicar",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Opções de aprovação",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Configurações de aprovação",
"492eedd88cdd7bb45f39c6594af748c4":"Limite de aprovação",
"437d490afc8d226f8c7d544f4e35d011":"Limite de aprovação ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Aprovações",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Aprovações",
"17adb7fe866e32055b1469fdb7e82795":"Configurações de aprovações",
"7125aa13db437084e1d27d3ff38f22e2":"Aprovar",
"84df787ff5746f9dc3cf0146becef4fd":"Solicitação de aprovação",
"7a568654d00245b0bab6c90a1b67d196":"Aprovado(a)",
"179c3e187ad8edeb8348cff222b938bf":"Aprovado(a)",
"ad058d17725870df9abbd4f5ec217636":"Aprovado ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Valor aprovado",
"4d58ba5c0e03fec1556c243be106e4a3":"Aprovado por",
"7d3d697fc99f6c4641b326623743f7b7":"Aprovado { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Aprovador",
"8c45e443fc6748190719d79a092e2528":"Os aprovadores têm um limite máximo de aprovação",
"4fe56aa6456c108cd288a4ec8acf435f":"Arquivar",
"a9b6f93de9630064be9809130aa1e195":"Você está pronto para assumir o controle dos gastos com seu cartão?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Tem certeza de que deseja cancelar esta solicitação?",
"301bfc2ce058f49e0704fec3d162b5b5":"Tem certeza de que deseja atribuir novamente este pedido para você?",
"2181faf8d9973d1f4138e401a762086e":"Tem certeza de que deseja remover ${firstName} ${lastName} como administrador da empresa?",
"b982277f29055d0ef6e295fe2006d9e0":"Tem certeza de que deseja remover {firstName} da equipe?",
"456c0ccff14acb808583cd9dece857b6":"Atribuído a",
"bde315b9cf7fd55f4b65aabbbf38d462":"Pelo menos um gerente ou proprietário da equipe deve estar disponível para uso fora do escritório.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Autenticação",
"1961ec69dd0c8b5718c831312e7f3d0f":"Coleta automatizada de recibos",
"e39f2477cf34d726936a5b5c2b48a4ad":"Crie automaticamente relatórios de reconciliação precisos, que incluem informações sobre clientes, aprovadores, recibos, códigos de conta e muito mais.",
"434ca07dfde0eaee14f094b7c75a6eae":"Disponível",
"eb85d8f50f2c89871b794f7d54279b22":"Saldo disponível",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Disponível { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Voltar",
"c8258f8cf9e20f6bbb89d957de40e820":"Segurança de nível bancário",
"d46c22a3f36dce568c07fcea4368b231":"Configurações básicas",
"fb481c8e517016094b4fdf618c5fa088":"Endereço de cobrança",
"79a9f821923c5a04c076902fef684281":"Endereço de cobrança",
"877a08b8347052c83aa0b689baea81e7":"Endereço de cobrança",
"97f018458e2627fac37d24846c7b8922":"Ao criar sua conta e usar o Tradeshift Go, você concorda com nossa",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Ao criar sua conta e usar o Tradeshift Go, você concorda com nossa",
"912d1864f0068c764bf4784020795d49":"NOME DO TITULAR",
"d53474c7fd99fbeb69481ce9d358aa09":"Pode solicitar cartões e ter uma carteira para todos os cartões. Cada membro obtém um aprovador padrão (gerente) para suas solicitações.",
"f30df2cab105ed3f8b4264aeb9800abd":"Pode aprovar solicitações e criar cartões para os membros que eles gerenciam. Os gerentes têm um painel para rastrear os gastos dos membros que gerenciam.",
"03ed9eccc71ef59165292eac542d3050":"Pode visualizar todas as solicitações e gastar atividades na equipe. Os proprietários de equipe também recebem um painel da equipe para rastrear os gastos e a carteira da equipe, onde podem ver todos os cartões ativos.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Não é possível remover este usuário. É necessário indicar pelo menos um proprietário ou gerente de uma equipe.",
"a0b70642629a55ea0ab6fa3414d342e0":"Não é possível remover o usuário com solicitações pendentes.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Cancelar",
"5446624d3c364ac05ddf8d6663da2796":"Cancelar cartão",
"53f3815c4fa3340b29efd81e627d1e6b":"Cancelar cartão",
"12257ea2a871b7fab72245b5aafe6424":"Cancelar solicitação",
"2aafb7eb4d56a516c4bb0754f0812df1":"Cancelar cartão",
"caae12601b01e2325161e95107f9e332":"Cancelar cartão",
"6ad1e57b1e4c7accbe166c948883077d":"Cancele com um clique, caso comprometido",
"f6527e49e6d5c989624507a583e23f39":"Cancelado ({count})",
"1ad3bf5121641dda511f42942af05a90":"O cancelamento deste cartão não reverterá as cobranças pendentes. Procure pela liquidação",
"aebd3826a195afad0a93050a7cc5d73d":"O cancelamento deste cartão não reverterá as cobranças pendentes. Procure os registros de liquidação dessas cobranças no extrato do cartão de crédito enviado pelo fornecedor do cartão.",
"a5b5136947da562f91b75daf8156f50e":"Detalhes do cartão",
"9ed31ad23a1578ce480d31207decf1d3":"Configurações do cartão",
"def15f2e007fc69886230fdb2511305c":"Detalhes do cartão",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Detalhes do cartão",
"67cfd260078ca32027c2fa4a4a2d29f4":"Titular do cartão",
"4458819ea60c68c46e551a8f5cc163cd":"Nome do cartão",
"0858bcbd506fa3240b165e59d2f18aae":"Nome do cartão",
"5212736dd18ee2c31f3650ad2c2c7130":"Nome do cartão atualizado.",
"c96d62fa510c3356dc73a91d1a80e262":"Número do cartão",
"41307fee4c32aefb5383a98fe3d6e45e":"Tipo de cartão",
"3f8045c3358621dad4346a44de0c05a8":"Nome do titular",
"879a6b452033cf5f3a4bb3e1837a0386":"Cidade",
"593334553ef852024e172505ec3c5f21":"Clique aqui",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Codificação",
"df931751d5790d74fa46707aaf7a26ae":"Codificação 1",
"7ac7154bc776aff52a71b0d83b860561":"Codificação 2",
"586fcf19136ae5e26795e9055b2e2995":"Codificação 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Codificação 4",
"379bb8e71bb090badebbb0751de86f11":"Codificação 5",
"3e84d13627b3d090ab2b8470af98340a":"Codificação 6",
"33b94b581f3792049992663396e51cb8":"Codificação 7",
"b73fbc32e439655833ee99126dd0e55f":"Codificação 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Lista de codificação",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Entrada de codificação",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Entrada de codificação excluída.",
"cada62a39ee72d790dcdbb25160f8783":"Entrada de codificação salva.",
"f645bf50888943ee1384693e56a0f475":"Nome da lista de codificação",
"a999182acbcd77c15299f79181b630be":"Lista de codificação salva.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Listas de codificação",
"d0b96596ce6baec5aabb8a0690ab30e1":"Comentários",
"ff9cb8842f832d21fb160b3d3784ce79":"Empresa",
"715b59a64e686eb91a21114644ffe39e":"O administrador da empresa foi removido",
"8758e9a01253c097a42393fda9610715":"Painel da empresa",
"e118b41009918b326db6746856831cbc":"ID da empresa",
"4dba88b05b3fa8875128e19e88c512b3":"Configurações da empresa",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Configurações da empresa",
"f2ea6d33de8182d97bf2f13354af4893":"Configurações da empresa: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Administradores da empresa",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Limite de aprovação da empresa",
"7e2f778da14a726b53e05926a3483f2b":"Cartão de crédito empresarial",
"7313fd1db3d63858586d1c0f89251d6e":"Nome da empresa",
"07721e91dd10ff8831f0d24e47cc1eab":"Nome da empresa",
"203221610ca4d347063c037e1a732ae8":"Configurações da empresa salvas.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Confirmar ação",
"d694cec09cdbc02b1ffe22642a63faa1":"Confirmar senha",
"512801312a133f29974f2259a455f39b":"Confirmar senha",
"f54c38ed0b9869d929ffc0bd5310131f":"Confirmação – Sua conta foi criada em Equipe {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Entre em contato com o administrador da empresa para criar códigos contábeis.",
"7a6a4de077398e8e138aec36e3b3d305":"Continuar com SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Todos os direitos reservados.",
"51f0d8e04f6ee626b866b37833dc2b61":"Centro de custo",
"e043f60a02f157d0f31a42f0db67cc87":"Não foi possível enviar o convite {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Não foi possível enviar sua mensagem, tente novamente.",
"ae28083f22a0b2fc5637614bce0976e0":"Criar lista personalizada",
"738078dc992208e05406f935995dc0c3":"Criar lista personalizada",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Criar nova lista",
"0f5783c3921b3d7398d281644bbe5bde":"Criar uma solicitação",
"6d62f934f2304701f88bdcfbc688de7e":"Criar conta",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Criar uma conta",
"f6b7d0d8531ea268f988005dd2147b2b":"Criar cartão",
"1a083675d1453448e4645403bbe52dd9":"Criar lista personalizada",
"c10ab11bdfb001da74850ed89c45482f":"Criar nova solicitação",
"43a9c06398408bfe77d06ffc2c1c3b87":"Criar nova solicitação",
"68bf17fd3f54a89c0a84224e2f9a971c":"Criar nova equipe",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Criar equipe",
"3b8e665101b9b3657f8a68c5305947b7":"Criado",
"40e91f2907181ffa8e62567b3a407e60":"Criado por",
"8929f7aacb969ef6353aba9903eb362d":"Cartão de crédito adicionado.",
"d86688c26e8ac5eb9facc971b6fe5155":"Moeda",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Moeda",
"500ded53f64e737a5e4337675ac9b7ce":"Nome da lista personalizada",
"b79a95f04fd829fb2e8d0313e522a94d":"Diariamente",
"ef445eab224fc599e4103c9b1745a4e0":"Painel de controle",
"788452c3ad2b1450b6d8d116b863e635":"Painel de controle",
"fb7add662cf7042355319a975fab6ae3":"Criptografia de dados",
"6bded249dbfafd14490eea8dd37e1f51":"Data",
"46e908a57cb09ef947af8c4a93a09716":"Data",
"e4213104a55eceb60e00408699438dcd":"Desativar equipe",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Desativar uma equipe cancelará todos os cartões virtuais ativos e nenhum dos membros, gerentes ou proprietários da equipe poderá acessar a equipe. Somente você ou outro administrador da empresa pode reativar uma equipe. Tem certeza?",
"0d0d5677bee2b99884802638e507160a":"Recusar",
"b5e3483aae9d36ce1440fe02904d6da1":"Recusar solicitação",
"00f3db97287cb17ee9c2a131f2141a65":"Recusado",
"74fdf2e0ad0de26fe28b0b72ba44f494":"({count}) recusado",
"268ed9551ae9f56647eaa394e43c160b":"Aprovador padrão",
"b77589ac3ac2317cfc96d7013658d5c9":"Excluir código de codificação: { entryId }, Entrada: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Declarações e relatórios detalhados",
"5234dfc4220ccd18a8b62fe84e372248":"Detalhes",
"37929896817a6744718004374bb42fd8":"Detalhes",
"dd0a6194e8a6ae14f3e1379a1640de15":"Você quer remover {fullName} ({email}) da empresa?",
"1a98048b34090da8b199135ccb4c1186":"Número distinto e CVC ocultam detalhes reais do cartão",
"3cf132b60fe16b816ca227bbcad60537":"Não cancelar",
"dee57797e14ccf852aa2bc545bafdf6d":"Descarregar",
"375ae8224d7d35393974775cebe8605a":"Baixar lista de usuários",
"54b3bfd45a293b441d441aab1a50e644":"Descarregar relatório",
"60cf3b94e5df14a504825068928fc118":"Baixar relatório em CSV",
"7341a4c73c0b688484304c37da4a6d8e":"DATA DE VALIDADE",
"f628f8ad44fbd6c70c99fc778407b666":"Pesquise facilmente o histórico de transações e explore as tendências nos gastos dos funcionários em qualquer período.",
"94bb409d034ea20697ae09d812e55365":"Editar lista personalizada",
"2974e704676cb79666bc236d8658fa97":"Editar perfil",
"9ff205f99ed4002d049f855f965ca6d8":"Editar usuário",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-mail",
"0534c00af758d42ebd8b0bd772eab074":"E-mail",
"0c9aa1fbdf925065d25161eab5e51d95":"E-mail",
"ea78fac68766a48b1b0f41305b7e9898":"E-mail",
"dc6a8f782e00c1bf05c48f3a8758e26c":"O funcionário solicita fundos do cartão empresarial para fazer a compra. Os fundos solicitados podem ter limitações de gastos definidas.",
"829cff16ce0b13810c914c161b2a9fba":"Ativar funções alternativas de obtenção do usuário",
"741387c7bd71e2b4a9601af9a6b9490e":"Ativar encaminhamento automático de solicitações de aprovação",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Ativar data de validade padrão para o cartão virtual",
"bb319710dad93e78cc6f58c615bdf210":"Ativar a lista de permissões do domínio de e-mail para usuários convidados",
"4700ced24cd278c3149ffa983006035c":"Ativar filtragem por centro de custo",
"3fe06ef8f551c4cb4c0375362e25521d":"Ativar opção de globalização no usuário de lado",
"2787d4bc9752272ac36b499ce7ad93ab":"Ativar cartões virtuais multiuso",
"6ee44e2280c9ce5009568846cbc3dd14":"Ativar mensagem de ausência",
"1941ca87053df0cebc6091ede92c18ea":"Ativar visualização de pessoas para usuários administradores da empresa",
"e50282c2ec7afb948d2c0b428db5d5ff":"Ativar carregamento de recibo para transações",
"268694cb2172918fe60f835919bf20ec":"Ativar carregamento de recibo na tabela de liquidações",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Ativar aprovação automática",
"d17c7acf598b569cce8a2bb97e0bb215":"Ativar cartões virtuais de uso único",
"cd731ca218c9e14caabefafe003cfa6d":"Ativar cartões virtuais de assinatura",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Finalizar encaminhamento em",
"c1f665a814894b17d603b7052b631d90":"Inscrever um novo cartão",
"6b6cea93e07e596be70f7b76801e1516":"Registrar novo cartão",
"7d6ac1ac9cba1620b61908842ebb106b":"Inscrever o cartão titular",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Inscrever o cartão titular",
"21a642b339c3eabf2789c0b05dcf828b":"Inscreva seu primeiro cartão corporativo no GO.",
"6efa8dca4fba23a190838746879fd704":"Digite um ID numérico",
"02c3457577da9dc8dba6e6f9380a248e":"Digite um nome de equipe exclusivo",
"40feb3fd1fa529cb599aeac9607664a4":"Inserir nova entrada",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Exemplo: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Mês de vencimento",
"b8bc74aeeb2de3492891c97361de107d":"Ano de vencimento",
"6a0b8d521500014d99dd192d8ce8403d":"Data de vencimento",
"8cf274169a732340430fe7cc5236e0e7":"O tamanho do arquivo deve ser menor que {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Nome",
"7458b55e967e51791d98e77458631e44":"Nome",
"3bbe52e71bc5c212cea77983f1a5b748":"Nome",
"9b49c88fa20cc006baaac15c857c8781":"Para compras únicas ou múltiplas",
"bb7a2907073bd760f4e82097132637b1":"Esqueceu a senha",
"26bdfaa592f3629273f13c22435b323d":"Esqueceu a sua senha?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Encaminhar",
"3f9fe6adc14ce5776097170653a24462":"Encaminhar solicitação",
"b774e35683f5737c36bf0cf3d6785973":"Encaminhar solicitação",
"628faa5054a912b1e470414f77057e6b":"Encaminhar solicitação",
"852b4065e1837fdc77940b6411a6d902":"Proteção contra fraude",
"616551bd10d95aca439401d6a7769d96":"Valor do frete",
"a65a230918e9c11e1ef8824745e26a3d":"Iniciado",
"7d4c4466b7716347ce3673acaa1983f7":"Obtenha mais do Go",
"11415a07658cfef80eb166896d77ad98":"Prepare-se para ainda mais informações sobre gastos. O Tradeshift Go agora se alinhará melhor com a maneira como as equipes empresariais gostam de trabalhar.",
"ac0ff5d14286ef74614a9c6021f08f69":"Obtenha monitoramento de gastos e acompanhamento de orçamento em tempo real, obtenha informações e faça uma análise detalhada de todas as atividades de compra.",
"db9071ab833054307252a936e7f60e1b":"Começar",
"24dc4bb00841b004031fca3a9bb9bf51":"Solicitação de cartão Go cancelada por {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"O Go lembra envia aos funcionários lembretes para todos os recibos ausentes, que podem ser anexados simplesmente respondendo à notificação ou, no caso de um recibo por e-mail, encaminhando-o para o Go.",
"a73603f0bc610ecea9ef9c793b802538":"A Go trabalha com sua rede de cartões de crédito para garantir os mesmos padrões de prevenção de fraude que você esperaria ao trabalhar com as instituições financeiras de elite.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Boas notícias! Sua solicitação de compra foi aprovada no Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Quer fazer comentários ou sanar suas dúvidas? Entre em contato em",
"9df1d3de68d663b848101df3c7b58e39":"Como funciona",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Tenho certeza",
"84074732eb8d1b4094404cd750baf949":"Se não houver nenhum valor, aplica-se o limite da empresa",
"138e8bd6102a7eea70a123ff03b1cd79":"Se não houver nenhum valor, aplica-se o limite da equipe",
"e5756af4d7e5ad1236ccfbb858428421":"Se você não se registrou no Tradeshift Go usando este endereço de email",
"4f004f75f47dbfecdb6d09990596e530":"Se seu email ainda não estiver registrado, enviaremos um email de ativação com detalhes sobre como proceder.",
"123837f267de602fb05361b3b1197c1e":"Se {username} corresponder a uma conta existente, enviaremos um email com detalhes sobre como proceder. Se depois de alguns minutos você ainda não conseguir ver o e-mail na sua caixa de entrada, verifique sua pasta de spam.",
"45efd96cd93525a9c17222217a37d324":"Formatos de arquivo de imagem ou PDF com suporte",
"fa6ae823f9ab8bf91c246efe2517956c":"Importe a configuração da sua conta do QuickBooks, NetSuite ou Xero diretamente para o Go. Exporte as últimas transações para sua solução contábil com um único clique.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inativa",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"A entrada contém um caractere inválido.",
"62c7be09da43f8f96a60ef3967eddc2c":"Apresentando um novo Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Domínio de email inválido",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Convidar pessoas",
"3809b41b14c55c3fcf54cd813d8e21f5":"Convidar para a equipe",
"fc90a11805961b0d7b5a5545e4d9bc05":"Convidar usuário",
"6f7f4acde92d267417f5d8a60d32400a":"Convide mais pessoas",
"09ecd2ca15bb5c38dc10f653118346cd":"Convidar novo usuário administrador",
"3f576ce504f53f972442a63a170c5418":"Convidar novo {role}",
"0215e052437ceea4e5cd562431b34ced":"Convidar pessoas",
"8aaf2c77df644b7e8524120f66da7d5f":"Valor da fatura",
"1b7dc8290f1205f092cc9133e0cde385":"Data da fatura",
"1a6fb210308df8e0135e49e76aaff31e":"Número da fatura",
"613da3d0cd586ef0360e0d93eace9508":"Emitido em",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Solicita",
"3322b671105c65511fbab1966b7fdcf6":"Idioma",
"b1d946192795a58aea6a61f4f923acb7":"Últimos 4 dígitos",
"b3c6b8db4ef6dcdcb451303e65548816":"Últimos 4 dígitos",
"0382123b9c847000fceff2484c665e39":"Últimos quatro dígitos",
"19f7c0055e150e9e3e66e85c2993799c":"Sobrenome",
"662c84bede8a7bf91ab15bcb73b48b49":"Sobrenome",
"cff8fbef5aac2523344b9535bd56973c":"Sobrenome",
"c3510b2184a92ff12e07a2d1b9a461e6":"Permita que os funcionários solicitem acesso ao seu cartão corporativo sem expor os detalhes do cartão, enquanto você impõe orçamentos e acompanha todas as compras.",
"90c2664b0f771d6ffb1c3241fb8abe44":"O limite pode ser sobrescrito em nível de equipe e de aprovador individual",
"9bf5d56627e500cad9f7ad21c3e7966d":"Limitar a certas categorias de despesas",
"e80a6d4232e52f6bfabee02e17403b59":"Vincule um desses cartões de origem a essa equipe ou inscreva um novo.",
"803570ab9f0fba5ff35ae2749200501f":"Carregando cartões...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Iniciar sessão",
"ad96c1ca962a883652dadb845f439415":"Entrar",
"072ca67e735adbed54acf41321370701":"Entrar",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Entrar",
"b246fe8836c906f1b56342378ca88895":"Elabore, aprove e monitore compras em equipes que espelham sua estrutura organizacional.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Gerenciar liquidações",
"95d672ab621e531ae1a69798e0e4b56c":"Gerencie todos os seus cartões virtuais em um só lugar.",
"96f2e88a167f476413b35aa711709e6e":"Gerenciar liquidações",
"151d8e6ceec242d482fff60da8311449":"Gerente",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Gerente",
"ef1b3b3627418a5e8e5cceb814a67c8d":"O gerente recebe cada solicitação de revisão e aprovação. Os limites de gastos também podem ser ajustados.",
"4f449985e83c1f9ca2e8f82b0707a371":"O gerente recebe cada solicitação de revisão e aprovação. Os limites de gastos também podem ser ajustados.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Gerentes",
"27f8111a955975ea6fd04f748d24cb4a":"Limite máximo de aprovação",
"5b1d9cfb78c55e75b47c62435d9299ca":"Membro",
"41e307c1874e2e82296b226e9bae4a5f":"Membro",
"6f9612aa1582373c2c1ba4e144132100":"Participantes",
"590429a0fcc42af85648d0fd70601dcc":"Participantes",
"3f50e62c8488d83ad84f69ebda853897":"Participantes",
"e0fc0acda5b74b93e797756dbbba67bc":"Participantes",
"c7ef9eda38b7eaee1f20031387a8bde7":"Mês",
"40dd08b507752bef4e3c4b36838317d5":"Mensalmente",
"d712b91f7498f212047aad4c6d0f8088":"Mais recente",
"12110d95b3d7a495afa4f28ac190f63e":"Mais recente",
"2f11ce3865f79ee7e5ee64d728082264":"Mais recente",
"eadc93865655cffc6a9863fdda5693e1":"Deve ter pelo menos 10 caracteres, consistindo em maiúsculas e minúsculas.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Deve ter pelo menos 10 caracteres, consistindo em maiúsculas e minúsculas.",
"50d0a69aa59df5201018771a06ea12e7":"Deve ser exclusivo",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Deve ser exclusivo.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Meus cartões",
"c36602f50d35c2816f0ba8a196b70876":"Minhas solicitações",
"ff6e372aa3eb685a39a701963db35b2f":"Minhas solicitações",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Nome",
"1c7514b561a2591a914225813d30d642":"Nome",
"d621826c1ceade262589af04e655e824":"Nova senha solicitada",
"35dc3304b61a9e69dc3a393123658837":"Novas solicitações serão encaminhadas automaticamente durante esse período.",
"e788124801b4727d121f60d059a2a4a7":"Avançar",
"eca804f1d53dfd984ffd93e2d00fc284":"Nenhum aprovador atribuído. Entre em contato com o administrador do programa",
"abdf7e93f8509399a63108a2b1a25546":"Nenhuma atividade para mostrar",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Não há cartões na sua carteira",
"87127e1e9f5bf922d0a4f948b68697c8":"Sem limite",
"40512efa6b75ef0c7924b1ee3070fca9":"Nenhum membro para mostrar",
"dcda493afcb2415d5ca49a17d1af6f50":"Nenhuma liquidação foi publicada ainda.",
"f0efb26e623bb990532e375d5c8875ec":"Não disponível",
"bc0cf602d1978e814070bb552e576a32":"Não mapeado",
"09e126c317429ccb52ad323c32293648":"Número de pagamentos",
"75b00b969b9d3b520929f1e652637b70":"Número de pagamentos",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Número de pagamentos",
"bd44ba180aa356c5755fdedd32adf03c":"Ah, não! Ocorreu um problema ao criar uma solicitação. Tente novamente.",
"c396b3728dd7393aa9e5427990c132a7":"Opa! Ocorreu um problema ao baixar o relatório.",
"42c07764b07dea1ee7de7605721c3f5f":"Ah, não! Não foi possível criar uma nova equipe.",
"e3a385878ec94bac12a99490d0a8e85a":"Ah, não! Não foi possível criar uma nova conta.",
"17f2dae79677017e2c2e879578c4e09a":"Opa! Algo deu errado. Tente novamente!",
"78c135de545c1663c9768226492a44a3":"Ou utilizar o logon único (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Ausência temporária",
"5d7e45f169e2f6968dc58f43b5267353":"Ausência temporária",
"ee589a20c262527db970671fb2c4f6bc":"Ausência temporária",
"4fd0c753fce49a016b98d988a3895223":"Visão geral",
"2789c5d5c9c042858f45aadc4e0de058":"Visão geral",
"083b7090b66ebd0307a18499ac7b676e":"A página não existe",
"a21d205920893cf579a7655a1430fe30":"Cartão do titular",
"fd35b7ce938e404fdb65de3bb749c528":"Cartão do titular para reporte",
"e8c7d2eea7db66ecb44751091a582304":"Senha",
"bb79e316e00fd21138750b6986e44455":"Senha",
"6cae2279088580976d84119d95097cae":"Senhas não são iguais",
"4baec0159d3074f84c0eebcc09ba4cd8":"Senhas não são iguais",
"b676587115243921602742cb8f696a6a":"Pausar criação de cartão",
"db4a26c871ef0b970b74e1f71763098b":"Pausar a emissão de novos cartões virtuais",
"33fc1e6d01b785e3a815c28ef7d56667":"Pendente",
"6ea62e4811b68df48b467e8d367aac2f":"Solicitações pendentes ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Pessoas",
"d91798f74d3344b720d1ddc0a7c8ebf6":"As pessoas de uma equipe podem ter três funções diferentes",
"e2d34cb1409b9778d34c4b74ecee1df6":"Insira um número inteiro positivo ou deixe vazio.",
"8da247d5d736c87122404eff1e5c0c73":"Insira um limite válido.",
"65e154267ac283d963cbc7be832969d9":"Insira um valor válido",
"28b4450d417065a69a395f567967d46c":"Predefinição com limitações de gastos solicitadas",
"7bbf151d8493170f206a23c2ecc6acd0":"Política de Privacidade",
"51602962f2d1e7515943b4a6c66562ce":"Foto do perfil",
"a51a17940c70a3123da4d68bed562c40":"Perfil salvo!",
"4d398249d01ab72d697a7e219493c637":"Provedor",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Mapeamento de Provedores",
"89b1deb2ffe12a13941c362abaeab01b":"Publicado",
"d8d806d48052159e3ff3b224f3f53586":"Objetivo",
"089917619c78af2efb30be0bb7ce076c":"Reativar equipe",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Motivo",
"e8484c78fda9f453eeb942ba4945f040":"Reatribuir",
"ff2fbc1172ba26776ad0982624cd2713":"Recibo",
"588e690db177ee0c7a7cadb39cbd5efc":"Atualizar fundos a cada semana, mês, ano",
"32955937daca05b2084556f248692baa":"Registrar",
"f15bb755ba24abe6ab106637292175ec":"Restantes",
"579782461133a8b8cc6bd77798961d82":"Remover cartão",
"e475edc165372f8c8deb532aa85d9950":"Remover lista",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Remover lista",
"f9769561315b964d0b3b4887ec208977":"Remover da equipe",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Remover recibo",
"ee181cef581fc8e8217e66b5762db898":"A remoção de uma lista a impede de ser usada e exclui todas as entradas existentes. Se você deseja remover temporariamente uma lista e manter suas entradas, pode desativar a configuração de publicação. Tem certeza de que deseja remover esta lista?",
"feea41d0bc1d577a07c8b6e1297abbde":"Remover um cartão titular de uma equipe não desativará os cartões virtuais existentes.",
"d09483824a2e0d6194a4c3f32311e752":"A remoção de um cartão de origem de uma equipe não desabilitará os cartões virtuais existentes. Tem certeza de que deseja remover este cartão desta equipe?",
"7ca4b1bf1346e179a29da97f260a93a4":"Renomear",
"db195ec9c818b8e92da142374e20d1bc":"Renomear lista de códigos",
"2fd93e229fe9ea754808805d3fef9c13":"Renomear lista de códigos",
"aa1e74d32805823f802a11b33c60c38b":"Tipo de relatório",
"6ac78d97a8cb7f060e43e596e3e23535":"Pedido cancelado.",
"25deccb1980291a0fa9997109c731440":"Solicitar demonstração",
"9f2754d87686903b1500ce8e8a55b377":"Solicitar demonstração",
"92ac924f72679f897e26f3e615be8c22":"Pedido encaminhado.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Solicite informações",
"270185007853cd779011ae0e8433f723":"Pedido reatribuído.",
"e5a059c9ba2691910321339cce989f9d":"Solicitação enviada.",
"77c88a231edfa7075114d91d2397e42d":"Título do pedido",
"cee91b833c4358acf0c554f746557e71":"O pedido foi recusado.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"O pedido foi recusado.",
"9e56abbbf87420eeedd625ce786515c7":"Solicitante",
"b4cb4426e44fee9580af71912a764536":"Solicitações",
"df309e888c29fc447d0309329b39ce93":"Redefinir senha",
"5daf278da19ecbaeb7b96554cb4db10f":"Tentar conectar agora",
"31e9501526d2114e4a01fdbf076de097":"Função",
"6fbceb787429f6f81117a1d5a58b4610":"Função",
"473b814ca86841ef672ccf9f7dac28d5":"Função",
"f1e16d5fd643e039d865b6aec7c986f9":"Função",
"ec78345a5975bc0f8efaee5256b43c2c":"Salvar",
"2cecea57b47d8f4a45c706287ff7d64e":"Salvar alterações",
"15774be5c338a39169917c7d32781dd0":"Salvar e continuar",
"844095b4b8f580737b8717672501048e":"Diga algo...",
"7e66f676c2e7b04514aca3a0935530f0":"Pesquisar por informações de código",
"5293ce9335ec2d176a6e838bf76404c6":"Pesquisar por descrição",
"efab3b0c95f263cdd0a8466329a387eb":"Pesquisar por descrição",
"d227b38dcbc4548c092b0836a7101506":"Pesquisar por nome do comerciante ou ID da transação",
"3629383abf1d5f85efd759f718159b40":"Pesquisar por nome",
"a034fcd6719228b28a6fc22d3a46ea8a":"Pesquisar por nomes de equipes",
"8ac7e0d63f343b548482d5f367f5b3d9":"Pesquisar por nomes de equipes",
"858323eb52cb45446597ee46330a0540":"Pesquisar pelo titular do cartão virtual",
"111bfcbff671e13de117ed5195fb6773":"Selecione",
"bcfecd59eb93e5314873d84ddf35aa58":"Selecione",
"909a4414ea573218bff2d533eaab46ea":"Selecione a data de término",
"114b206ca1ff1c0bc52abef342059b2a":"Selecione as opções da equipe",
"81a02e424615fb41a20b8d0f13441c88":"Selecionar equipes",
"b135b36495618e6692e7fbaf0a0aa83e":"Selecione um mês",
"677e90855e164c00a28b5c4763ac67e0":"Selecione uma função",
"d01570f3774738395b8ba3c64ce54c76":"Selecione um estado",
"7dee3f06c70626881efb0cd6af6b63e1":"Selecione um usuário para o qual encaminhar a solicitação",
"4c7bb810835a1540ddc1f6501da5a51b":"Selecione um ano",
"3ab198d2678af2b5b32180e4b39602ba":"Selecionar aprovador",
"f502ba79f2bd010c5e77891812400958":"Selecionar cartão",
"936027182d446e27ba209ea921ef7502":"Selecione o período de validade padrão",
"b22c893254ccf3bd34faaee511cf7ca8":"Selecionar duração",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Selecione as predefinições a partir da lista de códigos ou crie sua própria lista.",
"42cce5fa376b45bf290f86c684d69d96":"Selecionar campo do provedor",
"b761773d46699b60e8d6874b85593374":"Selecionar equipes",
"3a9396756942ede98d0cb6afc1032b15":"Selecione os aprovadores deles",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Auto",
"03e9d2c128af288126d94f631c5396be":"Enviar instruções",
"299a7e961804e2d649f06b5dc4362c29":"Enviar convite",
"155e7b3f7db7bf10b03b5f41de54eae1":"Enviar mensagem",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Definir uma data de validade",
"f89ec96a4b5bb639488ab742efa76a6a":"Definir nova senha",
"7d5377ad70d57728a45d3c0639de5544":"Configurações salvas",
"14313f11dee735f2144953e720776c28":"O valor da liquidação pode ser diferente se forem aplicadas gorjetas ou outras taxas à compra",
"199000fe0cda0adaaa9b864aa7447cb9":"Liquidações",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Liquidações",
"6e6ac6ff3bd722e37874fb1ea7115818":"Configure sua senha",
"9b4ecf052980e80bf5c65a415d470a43":"Compartilhe e controle o acesso ao seu cartão corporativo",
"ba746d78772d6be26cc508ba989becb0":"Deve ser menor ou igual a {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Mostrar transações sem recibos",
"7d474b60aeb7cc25b519e806fca71e12":"Entrar com sua conta existente \n\n Credenciais do Tradeshift",
"16080cd4e8319ef3e7de21b6c19d1437":"Alguma coisa está errada",
"8f358a370224baf849f9652810c68db2":"Algo deu errado",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Algo deu errado",
"99af87c475a4d40ef732deed96f11ed2":"Algo deu errado. Tente novamente.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Desculpe! Estamos com problemas de conexão no momento – tentando novamente em {retryConnectionPeriod} segundos.",
"84debc8e41646cd49ac2b14708ca6d73":"Desculpe, ocorreu um problema ao excluir a entrada de codificação. Tente novamente.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Desculpe, ocorreu um problema ao salvar a entrada de codificação. Tente novamente.",
"689d3418ee8f996ad25e152e80de63fe":"Desculpe, não foi possível desconectar você no momento.",
"b48efd4521ecd6428987d24636a4349f":"Ordenar",
"776a95003d3ec1dd58af69aedbc08579":"Ordenar",
"1b783238028c0ae085ca69ed823f4d1a":"Ordenar por",
"be70d19bf13c935d653b2c4fe7d36224":"Nome do cartão titular",
"564f8c942e7d1bdda5251545612fa348":"Nome do cartão titular",
"aa284602c746aad4a6de6366c24221b8":"Cartões de origem",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Cartões de origem",
"389d471e79a43440b3278b0fc7187d9e":"Cartões de origem",
"106342f689b57145817b00d6fe383f91":"Especificar data de término",
"2a5e19387ff300509876e3bae99b7ab9":"Acelere sua contabilidade",
"ca34d586bbdb7f368f1009305bccbe76":"Painel de gastos",
"4583120998b44c0a5982cf85dd938d2f":"Gastar ao longo do tempo",
"244d724806247953233a0bbfcbf8da4c":"Limite de gastos",
"d39b83dd151c8df6370460a458294ccf":"Gasto",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Montante gasto",
"5de2859528e030ac1f2af115ebdf209c":"Gasto { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Iniciar encaminhamento em",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Estado",
"1f1fe111548158ec842761eca7425ff3":"Endereço físico",
"31a0a748b1097a9e0aa95a811802bdd0":"Reconciliação sem estresse",
"111b782f3f0794c2294aefb6693b2c50":"Enviar",
"013e2555920b51ad2a3d2c3dc482c471":"Enviar pedido",
"1270cd8eb69ad64cc71e5de979423b3b":"Fim da assinatura",
"2802336ad3d8ff658257b88a93f4e50a":"Fim da assinatura",
"b28dd68454d7acbefafeefb42981fe16":"Frequência de assinaturas",
"907793bc80cf94d560e5f1a0250fdaee":"Frequência de assinaturas",
"29229e153308e4747edef1144de35a55":"Sucesso!",
"503eb46b80f374f39266e7a9aea8e63e":"Sucesso!",
"4f20e29cadadb9fbc7455bd4d80de567":"Sucesso!",
"601d04f10d558f436c78c10e90b44175":"Suporte",
"62558c185ea2c06c5472d1cd534f6e56":"Sincroniza com sua solução contábil",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Valor do imposto",
"0cb5d74f7a56fca30930616feb6e2f47":"Equipe",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Equipe",
"bf23b2fd889e3b12e0f0acf99f913eca":"Cartões da equipe",
"b90a650376782a9e43ed6be6135bd6f2":"Painel da equipe",
"6adbec395fe1c4bf776f9fe5fa021052":"Painel da equipe",
"6f2695eeb68501dbcfddbb4d265b846b":"Gerentes de equipe",
"3b779ade9c960ac95a670db0cc12e56b":"Membros da equipe",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Configurações da equipe",
"dc5235e1b88320d3ba7501e4c30985a3":"Configurações da equipe",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Configurações da equipe",
"a2eb92bfd7502f47b3cd170bc68ac638":"Configurações da equipe",
"f1ec84f051f19f0d0e301a709b13dc45":"Gastos da equipe",
"fbacde3a262ea8696e30135e8f987a44":"Comutador de equipe",
"74b9578ea5a620f5fef102188e42c02f":"Comutador de equipe",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Ações de equipe",
"3aa978fa7ef886621285b93257d428c3":"Limite de aprovação de equipe",
"a41f9df131c221a99926de7e2138653a":"Informações de equipe",
"941c7da8f256f91ba3819f991315a100":"Nome da equipe",
"c5d04e10cc2f0b53c2bda893eae09178":"Nome da equipe",
"504a8ba642ba37c9a743d0fabb2bd371":"Nome da equipe",
"e768a8e1e3d6a6bf470587dad75423fe":"Titular da equipe",
"e2d824067f8bd871756688c58c382196":"Proprietários da equipe",
"dca232ccc849c5ff5611255eb82baf53":"Proprietários da equipe",
"3b61bd586225790cf132591383d15448":"Comutador de equipe",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Os limites de equipes e individuais nunca excederão o limite da empresa",
"4b4fe189a48d8eb06d836afa8fb6317a":"Equipes atualizadas.",
"2a17f9a8c610db29fec63fd530c29fdc":"Equipes usando o cartão",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Equipes que podem ver isso",
"317868f3804ba4ed5d99dd9919c798ba":"Termos de Serviço",
"e8c9a9847db2ccd97524aa06f1af9d82":"Termos de Serviço e Política de Privacidade",
"38852078519c5c46715d8a034fc4901b":"Termos de Serviço e Política de Privacidade.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Agradecemos o seu interesse no Tradeshift Go. O suporte para cartões de crédito {cardLabel} estará disponível em breve. Um membro da nossa equipe de sucesso do cliente informará assim que estiver disponível.",
"f7047b35a069d0790a0a348a29c846a2":"Obrigado por se juntar à sua equipe da {companyName} para fazer compras comerciais usando os cartões virtuais Go.",
"133b0a70b1a956889164621380c378f9":"A ação que você está tentando executar não pode ser concluída. Em alguns casos, você só precisa tentar novamente.",
"18f5a1872e156f1aee84ae321780d952":"A quantia solicitada estava acima do limite de solicitações da empresa, de ${requestLimit}. Tente novamente com um pedido de valor menor.",
"867e3d4d3c78990c6852fc5348706ab2":"O valor solicitado estava acima do limite de solicitação do fornecedor de US$ ${providerLimit}. Tente novamente com um valor de solicitação menor.",
"570f5f4658b03dda7a910f56c86a3bd3":"O cartão desta solicitação foi cancelado.",
"3075c56105863546e3368ffead7a146b":"O cartão foi cancelado",
"c6d61bfe3a51966dbdb7e0372a80d15c":"A lista de codificação cuja publicação você está tentando cancelar está vinculada a uma integração. Se você tem certeza de como removê-la da integração, entre em contato com o suporte, que ajudar voce a concluir essa ação.",
"756ade8d71ec487a4b48d7c4277ea239":"A lista de codificação cuja publicação você está tentando cancelar está vinculada a uma integração. Você precisa primeiro remover o mapeamento antes de cancelar a publicação dessa lista de codificação.",
"4f40335f7c018986c95458183bc0c136":"O link para ativar sua conta não é válido ou expirou. Peça para a pessoa que o convidou enviar outro convite.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"O link para redefinir sua senha não é válido ou expirou. Solicite a redefinição de senha novamente para obter um novo link.",
"ded30f0e288c12125ca1a77f8d8fd600":"O motivo ficará visível para o aprovador e o solicitante",
"f294897b38fc23dd8f5de52b54ceae22":"A solicitação será recusada e o solicitante será notificado. Deseja prosseguir?",
"e8c7200dc985df0a94a9d996968b4cc0":"A solicitação será recusada e o solicitante será notificado. Deseja prosseguir?",
"5f3d196a2ade3f97a2a48b773fec5861":"A solicitação será recusada e o solicitante será notificado. Deseja prosseguir?",
"b04c191cd6b4f27669a4f390311f156c":"Não há proprietários ou gerentes de equipe a serem selecionados.",
"e25045557efbad7b52997b94a1fe6817":"Não há {type} para este período",
"7260690577c795e999acae63bb7c51ae":"Ocorreu um problema ao salvar as configurações da equipe. Tente novamente.",
"f2103024f2a9c6c0335a71c60567d6c9":"Ocorreu um erro ao carregar sua carteira",
"9776cb7eab9fa0d318a8144cd17de745":"Ocorreu um erro ao carregar sua carteira",
"77d28a14bc900be4360b570a82eb75a9":"Não há dados para mostrar",
"2b6feba67722337f88c1443909fe446f":"Ocorreu um problema ao adicionar o aprovador!",
"24b7212afb97c5d23faafa2ca4559095":"Ocorreu um problema ao adicionar o cartão de crédito. Tente novamente.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Ocorreu um problema ao adicionar o cartão de origem à equipe. Tente novamente.",
"5a8e1b922d1cee746ac6794599b10709":"Ocorreu um problema ao cancelar este cartão. Tente novamente.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Ocorreu um problema ao cancelar este cartão. Tente novamente.",
"22361c5d4df61ad7ae9792a3baf5729f":"Ocorreu um problema ao criar sua lista. Tente novamente.",
"098755bfddeaf0428c7feb6b69af9b6f":"Ocorreu um problema ao recusar a solicitação. Tente novamente.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Ocorreu um problema ao recusar a solicitação. Tente novamente.",
"7d461b6b7b54dbfa9c83243217cd1053":"Ocorreu um problema ao excluir a entrada de codificação. Tente novamente.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Ocorreu um problema ao excluir sua lista. Tente novamente.",
"beb43d7800cfff47e08cd61842d953bd":"Ocorreu um problema ao encaminhar o pedido. Tente novamente.",
"f7775350f35b8365fb9803232d9b792c":"Ocorreu um problema ao obter os detalhes do cartão. Tente novamente.",
"95abaa922c6c1648ca9c39ac4776896f":"Ocorreu um problema ao obter os detalhes do usuário. Tente novamente.",
"9d7e4ead60137041ae8a845aa6cf9979":"Ocorreu um problema ao carregar o perfil do usuário. Tente novamente.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Ocorreu um problema ao carregar os usuários desta equipe. Tente novamente.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Ocorreu um problema ao carregar suas configurações. Tente novamente.",
"b2f48028c400befad5f1d57f769ef7cd":"Ocorreu um problema ao reatribuir o pedido. Tente novamente.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Ocorreu um problema ao remover o cartão de origem da equipe. Tente novamente.",
"19dfcd761006496c1d20351c04643e45":"Ocorreu um problema ao remover este domínio. Tente novamente",
"149651b5784481c68aaf4ac27bb1f907":"Ocorreu um problema ao salvar a lista de códigos. Tente novamente.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Ocorreu um problema ao salvar o perfil. Tente novamente.",
"749ddc235744b6b371668ec0bb049176":"Ocorreu um problema ao salvar o usuário. Tente novamente.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Ocorreu um problema ao salvar este domínio. Tente novamente",
"04cf86909808f430b8b1904fb422e0d3":"Ocorreu um problema ao salvar as configurações de ausência temporária. Tente novamente.",
"2eaf6ee97d984598c95e0494d72aa492":"Ocorreu um problema ao enviar o pedido de compra.",
"148f847d3beccaa67fcd5096ae3602d3":"Ocorreu um problema ao atualizar o nome do cartão. Tente novamente.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Ocorreu um problema ao atualizar o nome do cartão. Tente novamente.",
"de8cc976f7af9edf189904239e6240da":"Ocorreu um problema ao atualizar as equipes. Tente novamente.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Ocorreu um erro ao adicionar o administrador. Tente novamente.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Ocorreu um erro ao desativar sua equipe. Tente novamente.",
"3f686a97a1b9fcdd73a40d2005693105":"Ocorreu um erro, tente novamente.",
"2f38263789163cf8ee30c8ae88852c82":"Ocorreu um erro ao publicar sua lista.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Ocorreu um erro ao remover o administrador. Tente novamente.",
"007a647254e1f85b6c1e789004e05df5":"Ocorreu um erro ao salvar as configurações",
"c3072d080b587d70f85d68ec089f028f":"Ocorreu um erro ao salvar as configurações da sua equipe. Tente novamente.",
"608cd0e109c3e51b0425df8921e4181d":"Ocorreu um erro nas configurações do mapeamento do provedor. Tente novamente.",
"177cefc68dd318ea75673326e77d8206":"Ocorreu um erro ao cancelar a publicação da sua lista.",
"255d22dc4f0e338a4cf53d5b7256e695":"Ocorreu um problema ao buscar o nome da pessoa para a qual você está encaminhando os pedidos de aprovação.",
"39ac4ec73fed8a981ad1857d989edfcb":"Este valor excede o limite de aprovação da empresa.",
"494ce3f598cbd97afe99774b5975a4bc":"Este valor excede o limite de aprovação da equipe.",
"f2a42214e7c480c5596439becfd0b7bf":"Este campo é obrigatório.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Essa configuração afeta apenas os novos cartões",
"881b7596cf985d9c246b05fcab930632":"Este cartão de origem foi adicionado à equipe.",
"a1b1aa354d06e7815b78c6abb7448e84":"Este cartão de origem foi removido da equipe.",
"b348b24fc4839ee15ed0aa424689bae3":"Essa equipe deve ter um administrador ou gerente antes de poder convidar um membro.",
"188b9ed447a43916f9e323623ea3a993":"Para aceitar o convite",
"30286e57da108ee7dbc0f8fbb62d4fff":"Total gasto ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Acompanhe os gastos de equipes, projetos e assinaturas.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Acompanhe a atividade dos cartões ativos de sua equipe, pause os gastos e desative cartões que a equipe já não precisará mais usar.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Redefinição de senha do Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Tente novamente",
"80196bc616d4a44a4997a2ea127f7b83":"Tente novamente",
"f96bbe81618b5f4a1807989eb4f69e24":"Tente novamente",
"d498d7d261b7ca56e07218d1a0dbdd49":"Ah, não! Ocorreu um erro. Tente novamente.",
"ce2e475291003852c71b01959698c496":"Não foi possível receber mensagens sobre o pedido{title}, tente novamente",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Desarquivar",
"d2e50be5287fd8fb436d0acbeb811864":"Identificador exclusivo",
"0e2fbce967f4a209215b6f48f8eddac3":"Retomar criação do cartão",
"f2315dec5dd07802b77af3a3a5414a7d":"Até 4 anos no futuro.",
"d2f30a2f0c955271e9af123dceca501a":"Até {label} no futuro",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Atualizar cartão titular",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Atualizar cartão titular",
"c8f6bc61a8982158e408194e48a274d9":"Falha no envio, tente novamente.",
"8a86a60cb836bdb5225cad20c5151078":"Carregar recibo",
"e448a1ce42de0cf526200331b4ad8b23":"Usuário",
"75edcc8fc27f41c99e28b48ff250c7f8":"Detalhes do usuário",
"d1651c2afdb6c92621adbb707e4753d0":"Usuário salvo.",
"5fa55883b7413526b441e999cb8089b3":"Nome de usuário",
"7a892b81fe2aae295f1b2d38bb67360f":"Nome de usuário",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Usuários com cartões ativos não podem ser removidos. Cancele os cartões para remover este usuário.",
"d40fa5935f1614925cf46259c886814a":"Exibir atividade do cartão",
"625419857efc7e72d162c9f8204109df":"Visualizar cartão na carteira",
"85a5e817826415651ed4991a76bb5385":"Ver conversa",
"09fc124dc9b8b14d6227a92bb0a4566f":"Ver conversa",
"d26cfc6d8f420f259f434b79593e9eb4":"Ver recibo",
"c7d0394c3206a3b74afbeabc41cc639c":"Ver recibo",
"01650aa52c5e5a22d3f38afd2c7f9411":"Ver recibo",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Ver recibo",
"b2d141897f73fc2a198ee1a7a438f89b":"Exibir solicitação",
"02fa2f56ef72845a9dfeabea22948061":"Exibir detalhes da equipe",
"2d9c55650b17dd605a1388f46c81ba38":"Cartão virtual",
"d0ec076e4600e854588f8da265208c0b":"Opções do cartão virtual",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Carteira",
"5279a2220e4cfb0d8b8b357311251cbe":"Carteira",
"b1746b5806659e3189f837d2dd54e08c":"Carteira",
"ff306d5b34ee993f9618c1f3c0209a39":"Não é possível arquivar a solicitação no momento. Tente novamente mais tarde.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Não é possível cancelar o pedido no momento. Tente novamente mais tarde.",
"a813d44f6bb26c5da09671e532abe022":"Não é possível desarquivar a solicitação no momento. Tente novamente mais tarde.",
"d4c7310eafc0d0da89f20596a22a3abe":"Não é possível criar uma solicitação no momento. Tente novamente!",
"dca679c10accb1db481a57a0e1ba08e2":"Não é possível criar uma solicitação no momento. Tente novamente!",
"db5584a2ef29b04959bce7f93db54c56":"Não é possível mudar de equipe no momento. Tente novamente mais tarde.",
"f2af2c261fabddb01766f4f974b85a62":"Semanalmente",
"e181ed0723a00ca7558b9d5d5258732b":"Bem-vindo(a) ao Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Entraremos em contato!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Qual cartão de crédito sua empresa usa?",
"968d94729053e166a76ea577ce3022e2":"e-mail corporativo",
"588a075e8694ff5cc86815697e1586bc":"Trabalhe como as equipes da sua empresa.",
"182ae1eb5226e0646b4033883cc0900c":"Ano",
"fe88fe7094c4ced427874b3d22326c8d":"Sim",
"9a2d4e3b22ad0567165eb0f661f952ad":"Sim, cancelar cartão",
"96b88e530375237cbc08138d9fb4109c":"Sim, remover esta lista",
"fd3780f468c531f2bc1b47b8748b5a66":"Você pode criar códigos contábeis nas configurações da empresa.",
"ab3ac6f945d9f756763d49257fd924dc":"Você pode gerenciar codificações nas configurações da empresa.",
"c919dae93fe780061629c255eb358140":"Você não pode remover o único cartão de origem vinculado a essa equipe. Adicione outro cartão para remover o cartão desejado.",
"11de97e873b66d91a77db7a05da32202":"Você pode promover um dos proprietários da equipe listados abaixo para ser um administrador da empresa ou convidar um novo usuário.",
"0ab790f08b2780be001b79d2ccd75c4e":"Não há códigos contábeis criados para esta conta.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Nenhuma lista de códigos foi criada ainda.",
"44e470218124a50c117870467530752e":"Você digitou uma senha ou endereço de email incorreto.",
"4b687d0730822fdbeea27c174e47f227":"Você não tem aprovações aprovadas.",
"020f03c17b79af66f335b6af868c66c0":"Você não tem aprovações canceladas.",
"def0b76f03e7c622341a7d8e08aaaae7":"Você não tem aprovações recusadas.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Você não tem aprovações pendentes.",
"7c034d6104f80a523b90bc607fc06608":"Você não possui cartões virtuais na sua carteira. Crie um pedido",
"0aed9198d61d161468d541dbbc9f477a":"Você tem encaminhamento de ausência temporária para {oooForwardToFullName} agendado até {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Você recebeu um novo cartão virtual:",
"04eeaf814767c30aa41577f3b11f916a":"Você solicitou recentemente uma redefinição de senha da sua conta Go. Para concluir o processo:",
"09ddf249810b9af7d3e1f4e537da500d":"Você tentou acessar uma página que não existe. Verifique se você possui o link correto.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Você foi convidado a fazer parte de sua equipe em",
"02c6f3a7cf31a87f4f39f992509f8c44":"Você foi adicionado como administrador da sua empresa",
"cf121a14590f109dafcae138265e353b":"Você foi adicionado a uma nova equipe!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Você foi adicionado à equipe",
"d3cef66a026f776903b342d450e5a832":"Sua solicitação de cartão Go foi negada",
"003f87c21a29220e661fc302b885fd5a":"Sua conta foi desativada. Entre em contato com o administrador da sua empresa ou equipe.",
"a52b1f0da15dce68007f524e48238143":"Sua instituição bancária retornou um erro ao adicionar este cartão. Entre em contato com sua instituição bancária para resolver e tente novamente.",
"4d883b173167297463aeac86957c26fe":"Seu endereço de email foi usado para criar uma conta no Tradeshift Go. Para confirmar seu endereço de email e concluir o registro",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Suas configurações de ausência temporária foram salvas.",
"542f08554110a12de0c23afd78f38e0e":"Sua senha foi alterada. Faça o login.",
"49b2dcbe5d461f35c37986962b5bd07a":"Sua solicitação foi recusada",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Você está na lista!",
"32f87c5ddeaee35b9178785ef4eb9313":"Código postal",
"c6974a3d5311374c697625f2a3234bce":"uma data",
"f86980f5ef00b645a6e4a0b59738a954":"uma pessoa que não conseguimos analisar",
"7066f854917b39bafe0bbecbb8bd6377":"aprova solicitações",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"de",
"49b171230ab9bcdd8b631c70cf397efb":"para <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"para sua empresa",
"35ad86f0b035d8156390670d60801e7f":"de {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"de {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"de",
"c1407aa5424f339dedf67bcac597cf71":"definiu que os pedidos de aprovação serão encaminhados para você durante",
"67a948b8af3284a401ebefcb1b4723ab":"está aguardando sua aprovação de",
"9ca64449d64b98fef7859e22bc23c4dc":"faz pedidos",
"ac850ab84428e9eb6a57e5d813de5e45":"gerencia a equipe",
"2168839a1f4c73ef5c89c9e930148e7d":"desconsidere este email Nenhuma ação é necessária",
"85431c325faab98e02daa60151465144":"enviou este convite",
"81c504fd54119e7e580eb05dedef46d0":"enviou este convite",
"d74545060b38445ef4cc49fbb8d79876":"enviou este convite.",
"2d96c53ee7ee0a54aadad19bfce0697d":"ocorreu um erro ao obter as fontes do seu cartão",
"b2fda295788d579fffd3f43b7737fd63":"para inscrever seu cartão corporativo no Go e conceder aos seus funcionários acesso simples e seguro ao cartão",
"d35bd5c5d74af765d45cef3c4e78f52a":"para fazer compras comerciais usando os cartões virtuais Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"para fazer compras comerciais usando os cartões virtuais Go",
"9546989e681a7e25fbdfe7631e9ebc03":"para fazer compras comerciais usando os cartões virtuais Go.",
"6174e0a3d26409dd6e410052e2805660":"para começar a usar os cartões Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"ativar mensagem de ausência",
"fd2c1099fc9f3831d7f24195aaa11269":"você@empresa.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} é responsável por aprovar solicitações de outros membros da equipe. Atribua um novo aprovador aos membros afetados para continuar.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} foi convidado com sucesso${this.teamId ? '.' : ' to the team.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} foi removido",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} cancelou o pedido de",
"9567a2d4cacd58fb4f096132d26c1660":"{username} é responsável por aprovar solicitações de outros membros da equipe. Atribua um novo aprovador aos membros afetados para continuar.",
};
