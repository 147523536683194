import _ from 'lodash';

const InviteUserAsideComponent = {
	bindings: {
		isOpen: '<',
		tsAdmin: '<',
		onClose: '&',
		onUserInvited: '&',
		role: '<?',
		teamId: '<?',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.InviteNewRoleText()}}">
			<ts-note
					icon="'ts-icon-warning'"
					ng-if="$ctrl.role.id === 'team_member' && $ctrl.teamApprovers.length === 0"
					message="$ctrl.approverMessage"></ts-note>
			<ts-form
					is-submitting="$ctrl.isSubmitting"
					on-cancel="$ctrl.onCancel()"
					on-submit="$ctrl.onFormSubmit()"
					submit-label="{{$ctrl.sendInvitationButtonText}}">
				<ts-form-fields>
					<ts-input
							class="tst-invite-email"
							label="{{$ctrl.emailFormText}}"
							validate="$ctrl.validateEmailDomain(value)"
							ng-model="$ctrl.username"
							on-blur="$ctrl.checkForUser(value)"
							type="email"></ts-input>
					<ts-input
							class="tst-invite-firstname"
							label="{{$ctrl.firstNameFieldText}}"
							ng-model="$ctrl.firstName"></ts-input>
					<ts-input
							class="tst-invite-lastname"
							label="{{$ctrl.lastNameFieldText}}"
							ng-model="$ctrl.lastName"></ts-input>
					<ts-select
							class="tst-invite-role"
							is-disabled="{{$ctrl.disableRoleSelection}}"
							label="{{$ctrl.roleFieldText}}"
							ng-model="$ctrl.role"
							on-change="$ctrl.onRoleChange(value)"
							options="$ctrl.roles"></ts-select>
					<ts-input
							change="$ctrl.onSelfApprovedToggled()"
							is-required="false"
							label="{{$ctrl.enableSelfApprovalText}}"
							is-disabled="$ctrl.selfApprovalLocked"
							ng-if="$ctrl.role.id === 'manager' || $ctrl.role.id === 'team_admin' || $ctrl.role.id === 'company_admin'"
							ng-model="$ctrl.selfApprovalEnabled"
							type="checkbox"></ts-input>
					<ts-select
							class="tst-invite-approver"
							is-disabled="{{$ctrl.selfApprovalEnabled}}"
							label="{{$ctrl.approverFieldText}}"
							ng-model="$ctrl.approver"
							options="$ctrl.teamApprovers"
							placeholder="{{$ctrl.selectApproverFieldText}}"></ts-select>
					<ts-input
							class="tst-invite-companyId"
							label="{{$ctrl.companyIdFormText}}"
							ng-if="$ctrl.tsAdmin"
							ng-model="$ctrl.companyAccountId"></ts-input>
				</ts-form-fields>
			</ts-form>
		</ts-aside>
	`,
	controller: class InviteUserAsideController {
		/* @ngInject */
		constructor(
			DomainWhitelistingService,
			EventEmitter,
			FeatureService,
			LexicoService,
			PaymentsService,
			RoleService,
			UserService,
		) {
			this.lexico = LexicoService.getLexico();
			this.approverMessage = this.lexico.trc(
				'Invite user validation message',
				'This team must have an admin or manager before you can invite a member.',
			);
			this.sendInvitationButtonText = this.lexico.trc('Button', 'Send invitation');
			this.emailFormText = this.lexico.trc('Form input label', 'Email');
			this.firstNameFieldText = this.lexico.trc('Form input label', 'First name');
			this.lastNameFieldText = this.lexico.trc('Form input label', 'Last name');
			this.roleFieldText = this.lexico.trc('Form input label', 'Role');
			this.enableSelfApprovalText = this.lexico.trc('Form input label', 'Enable self-approval');
			this.approverFieldText = this.lexico.trc('Form input label', 'Approver');
			this.selectApproverFieldText = this.lexico.trc('Form input label', 'Select their approver');
			this.companyIdFormText = this.lexico.trc('Form input label', 'Company Id');

			this.isSubmitting = false;
			this.selfApprovalEnabled = false;
			this.DomainWhitelistingService = DomainWhitelistingService;
			this.EventEmitter = EventEmitter;
			this.FeatureService = FeatureService;
			this.PaymentsService = PaymentsService;
			this.RoleService = RoleService;
			this.UserService = UserService;
			this.teamApprovers = [];
		}

		$onChanges(changes) {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}

			if (changes.role && changes.role.currentValue) {
				this.disableRoleSelection = true;
				this.roles = this.RoleService.getRoleOptionsObject(changes.role.currentValue.id);
			}
		}

		async $onInit() {
			// We omit company admin and support admin here, but in the company settings page when we add
			// company admins, we explicitly pass through the company admin role which happens above in the
			// $onChanges method which will ensure the company admin role shows up in the locked `<ts-select`
			// otherwise, we are only inviting team roles.
			if (!this.role) {
				this.roles = this.tsAdmin ? this.RoleService.getRolesV2() : this.RoleService.getTeamRolesV2();
			}
			this.InviteNewRoleText = () =>
				`${this.lexico.tr('Invite new')} ${this.role ? this.role.label.toLowerCase() : 'user'}`;
			this.features = await this.FeatureService.getFeatures();
			this.whitelistedDomains = await this.DomainWhitelistingService.getWhitelistedDomains();
		}

		checkForUser(value) {
			if (value) {
				this.UserService.getUserByEmail(value).then(result => {
					this.firstName = result.firstName;
					this.lastName = result.lastName;
				});
			}
		}

		ensureSelfApproverInSelect() {
			let selfApprover = this.teamApprovers.find(teamApprover => teamApprover.id === 'self-approval');
			if (!selfApprover) {
				selfApprover = { id: 'self-approval', label: this.lexico.trc('Form select option', 'Self') };
				this.teamApprovers = [selfApprover, ...this.teamApprovers];
			}
			this.approver = selfApprover;
		}

		generateValidateDomainRegex() {
			if (!this.validateDomainRegex) {
				this.setValidateDomainRegex();
			}
			return this.validateDomainRegex;
		}

		isDomainWhitelistingEnabled() {
			return (
				_.has(this, 'features') &&
				_.has(this, 'whitelistedDomains') &&
				this.features.ENABLE_DOMAIN_WHITELISTING &&
				!_.isEmpty(this.whitelistedDomains)
			);
		}

		isSelfApprovalLocked() {
			const teamApproversWithoutSelf = this.teamApprovers.filter(teamApprover => {
				return teamApprover.id !== 'self-approval';
			});
			return teamApproversWithoutSelf.length === 0;
		}

		async onFormSubmit() {
			this.isSubmitting = true;
			const userData = {
				firstName: this.firstName,
				lastName: this.lastName,
				roleId: this.role.id,
				username: this.username,
				companyAccountId: this.companyAccountId,
			};

			if (this.teamId) {
				Object.assign(userData, { teamId: this.teamId });
			}

			if (this.approver) {
				Object.assign(userData, { approverId: this.approver.id });
			}

			try {
				const invitedUser = await this.UserService.sendInvitation(userData);
				const successMessage = `${this.firstName} ${this.lastName} ${this.lexico.tr('was successfully invited')}${
					this.teamId ? '.' : this.lexico.tr(' to the team.')
				}`;
				ts.ui.Notification.success(successMessage);
				this.onUserInvited(this.EventEmitter({ invitedUser }));
				if (this.teamId) {
					await this.updateTeamApprovers();
				}
				this.onClose();
			} catch (e) {
				const errorMessage = `${this.lexico.tr('Could not invite')} ${this.username}`;
				ts.ui.Notification.warning(errorMessage);
			} finally {
				this.isSubmitting = false;
			}
		}

		onSelfApprovedToggled() {
			// Because we are using the ts-form, this option needs to have something selected for the form to be valid
			// which requires us to do this weirdness
			if (this.selfApprovalEnabled === true) {
				this.ensureSelfApproverInSelect();
			} else {
				this.removeSelfApproverFromSelect();
			}
		}

		async onOpen() {
			this.firstName = '';
			this.lastName = '';
			this.username = '';
			this.companyAccountId = '';
			this.shouldShowApprover = false;
			this.selfApprovalEnabled = false;
			this.selfApprovalLocked = false;

			if (this.teamId) {
				await this.updateTeamApprovers();
			}
		}

		onRoleChange(role) {
			if (role.id === 'team_member') {
				this.selfApprovalEnabled = false;
				this.removeSelfApproverFromSelect();
			} else {
				if (this.isSelfApprovalLocked()) {
					this.selfApprovalEnabled = this.selfApprovalLocked = true;
				}
				if (this.selfApprovalEnabled === true) {
					this.ensureSelfApproverInSelect();
				}
			}
		}

		removeSelfApproverFromSelect() {
			this.approver = undefined;
			this.teamApprovers = this.teamApprovers.filter(teamApprover => teamApprover.id !== 'self-approval');
		}

		setValidateDomainRegex() {
			if (!this.isDomainWhitelistingEnabled()) {
				return;
			}

			const domains = _.map(this.whitelistedDomains, domain => {
				return domain.replace('.', '\\.');
			}).join('|');
			const regexPattern = `@(${domains})$`;
			this.validateDomainRegex = new RegExp(regexPattern, 'i');
		}

		// We call this method twice. On form submit, and on open.
		// The reasons for this are as follows:
		// 1) On form submit we want to know if a manager was added to the team so we can display them
		// in the approvers select and stop showing the note saying you can't invite members until
		// you have a manager or admin in the team
		// 2) On open we want to refetch this list in case we've removed all available approvers from
		// this team and you can no longer invite members. Since this aside component doesn't know about
		// team-members being removed, we just have to check explicitly
		async updateTeamApprovers() {
			const teamApprovers = await this.PaymentsService.getTeamApprovers({ teamId: this.teamId });
			this.teamApprovers = teamApprovers.map(approver =>
				Object.assign(
					{},
					{
						label: approver.fullName,
						id: approver.userId,
					},
				),
			);
		}

		validateEmailDomain(value) {
			if (this.isDomainWhitelistingEnabled() && value) {
				let valid;
				this.generateValidateDomainRegex();
				if (!this.validateDomainRegex.test(value)) {
					valid = false;
				} else {
					valid = true;
				}
				return {
					valid,
					errorMessage: valid ? '' : this.lexico.trc('Validation error message', 'Invalid email domain'),
				};
			}
		}
	},
};

export default InviteUserAsideComponent;
