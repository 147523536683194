import angular from 'angular';

import ContextMenuModule from './context-menu';
import FileUploadModule from './file-upload';
import PeopleTableModule from './people-table';
import SearchModule from './search';
import SubHeaderModule from './sub-header';
import TagModule from './tag';

export default angular.module('go.common.components', [
	ContextMenuModule,
	FileUploadModule,
	PeopleTableModule,
	SearchModule,
	SubHeaderModule,
	TagModule,
]).name;
