"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportInitialState = void 0;
exports.supportSlice = supportSlice;
exports.supportInitialState = {
    support: {
        requestProfile: '',
        providerMapping: '',
        workflowConfig: '',
    },
};
function supportSlice(set) {
    return {
        ...exports.supportInitialState,
        setRequestProfile(requestProfile) {
            set((state) => {
                state.support.requestProfile = requestProfile;
                return state;
            }, false, '[setRequestProfile]');
        },
        setProviderMapping(providerMapping) {
            set((state) => {
                state.support.providerMapping = providerMapping;
                return state;
            }, false, '[setProviderMapping]');
        },
        setWorkflowConfig(workflowConfig) {
            set((state) => {
                state.support.workflowConfig = workflowConfig;
                return state;
            }, false, '[setWorkflowConfig]');
        },
    };
}
