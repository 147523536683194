module.exports = {
"d447f4a1755a0025dddd2f736255879f":"Liczba transakcji",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minuta",
"ce09c436b49af331a32f1152ede7dd18":"1. Poproś o finansowanie",
"d2226752d3065fbad4744924b4a0ee73":"2. Przejrzyj i zatwierdź",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Przejrzyj i zatwierdź",
"82bab92f1c8030888550b51521266c53":"3. Zapłać kartą wirtualną",
"2ecd62e02e507f8e839a55eac0a04886":"Pozycja kodowania o identyfikatorze „{entryId}” już istnieje.",
"5dfd25849740fbce8147838b0d5094eb":"Łatwa do zapamiętania nazwa, np. Karta marketingowa",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Wkrótce skontaktuje się z Tobą ktoś z naszego zespołu ds. sukcesów klientów.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Osoba, która nie została sprawdzona",
"976b50e70e4433e2786233979d243445":"Istnieje już zespół o takiej nazwie.",
"35b61d21ac11d5fc31ebe58541263445":"Zaakceptuj zaproszenie do dołączenia",
"a372cb2d4204654f52cd9170fb10d374":"Kody księgowania",
"110fc66010b6b91cebee7a59a2067aab":"Kody księgowania",
"db862bb04dbdc6d04e47063f33be8865":"Kodami księgowania zarządza się za pomocą list kodowania i zawartych w nich pozycji.",
"d6e3848e824f077396be4351eb58edd2":"Wymagane działanie — zatwierdź/odrzuć nowy wniosek od: {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Działania",
"476e728ea824229f52438e543818bcdd":"Działania",
"3117746cb7d4ab815ec98274ec4398d5":"Działania",
"3ab09b66a7f16219d8135977a2a76d10":"Działania",
"ffafa66eaab0409a2e90aaeb43796def":"Aktywne",
"0447e0aa5d00dbdab11b5546793c4479":"Aktywne",
"8ee51d21b05466f7d962d4ff5a0b384e":"Aktywne karty",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Aktywne karty wirtualne",
"89c6ec05861aa914304ba35bc6744124":"Aktywne karty",
"5280659bcecaf8344c57790f9962a9a2":"Aktywność",
"8a15368ae286fa64e1cb7624cd410fc6":"Dodaj listę kodowania",
"8ded7e09e86be231b1165308329ac76d":"Dodaj listę",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Dodaj administratora",
"695bb4bc29e2df769ddf7427ab71012a":"Dodaj osobę zatwierdzającą",
"4a1d4516cab00b7c5ecdb150e346e5db":"Dodaj osobę zatwierdzającą",
"56869851b27c2915f5a7fe6a33b91ac7":"Dodaj listę kodowania",
"8f8691057c3994aa61f9994202680c87":"Dodaj listę kodowania",
"69f7a406b79d19bf8082ed245ab29123":"Dodaj listę kodowania",
"48a92ca1cc63018707be99b13196369f":"Dodaj administratora firmy",
"a679db9ff80d0e5769ae1ba311c7916f":"Dodaj domenę",
"8f5e8078be77146ba7f3be49ef948906":"Dodaj wpis",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Dodaj wiadomość",
"f8a4b14162e19995074e63135b65c84d":"Dodaj nową kartę",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Dodaj osoby",
"c670549d0b898bb6a571eb2cd7804a6e":"Dodaj pokwitowanie",
"eb937098e77d8a6b0565ed72357dcaae":"Dodaj pokwitowanie",
"1e154f31c31b63a459f3956e380b3ef9":"Dodaj wybrane",
"ba24db9956f156a18b32e653552ff682":"Dodaj jakieś osoby do tego zespołu",
"52bd93fb5489b25167bb541f7c6504ae":"Dodaj kartę źródłową",
"21ab3f08eb507dbc76df166eafeb393a":"Dodaj użytkownika {fullName} jako osobę zatwierdzającą.",
"f879c06507d3076bbf52ea4fd2df850e":"Dodatkowe szczegóły karty",
"21e934ae30eece4a894337701f497638":"Wszystkie",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Wszystkie karty",
"8fac71195e2b506e029752e4864b6a5d":"Wszyscy członkowie",
"f9708417df16227742e0545a8642eea7":"Wszyscy dostępni użytkownicy zostali dodani do tej karty.",
"cb5abf070f8cba7982205038baab77bb":"Wszystkie karty",
"dae7d602eec2c84dea0d80ae25e56e6d":"Wszystkie karty",
"0b4bdfde6578668b6018d3615a1ad30f":"Wszystkie centra kosztów",
"97b875b81f54b4432d998a3e47d949cc":"Wszystkie zespoły",
"f0ae83969f7c20a1964caf5156dae966":"Wszystkie zespoły",
"d87455601a00282078582b36a3825b7d":"Wszystkie zespoły",
"24c7b7f55882ea94a525392ca8218fa0":"Wszystkie zespoły",
"8f860e35b64a8f447bded1e5ea66a2a8":"Wszystkie karty wirtualne",
"e403956ecaa6fb33ec1fef5c49d71042":"Wszystkie karty wirtualne",
"4e6f4e8f25f61d40e1eba77de94f6458":"Wszystkie Twoje dane finansowe są zaszyfrowane i przechowywane na serwerach w bezpiecznych miejscach.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Kwota",
"a4d099d0dbe3889b0995546e6ed28932":"Kwota",
"6c0ef32aae76bc31a61e2124f542bdf1":"Kwota",
"adec89c93525f1a8d1c64d856f405e27":"Kwota: od najwyższej",
"18e632a170203894f40deed1d33ee552":"Kwota: od najniższej",
"a7944575cafef9126a499a1b06999fe3":"Wystąpił błąd. Spróbuj ponownie później.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Zastosuj",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Opcje zatwierdzania",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Ustawienia zatwierdzania",
"492eedd88cdd7bb45f39c6594af748c4":"Limit zatwierdzania",
"437d490afc8d226f8c7d544f4e35d011":"Limit zatwierdzania ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Zatwierdzenia",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Zatwierdzenia",
"17adb7fe866e32055b1469fdb7e82795":"Ustawienia zatwierdzania",
"7125aa13db437084e1d27d3ff38f22e2":"Zatwierdź",
"84df787ff5746f9dc3cf0146becef4fd":"Prośba o zatwierdzenie",
"7a568654d00245b0bab6c90a1b67d196":"Zatwierdzono",
"179c3e187ad8edeb8348cff222b938bf":"Zatwierdzono",
"ad058d17725870df9abbd4f5ec217636":"Zatwierdzono ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Zatwierdzona kwota",
"4d58ba5c0e03fec1556c243be106e4a3":"Zatwierdzone przez",
"7d3d697fc99f6c4641b326623743f7b7":"Zatwierdzone { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Osoba zatwierdzająca",
"8c45e443fc6748190719d79a092e2528":"Osoby zatwierdzające mają maksymalne limity zatwierdzania",
"4fe56aa6456c108cd288a4ec8acf435f":"Archiwizuj",
"a9b6f93de9630064be9809130aa1e195":"Chcesz przejąć kontrolę nad wydatkami z karty?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Na pewno chcesz anulować ten wniosek?",
"301bfc2ce058f49e0704fec3d162b5b5":"Na pewno chcesz ponownie przypisać ten wniosek do siebie?",
"2181faf8d9973d1f4138e401a762086e":"Na pewno chcesz usunąć użytkownika ${firstName} ${lastName} z grupy administratorów firmy?",
"b982277f29055d0ef6e295fe2006d9e0":"Na pewno chcesz usunąć użytkownika {firstName} z zespołu?",
"456c0ccff14acb808583cd9dece857b6":"Przypisano do",
"bde315b9cf7fd55f4b65aabbbf38d462":"Co najmniej jeden przełożony lub właściciel zespołu musi być do dyspozycji poza biurem.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Uwierzytelnianie",
"1961ec69dd0c8b5718c831312e7f3d0f":"Automatyczne zbieranie potwierdzeń",
"e39f2477cf34d726936a5b5c2b48a4ad":"Włącz automatyczne tworzenie dokładnych raportów uzgadniających, które zawierają informacje o nabywcach, osobach zatwierdzających, potwierdzeniach, kodach kont i inne dane.",
"434ca07dfde0eaee14f094b7c75a6eae":"Dostępne",
"eb85d8f50f2c89871b794f7d54279b22":"Dostępne saldo",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Dostępne { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Wstecz",
"c8258f8cf9e20f6bbb89d957de40e820":"Zabezpieczenia klasy bankowej",
"d46c22a3f36dce568c07fcea4368b231":"Ustawienia podstawowe",
"fb481c8e517016094b4fdf618c5fa088":"Adres rozliczeniowy",
"79a9f821923c5a04c076902fef684281":"Adres rozliczeniowy",
"877a08b8347052c83aa0b689baea81e7":"Adres rozliczeniowy",
"97f018458e2627fac37d24846c7b8922":"Przechodząc do utworzenia konta i korzystania z Tradeshift Go, użytkownik wyraża zgodę na",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Przechodząc do utworzenia konta i korzystania z Tradeshift Go, użytkownik wyraża zgodę na",
"912d1864f0068c764bf4784020795d49":"IMIĘ I NAZWISKO POSIADACZA KARTY",
"d53474c7fd99fbeb69481ce9d358aa09":"Może wnioskować o karty i mieć portfel na wszystkie swoje karty. Każdy członek ma przypisaną domyślną osobę zatwierdzającą (przełożonego) jego zlecenia.",
"f30df2cab105ed3f8b4264aeb9800abd":"Może zatwierdzać prośby i tworzyć karty dla członków, którymi zarządza. Przełożeni mają panel umożliwiający monitorowanie wydatków członków, którymi zarządzają.",
"03ed9eccc71ef59165292eac542d3050":"Może przeglądać wszystkie prośby i działania związane z wydatkami w zespole. Właściciele zespołów uzyskują również panel do monitorowania wydatków oraz portfela zespołu, na którym widać wszystkie aktywne karty.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Nie można usunąć tego użytkownika. W zespole musi być co najmniej jeden właściciel lub menedżer zespołu.",
"a0b70642629a55ea0ab6fa3414d342e0":"Nie można usunąć użytkownika z oczekującymi wnioskami.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Anuluj",
"5446624d3c364ac05ddf8d6663da2796":"Anuluj kartę",
"53f3815c4fa3340b29efd81e627d1e6b":"Anuluj kartę",
"12257ea2a871b7fab72245b5aafe6424":"Anuluj zlecenie",
"2aafb7eb4d56a516c4bb0754f0812df1":"Anuluj kartę",
"caae12601b01e2325161e95107f9e332":"Anuluj kartę",
"6ad1e57b1e4c7accbe166c948883077d":"Anuluj kliknięciem w razie naruszenia bezpieczeństwa",
"f6527e49e6d5c989624507a583e23f39":"Anulowano ({count})",
"1ad3bf5121641dda511f42942af05a90":"Anulowanie tej karty nie spowoduje anulowania oczekujących opłat. Poszukaj zapisów uiszczenia",
"aebd3826a195afad0a93050a7cc5d73d":"Anulowanie tej karty nie spowoduje anulowania oczekujących opłat. Poszukaj zapisów uiszczenia tych opłat na wyciągu z karty kredytowej przesłanym przez wystawcę karty kredytowej.",
"a5b5136947da562f91b75daf8156f50e":"Dane karty",
"9ed31ad23a1578ce480d31207decf1d3":"Ustawienia karty",
"def15f2e007fc69886230fdb2511305c":"Dane karty",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Dane karty",
"67cfd260078ca32027c2fa4a4a2d29f4":"Posiadacz karty",
"4458819ea60c68c46e551a8f5cc163cd":"Nazwa karty",
"0858bcbd506fa3240b165e59d2f18aae":"Nazwa karty",
"5212736dd18ee2c31f3650ad2c2c7130":"Zaktualizowano nazwę karty.",
"c96d62fa510c3356dc73a91d1a80e262":"Numer karty",
"41307fee4c32aefb5383a98fe3d6e45e":"Typ karty",
"3f8045c3358621dad4346a44de0c05a8":"Imię i nazwisko posiadacza karty",
"879a6b452033cf5f3a4bb3e1837a0386":"Miasto",
"593334553ef852024e172505ec3c5f21":"Kliknij tutaj",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Kodowanie",
"df931751d5790d74fa46707aaf7a26ae":"Kodowanie 1",
"7ac7154bc776aff52a71b0d83b860561":"Kodowanie 2",
"586fcf19136ae5e26795e9055b2e2995":"Kodowanie 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Kodowanie 4",
"379bb8e71bb090badebbb0751de86f11":"Kodowanie 5",
"3e84d13627b3d090ab2b8470af98340a":"Kodowanie 6",
"33b94b581f3792049992663396e51cb8":"Kodowanie 7",
"b73fbc32e439655833ee99126dd0e55f":"Kodowanie 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Lista kodowania",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Pozycja kodowania",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Skasowano pozycję kodowania.",
"cada62a39ee72d790dcdbb25160f8783":"Zapisano pozycję kodowania.",
"f645bf50888943ee1384693e56a0f475":"Nazwa listy kodowania",
"a999182acbcd77c15299f79181b630be":"Zapisano listę kodowania.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Listy kodowania",
"d0b96596ce6baec5aabb8a0690ab30e1":"Komentarze",
"ff9cb8842f832d21fb160b3d3784ce79":"Firma",
"715b59a64e686eb91a21114644ffe39e":"Usunięto administratora firmy",
"8758e9a01253c097a42393fda9610715":"Panel firmy",
"e118b41009918b326db6746856831cbc":"Identyfikator firmy",
"4dba88b05b3fa8875128e19e88c512b3":"Ustawienia firmy",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Ustawienia firmy",
"f2ea6d33de8182d97bf2f13354af4893":"Ustawienia firmy: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Administratorzy firmy",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Limit zatwierdzania firmy",
"7e2f778da14a726b53e05926a3483f2b":"Firmowa karta kredytowa",
"7313fd1db3d63858586d1c0f89251d6e":"Nazwa firmy",
"07721e91dd10ff8831f0d24e47cc1eab":"Nazwa firmy",
"203221610ca4d347063c037e1a732ae8":"Zapisano ustawienia firmy.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Potwierdź działanie",
"d694cec09cdbc02b1ffe22642a63faa1":"Potwierdź hasło",
"512801312a133f29974f2259a455f39b":"Potwierdź hasło",
"f54c38ed0b9869d929ffc0bd5310131f":"Potwierdzenie — utworzono Twoje konto w obszarze zespołu {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Skontaktuj się z administratorem firmy w celu utworzenia kodów księgowania.",
"7a6a4de077398e8e138aec36e3b3d305":"Kontynuuj przy użyciu logowania jednokrotnego",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Wszelkie prawa zastrzeżone.",
"51f0d8e04f6ee626b866b37833dc2b61":"Centrum kosztów",
"e043f60a02f157d0f31a42f0db67cc87":"Nie udało się zaprosić {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Nie udało się wysłać wiadomości. Spróbuj ponownie.",
"ae28083f22a0b2fc5637614bce0976e0":"Utwórz listę niestandardową",
"738078dc992208e05406f935995dc0c3":"Utwórz listę niestandardową",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Utwórz nową listę",
"0f5783c3921b3d7398d281644bbe5bde":"Utwórz wniosek",
"6d62f934f2304701f88bdcfbc688de7e":"Załóż konto",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Utwórz konto",
"f6b7d0d8531ea268f988005dd2147b2b":"Utwórz kartę",
"1a083675d1453448e4645403bbe52dd9":"Utwórz listę niestandardową",
"c10ab11bdfb001da74850ed89c45482f":"Utwórz nowy wniosek",
"43a9c06398408bfe77d06ffc2c1c3b87":"Utwórz nowy wniosek",
"68bf17fd3f54a89c0a84224e2f9a971c":"Utwórz nowy zespół",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Utwórz zespół",
"3b8e665101b9b3657f8a68c5305947b7":"Utworzono",
"40e91f2907181ffa8e62567b3a407e60":"Utworzone przez",
"8929f7aacb969ef6353aba9903eb362d":"Dodano kartę kredytową",
"d86688c26e8ac5eb9facc971b6fe5155":"Waluta",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Waluta",
"500ded53f64e737a5e4337675ac9b7ce":"Nazwa listy niestandardowej",
"b79a95f04fd829fb2e8d0313e522a94d":"Codziennie",
"ef445eab224fc599e4103c9b1745a4e0":"Panel",
"788452c3ad2b1450b6d8d116b863e635":"Panel",
"fb7add662cf7042355319a975fab6ae3":"Szyfrowanie danych",
"6bded249dbfafd14490eea8dd37e1f51":"Data",
"46e908a57cb09ef947af8c4a93a09716":"Data",
"e4213104a55eceb60e00408699438dcd":"Dezaktywuj zespół",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Dezaktywacja zespołu spowoduje anulowanie wszystkich aktywnych kart wirtualnych i żaden z członków, przełożonych ani właścicieli zespołu nie będzie mieć dostępu do zespołu. Zespół będzie mógł zostać ponownie aktywowany tylko przez innego administratora firmy. Na pewno chcesz to zrobić?",
"0d0d5677bee2b99884802638e507160a":"Odrzuć",
"b5e3483aae9d36ce1440fe02904d6da1":"Odrzuć prośbę",
"00f3db97287cb17ee9c2a131f2141a65":"Odmowa",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Odmowa ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Domyślna osoba zatwierdzająca",
"b77589ac3ac2317cfc96d7013658d5c9":"Skasuj identyfikator kodowania: { entryId }, pozycja: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Szczegółowe wyciągi i raporty",
"5234dfc4220ccd18a8b62fe84e372248":"Szczegóły",
"37929896817a6744718004374bb42fd8":"Szczegóły",
"dd0a6194e8a6ae14f3e1379a1640de15":"Chcesz usunąć użytkownika {fullName} ({email}) z firmy?",
"1a98048b34090da8b199135ccb4c1186":"Niepowtarzalny numer i kod CVC skrywają prawdziwe dane karty",
"3cf132b60fe16b816ca227bbcad60537":"Nie anuluj",
"dee57797e14ccf852aa2bc545bafdf6d":"Pobierz",
"375ae8224d7d35393974775cebe8605a":"Pobierz listę użytkowników",
"54b3bfd45a293b441d441aab1a50e644":"Pobierz raport",
"60cf3b94e5df14a504825068928fc118":"Pobierz raport CSV",
"7341a4c73c0b688484304c37da4a6d8e":"DATA WYGAŚNIĘCIA",
"f628f8ad44fbd6c70c99fc778407b666":"Możesz łatwo przeszukiwać transakcje historyczne i badać trendy w wydatkach pracowników w dowolnym okresie.",
"94bb409d034ea20697ae09d812e55365":"Edytuj listę niestandardową",
"2974e704676cb79666bc236d8658fa97":"Edytuj profil",
"9ff205f99ed4002d049f855f965ca6d8":"Edytuj użytkownika",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-mail",
"0534c00af758d42ebd8b0bd772eab074":"E-mail",
"0c9aa1fbdf925065d25161eab5e51d95":"E-mail",
"ea78fac68766a48b1b0f41305b7e9898":"E-mail",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Pracownik prosi o środki z karty firmowej na dokonanie zakupu. Wnioskowane środki mogą podlegać limitom wydatków.",
"829cff16ce0b13810c914c161b2a9fba":"Włącz inne funkcje dotyczące użytkowników",
"741387c7bd71e2b4a9601af9a6b9490e":"Włącz automatyczne przesyłanie próśb o zatwierdzenie",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Włącz domyślną datę wygaśnięcia karty wirtualnej",
"bb319710dad93e78cc6f58c615bdf210":"Włącz tworzenie białej listy domen e-mail dla zaproszonych użytkowników",
"4700ced24cd278c3149ffa983006035c":"Włącz filtrowanie według centrum kosztów",
"3fe06ef8f551c4cb4c0375362e25521d":"Włącz opcję globalizacji w obszarze bocznym użytkownika",
"2787d4bc9752272ac36b499ce7ad93ab":"Włącz karty wirtualne do wielokrotnego użytku",
"6ee44e2280c9ce5009568846cbc3dd14":"Włącz tryb Poza biurem",
"1941ca87053df0cebc6091ede92c18ea":"Włącz widok osób dla administratorów firmy",
"e50282c2ec7afb948d2c0b428db5d5ff":"Włącz przesyłanie pokwitowań do transakcji",
"268694cb2172918fe60f835919bf20ec":"Włącz przesyłanie pokwitowań w tabeli rozliczeń",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Włącz zatwierdzanie własne",
"d17c7acf598b569cce8a2bb97e0bb215":"Włącz karty wirtualne do jednorazowego użytku",
"cd731ca218c9e14caabefafe003cfa6d":"Włącz subskrypcyjne karty wirtualne",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Koniec przesyłania",
"c1f665a814894b17d603b7052b631d90":"Zarejestruj nową kartę",
"6b6cea93e07e596be70f7b76801e1516":"Zarejestruj nową kartę",
"7d6ac1ac9cba1620b61908842ebb106b":"Zarejestruj kartę źródłową",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Zarejestruj kartę źródłową",
"21a642b339c3eabf2789c0b05dcf828b":"Zarejestruj swoją pierwszą kartę firmową w GO.",
"6efa8dca4fba23a190838746879fd704":"Wprowadź identyfikator numeryczny",
"02c3457577da9dc8dba6e6f9380a248e":"Wprowadź unikatową nazwę zespołu",
"40feb3fd1fa529cb599aeac9607664a4":"Wprowadź nowy wpis",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Przykład: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Miesiąc wygaśnięcia",
"b8bc74aeeb2de3492891c97361de107d":"Rok wygaśnięcia",
"6a0b8d521500014d99dd192d8ce8403d":"Data wygaśnięcia",
"8cf274169a732340430fe7cc5236e0e7":"Plik musi mniejszy rozmiar niż {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Imię",
"7458b55e967e51791d98e77458631e44":"Imię",
"3bbe52e71bc5c212cea77983f1a5b748":"Imię",
"9b49c88fa20cc006baaac15c857c8781":"Na potrzeby jednego lub wielu zakupów",
"bb7a2907073bd760f4e82097132637b1":"Nie pamiętam hasła",
"26bdfaa592f3629273f13c22435b323d":"Nie pamiętasz hasła?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Prześlij",
"3f9fe6adc14ce5776097170653a24462":"Prześlij prośbę",
"b774e35683f5737c36bf0cf3d6785973":"Prześlij prośbę",
"628faa5054a912b1e470414f77057e6b":"Prześlij prośbę",
"852b4065e1837fdc77940b6411a6d902":"Ochrona przed oszustwami",
"616551bd10d95aca439401d6a7769d96":"Kwota frachtu",
"a65a230918e9c11e1ef8824745e26a3d":"Rozpocznij",
"7d4c4466b7716347ce3673acaa1983f7":"Wykorzystaj lepiej potencjał Go",
"11415a07658cfef80eb166896d77ad98":"Przygotuj się na jeszcze lepszy wgląd w wydatki. Tradeshift Go będzie teraz lepiej dopasowany do metod pracy działów biznesowych.",
"ac0ff5d14286ef74614a9c6021f08f69":"Zyskaj sposób na monitorowanie wydatków i budżetu w czasie rzeczywistym, analizy i dogłębny wgląd we wszystkie działania związane z zakupami.",
"db9071ab833054307252a936e7f60e1b":"Rozpocznij",
"24dc4bb00841b004031fca3a9bb9bf51":"Zamówienie karty Go zostało anulowane przez: {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go przypomina pracownikom o wszelkich brakujących potwierdzeniach, które można załączyć przez proste wysłanie odpowiedzi na powiadomienie lub — w przypadku potwierdzenia e-maila — przesłanie go do Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go współpracuje z operatorami kart kredytowych, aby zagwarantować takie same standardy zapobiegania oszustwom, jakich można by oczekiwać we współpracy z najbardziej elitarnymi instytucjami finansowymi.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Dobra wiadomość! Twoje zlecenie zakupu zostało zatwierdzone w Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Masz pytania lub chcesz się podzielić opinią? Skontaktuj się:",
"9df1d3de68d663b848101df3c7b58e39":"Jak to działa",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Na pewno",
"84074732eb8d1b4094404cd750baf949":"Jeśli nie podasz żadnej wartości, będzie stosowany limit firmy",
"138e8bd6102a7eea70a123ff03b1cd79":"Jeśli nie podasz żadnej wartości, będzie stosowany limit zespołu",
"e5756af4d7e5ad1236ccfbb858428421":"Jeśli jeszcze nie dokonano rejestracji w Tradeshift Go przy użyciu tego adresu e-mail",
"4f004f75f47dbfecdb6d09990596e530":"Jeśli Twój e-mail nie jest jeszcze zarejestrowany, wyślemy Ci e-mail aktywacyjny ze szczegółowymi informacjami na temat dalszego postępowania.",
"123837f267de602fb05361b3b1197c1e":"W przypadku dopasowania nazwy użytkownika {username} do istniejącego konta, wyślemy Ci e-mail ze szczegółowymi informacjami na temat dalszego postępowania. Jeśli po kilku minutach nadal nie znajdziesz e-maila w skrzynce odbiorczej, sprawdź folder spamu.",
"45efd96cd93525a9c17222217a37d324":"Obsługiwane są formaty plików obrazów lub PDF",
"fa6ae823f9ab8bf91c246efe2517956c":"Zaimportuj swoje konto QuickBooks, NetSuite lub Xero bezpośrednio do Go. Możesz jednym kliknięciem wyeksportować najnowsze transakcje do swojego rozwiązania księgowego.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Nieaktywna",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Dane wejściowe zawierają niedozwolony znak.",
"62c7be09da43f8f96a60ef3967eddc2c":"Przedstawiamy zupełnie nową usługę Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Nieprawidłowa domena e-mail",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Zaproś osoby",
"3809b41b14c55c3fcf54cd813d8e21f5":"Zaproś do zespołu",
"fc90a11805961b0d7b5a5545e4d9bc05":"Zaproś użytkownika",
"6f7f4acde92d267417f5d8a60d32400a":"Zaproś więcej osób",
"09ecd2ca15bb5c38dc10f653118346cd":"Zaproś nowego użytkownika-administratora",
"3f576ce504f53f972442a63a170c5418":"Zaproś nowego {role}",
"0215e052437ceea4e5cd562431b34ced":"Zaproś osoby",
"8aaf2c77df644b7e8524120f66da7d5f":"Kwota faktury",
"1b7dc8290f1205f092cc9133e0cde385":"Data faktury",
"1a6fb210308df8e0135e49e76aaff31e":"Numer faktury",
"613da3d0cd586ef0360e0d93eace9508":"Wystawiono",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Potrwa to",
"3322b671105c65511fbab1966b7fdcf6":"Język",
"b1d946192795a58aea6a61f4f923acb7":"Ostatnie 4 cyfry",
"b3c6b8db4ef6dcdcb451303e65548816":"Ostatnie 4 cyfry",
"0382123b9c847000fceff2484c665e39":"Ostatnie cztery cyfry",
"19f7c0055e150e9e3e66e85c2993799c":"Nazwisko",
"662c84bede8a7bf91ab15bcb73b48b49":"Nazwisko",
"cff8fbef5aac2523344b9535bd56973c":"Nazwisko",
"c3510b2184a92ff12e07a2d1b9a461e6":"Daj pracownikom możliwość wnioskowania o dostęp do karty firmowej bez ujawniania danych karty — wszystko to przy wymuszaniu przestrzegania limitów budżetowych i monitorowaniu każdego zakupu.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Limit można zmieniać na poziomie zespołów i poszczególnych osób zatwierdzających.",
"9bf5d56627e500cad9f7ad21c3e7966d":"Ogranicz do pewnych kategorii wydatków",
"e80a6d4232e52f6bfabee02e17403b59":"Powiąż z tym zespołem jedną z tych kart źródłowych lub zarejestruj nową.",
"803570ab9f0fba5ff35ae2749200501f":"Ładowanie kart...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Zaloguj się",
"ad96c1ca962a883652dadb845f439415":"Zaloguj się",
"072ca67e735adbed54acf41321370701":"Zaloguj się",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Zaloguj się",
"b246fe8836c906f1b56342378ca88895":"Dokonywanie, zatwierdzanie i monitorowanie zakupów w zespołach odzwierciedlających strukturę organizacyjną firmy.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Zarządzaj rozliczeniami",
"95d672ab621e531ae1a69798e0e4b56c":"Zarządzaj wszystkimi swoimi kartami wirtualnymi w jednym miejscu.",
"96f2e88a167f476413b35aa711709e6e":"Zarządzaj rozliczeniami",
"151d8e6ceec242d482fff60da8311449":"Przełożony",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Menedżer",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Przełożony otrzymuje każdą prośbę o przejrzenie i zatwierdzenie. Można też dostosować limity wydatków.",
"4f449985e83c1f9ca2e8f82b0707a371":"Przełożony otrzymuje każdą prośbę o przejrzenie i zatwierdzenie. Można też dostosować limity wydatków.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Przełożeni",
"27f8111a955975ea6fd04f748d24cb4a":"Maksymalny limit zatwierdzania",
"5b1d9cfb78c55e75b47c62435d9299ca":"Członek",
"41e307c1874e2e82296b226e9bae4a5f":"Członek",
"6f9612aa1582373c2c1ba4e144132100":"Członkowie",
"590429a0fcc42af85648d0fd70601dcc":"Członkowie",
"3f50e62c8488d83ad84f69ebda853897":"Członkowie",
"e0fc0acda5b74b93e797756dbbba67bc":"Członkowie",
"c7ef9eda38b7eaee1f20031387a8bde7":"Miesiąc",
"40dd08b507752bef4e3c4b36838317d5":"Co miesiąc",
"d712b91f7498f212047aad4c6d0f8088":"Najnowsze",
"12110d95b3d7a495afa4f28ac190f63e":"Najnowsze",
"2f11ce3865f79ee7e5ee64d728082264":"Najnowsze",
"eadc93865655cffc6a9863fdda5693e1":"Musi się składać z co najmniej 10 znaków oraz zawierać wielką i małą literę.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Musi się składać z co najmniej 10 znaków oraz zawierać wielkie i małe litery.",
"50d0a69aa59df5201018771a06ea12e7":"Najnowsze",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Musi być unikatowe.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Moje karty",
"c36602f50d35c2816f0ba8a196b70876":"Moje prośby",
"ff6e372aa3eb685a39a701963db35b2f":"Moje prośby",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Imię i nazwisko",
"1c7514b561a2591a914225813d30d642":"Imię i nazwisko",
"d621826c1ceade262589af04e655e824":"Zażądano nowego hasła",
"35dc3304b61a9e69dc3a393123658837":"W tym okresie nowe prośby będą przesyłane automatycznie.",
"e788124801b4727d121f60d059a2a4a7":"Następny",
"eca804f1d53dfd984ffd93e2d00fc284":"Nie przypisano żadnej osoby zatwierdzającej. Skontaktuj się z administratorem.",
"abdf7e93f8509399a63108a2b1a25546":"Brak działań do pokazania",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Brak kart w Twoim portfelu",
"87127e1e9f5bf922d0a4f948b68697c8":"Bez limitu",
"40512efa6b75ef0c7924b1ee3070fca9":"Brak członków do pokazania",
"dcda493afcb2415d5ca49a17d1af6f50":"Jeszcze nie zaksięgowano żadnych rozliczeń.",
"f0efb26e623bb990532e375d5c8875ec":"Niedostępne",
"bc0cf602d1978e814070bb552e576a32":"Nie zamapowano",
"09e126c317429ccb52ad323c32293648":"Liczba płatności",
"75b00b969b9d3b520929f1e652637b70":"Liczba płatności",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Liczba płatności",
"bd44ba180aa356c5755fdedd32adf03c":"Ups! Podczas tworzenia wniosku wystąpił problem. Spróbuj ponownie.",
"c396b3728dd7393aa9e5427990c132a7":"Ups! Podczas pobierania raportu wystąpił problem.",
"42c07764b07dea1ee7de7605721c3f5f":"Ups! Nie udało się utworzyć nowego zespołu.",
"e3a385878ec94bac12a99490d0a8e85a":"Ups! Nie udało się utworzyć konta.",
"17f2dae79677017e2c2e879578c4e09a":"Ups! Wystąpił problem. Spróbuj ponownie.",
"78c135de545c1663c9768226492a44a3":"Lub użyj logowania jednokrotnego",
"9c038198d567c5379b055ff5ff662e9f":"Poza biurem",
"5d7e45f169e2f6968dc58f43b5267353":"Poza biurem",
"ee589a20c262527db970671fb2c4f6bc":"Poza biurem",
"4fd0c753fce49a016b98d988a3895223":"Informacje ogólne",
"2789c5d5c9c042858f45aadc4e0de058":"Informacje ogólne",
"083b7090b66ebd0307a18499ac7b676e":"Strona nie istnieje",
"a21d205920893cf579a7655a1430fe30":"Karta nadrzędna",
"fd35b7ce938e404fdb65de3bb749c528":"Karta nadrzędna do raportu",
"e8c7d2eea7db66ecb44751091a582304":"Hasło",
"bb79e316e00fd21138750b6986e44455":"Hasło",
"6cae2279088580976d84119d95097cae":"Hasła nie są zgodne",
"4baec0159d3074f84c0eebcc09ba4cd8":"Hasła nie są zgodne",
"b676587115243921602742cb8f696a6a":"Wstrzymaj tworzenie kart",
"db4a26c871ef0b970b74e1f71763098b":"Wstrzymaj wystawianie nowych kart wirtualnych",
"33fc1e6d01b785e3a815c28ef7d56667":"Oczekujące",
"6ea62e4811b68df48b467e8d367aac2f":"Oczekujące wnioski ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Osoby",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Członkowie zespołu mogą mieć trzy role",
"e2d34cb1409b9778d34c4b74ecee1df6":"Wprowadź liczbę całkowitą dodatnią lub pozostaw pole puste.",
"8da247d5d736c87122404eff1e5c0c73":"Wprowadź prawidłowy limit.",
"65e154267ac283d963cbc7be832969d9":"Wprowadź prawidłową wartość",
"28b4450d417065a69a395f567967d46c":"Zdefiniuj żądane limity wydatków",
"7bbf151d8493170f206a23c2ecc6acd0":"Polityka prywatności",
"51602962f2d1e7515943b4a6c66562ce":"Zdjęcie profilowe",
"a51a17940c70a3123da4d68bed562c40":"Zapisano profil.",
"4d398249d01ab72d697a7e219493c637":"Dostawca",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Mapowanie dostawców",
"89b1deb2ffe12a13941c362abaeab01b":"Opublikowane",
"d8d806d48052159e3ff3b224f3f53586":"Cel",
"089917619c78af2efb30be0bb7ce076c":"Ponownie aktywuj zespół",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Uzasadnienie",
"e8484c78fda9f453eeb942ba4945f040":"Przypisz ponownie",
"ff2fbc1172ba26776ad0982624cd2713":"Potwierdzenie",
"588e690db177ee0c7a7cadb39cbd5efc":"Odświeżaj fundusze co tydzień, miesiąc, rok",
"32955937daca05b2084556f248692baa":"Zarejestruj",
"f15bb755ba24abe6ab106637292175ec":"Pozostałe",
"579782461133a8b8cc6bd77798961d82":"Usuń kartę",
"e475edc165372f8c8deb532aa85d9950":"Usuń listę",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Usuń listę",
"f9769561315b964d0b3b4887ec208977":"Usuń z zespołu",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Usuń potwierdzenie",
"ee181cef581fc8e8217e66b5762db898":"Usunięcie listy spowoduje wyłączenie jej z użycia i usunięcie wszystkich dotychczasowych wpisów. Jeśli chcesz tymczasowo usunąć istę i zachować wpisy, wyłącz ustawienie publikacji. Na pewno chcesz usunąć tę listę?",
"feea41d0bc1d577a07c8b6e1297abbde":"Usunięcie karty źródłowej z zespołu nie spowoduje wyłączenia istniejących już kart wirtualnych.",
"d09483824a2e0d6194a4c3f32311e752":"Usunięcie karty źródłowej z zespołu nie spowoduje wyłączenia istniejących już kart wirtualnych. Na pewno chcesz usunąć tę kartę z zespołu?",
"7ca4b1bf1346e179a29da97f260a93a4":"Zmień nazwę",
"db195ec9c818b8e92da142374e20d1bc":"Zmień nazwę listy kodowania",
"2fd93e229fe9ea754808805d3fef9c13":"Zmień nazwę listy kodowania",
"aa1e74d32805823f802a11b33c60c38b":"Typ raportu",
"6ac78d97a8cb7f060e43e596e3e23535":"Anulowano wniosek.",
"25deccb1980291a0fa9997109c731440":"Poproś o demonstrację",
"9f2754d87686903b1500ce8e8a55b377":"Poproś o demonstrację",
"92ac924f72679f897e26f3e615be8c22":"Przekazano wniosek.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Poproś o informacje",
"270185007853cd779011ae0e8433f723":"Zmieniono przypisanie wniosku.",
"e5a059c9ba2691910321339cce989f9d":"Przesłano prośbę.",
"77c88a231edfa7075114d91d2397e42d":"Tytuł wniosku",
"cee91b833c4358acf0c554f746557e71":"Odmowa wniosku.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Odmowa wniosku.",
"9e56abbbf87420eeedd625ce786515c7":"Zlecający",
"b4cb4426e44fee9580af71912a764536":"Prośby",
"df309e888c29fc447d0309329b39ce93":"Zresetuj hasło",
"5daf278da19ecbaeb7b96554cb4db10f":"Spróbuj ponownie dodać kontakt",
"31e9501526d2114e4a01fdbf076de097":"Rola",
"6fbceb787429f6f81117a1d5a58b4610":"Rola",
"473b814ca86841ef672ccf9f7dac28d5":"Rola",
"f1e16d5fd643e039d865b6aec7c986f9":"Rola",
"ec78345a5975bc0f8efaee5256b43c2c":"Zapisz",
"2cecea57b47d8f4a45c706287ff7d64e":"Zapisz zmiany",
"15774be5c338a39169917c7d32781dd0":"Zapisz i kontynuuj",
"844095b4b8f580737b8717672501048e":"Powiedz coś...",
"7e66f676c2e7b04514aca3a0935530f0":"Szukaj wg informacji o kodowaniu",
"5293ce9335ec2d176a6e838bf76404c6":"Szukaj wg opisu",
"efab3b0c95f263cdd0a8466329a387eb":"Szukaj wg opisu",
"d227b38dcbc4548c092b0836a7101506":"Szukaj wg nazwy handlowca lub identyfikatora transakcji",
"3629383abf1d5f85efd759f718159b40":"Szukaj wg nazwy",
"a034fcd6719228b28a6fc22d3a46ea8a":"Szukaj wg nazwy zespołu",
"8ac7e0d63f343b548482d5f367f5b3d9":"Szukaj wg nazwy zespołu",
"858323eb52cb45446597ee46330a0540":"Szukaj wg właściciela karty wirtualnej",
"111bfcbff671e13de117ed5195fb6773":"Wybierz",
"bcfecd59eb93e5314873d84ddf35aa58":"Wybierz",
"909a4414ea573218bff2d533eaab46ea":"Wybierz datę końcową",
"114b206ca1ff1c0bc52abef342059b2a":"Wybierz opcje zespołu",
"81a02e424615fb41a20b8d0f13441c88":"Wybierz zespoły",
"b135b36495618e6692e7fbaf0a0aa83e":"Wybierz miesiąc",
"677e90855e164c00a28b5c4763ac67e0":"Wybierz rolę",
"d01570f3774738395b8ba3c64ce54c76":"Wybierz stan",
"7dee3f06c70626881efb0cd6af6b63e1":"Wybierz użytkownika, do którego ma zostać przesłana prośba",
"4c7bb810835a1540ddc1f6501da5a51b":"Wybierz rok",
"3ab198d2678af2b5b32180e4b39602ba":"Wybierz osobę zatwierdzającą",
"f502ba79f2bd010c5e77891812400958":"Wybierz kartę",
"936027182d446e27ba209ea921ef7502":"Wybierz domyślny zakres dat wygaśnięcia",
"b22c893254ccf3bd34faaee511cf7ca8":"Wybierz czas trwania",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Wybierz jedną z gotowych list kodowania lub utwórz własną nową listę.",
"42cce5fa376b45bf290f86c684d69d96":"Wybierz pole usługodawcy",
"b761773d46699b60e8d6874b85593374":"Wybierz zespoły",
"3a9396756942ede98d0cb6afc1032b15":"Wybierz osobę zatwierdzającą",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Samodzielnie",
"03e9d2c128af288126d94f631c5396be":"Wyślij instrukcje",
"299a7e961804e2d649f06b5dc4362c29":"Wyślij zaproszenie",
"155e7b3f7db7bf10b03b5f41de54eae1":"Wyślij wiadomość",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Ustaw datę wygaśnięcia",
"f89ec96a4b5bb639488ab742efa76a6a":"Ustaw nowe hasło",
"7d5377ad70d57728a45d3c0639de5544":"Zapisano ustawienia",
"14313f11dee735f2144953e720776c28":"Kwota rozliczenia może być inna, jeśli zakup jest związany z napiwkiem lub innymi opłatami",
"199000fe0cda0adaaa9b864aa7447cb9":"Rozliczenia",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Rozliczenia",
"6e6ac6ff3bd722e37874fb1ea7115818":"Skonfiguruj hasło",
"9b4ecf052980e80bf5c65a415d470a43":"Przyznawaj i kontroluj dostęp do karty firmowej",
"ba746d78772d6be26cc508ba989becb0":"Powinno być mniejsze niż lub równe {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Pokaż transakcje bez pokwitowań",
"7d474b60aeb7cc25b519e806fca71e12":"Zaloguj się przy użyciu swoich obecnych \n\n danych logowania Tradeshift",
"16080cd4e8319ef3e7de21b6c19d1437":"Coś jest nie tak",
"8f358a370224baf849f9652810c68db2":"Wystąpił jakiś problem",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Wystąpił jakiś problem",
"99af87c475a4d40ef732deed96f11ed2":"Wystąpił problem. Spróbuj ponownie.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Niestety! Obecnie występuje problem z połączeniem — za {retryConnectionPeriod} s zostanie podjęta ponowna próba.",
"84debc8e41646cd49ac2b14708ca6d73":"Niestety, podczas kasowania pozycji kodowania wystąpił problem. Spróbuj ponownie.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Niestety, podczas zapisywania pozycji kodowania wystąpił problem. Spróbuj ponownie.",
"689d3418ee8f996ad25e152e80de63fe":"Niestety, obecnie nie można Cię wylogować.",
"b48efd4521ecd6428987d24636a4349f":"Sortuj",
"776a95003d3ec1dd58af69aedbc08579":"Sortuj",
"1b783238028c0ae085ca69ed823f4d1a":"Sortuj wg",
"be70d19bf13c935d653b2c4fe7d36224":"Nazwa karty źródłowej",
"564f8c942e7d1bdda5251545612fa348":"Nazwa karty źródłowej",
"aa284602c746aad4a6de6366c24221b8":"Karty źródłowe",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Karty źródłowe",
"389d471e79a43440b3278b0fc7187d9e":"Karty źródłowe",
"106342f689b57145817b00d6fe383f91":"Konkretna data końcowa",
"2a5e19387ff300509876e3bae99b7ab9":"Przyspiesz księgowanie",
"ca34d586bbdb7f368f1009305bccbe76":"Panel wydatków",
"4583120998b44c0a5982cf85dd938d2f":"Wydatki w czasie",
"244d724806247953233a0bbfcbf8da4c":"Limit wydatków",
"d39b83dd151c8df6370460a458294ccf":"Wydane",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Wydana kwota",
"5de2859528e030ac1f2af115ebdf209c":"Wydane { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Rozpocznij przesyłanie",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Status",
"1f1fe111548158ec842761eca7425ff3":"Ulica i numer",
"31a0a748b1097a9e0aa95a811802bdd0":"Bezproblemowe uzgadnianie",
"111b782f3f0794c2294aefb6693b2c50":"Prześlij",
"013e2555920b51ad2a3d2c3dc482c471":"Prześlij prośbę",
"1270cd8eb69ad64cc71e5de979423b3b":"Koniec subskrypcji",
"2802336ad3d8ff658257b88a93f4e50a":"Koniec subskrypcji",
"b28dd68454d7acbefafeefb42981fe16":"Okres subskrypcji",
"907793bc80cf94d560e5f1a0250fdaee":"Okres subskrypcji",
"29229e153308e4747edef1144de35a55":"Sukces!",
"503eb46b80f374f39266e7a9aea8e63e":"Sukces!",
"4f20e29cadadb9fbc7455bd4d80de567":"Sukces!",
"601d04f10d558f436c78c10e90b44175":"Pomoc techniczna",
"62558c185ea2c06c5472d1cd534f6e56":"Synchronizacje z rozwiązaniem księgowym",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Kwota podatku",
"0cb5d74f7a56fca30930616feb6e2f47":"Zespół",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Zespół",
"bf23b2fd889e3b12e0f0acf99f913eca":"Karty zespołu",
"b90a650376782a9e43ed6be6135bd6f2":"Panel zespołu",
"6adbec395fe1c4bf776f9fe5fa021052":"Panel zespołu",
"6f2695eeb68501dbcfddbb4d265b846b":"Przełożeni zespołu",
"3b779ade9c960ac95a670db0cc12e56b":"Członkowie zespołu",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Ustawienia zespołu",
"dc5235e1b88320d3ba7501e4c30985a3":"Ustawienia zespołu",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Ustawienia zespołu",
"a2eb92bfd7502f47b3cd170bc68ac638":"Ustawienia zespołu",
"f1ec84f051f19f0d0e301a709b13dc45":"Wydatki zespołu",
"fbacde3a262ea8696e30135e8f987a44":"Przełącznik zespołów",
"74b9578ea5a620f5fef102188e42c02f":"Przełącznik zespołów",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Działania zespołu",
"3aa978fa7ef886621285b93257d428c3":"Limit zatwierdzania zespołu",
"a41f9df131c221a99926de7e2138653a":"Informacje o zespole",
"941c7da8f256f91ba3819f991315a100":"Nazwa zespołu",
"c5d04e10cc2f0b53c2bda893eae09178":"Nazwa zespołu",
"504a8ba642ba37c9a743d0fabb2bd371":"Nazwa zespołu",
"e768a8e1e3d6a6bf470587dad75423fe":"Właściciel zespołu",
"e2d824067f8bd871756688c58c382196":"Właściciele zespołu",
"dca232ccc849c5ff5611255eb82baf53":"Właściciele zespołu",
"3b61bd586225790cf132591383d15448":"Przełącznik zespołów",
"bc05b40509f0271c12cfaaef8e5319b1":"Zespoły",
"39e73773d07a800673ec6600422952d6":"Zespoły",
"daabb564bb3dcfb41de69492f854d573":"Zespoły",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Limity zespołów i poszczególnych osób nie mogą przekraczać limitu firmy",
"4b4fe189a48d8eb06d836afa8fb6317a":"Zaktualizowano zespoły.",
"2a17f9a8c610db29fec63fd530c29fdc":"Zespoły korzystające z karty",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Zespoły, które to widzą",
"317868f3804ba4ed5d99dd9919c798ba":"Warunki usługi",
"e8c9a9847db2ccd97524aa06f1af9d82":"Regulamin i Polityka prywatności",
"38852078519c5c46715d8a034fc4901b":"Regulamin i Politykę prywatności.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Dziękujemy za zainteresowanie Tradeshift Go. Wkrótce zostanie wprowadzona obsługa kart {cardLabel}. Ktoś z naszego zespołu ds. sukcesów klientów powiadomi Cię, gdy tylko będzie ona dostępna.",
"f7047b35a069d0790a0a348a29c846a2":"Dziękujemy za dołączenie do zespołu firmy {companyName} w celu dokonywania zakupów dla firmy przy użyciu kart wirtualnych Go.",
"133b0a70b1a956889164621380c378f9":"Działanie, które próbujesz wykonać, nie może zostać ukończone. Czasem się udaje przy ponownej próbie.",
"18f5a1872e156f1aee84ae321780d952":"Kwota, o którą wnioskowano, przekraczała firmowy limit wniosków wynoszący {requestLimit} $. Spróbuj ponownie z niższą kwotą.",
"867e3d4d3c78990c6852fc5348706ab2":"Kwota, o którą wnioskowano, przekraczała limit wniosku dostawcy wynoszący ${providerLimit}. Spróbuj ponownie z niższą kwotą.",
"570f5f4658b03dda7a910f56c86a3bd3":"Anulowano kartę związaną z tym wnioskiem.",
"3075c56105863546e3368ffead7a146b":"Karta została anulowana.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Lista kodowania, której publikację próbujesz cofnąć, jest powiązana z integracją. Jeśli na pewno chcesz ją usunąć z integracji, skontaktuj się z zespołem pomocy technicznej, który może to zrobić.",
"756ade8d71ec487a4b48d7c4277ea239":"Lista kodowania, której publikację próbujesz cofnąć, jest powiązana z integracją. Zanim cofniesz jej publikację, musisz usunąć mapowanie,.",
"4f40335f7c018986c95458183bc0c136":"Link służący do aktywacji konta jest nieprawidłowy lub wygasł. Skontaktuj się z osobą zapraszającą w celu uzyskania nowego zaproszenia.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Link służący do zresetowania hasła jest nieprawidłowy lub wygasł. Aby uzyskać nowy link, jeszcze raz wyślij prośbę o zresetowanie hasła.",
"ded30f0e288c12125ca1a77f8d8fd600":"Powód będzie widoczny dla osoby zatwierdzającej i przesyłającej prośbę",
"f294897b38fc23dd8f5de52b54ceae22":"Wniosek zostanie odrzucony i wnioskodawca otrzyma stosowne powiadomienie. Chcesz kontynuować?",
"e8c7200dc985df0a94a9d996968b4cc0":"Wniosek zostanie odrzucony i wnioskodawca otrzyma stosowne powiadomienie. Chcesz kontynuować?",
"5f3d196a2ade3f97a2a48b773fec5861":"Wniosek zostanie odrzucony i wnioskodawca otrzyma stosowne powiadomienie. Chcesz kontynuować?",
"b04c191cd6b4f27669a4f390311f156c":"Nie ma żadnych właścicieli ani przełożonych zespołu do wyboru.",
"e25045557efbad7b52997b94a1fe6817":"Brak pozycji „{type}” w tym okresie",
"7260690577c795e999acae63bb7c51ae":"Podczas zapisywania ustawień zespołu wystąpił problem. Spróbuj ponownie.",
"f2103024f2a9c6c0335a71c60567d6c9":"Podczas ładowania portfela wystąpił błąd.",
"9776cb7eab9fa0d318a8144cd17de745":"Podczas ładowania portfela wystąpił błąd.",
"77d28a14bc900be4360b570a82eb75a9":"Brak danych do pokazania",
"2b6feba67722337f88c1443909fe446f":"Podczas dodawania osoby zatwierdzającej wystąpił problem.",
"24b7212afb97c5d23faafa2ca4559095":"Podczas dodawania karty kredytowej wystąpił problem. Spróbuj ponownie.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Podczas dodawania karty źródłowej do zespołu wystąpił problem. Spróbuj ponownie.",
"5a8e1b922d1cee746ac6794599b10709":"Podczas anulowania tej karty wystąpił problem. Spróbuj ponownie.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Podczas anulowania tej karty wystąpił problem. Spróbuj ponownie.",
"22361c5d4df61ad7ae9792a3baf5729f":"Podczas tworzenia listy wystąpił problem. Spróbuj ponownie.",
"098755bfddeaf0428c7feb6b69af9b6f":"Podczas odrzucania wniosku wystąpił problem. Spróbuj ponownie.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Podczas odrzucania wniosku wystąpił problem. Spróbuj ponownie.",
"7d461b6b7b54dbfa9c83243217cd1053":"Podczas kasowania pozycji kodowania wystąpił problem. Spróbuj ponownie.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Podczas kasowania listy wystąpił problem. Spróbuj ponownie.",
"beb43d7800cfff47e08cd61842d953bd":"Podczas przekazywania wniosku wystąpił problem. Spróbuj ponownie.",
"f7775350f35b8365fb9803232d9b792c":"Podczas uzyskiwania szczegółów karty wystąpił problem. Spróbuj ponownie.",
"95abaa922c6c1648ca9c39ac4776896f":"Podczas uzyskiwania szczegółów użytkownika wystąpił problem. Spróbuj ponownie.",
"9d7e4ead60137041ae8a845aa6cf9979":"Podczas ładowania profilu użytkownika wystąpił problem. Spróbuj ponownie.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Podczas ładowania użytkowników z zespołu wystąpił problem. Spróbuj ponownie.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Podczas ładowania ustawień wystąpił problem. Spróbuj ponownie.",
"b2f48028c400befad5f1d57f769ef7cd":"Podczas zmieniania przypisania wniosku wystąpił problem. Spróbuj ponownie.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Podczas usuwania karty źródłowej z zespołu wystąpił problem. Spróbuj ponownie.",
"19dfcd761006496c1d20351c04643e45":"Podczas usuwania domeny wystąpił problem. Spróbuj ponownie.",
"149651b5784481c68aaf4ac27bb1f907":"Podczas zapisywania listy kodowania wystąpił problem. Spróbuj ponownie.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Podczas zapisywania profilu wystąpił problem. Spróbuj ponownie.",
"749ddc235744b6b371668ec0bb049176":"Podczas zapisywania użytkownika wystąpił problem. Spróbuj ponownie.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Podczas zapisywania domeny wystąpił problem. Spróbuj ponownie.",
"04cf86909808f430b8b1904fb422e0d3":"Podczas zapisywania ustawień na czas nieobecności wystąpił problem. Spróbuj ponownie.",
"2eaf6ee97d984598c95e0494d72aa492":"Podczas przesyłania zlecenia zakupu wystąpił problem.",
"148f847d3beccaa67fcd5096ae3602d3":"Podczas aktualizowania nazwy karty wystąpił problem. Spróbuj ponownie.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Podczas aktualizowania nazwy karty wystąpił problem. Spróbuj ponownie.",
"de8cc976f7af9edf189904239e6240da":"Podczas aktualizowania zespołów wystąpił problem. Spróbuj ponownie.",
"5fd644b864eecc535bd42cae11543906":"Podczas operacji „{operation} listy” wystąpił problem. Spróbuj ponownie.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Podczas dodawania administratora wystąpił problem. Spróbuj ponownie.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Podczas dezaktywowania zespołu wystąpił błąd. Spróbuj ponownie.",
"3f686a97a1b9fcdd73a40d2005693105":"Wystąpił błąd. Spróbuj ponownie.",
"2f38263789163cf8ee30c8ae88852c82":"Podczas publikowania listy wystąpił błąd.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Podczas usuwania administratora wystąpił błąd. Spróbuj ponownie.",
"007a647254e1f85b6c1e789004e05df5":"Podczas zapisywania ustawień wystąpił błąd.",
"c3072d080b587d70f85d68ec089f028f":"Podczas zapisywania ustawień zespołu wystąpił błąd. Spróbuj ponownie.",
"608cd0e109c3e51b0425df8921e4181d":"Podczas ustawiania mapowania usługodawcy wystąpił błąd. Spróbuj ponownie.",
"177cefc68dd318ea75673326e77d8206":"Podczas wycofywania publikacji listy wystąpił błąd.",
"255d22dc4f0e338a4cf53d5b7256e695":"Podczas pobierania imienia i nazwiska osoby, do której przesyłasz wnioski o zatwierdzenie, wystąpił problem.",
"39ac4ec73fed8a981ad1857d989edfcb":"Ta kwota przekracza limit zatwierdzania firmy.",
"494ce3f598cbd97afe99774b5975a4bc":"Ta kwota przekracza limit zatwierdzania zespołu.",
"f2a42214e7c480c5596439becfd0b7bf":"To pole jest wymagane.",
"2dd5b9e9b5f80256af212924a6ac31c4":"To ustawienie wpłynie tylko na nowe karty",
"881b7596cf985d9c246b05fcab930632":"Ta karta źródłowa została dodana do zespołu.",
"a1b1aa354d06e7815b78c6abb7448e84":"Ta karta źródłowa została usunięta z zespołu.",
"b348b24fc4839ee15ed0aa424689bae3":"Ten zespół musi mieć administratora lub menedżera, zanim będzie można zaprosić jakiegoś członka.",
"188b9ed447a43916f9e323623ea3a993":"Aby przyjąć zaproszenie",
"969de95e19a06f5ae7cec6449cbb6a27":"Suma",
"30286e57da108ee7dbc0f8fbb62d4fff":"Wydana suma ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Monitoruj wydatki we wszystkich zespołach, projektach i subskrypcjach.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Monitoruj operacje na aktywnych kartach zespołu, wstrzymuj wydatki i dezaktywuj kart, które nie są już zespołowi potrzebne.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Resetuj hasło Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Spróbuj ponownie",
"80196bc616d4a44a4997a2ea127f7b83":"Spróbuj ponownie",
"f96bbe81618b5f4a1807989eb4f69e24":"Spróbuj ponownie",
"d498d7d261b7ca56e07218d1a0dbdd49":"Ups! Wystąpił błąd. Spróbuj ponownie.",
"ce2e475291003852c71b01959698c496":"Nie można uzyskać komunikatów dotyczących wniosku „{title}”. Spróbuj ponownie.",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Anuluj archiwizację",
"d2e50be5287fd8fb436d0acbeb811864":"Unikatowy identyfikator",
"0e2fbce967f4a209215b6f48f8eddac3":"Anuluj wstrzymanie tworzenia kart",
"f2315dec5dd07802b77af3a3a5414a7d":"Do 4 lat w przyszłości.",
"d2f30a2f0c955271e9af123dceca501a":"Do {label} w przyszłości",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Aktualizuj kartę źródłową",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Aktualizuj kartę źródłową",
"c8f6bc61a8982158e408194e48a274d9":"Nie udało się przesłać. Spróbuj ponownie.",
"8a86a60cb836bdb5225cad20c5151078":"Prześlij potwierdzenie",
"e448a1ce42de0cf526200331b4ad8b23":"Użytkownik",
"75edcc8fc27f41c99e28b48ff250c7f8":"Dane użytkownika",
"d1651c2afdb6c92621adbb707e4753d0":"Zapisano użytkownika.",
"5fa55883b7413526b441e999cb8089b3":"Nazwa użytkownika",
"7a892b81fe2aae295f1b2d38bb67360f":"Nazwa użytkownika",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Nie można usunąć użytkowników z aktywnymi kartami. Aby usunąć tego użytkownika, anuluj karty.",
"d40fa5935f1614925cf46259c886814a":"Wyświetl aktywność na karcie",
"625419857efc7e72d162c9f8204109df":"Wyświetl kartę w portfelu",
"85a5e817826415651ed4991a76bb5385":"Wyświetl konwersację",
"09fc124dc9b8b14d6227a92bb0a4566f":"Wyświetl konwersację",
"d26cfc6d8f420f259f434b79593e9eb4":"Wyświetl pokwitowanie",
"c7d0394c3206a3b74afbeabc41cc639c":"Wyświetl pokwitowanie",
"01650aa52c5e5a22d3f38afd2c7f9411":"Wyświetl pokwitowanie",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Wyświetl pokwitowanie",
"b2d141897f73fc2a198ee1a7a438f89b":"Wyświetl prośbę",
"02fa2f56ef72845a9dfeabea22948061":"Wyświetl dane zespołu",
"2d9c55650b17dd605a1388f46c81ba38":"Karta wirtualna",
"d0ec076e4600e854588f8da265208c0b":"Opcje karty wirtualnej",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Portfel",
"5279a2220e4cfb0d8b8b357311251cbe":"Portfel",
"b1746b5806659e3189f837d2dd54e08c":"Portfel",
"ff306d5b34ee993f9618c1f3c0209a39":"Obecnie nie można zarchiwizować wniosku. Spróbuj ponownie później.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Obecnie nie można anulować wniosku. Spróbuj ponownie później.",
"a813d44f6bb26c5da09671e532abe022":"Obecnie nie można cofnąć archiwizacji wniosku. Spróbuj ponownie później.",
"d4c7310eafc0d0da89f20596a22a3abe":"Obecnie nie można utworzyć wniosku. Spróbuj ponownie.",
"dca679c10accb1db481a57a0e1ba08e2":"Obecnie nie można utworzyć zlecenia. Spróbuj ponownie.",
"db5584a2ef29b04959bce7f93db54c56":"Obecnie nie można zmienić zespołu. Spróbuj ponownie później.",
"f2af2c261fabddb01766f4f974b85a62":"Co tydzień",
"e181ed0723a00ca7558b9d5d5258732b":"Witamy w Tradeshift Go! {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Będziemy w kontakcie!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Z jakiej karty kredytowej korzysta Twoja firma?",
"968d94729053e166a76ea577ce3022e2":"Służbowy adres e-mail",
"588a075e8694ff5cc86815697e1586bc":"Pracuj tak, jak robią to Twoje działy biznesowe.",
"182ae1eb5226e0646b4033883cc0900c":"Rok",
"fe88fe7094c4ced427874b3d22326c8d":"Tak",
"9a2d4e3b22ad0567165eb0f661f952ad":"Tak, anuluj kartę",
"96b88e530375237cbc08138d9fb4109c":"Tak, usuń listę",
"fd3780f468c531f2bc1b47b8748b5a66":"Możesz utworzyć kody księgowania w ustawieniach firmy.",
"ab3ac6f945d9f756763d49257fd924dc":"Można zarządzać kodowaniem w ustawieniach firmy.",
"c919dae93fe780061629c255eb358140":"Nie możesz usunąć jedynej karty źródłowej powiązanej z tym zespołem. Aby usunąć tę kartę, dodaj inną.",
"11de97e873b66d91a77db7a05da32202":"Możesz wyznaczyć jednego z poniższych właścicieli zespołów na administratora firmy lub zaprosić nowego użytkownika.",
"0ab790f08b2780be001b79d2ccd75c4e":"Dla tego konta nie ma utworzonych żadnych kodów księgowania.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Nie ma utworzonych żadnych list kodowania.",
"44e470218124a50c117870467530752e":"Wprowadzono nieprawidłowy adres e-mail lub hasło.",
"4b687d0730822fdbeea27c174e47f227":"Nie ma żadnych zatwierdzonych zatwierdzeń.",
"020f03c17b79af66f335b6af868c66c0":"Nie ma żadnych anulowanych zatwierdzeń.",
"def0b76f03e7c622341a7d8e08aaaae7":"Nie ma żadnych odmówionych zatwierdzeń.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Nie ma żadnych oczekujących zatwierdzeń.",
"7c034d6104f80a523b90bc607fc06608":"Nie masz kart wirtualnych w portfelu. Utwórz wniosek",
"0aed9198d61d161468d541dbbc9f477a":"Masz zaplanowane przesyłanie do: {oooForwardToFullName} na czas nieobecności do {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Masz nową kartę wirtualną:",
"04eeaf814767c30aa41577f3b11f916a":"Niedawno otrzymaliśmy Twoją prośbę o zresetowanie hasła do konta Go. Aby ukończyć tę procedurę:",
"09ddf249810b9af7d3e1f4e537da500d":"Próbujesz uzyskać dostęp do strony, która nie istnieje. Sprawdź, czy masz prawidłowy link.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Masz zaproszenie do zespołu:",
"02c6f3a7cf31a87f4f39f992509f8c44":"Dodano Cię jako administratora firmy",
"cf121a14590f109dafcae138265e353b":"Dodano Cię do nowego zespołu.",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Dodano Cię do zespołu",
"d3cef66a026f776903b342d450e5a832":"Twój wniosek o kartę Go został odrzucony",
"003f87c21a29220e661fc302b885fd5a":"Twoje konto zostało wyłączone. Skontaktuj się z administratorem firmy lub zespołu.",
"a52b1f0da15dce68007f524e48238143":"Podczas dodawania tej karty bank zwrócił błąd. Skontaktuj się z bankiem, aby rozwiązać ten problem, i spróbuj ponownie.",
"4d883b173167297463aeac86957c26fe":"Twój adres e-mail został użyty do utworzenia konta Tradeshift Go. Aby zweryfikować adres e-mail i sfinalizować rejestrację",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Zapisano ustawienia na czas nieobecności.",
"542f08554110a12de0c23afd78f38e0e":"Hasło zostało zresetowane. Zaloguj się.",
"49b2dcbe5d461f35c37986962b5bd07a":"Twój wniosek został odrzucony",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Jesteś na liście.",
"32f87c5ddeaee35b9178785ef4eb9313":"Kod pocztowy",
"c6974a3d5311374c697625f2a3234bce":"data",
"f86980f5ef00b645a6e4a0b59738a954":"osoba, która nie została sprawdzona",
"7066f854917b39bafe0bbecbb8bd6377":"zatwierdza wnioski",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"dla",
"49b171230ab9bcdd8b631c70cf397efb":"na: <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"dla Twojej firmy",
"35ad86f0b035d8156390670d60801e7f":"dla {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"dla {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"od",
"c1407aa5424f339dedf67bcac597cf71":"ustawił(a) przekierowywanie do Ciebie wniosków o zatwierdzenie w okresie",
"67a948b8af3284a401ebefcb1b4723ab":"oczekuje na Twoje zatwierdzenie dokumentu",
"9ca64449d64b98fef7859e22bc23c4dc":"składa wnioski",
"ac850ab84428e9eb6a57e5d813de5e45":"zarządza zespołem",
"2168839a1f4c73ef5c89c9e930148e7d":"Zignoruj ten e-mail. Nie jest wymagane żadne działanie",
"85431c325faab98e02daa60151465144":"jest nadawcą tego zaproszenia",
"81c504fd54119e7e580eb05dedef46d0":"jest nadawcą tego zaproszenia",
"d74545060b38445ef4cc49fbb8d79876":"jest nadawcą tego zaproszenia.",
"2d96c53ee7ee0a54aadad19bfce0697d":"podczas pobierania środków z karty wystąpił błąd",
"b2fda295788d579fffd3f43b7737fd63":"w celu zarejestrowania karty firmowej w Go, aby pracownicy mogli łatwo i bezpiecznie uzyskiwać do niej dostęp.",
"d35bd5c5d74af765d45cef3c4e78f52a":"do dokonywania zakupów dla firmy przy użyciu kart wirtualnych Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"do dokonywania zakupów dla firmy przy użyciu kart wirtualnych Go",
"9546989e681a7e25fbdfe7631e9ebc03":"do dokonywania zakupów dla firmy przy użyciu kart wirtualnych Go.",
"6174e0a3d26409dd6e410052e2805660":"w celu korzystania z kart Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"wyłącz tryb Poza biurem",
"fd2c1099fc9f3831d7f24195aaa11269":"ty@company.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} odpowiada za zatwierdzanie wniosków innych członków zespołu. Aby kontynuować, przypisz nową osobę zatwierdzającą dla członków, których to dotyczy.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} — pomyślnie zaproszono ${this.teamId ? '.' : ' do zespołu.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} usunięte",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} anulował(a) wniosek o:",
"9567a2d4cacd58fb4f096132d26c1660":"{username} odpowiada za zatwierdzanie wniosków innych członków zespołu. Aby kontynuować, przypisz nową osobę zatwierdzającą dla członków, których to dotyczy.",
};
