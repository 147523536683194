const TSInputComponent = {
	bindings: {
		change: '&?',
		currency: '@',
		label: '@',
		isDisabled: '=',
		infoMessages: '<',
		isReadonly: '@',
		isRequired: '@',
		onBlur: '&',
		pattern: '@',
		patternRestrict: '<',
		placeholder: '@',
		singleError: '@',
		type: '@',
		validate: '&?', // validate function needs to return an object. i.e {valid: true, errorMessage: "error"}
	},
	require: {
		model: 'ngModel',
	},
	/*
		It was necessary to have separate static html for each input type because otherwise
		ts ui components won't render each input type correctly. Using a single input and
		switching the type dynamically using angular does not work.
	*/
	template: `
		<fieldset ng-if="$ctrl.type !== 'checkbox'">
			<label
					ng-class="{ 'has-currency': !!$ctrl.currency}" data-currency="{{$ctrl.currency}}"
					title="{{$ctrl.label}}">
				<span ng-bind="$ctrl.label"></span>
				<input
					ng-blur="$ctrl.onBlurEvent($event)"
					ng-change="$ctrl.onInputChange()"
					ng-model="$ctrl.value"
					ng-pattern="$ctrl.pattern"
					ng-pattern-restrict="{{ $ctrl.patternRestrict }}"
					ng-readonly="$ctrl.isReadonly === 'true' ? true : false"
					ng-required="$ctrl.isRequired === 'false' ? false : true"
					placeholder="{{$ctrl.placeholder}}"
					type="text"
					validate-input="$ctrl.isValid({ value })" />
			</label>
			<dl ng-if="!$ctrl.singleError" class="ts-errors" ng-show="$ctrl.errorMessage">
				<dt>Error: {{ $ctrl.label }}</dt>
				<dd ng-bind="$ctrl.errorMessage"></dd>
			</dl>
			<dl ng-if="$ctrl.singleError" class="ts-errors" ng-show="$ctrl.errorMessage">
				<dt>{{ $ctrl.errorMessage }}</dt>
			</dl>
			<dl class="ts-info" ng-show="$ctrl.infoMessages">
				<dd ng-repeat="message in $ctrl.infoMessages">{{message}}</dd>
			</dl>
		</fieldset>
		<fieldset ng-if="$ctrl.type === 'checkbox'">
			<label
					ng-class="{ 'has-currency': !!$ctrl.currency}" data-currency="{{$ctrl.currency}}"
					title="{{$ctrl.label}}">
				<span ng-bind="$ctrl.label"></span>
				<input
					ng-disabled="$ctrl.isDisabled === true || $ctrl.isDisabled === 'true' ? true : false"
					ng-change="$ctrl.onInputChange()"
					ng-model="$ctrl.value"
					ng-readonly="$ctrl.isReadonly === 'true' ? true : false"
					ng-required="$ctrl.isRequired === 'false' ? false : true"
					type="checkbox" />
			</label>
			<dl ng-if="!$ctrl.singleError" class="ts-errors" ng-show="$ctrl.errorMessage">
				<dt>Error: {{ $ctrl.label }}</dt>
				<dd ng-bind="$ctrl.errorMessage"></dd>
			</dl>
			<dl ng-if="$ctrl.singleError" class="ts-errors" ng-show="$ctrl.errorMessage">
				<dt>{{ $ctrl.errorMessage }}</dt>
			</dl>
			<dl class="ts-info" ng-show="$ctrl.infoMessages">
				<dd ng-repeat="message in $ctrl.infoMessages">{{message}}</dd>
			</dl>
		</fieldset>
	`,
	controller: class TSInputController {
		$onInit() {
			this.model.$render = () => {
				this.value = this.model.$viewValue;
			};
		}

		isValid({ value }) {
			if (!this.validate) {
				this.errorMessage = null;
				return true;
			}

			const result = this.validate({ value });

			if (!result) {
				this.errorMessage = null;
				return true;
			}

			this.errorMessage = result.errorMessage;
			return result.valid;
		}

		onBlurEvent(event) {
			return this.onBlur({ value: event.target.value });
		}

		onInputChange() {
			this.model.$setViewValue(this.value);
			if (this.change) {
				this.change();
			}
		}
	},
};

export default TSInputComponent;
