/* @ngInject */
function CardInfoController($scope, CardInfoService, FeatureService) {
	let chromePluginEnabled = false;

	if ($scope.card.isVirtual()) {
		$scope.remainingValue = CardInfoService.getRemainingValue($scope.card);
		$scope.spentAmount = CardInfoService.getSpentAmount($scope.card, $scope);
		$scope.spentPercentage = CardInfoService.getSpentPercentage($scope.card);
	}

	// this will eventually be dynamic
	const request = $scope.card.getRequest();
	$scope.currency = request ? request.getCurrency() : '';

	$scope.isUseCardShown = () => !!window.chrome && chromePluginEnabled;

	$scope.useCard = function (card) {
		const cardData = card.getCardDataForExtension();
		// eslint-disable-next-line
		chrome.runtime.sendMessage('ejdglgonkcagdcaffbbjipeacdpmklea', { card: cardData });
	};

	return FeatureService.getFeatures().then(features => {
		chromePluginEnabled = !!features.ENABLE_CHROME_EXTENSION_LINK;
	});
}

module.exports = CardInfoController;
