const _ = require('lodash');

const TeamBoxComponent = {
	bindings: {
		currency: '@',
		dashboardMetrics: '<',
	},
	template: `
		<ts-table
					class="team-member-box-table"
					columns="$ctrl.columns"
					max-rows="5"
					on-cell-click="$ctrl.onCellClick($event)"
					rows="$ctrl.rows"/>
	`,
	controller: /* @ngInject */ function ($filter, $state, $stateParams, i18nService, LexicoService, UserService) {
		this.lexico = LexicoService.getLexico();

		this.onCellClick = ({ columnIndex, rowIndex }) => {
			if (columnIndex === 4) {
				$state.go('main.walletTeamMemberView', {
					teamId: $stateParams.teamId,
					userId: this.rows[rowIndex][4].value,
				});
			}
		};

		this.columns = table => {
			const currencySymbol = i18nService.getCurrencySymbol(this.currency);

			table.sortable((index, ascending) => {
				table.sort(index, ascending);
			});

			return [
				{
					flex: 3,
					label: this.lexico.trc('Table component title', 'Member'),
					search: {
						tip: 'Search by member name',
					},
				},
				{
					label: `${this.lexico.tr('Approved')} ${currencySymbol}`,
					flex: 2,
				},
				{
					label: `${this.lexico.tr('Spent')} ${currencySymbol}`,
					flex: 2,
				},
				{
					flex: 2,
					label: `${this.lexico.tr('Available')} ${currencySymbol}`,
				},
				{
					flex: 2,
					label: '',
				},
			];
		};

		this.generateRows = async people => {
			return Promise.all(
				people.map(async person => {
					// We are currently only using USD. When we aren't, we'll deal with this another way
					const metrics = person.metrics[0];
					// But if we do, we'll want to properly format the symbols so we need to find them like this
					// And we need to get the user, since all we have is an id
					const user = await UserService.getUserById(person.memberId);
					return [
						{
							text: `${_.get(user, 'fullName', '')} \n *${_.get(metrics, 'activeCardCount', 0)}*`,
							value: `${_.get(user, 'fullName', '')}`,
						},
						{
							text: `${$filter('currency')(_.get(metrics, 'approvedAmount', 0), this.currency, true)}`,
							value: Number(_.get(metrics, 'approvedAmount', 0)),
						},
						{
							text: `${$filter('currency')(_.get(metrics, 'spentAmount', 0), this.currency, true)}`,
							value: Number(_.get(metrics, 'spentAmount', 0)),
						},
						{
							text: `${$filter('currency')(_.get(metrics, 'availableBalance', 0), this.currency, true)}`,
							value: Number(_.get(metrics, 'availableBalance', 0)),
						},
						{
							item: 'Icon',
							type: 'team-people-chevron-icon',
							color: 'black',
							value: _.get(user, 'userId', person.memberId),
						},
					];
				}),
			);
		};

		this.$onChanges = async () => {
			const people = this.dashboardMetrics.people;
			this.rows = await this.generateRows(people);
		};
	},
};

export default TeamBoxComponent;
