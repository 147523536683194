"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledTextareaAutosize = void 0;
const material_1 = require("@mui/material");
exports.StyledTextareaAutosize = (0, material_1.styled)(material_1.TextareaAutosize)(({ theme }) => ({
    overflow: 'visible',
    width: '100%',
    display: 'flex',
    height: 'auto',
    padding: '0 10px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'inherit',
    resize: 'none', // hide resize ui element
    '&:hover': {
        backgroundColor: 'inherit',
    },
    '&:focus': {
        opacity: 1,
    },
    '&::placeholder': {
        fontWeight: theme.typography.tsFontWeight.default,
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        userSelect: 'text',
        whiteSpace: 'nowrap',
        wordWrap: 'normal',
        color: theme.palette.tsGoColor.grayDarker,
        cursor: 'text',
        lineHeight: '51px',
    },
}));
