import angular from 'angular';

import AddAdminModule from './add-admin';
import AddCardSourceAsideModule from './add-card-source';
import AddCodingListAsideModule from './add-coding-list';
import AddCustomListAsideModule from './add-custom-list';
import AddDefaultManagerAside from './add-default-manager';
import ApprovalsSettingsAsideModule from './approvals-settings';
import ApproverOptionsAsideComponent from './approver-options';
import CancelCardAsideModule from './cancel-card';
import CardDetailsAsideModule from './card-details';
import CardSourceDetailAsideModule from './card-source-detail';
import CodingListAsideModule from './coding-list';
import ConfirmActionAsideModule from './confirm-action';
import DownloadReportModule from './download-report';
import EditSourceCardAsideModule from './edit-source-card';
import EditUserAsideModule from './edit-user';
import ForwardRequestAsideModule from './forward-request';
import InviteUserAsideModule from './invite-user';
import InviteUserToTeamAside from './invite-user-to-team';
import NumberOfPaymentsAsideModule from './number-of-payments';
import OutOfOfficeAsideModule from './out-of-office';
import ReceiptModule from './receipt';
import RemoveListModule from './remove-list';
import RequestInfoModule from './request-info';
import SelectAsideModule from './select';
import SelectCardSourceAsideModule from './select-card-source';
import SelectTeamActionsAside from './select-team-options';
import TeamActionsModule from './team-actions';
import TeamSwitcherAside from './team-switcher';
import UserDetailsAside from './user-details';

export default angular.module('go.common.asides', [
	AddAdminModule,
	AddCardSourceAsideModule,
	AddCodingListAsideModule,
	AddCustomListAsideModule,
	AddDefaultManagerAside,
	ApproverOptionsAsideComponent,
	ApprovalsSettingsAsideModule,
	CancelCardAsideModule,
	CardDetailsAsideModule,
	CardSourceDetailAsideModule,
	CodingListAsideModule,
	ConfirmActionAsideModule,
	DownloadReportModule,
	EditSourceCardAsideModule,
	EditUserAsideModule,
	InviteUserAsideModule,
	InviteUserToTeamAside,
	NumberOfPaymentsAsideModule,
	OutOfOfficeAsideModule,
	ForwardRequestAsideModule,
	ReceiptModule,
	RemoveListModule,
	RequestInfoModule,
	SelectAsideModule,
	SelectCardSourceAsideModule,
	SelectTeamActionsAside,
	TeamActionsModule,
	TeamSwitcherAside,
	UserDetailsAside,
]).name;
