const ApproverOptionsAsideComponent = {
	bindings: {
		approval: '<',
		approveRequest: '&',
		declineRequest: '&',
		forwardRequest: '&',
		isOpen: '<',
		onClose: '&',
		viewConversation: '&',
	},
	template: `
	<ts-aside
			is-open="$ctrl.isOpen"
			on-close="$ctrl.onClose()"
			title="{{$ctrl.approvalOptionsTitleText}}">
		<div class="panel-card">
			<request-card
					ng-if="$ctrl.approval"
					request="$ctrl.approval.getRequest()"></request-card>
		</div>
		<menu data-ts="Buttons">
			<li>
				<button
						class="ts-primary"
						ng-click="$ctrl.approveRequest($ctrl.EventEmitter($ctrl.approval))">
					<span ng-bind="$ctrl.approveRequestButtonText"></span>
				</button>
			</li>
			<li>
				<button
						class="ts-secondary"
						ng-click="$ctrl.forwardRequest($ctrl.EventEmitter($ctrl.approval))">
					<span ng-bind="$ctrl.forwardRequestButtonText"></span>
				</button>
			</li>
			<li>
				<button
						class="ts-secondary"
						ng-click="$ctrl.viewConversation($ctrl.EventEmitter($ctrl.approval))">
					<span ng-bind="$ctrl.viewConversationButtonText"></span>
				</button>
			</li>
			<li>
				<button
						class="ts-secondary"
						ng-click="$ctrl.declineRequest($ctrl.EventEmitter($ctrl.approval))">
					<span ng-bind="$ctrl.declineButtonText"></span>
				</button>
			</li>
			<li>
				<button
						class="ts-secondary"
						ng-click="$ctrl.onClose()">
					<span ng-bind="$ctrl.cancelButtonText"></span>
				</button>
			</li>
		</menu>
	</ts-aside>
	`,
	controller: class ApproverOptionsAsideController {
		/* @ngInject */
		constructor(EventEmitter, LexicoService) {
			this.lexico = LexicoService.getLexico();
			this.approvalOptionsTitleText = this.lexico.trc('Aside title', 'Approval Options');
			this.approveRequestButtonText = this.lexico.trc('Button', 'Approve Request');
			this.forwardRequestButtonText = this.lexico.trc('Button', 'Forward Request');
			this.viewConversationButtonText = this.lexico.trc('Button', 'View Conversation');
			this.declineButtonText = this.lexico.trc('Button', 'Decline Request');
			this.cancelButtonText = this.lexico.trc('Button', 'Cancel');
			this.EventEmitter = EventEmitter;
		}
	},
};

export default ApproverOptionsAsideComponent;
