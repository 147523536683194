const ConfirmActionAsideComponent = {
	bindings: {
		hideConfirm: '@',
		isOpen: '<',
		message: '<',
		onConfirm: '&',
		onCancel: '&',
		title: '@',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onCancel()"
				title="{{ $ctrl.title || $ctrl.confirmActionTitleText }}">
			<div>
				<ts-note icon="'ts-icon-warning'" message="$ctrl.message"></ts-note>
				<menu data-ts="Buttons">
					<ts-menu-button
							button-class="ts-danger"
							label="$ctrl.yesButtonText"
							on-click="$ctrl.onConfirm()"
							ng-if="!$ctrl.hideConfirm"></ts-menu-button>
					<ts-menu-button
							button-class="ts-tertiary"
							label="$ctrl.cancelButtonText"
							on-click="$ctrl.onCancel()"></ts-menu-button>
				</menu>
			</div>
		</ts-aside>
	`,
	controller: class ConfirmActionAsideController {
		/* @ngInject */
		constructor(LexicoService) {
			this.lexico = LexicoService.getLexico();
			this.confirmActionTitleText = this.lexico.trc('Form title', 'Confirm action');
			this.yesButtonText = this.lexico.trc('Button', 'Yes');
			this.cancelButtonText = this.lexico.trc('Button', 'Cancel');
		}
	},
};

export default ConfirmActionAsideComponent;
