const Cookies = require('js-cookie');

/* @ngInject */
function RouteController($state, _isAuthenticated, _user, TeamsService, UserServiceV2, SessionService, constants) {
	(async function init() {
		if (!_isAuthenticated) {
			$state.go('signin.login');
			return;
		}

		const routerStateCookie = Cookies.get(constants.cookies.preLoginRouterState);
		if (routerStateCookie) {
			Cookies.remove(constants.cookies.preLoginRouterState);
			const routerState = JSON.parse(routerStateCookie);
			$state.go(routerState.redirectTo || 'signedIn', routerState.redirectParams || {});
			return;
		}

		$state.go('main.wallet');
	})();
}

// TODO: can probably remove this controller now that the transitions are in app.js, or at least can do
// the redirect in resolve rather than the controller
RouteController.resolve = {
	_isAuthenticated: /* @ngInject */ SessionService => SessionService.isAuthenticated(),
	_user: /* @ngInject */ UserServiceV2 => UserServiceV2.getUser(),
};

module.exports = RouteController;
