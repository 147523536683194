const SvgSecuritySecure = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M15.5 5.73H14V4a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v1.73H2.5A1.5 1.5 0 0 0 1 7.23v9.27A1.5 1.5 0 0 0 2.5 18h13a1.5 1.5 0 0 0 1.5-1.5V7.23a1.5 1.5 0 0 0-1.5-1.5M5.2 4A2.81 2.81 0 0 1 8 1.2h2A2.81 2.81 0 0 1 12.8 4v1.73h-1.2V4A1.6 1.6 0 0 0 10 2.4H8A1.6 1.6 0 0 0 6.4 4v1.73H5.2Zm5.2 0v1.73H7.6V4a.4.4 0 0 1 .4-.4h2a.4.4 0 0 1 .4.4m5.4 12.5a.31.31 0 0 1-.3.3h-13a.31.31 0 0 1-.3-.3V7.23a.31.31 0 0 1 .3-.3h13a.31.31 0 0 1 .3.3ZM8.4 14h1.2v-4H8.4Z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
export default SvgSecuritySecure;
