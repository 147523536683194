const FooterService = () => {
	const service = {};
	const _footer = ts.ui.Footer;
	let isFooterShown = false;

	service.isFooterShown = () => isFooterShown;

	service.create = ({ $ctrl, $scope, buttons }) => {
		_footer.buttons(buttons);
		isFooterShown = true;
		if ($scope) {
			$scope.$on('$destroy', () => {
				service.destroy();
			});
		} else if ($ctrl) {
			const existingHook = $ctrl.$onDestroy;
			$ctrl.$onDestroy = () => {
				if (existingHook) {
					existingHook();
				}
				service.destroy();
			};
		}
		return _footer;
	};

	service.destroy = () => {
		_footer.clear();
		isFooterShown = false;
	};

	return service;
};

module.exports = FooterService;
