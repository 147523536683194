function requestInfo() {
	return {
		restrict: 'EA',
		scope: {
			requestInfo: '=request',
		},
		template: require('./requestInfo.html'),
	};
}

module.exports = requestInfo;
