const FileUploadComponent = {
	bindings: {
		accept: '@',
		fileFormatsInfo: '@',
		label: '@',
		maxFileSize: '<',
		method: '@',
		onFileUploaded: '&',
		uploadedMessage: '@',
		url: '<',
	},
	require: {
		model: 'ngModel',
	},
	template: `
		<fieldset>
			<label>
				<span ng-bind="$ctrl.label"></span>
				<input
						accept="{{ $ctrl.accept }}"
						file-model
						ng-change="$ctrl.onInputChange()"
						ng-model="$ctrl.file"
						type="file" />
				<div
						class="file-upload-overlay"
						ng-class="{ 'uploading': $ctrl.isUploading }">
					<span ng-bind="$ctrl.fileName"></span>
				</div>
			</label>
			<dl class="ts-info" ng-if="$ctrl.state === 'INITIAL'">
				<dd ng-bind="$ctrl.fileFormatsInfo"></dd>
				<dd ng-bind="$ctrl.maxFileSizeInfo"></dd>
			</dl>
			<dl class="go-success" ng-if="$ctrl.state === 'UPLOADED'">
				<dt ng-bind="$ctrl.uploadedMessage"></dt>
			</dl>
			<dl class="ts-errors" ng-if="$ctrl.state === 'ERROR'">
				<dt ng-bind="$ctrl.errorReason"></dt>
				<dd ng-bind="$ctrl.fileFormatsInfo"></dd>
				<dd ng-bind="$ctrl.maxFileSizeInfo"></dd>
			</dl>
		</fieldset>
	`,
	controller: class FileUploadController {
		/* @ngInject */
		constructor(constants, EventEmitter, fileUploadService, LexicoService) {
			this.constants = constants;
			this.EventEmitter = EventEmitter;
			this.fileUploadService = fileUploadService;
			this.lexico = LexicoService.getLexico();
		}

		$onInit() {
			this.model.$render = () => {
				this.file = null;
				this.fileName = this.model.$viewValue;
				this.state = this.fileName ? 'UPLOADED' : 'INITIAL';
			};
		}

		onInputChange() {
			if (!this.file) {
				// user opened select file dialog and pressed cancel
				return;
			}
			const isValidFileSize = this.fileUploadService.validateFileSize({
				file: this.file,
				maxSizeMB: this.maxFileSize,
			});
			if (!isValidFileSize) {
				this.file = null;
				return;
			}
			this.fileName = null;
			this.isUploading = true;
			this.fileUploadService
				.upload({
					file: this.file,
					method: this.method,
					url: this.url,
				})
				.then(
					successResponse => {
						this.state = 'UPLOADED';
						this.fileName = this.file.name;
						this.model.$setViewValue(this.fileName);
						this.onFileUploaded(this.EventEmitter({ response: successResponse }));
					},
					errorResponse => {
						this.state = 'ERROR';
						this.errorReason =
							this.lexico.trc('Error message', this.constants.errorStrings[errorResponse.errorReason]) ||
							this.lexico.trc('Error message', 'Upload failed, try again.');
						this.file = null;
					},
				)
				.finally(() => {
					this.isUploading = false;
				});
		}

		$onChanges(changes) {
			if (changes.maxFileSize && changes.maxFileSize.currentValue) {
				this.maxFileSizeInfo = `${this.lexico.tr('File size should be less than')} ${this.maxFileSize} MB`;
			}
		}
	},
};

export default FileUploadComponent;
