const NumberOfPaymentsAsideComponent = {
	bindings: {
		isOpen: '<',
		numberOfPayments: '<',
		onSubmit: '&',
		onCancel: '&',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onCancel()"
				title="{{$ctrl.numberOfPaymentsTitleText}}">
			<ts-form
				on-cancel="$ctrl.onCancel()"
				on-submit="$ctrl.onFormSubmit()"
				submit-label="{{$ctrl.saveButtonText}}">
				<ts-form-fields>
					<ts-input
						label="{{$ctrl.numberOfPaymentsFormText}}"
						type="number"
						ng-model="$ctrl.numberOfPayments"></ts-input>
				</ts-form-fields>
			</ts-form>
		</ts-aside>
	`,
	controller: class NumberOfPaymentsAsideController {
		/* @ngInject */
		constructor(EventEmitter, LexicoService) {
			this.EventEmitter = EventEmitter;
			this.lexico = LexicoService.getLexico();
			this.numberOfPaymentsTitleText = this.lexico.trc('Component title', 'Number of payments');
			this.saveButtonText = this.lexico.trc('Button', 'Save');
			this.numberOfPaymentsFormText = this.lexico.trc('Form input label', 'Number of payments');
		}

		onFormSubmit() {
			this.onSubmit(this.EventEmitter({ numberOfPayments: this.numberOfPayments }));
		}
	},
};

export default NumberOfPaymentsAsideComponent;
