"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userInitialState = void 0;
exports.userSlice = userSlice;
exports.userInitialState = {
    user: {
        userId: '',
        email: '',
        firstName: '',
        lastName: '',
        fullName: '',
        companyAccountId: '',
        companyName: '',
        initials: '',
        language: '',
        roleId: '',
        roles: [],
        state: '',
        isSupportAdmin: false,
        properties: {},
        userSelectedTeam: '',
        avatarUrl: '',
        invitedUser: {},
        canForwardApprovals: false,
        phoneDetails: null,
    },
};
function userSlice(set) {
    return {
        ...exports.userInitialState,
        setUser(user) {
            set((state) => {
                state.user = { ...state.user, ...user };
                return state;
            }, false, '[setUser]');
        },
        setUserSelectedTeam(selectedTeam) {
            set((state) => {
                state.user.userSelectedTeam = selectedTeam;
                return state;
            }, false, '[setUserSelectedTeam]');
        },
        setInvitedUser(invitedUser) {
            set((state) => {
                state.user.invitedUser = invitedUser;
                return state;
            }, false, '[setInvitedUser]');
        },
        setCanForwardApprovals(canForwardApprovals) {
            set((state) => {
                state.user.canForwardApprovals = canForwardApprovals;
                return state;
            }, false, '[setCanForwardApprovals]');
        },
    };
}
