"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modalInitialState = void 0;
exports.modalSlice = modalSlice;
exports.modalInitialState = {
    modal: {
        team: {
            isOpen: false,
            isSubmitting: false,
            step: 0,
            isSaveBtnDisabled: true,
        },
    },
};
function modalSlice(set) {
    return {
        ...exports.modalInitialState,
        setIsTeamModalOpen(isOpen) {
            set((state) => {
                state.modal.team.isOpen = isOpen;
                return state;
            }, false, '[setIsTeamModalOpen]');
        },
        setIsTeamSubmitting(isSubmitting) {
            set((state) => {
                state.modal.team.isSubmitting = isSubmitting;
                return state;
            }, false, '[setIsTeamSubmitting]');
        },
        setStep(step) {
            set((state) => {
                state.modal.team.step = step;
                return state;
            }, false, '[setStep]');
        },
        setIsSaveBtnDisabled(isSaveBtnDisabled) {
            set((state) => {
                state.modal.team.isSaveBtnDisabled = isSaveBtnDisabled;
                return state;
            }, false, '[setIsSaveBtnDisabled]');
        },
    };
}
