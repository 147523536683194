const SvgSettings = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M16.38 7a7.3 7.3 0 0 0-.75-1.78l.69-1.62-1.91-1.92-1.62.69A7.5 7.5 0 0 0 11 1.62L10.35 0h-2.7L7 1.62a7.3 7.3 0 0 0-1.78.75l-1.63-.69-1.91 1.91.69 1.62A7.3 7.3 0 0 0 1.62 7L0 7.65v2.7l1.62.65a7.3 7.3 0 0 0 .75 1.78l-.69 1.62 1.91 1.91 1.62-.69a7.3 7.3 0 0 0 1.79.76L7.65 18h2.7l.65-1.62a8 8 0 0 0 1.78-.74l1.62.68 1.91-1.91-.69-1.62a7.3 7.3 0 0 0 .76-1.79l1.62-.65v-2.7Zm-.45 2.9-.56.22-.15.57a6.2 6.2 0 0 1-.63 1.51l-.29.52.23.54.37.87-.77.77-.87-.37-.54-.23-.52.29a6 6 0 0 1-1.51.63l-.57.15-.22.56-.36.87H8.46l-.35-.87-.23-.56-.57-.15a6.2 6.2 0 0 1-1.51-.63l-.51-.29-.55.23-.87.37-.77-.77.37-.87.23-.54-.29-.52a6.2 6.2 0 0 1-.63-1.51l-.15-.57-.56-.22-.87-.36V8.46l.87-.35.55-.23.16-.57a6.2 6.2 0 0 1 .63-1.51l.29-.51-.23-.55-.37-.87.77-.77.87.37.54.23.52-.29a6.2 6.2 0 0 1 1.51-.63l.57-.15.22-.56.36-.87h1.08l.36.87.22.55.57.16a6.2 6.2 0 0 1 1.51.63l.52.29.54-.23.87-.37.77.77-.37.87-.23.54.29.52a6 6 0 0 1 .63 1.51l.15.57.56.22.87.36v1.08ZM9 4.8A4.2 4.2 0 1 0 12 6a4.18 4.18 0 0 0-3-1.2m2.12 6.32a3 3 0 0 1-4.24 0 3 3 0 0 1 0-4.24 3 3 0 0 1 4.24 0 3 3 0 0 1 0 4.24"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
export default SvgSettings;
