/* @ngInject */
function UserSettingsService(CacheFactory, constants, HttpService) {
	const DEFAULT_CACHE_SETTINGS = constants.cache.defaultSettings;
	const USER_SETTINGS_CACHE_ID = constants.cache.ids.userSettingsCache;

	const userSettings = {};

	const getCache = cacheId => CacheFactory.get(cacheId) || CacheFactory.createCache(cacheId, DEFAULT_CACHE_SETTINGS);

	userSettings.clearCache = () => {
		const cache = getCache(USER_SETTINGS_CACHE_ID);
		cache.removeAll();
	};

	userSettings.getOooSettings = () => HttpService.tradeshiftGo().path('/rest/userSettings/ooo').get();

	userSettings.getUserSetting = (settingName, { useCache } = {}) => {
		const cache = useCache ? getCache(USER_SETTINGS_CACHE_ID) : null;
		return HttpService.tradeshiftGo()
			.path('/rest/userSettings/' + settingName)
			.config({ cache })
			.get();
	};

	userSettings.saveUserSetting = settingsValues =>
		HttpService.tradeshiftGo().path('/rest/userSettings/save').post(settingsValues);

	userSettings.getSelectedTeamID = async () => {
		const [selectedTeamID] = await userSettings.getUserSetting('selectedTeamID');
		return selectedTeamID;
	};

	userSettings.setSelectedTeamID = teamId => userSettings.saveUserSetting({ selectedTeamID: teamId });

	return userSettings;
}

module.exports = UserSettingsService;
