"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sideMenuInitialState = void 0;
exports.sideMenuSlice = sideMenuSlice;
exports.sideMenuInitialState = {
    sideMenu: {
        isOpenOnDesktopView: true,
        isOpenOnMobileView: false,
        isTeamSwitcherAsideOpen: false,
        shouldRenderSideMenu: false,
    },
};
function sideMenuSlice(set) {
    return {
        ...exports.sideMenuInitialState,
        openSideMenuOnDesktopView(isOpenOnDesktopView) {
            set((state) => {
                state.sideMenu.isOpenOnDesktopView = isOpenOnDesktopView;
                state.sideMenu.isOpenOnMobileView = false;
                return state;
            }, false, '[openSideMenuOnDesktopView]');
        },
        openSideMenuOnMobileView(isOpenOnMobileView) {
            set((state) => {
                state.sideMenu.isOpenOnMobileView = isOpenOnMobileView;
                state.sideMenu.isOpenOnDesktopView = false;
                return state;
            }, false, '[openSideMenuOnMobileView]');
        },
        setIsOpenTeamSwitcherAside(isTeamSwitcherAsideOpen) {
            set((state) => {
                state.sideMenu.isTeamSwitcherAsideOpen = isTeamSwitcherAsideOpen;
                return state;
            }, false, '[setIsOpenTeamSwitcherAside]');
        },
        setShouldRenderSideMenu(shouldRenderSideMenu) {
            set((state) => {
                state.sideMenu.shouldRenderSideMenu = shouldRenderSideMenu;
                return state;
            }, false, '[setShouldRenderSideMenu]');
        },
    };
}
