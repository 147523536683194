"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authInitialState = void 0;
exports.authSlice = authSlice;
exports.authInitialState = {
    auth: {
        login: {
            isPasswordLogin: true,
            isSSOLogin: false,
        },
    },
};
function authSlice(set) {
    return {
        ...exports.authInitialState,
        setLoginMethod({ isPasswordLogin = false, isSSOLogin = false }) {
            set((state) => {
                state.auth.login.isPasswordLogin = isPasswordLogin;
                state.auth.login.isSSOLogin = isSSOLogin;
                return state;
            }, false, '[setLoginMethod]');
        },
    };
}
