"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useValidation = useValidation;
const react_1 = require("react");
const zod_1 = require("zod");
const removeFalsyPropertiesFromErrors = obj => {
    return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value && (!Array.isArray(value) || value.length > 0)) {
            acc[key] = value;
        }
        return acc;
    }, {});
};
function useValidation() {
    const [fieldErrors, setFieldErrors] = (0, react_1.useState)({});
    const validateField = (0, react_1.useCallback)((schema, field, formState) => {
        const { success, error } = schema.safeParse(formState);
        if (success) {
            setFieldErrors(prev => ({ ...prev, [field]: undefined }));
        }
        else if (error instanceof zod_1.ZodError) {
            const fieldError = error.flatten().fieldErrors[field] || [];
            setFieldErrors(prev => ({ ...prev, [field]: fieldError }));
        }
    }, []);
    const clearFieldError = (0, react_1.useCallback)((field) => {
        setFieldErrors(prev => ({ ...prev, [field]: undefined }));
    }, []);
    const validateAllSchema = (0, react_1.useCallback)((schema, formState) => {
        const { success, error } = schema.safeParse(formState);
        if (success) {
            setFieldErrors({});
        }
        else if (error instanceof zod_1.ZodError) {
            const allFieldErrors = error.flatten().fieldErrors;
            setFieldErrors(allFieldErrors);
        }
    }, []);
    const errorsColection = Array.isArray(fieldErrors) ? fieldErrors.join('. ') : fieldErrors;
    const errors = removeFalsyPropertiesFromErrors(errorsColection);
    return {
        validateField,
        clearFieldError,
        validateAllSchema,
        fieldErrors: errors,
    };
}
