const OverviewBoxComponent = {
	bindings: {
		type: '@', // virtual-cards, available-balance, approved-amount, spent-amount
		currency: '@?',
		amount: '<',
		text: '@',
		borders: '<',
	},
	template: `
		<div
				class="overview-box"
				ng-class="$ctrl.borders">
			<div class="icon-container" ng-class="$ctrl.type"></div>
			<p class="amount" ng-bind="$ctrl.currency ? $ctrl.formatAmount($ctrl.amount) : $ctrl.amount"></p>
			<span id="{{$ctrl.type}}" class="text" ng-bind="$ctrl.text"></span>
		</div>`,
	controller: /* @ngInject */ function ($filter) {
		this.formatAmount = () => {
			return $filter('currency')(this.amount, this.currency);
		};
	},
};

export default OverviewBoxComponent;
