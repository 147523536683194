/* @ngInject */
const requiredDirective = $interval => {
	const MAX_TRIES = 5;
	const TRY_INTERVAL = 100;
	const isChrome = navigator.userAgent.match(/chrome/i) && !navigator.userAgent.match(/edge/i);

	const linkFn = (scope, elem, attrs, ngModel) => {
		const validator = (modelValue, viewValue) => {
			if (isChrome && elem[0].matches('input[type=password]:-webkit-autofill')) {
				$interval.cancel(timer);
				return true;
			}
			return originalValidator(modelValue, viewValue);
		};

		if (!ngModel) {
			return;
		}
		const originalValidator = ngModel.$validators.required;
		ngModel.$validators.required = validator;

		let tries = 0;
		const timer = $interval(() => {
			tries++;
			if (tries > MAX_TRIES) {
				$interval.cancel(timer);
			}
			ngModel.$validate();
		}, TRY_INTERVAL);
	};

	return {
		priority: 100,
		require: '?ngModel',
		link: linkFn,
	};
};

/**
 * element.matches() pollyfill
 */
Element &&
	(ElementPrototype => {
		ElementPrototype.matches =
			ElementPrototype.matchesSelector ||
			ElementPrototype.mozMatchesSelector ||
			ElementPrototype.msMatchesSelector ||
			ElementPrototype.oMatchesSelector ||
			ElementPrototype.webkitMatchesSelector ||
			(selector => {
				const node = this;
				const nodes = (node.parentNode || node.document).querySelectorAll(selector);
				let i = -1;

				// eslint-disable-next-line no-empty
				while (nodes[++i] && nodes[i] !== node) {}

				return !!nodes[i];
			});
	})(Element.prototype);

module.exports = requiredDirective;
