const CardDetailsAsideComponent = {
	bindings: {
		assigner: '<',
		cardDetails: '<',
		cardHolderName: '<',
		expirationDate: '<',
		issueDate: '<',
		isOpen: '<',
		lastFourDigits: '<',
		onClose: '&',
		openCancelCard: '&',
		sourceCardDetails: '<',
		sref: '<',
		onViewCardActivity: '&',
		shouldHideCancelCard: '<',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.cardDetailsHeaderText}}">
			<div class="card-info">
				<div>
					<span ng-bind="$ctrl.cardHolderNameText"></span>
					<p ng-bind="$ctrl.cardHolderName"></p>
				</div>
				<div class="ts-multi">
					<div>
						<span ng-bind="$ctrl.last4DigitsText"></span>
						<p ng-bind="$ctrl.lastFourDigits"></p>
					</div>
					<div>
						<span ng-bind="$ctrl.expirationDateText"></span>
						<p ng-bind="$ctrl.expirationDate"></p>
					</div>
					<div>
						<span ng-bind="$ctrl.issuedOnText"></span>
						<p ng-bind="$ctrl.convertedDate"></p>
					</div>
					<div>
						<span ng-bind="$ctrl.approvedByText"></span>
						<p
								class="card-approver-name"
								ng-bind="$ctrl.approver.email"></p>
					</div>
					<div>
						<span ng-bind="$ctrl.sourceCardDetailsText"></span>
						<event-go-typing class="source-card-loader" ng-if="!$ctrl.sourceCardDetails.name">
							<div class="dot one"></div>
							<div class="dot two"></div>
							<div class="dot three"></div>
						</event-go-typing>
						<p ng-bind="$ctrl.sourceCardDetails.name"></p>
					</div>
				</div>
			</div>
			<menu data-ts="Buttons">
				<ts-menu-button
						ng-show="$ctrl.sref"
						button-class="ts-primary"
						label="$ctrl.viewCardActivityText"
						ui-sref="{{$ctrl.sref}}"></ts-menu-button>
				<ts-menu-button
						ng-show="!$ctrl.sref"
						button-class="ts-primary"
						label="$ctrl.viewCardActivityText"
						on-click="$ctrl.onViewCardActivity()"></ts-menu-button>
				<ts-menu-button
						ng-show="!$ctrl.shouldHideCancelCard"
						button-class="ts-secondary"
						label="$ctrl.cancelCardButtonText"
						on-click="$ctrl.openCancelCard()"></ts-menu-button>
			</menu>
		</ts-aside>
	`,
	controller: class CardDetailsAsideController {
		/* @ngInject */
		constructor($scope, LexicoService, UserService) {
			this.$scope = $scope;
			this.lexico = LexicoService.getLexico();
			this.cardDetailsHeaderText = this.lexico.trc('Component title', 'Card Details');
			this.cardHolderNameText = this.lexico.trc('Data label', 'CARDHOLDER NAME');
			this.last4DigitsText = this.lexico.trc('Data label', 'Last 4 Digits');
			this.expirationDateText = this.lexico.trc('Data label', 'EXPIRY DATE');
			this.issuedOnText = this.lexico.trc('Data label', 'Issued on');
			this.approvedByText = this.lexico.trc('Data label', 'Approved By');
			this.viewCardActivityText = this.lexico.trc('Button', 'View Card Activity');
			this.cancelCardButtonText = this.lexico.trc('Button', 'Cancel Card');
			this.sourceCardDetailsText = this.lexico.trc('Button', 'Source Card Name');

			this.UserService = UserService;
		}

		initConvertedDate(issueDate) {
			this.convertedDate = new Date(issueDate).toLocaleDateString('en-US');
		}

		async initApprover(assigner) {
			// first clear approver instead of waiting for API call to finish;
			// otherwise it might display data from previous opening of the aside
			// which might be for a different card
			this.approver = {};
			this.$scope.$applyAsync();

			if (assigner) {
				this.approver = await this.UserService.getUserById(assigner);
			}
		}

		async $onChanges(changes) {
			if (changes.issueDate?.currentValue !== changes.issueDate?.previousValue) {
				this.initConvertedDate(changes.issueDate?.currentValue);
			}
			if (changes.assigner?.currentValue !== changes.assigner?.previousValue) {
				await this.initApprover(changes.assigner?.currentValue);
			}
			this.$scope.$applyAsync();
		}
	},
};

export default CardDetailsAsideComponent;
