module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# di transazioni",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minuto",
"ce09c436b49af331a32f1152ede7dd18":"1. Richiedi fondi",
"d2226752d3065fbad4744924b4a0ee73":"2. Rivedi ed approva",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Rivedi ed approva",
"82bab92f1c8030888550b51521266c53":"3. Paga con carta virtuale",
"2ecd62e02e507f8e839a55eac0a04886":"Una voce di codifica con ID '{entryId}' esiste già.",
"5dfd25849740fbce8147838b0d5094eb":"Un appellativo simpatico, per esempio Carta Marketing",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Un membro del nostro team di successo del cliente ti contatterà a breve.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Una persona che non poteva essere cercata",
"976b50e70e4433e2786233979d243445":"Un team con questo nome esiste già.",
"35b61d21ac11d5fc31ebe58541263445":"Accetta il tuo invito a partecipare",
"a372cb2d4204654f52cd9170fb10d374":"Codici contabili",
"110fc66010b6b91cebee7a59a2067aab":"Codici contabili",
"db862bb04dbdc6d04e47063f33be8865":"I codici contabili sono gestiti tramite elenchi di codifica e voci negli elenchi.",
"d6e3848e824f077396be4351eb58edd2":"Azione richiesta: approvare/rifiutare la nuova richiesta entro {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Azioni",
"476e728ea824229f52438e543818bcdd":"Azioni",
"3117746cb7d4ab815ec98274ec4398d5":"Azioni",
"3ab09b66a7f16219d8135977a2a76d10":"Azioni",
"ffafa66eaab0409a2e90aaeb43796def":"Attivo",
"0447e0aa5d00dbdab11b5546793c4479":"Attivo",
"8ee51d21b05466f7d962d4ff5a0b384e":"Carte attive",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Carte virtuali attive",
"89c6ec05861aa914304ba35bc6744124":"Carte attive",
"5280659bcecaf8344c57790f9962a9a2":"Attività",
"8a15368ae286fa64e1cb7624cd410fc6":"Aggiungi elenco di codifica",
"8ded7e09e86be231b1165308329ac76d":"Aggiungi elenco",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Aggiungi amministratore",
"695bb4bc29e2df769ddf7427ab71012a":"Aggiungi approvatore",
"4a1d4516cab00b7c5ecdb150e346e5db":"Aggiungi approvatore",
"56869851b27c2915f5a7fe6a33b91ac7":"Aggiungi elenco di codifica",
"8f8691057c3994aa61f9994202680c87":"Aggiungi elenco di codifica",
"69f7a406b79d19bf8082ed245ab29123":"Aggiungi elenco di codifica",
"48a92ca1cc63018707be99b13196369f":"Aggiungere amministratore azienda",
"a679db9ff80d0e5769ae1ba311c7916f":"Aggiungi dominio",
"8f5e8078be77146ba7f3be49ef948906":"Aggiungi voce",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Aggiungi messaggio",
"f8a4b14162e19995074e63135b65c84d":"Aggiungi nuova carta",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Aggiungi persone",
"c670549d0b898bb6a571eb2cd7804a6e":"Aggiungi ricevuta",
"eb937098e77d8a6b0565ed72357dcaae":"Aggiungi ricevuta",
"1e154f31c31b63a459f3956e380b3ef9":"Aggiungi selezionata",
"ba24db9956f156a18b32e653552ff682":"Aggiungi alcune persone a questo team",
"52bd93fb5489b25167bb541f7c6504ae":"Aggiungi carta sorgente",
"21ab3f08eb507dbc76df166eafeb393a":"{fullName} aggiunto come approvatore!",
"f879c06507d3076bbf52ea4fd2df850e":"Dettagli aggiuntivi carta",
"21e934ae30eece4a894337701f497638":"Tutto",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Tutte le carte",
"8fac71195e2b506e029752e4864b6a5d":"Tutti i membri",
"f9708417df16227742e0545a8642eea7":"Tutti gli utenti disponibili sono già stati aggiunti a questa carta.",
"cb5abf070f8cba7982205038baab77bb":"Tutte le carte",
"dae7d602eec2c84dea0d80ae25e56e6d":"Tutte le carte",
"0b4bdfde6578668b6018d3615a1ad30f":"Tutti i centro di costo",
"97b875b81f54b4432d998a3e47d949cc":"Tutti i team",
"f0ae83969f7c20a1964caf5156dae966":"Tutti i team",
"d87455601a00282078582b36a3825b7d":"Tutti i team",
"24c7b7f55882ea94a525392ca8218fa0":"Tutti i team",
"8f860e35b64a8f447bded1e5ea66a2a8":"Tutte le carte virtuali",
"e403956ecaa6fb33ec1fef5c49d71042":"Tutte le carte virtuali",
"4e6f4e8f25f61d40e1eba77de94f6458":"Tutte le tue informazioni finanziarie sono crittografate e su server in luoghi sicuri.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Importo",
"a4d099d0dbe3889b0995546e6ed28932":"Importo",
"6c0ef32aae76bc31a61e2124f542bdf1":"Importo",
"adec89c93525f1a8d1c64d856f405e27":"Importo: da alto a basso",
"18e632a170203894f40deed1d33ee552":"Importo: da basso a alto",
"a7944575cafef9126a499a1b06999fe3":"Si è verificato un errore, ti preghiamo di riprovare più tardi.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Applica",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Opzioni di approvazione",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Impostazioni di approvazione",
"492eedd88cdd7bb45f39c6594af748c4":"Limite di approvazione",
"437d490afc8d226f8c7d544f4e35d011":"Limite di approvazione ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Approvazioni",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Approvazioni",
"17adb7fe866e32055b1469fdb7e82795":"Impostazioni approvazioni",
"7125aa13db437084e1d27d3ff38f22e2":"Approvare",
"84df787ff5746f9dc3cf0146becef4fd":"Approva richiesta",
"7a568654d00245b0bab6c90a1b67d196":"Approvato",
"179c3e187ad8edeb8348cff222b938bf":"Approvato",
"ad058d17725870df9abbd4f5ec217636":"({count}) approvato",
"55ab98be3f0530e16d7b4c962ea0faca":"Importo approvato",
"4d58ba5c0e03fec1556c243be106e4a3":"Approvato da",
"7d3d697fc99f6c4641b326623743f7b7":"Approvato { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Approvatore",
"8c45e443fc6748190719d79a092e2528":"Gli approvatori hanno un limite massimo di approvazione",
"4fe56aa6456c108cd288a4ec8acf435f":"Archiviare",
"a9b6f93de9630064be9809130aa1e195":"Sei pronto a prendere il controllo della spesa della tua carta?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Sei sicuro di voler cancellare questa richiesta?",
"301bfc2ce058f49e0704fec3d162b5b5":"Sei sicuro di voler riassegnare questa richiesta nuovamente a te?",
"2181faf8d9973d1f4138e401a762086e":"Sei sicuro di voler rimuovere ${firstName} ${lastName} dall’essere un amministratore dell’azienda?",
"b982277f29055d0ef6e295fe2006d9e0":"Sei sicuro di voler rimuovere {firstName} dal team?",
"456c0ccff14acb808583cd9dece857b6":"Assegnato a",
"bde315b9cf7fd55f4b65aabbbf38d462":"Almeno un manager o il proprietario del team deve essere disponibile per usare la funzione fuori sede.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Autenticazione",
"1961ec69dd0c8b5718c831312e7f3d0f":"Raccolta ricevute automatizzata",
"e39f2477cf34d726936a5b5c2b48a4ad":"Crea automaticamente rapporti di riconciliazione accurati che includano informazioni su acquirenti, approvatori, ricevute, codici account ed altro.",
"434ca07dfde0eaee14f094b7c75a6eae":"Disponibile",
"eb85d8f50f2c89871b794f7d54279b22":"Saldo disponibile",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Disponibile { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Indetro",
"c8258f8cf9e20f6bbb89d957de40e820":"Sicurezza di livello bancario",
"d46c22a3f36dce568c07fcea4368b231":"Impostazioni di base",
"fb481c8e517016094b4fdf618c5fa088":"Indirizzo di fatturazione",
"79a9f821923c5a04c076902fef684281":"Indirizzo di fatturazione",
"877a08b8347052c83aa0b689baea81e7":"Indirizzo di fatturazione",
"97f018458e2627fac37d24846c7b8922":"Continuando con la creazione del tuo account e l’utilizzo di Tradeshift Go, accetti i nostri",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Continuando con la creazione del tuo account e l’utilizzo di Tradeshift Go, accetti i nostri",
"912d1864f0068c764bf4784020795d49":"NOME TITOLARE DELLA CARTA",
"d53474c7fd99fbeb69481ce9d358aa09":"Può richiedere carte e avere un portafoglio per tutte le loro carte. Ogni membro avrà un responsabile dell'approvazione (manager) predefinito per le proprie richieste.",
"f30df2cab105ed3f8b4264aeb9800abd":"Può approvare le richieste e creare carte per i membri che gestisce. I manager hanno una bacheca dove tenere traccia delle spese dei membri che gestiscono.",
"03ed9eccc71ef59165292eac542d3050":"Può visualizzare tutte le richieste e le attività di spesa nel team. I proprietari di un team hanno anche una bacheca per tenere traccia delle spese e del portafoglio del team, dove possono vedere tutte le carte attive.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Impossibile rimuovere questo utente. È necessario almeno un proprietario o un manager del team.",
"a0b70642629a55ea0ab6fa3414d342e0":"Impossibile rimuovere utente con richieste in sospeso.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Annulla",
"5446624d3c364ac05ddf8d6663da2796":"Cancella carta",
"53f3815c4fa3340b29efd81e627d1e6b":"Cancella carta",
"12257ea2a871b7fab72245b5aafe6424":"Annulla richiesta",
"2aafb7eb4d56a516c4bb0754f0812df1":"Cancella carta",
"caae12601b01e2325161e95107f9e332":"Cancella carta",
"6ad1e57b1e4c7accbe166c948883077d":"Annulla con un clic se compromesso",
"f6527e49e6d5c989624507a583e23f39":"({count}) cancellata",
"1ad3bf5121641dda511f42942af05a90":"L'annullamento di questa carta non annullerà gli addebiti in sospeso. Ti preghiamo di cercare il pagamento",
"aebd3826a195afad0a93050a7cc5d73d":"L'annullamento di questa carta non annullerà gli addebiti in sospeso. Ti preghiamo di cercare i registri di pagamento per tali addebiti nell'estratto conto della carta di credito inviato dal fornitore della carta.",
"a5b5136947da562f91b75daf8156f50e":"Dettagli carta",
"9ed31ad23a1578ce480d31207decf1d3":"Impostazioni carta",
"def15f2e007fc69886230fdb2511305c":"Dettagli carta",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Dettagli carta",
"67cfd260078ca32027c2fa4a4a2d29f4":"Titolare carta",
"4458819ea60c68c46e551a8f5cc163cd":"Nome carta",
"0858bcbd506fa3240b165e59d2f18aae":"Nome carta",
"5212736dd18ee2c31f3650ad2c2c7130":"Nome carta aggiornato.",
"c96d62fa510c3356dc73a91d1a80e262":"Numero carta",
"41307fee4c32aefb5383a98fe3d6e45e":"Tipo carta",
"3f8045c3358621dad4346a44de0c05a8":"Nome titolare della carta",
"879a6b452033cf5f3a4bb3e1837a0386":"Città",
"593334553ef852024e172505ec3c5f21":"Clicca qui",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Codifica",
"df931751d5790d74fa46707aaf7a26ae":"Codifica 1",
"7ac7154bc776aff52a71b0d83b860561":"Codifica 2",
"586fcf19136ae5e26795e9055b2e2995":"Codifica 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Codifica 4",
"379bb8e71bb090badebbb0751de86f11":"Codifica 5",
"3e84d13627b3d090ab2b8470af98340a":"Codifica 6",
"33b94b581f3792049992663396e51cb8":"Codifica 7",
"b73fbc32e439655833ee99126dd0e55f":"Codifica 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Elenco di codifica",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Voce di codifica",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Voce di codifica eliminata.",
"cada62a39ee72d790dcdbb25160f8783":"Voce di codifica salvata.",
"f645bf50888943ee1384693e56a0f475":"Nome elenco di codifica",
"a999182acbcd77c15299f79181b630be":"Elenco di codifica salvato.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Elenchi di codifica",
"d0b96596ce6baec5aabb8a0690ab30e1":"Commenti",
"ff9cb8842f832d21fb160b3d3784ce79":"Azienda",
"715b59a64e686eb91a21114644ffe39e":"L'amministratore dell'azienda è stato rimosso",
"8758e9a01253c097a42393fda9610715":"Bacheca aziendale",
"e118b41009918b326db6746856831cbc":"Id azienda",
"4dba88b05b3fa8875128e19e88c512b3":"Impostazioni dell'azienda",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Impostazioni dell'azienda",
"f2ea6d33de8182d97bf2f13354af4893":"Impostazioni dell'azienda: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Amministratori azienda",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Limite d'approvazione aziendale",
"7e2f778da14a726b53e05926a3483f2b":"Carta di credito aziendale",
"7313fd1db3d63858586d1c0f89251d6e":"Nome dell'azienda",
"07721e91dd10ff8831f0d24e47cc1eab":"Nome dell'azienda",
"203221610ca4d347063c037e1a732ae8":"Impostazioni dell'azienda salvate.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Conferma azione",
"d694cec09cdbc02b1ffe22642a63faa1":"Confermare password",
"512801312a133f29974f2259a455f39b":"Confermare password",
"f54c38ed0b9869d929ffc0bd5310131f":"Conferma: il tuo account è stato creato in team {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Contatta l'amministratore della tua azienda per la creazione di codici contabili.",
"7a6a4de077398e8e138aec36e3b3d305":"Continua con SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Tutti i diritti riservati.",
"51f0d8e04f6ee626b866b37833dc2b61":"Centro costi",
"e043f60a02f157d0f31a42f0db67cc87":"Impossibile invitare {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Impossibile inviare il messaggio, riprova.",
"ae28083f22a0b2fc5637614bce0976e0":"Crea elenco personalizzato",
"738078dc992208e05406f935995dc0c3":"Crea elenco personalizzato",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Crea nuovo elenco",
"0f5783c3921b3d7398d281644bbe5bde":"Crea una richiesta",
"6d62f934f2304701f88bdcfbc688de7e":"Crea account",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Crea un account",
"f6b7d0d8531ea268f988005dd2147b2b":"Crea carta",
"1a083675d1453448e4645403bbe52dd9":"Crea elenco personalizzato",
"c10ab11bdfb001da74850ed89c45482f":"Crea nuova richiesta",
"43a9c06398408bfe77d06ffc2c1c3b87":"Crea nuova richiesta",
"68bf17fd3f54a89c0a84224e2f9a971c":"Crea nuovo team",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Creare team",
"3b8e665101b9b3657f8a68c5305947b7":"Creato",
"40e91f2907181ffa8e62567b3a407e60":"Creato da:",
"8929f7aacb969ef6353aba9903eb362d":"Carta di credito aggiunta",
"d86688c26e8ac5eb9facc971b6fe5155":"Valuta",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Valuta",
"500ded53f64e737a5e4337675ac9b7ce":"Nome elenco personalizzato",
"b79a95f04fd829fb2e8d0313e522a94d":"Quotidiano",
"ef445eab224fc599e4103c9b1745a4e0":"Bacheca",
"788452c3ad2b1450b6d8d116b863e635":"Bacheca",
"fb7add662cf7042355319a975fab6ae3":"Crittografia dei dati",
"6bded249dbfafd14490eea8dd37e1f51":"Data",
"46e908a57cb09ef947af8c4a93a09716":"Data",
"e4213104a55eceb60e00408699438dcd":"Disattiva il team",
"c8f8e49f0db4d998dd0a274bfd4e89db":"La disattivazione di un team annullerà tutte le carte virtuali attive e nessuno dei membri, manager o proprietari sarà in grado d’accedere al team. Solo tu o un altro amministratore della società potrà riattivare il team. Sei sicuro?",
"0d0d5677bee2b99884802638e507160a":"Declina",
"b5e3483aae9d36ce1440fe02904d6da1":"Declina richiesta",
"00f3db97287cb17ee9c2a131f2141a65":"Declinato",
"74fdf2e0ad0de26fe28b0b72ba44f494":"({count}) declinato",
"268ed9551ae9f56647eaa394e43c160b":"Approvatore predefinito",
"b77589ac3ac2317cfc96d7013658d5c9":"Elimina ID codifica: { entryId }, Voce: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Dichiarazioni e relazioni dettagliate",
"5234dfc4220ccd18a8b62fe84e372248":"Dettagli",
"37929896817a6744718004374bb42fd8":"Dettagli",
"dd0a6194e8a6ae14f3e1379a1640de15":"Intendevi rimuovere {fullName} ({email}) dall’azienda?",
"1a98048b34090da8b199135ccb4c1186":"Il numero distinto ed il CVC nascondono i dettagli reali della carta",
"3cf132b60fe16b816ca227bbcad60537":"Non cancellare",
"dee57797e14ccf852aa2bc545bafdf6d":"Scarica",
"375ae8224d7d35393974775cebe8605a":"Scarica l'elenco utenti",
"54b3bfd45a293b441d441aab1a50e644":"Scarica report",
"60cf3b94e5df14a504825068928fc118":"Scarica report CSV",
"7341a4c73c0b688484304c37da4a6d8e":"DATA DI SCADENZA",
"f628f8ad44fbd6c70c99fc778407b666":"Cerca facilmente le transazioni storiche ed esplora le tendenze dI spesa dei dipendenti in qualsiasi intervallo di tempo.",
"94bb409d034ea20697ae09d812e55365":"Modifica elenco personalizzato",
"2974e704676cb79666bc236d8658fa97":"Modifica profilo",
"9ff205f99ed4002d049f855f965ca6d8":"Modifica utente",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-mail",
"0534c00af758d42ebd8b0bd772eab074":"E-mail",
"0c9aa1fbdf925065d25161eab5e51d95":"E-mail",
"ea78fac68766a48b1b0f41305b7e9898":"E-mail",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Il dipendente richiede fondi dalla carta aziendale per effettuare l'acquisto. I fondi richiesti possono avere impostazioni di limiti di spesa.",
"829cff16ce0b13810c914c161b2a9fba":"Attiva le funzioni alternative per ottenere l’utente",
"741387c7bd71e2b4a9601af9a6b9490e":"Abilita l'inoltro automatico delle richieste di approvazione",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Abilita la data di scadenza predefinita per la carta virtuale",
"bb319710dad93e78cc6f58c615bdf210":"Attivare la lista bianca del dominio e-mail per gli utenti invitati",
"4700ced24cd278c3149ffa983006035c":"Abilita il filtro per centro di costo",
"3fe06ef8f551c4cb4c0375362e25521d":"Abilita opzione di globalizzazione dell’utente a parte",
"2787d4bc9752272ac36b499ce7ad93ab":"Attiva le carte virtuali multiuso",
"6ee44e2280c9ce5009568846cbc3dd14":"Abilita la funzione fuori sede",
"1941ca87053df0cebc6091ede92c18ea":"Abilita la visualizzazione delle persone per gli utenti amministratori dell'azienda",
"e50282c2ec7afb948d2c0b428db5d5ff":"Abilita il caricamento della ricevuta per le transazioni",
"268694cb2172918fe60f835919bf20ec":"Abilita il caricamento delle ricevute nella tabella dei pagamenti",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Abilita auto approvazione",
"d17c7acf598b569cce8a2bb97e0bb215":"Abilita le carte virtuali monouso",
"cd731ca218c9e14caabefafe003cfa6d":"Abilita abbonamento carte virtuali",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Termina l'inoltro il",
"c1f665a814894b17d603b7052b631d90":"Registra una nuova carta",
"6b6cea93e07e596be70f7b76801e1516":"Registra nuova carta",
"7d6ac1ac9cba1620b61908842ebb106b":"Registra carta sorgente",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Registra carta sorgente",
"21a642b339c3eabf2789c0b05dcf828b":"Registra la tua prima carta aziendale su GO.",
"6efa8dca4fba23a190838746879fd704":"Inserisci un id numerico",
"02c3457577da9dc8dba6e6f9380a248e":"Inserisci un nome univoco per il team.",
"40feb3fd1fa529cb599aeac9607664a4":"Inserisci nuova voce",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Esempio: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Mese di scadenza",
"b8bc74aeeb2de3492891c97361de107d":"Anno di scadenza",
"6a0b8d521500014d99dd192d8ce8403d":"Data di scadenza",
"8cf274169a732340430fe7cc5236e0e7":"La dimensione del file dovrebbe essere inferiore a {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Nome",
"7458b55e967e51791d98e77458631e44":"Nome",
"3bbe52e71bc5c212cea77983f1a5b748":"Nome",
"9b49c88fa20cc006baaac15c857c8781":"Per singoli o multipli acquisti",
"bb7a2907073bd760f4e82097132637b1":"Hai dimenticato la password",
"26bdfaa592f3629273f13c22435b323d":"Hai dimenticato la password ?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Inoltra",
"3f9fe6adc14ce5776097170653a24462":"Inoltra richiesta",
"b774e35683f5737c36bf0cf3d6785973":"Inoltra richiesta",
"628faa5054a912b1e470414f77057e6b":"Inoltra richiesta",
"852b4065e1837fdc77940b6411a6d902":"Protezione antifrode",
"616551bd10d95aca439401d6a7769d96":"Importo del trasporto",
"a65a230918e9c11e1ef8824745e26a3d":"Per iniziare",
"7d4c4466b7716347ce3673acaa1983f7":"Ottieni di più da Go",
"11415a07658cfef80eb166896d77ad98":"Preparati per ulteriori approfondimenti sulla spesa. Anche Tradeshift Go ora si allineerà meglio con il modo in cui i team aziendali amano lavorare.",
"ac0ff5d14286ef74614a9c6021f08f69":"Ottieni il monitoraggio della spesa e del budget in tempo reale, ottieni informazioni dettagliate e approfondisci tutte le attività d’acquisto.",
"db9071ab833054307252a936e7f60e1b":"Per iniziare",
"24dc4bb00841b004031fca3a9bb9bf51":"Richiesta di carta Go annullata da {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go ricorda ai dipendenti eventuali ricevute mancanti, che possono essere allegate semplicemente rispondendo alla notifica o, nel caso di una ricevuta via e-mail, inoltrandola a Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go lavora con la tua rete di carte di credito per garantire gli stessi standard di prevenzione dalle frodi che ti aspetteresti quando lavori con le più importanti istituzioni finanziarie.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Buone notizie! La tua richiesta emissione dell'ordine è stata approvata su Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Hai domande o feedback? Mettiti in contatto a",
"9df1d3de68d663b848101df3c7b58e39":"Come funziona",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Sono certo",
"84074732eb8d1b4094404cd750baf949":"Se non è presente alcun valore sarà applicato il limite aziendale",
"138e8bd6102a7eea70a123ff03b1cd79":"Se non è presente alcun valore sarà applicato il limite del team",
"e5756af4d7e5ad1236ccfbb858428421":"Se non ti sei registrato per Tradeshift Go utilizzando questo indirizzo e-mail",
"4f004f75f47dbfecdb6d09990596e530":"Se la tua e-mail non è ancora registrata, t’invieremo una e-mail d’attivazione con i dettagli su come procedere.",
"123837f267de602fb05361b3b1197c1e":"Se {username} abbinato ad un account esistente, t’invieremo un'e-mail con i dettagli su come procedere. Se dopo qualche minuto non riesci ancora a vedere l'e-mail nella tua casella di posta in arrivo, controlla la cartella dello spam.",
"45efd96cd93525a9c17222217a37d324":"Formati di file d’immagine o PDF supportati",
"fa6ae823f9ab8bf91c246efe2517956c":"Importa la configurazione del tuo account QuickBooks, NetSuite o Xero direttamente in Go. Esporta le ultime transazioni nella tua soluzione di contabilità con un unico clic.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inattivo",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"L'input contiene un carattere non valido.",
"62c7be09da43f8f96a60ef3967eddc2c":"Introducendo un Tradeshift Go totalmente nuovo.",
"19746bcbed8b8bcb1a9b83efca722077":"Dominio e-mail non valido.",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Invita persone",
"3809b41b14c55c3fcf54cd813d8e21f5":"Invita nel team",
"fc90a11805961b0d7b5a5545e4d9bc05":"Invita utente",
"6f7f4acde92d267417f5d8a60d32400a":"Invita più persone",
"09ecd2ca15bb5c38dc10f653118346cd":"Invita nuovo utente amministratore",
"3f576ce504f53f972442a63a170c5418":"Invita nuovo {role}",
"0215e052437ceea4e5cd562431b34ced":"Invita persone",
"8aaf2c77df644b7e8524120f66da7d5f":"Importo della fattura",
"1b7dc8290f1205f092cc9133e0cde385":"Data fattura",
"1a6fb210308df8e0135e49e76aaff31e":"Numero della fattura",
"613da3d0cd586ef0360e0d93eace9508":"Emesso il",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Necessita",
"3322b671105c65511fbab1966b7fdcf6":"Lingua",
"b1d946192795a58aea6a61f4f923acb7":"Ultime 4 cifre",
"b3c6b8db4ef6dcdcb451303e65548816":"Ultime 4 cifre",
"0382123b9c847000fceff2484c665e39":"Le ultime quattro cifre",
"19f7c0055e150e9e3e66e85c2993799c":"Cognome",
"662c84bede8a7bf91ab15bcb73b48b49":"Cognome",
"cff8fbef5aac2523344b9535bd56973c":"Cognome",
"c3510b2184a92ff12e07a2d1b9a461e6":"Consenti ai dipendenti di richiedere l'accesso alla tua carta aziendale senza esporre i dettagli della tua carta, il tutto facendo rispettare i budget e monitorando ogni acquisto.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Il limite può essere superato a livello di team e di approvatore individuale",
"9bf5d56627e500cad9f7ad21c3e7966d":"Limitare a determinate categorie di spesa",
"e80a6d4232e52f6bfabee02e17403b59":"Collega una di queste carte sorgente a questo team o registrane una nuova.",
"803570ab9f0fba5ff35ae2749200501f":"Caricamento carte...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Accedi",
"b246fe8836c906f1b56342378ca88895":"Effettua, approva e monitora gli acquisti in team che rispecchiano la tua struttura organizzativa.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Gestisci pagamenti",
"95d672ab621e531ae1a69798e0e4b56c":"Gestisci tutte le tue carte virtuali in un unico posto.",
"96f2e88a167f476413b35aa711709e6e":"Gestisci i pagamenti",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Il manager riceve ogni richiesta per revisione ed approvazione. Anche le limitazioni di spesa possono essere regolate.",
"4f449985e83c1f9ca2e8f82b0707a371":"Il manager riceve ogni richiesta per revisione ed approvazione. Anche le limitazioni di spesa possono essere regolate.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Manager",
"27f8111a955975ea6fd04f748d24cb4a":"Limite massimo di approvazione",
"5b1d9cfb78c55e75b47c62435d9299ca":"Membro",
"41e307c1874e2e82296b226e9bae4a5f":"Membro",
"6f9612aa1582373c2c1ba4e144132100":"Membri",
"590429a0fcc42af85648d0fd70601dcc":"Membri",
"3f50e62c8488d83ad84f69ebda853897":"Membri",
"e0fc0acda5b74b93e797756dbbba67bc":"Membri",
"c7ef9eda38b7eaee1f20031387a8bde7":"Mese",
"40dd08b507752bef4e3c4b36838317d5":"Mensile",
"d712b91f7498f212047aad4c6d0f8088":"Più recente",
"12110d95b3d7a495afa4f28ac190f63e":"Più recente",
"2f11ce3865f79ee7e5ee64d728082264":"Più recente",
"eadc93865655cffc6a9863fdda5693e1":"Dev’essere di almeno 10 caratteri e contenere lettere maiuscole e minuscole.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Dev’essere di almeno 10 caratteri e contenere lettere maiuscole e minuscole.",
"50d0a69aa59df5201018771a06ea12e7":"Deve essere unico",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Deve essere unico.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Le mie carte",
"c36602f50d35c2816f0ba8a196b70876":"Le mie richieste",
"ff6e372aa3eb685a39a701963db35b2f":"Le mie richieste",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Nome",
"1c7514b561a2591a914225813d30d642":"Nome",
"d621826c1ceade262589af04e655e824":"Nuova password richiesta",
"35dc3304b61a9e69dc3a393123658837":"Le nuove richieste verranno automaticamente inoltrate durante questo periodo di tempo.",
"e788124801b4727d121f60d059a2a4a7":"Avanti",
"eca804f1d53dfd984ffd93e2d00fc284":"Nessun approvatore assegnato, contatta l'amministratore del tuo programma",
"abdf7e93f8509399a63108a2b1a25546":"Nessuna attività da mostrare",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Nessuna carta nel tuo portafoglio",
"87127e1e9f5bf922d0a4f948b68697c8":"Senza limite",
"40512efa6b75ef0c7924b1ee3070fca9":"Nessun membro da mostrare",
"dcda493afcb2415d5ca49a17d1af6f50":"I pagamenti non sono ancora stati pubblicati.",
"f0efb26e623bb990532e375d5c8875ec":"Non disponibile",
"bc0cf602d1978e814070bb552e576a32":"Non mappato",
"09e126c317429ccb52ad323c32293648":"Numero di pagamenti",
"75b00b969b9d3b520929f1e652637b70":"Numero di pagamenti",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Numero di pagamenti",
"bd44ba180aa356c5755fdedd32adf03c":"Oh accidenti! Si è verificato un problema durante la creazione di una richiesta. Ti preghiamo di riprovare.",
"c396b3728dd7393aa9e5427990c132a7":"Oh cavoli! Si è verificato un problema durante il download del report.",
"42c07764b07dea1ee7de7605721c3f5f":"Oh accidenti! Non siamo stati in grado di creare un nuovo team.",
"e3a385878ec94bac12a99490d0a8e85a":"Oh accidenti! Non siamo stati in grado di creare il tuo account.",
"17f2dae79677017e2c2e879578c4e09a":"Oops! Qualcosa è andato storto. Ti preghiamo di riprovare!",
"78c135de545c1663c9768226492a44a3":"Oppure usa Single Sign-On (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Fuori ufficio",
"5d7e45f169e2f6968dc58f43b5267353":"Fuori ufficio",
"ee589a20c262527db970671fb2c4f6bc":"Fuori ufficio",
"4fd0c753fce49a016b98d988a3895223":"Panoramica",
"2789c5d5c9c042858f45aadc4e0de058":"Panoramica",
"083b7090b66ebd0307a18499ac7b676e":"La pagina non esiste",
"a21d205920893cf579a7655a1430fe30":"Carta principale",
"fd35b7ce938e404fdb65de3bb749c528":"Carta principale per report",
"6cae2279088580976d84119d95097cae":"Le password non corrispondono",
"4baec0159d3074f84c0eebcc09ba4cd8":"Le password non corrispondono",
"b676587115243921602742cb8f696a6a":"Metti in pausa la creazione della carta",
"db4a26c871ef0b970b74e1f71763098b":"Sospendi l'emissione di nuove carte virtuali",
"33fc1e6d01b785e3a815c28ef7d56667":"In attesa",
"6ea62e4811b68df48b467e8d367aac2f":"({count}) richieste in attesa",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Persone",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Le persone in un team possono avere 3 ruoli diversi",
"e2d34cb1409b9778d34c4b74ecee1df6":"Inserisci un numero intero positivo o lascia vuoto.",
"8da247d5d736c87122404eff1e5c0c73":"Ti preghiamo d’inserire un limite valido.",
"65e154267ac283d963cbc7be832969d9":"Ti preghiamo d’inserire un valore valido.",
"28b4450d417065a69a395f567967d46c":"Preimpostazione con limiti di spesa richiesti",
"7bbf151d8493170f206a23c2ecc6acd0":"Informativa sulla privacy",
"51602962f2d1e7515943b4a6c66562ce":"Immagine del profilo",
"a51a17940c70a3123da4d68bed562c40":"Profilo salvato!",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Mappatura del fornitore",
"89b1deb2ffe12a13941c362abaeab01b":"Pubblicato",
"d8d806d48052159e3ff3b224f3f53586":"Scopo",
"089917619c78af2efb30be0bb7ce076c":"Riattiva il team",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Ragione",
"e8484c78fda9f453eeb942ba4945f040":"Riassegnare",
"ff2fbc1172ba26776ad0982624cd2713":"Ricevuta",
"588e690db177ee0c7a7cadb39cbd5efc":"Aggiorna fondi ogni settimana, mese, anno",
"32955937daca05b2084556f248692baa":"Registrati",
"f15bb755ba24abe6ab106637292175ec":"Rimanente",
"579782461133a8b8cc6bd77798961d82":"Rimuovi carta",
"e475edc165372f8c8deb532aa85d9950":"Rimuovi elenco",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Rimuovi elenco",
"f9769561315b964d0b3b4887ec208977":"Rimuovi dal team",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Rimuovi ricevuta",
"ee181cef581fc8e8217e66b5762db898":"La rimozione di un elenco ne rimuove l'utilizzo e cancella tutte le voci esistenti. Se desideri rimuovere temporaneamente un elenco e conservare le voci, puoi disattivare l'impostazione di pubblicazione. Sei sicuro di voler rimuovere quest’elenco?",
"feea41d0bc1d577a07c8b6e1297abbde":"La rimozione di una carta sorgente da un team non disabiliterà le carte virtuali esistenti.",
"d09483824a2e0d6194a4c3f32311e752":"La rimozione di una carta sorgente da un team non disabiliterà le carte virtuali esistenti. Sei sicuro di voler rimuovere questa carta dal team?",
"7ca4b1bf1346e179a29da97f260a93a4":"Rinomina",
"db195ec9c818b8e92da142374e20d1bc":"Rinomina elenco di codifica",
"2fd93e229fe9ea754808805d3fef9c13":"Rinomina elenco di codifica",
"aa1e74d32805823f802a11b33c60c38b":"Tipo di report",
"6ac78d97a8cb7f060e43e596e3e23535":"Richiesta cancellata.",
"25deccb1980291a0fa9997109c731440":"Richiedi demo",
"9f2754d87686903b1500ce8e8a55b377":"Richiedi demo",
"92ac924f72679f897e26f3e615be8c22":"Richiesta inoltrata.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Richiedi informazioni",
"270185007853cd779011ae0e8433f723":"Richiesta riassegnata.",
"e5a059c9ba2691910321339cce989f9d":"Richiesta inviata.",
"77c88a231edfa7075114d91d2397e42d":"Titolo richiesta",
"cee91b833c4358acf0c554f746557e71":"La richiesta è stata declinata.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"La richiesta è stata declinata",
"9e56abbbf87420eeedd625ce786515c7":"Richiedente",
"b4cb4426e44fee9580af71912a764536":"Richieste",
"df309e888c29fc447d0309329b39ce93":"Reimposta la password",
"5daf278da19ecbaeb7b96554cb4db10f":"Riprova a connetterti ora",
"31e9501526d2114e4a01fdbf076de097":"Ruolo",
"6fbceb787429f6f81117a1d5a58b4610":"Ruolo",
"473b814ca86841ef672ccf9f7dac28d5":"Ruolo",
"f1e16d5fd643e039d865b6aec7c986f9":"Ruolo",
"ec78345a5975bc0f8efaee5256b43c2c":"Salva",
"2cecea57b47d8f4a45c706287ff7d64e":"Salva modifiche",
"15774be5c338a39169917c7d32781dd0":"Salva e continua",
"844095b4b8f580737b8717672501048e":"Di qualcosa...",
"7e66f676c2e7b04514aca3a0935530f0":"Cerca per informazioni di codifica",
"5293ce9335ec2d176a6e838bf76404c6":"Cerca per descrizione",
"efab3b0c95f263cdd0a8466329a387eb":"Cerca per descrizione",
"d227b38dcbc4548c092b0836a7101506":"Cerca per nome commerciante o ID transazione",
"3629383abf1d5f85efd759f718159b40":"Ricerca per nome",
"a034fcd6719228b28a6fc22d3a46ea8a":"Cerca per nome del team",
"8ac7e0d63f343b548482d5f367f5b3d9":"Cerca per nome del team",
"858323eb52cb45446597ee46330a0540":"Cerca per proprietario della carta virtuale",
"111bfcbff671e13de117ed5195fb6773":"Seleziona",
"bcfecd59eb93e5314873d84ddf35aa58":"Seleziona",
"909a4414ea573218bff2d533eaab46ea":"Seleziona data di fine",
"114b206ca1ff1c0bc52abef342059b2a":"Seleziona opzioni del team",
"81a02e424615fb41a20b8d0f13441c88":"Seleziona team",
"b135b36495618e6692e7fbaf0a0aa83e":"Scegli un mese",
"677e90855e164c00a28b5c4763ac67e0":"Selezionare un ruolo",
"d01570f3774738395b8ba3c64ce54c76":"Seleziona una regione",
"7dee3f06c70626881efb0cd6af6b63e1":"Seleziona un utente a cui inoltrare la richiesta",
"4c7bb810835a1540ddc1f6501da5a51b":"Scegli un anno",
"3ab198d2678af2b5b32180e4b39602ba":"Seleziona approvatore",
"f502ba79f2bd010c5e77891812400958":"Scegli una carta",
"936027182d446e27ba209ea921ef7502":"Seleziona l'intervallo di data di scadenza predefinito",
"b22c893254ccf3bd34faaee511cf7ca8":"Seleziona durata",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Seleziona dalle preimpostazioni dell'elenco di codifica o crea il tuo nuovo elenco.",
"42cce5fa376b45bf290f86c684d69d96":"Seleziona campo provider",
"b761773d46699b60e8d6874b85593374":"Seleziona team",
"3a9396756942ede98d0cb6afc1032b15":"Seleziona il loro approvatore",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Se stesso",
"03e9d2c128af288126d94f631c5396be":"Inviando istruzioni",
"299a7e961804e2d649f06b5dc4362c29":"Invia invito",
"155e7b3f7db7bf10b03b5f41de54eae1":"Invia messaggio",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Imposta una data di scadenza",
"f89ec96a4b5bb639488ab742efa76a6a":"Imposta una nuova password",
"7d5377ad70d57728a45d3c0639de5544":"Impostazioni salvate",
"14313f11dee735f2144953e720776c28":"L’importo del pagamento può essere diverse se mance o altre commissioni sono applicate all’acquisto",
"199000fe0cda0adaaa9b864aa7447cb9":"Pagamenti",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Pagamenti",
"6e6ac6ff3bd722e37874fb1ea7115818":"Imposta la tua password",
"9b4ecf052980e80bf5c65a415d470a43":"Condividi e controlla l'accesso alla tua carta aziendale",
"ba746d78772d6be26cc508ba989becb0":"Dovrebbe essere minore o uguale a {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Mostra le transazioni senza ricevute",
"7d474b60aeb7cc25b519e806fca71e12":"Accedi con le tue credenziali \n\n esistenti di Tradeshift",
"16080cd4e8319ef3e7de21b6c19d1437":"Qualcosa è sbagliato",
"8f358a370224baf849f9652810c68db2":"Qualcosa è andato storto",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Qualcosa è andato storto",
"99af87c475a4d40ef732deed96f11ed2":"Qualcosa è andato storto. Ti preghiamo di riprovare.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Siamo spiacenti! Stiamo riscontrando problemi di connessione - riprova in {retryConnectionPeriod} secondi.",
"84debc8e41646cd49ac2b14708ca6d73":"Spiacenti, si è verificato un problema durante l'eliminazione della voce di codifica. Ti preghiamo di riprovare.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Spiacenti, si è verificato un problema durante il salvataggio della voce di codifica. Ti preghiamo di riprovare.",
"689d3418ee8f996ad25e152e80de63fe":"Spiacenti, al momento non è possibile disconnettersi.",
"b48efd4521ecd6428987d24636a4349f":"Ordinare",
"776a95003d3ec1dd58af69aedbc08579":"Ordinare",
"1b783238028c0ae085ca69ed823f4d1a":"Ordina per",
"be70d19bf13c935d653b2c4fe7d36224":"Nome carta sorgente",
"564f8c942e7d1bdda5251545612fa348":"Nome carta sorgente",
"aa284602c746aad4a6de6366c24221b8":"Carte sorgente",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Carte sorgente",
"389d471e79a43440b3278b0fc7187d9e":"Carte sorgente",
"106342f689b57145817b00d6fe383f91":"Specifica data di fine",
"2a5e19387ff300509876e3bae99b7ab9":"Velocizza la tua contabilità",
"ca34d586bbdb7f368f1009305bccbe76":"Bacheca di spesa",
"4583120998b44c0a5982cf85dd938d2f":"Spese nel tempo",
"244d724806247953233a0bbfcbf8da4c":"Limite di spesa",
"d39b83dd151c8df6370460a458294ccf":"Speso",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Importo speso",
"5de2859528e030ac1f2af115ebdf209c":"Speso { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Inizia l’inoltro il",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Stato",
"b089930f1f6ffac09438cc19590f8f6b":"Stato",
"f174d20592156eb4b10a7cf1144c973e":"Stato",
"ecf342482d1b6ab932c2000cd7dc38c4":"Stato",
"1f1fe111548158ec842761eca7425ff3":"Indirizzo",
"31a0a748b1097a9e0aa95a811802bdd0":"Riconciliazione senza stress",
"111b782f3f0794c2294aefb6693b2c50":"Invia",
"013e2555920b51ad2a3d2c3dc482c471":"Invia richiesta",
"1270cd8eb69ad64cc71e5de979423b3b":"L’abbonamento termina",
"2802336ad3d8ff658257b88a93f4e50a":"L’abbonamento termina",
"b28dd68454d7acbefafeefb42981fe16":"Frequenza abbonamento",
"907793bc80cf94d560e5f1a0250fdaee":"Frequenza abbonamento",
"29229e153308e4747edef1144de35a55":"Successo!",
"503eb46b80f374f39266e7a9aea8e63e":"Successo!",
"4f20e29cadadb9fbc7455bd4d80de567":"Successo!",
"601d04f10d558f436c78c10e90b44175":"Supporto",
"62558c185ea2c06c5472d1cd534f6e56":"Sincronizzazione con la tua soluzione di contabilità",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Importo fiscale",
"bf23b2fd889e3b12e0f0acf99f913eca":"Carte del team",
"b90a650376782a9e43ed6be6135bd6f2":"Bacheca team",
"6adbec395fe1c4bf776f9fe5fa021052":"Bacheca team",
"6f2695eeb68501dbcfddbb4d265b846b":"Manager team",
"3b779ade9c960ac95a670db0cc12e56b":"Membri del team",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Impostazioni del team",
"dc5235e1b88320d3ba7501e4c30985a3":"Impostazioni del team",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Impostazioni del team",
"a2eb92bfd7502f47b3cd170bc68ac638":"Impostazioni del team",
"f1ec84f051f19f0d0e301a709b13dc45":"Spese del team",
"fbacde3a262ea8696e30135e8f987a44":"Cambia team",
"74b9578ea5a620f5fef102188e42c02f":"Cambia team",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Azioni del team",
"3aa978fa7ef886621285b93257d428c3":"Limite d'approvazione del team",
"a41f9df131c221a99926de7e2138653a":"Informazioni del team",
"941c7da8f256f91ba3819f991315a100":"Nome del team",
"c5d04e10cc2f0b53c2bda893eae09178":"Nome del team",
"504a8ba642ba37c9a743d0fabb2bd371":"Nome del team",
"e768a8e1e3d6a6bf470587dad75423fe":"Proprietario del team",
"e2d824067f8bd871756688c58c382196":"Proprietari del team",
"dca232ccc849c5ff5611255eb82baf53":"Proprietari del team",
"3b61bd586225790cf132591383d15448":"Cambia team",
"bc05b40509f0271c12cfaaef8e5319b1":"Team",
"39e73773d07a800673ec6600422952d6":"Team",
"daabb564bb3dcfb41de69492f854d573":"Team",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Limiti di team ed individuali non supereranno mai i limiti aziendali",
"4b4fe189a48d8eb06d836afa8fb6317a":"Team aggiornati",
"2a17f9a8c610db29fec63fd530c29fdc":"Team che usano la carta",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Team che vedono questo",
"317868f3804ba4ed5d99dd9919c798ba":"Termini del servizio",
"e8c9a9847db2ccd97524aa06f1af9d82":"Termini del servizio ed informativa sulla privacy",
"38852078519c5c46715d8a034fc4901b":"Termini del servizio ed informativa sulla privacy",
"c7b01ce3decb069a8a4b6445627f6f8c":"Grazie per il tuo interesse in Tradeshift Go. Il supporto per le carte di credito {cardLabel} è in arrivo. Un membro del nostro team di successo dei clienti ti farà sapere quando sarà disponibile.",
"f7047b35a069d0790a0a348a29c846a2":"Grazie per unirti al tuo team a {companyName} per effettuare acquisti aziendali utilizzando le carte virtuali Go.",
"133b0a70b1a956889164621380c378f9":"L'azione che stai tentando di eseguire non può essere completata. In alcuni casi aiuta semplicemente riprovare.",
"18f5a1872e156f1aee84ae321780d952":"L'importo richiesto era superiore al limite di richiesta dell'azienda di ${requestLimit}. Ti preghiamo di riprovare.con un importo di richiesta inferiore.",
"867e3d4d3c78990c6852fc5348706ab2":"L'importo richiesto era superiore al limite di richiesta del fornitore di ${providerLimit}. Ti preghiamo di riprovare.con una richiesta d'importo inferiore.",
"570f5f4658b03dda7a910f56c86a3bd3":"La carta per questa richiesta è stata cancellata.",
"3075c56105863546e3368ffead7a146b":"La carta è stata cancellata",
"c6d61bfe3a51966dbdb7e0372a80d15c":"L'elenco di codifica cui stai cercando d'annullare la pubblicazione è collegato ad un'integrazione. Se sei sicuro di volerlo rimuovere dall'integrazione, ti preghiamo di contattare il supporto che è in grado di completare questa azione.",
"756ade8d71ec487a4b48d7c4277ea239":"L' elenco di codifica cui stai cercando d'annullare la pubblicazione è collegato ad un'integrazione. Per annullare la pubblicazione di quest'elenco di codifica dovrai per prima cosa rimuovere la mappatura.",
"4f40335f7c018986c95458183bc0c136":"Il link per attivare il tuo account non è valido o è scaduto. Ti preghiamo di verificare con la persona che ti ha invitato per ricevere un altro invito.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Il link per ripristinare la tua password non è valido o è scaduto. Ti preghiamo di richiedere nuovamente un ripristino della password per ottenere un nuovo link.",
"ded30f0e288c12125ca1a77f8d8fd600":"Il motivo sarà visibile al responsabile dell'approvazione e al richiedente",
"f294897b38fc23dd8f5de52b54ceae22":"La richiesta verrà rifiutata e il richiedente verrà avvisato. Vuoi continuare?",
"e8c7200dc985df0a94a9d996968b4cc0":"La richiesta verrà rifiutata e il richiedente verrà avvisato. Vuoi continuare?",
"5f3d196a2ade3f97a2a48b773fec5861":"La richiesta verrà rifiutata e il richiedente verrà avvisato. Vuoi continuare?",
"b04c191cd6b4f27669a4f390311f156c":"Non ci sono proprietari di team o manager da selezionare.",
"e25045557efbad7b52997b94a1fe6817":"Non ci sono {type} per questo intervallo",
"7260690577c795e999acae63bb7c51ae":"Si è verificato un problema durante il salvataggio delle impostazioni del team. Ti preghiamo di riprovare.",
"f2103024f2a9c6c0335a71c60567d6c9":"Si è verificato un errore caricando il tuo portafoglio.",
"9776cb7eab9fa0d318a8144cd17de745":"Si è verificato un errore caricando il tuo portafoglio.",
"77d28a14bc900be4360b570a82eb75a9":"Non ci sono dati da mostrare",
"2b6feba67722337f88c1443909fe446f":"Si è verificato un problema aggiungendo l’approvatore.",
"24b7212afb97c5d23faafa2ca4559095":"Si è verificato un problema aggiungendo la carta di credito. Ti preghiamo di riprovare.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Si è verificato un problema aggiungendo la carta sorgente al team. Ti preghiamo di riprovare",
"5a8e1b922d1cee746ac6794599b10709":"Si è verificato un problema cancellando questa carta. Ti preghiamo di riprovare.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Si è verificato un problema cancellando questa carta. Ti preghiamo di riprovare.",
"22361c5d4df61ad7ae9792a3baf5729f":"Si è verificato un problema durante la creazione del tuo elenco. Ti preghiamo di riprovare",
"098755bfddeaf0428c7feb6b69af9b6f":"Si è verificato un problema durante la declinazione della richiesta. Ti preghiamo di riprovare.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Si è verificato un problema durante la declinazione della richiesta. Ti preghiamo di riprovare.",
"7d461b6b7b54dbfa9c83243217cd1053":"Si è verificato un problema durante l'eliminazione della voce di codifica. Ti preghiamo di riprovare.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Si è verificato un problema durante l’eliminazione del tuo elenco. Ti preghiamo di riprovare.",
"beb43d7800cfff47e08cd61842d953bd":"Si è verificato un problema durante l’inoltro della richiesta. Ti preghiamo di riprovare.",
"f7775350f35b8365fb9803232d9b792c":"Si è verificato un problema nell'ottenere i dettagli della carta. Ti preghiamo di riprovare.",
"95abaa922c6c1648ca9c39ac4776896f":"Si è verificato un problema nell'ottenere i dettagli dell’utente. Ti preghiamo di riprovare.",
"9d7e4ead60137041ae8a845aa6cf9979":"Si è verificato un problema durante il caricamento del profilo dell’utente. Ti preghiamo di riprovare.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Si è verificato un problema caricando questi utenti dei team. Ti preghiamo di riprovare.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Si è verificato un problema durante il caricamento delle tue impostazioni. Ti preghiamo di riprovare.",
"b2f48028c400befad5f1d57f769ef7cd":"Si è verificato un problema riassegnando la richiesta. Ti preghiamo di riprovare.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Si è verificato un problema rimuovendo la carta sorgente dal team. Ti preghiamo di riprovare.",
"19dfcd761006496c1d20351c04643e45":"Si è verificato un problema rimuovendo questo dominio, ti preghiamo di riprovare",
"149651b5784481c68aaf4ac27bb1f907":"Si è verificato un problema durante il salvataggio dell’elenco di codifica. Ti preghiamo di riprovare.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Si è verificato un problema durante il salvataggio del profilo. Ti preghiamo di riprovare.",
"749ddc235744b6b371668ec0bb049176":"Si è verificato un problema durante il salvataggio dell’utente. Ti preghiamo di riprovare.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Si è verificato un problema salvando questo dominio, ti preghiamo di riprovare",
"04cf86909808f430b8b1904fb422e0d3":"Si è verificato un problema durante il salvataggio delle impostazioni fuori sede. Ti preghiamo di riprovare.",
"2eaf6ee97d984598c95e0494d72aa492":"Si è verificato un problema inviando la richiesta emissione dell'ordine.",
"148f847d3beccaa67fcd5096ae3602d3":"Si è verificato un problema aggiornando il nome della carta. Ti preghiamo di riprovare.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Si è verificato un problema aggiornando il nome della carta. Ti preghiamo di riprovare.",
"de8cc976f7af9edf189904239e6240da":"Si è verificato un problema aggiornando i team. Ti preghiamo di riprovare.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Si è verificato un errore durante l'aggiunta dell’amministratore, ti preghiamo di riprovare.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Si è verificato un errore disattivando il tuo team. Ti preghiamo di riprovare.",
"3f686a97a1b9fcdd73a40d2005693105":"Si è verificato un errore ti preghiamo di riprovare.",
"2f38263789163cf8ee30c8ae88852c82":"Si è verificato un errore pubblicando il tuo elenco.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Si è verificato un errore durante la rimozione dell’amministratore, ti preghiamo di riprovare.",
"007a647254e1f85b6c1e789004e05df5":"Si è verificato un errore durante il salvataggio delle impostazioni",
"c3072d080b587d70f85d68ec089f028f":"Si è verificato un errore durante il salvataggio delle impostazioni per il tuo team Ti preghiamo di riprovare.",
"608cd0e109c3e51b0425df8921e4181d":"Si è verificato un errore impostando la mappatura del provider. Ti preghiamo di riprovare.",
"177cefc68dd318ea75673326e77d8206":"Si è verificato un errore spubblicando il tuo elenco.",
"255d22dc4f0e338a4cf53d5b7256e695":"Si è verificato un problema durante il recupero del nome della persona a cui stai inoltrando le richieste di approvazione.",
"39ac4ec73fed8a981ad1857d989edfcb":"Questo importo supera il limite d'approvazione dell'azienda.",
"494ce3f598cbd97afe99774b5975a4bc":"Questo importo supera il limite d'approvazione del team.",
"f2a42214e7c480c5596439becfd0b7bf":"Questo campo è obbligatorio.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Questa impostazione avrà effetto solo sulle nuove carte",
"881b7596cf985d9c246b05fcab930632":"Questa carta sorgente è stata aggiunta al team.",
"a1b1aa354d06e7815b78c6abb7448e84":"Questa carta sorgente è stata rimossa dal team.",
"b348b24fc4839ee15ed0aa424689bae3":"Questo team deve avere un amministratore o un manager prima di poter invitare un membro.",
"188b9ed447a43916f9e323623ea3a993":"Per accettare l’invito",
"969de95e19a06f5ae7cec6449cbb6a27":"Totale",
"30286e57da108ee7dbc0f8fbb62d4fff":"Totale speso ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Tieni traccia delle spese tra team, progetti ed abbonamenti.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Tieni traccia dell'attività delle carte attive del tuo team, metti in pausa le spese e disattiva le carte non più necessarie al team.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Reimposta la password Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Riprova",
"80196bc616d4a44a4997a2ea127f7b83":"Riprova",
"f96bbe81618b5f4a1807989eb4f69e24":"Riprova",
"d498d7d261b7ca56e07218d1a0dbdd49":"Uh oh! Si è verificato un errore. Ti preghiamo di riprovare.",
"ce2e475291003852c71b01959698c496":"Impossibile ottenere i messaggi sulla richiesta {title}, Riprova",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Disarchivia",
"d2e50be5287fd8fb436d0acbeb811864":"Identificatore univoco",
"0e2fbce967f4a209215b6f48f8eddac3":"Riattiva la creazione della carta",
"f2315dec5dd07802b77af3a3a5414a7d":"Fino a 4 anni in futuro.",
"d2f30a2f0c955271e9af123dceca501a":"Fino a {label} in futuro",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Aggiorna carta sorgente",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Aggiorna carta sorgente",
"c8f6bc61a8982158e408194e48a274d9":"Caricamento fallito, riprova.",
"8a86a60cb836bdb5225cad20c5151078":"Carica ricevuta",
"e448a1ce42de0cf526200331b4ad8b23":"Utente",
"75edcc8fc27f41c99e28b48ff250c7f8":"Dettagli utente",
"d1651c2afdb6c92621adbb707e4753d0":"Utente salvato.",
"5fa55883b7413526b441e999cb8089b3":"Nome utente",
"7a892b81fe2aae295f1b2d38bb67360f":"Nome utente",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Utenti con carte attive non possono essere rimosse. Ti preghiamo di cancellare le carte per rimuovere questo utente.",
"d40fa5935f1614925cf46259c886814a":"Vedi attività carta",
"625419857efc7e72d162c9f8204109df":"Visualizza carta nel portafoglio",
"85a5e817826415651ed4991a76bb5385":"Visualizza conversazione",
"09fc124dc9b8b14d6227a92bb0a4566f":"Vedi conversazione",
"d26cfc6d8f420f259f434b79593e9eb4":"Visualizza ricevuta",
"c7d0394c3206a3b74afbeabc41cc639c":"Visualizza ricevuta",
"01650aa52c5e5a22d3f38afd2c7f9411":"Visualizza ricevuta",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Visualizza ricevuta",
"b2d141897f73fc2a198ee1a7a438f89b":"Visualizza richiesta",
"02fa2f56ef72845a9dfeabea22948061":"Vedi dettagli del team",
"2d9c55650b17dd605a1388f46c81ba38":"Carta virtuale",
"d0ec076e4600e854588f8da265208c0b":"Opzioni carta virtuale",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Portafoglio",
"5279a2220e4cfb0d8b8b357311251cbe":"Portafoglio",
"b1746b5806659e3189f837d2dd54e08c":"Portafoglio",
"ff306d5b34ee993f9618c1f3c0209a39":"Al momento non è possibile archiviare la richiesta. Ti preghiamo di riprovare più tardi.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Al momento non è possibile cancellare la richiesta. Ti preghiamo di riprovare più tardi.",
"a813d44f6bb26c5da09671e532abe022":"Al momento non è possibile disarchiviare la richiesta. Ti preghiamo di riprovare più tardi.",
"d4c7310eafc0d0da89f20596a22a3abe":"Al momento non siamo stati in grado di creare una richiesta. Ti preghiamo di riprovare!",
"dca679c10accb1db481a57a0e1ba08e2":"Al momento non siamo stati in grado di creare una richiesta. Ti preghiamo di riprovare!",
"db5584a2ef29b04959bce7f93db54c56":"Al momento non è possibile cambiare team. Ti preghiamo di riprovare più tardi.",
"f2af2c261fabddb01766f4f974b85a62":"Settimanale",
"e181ed0723a00ca7558b9d5d5258732b":"Benvenuto su Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Ti contatteremo presto!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Quale carta di credito utilizza la tua azienda?",
"968d94729053e166a76ea577ce3022e2":"E-mail di lavoro",
"588a075e8694ff5cc86815697e1586bc":"Lavora come fanno i tuoi team aziendali.",
"182ae1eb5226e0646b4033883cc0900c":"Anno",
"fe88fe7094c4ced427874b3d22326c8d":"Sì",
"9a2d4e3b22ad0567165eb0f661f952ad":"Sì, cancella carta",
"96b88e530375237cbc08138d9fb4109c":"Sì, rimuovi questo elenco",
"fd3780f468c531f2bc1b47b8748b5a66":"Puoi creare codici contabili nelle impostazioni dell'azienda.",
"ab3ac6f945d9f756763d49257fd924dc":"Puoi gestire le codifiche nelle impostazioni dell'azienda.",
"c919dae93fe780061629c255eb358140":"Non puoi rimuovere l'unica carta sorgente collegata a questo team. Aggiungi un'altra carta per rimuoverla.",
"11de97e873b66d91a77db7a05da32202":"Puoi promuovere uno dei proprietari dei team elencati di seguito come amministratore dell'azienda o invitare un nuovo utente.",
"0ab790f08b2780be001b79d2ccd75c4e":"Non hai alcun codice contabile creato per questo account.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Non hai creato alcun elenco di codifica.",
"44e470218124a50c117870467530752e":"Hai inserito un indirizzo e-mail o una password errati.",
"4b687d0730822fdbeea27c174e47f227":"Non hai approvazioni approvate.",
"020f03c17b79af66f335b6af868c66c0":"Non hai approvazioni annullate.",
"def0b76f03e7c622341a7d8e08aaaae7":"Non hai approvazioni rifiutate.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Non hai approvazioni in sospeso.",
"7c034d6104f80a523b90bc607fc06608":"Non hai carte virtuali nel tuo portafoglio. Crea una richiesta",
"0aed9198d61d161468d541dbbc9f477a":"Hai un inoltro fuori sede a {oooForwardToFullName} programmato fino a {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Hai ricevuto una nuova carta virtuale:",
"04eeaf814767c30aa41577f3b11f916a":"Di recente hai richiesto una reimpostazione della password del tuo account Go. Per completare il processo:",
"09ddf249810b9af7d3e1f4e537da500d":"Hai provato ad accedere a una pagina che non esiste. Assicurati di avere il link corretto.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Sei invitato ad unirti al tuo team a",
"02c6f3a7cf31a87f4f39f992509f8c44":"Sei stato aggiunto come amministratore per la tua azienda",
"cf121a14590f109dafcae138265e353b":"Sei stato aggiunto a un nuovo team!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Sei stato aggiunto ad un team",
"d3cef66a026f776903b342d450e5a832":"La tua richiesta di carta Go è stata rifiutata",
"003f87c21a29220e661fc302b885fd5a":"Il tuo account è stato disattivato, ti preghiamo di contattare l'amministratore dell'azienda o del team.",
"a52b1f0da15dce68007f524e48238143":"La tua banca ha riportato un errore durante l'aggiunta di questa carta. Contatta la tua banca per risolvere l’errore e riprova.",
"4d883b173167297463aeac86957c26fe":"Il tuo indirizzo e-mail è stato utilizzato per creare un account su Tradeshift Go. Per verificare il tuo indirizzo e-mail e completare la registrazione",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Le tue impostazioni fuori sede sono state salvate.",
"542f08554110a12de0c23afd78f38e0e":"La tua password è stata ripristinata. Ti preghiamo d’accedere.",
"49b2dcbe5d461f35c37986962b5bd07a":"La tua richiesta è stata declinata",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Sei sull’elenco!",
"32f87c5ddeaee35b9178785ef4eb9313":"Codice postale",
"c6974a3d5311374c697625f2a3234bce":"una data",
"f86980f5ef00b645a6e4a0b59738a954":"Una persona che non poteva essere cercata",
"7066f854917b39bafe0bbecbb8bd6377":"approva le richieste",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"per",
"49b171230ab9bcdd8b631c70cf397efb":"per <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"per la tua azienda",
"35ad86f0b035d8156390670d60801e7f":"per {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"per {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"da",
"c1407aa5424f339dedf67bcac597cf71":"ha impostato d’inoltrarti le richieste d’approvazione durante",
"67a948b8af3284a401ebefcb1b4723ab":"è in attesa per la tua approvazione di",
"9ca64449d64b98fef7859e22bc23c4dc":"esegue richieste",
"ac850ab84428e9eb6a57e5d813de5e45":"gestisce team",
"2168839a1f4c73ef5c89c9e930148e7d":"Ti preghiamo d’ignorare questa e-mail. Nessuna azione richiesta.",
"85431c325faab98e02daa60151465144":"ti ha inviato questo invito",
"81c504fd54119e7e580eb05dedef46d0":"ti ha inviato questo invito",
"d74545060b38445ef4cc49fbb8d79876":"ti ha inviato questo invito.",
"2d96c53ee7ee0a54aadad19bfce0697d":"si è verificato un errore durante il recupero delle sorgenti della tua carta",
"b2fda295788d579fffd3f43b7737fd63":"Per registrare la tua carta aziendale con Go, to enroll your corporate card with Go, per garantire ai tuoi dipendenti l'accesso semplice e sicuro alla carta",
"d35bd5c5d74af765d45cef3c4e78f52a":"per effettuare acquisti aziendali utilizzando le carte virtuali Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"per effettuare acquisti aziendali utilizzando le carte virtuali Go",
"9546989e681a7e25fbdfe7631e9ebc03":"per effettuare acquisti aziendali utilizzando le carte virtuali Go.",
"6174e0a3d26409dd6e410052e2805660":"Per iniziare ad usare le carte Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"Spegni fuori ufficio",
"fd2c1099fc9f3831d7f24195aaa11269":"tu@azienda.it",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} è responsabile per l'approvazione delle richieste degli altri membri del team. Ti preghiamo d’assegnare un nuovo approvatore ai membri interessati per procedere.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} è stato invitato con successo${this.teamId ? '.' : ' al team.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} è stato rimosso",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} cancellato la richiesta per",
"9567a2d4cacd58fb4f096132d26c1660":"{username} è responsabile per l'approvazione delle richieste degli altri membri del team. Ti preghiamo d’assegnare un nuovo approvatore ai membri interessati per procedere.",
};
