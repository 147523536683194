const _ = require('lodash');

/* @ngInject */
function RoleService(constants, HttpService) {
	const ROLES = constants.roles;
	const service = {};
	service.getRoleId = function (label) {
		const roles = service.getRoles();
		const foundRole = _.find(roles, function (role) {
			return _.toLower(role.label) === _.toLower(label);
		});
		return _.get(foundRole, 'id');
	};
	service.getRoleLabel = function (roleId) {
		const roles = service.getRoles();
		const foundRole = _.find(roles, function (role) {
			return role.id === roleId;
		});
		return _.get(foundRole, 'label', 'Unknown');
	};
	service.getRole = function (key) {
		return ROLES[key];
	};
	service.getRoleV2 = key => constants.roles[key];

	service.getRoles = function () {
		return Object.keys(ROLES).map(function (key) {
			return ROLES[key];
		});
	};

	service.getTSAdminRoles = () => {
		const filteredRoles = _.omit(ROLES, ['finance']);
		return Object.values(filteredRoles);
	};

	// omits the finance and support roles when inviting or editing user roles for GoV1.
	service.getFilteredRoles = () => {
		const filteredRoles = _.omit(ROLES, ['finance', 'support']);
		return Object.values(filteredRoles);
	};

	service.getRolesV2 = () => constants.roles;

	service.getTeamRolesV2 = () => {
		return _.omit(constants.roles, [
			'companyAdmin',
			'tradeshiftSupportAdmin',
			'getRoleById',
			'getRoleOptionsObject',
			'admin',
			'basic',
			'finance',
			'member',
			'support',
		]);
	};

	service.getRoleLabelV2 = roleId => _.find(constants.roles, { id: roleId }).label;

	service.getRoleOptionsObject = roleId => constants.roles.getRoleOptionsObject(roleId);

	service.isValidRole = function (value) {
		const roles = service.getRoles();
		return _.some(roles, function (role) {
			return _.toLower(role.label) === _.toLower(value);
		});
	};

	service.addRoleToUser = (userId, roleId, securityGroupId) =>
		HttpService.tradeshiftGo().path(`/rest/users/${userId}/roles/${roleId}/securityGroup/${securityGroupId}`).post();

	service.updateUserRole = (userId, roleId, securityGroupId) =>
		HttpService.tradeshiftGo().path(`/rest/users/${userId}/roles/${roleId}/securityGroup/${securityGroupId}`).put();

	service.removeRoleFromUser = (userId, roleId, securityGroupId) =>
		HttpService.tradeshiftGo().path(`/rest/users/${userId}/roles/${roleId}/securityGroup/${securityGroupId}`).delete();

	return service;
}

module.exports = RoleService;
