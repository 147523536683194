"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypographyComment = exports.TypographyInfo = exports.TypographyNotice = exports.StyledListItem = exports.ItemWrapper = void 0;
const material_1 = require("@mui/material");
exports.ItemWrapper = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    backgroundColor: theme.palette['tsGoColor'].white,
    borderRadius: '3px',
    border: `1px solid ${theme.palette['tsGoColor'].grayLight()}`,
    display: 'flex',
    padding: `${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnitQuarter} ${theme.tsGoUnit.tsUnitHalf}`,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: theme.tsGoUnit.tsUnitQuarter,
}));
exports.StyledListItem = (0, material_1.styled)(material_1.ListItem)(({ theme }) => ({
    color: theme.palette.tsGoColor.blue,
    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.medium),
    '&:not(:first-of-type)': {
        listStyleType: 'disc',
    },
}));
const TypographyNotice = (theme) => ({
    fontSize: theme.typography.tsFontSize.mini,
    display: 'flex',
    gap: theme.tsGoUnit.tsUnitQuarter,
    paddingBottom: theme.tsGoUnit.tsUnitHalf,
    alignItems: 'center',
});
exports.TypographyNotice = TypographyNotice;
exports.TypographyInfo = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    ...(0, exports.TypographyNotice)(theme),
    color: theme.palette.tsGoColor.yellowDarker,
}));
exports.TypographyComment = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    ...(0, exports.TypographyNotice)(theme),
    color: theme.palette.tsGoColor.grayDark,
}));
