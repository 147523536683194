const TSUserImageDirective = () => ({
	restrict: 'E',
	scope: {
		size: '<',
		user: '<',
	},
	template: '<img data-ts="UserImage" />',
	link($scope, $element) {
		const getSquareAvatar = (src, size) => {
			if (!src || !size) {
				return;
			}
			const filename = src.split('/').pop();
			return src.replace(/upload\/.*/, `upload/w_${size},h_${size},c_fill/${filename}`);
		};

		$scope.$watch('user', function (newValue, oldValue) {
			if (newValue !== oldValue) {
				$element.children().attr({
					alt: $scope.user.fullName,
					height: $scope.size,
					src: getSquareAvatar($scope.user.avatarUrl, $scope.size),
					width: $scope.size,
				});
			}
		});
	},
});

export default TSUserImageDirective;
