const _ = require('lodash');

/* @ngInject */
function ReportController($scope, _manageHeader, _report, FooterService) {
	const COLUMNS = [
		{
			flex: 2,
			key: 'requestedByName',
			label: 'Requester',
		},
		{
			flex: 2.25,
			key: 'requestedAmount',
			label: 'Requested amount',
			type: 'ts-number',
		},
		{
			flex: 1.25,
			key: 'currency',
			label: 'Currency',
		},
		{
			flex: 1.5,
			key: 'requestedOn',
			label: 'Requested on',
		},
		{
			flex: 3.5,
			key: 'approvedByEmail',
			label: 'Manager',
		},
		{
			flex: 1.5,
			key: 'approvedOn',
			label: 'Approved on',
		},
		{
			flex: 1.5,
			key: 'state',
			label: 'Status',
		},
	];
	const FOOTER_BUTTONS = [
		{
			label: 'Download report CSV',
			onclick: () => {
				$scope.$evalAsync(() => {
					$scope.asides.downloadReport.isOpen = true;
				});
			},
			type: 'ts-primary',
		},
	];

	$scope.onAsideClosed = key => {
		$scope.asides[key].isOpen = false;
	};

	(function init() {
		$scope.asides = {
			downloadReport: {
				isOpen: false,
			},
		};

		const keys = _.map(COLUMNS, 'key');
		$scope.table = {
			columns: _.map(COLUMNS, ({ flex, label, type }) => ({ flex, label, type })),
			rows: _.map(_report.lines, line => _.map(keys, key => line[key] || '')),
			status: `Total: ${_report.totals.count} requests for ${_report.totals.amount}`,
		};
		_manageHeader.selectTab({ selectedTab: 'report' });
		FooterService.create({ $scope, buttons: FOOTER_BUTTONS });
	})();
}

ReportController.resolve = {
	_report: /* @ngInject */ ReportService => ReportService.getReport(),
};

module.exports = ReportController;
