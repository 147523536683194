const _ = require('lodash');

/* @ngInject */
function RequestChangedController($scope, ConfigService) {
	const propertyLabels = _.chain(ConfigService.get('CODING_LISTS'))
		.assign(ConfigService.get('CHANGED_PROPERTIES'))
		.mapValues(function (key) {
			return key.name || key.label;
		})
		.value();

	function formatPrice(price) {
		if (!price) {
			return;
		}
		// some change events have already been stored with the currency
		if (_.includes(price, '$')) {
			return price;
		}
		return ['$', price].join('');
	}

	(function init() {
		const data = $scope.event.data.body.data;
		const property = _.toLower(data.property);
		$scope.propertyLabel = propertyLabels[property] ? propertyLabels[property] : data.property;
		if (property === 'amount') {
			$scope.oldValue = formatPrice(data.oldValue);
			$scope.newValue = formatPrice(data.newValue);
		} else {
			$scope.oldValue = data.oldValue;
			$scope.newValue = data.newValue;
		}
	})();
}

module.exports = RequestChangedController;
