const _ = require('lodash');

const PaymentsHelper = /* @ngInject */ (constants, LexicoService) => {
	const helper = {};

	const lexico = LexicoService.getLexico();

	helper.formatBillingAddress = ({ addressLine1, city, region, zipCode } = constants.emptyBillingAddress) => {
		if (_.isEmpty(addressLine1)) {
			return '';
		}
		return `${addressLine1}\n${city}, ${region} ${zipCode}`;
	};

	helper.getCardSourceTableColumns = () => [
		{
			label: lexico.trc('Table column title', 'Card name'),
			flex: 3,
		},
		{
			label: lexico.trc('Table column title', 'Provider'),
			flex: 3,
		},
		{
			label: lexico.trc('Table column title', 'Last four digits'),
			flex: 3,
		},
		{
			label: lexico.trc('Table column title', 'Currency'),
		},
		{
			label: lexico.trc('Table column title', 'Status'),
			flex: 2,
		},
		{
			label: lexico.trc('Table column title', 'Billing address'),
			flex: 5,
		},
		{
			label: lexico.trc('Table column title', 'Teams'),
			minwidth: 75,
		},
		{
			label: lexico.trc('Table column title', 'Details'),
			width: 75,
		},
	];

	return helper;
};

module.exports = PaymentsHelper;
