const SubHeaderComponent = {
	transclude: true,
	template: `
		<div ng-transclude></div>
	`,
	controller: /* @ngInject */ function ($element) {
		let subHeaderElement;
		this.$onInit = () => {
			const headerElement = document.querySelectorAll('.ts-app .ts-headerbar')[0];
			subHeaderElement = $element[0];
			if (headerElement !== undefined) {
				headerElement.insertAdjacentElement('afterend', subHeaderElement);
			}
		};
		this.$onDestroy = () => {
			subHeaderElement.remove();
		};
	},
};

export default SubHeaderComponent;
