"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHeaderState = void 0;
const zustandStore_1 = require("../../../../../zustandStore");
const useHeaderState = () => {
    const header = (0, zustandStore_1.useZustand)(state => state.header);
    const { headerName, showCloseButtonInTeamOverviewPageHeader, headerButtons } = header;
    const { setTeam, setTeamId, openSideMenuOnMobileView, setTeamName, setTeamRequestLimit } = (0, zustandStore_1.useZustand)();
    return {
        headerName,
        headerButtons,
        showCloseButtonInTeamOverviewPageHeader,
        setTeam,
        setTeamId,
        openSideMenuOnMobileView,
        setTeamName,
        setTeamRequestLimit,
    };
};
exports.useHeaderState = useHeaderState;
