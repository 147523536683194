export default /* @ngInject */ (constants, EventEmitter, PaymentsService, TeamsService, CompanyService) => {
	const helper = {};

	helper.addCardSourceToTeam = async ({ cardSourceId, teamId }) => {
		try {
			await PaymentsService.addCardSourceToTeam(teamId, cardSourceId);
		} catch (error) {
			ts.ui.Notification.warning('An error occurred while adding the source card to the team');
		}
	};

	helper.createTeam = async ({ onTeamAdded, teamName }) => {
		try {
			const { teamId } = await TeamsService.createTeam(teamName);
			await onTeamAdded(new EventEmitter({ teamId }));
			return teamId;
		} catch (error) {
			if (error.status === 409) {
				ts.ui.Notification.warning('This team name already exists');
			} else {
				ts.ui.Notification.error('There was an error creating the team');
				throw error;
			}
		}
	};

	helper.updateSaveAndContinueButton = ({ disabled, onChangeButtons, saveAndContinue }) => {
		onChangeButtons(
			new EventEmitter([
				{
					disabled,
					label: 'Save and continue',
					type: 'ts-primary tst-save-and-continue',
					onclick: () => {
						try {
							saveAndContinue();
						} catch (error) {
							ts.ui.Notification.error('An error occured while trying to save the team');
						}
					},
				},
			]),
		);
	};

	helper.fetchLimit = async () => {
		try {
			let limit = await CompanyService.getRequestLimit();
			if (!limit) {
				limit = constants.maxCompanyApprovalLimit;
			}
			return limit;
		} catch (error) {
			return constants.maxCompanyApprovalLimit;
		}
	};

	return helper;
};
