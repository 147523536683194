const TSFormLabelComponent = {
	bindings: {
		label: '@',
	},
	template: `
		<fieldset>
			<span ng-bind="$ctrl.label"></span>
		</fieldset>
	`,
	controller: class TSFormLabelController {},
};

export default TSFormLabelComponent;
