"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.codingEntryInitialState = void 0;
exports.codingEntrySlice = codingEntrySlice;
exports.codingEntryInitialState = {
    codingEntry: {
        currentSelectedCodingEntry: { ID: '', path: '' },
        currentCodingEntryTeams: [],
        newCodingEntry: { ID: '', path: '' },
        newCodingEntryTeams: [],
    },
};
function codingEntrySlice(set) {
    return {
        ...exports.codingEntryInitialState,
        setCurrentSelectedCodingEntry(currentSelectedCodingEntry) {
            set((state) => {
                state.codingEntry.currentSelectedCodingEntry.ID = currentSelectedCodingEntry.ID || '';
                state.codingEntry.currentSelectedCodingEntry.path = currentSelectedCodingEntry.path || '';
                return state;
            }, false, '[setCurrentSelectedCodingEntry]');
        },
        setCurrentSelectedCodingEntryTeams(currentSelectedCodingEntryTeams) {
            set((state) => {
                state.codingEntry.currentCodingEntryTeams = currentSelectedCodingEntryTeams;
                return state;
            }, false, '[setCurrentSelectedCodingEntryTeams]');
        },
        updateSelectedCodingEntryTeamByTeamId(teamToUpdate) {
            set((state) => {
                const teamIndex = state.codingEntry.currentCodingEntryTeams.findIndex(team => team.teamId === teamToUpdate.teamId);
                if (teamIndex !== -1) {
                    state.codingEntry.currentCodingEntryTeams[teamIndex] = teamToUpdate;
                }
                return state;
            }, false, '[updateSelectedCodingEntryTeamByTeamId]');
        },
        setNewCodingEntry(newCodingEntry) {
            set((state) => {
                state.codingEntry.newCodingEntry.ID = newCodingEntry.ID || '';
                state.codingEntry.newCodingEntry.path = newCodingEntry.path || '';
                return state;
            }, false, '[setNewCodingEntry]');
        },
        setNewCodingEntryTeams(newCodingEntryTeams) {
            set((state) => {
                state.codingEntry.newCodingEntryTeams = newCodingEntryTeams;
                return state;
            }, false, '[setNewCodingEntryTeams]');
        },
    };
}
