module.exports = {
	user: /* @ngInject */ UserService => UserService.getUser(),
	userRole: /* @ngInject */ (user, RoleServiceV2) => RoleServiceV2.getCurrentRoleForUser(user),
	teamId: /* @ngInject */ $stateParams => $stateParams.teamId,
	isCompanyAdmin: /* @ngInject */ UserServiceV2 =>
		(async () => {
			const user = await UserServiceV2.getUser();
			const isCompanyAdmin = await UserServiceV2.isCompanyAdmin(user);
			return isCompanyAdmin;
		})(),
	shouldReturnToCompanyDashboard: /* @ngInject */ UserServiceV2 => {
		// since there's no company_dashboard and no entry for dashboard against companyId in the role_restrictions
		// table at the moment, we're checking against 'company_settings' for now, which also works
		return UserServiceV2.verifyAccess({
			objectId: 'company_settings',
			operationId: 'view',
		});
	},
	showDownloadReportButton: /* @ngInject */ (user, RoleServiceV2) =>
		// Need this IIFE because babel-plugin-angularjs-annotate does not like async/await
		(async () => {
			const { id: roleId } = await RoleServiceV2.getCurrentRoleForUser(user);
			return roleId === 'company_admin' || roleId === 'team_admin';
		})(),
	team: /* @ngInject */ ($stateParams, TeamsService) => TeamsService.setCurrentTeamById($stateParams.teamId),
};
