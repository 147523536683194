const _ = require('lodash');

const CodingListsModalViewComponent = {
	bindings: {
		isCompanyAdmin: '<',
		isCurrentStep: '<',
		isFtux: '<',
		onChangeButtons: '&',
		onPreviousStep: '&',
		onSave: '&',
		teamId: '<',
	},
	template: `
			<div
					class="coding-list-error tst-coding-list-empty"
					ng-if="$ctrl.isFtux">
				<p ng-bind="$ctrl.noCodingListsText1"></p>
				<p ng-bind="$ctrl.noCodingListsText2"></p>
			</div>
			<div
					class="modal-panel-content content"
					ng-if="!$ctrl.isFtux">
				<div>
					<coding-lists
							added-team-id="$ctrl.teamId"
							is-company-admin="$ctrl.isCompanyAdmin"
							ng-if="$ctrl.teamId"></coding-lists>
				</div>
			</div>`,
	controller: class CodingListsModalViewController {
		/* @ngInject */
		constructor(CodingHelper, EventEmitter, LexicoService) {
			this.CodingHelper = CodingHelper;
			this.EventEmitter = EventEmitter;
			this.lexico = LexicoService.getLexico();
			this.noCodingListsText1 = this.lexico.trc('Empty list message', 'You don’t have any coding lists created.');
			this.noCodingListsText2 = this.lexico.trc('Empty list message', 'You can manage codings in company settings.');
		}

		$onInit() {
			this.hasEntries();
		}

		async hasEntries() {
			this.loadedLists = await this.CodingHelper.loadLists();
			this.isFtux = this.loadedLists.every(list => _.isEmpty(list.entries));
		}

		async $onChanges(changes) {
			if (changes.isCurrentStep && changes.isCurrentStep.currentValue) {
				this.onStepSelected();
			}
		}

		onStepSelected() {
			this.updateModalButtons();
		}

		updateModalButtons() {
			const buttons = [];
			buttons.push({
				label: this.lexico.trc('Button', 'Back'),
				type: 'ts-secondary',
				onclick: () => {
					this.onPreviousStep();
				},
			});
			buttons.push({
				label: this.lexico.trc('Button', 'Save'),
				type: 'ts-primary tst-save',
				onclick: () => {
					this.onSave();
				},
			});
			this.onChangeButtons(this.EventEmitter(buttons));
		}
	},
};

export default CodingListsModalViewComponent;
