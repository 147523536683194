const moment = require('moment');

function approvalInfo() {
	return {
		link: function ($scope) {
			$scope.getForwardedByYouMessage = approval => {
				const completedOn = formatDate(approval.getCompletedOn());
				if (approval.isAutoForwarded()) {
					return `This request was automatically forwarded to ${approval.getReassignedToFullName()} on ${completedOn}.`;
				} else {
					return `You forwarded this request to ${approval.getReassignedToFullName()} on ${completedOn}.`;
				}
			};

			$scope.getForwardedToYouMessage = approval => {
				const assignedOn = formatDate(approval.getAssignedOn());
				if (approval.isReassignedToSelf()) {
					return `This request was re-assigned to you on ${assignedOn}.`;
				}
				if (approval.getOutcome() === 'SELF_REASSIGNED') {
					return 'This request has been reassigned.';
				} else {
					return `${approval.getReassignedByFullName()}  forwarded this request to you on ${assignedOn}.`;
				}
			};

			const formatDate = date => moment(date).format('MMM D, YYYY');
		},
		restrict: 'E',
		scope: {
			approval: '=',
		},
		template: require('./approvalInfo.html'),
	};
}

module.exports = approvalInfo;
