"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.codingListInitialState = void 0;
exports.codingListSlice = codingListSlice;
exports.codingListInitialState = {
    codingList: {
        currentCodingListSelected: { name: '', id: '', code: '', enabled: false },
        selectedCodingEntries: { code: '', values: [] },
    },
};
function codingListSlice(set) {
    return {
        ...exports.codingListInitialState,
        setCurrentCodingListSelected(currentCodingListSelected) {
            set((state) => {
                state.codingList.currentCodingListSelected.id =
                    currentCodingListSelected.id ?? state.codingList.currentCodingListSelected.id;
                state.codingList.currentCodingListSelected.name =
                    currentCodingListSelected.name ?? state.codingList.currentCodingListSelected.name;
                state.codingList.currentCodingListSelected.code =
                    currentCodingListSelected.code ?? state.codingList.currentCodingListSelected.code;
                state.codingList.currentCodingListSelected.enabled =
                    currentCodingListSelected.enabled ?? state.codingList.currentCodingListSelected.enabled;
                return state;
            }, false, '[setCurrentCodingListSelected]');
        },
        resetCurrentCodingListSelected() {
            set((state) => {
                state.codingList.currentCodingListSelected = { name: '', id: '', code: '', enabled: false };
                return state;
            }, false, '[resetCurrentCodingListSelected]');
        },
        setSelectedCodingEntries(selectedCodingEntries) {
            set((state) => {
                state.codingList.selectedCodingEntries = selectedCodingEntries;
                return state;
            }, false, '[setSelectedCodingEntries]');
        },
        setCurrentCodingListSelectedName(name) {
            set((state) => {
                state.codingList.currentCodingListSelected.name = name || state.codingList.currentCodingListSelected.name;
                return state;
            }, false, '[setSelectedCodingEntries]');
        },
    };
}
