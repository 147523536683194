"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiFormControlOverrides = void 0;
exports.muiFormControlOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                width: '100%',
                padding: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 0 : `0 ${theme.tsGoUnit.tsUnitHalf}`,
            };
        },
    },
};
