const _ = require('lodash');

/* @ngInject */
function InviteUserController(
	$q,
	$scope,
	AsideService,
	CodingService,
	constants,
	DomainWhitelistingService,
	FeatureService,
	NameService,
	PaymentsService,
	RoleService,
	UserService,
) {
	const that = this;
	const SUPPORT_ROLE_ID = ($scope.SUPPORT_ROLE_ID = RoleService.getRole('support').id);
	const allRoles = RoleService.getFilteredRoles();
	const go2SupportAdmin = RoleService.getRoleV2('tradeshiftSupportAdmin');
	const TSAdminRoles = RoleService.getTSAdminRoles();
	let validateDomainRegex = '';

	function init() {
		that.onSuccess = $scope.aside.onSuccess;
		that.isLoading = {
			aside: true,
		};
		$scope.isGo2SupportAdmin = false;
		const promises = [
			CodingService.getListEntries({ listId: 'costcentercode' }),
			DomainWhitelistingService.getWhitelistedDomains(),
			FeatureService.getFeatures(),
			PaymentsService.getApprovers(),
			UserService.getUser(),
			UserService.isTSAdmin(),
		];
		$q.all(promises).then(([costCenters, whitelistedDomains, features, managers, user, isTSAdmin]) => {
			const roles = isTSAdmin ? TSAdminRoles : allRoles;
			that.features = features;
			that.options = { costCenters, managers, roles };
			that.form = {
				role: _.find(that.options.roles, { label: 'Member' }),
			};
			that.user = user;
			that.isTSAdmin = isTSAdmin;
			that.isLoading.aside = false;
			that.whitelistedDomains = whitelistedDomains;
		});
	}

	that.cancel = function () {
		AsideService.closeAll();
	};

	that.getValidateDomainRegex = () => {
		if (!validateDomainRegex) {
			that.setValidateDomainRegex();
		}
		return validateDomainRegex;
	};

	that.isDomainWhitelistingEnabled = () => {
		return (
			_.has(that, 'features') &&
			_.has(that, 'whitelistedDomains') &&
			that.features.ENABLE_DOMAIN_WHITELISTING &&
			!_.isEmpty(that.whitelistedDomains)
		);
	};

	that.invite = async invitation => {
		if (that.isGo2SupportAdmin) {
			await that.addGo2SupportAdmin(invitation);
		} else {
			await that.inviteGo1User(invitation);
		}
	};

	that.addGo2SupportAdmin = async invitation => {
		const userData = {
			firstName: invitation.firstName,
			lastName: invitation.lastName,
			roleId: invitation.role.id,
			username: invitation.username,
			companyAccountId: invitation.companyAccountId || that.user.companyAccountId,
		};
		try {
			const invitedUser = await UserService.sendInvitation(userData);
			ts.ui.Notification.success(`Added ${invitedUser.email} as a Support Admin `);
			AsideService.closeAll();
		} catch (e) {
			ts.ui.Notification.warning(`Could not add ${invitation.username} as an admin`);
		}
	};

	that.inviteGo1User = function (invitation) {
		const data = _.omitBy(
			{
				costCenter: invitation.role.id === SUPPORT_ROLE_ID ? undefined : _.get(invitation, 'costCenter.ID'),
				firstName: invitation.firstName,
				lastName: invitation.lastName,
				manager: invitation.role.id === SUPPORT_ROLE_ID ? undefined : _.get(invitation, 'manager.email'),
				role: invitation.role.id,
				username: invitation.username,
				companyAccountId: invitation.companyAccountId || that.user.companyAccountId,
			},
			_.isUndefined,
		);
		return UserService.sendInvitation(data)
			.then(function (result) {
				const user = {
					companyAccountId: result.CompanyAccountId,
					email: result.Person.Email,
					firstName: result.Person.FirstName,
					fullName: NameService.fullName(invitation),
					lastName: result.Person.LastName,
					properties: {
						manager: data.manager,
					},
					roleId: data.role,
					state: 'CREATED',
					userId: result.Id,
				};
				if (invitation.costCenter) {
					user.costCenter = invitation.costCenter;
					user.properties.costcenter = invitation.costCenter.ID;
				}
				ts.ui.Notification.success('Invited ' + user.email);
				that.onSuccess(user);
				AsideService.closeAll();
			})
			.catch(function () {
				ts.ui.Notification.warning('Could not invite ' + invitation.username);
			});
	};

	that.setValidateDomainRegex = function () {
		if (!that.isDomainWhitelistingEnabled()) {
			return;
		}

		const domains = _.map(that.whitelistedDomains, domain => {
			return domain.replace('.', '\\.');
		}).join('|');
		const regexPattern = `@(${domains})$`;
		validateDomainRegex = new RegExp(regexPattern, 'i');
	};

	$scope.$watch('aside.show', function (show) {
		if (show) {
			init();
		}
	});

	that.toggleGo2SupportAdmin = () => {
		if (this.isGo2SupportAdmin) {
			that.form.role = go2SupportAdmin;
			$scope.SUPPORT_ROLE_ID = go2SupportAdmin.id;
		} else {
			$scope.SUPPORT_ROLE_ID = RoleService.getRole('support').id;
		}
	};
}

module.exports = InviteUserController;
