import c3 from 'c3';
import moment from 'moment';

const BarChartComponent = {
	bindings: {
		currency: '@',
	},
	template: `
		<div data-ts="Board" id="chart-board">
			<div class="bar-chart-panel" data-ts="Panel">
				<div id="chart"></div>
			</div>
		</div>
		`,
	controller: /* @ngInject */ function (
		$filter,
		$stateParams,
		$window,
		i18nService,
		LexicoService,
		PaymentsService,
		UserService,
		RoleServiceV2,
	) {
		this.lexico = LexicoService.getLexico();
		this.isMobileScreen = ts.ui.isMobilePoint();

		this.generateData = async type => {
			const dailytickCount = this.isMobileScreen ? 7 : 15;
			const weeklyTickCount = this.isMobileScreen ? 4 : 7;
			const monthlyTickCount = this.isMobileScreen ? 6 : 12;

			this.chartMetaData = {
				daily: {
					units: 'days',
					tickCount: dailytickCount,
				},
				weekly: {
					units: 'weeks',
					tickCount: weeklyTickCount,
				},
				monthly: {
					units: 'months',
					tickCount: monthlyTickCount,
				},
			};
			const timeLengthArray = Array.from(Array(this.chartMetaData[type].tickCount));

			const getDateRange = () => {
				return {
					to: moment().toISOString(),
					from: moment().subtract(timeLengthArray.length, this.chartMetaData[type].units).startOf('day').toISOString(),
				};
			};

			const settlements = await this.getSettlements(getDateRange());

			const getStartDate = index => {
				if (type === 'monthly') {
					return moment()
						.startOf('month')
						.subtract(timeLengthArray.length - index - 1, this.chartMetaData[type].units);
				}
				if (type === 'weekly') {
					return moment()
						.startOf('day')
						.weekday(1)
						.subtract(timeLengthArray.length - index - 1, this.chartMetaData[type].units);
				}
				if (type === 'daily') {
					return moment()
						.startOf('day')
						.subtract(timeLengthArray.length - index - 1, this.chartMetaData[type].units);
				}
			};

			const getTotals = index => {
				const startDate = getStartDate(index);
				const oneAhead = moment(startDate).add(1, this.chartMetaData[type].units);
				const filteredSettlements = settlements.filter(settlement =>
					moment(settlement.date).isBetween(startDate, oneAhead, null, '[)'),
				);
				return {
					totalCount: filteredSettlements.length,
					totalSpent: filteredSettlements.reduce((output, settlement) => {
						output = Number(output) + Number(settlement.amount);
						return output;
					}, 0),
				};
			};

			const aggregatedData = timeLengthArray.map((timeSlice, index) => {
				const { totalCount, totalSpent } = getTotals(index);
				return {
					currency: this.currency,
					totalSpent,
					totalCount,
					startDate: getStartDate(index),
				};
			});

			return Object.assign(
				{},
				{
					aggregates: aggregatedData,
				},
			);
		};

		this.getSettlements = async ({ to, from }) => {
			try {
				const user = await UserService.getUser();
				const { id } = await RoleServiceV2.getCurrentRoleForUser(user);

				if (id === 'manager') {
					return PaymentsService.getSettlementsForTeamManager({ to, from, teamId: $stateParams.teamId });
				}
				if (id === 'team_admin') {
					return PaymentsService.getSettlementsForTeam({ to, from, teamId: $stateParams.teamId });
				}
				// If the current user is company admin or support admin and he is viewing a team's dashboard
				if ((id === 'company_admin' || id === 'tradeshift_support_admin') && $stateParams.teamId) {
					return PaymentsService.getSettlementsForTeam({ to, from, teamId: $stateParams.teamId });
				}
				// we put this last just in case some day company admins can also have roles in other teams.
				// If that is the case, we'll let their other roles supercede the company admin role
				if (id === 'company_admin' || id === 'tradeshift_support_admin') {
					return PaymentsService.getSettlementsForCompany({ to, from });
				}
				return [];
			} catch (error) {
				return [];
			}
		};

		this.formatTicks = (type, index, chartData) => {
			const date = chartData.aggregates[index].startDate;
			if (type === 'monthly') {
				if (moment(date).month() === 11 && moment(date).isBefore(moment().startOf('year'))) {
					return moment(date).format("MMM'YY");
				}
				return moment(date).format('MMM');
			}

			if (type === 'weekly') {
				return `${moment(date).weekday(1).format('M/D')} - ${moment(date).weekday(7).format('M/D')}`;
			}

			if (type === 'daily') {
				return moment(date).format('M/D');
			}
		};

		this.renderData = async type => {
			const chartData = await this.generateData(type);
			const currencySymbol = i18nService.getCurrencySymbol(this.currency);
			c3.generate({
				axis: {
					x: {
						tick: {
							culling: false,
							format: index => this.formatTicks(type, index, chartData),
							outer: false,
						},
					},
					y: {
						tick: {
							format: value => i18nService.formatNumberShortDecimal(value),
							outer: false,
						},
					},
					y2: {
						show: true,
						tick: {
							format: value => (Number(value) % 1 === 0 ? value : ''),
							outer: false,
						},
					},
				},
				data: {
					axes: {
						totalSpent: 'y',
						totalCount: 'y2',
					},
					colors: {
						totalSpent: '#CBD7DC',
						totalCount: '#A70262',
					},
					keys: {
						value: ['totalSpent', 'totalCount'],
					},
					json: chartData.aggregates,
					names: {
						totalSpent: `${this.lexico.tr('Total spent')} (${currencySymbol})`,
						totalCount: this.lexico.trc('Bar chart title', '# of transactions'),
					},
					types: {
						totalSpent: 'bar',
						totalCount: 'line',
					},
				},
				padding: {
					top: 0,
					left: 45,
					right: 58,
					bottom: 15,
				},
				legend: {
					item: {
						tile: {
							width: 15,
							height: 15,
						},
					},
				},
				tooltip: {
					contents: ([spent, transactions]) => {
						const template =
							`<div class="go-c3-tooltip flex-column" id="tooltip">
							<div class="flex-row">
								<span class="text">` +
							this.lexico.trc('Bar chart tooltip', 'Spent') +
							`: ${$filter('currency')(spent.value, this.currency)}</span>
							</div>
							<div class="flex-row">
								<span class="text">` +
							this.lexico.trc('Bar chart tooltip', 'Transactions') +
							`: ${transactions.value}</span>
							</div>
						</div>`;
						return template;
					},
				},
				transition: {
					duration: 750,
				},
				onresize: () => {
					if (this.getCurrentScreenSize() < 650 && !this.isMobileScreen) {
						this.isMobileScreen = true;
						this.renderData(type);
					} else if (this.getCurrentScreenSize() >= 650 && this.isMobileScreen) {
						this.isMobileScreen = false;
						this.renderData(type);
					}
				},
			});
		};

		this.getCurrentScreenSize = () => $window.innerWidth;

		this.$onInit = () => {
			ts.ui.get('#chart-board', board => {
				board
					.tabs()
					.push(
						{ label: this.lexico.trc('Bar chart tab title', 'Monthly'), onselect: () => this.renderData('monthly') },
						{ label: this.lexico.trc('Bar chart tab title', 'Weekly'), onselect: () => this.renderData('weekly') },
						{ label: this.lexico.trc('Bar chart tab title', 'Daily'), onselect: () => this.renderData('daily') },
					);
			});
		};
	},
};

export default BarChartComponent;
