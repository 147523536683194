import { camelCase, debounce, escape, find, get, isEmpty, isNil, isNumber, set } from 'lodash';

const PeopleTableComponent = {
	bindings: {
		limit: '<',
		onInviteUserAsideClosed: '&',
		openInviteUserAside: '<',
		page: '<?',
		teamId: '<',
		totalPages: '<?',
		userInvited: '&',
		userRemoved: '&',
		users: '=',
		renderButton: '<',
	},
	template: `
		<ts-table
				class="people-table"
				columns="$ctrl.columns"
				ng-if="$ctrl.users.length > 0 || $ctrl.emptySearchResults"
				custom-search="$ctrl.onSearch($event)"
				max-rows="$ctrl.limit"
				on-cell-click="$ctrl.onCellClick($event)"
				on-page-select="$ctrl.onPageSelect($event)"
				page="$ctrl.page - 1"
				pages="$ctrl.totalPages"
				rows="$ctrl.rows"></ts-table>
		<go-invite-user-aside
				is-open="$ctrl.asides.inviteUser.isOpen"
				on-close="$ctrl.closeInviteUserAside()"
				on-user-invited="$ctrl.onUserInvited($event)"
				team-id="$ctrl.teamId"></go-invite-user-aside>
		<go-add-default-manager-aside
				is-open="$ctrl.asides.addApprover.isOpen"
				on-approver-added="$ctrl.onApproverAdded($event)"
				on-close="$ctrl.closeAside('addApprover')"
				team-id="$ctrl.teamId"></go-add-default-manager-aside>
		<go-select-aside
				is-open="$ctrl.asides.selectRole.isOpen"
				model="$ctrl.asides.selectRole.model"
				on-close="$ctrl.closeAside('selectRole')"
				on-select="$ctrl.onSelectRole($event)"
				options="$ctrl.roles"
				title="$ctrl.selectARoleText"></go-select-aside>
		<go-user-details-aside
				has-company-approval-limits="$ctrl.hasCompanyApprovalLimits"
				has-team-approval-limits="$ctrl.hasTeamApprovalLimits"
				is-open="$ctrl.asides.userDetails.isOpen"
				ng-show="$ctrl.asides.userDetails.isOpen"
				on-close="$ctrl.closeAside('userDetails')"
				on-submit="$ctrl.updateTableFromDetails($event)"
				on-remove-user="$ctrl.onRemoveUser()"
				team-id="$ctrl.teamId"
				user-id="$ctrl.users[$ctrl.selectedRowIndex].userId"></go-user-details-aside>
	`,
	controller: /* @ngInject */ function (
		$timeout,
		EventEmitter,
		FeatureService,
		LexicoService,
		LoadingService,
		RoleService,
		TeamsService,
		constants,
	) {
		const REQUEST_LIMIT_PROPERTY = constants.teamProperties.requestLimit;
		const { userTeamProperties, userStateToStatus } = constants;
		this.emptySearchResults = false;
		this.lexico = LexicoService.getLexico();
		this.selectARoleText = this.lexico.trc('Component title', 'Select a role');

		this.asides = {
			addApprover: {
				isOpen: false,
			},
			inviteUser: {
				isOpen: false,
			},
			selectRole: {
				isOpen: false,
			},
			userDetails: {
				isOpen: false,
			},
		};

		this.closeInviteUserAside = () => {
			this.closeAside('inviteUser');
			this.onInviteUserAsideClosed();
		};

		this.closeAside = key => {
			this.asides[key].isOpen = false;
		};

		this.onCellClick = ({ columnIndex, rowIndex }) => {
			this.selectedRowIndex = rowIndex;

			if (columnIndex === 1 || columnIndex === 2) {
				const user = this.users[rowIndex];
				const roleId = camelCase(user.roles[0].roleId);
				this.asides.selectRole.model = this.roles[roleId];
				this.openAside('selectRole');
			}
			if (columnIndex === 3 || columnIndex === 4) {
				this.openAside('addApprover');
			}
			if (columnIndex === 6) {
				this.openAside('userDetails');
			}
		};

		this.$onChanges = changes => {
			if (changes.openInviteUserAside && changes.openInviteUserAside.currentValue) {
				this.asides.inviteUser.isOpen = true;
			}
			if (changes.users) {
				this.$onInit();
			}
		};

		this.openAside = key => {
			$timeout(() => {
				this.asides[key].isOpen = true;
			});
		};

		this.generateColumns = () => {
			const name = {
				label: this.lexico.trc('Data table column label', 'Name'),
				search: {
					tip: this.lexico.trc('Data table column tooltip', 'Search by name'),
					customSearch: true,
				},
			};

			const role = {
				label: this.lexico.trc('Data table column label', 'Role'),
				type: 'fake-select',
			};

			const manager = {
				label: this.lexico.trc('Data table column label', 'Default Approver'),
				type: 'fake-select',
				flex: 2,
			};

			const status = {
				label: this.lexico.trc('Data table column label', 'Status'),
			};

			const actions = {
				label: this.lexico.trc('Data table column label', 'Actions'),
				width: 75,
			};

			const selectIcon = {
				label: '',
				type: 'collapse',
				width: 26,
			};

			return [name, role, selectIcon, manager, selectIcon, status, actions];
		};

		this.onSearch = async ({ value }) => {
			this.value = value;
			const limit = constants.teams.pageLimit;

			const processSearch = debounce(async () => {
				const { users, pageId, numPages, itemCount } = await TeamsService.getTeamUsers({
					limit,
					teamId: this.teamId,
					searchParam: value || null,
				});

				if (isEmpty(users)) {
					$timeout(() => {
						this.emptySearchResults = true;
						this.users = users;
						this.updateTable();
					});
				} else {
					$timeout(() => {
						this.emptySearchResults = false;
						this.users = users;
						this.page = pageId;
						this.limit = itemCount;
						this.totalPages = numPages;
						this.updateTable();
					});
				}
			}, 300);

			processSearch();
		};

		this.getRoleLabel = user => {
			const role = find(user.roles, { securityGroupId: this.teamId });
			return role ? RoleService.getRoleLabelV2(role.roleId) : '';
		};

		this.generateRows = (users = []) =>
			users.map(user => [
				`${escape(user.fullName)} \n *${user.email}*`,
				this.getRoleLabel(user),
				{
					item: 'Icon',
					type: 'ts-icon-select',
				},
				get(user, `teams[${this.teamId}].approver`) ? escape(user.teams[this.teamId].approver.fullName) : ' -- ',
				{
					item: 'Icon',
					type: 'ts-icon-select',
				},
				{
					type: 'ts-orange',
					text: userStateToStatus[user.state],
				},
				{
					item: 'Icon',
					type: 'ts-icon-other',
				},
			]);

		this.onUserInvited = () => {
			this.userInvited();
			this.renderButton('add');
			this.closeAside('inviteUser');
			this.onPageSelect({ index: 'last' });
		};

		this.onApproverAdded = async ({ approver }) => {
			const user = this.users[this.selectedRowIndex];
			await TeamsService.setUserTeamProperty(
				user.userId,
				this.teamId,
				userTeamProperties.all.defaultApprover,
				approver.userId,
			);
			set(user, `teams[${this.teamId}].approver`, approver);
			this.updateTable();
			this.closeAside('addApprover');
		};

		this.onPageSelect = async ({ index }) => {
			LoadingService.startLoading();
			try {
				if (isNumber(index)) {
					index++;
				}

				const paginatedUserData = await TeamsService.getTeamUsers({
					limit: this.limit,
					page: index,
					teamId: this.teamId,
					searchParam: this.value || null,
				});

				$timeout(() => {
					this.page = paginatedUserData.pageId;
					this.users = paginatedUserData.users;
					this.totalPages = paginatedUserData.numPages;
					this.updateTable();
				});
			} catch (e) {
				const warningMessage = this.lexico.trc(
					'Warning message',
					'There was a problem loading this teams users. Please try again.',
				);
				ts.ui.Notification.warning(warningMessage);
			} finally {
				LoadingService.stopLoading();
			}
		};

		this.onSelectRole = async ({ option }) => {
			const user = this.users[this.selectedRowIndex];
			const decoratedUser = await TeamsService.getDecoratedUser({
				teamId: this.teamId,
				userId: user.userId,
			});
			const currentTeamRole = user.roles.find(role => role.securityGroupId === this.teamId).roleId;
			if (
				(currentTeamRole === 'team_admin' || currentTeamRole === 'manager') &&
				get(decoratedUser, 'requesters.length') > 0 &&
				option.id === 'team_member'
			) {
				const warningMessage = `${escape(user.fullName)} ${this.lexico.tr(
					'is responsible for approving requests from other team members. Please assign a new approver to the impacted members to proceed.',
				)}`;
				ts.ui.Notification.warning(warningMessage);
			} else if (option.id !== user.roles[0].roleId) {
				await RoleService.updateUserRole(user.userId, option.id, this.teamId);
				user.roles = user.roles.map(role =>
					role.securityGroupId === this.teamId ? Object.assign({}, role, { roleId: option.id }) : role,
				);
				this.updateTable();
			}
			this.closeAside('selectRole');
		};

		this.onRemoveUser = async () => {
			const { userId } = this.users[this.selectedRowIndex];
			this.closeAside('userDetails');
			await TeamsService.removeUserFromTeam(this.teamId, userId);
			const removedUser = this.users.find(user => user.userId === userId);
			this.users = this.users.filter(user => user.userId !== userId);
			this.renderButton('remove');
			if (this.users.length === 0) {
				this.userRemoved(EventEmitter({ isEmpty: true }));
			} else {
				this.updateTable();
				this.userRemoved(EventEmitter({ isEmpty: false }));
			}
			const successMessage = `${removedUser.firstName} ${this.lexico.tr('was removed')}`;
			ts.ui.Notification.success(successMessage);
		};

		this.updateTable = () => {
			this.rows = this.generateRows(this.users);
		};

		this.updateUserLimit = async limit => {
			const user = this.users[this.selectedRowIndex];
			await TeamsService.setUserTeamProperty(user.userId, this.teamId, REQUEST_LIMIT_PROPERTY, limit);
			set(user, `teams[${this.teamId}].approvalLimit`, limit);
		};

		this.updateTableFromDetails = ({ role, approver, requestLimit }) => {
			this.onSelectRole({ option: role });
			if (approver) {
				this.onApproverAdded({ approver });
			}
			if (!isNil(requestLimit)) {
				this.updateUserLimit(requestLimit);
			}
			this.closeAside('userDetails');
		};

		this.$onInit = async () => {
			this.columns = this.generateColumns();
			this.rows = this.generateRows(this.users);
			this.roles = RoleService.getTeamRolesV2();
			const features = await FeatureService.getFeatures();
			this.team = await TeamsService.getTeam({ teamId: this.teamId });
			this.hasCompanyApprovalLimits = get(features, 'ENABLE_USER_APPROVAL_LIMITS', false);
			this.hasTeamApprovalLimits = this.team.properties.ENABLE_USER_APPROVAL_LIMITS === 'true';
		};
	},
};

export default PeopleTableComponent;
