import { v4 } from 'uuid';

import ts from '../../ts';

const AddCustomListAsideComponent = {
	bindings: {
		buttonTitle: '<',
		isOpen: '<',
		lists: '<',
		onClose: '&',
		selectedCustomList: '<',
		title: '<',
		updateTableAfterListAdd: '&',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{ $ctrl.title }}">
			<ts-form hide-buttons="true">
				<ts-form-fields>
					<ts-input
							label="{{$ctrl.customListNameText}}"
							ng-model="$ctrl.name"
							validate="$ctrl.validateInput(value)"
							type="text"></ts-input>
				</ts-form-fields>
			</ts-form>
			<menu data-ts="Buttons">
				<li>
					<button
							ng-disabled="$ctrl.isEnabled"
							class="ts-primary"
							ng-click="$ctrl.addCustomList($ctrl.name)">
						<span>{{ $ctrl.buttonTitle }}</span>
					</button>
				</li>
				<li>
					<button
							class="ts-secondary"
							ng-click="$ctrl.onClose()">
						<span ng-bind="$ctrl.cancelButtonText"></span>
					</button>
				</li>
			</menu>
		</ts-aside>
	`,
	controller: class AddCustomListAsideController {
		/* @ngInject */
		constructor(CodingHelper, CodingService, EventEmitter, LexicoService) {
			this.lexico = LexicoService.getLexico();
			this.customListNameText = this.lexico.trc('Form input title', 'Custom List Name');
			this.cancelButtonText = this.lexico.trc('Button', 'Cancel');
			this.isEnabled = true;
			this.isLoading = false;
			this.CodingHelper = CodingHelper;
			this.CodingService = CodingService;
			this.EventEmitter = EventEmitter;
		}

		$onChanges(changes) {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.init();
			}
		}

		async init() {
			this.isLoading = true;
			if (this.selectedCustomList) {
				const list = await this.CodingService.getList({ listId: this.selectedCustomList.listId });
				const { code, group, ID: id, name, ordering } = list;
				const target = 'VirtualCreditCardRequest';
				this.codingList = { code, group, id, name, ordering, target };
				this.name = name;
			} else {
				this.name = null;
				const uuid = v4();
				this.codingList = {
					code: uuid,
					group: uuid,
					id: uuid,
					ordering: '80',
					target: 'VirtualCreditCardRequest',
				};
			}
			this.isLoading = false;
		}

		async addCustomList(name) {
			const description = name;
			const codingList = Object.assign({}, this.codingList, { description, name });

			try {
				await this.CodingService.saveList(codingList);
				this.updateTableAfterListAdd(this.EventEmitter(codingList));
				this.isLoading = false;
				this.onClose();
			} catch (e) {
				this.isLoading = false;
				const warningMessage = this.lexico.trc(
					'Warning message',
					'There was a problem creating your list. Please try again',
				);
				ts.ui.Notification.warning(warningMessage);
			}
		}

		validateInput(value) {
			if (!this.lists) {
				return;
			}

			const listNames = this.lists.map(list => list.name);

			if (listNames.includes(value)) {
				this.isEnabled = true;
				const errorMessage = this.lexico.trc('Error message', 'Must be unique');
				return { valid: false, errorMessage };
			} else {
				this.isEnabled = false;
				const errorMessage = '';
				return { valid: true, errorMessage };
			}
		}
	},
};

export default AddCustomListAsideComponent;
