const _ = require('lodash');
const moment = require('moment');
const { queryClient } = require('../../../../router/Root');
const { requestKeys } = require('../../../../queries/query-keys');
// const { approvalKeys } = require('../../../../queries/approvals/approvalQuery');
const purchaseValidator = require('../../../../../common/validators/purchaseValidator');

/* @ngInject */
function PurchaseRequestFormController(
	$q,
	$scope,
	CodingHelper,
	CodingService,
	CompanyService, // NO SONAR
	ConfigService,
	constants,
	FeatureService,
	HeaderService,
	LexicoService,
	PaymentsService,
	StringsService,
	TeamsService,
	Request,
	RequestRegistry,
	RequestProfilesService,
	UserService,
) {
	// NO SONAR
	const DATE_FORMAT = 'YYYY-MM-DD';
	$scope.lexico = LexicoService.getLexico();
	$scope.inputValidationMessageText = $scope.lexico.trc(
		'Form input validation message',
		'Input contains an invalid character.',
	);
	$scope.cardTypeInputField = $scope.lexico.trc('Form input label', 'Card type');
	$scope.subscriptionFrequencyText = $scope.lexico.trc('Form input label', 'Subscription Frequency');
	$scope.subscriptionEndsText = $scope.lexico.trc('Form input label', 'Subscription Ends');
	$scope.numberOfPaymentsText = $scope.lexico.trc('Form input label', 'Number of payments');
	$scope.selectEndDateText = $scope.lexico.trc('Form input label', 'Select End Date');
	$scope.assignedToText = $scope.lexico.trc('Form input label', 'Assigned to');
	$scope.noApproverAssignedText = $scope.lexico.trc(
		'Form input validation message',
		'No Approver assigned, please contact your program administrator',
	);
	$scope.submitRequestText = $scope.lexico.trc('Button', 'Submit request');
	$scope.requestSubmittedText = $scope.lexico.trc('Success message', 'Request created.');

	// $scope.currencyZeroValid is used in the deprecated amex purchase request profile and should be removed as part
	// of that ticket: https://tradeshift.atlassian.net/browse/TGO-442
	$scope.currencyZeroValid = constants.regexPatterns.currencyZeroValid;
	$scope.CURRENCY_ZERO_INVALID_PATTERN = constants.regexPatterns.currencyZeroInvalid;
	$scope.currency = 'N/A';

	const filterCardTypes = (cardTypes, subscriptionEnabled, singleUseEnabled, multiuseEnabled) => {
		return cardTypes.filter(({ key }) => {
			return (
				((subscriptionEnabled && key === 'recurring-use') || key !== 'recurring-use') &&
				((singleUseEnabled && key === 'single-use') || key !== 'single-use') &&
				((multiuseEnabled && key === 'multiple-use') || key !== 'multiple-use')
			);
		});
	};

	function getNormalizedPurchaseRequest(purchaseRequest) {
		const clonedPurchaseRequest = _.cloneDeep(purchaseRequest);
		const normalizedCurrencyFields = _.reduce(
			clonedPurchaseRequest.currencyFields,
			function (result, value, key) {
				result[key] = value.replace(/[$,]/g, '');
				return result;
			},
			{},
		);
		clonedPurchaseRequest.fields = _.assign({}, clonedPurchaseRequest.fields, normalizedCurrencyFields);
		clonedPurchaseRequest.currency = $scope.currency;
		if (clonedPurchaseRequest.cardType !== 'recurring-use') {
			delete clonedPurchaseRequest.fields.frequency;
		}
		return clonedPurchaseRequest;
	}

	function getProviderLimitWithoutPadding(providerLimit, cardType) {
		if (cardType === 'recurring-use') {
			const providerLimitWithoutPadding = (providerLimit * 100) / ($scope.subscriptionPadding + 100);
			return _.toNumber(providerLimitWithoutPadding.toFixed(2));
		} else {
			return providerLimit;
		}
	}

	$scope.submitRequest = async function (request, purchaseRequest) {
		$scope.$emit(constants.scopeEvents.goTyping, { isTyping: true });
		const normalizedPurchaseRequest = getNormalizedPurchaseRequest(purchaseRequest);
		const amount = normalizedPurchaseRequest.fields.amount || normalizedPurchaseRequest.fields.InvoiceAmount;
		const requestLimit = await CompanyService.getRequestLimit();

		if (requestLimit !== '' && requestLimit < amount) {
			const warningMessage = `${$scope.lexico.tr(
				"The amount requested was over the company's request limit of",
			)} ${requestLimit}. ${$scope.lexico.tr('Please try again with a lower request amount.')}`;
			ts.ui.Notification.warning(warningMessage);
			$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			return;
		}
		const providerLimit = getProviderLimitWithoutPadding(
			constants.companyProperties.providerLimit,
			purchaseRequest.cardType,
		);
		if (_.toNumber(amount) > providerLimit) {
			const warningMessage = `${$scope.lexico.tr(
				"The amount requested was over the provider's request limit of",
			)} ${providerLimit}. ${$scope.lexico.tr('Please try again with a lower request amount.')}`;
			ts.ui.Notification.warning(warningMessage);
			$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			return;
		}
		return request
			.submit(normalizedPurchaseRequest)
			.then(function () {
				// invalidate in sidemenu the requests list and for approvals icon
				queryClient.invalidateQueries({
					queryKey: [requestKeys.getRequestList],
				});
				HeaderService.get().title(request.getTitle());
				$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
				request.setCardType(purchaseRequest.cardType);
			})
			.catch(function (error) {
				let warningMessage;
				if (error.status === 429) {
					warningMessage = $scope.lexico.trc(
						'Warning message',
						'Your company has exceeded the limit for card requests submitted per minute. ' +
							'Please limit requests to less than 5 per minute and try again later.',
					);
				} else {
					warningMessage = $scope.lexico.trc('Warning message', 'There was a problem submitting the purchase request.');
				}
				ts.ui.Notification.warning(warningMessage);
				$scope.event.hasError = true;
				$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			});
	};

	$scope.onConfirmNumberOfPaymentsAside = ({ numberOfPayments }) => {
		$scope.purchaseRequest.fields.numberOfPayments = numberOfPayments;
		$scope.hideNumberOfPaymentsAside();
	};

	$scope.hideNumberOfPaymentsAside = () => {
		$scope.isNumberOfPaymentsAsideShown = false;
	};

	$scope.showNumberOfPaymentsAside = () => {
		$scope.isNumberOfPaymentsAsideShown = true;
	};

	$scope.setDates = async () => {
		const virtualCardExpRange = await CompanyService.getVirtualCardExpRange();
		const { ENABLE_VIRTUAL_CARD_EXP_RANGE } = await FeatureService.getFeatures();

		const momentDateNow = moment().startOf('day');
		const momentDateNextYear = moment().startOf('day').add(1, 'year');
		const default1YearEndDateDays = momentDateNextYear.diff(momentDateNow, 'days');
		const endDateOffSetDays = ENABLE_VIRTUAL_CARD_EXP_RANGE ? virtualCardExpRange : constants.virtualCardDefaultExpDate;
		const defaultEndDate = ENABLE_VIRTUAL_CARD_EXP_RANGE ? virtualCardExpRange : default1YearEndDateDays;

		$scope.maxEndDate = moment().add(endDateOffSetDays, 'days').format(DATE_FORMAT);
		$scope.minEndDate = moment().add(2, 'days').format(DATE_FORMAT);
		$scope.defaultEndDate = moment().add(defaultEndDate, 'days').format(DATE_FORMAT);
		$scope.purchaseRequest = { fields: { endDate: $scope.defaultEndDate } };
		$scope.dateMessage = $scope.lexico.trc('Form input hint', 'Up to 4 years in the future.');

		if (ENABLE_VIRTUAL_CARD_EXP_RANGE) {
			const rangeObj = constants.virtualCardExpRanges.find(range => range.id === virtualCardExpRange);
			const rangeMessage = _.get(rangeObj, 'label', $scope.lexico.trc('Form input hint', 'a date'));
			$scope.dateMessage = `${$scope.lexico.tr('Up to')} ${rangeMessage} ${$scope.lexico.tr('in the future')}`;
		}
	};

	$scope.validatePurchaseAmount = amount => {
		const isValid = purchaseValidator.validatePurchaseAmount(amount);
		const errorMessage = isValid ? '' : $scope.lexico.tr('Amount field is invalid');
		return {
			valid: isValid,
			errorMessage,
		};
	};

	$scope.validatePurchasePurpose = description => {
		const isValid = purchaseValidator.validatePurchasePurpose(description);
		const errorMessage = isValid
			? ''
			: $scope.lexico.tr('The following characters are now allowed: " ’ / < > &, Please try again');
		return {
			valid: isValid,
			errorMessage,
		};
	};

	(async function init() {
		$scope.features = {};
		$scope.isLoading = {
			event: true,
		};
		$scope.missingFilters = false;
		$scope.isNumberOfPaymentsAsideShown = false;
		$scope.$emit(constants.scopeEvents.goTyping, { isTyping: true });

		const request = RequestRegistry.getRequest($scope.event.requestId);
		$scope.request = !_.isEmpty(request) ? request : new Request($scope.event.data);
		$scope.request.update($scope.event.data);

		if (request.hasReachedState('submitted')) {
			$scope.isLoading.event = false;
			$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			return;
		}
		await $scope.setDates();

		$scope.subscriptionPadding = ConfigService.get('SUBSCRIPTION_PADDING_PERCENTAGE');

		$scope.currency = await PaymentsService.getCurrencies(request.getTeamId());

		UserService.getUser()
			.then(function (user) {
				$scope.user = user;
				const teamId = request.getTeamId();
				return $q.all([
					CodingHelper.loadTeamCodingLists(request.getTeamId()),
					RequestProfilesService.getProfileForCompany({
						companyId: $scope.user.companyAccountId,
					}),
					TeamsService.getTeam({ teamId }),
					PaymentsService.getCurrencies(teamId),
				]);
			})
			.then(function (results) {
				$scope.codingLists = CodingService.mapTeamCodingLists(results[0]);
				const team = results[2];
				$scope.ENABLE_SUBSCRIPTIONS = team.properties.ENABLE_SUBSCRIPTIONS === 'true';
				$scope.ENABLE_SINGLEUSE = team.properties.ENABLE_SINGLEUSE === 'true';
				$scope.ENABLE_MULTIUSE = team.properties.ENABLE_MULTIUSE === 'true';
				$scope.cardTypes = filterCardTypes(
					ConfigService.get('CARD_TYPES'),
					$scope.ENABLE_SUBSCRIPTIONS,
					$scope.ENABLE_SINGLEUSE,
					$scope.ENABLE_MULTIUSE,
				);

				// since we dont support multiple currencies just yet this will use the first item in the list
				$scope.currency = results[3][0];

				const isMultiUse = $scope.cardTypes.find(type => type.key === 'multiple-use');
				$scope.purchaseRequest.cardType = isMultiUse ? 'multiple-use' : $scope.cardTypes[0].key;

				if ($scope.ENABLE_SUBSCRIPTIONS) {
					$scope.subscriptionEndings = ConfigService.get('SUBSCRIPTIONS').endings;
					$scope.subscriptionFrequencies = ConfigService.get('SUBSCRIPTIONS').frequencies;

					// this code is here temporarily while we wait for the updates to card services
					// that will allow us to make subscription cards that end after X payments.
					$scope.$watch('purchaseRequest.cardType', newVal => {
						$scope.purchaseRequest.fields = $scope.purchaseRequest.fields || {};
						if (newVal === 'recurring-use') {
							_.assign($scope.purchaseRequest.fields, {
								ends: 'on-a-specific-date',
							});
						} else {
							_.assign($scope.purchaseRequest.fields, {
								ends: '',
							});
						}
					});

					// this code is being disabled until we have the ability to
					// create subscription cards that end after X number of payments
					// $scope.$watch('purchaseRequest.fields.ends', (newVal) => {
					// 	if (newVal === 'on-a-specific-date') {
					// 		const datePicker = ts.ui.DatePicker({
					// 			onselect: (value) => {
					// 				$scope.purchaseRequest.fields.endDate = value;
					// 				datePicker.close();
					// 			},
					// 			title: 'Select end date',
					// 		});
					// 		datePicker.open();
					// 	} else if (newVal === 'number-of-payments') {
					// 		$scope.showNumberOfPaymentsAside();
					// 	}
					// });
				}

				const requestProfile = results[1];
				$scope.template = _.kebabCase(requestProfile.id);
				$scope.request.setProfileId(requestProfile.id); // TODO: would be nice to have this set already
				$scope.formFields = _.reduce(
					requestProfile.fieldDefinitions,
					function (result, value) {
						result[value.id] = {
							id: value.id,
							isRequired: value.required,
							label: StringsService.fieldDefinitions[value.id],
							placeholder: value.description,
						};
						return result;
					},
					{},
				);
				$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			})
			.catch(function () {
				const warningMessage = $scope.lexico.trc(
					'Warning message',
					'Oh snap! There was a problem creating a request. Please try again.',
				);
				ts.ui.Notification.warning(warningMessage);
			})
			.finally(function () {
				$scope.isLoading.event = false;
				$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			});
	})();
}

module.exports = PurchaseRequestFormController;
