"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEmailInput = void 0;
const react_1 = require("react");
const zod_1 = require("zod");
const emailSchema = zod_1.z.string().email();
const initialState = {
    value: '',
    isError: false,
    isBlurred: false,
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_EMAIL': {
            return { ...state, value: action.payload };
        }
        case 'SET_IS_EMAIL_ERROR': {
            return { ...state, isError: action.payload };
        }
        case 'SET_BLURRED': {
            return { ...state, isBlurred: action.payload };
        }
        case 'RESET': {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
const useEmailInput = () => {
    const [state, dispatch] = (0, react_1.useReducer)(reducer, initialState);
    const handleEmailChange = (event) => {
        dispatch({ type: 'SET_BLURRED', payload: false });
        const email = event.target.value;
        dispatch({ type: 'SET_EMAIL', payload: email });
        const emailIsValid = emailSchema.safeParse(email).success;
        if (emailIsValid) {
            dispatch({ type: 'SET_IS_EMAIL_ERROR', payload: false });
        }
        else if (state.isBlurred) {
            dispatch({ type: 'SET_IS_EMAIL_ERROR', payload: true });
        }
    };
    const handleEmailValidation = () => {
        dispatch({ type: 'SET_BLURRED', payload: true });
        if (state.value.length > 0) {
            const isEmailError = emailSchema.safeParse(state.value).success;
            dispatch({ type: 'SET_IS_EMAIL_ERROR', payload: !isEmailError });
        }
        else {
            dispatch({ type: 'SET_IS_EMAIL_ERROR', payload: false });
        }
    };
    return { state, handleEmailChange, handleEmailValidation };
};
exports.useEmailInput = useEmailInput;
