/* @ngInject */
const CodingListController = (
	$scope,
	$state,
	_codingList,
	_costCenterFiltering,
	_manageHeader,
	CodingHelper,
	CodingListTable,
	HeaderService,
) => {
	$scope.asides = {
		confirmDeleteEntry: {
			isOpen: false,
			listId: _codingList.listId,
		},
		selectCostCenters: {
			isOpen: false,
			options: _costCenterFiltering.costCenterOptions,
			title: 'Select cost centers',
		},
	};

	$scope.onDeleteEntryCancelled = () => {
		$scope.asides.confirmDeleteEntry.isOpen = false;
	};

	$scope.onDeleteEntryConfirmed = () => {
		const aside = $scope.asides.confirmDeleteEntry;
		CodingHelper.deleteEntry(aside.listId, aside.entry)
			.then(() => {
				$scope.table.removeEntry(aside.entry);
				aside.isOpen = false;
			})
			.catch(() => {
				ts.ui.Notification.warning('There was a problem deleting the coding entry. Please try again.');
			});
	};

	$scope.$on('$destroy', () => {
		if ($scope.initialRowLength !== 1 && $scope.table.rows.length === 1) {
			ts.ui.Notification.warning('The coding list was disabled because there are no more entries.');
			CodingHelper.toggleListEnabledById({ enabled: false, listId: _codingList.listId });
		}
		_manageHeader.create();
	});

	$scope.onSelectCostCentersClosed = () => {
		$scope.asides.selectCostCenters.isOpen = false;
	};

	$scope.onSubmitSelectCostCenters = ({ columnIndex, rowIndex, id: values }) => {
		$scope.table.addCostCenters({ columnIndex, rowIndex, values });
		$scope.asides.selectCostCenters.isOpen = false;
	};

	(function init() {
		$scope.table = CodingListTable({
			confirmDeleteEntryAside: $scope.asides.confirmDeleteEntry,
			costCenterOptions: _costCenterFiltering.costCenterOptions,
			entries: _codingList.entries,
			listId: _codingList.listId,
			selectCostCenterAside: $scope.asides.selectCostCenters,
			showCostCentersColumn: _costCenterFiltering.showCostCentersColumn,
		});
		HeaderService.create({
			type: 'lite',
			title: _codingList.name,
			buttons: [
				{
					icon: 'ts-icon-close',
					type: 'ts-secondary go-close-modal',
					onclick: () => {
						$state.go('main.manage.coding');
					},
				},
			],
		});

		$scope.initialRowLength = $scope.table.rows.length;
	})();
};

CodingListController.resolve = {
	_codingList: /* @ngInject */ ($transition$, CodingHelper) => {
		const listId = $transition$.params().codingListId;
		return CodingHelper.loadList(listId);
	},
	_costCenterFiltering: /* @ngInject */ ($transition$, CodingHelper, FeatureService) => {
		const returnValue = { showCostCentersColumn: false, costCenterOptions: [] };
		if ($transition$.params().codingListId !== 'glcode') {
			return returnValue;
		}
		return FeatureService.getFeatures().then(features => {
			if (!features.ENABLE_FILTERED_BY_COSTCENTER) {
				return returnValue;
			}
			return CodingHelper.loadList('costcentercode').then(costCenterList => {
				returnValue.showCostCentersColumn = true;
				returnValue.costCenterOptions = costCenterList.entries.map(entry => ({
					id: entry.entryId,
					description: entry.entryLabel,
					label: entry.entryId,
				}));
				return returnValue;
			});
		});
	},
};

module.exports = CodingListController;
