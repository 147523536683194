const _ = require('lodash');

/* @ngInject */
function ApprovalsListBuilder(constants, ApprovalList) {
	return {
		getApprovalLists: function () {
			const approvalLists = {};
			_.map(constants.tasks.states, function (key) {
				approvalLists[key] = new ApprovalList(key);
			});
			return approvalLists;
		},
	};
}

module.exports = ApprovalsListBuilder;
