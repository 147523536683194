module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# dari transaksi",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 menit",
"ce09c436b49af331a32f1152ede7dd18":"1. Minta dana",
"d2226752d3065fbad4744924b4a0ee73":"2. Tinjau dan setujui",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Tinjau dan setujui",
"82bab92f1c8030888550b51521266c53":"3. Bayar dengan kartu virtual",
"2ecd62e02e507f8e839a55eac0a04886":"Entri pemberian kode dengan ID '{entryId}' sudah ada.",
"5dfd25849740fbce8147838b0d5094eb":"Nama singkat yang mudah, misalnya, Kartu Pemasaran",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Anggota tim keberhasilan pelanggan kami akan segera menghubungi Anda.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Seseorang yang gagal kami cari",
"976b50e70e4433e2786233979d243445":"Tim dengan nama ini sudah ada.",
"35b61d21ac11d5fc31ebe58541263445":"Terima undangan Anda untuk bergabung",
"a372cb2d4204654f52cd9170fb10d374":"Kode Akunting",
"110fc66010b6b91cebee7a59a2067aab":"Kode akunting",
"db862bb04dbdc6d04e47063f33be8865":"Kode akunting dikelola melalui daftar pemberian kode dan entri dalam daftar.",
"d6e3848e824f077396be4351eb58edd2":"Diperlukan tindakan - setujui/tolak permintaan baru dari {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Tindakan",
"476e728ea824229f52438e543818bcdd":"Tindakan",
"3117746cb7d4ab815ec98274ec4398d5":"Tindakan",
"3ab09b66a7f16219d8135977a2a76d10":"Tindakan",
"ffafa66eaab0409a2e90aaeb43796def":"Aktif",
"0447e0aa5d00dbdab11b5546793c4479":"Aktif",
"8ee51d21b05466f7d962d4ff5a0b384e":"Kartu Aktif",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Kartu Virtual Aktif",
"89c6ec05861aa914304ba35bc6744124":"Kartu aktif",
"5280659bcecaf8344c57790f9962a9a2":"Aktivitas",
"8a15368ae286fa64e1cb7624cd410fc6":"Tambahkan Daftar Pemberian Kode",
"8ded7e09e86be231b1165308329ac76d":"Tambahkan Daftar",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Tambahkan admin",
"695bb4bc29e2df769ddf7427ab71012a":"Tambahkan pemberi persetujuan",
"4a1d4516cab00b7c5ecdb150e346e5db":"Tambahkan pemberi persetujuan",
"56869851b27c2915f5a7fe6a33b91ac7":"Tambahkan daftar pemberian kode",
"8f8691057c3994aa61f9994202680c87":"Tambahkan daftar pemberian kode",
"69f7a406b79d19bf8082ed245ab29123":"Tambahkan daftar pemberian kode",
"48a92ca1cc63018707be99b13196369f":"Tambahkan admin perusahaan",
"a679db9ff80d0e5769ae1ba311c7916f":"Tambahkan domain",
"8f5e8078be77146ba7f3be49ef948906":"Tambahkan entri",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Tambahkan pesan",
"f8a4b14162e19995074e63135b65c84d":"Tambahkan kartu baru",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Tambahkan orang",
"c670549d0b898bb6a571eb2cd7804a6e":"Tambahkan tanda terima",
"eb937098e77d8a6b0565ed72357dcaae":"Tambahkan tanda terima",
"1e154f31c31b63a459f3956e380b3ef9":"Tambahkan yang dipilih",
"ba24db9956f156a18b32e653552ff682":"Tambahkan beberapa orang ke tim ini",
"52bd93fb5489b25167bb541f7c6504ae":"Tambahkan kartu sumber",
"21ab3f08eb507dbc76df166eafeb393a":"Telah menambahkan {fullName} sebagai pemberi persetujuan!",
"f879c06507d3076bbf52ea4fd2df850e":"Detail kartu tambahan",
"21e934ae30eece4a894337701f497638":"Semua",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Semua Kartu",
"8fac71195e2b506e029752e4864b6a5d":"Semua anggota",
"f9708417df16227742e0545a8642eea7":"Semua pengguna yang tersedia telah ditambahkan ke kartu ini.",
"cb5abf070f8cba7982205038baab77bb":"Semua kartu",
"dae7d602eec2c84dea0d80ae25e56e6d":"Semua kartu",
"0b4bdfde6578668b6018d3615a1ad30f":"Semua pusat biaya",
"97b875b81f54b4432d998a3e47d949cc":"Semua tim",
"f0ae83969f7c20a1964caf5156dae966":"Semua tim",
"d87455601a00282078582b36a3825b7d":"Semua tim",
"24c7b7f55882ea94a525392ca8218fa0":"Semua tim",
"8f860e35b64a8f447bded1e5ea66a2a8":"Semua kartu virtual",
"e403956ecaa6fb33ec1fef5c49d71042":"Semua kartu virtual",
"4e6f4e8f25f61d40e1eba77de94f6458":"Semua informasi keuangan Anda dienkripsi dan pada server di lokasi yang aman.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Nilai",
"a4d099d0dbe3889b0995546e6ed28932":"Nilai",
"6c0ef32aae76bc31a61e2124f542bdf1":"Nilai",
"adec89c93525f1a8d1c64d856f405e27":"Nilai: tinggi ke rendah",
"18e632a170203894f40deed1d33ee552":"Nilai: rendah ke tinggi",
"a7944575cafef9126a499a1b06999fe3":"Terjadi kesalahan, silakan coba lagi nanti.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Terapkan",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Opsi Persetujuan",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Pengaturan Persetujuan",
"492eedd88cdd7bb45f39c6594af748c4":"Batas persetujuan",
"437d490afc8d226f8c7d544f4e35d011":"Batas persetujuan ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Persetujuan",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Persetujuan",
"17adb7fe866e32055b1469fdb7e82795":"Pengaturan persetujuan",
"7125aa13db437084e1d27d3ff38f22e2":"Setujui",
"84df787ff5746f9dc3cf0146becef4fd":"Setujui Permintaan",
"7a568654d00245b0bab6c90a1b67d196":"Disetujui",
"179c3e187ad8edeb8348cff222b938bf":"Disetujui",
"ad058d17725870df9abbd4f5ec217636":"Disetujui ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Nilai yang Disetujui",
"4d58ba5c0e03fec1556c243be106e4a3":"Disetujui Oleh",
"7d3d697fc99f6c4641b326623743f7b7":"Disetujui { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Pemberi persetujuan",
"8c45e443fc6748190719d79a092e2528":"Pemberi persetujuan memiliki batas persetujuan maksimum",
"4fe56aa6456c108cd288a4ec8acf435f":"Arsipkan",
"a9b6f93de9630064be9809130aa1e195":"Anda siap untuk mengendalikan pengeluaran kartu Anda?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Anda yakin ingin membatalkan permintaan ini?",
"301bfc2ce058f49e0704fec3d162b5b5":"Anda yakin ingin menetapkan kembali permintaan ini kepada Anda?",
"2181faf8d9973d1f4138e401a762086e":"Anda yakin ingin menghapus ${firstName} ${lastName} dari menjadi administrator perusahaan?",
"b982277f29055d0ef6e295fe2006d9e0":"Anda yakin ingin menghapus {firstName} dari tim ini?",
"456c0ccff14acb808583cd9dece857b6":"Tugaskan kepada",
"bde315b9cf7fd55f4b65aabbbf38d462":"Minimal satu manajer atau pemilik tim harus dapat digunakan di luar kantor.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Otentikasi",
"1961ec69dd0c8b5718c831312e7f3d0f":"Koleksi tanda terima otomatis",
"e39f2477cf34d726936a5b5c2b48a4ad":"Secara otomatis buat laporan rekonsiliasi yang akurat yang mencakup informasi tentang pembeli, penerima, tanda terima, kode akun, dan lainnya.",
"434ca07dfde0eaee14f094b7c75a6eae":"Tersedia",
"eb85d8f50f2c89871b794f7d54279b22":"Saldo Tersedia",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Tersedia { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Kembali",
"c8258f8cf9e20f6bbb89d957de40e820":"Keamanan tingkat bank",
"d46c22a3f36dce568c07fcea4368b231":"Pengaturan dasar",
"fb481c8e517016094b4fdf618c5fa088":"Alamat penagihan",
"79a9f821923c5a04c076902fef684281":"Alamat penagihan",
"877a08b8347052c83aa0b689baea81e7":"Alamat penagihan",
"97f018458e2627fac37d24846c7b8922":"Dengan melanjutkan untuk membuat akun Anda dan menggunakan Tradeshift Go berarti Anda menyetujui",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Dengan melanjutkan untuk membuat akun Anda dan menggunakan Tradeshift Go berarti Anda menyetujui",
"912d1864f0068c764bf4784020795d49":"NAMA PEMEGANG KARTU",
"d53474c7fd99fbeb69481ce9d358aa09":"Dapat Meminta kartu dan memiliki dompet untuk semua kartu mereka. Masing-masing anggota mendapatkan pemberi persetujuan (manajer) default untuk permintaan mereka.",
"f30df2cab105ed3f8b4264aeb9800abd":"Dapat menyetujui permintaan dan membuat kartu untuk anggota yang mereka kelola. Manajer memiliki dasbor untuk melacak pengeluaran anggota yang mereka kelola.",
"03ed9eccc71ef59165292eac542d3050":"Dapat melihat semua permintaan dan aktivitas belanja di tim. Pemilik tim juga mendapatkan dasbor tim untuk melacak pengeluaran dan dompet tim tempat mereka dapat melihat semua kartu yang aktif.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Tidak dapat menghapus pengguna ini. Anda membutuhkan setidaknya satu pemilik tim atau manajer di tim.",
"a0b70642629a55ea0ab6fa3414d342e0":"Tidak dapat menghapus pengguna dengan permintaan yang tertunda.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Batalkan",
"5446624d3c364ac05ddf8d6663da2796":"Batalkan Kartu",
"53f3815c4fa3340b29efd81e627d1e6b":"Batalkan Kartu",
"12257ea2a871b7fab72245b5aafe6424":"atalkan Permintaan",
"2aafb7eb4d56a516c4bb0754f0812df1":"Batalkan kartu",
"caae12601b01e2325161e95107f9e332":"Batalkan kartu",
"6ad1e57b1e4c7accbe166c948883077d":"Batalkan dengan klik jika disusupi",
"f6527e49e6d5c989624507a583e23f39":"Dibatalkan ({count})",
"1ad3bf5121641dda511f42942af05a90":"Membatalkan kartu ini tidak akan membalik biaya yang tertunda. Harap cari pelunasannya",
"aebd3826a195afad0a93050a7cc5d73d":"Membatalkan kartu ini tidak akan membalik biaya yang tertunda. Harap cari catatan pelunasan untuk biaya dalam laporan kartu kredit yang dikirim oleh penyedia kartu Anda.",
"a5b5136947da562f91b75daf8156f50e":"Detail Kartu",
"9ed31ad23a1578ce480d31207decf1d3":"Pengaturan Kartu",
"def15f2e007fc69886230fdb2511305c":"Detail kartu",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Detail kartu",
"67cfd260078ca32027c2fa4a4a2d29f4":"Pemegang kartu",
"4458819ea60c68c46e551a8f5cc163cd":"Nama kartu",
"0858bcbd506fa3240b165e59d2f18aae":"Nama kartu",
"5212736dd18ee2c31f3650ad2c2c7130":"Nama kartu telah diperbarui.",
"c96d62fa510c3356dc73a91d1a80e262":"Nomor kartu",
"41307fee4c32aefb5383a98fe3d6e45e":"Jenis kartu",
"3f8045c3358621dad4346a44de0c05a8":"Nama pemegang kartu",
"879a6b452033cf5f3a4bb3e1837a0386":"Kota",
"593334553ef852024e172505ec3c5f21":"Klik di Sini",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Pemberian Kode",
"df931751d5790d74fa46707aaf7a26ae":"Pemberian Kode 1",
"7ac7154bc776aff52a71b0d83b860561":"Pemberian Kode 2",
"586fcf19136ae5e26795e9055b2e2995":"Pemberian Kode 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Pemberian Kode 4",
"379bb8e71bb090badebbb0751de86f11":"Pemberian Kode 5",
"3e84d13627b3d090ab2b8470af98340a":"Pemberian Kode 6",
"33b94b581f3792049992663396e51cb8":"Pemberian Kode 7",
"b73fbc32e439655833ee99126dd0e55f":"Pemberian Kode 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Daftar Pemberian Kode",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Entri pemberian kode",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Entri pemberian kode telah dihapus.",
"cada62a39ee72d790dcdbb25160f8783":"Entri pemberian kode telah disimpan.",
"f645bf50888943ee1384693e56a0f475":"Nama daftar pemberian kode",
"a999182acbcd77c15299f79181b630be":"Daftar pemberian kode telah disimpan.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Daftar pemberian kode",
"d0b96596ce6baec5aabb8a0690ab30e1":"Komentar",
"ff9cb8842f832d21fb160b3d3784ce79":"Perusahaan",
"715b59a64e686eb91a21114644ffe39e":"Admin Perusahaan telah dihapus",
"8758e9a01253c097a42393fda9610715":"Dasbor Perusahaan",
"e118b41009918b326db6746856831cbc":"ID Perusahaan",
"4dba88b05b3fa8875128e19e88c512b3":"Pengaturan Perusahaan",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Pengaturan Perusahaan",
"f2ea6d33de8182d97bf2f13354af4893":"Pengaturan Perusahaan: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Admin perusahaan",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Batas persetujuan perusahaan",
"7e2f778da14a726b53e05926a3483f2b":"Kartu kredit perusahaan",
"7313fd1db3d63858586d1c0f89251d6e":"Nama perusahaan",
"07721e91dd10ff8831f0d24e47cc1eab":"Nama perusahaan",
"203221610ca4d347063c037e1a732ae8":"Pengaturan perusahaan telah disimpan.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Konfirmasikan tindakan",
"d694cec09cdbc02b1ffe22642a63faa1":"Konfirmasikan kata sandi",
"512801312a133f29974f2259a455f39b":"Konfirmasikan kata sandi",
"f54c38ed0b9869d929ffc0bd5310131f":"Konfirmasi - Akun Anda telah dibuat di bawah Tim {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Hubungi admin perusahaan Anda untuk membuat kode akunting.",
"7a6a4de077398e8e138aec36e3b3d305":"Lanjutkan dengan SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Hak cipta {date} Tradeshift Inc. Semua hak dilindungi undang-undang.",
"51f0d8e04f6ee626b866b37833dc2b61":"Pusat Biaya",
"e043f60a02f157d0f31a42f0db67cc87":"Tidak dapat mengundang {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Tidak dapat mengirim pesan Anda, silakan coba lagi.",
"ae28083f22a0b2fc5637614bce0976e0":"Buat Daftar Kustom",
"738078dc992208e05406f935995dc0c3":"Buat Daftar Kustom",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Buat Daftar Baru",
"0f5783c3921b3d7398d281644bbe5bde":"Buat permintaan",
"6d62f934f2304701f88bdcfbc688de7e":"Buat akun",
"f6b7d0d8531ea268f988005dd2147b2b":"Buat kartu",
"1a083675d1453448e4645403bbe52dd9":"Buat daftar kustom",
"c10ab11bdfb001da74850ed89c45482f":"Buat permintaan baru",
"43a9c06398408bfe77d06ffc2c1c3b87":"Buat permintaan baru",
"68bf17fd3f54a89c0a84224e2f9a971c":"Buat tim baru",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Buat tim",
"3b8e665101b9b3657f8a68c5305947b7":"Dibuat",
"40e91f2907181ffa8e62567b3a407e60":"Dibuat oleh",
"8929f7aacb969ef6353aba9903eb362d":"Kartu kredit telah ditambahkan.",
"d86688c26e8ac5eb9facc971b6fe5155":"Mata uang",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Mata uang",
"500ded53f64e737a5e4337675ac9b7ce":"Nama Daftar Kustom",
"b79a95f04fd829fb2e8d0313e522a94d":"Harian",
"ef445eab224fc599e4103c9b1745a4e0":"Dasbor",
"788452c3ad2b1450b6d8d116b863e635":"Dasbor",
"fb7add662cf7042355319a975fab6ae3":"Enkripsi Data",
"6bded249dbfafd14490eea8dd37e1f51":"Tanggal",
"46e908a57cb09ef947af8c4a93a09716":"Tanggal",
"e4213104a55eceb60e00408699438dcd":"Nonaktifkan tim",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Menonaktifkan tim akan membatalkan semua kartu virtual yang aktif dan tidak ada anggota tim, manajer, atau pemilik yang dapat mengakses tim. Hanya Anda atau admin perusahaan lain yang dapat mengaktifkan kembali tim. Anda yakin?",
"0d0d5677bee2b99884802638e507160a":"Tolak",
"b5e3483aae9d36ce1440fe02904d6da1":"Tolak Permintaan",
"00f3db97287cb17ee9c2a131f2141a65":"Ditolak",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Ditolak ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Pemberi Persetujuan Default",
"b77589ac3ac2317cfc96d7013658d5c9":"Hapus ID pemberian kode: { entryId }, Entri: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Detail pernyataan dan pelaporan",
"5234dfc4220ccd18a8b62fe84e372248":"Detail",
"37929896817a6744718004374bb42fd8":"Detail",
"dd0a6194e8a6ae14f3e1379a1640de15":"Anda bermaksud menghapus {fullName} ({email}) dari perusahaan?",
"1a98048b34090da8b199135ccb4c1186":"Nomor yang berbeda dan CVC menyembunyikan detail kartu yang sebenarnya",
"3cf132b60fe16b816ca227bbcad60537":"Jangan batalkan",
"375ae8224d7d35393974775cebe8605a":"Unduh Daftar Pengguna",
"54b3bfd45a293b441d441aab1a50e644":"Unduh laporan",
"60cf3b94e5df14a504825068928fc118":"Unduh CSV laporan",
"7341a4c73c0b688484304c37da4a6d8e":"TANGGAL KEDALUWARSA",
"f628f8ad44fbd6c70c99fc778407b666":"Cari transaksi historis dengan mudah dan jelajahi tren pengeluaran karyawan sepanjang periode waktu apa saja.",
"94bb409d034ea20697ae09d812e55365":"Edit Daftar Kustom",
"2974e704676cb79666bc236d8658fa97":"Edit profil",
"9ff205f99ed4002d049f855f965ca6d8":"Edit pengguna",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Karyawan meminta dana dari kartu perusahaan untuk melakukan pembelian. Dana yang diminta dapat menetapkan batasan belanja.",
"829cff16ce0b13810c914c161b2a9fba":"Aktifkan dapatkan fungsi pengguna alternatif",
"741387c7bd71e2b4a9601af9a6b9490e":"Aktifkan penerusan permintaan persetujuan secara otomatis",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Aktifkan tanggal kedaluwarsa default untuk kartu virtual",
"bb319710dad93e78cc6f58c615bdf210":"Aktifkan daftar putih domain email untuk pengguna yang diundang",
"4700ced24cd278c3149ffa983006035c":"Aktifkan pemfilteran berdasarkan pusat biaya",
"3fe06ef8f551c4cb4c0375362e25521d":"Aktifkan opsi globalisasi di sisi pengguna",
"2787d4bc9752272ac36b499ce7ad93ab":"Aktifkan kartu virtual multiguna",
"6ee44e2280c9ce5009568846cbc3dd14":"Aktifkan di luar kantor",
"1941ca87053df0cebc6091ede92c18ea":"Aktifkan tampilan orang untuk pengguna admin perusahaan",
"e50282c2ec7afb948d2c0b428db5d5ff":"Aktifkan unggahan tanda terima untuk transaksi",
"268694cb2172918fe60f835919bf20ec":"Aktifkan unggahan tanda terima di tabel pelunasan",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Aktifkan persetujuan sendiri",
"d17c7acf598b569cce8a2bb97e0bb215":"Aktifkan kartu virtual sekali pakai",
"cd731ca218c9e14caabefafe003cfa6d":"Aktifkan kartu virtual berlangganan",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Akhiri penerusan pada",
"c1f665a814894b17d603b7052b631d90":"Daftarkan kartu baru",
"6b6cea93e07e596be70f7b76801e1516":"Daftarkan kartu baru",
"7d6ac1ac9cba1620b61908842ebb106b":"Daftarkan kartu sumber",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Daftarkan kartu sumber",
"21a642b339c3eabf2789c0b05dcf828b":"Daftarkan kartu perusahaan pertama Anda ke GO.",
"6efa8dca4fba23a190838746879fd704":"Masukkan ID angka",
"02c3457577da9dc8dba6e6f9380a248e":"Masukkan nama tim yang unik",
"40feb3fd1fa529cb599aeac9607664a4":"Masukkan entri baru",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Contoh: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Bulan Kedaluwarsa",
"b8bc74aeeb2de3492891c97361de107d":"Tahun Kedaluwarsa",
"6a0b8d521500014d99dd192d8ce8403d":"Tanggal kedaluwarsa",
"8cf274169a732340430fe7cc5236e0e7":"Ukuran file harus kurang dari {maxFileSize} MB",
"9b49c88fa20cc006baaac15c857c8781":"Untuk pembelian tunggal atau ganda",
"bb7a2907073bd760f4e82097132637b1":"Lupa Kata Sandi",
"26bdfaa592f3629273f13c22435b323d":"Lupa kata sandi Anda?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Teruskan",
"3f9fe6adc14ce5776097170653a24462":"Teruskan Permintaan",
"b774e35683f5737c36bf0cf3d6785973":"Teruskan permintaan",
"628faa5054a912b1e470414f77057e6b":"Teruskan permintaan",
"852b4065e1837fdc77940b6411a6d902":"Perlindungan dari Penipuan",
"616551bd10d95aca439401d6a7769d96":"Nilai pengiriman",
"a65a230918e9c11e1ef8824745e26a3d":"Memulai",
"7d4c4466b7716347ce3673acaa1983f7":"Dapatkan lebih banyak dari Go",
"11415a07658cfef80eb166896d77ad98":"Bersiaplah untuk wawasan belanja yang lebih banyak lagi. Tradeshift Go kini akan lebih selaras dengan cara tim bisnis suka bekerja.",
"ac0ff5d14286ef74614a9c6021f08f69":"Dapatkan pemantauan belanja secara seketika dan pelacakan anggaran, dapatkan wawasan, dan telusuri semua aktivitas pembelian.",
"24dc4bb00841b004031fca3a9bb9bf51":"Permintaan kartu Go telah dibatalkan oleh {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go mengingatkan karyawan akan setiap tanda terima yang hilang, yang dapat dilampirkan hanya dengan menanggapi pemberitahuan, atau bila tanda terima email, meneruskannya ke Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go bekerja dengan jaringan kartu kredit Anda untuk menjamin standar pencegahan dari penipuan yang sama seperti yang Anda harapkan ketika bekerja dengan lembaga keuangan yang paling elit.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Kabar gembira! Permintaan pembelian Anda telah disetujui di Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Ada pertanyaan atau masukan? Hubungi kami di",
"9df1d3de68d663b848101df3c7b58e39":"Cara Kerjanya",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Saya yakin",
"84074732eb8d1b4094404cd750baf949":"Jika tidak ada nilai maka batas perusahaan berlaku",
"138e8bd6102a7eea70a123ff03b1cd79":"Jika tidak ada nilai maka batas tim berlaku",
"e5756af4d7e5ad1236ccfbb858428421":"Jika Anda tidak mendaftar untuk Tradeshift Go menggunakan alamat email ini",
"4f004f75f47dbfecdb6d09990596e530":"Jika email Anda belum terdaftar, kami akan mengirimi Anda email aktivasi yang berisi detail tentang cara melanjutkan.",
"123837f267de602fb05361b3b1197c1e":"Jika {username} cocok dengan akun yang ada, kami akan mengirim email yang berisi detail tentang cara melanjutkan. Jika setelah beberapa menit Anda masih belum dapat melihat email di kotak masuk, periksa folder spam Anda.",
"45efd96cd93525a9c17222217a37d324":"Format gambar atau file PDF didukung",
"fa6ae823f9ab8bf91c246efe2517956c":"Impor pengaturan QuickBooks, NetSuite, atau Xero Anda langsung ke Go. Ekspor transaksi terbaru ke dalam solusi akunting Anda dengan sekali klik.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Nonaktif",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Input berisi karakter yang tidak valid.",
"62c7be09da43f8f96a60ef3967eddc2c":"Memperkenalkan Tradeshift Go yang benar-benar baru.",
"19746bcbed8b8bcb1a9b83efca722077":"Domain email tidak valid",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Undang Orang",
"3809b41b14c55c3fcf54cd813d8e21f5":"Undang Ke Tim",
"fc90a11805961b0d7b5a5545e4d9bc05":"Undang Pengguna",
"6f7f4acde92d267417f5d8a60d32400a":"Undang lebih banyak orang",
"09ecd2ca15bb5c38dc10f653118346cd":"Undang pengguna admin baru",
"3f576ce504f53f972442a63a170c5418":"Undang yang baru {role}",
"0215e052437ceea4e5cd562431b34ced":"Undang orang",
"8aaf2c77df644b7e8524120f66da7d5f":"Nilai faktur",
"1b7dc8290f1205f092cc9133e0cde385":"Tanggal faktur",
"1a6fb210308df8e0135e49e76aaff31e":"Nomor Faktur",
"613da3d0cd586ef0360e0d93eace9508":"Dikeluarkan pada",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Membutuhkan",
"3322b671105c65511fbab1966b7fdcf6":"Bahasa",
"b1d946192795a58aea6a61f4f923acb7":"4 Digit Terakhir",
"b3c6b8db4ef6dcdcb451303e65548816":"4 digit terakhir",
"0382123b9c847000fceff2484c665e39":"Empat digit terakhir",
"c3510b2184a92ff12e07a2d1b9a461e6":"Biarkan karyawan meminta akses ke kartu perusahaan Anda tanpa memaparkan detail kartu Anda, semuanya dilakukan sambil menegakkan anggaran dan melacak setiap pembelian.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Batas dapat ditimpa di tingkat tim dan pemberi persetujuan individual",
"9bf5d56627e500cad9f7ad21c3e7966d":"Batasi untuk kategori pengeluaran tertentu",
"e80a6d4232e52f6bfabee02e17403b59":"Tautkan salah satu kartu sumber ini ke tim ini atau daftarkan yang baru.",
"803570ab9f0fba5ff35ae2749200501f":"Memuat kartu...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Login",
"b246fe8836c906f1b56342378ca88895":"Buat, setujui, dan pantau pembelian dalam tim yang mencerminkan struktur organisasi Anda.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Kelola Pelunasan",
"95d672ab621e531ae1a69798e0e4b56c":"Kelola semua kartu virtual Anda di satu tempat.",
"96f2e88a167f476413b35aa711709e6e":"Kelola pelunasan",
"151d8e6ceec242d482fff60da8311449":"Manajer",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Manajer",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Manajer menerima setiap permintaan untuk ditinjau dan disetujui. Batasan belanja juga bisa disesuaikan.",
"4f449985e83c1f9ca2e8f82b0707a371":"Manajer menerima setiap permintaan untuk ditinjau dan disetujui. Batasan belanja juga bisa disesuaikan.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Manajer",
"27f8111a955975ea6fd04f748d24cb4a":"Batas persetujuan maksimum",
"5b1d9cfb78c55e75b47c62435d9299ca":"Anggota",
"41e307c1874e2e82296b226e9bae4a5f":"Anggota",
"6f9612aa1582373c2c1ba4e144132100":"Anggota",
"590429a0fcc42af85648d0fd70601dcc":"Anggota",
"3f50e62c8488d83ad84f69ebda853897":"Anggota",
"e0fc0acda5b74b93e797756dbbba67bc":"Anggota",
"c7ef9eda38b7eaee1f20031387a8bde7":"Bulan",
"40dd08b507752bef4e3c4b36838317d5":"Bulanan",
"d712b91f7498f212047aad4c6d0f8088":"Terbaru",
"12110d95b3d7a495afa4f28ac190f63e":"Terbaru",
"2f11ce3865f79ee7e5ee64d728082264":"Terbaru",
"eadc93865655cffc6a9863fdda5693e1":"Harus berupa paling sedikit 10 karakter, yang terdiri atas huruf besar dan kecil.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Harus berupa paling sedikit 10 karakter, yang terdiri atas huruf besar dan kecil.",
"50d0a69aa59df5201018771a06ea12e7":"Harus unik",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Harus unik.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Kartu Saya",
"c36602f50d35c2816f0ba8a196b70876":"Permintaan Saya",
"ff6e372aa3eb685a39a701963db35b2f":"Permintaan Saya",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Nama",
"1c7514b561a2591a914225813d30d642":"Nama",
"d621826c1ceade262589af04e655e824":"Kata sandi baru diminta",
"35dc3304b61a9e69dc3a393123658837":"Permintaan baru akan diteruskan secara otomatis selama jangka waktu ini.",
"e788124801b4727d121f60d059a2a4a7":"Berikutnya",
"eca804f1d53dfd984ffd93e2d00fc284":"Tidak ada Pemberi Persetujuan yang ditugaskan, silakan hubungi administrator program Anda",
"abdf7e93f8509399a63108a2b1a25546":"Tidak ada aktivitas yang ditampilkan",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Tidak ada kartu di dompet Anda",
"87127e1e9f5bf922d0a4f948b68697c8":"Tidak ada batas",
"40512efa6b75ef0c7924b1ee3070fca9":"Tidak ada anggota yang ditampilkan",
"dcda493afcb2415d5ca49a17d1af6f50":"Belum ada pelunasan yang diposting.",
"f0efb26e623bb990532e375d5c8875ec":"Tidak tersedia",
"bc0cf602d1978e814070bb552e576a32":"Tidak dipetakan",
"09e126c317429ccb52ad323c32293648":"Jumlah pembayaran",
"75b00b969b9d3b520929f1e652637b70":"Jumlah pembayaran",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Jumlah pembayaran",
"bd44ba180aa356c5755fdedd32adf03c":"Oh, tidak! Terjadi masalah saat membuat permintaan. Harap coba lagi.",
"c396b3728dd7393aa9e5427990c132a7":"Oh tidak! Terjadi masalah saat mengunduh laporan.",
"42c07764b07dea1ee7de7605721c3f5f":"Oh, tidak! Kami tidak dapat membuat tim baru.",
"e3a385878ec94bac12a99490d0a8e85a":"Oh, tidak! Kami tidak dapat membuat akun Anda.",
"17f2dae79677017e2c2e879578c4e09a":"Aduh! Ada yang salah. Harap coba lagi!",
"78c135de545c1663c9768226492a44a3":"Atau gunakan Single Sign-On (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Keluar kantor",
"5d7e45f169e2f6968dc58f43b5267353":"Keluar kantor",
"ee589a20c262527db970671fb2c4f6bc":"Keluar kantor",
"4fd0c753fce49a016b98d988a3895223":"Gambaran Sekilas",
"2789c5d5c9c042858f45aadc4e0de058":"Gambaran Sekilas",
"083b7090b66ebd0307a18499ac7b676e":"Halaman tidak ada",
"a21d205920893cf579a7655a1430fe30":"Kartu induk",
"fd35b7ce938e404fdb65de3bb749c528":"Kartu induk untuk laporan",
"e8c7d2eea7db66ecb44751091a582304":"Kata sandi",
"bb79e316e00fd21138750b6986e44455":"Kata sandi",
"6cae2279088580976d84119d95097cae":"Kata sandi tidak cocok",
"4baec0159d3074f84c0eebcc09ba4cd8":"Kata sandi tidak cocok",
"b676587115243921602742cb8f696a6a":"Jeda Pembuatan Kartu",
"db4a26c871ef0b970b74e1f71763098b":"Jeda kartu virtual baru agar tidak diterbitkan",
"33fc1e6d01b785e3a815c28ef7d56667":"Tertunda",
"6ea62e4811b68df48b467e8d367aac2f":"Permintaan Tertunda ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Orang",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Orang dalam tim dapat memiliki 3 peran berbeda",
"e2d34cb1409b9778d34c4b74ecee1df6":"Harap masukkan bilangan bulat positif atau biarkan kosong.",
"8da247d5d736c87122404eff1e5c0c73":"Harap masukkan batas yang valid.",
"65e154267ac283d963cbc7be832969d9":"Harap masukkan dalam nilai yang valid",
"28b4450d417065a69a395f567967d46c":"Preset dengan batasan belanja yang diminta",
"7bbf151d8493170f206a23c2ecc6acd0":"Kebijakan Privasi",
"51602962f2d1e7515943b4a6c66562ce":"Gambar profil",
"a51a17940c70a3123da4d68bed562c40":"Profil telah disimpan!",
"4d398249d01ab72d697a7e219493c637":"Penyedia",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Pemetaan Penyedia",
"89b1deb2ffe12a13941c362abaeab01b":"Dipublikasikan",
"d8d806d48052159e3ff3b224f3f53586":"Tujuan",
"089917619c78af2efb30be0bb7ce076c":"Aktifkan kembali tim",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Alasan",
"e8484c78fda9f453eeb942ba4945f040":"Tugaskan ulang",
"ff2fbc1172ba26776ad0982624cd2713":"Tanda Terima",
"588e690db177ee0c7a7cadb39cbd5efc":"Segarkan dana setiap pekan, bulan, tahun",
"32955937daca05b2084556f248692baa":"Daftar",
"f15bb755ba24abe6ab106637292175ec":"Tersisa",
"579782461133a8b8cc6bd77798961d82":"Hapus Kartu",
"e475edc165372f8c8deb532aa85d9950":"Hapus Daftar",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Hapus Daftar",
"f9769561315b964d0b3b4887ec208977":"Hapus dari tim",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Hapus tanda terima",
"ee181cef581fc8e8217e66b5762db898":"Dengan menghapus daftar maka menghapusnya agar tidak digunakan dan akan menghapus semua entri yang sudah ada. Jika Anda ingin menghapus daftar untuk sementara dan menyimpan entri, Anda dapat menonaktifkan pengaturan publikasikan. Anda yakin ingin menghapus daftar ini?",
"feea41d0bc1d577a07c8b6e1297abbde":"Menghapus kartu sumber dari tim tidak akan menonaktifkan kartu virtual yang ada.",
"d09483824a2e0d6194a4c3f32311e752":"Dengan menghapus kartu sumber dari tim tidak akan menonaktifkan kartu virtual yang sudah ada. Anda yakin ingin menghapus kartu ini dari tim ini?",
"7ca4b1bf1346e179a29da97f260a93a4":"Ganti nama",
"db195ec9c818b8e92da142374e20d1bc":"Ubah Nama Daftar Pemberian Kode",
"2fd93e229fe9ea754808805d3fef9c13":"Ubah nama daftar pemberian kode",
"aa1e74d32805823f802a11b33c60c38b":"Jenis laporan",
"6ac78d97a8cb7f060e43e596e3e23535":"Permintaan telah dibatalkan.",
"25deccb1980291a0fa9997109c731440":"Minta demo",
"9f2754d87686903b1500ce8e8a55b377":"Minta demo",
"92ac924f72679f897e26f3e615be8c22":"Permintaan telah diteruskan.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Minta info",
"270185007853cd779011ae0e8433f723":"Permintaan telah ditetapkan ulang.",
"e5a059c9ba2691910321339cce989f9d":"Permintaan diserahkan.",
"77c88a231edfa7075114d91d2397e42d":"Judul permintaan",
"cee91b833c4358acf0c554f746557e71":"Permintaan telah ditolak.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Permintaan telah ditolak.",
"9e56abbbf87420eeedd625ce786515c7":"Pemohon",
"b4cb4426e44fee9580af71912a764536":"Permintaan",
"5daf278da19ecbaeb7b96554cb4db10f":"Coba lagi koneksi sekarang",
"31e9501526d2114e4a01fdbf076de097":"Peran",
"6fbceb787429f6f81117a1d5a58b4610":"Peran",
"473b814ca86841ef672ccf9f7dac28d5":"Peran",
"f1e16d5fd643e039d865b6aec7c986f9":"Peran",
"ec78345a5975bc0f8efaee5256b43c2c":"Simpan",
"2cecea57b47d8f4a45c706287ff7d64e":"Simpan Perubahan",
"15774be5c338a39169917c7d32781dd0":"Simpan dan lanjutkan",
"844095b4b8f580737b8717672501048e":"Katakan sesuatu...",
"7e66f676c2e7b04514aca3a0935530f0":"Cari berdasarkan informasi pemberian kode",
"5293ce9335ec2d176a6e838bf76404c6":"Cari berdasarkan deskripsi",
"efab3b0c95f263cdd0a8466329a387eb":"Cari berdasarkan deskripsi",
"d227b38dcbc4548c092b0836a7101506":"Cari berdasarkan nama merchant atau id transaksi",
"3629383abf1d5f85efd759f718159b40":"Cari berdasarkan nama",
"a034fcd6719228b28a6fc22d3a46ea8a":"Cari berdasarkan nama tim",
"8ac7e0d63f343b548482d5f367f5b3d9":"Cari berdasarkan nama tim",
"858323eb52cb45446597ee46330a0540":"Cari berdasarkan pemilik kartu virtual",
"909a4414ea573218bff2d533eaab46ea":"Pilih Tanggal Selesai",
"114b206ca1ff1c0bc52abef342059b2a":"Pilih Opsi Tim",
"81a02e424615fb41a20b8d0f13441c88":"Pilih Tim",
"b135b36495618e6692e7fbaf0a0aa83e":"Pilih bulan",
"677e90855e164c00a28b5c4763ac67e0":"Pilih peran",
"d01570f3774738395b8ba3c64ce54c76":"Pilih negara",
"7dee3f06c70626881efb0cd6af6b63e1":"Pilih pengguna untuk meneruskan permintaan",
"4c7bb810835a1540ddc1f6501da5a51b":"Pilih tahun",
"3ab198d2678af2b5b32180e4b39602ba":"Pilih pemberi persetujuan",
"f502ba79f2bd010c5e77891812400958":"Pilih kartu",
"936027182d446e27ba209ea921ef7502":"Pilih rentang tanggal kedaluwarsa default",
"b22c893254ccf3bd34faaee511cf7ca8":"Pilih durasi",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Pilih dari preset daftar pemberian kode atau buat daftar baru Anda sendiri.",
"42cce5fa376b45bf290f86c684d69d96":"Pilih bidang penyedia",
"b761773d46699b60e8d6874b85593374":"Pilih tim",
"3a9396756942ede98d0cb6afc1032b15":"Pilih pemberi persetujuan mereka",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Diri",
"03e9d2c128af288126d94f631c5396be":"Kirim Petunjuka",
"299a7e961804e2d649f06b5dc4362c29":"Kirim undangan",
"155e7b3f7db7bf10b03b5f41de54eae1":"Kirim pesan",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Atur tanggal kedaluwarsa",
"f89ec96a4b5bb639488ab742efa76a6a":"Atur kata sandi baru",
"7d5377ad70d57728a45d3c0639de5544":"Pengaturan telah disimpan",
"14313f11dee735f2144953e720776c28":"Nilai pelunasan dapat berbeda jika tip atau biaya lain diterapkan untuk pembelian",
"199000fe0cda0adaaa9b864aa7447cb9":"Pelunasan",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Pelunasan",
"6e6ac6ff3bd722e37874fb1ea7115818":"Siapkan kata sandi Anda",
"9b4ecf052980e80bf5c65a415d470a43":"Bagikan dan kendalikan akses ke kartu perusahaan Anda",
"ba746d78772d6be26cc508ba989becb0":"Harus kurang dari atau sama dengan {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Tampilkan transaksi tanpa tanda terima",
"7d474b60aeb7cc25b519e806fca71e12":"Masuk dengan yang ada \n\n Kredensial Tradeshift",
"16080cd4e8319ef3e7de21b6c19d1437":"Ada yang salah",
"8f358a370224baf849f9652810c68db2":"Ada yang salah",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Ada yang salah",
"99af87c475a4d40ef732deed96f11ed2":"Ada yang salah. Harap coba lagi.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Maaf! Kami sedang mengalami masalah saat menghubungkan - mencoba lagi dalam {retryConnectionPeriod} detik.",
"84debc8e41646cd49ac2b14708ca6d73":"Maaf, terjadi masalah saat menghapus entri pemberian kode. Harap coba lagi.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Maaf, terjadi masalah saat menyimpan entri pemberian kode. Harap coba lagi.",
"689d3418ee8f996ad25e152e80de63fe":"Maaf, kami tidak dapat mengeluarkan Anda saat ini.",
"b48efd4521ecd6428987d24636a4349f":"Urut",
"776a95003d3ec1dd58af69aedbc08579":"Urut",
"1b783238028c0ae085ca69ed823f4d1a":"Urut berdasarkan",
"be70d19bf13c935d653b2c4fe7d36224":"Nama Kartu Sumber",
"564f8c942e7d1bdda5251545612fa348":"Nama kartu sumber",
"aa284602c746aad4a6de6366c24221b8":"Kartu sumber",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Kartu sumber",
"389d471e79a43440b3278b0fc7187d9e":"Kartu sumber",
"106342f689b57145817b00d6fe383f91":"Tanggal Selesai Spesifik",
"2a5e19387ff300509876e3bae99b7ab9":"Percepat akunting Anda",
"ca34d586bbdb7f368f1009305bccbe76":"Dasbor Belanja",
"4583120998b44c0a5982cf85dd938d2f":"Belanja Seiring Waktu",
"244d724806247953233a0bbfcbf8da4c":"Batas belanja",
"d39b83dd151c8df6370460a458294ccf":"Dibelanjakan",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Nilai yang Dibelanjakan",
"5de2859528e030ac1f2af115ebdf209c":"Dibelanjakan { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Mulai penerusan pada",
"1f1fe111548158ec842761eca7425ff3":"Alamat jalan",
"31a0a748b1097a9e0aa95a811802bdd0":"Rekonsiliasi bebas stres",
"111b782f3f0794c2294aefb6693b2c50":"Serahkan",
"013e2555920b51ad2a3d2c3dc482c471":"Serahkan permintaan",
"1270cd8eb69ad64cc71e5de979423b3b":"Berlangganan Berakhir",
"2802336ad3d8ff658257b88a93f4e50a":"Berlangganan Berakhir",
"b28dd68454d7acbefafeefb42981fe16":"Frekuensi Berlangganan",
"907793bc80cf94d560e5f1a0250fdaee":"Frekuensi Berlangganan",
"29229e153308e4747edef1144de35a55":"Berhasil!",
"503eb46b80f374f39266e7a9aea8e63e":"Berhasil!",
"4f20e29cadadb9fbc7455bd4d80de567":"Berhasil!",
"601d04f10d558f436c78c10e90b44175":"Dukungan",
"62558c185ea2c06c5472d1cd534f6e56":"Sinkronisasi dengan solusi akunting Anda",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Nilai pajak",
"0cb5d74f7a56fca30930616feb6e2f47":"Tim",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Tim",
"bf23b2fd889e3b12e0f0acf99f913eca":"Kartu Tim",
"b90a650376782a9e43ed6be6135bd6f2":"Dasbor Tim",
"6adbec395fe1c4bf776f9fe5fa021052":"Dasbor Tim",
"6f2695eeb68501dbcfddbb4d265b846b":"Manajer Tim",
"3b779ade9c960ac95a670db0cc12e56b":"Anggota Tim",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Pengaturan Tim",
"dc5235e1b88320d3ba7501e4c30985a3":"Pengaturan Tim",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Pengaturan Tim",
"a2eb92bfd7502f47b3cd170bc68ac638":"Pengaturan Tim",
"f1ec84f051f19f0d0e301a709b13dc45":"Belanja Tim",
"fbacde3a262ea8696e30135e8f987a44":"Pengalih Tim",
"74b9578ea5a620f5fef102188e42c02f":"Pengalih Tim",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Tindakan tim",
"3aa978fa7ef886621285b93257d428c3":"Batas persetujuan tim",
"a41f9df131c221a99926de7e2138653a":"Informasi tim",
"941c7da8f256f91ba3819f991315a100":"Nama tim",
"c5d04e10cc2f0b53c2bda893eae09178":"Nama tim",
"504a8ba642ba37c9a743d0fabb2bd371":"Nama tim",
"e768a8e1e3d6a6bf470587dad75423fe":"Pemilik tim",
"e2d824067f8bd871756688c58c382196":"Pemilik tim",
"dca232ccc849c5ff5611255eb82baf53":"Pemilik tim",
"3b61bd586225790cf132591383d15448":"Pengalih tim",
"bc05b40509f0271c12cfaaef8e5319b1":"Tim",
"39e73773d07a800673ec6600422952d6":"Tim",
"daabb564bb3dcfb41de69492f854d573":"Tim",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Batas tim dan invidual tidak akan pernah melebihi batas perusahaan",
"4b4fe189a48d8eb06d836afa8fb6317a":"Tim telah diperbarui.",
"2a17f9a8c610db29fec63fd530c29fdc":"Tim yang menggunakan kartu",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Tim yang melihat ini",
"317868f3804ba4ed5d99dd9919c798ba":"Syarat Layanan",
"e8c9a9847db2ccd97524aa06f1af9d82":"Syarat Layanan dan Kebijakan Privasi",
"38852078519c5c46715d8a034fc4901b":"Syarat Layanan dan Kebijakan Privasi.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Terima kasih atas minat Anda pada Tradeshift Go. Dukungan untuk kartu kredit {cardLabel} akan segera hadir. Anggota tim keberhasilan pelanggan kami akan memberi tahu Anda jika sudah tersedia.",
"f7047b35a069d0790a0a348a29c846a2":"Terima kasih telah bergabung dengan tim Anda di {companyName} untuk melakukan pembelian bisnis denga menggunakan kartu virtual Go.",
"133b0a70b1a956889164621380c378f9":"Tindakan yang sedang Anda coba lakukan tidak dapat diselesaikan. Dalam sejumlah kasus, mencoba lagi dapat membantu.",
"18f5a1872e156f1aee84ae321780d952":"Nilai yang diminta melebihi batas permintaan perusahaan sebesar ${requestLimit}. Harap coba lagi dengan nilai permintaan yang lebih rendah.",
"867e3d4d3c78990c6852fc5348706ab2":"Nilai yang diminta melebihi batas permintaan penyedia sebesar ${providerLimit}. Silakan coba lagi dengan nilai permintaan yang lebih rendah.",
"570f5f4658b03dda7a910f56c86a3bd3":"Kartu untuk permintaan ini telah dibatalkan.",
"3075c56105863546e3368ffead7a146b":"Kartu telah dibatalkan.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Daftar pemberian kode yang sedang Anda coba batalkan penerbitannya ditautkan ke sebuah integrasi. Jika Anda yakin ingin menghapus ini dari integrasi, silakan hubungi bagian dukungan yang dapat menyelesaikan tindakan ini.",
"756ade8d71ec487a4b48d7c4277ea239":"Daftar pemberian kode yang sedang Anda coba batalkan penerbitannya ditautkan ke sebuah integrasi. Anda harus menghapus pemetaan terlebih dahulu sebelum membatalkan penerbitan daftar pemberian kode ini.",
"4f40335f7c018986c95458183bc0c136":"Tautan untuk mengaktifkan akun Anda tidak valid atau telah kedaluwarsa. Harap tanyakan kepada dengan orang yang mengundang Anda untuk mendapatkan undangan lain.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Tautan untuk mengatur ulang kata sandi Anda tidak valid atau telah kedaluwarsa. Harap minta pengaturan ulang kata sandi lagi untuk mendapatkan tautan baru.",
"ded30f0e288c12125ca1a77f8d8fd600":"Alasan akan terlihat oleh pemberi persetujuan dan pemohon",
"f294897b38fc23dd8f5de52b54ceae22":"Permintaan akan ditolak dan pemohon akan diberi tahu. Anda ingin melanjutkan?",
"e8c7200dc985df0a94a9d996968b4cc0":"Permintaan akan ditolak dan pemohon akan diberi tahu. Anda ingin melanjutkan?",
"5f3d196a2ade3f97a2a48b773fec5861":"Permintaan akan ditolak dan pemohon akan diberi tahu. Anda ingin melanjutkan?",
"b04c191cd6b4f27669a4f390311f156c":"Tidak ada pemilik tim atau manajer yang akan dipilih.",
"e25045557efbad7b52997b94a1fe6817":"Tidak ada {type} untuk periode waktu ini",
"7260690577c795e999acae63bb7c51ae":"Terjadi masalah saat menyimpan pengaturan tim. Harap coba lagi.",
"f2103024f2a9c6c0335a71c60567d6c9":"Terjadi kesalahan saat memuat dompet Anda.",
"9776cb7eab9fa0d318a8144cd17de745":"Terjadi kesalahan saat memuat dompet Anda.",
"77d28a14bc900be4360b570a82eb75a9":"Tidak ada data untuk ditampilkan",
"2b6feba67722337f88c1443909fe446f":"Terjadi masalah saat menambahkan pemberi persetujuan!",
"24b7212afb97c5d23faafa2ca4559095":"Terjadi masalah saat menambahkan kartu kredit. Harap coba lagi.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Terjadi masalah saat menambahkan kartu sumber ke tim. Harap coba lagi",
"5a8e1b922d1cee746ac6794599b10709":"Terjadi masalah saat membatalkan kartu ini. Harap coba lagi.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Terjadi masalah saat membatalkan kartu ini. Harap coba lagi.",
"22361c5d4df61ad7ae9792a3baf5729f":"Terjadi masalah saat membuat daftar Anda. Harap coba lagi",
"098755bfddeaf0428c7feb6b69af9b6f":"Terjadi masalah saat menolak permintaan. Harap coba lagi.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Terjadi masalah saat menolak permintaan. Harap coba lagi.",
"7d461b6b7b54dbfa9c83243217cd1053":"Terjadi masalah saat menghapus entri pemberian kode. Harap coba lagi.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Terjadi masalah saat menghapus daftar Anda. Harap coba lagi.",
"beb43d7800cfff47e08cd61842d953bd":"Terjadi masalah saat meneruskan permintaan. Harap coba lagi.",
"f7775350f35b8365fb9803232d9b792c":"Terjadi masalah saat memperoleh detail kartu. Harap coba lagi.",
"95abaa922c6c1648ca9c39ac4776896f":"Terjadi masalah saat memperoleh detail pengguna. Harap coba lagi.",
"9d7e4ead60137041ae8a845aa6cf9979":"Terjadi masalah saat memuat profil pengguna. Harap coba lagi.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Terjadi masalah saat memuat pengguna tim. Harap coba lagi.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Terjadi masalah saat memuat pengaturan Anda. Harap coba lagi.",
"b2f48028c400befad5f1d57f769ef7cd":"Terjadi masalah saat menetapkan kembali permintaan. Harap coba lagi.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Terjadi masalah saat menghapus kartu sumber dari tim. Harap coba lagi.",
"19dfcd761006496c1d20351c04643e45":"Terjadi masalah saat menghapus domain ini, harap coba lagi",
"149651b5784481c68aaf4ac27bb1f907":"Terjadi masalah saat menyimpan daftar pemberian kode. Harap coba lagi.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Terjadi masalah saat menyimpan profil. Harap coba lagi.",
"749ddc235744b6b371668ec0bb049176":"Terjadi masalah saat menyimpan pengguna. Harap coba lagi.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Terjadi masalah saat menyimpan domain ini, harap coba lagi",
"04cf86909808f430b8b1904fb422e0d3":"Terjadi masalah saat menyimpan pengaturan kantor Anda. Harap coba lagi.",
"2eaf6ee97d984598c95e0494d72aa492":"Terjadi masalah saat mengirim permintaan pembelian.",
"148f847d3beccaa67fcd5096ae3602d3":"Terjadi masalah saat memperbarui nama kartu. Harap coba lagi.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Terjadi masalah saat memperbarui nama kartu. Harap coba lagi.",
"de8cc976f7af9edf189904239e6240da":"Terjadi masalah saat memperbarui tim. Harap coba lagi.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Terjadi kesalahan saat menambahkan admin, harap coba lagi.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Terjadi kesalahan saat menonaktifkan tim Anda. Silakan Coba lagi.",
"3f686a97a1b9fcdd73a40d2005693105":"Terjadi kesalahan, harap coba lagi.",
"2f38263789163cf8ee30c8ae88852c82":"Terjadi kesalahan saat memublikasikan daftar Anda.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Terjadi kesalahan saat menghapus admin, harap coba lagi.",
"007a647254e1f85b6c1e789004e05df5":"Terdapat kesalahan saat menyimpan pengaturan.",
"c3072d080b587d70f85d68ec089f028f":"Terdapat kesalahan saat menyimpan pengaturan untuk tim Anda. Harap coba lagi.",
"608cd0e109c3e51b0425df8921e4181d":"Terdapat kesalahan saat mengatur pemetaan penyedia. Harap coba lagi.",
"177cefc68dd318ea75673326e77d8206":"Terjadi kesalahan saat batal memublikasikan daftar Anda.",
"255d22dc4f0e338a4cf53d5b7256e695":"Terjadi masalah saat mengambil nama orang yang Anda ajukan permintaan persetujuan.",
"39ac4ec73fed8a981ad1857d989edfcb":"Nilai ini melebihi batas persetujuan perusahaan.",
"494ce3f598cbd97afe99774b5975a4bc":"Nilai ini melebihi batas persetujuan tim.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Pengaturan ini hanya akan memengaruhi kartu baru",
"881b7596cf985d9c246b05fcab930632":"Kartu sumber ini telah ditambahkan ke tim.",
"a1b1aa354d06e7815b78c6abb7448e84":"Kartu sumber ini telah dihapus dari tim.",
"b348b24fc4839ee15ed0aa424689bae3":"Tim ini harus memiliki admin atau manajer sebelum Anda dapat mengundang anggota.",
"188b9ed447a43916f9e323623ea3a993":"Untuk menerima undangan",
"30286e57da108ee7dbc0f8fbb62d4fff":"Total yang dibelanjakan ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Lacak pengeluaran lintas tim, proyek, dan langganan.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Lacak aktivitas kartu aktif tim Anda, jeda pengeluaran, dan nonaktifkan kartu yang tidak lagi dibutuhkan oleh tim.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Pengaturan ulang kata sandi Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Coba lagi",
"80196bc616d4a44a4997a2ea127f7b83":"Coba lagi",
"f96bbe81618b5f4a1807989eb4f69e24":"Coba lagi",
"d498d7d261b7ca56e07218d1a0dbdd49":"Oh tidak! Terjadi kesalahan. Harap coba lagi.",
"ce2e475291003852c71b01959698c496":"Tidak dapat memperoleh pesan berdasarkan permintaan {title}, Coba lagi",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Batal arsipkan",
"d2e50be5287fd8fb436d0acbeb811864":"Pengenal unik",
"0e2fbce967f4a209215b6f48f8eddac3":"Batalkan Jeda Pembuatan Kartu",
"f2315dec5dd07802b77af3a3a5414a7d":"Hingga 4 tahun ke depan.",
"d2f30a2f0c955271e9af123dceca501a":"Hingga {label} ke depan.",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Perbarui kartu sumber",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Perbarui kartu sumber",
"c8f6bc61a8982158e408194e48a274d9":"Pengunggahan gagal, coba lagi.",
"8a86a60cb836bdb5225cad20c5151078":"Unggah tanda terima",
"e448a1ce42de0cf526200331b4ad8b23":"Pengguna",
"75edcc8fc27f41c99e28b48ff250c7f8":"Detail Pengguna",
"d1651c2afdb6c92621adbb707e4753d0":"Pengguna telah disimpan.",
"5fa55883b7413526b441e999cb8089b3":"Nama pengguna",
"7a892b81fe2aae295f1b2d38bb67360f":"Nama pengguna",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Pengguna dengan kartu yang aktif tidak dapat dihapus. Harap batalkan kartu untuk menghapus pengguna ini.",
"d40fa5935f1614925cf46259c886814a":"Lihat Aktivitas Kartu",
"625419857efc7e72d162c9f8204109df":"Lihat Kartu di Dompet",
"85a5e817826415651ed4991a76bb5385":"Lihat Percakapan",
"09fc124dc9b8b14d6227a92bb0a4566f":"Lihat percakapan",
"d26cfc6d8f420f259f434b79593e9eb4":"Lihat tanda terima",
"c7d0394c3206a3b74afbeabc41cc639c":"Lihat tanda terima",
"01650aa52c5e5a22d3f38afd2c7f9411":"Lihat tanda terima",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Lihat tanda terima",
"b2d141897f73fc2a198ee1a7a438f89b":"Lihat permintaan",
"02fa2f56ef72845a9dfeabea22948061":"Lihat detail tim",
"2d9c55650b17dd605a1388f46c81ba38":"Kartu virtual",
"d0ec076e4600e854588f8da265208c0b":"Opsi kartu virtual",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Dompet",
"5279a2220e4cfb0d8b8b357311251cbe":"Dompet",
"b1746b5806659e3189f837d2dd54e08c":"Dompet",
"ff306d5b34ee993f9618c1f3c0209a39":"Kami tidak dapat mengarsipkan permintaan untuk saat ini. Harap coba lagi nanti.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Kami tidak dapat membatalkan permintaan untuk saat ini. Harap coba lagi nanti.",
"a813d44f6bb26c5da09671e532abe022":"Kami tidak dapat membatalkan pengarsipan permintaan untuk saat ini. Harap coba lagi nanti.",
"d4c7310eafc0d0da89f20596a22a3abe":"Kami belum dapat membuat permintaan untuk saat ini. Harap coba lagi!",
"dca679c10accb1db481a57a0e1ba08e2":"Kami belum dapat membuat permintaan untuk saat ini. Harap coba lagi!",
"db5584a2ef29b04959bce7f93db54c56":"Kami tidak dapat beralih tim untuk saat ini. Harap coba lagi nanti.",
"f2af2c261fabddb01766f4f974b85a62":"Mingguan",
"e181ed0723a00ca7558b9d5d5258732b":"Selamat datang di Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Kami akan selalu berkomunikasi!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Kartu kredit mana yang digunakan perusahaan Anda?",
"968d94729053e166a76ea577ce3022e2":"Email kantor",
"588a075e8694ff5cc86815697e1586bc":"Kerjakan seperti yang dilakukan oleh tim bisnis Anda.",
"182ae1eb5226e0646b4033883cc0900c":"Tahun",
"fe88fe7094c4ced427874b3d22326c8d":"Ya",
"9a2d4e3b22ad0567165eb0f661f952ad":"Ya, Batalkan Kartu",
"96b88e530375237cbc08138d9fb4109c":"Ya, Hapus Daftar Ini",
"fd3780f468c531f2bc1b47b8748b5a66":"Anda dapat membuat kode akunting dalam pengaturan perusahaan.",
"ab3ac6f945d9f756763d49257fd924dc":"Anda dapat mengelola pemberian kode di pengaturan perusahaan.",
"c919dae93fe780061629c255eb358140":"Anda tidak dapat menghapus satu-satunya kartu sumber yang tertaut ke tim ini. Tambahkan kartu lain untuk menghapus yang ini.",
"11de97e873b66d91a77db7a05da32202":"Anda dapat mempromosikan salah satu pemilik tim yang tercantum di bawah ini untuk menjadi admin perusahaan atau mengundang pengguna baru.",
"0ab790f08b2780be001b79d2ccd75c4e":"Anda tidak memiliki kode akunting yang dibuat untuk akun ini.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Anda tidak memiliki daftar pemberian kode yang dibuat.",
"44e470218124a50c117870467530752e":"Anda telah memasukkan alamat email atau kata sandi yang salah.",
"4b687d0730822fdbeea27c174e47f227":"Anda tidak memiliki persetujuan yang disetujui.",
"020f03c17b79af66f335b6af868c66c0":"Anda tidak memiliki persetujuan yang dibatalkan.",
"def0b76f03e7c622341a7d8e08aaaae7":"Anda tidak memiliki persetujuan yang ditolak.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Anda tidak memiliki persetujuan yang tertunda.",
"7c034d6104f80a523b90bc607fc06608":"Anda tidak memiliki kartu virtual di dompet Anda. Buat permintaan",
"0aed9198d61d161468d541dbbc9f477a":"Anda kehabisan penerusan kantor ke {oooForwardToFullName} yang dijadwalkan hingga {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Anda telah menerima kartu virtual baru:",
"04eeaf814767c30aa41577f3b11f916a":"Anda baru-baru ini telah meminta pengaturan ulang kata sandi akun Go Anda. Untuk menyelesaikan prosesnya:",
"09ddf249810b9af7d3e1f4e537da500d":"Anda telah mencoba mengakses halaman yang tidak ada. Harap pastikan Anda memiliki tautan yang benar.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Anda telah diundang untuk bergabung dengan tim Anda di",
"02c6f3a7cf31a87f4f39f992509f8c44":"Anda telah ditambahkan sebagai admin untuk perusahaan Anda",
"cf121a14590f109dafcae138265e353b":"Anda telah ditambahkan ke Tim baru!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Anda telah ditambahkan ke tim!",
"d3cef66a026f776903b342d450e5a832":"Permintaan kartu Go Anda telah ditolak",
"003f87c21a29220e661fc302b885fd5a":"Akun Anda telah dinonaktifkan, harap hubungi administrator perusahaan atau tim Anda.",
"a52b1f0da15dce68007f524e48238143":"Bank Anda mengalami kesalahan saat menambahkan kartu ini. Harap hubungi bank Anda untuk menyelesaikan masalah tersebut dan coba lagi.",
"4d883b173167297463aeac86957c26fe":"Alamat email Anda telah digunakan untuk membuat akun di Tradeshift Go. Untuk memverifikasi alamat email Anda dan menyelesaikan pendaftaran",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Pengaturan di luar kantor Anda telah disimpan.",
"542f08554110a12de0c23afd78f38e0e":"Kata sandi Anda telah diatur ulang. Harap masuk.",
"49b2dcbe5d461f35c37986962b5bd07a":"Permintaan Anda telah ditolak",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Anda sudah ada dalam daftar!",
"32f87c5ddeaee35b9178785ef4eb9313":"Kode pos",
"c6974a3d5311374c697625f2a3234bce":"tanggal",
"f86980f5ef00b645a6e4a0b59738a954":"seseorang yang gagal kami cari",
"7066f854917b39bafe0bbecbb8bd6377":"menyetujui permintaan",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"untuk",
"49b171230ab9bcdd8b631c70cf397efb":"untuk <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"untuk perusahaan Anda",
"35ad86f0b035d8156390670d60801e7f":"untuk {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"untuk {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"dari",
"c1407aa5424f339dedf67bcac597cf71":"telah menetapkan permintaan persetujuan mereka untuk diteruskan kepada Anda selama",
"67a948b8af3284a401ebefcb1b4723ab":"sedang menunggu persetujuan",
"9ca64449d64b98fef7859e22bc23c4dc":"membuat permintaan",
"ac850ab84428e9eb6a57e5d813de5e45":"mengelola tim",
"2168839a1f4c73ef5c89c9e930148e7d":"harap abaikan email ini. Tidak diperlukan tindakan",
"85431c325faab98e02daa60151465144":"telah mengirimi Anda undangan ini",
"81c504fd54119e7e580eb05dedef46d0":"telah mengirimi Anda undangan ini",
"d74545060b38445ef4cc49fbb8d79876":"telah mengirimi Anda undangan ini.",
"2d96c53ee7ee0a54aadad19bfce0697d":"terjadi kesalahan dalam mendapatkan sumber kartu Anda",
"b2fda295788d579fffd3f43b7737fd63":"untuk mendaftarkan kartu perusahaan Anda dengan Go, untuk memberikan karyawan Anda akses kartu yang sederhana & aman",
"d35bd5c5d74af765d45cef3c4e78f52a":"untuk melakukan pembelian bisnis dengan menggunakan kartu virtual Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"untuk melakukan pembelian bisnis dengan menggunakan kartu virtual Go",
"9546989e681a7e25fbdfe7631e9ebc03":"untuk melakukan pembelian bisnis dengan menggunakan kartu virtual Go.",
"6174e0a3d26409dd6e410052e2805660":"untuk mulai menggunakan kartu Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"nonaktifkan keluar kantor",
"fd2c1099fc9f3831d7f24195aaa11269":"Anda@perusahaan.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} bertanggung jawab untuk menyetujui permintaan dari anggota tim lain. Harap tetapkan pemberi persetujuan baru kepada anggota yang terkena dampak untuk melanjutkan.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} telah berhasil mengundang${this.teamId ? '.' : 'ke tim.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} telah dihapus",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} telah membatalkan permintaan untuk",
"9567a2d4cacd58fb4f096132d26c1660":"{username} bertanggung jawab untuk menyetujui permintaan dari anggota tim lain. Harap tetapkan pemberi persetujuan baru kepada anggota yang terkena dampak untuk melanjutkan.",
};
