const _ = require('lodash');
const moment = require('moment');

const ActivityFeedBoxComponent = {
	bindings: {
		downloadEnabled: '=',
		isCompanyAdmin: '<',
		searchQuery: '<',
		teamId: '<',
		user: '<',
	},
	template: `
	<div
			data-ts="Board"
			id="approvals-board"
			class="approvals-board">
		<div class="scrolling-rows">
			<div
					data-ts="Panel"
					class="ts-panel"
					ng-if="!$ctrl.selectedTab.noItemsToShow"
					ng-repeat="row in $ctrl.selectedTab">
				<div class="panel-image-containter">
					<a
							class="user-circle"
							ng-if="row.circle.displayCircleText">
						<span
								class="label"
								ng-bind="row.circle.circleText || 'NA'"></span>
					</a>
					<user-image
							class="avatar-current"
							ng-if="row.circle.displayCircleImage"
							size="47"
							user="row.user"></user-image>
				</div>

				<div class="panel-text-container">
					<span class="approval-details">
						<span class="title">
							<span class="requester-name" ng-bind="row.requesterName"></span>
							<span class="regular-font" ng-bind="$ctrl.requestedFromText"></span>
							<span class="assignee-name" ng-bind="row.assigneeName"></span>
							<span ng-bind="row.teamName"></span>
						</span></br>
						<span class="amount-type-purpose">
							<span class="approval-amount" ng-bind="row.approvalAmount"></span>
							<span ng-bind="$ctrl.dotDelimiter"></span>
							<span ng-bind="row.cardType"></span>
							<span ng-bind="$ctrl.dotDelimiter"></span>
							<span class="sub-title-timestamp">
								<span ng-bind="row.creationElapsedTime"></span>
								<div class="tooltip" ng-bind="row.creationDate"></div>
							</span>
							<span ng-bind="$ctrl.dotDelimiter"></span>
							<span ng-bind="row.requestTitle"></span>
						</span>
					</span>
				</div>

				<div class="panel-button-container">
					<button
							data-ts="Button"
							ng-class="button.classes"
							ng-click="button.onclick()"
							ng-if="button.element === 'button'"
							ng-repeat="button in row.buttons"
							title="{{button.text}}"
							type="button">
						<span ng-bind="button.text"></span>
					</button>
					<i
							ng-class="button.classes"
							ng-click="button.onclick()"
							ng-if="button.element === 'i'"
							ng-repeat="button in row.buttons"></i>
				</div>
			</div>
			<div
					class="text-center no-items empty-state"
					ng-if="$ctrl.selectedTab.noItemsToShow">
				<div class="empty-state-illustration"></div>
				<p ng-bind="$ctrl.selectedTab.noItemsToShowText"></p>
			</div>
		</div>
	</div>
	<go-forward-request-aside-v2
			approval="$ctrl.componentAsides.forwardRequest.approval"
			is-open="$ctrl.componentAsides.forwardRequest.isOpen"
			on-close="$ctrl.onAsideClosed('forwardRequest')"
			on-success="$ctrl.onRequestForwarded($event)"></go-forward-request-aside-v2>
	<div
			ng-include="'asides/asides'"
			ng-init="asides = $ctrl.asides"></div>
	<go-download-report-aside
			is-company-admin="$ctrl.isCompanyAdmin"
			is-open="$ctrl.componentAsides.downloadReport.isOpen"
			on-close="$ctrl.onAsideClosed('downloadReport')"
			default-to-settlements="false"></go-download-report-aside>
	<go-approver-options-aside
			approval="$ctrl.componentAsides.approverOptions.approval"
			approve-request="$ctrl.openCreateCardAside($event)"
			decline-request="$ctrl.openConfirmDeclineAside($event)"
			forward-request="$ctrl.openForwardRequestAside($event)"
			is-open="$ctrl.componentAsides.approverOptions.isOpen"
			on-close="$ctrl.onAsideClosed('approverOptions')"
			view-conversation="$ctrl.viewConversation($event)"></go-approver-options-aside>
	`,
	controller: /* @ngInject */ function (
		$filter,
		$scope,
		$state,
		AsideService,
		ApprovalLists,
		ConfigService, // NOSONAR
		constants,
		LexicoService,
		NameService,
		RequestService,
		RoleServiceV2, // NOSONAR
		LoadingService,
	) {
		const TASK_OUTCOME_REJECTED = ConfigService.get('TASK_OUTCOMES').rejected;

		this.lexico = LexicoService.getLexico();

		this.requestedFromText = this.lexico.trc('Name of requester', 'requested from');
		this.dotDelimiter = ' · ';

		const getApprovalRequests = label => {
			const filteredApprovals = {
				pending: this.approvalItems.filter(a => a.isPending()),
				approved: this.approvalItems.filter(a => a.isApproved()),
				declined: this.approvalItems.filter(a => a.isRejected()),
			};

			return filteredApprovals[label]
				.map(approval => {
					let buttons = [];
					const req = approval._approval.request;
					const buttonsVariations = [
						{
							element: 'i',
							classes: 'chat-icon approval-icon',
							onclick: () => {
								LoadingService.startLoading();
								try {
									const requestId = approval.getRequestId();
									window.localStorage.setItem('conversationId', requestId);
								} catch {
									// ignore
								}
								$state.go('main.approval', { approvalId: approval._approval.id });
							},
							text: '',
						},
						{
							element: 'button',
							classes: 'ts-primary ts-micro',
							onclick: () => this.openCreateCardAside(approval),
							text: this.lexico.trc('Button', 'Approve'),
						},
						{
							element: 'i',
							classes: 'ts-icon-other',
							onclick: () => this.openApproverOptionsAside(approval),
							text: '',
						},
					];

					switch (label) {
						case 'approved':
							buttons = [buttonsVariations[0]];
							break;
						case 'pending':
							buttons = [buttonsVariations[1], buttonsVariations[2]];
							break;
						default:
							buttons = [];
					}

					return {
						requesterName: NameService.fullName(req.requester),
						assigneeName: _.escape(req.assignee),
						teamName: req.teamName ? ` (${_.escape(req.teamName)})` : 'N/A',
						approvalAmount: $filter('currency')(parseFloat(req.amount), req.currency),
						cardType: _.capitalize(req.cardType.replace(/-/g, ' ')),
						creationElapsedTime: moment(req.lastEdit).fromNow(),
						creationDate: moment(req.lastEdit).format('MMM Do YYYY, h:mm a'),
						requestTitle: _.escape(req.title),
						state: req.state,
						requestId: req.requestId,
						circle: {
							displayCircleImage: !!req.requester.avatarUrl,
							displayCircleText: !req.requester.avatarUrl,
							circleText: req.requester.firstName[0] + req.requester.lastName[0],
						},
						user: req.requester,
						buttons,
						name: req.assignee,
						description: _.escape(req.title),
					};
				})
				.filter(a => {
					return (
						this.searchQuery === '' ||
						a.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1 ||
						a.description.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1
					);
				});
		};

		const getNoRowsMessage = () => {
			const noActivityMessage = this.lexico.trc('Component tab title', 'No activity to show');
			return { noItemsToShow: true, noItemsToShowText: noActivityMessage };
		};

		this.selectedTab = getNoRowsMessage('approved');

		this.renderTab = tabLabel => {
			this.tabLabel = tabLabel;
			this.updateTabRows();
		};

		this.updateTabRows = () => {
			const tabRows = getApprovalRequests(this.tabLabel);
			// If there are approvals in tabRows then we filter them by request id so that they are unique.
			// This is needed for https://tradeshift.atlassian.net/browse/ZTN-11435
			this.selectedTab = tabRows.length ? _.uniqBy(tabRows, 'requestId') : getNoRowsMessage(this.tabLabel);
			$scope.$evalAsync();
		};

		this.retrieveRequestsAndUpdateTab = async () => {
			await this.retrieveApprovalRequests();
			this.updateTabRows();
		};

		this.retrieveApprovalRequests = async () => {
			this.approvalItems = await ApprovalLists.getCurrentCombinedLists({ teamId: this.teamId });
		};

		const init = async () => {
			this.asides = AsideService.get(['confirmAction', 'createCardV2']);

			this.approvalItems = await ApprovalLists.getCurrentCombinedLists({ teamId: this.teamId });

			if (this.user) {
				const { id } = RoleServiceV2.getCurrentRoleForUser(this.user);
				if (id === 'manager') {
					this.approvalItems = this.approvalItems.filter(req => req.isUserAssigned(this.user.userId));
				}
			}

			this.tabs = [
				{
					label: this.lexico.trc('Component tab title', 'Approved'),
					onselect: () => this.renderTab('approved'),
				},
				{
					label: this.lexico.trc('Component tab title', 'Pending'),
					onselect: () => this.renderTab('pending'),
				},
				{
					label: this.lexico.trc('Component tab title', 'Declined'),
					onselect: () => this.renderTab('declined'),
				},
			];

			ts.ui.get('#approvals-board', board => {
				this.board = board;
				this.board.tabs(this.tabs);
				if (this.downloadEnabled) {
					this.board.buttons([
						{
							label: this.lexico.trc('Button', 'Download report'),
							onclick: () => {
								this.componentAsides.downloadReport.isOpen = true;
							},
						},
					]);
				}
			});
		};

		$scope.$on(constants.scopeEvents.approvalsUpdate, () => {
			this.retrieveRequestsAndUpdateTab();
		});

		this.$onChanges = changes => {
			if (changes.searchQuery && !changes.searchQuery.isFirstChange()) {
				this.updateTabRows();
			}
			if (changes.teamId && changes.teamId.isFirstChange()) {
				init();
			}
		};

		this.componentAsides = {
			forwardRequest: { isOpen: false },
			downloadReport: { isOpen: false },
			approverOptions: { isOpen: false },
		};

		this.onAsideClosed = key => {
			this.componentAsides[key].isOpen = false;
		};

		this.openApproverOptionsAside = approval => {
			this.componentAsides.approverOptions.approval = approval;
			this.componentAsides.approverOptions.isOpen = true;
		};

		this.onRequestForwarded = () => {
			this.componentAsides.forwardRequest.isOpen = false;
		};

		this.openForwardRequestAside = approval => {
			this.componentAsides.forwardRequest.approval = approval;
			this.componentAsides.forwardRequest.isOpen = true;
		};

		this.viewConversation = approval => {
			try {
				const requestId = approval.getRequestId();
				window.localStorage.setItem('conversationId', requestId);
			} catch {
				// ignore
			}
			$state.go('main.approval', { approvalId: approval._approval.id });
		};

		this.openCreateCardAside = approval => {
			AsideService.open('createCardV2', {
				model: { approval },
				onSuccess: () => this.renderTab('pending'),
			});
		};

		this.openConfirmDeclineAside = approval => {
			AsideService.open('confirmAction', {
				model: {
					message: this.lexico.trc(
						'Warning message',
						'The request will be declined and the requester will be notified. Would you like to proceed?',
					),
				},
				onSuccess: () => {
					RequestService.reject({
						taskId: approval.getId(),
						requestId: approval.getRequestId(),
					})
						.then(() => {
							const declinedMessage = this.lexico.trc('Declined message', 'Request was declined.');
							ts.ui.Notification.success(declinedMessage);
							ApprovalLists.completeApproval(approval.getId(), TASK_OUTCOME_REJECTED);
							this.renderTab('pending');
						})
						.catch(() => {
							const errorMessage = this.lexico.trc(
								'Error message',
								'There was a problem declining the request. Please try again.',
							);
							ts.ui.Notification.warning(errorMessage);
						});
				},
			});
		};
	},
};

export default ActivityFeedBoxComponent;
