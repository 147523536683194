const SvgTransactionsHistory = props => (
	<svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
		<path
			fill="#333"
			d="M6 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6m0 10.8a4.801 4.801 0 0 1 0-9.6 4.801 4.801 0 0 1 0 9.6m3.22-5V13h-4V9h1.2v2.8zM18 4.22 13.8 0H3.6v5.22c.39-.14.79-.24 1.2-.31V1.2h7.8v4.22h4.2v9h-4.03c-.15.42-.34.82-.56 1.2h5.78V4.22zm-4.2 0V1.7l2.51 2.52z"
		/>
	</svg>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

export default SvgTransactionsHistory;
