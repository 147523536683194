"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useClearGoBranchMutation = exports.useStoreGoBranchMutation = exports.getGoBranchQuery = exports.supportSettingsKeys = void 0;
const react_query_1 = require("@tanstack/react-query");
const uuid_1 = require("uuid");
const supportSettingsRepository_1 = require("../repositories/supportSettingsRepository");
exports.supportSettingsKeys = {
    getGoBranch: 'getGoBranch',
    storeGoBranch: 'storeGoBranch',
    clearGoBranch: 'clearGoBranch',
};
const getGoBranchQuery = ({ companyId, readLinkClicked }) => (0, react_query_1.queryOptions)({
    queryKey: [exports.supportSettingsKeys.getGoBranch, companyId],
    queryFn: () => supportSettingsRepository_1.SupportSettingsRepository.getGoBranch({ companyId }),
    enabled: Boolean(companyId) && (0, uuid_1.validate)(companyId) && readLinkClicked,
});
exports.getGoBranchQuery = getGoBranchQuery;
const useStoreGoBranchMutation = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: ({ companyId, goBranchId }) => supportSettingsRepository_1.SupportSettingsRepository.storeGoBranch({ companyId, goBranchId }),
    });
};
exports.useStoreGoBranchMutation = useStoreGoBranchMutation;
const useClearGoBranchMutation = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: ({ companyId }) => supportSettingsRepository_1.SupportSettingsRepository.clearGoBranch({ companyId }),
    });
};
exports.useClearGoBranchMutation = useClearGoBranchMutation;
