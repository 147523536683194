"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiListOverrides = void 0;
function getSupportMenuStyle() {
    return {
        width: '100%',
    };
}
function getSideMenuTeamSwitcherMenuStyle() {
    return {
        height: '100vh',
        maxHeight: 'calc(100vh - 60px)',
        overflowY: 'auto',
    };
}
function getSideMenuSwitcherStyle(ownerState) {
    const defaultStyles = {
        opacity: 1,
        display: 'none',
        left: '60px',
        bottom: 0,
        backgroundColor: '#192226',
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        paddingTop: 0,
        zIndex: -1,
        transition: 'transform 0.3s ease-in-out',
        width: '300px',
    };
    if (ownerState.className === 'open') {
        return {
            ...defaultStyles,
            transform: 'translateX(0)',
            display: 'block',
        };
    }
    else if (ownerState.className === 'closed') {
        return {
            ...defaultStyles,
            transform: 'translateX(-100%)',
        };
    }
    return { ...defaultStyles };
}
exports.muiListOverrides = {
    styleOverrides: {
        root: ({ ownerState }) => ({
            listStyle: 'inherit !important',
            ...(ownerState['ts-variant'] === 'supportMenu' && getSupportMenuStyle()),
            ...(ownerState['ts-variant'] === 'sideMenuTeamSwitcherMenu' && getSideMenuTeamSwitcherMenuStyle()),
            ...(ownerState['ts-variant'] === 'sideMenuTeamSwitcher' && getSideMenuSwitcherStyle(ownerState)),
        }),
    },
};
