const ViewImageModalComponent = {
	bindings: {
		filename: '<',
		isOpen: '<',
		onClose: '&',
		title: '@',
		url: '<',
	},
	template: `
		<ts-modal
			is-open="$ctrl.isOpen"
			on-close="$ctrl.onClose()"
			title="{{ $ctrl.title }}">
			<div class="image-container" ng-if="$ctrl.content">
				<img
					ng-src="{{ $ctrl.content }}"
					ng-if="$ctrl.type === 'image'"
				/>
				<pdf
					ng-if="$ctrl.type === 'pdf'"
					src="{{ $ctrl.content }}"
				></pdf>
			</div>
		</ts-modal>
		`,
	controller: /* @ngInject */ function ($element, $window, DOMService, FileSaver, HttpService, $sce) {
		let blob;

		const loadImage = url => {
			this.objectURL = null;
			this.type = null;
			this.content = null;
			return HttpService.tradeshiftGo()
				.path(url)
				.config({
					responseType: 'blob',
					cache: true,
				})
				.get()
				.then(_blob_ => {
					blob = _blob_;
					this.objectURL = $window.URL.createObjectURL(blob);
					this.content = $sce.trustAsResourceUrl(this.objectURL);
					this.type = blob.type === 'application/pdf' ? 'pdf' : 'image';
				});
		};

		this.$onInit = () => {
			this.viewImageElement = DOMService.moveElementBelowNgApp({ $element });
		};

		this.$onChanges = changes => {
			if (changes.url && changes.url.currentValue) {
				return loadImage(changes.url.currentValue);
			}
		};

		this.downloadPDF = () => {
			FileSaver.saveAs(blob, this.filename);
		};
	},
};

export default ViewImageModalComponent;
