const outOfOffice = {
	oooEnabled: 'OOO_ENABLED',
	oooStartDate: 'OOO_START_DATE',
	oooEndDate: 'OOO_END_DATE',
	oooForwardToFullName: 'OOO_FORWARD_TO_FULL_NAME',
	oooForwardTo: 'OOO_FORWARD_TO',
	oooForwardToEmail: 'OOO_FORWARD_TO_EMAIL',
};

module.exports = {
	requestLimit: 'REQUEST_LIMIT',
	outOfOffice,
	all: Object.assign(
		{
			defaultApprover: 'DEFAULT_APPROVER',
			invitedDate: 'INVITED_DATE',
			requestLimit: 'REQUEST_LIMIT',
		},
		outOfOffice,
	),
};
