module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# din tranzacții",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minut",
"ce09c436b49af331a32f1152ede7dd18":"1. Solicitați fonduri",
"d2226752d3065fbad4744924b4a0ee73":"2. Examinați și aprobați",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Examinați și aprobați",
"82bab92f1c8030888550b51521266c53":"3. Plătiți cu un card virtual",
"2ecd62e02e507f8e839a55eac0a04886":"O înregistrare de cod cu ID '{entryId}' există deja.",
"5dfd25849740fbce8147838b0d5094eb":"Un pseudonim amical, de exemplu, Card de marketing",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Un membru al echipei noastre de succes a clientului vă va contacta în scurt timp.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"O persoană pe care nu am reușit să o căutăm",
"976b50e70e4433e2786233979d243445":"O echipă cu acest nume există deja.",
"35b61d21ac11d5fc31ebe58541263445":"Acceptați invitația de a vă alătura",
"a372cb2d4204654f52cd9170fb10d374":"Coduri contabilitate",
"110fc66010b6b91cebee7a59a2067aab":"Coduri de contabilitate",
"db862bb04dbdc6d04e47063f33be8865":"Codurile de contabilitate sunt gestionate prin listele de codificare și intrările din liste.",
"d6e3848e824f077396be4351eb58edd2":"Este necesară o acțiune - aprobare/respingere solicitare nouă de către {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Acțiuni",
"476e728ea824229f52438e543818bcdd":"Acțiuni",
"3117746cb7d4ab815ec98274ec4398d5":"Acțiuni",
"3ab09b66a7f16219d8135977a2a76d10":"Acțiuni",
"ffafa66eaab0409a2e90aaeb43796def":"Activați",
"0447e0aa5d00dbdab11b5546793c4479":"Activați",
"8ee51d21b05466f7d962d4ff5a0b384e":"Carduri active",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Carduri virtuale active",
"89c6ec05861aa914304ba35bc6744124":"Carduri active",
"5280659bcecaf8344c57790f9962a9a2":"Activitate",
"8a15368ae286fa64e1cb7624cd410fc6":"Adăugați lista de codificare",
"8ded7e09e86be231b1165308329ac76d":"Adăugați lista",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Adăugați un administrator",
"695bb4bc29e2df769ddf7427ab71012a":"Adăugați aprobator",
"4a1d4516cab00b7c5ecdb150e346e5db":"Adăugați aprobator",
"56869851b27c2915f5a7fe6a33b91ac7":"Adăugați lista de codificare",
"8f8691057c3994aa61f9994202680c87":"Adăugați lista de codificare",
"69f7a406b79d19bf8082ed245ab29123":"Adăugați lista de codificare",
"48a92ca1cc63018707be99b13196369f":"Adăugați administratorul firmei",
"a679db9ff80d0e5769ae1ba311c7916f":"Adăugați domeniu",
"8f5e8078be77146ba7f3be49ef948906":"Adăugați înregistrare",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Adăugați un mesaj",
"f8a4b14162e19995074e63135b65c84d":"Adăugați un card nou",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Adăugați persoane",
"c670549d0b898bb6a571eb2cd7804a6e":"Adăugați chitanță",
"eb937098e77d8a6b0565ed72357dcaae":"Adăugați chitanță",
"1e154f31c31b63a459f3956e380b3ef9":"Adăugați selectate",
"ba24db9956f156a18b32e653552ff682":"Adăugați persoane în această echipă",
"52bd93fb5489b25167bb541f7c6504ae":"Adăugați card sursă",
"21ab3f08eb507dbc76df166eafeb393a":"{fullName} adăugat ca aprobator!",
"f879c06507d3076bbf52ea4fd2df850e":"Detalii suplimentare card",
"21e934ae30eece4a894337701f497638":"Tot",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Toate cardurile",
"8fac71195e2b506e029752e4864b6a5d":"Toți membrii",
"f9708417df16227742e0545a8642eea7":"Toți utilizatorii disponibili au fost adăugați deja la acest card.",
"cb5abf070f8cba7982205038baab77bb":"Toate cardurile",
"dae7d602eec2c84dea0d80ae25e56e6d":"Toate cardurile",
"0b4bdfde6578668b6018d3615a1ad30f":"Toate centrele de cost",
"97b875b81f54b4432d998a3e47d949cc":"Toate echipele",
"f0ae83969f7c20a1964caf5156dae966":"Toate echipele",
"d87455601a00282078582b36a3825b7d":"Toate echipele",
"24c7b7f55882ea94a525392ca8218fa0":"Toate echipele",
"8f860e35b64a8f447bded1e5ea66a2a8":"Toate cardurile virtuale",
"e403956ecaa6fb33ec1fef5c49d71042":"Toate cardurile virtuale",
"4e6f4e8f25f61d40e1eba77de94f6458":"Toate informațiile dvs. financiare sunt criptate pe servere din locații sigure.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Sumă",
"a4d099d0dbe3889b0995546e6ed28932":"Sumă",
"6c0ef32aae76bc31a61e2124f542bdf1":"Sumă",
"adec89c93525f1a8d1c64d856f405e27":"Sumă: de la mare la mic",
"18e632a170203894f40deed1d33ee552":"Sumă: de la mic la mare",
"a7944575cafef9126a499a1b06999fe3":"A intervenit o eroare, încercați din nou mai târziu.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Aplicați",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Opțiuni de aprobare",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Setări aprobare",
"492eedd88cdd7bb45f39c6594af748c4":"Limită de aprobare",
"437d490afc8d226f8c7d544f4e35d011":"Limită de aprobare ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Aprobări",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Aprobări",
"17adb7fe866e32055b1469fdb7e82795":"Setări aprobări",
"7125aa13db437084e1d27d3ff38f22e2":"Aprobați",
"84df787ff5746f9dc3cf0146becef4fd":"Aprobare solicitare",
"7a568654d00245b0bab6c90a1b67d196":"Aprobat",
"179c3e187ad8edeb8348cff222b938bf":"Aprobat",
"ad058d17725870df9abbd4f5ec217636":"({count}) aprobată",
"55ab98be3f0530e16d7b4c962ea0faca":"Sumă aprobată",
"4d58ba5c0e03fec1556c243be106e4a3":"Aprobat de",
"7d3d697fc99f6c4641b326623743f7b7":"Aprobat { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Aprobator",
"8c45e443fc6748190719d79a092e2528":"Aprobatorii au o limită de aprobare maximă",
"4fe56aa6456c108cd288a4ec8acf435f":"Arhivați",
"a9b6f93de9630064be9809130aa1e195":"Sunteți gata să preluați controlul cheltuielilor de pe cardul dvs.?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Sigur doriți să ștergeți această solicitare?",
"301bfc2ce058f49e0704fec3d162b5b5":"Sigur doriți să reatribuiți această solicitarea înapoi către dvs.?",
"2181faf8d9973d1f4138e401a762086e":"Sigur doriți să eliminați ${firstName} ${lastName} din funcția de administrator al firmei?",
"b982277f29055d0ef6e295fe2006d9e0":"Sigur doriți să eliminați {firstName} din echipă?",
"456c0ccff14acb808583cd9dece857b6":"Atribuit către",
"bde315b9cf7fd55f4b65aabbbf38d462":"Cel puțin un manager sau un deținător de echipă trebuie să fie disponibil pentru utilizarea opțiunii „în afara serviciului”.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Autentificare",
"1961ec69dd0c8b5718c831312e7f3d0f":"Recepție automată colecție",
"e39f2477cf34d726936a5b5c2b48a4ad":"Creați automat rapoarte de reconciliere exacte, care includ informații despre cumpărători, aprobatori, recepții, coduri de cont și altele.",
"434ca07dfde0eaee14f094b7c75a6eae":"Disponibil",
"eb85d8f50f2c89871b794f7d54279b22":"Sold disponibil",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Disponibil { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Înapoi",
"c8258f8cf9e20f6bbb89d957de40e820":"Securitate bancară",
"d46c22a3f36dce568c07fcea4368b231":"Setări de bază",
"fb481c8e517016094b4fdf618c5fa088":"Adresă de facturare",
"79a9f821923c5a04c076902fef684281":"Adresă de facturare",
"877a08b8347052c83aa0b689baea81e7":"Adresă de facturare",
"97f018458e2627fac37d24846c7b8922":"Continuând cu crearea contului și utilizarea Tradeshift Go, sunteți de acord cu",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Continuând cu crearea contului și utilizarea Tradeshift Go, sunteți de acord cu",
"912d1864f0068c764bf4784020795d49":"NUME DEȚINĂTOR CARD",
"d53474c7fd99fbeb69481ce9d358aa09":"Pot solicita carduri și obține un portofel pentru toate cardurile. Fiecare membru primește un aprobator (manager) implicit pentru solicitările sale.",
"f30df2cab105ed3f8b4264aeb9800abd":"Pot aproba solicitări și crea carduri pentru membrii pe care îi gestionează. Managerii au un tablou de bord pentru urmărirea cheltuielilor membrilor pe care îi gestionează.",
"03ed9eccc71ef59165292eac542d3050":"Pot vizualiza toate solicitările și activitatea legată de cheltuieli din echipă. Deținătorii de echipă pot obține un tablou de bord pentru echipă, pentru urmărirea cheltuielilor și a portofelului echipei, unde pot fi văzute toate cardurile active.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Imposibil de eliminat acest utilizator. La o echipă aveți nevoie de cel puțin un proprietar sau manager de echipă.",
"a0b70642629a55ea0ab6fa3414d342e0":"Imposibil de eliminat utilizator cu solicitări în așteptare.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Anulați",
"5446624d3c364ac05ddf8d6663da2796":"Anulați cardul",
"53f3815c4fa3340b29efd81e627d1e6b":"Anulați cardul",
"12257ea2a871b7fab72245b5aafe6424":"Anulați solicitarea",
"2aafb7eb4d56a516c4bb0754f0812df1":"Anulați cardul",
"caae12601b01e2325161e95107f9e332":"Anulați cardul",
"6ad1e57b1e4c7accbe166c948883077d":"Anulați cu un clic, dacă este compromis",
"f6527e49e6d5c989624507a583e23f39":"({count}) anulată",
"1ad3bf5121641dda511f42942af05a90":"Anularea acestui card nu va storna nicio taxă în așteptare. Căutați decontarea",
"aebd3826a195afad0a93050a7cc5d73d":"Anularea acestui card nu va storna nicio taxă în așteptare. Căutați înregistrările de decontare pentru acele taxe din declarația cardului de credit trimis de furnizorul dvs. de carduri.",
"a5b5136947da562f91b75daf8156f50e":"Detalii card",
"9ed31ad23a1578ce480d31207decf1d3":"Setări card",
"def15f2e007fc69886230fdb2511305c":"Detalii card",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Detalii card",
"67cfd260078ca32027c2fa4a4a2d29f4":"Deținător card",
"4458819ea60c68c46e551a8f5cc163cd":"Nume card",
"0858bcbd506fa3240b165e59d2f18aae":"Nume card",
"5212736dd18ee2c31f3650ad2c2c7130":"Nume card actualizat.",
"c96d62fa510c3356dc73a91d1a80e262":"Număr card",
"41307fee4c32aefb5383a98fe3d6e45e":"Tip card",
"3f8045c3358621dad4346a44de0c05a8":"Nume deținător card",
"879a6b452033cf5f3a4bb3e1837a0386":"Oraș",
"593334553ef852024e172505ec3c5f21":"Clic aici",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Codificare",
"df931751d5790d74fa46707aaf7a26ae":"Codificarea 1",
"7ac7154bc776aff52a71b0d83b860561":"Codificarea 2",
"586fcf19136ae5e26795e9055b2e2995":"Codificarea 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Codificarea 4",
"379bb8e71bb090badebbb0751de86f11":"Codificarea 5",
"3e84d13627b3d090ab2b8470af98340a":"Codificarea 6",
"33b94b581f3792049992663396e51cb8":"Codificarea 7",
"b73fbc32e439655833ee99126dd0e55f":"Codificarea 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Listă de codificare",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Înregistrare codificare",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Înregistrare codificare ștearsă.",
"cada62a39ee72d790dcdbb25160f8783":"Înregistrare codificare salvată.",
"f645bf50888943ee1384693e56a0f475":"Nume listă de codificare",
"a999182acbcd77c15299f79181b630be":"Listă de codificare salvată.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Liste de codificare",
"d0b96596ce6baec5aabb8a0690ab30e1":"Comentarii",
"ff9cb8842f832d21fb160b3d3784ce79":"Firmă",
"715b59a64e686eb91a21114644ffe39e":"Administratorul firmei a fost eliminat",
"8758e9a01253c097a42393fda9610715":"Tablou de bord firmă",
"e118b41009918b326db6746856831cbc":"ID firmă",
"4dba88b05b3fa8875128e19e88c512b3":"Setări firmă",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Setări firmă",
"f2ea6d33de8182d97bf2f13354af4893":"Setări firmă: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Administratori firmă",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Limită aprobare firmă",
"7e2f778da14a726b53e05926a3483f2b":"Card de credit companie",
"7313fd1db3d63858586d1c0f89251d6e":"Nume firmă",
"07721e91dd10ff8831f0d24e47cc1eab":"Nume firmă",
"203221610ca4d347063c037e1a732ae8":"Setările firmei au fost salvate.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Confirmare acțiune",
"d694cec09cdbc02b1ffe22642a63faa1":"Confirmați parola",
"512801312a133f29974f2259a455f39b":"Confirmați parola",
"f54c38ed0b9869d929ffc0bd5310131f":"Confirmare - Contul dvs. a fost creat la Echipă {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Contactați administratorul firmei dvs. pentru crearea codurilor de contabilitate.",
"7a6a4de077398e8e138aec36e3b3d305":"Continuați cu SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Drepturi de autor {date} Tradeshift Inc. Toate drepturile rezervate.",
"51f0d8e04f6ee626b866b37833dc2b61":"Centru de cost",
"e043f60a02f157d0f31a42f0db67cc87":"Imposibil de invitat {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Mesajul dvs. nu a putut fi trimis, încercați din nou.",
"ae28083f22a0b2fc5637614bce0976e0":"Creați lista particularizată",
"738078dc992208e05406f935995dc0c3":"Creați lista particularizată",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Creați o listă nouă",
"0f5783c3921b3d7398d281644bbe5bde":"Creați o solicitare",
"6d62f934f2304701f88bdcfbc688de7e":"Creați cont",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Creați un cont",
"f6b7d0d8531ea268f988005dd2147b2b":"Creați card",
"1a083675d1453448e4645403bbe52dd9":"Creați listă personalizată",
"c10ab11bdfb001da74850ed89c45482f":"Creați o solicitare nouă",
"43a9c06398408bfe77d06ffc2c1c3b87":"Creați o solicitare nouă",
"68bf17fd3f54a89c0a84224e2f9a971c":"Creați o echipă nouă",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Creați echipa",
"3b8e665101b9b3657f8a68c5305947b7":"Creat",
"40e91f2907181ffa8e62567b3a407e60":"Creat de",
"8929f7aacb969ef6353aba9903eb362d":"Card de credit adăugat.",
"d86688c26e8ac5eb9facc971b6fe5155":"Monedă",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Monedă",
"500ded53f64e737a5e4337675ac9b7ce":"Nume listă personalizată",
"b79a95f04fd829fb2e8d0313e522a94d":"Zilnic",
"ef445eab224fc599e4103c9b1745a4e0":"Tablou de bord",
"788452c3ad2b1450b6d8d116b863e635":"Tablou de bord",
"fb7add662cf7042355319a975fab6ae3":"Criptare date",
"6bded249dbfafd14490eea8dd37e1f51":"Dată",
"46e908a57cb09ef947af8c4a93a09716":"Dată",
"e4213104a55eceb60e00408699438dcd":"Dezactivare echipă",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Dezactivarea unei echipe va anula toate cardurile virtuale active și niciunul dintre membrii echipei, managerii sau deținătorii nu vor putea accesa echipa. Doar dvs. sau administratorul altei companii puteți reactiva o echipă. Sunteți sigur?",
"0d0d5677bee2b99884802638e507160a":"Refuzare",
"b5e3483aae9d36ce1440fe02904d6da1":"Refuzați cererea",
"00f3db97287cb17ee9c2a131f2141a65":"Refuzat",
"74fdf2e0ad0de26fe28b0b72ba44f494":"({count}) refuzat",
"268ed9551ae9f56647eaa394e43c160b":"Aprobator implicit",
"b77589ac3ac2317cfc96d7013658d5c9":"Ștergeți ID-ul codificării: { entryId }, Intrare: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Extrase detaliate și raportare",
"5234dfc4220ccd18a8b62fe84e372248":"Detalii",
"37929896817a6744718004374bb42fd8":"Detalii",
"dd0a6194e8a6ae14f3e1379a1640de15":"Doreați de fapt să eliminați {fullName} ({email}) din firmă?",
"1a98048b34090da8b199135ccb4c1186":"Numărul distinct și CVC ascund detaliile cardului real",
"3cf132b60fe16b816ca227bbcad60537":"Nu anulați",
"dee57797e14ccf852aa2bc545bafdf6d":"Descărcați",
"375ae8224d7d35393974775cebe8605a":"Descărcați lista de utilizatori",
"60cf3b94e5df14a504825068928fc118":"Descărcați raportul CSV",
"7341a4c73c0b688484304c37da4a6d8e":"DATA EXPIRĂRII",
"f628f8ad44fbd6c70c99fc778407b666":"Căutați cu ușurință tranzacții istorice și explorați tendințele de cheltuială ale angajaților în orice perioadă de timp.",
"94bb409d034ea20697ae09d812e55365":"Editați lista personalizantă",
"2974e704676cb79666bc236d8658fa97":"Editați profilul",
"9ff205f99ed4002d049f855f965ca6d8":"Editați utilizatorul",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-mail",
"0534c00af758d42ebd8b0bd772eab074":"E-mail",
"0c9aa1fbdf925065d25161eab5e51d95":"E-mail",
"ea78fac68766a48b1b0f41305b7e9898":"E-mail",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Angajatul solicită fonduri de pe cardul companiei pentru a efectua achiziția. Fondurile solicitate pot avea setate limite de cheltuială.",
"829cff16ce0b13810c914c161b2a9fba":"Activați modalități alternative de obținere a funcțiilor pentru utilizator",
"741387c7bd71e2b4a9601af9a6b9490e":"Activați redirecționarea automată a solicitărilor de aprobare",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Activați data de expirare implicită pentru cardul virtual",
"bb319710dad93e78cc6f58c615bdf210":"Activați adăugarea utilizatorilor invitați pe lista albă a domeniului de e-mail",
"4700ced24cd278c3149ffa983006035c":"Activați filtrarea după centrul de cost",
"3fe06ef8f551c4cb4c0375362e25521d":"Activați opțiunea de globalizare pentru utilizatori, în afară de",
"2787d4bc9752272ac36b499ce7ad93ab":"Activați utilizarea mai multor carduri virtuale",
"6ee44e2280c9ce5009568846cbc3dd14":"Activați opțiunea „în afara serviciului”",
"1941ca87053df0cebc6091ede92c18ea":"Activați vizualizare persoane pentru utilizatori administrator firmă",
"e50282c2ec7afb948d2c0b428db5d5ff":"Activați încărcarea recepției pentru tranzacții",
"268694cb2172918fe60f835919bf20ec":"Activați încărcarea recepției în tabelul cu extrase",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Activați aprobarea automată",
"d17c7acf598b569cce8a2bb97e0bb215":"Activați utilizarea unui singur card virtual",
"cd731ca218c9e14caabefafe003cfa6d":"Activați cardurile virtuale cu abonament",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Încheiere redirecționare pe",
"c1f665a814894b17d603b7052b631d90":"Înscrieți un card nou",
"6b6cea93e07e596be70f7b76801e1516":"Înscrieți cardul nou",
"7d6ac1ac9cba1620b61908842ebb106b":"Înscrieți cardul sursă",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Înscrieți cardul sursă",
"21a642b339c3eabf2789c0b05dcf828b":"Înscrieți-vă primul card de corporație în GO.",
"6efa8dca4fba23a190838746879fd704":"Introduceți un Id numeric",
"02c3457577da9dc8dba6e6f9380a248e":"Introduceți un nume de echipă unic",
"40feb3fd1fa529cb599aeac9607664a4":"Introduceți o înregistrare nouă",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Exemplu: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Luna expirării",
"b8bc74aeeb2de3492891c97361de107d":"Anul expirării",
"6a0b8d521500014d99dd192d8ce8403d":"Data expirării",
"8cf274169a732340430fe7cc5236e0e7":"Dimensiunea fișierului trebuie să fie mai mică de {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Prenume",
"7458b55e967e51791d98e77458631e44":"Prenume",
"3bbe52e71bc5c212cea77983f1a5b748":"Prenume",
"9b49c88fa20cc006baaac15c857c8781":"Pentru cumpărături singulare sau multiple",
"bb7a2907073bd760f4e82097132637b1":"Parolă uitată",
"26bdfaa592f3629273f13c22435b323d":"V-ați uitat parola?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Redirecționare",
"3f9fe6adc14ce5776097170653a24462":"Redirecționare solicitare",
"b774e35683f5737c36bf0cf3d6785973":"Redirecționare solicitare",
"628faa5054a912b1e470414f77057e6b":"Redirecționare solicitare",
"852b4065e1837fdc77940b6411a6d902":"Protecție împotriva fraudei",
"616551bd10d95aca439401d6a7769d96":"Sumă transport",
"a65a230918e9c11e1ef8824745e26a3d":"Începeți",
"7d4c4466b7716347ce3673acaa1983f7":"Aflați mai multe de pe Go",
"11415a07658cfef80eb166896d77ad98":"Pregătiți-vă pentru o imagine de ansamblu și mai mare asupra cheltuielilor. De asemenea, acum Tradeshift Go se va alinia mai bine la modul în care echipele de afaceri doresc să lucreze.",
"ac0ff5d14286ef74614a9c6021f08f69":"Monitorizați cheltuielile în timp real și urmăriți bugetul, aveți o imagine de ansamblu și descoperiți toată activitatea de cumpărare.",
"db9071ab833054307252a936e7f60e1b":"Începeți",
"24dc4bb00841b004031fca3a9bb9bf51":"Solicitare card Go anulată de {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go le aduce aminte angajaților de bonurile lipsă, care pot fi atașate prin răspunderea la o notificare, sau în cazul primirii unuia prin e-mail, prin redirecționarea către Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go colaborează cu rețeaua cardului dvs. de credit pentru a garanta aceleași standarde de prevenire a fraudelor la care vă așteptați atunci când lucrați cu cele mai bune instituții financiare de elită.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Vești bune! Solicitarea dvs. de cumpărare a fost aprobată pe Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Aveți întrebări sau feedback? Luați legătura cu",
"9df1d3de68d663b848101df3c7b58e39":"Cum funcționează",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Sunt sigur",
"84074732eb8d1b4094404cd750baf949":"În cazul în care nu se aplică nicio valoare, se aplică limita firmei",
"138e8bd6102a7eea70a123ff03b1cd79":"În cazul în care nu se aplică nicio valoare, se aplică limita echipei",
"e5756af4d7e5ad1236ccfbb858428421":"Dacă nu v-ați înregistrat pe Tradeshift Go folosind această adresă de e-mail",
"4f004f75f47dbfecdb6d09990596e530":"Dacă e-mailul dvs. nu este încă înregistrat, vă trimitem un e-mail de activare cu detalii despre cum să procedați.",
"123837f267de602fb05361b3b1197c1e":"În cazul în care {username} a găsit o corespondență cu un cont existent, vă trimitem un e-mail cu detalii despre cum să procedați. Dacă în decurs de câteva minute încă nu puteți vedea e-mailul din inbox, verificați folderul dvs. de spam.",
"45efd96cd93525a9c17222217a37d324":"Formate de fișiere imagine sau PDF acceptate",
"fa6ae823f9ab8bf91c246efe2517956c":"Importați configurațiile conturilor dvs. QuickBooks, NetSuite sau Xero direct în Go. Exportați cele mai recente tranzacții în soluția dvs. contabilă cu un singur clic.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inactiv",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Intrarea conține un caracter nevalid.",
"62c7be09da43f8f96a60ef3967eddc2c":"Vă prezentăm un Tradeshift Go complet nou.",
"19746bcbed8b8bcb1a9b83efca722077":"Domeniu de e-mail invalid",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Invitați persoane",
"3809b41b14c55c3fcf54cd813d8e21f5":"Invitați în echipă",
"fc90a11805961b0d7b5a5545e4d9bc05":"Invitați utilizatorul",
"6f7f4acde92d267417f5d8a60d32400a":"Invitați mai multe persoane",
"09ecd2ca15bb5c38dc10f653118346cd":"Invitați un utilizator administrator nou",
"3f576ce504f53f972442a63a170c5418":"Invitați nou {role}",
"0215e052437ceea4e5cd562431b34ced":"Invitați persoane",
"8aaf2c77df644b7e8524120f66da7d5f":"Sumă factură",
"1b7dc8290f1205f092cc9133e0cde385":"Data facturii",
"1a6fb210308df8e0135e49e76aaff31e":"Număr factură",
"613da3d0cd586ef0360e0d93eace9508":"Emis la",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Durează",
"3322b671105c65511fbab1966b7fdcf6":"Limbă",
"b1d946192795a58aea6a61f4f923acb7":"Ultimele 4 cifre",
"b3c6b8db4ef6dcdcb451303e65548816":"Ultimele 4 cifre",
"0382123b9c847000fceff2484c665e39":"Ultimele patru cifre",
"19f7c0055e150e9e3e66e85c2993799c":"Nume de familie",
"662c84bede8a7bf91ab15bcb73b48b49":"Nume de familie",
"cff8fbef5aac2523344b9535bd56973c":"Nume de familie",
"c3510b2184a92ff12e07a2d1b9a461e6":"Le permite angajaților să solicite acces la cardul dvs. de corporație, fără expunerea detaliilor dvs. de pe card, totul în timpul îmbunătățirii bugetelor și urmăririi fiecărei achiziții.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Limita poate fi suprascrisă la nivel de aprobator de echipă și individual",
"9bf5d56627e500cad9f7ad21c3e7966d":"Limită pentru anumite categorii de cheltuieli",
"e80a6d4232e52f6bfabee02e17403b59":"Conectați unul dintre aceste carduri sursă la această echipă sau înscrieți unul nou.",
"803570ab9f0fba5ff35ae2749200501f":"Încărcare carduri...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Conectați-vă",
"ad96c1ca962a883652dadb845f439415":"Conectați-vă",
"072ca67e735adbed54acf41321370701":"Conectați-vă",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Conectați-vă",
"b246fe8836c906f1b56342378ca88895":"Efectuați, aprobați și monitorizați achiziții în echipe care reflectă structura dvs. organizațională.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Gestionare extrase",
"95d672ab621e531ae1a69798e0e4b56c":"Gestionați toate cărcardurile dvs. virtuale într-un singur loc.",
"96f2e88a167f476413b35aa711709e6e":"Gestionați decontările",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Managerul primește fiecare solicitare de examinare și aprobare. Și limitele de cheltuieli pot fi reglate.",
"4f449985e83c1f9ca2e8f82b0707a371":"Managerul primește fiecare solicitare de examinare și aprobare. Și limitele de cheltuieli pot fi reglate.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Manageri",
"27f8111a955975ea6fd04f748d24cb4a":"Limită maximă de aprobare",
"5b1d9cfb78c55e75b47c62435d9299ca":"Membru",
"41e307c1874e2e82296b226e9bae4a5f":"Membru",
"6f9612aa1582373c2c1ba4e144132100":"Membri",
"590429a0fcc42af85648d0fd70601dcc":"Membri",
"3f50e62c8488d83ad84f69ebda853897":"Membri",
"e0fc0acda5b74b93e797756dbbba67bc":"Membri",
"c7ef9eda38b7eaee1f20031387a8bde7":"Lună",
"40dd08b507752bef4e3c4b36838317d5":"Lunar",
"d712b91f7498f212047aad4c6d0f8088":"Cel mai recent",
"12110d95b3d7a495afa4f28ac190f63e":"Cel mai recent",
"2f11ce3865f79ee7e5ee64d728082264":"Cel mai recent",
"eadc93865655cffc6a9863fdda5693e1":"Trebuie să aibă cel puțin 10 caractere, din majuscule și minuscule.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Trebuie să aibă cel puțin 10 caractere, din majuscule și minuscule.",
"50d0a69aa59df5201018771a06ea12e7":"Trebuie să fie unic",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Trebuie să fie unic.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Cardurile mele",
"c36602f50d35c2816f0ba8a196b70876":"Solicitările mele",
"ff6e372aa3eb685a39a701963db35b2f":"Solicitările mele",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Nume",
"1c7514b561a2591a914225813d30d642":"Nume",
"d621826c1ceade262589af04e655e824":"S-a solicitat o parolă nouă",
"35dc3304b61a9e69dc3a393123658837":"Solicitările noi vor fi redirecționate automat în această perioadă de timp.",
"e788124801b4727d121f60d059a2a4a7":"Următor",
"eca804f1d53dfd984ffd93e2d00fc284":"Niciun aprobator atribuit, contactați-vă administratorul de program",
"abdf7e93f8509399a63108a2b1a25546":"Nicio activitate de afișat",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Nu există carduri în portofelul dvs.",
"87127e1e9f5bf922d0a4f948b68697c8":"Fără limită",
"40512efa6b75ef0c7924b1ee3070fca9":"Niciun membru de afișat",
"dcda493afcb2415d5ca49a17d1af6f50":"Încă nu a fost afișat niciun extras.",
"f0efb26e623bb990532e375d5c8875ec":"Indisponibil",
"bc0cf602d1978e814070bb552e576a32":"Nu este mapat",
"09e126c317429ccb52ad323c32293648":"Număr de plăți",
"75b00b969b9d3b520929f1e652637b70":"Număr de plăți",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Număr de plăți",
"bd44ba180aa356c5755fdedd32adf03c":"Ce păcat! A apărut o eroare la crearea unei solicitări. Încercați din nou.",
"c396b3728dd7393aa9e5427990c132a7":"Of! A apărut o eroare la descărcarea raportului.",
"42c07764b07dea1ee7de7605721c3f5f":"Ce păcat! Nu am putut să crea o echipă nouă.",
"e3a385878ec94bac12a99490d0a8e85a":"Ce păcat! Nu am putut să crea contul dvs.",
"17f2dae79677017e2c2e879578c4e09a":"Ups! A apărut o problemă. Încercați din nou!",
"78c135de545c1663c9768226492a44a3":"Sau utilizați Autentificare unică individuală (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Lipsiți de la birou",
"5d7e45f169e2f6968dc58f43b5267353":"Lipsiți de la birou",
"ee589a20c262527db970671fb2c4f6bc":"Lipsiți de la birou",
"4fd0c753fce49a016b98d988a3895223":"Prezentare generală",
"2789c5d5c9c042858f45aadc4e0de058":"Prezentare generală",
"083b7090b66ebd0307a18499ac7b676e":"Pagina nu există",
"a21d205920893cf579a7655a1430fe30":"Card principal",
"fd35b7ce938e404fdb65de3bb749c528":"Card principal pentru raport",
"e8c7d2eea7db66ecb44751091a582304":"Parolă",
"bb79e316e00fd21138750b6986e44455":"Parolă",
"6cae2279088580976d84119d95097cae":"Parolele nu se potrivesc",
"4baec0159d3074f84c0eebcc09ba4cd8":"Parolele nu se potrivesc",
"b676587115243921602742cb8f696a6a":"Pauză creare card",
"db4a26c871ef0b970b74e1f71763098b":"Pauză emitere carduri virtuale noi",
"33fc1e6d01b785e3a815c28ef7d56667":"În așteptare",
"6ea62e4811b68df48b467e8d367aac2f":"Solicitări în așteptare ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Persoane",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Persoanele dintr-o echipă pot avea 3 roluri diferite",
"e2d34cb1409b9778d34c4b74ecee1df6":"Introduceți un număr întreg pozitiv sau lăsați necompletat.",
"8da247d5d736c87122404eff1e5c0c73":"Introduceți o limită validă.",
"65e154267ac283d963cbc7be832969d9":"Introduceți o valoare validă",
"28b4450d417065a69a395f567967d46c":"Presetare cu limitele de cheltuieli solicitate",
"7bbf151d8493170f206a23c2ecc6acd0":"Politică de confidențialitate",
"51602962f2d1e7515943b4a6c66562ce":"Imagine de profil",
"a51a17940c70a3123da4d68bed562c40":"Profil salvat!",
"4d398249d01ab72d697a7e219493c637":"Furnizor",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Mapare furnizor",
"89b1deb2ffe12a13941c362abaeab01b":"Publicat",
"d8d806d48052159e3ff3b224f3f53586":"Scop",
"089917619c78af2efb30be0bb7ce076c":"Reactivare echipă",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Motiv",
"e8484c78fda9f453eeb942ba4945f040":"Reatribuire",
"ff2fbc1172ba26776ad0982624cd2713":"Notă de recepție",
"588e690db177ee0c7a7cadb39cbd5efc":"Reîmprospătați fondurile în fiecare săptămână, lună, an",
"32955937daca05b2084556f248692baa":"Înregistrare",
"f15bb755ba24abe6ab106637292175ec":"Rămas",
"579782461133a8b8cc6bd77798961d82":"Eliminați cardul",
"e475edc165372f8c8deb532aa85d9950":"Eliminați lista",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Eliminați lista",
"f9769561315b964d0b3b4887ec208977":"Eliminare din echipă",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Eliminare notă de recepție",
"ee181cef581fc8e8217e66b5762db898":"Eliminarea unei liste o elimină de la utilizare și șterge toate înregistrările existente. Dacă doriți să eliminați temporar o listă și să păstrați înregistrările, puteți dezactiva setarea de publicare. Sigur doriți să eliminați această listă?",
"feea41d0bc1d577a07c8b6e1297abbde":"Eliminarea unui card sursă dintr-o echipă nu va dezactiva cardurile virtuale existente.",
"d09483824a2e0d6194a4c3f32311e752":"Eliminarea unui card sursă de la o echipă nu va dezactiva cardurile virtuale existente. Sigur doriți să eliminați acest card din această echipă?",
"7ca4b1bf1346e179a29da97f260a93a4":"Redenumire",
"db195ec9c818b8e92da142374e20d1bc":"Redenumiți lista de codificare",
"2fd93e229fe9ea754808805d3fef9c13":"Redenumiți lista de codificare",
"aa1e74d32805823f802a11b33c60c38b":"Tip raport",
"6ac78d97a8cb7f060e43e596e3e23535":"Solicitare anulată.",
"25deccb1980291a0fa9997109c731440":"Solicitați o demonstrație",
"9f2754d87686903b1500ce8e8a55b377":"Solicitați o demonstrație",
"92ac924f72679f897e26f3e615be8c22":"Solicitare redirecționată.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Solicitați informații",
"270185007853cd779011ae0e8433f723":"Solicitare reatribuită.",
"e5a059c9ba2691910321339cce989f9d":"Solicitare trimisă.",
"77c88a231edfa7075114d91d2397e42d":"Titlu solicitare",
"cee91b833c4358acf0c554f746557e71":"Solicitarea a fost refuzată.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Solicitarea a fost refuzată.",
"9e56abbbf87420eeedd625ce786515c7":"Solicitant",
"b4cb4426e44fee9580af71912a764536":"Solicitări",
"df309e888c29fc447d0309329b39ce93":"Resetați parola",
"5daf278da19ecbaeb7b96554cb4db10f":"Încertați din nou conexiunea acum",
"31e9501526d2114e4a01fdbf076de097":"Rol",
"6fbceb787429f6f81117a1d5a58b4610":"Rol",
"473b814ca86841ef672ccf9f7dac28d5":"Rol",
"f1e16d5fd643e039d865b6aec7c986f9":"Rol",
"ec78345a5975bc0f8efaee5256b43c2c":"Salvați",
"2cecea57b47d8f4a45c706287ff7d64e":"Salvează modificările",
"15774be5c338a39169917c7d32781dd0":"Salvați și continuați",
"844095b4b8f580737b8717672501048e":"Spuneți ceva...",
"7e66f676c2e7b04514aca3a0935530f0":"Căutați după informațiile de codificare",
"5293ce9335ec2d176a6e838bf76404c6":"Căutare după descriere",
"efab3b0c95f263cdd0a8466329a387eb":"Căutare după descriere",
"d227b38dcbc4548c092b0836a7101506":"Căutați după numele comerciantului sau Id de tranzacție",
"3629383abf1d5f85efd759f718159b40":"Căutare după nume",
"a034fcd6719228b28a6fc22d3a46ea8a":"Căutați după numele echipei",
"8ac7e0d63f343b548482d5f367f5b3d9":"Căutați după numele echipei",
"858323eb52cb45446597ee46330a0540":"Căutați după titularul cardului virtual",
"111bfcbff671e13de117ed5195fb6773":"Selectați",
"bcfecd59eb93e5314873d84ddf35aa58":"Selectați",
"909a4414ea573218bff2d533eaab46ea":"Selectați data de sfârșit",
"114b206ca1ff1c0bc52abef342059b2a":"Selectați opțiunile de echipă",
"81a02e424615fb41a20b8d0f13441c88":"Selectează echipe",
"b135b36495618e6692e7fbaf0a0aa83e":"Selectați o lună",
"677e90855e164c00a28b5c4763ac67e0":"Selectați un rol",
"d01570f3774738395b8ba3c64ce54c76":"Selectați un stat",
"7dee3f06c70626881efb0cd6af6b63e1":"Selectați un utilizator căruia să-i redirecționați solicitarea",
"4c7bb810835a1540ddc1f6501da5a51b":"Selectați un an",
"3ab198d2678af2b5b32180e4b39602ba":"Selectare aprobator",
"f502ba79f2bd010c5e77891812400958":"Selectați cardul",
"936027182d446e27ba209ea921ef7502":"Selectați intervalul de date de expirare implicit",
"b22c893254ccf3bd34faaee511cf7ca8":"Selectați durata",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Selectați din presetările din lista de codificare sau creați-vă propria listă nouă.",
"42cce5fa376b45bf290f86c684d69d96":"Selectați câmpul furnizorului",
"b761773d46699b60e8d6874b85593374":"Selectați echipele",
"3a9396756942ede98d0cb6afc1032b15":"Selectați aprobatorul acestora",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Propriu",
"03e9d2c128af288126d94f631c5396be":"Trimiteți instrucțiuni",
"299a7e961804e2d649f06b5dc4362c29":"Trimiteți invitația",
"155e7b3f7db7bf10b03b5f41de54eae1":"Trimiteți mesajul",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Setați o dată de expirare",
"f89ec96a4b5bb639488ab742efa76a6a":"Setați o parolă nouă",
"7d5377ad70d57728a45d3c0639de5544":"Setări salvate",
"14313f11dee735f2144953e720776c28":"Suma extrasului poate fi diferită în cazul în care pentru achiziție se aplică și bacșiș sau alte taxe",
"199000fe0cda0adaaa9b864aa7447cb9":"Decontări",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Decontări",
"6e6ac6ff3bd722e37874fb1ea7115818":"Configurați-vă parola",
"9b4ecf052980e80bf5c65a415d470a43":"Partajați și controlați accesul la cardul dvs. de corporație",
"ba746d78772d6be26cc508ba989becb0":"Trebuie să fie mai mică sau egală cu {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Afișare tranzacții fără note de recepție",
"7d474b60aeb7cc25b519e806fca71e12":"Conectați-vă cu acreditările \n\n Tradeshift existente",
"16080cd4e8319ef3e7de21b6c19d1437":"Ceva nu este corect",
"8f358a370224baf849f9652810c68db2":"A apărut o problemă",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"A apărut o problemă",
"99af87c475a4d40ef732deed96f11ed2":"A apărut o problemă. Încercați din nou.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Ne pare rău! În acest moment, avem probleme la conectare - se încearcă din nou {retryConnectionPeriod} secunde.",
"84debc8e41646cd49ac2b14708ca6d73":"Ne pare rău, a apărut o eroare la ștergerea înregistrării codificării. Încercați din nou.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Ne pare rău, a apărut o eroare la salvarea înregistrării codificării. Încercați din nou.",
"689d3418ee8f996ad25e152e80de63fe":"Ne pare rău, în acest moment nu am putut să vă deconectăm.",
"b48efd4521ecd6428987d24636a4349f":"Sortare",
"776a95003d3ec1dd58af69aedbc08579":"Sortare",
"1b783238028c0ae085ca69ed823f4d1a":"Sortare după",
"be70d19bf13c935d653b2c4fe7d36224":"Nume card sursă",
"564f8c942e7d1bdda5251545612fa348":"Nume card sursă",
"aa284602c746aad4a6de6366c24221b8":"Carduri sursă",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Carduri sursă",
"389d471e79a43440b3278b0fc7187d9e":"Carduri sursă",
"106342f689b57145817b00d6fe383f91":"Dată de sfârșit specifică",
"2a5e19387ff300509876e3bae99b7ab9":"Îmbunătățiți viteza contabilității",
"ca34d586bbdb7f368f1009305bccbe76":"Cheltuieli tablou de bord",
"4583120998b44c0a5982cf85dd938d2f":"Petreceți în timp",
"244d724806247953233a0bbfcbf8da4c":"Limită de cheltuieli",
"d39b83dd151c8df6370460a458294ccf":"Cheltuieli",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Sumă cheltuită",
"5de2859528e030ac1f2af115ebdf209c":"Cheltuieli { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Începeți redirecționarea către",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Stare",
"b089930f1f6ffac09438cc19590f8f6b":"Stare",
"f174d20592156eb4b10a7cf1144c973e":"Stare",
"ecf342482d1b6ab932c2000cd7dc38c4":"Stare",
"1f1fe111548158ec842761eca7425ff3":"Adresă stradă",
"31a0a748b1097a9e0aa95a811802bdd0":"Reconciliere fără stres",
"111b782f3f0794c2294aefb6693b2c50":"Remiteți",
"013e2555920b51ad2a3d2c3dc482c471":"Timiteți solicitarea",
"1270cd8eb69ad64cc71e5de979423b3b":"Abonamentul se termină",
"2802336ad3d8ff658257b88a93f4e50a":"Abonamentul se termină",
"b28dd68454d7acbefafeefb42981fe16":"Frecvență abonament",
"907793bc80cf94d560e5f1a0250fdaee":"Frecvență abonament",
"29229e153308e4747edef1144de35a55":"Succes!",
"503eb46b80f374f39266e7a9aea8e63e":"Succes!",
"4f20e29cadadb9fbc7455bd4d80de567":"Succes!",
"601d04f10d558f436c78c10e90b44175":"Asistență",
"62558c185ea2c06c5472d1cd534f6e56":"Se sincronizează cu soluția dvs. de contabilitate",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Valoare impozit",
"0cb5d74f7a56fca30930616feb6e2f47":"Echipă",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Echipă",
"bf23b2fd889e3b12e0f0acf99f913eca":"Echipă carduri",
"b90a650376782a9e43ed6be6135bd6f2":"Tablou de bord pentru echipă",
"6adbec395fe1c4bf776f9fe5fa021052":"Tablou de bord pentru echipă",
"6f2695eeb68501dbcfddbb4d265b846b":"Manageri de echipă",
"3b779ade9c960ac95a670db0cc12e56b":"Membri ai echipei",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Setări echipă",
"dc5235e1b88320d3ba7501e4c30985a3":"Setări echipă",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Setări echipă",
"a2eb92bfd7502f47b3cd170bc68ac638":"Setări echipă",
"f1ec84f051f19f0d0e301a709b13dc45":"Echipă cheltuieli",
"fbacde3a262ea8696e30135e8f987a44":"Comutator echipă",
"74b9578ea5a620f5fef102188e42c02f":"Comutator echipă",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Acțiuni echipă",
"3aa978fa7ef886621285b93257d428c3":"Limită aprobare echipă",
"a41f9df131c221a99926de7e2138653a":"Informații despre echipă",
"941c7da8f256f91ba3819f991315a100":"Nume echipă",
"c5d04e10cc2f0b53c2bda893eae09178":"Nume echipă",
"504a8ba642ba37c9a743d0fabb2bd371":"Nume echipă",
"e768a8e1e3d6a6bf470587dad75423fe":"Echipă proprietar",
"e2d824067f8bd871756688c58c382196":"Proprietari echipă",
"dca232ccc849c5ff5611255eb82baf53":"Proprietari echipă",
"3b61bd586225790cf132591383d15448":"Comutator echipă",
"bc05b40509f0271c12cfaaef8e5319b1":"Echipe",
"39e73773d07a800673ec6600422952d6":"Echipe",
"daabb564bb3dcfb41de69492f854d573":"Echipe",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Limitele de echipă și individuale nu vor depăși niciodată limita firmei",
"4b4fe189a48d8eb06d836afa8fb6317a":"Echipe actualizate.",
"2a17f9a8c610db29fec63fd530c29fdc":"Echipele care folosesc cardul",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Echipe care văd asta",
"317868f3804ba4ed5d99dd9919c798ba":"Condițiile de furnizare a serviciilor",
"e8c9a9847db2ccd97524aa06f1af9d82":"Condițiile de furnizare a serviciilor și politica de confidențialitate",
"38852078519c5c46715d8a034fc4901b":"Condițiile de furnizare a serviciilor și politica de confidențialitate.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Vă mulțimim pentru interesul acordat Tradeshift Go. Asistența pentru cardurile de credit {cardLabel} este disponibilă curând. Un membru al echipei noastre de succes a clientului vă va anunța imediat ce acesta va fi disponibilă.",
"f7047b35a069d0790a0a348a29c846a2":"Vă mulțumim pentru că v-ați alătura echipei {companyName} pentru a face achiziții comerciale folosind carduri virtuale Go.",
"133b0a70b1a956889164621380c378f9":"Acțiunea pe care încercați să o efectuați nu poate fi finalizată. În unele cazuri, simpla încercare din nou ajută.",
"18f5a1872e156f1aee84ae321780d952":"Suma solicitată depășea limita de solicitare a firmei de ${requestLimit}. Încercați din nou, solicitând o sumă mai mică.",
"867e3d4d3c78990c6852fc5348706ab2":"Suma solicitată depășea limita de solicitare a furnizorului de ${providerLimit}. Încercați din nou, solicitând o sumă mai mică.",
"570f5f4658b03dda7a910f56c86a3bd3":"Cardul pentru această solicitare a fost anulat.",
"3075c56105863546e3368ffead7a146b":"Cardul a fost anulat.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Lista de codificare pe care încercați să o anulați este legată de o integrare. Dacă sunteți sigur că doriți să o eliminați din integrare, vă rugăm să contactați serviciul de asistență care poate finaliza această acțiune.",
"756ade8d71ec487a4b48d7c4277ea239":"Lista de codificare pe care încercați să o anulați este legată de o integrare. Trebuie mai întâi să eliminați maparea înainte de a anula publicarea acestei liste de codificare.",
"4f40335f7c018986c95458183bc0c136":"Linkul pentru activarea contului dvs. este invalid sau a expirat. Consultați persoana care v-a invitat pentru a obține o altă invitație.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Linkul pentru resetarea parolei dvs. este invalid sau a expirat. Solicitați din nou o resetare a parolei pentru a primi un link nou.",
"ded30f0e288c12125ca1a77f8d8fd600":"Motivul va fi vizibil pentru aprobator și solicitant",
"f294897b38fc23dd8f5de52b54ceae22":"Solicitarea va fi respinsă și solicitantul va fi notificat. Doriți să continuați?",
"e8c7200dc985df0a94a9d996968b4cc0":"Solicitarea va fi respinsă și solicitantul va fi notificat. Doriți să continuați?",
"5f3d196a2ade3f97a2a48b773fec5861":"Solicitarea va fi respinsă și solicitantul va fi notificat. Doriți să continuați?",
"b04c191cd6b4f27669a4f390311f156c":"Nu există deținători sau manageri de echipă care pot fi selectați.",
"e25045557efbad7b52997b94a1fe6817":"Nu există {type} pentru această perioadă de timp",
"7260690577c795e999acae63bb7c51ae":"A apărut o eroare la salvarea setărilor echipei. Încercați din nou.",
"f2103024f2a9c6c0335a71c60567d6c9":"A apărut o eroare la încărcarea portofelului dvs.",
"9776cb7eab9fa0d318a8144cd17de745":"A apărut o eroare la încărcarea portofelului dvs.",
"77d28a14bc900be4360b570a82eb75a9":"Nu există nicio dată de afișat",
"2b6feba67722337f88c1443909fe446f":"A apărut o eroare la adăugarea aprobatorului!",
"24b7212afb97c5d23faafa2ca4559095":"A apărut o eroare la adăugarea cardului de credit. Încercați din nou.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"A apărut o eroare la adăugarea cardului sursă la echipă. Încercați din nou",
"5a8e1b922d1cee746ac6794599b10709":"A apărut o eroare la anularea acestui card. Încercați din nou.",
"91f1cb4d9e0c36605495a88dcf57d19b":"A apărut o eroare la anularea acestui card. Încercați din nou.",
"22361c5d4df61ad7ae9792a3baf5729f":"A apărut o eroare la crearea listei dvs. Încercați din nou",
"098755bfddeaf0428c7feb6b69af9b6f":"A apărut o eroare la refuzarea solicitării. Încercați din nou.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"A apărut o eroare la refuzarea solicitării. Încercați din nou.",
"7d461b6b7b54dbfa9c83243217cd1053":"A apărut o eroare la ștergerea înregistrării codificării. Încercați din nou.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"A apărut o eroare la ștergerea listei dvs. Încercați din nou.",
"beb43d7800cfff47e08cd61842d953bd":"A apărut o eroare la redirecționarea solicitării. Încercați din nou.",
"f7775350f35b8365fb9803232d9b792c":"A apărut o eroare la obținerea detaliilor de card. Încercați din nou.",
"95abaa922c6c1648ca9c39ac4776896f":"A apărut o eroare la obținerea detaliilor utilizatorului. Încercați din nou.",
"9d7e4ead60137041ae8a845aa6cf9979":"A apărut o problemă la încărcarea profilului utilizatorului. Încercați din nou.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"A apărut o problemă la încărcarea acestor utilizatori ai echipei. Încercați din nou.",
"3f70e7e0157e3f1f9395be31d5761f0d":"A apărut o problemă la încărcarea setărilor dvs. Încercați din nou.",
"b2f48028c400befad5f1d57f769ef7cd":"A apărut o eroare la reatribuirea solicitării. Încercați din nou.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"A apărut o eroare la eliminarea cardului sursă de la echipă. Încercați din nou.",
"19dfcd761006496c1d20351c04643e45":"A apărut o eroare la eliminarea acestui domeniu, încercați din nou",
"149651b5784481c68aaf4ac27bb1f907":"A apărut o problemă la salvarea listei de codificare. Încercați din nou.",
"c141d505f8aea3e52fdcd81dc09c0b83":"A apărut o eroare la salvarea profilului. Încercați din nou.",
"749ddc235744b6b371668ec0bb049176":"A apărut o problemă la salvarea utilizatorului. Încercați din nou.",
"28b131c0e50b410bdfcc03c611b3d2d8":"A apărut o eroare la salvarea acestui domeniu, încercați din nou",
"04cf86909808f430b8b1904fb422e0d3":"A apărut o problemă la încărcarea setărilor privind cât lipsiți de la birou. Încercați din nou.",
"2eaf6ee97d984598c95e0494d72aa492":"A apărut o eroare la remiterea solicitării de cumpărare.",
"148f847d3beccaa67fcd5096ae3602d3":"A apărut o eroare la actualizarea numelui de card. Încercați din nou.",
"4ecf92b6918afa4c7b83fe381a4790e6":"A apărut o eroare la actualizarea numelui de card. Încercați din nou.",
"de8cc976f7af9edf189904239e6240da":"A apărut o eroare la actualizarea echipelor. Încercați din nou.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"A apărut o eroare la adăugarea administratorului, încercați din nou.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"A apărut o eroare la dezactivarea echipei dvs. Încercați din nou.",
"3f686a97a1b9fcdd73a40d2005693105":"A apărut o eroare, încercați din nou.",
"2f38263789163cf8ee30c8ae88852c82":"A apărut o eroare la publicarea listei dvs.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"A apărut o eroare la eliminarea administratorului, încercați din nou.",
"007a647254e1f85b6c1e789004e05df5":"A apărut o eroare la salvarea setărilor",
"c3072d080b587d70f85d68ec089f028f":"A apărut o eroare la salvarea setărilor de la echipa dvs. Încercați din nou.",
"608cd0e109c3e51b0425df8921e4181d":"A apărut o eroare la setările de la maparea furnizorului. Încercați din nou.",
"177cefc68dd318ea75673326e77d8206":"A apărut o eroare la nepublicarea listei dvs.",
"255d22dc4f0e338a4cf53d5b7256e695":"A apărut o eroare care preluarea numelui persoanei către care redirecționați solicitările de aprobare.",
"39ac4ec73fed8a981ad1857d989edfcb":"Această sumă depășește limita de aprobare a firmei.",
"494ce3f598cbd97afe99774b5975a4bc":"Această sumă depășește limita de aprobare a echipei.",
"f2a42214e7c480c5596439becfd0b7bf":"Acest câmp este obligatoriu.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Această setare va afecta doar cardurile noi",
"881b7596cf985d9c246b05fcab930632":"Acest card sursă a fost adăugat echipei.",
"a1b1aa354d06e7815b78c6abb7448e84":"Acest card sursă a fost eliminat de la echipă.",
"b348b24fc4839ee15ed0aa424689bae3":"Această echipă trebuie să aibă un administrator sau un manager înainte ca dvs. să puteți invita un membru.",
"188b9ed447a43916f9e323623ea3a993":"Pentru a accepta invitația",
"30286e57da108ee7dbc0f8fbb62d4fff":"Total cheltuit ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Urmăriți cheltuielile pe echipe, proiecte și abonamente.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Urmăriți activitatea cardurilor active ale echipei dvs., întrerupeți cheltuielile și dezactivați cardurile care nu mai sunt necesare echipei.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Resetare parola Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Încercați din nou",
"80196bc616d4a44a4997a2ea127f7b83":"Încercați din nou",
"f96bbe81618b5f4a1807989eb4f69e24":"Încercați din nou",
"d498d7d261b7ca56e07218d1a0dbdd49":"O, nu! A apărut o eroare. Încercați din nou.",
"ce2e475291003852c71b01959698c496":"Imposibil de obținut mesaje la solicitarea {title}, încercați din nou",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Dezarhivați",
"d2e50be5287fd8fb436d0acbeb811864":"Identificator unic",
"0e2fbce967f4a209215b6f48f8eddac3":"Reluare creare card",
"f2315dec5dd07802b77af3a3a5414a7d":"Până la 4 ani în viitor.",
"d2f30a2f0c955271e9af123dceca501a":"Până la {label} în viitor.",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Actualizați cardul sursă",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Actualizați cardul sursă",
"c8f6bc61a8982158e408194e48a274d9":"Încărcarea nu a reușit, încercați din nou.",
"8a86a60cb836bdb5225cad20c5151078":"Încărcați nota de recepție",
"e448a1ce42de0cf526200331b4ad8b23":"Utilizator",
"75edcc8fc27f41c99e28b48ff250c7f8":"Detalii utilizator",
"d1651c2afdb6c92621adbb707e4753d0":"Utilizator salvat.",
"5fa55883b7413526b441e999cb8089b3":"Utilizator",
"7a892b81fe2aae295f1b2d38bb67360f":"Utilizator",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Utilizatorii cu carduri active nu pot fi eliminați. Anulați cardurile pentru a elimina acest utilizator.",
"d40fa5935f1614925cf46259c886814a":"Vizualizați activitatea cardului",
"625419857efc7e72d162c9f8204109df":"Vizualizați cardul în portofel",
"85a5e817826415651ed4991a76bb5385":"Vizualizați conversaţia",
"09fc124dc9b8b14d6227a92bb0a4566f":"Vizualizați conversația",
"d26cfc6d8f420f259f434b79593e9eb4":"Vizualizați chitanța",
"c7d0394c3206a3b74afbeabc41cc639c":"Vizualizați chitanța",
"01650aa52c5e5a22d3f38afd2c7f9411":"Vizualizați chitanța",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Vizualizați chitanța",
"b2d141897f73fc2a198ee1a7a438f89b":"Vizualizați solicitarea",
"02fa2f56ef72845a9dfeabea22948061":"Vizualizați detaliile echipei",
"2d9c55650b17dd605a1388f46c81ba38":"Card virtual",
"d0ec076e4600e854588f8da265208c0b":"Opțiuni card virtual",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Portofel",
"5279a2220e4cfb0d8b8b357311251cbe":"Portofel",
"b1746b5806659e3189f837d2dd54e08c":"Portofel",
"ff306d5b34ee993f9618c1f3c0209a39":"Nu putem arhiva solicitarea în acest moment. Încercați din nou mai târziu.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Nu putem anula solicitarea în acest moment. Încercați din nou mai târziu.",
"a813d44f6bb26c5da09671e532abe022":"Nu putem dezarhiva solicitarea în acest moment. Încercați din nou mai târziu.",
"d4c7310eafc0d0da89f20596a22a3abe":"Nu am reușit să creăm o solicitare în acest moment. Încercați din nou!",
"dca679c10accb1db481a57a0e1ba08e2":"Nu am reușit să creăm o solicitare în acest moment. Încercați din nou!",
"db5584a2ef29b04959bce7f93db54c56":"Nu putem comuta echipa în acest moment. Încercați din nou mai târziu.",
"f2af2c261fabddb01766f4f974b85a62":"Săptămânaly",
"e181ed0723a00ca7558b9d5d5258732b":"Bun venit pe Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Păstrăm legătura!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Ce card de credit folosește compania dvs.?",
"968d94729053e166a76ea577ce3022e2":"E-mail de lucru",
"588a075e8694ff5cc86815697e1586bc":"Lucrați la fel ca echipele dvs. de afaceri.",
"182ae1eb5226e0646b4033883cc0900c":"An",
"fe88fe7094c4ced427874b3d22326c8d":"Da",
"9a2d4e3b22ad0567165eb0f661f952ad":"Da, anulați cardul",
"96b88e530375237cbc08138d9fb4109c":"Da, eliminați această listă",
"fd3780f468c531f2bc1b47b8748b5a66":"Puteți crea coduri de contabilitate din setările firmei.",
"ab3ac6f945d9f756763d49257fd924dc":"Puteți gestiona coduri de contabilitate din setările firmei.",
"c919dae93fe780061629c255eb358140":"Nu puteți elimina singurul card sursă conectat la această echipă. Adăugați un alt card pentru a-l elimina pe acesta.",
"11de97e873b66d91a77db7a05da32202":"Puteți promova un deținător de echipă prezentat mai jos ca administrator de firmă sau să invitați un utilizator nou.",
"0ab790f08b2780be001b79d2ccd75c4e":"Nu aveți niciun cod de contabilitate creat pentru acest cont.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Nu aveți nicio listă de codificare creată.",
"44e470218124a50c117870467530752e":"Ați introdus o adresă de e-mail sau o parolă incorecte.",
"4b687d0730822fdbeea27c174e47f227":"Nu aveți nicio aprobare aprobată.",
"020f03c17b79af66f335b6af868c66c0":"Nu aveți nicio aprobare anulată.",
"def0b76f03e7c622341a7d8e08aaaae7":"Nu aveți nicio aprobare refuzată.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Nu aveți nicio aprobare în așteptare.",
"7c034d6104f80a523b90bc607fc06608":"Nu aveți niciun card virtual în portofel. Creați o solicitare",
"0aed9198d61d161468d541dbbc9f477a":"Redirecționați cât lipsiți de la birou la {oooForwardToFullName} programat până când {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Ați primit un card virtual nou:",
"04eeaf814767c30aa41577f3b11f916a":"Ați solicitat recent o resetare a parolei contului dvs. Go. Pentru a finaliza procesul:",
"09ddf249810b9af7d3e1f4e537da500d":"Ați încercat să accesați o pagină care nu există. Asigurați-vă că aveți link-ul corect.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Sunteți invitat să vă alăturați echipei pe",
"02c6f3a7cf31a87f4f39f992509f8c44":"Ați fost adăugat ca administrator pentru firma dvs.",
"cf121a14590f109dafcae138265e353b":"Ați fost adăugat la o echipă nouă!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Ați fost adăugat la echipă",
"d3cef66a026f776903b342d450e5a832":"Solicitarea cardului dvs. Go a fost refuzată",
"003f87c21a29220e661fc302b885fd5a":"Contul dvs. a fost dezactivat. Contactați firma dvs. sau administratorul echipei.",
"a52b1f0da15dce68007f524e48238143":"Banca dvs. a returnat o eroare la adăugarea acestui card. Contactați banca dvs. pentru a soluționa problema și încercați din nou.",
"4d883b173167297463aeac86957c26fe":"Adresa dvs. de e-mail a fost utilizată pentru a crea un cont pe Tradeshift Go. Pentru a verifica adresa dvs. de e-mail și pentru a finaliza înregistrarea",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Setările privind cât lipsiți de la birou au fost salvate.",
"542f08554110a12de0c23afd78f38e0e":"Parola ta a fost resetată. Conectați-vă.",
"49b2dcbe5d461f35c37986962b5bd07a":"Solicitarea dvs. a fost refuzată",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Sunteți pe listă!",
"32f87c5ddeaee35b9178785ef4eb9313":"Cod Zip",
"c6974a3d5311374c697625f2a3234bce":"o dată",
"f86980f5ef00b645a6e4a0b59738a954":"o persoană pe care nu am reușit să o căutăm",
"7066f854917b39bafe0bbecbb8bd6377":"aprobă solicitările",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"pentru",
"49b171230ab9bcdd8b631c70cf397efb":"pentru <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"pentru firma dvs.",
"35ad86f0b035d8156390670d60801e7f":"pentru {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"pentru {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"de la",
"c1407aa5424f339dedf67bcac597cf71":"a setat cererile de aprobare care vă vor fi redirecționate în timpul",
"67a948b8af3284a401ebefcb1b4723ab":"așteaptă să aprobați",
"9ca64449d64b98fef7859e22bc23c4dc":"face solicitări",
"ac850ab84428e9eb6a57e5d813de5e45":"gestionează echipa",
"2168839a1f4c73ef5c89c9e930148e7d":"ignorați acest e-mail. Nu este necesară nicio acțiune",
"85431c325faab98e02daa60151465144":"trimis această invitație",
"81c504fd54119e7e580eb05dedef46d0":"trimis această invitație",
"d74545060b38445ef4cc49fbb8d79876":"trimis această invitație.",
"2d96c53ee7ee0a54aadad19bfce0697d":"a apărut o eroare la obținerea cardurilor dvs. sursă",
"b2fda295788d579fffd3f43b7737fd63":"pentru a vă înscrie cardul de corporație cu Go, pentru a le oferi angajaților dvs. acces simplu și sigur la card",
"d35bd5c5d74af765d45cef3c4e78f52a":"pentru a face achiziții comerciale folosind carduri virtuale Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"pentru a face achiziții comerciale folosind carduri virtuale Go",
"9546989e681a7e25fbdfe7631e9ebc03":"pentru a face achiziții comerciale folosind carduri virtuale Go.",
"6174e0a3d26409dd6e410052e2805660":"pentru a începe să utilizați cardurile Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"dezactivați funcția „în afara serviciului”",
"fd2c1099fc9f3831d7f24195aaa11269":"dumneavoastră@firma.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} este responsabil pentru aprobarea solicitărilor din partea altor membri ai echipei. Atribuiți un aprobator nou membrilor afectați pentru a continua.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} a fost invitat cu succes${this.teamId ? '.' : ' to the team.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} a fost eliminat",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} anulat solicitarea pentru",
"9567a2d4cacd58fb4f096132d26c1660":"{username} este responsabil pentru aprobarea solicitărilor din partea altor membri ai echipei. Atribuiți un aprobator nou membrilor afectați pentru a continua.",
};
