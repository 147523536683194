/* @ngInject */
function TaskService(HttpService) {
	const service = {};

	service.completeTask = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/tasks/' + options.taskId + '/complete')
			.post();
	};
	// TODO: might want to change this to /rest/approvals or something
	service.getCount = function () {
		return HttpService.tradeshiftGo().path('/rest/tasks/count').params({ taskType: 'approvals' }).get();
	};
	service.getTask = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/tasks/' + options.taskId)
			.get();
	};
	service.getTasks = function (state) {
		return HttpService.tradeshiftGo()
			.path('/rest/tasks')
			.params({
				type: 'approvals',
				state,
			})
			.get();
	};
	service.getVirtualCardTask = function (options) {
		return HttpService.tradeshiftGo().path(`/rest/tasks/card/${options.virtualCardId}`).get();
	};

	return service;
}

module.exports = TaskService;
