"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.footerInitialState = void 0;
exports.footerSlice = footerSlice;
exports.footerInitialState = {
    footer: {
        buttons: [],
    },
};
function footerSlice(set) {
    return {
        ...exports.footerInitialState,
        setFooterButtons(buttons) {
            set((state) => {
                state.footer.buttons = buttons;
                return state;
            }, false, '[setFooterButtons]');
        },
    };
}
