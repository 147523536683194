module.exports = {
"d447f4a1755a0025dddd2f736255879f":"Núm. de transacciones",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minuto",
"ce09c436b49af331a32f1152ede7dd18":"1. Solicitar financiación",
"d2226752d3065fbad4744924b4a0ee73":"2. Revisar y aprobar",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Revisar y aprobar",
"82bab92f1c8030888550b51521266c53":"3. Pagar con tarjeta virtual",
"2ecd62e02e507f8e839a55eac0a04886":"Ya existe una entrada de código con el ID'{entryId}'.",
"5dfd25849740fbce8147838b0d5094eb":"Nombre sencillo, p. ej., Tarjeta de marketing",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Un miembro de nuestro equipo de logros de clientes se pondrá en contacto en breve.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Una persona que no hemos podido encontrar",
"976b50e70e4433e2786233979d243445":"Ya existe un equipo con este nombre.",
"35b61d21ac11d5fc31ebe58541263445":"Aceptar la invitación a unirse",
"a372cb2d4204654f52cd9170fb10d374":"Códigos de contabilidad",
"110fc66010b6b91cebee7a59a2067aab":"Códigos de contabilidad",
"db862bb04dbdc6d04e47063f33be8865":"Los códigos de contabilidad se gestionan mediante listas de codificación y entradas en listas.",
"d6e3848e824f077396be4351eb58edd2":"Acción necesaria: aprobar/rechazar nueva solicitud por {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Acciones",
"476e728ea824229f52438e543818bcdd":"Acciones",
"3117746cb7d4ab815ec98274ec4398d5":"Acciones",
"3ab09b66a7f16219d8135977a2a76d10":"Acciones",
"ffafa66eaab0409a2e90aaeb43796def":"Activas",
"0447e0aa5d00dbdab11b5546793c4479":"Activas",
"8ee51d21b05466f7d962d4ff5a0b384e":"Tarjetas activas",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Tarjetas virtuales activas",
"89c6ec05861aa914304ba35bc6744124":"Tarjetas activas",
"5280659bcecaf8344c57790f9962a9a2":"Actividad",
"8a15368ae286fa64e1cb7624cd410fc6":"Agregar lista de codificación",
"8ded7e09e86be231b1165308329ac76d":"Agregar lista",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Agregar administrador",
"695bb4bc29e2df769ddf7427ab71012a":"Agregar aprobador",
"4a1d4516cab00b7c5ecdb150e346e5db":"Agregar aprobador",
"56869851b27c2915f5a7fe6a33b91ac7":"Agregar lista de codificación",
"8f8691057c3994aa61f9994202680c87":"Agregar lista de codificación",
"69f7a406b79d19bf8082ed245ab29123":"Agregar lista de codificación",
"48a92ca1cc63018707be99b13196369f":"Agregar Administrador de la empresa",
"a679db9ff80d0e5769ae1ba311c7916f":"Agregar dominio",
"8f5e8078be77146ba7f3be49ef948906":"Agregar entrada",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Agregar mensaje",
"f8a4b14162e19995074e63135b65c84d":"Agregar una nueva tarjeta",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Agregar personas",
"c670549d0b898bb6a571eb2cd7804a6e":"Agregar recibo",
"eb937098e77d8a6b0565ed72357dcaae":"Agregar recibo",
"1e154f31c31b63a459f3956e380b3ef9":"Agregar seleccionado",
"ba24db9956f156a18b32e653552ff682":"Agregar personas en este equipo",
"52bd93fb5489b25167bb541f7c6504ae":"Agregar tarjeta de origen",
"21ab3f08eb507dbc76df166eafeb393a":"Se ha agregado {fullName} como aprobador.",
"f879c06507d3076bbf52ea4fd2df850e":"Detalles de tarjeta adicionales",
"21e934ae30eece4a894337701f497638":"Todas",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Todas las tarjetas",
"8fac71195e2b506e029752e4864b6a5d":"Todos los miembros",
"f9708417df16227742e0545a8642eea7":"Todos los usuarios disponibles ya se han añadido a esta tarjeta.",
"cb5abf070f8cba7982205038baab77bb":"Todas las tarjetas",
"dae7d602eec2c84dea0d80ae25e56e6d":"Todas las tarjetas",
"0b4bdfde6578668b6018d3615a1ad30f":"Todos los centros de costes",
"97b875b81f54b4432d998a3e47d949cc":"Todos los equipos",
"f0ae83969f7c20a1964caf5156dae966":"Todos los equipos",
"d87455601a00282078582b36a3825b7d":"Todos los equipos",
"24c7b7f55882ea94a525392ca8218fa0":"Todos los equipos",
"8f860e35b64a8f447bded1e5ea66a2a8":"Todas las tarjetas virtuales",
"e403956ecaa6fb33ec1fef5c49d71042":"Todas las tarjetas virtuales",
"4e6f4e8f25f61d40e1eba77de94f6458":"Toda la información financiera está encriptada y en servidores en ubicaciones seguras.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Cantidad",
"a4d099d0dbe3889b0995546e6ed28932":"Cantidad",
"6c0ef32aae76bc31a61e2124f542bdf1":"Cantidad",
"adec89c93525f1a8d1c64d856f405e27":"Cantidad: descendente",
"18e632a170203894f40deed1d33ee552":"Cantidad: ascendente",
"a7944575cafef9126a499a1b06999fe3":"Se ha producido un error. Por favor, inténtelo de nuevo más tarde.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Aplicar",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Opciones de aprobación",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Configuración de aprobación",
"492eedd88cdd7bb45f39c6594af748c4":"Límite de aprobación",
"437d490afc8d226f8c7d544f4e35d011":"Límite de aprobación (€)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Aprobaciones",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Aprobaciones",
"17adb7fe866e32055b1469fdb7e82795":"Valores de aprobaciones",
"7125aa13db437084e1d27d3ff38f22e2":"Aprobar",
"84df787ff5746f9dc3cf0146becef4fd":"Aprobar solicitud",
"7a568654d00245b0bab6c90a1b67d196":"Aprobada",
"179c3e187ad8edeb8348cff222b938bf":"Aprobada",
"ad058d17725870df9abbd4f5ec217636":"Aprobado ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Importe aprobado",
"4d58ba5c0e03fec1556c243be106e4a3":"Aprobado por",
"7d3d697fc99f6c4641b326623743f7b7":"Aprobado { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Aprobador",
"8c45e443fc6748190719d79a092e2528":"Los aprobadores tienen un límite máximo de aprobación",
"4fe56aa6456c108cd288a4ec8acf435f":"Archivar",
"a9b6f93de9630064be9809130aa1e195":"¿Seguro que quieres tomar el control del gasto de la tarjeta?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"¿Seguro que quieres cancelar esta solicitud?",
"301bfc2ce058f49e0704fec3d162b5b5":"¿Seguro que quieres volver a asignarte esta solicitud?",
"2181faf8d9973d1f4138e401a762086e":"¿Seguro que quieres eliminar ${firstName} ${lastName} para que deje de ser administrador de la empresa?",
"b982277f29055d0ef6e295fe2006d9e0":"¿Seguro que quieres eliminar {firstName} del equipo?",
"456c0ccff14acb808583cd9dece857b6":"Asignado a",
"bde315b9cf7fd55f4b65aabbbf38d462":"Para usar la función Ausente de la oficina, debe haber disponible al menos un encargado o propietario del equipo.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Autenticación",
"1961ec69dd0c8b5718c831312e7f3d0f":"Recopilación automatizada de recibos",
"e39f2477cf34d726936a5b5c2b48a4ad":"Crear automáticamente precisos informes de conciliación, que incluyen información de compradores, aprobadores, recibos, códigos de cuenta, etc.",
"434ca07dfde0eaee14f094b7c75a6eae":"Disponible",
"eb85d8f50f2c89871b794f7d54279b22":"Saldo disponible",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Disponible { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Atrás",
"c8258f8cf9e20f6bbb89d957de40e820":"Seguridad equiparable a la cualquier banco",
"d46c22a3f36dce568c07fcea4368b231":"Configuración básica",
"fb481c8e517016094b4fdf618c5fa088":"Dirección de facturación",
"79a9f821923c5a04c076902fef684281":"Dirección de facturación",
"877a08b8347052c83aa0b689baea81e7":"Dirección de facturación",
"97f018458e2627fac37d24846c7b8922":"Al continuar la creación de la cuenta y utilizar Tradeshift Go, se acepta estar de acuerdo con nuestro",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Al continuar la creación de la cuenta y utilizar Tradeshift Go, se acepta estar de acuerdo con nuestro",
"912d1864f0068c764bf4784020795d49":"NOMBRE DEL TITULAR",
"d53474c7fd99fbeb69481ce9d358aa09":"Puede solicitar tarjetas y tiene una cartera para todas sus tarjetas. Cada miembro tiene un aprobador predeterminado (encargado) para sus solicitudes.",
"f30df2cab105ed3f8b4264aeb9800abd":"Puede aprobar solicitudes y crear tarjetas para los miembros que gestione. Los gestores tienen un panel para controlar el gasto de los miembros que gestionan.",
"03ed9eccc71ef59165292eac542d3050":"Pueden ver todas las actividades de solicitudes y gastos del equipo. Los propietarios de equipo también tienen un panel de equipo para controlar el gasto y la cartera del equipo, y ver todas las tarjetas activas.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Este usuario no se puede eliminar. Necesitas al menos un propietario del equipo o un gestor del equipo.",
"a0b70642629a55ea0ab6fa3414d342e0":"No se puede eliminar un usuario que tenga solicitudes pendientes.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Cancelar",
"5446624d3c364ac05ddf8d6663da2796":"Cancelar tarjeta",
"53f3815c4fa3340b29efd81e627d1e6b":"Cancelar tarjeta",
"12257ea2a871b7fab72245b5aafe6424":"Cancelar solicitud",
"2aafb7eb4d56a516c4bb0754f0812df1":"Cancelar tarjeta",
"caae12601b01e2325161e95107f9e332":"Cancelar tarjeta",
"6ad1e57b1e4c7accbe166c948883077d":"Cancelar con un clic, si hay algún problema",
"f6527e49e6d5c989624507a583e23f39":"Cancelada ({count})",
"1ad3bf5121641dda511f42942af05a90":"La cancelación de esta tarjeta no revierte ningún cargo pendiente. Por favor, consulta la liquidación",
"aebd3826a195afad0a93050a7cc5d73d":"La cancelación de esta tarjeta no revierte ningún cargo pendiente. Consulta los registros de liquidación de dichos cargos en el estado de la tarjeta de crédito por parte del proveedor de la misma.",
"a5b5136947da562f91b75daf8156f50e":"Detalles de la tarjeta",
"9ed31ad23a1578ce480d31207decf1d3":"Configuración de la tarjeta",
"def15f2e007fc69886230fdb2511305c":"Detalles de la tarjeta",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Detalles de la tarjeta",
"67cfd260078ca32027c2fa4a4a2d29f4":"Titular de la tarjeta",
"4458819ea60c68c46e551a8f5cc163cd":"Nombre de la tarjeta",
"0858bcbd506fa3240b165e59d2f18aae":"Nombre de la tarjeta",
"5212736dd18ee2c31f3650ad2c2c7130":"Nombre de la tarjeta actualizado.",
"c96d62fa510c3356dc73a91d1a80e262":"Número de la tarjeta",
"41307fee4c32aefb5383a98fe3d6e45e":"Tipo de tarjeta",
"3f8045c3358621dad4346a44de0c05a8":"Nombre del titular",
"879a6b452033cf5f3a4bb3e1837a0386":"Ciudad",
"593334553ef852024e172505ec3c5f21":"Haz clic aquí",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Codificación",
"df931751d5790d74fa46707aaf7a26ae":"Código 1",
"7ac7154bc776aff52a71b0d83b860561":"Código 2",
"586fcf19136ae5e26795e9055b2e2995":"Código 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Código 4",
"379bb8e71bb090badebbb0751de86f11":"Código 5",
"3e84d13627b3d090ab2b8470af98340a":"Código 6",
"33b94b581f3792049992663396e51cb8":"Código 7",
"b73fbc32e439655833ee99126dd0e55f":"Código 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Lista de codificación",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Entrada de código",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Entrada de código eliminada.",
"cada62a39ee72d790dcdbb25160f8783":"Entrada de código guardada.",
"f645bf50888943ee1384693e56a0f475":"Nombre de lista de codificación",
"a999182acbcd77c15299f79181b630be":"Lista de códigos guardada.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Listas de codificación",
"d0b96596ce6baec5aabb8a0690ab30e1":"Comentarios",
"ff9cb8842f832d21fb160b3d3784ce79":"Empresa",
"715b59a64e686eb91a21114644ffe39e":"El administrador de la empresa se ha eliminado",
"8758e9a01253c097a42393fda9610715":"Panel de la empresa",
"e118b41009918b326db6746856831cbc":"ID empresa",
"4dba88b05b3fa8875128e19e88c512b3":"Configuración de la empresa",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Configuración de la empresa",
"f2ea6d33de8182d97bf2f13354af4893":"Configuración de la empresa: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Administradores de la empresa",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Límite de aprobación de la empresa",
"7e2f778da14a726b53e05926a3483f2b":"Tarjeta de crédito de la empresa",
"7313fd1db3d63858586d1c0f89251d6e":"Nombre de la empresa",
"07721e91dd10ff8831f0d24e47cc1eab":"Nombre de la empresa",
"203221610ca4d347063c037e1a732ae8":"La configuración de la empresa se ha guardado.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Confirmar acción",
"d694cec09cdbc02b1ffe22642a63faa1":"Confirmar contraseña",
"512801312a133f29974f2259a455f39b":"Confirmar contraseña",
"f54c38ed0b9869d929ffc0bd5310131f":"Confirmación: tu cuenta se ha creado en el Equipo {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Consultar al administrador de la empresa sobre la creación de códigos de contabilidad.",
"7a6a4de077398e8e138aec36e3b3d305":"Continuar con SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Reservados todos los derechos.",
"51f0d8e04f6ee626b866b37833dc2b61":"Centro de costos",
"e043f60a02f157d0f31a42f0db67cc87":"No se ha podido invitar {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Tu mensaje no se ha podido enviar; vuelve a intentarlo.",
"ae28083f22a0b2fc5637614bce0976e0":"Crear lista personalizada",
"738078dc992208e05406f935995dc0c3":"Crear lista personalizada",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Crear nueva lista",
"0f5783c3921b3d7398d281644bbe5bde":"Crear una solicitud",
"6d62f934f2304701f88bdcfbc688de7e":"Crear cuenta",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Regístrate",
"f6b7d0d8531ea268f988005dd2147b2b":"Crear tarjeta",
"1a083675d1453448e4645403bbe52dd9":"Crear lista de personalizada",
"c10ab11bdfb001da74850ed89c45482f":"Crear solicitud nueva",
"43a9c06398408bfe77d06ffc2c1c3b87":"Crear solicitud nueva",
"68bf17fd3f54a89c0a84224e2f9a971c":"Crear nuevo equipo",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Crear un equipo",
"3b8e665101b9b3657f8a68c5305947b7":"Creado",
"40e91f2907181ffa8e62567b3a407e60":"Creado por",
"8929f7aacb969ef6353aba9903eb362d":"Tarjeta de crédito añadida.",
"d86688c26e8ac5eb9facc971b6fe5155":"Moneda",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Moneda",
"500ded53f64e737a5e4337675ac9b7ce":"Nombre de lista personalizada",
"b79a95f04fd829fb2e8d0313e522a94d":"Diario",
"ef445eab224fc599e4103c9b1745a4e0":"Panel",
"788452c3ad2b1450b6d8d116b863e635":"Panel",
"fb7add662cf7042355319a975fab6ae3":"Cifrado de datos",
"6bded249dbfafd14490eea8dd37e1f51":"Fecha",
"46e908a57cb09ef947af8c4a93a09716":"Fecha",
"e4213104a55eceb60e00408699438dcd":"Desactivar equipo",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Al desactivar un equipo se cancelan todas las tarjetas virtuales activas y ninguno de los miembros, encargados ni propietarios del equipo podrá acceder al equipo. Sólo tú u otro administrador de la empresa puede volver a activar un equipo. ¿Seguro?",
"0d0d5677bee2b99884802638e507160a":"Rechazar",
"b5e3483aae9d36ce1440fe02904d6da1":"Rechazar solicitud",
"00f3db97287cb17ee9c2a131f2141a65":"Rechazada",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Rechazada ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Aprobador predeterminado",
"b77589ac3ac2317cfc96d7013658d5c9":"Eliminar ID de codificación: { entryId }, Entrada: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Extractos e informes detallados",
"5234dfc4220ccd18a8b62fe84e372248":"Detalles",
"37929896817a6744718004374bb42fd8":"Detalles",
"dd0a6194e8a6ae14f3e1379a1640de15":"¿Querías eliminar {fullName} ({email}) de la empresa?",
"1a98048b34090da8b199135ccb4c1186":"Un número y CVC  distintos ocultan los detalles reales de la tarjeta",
"3cf132b60fe16b816ca227bbcad60537":"No cancelar",
"dee57797e14ccf852aa2bc545bafdf6d":"Descargar",
"375ae8224d7d35393974775cebe8605a":"Descargar lista de usuarios",
"54b3bfd45a293b441d441aab1a50e644":"Descargar informe",
"60cf3b94e5df14a504825068928fc118":"Descargar CSV del informe",
"7341a4c73c0b688484304c37da4a6d8e":"FECHA DE CADUCIDAD",
"f628f8ad44fbd6c70c99fc778407b666":"Buscar fácilmente transacciones del historial y explorar tendencias en el gasto de los empleados durante cualquier periodo de tiempo.",
"94bb409d034ea20697ae09d812e55365":"Modificar lista personalizada",
"2974e704676cb79666bc236d8658fa97":"Editar perfil",
"9ff205f99ed4002d049f855f965ca6d8":"Editar usuario",
"3d7a4a9f4e70f57463e9a97caadb8205":"Correo electrónico",
"0534c00af758d42ebd8b0bd772eab074":"Correo electrónico",
"0c9aa1fbdf925065d25161eab5e51d95":"Correo electrónico",
"ea78fac68766a48b1b0f41305b7e9898":"Correo electrónico",
"dc6a8f782e00c1bf05c48f3a8758e26c":"El empleado solicita fondos de la tarjeta de la empresa para realizar la compra. Los fondos solicitados pueden tener establecido límite de gasto.",
"829cff16ce0b13810c914c161b2a9fba":"Habilitar funciones de usuario get alternativas",
"741387c7bd71e2b4a9601af9a6b9490e":"Habilitar reenvío automático de solicitudes de aprobación",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Habilitar fecha de caducidad predeterminada para tarjeta virtual",
"bb319710dad93e78cc6f58c615bdf210":"Habilitar listas blancas de dominios de correo para usuarios invitados",
"4700ced24cd278c3149ffa983006035c":"Habilitar filtrado por centro de costes",
"3fe06ef8f551c4cb4c0375362e25521d":"Habilitar opción de globalización en usuario aparte",
"2787d4bc9752272ac36b499ce7ad93ab":"Habilitar tarjetas virtuales multiuso",
"6ee44e2280c9ce5009568846cbc3dd14":"Habilitar Ausente de la oficina",
"1941ca87053df0cebc6091ede92c18ea":"Habilitar Ver personas para los usuarios administradores de la empresa",
"e50282c2ec7afb948d2c0b428db5d5ff":"Habilitar subir recibo para transacciones",
"268694cb2172918fe60f835919bf20ec":"Habilitar subir recibo en tabla de liquidaciones",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Habilitar autoaprobación",
"d17c7acf598b569cce8a2bb97e0bb215":"Habilitar tarjetas virtuales de un solo uso",
"cd731ca218c9e14caabefafe003cfa6d":"Habilitar tarjetas virtuales de suscripción",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Finalizar reenvío el",
"c1f665a814894b17d603b7052b631d90":"Inscribir una tarjeta nueva",
"6b6cea93e07e596be70f7b76801e1516":"Incorporar una tarjeta nueva",
"7d6ac1ac9cba1620b61908842ebb106b":"Inscribir tarjeta origen",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Inscribir tarjeta origen",
"21a642b339c3eabf2789c0b05dcf828b":"Inscribe tu primera tarjeta corporativo y EMPIEZA.",
"6efa8dca4fba23a190838746879fd704":"Especifica un ID numérico",
"02c3457577da9dc8dba6e6f9380a248e":"Escribir un nombre único de equipo",
"40feb3fd1fa529cb599aeac9607664a4":"Añadir nueva entrada",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Ejemplo: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Mes de caducidad",
"b8bc74aeeb2de3492891c97361de107d":"Año de caducidad",
"6a0b8d521500014d99dd192d8ce8403d":"Fecha de caducidad",
"8cf274169a732340430fe7cc5236e0e7":"El tamaño del archivo debe ser inferior a {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Nombre",
"7458b55e967e51791d98e77458631e44":"Nombre",
"3bbe52e71bc5c212cea77983f1a5b748":"Nombre",
"9b49c88fa20cc006baaac15c857c8781":"Para compras únicas o múltiples",
"bb7a2907073bd760f4e82097132637b1":"Contraseña olvidada",
"26bdfaa592f3629273f13c22435b323d":"He olvidado la contraseña",
"e11d8d67b14d2ccb5abdcf4250c60004":"Reenviar",
"3f9fe6adc14ce5776097170653a24462":"Reenviar solicitud",
"b774e35683f5737c36bf0cf3d6785973":"Reenviar solicitud",
"628faa5054a912b1e470414f77057e6b":"Reenviar solicitud",
"852b4065e1837fdc77940b6411a6d902":"Protección contra fraude",
"616551bd10d95aca439401d6a7769d96":"Importe de transporte",
"a65a230918e9c11e1ef8824745e26a3d":"Iniciación",
"7d4c4466b7716347ce3673acaa1983f7":"Aprovecha Go al máximo",
"11415a07658cfef80eb166896d77ad98":"Incluso más información sobre gastos. Tradeshift Go ahora se ajusta mejor a la forma a la que los equipos empresariales les gusta trabajar.",
"ac0ff5d14286ef74614a9c6021f08f69":"Puedes supervisar los gastos en tiempo real y hacer un seguimiento del presupuesto, obtener información importante y el desglose de toda la actividad de compras.",
"db9071ab833054307252a936e7f60e1b":"Comenzar",
"24dc4bb00841b004031fca3a9bb9bf51":"Solicitud de tarjeta Go cancelada por {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go recuerda a los empleados los recibos que falten, que se pueden adjuntar simplemente respondiendo a la notificación o, en caso de un recibo por correo, reenviándolo a Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go funciona con tu red de tarjetas de crédito para garantizar los mismos estándares de prevención de fraude que se pueden esperar al trabajar con las instituciones financieras de mayor reconocimiento mundial.",
"bc2a9dc3bcb628316ab99a22d24d027c":"¡Buenas noticias! Tu solicitud de compra ha sido aprobada en Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"¿Tienes preguntas o comentarios? Envíalos a",
"9df1d3de68d663b848101df3c7b58e39":"Cómo funciona",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Estoy seguro",
"84074732eb8d1b4094404cd750baf949":"Si no hay valor, se aplica el límite de la empresa",
"138e8bd6102a7eea70a123ff03b1cd79":"Si no hay valor, se aplica el límite de equipo",
"e5756af4d7e5ad1236ccfbb858428421":"Si no te registraste en Tradeshift Go usando esta dirección de correo",
"4f004f75f47dbfecdb6d09990596e530":"Si aún no te has registrado, te enviaremos un correo de activación con detalles sobre cómo continuar.",
"123837f267de602fb05361b3b1197c1e":"Si {username} coincide con una cuenta existente, te enviaremos un correo electrónico con información sobre cómo continuar. Si pasados unos minutos no puedes ver el correo en tu bandeja de entrada, revisa tu directorio de correo no deseado.",
"45efd96cd93525a9c17222217a37d324":"Se aceptan formatos de archivo en imagen o PDF",
"fa6ae823f9ab8bf91c246efe2517956c":"Importar la configuración de QuickBooks, NetSuite o Xero directamente en Go. Exportar las transacciones más recientes al sistema de contabilidad de la empresa con un solo clic.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inactiva",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"El texto introducido contiene un carácter que no es válido.",
"62c7be09da43f8f96a60ef3967eddc2c":"Presentación de un Tradeshift Go completamente nuevo.",
"19746bcbed8b8bcb1a9b83efca722077":"El dominio del correo no es válido",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Invitar a personas",
"3809b41b14c55c3fcf54cd813d8e21f5":"Invitar a equipo",
"fc90a11805961b0d7b5a5545e4d9bc05":"Invitar a usuario",
"6f7f4acde92d267417f5d8a60d32400a":"Invitar a más gente",
"09ecd2ca15bb5c38dc10f653118346cd":"Invitar a nuevo usuario administrador",
"3f576ce504f53f972442a63a170c5418":"Invitar a nuevo {role}",
"0215e052437ceea4e5cd562431b34ced":"Invitar a gente",
"8aaf2c77df644b7e8524120f66da7d5f":"Importe de factura",
"1b7dc8290f1205f092cc9133e0cde385":"Fecha de la factura",
"1a6fb210308df8e0135e49e76aaff31e":"Número de factura",
"613da3d0cd586ef0360e0d93eace9508":"Emitido el",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Hace falta",
"3322b671105c65511fbab1966b7fdcf6":"Idioma",
"b1d946192795a58aea6a61f4f923acb7":"Últimos cuatro dígitos",
"b3c6b8db4ef6dcdcb451303e65548816":"Últimos cuatro dígitos",
"0382123b9c847000fceff2484c665e39":"Últimos cuatro dígitos",
"19f7c0055e150e9e3e66e85c2993799c":"Apellidos",
"662c84bede8a7bf91ab15bcb73b48b49":"Apellidos",
"cff8fbef5aac2523344b9535bd56973c":"Apellidos",
"c3510b2184a92ff12e07a2d1b9a461e6":"Dejar que los empleados soliciten acceso a la tarjeta de la empresa sin que vean los detalles de la tarjeta, a la vez que se imponen presupuestos y se hace el seguimiento de todas y cada una de las compras.",
"90c2664b0f771d6ffb1c3241fb8abe44":"El límite se puede alterar a nivel de aprobador el individual o del equipo",
"9bf5d56627e500cad9f7ad21c3e7966d":"Limitar a determinadas categorías de gastos",
"e80a6d4232e52f6bfabee02e17403b59":"Enlazar una de estas tarjetas de origen a este equipo, inscribir una nueva.",
"803570ab9f0fba5ff35ae2749200501f":"Cargando tarjetas...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Iniciar sesión",
"ad96c1ca962a883652dadb845f439415":"Inicio de sesión",
"072ca67e735adbed54acf41321370701":"Inicio de sesión",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Inicio de sesión",
"b246fe8836c906f1b56342378ca88895":"Realizar, aprobar y supervisar compras en equipos que replican la estructura de tu organización.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Gestionar liquidaciones",
"95d672ab621e531ae1a69798e0e4b56c":"Gestionar todas tus tarjetas virtuales a un único lugar.",
"96f2e88a167f476413b35aa711709e6e":"Gestionar liquidaciones",
"151d8e6ceec242d482fff60da8311449":"Gestor",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Gestor",
"ef1b3b3627418a5e8e5cceb814a67c8d":"El gestor recibe cada solicitud para la revisión y aprobación. Las limitaciones de gastos también se pueden ajustar.",
"4f449985e83c1f9ca2e8f82b0707a371":"El gestor recibe cada solicitud para la revisión y aprobación. Las limitaciones de gastos también se pueden ajustar.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Gestores",
"27f8111a955975ea6fd04f748d24cb4a":"Límite máximo de aprobación",
"5b1d9cfb78c55e75b47c62435d9299ca":"Miembro",
"41e307c1874e2e82296b226e9bae4a5f":"Miembro",
"6f9612aa1582373c2c1ba4e144132100":"Miembros",
"590429a0fcc42af85648d0fd70601dcc":"Miembros",
"3f50e62c8488d83ad84f69ebda853897":"Miembros",
"e0fc0acda5b74b93e797756dbbba67bc":"Miembros",
"c7ef9eda38b7eaee1f20031387a8bde7":"Mes",
"40dd08b507752bef4e3c4b36838317d5":"Mensual",
"d712b91f7498f212047aad4c6d0f8088":"Más reciente",
"12110d95b3d7a495afa4f28ac190f63e":"Más reciente",
"2f11ce3865f79ee7e5ee64d728082264":"Más reciente",
"eadc93865655cffc6a9863fdda5693e1":"Debe tener al menos 10 caracteres e incluir letras mayúsculas y minúsculas.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Debe tener al menos 10 caracteres e incluir letras mayúsculas y minúsculas.",
"50d0a69aa59df5201018771a06ea12e7":"Debe ser único",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Debe ser único.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Mis tarjetas",
"c36602f50d35c2816f0ba8a196b70876":"Mis solicitudes",
"ff6e372aa3eb685a39a701963db35b2f":"Mis solicitudes",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Nombre",
"1c7514b561a2591a914225813d30d642":"Nombre",
"d621826c1ceade262589af04e655e824":"Nueva contraseña solicitada",
"35dc3304b61a9e69dc3a393123658837":"Las nuevas solicitudes se reenviarán automáticamente durante este periodo de tiempo.",
"e788124801b4727d121f60d059a2a4a7":"Próximo",
"eca804f1d53dfd984ffd93e2d00fc284":"No hay Aprobador asignado; consulte al administrador de su programa",
"abdf7e93f8509399a63108a2b1a25546":"No hay actividad que mostrar",
"dbf0f00d4ae2b2456f9414c6b2c43793":"No hay tarjetas en la cartera",
"87127e1e9f5bf922d0a4f948b68697c8":"Sin límite",
"40512efa6b75ef0c7924b1ee3070fca9":"No hay miembros que mostrar",
"dcda493afcb2415d5ca49a17d1af6f50":"Aún no se ha publicado ninguna liquidación.",
"f0efb26e623bb990532e375d5c8875ec":"No disponible",
"bc0cf602d1978e814070bb552e576a32":"No correlacionado",
"09e126c317429ccb52ad323c32293648":"Número de pagos",
"75b00b969b9d3b520929f1e652637b70":"Número de pagos",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Número de pagos",
"bd44ba180aa356c5755fdedd32adf03c":"Oh, vaya. Ha habido un problema al crear una solicitud. Vuelve a intentarlo.",
"c396b3728dd7393aa9e5427990c132a7":"ˇOh, vaya! Ha habido un problema al descargar el informe.",
"42c07764b07dea1ee7de7605721c3f5f":"Oh, vaya. No hemos podido crear un equipo nuevo.",
"e3a385878ec94bac12a99490d0a8e85a":"Oh, vaya. No hemos podido crear tu cuenta.",
"17f2dae79677017e2c2e879578c4e09a":"Vaya. Algo fue mal. Vuelve a intentarlo.",
"78c135de545c1663c9768226492a44a3":"o usar Single Sign-On (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Ausente de la oficina",
"5d7e45f169e2f6968dc58f43b5267353":"Ausente de la oficina",
"ee589a20c262527db970671fb2c4f6bc":"Ausente de la oficina",
"4fd0c753fce49a016b98d988a3895223":"Descripción general",
"2789c5d5c9c042858f45aadc4e0de058":"Descripción general",
"083b7090b66ebd0307a18499ac7b676e":"La página no existe",
"a21d205920893cf579a7655a1430fe30":"Tarjeta padre",
"fd35b7ce938e404fdb65de3bb749c528":"Tarjeta padre para informe",
"e8c7d2eea7db66ecb44751091a582304":"Contraseña",
"bb79e316e00fd21138750b6986e44455":"Contraseña",
"6cae2279088580976d84119d95097cae":"Las contraseñas no coinciden",
"4baec0159d3074f84c0eebcc09ba4cd8":"Las contraseñas no coinciden",
"b676587115243921602742cb8f696a6a":"Pausar creación de tarjeta",
"db4a26c871ef0b970b74e1f71763098b":"Pausar la emisión de nuevas tarjetas virtuales",
"33fc1e6d01b785e3a815c28ef7d56667":"Pendiente",
"6ea62e4811b68df48b467e8d367aac2f":"Solicitudes pendientes ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Usuarios",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Las personas que un equipo pueden tener tres roles distintos",
"e2d34cb1409b9778d34c4b74ecee1df6":"Especificar un número entero positivo o dejar en blanco.",
"8da247d5d736c87122404eff1e5c0c73":"Escribe un límite válido.",
"65e154267ac283d963cbc7be832969d9":"Introduce un valor válido",
"28b4450d417065a69a395f567967d46c":"Preconfiguración con limitaciones de gasto solicitadas",
"7bbf151d8493170f206a23c2ecc6acd0":"Política de privacidad",
"51602962f2d1e7515943b4a6c66562ce":"Foto del perfil",
"a51a17940c70a3123da4d68bed562c40":"Perfil guardado.",
"4d398249d01ab72d697a7e219493c637":"Proveedor",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Correlación de proveedor",
"89b1deb2ffe12a13941c362abaeab01b":"Publicado",
"d8d806d48052159e3ff3b224f3f53586":"Objetivo",
"089917619c78af2efb30be0bb7ce076c":"Reactivar equipo",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Motivo",
"e8484c78fda9f453eeb942ba4945f040":"Reasignar",
"ff2fbc1172ba26776ad0982624cd2713":"Recibo",
"588e690db177ee0c7a7cadb39cbd5efc":"Actualizar fondos cada semana, mes, año",
"32955937daca05b2084556f248692baa":"Registrarse",
"f15bb755ba24abe6ab106637292175ec":"Restante",
"579782461133a8b8cc6bd77798961d82":"Eliminar tarjeta",
"e475edc165372f8c8deb532aa85d9950":"Eliminar lista",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Eliminar lista",
"f9769561315b964d0b3b4887ec208977":"Eliminar del equipo",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Eliminar recibo",
"ee181cef581fc8e8217e66b5762db898":"Al eliminar una lista se impide su uso y se borran todas las entradas existentes. Si quieres eliminar temporalmente una lista y mantener las entradas, puedes desactivar la configuración de publicación. ¿Realmente quieres eliminar esta lista?",
"feea41d0bc1d577a07c8b6e1297abbde":"Al eliminar una tarjeta de origen de un equipo no se inhabilitan las tarjetas virtuales existentes.",
"d09483824a2e0d6194a4c3f32311e752":"Al eliminar una tarjeta de origen de un equipo no se inhabilitan las tarjetas virtuales existentes. ¿Seguro que quieres quitar esta tarjeta de tu equipo?",
"7ca4b1bf1346e179a29da97f260a93a4":"Renombrar",
"db195ec9c818b8e92da142374e20d1bc":"Renombrar lista de codificación",
"2fd93e229fe9ea754808805d3fef9c13":"Renombrar lista de codificación",
"aa1e74d32805823f802a11b33c60c38b":"Tipo del informe",
"6ac78d97a8cb7f060e43e596e3e23535":"Solicitud cancelada.",
"25deccb1980291a0fa9997109c731440":"Solicitar demostración",
"9f2754d87686903b1500ce8e8a55b377":"Solicitar demostración",
"92ac924f72679f897e26f3e615be8c22":"Solicitud reenviada.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Solicitar información",
"270185007853cd779011ae0e8433f723":"Solicitud reasignada.",
"e5a059c9ba2691910321339cce989f9d":"Solicitud enviada.",
"77c88a231edfa7075114d91d2397e42d":"Título de la solicitud",
"cee91b833c4358acf0c554f746557e71":"La solicitud se ha rechazado.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"La solicitud se ha rechazado.",
"9e56abbbf87420eeedd625ce786515c7":"Solicitante",
"b4cb4426e44fee9580af71912a764536":"Solicitudes",
"df309e888c29fc447d0309329b39ce93":"Restablecer contraseña",
"5daf278da19ecbaeb7b96554cb4db10f":"Reintentar conexión ahora",
"31e9501526d2114e4a01fdbf076de097":"Rol",
"6fbceb787429f6f81117a1d5a58b4610":"Rol",
"473b814ca86841ef672ccf9f7dac28d5":"Rol",
"f1e16d5fd643e039d865b6aec7c986f9":"Rol",
"ec78345a5975bc0f8efaee5256b43c2c":"Guardar",
"2cecea57b47d8f4a45c706287ff7d64e":"Guardar cambios",
"15774be5c338a39169917c7d32781dd0":"Guardar y continuar",
"844095b4b8f580737b8717672501048e":"Decir algo...",
"7e66f676c2e7b04514aca3a0935530f0":"Buscar por información de códigos",
"5293ce9335ec2d176a6e838bf76404c6":"Buscar por descripción",
"efab3b0c95f263cdd0a8466329a387eb":"Buscar por descripción",
"d227b38dcbc4548c092b0836a7101506":"Buscar por nombre o ID de transacción",
"3629383abf1d5f85efd759f718159b40":"Buscar por nombre",
"a034fcd6719228b28a6fc22d3a46ea8a":"Buscar por nombre de equipo",
"8ac7e0d63f343b548482d5f367f5b3d9":"Buscar por nombre de equipo",
"858323eb52cb45446597ee46330a0540":"Buscar por propietario de tarjeta virtual",
"111bfcbff671e13de117ed5195fb6773":"Seleccionar",
"bcfecd59eb93e5314873d84ddf35aa58":"Seleccionar",
"909a4414ea573218bff2d533eaab46ea":"Seleccionar Fecha final",
"114b206ca1ff1c0bc52abef342059b2a":"Seleccionar opciones de equipo",
"81a02e424615fb41a20b8d0f13441c88":"Seleccionar equipos",
"b135b36495618e6692e7fbaf0a0aa83e":"Seleccionar un mes",
"677e90855e164c00a28b5c4763ac67e0":"Seleccionar un rol",
"d01570f3774738395b8ba3c64ce54c76":"Seleccionar un estado",
"7dee3f06c70626881efb0cd6af6b63e1":"Seleccionar un usuario al que reenviar la solicitud",
"4c7bb810835a1540ddc1f6501da5a51b":"Seleccionar un año",
"3ab198d2678af2b5b32180e4b39602ba":"Seleccionar aprobador",
"f502ba79f2bd010c5e77891812400958":"Seleccionar tarjeta",
"936027182d446e27ba209ea921ef7502":"Seleccionar el rango de fecha de caducidad predeterminado",
"b22c893254ccf3bd34faaee511cf7ca8":"Seleccionar duración",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Seleccionar de los valores preestablecidos de la lista de codificación o crear una lista nueva propia.",
"42cce5fa376b45bf290f86c684d69d96":"Seleccionar campo de proveedor",
"b761773d46699b60e8d6874b85593374":"Seleccionar equipos",
"3a9396756942ede98d0cb6afc1032b15":"Seleccionar el aprobador",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Auto",
"03e9d2c128af288126d94f631c5396be":"Enviar instrucciones",
"299a7e961804e2d649f06b5dc4362c29":"Enviar invitación",
"155e7b3f7db7bf10b03b5f41de54eae1":"Enviar mensaje",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Establecer una fecha de caducidad",
"f89ec96a4b5bb639488ab742efa76a6a":"Establecer una contraseña nueva",
"7d5377ad70d57728a45d3c0639de5544":"Configuración guardada",
"14313f11dee735f2144953e720776c28":"El importe del pago puede ser distinto si a la compra se aplican propinas u otras tasas",
"199000fe0cda0adaaa9b864aa7447cb9":"Liquidaciones",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Liquidaciones",
"6e6ac6ff3bd722e37874fb1ea7115818":"Configurar la contraseña",
"9b4ecf052980e80bf5c65a415d470a43":"Compartir y controlar el acceso a la tarjeta corporativa",
"ba746d78772d6be26cc508ba989becb0":"Debe ser menor o igual que {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Mostrar transacciones sin recibos",
"7d474b60aeb7cc25b519e806fca71e12":"Iniciar sesión con el (existente) \n\n Credenciales de Tradeshift",
"16080cd4e8319ef3e7de21b6c19d1437":"Algo no está bien",
"8f358a370224baf849f9652810c68db2":"Algo fue mal",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Algo fue mal",
"99af87c475a4d40ef732deed96f11ed2":"Algo fue mal. Vuelve a intentarlo.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Lo sentimos. Parece que hay algún problema ahora mismo; vuelve a intentarlo en {retryConnectionPeriod} segundos.",
"84debc8e41646cd49ac2b14708ca6d73":"Lo sentimos, pero ha habido un problema al eliminar la entrada de código. Vuelve a intentarlo.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Lo sentimos, pero ha habido un problema al guardar la entrada de código. Vuelve a intentarlo.",
"689d3418ee8f996ad25e152e80de63fe":"Lo sentimos, pero no hemos podido cerrar tu sesión ahora mismo.",
"b48efd4521ecd6428987d24636a4349f":"Ordenar",
"776a95003d3ec1dd58af69aedbc08579":"Ordenar",
"1b783238028c0ae085ca69ed823f4d1a":"Ordenar por",
"be70d19bf13c935d653b2c4fe7d36224":"Nombre de tarjeta de origen",
"564f8c942e7d1bdda5251545612fa348":"Nombre de tarjeta de origen",
"aa284602c746aad4a6de6366c24221b8":"Tarjetas de origen",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Tarjetas de origen",
"389d471e79a43440b3278b0fc7187d9e":"Tarjetas de origen",
"106342f689b57145817b00d6fe383f91":"Fecha final específica",
"2a5e19387ff300509876e3bae99b7ab9":"Acelerar la contabilidad",
"ca34d586bbdb7f368f1009305bccbe76":"Panel de gasto",
"4583120998b44c0a5982cf85dd938d2f":"Gasto a lo largo del tiempo",
"244d724806247953233a0bbfcbf8da4c":"Límite de gasto",
"d39b83dd151c8df6370460a458294ccf":"Gasto",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Importe del gasto",
"5de2859528e030ac1f2af115ebdf209c":"Gasto { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Iniciar reenvío a",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Estado",
"b089930f1f6ffac09438cc19590f8f6b":"Estado",
"f174d20592156eb4b10a7cf1144c973e":"Estado",
"ecf342482d1b6ab932c2000cd7dc38c4":"Estado",
"1f1fe111548158ec842761eca7425ff3":"Dirección postal",
"31a0a748b1097a9e0aa95a811802bdd0":"Reconciliación sin agobios",
"111b782f3f0794c2294aefb6693b2c50":"Enviar",
"013e2555920b51ad2a3d2c3dc482c471":"Enviar solicitud",
"1270cd8eb69ad64cc71e5de979423b3b":"La suscripción finaliza",
"2802336ad3d8ff658257b88a93f4e50a":"La suscripción finaliza",
"b28dd68454d7acbefafeefb42981fe16":"Frecuencia de la suscripción",
"907793bc80cf94d560e5f1a0250fdaee":"Frecuencia de la suscripción",
"29229e153308e4747edef1144de35a55":"Correcto.",
"503eb46b80f374f39266e7a9aea8e63e":"Correcto.",
"4f20e29cadadb9fbc7455bd4d80de567":"Correcto.",
"601d04f10d558f436c78c10e90b44175":"Ayuda",
"62558c185ea2c06c5472d1cd534f6e56":"Se sincroniza con la solución de contabilidad",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Importe del impuesto",
"0cb5d74f7a56fca30930616feb6e2f47":"Equipo",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Equipo",
"bf23b2fd889e3b12e0f0acf99f913eca":"Tarjetas de equipo",
"b90a650376782a9e43ed6be6135bd6f2":"Panel del equipo",
"6adbec395fe1c4bf776f9fe5fa021052":"Panel del equipo",
"6f2695eeb68501dbcfddbb4d265b846b":"Gestores del equipo",
"3b779ade9c960ac95a670db0cc12e56b":"Miembros del equipo",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Configuración del equipo",
"dc5235e1b88320d3ba7501e4c30985a3":"Configuración del equipo",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Configuración del equipo",
"a2eb92bfd7502f47b3cd170bc68ac638":"Configuración del equipo",
"f1ec84f051f19f0d0e301a709b13dc45":"Gasto del equipo",
"fbacde3a262ea8696e30135e8f987a44":"Conmutador de equipo",
"74b9578ea5a620f5fef102188e42c02f":"Conmutador de equipo",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Acciones del equipo",
"3aa978fa7ef886621285b93257d428c3":"Límite de aprobación del equipo",
"a41f9df131c221a99926de7e2138653a":"Información del equipo",
"941c7da8f256f91ba3819f991315a100":"Nombre del equipo",
"c5d04e10cc2f0b53c2bda893eae09178":"Nombre del equipo",
"504a8ba642ba37c9a743d0fabb2bd371":"Nombre del equipo",
"e768a8e1e3d6a6bf470587dad75423fe":"Propietario del equipo",
"e2d824067f8bd871756688c58c382196":"Propietarios del equipo",
"dca232ccc849c5ff5611255eb82baf53":"Propietarios del equipo",
"3b61bd586225790cf132591383d15448":"Conmutador de equipo",
"bc05b40509f0271c12cfaaef8e5319b1":"Equipos",
"39e73773d07a800673ec6600422952d6":"Equipos",
"daabb564bb3dcfb41de69492f854d573":"Equipos",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Los límites individuales y de equipo nunca superarán el límite de la empresa",
"4b4fe189a48d8eb06d836afa8fb6317a":"Equipos actualizados.",
"2a17f9a8c610db29fec63fd530c29fdc":"Equipos que utilizan la tarjeta",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Equipos que pueden ver esto",
"317868f3804ba4ed5d99dd9919c798ba":"Condiciones del servicio",
"e8c9a9847db2ccd97524aa06f1af9d82":"Condiciones del servicio y política de privacidad",
"38852078519c5c46715d8a034fc4901b":"Condiciones del servicio y política de privacidad.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Gracias por interesarte en Tradeshift Go. Muy pronto ofreceremos soporte para tarjetas de crédito {cardLabel}. Un miembro de nuestro equipo de logros del cliente te informará cuando esté disponible.",
"f7047b35a069d0790a0a348a29c846a2":"Gracias por unirte al equipo en {companyName} para realizar compras empresariales mediante tarjetas virtuales Go.",
"133b0a70b1a956889164621380c378f9":"La acción que intentas realizar no se puede completar. En algunos casos, es suficiente con volver a intentarlo.",
"18f5a1872e156f1aee84ae321780d952":"El importe solicitado supera el límite de solicitud de la empresa, que es ${requestLimit}. Vuelve a intentarlo con un importe de solicitud más bajo.",
"867e3d4d3c78990c6852fc5348706ab2":"El importe solicitado supera el límite de solicitud del proveedor, que es ${providerLimit}. Vuelva a intentarlo con un importe de solicitud más bajo.",
"570f5f4658b03dda7a910f56c86a3bd3":"La tarjeta para esta solicitud se ha cancelado.",
"3075c56105863546e3368ffead7a146b":"Tu tarjeta se ha cancelado.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"La lista de codificación que intenta despublicar está vinculada a una integración. Si está seguro de eliminarla de la integración, póngase en contacto con el soporte técnico, que es quien puede completar esta acción.",
"756ade8d71ec487a4b48d7c4277ea239":"La lista de codificación que está intentando despublicar está vinculada a una integración. Para despublicar esta lista de codificación antes hay que quitar la correlación.",
"4f40335f7c018986c95458183bc0c136":"El enlace para activar tu cuenta no es válido, o bien ha caducado. Consulta a la persona que te ha invitado para que te envíe otra invitación.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"El enlace para restablecer tu contraseña no es válido, o bien ha caducado. Para obtener un enlace nuevo, vuelve a solicitar el restablecimiento de la contraseña.",
"ded30f0e288c12125ca1a77f8d8fd600":"Tanto el aprobador como el usuario que hace la solicitud verán el motivo",
"f294897b38fc23dd8f5de52b54ceae22":"La solicitud se rechazará y se notificará al solicitante. ¿Quieres continuar?",
"e8c7200dc985df0a94a9d996968b4cc0":"La solicitud se rechazará y se notificará al solicitante. ¿Quieres continuar?",
"5f3d196a2ade3f97a2a48b773fec5861":"La solicitud se rechazará y se notificará al solicitante. ¿Quieres continuar?",
"b04c191cd6b4f27669a4f390311f156c":"No hay propietarios ni gestores de equipo que seleccionar.",
"e25045557efbad7b52997b94a1fe6817":"No hay {type} para este periodo de tiempo",
"7260690577c795e999acae63bb7c51ae":"Ha habido un problema al guardar la configuración del equipo. Vuelve a intentarlo.",
"f2103024f2a9c6c0335a71c60567d6c9":"Ha habido un error al cargar la cartera.",
"9776cb7eab9fa0d318a8144cd17de745":"Ha habido un error al cargar la cartera.",
"77d28a14bc900be4360b570a82eb75a9":"No hay datos que mostrar",
"2b6feba67722337f88c1443909fe446f":"Ha habido un problema al agregar el aprobador.",
"24b7212afb97c5d23faafa2ca4559095":"Ha habido un problema al agregar la tarjeta de crédito. Vuelve a intentarlo.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Ha habido un problema al agregar la tarjeta de origen al equipo. Vuelve a intentarlo.",
"5a8e1b922d1cee746ac6794599b10709":"Ha habido un problema al cancelar esta tarjeta. Vuelve a intentarlo.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Ha habido un problema al cancelar esta tarjeta. Vuelve a intentarlo.",
"22361c5d4df61ad7ae9792a3baf5729f":"Ha habido un problema al crear tu lista. Vuelve a intentarlo.",
"098755bfddeaf0428c7feb6b69af9b6f":"Ha habido un problema al rechazar la solicitud. Vuelve a intentarlo.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Ha habido un problema al rechazar la solicitud. Vuelve a intentarlo.",
"7d461b6b7b54dbfa9c83243217cd1053":"Ha habido un problema al eliminar la entrada de código. Vuelve a intentarlo.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Ha habido un problema al eliminar tu lista. Vuelve a intentarlo.",
"beb43d7800cfff47e08cd61842d953bd":"Ha habido un problema al reenviar la solicitud. Vuelve a intentarlo.",
"f7775350f35b8365fb9803232d9b792c":"Ha habido un problema al obtener los detalles de la tarjeta. Vuelve a intentarlo.",
"95abaa922c6c1648ca9c39ac4776896f":"Ha habido un problema al obtener los detalles del usuario. Vuelve a intentarlo.",
"9d7e4ead60137041ae8a845aa6cf9979":"Ha habido un problema al cargar el perfil de usuario. Vuelve a intentarlo.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Ha habido un problema al cargar estos usuarios del equipo. Vuelve a intentarlo.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Ha habido un problema al cargar tu configuración. Vuelve a intentarlo.",
"b2f48028c400befad5f1d57f769ef7cd":"Ha habido un problema al reasignar la solicitud. Vuelve a intentarlo.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Ha habido un problema al eliminar la tarjeta de origen del equipo. Vuelve a intentarlo.",
"19dfcd761006496c1d20351c04643e45":"Ha habido un problema al eliminar este dominio. Inténtalo de nuevo.",
"149651b5784481c68aaf4ac27bb1f907":"Ha habido un problema al guardar la lista de códigos. Vuelve a intentarlo.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Ha habido un problema al guardar el perfil. Vuelve a intentarlo.",
"749ddc235744b6b371668ec0bb049176":"Ha habido un problema al guardar el usuario. Vuelve a intentarlo.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Ha habido un problema al guardar este dominio; inténtalo de nuevo.",
"04cf86909808f430b8b1904fb422e0d3":"Ha habido un problema al guardar la configuración de Ausente de la oficina. Vuelve a intentarlo.",
"2eaf6ee97d984598c95e0494d72aa492":"Ha habido un problema al enviar la solicitud de compra.",
"148f847d3beccaa67fcd5096ae3602d3":"Ha habido un problema al actualizar el nombre de la tarjeta. Vuelve a intentarlo.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Ha habido un problema al actualizar el nombre de la tarjeta. Vuelve a intentarlo.",
"de8cc976f7af9edf189904239e6240da":"Ha habido un problema al actualizar los equipos. Vuelve a intentarlo.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Ha habido un error al agregar el administrador; por favor, inténtalo de nuevo.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Error al desactivar el equipo. Hay que volver a intentar.",
"3f686a97a1b9fcdd73a40d2005693105":"Ha habido un error; por favor, inténtalo de nuevo.",
"2f38263789163cf8ee30c8ae88852c82":"Ha habido un error al publicar tu lista.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Ha habido un error al eliminar el administrador; por favor, inténtalo de nuevo.",
"007a647254e1f85b6c1e789004e05df5":"Ha habido un error al guardar la configuración.",
"c3072d080b587d70f85d68ec089f028f":"Ha habido un error al guardar la configuración de tu equipo. Vuelve a intentarlo.",
"608cd0e109c3e51b0425df8921e4181d":"Ha habido un error al configurar la correlación del proveedor. Vuelve a intentarlo.",
"177cefc68dd318ea75673326e77d8206":"Ha habido un error al cancelar la publicación de tu lista.",
"255d22dc4f0e338a4cf53d5b7256e695":"Ha habido un problema al recuperar el nombre de la persona a la que reenvías las solicitudes de aprobación.",
"39ac4ec73fed8a981ad1857d989edfcb":"Esta cantidad excede el límite de aprobación de la empresa.",
"494ce3f598cbd97afe99774b5975a4bc":"Esta cantidad excede el límite de aprobación del equipo.",
"f2a42214e7c480c5596439becfd0b7bf":"Este campo es requerido",
"2dd5b9e9b5f80256af212924a6ac31c4":"Esta configuración sólo afecta a las tarjetas nuevas",
"881b7596cf985d9c246b05fcab930632":"Esta tarjeta de origen se ha agregado al equipo.",
"a1b1aa354d06e7815b78c6abb7448e84":"Esta tarjeta de origen se ha eliminado del equipo.",
"b348b24fc4839ee15ed0aa424689bae3":"Este equipo debe tener un administrador o gestor para poder invitar a un miembro.",
"188b9ed447a43916f9e323623ea3a993":"Para aceptar la invitación",
"30286e57da108ee7dbc0f8fbb62d4fff":"Gasto total ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Seguimiento del gasto en los equipos, proyectos y suscripciones.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Haz un seguimiento de la actividad de las tarjetas activas del equipo, pon el gasto en pausa y desactiva las tarjetas que el equipo ya no necesite.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Restablece tu contraseña de Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Volver a intentarlo",
"80196bc616d4a44a4997a2ea127f7b83":"Volver a intentarlo",
"f96bbe81618b5f4a1807989eb4f69e24":"Volver a intentarlo",
"d498d7d261b7ca56e07218d1a0dbdd49":"Oh, vaya. Ha habido un error. Vuelve a intentarlo.",
"ce2e475291003852c71b01959698c496":"No se han podido obtener mensajes sobre la solicitud {title}; vuelve a intentarlo.",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Desarchivar",
"d2e50be5287fd8fb436d0acbeb811864":"Identificador exclusivo",
"0e2fbce967f4a209215b6f48f8eddac3":"Cancelar la pausa de la creación de tarjeta",
"f2315dec5dd07802b77af3a3a5414a7d":"Hasta 4 años en el futuro.",
"d2f30a2f0c955271e9af123dceca501a":"Hasta {label} en el futuro.",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Actualizar tarjeta origen",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Actualizar tarjeta origen",
"c8f6bc61a8982158e408194e48a274d9":"Error en la subida; vuelve a intentarlo.",
"8a86a60cb836bdb5225cad20c5151078":"Subir recibo",
"e448a1ce42de0cf526200331b4ad8b23":"Usuario",
"75edcc8fc27f41c99e28b48ff250c7f8":"Detalles de usuario",
"d1651c2afdb6c92621adbb707e4753d0":"Usuario guardado.",
"5fa55883b7413526b441e999cb8089b3":"Nome utente",
"7a892b81fe2aae295f1b2d38bb67360f":"Nome utente",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Los usuarios con tarjetas activas no se pueden eliminar. Para eliminarlo este usuario, antes hay que cancelar las tarjetas.",
"d40fa5935f1614925cf46259c886814a":"Ver actividad de la tarjeta",
"625419857efc7e72d162c9f8204109df":"Ver tarjeta en la cartera",
"85a5e817826415651ed4991a76bb5385":"Ver conversación",
"09fc124dc9b8b14d6227a92bb0a4566f":"Ver conversación",
"d26cfc6d8f420f259f434b79593e9eb4":"Ver recibo",
"c7d0394c3206a3b74afbeabc41cc639c":"Ver recibo",
"01650aa52c5e5a22d3f38afd2c7f9411":"Ver recibo",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Ver recibo",
"b2d141897f73fc2a198ee1a7a438f89b":"Ver solicitud",
"02fa2f56ef72845a9dfeabea22948061":"Ver detalles del equipo",
"2d9c55650b17dd605a1388f46c81ba38":"Tarjeta virtual",
"d0ec076e4600e854588f8da265208c0b":"Opciones de tarjeta virtual",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Cartera",
"5279a2220e4cfb0d8b8b357311251cbe":"Cartera",
"b1746b5806659e3189f837d2dd54e08c":"Cartera",
"ff306d5b34ee993f9618c1f3c0209a39":"No hemos podido archivar la solicitud en este momento. Vuelve a intentarlo más tarde.",
"90272a9bb4d2048e81d7962ccfc9a97d":"No hemos podido cancelar la solicitud en este momento. Vuelve a intentarlo más tarde.",
"a813d44f6bb26c5da09671e532abe022":"No hemos podido desarchivar la solicitud en este momento. Vuelve a intentarlo más tarde.",
"d4c7310eafc0d0da89f20596a22a3abe":"No hemos podido crear una solicitud en este momento. Vuelve a intentarlo.",
"dca679c10accb1db481a57a0e1ba08e2":"No hemos podido crear una solicitud en este momento. Vuelve a intentarlo.",
"db5584a2ef29b04959bce7f93db54c56":"No hemos podido intercambiar el equipo en este momento. Vuelve a intentarlo más tarde.",
"f2af2c261fabddb01766f4f974b85a62":"Semanal",
"e181ed0723a00ca7558b9d5d5258732b":"Te damos la bienvenida a Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Estaremos en contacto",
"76b9c7afd38e52841fba94b5ead1d5cc":"¿Qué tarjeta de crédito utiliza la empresa?",
"968d94729053e166a76ea577ce3022e2":"Correo de empresa",
"588a075e8694ff5cc86815697e1586bc":"Trabaja de la misma forma en la que lo hacen tus equipos de negocio.",
"182ae1eb5226e0646b4033883cc0900c":"Año",
"fe88fe7094c4ced427874b3d22326c8d":"Sí",
"9a2d4e3b22ad0567165eb0f661f952ad":"Sí, cancelar tarjeta",
"96b88e530375237cbc08138d9fb4109c":"Sí, eliminar esta lista",
"fd3780f468c531f2bc1b47b8748b5a66":"Se pueden crear códigos de contabilidad en valores de empresa.",
"ab3ac6f945d9f756763d49257fd924dc":"Se pueden gestionar codificaciones en valores de empresa.",
"c919dae93fe780061629c255eb358140":"No puedes eliminar la única tarjeta de origen enlazada a este equipo. Añade otra tarjeta para poder eliminar ésta.",
"11de97e873b66d91a77db7a05da32202":"Se puede promocionar uno más propietarios del equipo de la lista siguiente para que pasen a ser administradores de la empresa, o invitar a un usuario nuevo",
"0ab790f08b2780be001b79d2ccd75c4e":"No hay códigos de contabilidad creados para esta cuenta.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"No hay creada ninguna lista de codificación.",
"44e470218124a50c117870467530752e":"Has introducido un correo o contraseña incorrectos.",
"4b687d0730822fdbeea27c174e47f227":"No hay aprobaciones aprobadas.",
"020f03c17b79af66f335b6af868c66c0":"No hay aprobaciones canceladas.",
"def0b76f03e7c622341a7d8e08aaaae7":"No hay aprobaciones rechazadas.",
"f138fc3cef5a3fc7c6aeabc235e63273":"No hay aprobaciones pendientes.",
"7c034d6104f80a523b90bc607fc06608":"No tienes tarjetas virtuales en tu cartera. Crea una solicitud",
"0aed9198d61d161468d541dbbc9f477a":"El reenvío de tu aviso Ausente de la oficina a {oooForwardToFullName} está programado hasta  {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Has recibido una tarjeta virtual nueva:",
"04eeaf814767c30aa41577f3b11f916a":"Recientemente has solicitado el restablecimiento de la contraseña de tu cuenta de Go. Para completar el proceso:",
"09ddf249810b9af7d3e1f4e537da500d":"Has intentado acceder a una página que no existe. Asegúrate de usar el enlace correcto.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Te han invitado a unirte al equipo en",
"02c6f3a7cf31a87f4f39f992509f8c44":"Has sido añadido como administrador para tu empresa",
"cf121a14590f109dafcae138265e353b":"Has sido añadido a un equipo nuevo.",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Has sido añadido al equipo",
"d3cef66a026f776903b342d450e5a832":"Tu solicitud de tarjeta Go ha sido denegada",
"003f87c21a29220e661fc302b885fd5a":"Tu cuenta se ha inhabilitado; consulta al administrador del equipo o de tu empresa.",
"a52b1f0da15dce68007f524e48238143":"Tu banco ha devuelto un error al agregar esta tarjeta. Ponte en contacto con el banco para resolverlo, y vuelve a intentarlo.",
"4d883b173167297463aeac86957c26fe":"Tu dirección de correo se ha utilizado para crear una cuenta en Tradeshift Go. Para comprobar tu dirección de correo y completar el registro",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Tu configuración Ausente de la oficina se ha guardado.",
"542f08554110a12de0c23afd78f38e0e":"Tu contraseña se ha restablecido. Inicia sesión, por favor.",
"49b2dcbe5d461f35c37986962b5bd07a":"Tu solicitud se ha denegado",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Estás en la lista.",
"32f87c5ddeaee35b9178785ef4eb9313":"Código postal",
"c6974a3d5311374c697625f2a3234bce":"a fecha",
"f86980f5ef00b645a6e4a0b59738a954":"una persona que no hemos podido encontrar",
"7066f854917b39bafe0bbecbb8bd6377":"aprueba solicitudes",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"para",
"49b171230ab9bcdd8b631c70cf397efb":"para <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"para tu empresa",
"35ad86f0b035d8156390670d60801e7f":"para {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"para {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"desde",
"c1407aa5424f339dedf67bcac597cf71":"ha configurado que sus solicitudes de aprobación se reenvíen a ti durante",
"67a948b8af3284a401ebefcb1b4723ab":"está a la espera de tu aprobación de",
"9ca64449d64b98fef7859e22bc23c4dc":"realiza solicitudes",
"ac850ab84428e9eb6a57e5d813de5e45":"gestiona el equipo",
"2168839a1f4c73ef5c89c9e930148e7d":"Por favor, ignora este correo. No hay que hacer nada.",
"85431c325faab98e02daa60151465144":"te ha enviado esta invitación",
"81c504fd54119e7e580eb05dedef46d0":"te ha enviado esta invitación",
"d74545060b38445ef4cc49fbb8d79876":"te ha enviado esta invitación.",
"2d96c53ee7ee0a54aadad19bfce0697d":"error al obtener los orígenes de tarjeta",
"b2fda295788d579fffd3f43b7737fd63":"para inscribir la tarjeta de empresa en Go, para otorgar a los empleados un acceso sencillo y seguro a la tarjeta",
"d35bd5c5d74af765d45cef3c4e78f52a":"para realizar compras empresariales con tarjetas virtuales de Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"para realizar compras empresariales con tarjetas virtuales de Go",
"9546989e681a7e25fbdfe7631e9ebc03":"para realizar compras empresariales usando tarjetas virtuales de Go.",
"6174e0a3d26409dd6e410052e2805660":"para empezar a utilizar tarjetas de Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"Desactivar Ausente de la oficina",
"fd2c1099fc9f3831d7f24195aaa11269":"tu@empresa.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} es responsable de aprobar solicitudes de otros miembros del equipo. Para continuar, asigna un nuevo aprobador a los miembros afectados.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} Ha sido invitado correctamente invitado ${this.teamId ? '.' : ' al equipo.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} se ha eliminado",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} ha cancelado la solicitud de",
"9567a2d4cacd58fb4f096132d26c1660":"{username} es responsable de aprobar solicitudes de otros miembros del equipo. Para continuar, asigna un nuevo aprobador a los miembros afectados.",
};
