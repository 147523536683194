module.exports = {
	en: 'English (United States)',
	'pt-BR': 'Portugues (Brazillian)',
	zh: 'Chinese (Simplified)',
	'zh-Hant-TW': 'Chinese (Traditional)',
	cs: 'Czech',
	da: 'Danish',
	nl: 'Dutch',
	'en-GB': 'English (United Kingdom)',
	fi: 'Finnish',
	fr: 'French',
	de: 'German',
	hu: 'Hungarian',
	id: 'Indonesian',
	it: 'Italian',
	ja: 'Japanese',
	ms: 'Malay',
	nb: 'Norwegian Bokmal',
	pl: 'Polish',
	pt: 'Portuguese',
	ro: 'Romanian',
	sk: 'Slovak',
	es: 'Spanish',
	sv: 'Swedish',
};
