const SvgSecurityEnvironment = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M0 0v7.25C0 12.93 3.8 16.5 9 18a12.46 12.46 0 0 0 9-12V0Zm16.8 6A11.32 11.32 0 0 1 9 16.75c-3.07-1-7.8-3.42-7.8-9.5v-6h15.6Zm-2.94-1.12L13 4 7.34 9.7 5 7.34l-.85.85 3.2 3.21Z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

export default SvgSecurityEnvironment;
