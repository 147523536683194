import { cloneDeep, findIndex } from 'lodash';

const TSTabBarComponent = {
	bindings: {
		onTabSelect: '&',
		selectedTabSref: '<?',
		tabs: '<',
	},
	template: '<header data-ts="TabBar" id="js-tabs"></header>',
	controller: class TSTabBarController {
		/* @ngInject */
		constructor($timeout, EventEmitter) {
			this.EventEmitter = EventEmitter;
			this.$timeout = $timeout;
		}

		$onInit() {
			ts.ui.get('#js-tabs', element => {
				this.tabBar = element;
				this.setTabs(this.tabs);
				if (this.selectedTabSref) {
					const selectedTabIndex = findIndex(this.tabs, ['sref', this.selectedTabSref]);
					if (selectedTabIndex !== -1) {
						this.tabBar.tabs().selectedIndex = selectedTabIndex;
					}
				}
			});
		}

		$onChanges(changes) {
			if (!this.tabBar) {
				return; // $onChanges is called before $onInit so need to make sure we have the element
			}

			if (changes.tabs && changes.tabs.currentValue) {
				this.tabs = cloneDeep(changes.tabs.currentValue);
				this.setTabs(this.tabs);
			}
		}

		setTabs(tabs) {
			this.tabBar.tabs(
				tabs.map(({ key, label, sref }) => {
					const onselect = () => {
						// $timeout forces digest cycle so changes in parent method are propagated to children
						this.$timeout(() => this.onTabSelect(this.EventEmitter({ key, sref })));
					};
					return { key, label, onselect, sref };
				}),
			);
		}
	},
};

export default TSTabBarComponent;
