"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageSpinnerInitialState = void 0;
exports.pageSpinnerSlice = pageSpinnerSlice;
exports.pageSpinnerInitialState = {
    pageSpinner: {
        isLoading: false,
    },
};
function pageSpinnerSlice(set) {
    return {
        ...exports.pageSpinnerInitialState,
        setPageLoading(isLoading) {
            set((state) => {
                // we do not want to intersect angularjs with react loaders
                if (window.$state?.loadingService?.isLoading()) {
                    state.pageSpinner.isLoading = false;
                    return state;
                }
                state.pageSpinner.isLoading = isLoading;
                return state;
            }, false, '[setPageLoading]');
        },
    };
}
