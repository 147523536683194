// import { isNil, cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';

const CodingListAsideComponent = {
	bindings: {
		isOpen: '<',
		lists: '<',
		onClose: '&',
		onCodingListSaved: '&',
		renameListId: '<?',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.cancel()"
				title="{{ $ctrl.title }}">
			<ts-form
					is-submitting="$ctrl.isSubmitting"
					on-cancel="$ctrl.cancel()"
					on-submit="$ctrl.submitForm($ctrl.name)"
					submit-label="{{ $ctrl.submitLabel }}">
				<ts-form-fields>
					<ts-input
							label="{{$ctrl.codingListNameFormText}}"
							ng-model="$ctrl.name"
							type="text"
							validate="$ctrl.validateInput(value)"></ts-input>
				</ts-form-fields>
			</ts-form>
		</ts-aside>
	`,
	controller: class CodingListAsideController {
		/* @ngInject */
		constructor(CodingHelper, CodingService, EventEmitter, LexicoService) {
			this.codingListName = '';
			this.CodingHelper = CodingHelper;
			this.CodingService = CodingService;
			this.EventEmitter = EventEmitter;
			this.lexico = LexicoService.getLexico();
			this.codingListNameFormText = this.lexico.trc('Form input label', 'Coding list name');
		}

		$onChanges(changes) {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.init();
			}
			if (changes.lists && changes.lists.currentValue) {
				this.lists = cloneDeep(changes.lists.currentValue);
			}
		}

		init() {
			this.updateTitle();
			this.submitLabel = this.title;
			if (this.renameListId) {
				this.isLoading = true;
				this.CodingService.getList({ listId: this.renameListId })
					.then(({ code, group, ID: id, name, ordering }) => {
						const target = 'VirtualCreditCardRequest';
						this.codingList = { code, group, id, name, ordering, target };
						this.name = name;
					})
					.finally(() => {
						this.isLoading = false;
					});
			} else {
				this.name = null;
				const uuid = v4();
				this.codingList = {
					code: uuid,
					group: uuid,
					id: uuid,
					ordering: '80',
					target: 'VirtualCreditCardRequest',
				};
			}
		}

		validateInput(value) {
			if (!this.lists) {
				return;
			}
			const listNames = this.CodingHelper.getListNames(this.lists);
			const valid = !listNames.includes(value);
			const errorMessage =
				valid || value === this.codingList.name ? null : this.lexico.trc('Validation message', 'Must be unique.');
			return { valid, errorMessage };
		}

		cancel() {
			this.name = '';
			this.onClose();
		}

		updateTitle() {
			if (this.renameListId) {
				this.title = this.lexico.trc('Component title', 'Rename coding list');
			} else {
				this.title = this.lexico.trc('Component title', 'Add coding list');
			}
		}

		submitForm(name) {
			this.isSubmitting = true;
			const description = name;
			const codingList = Object.assign({}, this.codingList, { description, name });

			this.CodingService.saveList(codingList)
				.then(() => {
					const successMessage = this.lexico.trc('Success message', 'Coding list saved.');
					ts.ui.Notification.success(successMessage);
					this.onCodingListSaved(this.EventEmitter({ codingList }));
				})
				.catch(() => {
					const warningMessage = this.lexico.trc(
						'Warning message',
						'There was a problem saving the coding list. Please try again.',
					);
					ts.ui.Notification.warning(warningMessage);
				})
				.finally(() => {
					this.isSubmitting = false;
				});
		}
	},
};

export default CodingListAsideComponent;
