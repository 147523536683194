module.exports = {
"d447f4a1755a0025dddd2f736255879f":"Nombre de transactions",
"ce09c436b49af331a32f1152ede7dd18":"1. Demander de financement",
"d2226752d3065fbad4744924b4a0ee73":"2. Vérifier et approuver",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Vérifier et approuver",
"82bab92f1c8030888550b51521266c53":"3. Payer avec une carte virtuelle",
"2ecd62e02e507f8e839a55eac0a04886":"Une entrée de codage avec l’ID '{entryId}' existe déjà.",
"5dfd25849740fbce8147838b0d5094eb":"Un surnom convivial, par exemple, Carte Marketing",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Un membre de notre équipe de service à la clientèle vous contactera sous peu.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Une personne le pourra pas consulter",
"976b50e70e4433e2786233979d243445":"Ce nom d’équipe existe déjà.",
"35b61d21ac11d5fc31ebe58541263445":"Accepter votre invitation pour rejoindre",
"a372cb2d4204654f52cd9170fb10d374":"Codes de comptabilité",
"110fc66010b6b91cebee7a59a2067aab":"Code de comptabilité",
"db862bb04dbdc6d04e47063f33be8865":"Les codes comptables sont gérés via des listes de codage et des entrées dans des listes.",
"d6e3848e824f077396be4351eb58edd2":"Action requise - approuver/rejeter la nouvelle demande au plus tard le {requesterFullName}",
"ffafa66eaab0409a2e90aaeb43796def":"Actif",
"0447e0aa5d00dbdab11b5546793c4479":"Actif",
"8ee51d21b05466f7d962d4ff5a0b384e":"Cartes actives",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Cartes virtuelles actives",
"89c6ec05861aa914304ba35bc6744124":"Cartes actives",
"5280659bcecaf8344c57790f9962a9a2":"Activité",
"8a15368ae286fa64e1cb7624cd410fc6":"Ajouter une liste de codage",
"8ded7e09e86be231b1165308329ac76d":"Ajouter une liste",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Ajouter un administrateur",
"695bb4bc29e2df769ddf7427ab71012a":"Ajouter un approbateur",
"4a1d4516cab00b7c5ecdb150e346e5db":"Ajouter un approbateur",
"56869851b27c2915f5a7fe6a33b91ac7":"Ajouter une liste de codage",
"8f8691057c3994aa61f9994202680c87":"Ajouter une liste de codage",
"69f7a406b79d19bf8082ed245ab29123":"Ajouter une liste de codage",
"48a92ca1cc63018707be99b13196369f":"Ajouter un administrateur d’entreprise",
"a679db9ff80d0e5769ae1ba311c7916f":"Ajouter un domaine",
"8f5e8078be77146ba7f3be49ef948906":"Ajouter une entrée",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Ajouter un message",
"f8a4b14162e19995074e63135b65c84d":"Ajouter une nouvelle carte",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Ajouter des personnes",
"c670549d0b898bb6a571eb2cd7804a6e":"Ajouter un reçu",
"eb937098e77d8a6b0565ed72357dcaae":"Ajouter un reçu",
"1e154f31c31b63a459f3956e380b3ef9":"Ajouter sélectionné",
"ba24db9956f156a18b32e653552ff682":"Ajouter des personnes à cette équipe",
"52bd93fb5489b25167bb541f7c6504ae":"Ajouter une carte source",
"21ab3f08eb507dbc76df166eafeb393a":"{fullName} Ajouté comme approbateur.",
"f879c06507d3076bbf52ea4fd2df850e":"Informations de carte supplémentaires",
"21e934ae30eece4a894337701f497638":"Tout",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Toutes les cartes",
"8fac71195e2b506e029752e4864b6a5d":"Tous les membres",
"f9708417df16227742e0545a8642eea7":"Tous les utilisateurs disponibles ont déjà été ajoutés à cette carte.",
"cb5abf070f8cba7982205038baab77bb":"Toutes les cartes",
"dae7d602eec2c84dea0d80ae25e56e6d":"Toutes les cartes",
"0b4bdfde6578668b6018d3615a1ad30f":"Tous les centre de coûts",
"97b875b81f54b4432d998a3e47d949cc":"Toutes les équipes",
"f0ae83969f7c20a1964caf5156dae966":"Toutes les équipes",
"d87455601a00282078582b36a3825b7d":"Toutes les équipes",
"24c7b7f55882ea94a525392ca8218fa0":"Toutes les équipes",
"8f860e35b64a8f447bded1e5ea66a2a8":"Toutes les cartes virtuelles",
"e403956ecaa6fb33ec1fef5c49d71042":"Toutes les cartes virtuelles",
"4e6f4e8f25f61d40e1eba77de94f6458":"Toutes vos informations financières sont chiffrées et sur des serveurs dans des emplacements sécurisés.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Montant",
"a4d099d0dbe3889b0995546e6ed28932":"Montant",
"6c0ef32aae76bc31a61e2124f542bdf1":"Montant",
"adec89c93525f1a8d1c64d856f405e27":"Montant : élevé à faible",
"18e632a170203894f40deed1d33ee552":"Montant : faible à élevé",
"a7944575cafef9126a499a1b06999fe3":"Une erreur s'est produite, veuillez réessayer plus tard.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Appliquer",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Options d’approbation",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Paramètres d’approbation",
"492eedd88cdd7bb45f39c6594af748c4":"Limite d'approbation",
"437d490afc8d226f8c7d544f4e35d011":"Limite d'approbation ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Approbations",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Approbations",
"17adb7fe866e32055b1469fdb7e82795":"Paramètres d’approbation",
"7125aa13db437084e1d27d3ff38f22e2":"Approuver",
"84df787ff5746f9dc3cf0146becef4fd":"Demande d’approbation",
"7a568654d00245b0bab6c90a1b67d196":"Approuvé",
"179c3e187ad8edeb8348cff222b938bf":"Approuvé",
"ad058d17725870df9abbd4f5ec217636":"Approuvé ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Montant approuvé",
"4d58ba5c0e03fec1556c243be106e4a3":"Approuvé par",
"7d3d697fc99f6c4641b326623743f7b7":"Approuvé { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Approbateur",
"8c45e443fc6748190719d79a092e2528":"Les approbateurs ont une limite d’approbation maximale.",
"a9b6f93de9630064be9809130aa1e195":"Êtes-vous prêt à prendre le contrôle des dépenses de votre carte ?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Voulez-vous vraiment annuler cette demande ?",
"301bfc2ce058f49e0704fec3d162b5b5":"Voulez-vous vraiment vous réaffecter cette demande ?",
"2181faf8d9973d1f4138e401a762086e":"Voulez-vous vraiment supprimer ${firstName} ${lastName} du poste d’administrateur d’entreprise ?",
"b982277f29055d0ef6e295fe2006d9e0":"Voulez-vous vraiment supprimer {firstName} de l’équipe ?",
"456c0ccff14acb808583cd9dece857b6":"Attribué à",
"bde315b9cf7fd55f4b65aabbbf38d462":"Au moins un gestionnaire ou propriétaire d'équipe doit être disponible à l'extérieur du bureau.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Autentification",
"1961ec69dd0c8b5718c831312e7f3d0f":"Collecte de reçus automatisée",
"e39f2477cf34d726936a5b5c2b48a4ad":"Créez automatiquement des rapports de rapprochement précis qui incluent des informations sur les acheteurs, les approbateurs, les reçus, les codes de compte et plus encore.",
"434ca07dfde0eaee14f094b7c75a6eae":"Disponible",
"eb85d8f50f2c89871b794f7d54279b22":"Solde disponible",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Disponible { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Précédent",
"c8258f8cf9e20f6bbb89d957de40e820":"Sécurité de qualité bancaire",
"d46c22a3f36dce568c07fcea4368b231":"Paramètres de base",
"fb481c8e517016094b4fdf618c5fa088":"Adresse de facturation",
"79a9f821923c5a04c076902fef684281":"Adresse de facturation",
"877a08b8347052c83aa0b689baea81e7":"Adresse de facturation",
"97f018458e2627fac37d24846c7b8922":"En procédant à la création de votre compte et à l'utilisation de Tradeshift Go, vous acceptez notre",
"3dd252416d7ad21a6bfb3cecc58b75c6":"En procédant à la création de votre compte et à l'utilisation de Tradeshift Go, vous acceptez notre",
"912d1864f0068c764bf4784020795d49":"NOM DU DÉTENTEUR DE LA CARTE",
"d53474c7fd99fbeb69481ce9d358aa09":"Peut demander des cartes et avoir un portefeuille pour toutes ses cartes. Chaque membre obtient un approbateur par défaut (gestionnaire) pour ses demandes.",
"f30df2cab105ed3f8b4264aeb9800abd":"Peut approuver les demandes et créer des fiches pour les membres qu'ils gèrent. Les gestionnaires disposent d'un tableau de bord pour suivre les dépenses des membres qu'ils gèrent.",
"03ed9eccc71ef59165292eac542d3050":"Peut afficher toutes les demandes et dépenser l'activité dans l'équipe. Les propriétaires d'équipe obtiennent également un tableau de bord d'équipe pour suivre les dépenses et le portefeuille d'équipe où ils peuvent voir toutes les cartes actives.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Impossible de supprimer cet utilisateur Au moins un propriétaire ou un responsable d'équipe doit figurer dans une équipe.",
"a0b70642629a55ea0ab6fa3414d342e0":"Impossible de supprimer l’utilisateur avec les demandes en attente",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Annuler",
"5446624d3c364ac05ddf8d6663da2796":"Annuler la carte",
"53f3815c4fa3340b29efd81e627d1e6b":"Annuler la carte",
"12257ea2a871b7fab72245b5aafe6424":"Annuler la demande",
"2aafb7eb4d56a516c4bb0754f0812df1":"Annuler la carte",
"caae12601b01e2325161e95107f9e332":"Annuler la carte",
"6ad1e57b1e4c7accbe166c948883077d":"Annuler avec un clic si compromis",
"f6527e49e6d5c989624507a583e23f39":"Annulé ({count})",
"1ad3bf5121641dda511f42942af05a90":"L'annulation de cette carte ne rétablira pas les frais en attente. Veuillez rechercher le règlement.",
"aebd3826a195afad0a93050a7cc5d73d":"L'annulation de cette carte ne rétablira pas les frais en attente. Recherchez les enregistrements de règlement de ces frais dans le relevé de carte de crédit envoyé par votre fournisseur de carte.",
"a5b5136947da562f91b75daf8156f50e":"Informations de la carte",
"9ed31ad23a1578ce480d31207decf1d3":"Paramètres de la carte",
"def15f2e007fc69886230fdb2511305c":"Informations de la carte",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Informations de la carte",
"67cfd260078ca32027c2fa4a4a2d29f4":"Détenteur de la carte",
"4458819ea60c68c46e551a8f5cc163cd":"Nom de la carte",
"0858bcbd506fa3240b165e59d2f18aae":"Nom de la carte",
"5212736dd18ee2c31f3650ad2c2c7130":"Nom de carte mis à jour",
"c96d62fa510c3356dc73a91d1a80e262":"Numéro de la carte",
"41307fee4c32aefb5383a98fe3d6e45e":"Type de carte",
"3f8045c3358621dad4346a44de0c05a8":"Nom du détenteur de la carte",
"879a6b452033cf5f3a4bb3e1837a0386":"Ville",
"593334553ef852024e172505ec3c5f21":"Cliquez ici",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Codage",
"df931751d5790d74fa46707aaf7a26ae":"Codage 1",
"7ac7154bc776aff52a71b0d83b860561":"Codage 2",
"586fcf19136ae5e26795e9055b2e2995":"Codage 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Codage 4",
"379bb8e71bb090badebbb0751de86f11":"Codage 5",
"3e84d13627b3d090ab2b8470af98340a":"Codage 6",
"33b94b581f3792049992663396e51cb8":"Codage 7",
"b73fbc32e439655833ee99126dd0e55f":"Codage 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Liste de codage",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Entrée de codage",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Entrée de codage supprimée",
"cada62a39ee72d790dcdbb25160f8783":"Entrée de codage enregistrée",
"f645bf50888943ee1384693e56a0f475":"Nom de la liste de codage",
"a999182acbcd77c15299f79181b630be":"Liste de codage enregistrée",
"eb57b184bb76e20bfaedc92cfdd562e6":"Listes de codage",
"d0b96596ce6baec5aabb8a0690ab30e1":"Commentaires",
"ff9cb8842f832d21fb160b3d3784ce79":"Entreprise",
"715b59a64e686eb91a21114644ffe39e":"L’administrateur d’entreprise a été supprimé",
"8758e9a01253c097a42393fda9610715":"Tableau de bord d’entreprise",
"e118b41009918b326db6746856831cbc":"ID d’entreprise",
"4dba88b05b3fa8875128e19e88c512b3":"Paramètres de l'entreprise",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Paramètres de l'entreprise",
"f2ea6d33de8182d97bf2f13354af4893":"Paramètres d’entreprise : {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Administrateurs d’entreprise",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Limite d'approbation de l'entreprise",
"7e2f778da14a726b53e05926a3483f2b":"Carte de crédit d’entreprise",
"7313fd1db3d63858586d1c0f89251d6e":"Nom de l'entreprise",
"07721e91dd10ff8831f0d24e47cc1eab":"Nom de l'entreprise",
"203221610ca4d347063c037e1a732ae8":"Paramètres d’entreprise enregistrés.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Confirmer l’action",
"d694cec09cdbc02b1ffe22642a63faa1":"Confirmer le mot de passe",
"512801312a133f29974f2259a455f39b":"Confirmer le mot de passe",
"f54c38ed0b9869d929ffc0bd5310131f":"Confirmation - Votre compte a été créé sous Team {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Contactez l'administrateur de votre entreprise pour la création des codes de comptabilité.",
"7a6a4de077398e8e138aec36e3b3d305":"Continuer avec SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Tous droits réservés.",
"51f0d8e04f6ee626b866b37833dc2b61":"Centre de coûts",
"e043f60a02f157d0f31a42f0db67cc87":"Impossible d'inviter {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Impossible d’envoyer votre message. Réessayez.",
"ae28083f22a0b2fc5637614bce0976e0":"Créer une liste personnalisée",
"738078dc992208e05406f935995dc0c3":"Créer une liste personnalisée",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Créer une liste",
"0f5783c3921b3d7398d281644bbe5bde":"Créer une demande",
"6d62f934f2304701f88bdcfbc688de7e":"Créer un compte",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Créer un compte",
"f6b7d0d8531ea268f988005dd2147b2b":"Créer une carte",
"1a083675d1453448e4645403bbe52dd9":"Créer une liste personnalisée",
"c10ab11bdfb001da74850ed89c45482f":"Créer une demande",
"43a9c06398408bfe77d06ffc2c1c3b87":"Créer une demande",
"68bf17fd3f54a89c0a84224e2f9a971c":"Créer une équipe",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Créer une équipe",
"3b8e665101b9b3657f8a68c5305947b7":"Créé",
"40e91f2907181ffa8e62567b3a407e60":"Créé par",
"8929f7aacb969ef6353aba9903eb362d":"Carte de crédit ajoutée",
"d86688c26e8ac5eb9facc971b6fe5155":"Devise",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Devise",
"500ded53f64e737a5e4337675ac9b7ce":"Nom de la liste personnalisée",
"b79a95f04fd829fb2e8d0313e522a94d":"Quotidien",
"ef445eab224fc599e4103c9b1745a4e0":"Tableau de bord",
"788452c3ad2b1450b6d8d116b863e635":"Tableau de bord",
"fb7add662cf7042355319a975fab6ae3":"Chiffrement des données",
"e4213104a55eceb60e00408699438dcd":"Désactiver l’équipe",
"c8f8e49f0db4d998dd0a274bfd4e89db":"La désactivation d'une équipe annule toutes les cartes virtuelles actives, et aucun des membres de l'équipe, des gestionnaires ou des propriétaires ne pourra accéder à l'équipe. Seul vous ou un autre administrateur de l'entreprise pouvez réactiver une équipe. Êtes-vous sûr ?",
"0d0d5677bee2b99884802638e507160a":"Décliner",
"b5e3483aae9d36ce1440fe02904d6da1":"Décliner la demande",
"00f3db97287cb17ee9c2a131f2141a65":"Décliné",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Décliné ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Approbateur par défaut",
"b77589ac3ac2317cfc96d7013658d5c9":"Supprimer l’ID de codage { entryId }, Entrée : { entryPath } ?",
"9dd88505aa78eb11e87fb553c5fa785d":"Instructions détaillées et rapports",
"5234dfc4220ccd18a8b62fe84e372248":"Détails",
"37929896817a6744718004374bb42fd8":"Détails",
"dd0a6194e8a6ae14f3e1379a1640de15":"Voulez-vous vraiment supprimer {fullName} ({email}) de l’entreprise ?",
"1a98048b34090da8b199135ccb4c1186":"Numéro distinct et informations de cryptogramme de carte masqué",
"3cf132b60fe16b816ca227bbcad60537":"Ne pas annuler",
"dee57797e14ccf852aa2bc545bafdf6d":"Télécharger",
"375ae8224d7d35393974775cebe8605a":"Télécharger la listes d’utilisateurs",
"54b3bfd45a293b441d441aab1a50e644":"Télécharger le rapport",
"60cf3b94e5df14a504825068928fc118":"Télécharger le fichier de rapport CSV",
"7341a4c73c0b688484304c37da4a6d8e":"DATE D’EXPIRATION",
"f628f8ad44fbd6c70c99fc778407b666":"Recherchez facilement les transactions historiques et explorez les tendances des dépenses des employés sur n'importe quelle période.",
"94bb409d034ea20697ae09d812e55365":"Modifier la liste personnalisée",
"2974e704676cb79666bc236d8658fa97":"Modifier le profil",
"9ff205f99ed4002d049f855f965ca6d8":"Modifier l'utilisateur",
"3d7a4a9f4e70f57463e9a97caadb8205":"Adresse électronique",
"0534c00af758d42ebd8b0bd772eab074":"Adresse électronique",
"0c9aa1fbdf925065d25161eab5e51d95":"Adresse électronique",
"ea78fac68766a48b1b0f41305b7e9898":"Adresse électronique",
"dc6a8f782e00c1bf05c48f3a8758e26c":"L'employé demande des fonds à la carte d'entreprise pour effectuer l'achat. Les fonds demandés peuvent avoir défini des limites de dépenses.",
"829cff16ce0b13810c914c161b2a9fba":"Activer les autres fonctions utilisateur get",
"741387c7bd71e2b4a9601af9a6b9490e":"Activer le transfert automatique des demandes d'approbation",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Activer la date d'expiration par défaut pour la carte virtuelle",
"bb319710dad93e78cc6f58c615bdf210":"Activer la liste blanche de domaine de messagerie pour les utilisateurs invités",
"4700ced24cd278c3149ffa983006035c":"Activer le filtrage par centre de coûts",
"3fe06ef8f551c4cb4c0375362e25521d":"Activer l’option de globalisation dans l’utilisateur à côté",
"2787d4bc9752272ac36b499ce7ad93ab":"Activer les carte virtuelles multi-utilisations",
"6ee44e2280c9ce5009568846cbc3dd14":"Activer Absent du bureau",
"1941ca87053df0cebc6091ede92c18ea":"Activer la vue des personnes pour les administrateurs de la société",
"e50282c2ec7afb948d2c0b428db5d5ff":"Activer le chargement des reçus des transactions",
"268694cb2172918fe60f835919bf20ec":"Activer le chargement des reçus dans la tables de règlements",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Activer l'auto-approbation",
"d17c7acf598b569cce8a2bb97e0bb215":"Activer les carte virtuelles à utilisation unique",
"cd731ca218c9e14caabefafe003cfa6d":"Activer les cartes virtuelles d’abonnement",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Arrêter l’envoi le",
"c1f665a814894b17d603b7052b631d90":"Enregistrer une nouvelle carte",
"6b6cea93e07e596be70f7b76801e1516":"Enregistrer une nouvelle carte",
"7d6ac1ac9cba1620b61908842ebb106b":"Enregistrer une carte source",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Enregistrer une carte source",
"21a642b339c3eabf2789c0b05dcf828b":"Enregistrer votre première carte d’entreprise dans GO.",
"6efa8dca4fba23a190838746879fd704":"Entrer un ID numérique",
"02c3457577da9dc8dba6e6f9380a248e":"Entrer un nom d’équipe unique",
"40feb3fd1fa529cb599aeac9607664a4":"Entrer une nouvelle entrée",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Exemple : timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Mois d’expiration",
"b8bc74aeeb2de3492891c97361de107d":"Année d’expiration",
"6a0b8d521500014d99dd192d8ce8403d":"Date d’expiration",
"8cf274169a732340430fe7cc5236e0e7":"La taille de fichier doit être inférieure à {maxFileSize} Mo",
"58bb9c6f074e777ab1829b41acb18a81":"Prénom",
"7458b55e967e51791d98e77458631e44":"Prénom",
"3bbe52e71bc5c212cea77983f1a5b748":"Prénom",
"9b49c88fa20cc006baaac15c857c8781":"Pour un ou plusieurs achats",
"bb7a2907073bd760f4e82097132637b1":"Mot de passe oublié",
"26bdfaa592f3629273f13c22435b323d":"Mot de passe oublié ?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Faire suivre",
"3f9fe6adc14ce5776097170653a24462":"Faire suivre la demande",
"b774e35683f5737c36bf0cf3d6785973":"Faire suivre la demande",
"628faa5054a912b1e470414f77057e6b":"Faire suivre la demande",
"852b4065e1837fdc77940b6411a6d902":"Protection contre la fraude",
"616551bd10d95aca439401d6a7769d96":"Montant du fret",
"a65a230918e9c11e1ef8824745e26a3d":"Démarrer",
"7d4c4466b7716347ce3673acaa1983f7":"Obtenir plus de Go",
"11415a07658cfef80eb166896d77ad98":"Préparez-vous pour encore plus d'informations sur les dépenses. De plus, Tradeshift Go sera maintenant mieux adapté aux préférences de travail des équipes commerciales.",
"ac0ff5d14286ef74614a9c6021f08f69":"Contrôlez en temps réel les dépenses et le budget et accédez à toutes les données détaillées des activités d'achat.",
"db9071ab833054307252a936e7f60e1b":"Démarrer",
"24dc4bb00841b004031fca3a9bb9bf51":"Demande de carte Go annulée par {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go rappelle aux employés les reçus manquants, qui peuvent être joints simplement en répondant à la notification, ou dans le cas d'un reçu par e-mail, envoyés à Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go travaille avec votre réseau de cartes de crédit pour garantir les mêmes normes de prévention des fraudes que vous attendez lorsque vous travaillez avec les meilleures institutions financières.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Bonne nouvelle ! Votre demande d’achat a été approuvée sur Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Des questions ou commentaires ? Entrer en contact sur",
"9df1d3de68d663b848101df3c7b58e39":"Comment ça fonctionne",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Je suis certain",
"84074732eb8d1b4094404cd750baf949":"Si aucune valeur, alors la limite de la société est appliquée.",
"138e8bd6102a7eea70a123ff03b1cd79":"Si aucune valeur, alors la limite d'équipe est appliquée.",
"e5756af4d7e5ad1236ccfbb858428421":"Si vous ne vous êtes pas inscrit à Tradeshift Go en utilisant cette adresse électronique",
"4f004f75f47dbfecdb6d09990596e530":"Si votre e-mail n'est pas encore enregistré, nous vous enverrons un email d'activation avec des informations sur la façon de procéder.",
"123837f267de602fb05361b3b1197c1e":"Si {username} correspond à un compte existant, nous vous enverrons un e-mail avec les informations expliquant comment procéder. Si, après quelques minutes, vous n’avez pas reçu l’e-mail, veuillez vérifier votre dossier de courrier indésirable.",
"45efd96cd93525a9c17222217a37d324":"Formats de fichier image ou PDF compatibles",
"fa6ae823f9ab8bf91c246efe2517956c":"Importez la configuration de votre compte QuickBooks, NetSuite ou Xero directement dans Go. Exportez les dernières transactions dans votre solution comptable en un seul clic.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inactif",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"L'entrée contient un caractère non valide.",
"62c7be09da43f8f96a60ef3967eddc2c":"Présentation d'un tout nouveau Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Domaine de messagerie non valide",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Inviter des personnes",
"3809b41b14c55c3fcf54cd813d8e21f5":"Inviter dans l’équipe",
"fc90a11805961b0d7b5a5545e4d9bc05":"Inviter un utilisateur",
"6f7f4acde92d267417f5d8a60d32400a":"Inviter plus de personnes",
"09ecd2ca15bb5c38dc10f653118346cd":"Inviter un nouvel administrateur",
"3f576ce504f53f972442a63a170c5418":"Inviter nouveau {role}",
"0215e052437ceea4e5cd562431b34ced":"Inviter des personnes",
"8aaf2c77df644b7e8524120f66da7d5f":"Montant de la facture",
"1b7dc8290f1205f092cc9133e0cde385":"Date de facturation",
"613da3d0cd586ef0360e0d93eace9508":"date d’émission",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Cela prend",
"3322b671105c65511fbab1966b7fdcf6":"Langue",
"b1d946192795a58aea6a61f4f923acb7":"4 derniers chiffres",
"b3c6b8db4ef6dcdcb451303e65548816":"4 derniers chiffres",
"0382123b9c847000fceff2484c665e39":"Quatre derniers chiffres",
"19f7c0055e150e9e3e66e85c2993799c":"Nom",
"662c84bede8a7bf91ab15bcb73b48b49":"Nom",
"cff8fbef5aac2523344b9535bd56973c":"Nom",
"c3510b2184a92ff12e07a2d1b9a461e6":"Permettez aux employés de demander l'accès à votre carte d'entreprise sans exposer les informations de votre carte, tout en respectant les budgets et en suivant chaque achat.",
"90c2664b0f771d6ffb1c3241fb8abe44":"La limite peut être remplacée au niveau d'une équipe et d'un approbateur.",
"9bf5d56627e500cad9f7ad21c3e7966d":"Limiter à certaines catégories de dépenses",
"e80a6d4232e52f6bfabee02e17403b59":"Liez l'une de ces cartes sources à cette équipe ou inscrivez-en une nouvelle.",
"803570ab9f0fba5ff35ae2749200501f":"Chargement des cartes...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Se connecter",
"ad96c1ca962a883652dadb845f439415":"Connexion",
"072ca67e735adbed54acf41321370701":"Connexion",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Connexion",
"b246fe8836c906f1b56342378ca88895":"Effectuez, approuvez et surveillez les achats au sein d'équipes reflétant votre structure organisationnelle.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Gérer les règlements",
"95d672ab621e531ae1a69798e0e4b56c":"Gérez toutes vos cartes virtuelles dans un même emplacement.",
"96f2e88a167f476413b35aa711709e6e":"Gérer les règlements",
"151d8e6ceec242d482fff60da8311449":"Responsable",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Responsable",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Le gestionnaire reçoit chaque demande d'examen et d'approbation. Les limitations de dépenses peuvent être également ajustées.",
"4f449985e83c1f9ca2e8f82b0707a371":"Le gestionnaire reçoit chaque demande d'examen et d'approbation. Les limitations de dépenses peuvent être également ajustées.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Gestionnaires",
"27f8111a955975ea6fd04f748d24cb4a":"Limite d’approbation maximum",
"5b1d9cfb78c55e75b47c62435d9299ca":"Membre",
"41e307c1874e2e82296b226e9bae4a5f":"Membre",
"6f9612aa1582373c2c1ba4e144132100":"Membres",
"590429a0fcc42af85648d0fd70601dcc":"Membres",
"3f50e62c8488d83ad84f69ebda853897":"Membres",
"e0fc0acda5b74b93e797756dbbba67bc":"Membres",
"c7ef9eda38b7eaee1f20031387a8bde7":"Mois",
"40dd08b507752bef4e3c4b36838317d5":"Mensuel",
"d712b91f7498f212047aad4c6d0f8088":"Plus récent",
"12110d95b3d7a495afa4f28ac190f63e":"Plus récent",
"2f11ce3865f79ee7e5ee64d728082264":"Plus récent",
"eadc93865655cffc6a9863fdda5693e1":"Doit contenir au moins 10 caractères contenant des majuscules et des minuscules.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Doit contenir au moins 10 caractères contenant des majuscules et des minuscules.",
"50d0a69aa59df5201018771a06ea12e7":"Doit être unique",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Doit être unique.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Mes cartes",
"c36602f50d35c2816f0ba8a196b70876":"Mes demandes",
"ff6e372aa3eb685a39a701963db35b2f":"Mes demandes",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Nom",
"1c7514b561a2591a914225813d30d642":"Nom",
"d621826c1ceade262589af04e655e824":"Nouveau mot de passe demandé",
"35dc3304b61a9e69dc3a393123658837":"Les nouvelles demandes seront automatiquement transmises pendant cette période.",
"e788124801b4727d121f60d059a2a4a7":"Suivant",
"eca804f1d53dfd984ffd93e2d00fc284":"Aucun approbateur attribué. Veuillez contacter votre administrateur de programme.",
"abdf7e93f8509399a63108a2b1a25546":"Aucune activité à afficher",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Aucune carte dans votre portefeuille",
"87127e1e9f5bf922d0a4f948b68697c8":"Aucune limite",
"40512efa6b75ef0c7924b1ee3070fca9":"Aucun membres à afficher",
"dcda493afcb2415d5ca49a17d1af6f50":"Aucun règlement n'a été publié.",
"f0efb26e623bb990532e375d5c8875ec":"Non disponible",
"bc0cf602d1978e814070bb552e576a32":"Non mappé",
"09e126c317429ccb52ad323c32293648":"Nombre de paiements",
"75b00b969b9d3b520929f1e652637b70":"Nombre de paiements",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Nombre de paiements",
"bd44ba180aa356c5755fdedd32adf03c":"Oh, mince ! Une erreur s’est produite lors de la création d'une demande. Réessayez.",
"c396b3728dd7393aa9e5427990c132a7":"Oh non ! Un problème est apparu lors du téléchargement du rapport.",
"42c07764b07dea1ee7de7605721c3f5f":"Oh, mince ! Nous n’avons pas pu créer une nouvelle équipe.",
"e3a385878ec94bac12a99490d0a8e85a":"Oh, mince ! Nous n’avons pas pu créer votre compte.",
"17f2dae79677017e2c2e879578c4e09a":"Oups ! Une erreur s’est produite. Réessayez.",
"78c135de545c1663c9768226492a44a3":"Ou utiliser Single Sign-On (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Absence du bureau",
"5d7e45f169e2f6968dc58f43b5267353":"Absence du bureau",
"ee589a20c262527db970671fb2c4f6bc":"Absence du bureau",
"4fd0c753fce49a016b98d988a3895223":"Présentation",
"2789c5d5c9c042858f45aadc4e0de058":"Présentation",
"083b7090b66ebd0307a18499ac7b676e":"La page n''existe pas.",
"a21d205920893cf579a7655a1430fe30":"Carte parente",
"fd35b7ce938e404fdb65de3bb749c528":"Carte parente du rapport",
"e8c7d2eea7db66ecb44751091a582304":"Mot de passe",
"bb79e316e00fd21138750b6986e44455":"Mot de passe",
"6cae2279088580976d84119d95097cae":"Les mots de passe ne correspondent pas.",
"4baec0159d3074f84c0eebcc09ba4cd8":"Les mots de passe ne correspondent pas.",
"b676587115243921602742cb8f696a6a":"Suspendre la création de carte",
"db4a26c871ef0b970b74e1f71763098b":"Suspendre l’utilisation des nouvelles cartes virtuelles",
"33fc1e6d01b785e3a815c28ef7d56667":"En attente",
"6ea62e4811b68df48b467e8d367aac2f":"Demandes en attente ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Personnes",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Les personnes d’une équipe peuvent avoir 3 rôles différents.",
"e2d34cb1409b9778d34c4b74ecee1df6":"Veuillez entrer un nombre positif ou n'entrez rien.",
"8da247d5d736c87122404eff1e5c0c73":"Entrez une limite valide.",
"65e154267ac283d963cbc7be832969d9":"Entrez une valeur valide.",
"28b4450d417065a69a395f567967d46c":"Prédéfini avec les limitations de dépenses demandées",
"7bbf151d8493170f206a23c2ecc6acd0":"Politique de confidentialité",
"51602962f2d1e7515943b4a6c66562ce":"Image de profil",
"a51a17940c70a3123da4d68bed562c40":"Profil enregistré.",
"4d398249d01ab72d697a7e219493c637":"Fournisseur",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Mappage de fournisseur",
"89b1deb2ffe12a13941c362abaeab01b":"Publié",
"d8d806d48052159e3ff3b224f3f53586":"Objet",
"089917619c78af2efb30be0bb7ce076c":"Réactiver l’équipe",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Raison",
"e8484c78fda9f453eeb942ba4945f040":"Réassigner",
"ff2fbc1172ba26776ad0982624cd2713":"Reçu",
"588e690db177ee0c7a7cadb39cbd5efc":"Actualiser les fonds toutes les semaines, tous les mois, tous les ans",
"32955937daca05b2084556f248692baa":"S'inscrire",
"f15bb755ba24abe6ab106637292175ec":"Restant",
"579782461133a8b8cc6bd77798961d82":"Supprimer la carte",
"e475edc165372f8c8deb532aa85d9950":"Supprimer la liste",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Supprimer la liste",
"f9769561315b964d0b3b4887ec208977":"Supprimer de l’équipe",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Supprimer le reçu",
"ee181cef581fc8e8217e66b5762db898":"La suppression d'une liste empêche de l’utiliser et supprime toutes les entrées existantes. Si vous souhaitez supprimer temporairement une liste et conserver les entrées, vous pouvez désactiver le paramètre de publication. Voulez-vous vraiment supprimer cette liste ?",
"feea41d0bc1d577a07c8b6e1297abbde":"La suppression d'une carte source d'une équipe ne désactive pas les cartes virtuelles existantes.",
"d09483824a2e0d6194a4c3f32311e752":"La suppression d’une carte source d'une équipe ne désactive pas les cartes virtuelles existantes. Êtes-vous sûr de vouloir retirer cette carte de cette équipe ?",
"7ca4b1bf1346e179a29da97f260a93a4":"Renommer",
"db195ec9c818b8e92da142374e20d1bc":"Renommer la liste de codage",
"2fd93e229fe9ea754808805d3fef9c13":"Renommer la liste de codage",
"aa1e74d32805823f802a11b33c60c38b":"Type de rapport",
"6ac78d97a8cb7f060e43e596e3e23535":"Demande annulée",
"25deccb1980291a0fa9997109c731440":"Demander une démo",
"9f2754d87686903b1500ce8e8a55b377":"Demander une démo",
"92ac924f72679f897e26f3e615be8c22":"Demande envoyée",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Demander des infos",
"270185007853cd779011ae0e8433f723":"Demande réaffectée",
"e5a059c9ba2691910321339cce989f9d":"Demande envoyée",
"77c88a231edfa7075114d91d2397e42d":"Titre de la demande",
"cee91b833c4358acf0c554f746557e71":"La demande a été refusée",
"5e914c38c1e3bd2b9f4ae903f1d94108":"La demande a été refusée",
"9e56abbbf87420eeedd625ce786515c7":"Demandeur",
"b4cb4426e44fee9580af71912a764536":"Demandes",
"df309e888c29fc447d0309329b39ce93":"Réinitialiser le mot de passe",
"5daf278da19ecbaeb7b96554cb4db10f":"Réessayer la connexion maintenant",
"31e9501526d2114e4a01fdbf076de097":"Rôle",
"6fbceb787429f6f81117a1d5a58b4610":"Rôle",
"473b814ca86841ef672ccf9f7dac28d5":"Rôle",
"f1e16d5fd643e039d865b6aec7c986f9":"Rôle",
"ec78345a5975bc0f8efaee5256b43c2c":"Enregistrer",
"2cecea57b47d8f4a45c706287ff7d64e":"Enregistrer les modifications",
"15774be5c338a39169917c7d32781dd0":"Enregistrer et continuer",
"844095b4b8f580737b8717672501048e":"Dites quelque chose...",
"7e66f676c2e7b04514aca3a0935530f0":"Rechercher par informations de codage",
"5293ce9335ec2d176a6e838bf76404c6":"Rechercher par description",
"efab3b0c95f263cdd0a8466329a387eb":"Rechercher par description",
"d227b38dcbc4548c092b0836a7101506":"Rechercher par nom de commerçant ou ID de transaction",
"3629383abf1d5f85efd759f718159b40":"Rechercher par nom",
"a034fcd6719228b28a6fc22d3a46ea8a":"Rechercher par nom d’équipe",
"8ac7e0d63f343b548482d5f367f5b3d9":"Rechercher par nom d’équipe",
"858323eb52cb45446597ee46330a0540":"Rechercher par propriétaire de carte virtuelle",
"111bfcbff671e13de117ed5195fb6773":"Sélectionner",
"bcfecd59eb93e5314873d84ddf35aa58":"Sélectionner",
"909a4414ea573218bff2d533eaab46ea":"Sélectionner la date de fin",
"114b206ca1ff1c0bc52abef342059b2a":"Sélectionner les options d’équipe",
"81a02e424615fb41a20b8d0f13441c88":"Sélectionner les équipes",
"b135b36495618e6692e7fbaf0a0aa83e":"Sélectionner un mois",
"677e90855e164c00a28b5c4763ac67e0":"Sélectionner un rôle",
"d01570f3774738395b8ba3c64ce54c76":"Sélectionner une date",
"7dee3f06c70626881efb0cd6af6b63e1":"Sélectionner un utilisateur pour faire suivre la demande",
"4c7bb810835a1540ddc1f6501da5a51b":"Sélectionner une année",
"3ab198d2678af2b5b32180e4b39602ba":"Sélectionner un approbateur",
"f502ba79f2bd010c5e77891812400958":"Sélectionner une carte",
"936027182d446e27ba209ea921ef7502":"Sélectionner la plage de dates d’expiration par défaut",
"b22c893254ccf3bd34faaee511cf7ca8":"Sélectionner la durée",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Sélectionnez dans les éléments prédéfinis de la liste de codage ou créez votre propre liste.",
"42cce5fa376b45bf290f86c684d69d96":"Sélectionner le champ de fournisseur",
"b761773d46699b60e8d6874b85593374":"Sélectionner les équipes",
"3a9396756942ede98d0cb6afc1032b15":"Sélectionner leur approbateur",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Auto",
"03e9d2c128af288126d94f631c5396be":"Envoyer les instructions",
"299a7e961804e2d649f06b5dc4362c29":"Envoyer une invitation",
"155e7b3f7db7bf10b03b5f41de54eae1":"Envoyer un message",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Définir une date d’expiration",
"f89ec96a4b5bb639488ab742efa76a6a":"Définir un nouveau mot de passe",
"7d5377ad70d57728a45d3c0639de5544":"Paramètres enregistrés",
"14313f11dee735f2144953e720776c28":"Le montant du règlement pourrait être différent si des pourboires ou d'autres frais sont appliqués à l'achat.",
"199000fe0cda0adaaa9b864aa7447cb9":"Règlements",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Règlements",
"6e6ac6ff3bd722e37874fb1ea7115818":"Définir votre mot de passe",
"9b4ecf052980e80bf5c65a415d470a43":"Partager et contrôler l’accès à votre carte d’entreprise",
"ba746d78772d6be26cc508ba989becb0":"Doit être inférieur ou égal à {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Afficher les transactions sans reçus",
"7d474b60aeb7cc25b519e806fca71e12":"Se connecter avec vos informations \n\n d’identification Tradeshift existantes",
"16080cd4e8319ef3e7de21b6c19d1437":"Quelque chose ne va pas",
"8f358a370224baf849f9652810c68db2":"Une erreur s’est produite.",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Une erreur s’est produite.",
"99af87c475a4d40ef732deed96f11ed2":"Une erreur s’est produite. Réessayez.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Désolé ! Nous rencontrons des problèmes de connexion actuellement. Réessayez dans {retryConnectionPeriod} secondes.",
"84debc8e41646cd49ac2b14708ca6d73":"Désolé, une erreur s’est produite lors de la suppression de l’entrée de codage. Réessayez.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Désolé, une erreur s’est produite lors de l’enregistrement de l’entrée de codage. Réessayez.",
"689d3418ee8f996ad25e152e80de63fe":"Désolé, nous n’avons pas pu vous connecter.",
"b48efd4521ecd6428987d24636a4349f":"Trier",
"776a95003d3ec1dd58af69aedbc08579":"Trier",
"1b783238028c0ae085ca69ed823f4d1a":"Trier par",
"be70d19bf13c935d653b2c4fe7d36224":"Nom de la carte source",
"564f8c942e7d1bdda5251545612fa348":"Nom de la carte source",
"aa284602c746aad4a6de6366c24221b8":"Cartes sources",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Cartes sources",
"389d471e79a43440b3278b0fc7187d9e":"Cartes sources",
"106342f689b57145817b00d6fe383f91":"Date de fin spécifique",
"2a5e19387ff300509876e3bae99b7ab9":"Accélérer la comptabilité",
"ca34d586bbdb7f368f1009305bccbe76":"Tableau de bord des dépenses",
"4583120998b44c0a5982cf85dd938d2f":"Dépense dans le temps",
"244d724806247953233a0bbfcbf8da4c":"Limite de dépenses",
"d39b83dd151c8df6370460a458294ccf":"Dépensé",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Montant dépensé",
"5de2859528e030ac1f2af115ebdf209c":"Dépensé { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Démarrer la redirection le",
"b0cb87df77ae00aa1d4e560b3a46dc50":"État",
"b089930f1f6ffac09438cc19590f8f6b":"Statut",
"f174d20592156eb4b10a7cf1144c973e":"Statut",
"ecf342482d1b6ab932c2000cd7dc38c4":"Statut",
"1f1fe111548158ec842761eca7425ff3":"Nom de la rue",
"31a0a748b1097a9e0aa95a811802bdd0":"Rapprochement sans stress",
"111b782f3f0794c2294aefb6693b2c50":"Envoyer",
"013e2555920b51ad2a3d2c3dc482c471":"Envoyer la demande",
"1270cd8eb69ad64cc71e5de979423b3b":"L’abonnement s’arrête",
"2802336ad3d8ff658257b88a93f4e50a":"L’abonnement s’arrête",
"b28dd68454d7acbefafeefb42981fe16":"Fréquence d’abonnement",
"907793bc80cf94d560e5f1a0250fdaee":"Fréquence d’abonnement",
"29229e153308e4747edef1144de35a55":"Succès !",
"503eb46b80f374f39266e7a9aea8e63e":"Succès !",
"4f20e29cadadb9fbc7455bd4d80de567":"Succès !",
"62558c185ea2c06c5472d1cd534f6e56":"Synchronisations avec votre solution de comptabilité",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Montant de la taxe",
"0cb5d74f7a56fca30930616feb6e2f47":"Equipe",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Equipe",
"bf23b2fd889e3b12e0f0acf99f913eca":"Cartes d’équipe",
"b90a650376782a9e43ed6be6135bd6f2":"Tableau de bord de l’équipe",
"6adbec395fe1c4bf776f9fe5fa021052":"Tableau de bord de l’équipe",
"6f2695eeb68501dbcfddbb4d265b846b":"Responsables d’équipes",
"3b779ade9c960ac95a670db0cc12e56b":"Membres de l'équipe",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Paramètres de l’équipe",
"dc5235e1b88320d3ba7501e4c30985a3":"Paramètres de l’équipe",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Paramètres de l’équipe",
"a2eb92bfd7502f47b3cd170bc68ac638":"Paramètres de l’équipe",
"f1ec84f051f19f0d0e301a709b13dc45":"Dépenses d’équipe",
"fbacde3a262ea8696e30135e8f987a44":"Commutateur d’équipe",
"74b9578ea5a620f5fef102188e42c02f":"Commutateur d’équipe",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Actions de l’équipe",
"3aa978fa7ef886621285b93257d428c3":"Limite d'approbation de l'équipe",
"a41f9df131c221a99926de7e2138653a":"Informations de l’équipe",
"941c7da8f256f91ba3819f991315a100":"Nom de l’équipe",
"c5d04e10cc2f0b53c2bda893eae09178":"Nom de l’équipe",
"504a8ba642ba37c9a743d0fabb2bd371":"Nom de l’équipe",
"e768a8e1e3d6a6bf470587dad75423fe":"Propriétaire d’équipe",
"e2d824067f8bd871756688c58c382196":"Propriétaires d’équipe",
"dca232ccc849c5ff5611255eb82baf53":"Propriétaires d’équipe",
"3b61bd586225790cf132591383d15448":"Commutateur d’équipe",
"bc05b40509f0271c12cfaaef8e5319b1":"Équipes",
"39e73773d07a800673ec6600422952d6":"Équipes",
"daabb564bb3dcfb41de69492f854d573":"Équipes",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Les équipes et les limites individuelles ne dépasseront jamais la limite de l'entreprise",
"4b4fe189a48d8eb06d836afa8fb6317a":"Équipes mises à jour",
"2a17f9a8c610db29fec63fd530c29fdc":"Équipes utilisant la carte",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Les équipes qui voit ce",
"317868f3804ba4ed5d99dd9919c798ba":"Conditions du service",
"e8c9a9847db2ccd97524aa06f1af9d82":"Conditions d’utilisation du service et politique de confidentialité",
"38852078519c5c46715d8a034fc4901b":"Conditions d'utilisation du service et politique de confidentialité.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Nous vous remercions de l’intérêt que vous portez à Tradeshift Go. Les cartes de crédit {cardLabel} seront bientôt prises en charge. Un membre de notre équipe de service à la clientèle vous le fera savoir dès que le support sera disponible.",
"f7047b35a069d0790a0a348a29c846a2":"Nous vous remercions d’avoir permis à à votre équipe de rejoindre {companyName} pour effectuer des achats professionnels en utilisant des cartes virtuelles Go.",
"133b0a70b1a956889164621380c378f9":"L’action que vous tentez d’exécuter ne peut pas être effectuée. Dans certains cas, réessayez simplement.",
"18f5a1872e156f1aee84ae321780d952":"Le montant demandé dépassait la limite de demande de la société de ${requestLimit}. Réessayez avec un montant de demande plus faible.",
"867e3d4d3c78990c6852fc5348706ab2":"Le montant demandé était supérieur à la limite de demande du fournisseur, qui est de ${providerLimit}. Veuillez réessayer avec un montant de demande inférieur.",
"570f5f4658b03dda7a910f56c86a3bd3":"La carte de la demande a été annulée.",
"3075c56105863546e3368ffead7a146b":"La carte a été annulée.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"La liste de codage dont vous essayez d'annuler la publication est liée à une intégration. Si vous êtes certain de vouloir la supprimer de l'intégration, veuillez contacter l'assistance qui peut effectuer cette action.",
"756ade8d71ec487a4b48d7c4277ea239":"La liste de codage dont vous essayez d'annuler la publication est liée à une intégration. Vous devez d'abord supprimer le mappage avant d'annuler la publication de cette liste de codage.",
"4f40335f7c018986c95458183bc0c136":"Le lien pour activer votre compte n’est pas valide ou a expiré. Vérifiez avec la personne qui vous a invité à obtenir une autre invitation.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Le lien pour réinitialiser votre mot de passe n’est pas valide ou a expiré. Veuillez demander à nouveau une réinitialisation du mot de passe pour obtenir un nouveau lien.",
"ded30f0e288c12125ca1a77f8d8fd600":"La raison sera visible de l'approbateur et du demandeur.",
"f294897b38fc23dd8f5de52b54ceae22":"La demande sera refusée et le demandeur sera informé. Voulez-vous continuer ?",
"e8c7200dc985df0a94a9d996968b4cc0":"La demande sera refusée et le demandeur sera informé. Voulez-vous continuer ?",
"5f3d196a2ade3f97a2a48b773fec5861":"La demande sera refusée, et le demandeur sera informé. Voulez-vous continuer ?",
"b04c191cd6b4f27669a4f390311f156c":"Il n'existe aucun propriétaire ou manager d'équipe à sélectionner.",
"e25045557efbad7b52997b94a1fe6817":"Aucun {type} pour cette période",
"7260690577c795e999acae63bb7c51ae":"Un problème est survenu lors de la sauvegarde des paramètres de l’équipe. Réessayez.",
"f2103024f2a9c6c0335a71c60567d6c9":"Une erreur s'est produite lors du chargement de votre portefeuille.",
"9776cb7eab9fa0d318a8144cd17de745":"Une erreur s'est produite lors du chargement de votre portefeuille.",
"77d28a14bc900be4360b570a82eb75a9":"Aucune donnée à afficher",
"2b6feba67722337f88c1443909fe446f":"Une erreur s'est produite lors de l’ajout de l’approbateur.",
"24b7212afb97c5d23faafa2ca4559095":"Une erreur s'est produite lors de l’ajout de la carte de crédit. Réessayez.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Une erreur s'est produite lors de l’ajout de la carte source à l’équipe. Réessayez.",
"5a8e1b922d1cee746ac6794599b10709":"Une erreur s'est produite lors de l’annulation de la carte. Réessayez.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Une erreur s'est produite lors de l’annulation de la carte. Réessayez.",
"22361c5d4df61ad7ae9792a3baf5729f":"Une erreur s'est produite lors de la création de votre liste. Réessayez.",
"098755bfddeaf0428c7feb6b69af9b6f":"Une erreur s'est produite lors du rejet de la demande. Réessayez.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Une erreur s'est produite lors du rejet de la demande. Réessayez.",
"7d461b6b7b54dbfa9c83243217cd1053":"Une erreur s'est produite lors de la suppression de l’entrée de codage. Réessayez.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Une erreur s'est produite lors de la suppression de votre liste. Réessayez.",
"beb43d7800cfff47e08cd61842d953bd":"Une erreur s'est produite lors de l’envoi de la demande. Réessayez.",
"f7775350f35b8365fb9803232d9b792c":"Une erreur s'est produite lors de l'obtention des informations de la carte. Réessayez.",
"95abaa922c6c1648ca9c39ac4776896f":"Une erreur s'est produite lors de l’obtention des informations de l’utilisateur. Réessayez.",
"9d7e4ead60137041ae8a845aa6cf9979":"Une erreur s'est produite lors du chargement du profil de l’utilisateur. Réessayez.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Une erreur s'est produite lors du chargement des utilisateurs de l’équipe. Réessayez.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Une erreur s'est produite lors du chargement de vos paramètres. Réessayez.",
"b2f48028c400befad5f1d57f769ef7cd":"Une erreur s'est produite lors de la réaffectation de la demande. Réessayez.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Une erreur s'est produite lors de la suppression de la carte source de l’équipe. Réessayez.",
"19dfcd761006496c1d20351c04643e45":"Une erreur s'est produite lors de la suppression de ce domaine. Réessayez.",
"149651b5784481c68aaf4ac27bb1f907":"Une erreur s'est produite lors de l’enregistrement de la liste de codage. Réessayez.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Une erreur s'est produite lors de l’enregistrement du profil. Réessayez.",
"749ddc235744b6b371668ec0bb049176":"Une erreur s'est produite lors de l’enregistrement de l’utilisateur. Réessayez.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Une erreur s'est produite lors de l’enregistrement de l’utilisateur. Réessayez.",
"04cf86909808f430b8b1904fb422e0d3":"Une erreur s'est produite lors de l’enregistrement des paramètres d’absence du bureau. Réessayez.",
"2eaf6ee97d984598c95e0494d72aa492":"Une erreur s'est produite lors de l’envoi de la demande d’achat.",
"148f847d3beccaa67fcd5096ae3602d3":"Une erreur s'est produite lors de la mise à jour du nom de la carte. Réessayez.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Une erreur s'est produite lors de la mise à jour du nom de la carte. Réessayez.",
"de8cc976f7af9edf189904239e6240da":"Une erreur s'est produite lors de la mise à jour des équipes. Réessayez.",
"5fd644b864eecc535bd42cae11543906":"Une erreur s'est produite lors de {operation} la liste. Veuillez réessayer.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Une erreur s'est produite lors de l’ajout de l’administrateur. Réessayez.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Une erreur s’est produite lors de la désactivation de votre équipe. Réessayez.",
"3f686a97a1b9fcdd73a40d2005693105":"Une erreur s'est produite. Réessayez.",
"2f38263789163cf8ee30c8ae88852c82":"Une erreur s'est produite lors de la publication de votre liste.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Une erreur s'est produite lors de la suppression de l’administrateur. Réessayez.",
"007a647254e1f85b6c1e789004e05df5":"Une erreur s'est produite lors de l’enregistrement des paramètres.",
"c3072d080b587d70f85d68ec089f028f":"Une erreur s'est produite lors de l’enregistrement des paramètres de votre équipe. Réessayez.",
"608cd0e109c3e51b0425df8921e4181d":"Une erreur s'est produite lors du mappage du fournisseur. Réessayez.",
"177cefc68dd318ea75673326e77d8206":"Une erreur s'est produite lors de l’annulation de la publication de votre liste.",
"255d22dc4f0e338a4cf53d5b7256e695":"Une erreur s'est produite lors de l’extraction du nom de la personne à laquelle vous envoyez les demandes d’approbation.",
"39ac4ec73fed8a981ad1857d989edfcb":"Ce montant dépasse la limite d'approbation de l'entreprise.",
"494ce3f598cbd97afe99774b5975a4bc":"Ce montant dépasse la limite d'approbation de l'équipe.",
"f2a42214e7c480c5596439becfd0b7bf":"Ce champ est obligatoire.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Ce paramètre affectera uniquement les nouvelles cartes.",
"881b7596cf985d9c246b05fcab930632":"La carte source a été ajoutée à l’équipe.",
"a1b1aa354d06e7815b78c6abb7448e84":"La carte source a été supprimée de l’équipe.",
"b348b24fc4839ee15ed0aa424689bae3":"Cette équipe doit avoir un administrateur ou un responsable pour pouvoir inviter un membre.",
"188b9ed447a43916f9e323623ea3a993":"Pour accepter l’invitation",
"30286e57da108ee7dbc0f8fbb62d4fff":"Total dépensé ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Suivez les dépenses dans les équipes, les projets et les abonnements.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Suivez l’activité des cartes actives de votre équipe, suspendez les dépenses et désactivez les cartes dont l’équipe n’a plus besoin.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Réinitialiser le mot de passe de Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Réessayez",
"80196bc616d4a44a4997a2ea127f7b83":"Réessayez",
"f96bbe81618b5f4a1807989eb4f69e24":"Réessayez",
"d498d7d261b7ca56e07218d1a0dbdd49":"Oh ! Une erreur s'est produite. Réessayez.",
"ce2e475291003852c71b01959698c496":"Impossible d'obtenir les messages sur demande {title}. Réessayez.",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Désarchiver",
"d2e50be5287fd8fb436d0acbeb811864":"Identifiant unique",
"0e2fbce967f4a209215b6f48f8eddac3":"Reprendre la création de carte",
"f2315dec5dd07802b77af3a3a5414a7d":"Jusqu’à 4 ans dans le futur",
"d2f30a2f0c955271e9af123dceca501a":"Jusqu’à {label} dans le futur",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Mettre à jour la carte source",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Mettre à jour la carte source",
"c8f6bc61a8982158e408194e48a274d9":"Échec du chargement. Réessayez.",
"8a86a60cb836bdb5225cad20c5151078":"Charger le reçu",
"e448a1ce42de0cf526200331b4ad8b23":"Utilisateur",
"75edcc8fc27f41c99e28b48ff250c7f8":"Informations utilisateur",
"d1651c2afdb6c92621adbb707e4753d0":"Utilisateur enregistré",
"5fa55883b7413526b441e999cb8089b3":"Nom d’utilisateur",
"7a892b81fe2aae295f1b2d38bb67360f":"Nom d’utilisateur",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Les utilisateurs ayant des cartes actives ne peuvent pas être supprimés. Annulez les cartes pour supprimer cet utilisateur.",
"d40fa5935f1614925cf46259c886814a":"Afficher l’activité de carte",
"625419857efc7e72d162c9f8204109df":"Afficher la carte dans le portefeuille",
"85a5e817826415651ed4991a76bb5385":"Afficher la conversation",
"09fc124dc9b8b14d6227a92bb0a4566f":"Afficher la conversation",
"d26cfc6d8f420f259f434b79593e9eb4":"Afficher le reçu",
"c7d0394c3206a3b74afbeabc41cc639c":"Afficher le reçu",
"01650aa52c5e5a22d3f38afd2c7f9411":"Afficher le reçu",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Afficher le reçu",
"b2d141897f73fc2a198ee1a7a438f89b":"Afficher la demande",
"02fa2f56ef72845a9dfeabea22948061":"Afficher les informations de l’équipe",
"2d9c55650b17dd605a1388f46c81ba38":"Carte virtuelle",
"d0ec076e4600e854588f8da265208c0b":"Options de carte virtuelle",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Portefeuille",
"5279a2220e4cfb0d8b8b357311251cbe":"Portefeuille",
"b1746b5806659e3189f837d2dd54e08c":"Portefeuille",
"ff306d5b34ee993f9618c1f3c0209a39":"Nous ne pouvons pas archiver la demande actuellement. Réessayez plus tard.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Impossible d’annuler la demande actuellement Réessayez plus tard.",
"a813d44f6bb26c5da09671e532abe022":"Impossible de désarchiver la demande actuellement Réessayez plus tard.",
"d4c7310eafc0d0da89f20596a22a3abe":"Impossible de créer une demande actuellement Réessayez.",
"dca679c10accb1db481a57a0e1ba08e2":"Impossible de créer une demande actuellement Réessayez.",
"db5584a2ef29b04959bce7f93db54c56":"Impossible de changer d’équipe actuellement Réessayez plus tard.",
"f2af2c261fabddb01766f4f974b85a62":"Hebdomadaire",
"e181ed0723a00ca7558b9d5d5258732b":"Bienvenue dans Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Nous vous contacterons !",
"76b9c7afd38e52841fba94b5ead1d5cc":"Quelles carte de crédit votre entreprise utilise-t-elle ?",
"968d94729053e166a76ea577ce3022e2":"Adresse e-mail professionnelle",
"588a075e8694ff5cc86815697e1586bc":"Travaillez comme vos équipes commerciales.",
"182ae1eb5226e0646b4033883cc0900c":"Année",
"fe88fe7094c4ced427874b3d22326c8d":"Oui",
"9a2d4e3b22ad0567165eb0f661f952ad":"Oui, annuler la carte",
"96b88e530375237cbc08138d9fb4109c":"Oui, supprimer cette liste",
"fd3780f468c531f2bc1b47b8748b5a66":"Vous pouvez créer des codes de comptabilité dans les paramètres d’entreprise.",
"ab3ac6f945d9f756763d49257fd924dc":"Vous pouvez gérer les codages dans les paramètres d’entreprise.",
"c919dae93fe780061629c255eb358140":"Vous ne pouvez pas supprimer la seule carte source liée à cette équipe. Ajoutez une autre carte pour pouvoir supprimer celle-ci.",
"11de97e873b66d91a77db7a05da32202":"Vous pouvez promouvoir l'un des propriétaires d'équipe répertoriés ci-dessous comme administrateur de l'entreprise ou inviter un nouvel utilisateur.",
"0ab790f08b2780be001b79d2ccd75c4e":"Vous ne disposez pas de codes de comptabilité créé pour ce compte.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Vous ne disposez pas de listes de codage créées",
"44e470218124a50c117870467530752e":"L’adresse e-mail ou le mot de passe que vous avez entrés ne sont pas corrects.",
"4b687d0730822fdbeea27c174e47f227":"Vous n’avez pas d’approbations approuvées.",
"020f03c17b79af66f335b6af868c66c0":"Vous n’avez pas d’approbations annulées.",
"def0b76f03e7c622341a7d8e08aaaae7":"Vous n’avez pas d’approbations refusées.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Vous n’avez pas d’approbations en attente.",
"7c034d6104f80a523b90bc607fc06608":"Votre portefeuille ne contient aucune carte virtuelle. Créer une demande",
"0aed9198d61d161468d541dbbc9f477a":"Le renvoi en cas d’absence vers {oooForwardToFullName} est planifié jusqu’à {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Vous avez reçu une nouvelle carte virtuelle.",
"04eeaf814767c30aa41577f3b11f916a":"Vous avez demandé récemment la réinitialisation du mot de passe de votre compte Go. Pour exécuter le processus :",
"09ddf249810b9af7d3e1f4e537da500d":"Vous avez tenté d’accéder à une page qui n’existe pas. Vérifiez que vous disposez du lien correct.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Vous êtes invité à rejoindre votre équipe à",
"02c6f3a7cf31a87f4f39f992509f8c44":"Vous avez été ajouté comme administrateur de votre entreprise.",
"cf121a14590f109dafcae138265e353b":"Vous avez été ajouté à une nouvelle équipe.",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Vous avez été ajouté à l’équipe.",
"d3cef66a026f776903b342d450e5a832":"Votre de demande de carte Go a été rejetée.",
"003f87c21a29220e661fc302b885fd5a":"Votre compte a été désactivé. Veuillez contacter l’administrateur de votre société ou de votre équipe.",
"a52b1f0da15dce68007f524e48238143":"Votre bande a retourné une erreur lors de l’ajout de cette carte. Contactez votre banque pour résoudre le problème, puis réessayez.",
"4d883b173167297463aeac86957c26fe":"Votre adresse e-mail a été utilisée pour créer un compte sur Tradeshift Go. Pour vérifier votre adresse e-mail et terminer votre inscription",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Vos paramètres d’absence ont été enregistrés.",
"542f08554110a12de0c23afd78f38e0e":"Votre mot de passe a été réinitialisé. Veuillez vous connecter.",
"49b2dcbe5d461f35c37986962b5bd07a":"Vous demande a été refusée.",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Vous êtes sur la liste.",
"32f87c5ddeaee35b9178785ef4eb9313":"Code postal",
"c6974a3d5311374c697625f2a3234bce":"une date",
"f86980f5ef00b645a6e4a0b59738a954":"une personne que nous n’avons pas vérifiée",
"7066f854917b39bafe0bbecbb8bd6377":"approuve les demandes",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"pour",
"49b171230ab9bcdd8b631c70cf397efb":"pour <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"pour votre entreprise",
"35ad86f0b035d8156390670d60801e7f":"pour {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"pour {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"de",
"c1407aa5424f339dedf67bcac597cf71":"a défini ses demandes d’approbation pour vous les faire suivre pendant",
"67a948b8af3284a401ebefcb1b4723ab":"est en attente de votre approbation de",
"9ca64449d64b98fef7859e22bc23c4dc":"fait des demandes",
"ac850ab84428e9eb6a57e5d813de5e45":"gère l’équipe",
"2168839a1f4c73ef5c89c9e930148e7d":"Ne tenez pas compte de cet e-mail. Aucune action requise",
"85431c325faab98e02daa60151465144":"vous a envoyé cette invitation",
"81c504fd54119e7e580eb05dedef46d0":"vous a envoyé cette invitation",
"d74545060b38445ef4cc49fbb8d79876":"vous a envoyé cette invitation",
"2d96c53ee7ee0a54aadad19bfce0697d":"Une erreur s’est produite lors de l'obtention de vos sources de carte.",
"b2fda295788d579fffd3f43b7737fd63":"pour enregistrer votre carte d'entreprise avec Go, pour accorder à vos employés un accès simple et sécurisé à la carte",
"d35bd5c5d74af765d45cef3c4e78f52a":"pour effectuer des achats professionnels en utilisant des cartes virtuelles Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"pour effectuer des achats professionnels en utilisant des cartes virtuelles Go",
"9546989e681a7e25fbdfe7631e9ebc03":"pour effectuer des achats professionnels en utilisant des cartes virtuelles Go",
"6174e0a3d26409dd6e410052e2805660":"pour commencer à utiliser des cartes Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"désactiver Absent du bureau",
"fd2c1099fc9f3831d7f24195aaa11269":"vous@entreprise.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} est responsable de l’approbation des demandes des autres membres de l’équipe Affectez un nouvel approbateur aux membres impactés pour pouvoir continuer.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} a été invité avec succès${this.teamId ? '.' : ‘ à l’équipe.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} a été supprimé",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} a annulé la demande de",
"9567a2d4cacd58fb4f096132d26c1660":"{username} est responsable de l’approbation des demandes des autres membres de l’équipe Affectez un nouvel approbateur aux membres impactés pour pouvoir continuer.",
};
