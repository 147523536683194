"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsCodingLoader = void 0;
const react_router_dom_1 = require("react-router-dom");
const codingQuery_1 = require("../../../queries/codingQuery");
const companyQuery_1 = require("../../../queries/companyQuery");
const teamQuery_1 = require("../../../queries/teamQuery");
const userQuery_1 = require("../../../queries/userQuery");
const stopAngularjsLoader_1 = require("../stopAngularjsLoader");
const settingsCodingLoader = async ({ queryClient }) => {
    const user = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const companyPropertiesData = await queryClient.ensureQueryData((0, companyQuery_1.companyPropertiesQuery)(user?.companyAccountId, { cardprovider: 'tsgo-cardprovider' }));
    const isHsbcCardProvider = companyPropertiesData?.cardprovider === 'HSBC';
    if (isHsbcCardProvider) {
        // we prevent the user from accessing the page if the card provider is HSBC
        (0, stopAngularjsLoader_1.stopAngularJsLoader)();
        return (0, react_router_dom_1.redirect)('/react/error');
    }
    const codingLists = await queryClient.ensureQueryData((0, codingQuery_1.getCodingListsQuery)({ skipDisabled: false }));
    const codingEntries = await queryClient.ensureQueryData((0, codingQuery_1.getCodingListsEntriesQuery)({
        codingLists,
    }));
    (0, stopAngularjsLoader_1.stopAngularJsLoader)();
    return {
        user,
        teams: await queryClient.ensureQueryData((0, teamQuery_1.teamsQuery)({ state: 'active' })),
        allTeams: await queryClient.ensureQueryData(teamQuery_1.allTeamsQuery),
        codingLists,
        codingEntries,
    };
};
exports.settingsCodingLoader = settingsCodingLoader;
