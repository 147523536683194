"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const appConstants_1 = require("../../../../constants/appConstants");
const strings_1 = require("../../../../constants/strings");
const zustandStore_1 = require("../../../../zustandStore");
const CardSettingFeatures = ({ setOpenMultiUseAside, setOpenSingleUseAside, setOpenSubscriptionAside, handleCardFeatureToggle, }) => {
    const team = (0, zustandStore_1.useZustand)(state => state.team?.team);
    const featureSettings = [
        {
            name: appConstants_1.teamFeaturesConstants.enableMutiUseVirtualCard,
            setOpen: setOpenMultiUseAside,
            label: strings_1.teamSettingsOverviewStrings.FEATURE_ENABLE_MULTI_USE_VIRTUAL_CARDS,
        },
        {
            name: appConstants_1.teamFeaturesConstants.enableSingleUseVirtualCard,
            setOpen: setOpenSingleUseAside,
            label: strings_1.teamSettingsOverviewStrings.FEATURE_ENABLE_SINGLE_USE_VIRTUAL_CARDS,
        },
        {
            name: appConstants_1.teamFeaturesConstants.enableSubscriptionVirtualCard,
            setOpen: setOpenSubscriptionAside,
            label: strings_1.teamSettingsOverviewStrings.FEATURE_ENABLE_SUBSCRIPTION_VIRTUAL_CARDS,
        },
    ];
    return ((0, jsx_runtime_1.jsx)(material_1.Grid, { item: true, xs: 6, children: (0, jsx_runtime_1.jsx)(material_1.FormControl, { children: featureSettings.map(setting => ((0, jsx_runtime_1.jsx)(material_1.FormGroup, { children: (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { name: setting.name, control: (0, jsx_runtime_1.jsx)(material_1.Switch, { color: "primary", checked: Boolean(team?.properties?.[setting.name] === 'true'), onClick: () => {
                            handleCardFeatureToggle(setting.name, setting.setOpen);
                        } }), label: setting.label, labelPlacement: "start" }) }, setting.name))) }) }));
};
exports.default = CardSettingFeatures;
