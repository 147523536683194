import { cloneDeep, difference, get, isNil } from 'lodash';

const CardSourceDetailAsideComponent = {
	bindings: {
		cardSourceId: '<',
		includeRemoveCard: '<',
		includeSelectTeams: '<',
		isOpen: '<',
		numCards: '<',
		onClose: '&',
		onRemove: '&',
		onUpdate: '&',
		team: '<',
		teams: '<',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.cardDetailsAsideTitleText}}">
			<ts-form
					on-cancel="$ctrl.onClose()"
					on-submit="$ctrl.onFormSubmit($ctrl.name)"
					submit-label="{{$ctrl.saveButtonText}}">
				<ts-form-fields>
					<ts-input
							is-required="true"
							label="{{$ctrl.cardNameFieldText}}"
							ng-model="$ctrl.name"
							type="text"
							validate="$ctrl.validateCardName(value)"></ts-input>
					<fieldset ng-if="$ctrl.includeSelectTeams">
						<label>
							<span ng-bind="$ctrl.teamsUsingCardText"></span>
							<button
									data-ts="Button"
									id="select-teams"
									ng-click="$ctrl.asides.selectTeams.isOpen = true">
								<span ng-bind="$ctrl.asides.selectTeams.model.id.length + ' selected '"></span>
								<i class="ts-icon-select"></i>
							</button>
						</label>
						<dl class="ts-info">
							<dd ng-bind="$ctrl.removeSourceCardInfoText"></dd>
						</dl>
					</fieldset>
					<fieldset>
						<span ng-bind="$ctrl.additionalCardDetailsText"></span>
					</fieldset>
					<div class="subfields">
						<fieldset>
							<label>
								<span ng-bind="$ctrl.cardHolderNameFieldText"></span>
								<span ng-bind="$ctrl.card.getCardHolderName()"></span>
							</label>
						</fieldset>
						<fieldset>
							<label>
								<span ng-bind="$ctrl.last4DigitsFieldText"></span>
								<span ng-bind="$ctrl.card.getLastFourDigits()"></span>
							</label>
						</fieldset>
						<fieldset>
							<label>
								<span ng-bind="$ctrl.currencyFieldText"></span>
								<span ng-bind="$ctrl.card.getCurrency()"></span>
							</label>
						</fieldset>
						<fieldset>
							<label>
								<span ng-bind="$ctrl.billingAddressFieldText"></span>
								<p>
									<span ng-bind="$ctrl.card.getAddressLine1()"></span>, </br>
									<span ng-bind="$ctrl.card.getCity()"></span>,
									<span ng-bind="$ctrl.card.getRegion()"></span>
									<span ng-bind="$ctrl.card.getZipCode()"></span>
								</p>
							</label>
						</fieldset>
					</div>
				</ts-form-fields>
				<ts-form-buttons
						ng-if="$ctrl.includeRemoveCard">
					<ts-menu-button
							button-class="ts-secondary"
							label="$ctrl.removeFromTeamButtonText"
							on-click="$ctrl.onOpenRemoveCard()"></ts-menu-button>
				</ts-form-buttons>
			</ts-form>
		</ts-aside>
		<go-confirm-action-aside
				hide-confirm="true"
				is-open="$ctrl.asides.cantRemoveCard.isOpen"
				message="$ctrl.asides.cantRemoveCard.message"
				on-cancel="$ctrl.onAsideClosed('cantRemoveCard')"
				title="{{$ctrl.removedCardAsideTitleText}}"></go-confirm-action-aside>
		<go-confirm-action-aside
				is-open="$ctrl.asides.removeCard.isOpen"
				message="$ctrl.asides.removeCard.message"
				on-confirm="$ctrl.onRemoveCard()"
				on-cancel="$ctrl.onAsideClosed('removeCard')"
				title="{{$ctrl.removedCardAsideTitleText}}"></go-confirm-action-aside>
		<go-select-aside
				allow-empty-selection="true"
				has-select-all="false"
				is-multi-select="true"
				is-open="$ctrl.asides.selectTeams.isOpen"
				model="$ctrl.asides.selectTeams.model"
				on-close="$ctrl.onCloseSelectTeams()"
				on-submit="$ctrl.onSelectTeams($event)"
				options="$ctrl.asides.selectTeams.options"
				select-all-label="{{$ctrl.allTeamsLabelText}}"
				submit-label="{{$ctrl.applyButtonText}}"
				title="$ctrl.asides.selectTeams.title"></go-select-aside>
	`,

	controller: class CardSourceDetailAsideController {
		/* @ngInject */
		constructor(Card, EventEmitter, LexicoService, PaymentsHelper, PaymentsService, constants) {
			this.lexico = LexicoService.getLexico();
			this.cardDetailsAsideTitleText = this.lexico.trc('Component title', 'Card details');
			this.saveButtonText = this.lexico.trc('Button', 'Save');
			this.cardNameFieldText = this.lexico.trc('Input field label', 'Card name');
			this.teamsUsingCardText = this.lexico.trc('Form field title', 'Teams using the card');
			this.removeSourceCardInfoText = this.lexico.trc(
				'Info message',
				'Removing a source card from a team will not disable existing virtual cards.',
			);
			this.additionalCardDetailsText = this.lexico.trc('Form field title', 'Additional card details');
			this.cardHolderNameFieldText = this.lexico.trc('Form field title', 'Cardholder name');
			this.last4DigitsFieldText = this.lexico.trc('Form field title', 'Last 4 digits');
			this.currencyFieldText = this.lexico.trc('Form field title', 'Currency');
			this.billingAddressFieldText = this.lexico.trc('Form field title', 'Billing address');
			this.removeFromTeamButtonText = this.lexico.trc('Button', 'Remove from team');
			this.removedCardAsideTitleText = this.lexico.trc('Aside component title', 'Remove Card');
			this.allTeamsLabelText = this.lexico.trc('Select all label', 'All teams');
			this.applyButtonText = this.lexico.trc('Button label', 'Apply');

			this.Card = Card;
			this.EventEmitter = EventEmitter;
			this.isSubmitting = false;
			this.PaymentsHelper = PaymentsHelper;
			this.PaymentsService = PaymentsService;
			this.constants = constants;
		}

		onAsideClosed(key) {
			this.asides[key].isOpen = false;
		}

		async getSelectedTeamIds() {
			if (!this.includeSelectTeams) {
				return;
			}
			const cardSourceTeams = await this.PaymentsService.getTeamsForCardSource({
				cardSourceId: this.cardSourceId,
			});
			return cardSourceTeams.filter(team => team.state === this.constants.userStatus.ACTIVE).map(team => team.id);
		}

		async getSelectTeamOptions() {
			if (!this.includeSelectTeams) {
				return;
			}
			const activeTeams = cloneDeep(this.teams).filter(team => team.state === this.constants.userStatus.ACTIVE);
			const selectTeamOptions = await Promise.all(
				activeTeams.map(async team => {
					if (!this.selectedTeamIds.find(selectedTeam => team.id === selectedTeam)) {
						return team;
					}

					const cardSourcesForTeam = await this.PaymentsService.getCardSources({ teamId: team.id });
					team.disabled = cardSourcesForTeam.length <= 1;
					return team;
				}),
			);
			return selectTeamOptions;
		}

		$onChanges(changes) {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}
		}

		onCloseSelectTeams() {
			this.asides.selectTeams.model = cloneDeep(this.asides.selectTeams.model);
			this.asides.selectTeams.isOpen = false;
		}

		async onFormSubmit() {
			this.isSubmitting = true;
			try {
				await this.PaymentsService.setCardName({
					cardSourceId: this.cardSourceId,
					name: this.name,
					teamId: this.teamId,
				});
				const successMessage = this.lexico.trc('Success message', 'Card name updated.');
				ts.ui.Notification.success(successMessage);
				this.onUpdate(
					this.EventEmitter({
						cardSourceId: this.cardSourceId,
						fieldsToUpdate: { name: this.name },
					}),
				);
			} catch (err) {
				const errorMessage = this.lexico.trc(
					'Error message',
					'There was a problem updating the card name. Please try again.',
				);
				ts.ui.Notification.warning(errorMessage);
			}

			this.onClose();
			this.isSubmitting = false;
			this.name = '';
		}

		async onOpen() {
			this.isLoading = true;
			this.teamId = get(this, 'team.id');
			try {
				const cardSource = await this.PaymentsService.getCardSource({
					cardSourceId: this.cardSourceId,
					includeCardHolder: true,
					teamId: this.teamId,
				});
				this.selectedTeamIds = await this.getSelectedTeamIds();
				this.selectTeamOptions = await this.getSelectTeamOptions();
				this.card = new this.Card(cardSource);
				this.name = this.card.getCardSourceName();
				this.asides = {
					cantRemoveCard: {
						isOpen: false,
						message: this.lexico.trc(
							'Card removal warning message',
							'You can not remove the only source card linked to this team. Add another card in order to remove this one.',
						),
					},
					removeCard: {
						isOpen: false,
						message: this.lexico.trc(
							'Card removal warning message',
							'Removing a source card from a team will not disable existing virtual cards. Are you ' +
								'sure you want to remove this card from this team?',
						),
					},
					selectTeams: {
						isOpen: false,
						model: {
							id: this.selectedTeamIds,
						},
						options: this.selectTeamOptions,
						title: this.lexico.trc('Option select title', 'Select teams'),
					},
				};
			} catch (error) {
				const errorMessage = this.lexico.trc(
					'Error Message',
					'There was a problem getting the card details. Please try again.',
				);
				ts.ui.Notification.warning(errorMessage);
				this.onClose();
			} finally {
				this.isLoading = false;
			}
		}

		onOpenRemoveCard() {
			if (this.numCards > 1) {
				this.asides.removeCard.isOpen = true;
			} else {
				this.asides.cantRemoveCard.isOpen = true;
			}
		}

		async onRemoveCard() {
			try {
				await this.PaymentsService.removeCardSourceFromTeam(this.team.id, this.cardSourceId);
				this.onRemove(
					this.EventEmitter({
						cardSourceId: this.cardSourceId,
					}),
				);
				this.onAsideClosed('removeCard');
				const successMessage = this.lexico.trc('Success message', 'This source card was removed from the team.');
				ts.ui.Notification.success(successMessage);
			} catch (err) {
				const errorMessage = this.lexico.trc(
					'Error message',
					'There was a problem removing the source card from the team. Please try again.',
				);
				ts.ui.Notification.warning(errorMessage);
			}
		}

		async onSelectTeams({ id: newTeamIds }) {
			this.asides.selectTeams.isOpen = false;
			const oldTeamIds = this.asides.selectTeams.model.id;
			const teamsToBeAdded = difference(newTeamIds, oldTeamIds);
			const teamsToBeRemoved = difference(oldTeamIds, newTeamIds);
			try {
				await Promise.all(
					teamsToBeAdded.map(teamId => this.PaymentsService.addCardSourceToTeam(teamId, this.cardSourceId)),
				);
				await Promise.all(
					teamsToBeRemoved.map(teamId => this.PaymentsService.removeCardSourceFromTeam(teamId, this.cardSourceId)),
				);
				this.asides.selectTeams.model.id = cloneDeep(newTeamIds);
				this.onUpdate(
					this.EventEmitter({
						cardSourceId: this.cardSourceId,
						fieldsToUpdate: { teamsCount: this.asides.selectTeams.model.id.length },
					}),
				);
				const successMessage = this.lexico.trc('Success message', 'Teams updated.');
				ts.ui.Notification.success(successMessage);
			} catch (err) {
				const errorMessage = this.lexico.trc(
					'Error message',
					'There was a problem updating the teams. Please try again.',
				);
				ts.ui.Notification.warning(errorMessage);
			}
		}

		validateCardName(value) {
			if (isNil(this.name)) {
				return;
			}
			return { valid: value !== this.name };
		}
	},
};

export default CardSourceDetailAsideComponent;
