const SelectTeamActionsAsideComponent = {
	bindings: {
		goToTeamDashboard: '&',
		goToTeamSettings: '&',
		isOpen: '<',
		onClose: '&',
		title: '<',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{ $ctrl.title }}">
			<menu data-ts="Buttons">
				<li>
					<button
							class="ts-tertiary"
							ng-click="$ctrl.goToTeamDashboard()">
						<span ng-bind="$ctrl.teamDashboardButtonText"></span>
					</button>
				</li>
				<li>
					<button
							class="ts-tertiary"
							ng-click="$ctrl.goToTeamSettings()">
						<span ng-bind="$ctrl.teamSettingsButtonText"></span>
					</button>
				</li>
				<li>
					<button
							class="ts-tertiary"
							ng-click="$ctrl.onClose()">
						<span ng-bind="$ctrl.cancelButtonText"></span>
					</button>
				</li>
        	</menu>
		</ts-aside>
	`,
	controller: /* @ngInject */ function (LexicoService) {
		this.isLoading = false;
		this.lexico = LexicoService.getLexico();
		this.teamDashboardButtonText = this.lexico.trc('Button', 'Team Dashboard');
		this.teamSettingsButtonText = this.lexico.trc('Button', 'Team Settings');
		this.cancelButtonText = this.lexico.trc('Button', 'Cancel');
	},
};

export default SelectTeamActionsAsideComponent;
