module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# transakcií",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minúta",
"ce09c436b49af331a32f1152ede7dd18":"1. Vyžiadať finančné prostriedky",
"d2226752d3065fbad4744924b4a0ee73":"2. Skontrolovať a schváliť",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Skontrolovať a schváliť",
"82bab92f1c8030888550b51521266c53":"3. Zaplatiť s virtuálnou kartou",
"2ecd62e02e507f8e839a55eac0a04886":"Položka kódovania s ID '{entryId}‘ už existuje.",
"5dfd25849740fbce8147838b0d5094eb":"Vlastná prezývka, napr. Marketingová karta",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Člen nášho tímu úspechu zákazníka vás bude čoskoro kontaktovať.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Osoba, ktorú sa nám nepodarilo vyhľadať",
"976b50e70e4433e2786233979d243445":"Tím s týmto názvom už existuje.",
"35b61d21ac11d5fc31ebe58541263445":"Prijmite pozvanie na pripojenie",
"a372cb2d4204654f52cd9170fb10d374":"Účtovné kódy",
"110fc66010b6b91cebee7a59a2067aab":"Účtovné kódy",
"db862bb04dbdc6d04e47063f33be8865":"Účtovné kódy sú vedené prostredníctvom zoznamov kódov a položiek v zoznamoch.",
"d6e3848e824f077396be4351eb58edd2":"Vyžaduje sa akcia – schváliť/odmietnuť novú žiadosť od {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Akcie",
"476e728ea824229f52438e543818bcdd":"Akcie",
"3117746cb7d4ab815ec98274ec4398d5":"Akcie",
"3ab09b66a7f16219d8135977a2a76d10":"Akcie",
"ffafa66eaab0409a2e90aaeb43796def":"Aktívne",
"0447e0aa5d00dbdab11b5546793c4479":"Aktívne",
"8ee51d21b05466f7d962d4ff5a0b384e":"Aktívne karty",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Aktívne virtuálne karty",
"89c6ec05861aa914304ba35bc6744124":"Aktívne karty",
"5280659bcecaf8344c57790f9962a9a2":"Aktivita",
"8a15368ae286fa64e1cb7624cd410fc6":"Pridať zoznam kódovania",
"8ded7e09e86be231b1165308329ac76d":"Pridať zoznam",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Pridať správcu",
"695bb4bc29e2df769ddf7427ab71012a":"Pridať schvaľovateľa",
"4a1d4516cab00b7c5ecdb150e346e5db":"Pridať schvaľovateľa",
"56869851b27c2915f5a7fe6a33b91ac7":"Pridať zoznam kódovania",
"8f8691057c3994aa61f9994202680c87":"Pridať zoznam kódovania",
"69f7a406b79d19bf8082ed245ab29123":"Pridať zoznam kódovania",
"48a92ca1cc63018707be99b13196369f":"Pridať správcu spoločnosti",
"a679db9ff80d0e5769ae1ba311c7916f":"Pridať doménu",
"8f5e8078be77146ba7f3be49ef948906":"Pridať položku",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Pridať správu",
"f8a4b14162e19995074e63135b65c84d":"Pridať novú kartu",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Pridať osobu",
"c670549d0b898bb6a571eb2cd7804a6e":"Pridať potvrdenie",
"eb937098e77d8a6b0565ed72357dcaae":"Pridať potvrdenie",
"1e154f31c31b63a459f3956e380b3ef9":"Pridať vybraté",
"ba24db9956f156a18b32e653552ff682":"Pridať niekoľko ľudí do tohto tímu",
"52bd93fb5489b25167bb541f7c6504ae":"Pridať zdrojovú kartu",
"21ab3f08eb507dbc76df166eafeb393a":"{fullName} bol pridaný ako schvaľovateľ!",
"f879c06507d3076bbf52ea4fd2df850e":"Ďalšie údaje o karte",
"21e934ae30eece4a894337701f497638":"Všetky",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Všetky karty",
"8fac71195e2b506e029752e4864b6a5d":"Všetci členovia",
"f9708417df16227742e0545a8642eea7":"Na túto kartu už boli pridaní všetci dostupní používatelia.",
"cb5abf070f8cba7982205038baab77bb":"Všetky karty",
"dae7d602eec2c84dea0d80ae25e56e6d":"Všetky karty",
"0b4bdfde6578668b6018d3615a1ad30f":"Všetky nákladové strediská",
"97b875b81f54b4432d998a3e47d949cc":"Všetky tímy",
"f0ae83969f7c20a1964caf5156dae966":"Všetky tímy",
"d87455601a00282078582b36a3825b7d":"Všetky tímy",
"24c7b7f55882ea94a525392ca8218fa0":"Všetky tímy",
"8f860e35b64a8f447bded1e5ea66a2a8":"Všetky virtuálne karty",
"e403956ecaa6fb33ec1fef5c49d71042":"Všetky virtuálne karty",
"4e6f4e8f25f61d40e1eba77de94f6458":"Všetky vaše finančné informácie sú šifrované a nachádzajú sa na serveroch na bezpečných miestach.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Čiastka",
"a4d099d0dbe3889b0995546e6ed28932":"Čiastka",
"6c0ef32aae76bc31a61e2124f542bdf1":"Čiastka",
"adec89c93525f1a8d1c64d856f405e27":"Suma: od najvyššej po najnižšiu",
"18e632a170203894f40deed1d33ee552":"Suma: od najnižšej po najvyššiu",
"a7944575cafef9126a499a1b06999fe3":"Vyskytla sa chyba, skúste znova neskôr.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Použiť",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Možnosti schválenia",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Nastavenie schválenia",
"492eedd88cdd7bb45f39c6594af748c4":"Schválenie",
"437d490afc8d226f8c7d544f4e35d011":"Limit na schválenie",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Schválenia",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Schválenia",
"17adb7fe866e32055b1469fdb7e82795":"Nastavenie schválení",
"7125aa13db437084e1d27d3ff38f22e2":"Schváliť",
"84df787ff5746f9dc3cf0146becef4fd":"Požiadavka na schválenie",
"7a568654d00245b0bab6c90a1b67d196":"Schválené",
"179c3e187ad8edeb8348cff222b938bf":"Schválené",
"ad058d17725870df9abbd4f5ec217636":"Schválené ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Schválená suma",
"4d58ba5c0e03fec1556c243be106e4a3":"Schválil",
"7d3d697fc99f6c4641b326623743f7b7":"Schválené { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Schvaľovateľ",
"8c45e443fc6748190719d79a092e2528":"Schvaľovatelia majú maximálny limit na schválenie",
"4fe56aa6456c108cd288a4ec8acf435f":"Archivovať",
"a9b6f93de9630064be9809130aa1e195":"Ste pripravení prevziať kontrolu nad výdavkami na vašej karte?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Naozaj chcete zrušiť túto požiadavku?",
"301bfc2ce058f49e0704fec3d162b5b5":"Naozaj chcete túto požiadavku priradiť znova sebe?",
"2181faf8d9973d1f4138e401a762086e":"Naozaj chcete odstrániť ${firstName} ${lastName} z úlohy správcu spoločnosti?",
"b982277f29055d0ef6e295fe2006d9e0":"Naozaj chcete odstrániť {firstName} z tímu?",
"456c0ccff14acb808583cd9dece857b6":"Priradený k",
"bde315b9cf7fd55f4b65aabbbf38d462":"Pri použití mimo pracoviska musí byť k dispozícii aspoň jeden manažér alebo vlastník tímu.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Overenie",
"1961ec69dd0c8b5718c831312e7f3d0f":"Automatický zber potvrdení",
"e39f2477cf34d726936a5b5c2b48a4ad":"Automaticky vytvárajte presné odsúhlasené zostavy, ktoré obsahujú informácie o kupujúcich, schvaľovateľoch, príjemkách, účtovných kódov a ďalšie.",
"434ca07dfde0eaee14f094b7c75a6eae":"Dostupné",
"eb85d8f50f2c89871b794f7d54279b22":"Dostupný zostatok",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Dostupné { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Späť",
"c8258f8cf9e20f6bbb89d957de40e820":"Zabezpečenie na úrovni banky",
"d46c22a3f36dce568c07fcea4368b231":"Základné nastavenia",
"fb481c8e517016094b4fdf618c5fa088":"Adresa pre fakturáciu",
"79a9f821923c5a04c076902fef684281":"Adresa pre fakturáciu",
"877a08b8347052c83aa0b689baea81e7":"Adresa pre fakturáciu",
"97f018458e2627fac37d24846c7b8922":"Pokračovaním vo vytváraní účtu a používaním služby Tradeshift Go vyjadrujete súhlas s našimi",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Pokračovaním vo vytváraní účtu a používaním služby Tradeshift Go vyjadrujete súhlas s našimi",
"912d1864f0068c764bf4784020795d49":"MENO DRŽITEĽA KARTY",
"d53474c7fd99fbeb69481ce9d358aa09":"Môže požiadať o karty a mať peňaženku na všetky svoje karty. Každý člen dostane predvoleného schvaľovateľa (manažéra) na svoje požiadavky.",
"f30df2cab105ed3f8b4264aeb9800abd":"Môže schvaľovať požiadavky a vytvárať karty pre členov, ktorých spravujú. Manažéri majú panel na sledovanie výdavkov členov, ktorých spravujú.",
"03ed9eccc71ef59165292eac542d3050":"Môže zobraziť všetky požiadavky a výdavkové činnosti v tíme. Vlastníci tímov tiež dostanú tímový panel na sledovanie výdavkov a tímovú peňaženku, kde môžu vidieť všetky aktívne karty.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Tento používateľ sa nedá odstrániť. Potrebujete aspoň jedného vlastníka tímu alebo  manažéra tímu.",
"a0b70642629a55ea0ab6fa3414d342e0":"Nemôžete odstrániť používateľa, ktorý má nevybavené požiadavky.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Zrušiť",
"5446624d3c364ac05ddf8d6663da2796":"Zrušiť kartu",
"53f3815c4fa3340b29efd81e627d1e6b":"Zrušiť kartu",
"12257ea2a871b7fab72245b5aafe6424":"Zrušiť požiadavku",
"2aafb7eb4d56a516c4bb0754f0812df1":"Zrušiť kartu",
"caae12601b01e2325161e95107f9e332":"Zrušiť kartu",
"6ad1e57b1e4c7accbe166c948883077d":"Ak dôjde k narušeniu zabezpečenia, zrušte jedným kliknutím",
"f6527e49e6d5c989624507a583e23f39":"Zrušené ({count})",
"1ad3bf5121641dda511f42942af05a90":"Zrušením tejto karty sa nestornujú žiadne čakajúce poplatky. Skontrolujte úhradu",
"aebd3826a195afad0a93050a7cc5d73d":"Zrušením tejto karty sa nestornujú žiadne čakajúce poplatky. Vyhľadajte záznamy o úhrade týchto poplatkov vo výpise kreditnej karty zaslanom poskytovateľom karty.",
"a5b5136947da562f91b75daf8156f50e":"Údaje o karte",
"9ed31ad23a1578ce480d31207decf1d3":"Nastavenia karty",
"def15f2e007fc69886230fdb2511305c":"Údaje o karte",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Údaje o karte",
"67cfd260078ca32027c2fa4a4a2d29f4":"Držiteľ karty",
"4458819ea60c68c46e551a8f5cc163cd":"Názov karty",
"0858bcbd506fa3240b165e59d2f18aae":"Názov karty",
"5212736dd18ee2c31f3650ad2c2c7130":"Názov karty bol aktualizovaný.",
"c96d62fa510c3356dc73a91d1a80e262":"Číslo karty",
"41307fee4c32aefb5383a98fe3d6e45e":"Typ karty",
"3f8045c3358621dad4346a44de0c05a8":"Meno držiteľa karty",
"879a6b452033cf5f3a4bb3e1837a0386":"Mesto",
"593334553ef852024e172505ec3c5f21":"Kliknite sem",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Kódovanie",
"df931751d5790d74fa46707aaf7a26ae":"Kódovanie 1",
"7ac7154bc776aff52a71b0d83b860561":"Kódovanie 2",
"586fcf19136ae5e26795e9055b2e2995":"Kódovanie 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Kódovanie 4",
"379bb8e71bb090badebbb0751de86f11":"Kódovanie 5",
"3e84d13627b3d090ab2b8470af98340a":"Kódovanie 6",
"33b94b581f3792049992663396e51cb8":"Kódovanie 7",
"b73fbc32e439655833ee99126dd0e55f":"Kódovanie 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Zoznam kódovania",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Položka kódovania",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Položka kódovania bola odstránená.",
"cada62a39ee72d790dcdbb25160f8783":"Položka kódovania bola uložená.",
"f645bf50888943ee1384693e56a0f475":"Názov zoznamu kódovania",
"a999182acbcd77c15299f79181b630be":"Zoznam kódovania bol uložený.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Zoznamy kódovania",
"d0b96596ce6baec5aabb8a0690ab30e1":"Komentáre",
"ff9cb8842f832d21fb160b3d3784ce79":"Spoločnosť",
"715b59a64e686eb91a21114644ffe39e":"Správca spoločnosti bol odstránený",
"8758e9a01253c097a42393fda9610715":"Tabuľa spoločnosti",
"e118b41009918b326db6746856831cbc":"ID spoločnosti",
"4dba88b05b3fa8875128e19e88c512b3":"Nastavenia spoločnosti",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Nastavenia spoločnosti",
"f2ea6d33de8182d97bf2f13354af4893":"Nastavenia spoločnosti: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Správcovia spoločnosti",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Limit na schválenie pre spoločnosť",
"7e2f778da14a726b53e05926a3483f2b":"Firemná kreditná karta",
"7313fd1db3d63858586d1c0f89251d6e":"Názov spoločnosti",
"07721e91dd10ff8831f0d24e47cc1eab":"Názov spoločnosti",
"203221610ca4d347063c037e1a732ae8":"Nastavenia spoločnosti boli uložené.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Potvrdiť akciu",
"d694cec09cdbc02b1ffe22642a63faa1":"Potvrdiť heslo",
"512801312a133f29974f2259a455f39b":"Potvrdiť heslo",
"f54c38ed0b9869d929ffc0bd5310131f":"Potvrdenie – váš účet bol vytvorený v tíme  {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Kontaktujte správcu spoločnosti, aby vám vytvoril účtovné kódy.",
"7a6a4de077398e8e138aec36e3b3d305":"Pokračovať pomocou SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Autorské práva {date} Tradeshift Inc. Všetky práva vyhradené.",
"51f0d8e04f6ee626b866b37833dc2b61":"Nákladové stredisko",
"e043f60a02f157d0f31a42f0db67cc87":"Nepodarilo sa pozvať {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Vašu správu sa nepodarilo odoslať, skúste to znova.",
"ae28083f22a0b2fc5637614bce0976e0":"Vytvoriť vlastný zoznam",
"738078dc992208e05406f935995dc0c3":"Vytvoriť vlastný zoznam",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Vytvoriť nový zoznam",
"0f5783c3921b3d7398d281644bbe5bde":"Vytvoriť požiadavku",
"6d62f934f2304701f88bdcfbc688de7e":"Vytvoriť účet",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Vytvoriť účet",
"f6b7d0d8531ea268f988005dd2147b2b":"Vytvoriť kartu",
"1a083675d1453448e4645403bbe52dd9":"Vytvoriť vlastný zoznam",
"c10ab11bdfb001da74850ed89c45482f":"Vytvoriť novú požiadavku",
"43a9c06398408bfe77d06ffc2c1c3b87":"Vytvoriť novú požiadavku",
"68bf17fd3f54a89c0a84224e2f9a971c":"Vytvoriť novú daň",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Vytvoriť tím",
"3b8e665101b9b3657f8a68c5305947b7":"Vytvorená",
"40e91f2907181ffa8e62567b3a407e60":"Vytvorená",
"8929f7aacb969ef6353aba9903eb362d":"Kreditná karta bola pridaná.",
"d86688c26e8ac5eb9facc971b6fe5155":"Mena",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Mena",
"500ded53f64e737a5e4337675ac9b7ce":"Názov vlastného zoznamu",
"b79a95f04fd829fb2e8d0313e522a94d":"Denne",
"ef445eab224fc599e4103c9b1745a4e0":"Tabuľa",
"788452c3ad2b1450b6d8d116b863e635":"Tabuľa",
"fb7add662cf7042355319a975fab6ae3":"Šifrovanie údajov",
"6bded249dbfafd14490eea8dd37e1f51":"Dátum",
"46e908a57cb09ef947af8c4a93a09716":"Dátum",
"e4213104a55eceb60e00408699438dcd":"Deaktivovať tím",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Deaktiváciou tímu sa zrušia všetky aktívne virtuálne karty a žiadny z členov tímu, manažéri alebo vlastníci nebudú mať prístup k tímu. Znovu aktivovať tím môžete iba vy alebo iný správca spoločnosti. Naozaj to chcete urobiť?",
"0d0d5677bee2b99884802638e507160a":"Odmietnuť",
"b5e3483aae9d36ce1440fe02904d6da1":"Odmietnuť požiadavku",
"00f3db97287cb17ee9c2a131f2141a65":"Odmietnuté",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Odmietnuté ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Predvolený schvaľovateľ",
"b77589ac3ac2317cfc96d7013658d5c9":"Odstrániť ID kódovania: { entryId }, položka: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Podrobné výpisy a hlásenia",
"5234dfc4220ccd18a8b62fe84e372248":"Podrobnosti",
"37929896817a6744718004374bb42fd8":"Podrobnosti",
"dd0a6194e8a6ae14f3e1379a1640de15":"Chceli ste odstrániť {fullName} ({email}) zo spoločnosti?",
"1a98048b34090da8b199135ccb4c1186":"Rozlišovacie číslo a CVC skryjú podrobnosti o skutočnej karte",
"3cf132b60fe16b816ca227bbcad60537":"Nezrušiť",
"dee57797e14ccf852aa2bc545bafdf6d":"Prevziať",
"375ae8224d7d35393974775cebe8605a":"Stiahnuť zoznam používateľov",
"54b3bfd45a293b441d441aab1a50e644":"Prevziať zostavu",
"60cf3b94e5df14a504825068928fc118":"Stiahnuť zostavu CSV",
"7341a4c73c0b688484304c37da4a6d8e":"DÁTUM SKONČENIA PLATNOSTI",
"f628f8ad44fbd6c70c99fc778407b666":"Jednoducho vyhľadáte historické transakcií a preskúmate tendencie vo výdavkoch zamestnancov v akomkoľvek časovom období.",
"94bb409d034ea20697ae09d812e55365":"Upraviť vlastný zoznam",
"2974e704676cb79666bc236d8658fa97":"Upraviť profil",
"9ff205f99ed4002d049f855f965ca6d8":"Upraviť používateľa",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-mail",
"0534c00af758d42ebd8b0bd772eab074":"E-mail",
"0c9aa1fbdf925065d25161eab5e51d95":"E-mail",
"ea78fac68766a48b1b0f41305b7e9898":"E-mail",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Zamestnanec požaduje finančné prostriedky z podnikovej karty na nákup. Požadované finančné prostriedky môžu mať nastavené obmedzenia výdavkov.",
"829cff16ce0b13810c914c161b2a9fba":"Povoliť alternatívne používateľské funkcie.",
"741387c7bd71e2b4a9601af9a6b9490e":"Povoliť automatické zasielanie požiadaviek na schválenie",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Povoliť predvolený dátum uplynutia platnosti virtuálnej karty",
"bb319710dad93e78cc6f58c615bdf210":"Povoliť vytvorenie bielej listiny e-mailov pozvaných používateľov",
"4700ced24cd278c3149ffa983006035c":"Povoliť filtrovanie podľa nákladového strediska",
"3fe06ef8f551c4cb4c0375362e25521d":"Povoliť možnosť globalizácie aj mimo používateľa",
"2787d4bc9752272ac36b499ce7ad93ab":"Povoliť viacúčelové virtuálne karty",
"6ee44e2280c9ce5009568846cbc3dd14":"Povoliť používanie mimo pracoviska",
"1941ca87053df0cebc6091ede92c18ea":"Povoliť zobrazenie ľudí pre používateľov správcovia spoločnosti",
"e50282c2ec7afb948d2c0b428db5d5ff":"Povoliť nahrávanie potvrdení pre transakcie",
"268694cb2172918fe60f835919bf20ec":"Povoľte nahrávanie potvrdení do tabuľky vyrovnaní",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Povoliť vlastné schválenie",
"d17c7acf598b569cce8a2bb97e0bb215":"Povoliť jednoúčelové virtuálne karty",
"cd731ca218c9e14caabefafe003cfa6d":"Povoliť predplatenie virtuálnych kariet",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Ukončiť posielanie ďalej",
"c1f665a814894b17d603b7052b631d90":"Zaregistrovať novú kartu",
"6b6cea93e07e596be70f7b76801e1516":"Zaregistrovať novú kartu",
"7d6ac1ac9cba1620b61908842ebb106b":"Zaregistrovať zdrojovú kartu",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Zaregistrovať zdrojovú kartu",
"21a642b339c3eabf2789c0b05dcf828b":"Zaregistrujte svoju prvú firemnú kartu v službe GO",
"6efa8dca4fba23a190838746879fd704":"Zadajte číselné ID",
"02c3457577da9dc8dba6e6f9380a248e":"Zadajte jedinečný názov tímu",
"40feb3fd1fa529cb599aeac9607664a4":"Zadajte novú položku",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Príklad: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Mesiac vypršania platnosti",
"b8bc74aeeb2de3492891c97361de107d":"Rok vypršania platnosti",
"6a0b8d521500014d99dd192d8ce8403d":"Dátum uplynutia platnosti",
"8cf274169a732340430fe7cc5236e0e7":"Veľkosť súboru by mala byť menšia ako {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Meno",
"7458b55e967e51791d98e77458631e44":"Meno",
"3bbe52e71bc5c212cea77983f1a5b748":"Meno",
"9b49c88fa20cc006baaac15c857c8781":"Na samostatné alebo viacnásobné nákupy",
"bb7a2907073bd760f4e82097132637b1":"Zabudnuté heslo",
"26bdfaa592f3629273f13c22435b323d":"Zabudli ste heslo?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Poslať ďalej",
"3f9fe6adc14ce5776097170653a24462":"Poslať požiadavku ďalej",
"b774e35683f5737c36bf0cf3d6785973":"Poslať požiadavku ďalej",
"628faa5054a912b1e470414f77057e6b":"Poslať požiadavku ďalej",
"852b4065e1837fdc77940b6411a6d902":"Ochrana pred falšovaním",
"616551bd10d95aca439401d6a7769d96":"Suma prepravného",
"a65a230918e9c11e1ef8824745e26a3d":"Začíname",
"7d4c4466b7716347ce3673acaa1983f7":"Využite možnosti Go naplno",
"11415a07658cfef80eb166896d77ad98":"Ešte viac informácií o výdavkoch. Aplikácia Tradeshift Go je teraz ešte lepšie prispôsobená štýlu práce obchodného tímu.",
"ac0ff5d14286ef74614a9c6021f08f69":"Môžete sledovať výdavky a rozpočet v reálnom čase a získať podrobné prehľady všetkých nákupných aktivít.",
"db9071ab833054307252a936e7f60e1b":"Začíname",
"24dc4bb00841b004031fca3a9bb9bf51":"Žiadosť o kartu Go bola zrušená používateľom {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Služba Go upozorní zamestnancov na každú chýbajúcu potvrdenku, ktorú je možné priložiť jednoduchým odpovedaním na oznámenie, alebo v prípade prijatia e-mailu, poslať ďalej na Go.",
"a73603f0bc610ecea9ef9c793b802538":"Služba Go pracuje so sieťou kreditných kariet a garantuje vám rovnaké štandardy predchádzania podvodom, aké by ste očakávali pri práci s najprestížnejšími finančnými inštitúciami.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Dobré správy! Vaša požiadavka na nákup v aplikácii Tradeshift Go bola schválená",
"ee397c9cb0668cc9cba62e1c1e619615":"Máte otázky alebo pripomienky? Kontaktujte nás na adrese",
"9df1d3de68d663b848101df3c7b58e39":"Ako to funguje",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Potvrdzujem",
"84074732eb8d1b4094404cd750baf949":"Ak nie je uvedená žiadna hodnota, uplatňuje sa limit spoločnosti",
"138e8bd6102a7eea70a123ff03b1cd79":"Ak nie je uvedená žiadna hodnota, uplatňuje sa limit tímu",
"e5756af4d7e5ad1236ccfbb858428421":"Ak ste sa nezaregistrovali v aplikácii Tradeshift Go pomocou tejto e-mailovej adresy",
"4f004f75f47dbfecdb6d09990596e530":"Ak váš e-mail ešte nie je zaregistrovaný, pošleme vám aktivačný e-mail s informáciami, ako postupovať.",
"123837f267de602fb05361b3b1197c1e":"Ak sa {username} zhoduje s existujúcim účtom, pošleme vám e-mail s informáciami o tom, ako postupovať.  Ak tento e-mail nedostanete do niekoľko minút, skontrolujte priečinok s nevyžiadanou poštou.",
"45efd96cd93525a9c17222217a37d324":"Podporované sú formáty súborov obrázkov alebo PDF",
"fa6ae823f9ab8bf91c246efe2517956c":"Importujte si nastavenia účtu QuickBooks, NetSuite alebo Xero priamo do služby Go. Exportujte najnovšie transakcie do svojho účtovníctva jediným kliknutím.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Neaktívne",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Vstup obsahuje neplatný znak.",
"62c7be09da43f8f96a60ef3967eddc2c":"Predstavujeme úplne novú službu Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Neplatná e-mailová doména",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Pozvať osoby",
"3809b41b14c55c3fcf54cd813d8e21f5":"Pozvať do tímu",
"fc90a11805961b0d7b5a5545e4d9bc05":"Pozvať používateľa",
"6f7f4acde92d267417f5d8a60d32400a":"Pozvať ďalšie osoby",
"09ecd2ca15bb5c38dc10f653118346cd":"Pozvať nového používateľa v roli správcu",
"3f576ce504f53f972442a63a170c5418":"Pozvať nového {role}",
"0215e052437ceea4e5cd562431b34ced":"Pozvať osoby",
"8aaf2c77df644b7e8524120f66da7d5f":"Suma faktúry",
"1b7dc8290f1205f092cc9133e0cde385":"Dátum fakturácie",
"1a6fb210308df8e0135e49e76aaff31e":"Číslo faktúry",
"613da3d0cd586ef0360e0d93eace9508":"Vystavené na",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Bude to trvať",
"3322b671105c65511fbab1966b7fdcf6":"Jazyk",
"b1d946192795a58aea6a61f4f923acb7":"Posledné 4 číslice",
"b3c6b8db4ef6dcdcb451303e65548816":"Posledné 4 číslice",
"0382123b9c847000fceff2484c665e39":"Posledné štyri číslice",
"19f7c0055e150e9e3e66e85c2993799c":"Priezvisko",
"662c84bede8a7bf91ab15bcb73b48b49":"Priezvisko",
"cff8fbef5aac2523344b9535bd56973c":"Priezvisko",
"c3510b2184a92ff12e07a2d1b9a461e6":"Umožnite zamestnancom požiadať o prístup k vašej firemnej karte bez sprístupnenia podrobností o karte, a to všetko pri presadzovaní rozpočtov a sledovaní každého nákupu.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Limit môže byť prepísaný na tímovej aj individuálnej úrovni schvaľovateľa",
"9bf5d56627e500cad9f7ad21c3e7966d":"Limit na určité kategórie výdavkov",
"e80a6d4232e52f6bfabee02e17403b59":"Prepojte jednu z týchto zdrojových kariet s týmto tímom alebo zaregistrujte novú.",
"803570ab9f0fba5ff35ae2749200501f":"Načítavajú sa karty...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Prihlásenie",
"ad96c1ca962a883652dadb845f439415":"Prihlásenie",
"072ca67e735adbed54acf41321370701":"Prihlásenie",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Prihlásenie",
"b246fe8836c906f1b56342378ca88895":"Nakupujte, schvaľujte a monitorujte nákupy v tímoch, ktoré odrážajú vašu organizačnú štruktúru.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Spravovať úhrady",
"95d672ab621e531ae1a69798e0e4b56c":"Spravujte všetky svoje virtuálne karty na jednom mieste.",
"96f2e88a167f476413b35aa711709e6e":"Spravujte úhrady",
"151d8e6ceec242d482fff60da8311449":"Spravovať",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Spravovať",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Manažér dostane každú požiadavku na kontrolu a schválenie. Taktiež je možné upraviť aj obmedzenia výdavkov.",
"4f449985e83c1f9ca2e8f82b0707a371":"Manažér dostane každú požiadavku na kontrolu a schválenie. Taktiež je možné upraviť aj obmedzenia výdavkov.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Manažéri",
"27f8111a955975ea6fd04f748d24cb4a":"Maximálny limit na schválenie",
"5b1d9cfb78c55e75b47c62435d9299ca":"Člen",
"41e307c1874e2e82296b226e9bae4a5f":"Člen",
"6f9612aa1582373c2c1ba4e144132100":"Členovia",
"590429a0fcc42af85648d0fd70601dcc":"Členovia",
"3f50e62c8488d83ad84f69ebda853897":"Členovia",
"e0fc0acda5b74b93e797756dbbba67bc":"Členovia",
"c7ef9eda38b7eaee1f20031387a8bde7":"Mesiac",
"40dd08b507752bef4e3c4b36838317d5":"Mesačne",
"d712b91f7498f212047aad4c6d0f8088":"Najnovšie",
"12110d95b3d7a495afa4f28ac190f63e":"Najnovšie",
"2f11ce3865f79ee7e5ee64d728082264":"Najnovšie",
"eadc93865655cffc6a9863fdda5693e1":"Musí mať minimálne 10 znakov a obsahovať veľké a malé písmená.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Musí mať minimálne 10 znakov a obsahovať veľké a malé písmená.",
"50d0a69aa59df5201018771a06ea12e7":"Musí byť jedinečné",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Musí byť jedinečné.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Moje karty",
"c36602f50d35c2816f0ba8a196b70876":"Moje požiadavky",
"ff6e372aa3eb685a39a701963db35b2f":"Moje požiadavky",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Názov",
"1c7514b561a2591a914225813d30d642":"Názov",
"d621826c1ceade262589af04e655e824":"Požadované nové heslo",
"35dc3304b61a9e69dc3a393123658837":"Nové požiadavky budú automaticky preposlané počas tohto časového rámca.",
"e788124801b4727d121f60d059a2a4a7":"Nasledujúci",
"eca804f1d53dfd984ffd93e2d00fc284":"Žiadny schvaľovateľ nie je priradený, kontaktujte svojho správcu programu.",
"abdf7e93f8509399a63108a2b1a25546":"Žiadna aktivita na zobrazenie",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Vo vašej peňaženke nie sú žiadne karty",
"87127e1e9f5bf922d0a4f948b68697c8":"Bez limitu",
"40512efa6b75ef0c7924b1ee3070fca9":"Žiadni členovia na zobrazenie",
"dcda493afcb2415d5ca49a17d1af6f50":"Zatiaľ neboli zverejnené žiadne úhrady.",
"f0efb26e623bb990532e375d5c8875ec":"Nedostupné",
"bc0cf602d1978e814070bb552e576a32":"Nepriradené",
"09e126c317429ccb52ad323c32293648":"Počet platieb",
"75b00b969b9d3b520929f1e652637b70":"Počet platieb",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Počet platieb",
"bd44ba180aa356c5755fdedd32adf03c":"Ach jaj! Počas vytvárania požiadavky sa vyskytol problém. Skúste to znova.",
"c396b3728dd7393aa9e5427990c132a7":"Ach jaj! Pri sťahovaní zostavy sa vyskytla chyba.",
"42c07764b07dea1ee7de7605721c3f5f":"Ach jaj! Nepodarilo sa nám vytvoriť tím.",
"e3a385878ec94bac12a99490d0a8e85a":"Ach jaj! Nepodarilo sa nám vytvoriť váš účet.",
"17f2dae79677017e2c2e879578c4e09a":"Ľutujeme! Vyskytol sa problém. Skúste to znova!",
"78c135de545c1663c9768226492a44a3":"Alebo použite jediné prihlásenie (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Mimo pracoviska",
"5d7e45f169e2f6968dc58f43b5267353":"Mimo pracoviska",
"ee589a20c262527db970671fb2c4f6bc":"Mimo pracoviska",
"4fd0c753fce49a016b98d988a3895223":"Kontrola",
"2789c5d5c9c042858f45aadc4e0de058":"Kontrola",
"083b7090b66ebd0307a18499ac7b676e":"Stránka neexistuje",
"a21d205920893cf579a7655a1430fe30":"Nadradená karta",
"fd35b7ce938e404fdb65de3bb749c528":"Nadradená karta na vykazovanie",
"e8c7d2eea7db66ecb44751091a582304":"Heslo",
"bb79e316e00fd21138750b6986e44455":"Heslo",
"6cae2279088580976d84119d95097cae":"Heslá sa nezhodujú.",
"4baec0159d3074f84c0eebcc09ba4cd8":"Heslá sa nezhodujú.",
"b676587115243921602742cb8f696a6a":"Pozastaviť vytvorenie karty",
"db4a26c871ef0b970b74e1f71763098b":"Pozastaviť vydávanie nových virtuálnych kariet",
"33fc1e6d01b785e3a815c28ef7d56667":"Čakajúca",
"6ea62e4811b68df48b467e8d367aac2f":"Nevybavené požiadavky ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Ľudia",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Ľudia v tíme môžu mať 3 rôzne úlohy",
"e2d34cb1409b9778d34c4b74ecee1df6":"Zadajte celé kladné číslo alebo nechajte pole prázdne.",
"8da247d5d736c87122404eff1e5c0c73":"Zadajte platný limit.",
"65e154267ac283d963cbc7be832969d9":"Zadajte platnú hodnotu",
"28b4450d417065a69a395f567967d46c":"Prednastavené s požadovanými obmedzeniami výdavkov",
"7bbf151d8493170f206a23c2ecc6acd0":"Politika ochrany osobných údajov",
"51602962f2d1e7515943b4a6c66562ce":"Profilový obrázok",
"a51a17940c70a3123da4d68bed562c40":"Profil bol uložený!",
"4d398249d01ab72d697a7e219493c637":"Poskytovateľ",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Priradenie poskytovateľa",
"89b1deb2ffe12a13941c362abaeab01b":"Publikované",
"d8d806d48052159e3ff3b224f3f53586":"Účel",
"089917619c78af2efb30be0bb7ce076c":"Znovu aktivovať tím",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Dôvod",
"e8484c78fda9f453eeb942ba4945f040":"Znova priradiť",
"ff2fbc1172ba26776ad0982624cd2713":"Potvrdenie",
"588e690db177ee0c7a7cadb39cbd5efc":"Obnovte si finančné prostriedky každý týždeň, mesiac, rok",
"32955937daca05b2084556f248692baa":"Registrovať",
"f15bb755ba24abe6ab106637292175ec":"Zostávajúce",
"579782461133a8b8cc6bd77798961d82":"Odstrániť kartu",
"e475edc165372f8c8deb532aa85d9950":"Odstrániť zoznam",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Odstrániť zoznam",
"f9769561315b964d0b3b4887ec208977":"Odstrániť z tímu",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Odstrániť potvrdenie",
"ee181cef581fc8e8217e66b5762db898":"Odstránením zoznamu dôjde k odstráneniu jeho používania a odstráneniu všetkých existujúcich položiek. Ak chcete zoznam dočasne odstrániť, ale zachovať jeho položky, môžete vypnúť nastavenie publikovania. Naozaj chcete odstrániť tento zoznam?",
"feea41d0bc1d577a07c8b6e1297abbde":"Odstránením zdrojovej karty z tímu nedôjde k zakázaniu existujúcich virtuálnych kariet.",
"d09483824a2e0d6194a4c3f32311e752":"Odstránením zdrojovej karty z tímu nedôjde k zakázaniu existujúcich virtuálnych kariet. Naozaj chcete odstrániť túto karta z tohto tímu?",
"7ca4b1bf1346e179a29da97f260a93a4":"Premenovať",
"db195ec9c818b8e92da142374e20d1bc":"Premenovať zoznam kódovania",
"2fd93e229fe9ea754808805d3fef9c13":"Premenovať zoznam kódovania",
"aa1e74d32805823f802a11b33c60c38b":"Typ zostavy",
"6ac78d97a8cb7f060e43e596e3e23535":"Požiadavka bola zrušená.",
"25deccb1980291a0fa9997109c731440":"Vyžiadať demoverziu",
"9f2754d87686903b1500ce8e8a55b377":"Vyžiadať demoverziu",
"92ac924f72679f897e26f3e615be8c22":"Požiadavka bola preposlaná.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Vyžiadať informácie",
"270185007853cd779011ae0e8433f723":"Požiadavka bola opätovne priradená.",
"e5a059c9ba2691910321339cce989f9d":"Požiadavka odoslaná.",
"77c88a231edfa7075114d91d2397e42d":"Názov požiadavky",
"cee91b833c4358acf0c554f746557e71":"Požiadavka bola odmietnutá.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Požiadavka bola odmietnutá.",
"9e56abbbf87420eeedd625ce786515c7":"Požadované",
"b4cb4426e44fee9580af71912a764536":"Požiadavky",
"df309e888c29fc447d0309329b39ce93":"Obnoviť heslo",
"5daf278da19ecbaeb7b96554cb4db10f":"Znovu sa pokúste pripojiť",
"31e9501526d2114e4a01fdbf076de097":"Rola",
"6fbceb787429f6f81117a1d5a58b4610":"Rola",
"473b814ca86841ef672ccf9f7dac28d5":"Rola",
"f1e16d5fd643e039d865b6aec7c986f9":"Rola",
"ec78345a5975bc0f8efaee5256b43c2c":"Uložiť",
"2cecea57b47d8f4a45c706287ff7d64e":"Uložiť zmeny",
"15774be5c338a39169917c7d32781dd0":"Uložiť a pokračovať",
"844095b4b8f580737b8717672501048e":"Povedzte nám niečo…",
"7e66f676c2e7b04514aca3a0935530f0":"Vyhľadávať podľa informácií o kódovaní",
"5293ce9335ec2d176a6e838bf76404c6":"Vyhľadať podľa popisu",
"efab3b0c95f263cdd0a8466329a387eb":"Vyhľadať podľa popisu",
"d227b38dcbc4548c092b0836a7101506":"Vyhľadávať podľa mena obchodníka alebo ID transakcie",
"3629383abf1d5f85efd759f718159b40":"Vyhľadávať podľa názvu",
"a034fcd6719228b28a6fc22d3a46ea8a":"Vyhľadávať podľa názvu tímu",
"8ac7e0d63f343b548482d5f367f5b3d9":"Vyhľadávať podľa názvu tímu",
"858323eb52cb45446597ee46330a0540":"Vyhľadávať podľa vlastníka virtuálnej karty",
"111bfcbff671e13de117ed5195fb6773":"Vybrať",
"bcfecd59eb93e5314873d84ddf35aa58":"Vybrať",
"909a4414ea573218bff2d533eaab46ea":"Vyberte dátum ukončenia",
"114b206ca1ff1c0bc52abef342059b2a":"Vyberte možnosti tímu",
"81a02e424615fb41a20b8d0f13441c88":"Vybrať tím",
"b135b36495618e6692e7fbaf0a0aa83e":"Vyberte mesiac",
"677e90855e164c00a28b5c4763ac67e0":"Vybrať rolu",
"d01570f3774738395b8ba3c64ce54c76":"Vyberte stav",
"7dee3f06c70626881efb0cd6af6b63e1":"Vyberte používateľa, ktorému sa bude požiadavka preposielať",
"4c7bb810835a1540ddc1f6501da5a51b":"Vyberte rok",
"3ab198d2678af2b5b32180e4b39602ba":"Vybrať schvaľovateľa",
"f502ba79f2bd010c5e77891812400958":"Vyberte kartu",
"936027182d446e27ba209ea921ef7502":"Vyberte predvolený rozsah dátumov uplynutia platnosti",
"b22c893254ccf3bd34faaee511cf7ca8":"Zvoliť dĺžku trvania",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Vyberte si z predvoleného zoznamu kódovania alebo vytvorte nový zoznam.",
"42cce5fa376b45bf290f86c684d69d96":"Vybrať pole poskytovateľa",
"b761773d46699b60e8d6874b85593374":"Vybrať tím",
"3a9396756942ede98d0cb6afc1032b15":"Vyberte ich schvaľovateľa",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Ja",
"03e9d2c128af288126d94f631c5396be":"Odoslať pokyny",
"299a7e961804e2d649f06b5dc4362c29":"Odoslať pozvanie",
"155e7b3f7db7bf10b03b5f41de54eae1":"Odoslať správu",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Nastaviť dátum uplynutia platnosti",
"f89ec96a4b5bb639488ab742efa76a6a":"Nastaviť nové heslo",
"7d5377ad70d57728a45d3c0639de5544":"Nastavenia uložené",
"14313f11dee735f2144953e720776c28":"Suma úhrady sa môže líšiť, ak sa na nákup vzťahujú poplatky za obsluhu alebo iné poplatky.",
"199000fe0cda0adaaa9b864aa7447cb9":"Úhrady",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Úhrady",
"6e6ac6ff3bd722e37874fb1ea7115818":"Nastavte si heslo",
"9b4ecf052980e80bf5c65a415d470a43":"Zdieľajte a ovládajte prístup k svojej firemnej karte",
"ba746d78772d6be26cc508ba989becb0":"Mal by byť menší alebo sa rovnať {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Zobraziť transakcie bez potvrdeniek",
"7d474b60aeb7cc25b519e806fca71e12":"Prihláste sa pomocou svojich existujúcich \n\n prihlasovacích údajov do Tradeshift",
"16080cd4e8319ef3e7de21b6c19d1437":"Niečo nie je v poriadku",
"8f358a370224baf849f9652810c68db2":"Ľutujeme, vyskytol sa problém.",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Ľutujeme, vyskytol sa problém.",
"99af87c475a4d40ef732deed96f11ed2":"Vyskytol sa problém. Skúste to znova.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Ľutujeme! Momentálne máme problém s pripojením – skúste to znova o {retryConnectionPeriod} sekúnd.",
"84debc8e41646cd49ac2b14708ca6d73":"Ľutujeme, pri odstraňovaní položky kódovania sa vyskytol problém. Skúste to znova.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Ľutujeme, pri ukladaní položky kódovania sa vyskytol problém. Skúste to znova.",
"689d3418ee8f996ad25e152e80de63fe":"Ľutujeme, ale momentálne sa nám nepodarili vás odhlásiť.",
"b48efd4521ecd6428987d24636a4349f":"Zoradiť",
"776a95003d3ec1dd58af69aedbc08579":"Zoradiť",
"1b783238028c0ae085ca69ed823f4d1a":"Zoradiť podľa",
"be70d19bf13c935d653b2c4fe7d36224":"Názov zdrojovej karty",
"564f8c942e7d1bdda5251545612fa348":"Názov zdrojovej karty",
"aa284602c746aad4a6de6366c24221b8":"Zdrojové karty",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Zdrojové karty",
"389d471e79a43440b3278b0fc7187d9e":"Zdrojové karty",
"106342f689b57145817b00d6fe383f91":"Konkrétny dátum ukončenia",
"2a5e19387ff300509876e3bae99b7ab9":"Zrýchlite svoje účtovníctvo",
"ca34d586bbdb7f368f1009305bccbe76":"Tabuľa výdavkov",
"4583120998b44c0a5982cf85dd938d2f":"Výdavky v priebehu času",
"244d724806247953233a0bbfcbf8da4c":"Limit výdavkov",
"d39b83dd151c8df6370460a458294ccf":"Minuté",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Minutá suma",
"5de2859528e030ac1f2af115ebdf209c":"Minuté { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Spustiť presmerovanie na",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Stav",
"b089930f1f6ffac09438cc19590f8f6b":"Stav",
"f174d20592156eb4b10a7cf1144c973e":"Stav",
"ecf342482d1b6ab932c2000cd7dc38c4":"Stav",
"1f1fe111548158ec842761eca7425ff3":"Adresa",
"31a0a748b1097a9e0aa95a811802bdd0":"Odsúhlasenie položiek bez stresu",
"111b782f3f0794c2294aefb6693b2c50":"Odoslať",
"013e2555920b51ad2a3d2c3dc482c471":"Odoslať požiadavku",
"1270cd8eb69ad64cc71e5de979423b3b":"Predplatné končí",
"2802336ad3d8ff658257b88a93f4e50a":"Predplatné končí",
"b28dd68454d7acbefafeefb42981fe16":"Frekvencia predplatného",
"907793bc80cf94d560e5f1a0250fdaee":"Frekvencia predplatného",
"29229e153308e4747edef1144de35a55":"Úspešné!",
"503eb46b80f374f39266e7a9aea8e63e":"Úspešné!",
"4f20e29cadadb9fbc7455bd4d80de567":"Úspešné!",
"601d04f10d558f436c78c10e90b44175":"Podpora",
"62558c185ea2c06c5472d1cd534f6e56":"Synchronizuje sa s vaším účtovníctvom",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Čiastka dane",
"0cb5d74f7a56fca30930616feb6e2f47":"Tím",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Tím",
"bf23b2fd889e3b12e0f0acf99f913eca":"Karty tímu",
"b90a650376782a9e43ed6be6135bd6f2":"Tímová tabuľa",
"6adbec395fe1c4bf776f9fe5fa021052":"Tímová tabuľa",
"6f2695eeb68501dbcfddbb4d265b846b":"Manažéri tímu",
"3b779ade9c960ac95a670db0cc12e56b":"Členovia tímu",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Nastavenia tímu",
"dc5235e1b88320d3ba7501e4c30985a3":"Nastavenia tímu",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Nastavenia tímu",
"a2eb92bfd7502f47b3cd170bc68ac638":"Nastavenia tímu",
"f1ec84f051f19f0d0e301a709b13dc45":"Výdavky tímu",
"fbacde3a262ea8696e30135e8f987a44":"Prepínač tímov",
"74b9578ea5a620f5fef102188e42c02f":"Prepínač tímov",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Činnosti tímu",
"3aa978fa7ef886621285b93257d428c3":"Limit na schválenie pre tím",
"a41f9df131c221a99926de7e2138653a":"Informácie o tíme",
"941c7da8f256f91ba3819f991315a100":"Názov tímu",
"c5d04e10cc2f0b53c2bda893eae09178":"Názov tímu",
"504a8ba642ba37c9a743d0fabb2bd371":"Názov tímu",
"e768a8e1e3d6a6bf470587dad75423fe":"Vlastník tímu",
"e2d824067f8bd871756688c58c382196":"Vlastníci tímu",
"dca232ccc849c5ff5611255eb82baf53":"Vlastníci tímu",
"3b61bd586225790cf132591383d15448":"Prepínač tímov",
"bc05b40509f0271c12cfaaef8e5319b1":"Tímy",
"39e73773d07a800673ec6600422952d6":"Tímy",
"daabb564bb3dcfb41de69492f854d573":"Tímy",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Limity tímov a jednotlivcov nemôžu prekročiť limit spoločnosti",
"4b4fe189a48d8eb06d836afa8fb6317a":"Tímy boli aktualizované.",
"2a17f9a8c610db29fec63fd530c29fdc":"Tímy používajúce kartu",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Tímy, ktoré to môžu vidieť",
"317868f3804ba4ed5d99dd9919c798ba":"Podmienky poskytovania služby",
"e8c9a9847db2ccd97524aa06f1af9d82":"Podmienky poskytovania služby a zásady ochrany osobných údajov",
"38852078519c5c46715d8a034fc4901b":"Podmienky poskytovania služby a zásady ochrany osobných údajov.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Ďakujeme za váš záujem o Tradeshift Go. Čoskoro ponúkneme podporu pre kreditné karty {cardLabel} . Akonáhle bude dostupná, člen nášho tímu úspechu zákazníka vám dá ihneď vedieť.",
"f7047b35a069d0790a0a348a29c846a2":"Ďakujeme, že ste sa pripojili k tímu v spoločnosti {companyName} , aby ste nakupovali pomocou virtuálnych kariet Go.",
"133b0a70b1a956889164621380c378f9":"Akcia, ktorú sa pokúšate vykonať, sa nedá dokončiť. V niektorých prípadoch pomôže skúsiť to znova.",
"18f5a1872e156f1aee84ae321780d952":"Požadovaná čiastka presiahla limit požiadavky spoločnosti o ${requestLimit}. Skúste to znova s nižšou čiastkou požiadavky.",
"867e3d4d3c78990c6852fc5348706ab2":"Požadovaná čiastka presiahla limit požiadavky poskytovateľa o ${providerLimit}. Skúste to znova s nižšou čiastkou požiadavky.",
"570f5f4658b03dda7a910f56c86a3bd3":"Karta pre túto požiadavku bola zrušená.",
"3075c56105863546e3368ffead7a146b":"Táto karta bola zrušená.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Zoznam kódovaní, ktorého publikovanie sa pokúšate zrušiť, je prepojený s integráciou. Ak ste si istí, že chcete tento zoznam odobrať z integrácie, kontaktujte podporu, ktorá môže túto akciu dokončiť.",
"756ade8d71ec487a4b48d7c4277ea239":"Zoznam kódovaní, ktorého publikovanie sa pokúšate zrušiť, je prepojený s integráciou. Pred zrušením publikovania tohto zoznamu musíte najprv odstrániť priradenie.",
"4f40335f7c018986c95458183bc0c136":"Odkaz na aktiváciu vášho účtu je neplatný alebo vypršala jeho platnosť. Poraďte sa s osobou, ktorá vás pozvala, aby vám poslala ďalšie pozvanie.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Odkaz na obnovu vášho hesla je neplatný alebo vypršala jeho platnosť. Ak chcete získať nový odkaz, znova požiadajte o obnovenie hesla.",
"ded30f0e288c12125ca1a77f8d8fd600":"Dôvod bude viditeľný pre schvaľovateľa a žiadateľa",
"f294897b38fc23dd8f5de52b54ceae22":"Požiadavka bude odmietnutá a žiadateľ o tom bude informovaný. Chcete pokračovať?",
"e8c7200dc985df0a94a9d996968b4cc0":"Požiadavka bude odmietnutá a žiadateľ o tom bude informovaný. Chcete pokračovať?",
"5f3d196a2ade3f97a2a48b773fec5861":"Požiadavka bude odmietnutá a žiadateľ o tom bude informovaný. Chcete pokračovať?",
"b04c191cd6b4f27669a4f390311f156c":"Nie sú vybraní žiadni vlastníci ani manažéri tímov.",
"e25045557efbad7b52997b94a1fe6817":"Nie sú tu žiadne {type} pre toto časové obdobie",
"7260690577c795e999acae63bb7c51ae":"Pri ukladaní nastavení tímu sa vyskytol problém. Skúste to znova.",
"f2103024f2a9c6c0335a71c60567d6c9":"Pri načítaní vašej peňaženky sa vyskytla chyba.",
"9776cb7eab9fa0d318a8144cd17de745":"Pri načítaní vašej peňaženky sa vyskytla chyba.",
"77d28a14bc900be4360b570a82eb75a9":"Nie sú tu žiadne údaje na zobrazenie",
"2b6feba67722337f88c1443909fe446f":"Pri pridávaní schvaľovateľ sa vyskytol problém.",
"24b7212afb97c5d23faafa2ca4559095":"Pri pridávaní kreditnej karty sa vyskytol problém. Skúste to znova.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Pri pridávaní zdrojovej karty k tímu sa vyskytol problém. Skúste to znova",
"5a8e1b922d1cee746ac6794599b10709":"Pri zrušení tejto karty sa vyskytol problém. Skúste to znova.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Pri zrušení tejto karty sa vyskytol problém. Skúste to znova.",
"22361c5d4df61ad7ae9792a3baf5729f":"Počas vytvárania vášho zoznamu sa vyskytol problém. Skúste to znova",
"098755bfddeaf0428c7feb6b69af9b6f":"Pri odmietnutí požiadavky sa vyskytol problém. Skúste to znova.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Pri odmietnutí požiadavky sa vyskytol problém. Skúste to znova.",
"7d461b6b7b54dbfa9c83243217cd1053":"Pri odstraňovaní položky kódovania sa vyskytol problém. Skúste to znova.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Pri odstraňovaní vášho zoznam sa vyskytol problém. Skúste to znova.",
"beb43d7800cfff47e08cd61842d953bd":"Pri preposielaní požiadavky sa vyskytol problém. Skúste to znova.",
"f7775350f35b8365fb9803232d9b792c":"Pri získavaní údajov o karte sa vyskytol problém. Skúste to znova.",
"95abaa922c6c1648ca9c39ac4776896f":"Pri získavaní údajov o používateľovi sa vyskytol problém. Skúste to znova.",
"9d7e4ead60137041ae8a845aa6cf9979":"Pri načítaní profilu používateľa sa vyskytol problém. Skúste to znova.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Pri načítaní používateľov tohto tímu sa vyskytol problém. Skúste to znova.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Pri načítaní vášho nastavenie sa vyskytol problém. Skúste to znova.",
"b2f48028c400befad5f1d57f769ef7cd":"Pri opätovnom priradení požiadavky sa vyskytol problém. Skúste to znova.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Pri odstraňovaní zdrojovej karty z tímu sa vyskytol problém. Skúste to znova.",
"19dfcd761006496c1d20351c04643e45":"Pri odstraňovaní tejto domény sa vyskytol problém, skúste to znova",
"149651b5784481c68aaf4ac27bb1f907":"Počas ukladania tohto zoznamu kódovania sa vyskytol problém. Skúste to znova.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Pri ukladaní profilu sa vyskytol problém. Skúste to znova.",
"749ddc235744b6b371668ec0bb049176":"Pri ukladaní používateľa sa vyskytol problém. Skúste to znova.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Pri ukladaní tejto domény sa vyskytol problém, skúste to znova",
"04cf86909808f430b8b1904fb422e0d3":"Pri ukladaní vašich nastavení neprítomnosti v kancelárii sa vyskytol problém. Skúste to znova.",
"2eaf6ee97d984598c95e0494d72aa492":"Pri odosielaní požiadavky na nákup sa vyskytol problém.",
"148f847d3beccaa67fcd5096ae3602d3":"Pri aktualizácii názvu karty sa vyskytol problém. Skúste to znova.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Pri aktualizácii názvu karty sa vyskytol problém. Skúste to znova.",
"de8cc976f7af9edf189904239e6240da":"Pri aktualizácii tímov sa vyskytol problém. Skúste to znova.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Počas pridávania správcu sa vyskytla chyba, skúste to znova.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Pri deaktivovaní vášho tímu sa vyskytla chyba. Skúste to znova.",
"3f686a97a1b9fcdd73a40d2005693105":"Vyskytla sa chyba, skúste to znova.",
"2f38263789163cf8ee30c8ae88852c82":"Pri publikovanie vášho zoznamu sa vyskytla chyba.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Počas odstraňovania správcu sa vyskytla chyba, skúste to znova.",
"007a647254e1f85b6c1e789004e05df5":"Pri ukladaní nastavení sa vyskytla chyba",
"c3072d080b587d70f85d68ec089f028f":"Pri ukladaní nastavení vášho tímu sa vyskytla chyba. Skúste to znova.",
"608cd0e109c3e51b0425df8921e4181d":"Pri nastavovaní priradenia poskytovateľa sa vyskytla chyba. Skúste to znova",
"177cefc68dd318ea75673326e77d8206":"Pri rušení publikovania vášho zoznamu sa vyskytla chyba.",
"255d22dc4f0e338a4cf53d5b7256e695":"Pri načítaní mena osoby, ktorej preposielate požiadavky na schválenie, sa vyskytol problém.",
"39ac4ec73fed8a981ad1857d989edfcb":"Táto čiastku prekračuje limit na schválenie pre spoločnosť.",
"494ce3f598cbd97afe99774b5975a4bc":"Táto čiastku prekračuje limit na schválenie pre tím.",
"f2a42214e7c480c5596439becfd0b7bf":"Toto pole je povinné.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Toto nastavenie ovplyvní iba nové karty",
"881b7596cf985d9c246b05fcab930632":"Táto zdrojová karta bola pridaná k tímu.",
"a1b1aa354d06e7815b78c6abb7448e84":"Táto zdrojová karta bola odstránená z tímu.",
"b348b24fc4839ee15ed0aa424689bae3":"Tento tím musí mať správcu alebo manažéra, aby ste mohli pozvať člena.",
"188b9ed447a43916f9e323623ea3a993":"Ak chcete prijať pozvanie",
"969de95e19a06f5ae7cec6449cbb6a27":"Celkom",
"30286e57da108ee7dbc0f8fbb62d4fff":"Celkové výdavky ({currencySymbol})",
"c1a02bee74ad2ab87b0e0114dfea6905":"Sledujte výdavky v tímoch, projektoch a predplatných.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Sledujte aktivitu aktívnych kariet svojho tímu, pozastavte výdavky a deaktivujte karty, ktoré tím už nepotrebuje.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Obnovenie hesla k aplikácii Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Skúste to znova",
"80196bc616d4a44a4997a2ea127f7b83":"Skúste to znova",
"f96bbe81618b5f4a1807989eb4f69e24":"Skúste to znova",
"d498d7d261b7ca56e07218d1a0dbdd49":"Ľutujeme. Vyskytla sa chyba. Skúste to znova",
"ce2e475291003852c71b01959698c496":"Nepodarilo sa získať správy k požiadavke {title}. Skúste to znova",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Zrušiť archivovanie",
"d2e50be5287fd8fb436d0acbeb811864":"Jednoznačný identifikátor",
"0e2fbce967f4a209215b6f48f8eddac3":"Zružiť pozastavenie vytvorenia karty",
"f2315dec5dd07802b77af3a3a5414a7d":"V budúcnosti až 4 roky.",
"d2f30a2f0c955271e9af123dceca501a":"V budúcnosti až {label}",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Aktualizovať zdrojovú kartu",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Aktualizovať zdrojovú kartu",
"c8f6bc61a8982158e408194e48a274d9":"Nahrávanie zlyhalo. Skúste to znova.",
"8a86a60cb836bdb5225cad20c5151078":"Nahrať potvrdenie",
"e448a1ce42de0cf526200331b4ad8b23":"Používateľ",
"75edcc8fc27f41c99e28b48ff250c7f8":"Údaje o používateľovi",
"d1651c2afdb6c92621adbb707e4753d0":"Používateľ bol uložený.",
"5fa55883b7413526b441e999cb8089b3":"Názov ponuky",
"7a892b81fe2aae295f1b2d38bb67360f":"Názov ponuky",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Používatelia s aktívnymi kartami nemôžu byť odstránení. Ak chcete tohto používateľa odstrániť, zrušte karty.",
"d40fa5935f1614925cf46259c886814a":"Zobraziť aktivitu na karte",
"625419857efc7e72d162c9f8204109df":"Zobraziť kartu v peňaženke",
"85a5e817826415651ed4991a76bb5385":"Zobraziť konverzáciu",
"09fc124dc9b8b14d6227a92bb0a4566f":"Zobraziť konverzáciu",
"d26cfc6d8f420f259f434b79593e9eb4":"Zobraziť potvrdenie",
"c7d0394c3206a3b74afbeabc41cc639c":"Zobraziť potvrdenie",
"01650aa52c5e5a22d3f38afd2c7f9411":"Zobraziť potvrdenie",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Zobraziť potvrdenie",
"b2d141897f73fc2a198ee1a7a438f89b":"Zobraziť požiadavku",
"02fa2f56ef72845a9dfeabea22948061":"Zobraziť údaje o tíme",
"2d9c55650b17dd605a1388f46c81ba38":"Virtuálna karta",
"d0ec076e4600e854588f8da265208c0b":"Možnosti virtuálnej karty",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Peňaženka",
"5279a2220e4cfb0d8b8b357311251cbe":"Peňaženka",
"b1746b5806659e3189f837d2dd54e08c":"Peňaženka",
"ff306d5b34ee993f9618c1f3c0209a39":"Momentálne nemôžeme žiadosť archivovať. Skúste to znovu neskôr.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Momentálne nemôžeme zrušiť túto požiadavku. Skúste to znovu neskôr.",
"a813d44f6bb26c5da09671e532abe022":"Momentálne nemôžeme zrušiť archivovanie tejto požiadavky. Skúste to znovu neskôr.",
"d4c7310eafc0d0da89f20596a22a3abe":"Momentálne sa nám nepodarilo vytvoriť žiadosť. Skúste to znova!",
"dca679c10accb1db481a57a0e1ba08e2":"Momentálne sa nám nepodarilo vytvoriť žiadosť. Skúste to znova!",
"db5584a2ef29b04959bce7f93db54c56":"Momentálne nemôžeme vymeniť tím. Skúste to znovu neskôr.",
"f2af2c261fabddb01766f4f974b85a62":"Týždenne",
"e181ed0723a00ca7558b9d5d5258732b":"Vitajte v Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Zostaneme v kontakte!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Ktorú kreditnú kartu používa vaša spoločnosť?",
"968d94729053e166a76ea577ce3022e2":"Pracovná e-mailová adresa",
"588a075e8694ff5cc86815697e1586bc":"Pracujte rovnakým spôsobom ako vaše obchodné tímy.",
"182ae1eb5226e0646b4033883cc0900c":"Rok",
"fe88fe7094c4ced427874b3d22326c8d":"Áno",
"9a2d4e3b22ad0567165eb0f661f952ad":"Áno, zrušiť kartu",
"96b88e530375237cbc08138d9fb4109c":"Áno, odstrániť tento zoznam",
"fd3780f468c531f2bc1b47b8748b5a66":"Účtovné kódy môžete vytvárať v nastaveniach spoločnosti.",
"ab3ac6f945d9f756763d49257fd924dc":"Kódy môžete spravovať v nastaveniach spoločnosti.",
"c919dae93fe780061629c255eb358140":"Nemôžete odstrániť jedinú zdrojovú kartu prepojenú s týmto tímom. Pridajte ďalšiu kartu, aby ste túto mohli odstrániť.",
"11de97e873b66d91a77db7a05da32202":"Jedného z vlastníkov tímov, uvedených nižšie, môžete povýšiť na správcu spoločnosti alebo pozvať nového používateľa.",
"0ab790f08b2780be001b79d2ccd75c4e":"Pre tento účet nemáte vytvorené žiadne účtovné kódy.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Nemáte vytvorené žiadne zoznamy kódovania.",
"44e470218124a50c117870467530752e":"Zadali ste nesprávnu e-mailovú adresu alebo heslo.",
"4b687d0730822fdbeea27c174e47f227":"Nemáte žiadne schválené schválenia.",
"020f03c17b79af66f335b6af868c66c0":"Nemáte žiadne zrušené schválenia.",
"def0b76f03e7c622341a7d8e08aaaae7":"Nemáte žiadne odmietnuté schválenia.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Nemáte žiadne nevybavené schválenia.",
"7c034d6104f80a523b90bc607fc06608":"Vo vašej peňaženke nemáte žiadne virtuálne karty. Vytvoriť požiadavku",
"0aed9198d61d161468d541dbbc9f477a":"Preposielanie oznámenia o neprítomnosti v kancelárii na adresu {oooForwardToFullName} máte naplánované do  {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Dostali ste novú virtuálnu kartu:",
"04eeaf814767c30aa41577f3b11f916a":"Nedávno ste požiadali o obnovenie hesla svojho účtu Go. Postup na dokončenie tohto procesu:",
"09ddf249810b9af7d3e1f4e537da500d":"Pokúsili ste sa o prístup na stránku, ktorá neexistuje. Ubezpečte sa, že máte správny odkaz.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Ste pozvaní, aby ste sa pripojili k svojmu tímu na",
"02c6f3a7cf31a87f4f39f992509f8c44":"Boli ste pridaní ako správca pre vašu spoločnosť",
"cf121a14590f109dafcae138265e353b":"Boli ste pridaní do nového tímu!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Boli ste pridaní do tímu",
"d3cef66a026f776903b342d450e5a832":"Vaša žiadosť o kartu Go bola zamietnutá",
"003f87c21a29220e661fc302b885fd5a":"Váš účet bol zablokovaný. Obráťte sa na správcu svojej spoločnosti alebo tímu.",
"a52b1f0da15dce68007f524e48238143":"Vaša banka vrátila chybu pri pridávaní tejto karty. Ak chcete problém vyriešiť, kontaktujte banku a skúste to znova.",
"4d883b173167297463aeac86957c26fe":"Vaša e-mailová adresa bola použitá na vytvorenie účtu v Tradeshift Go. Overte svoju e-mailovú adresu a dokončite svoju registráciu",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Vaše nastavenia neprítomnosti v kancelári boli uložené.",
"542f08554110a12de0c23afd78f38e0e":"Vaše heslo bolo obnovené. Prihláste sa, prosím.",
"49b2dcbe5d461f35c37986962b5bd07a":"Vaša požiadavka bola odmietnutá",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Ste na zozname!",
"32f87c5ddeaee35b9178785ef4eb9313":"PSČ",
"c6974a3d5311374c697625f2a3234bce":"dátum",
"f86980f5ef00b645a6e4a0b59738a954":"osoba, ktorú sa nám nepodarilo vyhľadať",
"7066f854917b39bafe0bbecbb8bd6377":"schvaľuje požiadavky",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"pre",
"49b171230ab9bcdd8b631c70cf397efb":"pre <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"pre vašu spoločnosť",
"35ad86f0b035d8156390670d60801e7f":"pre {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"pre {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"od č.1",
"c1407aa5424f339dedf67bcac597cf71":"nastavil(a) svoje požiadavky na schválenie, ktoré sa vám majú preposielať v priebehu",
"67a948b8af3284a401ebefcb1b4723ab":"čaká na vaše schválenie",
"9ca64449d64b98fef7859e22bc23c4dc":"vytvára požiadavky",
"ac850ab84428e9eb6a57e5d813de5e45":"riadi tím",
"2168839a1f4c73ef5c89c9e930148e7d":"ignorujte, prosím, tento e-mail. Nevyžaduje sa žiadna akcia",
"85431c325faab98e02daa60151465144":"vám odoslal túto pozvánku",
"81c504fd54119e7e580eb05dedef46d0":"vám odoslal túto pozvánku",
"d74545060b38445ef4cc49fbb8d79876":"vám odoslal túto pozvánku.",
"2d96c53ee7ee0a54aadad19bfce0697d":"pri získavaní zdrojov karty sa vyskytla chyba",
"b2fda295788d579fffd3f43b7737fd63":"zaregistrovať svoju firemnú kartu v službe Go a poskytnúť svojim zamestnancom jednoduchý & bezpečný prístup na kartu",
"d35bd5c5d74af765d45cef3c4e78f52a":"za účelom nakupovania pomocou virtuálnych kariet Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"za účelom nakupovania pomocou virtuálnych kariet Go",
"9546989e681a7e25fbdfe7631e9ebc03":"za účelom nakupovania pomocou virtuálnych kariet Go.",
"6174e0a3d26409dd6e410052e2805660":"začať používať karty Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"vypnúť mimo pracoviska",
"fd2c1099fc9f3831d7f24195aaa11269":"vy@spoločnosť.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} zodpovedá za schvaľovanie žiadostí ostatných členov tímu. Ak chcete pokračovať, priraďte nového schvaľovateľa pre dotknutých členov tímu.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} bol úspešne pozvaný${this.teamId ? '.' : ' do tímu.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} bol odstránený",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} zrušil požiadavku na",
"9567a2d4cacd58fb4f096132d26c1660":"{username} zodpovedá za schvaľovanie žiadostí ostatných členov tímu. Ak chcete pokračovať, priraďte nového schvaľovateľa pre dotknutých členov tímu.",
};
