function closeSidebar() {
	return {
		restrict: 'A',
		link: function ($scope, $element) {
			$element.on('click', function () {
				// mobile menu doesn't close when item is clicked, so we force it here
				if (ts.ui.isMobilePoint()) {
					ts.ui.get('#js-sidebar-new', newSidebar => {
						if (newSidebar) {
							newSidebar.close();
						}
					});
				}
			});
		},
	};
}

module.exports = closeSidebar;
