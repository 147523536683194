"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rolesInitialState = void 0;
exports.rolesSlice = rolesSlice;
exports.rolesInitialState = {
    role: {
        roles: {},
    },
};
function rolesSlice(set) {
    return {
        ...exports.rolesInitialState,
        setRoles({ roles, cardprovider }) {
            set((state) => {
                if (cardprovider === 'HSBC' && roles.manager) {
                    const { manager, ...rest } = roles;
                    state.role.roles = rest;
                }
                else {
                    state.role.roles = roles;
                }
                return state;
            }, false, '[setRoles]');
        },
    };
}
