"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetEventsQuery = exports.getEventsQuery = exports.useAddMessageMutation = exports.eventsKeys = void 0;
const react_query_1 = require("@tanstack/react-query");
const conversationRepository_1 = require("../repositories/conversationRepository");
const setTime_1 = require("../utils/setTime");
exports.eventsKeys = {
    getEvents: (options) => ['events', options.conversationId],
    getStream: (options) => ['stream', options.after],
};
const useAddMessageMutation = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: (options) => conversationRepository_1.ConversationRepository.addMessage(options),
    });
};
exports.useAddMessageMutation = useAddMessageMutation;
const getEventsQuery = (options) => ({
    queryKey: exports.eventsKeys.getEvents(options),
    queryFn: () => conversationRepository_1.ConversationRepository.getEvents(options),
    enabled: Boolean(options.conversationId),
    staleTime: (0, setTime_1.setTime)(1, 'hours'),
});
exports.getEventsQuery = getEventsQuery;
const useGetEventsQuery = (options) => {
    return (0, react_query_1.useQuery)({ ...(0, exports.getEventsQuery)(options), refetchInterval: options.polling ? 5000 : false });
};
exports.useGetEventsQuery = useGetEventsQuery;
