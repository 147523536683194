"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.activityInitialState = void 0;
exports.activitySlice = activitySlice;
exports.activityInitialState = {
    activity: {
        filters: {
            team: 'all',
            member: 'all',
        },
        options: {
            teamOptions: [{ id: 'all', label: 'All Teams' }],
            memberOptions: [{ id: 'all', label: 'All Members' }],
        },
        list: {
            pending: { approvals: [], count: 0 },
            approved: { approvals: [], count: 0 },
            declined: { approvals: [], count: 0 },
            canceled: { approvals: [], count: 0 },
        },
        filteredList: {
            pending: { approvals: [], count: 0 },
            approved: { approvals: [], count: 0 },
            declined: { approvals: [], count: 0 },
            canceled: { approvals: [], count: 0 },
        },
        viewAllApprovals: false,
    },
};
function activitySlice(set) {
    return {
        ...exports.activityInitialState,
        setActivityFilters(filters) {
            set((state) => {
                state.activity.filters = filters;
                return state;
            }, false, '[setActivityFilters]');
        },
        setActivityOptions(options) {
            set((state) => {
                state.activity.options = options;
                return state;
            }, false, '[setActivityOptions]');
        },
        setUiActivities(list) {
            set((state) => {
                state.activity.list = list;
                return state;
            }, false, '[setUiActivities]');
        },
        setFilteredList(filteredList) {
            set((state) => {
                state.activity.filteredList = filteredList;
                return state;
            }, false, '[setFilteredList]');
        },
        setViewAllApprovals(viewAllApprovals) {
            set((state) => {
                state.activity.viewAllApprovals = viewAllApprovals;
                return state;
            }, false, '[setViewAllApprovals]');
        },
    };
}
