"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const zustandStore_1 = require("../../../zustandStore");
const StyledPaper = (0, material_1.styled)(material_1.Paper)(({ theme }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.tsGoColor.pink,
    color: theme.palette.tsGoColor.white,
}));
const StyledErrorBox = (0, material_1.styled)(material_1.Box)(() => ({
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    top: '50%',
    position: 'absolute',
}));
const ErrorPage = () => {
    const { setHeaderName } = (0, zustandStore_1.useZustand)();
    const { heading, message } = (0, zustandStore_1.useZustand)(state => state.error);
    (0, react_1.useEffect)(() => {
        setHeaderName('Tradeshift Go');
    }, [setHeaderName]);
    return ((0, jsx_runtime_1.jsx)(StyledPaper, { children: (0, jsx_runtime_1.jsxs)(StyledErrorBox, { children: [(0, jsx_runtime_1.jsx)("h1", { children: heading }), (0, jsx_runtime_1.jsx)("p", { children: message })] }) }));
};
exports.default = ErrorPage;
