"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styledMessage_1 = require("../../../common/styledMessage");
const TermsAndConditionsFormFooter = () => {
    return ((0, jsx_runtime_1.jsxs)(styledMessage_1.StyledMessage, { "data-testid": "terms-and-conditions-form-footer", sx: {
            padding: '0 10px 20px',
            fontSize: '14px',
        }, children: ["We\u2019ll use any information you provide about the organisation or individuals connected to it in accordance with Our Privacy Notice.", (0, jsx_runtime_1.jsx)("br", {}), "This can be found at", ' ', (0, jsx_runtime_1.jsx)(material_1.Link, { underline: "none", href: "https://www.business.hsbc.uk/en-gb/regulations/legal-information", target: "_blank", rel: "noreferrer", children: "Legal Information | Business Banking | HSBC UK" })] }));
};
exports.default = TermsAndConditionsFormFooter;
