const _ = require('lodash');

/* @ngInject */
function AccountSetupTasksController($scope, $state, AccountSetupService, AsideService, ConfigService) {
	function completeSetupTask(key) {
		const task = _.find($scope.tasks, { key });
		AccountSetupService.completeTask(task.taskId)
			.then(function () {
				task.isComplete = true;
			})
			.catch(function () {
				ts.ui.Notification.warning('Oh snap! There was a problem completing the task. Please try again.');
			});
	}

	const handlers = {
		cards: function () {
			AsideService.open('addCardSource', {
				model: {},
				onSuccess: function () {
					completeSetupTask(ConfigService.get('SETUP_TASK_DEFINITIONS').cards.key);
				},
			});
		},
		accountCodes: function () {
			AsideService.open('codingLists', {
				model: {},
				onSuccess: function () {
					completeSetupTask(ConfigService.get('SETUP_TASK_DEFINITIONS').accountCodes.key);
				},
			});
		},
		employees: function () {
			AsideService.open('inviteUser', {
				model: {},
				onSuccess: function () {
					completeSetupTask(ConfigService.get('SETUP_TASK_DEFINITIONS').employees.key);
				},
			});
		},
	};

	function init() {
		AccountSetupService.getSetupTasks().then(function (tasks) {
			$scope.tasks = _.map(tasks, function (task) {
				return _.assign({}, task, {
					onClick: handlers[task.key],
				});
			});
		});
	}

	// TODO: move to NameService
	$scope.formatFirstName = function (event) {
		const firstName = _.get(event, 'data.firstName');
		return firstName ? ' ' + firstName : '';
	};

	init();
}

module.exports = AccountSetupTasksController;
