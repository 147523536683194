module.exports = {
	costcentercode: {
		id: 'costcentercode',
		code: '103',
		target: 'VirtualCreditCardRequest',
		name: 'Cost center code',
		description: 'Cost center code',
		group: 'costcentercode',
		ordering: '10',
		defaultEnabled: false,
	},
	departmentcode: {
		id: 'departmentcode',
		code: '102',
		target: 'VirtualCreditCardRequest',
		name: 'Department code',
		description: 'Department code',
		group: 'departmentcode',
		ordering: '20',
		defaultEnabled: false,
	},
	expendituretypecode: {
		id: 'expendituretypecode',
		code: '122',
		target: 'VirtualCreditCardRequest',
		name: 'Expenditure type',
		description: 'Expenditure type',
		group: 'expendituretypecode',
		ordering: '30',
		defaultEnabled: false,
	},
	glcode: {
		id: 'glcode',
		code: '105',
		target: 'VirtualCreditCardRequest',
		name: 'General ledger code',
		description: 'General ledger code',
		group: 'glcode',
		ordering: '40',
		defaultEnabled: false,
	},
};
