const AddPeopleModalView = {
	bindings: {
		isCurrentStep: '<',
		isModalOpen: '<',
		limit: '<',
		onChangeButtons: '&',
		onNextStep: '&',
		onPreviousStep: '&',
		teamId: '<',
	},
	template: `
		<div ng-if="$ctrl.noteVisible" data-ts="Note" class="purple-note">
			<i class="ts-icon-info"></i>
			<p ng-bind="$ctrl.warningMessage"></p>
		</div>
		<people-table
				class="add-people"
				limit="$ctrl.limit"
				users="$ctrl.users"
				on-invite-user-aside-closed="$ctrl.onInviteUserAsideClosed()"
				open-invite-user-aside="$ctrl.asides.inviteUser.isOpen"
				team-id="$ctrl.teamId"
				user-invited="$ctrl.userInvited()"
				user-removed="$ctrl.userRemoved($event)"
				render-button="$ctrl.updateButtons"></people-table>
	<div
			class="empty-state"
			ng-if="$ctrl.nullState === true">
		<div class="header-box">
			<span
					class="title tst-add-people-header"
					ng-bind="$ctrl.addPeopleHeaderText"></span>
			<span ng-bind="$ctrl.addPeopleContentText"></span>
		</div>
		<div class="roles-container">
			<div class="roles-info-container">
				<p
						class="title"
						ng-bind="$ctrl.teamOwnerHeaderText"></p>
				<span ng-bind="$ctrl.teamOwnersContentText">
				</span>
			</div>
		<div class="roles-info-container">
				<p
						class="title"
						ng-bind="$ctrl.managerHeaderText"></p>
				<span ng-bind="$ctrl.managerContentText">
				</span>
			</div>
			<div class="roles-info-container">
				<p
						class="title"
						ng-bind="$ctrl.memberHeaderText"></p>
				<span ng-bind="$ctrl.memberContentText">
				</span>
			</div>
		</div>
		<div class="button-container">
			<button
					class="ts-primary tst-invite-people"
					data-ts="Button"
					ng-click="$ctrl.openInviteUser()">
				<span ng-bind="$ctrl.invitePeopleText"></span>
			</button>
		</div>
	</div>`,

	controller: /* @ngInject */ function ($timeout, EventEmitter, LexicoService, constants) {
		this.lexico = LexicoService.getLexico();
		this.addPeopleHeaderText = this.lexico.trc('Info box header', 'Add some people to this team');
		this.addPeopleContentText = this.lexico.trc('Info box content', 'People in a team can have 3 different roles');
		this.teamOwnerHeaderText = this.lexico.trc('Info box header', 'Team owners');
		this.teamOwnersContentText = this.lexico.trc(
			'Info box content',
			'Can view all requests and spend activity in the team. Team owners also get a team dashboard to track spend and team wallet where they can see all active cards.',
		);
		this.managerHeaderText = this.lexico.trc('Info box header', 'Managers');
		this.managerContentText = this.lexico.trc(
			'Info box content',
			'Can approve requests and create cards for members they manage. Managers have a dashboard to track spend for members they manage.',
		);
		this.memberHeaderText = this.lexico.trc('Info box header', 'Members');
		this.memberContentText = this.lexico.trc(
			'Info box content',
			'Can Request cards and have a wallet for all their cards. Each member gets a default approver (manager) for their requests.',
		);
		this.invitePeopleText = this.lexico.trc('Button', 'Invite People');
		this.warningMessage = this.lexico.trc(
			'Warning message',
			'Your team has reached the maximum number of members (180). Please create a new team to invite additional members.',
		);
		this.usersLimit = constants.teams.userLimit;
		this.noteVisible = false;

		this.openInviteUser = () => {
			this.asides.inviteUser.isOpen = true;
		};

		this.onInviteUserAsideClosed = () => {
			this.asides.inviteUser.isOpen = false;
		};

		const onStepSelected = async () => {
			this.updateButtons({ isNullState: this.nullState });
		};

		const onModalOpen = () => {
			this.users = [];
			this.nullState = true;

			this.asides = {
				inviteUser: {
					isOpen: false,
				},
			};
		};

		this.$onChanges = changes => {
			if (changes.isCurrentStep && changes.isCurrentStep.currentValue) {
				onStepSelected();
			}
			if (changes.isModalOpen && changes.isModalOpen.currentValue) {
				onModalOpen();
			}
		};

		this.userInvited = () => {
			if (this.nullState) {
				this.switchToTableView();
			}
		};

		this.userRemoved = ({ isEmpty }) => {
			if (isEmpty) {
				this.nullState = true;
			} else {
				this.updateButtons({ isNullState: false, value: 'remove' });
			}
		};

		this.switchToTableView = () => {
			this.nullState = false;
			this.updateButtons({ isNullState: this.nullState });
		};

		this.updateButtons = ({ isNullState, value }) => {
			const length = value === 'remove' ? this.users.length - 1 : this.users.length + 1;
			if (length >= this.usersLimit) {
				this.noteVisible = true;
			} else {
				this.noteVisible = false;
			}
			const buttons = [
				{
					label: this.lexico.trc('Button', 'Save and continue'),
					type: 'ts-primary tst-save-and-continue',
					onclick: () => {
						this.onNextStep();
					},
				},
			];
			if (!isNullState) {
				buttons.push({
					label: this.lexico.trc('Button', 'Invite more people'),
					type: 'ts-secondary',
					onclick: () => {
						$timeout(() => {
							this.openInviteUser();
						});
					},
					disabled: this.users && length >= this.usersLimit,
				});
			}
			buttons.push({
				label: this.lexico.trc('Button', 'Back'),
				type: 'ts-secondary',
				onclick: () => {
					this.onPreviousStep();
				},
			});
			this.onChangeButtons(new EventEmitter(buttons));
		};
	},
};

export default AddPeopleModalView;
