"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiDividerOverrides = void 0;
function getDottedDivider(ownerState) {
    const baseStyle = {
        backgroundColor: 'transparent',
        color: 'transparent',
    };
    if (ownerState === 'horizontal') {
        return {
            ...baseStyle,
            borderBottom: '1px dotted #cad7dc',
            margin: '20px 0 20px 0',
        };
    }
    else if (ownerState === 'vertical') {
        return {
            ...baseStyle,
            borderRight: '1px dotted #cad7dc',
            margin: '0',
        };
    }
    return baseStyle;
}
exports.muiDividerOverrides = {
    styleOverrides: {
        root: props => {
            const { ownerState } = props;
            return {
                ...(ownerState['ts-variant'] === 'dotted' ? getDottedDivider(ownerState) : {}),
            };
        },
    },
};
