import 'core-js/stable';

import * as Sentry from '@sentry/react';
import uiRouter from '@uirouter/angularjs';
const angular = require('angular');
import { ngAnimate } from 'angular-animate';
import angularCache from 'angular-cache';
import angularFileSaver from 'angular-file-saver';
import ngSanitize from 'angular-sanitize';
const _ = require('lodash');
import { ngPatternRestrict } from 'ng-pattern-restrict';
import ngClipboard from 'ngclipboard';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import CommonModule from './common';
import { reactMigration } from './constants/appConstants';
import DashboardModule from './dashboard';
import MainModule from './main';
import ErrorFallback from './router/ErrorFallback';
import { excludedPaths } from './router/GoApp';
const Root = require('./router/Root');
const zustandStore = require('./zustandStore');
const logger = require('pino')();

const GoApp = require('./router/GoApp').default;
const errorReporter = require('./config/errorReporter');

angular
	.module(
		'go',
		_.compact([
			angularCache,
			ngAnimate,
			ngClipboard,
			angularFileSaver,
			ngPatternRestrict,
			ngSanitize,
			uiRouter,
			require('./error/error.module'),
			require('./manage'),
			require('./support'),
			CommonModule,
			DashboardModule,
			MainModule,
		]),
	)
	.constant('constants', require('./config/constants').getValues())
	.controller('AccountSetupTasksController', require('./directives/events/accountSetup/AccountSetupTasksController'))
	.controller('AddCardSourceController', require('./asides/addCardSource/AddCardSourceController'))
	.controller('ApprovalController', require('./approval/ApprovalController'))
	.controller('ApprovalOptionsController', require('./asides/approvalOptions/ApprovalOptionsController'))
	.controller('CreateCardController', require('./asides/createCard/CreateCardController'))
	.controller('CardInfoController', require('./directives/cardInfo/CardInfoController'))
	.controller('CodingListsController', require('./asides/codingLists/CodingListsController'))
	.controller('ConfirmActionController', require('./asides/confirmAction/ConfirmActionController'))
	.controller('ConversationController', require('./directives/conversation/ConversationController'))
	.controller('DefaultManagerController', require('./asides/defaultManager/DefaultManagerController'))
	.controller('EditProfileController', require('./asides/editProfile/EditProfileController'))
	.controller('InviteUserController', require('./asides/inviteUser/InviteUserController'))
	.controller('PurchaseRequestController', require('./directives/events/purchaseRequest/PurchaseRequestController'))
	.controller(
		'PurchaseRequestFormControllerV1',
		require('./directives/events/purchaseRequest/V1/PurchaseRequestFormController'),
	)
	.controller(
		'PurchaseRequestFormControllerV2',
		require('./directives/events/purchaseRequest/V2/PurchaseRequestFormController'),
	)
	.controller('RequestCardController', require('./directives/requestCard/RequestCardController'))
	.controller('RequestChangedController', require('./directives/events/requestChanged/RequestChangedController'))
	.controller('RouteController', require('./config/RouteController'))
	.controller('SortOptionsController', require('./asides/sortOptions/SortOptionsController'))
	.controller('VirtualCardAddedController', require('./directives/events/virtualCardAdded/VirtualCardAddedController'))
	.controller('VirtualCardController', require('./directives/virtualCard/VirtualCardController'))
	.directive('approvalInfo', require('./directives/approvalInfo/approvalInfo'))
	.directive('aside', require('./directives/aside'))
	.directive('avatarFileModel', require('./directives/avatarFileModel/avatarFileModel'))
	.directive('cardIcon', require('./directives/cardIcon/cardIcon'))
	.directive('cardInfo', require('./directives/cardInfo/cardInfo'))
	.directive('closeSidebar', require('./directives/closeSidebar'))
	.directive('conversation', require('./directives/conversation/conversation'))
	.directive('eventAccountSetup', require('./directives/events/accountSetup/accountSetup'))
	.directive('eventGoTyping', require('./directives/events/goTyping/goTyping'))
	.directive('eventMessageAdded', require('./directives/events/messageAdded/messageAdded'))
	.directive('eventPersonAdded', require('./directives/events/personAdded/personAdded'))
	.directive('eventPurchasesApprovalTask', require('./directives/events/approvalTask/approvalTask'))
	.directive('eventPurchasesCardAdded', require('./directives/events/virtualCardAdded/virtualCardAdded'))
	.directive('eventPurchasesManagerNotified', require('./directives/events/managerNotified/managerNotified'))
	.directive('eventPurchasesRequestApproved', require('./directives/events/requestApproved/requestApproved'))
	.directive('eventPurchasesRequestChanged', require('./directives/events/requestChanged/requestChanged'))
	.directive('eventPurchasesRequestReassigned', require('./directives/events/requestReassigned/requestReassigned'))
	.directive('eventPurchasesRequestRejected', require('./directives/events/requestRejected/requestRejected'))
	.directive('eventPurchasesRequestSubmitted', require('./directives/events/requestSubmitted/requestSubmitted'))
	.directive('eventPurchasesRequestForm', require('./directives/events/purchaseRequest/purchaseRequest'))
	.directive('purchaseRequestFormV1', require('./directives/events/purchaseRequest/V1/purchaseRequestForm'))
	.directive('purchaseRequestFormV2', require('./directives/events/purchaseRequest/V2/purchaseRequestForm'))
	.directive(
		'eventPurchasesConversationMessage',
		require('./directives/events/conversationMessage/conversationMessage'),
	)
	.directive('eventPurchasesSkillSelected', require('./directives/events/purchases/skillSelected/skillSelected'))
	.directive('fileModel', require('./directives/fileModel/fileModel'))
	.directive('pdf', require('./directives/pdf'))
	.directive('goDrawer', require('./directives/drawer'))
	.directive('objectEvent', require('./directives/events/objectEvent/objectEvent'))
	.directive('progressBar', require('./directives/progressBar/progressBar'))
	.directive('realCard', require('./directives/realCard/realCard'))
	.directive('requestCard', require('./directives/requestCard/requestCard'))
	.directive('requestInfo', require('./directives/requestInfo/requestInfo'))
	.directive('securedElement', require('./directives/securedElement/securedElement'))
	.directive('submitAsync', require('./directives/submitAsync'))
	.directive('userImage', require('./directives/userImage/userImage'))
	.directive('validateInput', require('./directives/validateInput/validateInput'))
	.directive('virtualCard', require('./directives/virtualCard/virtualCard'))
	.directive('required', require('./directives/required/required'))
	.factory('ApprovalList', require('./models/approvals/ApprovalList'))
	.factory('httpInterceptor', require('./factories/httpInterceptor'))
	.factory('RequestList', require('./models/requests/RequestList'))
	.service('AccountSetupService', require('./services/accountSetup'))
	.service('Approval', require('./models/approvals/Approval'))
	.service('ApprovalLists', require('./models/approvals/ApprovalLists'))
	.service('ApprovalListBuilder', require('./models/approvals/ApprovalListBuilder'))
	.service('ApprovalRegistry', require('./models/approvals/ApprovalRegistry'))
	.service('ApprovalService', require('./services/approval'))
	.service('AsideService', require('./services/aside'))
	.service('CardInfoService', require('./services/cardInfo'))
	.service('CodingService', require('./services/coding'))
	.service('CodingEntryStateService', require('./services/codingEntryState'))
	.service('CompanyService', require('./services/company'))
	.service('ConfigService', require('./services/config'))
	.service('ConversationService', require('./services/conversation'))
	.service('CreateRequestService', require('./services/createRequest'))
	.service('DashboardService', require('./services/dashboard'))
	.service('DomainWhitelistingService', require('./services/domainWhitelisting'))
	.service('DOMService', require('./services/dom'))
	.service('ErrorService', require('./services/error'))
	.service('EventService', require('./services/event'))
	.service('FeatureService', require('./services/feature'))
	.service('FilterCodingListsService', require('./services/filterCodingLists'))
	.service('FooterService', require('./services/footer'))
	.service('HeaderService', require('./services/header'))
	.service('HttpService', require('./services/httpService'))
	.service('i18nService', require('./services/i18n'))
	.service('LexicoService', require('./services/lexico'))
	.service('LoadingService', require('./services/loading'))
	.service('LogoutService', require('./services/logout'))
	.service('NameService', require('./services/Name'))
	.service('PaymentsService', require('./services/payments'))
	.service('ReceiptService', require('./services/receipt'))
	.service('ReportService', require('./services/report'))
	.service('ReassignRequestService', require('./services/reassignRequest'))
	.service('Request', require('./models/requests/Request'))
	.service('RequestListBuilder', require('./models/requests/RequestListBuilder'))
	.service('RequestLists', require('./models/requests/RequestLists'))
	.service('RequestProfilesService', require('./services/requestProfiles'))
	.service('RequestRegistry', require('./models/requests/RequestRegistry'))
	.service('RequestService', require('./services/request'))
	.service('RoleService', require('./services/role'))
	.service('RoleServiceV2', require('./v2/services/role'))
	.service('unsavedValuesCacheService', require('./services/unsavedValuesCache'))
	.service('ScrollService', require('./services/scroll'))
	.service('SessionService', require('./services/session'))
	.service('StringsService', require('./services/strings'))
	.service('TaskService', require('./services/task'))
	.service('TeamsService', require('./services/teams'))
	.service('UserService', require('./services/user'))
	.service('UserServiceV2', require('./v2/services/user'))
	.service('UserSettingService', require('./services/userSettings'))
	.service('UUIDService', require('./services/uuid'))
	.service('VirtualCard', require('./models/VirtualCard'))
	.service('Card', require('./models/Card'))
	.config(require('./config/routing'))
	.value('EventEmitter', payload => ({ $event: payload }))
	.config([
		'CacheFactoryProvider',
		'constants',
		function (CacheFactoryProvider, constants) {
			angular.extend(CacheFactoryProvider.defaults, { maxAge: constants.cache.maxAge });
		},
	])
	.config([
		'$compileProvider',
		'$httpProvider',
		function ($compileProvider, $httpProvider) {
			$httpProvider.interceptors.push('httpInterceptor');
			// https://docs.angularjs.org/guide/production
			$compileProvider.debugInfoEnabled(false);

			// disable IE ajax request caching to stop IE11 freezing
			// http://stackoverflow.com/questions/32107850/site-crashing-ie11-when-dev-tools-closed
			$httpProvider.defaults.cache = false;
			if (!$httpProvider.defaults.headers.get) {
				$httpProvider.defaults.headers.get = {};
			}
			$httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
		},
	])
	.config([
		'$provide',
		function ($provide) {
			errorReporter.decorateAngularServices($provide);
		},
	])
	.config([
		'$qProvider',
		function ($qProvider) {
			// https://github.com/angular-ui/ui-router/issues/2889
			$qProvider.errorOnUnhandledRejections(false);
		},
	])
	.run(
		/* @ngInject */
		function ($rootScope, $state, $templateCache, $transitions, HeaderService, SessionService, $http, $location) {
			$templateCache.put('purchaseRequest', require('./directives/events/purchaseRequest/purchaseRequest.html'));

			$templateCache.put(
				'requestForms/amex-v-1-0',
				require('./directives/events/purchaseRequest/V1/profiles/amexV10.html'),
			);
			$templateCache.put(
				'requestForms/default',
				require('./directives/events/purchaseRequest/V1/profiles/default.html'),
			);

			$templateCache.put('requestCards/amex-v-1-0', require('./directives/requestCard/profiles/amexV10.html'));
			$templateCache.put('requestCards/default', require('./directives/requestCard/profiles/default.html'));

			$templateCache.put('asides/asides', require('./asides/asides.html'));
			$templateCache.put('asides/approvalOptions', require('./asides/approvalOptions/approvalOptions.html'));
			$templateCache.put('asides/addCardSource', require('./asides/addCardSource/addCardSource.html'));
			$templateCache.put('asides/codingLists', require('./asides/codingLists/codingLists.html'));
			$templateCache.put('asides/confirmAction', require('./asides/confirmAction/confirmAction.html'));
			$templateCache.put('asides/createCard', require('./asides/createCard/createCard.html'));
			$templateCache.put('asides/createCardV2', require('./asides/createCard/createCard.html'));
			$templateCache.put('asides/defaultManager', require('./asides/defaultManager/defaultManager.html'));
			$templateCache.put('asides/editProfile', require('./asides/editProfile/editProfile.html'));
			$templateCache.put('asides/editProfileV2', require('./asides/editProfile/editProfile.html'));
			$templateCache.put('asides/inviteUser', require('./asides/inviteUser/inviteUser.html'));
			$templateCache.put('asides/sortOptions', require('./asides/sortOptions/sortOptions.html'));

			$rootScope.$state = $state;
			// expose to use in react
			window.$state = $state;
			let isUserAuthentichated = false;

			$rootScope.$on('$locationChangeStart', function () {
				const upcomingLocation = $location.path();

				const reactRoutes = reactMigration.migratedPages.map(page => page.reactJsRoute);

				const isReactRoute = reactRoutes.some(route => {
					if (upcomingLocation === '/') {
						return true;
					}
					return route !== '/' && upcomingLocation.includes(route);
				});

				const header = document.getElementsByClassName('ts-headerbar')[0];
				const subHeader = document.getElementsByTagName('go-sub-header')[0];
				const routingState = zustandStore.useZustand.getState().routing;

				if (!isReactRoute) {
					if (header) {
						header.style.display = 'block';
					}
					if (subHeader) {
						subHeader.style.display = 'block';
					}
					zustandStore.useZustand.setState({
						routing: { ...routingState, isMainContentInControl: false },
					});
				} else {
					if (header) {
						header.style.display = 'none';
					}
					if (subHeader) {
						subHeader.style.display = 'none';
					}
					zustandStore.useZustand.setState({
						routing: { ...routingState, isMainContentInControl: true },
					});
				}
			});

			$transitions.onBefore({ to: 'main.**' }, function (transition) {
				transition.promise.finally(
					SessionService.isAuthenticated().then(isAuthenticated => {
						isUserAuthentichated = isAuthenticated;
						if (window.IS_PLAYWRIGHT_RUNNING) {
							// we do not want to mock all the requests in playwright
							return true;
						}
						if (!isAuthenticated) {
							throw new Error('User not authenticated');
						}
					}),
				);
			});

			$transitions.onStart({}, function (transition) {
				const transactionStartDate = Date.now();

				const reactRoutes = reactMigration.migratedPages
					.filter(page => page.migrationDone)
					.map(page => page.reactJsRoute);

				if (reactRoutes.includes(window.location.pathname)) {
					setTimeout(() => {
						const header = document.getElementsByClassName('ts-headerbar')[0];
						const subHeader = document.getElementsByTagName('go-sub-header')[0];
						if (header) {
							header.style.display = 'none';
						}
						if (subHeader) {
							subHeader.style.display = 'none';
						}
					}, 0);
				}

				const featureFlag = localStorage.getItem(reactMigration.featureFlag);
				if (featureFlag && featureFlag === 'true') {
					if (reactMigration.migratedPages.map(page => page.angularJsRoute).includes(transition.to().name)) {
						const migratedPage = reactMigration.migratedPages.find(
							page => page.angularJsRoute === transition.to().name,
						);
						const reactJsRoute = migratedPage.reactJsRoute;
						if (migratedPage?.migrationDone) {
							return true;
						}

						const requiresPathId = migratedPage.requiresPathId;
						if (requiresPathId) {
							const pathId =
								transition.params().requestId || transition.params().approvalId || transition.params().taskId;
							const { userId } = transition.params();
							const activationToken = transition.params().activationToken;
							if (pathId) {
								window.location.href = `${reactJsRoute}/${pathId}`;
							} else if (userId && activationToken) {
								window.location.href = `${reactJsRoute}/${userId}/${activationToken}`;
							} else {
								logger.error(`Invalid request ID: ${pathId}`);
							}
						} else {
							window.location.href = reactJsRoute;
						}
						return;
					}
				}

				// const metricsId = FrontendMetricsClientService.startTrackingTransition(transition);
				const LoadingService = transition.injector().get('LoadingService');
				LoadingService.startLoading();
				transition.promise.finally(() => {
					$http.post('/rest/log/incoming', {
						location: $location.url(),
						start: transactionStartDate,
					});

					const currentPath = $location.path();
					const isRootOrEmpty = currentPath === '/' || currentPath === '';
					const isInExcludedList = excludedPaths.some(path => currentPath.includes(path));
					const shouldHideSideMenu = isRootOrEmpty || isInExcludedList;

					Root.queryClient.setDefaultOptions({
						queries: {
							enabled: !shouldHideSideMenu,
						},
					});

					const sideMenuState = zustandStore.useZustand.getState().sideMenu;
					zustandStore.useZustand.setState({
						sideMenu: { ...sideMenuState, shouldRenderSideMenu: !shouldHideSideMenu && isUserAuthentichated },
					});

					LoadingService.stopLoading();
					// FrontendMetricsClientService.stopTrackingTransition(transition, metricsId);
				});
			});
			$transitions.onError({}, function (transition) {
				// navigating to the manage pages causes the transition to run twice and the latter one is rejected
				// it shouldn't trigger onError - https://github.com/angular-ui/ui-router/issues/3212#issuecomment-271996294
				// but it does so we'll ignore it
				const REJECTION_TYPE_IGNORED = 5;
				const REJECTION_TYPE_SUPERSEDED = 2;

				// if we are already redirecting then we can expect the error to have been handled
				if (transition.error().redirected) {
					return;
				}

				if ([REJECTION_TYPE_SUPERSEDED, REJECTION_TYPE_IGNORED].includes(transition.error().type)) {
					return;
				}

				transition.promise.catch(function (error) {
					if (_.has(error, 'detail.redirectTo')) {
						transition.router.stateService.go(error.detail.redirectTo, error.detail.params);
						return;
					}
					// TODO investigate if detail.responseStatusCode and detail.statusCode can be merged
					if (
						_.get(error, 'detail.responseStatusCode') === 'Unauthorized' ||
						_.get(error, 'detail.status') === 401 ||
						_.get(error, 'detail.status') === 403
					) {
						SessionService.sessionExpiredRedirect({
							redirectFrom: transition.$from().name,
							redirectParams: _.omit(transition.params(), ['#']),
							redirectTo: transition.$to().name,
						});
					} else if (_.get(error, 'detail.statusCode') === 404) {
						transition.router.stateService.go('main.error', {
							error: error.detail,
						});
					} else {
						const errorMessage =
							"We're sorry, the action you’re trying to perform can't be " +
							'completed.\n\nIn some cases simply trying again helps.';
						if (error?.message !== 'The transition errored') {
							ts.ui.Notification.warning(errorMessage);
						}
					}
				});
			});

			ts.ui.ready(function () {
				HeaderService.init();

				// fixes bug - https://github.com/Tradeshift/tradeshift-ui/issues/321
				ts.ui.FakeInputSpirit.prototype._triggerinput = function () {
					const evt = document.createEvent('Event');
					evt.initEvent('change', true, true);
					this._proxyelement.dispatchEvent(evt);
				};

				// if a tab exists that matches the current url then select it
				$transitions.onStart({}, function (transition) {
					const tabs = HeaderService.get().tabs();
					if (!tabs) {
						return;
					}
					const tab = tabs.get(transition.$to().name);
					if (!tab) {
						return;
					}
					tab.select();
				});

				// opens left menu when in mobile view
				gui.Broadcast.addGlobal(ts.ui.BROADCAST_GLOBAL_MENU_OPEN, {
					onbroadcast() {
						ts.ui.get('#js-sidebar-new', newSidebar => {
							if (newSidebar) {
								newSidebar.open();
							}
						});
					},
				});
			});
		},
	);

function renderGoApp() {
	window.event = undefined;
	const container = document.getElementById('go-app-container');
	if (container) {
		const root = createRoot(container);
		root.render(
			<Sentry.ErrorBoundary
				beforeCapture={scope => {
					scope.setTag('aplication', 'react');
				}}
				fallback={ErrorFallback}
			>
				<React.StrictMode>
					<GoApp />
				</React.StrictMode>
			</Sentry.ErrorBoundary>,
		);
	}
}

renderGoApp();
