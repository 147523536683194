"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePurchaseAmount = exports.countDecimals = exports.getDecimalCount = exports.validatePurchasePurpose = void 0;
const validatePurchasePurpose = desc => {
    if (!desc) {
        return true;
    }
    const restrictedStrings = ['"', "'", '/', '<', '>', '&'];
    const isInvalid = restrictedStrings.find(s => desc.includes(s));
    return !isInvalid;
};
exports.validatePurchasePurpose = validatePurchasePurpose;
const getDecimalCount = num => {
    // Convert to String
    const numStr = num === undefined ? '' : String(num);
    // String Contains Decimal
    if (numStr.includes('.')) {
        const strArr = numStr.split('.');
        if (strArr?.length > 2) {
            return strArr.length;
        }
        return strArr[1]?.length;
    }
    // String Does Not Contain Decimal
    return 0;
};
exports.getDecimalCount = getDecimalCount;
const countDecimals = function (num) {
    if (Math.floor(num.valueOf()) === num.valueOf()) {
        return 0;
    }
    return num.toString().split('.')?.[1]?.length || 0;
};
exports.countDecimals = countDecimals;
const validatePurchaseAmount = amount => {
    if (!amount) {
        return true;
    }
    return !Number.isNaN(amount) && Number(amount) > 0 && (0, exports.countDecimals)(amount) <= 2;
};
exports.validatePurchaseAmount = validatePurchaseAmount;
