"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledSettlementHeaderBox = exports.StyledWalletContentBox = exports.StyledActivityHeaderBox = exports.StyledWalletHeaderBox = exports.StyledBox = void 0;
const material_1 = require("@mui/material");
exports.StyledBox = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    backgroundColor: 'hsl(0, 0%, 100%)',
    border: `px solid ${theme.palette.tsGoColor.grayLight()}`,
    margin: '20px auto',
    maxWidth: '960px',
    padding: '20px',
    position: 'relative',
}));
exports.StyledWalletHeaderBox = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    top: 0,
    position: 'sticky',
    backgroundColor: '#F3F4F9',
    zIndex: 100,
    gap: theme.tsGoUnit.tsUnitHalf,
    flexWrap: 'wrap',
    padding: `${theme.tsGoUnit.tsUnit} ${theme.tsGoUnit.tsUnitHalf}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));
exports.StyledActivityHeaderBox = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    top: 0,
    position: 'sticky',
    backgroundColor: '#F3F4F9',
    zIndex: 100,
    gap: theme.tsGoUnit.tsUnitHalf,
    flexWrap: 'wrap',
    padding: `${theme.tsGoUnit.tsUnit} ${theme.tsGoUnit.tsUnit} 0 ${theme.tsGoUnit.tsUnit}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));
exports.StyledWalletContentBox = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    padding: `0px ${theme.tsGoUnit.tsUnitHalf}`,
    zIndex: 0,
}));
exports.StyledSettlementHeaderBox = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    top: 0,
    position: 'sticky',
    backgroundColor: '#F3F4F9',
    zIndex: 100,
    gap: theme.tsGoUnit.tsUnitHalf,
    flexWrap: 'wrap',
    margin: `${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnit}`,
    display: 'flex',
    alignItems: 'center',
}));
