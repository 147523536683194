import angular from 'angular';

import cardsUniqById from './cards-uniq-by-id';
import currencyFilter from './currency.filter';
import providerMappingFilter from './provider-mapping.filter';
import sentenceCaseFilter from './sentence-case.filter';
import subscriptionEndsFilter from './subscription-ends.filter';
import subscriptionFrequencyFilter from './subscription-frequency.filter';

export default angular
	.module('go.common.filters', [])
	.filter('goSentenceCase', sentenceCaseFilter)
	.filter('providerMapping', providerMappingFilter)
	.filter('subscriptionEnds', subscriptionEndsFilter)
	.filter('subscriptionFrequency', subscriptionFrequencyFilter)
	.filter('cardsUniqById', cardsUniqById)
	.filter('currency', currencyFilter).name;
