import angular from 'angular';

import ForwardRequestAsideComponent from './forward-request-aside.component';
import ForwardRequestHelper from './forward-request-helper.component';
import ForwardRequestPanelComponent from './forward-request-panel.component';

export default angular
	.module('go.v2.common.asides.forward-request', [])
	.component('goForwardRequestAsideV2', ForwardRequestAsideComponent)
	.component('forwardRequestPanelV2', ForwardRequestPanelComponent)
	.factory('forwardRequestHelperV2', ForwardRequestHelper).name;
