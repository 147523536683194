/* @ngInject */
function ConfigService(HttpService) {
	const service = {};

	service.delete = key => HttpService.tradeshiftGo().path(`/rest/configuration/${key}`).delete();

	service.get = key => window.config[key];

	service.getAsync = key => HttpService.tradeshiftGo().path(`/rest/configuration/${key}`).get();

	service.set = ({ key, value }) => HttpService.tradeshiftGo().path(`/rest/configuration/${key}`).post({ value });

	service.getProviderMappingConfigs = () => HttpService.tradeshiftGo().path('/rest/providerMappingConfigs/').get();

	/** Version for frontend server */
	// service.getServerVersion = () => HttpService.tradeshiftGoFrontend().path('/rest/version').get();
	service.getServerVersion = () => Promise.resolve(window.config['VERSION']);

	service.getWorkflowConfigs = () => HttpService.tradeshiftGo().path('/rest/workflowConfigs/').get();

	return service;
}

module.exports = ConfigService;
