const _ = require('lodash');

const CodingHelper = /* @ngInject */ ($q, CodingEntryStateService, CodingService, ConfigService, TeamsService) => {
	const ALL_CODING_LISTS = ConfigService.get('CODING_LISTS');
	const PROVIDER_FIELD_OPTIONS = [
		{ id: 'coding1', label: 'Coding 1' },
		{ id: 'coding2', label: 'Coding 2' },
		{ id: 'coding3', label: 'Coding 3' },
		{ id: 'coding4', label: 'Coding 4' },
		{ id: 'coding5', label: 'Coding 5' },
		{ id: 'coding6', label: 'Coding 6' },
		{ id: 'coding7', label: 'Coding 7' },
		{ id: 'coding8', label: 'Coding 8' },
		{ id: 'not-mapped', label: 'Not mapped' },
	];
	const helper = {};
	helper.codingListTableColumnMap = {
		NEW_ENTRY: 0,
	};

	helper.codingListTableColumnNames = {
		ACTIONS: 'actions',
		COST_CENTERS: 'cost-centers',
		ID: 'unique-identifier',
		PATH: 'coding-entry',
		SELECT: 'select',
	};

	helper.deleteEntry = (listId, { ID: entryId }) => {
		// TODO check if entryId exists in the object already and improve destructuring
		return CodingService.deleteEntry({ listId, entryId })
			.then(() => {
				ts.ui.Notification.success('Coding entry deleted.');
			})
			.catch(() => {
				ts.ui.Notification.warning('Sorry, there was a problem deleting the coding entry. Please try again.');
			});
	};

	helper.entryHasData = (entry, showCostCentersColumn) => {
		if (showCostCentersColumn) {
			return (
				!helper.isNilOrEmptyString(entry.ID) &&
				!helper.isNilOrEmptyString(entry.path) &&
				Array.isArray(entry.filteredBy) &&
				!_.isEmpty(entry.filteredBy)
			);
		}
		return !helper.isNilOrEmptyString(entry.ID) && !helper.isNilOrEmptyString(entry.path);
	};

	helper.filterObjectByValue = (object, value) => {
		const clonedObject = _.cloneDeep(object);
		Object.entries(clonedObject).forEach(([entryKey, entryValue]) => {
			if (entryValue === value) {
				delete clonedObject[entryKey];
			}
		});
		return clonedObject;
	};

	helper.getCodingListTableColumnIndex = (columns, columnName) => {
		return columns.findIndex(column => column.type === columnName);
	};

	helper.getCodingListTableColumns = showCostCentersColumn => {
		const columns = [
			{ label: 'Unique identifier', type: helper.codingListTableColumnNames.ID },
			{ label: 'Coding entry', type: helper.codingListTableColumnNames.PATH },
			{ label: 'Actions', type: helper.codingListTableColumnNames.ACTIONS, width: ts.ui.UNIT * 4, editable: false },
		];

		if (showCostCentersColumn) {
			const costCenterCellIndex =
				helper.getCodingListTableColumnIndex(columns, helper.codingListTableColumnNames.PATH) + 1;
			columns.splice(
				costCenterCellIndex,
				0,
				{ label: 'Cost centers', type: helper.codingListTableColumnNames.COST_CENTERS, editable: false },
				{ label: '', type: helper.codingListTableColumnNames.SELECT, width: ts.ui.UNIT * 2, editable: false },
			);
		}

		_.forEach(helper.codingListTableColumnNames, (columnName, columnKey) => {
			helper.codingListTableColumnMap[columnKey] = helper.getCodingListTableColumnIndex(columns, columnName);
		});

		return columns;
	};

	helper.getCodingListTableRows = (costCenterOptions, entries, newEntry, showCostCentersColumn) => {
		const deleteButton = {
			item: 'Icon',
			type: 'ts-icon-remove go-table-cell-icon-color',
		};
		const openAsideButton = {
			item: 'Icon',
			type: 'ts-icon-select go-table-cell-icon-color',
		};
		let addCostCenterCell = {
			text: '*Add cost centers*',
			type: 'cell-placeholder',
		};
		const tableEntries = [
			[
				{
					text: newEntry.ID || '*Enter a numeric id*',
					type: newEntry.ID ? '`monotype text`' : 'cell-placeholder',
					value: newEntry.ID || '',
				},
				{
					text: newEntry.path || '*Enter new entry*',
					type: newEntry.path ? '`monotype text`' : 'cell-placeholder',
					value: newEntry.path || '',
				},
				{
					item: 'Button',
					label: 'Add entry',
					name: 'add-entry',
					type: 'ts-micro',
				},
			],
		];

		if (!showCostCentersColumn) {
			return tableEntries.concat(
				entries.map(entry => {
					return [entry.ID, entry.path, deleteButton];
				}),
			);
		}

		if (!_.isEmpty(newEntry.filteredBy)) {
			addCostCenterCell = helper.getCostCentersLabel(newEntry, costCenterOptions);
		}

		tableEntries[0].splice(helper.codingListTableColumnMap.COST_CENTERS, 0, addCostCenterCell, openAsideButton);

		return tableEntries.concat(
			entries.map(entry => [
				entry.ID,
				entry.path,
				helper.getCostCentersLabel(entry, costCenterOptions),
				openAsideButton,
				deleteButton,
			]),
		);
	};

	helper.getCodingTableRows = ({ lists, providerFields, showProviderFields }) => {
		return _.map(lists, list => {
			let deleteActionCell, nameCell;
			if (Object.keys(ALL_CODING_LISTS).includes(list.listId)) {
				nameCell = list.name;
				deleteActionCell = '';
			} else {
				nameCell = `${list.name} ([rename](${list.listId}))`;
				deleteActionCell = {
					item: 'Icon',
					type: 'ts-icon-delete go-table-cell-icon-color',
				};
			}
			const row = [
				nameCell,
				list.entries ? list.entries.length : 0,
				{
					item: 'Switch',
					name: 'Enable list',
					value: 'enable-list',
					checked: list.enabled,
				},
				{
					item: 'Icon',
					type: 'ts-icon-edit go-table-cell-icon-color',
				},
				deleteActionCell,
			];
			if (showProviderFields) {
				let providerFieldCell;
				const fieldId = _.findKey(providerFields, listId => listId === list.listId);
				if (fieldId) {
					providerFieldCell = {
						text: _.find(PROVIDER_FIELD_OPTIONS, ['id', fieldId]).label,
						value: fieldId,
					};
				} else {
					providerFieldCell = {
						text: '',
						value: 'not-mapped',
					};
				}
				row.splice(2, 0, providerFieldCell, {
					item: 'Icon',
					type: 'ts-icon-select go-table-cell-icon-color',
				});
			}
			return row;
		});
	};

	helper.getCostCentersLabel = (entry, costCenterOptions) => {
		const costCenterLabels = [];
		let costCenterString = '';

		if (!_.has(entry, 'filteredBy') || _.isEmpty(costCenterOptions)) {
			return costCenterString;
		}

		entry.filteredBy.forEach(filter => {
			if (filter.listID === 'costcentercode') {
				const costCenter = costCenterOptions.find(option => option.id === filter.entryID);
				if (costCenter) {
					costCenterLabels.push(costCenter.description);
				}
			}
		});

		if (costCenterLabels.length === costCenterOptions.length) {
			costCenterString = 'All cost centers';
		} else {
			costCenterString = costCenterLabels.sort().join(', ');
		}

		return costCenterString;
	};

	helper.getFilteredByValue = values =>
		values.map(id => ({
			listID: 'costcentercode',
			entryID: id,
			group: 'glcode',
		}));

	helper.getListNames = lists => {
		return _.reduce(
			lists,
			(result, list) => {
				result.push(list.name);
				return result;
			},
			[],
		);
	};

	helper.getProviderFieldOptions = () => {
		return PROVIDER_FIELD_OPTIONS;
	};

	helper.getUpdatedEntry = (entry, fieldToUpdate, value) => {
		const updatedEntry = _.cloneDeep(entry);
		switch (fieldToUpdate) {
			case helper.codingListTableColumnMap.ID:
				updatedEntry.ID = value;
				break;
			case helper.codingListTableColumnMap.PATH:
				updatedEntry.path = value;
				break;
			case helper.codingListTableColumnMap.COST_CENTERS:
				updatedEntry.filteredBy = helper.getFilteredByValue(value);
				break;
			case helper.codingListTableColumnMap.SELECT:
				updatedEntry.filteredBy = helper.getFilteredByValue(value);
				break;
			default:
		}
		return updatedEntry;
	};

	helper.isNewEntryId = (entries, entryId) => {
		if (entryId === '') {
			return false;
		}
		if (entries.find(({ ID: id }) => id === entryId)) {
			ts.ui.Notification.warning(`A coding entry with ID '${entryId}' already exists.`);
			return false;
		}
		return true;
	};

	helper.isNilOrEmptyString = value => _.isNil(value) || value === '';

	helper.loadEntries = listId => {
		return CodingService.getListEntries({ listId })
			.then(entries => {
				return entries.map(entry => helper.normalizeCodingEntry(entry));
			})
			.then(entries => _.sortBy(entries, 'ID'));
	};

	helper.loadList = listId => {
		return CodingService.getList({ listId }).then(response => {
			const list = helper.normalizeCodingList(response);
			return helper.loadEntries(list.listId).then(entries => {
				list.entries = entries;
				return list;
			});
		});
	};

	helper.loadLists = (includeDisabled = true) => {
		// TODO use CodingService.getListsAndEntries instead of getLists followed by loadEntries
		return CodingService.getLists({ includeDisabled }).then(response => {
			const lists = _.map(response, helper.normalizeCodingList);
			const promises = _.map(lists, ({ listId }) => helper.loadEntries(listId));
			return $q.all(promises).then(results => {
				results.forEach((entries, index) => {
					lists[index].entries = entries;
				});
				return lists.sort((listA, listB) => listA.listId > listB.listId);
			});
		});
	};

	helper.loadTeamCodingLists = async teamId => {
		const activeLists = await helper.loadLists(false);
		if (!teamId) {
			const team = await TeamsService.getCurrentTeam();
			teamId = team.id;
		}

		for (const list of activeLists) {
			const { ID: listId } = list;
			const teamEntries = await TeamsService.getTeamCodingListEntries({ teamId, listId });
			list.entries = teamEntries.map(entry => helper.normalizeCodingEntry(entry));
		}

		return activeLists.filter(list => !_.isEmpty(list.entries));
	};

	helper.loadProviderFields = () => {
		return CodingService.getProviderFieldsMap();
	};

	helper.normalizeCodingEntry = codingEntry => {
		// TODO: existing coding entries use ID, so let's use entryId and rename later
		const entryId = codingEntry.ID || codingEntry.entryId;
		const entryLabel = codingEntry.path || codingEntry.entryLabel;
		return _.assign({}, codingEntry, { entryId, entryLabel });
	};

	helper.normalizeCodingList = codingList => {
		// TODO: existing coding lists use ID and ALL_CODING_LISTS uses id, so let's use listId and rename later
		const listId = codingList.ID || codingList.id;
		return _.assign({}, codingList, { listId });
	};

	helper.saveProviderFields = providerFields => {
		return CodingService.saveProviderFieldsMap(providerFields);
	};

	helper.toggleListEnabled = ({ enabled, listIndex, lists }) => {
		const { listId } = lists[listIndex];
		return helper.toggleListEnabledById({ enabled, listId }).then(() => {
			Object.assign(lists[listIndex], { enabled });
		});
	};

	helper.toggleListEnabledById = ({ enabled, listId }) => {
		return $q.resolve().then(() => {
			if (enabled) {
				return CodingService.enableList({ listId });
			}
			return CodingService.disableList({ listId });
		});
	};

	helper.saveEntry = ({ entry, listId, replacesEntry }) => {
		const { ID: entryId, path: entryLabel, filteredBy } = entry;
		const options = { entryId, entryLabel, filteredBy, listId };
		if (replacesEntry && replacesEntry.ID !== entryId) {
			options.replaceEntryId = replacesEntry.ID;
		}
		return CodingService.addEntry(options)
			.then(() => {
				ts.ui.Notification.success('Coding entry saved.');
			})
			.catch(() => {
				ts.ui.Notification.warning('Sorry, there was a problem saving the coding entry. Please try again.');
			});
	};

	helper.validateEntryField = ({ entries, entry, fieldType, value }) => {
		if (fieldType === helper.codingListTableColumnMap.PATH) {
			if (entry.path === value) {
				return {
					isValid: true,
					isDirty: false,
				};
			} else if (value === '') {
				return {
					isValid: false,
				};
			} else {
				return {
					isValid: true,
					isDirty: true,
				};
			}
		} else {
			// (fieldType === ID)
			if (entry.ID === value) {
				return {
					isValid: true,
					isDirty: false,
				};
			} else if (helper.isNewEntryId(entries, value)) {
				return {
					isValid: true,
					isDirty: true,
				};
			} else {
				return {
					isValid: false,
				};
			}
		}
	};

	return helper;
};

module.exports = CodingHelper;
