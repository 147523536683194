import moment from 'moment';

const TSDatepickerComponent = {
	bindings: {
		asideLabel: '@',
		isRequired: '@',
		label: '@',
		max: '<?',
		min: '<?',
	},
	require: {
		model: 'ngModel',
	},
	template: `
		<fieldset>
			<label>
				<span ng-bind="$ctrl.label"></span>
				<input
						ng-attr-max="{{ $ctrl.max }}"
						ng-attr-min="{{ $ctrl.min }}"
						ng-change="$ctrl.onInputChange($ctrl.value)"
						ng-model="$ctrl.value"
						ng-required="$ctrl.isRequired"
						type="date" />
			</label>
		</fieldset>`,
	controller: class TSDatepickerController {
		/* @ngInject */
		constructor(constants) {
			this.DATE_PICKER_FORMAT = constants.datePickerFormat;
		}

		$onChanges(changes) {
			if (!this.datePicker) {
				return;
			}
			if (changes.asidelabel.currentValue) {
				this.asideLabel = changes.asideLabel.currentValue;
			}
			if (changes.label.currentValue) {
				this.label = changes.label.currentValue;
			}
			if (changes.max.currentValue) {
				this.max = changes.max.currentValue;
			}
			if (changes.min.currentValue) {
				this.min = changes.min.currentValue;
			}
		}

		$onInit() {
			this.model.$render = () => {
				if (this.model.$viewValue) {
					this.value = moment(this.model.$viewValue).toDate();
				} else {
					this.value = '';
				}
			};
		}

		onInputChange(value) {
			const formattedValue = moment(value).format(this.DATE_PICKER_FORMAT);
			this.model.$setViewValue(formattedValue);
		}
	},
};

export default TSDatepickerComponent;
