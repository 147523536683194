const ApprovalsSettingsAsideComponent = {
	bindings: {
		isOpen: '<',
		onClose: '&',
		openOoo: '&',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.close()"
				title="{{$ctrl.approvalSettingsHeaderText}}">
			<menu data-ts="Menu">
				<li>
					<button ng-click="$ctrl.openOooAside()">
						<span ng-bind="$ctrl.outOfOfficeMenuText"></span>
						<i class="ts-icon-arrowright"></i>
					</button>
				</li>
			</menu>
		</ts-aside>
	`,
	/* @ngInject */
	controller: function (LexicoService) {
		this.lexico = LexicoService.getLexico();
		this.approvalSettingsHeaderText = this.lexico.trc('Component title', 'Approval Settings');
		this.outOfOfficeMenuText = this.lexico.trc('Menu title', 'Out of office');

		this.close = () => {
			this.onClose();
		};

		this.openOooAside = () => {
			this.openOoo();
			this.close();
		};
	},
};

export default ApprovalsSettingsAsideComponent;
