const COST_CENTER_LIST_ID = 'costcentercode';

export default class EditUserService {
	/* @ngInject */
	constructor($q, CodingService, NameService, PaymentsService, UserService, RoleService, constants) {
		this.$q = $q;
		this.CodingService = CodingService;
		this.PaymentsService = PaymentsService;
		this.UserService = UserService;
		this.RoleService = RoleService;
		this.NameService = NameService;
		this.constants = constants;
	}

	assignOptionsModelToUser({ costCenter, manager, role }, user) {
		const PROPERTY_COSTCENTER = this.constants.userPropertyKeys.costcenter;
		const PROPERTY_MANAGER = this.constants.userPropertyKeys.manager;
		const properties = {
			[PROPERTY_COSTCENTER]: costCenter ? costCenter.id : '',
			[PROPERTY_MANAGER]: manager ? manager.email : '',
		};
		return Object.assign({}, user, {
			costCenter: costCenter ? { ID: costCenter.id, path: costCenter.label } : undefined,
			roleId: role.id,
			properties: Object.assign({}, user.properties, properties),
			fullName: this.NameService.fullName(user),
		});
	}

	getOptionsModel(formOptions, userProperties, roleId) {
		const { costCenters, roles, managers } = formOptions;
		const { costcenter, manager } = userProperties;
		return {
			costCenter: costCenters.find(({ id }) => id === costcenter),
			manager: managers.find(item => item.email === manager),
			role: roles.find(({ id }) => id === roleId),
		};
	}

	getFormOptions() {
		return this.$q
			.all([this.CodingService.getListsAndEntries(), this.PaymentsService.getApprovers()])
			.then(([codingLists, approvers]) => {
				const getCostCenterOptions = () => {
					return codingLists[COST_CENTER_LIST_ID].entries.map(({ entryId: id, label }) => ({ id, label }));
				};
				const costCenters = codingLists[COST_CENTER_LIST_ID] ? getCostCenterOptions() : [];
				const managers = approvers.map(({ userId: id, fullName: label, email }) => ({ id, email, label }));
				const roles = this.RoleService.getFilteredRoles();
				return { costCenters, managers, roles };
			});
	}

	isNameEditable(user) {
		return this.$q
			.all([this.UserService.getPrimaryUser(), this.UserService.getUser()])
			.then(
				([primaryUserId, loggedInUser]) => primaryUserId === loggedInUser.userId || loggedInUser.userId === user.userId,
			);
	}

	saveUser(user) {
		const { userId, properties } = user;
		const saveUserPromises = [
			this.UserService.saveUser({ user }),
			this.UserService.setUserProperties(userId, properties),
		];
		return this.$q.all(saveUserPromises).then(() => user);
	}
}
