"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showApprovalNotificationClassName = exports.StyledApprovalIconDisplayName = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const StyledIcon_1 = require("../../../material-ui-theme/components/styled/StyledIcon");
exports.StyledApprovalIconDisplayName = 'ApprovalIcon';
exports.showApprovalNotificationClassName = 'showApprovalNotificationClassName';
const IconWrapper = (0, react_1.forwardRef)(function IconWrapper(props, ref) {
    const { icon, className, ...other } = props;
    const StyledIconElement = StyledIcon_1.StyledIcon.withComponent(icon);
    return (0, jsx_runtime_1.jsx)(StyledIconElement, { className: className, ref: ref, ...other });
});
IconWrapper.displayName = 'IconWrapper';
exports.default = IconWrapper;
