const _ = require('lodash');

const UserServiceV2 = /* @ngInject */ (HttpService, UserService) => {
	const service = _.clone(UserService);

	service.isCompanyOrSupportAdmin = user => service.isCompanyAdmin(user) || service.isSupportAdmin(user);

	service.isCompanyAdmin = user => !!user.roles.find(role => role.roleId === 'company_admin');

	service.isManagerOrTeamAdmin = user => service.isManager(user) || service.isTeamAdmin(user);

	service.isTeamAdmin = user => !!user.roles.find(role => role.roleId === 'team_admin');

	service.isManager = user => !!user.roles.find(role => role.roleId === 'manager');

	service.isSupportAdmin = user => !!user.roles.find(role => role.roleId === 'tradeshift_support_admin');

	service.verifyAccess = ({ objectId, operationId, teamId, userId } = {}) => {
		if (teamId && userId) {
			return HttpService.tradeshiftGo()
				.path(`/rest/teams/${teamId}/users/${userId}/accesslist/operation/${operationId}/object/${objectId}`)
				.get();
		} else {
			return HttpService.tradeshiftGo().path(`/rest/accesslist/operation/${operationId}/object/${objectId}`).get();
		}
	};

	service.verifyTeamAccess = ({ objectId, operationId, teamId } = {}) =>
		HttpService.tradeshiftGo()
			.path(`/rest/teams/${teamId}/accesslist/operation/${operationId}/object/${objectId}`)
			.get();

	service.getUsersByRoleId = roleId => HttpService.tradeshiftGo().path(`/rest/users/role/${roleId}`).get();

	return service;
};

module.exports = UserServiceV2;
