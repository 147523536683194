const _ = require('lodash');

/* @ngInject */
const CodingEntryStateService = HttpService => ({
	getInactiveCodings: async ({ listId, teamId }) => {
		let teamInactiveEntries = await HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/inactiveCodings`).get();
		if (_.get(teamInactiveEntries, listId)) {
			teamInactiveEntries = teamInactiveEntries[listId].map(e => e.entry_id);
		} else {
			teamInactiveEntries = [];
		}
		return teamInactiveEntries;
	},
	setCodingEntryState: ({ entryId, entryState, listId, teamId }) =>
		HttpService.tradeshiftGo()
			.path(`/rest/teams/${teamId}/coding/lists/${listId}/entries/${entryId}/state`)
			.put(entryState),
});

module.exports = CodingEntryStateService;
