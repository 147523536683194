"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asideInitialState = void 0;
exports.asideSlice = asideSlice;
const strings_1 = require("../../constants/strings");
exports.asideInitialState = {
    aside: {
        walletCardDetailAside: {
            isOpen: false,
            cardDetail: undefined,
        },
        viewReceiptAside: {
            isOpen: false,
            settlement: undefined,
        },
        outOfOfficeAside: {
            isOpen: false,
        },
        requestAside: {
            isOpen: false,
        },
        addPeopleAside: {
            isOpen: false,
        },
        invitePeopleAside: {
            isOpen: false,
            shouldOpenAddAdminAside: false,
        },
        addAdminAside: {
            isOpen: false,
        },
        teamAside: {
            isOpen: false,
        },
        selectCardSource: {
            isOpen: false,
        },
        addCardSource: {
            isOpen: false,
            currentTeamName: '',
            currentCardId: '',
        },
        cardSourceDetail: {
            isOpen: false,
            cardSourceId: '',
        },
        cantRemoveCard: {
            isOpen: false,
            message: strings_1.cardDetailStrings.CANT_REMOVE_CARD,
        },
        removeCard: {
            isOpen: false,
            message: strings_1.cardDetailStrings.REMOVE_CARD,
        },
        selectTeams: {
            isOpen: false,
            model: {
                id: [],
            },
            options: [],
            title: strings_1.cardDetailStrings.SELECT_TEAMS,
        },
        userRole: {
            isOpen: false,
            curentUserRole: {},
        },
        userDetails: {
            isOpen: false,
            user: {},
            isSaveDisabled: true,
            reqLimitError: '',
        },
        userApprover: {
            isOpen: false,
        },
        codingListNewEntryAside: {
            isOpen: false,
        },
        codingListSelectorAside: {
            /* settings/coding Select Teams Aside */
            isOpen: false,
            // extra prop to know if the aside is open for a new entry or for editing an existing one
            isNewEntryAsideOpen: false,
        },
        removeCodingEntryAside: {
            isOpen: false,
        },
        createCodingList: {
            isOpen: false,
        },
        customCodingList: {
            isOpen: false,
        },
        renameCustomCodingList: {
            isOpen: false,
        },
        removeCodingList: {
            isOpen: false,
        },
        createCardAside: {
            isOpen: false,
            approval: undefined,
        },
        downloadReportAside: {
            isOpen: false,
            filters: undefined,
        },
    },
};
function asideSlice(set) {
    return {
        ...exports.asideInitialState,
        setIsOutOfOfficeAsideOpen(isOpen) {
            set((state) => {
                state.aside.outOfOfficeAside.isOpen = isOpen;
                return state;
            }, false, '[setIsOutOfOfficeAsideOpen]');
        },
        setIsAddAdminAsideOpen(isOpen) {
            set((state) => {
                state.aside.addAdminAside.isOpen = isOpen;
                return state;
            }, false, '[setIsAddAdminAsideOpen]');
        },
        setIsAddPeopleAsideOpen(isOpen) {
            set((state) => {
                state.aside.addPeopleAside.isOpen = isOpen;
                return state;
            }, false, '[setIsAddPeopleAsideOpen]');
        },
        setIsInvitePeopleAsideOpen(isOpen) {
            set((state) => {
                state.aside.invitePeopleAside.isOpen = isOpen;
                return state;
            }, false, '[setIsInvitePeopleAsideOpen]');
        },
        setShouldOpenAddAdminAsideAsideOpen(shouldOpenAddAdminAside) {
            set((state) => {
                state.aside.invitePeopleAside.shouldOpenAddAdminAside = shouldOpenAddAdminAside;
                return state;
            }, false, '[setShouldOpenAddAdminAsideAsideOpen]');
        },
        setIsTeamAsideOpen(isOpen) {
            set((state) => {
                state.aside.teamAside.isOpen = isOpen;
                return state;
            }, false, '[setIsTeamAsideOpen]');
        },
        setIsSelectCardSourceOpen(isOpen) {
            set((state) => {
                state.aside.selectCardSource.isOpen = isOpen;
                return state;
            }, false, '[setIsSelectCardSourceOpen]');
        },
        setIsAddSourceCardOpen(isOpen) {
            set((state) => {
                state.aside.addCardSource.isOpen = isOpen;
                return state;
            }, false, '[setIsAddSourceCardOpen]');
        },
        setCurrentTeamNameOnSubmit(currentTeamName) {
            set((state) => {
                state.aside.addCardSource.currentTeamName = currentTeamName;
                return state;
            }, false, '[setCurrentTeamNameOnSubmit]');
        },
        setCurrentCardIdOnSubmit(currentCardId) {
            set((state) => {
                state.aside.addCardSource.currentCardId = currentCardId;
                return state;
            }, false, '[setCurrentCardIdOnSubmit]');
        },
        setIsCardSourceDetailOpen(data) {
            set((state) => {
                state.aside.cardSourceDetail = {
                    isOpen: data.isOpen,
                    cardSourceId: data.cardSourceId || state.aside.cardSourceDetail.cardSourceId,
                };
                return state;
            }, false, '[setCardSourceDetailOpen]');
        },
        setIsSelectTeamsOpen(data) {
            set((state) => {
                state.aside.selectTeams = {
                    isOpen: data.isOpen ?? state.aside.selectTeams.isOpen,
                    model: data.model || state.aside.selectTeams.model,
                    options: data.options || state.aside.selectTeams.options,
                    title: data.title || state.aside.selectTeams.title,
                };
                return state;
            }, false, '[setIsSelectTeamsOpen]');
        },
        setOpenAsides({ type, isOpen }) {
            set((state) => {
                state.aside[type] = {
                    isOpen,
                };
                return state;
            }, false, '[setIsSelectTeamsOpen]');
        },
        setIsUserRoleAsideOpen(isOpen) {
            set((state) => {
                state.aside.userRole.isOpen = isOpen;
                return state;
            }, false, '[setIsUserRoleAsideOpen]');
        },
        setCurrentUserRoleAside(role) {
            set((state) => {
                state.aside.userRole.curentUserRole = role;
                return state;
            }, false, '[setCurrentUserRoleAside]');
        },
        setIsUserDetailsAsideOpen(isOpen) {
            set((state) => {
                state.aside.userDetails.isOpen = isOpen;
                return state;
            }, false, '[setIsUserDetailsAsideOpen]');
        },
        setIsUserDetailSaveBtnDisabled(isSaveDisabled) {
            set((state) => {
                state.aside.userDetails.isSaveDisabled = isSaveDisabled;
                return state;
            }, false, '[setIsUserDetailSaveBtnDisabled]');
        },
        setRequestLimitError(reqLimitError) {
            set((state) => {
                state.aside.userDetails.reqLimitError = reqLimitError;
                return state;
            }, false, '[setRequestLimitError]');
        },
        setCurrentUserDetailsAside(user) {
            set((state) => {
                state.aside.userDetails.user = user;
                return state;
            }, false, '[setCurrentUserDetailsAside]');
        },
        setIsUserApproverAsideOpen(isOpen) {
            set((state) => {
                state.aside.userApprover.isOpen = isOpen;
                return state;
            }, false, '[setIsUserApproverAsideOpen]');
        },
        setIsCodingListNewEntryAsideOpen(isOpen) {
            set((state) => {
                state.aside.codingListNewEntryAside.isOpen = isOpen;
                return state;
            }, false, '[setIsCodingListNewEntryAsideOpen]');
        },
        setIsCodingListSelectorAsideOpen(isOpen) {
            set((state) => {
                state.aside.codingListSelectorAside.isOpen = isOpen;
                return state;
            }, false, '[setIsCodingListSelectorAsideOpen]');
        },
        setIsNewEntryAsideOpen(isNewEntryAsideOpen) {
            set((state) => {
                state.aside.codingListSelectorAside.isNewEntryAsideOpen = isNewEntryAsideOpen;
                return state;
            }, false, '[setIsNewEntryAsideOpen]');
        },
        setIsRemoveCodingEntryAsideOpen(isOpen) {
            set((state) => {
                state.aside.removeCodingEntryAside.isOpen = isOpen;
                return state;
            }, false, '[setIsRemoveCodingEntryAsideOpen]');
        },
        setIsCreateCodinglistAsideOpen(isOpen) {
            set((state) => {
                state.aside.createCodingList.isOpen = isOpen;
                return state;
            }, false, '[setIsCreateCodinglistAsideOpen]');
        },
        setIsCustomCodingListAsideOpen(isOpen) {
            set((state) => {
                state.aside.customCodingList.isOpen = isOpen;
                return state;
            }, false, '[setIsCustomCodingListAsideOpen]');
        },
        setIsRenameCustomCodingListAsideOpen(isOpen) {
            set((state) => {
                state.aside.renameCustomCodingList.isOpen = isOpen;
                return state;
            }, false, '[setIsRenameCustomCodingListAsideOpen]');
        },
        setIsRemoveCodingListAsideOpen(isOpen) {
            set((state) => {
                state.aside.removeCodingList.isOpen = isOpen;
                return state;
            }, false, '[setIsRemoveCodingListAsideOpen]');
        },
        setIsRequestAsideOpen(isOpen) {
            set((state) => {
                state.aside.requestAside.isOpen = isOpen;
                return state;
            }, false, '[setIsRequestAsideOpen]');
        },
        setIsViewReceiptsAsideOpen(isOpen) {
            set((state) => {
                state.aside.viewReceiptAside.isOpen = isOpen;
                return state;
            }, false, '[setIsViewReceiptsAsideOpen]');
        },
        setViewReceiptsAsideSettlement(settlement) {
            set((state) => {
                state.aside.viewReceiptAside.settlement = settlement;
                return state;
            }, false, '[setViewReceiptsAsideSettlement]');
        },
        setIsWalletCardDetailAsideOpen: (isOpen) => {
            set((state) => {
                state.aside.walletCardDetailAside.isOpen = isOpen;
                return state;
            }, false, '[setIsWalletCardDetailAsideOpen]');
        },
        setWalletCardDetailAside: (cardDetail) => {
            set((state) => {
                state.aside.walletCardDetailAside.cardDetail = cardDetail;
                return state;
            }, false, '[setWalletCardDetailAside]');
        },
        setIsCreateCardAsideOpen: (isOpen) => {
            set((state) => {
                state.aside.createCardAside.isOpen = isOpen;
                return state;
            }, false, '[setIsCreateCardAsideOpen]');
        },
        setCreateCardAsideApproval: (approval) => {
            set((state) => {
                state.aside.createCardAside.approval = approval;
                return state;
            }, false, '[setCreateCardAsideApproval]');
        },
        setIsDownloadReportAsideOpen: (isOpen) => {
            set((state) => {
                state.aside.downloadReportAside.isOpen = isOpen;
                return state;
            }, false, '[setIsDownloadReportAsideOpen]');
        },
        setDownloadReportAsideFilters: (filters) => {
            set((state) => {
                state.aside.downloadReportAside.filters = filters;
                return state;
            }, false, '[setDownloadReportAsideFilters]');
        },
    };
}
