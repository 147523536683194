module.exports = {
"d447f4a1755a0025dddd2f736255879f":"č. transakcí",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minuta",
"ce09c436b49af331a32f1152ede7dd18":"1. Žádost o finanční prostředky",
"d2226752d3065fbad4744924b4a0ee73":"2. Kontrola a schválení",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Kontrola a schválení",
"82bab92f1c8030888550b51521266c53":"3. Platba virtuální platební kartou",
"2ecd62e02e507f8e839a55eac0a04886":"Záznam kódování s ID „{entryId}\" již existuje.",
"5dfd25849740fbce8147838b0d5094eb":"Popisný název, např. Marketingová karta",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Brzy se vám ozve člen našeho týmu úspěchu zákazníka.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Osoba, kterou se nám nepodařilo vyhledat",
"976b50e70e4433e2786233979d243445":"Tým s tímto názvem již existuje.",
"35b61d21ac11d5fc31ebe58541263445":"Přijmout vaši pozvánku na připojení",
"a372cb2d4204654f52cd9170fb10d374":"Účetní kódy",
"110fc66010b6b91cebee7a59a2067aab":"Účetní kódy",
"db862bb04dbdc6d04e47063f33be8865":"Účetní kódy se spravují prostřednictvím seznamů kódování a položek v seznamech.",
"d6e3848e824f077396be4351eb58edd2":"Je vyžadována akce – schválit/zamítnout novou žádost od {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Akce",
"476e728ea824229f52438e543818bcdd":"Akce",
"3117746cb7d4ab815ec98274ec4398d5":"Akce",
"3ab09b66a7f16219d8135977a2a76d10":"Akce",
"ffafa66eaab0409a2e90aaeb43796def":"Aktivní",
"0447e0aa5d00dbdab11b5546793c4479":"Aktivní",
"8ee51d21b05466f7d962d4ff5a0b384e":"Aktivní karty",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Aktivní virtuální karty",
"89c6ec05861aa914304ba35bc6744124":"Aktivní karty",
"5280659bcecaf8344c57790f9962a9a2":"Aktivita",
"8a15368ae286fa64e1cb7624cd410fc6":"Přidat seznam kódování",
"8ded7e09e86be231b1165308329ac76d":"Přidat seznam",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Přidat správce",
"695bb4bc29e2df769ddf7427ab71012a":"Přidat schvalovatele",
"4a1d4516cab00b7c5ecdb150e346e5db":"Přidat schvalovatele",
"56869851b27c2915f5a7fe6a33b91ac7":"Přidat seznam kódování",
"8f8691057c3994aa61f9994202680c87":"Přidat seznam kódování",
"69f7a406b79d19bf8082ed245ab29123":"Přidat seznam kódování",
"48a92ca1cc63018707be99b13196369f":"Přidat správce společnosti",
"a679db9ff80d0e5769ae1ba311c7916f":"Přidat doménu",
"8f5e8078be77146ba7f3be49ef948906":"Přidat záznam",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Přidat zprávu",
"f8a4b14162e19995074e63135b65c84d":"Přidat novou kartu",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Přidat osoby",
"c670549d0b898bb6a571eb2cd7804a6e":"Přidat příjmový doklad",
"eb937098e77d8a6b0565ed72357dcaae":"Přidat příjmový doklad",
"1e154f31c31b63a459f3956e380b3ef9":"Přidat vybrané",
"ba24db9956f156a18b32e653552ff682":"Přidat členy do tohoto týmu",
"52bd93fb5489b25167bb541f7c6504ae":"Přidat zdrojovou kartu",
"21ab3f08eb507dbc76df166eafeb393a":"{fullName} přidán jako schvalovatel!",
"f879c06507d3076bbf52ea4fd2df850e":"Další podrobnosti karty",
"21e934ae30eece4a894337701f497638":"Vše",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Všechny karty",
"8fac71195e2b506e029752e4864b6a5d":"Všichni členové",
"f9708417df16227742e0545a8642eea7":"K této kartě již byli přidáni všichni dostupní uživatelé.",
"cb5abf070f8cba7982205038baab77bb":"Všechny karty",
"dae7d602eec2c84dea0d80ae25e56e6d":"Všechny karty",
"0b4bdfde6578668b6018d3615a1ad30f":"Všechna nákladová střediska",
"97b875b81f54b4432d998a3e47d949cc":"Všechny týmy",
"f0ae83969f7c20a1964caf5156dae966":"Všechny týmy",
"d87455601a00282078582b36a3825b7d":"Všechny týmy",
"24c7b7f55882ea94a525392ca8218fa0":"Všechny týmy",
"8f860e35b64a8f447bded1e5ea66a2a8":"Všechny virtuální karty",
"e403956ecaa6fb33ec1fef5c49d71042":"Všechny virtuální karty",
"4e6f4e8f25f61d40e1eba77de94f6458":"Všechny vaše finanční údaje jsou zašifrovány a uloženy na serverech na bezpečných místech.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Částka",
"a4d099d0dbe3889b0995546e6ed28932":"Částka",
"6c0ef32aae76bc31a61e2124f542bdf1":"Částka",
"adec89c93525f1a8d1c64d856f405e27":"Částka: vysoká až nízká",
"18e632a170203894f40deed1d33ee552":"Částka: nízká až vysoká",
"a7944575cafef9126a499a1b06999fe3":"Došlo k chybě. Opakujte akci později.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Použít",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Možnosti schválení",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Nastavení schválení",
"492eedd88cdd7bb45f39c6594af748c4":"Schvalovací limit",
"437d490afc8d226f8c7d544f4e35d011":"Schvalovací limit ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Schválení",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Schválení",
"17adb7fe866e32055b1469fdb7e82795":"Nastavení schválení",
"7125aa13db437084e1d27d3ff38f22e2":"Schválit",
"84df787ff5746f9dc3cf0146becef4fd":"Schválit žádost",
"7a568654d00245b0bab6c90a1b67d196":"Schválené",
"179c3e187ad8edeb8348cff222b938bf":"Schválené",
"ad058d17725870df9abbd4f5ec217636":"Schváleno ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Schválená částka",
"4d58ba5c0e03fec1556c243be106e4a3":"Schválil:",
"7d3d697fc99f6c4641b326623743f7b7":"Schváleno { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Schvalovatel",
"8c45e443fc6748190719d79a092e2528":"Schvalovatelé mají maximální schvalovací limit",
"4fe56aa6456c108cd288a4ec8acf435f":"Archivovat",
"a9b6f93de9630064be9809130aa1e195":"Jste připraveni převzít kontrolu nad útratou vaší karty?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Určitě chcete zrušit tuto žádost?",
"301bfc2ce058f49e0704fec3d162b5b5":"Určitě chcete tuto žádost znovu přiřadit sobě?",
"2181faf8d9973d1f4138e401a762086e":"Určitě chcete odebrat uživatele ${firstName} ${lastName} z role správce?",
"b982277f29055d0ef6e295fe2006d9e0":"Určitě chcete odebrat uživatele {firstName} z týmu?",
"456c0ccff14acb808583cd9dece857b6":"Přiřazeno uživateli",
"bde315b9cf7fd55f4b65aabbbf38d462":"Pro použití pracovní nepřítomnosti musí být k dispozici minimálně jeden vedoucí nebo vlastník týmu.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Ověření",
"1961ec69dd0c8b5718c831312e7f3d0f":"Automatizovaný sběr potvrzení o přijetí",
"e39f2477cf34d726936a5b5c2b48a4ad":"Automatické vytváření přesných sestav odsouhlasení, které obsahují informace o nákupčích, schvalovatelích, potvrzení o přijetí, kódech účtů a další.",
"434ca07dfde0eaee14f094b7c75a6eae":"Dostupné",
"eb85d8f50f2c89871b794f7d54279b22":"Dostupný zůstatek",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Dostupná měna { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Zpět",
"c8258f8cf9e20f6bbb89d957de40e820":"Zabezpečení bankovního stupně",
"d46c22a3f36dce568c07fcea4368b231":"Základní nastavení",
"fb481c8e517016094b4fdf618c5fa088":"Fakturační adresa",
"79a9f821923c5a04c076902fef684281":"Fakturační adresa",
"877a08b8347052c83aa0b689baea81e7":"Fakturační adresa",
"97f018458e2627fac37d24846c7b8922":"Přikročením k vytvoření účtu a použitím služby Tradeshift Go souhlasíte s našimi",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Přikročením k vytvoření účtu a použitím služby Tradeshift Go souhlasíte s našimi",
"912d1864f0068c764bf4784020795d49":"JMÉNO VLASTNÍKA KARTY",
"d53474c7fd99fbeb69481ce9d358aa09":"Může žádat o karty a mít u všech svých karet peněženku. Každý člen dostane výchozího schvalovatele (správce) pro své žádosti.",
"f30df2cab105ed3f8b4264aeb9800abd":"Může schvalovat žádosti a vytvářet karty pro členy, které spravuje. Správcové mají řídicí panel pro sledování útraty členů, které spravují.",
"03ed9eccc71ef59165292eac542d3050":"Může prohlížet žádosti a aktivitu útraty v týmu. Vlastníci týmů také dostanou řídicí panel pro sledování útraty a týmovou peněženku, kde mohou vidět všechny aktivní karty.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Tohoto uživatele nelze odebrat. V týmu musí být alespoň jeden vlastník týmu nebo vedoucí týmu.",
"a0b70642629a55ea0ab6fa3414d342e0":"Nelze odebrat uživatele s nevyřízenými žádostmi.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Storno",
"5446624d3c364ac05ddf8d6663da2796":"Zrušit kartu",
"53f3815c4fa3340b29efd81e627d1e6b":"Zrušit kartu",
"12257ea2a871b7fab72245b5aafe6424":"Zrušit žádost",
"2aafb7eb4d56a516c4bb0754f0812df1":"Zrušit kartu",
"caae12601b01e2325161e95107f9e332":"Zrušit kartu",
"6ad1e57b1e4c7accbe166c948883077d":"V případě ohrožení zabezpečení jedním klepnutím stornovat",
"f6527e49e6d5c989624507a583e23f39":"Zrušená ({count})",
"1ad3bf5121641dda511f42942af05a90":"Zrušením této karty nebudou stornovány žádné nevyřízené poplatky. Vyhledejte úhradu",
"aebd3826a195afad0a93050a7cc5d73d":"Zrušením této karty nebudou stornovány žádné nevyřízené poplatky. Vyhledejte záznamy o úhradě těchto poplatků na výpisu kreditní karty odeslaném vaším poskytovatelem karty.",
"a5b5136947da562f91b75daf8156f50e":"Podrobnosti karty",
"9ed31ad23a1578ce480d31207decf1d3":"Nastavení karty",
"def15f2e007fc69886230fdb2511305c":"Podrobnosti karty",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Podrobnosti karty",
"67cfd260078ca32027c2fa4a4a2d29f4":"Vlastník karty",
"4458819ea60c68c46e551a8f5cc163cd":"Název karty",
"0858bcbd506fa3240b165e59d2f18aae":"Název karty",
"5212736dd18ee2c31f3650ad2c2c7130":"Název karty byl aktualizován.",
"c96d62fa510c3356dc73a91d1a80e262":"Číslo karty",
"41307fee4c32aefb5383a98fe3d6e45e":"Typ karty",
"3f8045c3358621dad4346a44de0c05a8":"Jméno vlastníka karty",
"879a6b452033cf5f3a4bb3e1837a0386":"Obec",
"593334553ef852024e172505ec3c5f21":"Klikněte sem",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Kódování",
"df931751d5790d74fa46707aaf7a26ae":"Kódování 1",
"7ac7154bc776aff52a71b0d83b860561":"Kódování 2",
"586fcf19136ae5e26795e9055b2e2995":"Kódování 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Kódování 4",
"379bb8e71bb090badebbb0751de86f11":"Kódování 5",
"3e84d13627b3d090ab2b8470af98340a":"Kódování 6",
"33b94b581f3792049992663396e51cb8":"Kódování 7",
"b73fbc32e439655833ee99126dd0e55f":"Kódování 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Seznam kódování",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Záznam kódování",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Záznam kódování byl smazán.",
"cada62a39ee72d790dcdbb25160f8783":"Záznam kódování byl uložen.",
"f645bf50888943ee1384693e56a0f475":"Název seznamu kódování",
"a999182acbcd77c15299f79181b630be":"Seznam kódování byl uložen.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Seznamy kódování",
"d0b96596ce6baec5aabb8a0690ab30e1":"Komentáře",
"ff9cb8842f832d21fb160b3d3784ce79":"Společnost",
"715b59a64e686eb91a21114644ffe39e":"Správce společnosti byl odstraněn",
"8758e9a01253c097a42393fda9610715":"Řídicí panel společnosti",
"e118b41009918b326db6746856831cbc":"ID společnosti",
"4dba88b05b3fa8875128e19e88c512b3":"Nastavení společnosti",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Nastavení společnosti",
"f2ea6d33de8182d97bf2f13354af4893":"Nastavení společnosti {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Správcové společnosti",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Limit pro schválení společnosti",
"7e2f778da14a726b53e05926a3483f2b":"Kreditní karta společnosti",
"7313fd1db3d63858586d1c0f89251d6e":"Název společnosti",
"07721e91dd10ff8831f0d24e47cc1eab":"Název společnosti",
"203221610ca4d347063c037e1a732ae8":"Nastavení společnosti byla uložena.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Potvrdit akci",
"d694cec09cdbc02b1ffe22642a63faa1":"Potvrdit heslo",
"512801312a133f29974f2259a455f39b":"Potvrdit heslo",
"f54c38ed0b9869d929ffc0bd5310131f":"Potvrzení – váš účet byl vytvořen v sekci Tým {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Ohledně vytvoření účetních kódů kontaktujte svého správce společnosti.",
"7a6a4de077398e8e138aec36e3b3d305":"Pokračovat pomocí jednotného přihlášení (SSO)",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Všechna práva vyhrazena.",
"51f0d8e04f6ee626b866b37833dc2b61":"Nákladové středisko",
"e043f60a02f157d0f31a42f0db67cc87":"Nelze pozvat {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Vaši zprávu se nepodařilo odeslat. Zkuste to znovu.",
"ae28083f22a0b2fc5637614bce0976e0":"Vytvořit vlastní seznam",
"738078dc992208e05406f935995dc0c3":"Vytvořit vlastní seznam",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Vytvořit nový seznam",
"0f5783c3921b3d7398d281644bbe5bde":"Vytvořit žádost",
"6d62f934f2304701f88bdcfbc688de7e":"Vytvořit účet",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Vytvořit účet",
"f6b7d0d8531ea268f988005dd2147b2b":"Vytvořit kartu",
"1a083675d1453448e4645403bbe52dd9":"Vytvořit vlastní seznam",
"c10ab11bdfb001da74850ed89c45482f":"Vytvořit novou žádost",
"43a9c06398408bfe77d06ffc2c1c3b87":"Vytvořit novou žádost",
"68bf17fd3f54a89c0a84224e2f9a971c":"Vytvořit nový tým",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Vytvořit tým",
"3b8e665101b9b3657f8a68c5305947b7":"Vytvořené",
"40e91f2907181ffa8e62567b3a407e60":"Vytvořil:",
"8929f7aacb969ef6353aba9903eb362d":"Kreditní karta byla přidána.",
"d86688c26e8ac5eb9facc971b6fe5155":"Měna",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Měna",
"500ded53f64e737a5e4337675ac9b7ce":"Název vlastního seznamu",
"b79a95f04fd829fb2e8d0313e522a94d":"Denně",
"ef445eab224fc599e4103c9b1745a4e0":"Řídicí panel",
"788452c3ad2b1450b6d8d116b863e635":"Řídicí panel",
"fb7add662cf7042355319a975fab6ae3":"Šifrování dat",
"6bded249dbfafd14490eea8dd37e1f51":"Datum",
"46e908a57cb09ef947af8c4a93a09716":"Datum",
"e4213104a55eceb60e00408699438dcd":"Deaktivovat tým",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Deaktivací týmu zrušíte všechny aktivní virtuální karty a k týmu nebude mít přístup žádný člen týmu, správce ani vlastník. Tým můžete znovu aktivovat pouze vy nebo správce společnosti. Jste si jistí?",
"0d0d5677bee2b99884802638e507160a":"Odmítnout",
"b5e3483aae9d36ce1440fe02904d6da1":"Odmítnout žádost",
"00f3db97287cb17ee9c2a131f2141a65":"Odmítnutá",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Odmítnuto ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Výchozí schvalovatel",
"b77589ac3ac2317cfc96d7013658d5c9":"Odstranit ID kódování: { entryId }, položka: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Podrobná prohlášení a výkazy",
"5234dfc4220ccd18a8b62fe84e372248":"Podrobnosti",
"37929896817a6744718004374bb42fd8":"Podrobnosti",
"dd0a6194e8a6ae14f3e1379a1640de15":"Chtěli jste odebrat uživatele {fullName} ({email}) ze společnosti?",
"1a98048b34090da8b199135ccb4c1186":"Jedinečné číslo a kód CVC skryjí podrobnosti skutečné karty",
"3cf132b60fe16b816ca227bbcad60537":"Nestornovat",
"dee57797e14ccf852aa2bc545bafdf6d":"Stáhnout",
"375ae8224d7d35393974775cebe8605a":"Stáhnout seznam uživatelů",
"54b3bfd45a293b441d441aab1a50e644":"Stáhnout sestavu",
"60cf3b94e5df14a504825068928fc118":"Stáhnout sestavu ve formátu CSV",
"7341a4c73c0b688484304c37da4a6d8e":"DATUM VYPRŠENÍ PLATNOSTI",
"f628f8ad44fbd6c70c99fc778407b666":"Můžete snadno vyhledávat historické transakce a prozkoumávat trendy v útratě zaměstnanců za libovolné časové období.",
"94bb409d034ea20697ae09d812e55365":"Upravit vlastní seznam",
"2974e704676cb79666bc236d8658fa97":"Upravit profil",
"9ff205f99ed4002d049f855f965ca6d8":"Upravit uživatele",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-mail",
"0534c00af758d42ebd8b0bd772eab074":"E-mail",
"0c9aa1fbdf925065d25161eab5e51d95":"E-mail",
"ea78fac68766a48b1b0f41305b7e9898":"E-mail",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Zaměstnanec požaduje finanční prostředky z karty společnosti na provedení nákupu. U požadovaných finančních prostředků jsou nastavena omezení útraty.",
"829cff16ce0b13810c914c161b2a9fba":"Povolit alternativní funkce pro získání uživatelů",
"741387c7bd71e2b4a9601af9a6b9490e":"Povolit automatické přeposílání žádostí o schválení",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Povolit výchozí datum vypršení platnosti u virtuální karty",
"bb319710dad93e78cc6f58c615bdf210":"Povolit přidávání e-mailové domény do seznamu povolených u pozvaných uživatelů",
"4700ced24cd278c3149ffa983006035c":"Povolit filtrování podle nákladového střediska",
"3fe06ef8f551c4cb4c0375362e25521d":"Povolit možnost globalizace u uživatele odloženého stranou",
"2787d4bc9752272ac36b499ce7ad93ab":"Povolit vícenásobné použití virtuálních karet",
"6ee44e2280c9ce5009568846cbc3dd14":"Povolit pracovní nepřítomnost",
"1941ca87053df0cebc6091ede92c18ea":"Povolit zobrazení osob pro správce společnosti",
"e50282c2ec7afb948d2c0b428db5d5ff":"Povolit nahrání potvrzení o přijetí u transakcí",
"268694cb2172918fe60f835919bf20ec":"Povolit nahrání potvrzení o přijetí u tabulky vyrovnání",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Povolit vlastní schválení",
"d17c7acf598b569cce8a2bb97e0bb215":"Povolit jediné použití virtuálních karet",
"cd731ca218c9e14caabefafe003cfa6d":"Povolit virtuální karty předplatného",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Ukončit přeposílání ke dni",
"c1f665a814894b17d603b7052b631d90":"Zaregistrovat novou kartu",
"6b6cea93e07e596be70f7b76801e1516":"Zaregistrovat novou kartu",
"7d6ac1ac9cba1620b61908842ebb106b":"Zaregistrovat zdrojovou kartu",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Zaregistrovat zdrojovou kartu",
"21a642b339c3eabf2789c0b05dcf828b":"Zaregistrujte si svou první firemní kartu ve službě GO",
"6efa8dca4fba23a190838746879fd704":"Zadejte číselné ID",
"02c3457577da9dc8dba6e6f9380a248e":"Zadejte jedinečný název týmu",
"40feb3fd1fa529cb599aeac9607664a4":"Zadat nový záznam",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Příklad: sumava.cz",
"97ae5e57984f05f92c25b09f5de40f9b":"Měsíc vypršení platnosti",
"b8bc74aeeb2de3492891c97361de107d":"Rok vypršení platnosti",
"6a0b8d521500014d99dd192d8ce8403d":"Datum vypršení",
"8cf274169a732340430fe7cc5236e0e7":"Velikost souboru by měla být menší než {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Jméno",
"7458b55e967e51791d98e77458631e44":"Jméno",
"3bbe52e71bc5c212cea77983f1a5b748":"Jméno",
"9b49c88fa20cc006baaac15c857c8781":"Pro jednotlivé nebo vícenásobné nákupy",
"bb7a2907073bd760f4e82097132637b1":"Zapomenuté heslo",
"26bdfaa592f3629273f13c22435b323d":"Zapomněli jste heslo?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Přeposlat",
"3f9fe6adc14ce5776097170653a24462":"Přeposlat žádost",
"b774e35683f5737c36bf0cf3d6785973":"Přeposlat žádost",
"628faa5054a912b1e470414f77057e6b":"Přeposlat žádost",
"852b4065e1837fdc77940b6411a6d902":"Ochrana proti podvodu",
"616551bd10d95aca439401d6a7769d96":"Přepravné",
"a65a230918e9c11e1ef8824745e26a3d":"Začínáme",
"7d4c4466b7716347ce3673acaa1983f7":"Víc můžete získat v aplikaci Go",
"11415a07658cfef80eb166896d77ad98":"Připravte se na ještě více přehledů výdajů. Aplikace Tradeshift Go je nyní lépe sladěná se způsobem, jakým rády pracují obchodní týmy.",
"ac0ff5d14286ef74614a9c6021f08f69":"Můžete získat přehledy, sledování výdajů a rozpočtu v reálném čase a podrobné přehledy veškerých nákupních aktivit.",
"db9071ab833054307252a936e7f60e1b":"Začínáme",
"24dc4bb00841b004031fca3a9bb9bf51":"Žádost o kartu Go zrušena uživatelem {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Služba Go připomíná zaměstnancům veškeré chybějící potvrzení o příjmu, která lze jednoduše přiložit k odpovědi na oznámení, nebo v případě obdržení e-mailu jeho přeposláním do služby Go.",
"a73603f0bc610ecea9ef9c793b802538":"Služba Go spolupracuje se sítí vaší kreditní karty na zaručení stejných standardů prevence podvodu, jaké byste očekávali při spolupráci s těmi nejlepšími finančními institucemi.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Dobré zprávy! Váš požadavek na nákup v aplikaci Tradeshift Go byl schválen",
"ee397c9cb0668cc9cba62e1c1e619615":"Máte nějaké otázky nebo zpětnou vazbu? Spojte se s námi zde:",
"9df1d3de68d663b848101df3c7b58e39":"Jak to funguje",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Jsem si jist(a)",
"84074732eb8d1b4094404cd750baf949":"Není-li uvedena žádná hodnota, platí limit společnosti",
"138e8bd6102a7eea70a123ff03b1cd79":"Není-li uvedena žádná hodnota, platí limit týmu",
"e5756af4d7e5ad1236ccfbb858428421":"Pokud jste se neregistrovali do služby Tradeshift Go pomocí této e-mailové adresy",
"4f004f75f47dbfecdb6d09990596e530":"Pokud ještě nemáte zaregistrovanou e-mailovou adresu, zašleme vám aktivační e-mail s podrobnými pokyny k její registraci.",
"123837f267de602fb05361b3b1197c1e":"Pokud uživatel {username} spároval existující účet, zašleme vám e-mail s podrobnými pokyny, jak pokračovat. Jestliže do pár minut neuvidíte tento e-mail ve své příchozí poště, zkontrolujte i složku se spamem.",
"45efd96cd93525a9c17222217a37d324":"Obrázek podporovaných formátů souboru PDF",
"fa6ae823f9ab8bf91c246efe2517956c":"Svá nastavení účtu QuickBooks, NetSuite nebo Xero si můžete naimportovat přímo do služby Go. Poslední transakce můžete jediným klepnutím exportovat do svého účetního řešení.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Neaktivní",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Vstup obsahuje neplatný znak.",
"62c7be09da43f8f96a60ef3967eddc2c":"Představujeme vám zcela novou službu Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Neplatná e-mailová doména",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Pozvat lidi",
"3809b41b14c55c3fcf54cd813d8e21f5":"Pozvat do týmu",
"fc90a11805961b0d7b5a5545e4d9bc05":"Pozvat uživatele",
"6f7f4acde92d267417f5d8a60d32400a":"Pozvat další osoby",
"09ecd2ca15bb5c38dc10f653118346cd":"Pozvat nového uživatele-správce",
"3f576ce504f53f972442a63a170c5418":"Pozvat nové {role}",
"0215e052437ceea4e5cd562431b34ced":"Pozvat osoby",
"8aaf2c77df644b7e8524120f66da7d5f":"Fakturovaná částka",
"1b7dc8290f1205f092cc9133e0cde385":"Datum faktury",
"1a6fb210308df8e0135e49e76aaff31e":"Číslo faktury",
"613da3d0cd586ef0360e0d93eace9508":"Vydáno dne",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Trvá",
"3322b671105c65511fbab1966b7fdcf6":"Jazyk",
"b1d946192795a58aea6a61f4f923acb7":"Poslední 4 číslice",
"b3c6b8db4ef6dcdcb451303e65548816":"Poslední 4 číslice",
"0382123b9c847000fceff2484c665e39":"Poslední čtyři číslice",
"19f7c0055e150e9e3e66e85c2993799c":"Příjmení",
"662c84bede8a7bf91ab15bcb73b48b49":"Příjmení",
"cff8fbef5aac2523344b9535bd56973c":"Příjmení",
"c3510b2184a92ff12e07a2d1b9a461e6":"Můžete zaměstnancům umožnit přístup k vaší firemní kartě bez zveřejnění podrobností karty, vše během vynucování rozpočtů a sledování jednotlivých nákupů.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Limit lze přepsat na úrovni týmu a jednotlivého schvalovatele",
"9bf5d56627e500cad9f7ad21c3e7966d":"Omezit na určité kategorie výdajů",
"e80a6d4232e52f6bfabee02e17403b59":"Propojit jednu z těchto zdrojových karet s tímto týmem nebo zaregistrovat novou.",
"803570ab9f0fba5ff35ae2749200501f":"Načítají se karty…",
"f5d8fe188045fb84a41f8567ee3b2d88":"Přihlásit se",
"ad96c1ca962a883652dadb845f439415":"Přihlášení",
"072ca67e735adbed54acf41321370701":"Přihlášení",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Přihlášení",
"b246fe8836c906f1b56342378ca88895":"Můžete vytvářet, schvalovat a sledovat nákupy v týmech, které odrážejí vaši organizační strukturu.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Spravovat vyrovnání",
"95d672ab621e531ae1a69798e0e4b56c":"Máte možnost správy všech vašich virtuálních karet na jednom místě.",
"96f2e88a167f476413b35aa711709e6e":"Správa úhrad",
"151d8e6ceec242d482fff60da8311449":"Správce",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Vedoucí",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Správce obdrží každou žádost ke kontrole a schválení. Upravit můžete i omezení útraty.",
"4f449985e83c1f9ca2e8f82b0707a371":"Správce obdrží každou žádost ke kontrole a schválení. Upravit můžete i omezení útraty.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Správci",
"27f8111a955975ea6fd04f748d24cb4a":"Maximální schvalovací limit",
"5b1d9cfb78c55e75b47c62435d9299ca":"Člen",
"41e307c1874e2e82296b226e9bae4a5f":"Člen",
"6f9612aa1582373c2c1ba4e144132100":"Členové",
"590429a0fcc42af85648d0fd70601dcc":"Členové",
"3f50e62c8488d83ad84f69ebda853897":"Členové",
"e0fc0acda5b74b93e797756dbbba67bc":"Členové",
"c7ef9eda38b7eaee1f20031387a8bde7":"Měsíc",
"40dd08b507752bef4e3c4b36838317d5":"Měsíčně",
"d712b91f7498f212047aad4c6d0f8088":"Nejnovější",
"12110d95b3d7a495afa4f28ac190f63e":"Nejnovější",
"2f11ce3865f79ee7e5ee64d728082264":"Nejnovější",
"eadc93865655cffc6a9863fdda5693e1":"Musí mít délku minimálně 10 znaků a obsahovat velká i malá písmena.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Musí mít délku minimálně 10 znaků a obsahovat velká i malá písmena.",
"50d0a69aa59df5201018771a06ea12e7":"Musí být jedinečné",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Musí být jedinečné.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Moje karty",
"c36602f50d35c2816f0ba8a196b70876":"Moje žádosti",
"ff6e372aa3eb685a39a701963db35b2f":"Moje žádosti",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Jméno",
"1c7514b561a2591a914225813d30d642":"Jméno",
"d621826c1ceade262589af04e655e824":"Bylo vyžádáno nové heslo",
"35dc3304b61a9e69dc3a393123658837":"Nové žádosti budou automaticky přeposlány v tomto časovém období.",
"e788124801b4727d121f60d059a2a4a7":"Další",
"eca804f1d53dfd984ffd93e2d00fc284":"Není přiřazen žádný schvalovatel. Kontaktujte svého správce programu",
"abdf7e93f8509399a63108a2b1a25546":"Žádná aktivita k zobrazení",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Nemáte v peněžence žádné karty",
"87127e1e9f5bf922d0a4f948b68697c8":"Žádný limit",
"40512efa6b75ef0c7924b1ee3070fca9":"Žádní členové k zobrazení",
"dcda493afcb2415d5ca49a17d1af6f50":"Zatím nebyla publikována žádná vyrovnání.",
"f0efb26e623bb990532e375d5c8875ec":"Není k dispozici",
"bc0cf602d1978e814070bb552e576a32":"Nezmapováno",
"09e126c317429ccb52ad323c32293648":"Počet úhrad",
"75b00b969b9d3b520929f1e652637b70":"Počet úhrad",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Počet úhrad",
"bd44ba180aa356c5755fdedd32adf03c":"Ale ne! Při vytváření žádosti se vyskytl problém. Zkuste to znovu.",
"c396b3728dd7393aa9e5427990c132a7":"Ale ne! Při stahování sestavy se vyskytl problém.",
"42c07764b07dea1ee7de7605721c3f5f":"Ale ne! Nový tým se nepodařilo vytvořit.",
"e3a385878ec94bac12a99490d0a8e85a":"Ale ne! Váš účet se nepodařilo vytvořit.",
"17f2dae79677017e2c2e879578c4e09a":"Jejda! Něco se pokazilo. Zkuste to znovu.",
"78c135de545c1663c9768226492a44a3":"Nebo použijte jednotné přihlášení (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Pracovní nepřítomnost",
"5d7e45f169e2f6968dc58f43b5267353":"Pracovní nepřítomnost",
"ee589a20c262527db970671fb2c4f6bc":"Pracovní nepřítomnost",
"4fd0c753fce49a016b98d988a3895223":"Přehled",
"2789c5d5c9c042858f45aadc4e0de058":"Přehled",
"083b7090b66ebd0307a18499ac7b676e":"Stránka neexistuje",
"a21d205920893cf579a7655a1430fe30":"Nadřazená karta",
"fd35b7ce938e404fdb65de3bb749c528":"Nadřazená karta pro sestavu",
"e8c7d2eea7db66ecb44751091a582304":"Heslo",
"bb79e316e00fd21138750b6986e44455":"Heslo",
"6cae2279088580976d84119d95097cae":"Hesla se neshodují",
"4baec0159d3074f84c0eebcc09ba4cd8":"Hesla se neshodují",
"b676587115243921602742cb8f696a6a":"Pozastavit vytváření karty",
"db4a26c871ef0b970b74e1f71763098b":"Pozastavit vydávání nových virtuálních karet",
"33fc1e6d01b785e3a815c28ef7d56667":"Nevyřízené",
"6ea62e4811b68df48b467e8d367aac2f":"Nevyřízené žádosti ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Lidé",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Členové týmu mohou mít 3 různé role",
"e2d34cb1409b9778d34c4b74ecee1df6":"Zadejte kladné celé číslo nebo ponechte prázdné.",
"8da247d5d736c87122404eff1e5c0c73":"Zadejte platný limit.",
"65e154267ac283d963cbc7be832969d9":"Zadejte platnou hodnotu",
"28b4450d417065a69a395f567967d46c":"Předvolba s požadovanými omezeními útraty",
"7bbf151d8493170f206a23c2ecc6acd0":"Zásady ochrany osobních údajů",
"51602962f2d1e7515943b4a6c66562ce":"Profilová fotografie",
"a51a17940c70a3123da4d68bed562c40":"Profil byl uložen",
"4d398249d01ab72d697a7e219493c637":"Poskytovatel",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Mapování poskytovatele",
"89b1deb2ffe12a13941c362abaeab01b":"Publikované",
"d8d806d48052159e3ff3b224f3f53586":"Účel",
"089917619c78af2efb30be0bb7ce076c":"Znovu aktivovat tým",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Důvod",
"e8484c78fda9f453eeb942ba4945f040":"Změnit přiřazení",
"ff2fbc1172ba26776ad0982624cd2713":"Potvrzení o přijetí",
"588e690db177ee0c7a7cadb39cbd5efc":"Obnovit finanční prostředky každý týden, měsíc, rok",
"32955937daca05b2084556f248692baa":"Registrovat",
"f15bb755ba24abe6ab106637292175ec":"Zbývající",
"579782461133a8b8cc6bd77798961d82":"Odebrat kartu",
"e475edc165372f8c8deb532aa85d9950":"Odstranit seznam",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Odstranit seznam",
"f9769561315b964d0b3b4887ec208977":"Odebrat z týmu",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Odebrat potvrzení o přijetí",
"ee181cef581fc8e8217e66b5762db898":"Odstraněním seznamu dojde k jeho odstranění z používání a ke smazání všech stávajících záznamů. Chcete-li seznam dočasně odstranit, ale zachovat jeho záznamy, můžete vypnout nastavení publikování. Určitě chcete odstranit tento seznam?",
"feea41d0bc1d577a07c8b6e1297abbde":"Odebráním zdrojové karty z týmu nezakážete stávající virtuální karty.",
"d09483824a2e0d6194a4c3f32311e752":"Odstraněním zdrojové karty z týmu nedojde k zakázání stávajících virtuálních karet. Určitě chcete odebrat tuto kartu z tohoto týmu?",
"7ca4b1bf1346e179a29da97f260a93a4":"Přejmenovat",
"db195ec9c818b8e92da142374e20d1bc":"Přejmenovat seznam kódování",
"2fd93e229fe9ea754808805d3fef9c13":"Přejmenovat seznam kódování",
"aa1e74d32805823f802a11b33c60c38b":"Typ sestavy",
"6ac78d97a8cb7f060e43e596e3e23535":"Žádost byla zrušena.",
"25deccb1980291a0fa9997109c731440":"Vyžádat si ukázku",
"9f2754d87686903b1500ce8e8a55b377":"Vyžádat si ukázku",
"92ac924f72679f897e26f3e615be8c22":"Žádost byla přeposlána.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Vyžádat si informace",
"270185007853cd779011ae0e8433f723":"Žádost byla znovu přiřazena.",
"e5a059c9ba2691910321339cce989f9d":"Žádost byla odeslána.",
"77c88a231edfa7075114d91d2397e42d":"Název žádosti",
"cee91b833c4358acf0c554f746557e71":"Žádost byla odmítnuta.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Žádost byla odmítnuta.",
"9e56abbbf87420eeedd625ce786515c7":"Žadatel",
"b4cb4426e44fee9580af71912a764536":"Žádosti",
"df309e888c29fc447d0309329b39ce93":"Obnovit heslo",
"5daf278da19ecbaeb7b96554cb4db10f":"Zkuste nyní připojení znovu",
"ec78345a5975bc0f8efaee5256b43c2c":"Uložit",
"2cecea57b47d8f4a45c706287ff7d64e":"Uložit změny",
"15774be5c338a39169917c7d32781dd0":"Uložit a pokračovat",
"844095b4b8f580737b8717672501048e":"Řekněte něco…",
"7e66f676c2e7b04514aca3a0935530f0":"Hledat podle informací o kódování",
"5293ce9335ec2d176a6e838bf76404c6":"Hledat podle popisu",
"efab3b0c95f263cdd0a8466329a387eb":"Hledat podle popisu",
"d227b38dcbc4548c092b0836a7101506":"Hledat podle jména obchodníka nebo ID transakce",
"3629383abf1d5f85efd759f718159b40":"Hledat podle názvu",
"a034fcd6719228b28a6fc22d3a46ea8a":"Hledat podle názvu týmu",
"8ac7e0d63f343b548482d5f367f5b3d9":"Hledat podle názvu týmu",
"858323eb52cb45446597ee46330a0540":"Hledat podle vlastníka virtuální karty",
"111bfcbff671e13de117ed5195fb6773":"Vybrat",
"bcfecd59eb93e5314873d84ddf35aa58":"Vybrat",
"909a4414ea573218bff2d533eaab46ea":"Vybrat datum ukončení",
"114b206ca1ff1c0bc52abef342059b2a":"Vybrat možnosti týmu",
"81a02e424615fb41a20b8d0f13441c88":"Vybrat týmy",
"b135b36495618e6692e7fbaf0a0aa83e":"Vybrat měsíc",
"677e90855e164c00a28b5c4763ac67e0":"Vyberte roli",
"d01570f3774738395b8ba3c64ce54c76":"Vybrat stav",
"7dee3f06c70626881efb0cd6af6b63e1":"Vybrat uživatele, jemuž bude žádost přeposlána",
"4c7bb810835a1540ddc1f6501da5a51b":"Vybrat rok",
"3ab198d2678af2b5b32180e4b39602ba":"Vybrat schvalovatele",
"f502ba79f2bd010c5e77891812400958":"Vybrat kartu",
"936027182d446e27ba209ea921ef7502":"Vybrat výchozí období vypršení platnosti",
"b22c893254ccf3bd34faaee511cf7ca8":"Vyberte dobu trvání",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Proveďte výběr z předvoleb seznamu kódování nebo si vytvořte svůj vlastní nový seznam.",
"42cce5fa376b45bf290f86c684d69d96":"Vybrat pole poskytovatele",
"b761773d46699b60e8d6874b85593374":"Vyberte týmy",
"3a9396756942ede98d0cb6afc1032b15":"Vyberte jejich schvalovatele",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Já",
"03e9d2c128af288126d94f631c5396be":"Zaslat pokyny",
"299a7e961804e2d649f06b5dc4362c29":"Zaslat pozvánku",
"155e7b3f7db7bf10b03b5f41de54eae1":"Zaslat zprávu",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Nastavit datum vypršení platnosti",
"f89ec96a4b5bb639488ab742efa76a6a":"Nastavit nové heslo",
"7d5377ad70d57728a45d3c0639de5544":"Nastavení byla uložena",
"14313f11dee735f2144953e720776c28":"Částka úhrady se může lišit, pokud je na nákup aplikováno spropitné nebo nějaký poplatek",
"199000fe0cda0adaaa9b864aa7447cb9":"Úhrady",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Úhrady",
"6e6ac6ff3bd722e37874fb1ea7115818":"Nastavte si heslo",
"9b4ecf052980e80bf5c65a415d470a43":"Sdílet a ovládat přístup k vaší firemní kartě",
"ba746d78772d6be26cc508ba989becb0":"Měla by být menší nebo rovna {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Zobrazit transakce bez potvrzení o přijetí",
"7d474b60aeb7cc25b519e806fca71e12":"Přihlaste se pomocí vašich stávajících \n\n přihlašovacích údajů ke službě Tradeshift",
"16080cd4e8319ef3e7de21b6c19d1437":"Něco není v pořádku",
"8f358a370224baf849f9652810c68db2":"Něco se pokazilo",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Něco se pokazilo",
"99af87c475a4d40ef732deed96f11ed2":"Něco se pokazilo. Zkuste to znovu.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Omlouváme se! V současné chvíli máme potíže s připojením – za {retryConnectionPeriod} sekund to zkusíme znovu.",
"84debc8e41646cd49ac2b14708ca6d73":"Omlouváme se, při odstraňování záznamu kódování se vyskytl problém. Zkuste to znovu.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Omlouváme se, při ukládání záznamu kódování se vyskytl problém. Zkuste to znovu.",
"689d3418ee8f996ad25e152e80de63fe":"Omlouváme se,  tuto chvíli vás nemůžeme odhlásit.",
"b48efd4521ecd6428987d24636a4349f":"Třídit",
"776a95003d3ec1dd58af69aedbc08579":"Třídit",
"1b783238028c0ae085ca69ed823f4d1a":"Třídit podle",
"be70d19bf13c935d653b2c4fe7d36224":"Název zdrojové karty",
"564f8c942e7d1bdda5251545612fa348":"Název zdrojové karty",
"aa284602c746aad4a6de6366c24221b8":"Zdrojové karty",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Zdrojové karty",
"389d471e79a43440b3278b0fc7187d9e":"Zdrojové karty",
"106342f689b57145817b00d6fe383f91":"Konkrétní datum ukončení",
"2a5e19387ff300509876e3bae99b7ab9":"Zrychlete své účetnictví",
"ca34d586bbdb7f368f1009305bccbe76":"Řídicí panel výdajů",
"4583120998b44c0a5982cf85dd938d2f":"Útrata v průběhu času",
"244d724806247953233a0bbfcbf8da4c":"Limit výdajů",
"d39b83dd151c8df6370460a458294ccf":"Utraceno",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Utracená částka",
"5de2859528e030ac1f2af115ebdf209c":"Utraceno { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Zahájit přeposílání ke dni",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Stav",
"b089930f1f6ffac09438cc19590f8f6b":"Stav",
"f174d20592156eb4b10a7cf1144c973e":"Stav",
"ecf342482d1b6ab932c2000cd7dc38c4":"Stav",
"1f1fe111548158ec842761eca7425ff3":"Adresa",
"31a0a748b1097a9e0aa95a811802bdd0":"Bezproblémové odsouhlasení",
"111b782f3f0794c2294aefb6693b2c50":"Odeslat",
"013e2555920b51ad2a3d2c3dc482c471":"Odeslat žádost",
"1270cd8eb69ad64cc71e5de979423b3b":"Předplatné končí",
"2802336ad3d8ff658257b88a93f4e50a":"Předplatné končí",
"b28dd68454d7acbefafeefb42981fe16":"Četnost odběrů",
"907793bc80cf94d560e5f1a0250fdaee":"Četnost odběrů",
"29229e153308e4747edef1144de35a55":"Výborně!",
"503eb46b80f374f39266e7a9aea8e63e":"Výborně!",
"4f20e29cadadb9fbc7455bd4d80de567":"Výborně!",
"601d04f10d558f436c78c10e90b44175":"Podpora",
"62558c185ea2c06c5472d1cd534f6e56":"Synchronizovat s vaším účetním řešením",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Výše daně",
"0cb5d74f7a56fca30930616feb6e2f47":"Tým",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Tým",
"bf23b2fd889e3b12e0f0acf99f913eca":"Karty týmu",
"b90a650376782a9e43ed6be6135bd6f2":"Řídicí panel týmu",
"6adbec395fe1c4bf776f9fe5fa021052":"Řídicí panel týmu",
"6f2695eeb68501dbcfddbb4d265b846b":"Správci týmu",
"3b779ade9c960ac95a670db0cc12e56b":"Členové týmu",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Nastavení týmu",
"dc5235e1b88320d3ba7501e4c30985a3":"Nastavení týmu",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Nastavení týmu",
"a2eb92bfd7502f47b3cd170bc68ac638":"Nastavení týmu",
"f1ec84f051f19f0d0e301a709b13dc45":"Výdaje týmu",
"fbacde3a262ea8696e30135e8f987a44":"Přepínání týmů",
"74b9578ea5a620f5fef102188e42c02f":"Přepínání týmů",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Akce týmu",
"3aa978fa7ef886621285b93257d428c3":"Limit pro schválení týmu",
"a41f9df131c221a99926de7e2138653a":"Informace o týmu",
"941c7da8f256f91ba3819f991315a100":"Název týmu",
"c5d04e10cc2f0b53c2bda893eae09178":"Název týmu",
"504a8ba642ba37c9a743d0fabb2bd371":"Název týmu",
"e768a8e1e3d6a6bf470587dad75423fe":"Vlastník týmu",
"e2d824067f8bd871756688c58c382196":"Vlastníci týmu",
"dca232ccc849c5ff5611255eb82baf53":"Vlastníci týmu",
"3b61bd586225790cf132591383d15448":"Přepínání týmů",
"bc05b40509f0271c12cfaaef8e5319b1":"Týmy",
"39e73773d07a800673ec6600422952d6":"Týmy",
"daabb564bb3dcfb41de69492f854d573":"Týmy",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Týmy ani jednotlivé limity nikdy nepřekročí limit společnosti",
"4b4fe189a48d8eb06d836afa8fb6317a":"Týmy byly aktualizovány.",
"2a17f9a8c610db29fec63fd530c29fdc":"Týmy používající kartu",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Týmy, které toto mohou vidět",
"317868f3804ba4ed5d99dd9919c798ba":"Podmínky poskytování služby",
"e8c9a9847db2ccd97524aa06f1af9d82":"Podmínky poskytování služby a Zásady ochrany osobních údajů",
"38852078519c5c46715d8a034fc4901b":"Podmínky poskytování služby a Zásady ochrany osobních údajů.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Děkujeme za váš zájem o službu Tradeshift Go. Již brzy bude k dispozici podpora kreditních karet {cardLabel}. Jakmile bude k dispozici, člen našeho týmu úspěchu zákazníka vám dá ihned vědět.",
"f7047b35a069d0790a0a348a29c846a2":"Děkujeme za připojení k vašemu týmu ve společnosti {companyName} za účelem nakupování pomocí virtuálních karet Go.",
"133b0a70b1a956889164621380c378f9":"Akci, kterou se pokoušíte provést, nelze dokončit. V některých případech postačí zkusit to znovu.",
"18f5a1872e156f1aee84ae321780d952":"Částka na žádosti přesáhla limit pro žádosti společnosti ve výši ${requestLimit}. Zkuste to znovu s nižší částkou na žádosti.",
"867e3d4d3c78990c6852fc5348706ab2":"Vyžádaná částka přesáhla limit žádosti poskytovatele ve výši ${providerLimit}. Zkuste to prosím znovu s požadavkem na nižší částku.",
"570f5f4658b03dda7a910f56c86a3bd3":"Karta pro tuto žádost byla zrušena.",
"3075c56105863546e3368ffead7a146b":"Karta byla zrušena.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Seznam kódování, jehož publikování se pokoušíte zrušit, je propojen s integrací. Pokud jste si jisti, že chcete tento seznam odebrat z integrace, kontaktujte prosím podporu, která může tuto akci dokončit.",
"756ade8d71ec487a4b48d7c4277ea239":"Seznam kódování, jehož publikování se pokoušíte zrušit, je propojen s integrací. Před zrušením publikování tohoto seznamu kódování je třeba nejprve odstranit mapování.",
"4f40335f7c018986c95458183bc0c136":"Odkaz na aktivaci vašeho účtu je neplatný nebo vypršela jeho platnost. Obraťte se na osobu, která vás pozvala, a požádejte ji o zaslání nového pozvání.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Odkaz na obnovu vašeho hesla je neplatný nebo vypršela jeho platnost. Požádejte o obnovu hesla znovu a získejte nový odkaz.",
"ded30f0e288c12125ca1a77f8d8fd600":"Důvod bude viditelný schvalovateli a žadateli",
"f294897b38fc23dd8f5de52b54ceae22":"Žádost bude odmítnuta a žadatel o tom bude informován. Chcete pokračovat?",
"e8c7200dc985df0a94a9d996968b4cc0":"Žádost bude odmítnuta a žadatel o tom bude informován. Chcete pokračovat?",
"5f3d196a2ade3f97a2a48b773fec5861":"Žádost bude odmítnuta a žadatel o tom bude informován. Chcete pokračovat?",
"b04c191cd6b4f27669a4f390311f156c":"K výběru nejsou žádní vlastníci ani správci týmu.",
"e25045557efbad7b52997b94a1fe6817":"Pro toto časové období nejsou žádné {type}",
"7260690577c795e999acae63bb7c51ae":"Při ukládání nastavení týmu se vyskytl problém. Zkuste to znovu.",
"f2103024f2a9c6c0335a71c60567d6c9":"Při načítání vaší peněženky došlo k chybě.",
"9776cb7eab9fa0d318a8144cd17de745":"Při načítání vaší peněženky došlo k chybě.",
"77d28a14bc900be4360b570a82eb75a9":"Nejsou žádné údaje k zobrazení",
"2b6feba67722337f88c1443909fe446f":"Při přidávání schvalovatele se vyskytl problém!",
"24b7212afb97c5d23faafa2ca4559095":"Při přidávání kreditní karty se vyskytl problém. Zkuste to znovu.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Při přidávání zdrojové karty k týmu se vyskytl problém. Zkuste to znovu",
"5a8e1b922d1cee746ac6794599b10709":"Při stornování této karty se vyskytl problém. Zkuste to znovu.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Při stornování této karty se vyskytl problém. Zkuste to znovu.",
"22361c5d4df61ad7ae9792a3baf5729f":"Při vytváření vašeho seznamu se vyskytl problém. Zkuste to znovu",
"098755bfddeaf0428c7feb6b69af9b6f":"Při odmítání žádosti se vyskytl problém. Zkuste to znovu.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Při odmítání žádosti se vyskytl problém. Zkuste to znovu.",
"7d461b6b7b54dbfa9c83243217cd1053":"Při odstraňování záznamu kódování se vyskytl problém. Zkuste to znovu.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Při odstraňování vašeho seznamu se vyskytl problém. Zkuste to znovu.",
"beb43d7800cfff47e08cd61842d953bd":"Při přeposílání žádosti se vyskytl problém. Zkuste to znovu.",
"f7775350f35b8365fb9803232d9b792c":"Při získávání podrobností o kartě se vyskytl problém. Zkuste to znovu.",
"95abaa922c6c1648ca9c39ac4776896f":"Při získávání podrobností o uživateli se vyskytl problém. Zkuste to znovu.",
"9d7e4ead60137041ae8a845aa6cf9979":"Při načítání uživatelského profilu se vyskytl problém. Zkuste to znovu.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Pi načítání uživatelů tohoto týmu se vyskytl problém. Zkuste to znovu.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Při načítání vašich nastavení se vyskytl problém. Zkuste to znovu.",
"b2f48028c400befad5f1d57f769ef7cd":"Při opětovném přiřazování žádosti se vyskytl problém. Zkuste to znovu.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Při odstraňování zdrojové karty z týmu se vyskytl problém. Zkuste to znovu.",
"19dfcd761006496c1d20351c04643e45":"Při odstraňování této domény se vyskytl problém. Zkuste to znovu.",
"149651b5784481c68aaf4ac27bb1f907":"Při ukládání seznamu kódování se vyskytl problém. Zkuste to znovu.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Při ukládání profilu se vyskytl problém. Zkuste to znovu.",
"749ddc235744b6b371668ec0bb049176":"Při ukládání uživatele se vyskytl problém. Zkuste to znovu.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Při ukládání této domény se vyskytl problém. Zkuste to znovu.",
"04cf86909808f430b8b1904fb422e0d3":"Při ukládání vašich nastavení volna se vyskytl problém. Zkuste to znovu.",
"2eaf6ee97d984598c95e0494d72aa492":"Při odesílání požadavku na nákup se vyskytl problém.",
"148f847d3beccaa67fcd5096ae3602d3":"Při aktualizaci názvu karty se vyskytl problém. Zkuste to znovu.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Při aktualizaci názvu karty se vyskytl problém. Zkuste to znovu.",
"de8cc976f7af9edf189904239e6240da":"Při aktualizaci týmů se vyskytl problém. Zkuste to znovu.",
"5fd644b864eecc535bd42cae11543906":"Při operaci {operation} se seznamem se vyskytl problém. Zkuste to prosím znovu.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Při přidávání správce došlo k chybě. Zkuste to znovu.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Při deaktivaci vašeho týmu se vyskytla chyba. Zkuste to prosím znovu.",
"3f686a97a1b9fcdd73a40d2005693105":"Vyskytla se chyba. Zkuste to znovu.",
"2f38263789163cf8ee30c8ae88852c82":"Při publikování vašeho seznamu došlo k chybě.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Při odebírání správce došlo k chybě. Zkuste to znovu.",
"007a647254e1f85b6c1e789004e05df5":"Při ukládání nastavení došlo k chybě",
"c3072d080b587d70f85d68ec089f028f":"Při ukládání nastavení pro váš tým došlo k chybě. Zkuste to znovu.",
"608cd0e109c3e51b0425df8921e4181d":"Při nastavování mapování poskytovatele došlo k chybě. Zkuste to znovu.",
"177cefc68dd318ea75673326e77d8206":"Při rušení publikování vašeho seznamu došlo k chybě.",
"255d22dc4f0e338a4cf53d5b7256e695":"Při načítání jména osoby, které přeposíláte žádosti o schválení, se vyskytl problém.",
"39ac4ec73fed8a981ad1857d989edfcb":"Tato částka překračuje limit pro schválení společnosti.",
"494ce3f598cbd97afe99774b5975a4bc":"Tato částka překračuje limit pro schválení týmu.",
"f2a42214e7c480c5596439becfd0b7bf":"Toto pole je povinné.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Toto nastavení ovlivní pouze nové karty",
"881b7596cf985d9c246b05fcab930632":"Tato zdrojová karta byla přidána k týmu.",
"a1b1aa354d06e7815b78c6abb7448e84":"Tato zdrojová karta byla odebrána z týmu.",
"b348b24fc4839ee15ed0aa424689bae3":"Tento tým musí mít správce nebo vedoucího, abyste mohli pozvat nějakého člena.",
"188b9ed447a43916f9e323623ea3a993":"Přijmout pozvání",
"969de95e19a06f5ae7cec6449cbb6a27":"Celkem",
"30286e57da108ee7dbc0f8fbb62d4fff":"Celkové výdaje ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Můžete sledovat výdaje napříč týmy, projekty a předplatnými.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Můžete sledovat aktivitu aktivních karet vašeho týmu, pozastavovat výdaje nebo deaktivovat karty, které již tým nepotřebuje.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Obnova hesla ke službě Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Zkusit znovu",
"80196bc616d4a44a4997a2ea127f7b83":"Zkusit znovu",
"f96bbe81618b5f4a1807989eb4f69e24":"Zkusit znovu",
"d498d7d261b7ca56e07218d1a0dbdd49":"Ale ne! Vyskytla se chyba. Zkuste to znovu.",
"ce2e475291003852c71b01959698c496":"Zprávy k žádosti {title} se nepodařilo získat. Zkuste to znovu.",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Vyjmout z archivu",
"d2e50be5287fd8fb436d0acbeb811864":"Jedinečný identifikátor",
"0e2fbce967f4a209215b6f48f8eddac3":"Obnovit vytváření karty",
"f2315dec5dd07802b77af3a3a5414a7d":"Až 4 roky do budoucnosti.",
"d2f30a2f0c955271e9af123dceca501a":"Až {label} do budoucnosti",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Aktualizovat zdrojovou kartu",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Aktualizovat zdrojovou kartu",
"c8f6bc61a8982158e408194e48a274d9":"Nahrání se nezdařilo. Zkuste to znovu.",
"8a86a60cb836bdb5225cad20c5151078":"Nahrát potvrzení o přijetí",
"e448a1ce42de0cf526200331b4ad8b23":"Uživatel",
"75edcc8fc27f41c99e28b48ff250c7f8":"Údaje o uživateli",
"d1651c2afdb6c92621adbb707e4753d0":"Uživatel byl uložen.",
"5fa55883b7413526b441e999cb8089b3":"Uživatelské jméno",
"7a892b81fe2aae295f1b2d38bb67360f":"Uživatelské jméno",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Uživatele s aktivními kartami nelze odebrat. Chcete-li tohoto uživatele odebrat, zrušte karty.",
"d40fa5935f1614925cf46259c886814a":"Zobrazit aktivitu karty",
"625419857efc7e72d162c9f8204109df":"Zobrazit kartu v peněžence",
"85a5e817826415651ed4991a76bb5385":"Zobrazit konverzaci",
"09fc124dc9b8b14d6227a92bb0a4566f":"Zobrazit konverzaci",
"d26cfc6d8f420f259f434b79593e9eb4":"Zobrazit příjmový doklad",
"c7d0394c3206a3b74afbeabc41cc639c":"Zobrazit příjmový doklad",
"01650aa52c5e5a22d3f38afd2c7f9411":"Zobrazit příjmový doklad",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Zobrazit příjmový doklad",
"b2d141897f73fc2a198ee1a7a438f89b":"Zobrazit požadavek",
"02fa2f56ef72845a9dfeabea22948061":"Zobrazit podrobnosti o týmu",
"2d9c55650b17dd605a1388f46c81ba38":"Virtuální karta",
"d0ec076e4600e854588f8da265208c0b":"Možnosti virtuální karty",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Peněženka",
"5279a2220e4cfb0d8b8b357311251cbe":"Peněženka",
"b1746b5806659e3189f837d2dd54e08c":"Peněženka",
"ff306d5b34ee993f9618c1f3c0209a39":"V tuto chvíli nelze žádost archivovat. Zkuste to znovu později.",
"90272a9bb4d2048e81d7962ccfc9a97d":"V tuto chvíli nelze žádost zrušit. Zkuste to znovu později.",
"a813d44f6bb26c5da09671e532abe022":"V tuto chvíli nelze žádost vyjmout z archivu. Zkuste to znovu později.",
"d4c7310eafc0d0da89f20596a22a3abe":"V tuto chvíli nelze vytvořit žádost. Zkuste to znovu.",
"dca679c10accb1db481a57a0e1ba08e2":"V tuto chvíli nelze vytvořit žádost. Zkuste to znovu.",
"db5584a2ef29b04959bce7f93db54c56":"V tuto chvíli nelze vyměnit tým. Zkuste to znovu později.",
"f2af2c261fabddb01766f4f974b85a62":"Týdně",
"e181ed0723a00ca7558b9d5d5258732b":"Vítejte ve službě Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Zůstaneme v kontaktu!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Jakou kreditní kartu vaše společnost používá?",
"968d94729053e166a76ea577ce3022e2":"Pracovní e-mail",
"588a075e8694ff5cc86815697e1586bc":"Pracujte stejným způsobem jako vaše obchodní týmy.",
"182ae1eb5226e0646b4033883cc0900c":"Rok",
"fe88fe7094c4ced427874b3d22326c8d":"Ano",
"9a2d4e3b22ad0567165eb0f661f952ad":"Ano, zrušit kartu",
"96b88e530375237cbc08138d9fb4109c":"Ano, odebrat tento seznam",
"fd3780f468c531f2bc1b47b8748b5a66":"Účetní kódy můžete vytvořit v nastaveních společnosti.",
"ab3ac6f945d9f756763d49257fd924dc":"Kódování můžete spravovat v nastaveních společnosti.",
"c919dae93fe780061629c255eb358140":"Jedinou zdrojovou kartu propojenou s tímto týmem nelze odstranit. Chcete-li tuto kartu odstranit, musíte nejdříve přidat jinou.",
"11de97e873b66d91a77db7a05da32202":"Na správce společnosti můžete povýšit jednoho z níže uvedených členů týmu nebo můžete pozvat nového uživatele.",
"0ab790f08b2780be001b79d2ccd75c4e":"Pro tento účet nemáte vytvořené žádné účetní kódy.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Nemáte vytvořené žádné seznamy kódování.",
"44e470218124a50c117870467530752e":"Zadali jste nesprávnou e-mailovou adresu nebo heslo.",
"4b687d0730822fdbeea27c174e47f227":"Nemáte žádná schválená schválení.",
"020f03c17b79af66f335b6af868c66c0":"Nemáte žádná zrušená schválení.",
"def0b76f03e7c622341a7d8e08aaaae7":"Nemáte žádná zamítnutá schválení.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Nemáte žádná nevyřízená schválení.",
"7c034d6104f80a523b90bc607fc06608":"Nemáte v peněžence žádné virtuální karty. Vytvořit žádost",
"0aed9198d61d161468d541dbbc9f477a":"Máte naplánováno přeposílání uživateli {oooForwardToFullName} v době volna až do {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Obdrželi jste novou virtuální kartu:",
"04eeaf814767c30aa41577f3b11f916a":"V nedávné době jste požádali o obnovu hesla k vašemu účtu Go. Postup dokončení tohoto procesu:",
"09ddf249810b9af7d3e1f4e537da500d":"Pokusili jste se vstoupit na stránku, která neexistuje. Přesvědčte se, zda máte správný odkaz.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Jste pozváni k připojení k vašemu týmu zde:",
"02c6f3a7cf31a87f4f39f992509f8c44":"Byli jste přidáni jako správce pro vaši společnost",
"cf121a14590f109dafcae138265e353b":"Byli jste přidáni do nového týmu!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Byli jste přidáni do týmu",
"d3cef66a026f776903b342d450e5a832":"Vaše žádost o kartu Go byla zamítnuta",
"003f87c21a29220e661fc302b885fd5a":"Váš účet byl zablokován. Kontaktujte vaši společnost nebo správce týmu.",
"a52b1f0da15dce68007f524e48238143":"Při přidávání této karty vrátila vaše banka chybu. Kontaktujte svou banku za účelem vyřešení této chyby a zkuste to znovu.",
"4d883b173167297463aeac86957c26fe":"Vaše e-mailová adresa byla použita k vytvoření účtu ve službě Tradeshift Go. Ověřit e-mailovou adresu a dokončit registraci",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Vaše nastavení dnů volna byla uložena.",
"542f08554110a12de0c23afd78f38e0e":"Vaše heslo bylo obnoveno. Přihlaste se prosím.",
"49b2dcbe5d461f35c37986962b5bd07a":"Vaše žádost byla zamítnuta",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Jste na seznamu!",
"32f87c5ddeaee35b9178785ef4eb9313":"PSČ",
"c6974a3d5311374c697625f2a3234bce":"datum",
"f86980f5ef00b645a6e4a0b59738a954":"osoba, kterou se nám nepodařilo vyhledat",
"7066f854917b39bafe0bbecbb8bd6377":"schvaluje žádosti",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"pro",
"49b171230ab9bcdd8b631c70cf397efb":"pro <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"pro vaši společnost",
"35ad86f0b035d8156390670d60801e7f":"pro {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"pro {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"od",
"c1407aa5424f339dedf67bcac597cf71":"nastavila, aby její žádosti o schválení byly přeposílány vám během",
"67a948b8af3284a401ebefcb1b4723ab":"čeká na vaše schválení",
"9ca64449d64b98fef7859e22bc23c4dc":"podává žádosti",
"ac850ab84428e9eb6a57e5d813de5e45":"vede tým",
"2168839a1f4c73ef5c89c9e930148e7d":"ignorujte prosím tento e-mail. Není vyžadována žádná akce",
"85431c325faab98e02daa60151465144":"vám zaslal toto pozvání",
"81c504fd54119e7e580eb05dedef46d0":"vám zaslal toto pozvání",
"d74545060b38445ef4cc49fbb8d79876":"vám zaslal toto pozvání.",
"2d96c53ee7ee0a54aadad19bfce0697d":"při získávání vašich zdrojů karet došlo k chybě",
"b2fda295788d579fffd3f43b7737fd63":"zaregistrovat vaši firemní kartu u služby Go za účelem udělení jednoduchého a bezpečného přístupu ke kartě vašim zaměstnancům",
"d35bd5c5d74af765d45cef3c4e78f52a":"za účelem nakupování pomocí virtuálních karet Go",
"8469a13ddf1cc19a4ebf26356d8ac646":"za účelem nakupování pomocí virtuálních karet Go",
"9546989e681a7e25fbdfe7631e9ebc03":"za účelem nakupování pomocí virtuálních karet Go.",
"6174e0a3d26409dd6e410052e2805660":"začít používat karty služby Tradeshift Go",
"0bee956ca9aa927e0063c36a300e29e1":"vypnout pracovní nepřítomnost",
"fd2c1099fc9f3831d7f24195aaa11269":"vase@spolecnost.cz",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} je zodpovědný za schvalování žádostí od ostatních členů týmu. Chcete-li pokračovat, přiřaďte nového schvalovatele pro dotčené členy týmu.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} byl(a) úspěšně pozván(a)${this.teamId ? ‚.‘ : ' týmu.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} byl odebrán",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} zrušil žádost pro",
"9567a2d4cacd58fb4f096132d26c1660":"{username} je zodpovědný za schvalování žádostí od ostatních členů týmu. Chcete-li pokračovat, přiřaďte nového schvalovatele pro dotčené členy týmu.",
};
