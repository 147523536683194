const _ = require('lodash');

/* @ngInject */
function AccountSetupService($rootScope, ConfigService, HttpService, TaskService) {
	const TASK_STATES = ConfigService.get('SETUP_TASK_STATES');
	const TASK_DEFINITIONS = ConfigService.get('SETUP_TASK_DEFINITIONS');

	const service = {};

	service.clearSetupState = function () {
		service.setupTasks = null;
	};
	service.completeTask = function (taskId) {
		return TaskService.completeTask({
			taskId,
		}).then(function () {
			_.chain(service.setupTasks).find({ taskId }).set('isComplete', true).value();
			return taskId;
		});
	};
	service.getSetupTasks = function () {
		return HttpService.tradeshiftGo()
			.path('/rest/tasks?type=setup')
			.get()
			.then(function (result) {
				service.setupTasks = _.chain(result.tasks)
					.map(function (setupTask) {
						return _.chain(TASK_DEFINITIONS)
							.find({
								key: _.get(setupTask, 'context.key'),
							})
							.assign({
								isComplete: setupTask.state !== TASK_STATES.assigned,
								taskId: setupTask.id,
							})
							.value();
					})
					.sortBy('order')
					.compact()
					.value();
				return service.setupTasks;
			});
	};
	service.isSetupComplete = function () {
		return _.isArray(service.setupTasks) && _.every(service.setupTasks, 'isComplete');
	};

	service.setupViewHidden = () => {
		$rootScope.$broadcast('setup-view-hidden');
	};

	return service;
}

module.exports = AccountSetupService;
