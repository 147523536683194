const EditSourceCardAsideComponent = {
	bindings: {
		cardSourceId: '<',
		isOpen: '<',
		onCancel: '&',
		onUpdate: '&',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onCancel()"
				title="{{$ctrl.updateSourceCardAsideTitle}}">
			<div>
				<ts-form
						is-submitting="$ctrl.isSubmitting"
						on-cancel="$ctrl.onCancel()"
						on-submit="$ctrl.onSubmit($ctrl.name)"
						submit-label="{{$ctrl.updateSourceCardText}}">
					<ts-form-fields>
						<ts-input
								label="{{$ctrl.sourceCardNameText}}"
								ng-model="$ctrl.name"
								type="text"></ts-input>
					</ts-form-fields>
				</ts-form>
			</div>
		</ts-aside>
	`,
	controller: class EditSourceCardAsideController {
		/* @ngInject */
		constructor(EventEmitter, LexicoService, PaymentsService) {
			this.EventEmitter = EventEmitter;
			this.isSubmitting = false;
			this.lexico = LexicoService.getLexico();
			this.updateSourceCardAsideTitle = this.lexico.trc('Component title', 'Update source card');
			this.updateSourceCardText = this.lexico.trc('Button', 'Update source card');
			this.sourceCardNameText = this.lexico.trc('Form input label', 'Source card name');
			this.PaymentsService = PaymentsService;
		}

		close() {
			this.onCancel();
		}

		onSubmit(name) {
			this.isSubmitting = true;
			this.PaymentsService.setCardName({
				cardSourceId: this.cardSourceId,
				name,
			})
				.then(() => {
					const successMessage = this.lexico.trc('Success message', 'Card name updated.');
					ts.ui.Notification.success(successMessage);
					this.onUpdate(
						this.EventEmitter({
							cardSourceId: this.cardSourceId,
							name,
						}),
					);
				})
				.catch(() => {
					const warningMessage = this.lexico.trc(
						'Warning message',
						'There was a problem updating the card name. Please try again.',
					);
					ts.ui.Notification.warning(warningMessage);
				})
				.finally(() => {
					this.close();
					this.isSubmitting = false;
					this.name = '';
				});
		}
	},
};

export default EditSourceCardAsideComponent;
