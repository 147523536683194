"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dialogInitialState = void 0;
exports.dialogSlice = dialogSlice;
const getDialogInitialState = () => ({
    alert: {
        message: '',
        type: '',
        open: false,
        actionText: 'OK',
        showCancel: false,
        onAccept: () => ({}),
    },
});
exports.dialogInitialState = {
    dialog: getDialogInitialState(),
};
function dialogSlice(set) {
    return {
        ...exports.dialogInitialState,
        setDialog(alert) {
            set((state) => {
                state.dialog.alert = alert;
                return state;
            }, false, '[setDialog]');
        },
        closeDialog() {
            set((state) => {
                state.dialog.alert.open = false;
                return state;
            }, false, '[closeDialog]');
        },
        resetDialog() {
            set((state) => {
                state.dialog = getDialogInitialState();
                return state;
            }, false, '[resetDialog]');
        },
    };
}
