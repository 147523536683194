"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiIconButtonOverrides = void 0;
function getPagerButtonStyle(theme) {
    // this renders the pager button the gotToFirst goToPrev
    // and goToNext and goToLast pages
    // << <  > >>
    return {
        backgroundColor: 'transparent',
        color: theme.palette.tsGoColor.blue,
        paddingLeft: 0,
        paddingRight: 0,
        '&:hover, &:disabled': {
            backgroundColor: 'transparent',
            textDecoration: 'none !important',
        },
        '&:hover': {
            color: theme.palette.tsGoColor.blueDark,
        },
        '&:disabled': {
            color: theme.palette.tsGoColor.gray,
        },
    };
}
exports.muiIconButtonOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => {
            return {
                ...(ownerState['ts-variant'] === 'pagerButton' && getPagerButtonStyle(theme)),
            };
        },
    },
};
