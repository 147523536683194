const angular = require('angular');
const ApproversTable = require('./tables/ApproversTable');
const CardDetailController = require('./controllers/CardDetailController');
const CodingController = require('./controllers/CodingController');
const CodingHelper = require('./helpers/CodingHelper');
const CodingListController = require('./controllers/CodingListController');
const CodingListTable = require('./tables/CodingListTable');
const CodingTable = require('./tables/CodingTable');
const ManageController = require('./controllers/ManageController');
const ManageHeaderHelper = require('./helpers/ManageHeaderHelper');
const PaymentsController = require('./controllers/PaymentsController');
const PaymentsHelper = require('./helpers/PaymentsHelper');
const ReportController = require('./controllers/ReportController');
const SettingsController = require('./controllers/SettingsController');
const UsersController = require('./controllers/UsersController');

/* @ngInject */
function routing($stateProvider) {
	$stateProvider
		.state('main.manage', {
			abstract: true,
			controller: 'ManageController',
			resolve: ManageController.resolve,
			template: require('./views/index.html'),
			url: 'manage',
		})
		.state('main.manage.cardSource', {
			controller: 'CardDetailController',
			resolve: CardDetailController.resolve,
			template: require('./views/cardDetail.html'),
			url: '/card/:cardSourceId',
		})
		.state('main.manage.coding', {
			controller: 'CodingController',
			resolve: CodingController.resolve,
			template: require('./views/coding.html'),
			url: '/coding',
		})
		.state('main.manage.codingList', {
			controller: 'CodingListController',
			resolve: CodingListController.resolve,
			template: require('./views/codingList.html'),
			url: '/coding/:codingListId',
		})
		.state('main.manage.payments', {
			controller: 'PaymentsController',
			resolve: PaymentsController.resolve,
			template: require('./views/payments.html'),
			url: '/payments',
		})
		.state('main.manage.report', {
			controller: 'ReportController',
			resolve: ReportController.resolve,
			template: require('./views/report.html'),
			url: '/report',
		})
		.state('main.manage.settings', {
			url: '/settings',
		})
		.state('main.manage.users', {
			controller: 'UsersController',
			resolve: UsersController.resolve,
			template: require('./views/users.html'),
			url: '/users',
		});
}

module.exports = angular
	.module('go.manage', [])
	.controller('CardDetailController', CardDetailController)
	.controller('CodingController', CodingController)
	.controller('CodingListController', CodingListController)
	.controller('ManageController', ManageController)
	.controller('PaymentsController', PaymentsController)
	.controller('ReportController', ReportController)
	.controller('SettingsController', SettingsController)
	.controller('UsersController', UsersController)
	.factory('ApproversTable', ApproversTable)
	.factory('CodingHelper', CodingHelper)
	.factory('CodingListTable', CodingListTable)
	.factory('CodingTable', CodingTable)
	.factory('ManageHeaderHelper', ManageHeaderHelper)
	.factory('PaymentsHelper', PaymentsHelper)
	.config(routing).name;
