/* @ngInject */
function securedElement(UserService) {
	const operations = UserService.getSupportedOperations();
	const types = UserService.getSupportedTypes();

	return {
		restrict: 'A',
		scope: {
			typeId: '@',
			operationId: '@',
		},
		link: function (scope, element) {
			const operationId = operations[scope.operationId] || scope.operationId;
			const typeId = types[scope.typeId] || scope.typeId;

			if (operationId && typeId) {
				UserService.verifyAccess(operationId, typeId).then(function (hasAccess) {
					if (hasAccess !== true) {
						element.remove();
					}
				});
			} else {
				console.log('Operation ID and Type ID are required');
			}
		},
	};
}

module.exports = securedElement;
