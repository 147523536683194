"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiSwitchOverrides = void 0;
const material_1 = require("@mui/material");
exports.muiSwitchOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                [`.${material_1.switchClasses.root}`]: {
                    alignContent: 'flex-start',
                },
                [`& .${material_1.switchClasses.switchBase}`]: {
                    [`+ .${material_1.switchClasses.track}`]: {
                        backgroundColor: theme.palette.tsGoColor.grayLight(),
                        opacity: 1,
                    },
                },
                [`& .${material_1.switchClasses.switchBase}.${material_1.switchClasses.checked}`]: {
                    color: theme.palette.tsGoColor.greenDark,
                    [` .${material_1.switchClasses.thumb}`]: {
                        backgroundColor: theme.palette.tsGoColor.greenDark,
                    },
                    [`+ .${material_1.switchClasses.track}`]: {
                        backgroundColor: theme.palette.tsGoColor.greenLight,
                        opacity: 1,
                    },
                },
                [`& .${material_1.switchClasses.switchBase}.${material_1.switchClasses.disabled}`]: {
                    color: theme.palette.tsGoColor.greenLight,
                    [` .${material_1.switchClasses.thumb}`]: {
                        backgroundColor: theme.palette.tsGoColor.gray,
                    },
                    [`+ .${material_1.switchClasses.track}`]: {
                        backgroundColor: theme.palette.tsGoColor.black,
                        opacity: 0.12,
                    },
                },
            };
        },
    },
};
