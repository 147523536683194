const TSFormComponent = {
	bindings: {
		hideButtons: '<?',
		isSubmitting: '<',
		onCancel: '&?', // optional so cancel button isn't shown when handler not provided, e.g. purchase request form
		onSubmit: '&',
		submitLabel: '@',
	},
	transclude: {
		tsFormFields: '?tsFormFields',
		tsFormButtons: '?tsFormButtons',
	},
	template: `
		<form data-ts="Form" name="form" ng-submit="$ctrl.onSubmit()">
			<div ng-transclude="tsFormFields"></div>
			<fieldset>
				<menu data-ts="Buttons">
					<ts-menu-button
							button-class="ts-primary"
							button-type="submit"
							is-busy="$ctrl.isSubmitting"
							is-disabled="form.$invalid"
							label="$ctrl.submitLabel"
							ng-if="!$ctrl.hideButtons"
							ng-if="$ctrl.onSubmit"></ts-menu-button>
					<div
							ng-if="!$ctrl.hideButtons"
							ng-transclude="tsFormButtons"></div>
					<ts-menu-button
							button-class="ts-tertiary"
							label="$ctrl.cancelButtonText"
							ng-if="$ctrl.onCancel && !$ctrl.hideButtons"
							on-click="$ctrl.onCancel()"></ts-menu-button>
				</menu>
			</fieldset>
		</form>
	`,
	controller: class TSFormController {
		/* @ngInject */
		constructor(LexicoService) {
			this.lexico = LexicoService.getLexico();
			this.cancelButtonText = this.lexico.trc('Button', 'Cancel');
		}
	},
};

export default TSFormComponent;
