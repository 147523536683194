/* @ngInject */
function EditProfileController(
	$scope,
	$state,
	constants,
	AsideService,
	FeatureService,
	LexicoService,
	NameService,
	RoleServiceV2,
	TeamsService,
	UserServiceV2,
) {
	this.lexico = LexicoService.getLexico();
	this.profilePictureInputLabelText = this.lexico.trc('Form input title', 'Profile picture');
	this.firstNameInputLabelText = this.lexico.trc('Form input title', 'First name');
	this.lastNameInputLabelText = this.lexico.trc('Form input title', 'Last name');
	this.emailInputLabelText = this.lexico.trc('Form input title', 'Email');
	this.companyInputLabelText = this.lexico.trc('Form input title', 'Company');
	this.teamInputLabelText = this.lexico.trc('Form input title', 'Team');
	this.roleInputLabelText = this.lexico.trc('Form input title', 'Role');
	this.languageInputLabelText = this.lexico.trc('Select input title', 'Language');
	this.saveButtonText = this.lexico.trc('Button', 'Save');
	this.cancelButtonText = this.lexico.trc('Button', 'Cancel');

	this.nameValidationErrorMessage = this.lexico.trc('Error message', 'Invalid name');

	// Left a hyphen as valid for those including middle names.
	const NameValidationRegex = /^[a-zA-Z\\-]*$/;

	const setLoading = isLoading => {
		AsideService.busy('editProfileV2', isLoading);
		this.isLoading = isLoading;
	};

	const closeAside = () => {
		AsideService.close('editProfileV2');
	};

	const init = async () => {
		setLoading(true);
		this.enableGlobalization = FeatureService.getFeaturesRegistryList().ENABLE_GLOBALIZATION;

		this.languages = this.buildLanguageOptions(constants.languages);
		this.onSuccess = $scope.aside.onSuccess;
		this.user = $scope.aside.model.user;
		this.language = {
			id: this.user.language,
			label: constants.languages[this.user.language],
		};

		try {
			this.role = await RoleServiceV2.getCurrentRoleForUser(this.user);
			this.isCompanyOrSupportAdmin = UserServiceV2.isCompanyOrSupportAdmin(this.user);
			if (!this.isCompanyOrSupportAdmin) {
				const currentTeam = await TeamsService.getCurrentTeam();
				this.currentTeam = currentTeam;
			}
		} catch (error) {
			const errorMessage = this.lexico.trc(
				'Error message',
				'There was a problem loading the user profile. Please try again.',
			);
			ts.ui.Notification.warning(errorMessage);
			// need to use setTimeout to avoid changing the value of show within the watch show callback otherwise the
			// watch fires twice.
			setTimeout(closeAside);
		} finally {
			setLoading(false);
		}
	};

	this.cancel = () => {
		closeAside();
	};

	this.buildLanguageOptions = languages => Object.keys(languages).map(key => ({ label: languages[key], id: key }));

	this.save = async (user, avatar) => {
		// updating user for sidebar
		const reloadTranslations = user.language !== this.language.id;
		user.fullName = NameService.fullName(this.user);
		user.initials = this.user.firstName.charAt(0) + this.user.lastName.charAt(0);
		user.language = this.language.id;
		try {
			await UserServiceV2.saveUser({ avatar, user });
			this.onSuccess(user);
			closeAside();
			const successMessage = this.lexico.trc('Success message', 'Profile saved!');
			ts.ui.Notification.success(successMessage);
			if (reloadTranslations) {
				$state.reload();
			}
		} catch (error) {
			const errorMessage = this.lexico.trc(
				'Error message',
				'There was a problem saving the profile. Please try again.',
			);
			ts.ui.Notification.warning(errorMessage);
		}
	};

	this.validateName = name =>
		NameValidationRegex.test(name)
			? null
			: {
					valid: false,
					errorMessage: this.nameValidationErrorMessage,
				};

	$scope.$watch('aside.show', show => {
		if (show === true) {
			init();
		}
	});
}

module.exports = EditProfileController;
