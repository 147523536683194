const angular = require('angular');

const pdf = () => {
	return {
		restrict: 'E',
		scope: {
			src: '@',
		},
		link(scope, element) {
			scope.$watch('src', function (newValue) {
				if (newValue) {
					var objectElement = angular.element('<object></object>');
					objectElement.attr('data', newValue);
					element.replaceWith(objectElement);
				}
			});
		},
	};
};

module.exports = pdf;
