"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateFileType = exports.validateFileSize = exports.getSpentPercentage = exports.getSpentAmount = exports.getRemainingValue = void 0;
exports.getCardAddressData = getCardAddressData;
const clamp = (value, lower, upper) => {
    return Math.min(Math.max(value, lower), upper);
};
const AUTH_PADDING_PERCENTAGE = 7; // default auth padding percentage CHECK
const getRemainingValue = (card) => {
    const limit = Number(card.amount);
    return limit - (0, exports.getSpentAmount)(card);
};
exports.getRemainingValue = getRemainingValue;
const getSpentAmount = (card) => {
    const fieldAuthPadding = Number(card.authPaddingPercentage);
    const authPaddingPercentage = Number.isNaN(Number(fieldAuthPadding)) ? AUTH_PADDING_PERCENTAGE : fieldAuthPadding;
    if (typeof card.balance !== 'number') {
        return 0;
    }
    return (Number(card.amount) * (100 + authPaddingPercentage)) / 100 - card.balance;
};
exports.getSpentAmount = getSpentAmount;
const getSpentPercentage = (card) => {
    const spent = (0, exports.getSpentAmount)(card);
    const limit = Number(card.amount);
    const percentage = (spent / limit) * 100;
    return clamp(Math.round(percentage), 0, 100);
};
exports.getSpentPercentage = getSpentPercentage;
const validateFileSize = ({ file, maxSizeMB }) => {
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    return file.size <= maxSizeBytes;
};
exports.validateFileSize = validateFileSize;
const validateFileType = ({ file }) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.webp|\.pdf)$/i;
    return allowedExtensions.test(file.name) ? true : false;
};
exports.validateFileType = validateFileType;
function getCardAddressData(card) {
    if (card && 'billingAddress' in card) {
        const city = card?.billingAddress?.city || '';
        const zipCode = card?.billingAddress?.zipCode || '';
        const address = card?.billingAddress?.address || card?.billingAddress?.addressLine1 || '';
        const addressCode = card?.billingAddress?.regionCode || card?.billingAddress?.region || '';
        const fullAddress = `${city} ${addressCode} ${zipCode}`;
        return { address, fullAddress, city, zipCode, addressCode };
    }
    return { address: '', fullAddress: '', city: '', zipCode: '', addressCode: '' };
}
