const _ = require('lodash');

/* @ngInject */
function ApprovalModel(constants, ConfigService, RequestRegistry) {
	// approvalStatusLookup is a mapping of: request state + approval outcome -> approval status
	const approvalStatusLookup = {
		[['approved', 'APPROVED']]: 'approved',
		[['approved', 'CARD-CANCELED']]: 'card-canceled',
		[['approved', 'REASSIGNED']]: 'approved',
		[['approved', undefined]]: 'pending',
		[['rejected', 'REASSIGNED']]: 'declined',
		[['rejected', 'REJECTED']]: 'declined',
		[['rejected', undefined]]: 'pending',
		[['submitted', 'MANUAL_REASSIGN']]: 'forwarded-pending',
		[['submitted', 'REASSIGNED']]: 'forwarded-pending',
		[['submitted', 'SELF_REASSIGNED']]: 'forwarded-pending',
		[['submitted', undefined]]: 'pending',
	};

	const approvalStatusToLabel = {
		approved: 'Approved',
		'card-canceled': 'Card canceled',
		declined: 'Declined',
		'forwarded-pending': 'Forwarded (pending)',
		pending: 'Pending',
		'pending-forwarded': 'Pending (forwarded)',
	};

	class Approval {
		constructor(approval) {
			this._approval = approval;
			this._request = RequestRegistry.storeRequest(approval.request);

			// we have data on the approval that isn't on the request, update the request so we can get it from there
			const profileId = _.get(approval, 'context.request.profileId', 'default');
			this._request.setProfileId(profileId);

			const fields = _.get(approval, 'context.request.fields');
			if (fields) {
				this._request.setFields(fields);
			} else {
				// handling legacy approvals where approval.context = { comments: String }
				this._request.setFields({
					amount: approval.request.amount,
					comments: approval.context.comments,
					description: approval.request.title,
				});
			}
		}

		_getAssignees() {
			return this._approval.assignees;
		}

		_getRequestState() {
			return this._request.getState();
		}

		getAssignedOn() {
			return this._approval.assignedOn;
		}

		getAssignedUserId() {
			return this._approval.context.assignedUserId;
		}

		getCardTypeKey() {
			return this._request.getCardTypeKey();
		}

		getComments() {
			return this._approval.context.comments;
		}

		getCompletedOn() {
			return this._approval.completedOn;
		}

		getFields() {
			return _.get(this._approval.context, 'fields', {});
		}

		getRequestId() {
			return this._approval.subjectId;
		}

		getId() {
			return this._approval.id;
		}

		getTeamId() {
			return this._approval.request.teamId;
		}

		getLastEdit() {
			return this._request.getLastEdit();
		}

		getMenuIcon() {
			return 'ts-icon-pay';
		}

		getMenuSref() {
			return 'main.approval({approvalId: "' + this.getId() + '"})';
		}

		getOutcome() {
			return this._approval.outcome;
		}

		getReassignedByFullName() {
			return _.get(this._approval, 'reassignedByUser.fullName');
		}

		getReassignedToFullName() {
			return _.get(this._approval, 'reassignedToUser.fullName');
		}

		getReassignedToUser() {
			return this._approval.reassignedToUser;
		}

		getRequest() {
			return this._request;
		}

		getRequestedByUserId() {
			return this._approval.assigner;
		}

		getStatus() {
			const requestState = this._getRequestState();
			const approvalOutcome = this.getOutcome();
			let approvalStatus = approvalStatusLookup[[requestState, approvalOutcome]];
			if (approvalStatus === 'pending' && this.isReassigned()) {
				approvalStatus = 'pending-forwarded';
			}
			return approvalStatus;
		}

		getStatusLabel() {
			const approvalStatus = this.getStatus();
			return approvalStatusToLabel[approvalStatus];
		}

		isActionable() {
			return this._approval.state === _.toUpper(constants.tasks.states.assigned) && this.isProcessing() !== true;
		}

		isApprovalLimitExceeded() {
			return this._approval.exceedsApprovalLimit;
		}

		isApproved() {
			return this._approval.outcome === _.toUpper(ConfigService.get('TASK_OUTCOMES').approved);
		}

		isCompleted() {
			return this._approval.state === _.toUpper(constants.tasks.states.completed);
		}

		isPending() {
			const status = this.getStatus();
			return status === 'pending' || status === 'pending-forwarded';
		}

		isReassignable() {
			return (
				this._approval.featureAccess && this._approval.featureAccess.reassignRequest === true && this.isActionable()
			);
		}

		isReassignedBySelf(userId) {
			const reassignedByUserId = _.get(this._approval, 'reassignedByUser.userId');
			return reassignedByUserId === userId;
		}

		isReassignableToSelf() {
			const status = this.getStatus();
			return (
				this._approval.featureAccess &&
				this._approval.featureAccess.reassignRequestToSelf === true &&
				(status === 'pending-forwarded' || status === 'forwarded-pending')
			);
		}

		isReassignedToSelf() {
			if (_.isString(this._approval.context.reassignedToSelf)) {
				return this._approval.context.reassignedToSelf === 'true';
			}
			return this._approval.context.reassignedToSelf;
		}

		isUserAssigned(userId) {
			return _.includes(this._getAssignees(), userId);
		}

		markApproved() {
			this._request.setState(_.toLower(constants.request.states.approved));
			this._approval.outcome = _.toUpper(ConfigService.get('TASK_OUTCOMES').approved);
		}

		isAutoForwarded() {
			return this._approval.context.isAutoForwarded === 'true';
		}

		isProcessing() {
			if (_.isString(this._approval.context.isProcessing)) {
				return this._approval.context.isProcessing === 'true';
			}
			return this._approval.context.isProcessing;
		}

		isReassigned() {
			return this._approval.reassignedByUser !== undefined;
		}

		isRejected() {
			return this._approval.outcome === _.toUpper(constants.tasks.outcomes.rejected);
		}

		markReassigned(reassignedToUser, reassignedOn) {
			this._approval.outcome = _.toUpper(ConfigService.get('TASK_OUTCOMES').reassigned);
			this._approval.reassignedToUser = reassignedToUser;
			this._approval.completedOn = reassignedOn;
		}

		markRejected() {
			this._request.setState(_.toLower(constants.request.states.rejected));
			this._approval.outcome = _.toUpper(ConfigService.get('TASK_OUTCOMES').rejected);
		}

		markComplete() {
			this._approval.state = _.toUpper(constants.tasks.states.completed);
		}

		update(approval) {
			this._approval = _.cloneDeep(approval);
		}

		setProcessing(isProcessing) {
			this._approval.context.isProcessing = isProcessing;
		}

		setCardType(cardType) {
			this._request.setCardType(cardType);
		}

		setOutcome(outcome) {
			this._approval.outcome = outcome;
		}
	}
	return Approval;
}

module.exports = ApprovalModel;
