const TSMenuButtonDirective = () => ({
	restrict: 'E',
	replace: true,
	scope: {
		buttonClass: '@',
		buttonType: '@?',
		isBusy: '<?',
		isDisabled: '<?',
		label: '<',
		onClick: '&?',
	},
	// NOTE: ng-class="{{ buttonClass }}" caused tsui components to render all buttons as ts-tertiary. The only way to
	// get the button to render correctly was to add ts-primary to all buttons as seen below.
	template: `
		<li>
			<button
					data-ts.busy="{{ isBusy }}"
					class="{{ buttonClass }}"
					ng-disabled="isDisabled"
					ng-click="onClick()"
					ng-attr-type="{{ buttonType ? buttonType : 'button' }}"
					title="{{ label }}">
				<span ng-bind="label"></span>
			</button>
		</li>
	`,
});

export default TSMenuButtonDirective;
