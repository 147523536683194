"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledTableCell = void 0;
const material_1 = require("@mui/material");
exports.StyledTableCell = (0, material_1.styled)(material_1.TableCell)(({ theme }) => ({
    padding: theme.spacing(1),
    position: 'relative',
    ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? {
            fontSize: '12px',
            fontWeight: 400,
            color: '#000000',
        }
        : {}),
}));
