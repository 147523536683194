const DashboardBoxComponent = {
	bindings: {
		onSearch: '&?',
		searchPlaceholder: '@',
		title: '@',
	},
	template: `
	<div class="dashboard-box">
		<div class="title">
			<p ng-bind="$ctrl.title"></p>
			<go-search
					ng-if="$ctrl.onSearch"
					on-search="$ctrl.search($event)"
					placeholder="{{$ctrl.searchPlaceholder}}"></go-search>
		</div>
		<div
			class="container"
			ng-transclude></div>
	</div>`,
	controller: /* @ngInject */ function (EventEmitter) {
		this.search = ({ query }) => {
			this.onSearch(EventEmitter({ query }));
		};
	},
	transclude: true,
};

export default DashboardBoxComponent;
