"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiSelectOverrides = void 0;
const material_1 = require("@mui/material");
exports.muiSelectOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                '&.Mui-readOnly': {
                    backgroundColor: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'hsla(0, 0%, 85%, 1)' : 'inherit',
                    color: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'hsla(0, 0%, 59%, 1) !important' : 'inherit',
                },
                [`.${material_1.selectClasses.nativeInput}:placeholder-shown`]: {
                    inset: `0 ${theme.tsGoUnit.tsUnitHalf}`,
                    opacity: 1,
                },
            };
        },
    },
};
