"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.walletLoader = void 0;
const access_1 = require("../../../../common/constants/access");
const walletHelper_1 = require("../../../components/pages/wallet/walletHelper");
const appConstants_1 = require("../../../constants/appConstants");
const teamQuery_1 = require("../../../queries/teamQuery");
const userQuery_1 = require("../../../queries/userQuery");
const userSelectors_1 = require("../../../selectors/userSelectors");
const stopAngularjsLoader_1 = require("../stopAngularjsLoader");
let teams = [];
let navigateTo = '/wallet';
const walletLoader = async ({ queryClient, request, params, }) => {
    const verifyUserAccessQuery = (0, userQuery_1.getVerifyUserAccessQuery)(access_1.composites.WRITE_COMPANY);
    const teamId = await queryClient.ensureQueryData((0, teamQuery_1.selectedTeamIdQuery)('selectedTeamID'));
    const userSelectedTeamId = teamId?.[0] || '';
    const userData = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const userRole = (0, userSelectors_1.isCompanyOrSupportAdmin)(userData.roles)
        ? appConstants_1.overviewConstants.COMPANY_ADMIN
        : userData.roles.find(role => role.securityGroupId === userSelectedTeamId)?.roleId || '';
    let team;
    try {
        team = await queryClient.ensureQueryData((0, teamQuery_1.teamQuery)({ teamId: teamId?.[0] || '' }));
    }
    catch (error) {
        team = { data: null, error, isError: true };
    }
    const hasAccess = await queryClient.ensureQueryData({
        queryKey: verifyUserAccessQuery.queryKey,
        queryFn: verifyUserAccessQuery.queryFn,
    });
    const paginatedWalletData = {
        count: 0,
        tasks: [],
        // we need this, so we do not disable the next pager when we have null values in tasks
        initialPaginatedWalletDataCount: 0,
    };
    let paginatedFilterApiQuery;
    const paginatedFilterApiQueryDefaultQueries = {
        status: 'ACTIVE',
        sortBy: 'CREATION_DATE',
        sortOrder: 'DESC',
    };
    if (userRole === appConstants_1.overviewConstants.COMPANY_ADMIN) {
        navigateTo = '/wallet';
        paginatedFilterApiQuery = {
            teamId: params.teamId || '',
            owner: params.userId || '',
            ...paginatedFilterApiQueryDefaultQueries,
        };
    }
    else if (userRole === appConstants_1.teamAdmin) {
        navigateTo = request.url.includes('wallet/team') ? '/wallet/team' : '/wallet/my-cards';
        paginatedFilterApiQuery = request.url.includes('wallet/team')
            ? {
                teamId: params.teamId || teamId?.[0] || '',
                owner: params.userId || '',
                ...paginatedFilterApiQueryDefaultQueries,
            }
            : {
                teamId: params.teamId || '',
                owner: params.userId || userData.userId,
                ...paginatedFilterApiQueryDefaultQueries,
            };
    }
    else {
        navigateTo = '/wallet/my-cards';
        paginatedFilterApiQuery = {
            teamId: params.teamId || '',
            owner: params.userId || userData.userId,
            ...paginatedFilterApiQueryDefaultQueries,
        };
    }
    const teamData = await queryClient.ensureQueryData((0, teamQuery_1.teamsQuery)({ state: 'active' }));
    let allMembersFromAllTeams = [];
    if (userRole === appConstants_1.overviewConstants.COMPANY_ADMIN) {
        teams = (0, walletHelper_1.buildTeamsOptions)({ teams: teamData });
        try {
            const allMembersCollections = await Promise.all(teamData.map(async (team) => {
                const teamMembers = await queryClient.ensureQueryData((0, teamQuery_1.getTeamUsersQuery)({ teamId: team.id, query: '' }));
                return teamMembers?.users || [];
            }));
            allMembersFromAllTeams = allMembersCollections.flat();
            const uniqueUserIds = new Set();
            const uniqueMembers = allMembersFromAllTeams.filter(member => {
                const isAlreadySeen = uniqueUserIds.has(member.userId);
                if (!isAlreadySeen) {
                    uniqueUserIds.add(member.userId);
                    return true;
                }
                return false;
            });
            allMembersFromAllTeams = uniqueMembers;
        }
        catch {
            allMembersFromAllTeams = [];
        }
    }
    (0, stopAngularjsLoader_1.stopAngularJsLoader)();
    return {
        teamId,
        userRole,
        userData,
        team,
        hasAccess,
        navigateTo,
        teams,
        paginatedWalletData,
        paginatedFilterApiQuery,
        allMembersFromAllTeams,
    };
};
exports.walletLoader = walletLoader;
