class HttpClient {
	constructor(http, baseUrl, defaultConfig) {
		this._http = http;
		this._baseUrl = baseUrl;
		this._path = '';
		this._config = {};
		this._defaultConfig = defaultConfig || {};
		// this._params
	}

	// reason why we have this wrapper - to concatenate with the baseUrl
	_getFullPath() {
		const path = this._path || this._config.path;
		return this._baseUrl + path;
	}

	path(path) {
		this._path = path;
		return this;
	}

	config(config) {
		this._config = config || {};
		return this;
	}

	params(params) {
		this._params = params || {};
		return this;
	}

	request({ method, data }) {
		return this._http({
			...this._defaultConfig,
			...this._config,
			url: this._getFullPath(),
			method: method || this._config.method,
			data: data || this._config.data,
			params: this._params || this._config.params,
		});
	}

	external() {
		this._baseUrl = this._baseUrl + '/external';
		return this;
	}

	get() {
		return this.request({ method: 'GET' });
	}

	post(data) {
		return this.request({ method: 'POST', data });
	}

	put(data) {
		return this.request({ method: 'PUT', data });
	}

	delete() {
		return this.request({ method: 'DELETE' });
	}
}

module.exports = HttpClient;
