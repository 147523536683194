const TSTextAreaComponent = {
	bindings: {
		label: '@',
		isRequired: '@',
		placeholder: '@',
	},
	require: {
		model: 'ngModel',
	},
	template: `
		<fieldset>
			<label>
				<span ng-bind="$ctrl.label"></span>
				<textarea
						ng-change="$ctrl.onTextAreaChange()"
						ng-model="$ctrl.value"
						ng-required="$ctrl.isRequired === 'false' ? false : true"
						placeholder="{{$ctrl.placeholder}}"></textarea>
			</label>
		</fieldset>
	`,
	controller: class TSTextAreaController {
		$onInit() {
			this.model.$render = () => {
				this.value = this.model.$viewValue;
			};
		}

		onTextAreaChange() {
			this.model.$setViewValue(this.value);
		}
	},
};

export default TSTextAreaComponent;
