/* @ngInject */
const CodingController = (
	$scope,
	$state,
	_lists,
	_manageHeader,
	_providerApiMap,
	_providerFields,
	CodingHelper,
	CodingTable,
	ConfigService,
	FooterService,
) => {
	const FOOTER_BUTTONS = [
		{
			label: 'Add coding list',
			onclick: () => {
				$scope.$evalAsync(() => {
					$scope.asides.codingList.renameListId = null;
					$scope.asides.codingList.isOpen = true;
				});
			},
			type: 'ts-primary',
		},
	];
	(function init() {
		$scope.asides = {
			codingList: {
				isOpen: false,
				lists: _lists,
			},
			confirmDeleteList: {
				isOpen: false,
			},
			select: {
				isOpen: false,
				options: CodingHelper.getProviderFieldOptions(),
				title: 'Select provider field',
			},
		};
		$scope.table = CodingTable({
			codingListAside: $scope.asides.codingList,
			confirmDeleteListAside: $scope.asides.confirmDeleteList,
			lists: _lists,
			providerFields: _providerFields,
			selectAside: $scope.asides.select,
			showProviderFields: _providerApiMap !== '',
		});
		_manageHeader.selectTab({ selectedTab: 'coding' });
		FooterService.create({ $scope, buttons: FOOTER_BUTTONS });
	})();
};

CodingController.resolve = {
	_lists: /* @ngInject */ CodingHelper => CodingHelper.loadLists(),
	_providerFields: /* @ngInject */ CodingHelper => CodingHelper.loadProviderFields(),
	_providerApiMap: /* @ngInject */ ConfigService => {
		return ConfigService.get('ProviderApiMap');
	},
	_providerMappingConfigs: /* @ngInject */ ConfigService => ConfigService.getProviderMappingConfigs(),
};

module.exports = CodingController;
