import angular from 'angular';

import TSAsideDirective from './ts-aside.directive';
import TSBarComponent from './ts-bar.component';
import TSDatepickerComponent from './ts-datepicker.component';
import TSFormLabelComponent from './ts-form-label.component';
import TSFormComponent from './ts-form.component';
import TSInputComponent from './ts-input.component';
import TSMenuButtonDirective from './ts-menu-button.directive';
import TSModalComponent from './ts-modal.component';
import TSNoteComponent from './ts-note.component';
import TSRadioComponent from './ts-radio.component';
import TSSearchComponent from './ts-search.component';
import TSSelectComponent from './ts-select.component';
import TSTabBarComponent from './ts-tab-bar.component';
import TSTableComponent from './ts-table.component';
import TSTextAreaComponent from './ts-text-area.component';
import TSTimeComponent from './ts-time.component';
import TSUserImageDirective from './ts-user-image.directive';

require('./ts.less');

export default angular
	.module('go.common.ts', [])
	.component('tsBar', TSBarComponent)
	.component('tsDatepicker', TSDatepickerComponent)
	.component('tsForm', TSFormComponent)
	.component('tsFormLabel', TSFormLabelComponent)
	.component('tsInput', TSInputComponent)
	.component('tsModal', TSModalComponent)
	.component('tsNote', TSNoteComponent)
	.component('tsRadio', TSRadioComponent)
	.component('tsSearch', TSSearchComponent)
	.component('tsSelect', TSSelectComponent)
	.component('tsTabBar', TSTabBarComponent)
	.component('tsTable', TSTableComponent)
	.component('tsTextArea', TSTextAreaComponent)
	.component('tsTime', TSTimeComponent)
	.directive('tsAside', TSAsideDirective)
	.directive('tsMenuButton', TSMenuButtonDirective)
	.directive('tsUserImage', TSUserImageDirective).name;
