const _ = require('lodash');

/* @ngInject */
function RequestService(HttpService) {
	const service = {};
	service.addTag = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/requests/' + options.requestId + '/tags/' + options.tag)
			.put();
	};
	service.approve = function (options) {
		return HttpService.tradeshiftGo()
			.path(`/rest/requests/${options.requestId}/approve`)
			.config({
				transformResponse: (data, headersGetter, status) => ({ errorReason: data, code: status }),
			})
			.post(options);
	};
	service.cancelRequest = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/requests/' + options.requestId)
			.delete();
	};
	service.getRequest = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/requests/' + options.requestId)
			.get();
	};
	service.getRequests = function (options) {
		const params = {};
		options = options || {};
		if (!_.isUndefined(options.archived)) {
			params.archived = options.archived;
		}
		return HttpService.tradeshiftGo().path('/rest/requests').params(params).get();
	};
	service.reject = function (options) {
		const data = {
			taskId: options.taskId,
		};
		return HttpService.tradeshiftGo()
			.path('/rest/requests/' + options.requestId + '/reject')
			.post(data);
	};
	service.removeTag = function (options) {
		return HttpService.tradeshiftGo()
			.path('/rest/requests/' + options.requestId + '/tags/' + options.tag)
			.delete();
	};
	service.submitRequest = function (request) {
		return HttpService.tradeshiftGo()
			.path('/rest/requests/' + request.requestId + '/submit')
			.post(request);
	};

	// IMPORTANT - this is NOT currently being used (supported a canceled feature)
	// PLEASE REMOVE this note if you decide to start using it
	// its purpose is to validate a particular value against a policy (a rule)
	service.validateRequest = function (policyKey, policyValue) {
		return HttpService.tradeshiftGo()
			.path('/rest/requests/validate/' + policyKey + '/' + policyValue)
			.get();
	};

	return service;
}

module.exports = RequestService;
