"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerSchema = void 0;
const mui_tel_input_1 = require("mui-tel-input");
const zod_1 = require("zod");
const nonEmptyString = (fieldName) => zod_1.z.string().min(1, `${fieldName} must contain at least 1 character(s)`);
const phoneValidation = (fieldName) => {
    return nonEmptyString(fieldName).refine(value => (0, mui_tel_input_1.matchIsValidTel)(value), {
        message: 'Invalid phone number',
    });
};
exports.registerSchema = zod_1.z.object({
    firstName: nonEmptyString('First name'),
    lastName: nonEmptyString('Last name'),
    username: zod_1.z.string().email({ message: 'Invalid email address' }),
    phoneNumber: nonEmptyString('Phone number'),
    phoneNumberValue: phoneValidation('Phone number'),
    phoneCountryCode: nonEmptyString('Country code'),
    companyName: nonEmptyString('Company name'),
});
