const TSRadioComponent = {
	bindings: {
		isRequired: '@',
		label: '@',
		options: '<',
	},
	require: {
		model: 'ngModel',
	},
	template: `
		<fieldset>
			<span ng-bind="$ctrl.label"></span>
			<label ng-repeat="options in $ctrl.options">
				<input
						type="radio"
						ng-change="$ctrl.onInputChange()"
						ng-value="options.value"
						ng-model="$ctrl.value"
						ng-required="$ctrl.isRequired === 'false' ? false : true"/>
				<span ng-bind="options.label"></span>
			</label>
		</fieldset>
	`,
	controller: class TSRadioController {
		$onInit() {
			this.model.$render = () => {
				this.value = this.model.$viewValue;
			};
		}

		onInputChange() {
			this.model.$setViewValue(this.value);
		}
	},
};

export default TSRadioComponent;
