import angular from 'angular';

import EditUserAsideComponent from './edit-user-aside.component';
import EditUserPanelComponent from './edit-user-panel.component';
import EditUserService from './edit-user.service';

import './edit-user.less';

export default angular
	.module('go.common.asides.edit-user', [])
	.component('goEditUserAside', EditUserAsideComponent)
	.component('editUserPanel', EditUserPanelComponent)
	.service('editUserService', EditUserService).name;
