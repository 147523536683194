"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.events = void 0;
exports.events = {
    types: {
        conversationCreated: 'conversationCreated',
        dataEvent: 'dataEvent',
        documentCreated: 'documentCreated',
        messageAdded: 'messageAdded',
        personAdded: 'personAdded',
        personRemoved: 'personRemoved',
        subscribed: 'subscribed',
        unsubscribed: 'unsubscribed',
        purchases: {
            approvalTask: 'purchases.approvalTask',
            conversationMessage: 'purchases.conversationMessage',
            managerNotified: 'purchases.managerNotified',
            requestApproved: 'purchases.requestApproved',
            requestCancelled: 'purchases.requestCancelled',
            requestChanged: 'purchases.requestChanged',
            requestCreated: 'purchases.skillSelected',
            requestForm: 'purchases.requestForm',
            requestReassigned: 'purchases.requestReassigned',
            requestRejected: 'purchases.requestRejected',
            requestSubmitted: 'purchases.requestSubmitted',
            virtualCardAdded: 'purchases.cardAdded',
        },
        accountSetup: 'accountSetup',
    },
};
