module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# von Transaktionen",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 Minute",
"ce09c436b49af331a32f1152ede7dd18":"1. Finanzierung anfordern",
"d2226752d3065fbad4744924b4a0ee73":"2. Prüfen und genehmigen",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Prüfen und genehmigen",
"82bab92f1c8030888550b51521266c53":"3. Mit einer virtuellen Karte bezahlen",
"2ecd62e02e507f8e839a55eac0a04886":"Ein Codierungseintrag mit der ID „{entryId}“ ist bereits vorhanden.",
"5dfd25849740fbce8147838b0d5094eb":"Ein hübscher Nickname, z.B. Marketingkarte",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Ein Mitglied unseres Kundenserviceteams wird Sie in Kürze kontaktieren.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Eine Person, die wir nicht finden konnten",
"976b50e70e4433e2786233979d243445":"Es gibt bereits ein Team mit diesem Namen.",
"35b61d21ac11d5fc31ebe58541263445":"Akzeptieren Sie Ihre Einladung, um sich anzumelden",
"a372cb2d4204654f52cd9170fb10d374":"Buchhaltungscodes",
"110fc66010b6b91cebee7a59a2067aab":"Buchhaltungscodes",
"db862bb04dbdc6d04e47063f33be8865":"Buchhaltungscodes werden über Codierungslisten und Einträge in Listen verwaltet.",
"d6e3848e824f077396be4351eb58edd2":"Maßnahme erforderlich - Neue Anfrage genehmigen/ablehnen bis {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Aktionen",
"476e728ea824229f52438e543818bcdd":"Aktionen",
"3117746cb7d4ab815ec98274ec4398d5":"Aktionen",
"3ab09b66a7f16219d8135977a2a76d10":"Aktionen",
"ffafa66eaab0409a2e90aaeb43796def":"Aktiv",
"0447e0aa5d00dbdab11b5546793c4479":"Aktiv",
"8ee51d21b05466f7d962d4ff5a0b384e":"Aktive Karten",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Aktive virtuelle Karten",
"89c6ec05861aa914304ba35bc6744124":"Aktive Karten",
"5280659bcecaf8344c57790f9962a9a2":"Aktivität",
"8a15368ae286fa64e1cb7624cd410fc6":"Codierungsliste hinzufügen",
"8ded7e09e86be231b1165308329ac76d":"Liste hinzufügen",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Admin hinzufügen",
"695bb4bc29e2df769ddf7427ab71012a":"Genehmigenden hinzufügen",
"4a1d4516cab00b7c5ecdb150e346e5db":"Genehmigenden hinzufügen",
"56869851b27c2915f5a7fe6a33b91ac7":"Codierungsliste hinzufügen",
"8f8691057c3994aa61f9994202680c87":"Codierungsliste hinzufügen",
"69f7a406b79d19bf8082ed245ab29123":"Codierungsliste hinzufügen",
"48a92ca1cc63018707be99b13196369f":"Firmen-Admin hinzufügen",
"a679db9ff80d0e5769ae1ba311c7916f":"Domain hinzufügen",
"8f5e8078be77146ba7f3be49ef948906":"Eintrag hinzufügen",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Nachricht hinzufügen",
"f8a4b14162e19995074e63135b65c84d":"Neue Karte hinzufügen",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Personen hinzufügen",
"c670549d0b898bb6a571eb2cd7804a6e":"Rechnung hinzufügen",
"eb937098e77d8a6b0565ed72357dcaae":"Rechnung hinzufügen",
"1e154f31c31b63a459f3956e380b3ef9":"Ausgewählte hinzufügen",
"ba24db9956f156a18b32e653552ff682":"Fügen Sie diesen Team einige Personen hinzu",
"52bd93fb5489b25167bb541f7c6504ae":"Quellkarte hinzufügen",
"21ab3f08eb507dbc76df166eafeb393a":"{fullName} als Genehmiger hinzugefügt!",
"f879c06507d3076bbf52ea4fd2df850e":"Weitere Kartendaten",
"21e934ae30eece4a894337701f497638":"Alle",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Alle Karten",
"8fac71195e2b506e029752e4864b6a5d":"Alle Mitglieder",
"f9708417df16227742e0545a8642eea7":"Alle verfügbaren Benutzer wurden dieser Karte bereits hinzugefügt.",
"cb5abf070f8cba7982205038baab77bb":"Alle Karten",
"dae7d602eec2c84dea0d80ae25e56e6d":"Alle Karten",
"0b4bdfde6578668b6018d3615a1ad30f":"Alle Kostenstellen",
"97b875b81f54b4432d998a3e47d949cc":"Alle Teams",
"f0ae83969f7c20a1964caf5156dae966":"Alle Teams",
"d87455601a00282078582b36a3825b7d":"Alle Teams",
"24c7b7f55882ea94a525392ca8218fa0":"Alle Teams",
"8f860e35b64a8f447bded1e5ea66a2a8":"Alle virtuellen Karten",
"e403956ecaa6fb33ec1fef5c49d71042":"Alle virtuellen Karten",
"4e6f4e8f25f61d40e1eba77de94f6458":"Alle Ihre Finanzdaten sind verschlüsselt und werden auf Servern an sicheren Orten verwahrt.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Betrag",
"a4d099d0dbe3889b0995546e6ed28932":"Betrag",
"6c0ef32aae76bc31a61e2124f542bdf1":"Betrag",
"adec89c93525f1a8d1c64d856f405e27":"Betrag: hoch bis niedrig",
"18e632a170203894f40deed1d33ee552":"Betrag: niedrig bis hoch",
"a7944575cafef9126a499a1b06999fe3":"Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Anwenden",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Genehmigungsoptionen",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Genehmigungseinstellungen",
"492eedd88cdd7bb45f39c6594af748c4":"Genehmigungslimit",
"437d490afc8d226f8c7d544f4e35d011":"Genehmigungslimit ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Genehmigungen",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Genehmigungen",
"17adb7fe866e32055b1469fdb7e82795":"Genehmigungseinstellungen",
"7125aa13db437084e1d27d3ff38f22e2":"Genehmigen",
"84df787ff5746f9dc3cf0146becef4fd":"Anfrage genehmigen",
"7a568654d00245b0bab6c90a1b67d196":"Genehmigt",
"179c3e187ad8edeb8348cff222b938bf":"Genehmigt",
"ad058d17725870df9abbd4f5ec217636":"({count}) genehmigt",
"55ab98be3f0530e16d7b4c962ea0faca":"Genehmigter Betrag",
"4d58ba5c0e03fec1556c243be106e4a3":"Akzeptiert von",
"7d3d697fc99f6c4641b326623743f7b7":"Akzeptiert { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Genehmigender",
"8c45e443fc6748190719d79a092e2528":"Genehmigende haben ein maximales Genehmigungslimit",
"4fe56aa6456c108cd288a4ec8acf435f":"Archivieren",
"a9b6f93de9630064be9809130aa1e195":"Sind Sie bereit, die Kontrolle über Ihre Kartenausgaben zu übernehmen?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Sind Sie sicher, dass Sie diese Anfrage löschen möchten?",
"301bfc2ce058f49e0704fec3d162b5b5":"Sind Sie sicher, dass Sie sich diese Anfrage wieder zuteilen möchten?",
"2181faf8d9973d1f4138e401a762086e":"Sind Sie sicher, dass Sie ${firstName} ${lastName} als Firmenadministrator entfernen möchten?",
"b982277f29055d0ef6e295fe2006d9e0":"Sind Sie sicher, dass Sie {firstName} aus dem Team entfernen möchten?",
"456c0ccff14acb808583cd9dece857b6":"Zugeteilt zu",
"bde315b9cf7fd55f4b65aabbbf38d462":"Mindestens ein Manager oder Teaminhaber muss verfügbar sein, um die Abwesenheitsnotiz zu verwenden.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Authentifizierung",
"1961ec69dd0c8b5718c831312e7f3d0f":"Automatische Eingangskollektion",
"e39f2477cf34d726936a5b5c2b48a4ad":"Erstellen Sie automatisch präzise Abstimmberichte mit Informationen über Käufer, Genehmigende, Eingänge, Buchhaltungscodes und mehr.",
"434ca07dfde0eaee14f094b7c75a6eae":"Verfügbar",
"eb85d8f50f2c89871b794f7d54279b22":"Verfügbarer Kontostand",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Verfügbar { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Zurück",
"c8258f8cf9e20f6bbb89d957de40e820":"Sicherheit auf Bankenniveau",
"d46c22a3f36dce568c07fcea4368b231":"Grundlegende Einstellungen",
"fb481c8e517016094b4fdf618c5fa088":"Rechnungsadresse",
"79a9f821923c5a04c076902fef684281":"Rechnungsadresse",
"877a08b8347052c83aa0b689baea81e7":"Rechnungsadresse",
"97f018458e2627fac37d24846c7b8922":"Indem Sie Ihr Konto eröffnen und Tradeshift Go verwenden, stimmen Sie Folgendem zu:",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Indem Sie Ihr Konto eröffnen und Tradeshift Go verwenden, stimmen Sie Folgendem zu:",
"912d1864f0068c764bf4784020795d49":"NAME DES KARTENINHABERS",
"d53474c7fd99fbeb69481ce9d358aa09":"Kann Karten anfordern und besitzt eine Wallet für alle Karten. Jedes Mitglied erhält einen Standard-Genehmigenden (Manager) für seine Anfragen.",
"f30df2cab105ed3f8b4264aeb9800abd":"Kann Anfragen genehmigen und Karten für Mitglieder, die er/sie verwaltet, erstellen. Manager verfügen über ein Dashboard, um die Ausgaben von Mitgliedern, die sie verwalten, zu verfolgen.",
"03ed9eccc71ef59165292eac542d3050":"Kann alle Anfragen und Ausgaben des Teams sehen. Teaminhaber erhalten ebenfalls ein Team-Dashboard um Ausgaben und die Team-Wallet zu verwalten, wo sie alle aktiven Karten sehen.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Dieser Benutzer kann nicht entfernt werden. Sie benötigen in einem Team mindestens einen Teaminhaber oder Manager.",
"a0b70642629a55ea0ab6fa3414d342e0":"Sie können keine Benutzer mit ausstehenden Anfragen entfernen.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Abbrechen",
"5446624d3c364ac05ddf8d6663da2796":"Karte stornieren",
"53f3815c4fa3340b29efd81e627d1e6b":"Karte stornieren",
"12257ea2a871b7fab72245b5aafe6424":"Anfrage stornieren",
"2aafb7eb4d56a516c4bb0754f0812df1":"Karte löschen",
"caae12601b01e2325161e95107f9e332":"Karte löschen",
"6ad1e57b1e4c7accbe166c948883077d":"Mit einem Klick stornieren, falls gefährdet",
"f6527e49e6d5c989624507a583e23f39":"({count}) gelöscht",
"1ad3bf5121641dda511f42942af05a90":"Die Löschung dieser Karte wird keine offenen Gebühren rückgängig machen. Bitte suchen Sie nach der Abrechnung",
"aebd3826a195afad0a93050a7cc5d73d":"Die Löschung dieser Karte macht keine offenen Gebühren rückgängig. Bitte suchen Sie in der Kreditkartenabrechnung Ihres Kartenanbieters nach den Abrechnungsdaten für diese Gebühren.",
"a5b5136947da562f91b75daf8156f50e":"Kartendaten",
"9ed31ad23a1578ce480d31207decf1d3":"Karteneinstellungen",
"def15f2e007fc69886230fdb2511305c":"Kartendaten",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Kartendaten",
"67cfd260078ca32027c2fa4a4a2d29f4":"Karteninhaber",
"4458819ea60c68c46e551a8f5cc163cd":"Kartenname",
"0858bcbd506fa3240b165e59d2f18aae":"Kartenname",
"5212736dd18ee2c31f3650ad2c2c7130":"Kartenname aktualisiert.",
"c96d62fa510c3356dc73a91d1a80e262":"Kartennummer",
"41307fee4c32aefb5383a98fe3d6e45e":"Kartentyp",
"3f8045c3358621dad4346a44de0c05a8":"Name des Karteninhabers",
"879a6b452033cf5f3a4bb3e1837a0386":"Stadt",
"593334553ef852024e172505ec3c5f21":"Klicken Sie hier",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Codierung",
"df931751d5790d74fa46707aaf7a26ae":"Codierung 1",
"7ac7154bc776aff52a71b0d83b860561":"Codierung 2",
"586fcf19136ae5e26795e9055b2e2995":"Codierung 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Codierung 4",
"379bb8e71bb090badebbb0751de86f11":"Codierung 5",
"3e84d13627b3d090ab2b8470af98340a":"Codierung 6",
"33b94b581f3792049992663396e51cb8":"Codierung 7",
"b73fbc32e439655833ee99126dd0e55f":"Codierung 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Codierungsliste",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Codierungseingabe",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Codierungseingabe gelöscht.",
"cada62a39ee72d790dcdbb25160f8783":"Codierungseingabe gespeichert.",
"f645bf50888943ee1384693e56a0f475":"Name des Codierungsliste",
"a999182acbcd77c15299f79181b630be":"Codierungsliste gespeichert.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Codierungsliste",
"d0b96596ce6baec5aabb8a0690ab30e1":"Kommentare",
"ff9cb8842f832d21fb160b3d3784ce79":"Unternehmen",
"715b59a64e686eb91a21114644ffe39e":"Der Firmenadmin wurde entfernt",
"8758e9a01253c097a42393fda9610715":"Unternehmens-Dashboard",
"e118b41009918b326db6746856831cbc":"Firmen-ID",
"4dba88b05b3fa8875128e19e88c512b3":"Unternehmenseinstellungen",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Unternehmenseinstellungen",
"f2ea6d33de8182d97bf2f13354af4893":"Unternehmenseinstellungen: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Firmenadmins",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Genehmigungslimit der Firma",
"7e2f778da14a726b53e05926a3483f2b":"Firmenkreditkarte",
"7313fd1db3d63858586d1c0f89251d6e":"Firmenname",
"07721e91dd10ff8831f0d24e47cc1eab":"Firmenname",
"203221610ca4d347063c037e1a732ae8":"Unternehmenseinstellungen gespeichert.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Aktion bestätigen",
"d694cec09cdbc02b1ffe22642a63faa1":"Passwort bestätigen",
"512801312a133f29974f2259a455f39b":"Passwort bestätigen",
"f54c38ed0b9869d929ffc0bd5310131f":"Bestätigung - Ihr Konto wurde unter Team eröffnet {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Kontaktieren Sie Ihren Firmenadmin, um Buchhaltungscodes zu erstellen.",
"7a6a4de077398e8e138aec36e3b3d305":"Weiter mit SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Alle Rechte vorbehalten.",
"51f0d8e04f6ee626b866b37833dc2b61":"Kostenstelle",
"e043f60a02f157d0f31a42f0db67cc87":"Konnte nicht eingeladen werden {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Ihre Nachricht konnte nicht versandt werden. Bitte versuchen Sie es erneut.",
"ae28083f22a0b2fc5637614bce0976e0":"Benutzerdefinierte Liste erstellen",
"738078dc992208e05406f935995dc0c3":"Benutzerdefinierte Liste erstellen",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Neue Liste erstellen",
"0f5783c3921b3d7398d281644bbe5bde":"Anfrage erstellen",
"6d62f934f2304701f88bdcfbc688de7e":"Konto erstellen",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Profil erstellen",
"f6b7d0d8531ea268f988005dd2147b2b":"Karte erstellen",
"1a083675d1453448e4645403bbe52dd9":"Benutzerdefinierte Liste erstellen",
"c10ab11bdfb001da74850ed89c45482f":"Neue Anfrage erstellen",
"43a9c06398408bfe77d06ffc2c1c3b87":"Neue Anfrage erstellen",
"68bf17fd3f54a89c0a84224e2f9a971c":"Neues Team erstellen",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Team erstellen",
"3b8e665101b9b3657f8a68c5305947b7":"Erstellt",
"40e91f2907181ffa8e62567b3a407e60":"Erstellt von",
"8929f7aacb969ef6353aba9903eb362d":"Kreditkarte hinzugefügt.",
"d86688c26e8ac5eb9facc971b6fe5155":"Währung",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Währung",
"500ded53f64e737a5e4337675ac9b7ce":"Name der benutzerdefinierten Liste",
"b79a95f04fd829fb2e8d0313e522a94d":"Täglich",
"fb7add662cf7042355319a975fab6ae3":"Datenverschlüsselung",
"6bded249dbfafd14490eea8dd37e1f51":"Datum",
"46e908a57cb09ef947af8c4a93a09716":"Datum",
"e4213104a55eceb60e00408699438dcd":"Team deaktivieren",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Indem Sie ein Team deaktivieren, werden alle aktiven virtuellen Karten storniert und kein Teammitglied, Manager oder Inhaber kann auf das Team zugreifen. Nur Sie und andere Firmenadmins können ein Team reaktivieren. Sind Sie sicher?",
"0d0d5677bee2b99884802638e507160a":"Ablehnen",
"b5e3483aae9d36ce1440fe02904d6da1":"Anfrage ablehnen",
"00f3db97287cb17ee9c2a131f2141a65":"Abgelehnt",
"74fdf2e0ad0de26fe28b0b72ba44f494":"({count}) abgelehnt",
"268ed9551ae9f56647eaa394e43c160b":"Standard-Genehmiger",
"b77589ac3ac2317cfc96d7013658d5c9":"Codierungs-ID löschen: { entryId }, Eintrag: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Detaillierte Abrechnungen und Berichte",
"dd0a6194e8a6ae14f3e1379a1640de15":"Wollten Sie {fullName} ({email}) aus dem Unternehmen entfernen?",
"1a98048b34090da8b199135ccb4c1186":"Eindeutige Zahl und CVC verbergen die echten Kartendaten",
"3cf132b60fe16b816ca227bbcad60537":"Nicht löschen",
"375ae8224d7d35393974775cebe8605a":"Benutzerliste herunterladen",
"54b3bfd45a293b441d441aab1a50e644":"Bericht herunterladen",
"60cf3b94e5df14a504825068928fc118":"Berichts-CSV herunterladen",
"7341a4c73c0b688484304c37da4a6d8e":"ABLAUFDATUM",
"f628f8ad44fbd6c70c99fc778407b666":"Durchsuchen Sie ganz einfach historische Transaktionen und erkunden Sie Trends bei Mitarbeiterausgaben in einem bestimmten Zeitraum.",
"94bb409d034ea20697ae09d812e55365":"Benutzerdefinierte Liste bearbeiten",
"2974e704676cb79666bc236d8658fa97":"Profil bearbeiten",
"9ff205f99ed4002d049f855f965ca6d8":"Benutzer bearbeiten",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-Mail",
"0534c00af758d42ebd8b0bd772eab074":"E-Mail",
"0c9aa1fbdf925065d25161eab5e51d95":"E-Mail",
"ea78fac68766a48b1b0f41305b7e9898":"E-Mail",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Der Mitarbeiter fordert Geldmittel von der Firmenkarte an, um den Kauf zu tätigen. Anforderte Geldmittel können mit Ausgabenlimits versehen werden.",
"829cff16ce0b13810c914c161b2a9fba":"Alternative Funktionen zum Abrufen von Benutzern aktivieren",
"741387c7bd71e2b4a9601af9a6b9490e":"Automatische Weiterleitung von Genehmigungsanfragen aktivieren",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Standard-Ablaufdatum für virtuelle Karte aktivieren",
"bb319710dad93e78cc6f58c615bdf210":"Whitelisting von E-Mail-Domains für eingeladene Benutzer aktivieren",
"4700ced24cd278c3149ffa983006035c":"Filtern nach Kostenstelle aktivieren",
"3fe06ef8f551c4cb4c0375362e25521d":"Globalisierungsoption in Benutzerfenster aktivieren",
"2787d4bc9752272ac36b499ce7ad93ab":"Virtuelle Mehrwegkarten aktivieren",
"6ee44e2280c9ce5009568846cbc3dd14":"Abwesenheitsnotiz aktivieren",
"1941ca87053df0cebc6091ede92c18ea":"Personen-Ansicht für Unternehmensadministratoren aktivieren",
"e50282c2ec7afb948d2c0b428db5d5ff":"Quittungsupload für Transaktionen aktivieren",
"268694cb2172918fe60f835919bf20ec":"Quittungsupload in Abrechnungstabelle aktivieren",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Eigengenehmigung aktivieren",
"d17c7acf598b569cce8a2bb97e0bb215":"Virtuelle Einwegkarten aktivieren",
"cd731ca218c9e14caabefafe003cfa6d":"Virtuelle Abo-Karten aktivieren",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Weiterleitung beenden am",
"c1f665a814894b17d603b7052b631d90":"Neue Karte anmelden",
"6b6cea93e07e596be70f7b76801e1516":"Neue Karte registrieren",
"7d6ac1ac9cba1620b61908842ebb106b":"Quellkarte anmelden",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Quellkarte anmelden",
"21a642b339c3eabf2789c0b05dcf828b":"Meldn Sie Ihre erste Firmenkarte bei GO an.",
"6efa8dca4fba23a190838746879fd704":"Geben Sie eine numerische ID ein",
"02c3457577da9dc8dba6e6f9380a248e":"Geben Sie einen einzigartigen Teamnamen ein",
"40feb3fd1fa529cb599aeac9607664a4":"Neuen Eintrag hinzufügen",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Beispiel: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Ablaufmonat",
"b8bc74aeeb2de3492891c97361de107d":"Ablaufjahr",
"6a0b8d521500014d99dd192d8ce8403d":"Ablaufdatum",
"8cf274169a732340430fe7cc5236e0e7":"Die Dateigröße sollte unter {maxFileSize} MB liegen",
"58bb9c6f074e777ab1829b41acb18a81":"Vorname",
"7458b55e967e51791d98e77458631e44":"Vorname",
"3bbe52e71bc5c212cea77983f1a5b748":"Vorname",
"9b49c88fa20cc006baaac15c857c8781":"Für einzelne oder mehrere Käufe",
"bb7a2907073bd760f4e82097132637b1":"Passwort vergessen",
"26bdfaa592f3629273f13c22435b323d":"Haben Sie Ihr Passwort vergessen?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Weiterleiten",
"3f9fe6adc14ce5776097170653a24462":"Anfrage weiterleiten",
"b774e35683f5737c36bf0cf3d6785973":"Anfrage weiterleiten",
"628faa5054a912b1e470414f77057e6b":"Anfrage weiterleiten",
"852b4065e1837fdc77940b6411a6d902":"Betrugsverhinderung",
"616551bd10d95aca439401d6a7769d96":"Frachtbetrag",
"a65a230918e9c11e1ef8824745e26a3d":"Los geht‘s",
"7d4c4466b7716347ce3673acaa1983f7":"Nutzen Sie GO optimal",
"11415a07658cfef80eb166896d77ad98":"Bereit für noch mehr Einblicke in Ihre Ausgaben? Tradeshift Go passt jetzt noch besser zur Art, auf die Geschäftsteams arbeiten:",
"ac0ff5d14286ef74614a9c6021f08f69":"Überwachen Sie Ihre Ausgaben und Ihr Budget in Echtzeit, erhalten Sie Einblicke und informieren Sie sich über alle Beschaffungsaktivitäten.",
"db9071ab833054307252a936e7f60e1b":"Jetzt anmelden",
"24dc4bb00841b004031fca3a9bb9bf51":"Go-Kartenanfrage storniert von {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go erinnert Mitarbeiter an fehlende Quittungen, welche einfach an eine Antwort auf die Benachrichtigung angehängt oder, im Falle einer E-Mail-Quittung, an Go weitergeleitet werden können.",
"a73603f0bc610ecea9ef9c793b802538":"Go arbeitet mit Ihrem Kreditkartennetzwerk zusammen, um dieselben Standards zur Betrugsverhinderung zu garantieren, die Sie sich von den renommiertesten Finanzinstituten erwarten können.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Gute Neuigkeiten! Ihre Kaufanfrage wurde bei Tradeshift Go genehmigt",
"ee397c9cb0668cc9cba62e1c1e619615":"Haben Sie Fragen oder Feedback? Kontaktieren Sie uns unter",
"9df1d3de68d663b848101df3c7b58e39":"So geht's",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Ich bin mir sicher",
"84074732eb8d1b4094404cd750baf949":"Falls kein Wert eingegeben wird, gilt das Unternehmenslimit",
"138e8bd6102a7eea70a123ff03b1cd79":"Falls kein Wert eingegeben wird, gilt das Teamlimit",
"e5756af4d7e5ad1236ccfbb858428421":"Ich habe mich nicht mit dieser E-Mail-Adresse bei Tradeshift Go registriert",
"4f004f75f47dbfecdb6d09990596e530":"Falls Ihre E-Mail-Adresse noch nicht registriert ist, senden wir Ihnen eine Aktivierungsmail mit Informationen über die nächsten Schritte.",
"123837f267de602fb05361b3b1197c1e":"Falls {username} zu einem bestehenden Konto gehört, versenden wir eine E-Mail mit Informationen über die nächsten Schritte. Falls Sie nach wenigen Minuten keine E-Mail erhalten, sehen Sie bitte in Ihrem Spam-Ordner nach.",
"45efd96cd93525a9c17222217a37d324":"Unterstützte Bild- oder PDF-Dateiformate",
"fa6ae823f9ab8bf91c246efe2517956c":"Importieren Sie Ihre QuickBooks, NetSuite oder Xero Kontoeinstellungen direkt in Go. Exportieren Sie die neuesten Transaktionen mit einem Klick in Ihre Buchhaltungslösung.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inaktiv",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Die Eingabe enthält ein ungültiges Zeichen.",
"62c7be09da43f8f96a60ef3967eddc2c":"Wir präsentieren ein vollkommen neues Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Ungültige E-Mail-Domain",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Personen einladen",
"3809b41b14c55c3fcf54cd813d8e21f5":"Zum Team einladen",
"fc90a11805961b0d7b5a5545e4d9bc05":"Benutzer einladen",
"6f7f4acde92d267417f5d8a60d32400a":"Mehr Personen einladen",
"09ecd2ca15bb5c38dc10f653118346cd":"Neuen Admin-Benutzer einladen",
"3f576ce504f53f972442a63a170c5418":"Neuen einladen {role}",
"0215e052437ceea4e5cd562431b34ced":"Personen einladen",
"8aaf2c77df644b7e8524120f66da7d5f":"Rechnungsbetrag",
"1b7dc8290f1205f092cc9133e0cde385":"Rechnungsdatum",
"1a6fb210308df8e0135e49e76aaff31e":"Rechnungsnummer",
"613da3d0cd586ef0360e0d93eace9508":"Ausgestellt am",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Es dauert",
"3322b671105c65511fbab1966b7fdcf6":"Sprache",
"b1d946192795a58aea6a61f4f923acb7":"Letzte 4 Ziffern",
"b3c6b8db4ef6dcdcb451303e65548816":"Letzte 4 Ziffern",
"0382123b9c847000fceff2484c665e39":"Die letzten vier Ziffern",
"19f7c0055e150e9e3e66e85c2993799c":"Nachname",
"662c84bede8a7bf91ab15bcb73b48b49":"Nachname",
"cff8fbef5aac2523344b9535bd56973c":"Nachname",
"c3510b2184a92ff12e07a2d1b9a461e6":"Ermöglichen Sie es Mitarbeitern, Zugriff auf Ihre Firmenkarte anzufordern, ohne Ihre Kartendaten bekanntzugeben, während Budgets eingehalten und jeder Kauf verfolgt wird.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Das Limit kann auf Team- und Genehmigendenniveau überschrieben werden",
"9bf5d56627e500cad9f7ad21c3e7966d":"Auf bestimmte Ausgabenkategorien beschränken",
"e80a6d4232e52f6bfabee02e17403b59":"Verbinden Sie eine dieser Quellkarten mit diesem Team oder melden Sie eine neue an.",
"803570ab9f0fba5ff35ae2749200501f":"Karten werden geladen...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Login",
"ad96c1ca962a883652dadb845f439415":"Einloggen",
"072ca67e735adbed54acf41321370701":"Einloggen",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Einloggen",
"b246fe8836c906f1b56342378ca88895":"Tätigen, genehmigen und überwachen Sie Käufe in Teams, die Ihrer Organisationsstruktur entsprechen.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Abrechnungen verwalten",
"95d672ab621e531ae1a69798e0e4b56c":"Verwalten Sie alle Ihre virtuellen Karten an einem Ort.",
"96f2e88a167f476413b35aa711709e6e":"Abrechnungen verwalten",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Der Manager erhält jede Anfrage zur Prüfung und Genehmigung. Ausgabenlimits können ebenfalls angepasst werden.",
"4f449985e83c1f9ca2e8f82b0707a371":"Der Manager erhält jede Anfrage zur Prüfung und Genehmigung. Ausgabenlimits können ebenfalls angepasst werden.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Manager",
"27f8111a955975ea6fd04f748d24cb4a":"Maximales-Genehmigungslimit",
"5b1d9cfb78c55e75b47c62435d9299ca":"Mitglied",
"41e307c1874e2e82296b226e9bae4a5f":"Mitglied",
"6f9612aa1582373c2c1ba4e144132100":"MItglieder",
"590429a0fcc42af85648d0fd70601dcc":"MItglieder",
"3f50e62c8488d83ad84f69ebda853897":"MItglieder",
"e0fc0acda5b74b93e797756dbbba67bc":"MItglieder",
"c7ef9eda38b7eaee1f20031387a8bde7":"Monat",
"40dd08b507752bef4e3c4b36838317d5":"Monatlich",
"d712b91f7498f212047aad4c6d0f8088":"Neueste",
"12110d95b3d7a495afa4f28ac190f63e":"Neueste",
"2f11ce3865f79ee7e5ee64d728082264":"Neueste",
"eadc93865655cffc6a9863fdda5693e1":"Muss mindestens 10 Zeichen lang sein und Groß- und Kleinbuchstaben enthalten.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Muss mindestens 10 Zeichen lang sein und Groß- und Kleinbuchstaben enthalten.",
"50d0a69aa59df5201018771a06ea12e7":"Muss einzigartig sein",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Muss einzigartig sein.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Meine Karten",
"c36602f50d35c2816f0ba8a196b70876":"Meine Anfragen",
"ff6e372aa3eb685a39a701963db35b2f":"Meine Anfragen",
"d621826c1ceade262589af04e655e824":"Neues Passwort angefordert",
"35dc3304b61a9e69dc3a393123658837":"Neue Anfragen werden in diesem Zeitraum automatisch weitergeleitet.",
"e788124801b4727d121f60d059a2a4a7":"Nächste",
"eca804f1d53dfd984ffd93e2d00fc284":"Kein Genehmigender zugeteilt, bitte kontaktieren Sie Ihren Programmadministrator",
"abdf7e93f8509399a63108a2b1a25546":"Es können keine Aktivitäten angezeigt werden",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Keine Karten in Ihrer Wallet",
"87127e1e9f5bf922d0a4f948b68697c8":"Kein Limit",
"40512efa6b75ef0c7924b1ee3070fca9":"Es können keine Mitglieder angezeigt werden",
"dcda493afcb2415d5ca49a17d1af6f50":"Es wurde noch keine Abrechnungen veröffentlicht.",
"f0efb26e623bb990532e375d5c8875ec":"Nicht verfügbar",
"bc0cf602d1978e814070bb552e576a32":"Nicht gemappt",
"09e126c317429ccb52ad323c32293648":"Anzahl der Zahlungen",
"75b00b969b9d3b520929f1e652637b70":"Anzahl der Zahlungen",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Anzahl der Zahlungen",
"bd44ba180aa356c5755fdedd32adf03c":"Oh nein! Beim Erstellen einer Anfrage ist ein Fehler auftreten. Bitte versuchen Sie es noch einmal.",
"c396b3728dd7393aa9e5427990c132a7":"Oh nein! Beim Download des Berichts ist ein Problem aufgetreten.",
"42c07764b07dea1ee7de7605721c3f5f":"Oh nein! Wir konnten kein neues Team erstellen.",
"e3a385878ec94bac12a99490d0a8e85a":"Oh nein! Wir konnten Ihr Konto nicht erstellen.",
"17f2dae79677017e2c2e879578c4e09a":"Hoppla! Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal!",
"78c135de545c1663c9768226492a44a3":"Oder Single Sign-On (SSO) nutzen",
"9c038198d567c5379b055ff5ff662e9f":"Abwesend",
"5d7e45f169e2f6968dc58f43b5267353":"Abwesend",
"ee589a20c262527db970671fb2c4f6bc":"Abwesend",
"4fd0c753fce49a016b98d988a3895223":"Überblick",
"2789c5d5c9c042858f45aadc4e0de058":"Überblick",
"083b7090b66ebd0307a18499ac7b676e":"Die Seite existiert nicht",
"a21d205920893cf579a7655a1430fe30":"Übergeordnete Karte",
"fd35b7ce938e404fdb65de3bb749c528":"Übergeordnete Karte für den Bericht",
"e8c7d2eea7db66ecb44751091a582304":"Passwort",
"bb79e316e00fd21138750b6986e44455":"Passwort",
"6cae2279088580976d84119d95097cae":"Passwörter stimmen nicht überein",
"4baec0159d3074f84c0eebcc09ba4cd8":"Passwörter stimmen nicht überein",
"b676587115243921602742cb8f696a6a":"Kartenerstellung pausieren",
"db4a26c871ef0b970b74e1f71763098b":"Ausstellung von neuen virtuellen Karten pausieren",
"33fc1e6d01b785e3a815c28ef7d56667":"Offen",
"6ea62e4811b68df48b467e8d367aac2f":"Ausstehende Anfragen ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Personen",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Personen in einem Team können drei verschiedenen Rollen haben",
"e2d34cb1409b9778d34c4b74ecee1df6":"Bitte geben Sie eine positive Ganzzahl ein oder lassen Sie das Feld leer.",
"8da247d5d736c87122404eff1e5c0c73":"Bitte geben Sie ein gültiges Limit ein.",
"65e154267ac283d963cbc7be832969d9":"Bitte geben Sie einen gültigen Wert ein",
"28b4450d417065a69a395f567967d46c":"Vorausgefüllt mit angeforderten Ausgabenlimits",
"7bbf151d8493170f206a23c2ecc6acd0":"Datenschutzerklärung",
"51602962f2d1e7515943b4a6c66562ce":"Profilbild",
"a51a17940c70a3123da4d68bed562c40":"Profil gespeichert!",
"4d398249d01ab72d697a7e219493c637":"Anbieter",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Anbieter-Mapping",
"89b1deb2ffe12a13941c362abaeab01b":"Veröffentlicht",
"d8d806d48052159e3ff3b224f3f53586":"Zweck",
"089917619c78af2efb30be0bb7ce076c":"Team reaktivieren",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Grund",
"e8484c78fda9f453eeb942ba4945f040":"Neu zuteilen",
"ff2fbc1172ba26776ad0982624cd2713":"Quittung",
"588e690db177ee0c7a7cadb39cbd5efc":"Geldmittel jede Woche, jeden Monat bzw. jedes Jahr aktualisieren",
"32955937daca05b2084556f248692baa":"Registrieren",
"f15bb755ba24abe6ab106637292175ec":"Verbleibend",
"579782461133a8b8cc6bd77798961d82":"Karte entfernen",
"e475edc165372f8c8deb532aa85d9950":"Liste entfernen",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Liste entfernen",
"f9769561315b964d0b3b4887ec208977":"Aus dem Team entfernen",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Quittung entfernen",
"ee181cef581fc8e8217e66b5762db898":"Wenn Sie eine Liste entfernen, wird diese nicht mehr verwendet und alle vorhandenen Einträge gelöscht. Falls Sie eine Liste vorübergehend entfernen und die Einträge behalten möchten, können Sie die Veröffentlichungseinstellung deaktivieren. Sind Sie sicher, dass Sie diese Liste entfernen möchten?",
"feea41d0bc1d577a07c8b6e1297abbde":"Das Entfernen einer Quellkarte aus einem Team deaktiviert keine vorhandenen virtuellen Karten.",
"d09483824a2e0d6194a4c3f32311e752":"Eine Quellkarte aus einem Team zu löschen, deaktiviert keine vorhandenen virtuellen Karten. Sind Sie sicher, dass Sie diese Karte aus dem Team entfernen möchten?",
"7ca4b1bf1346e179a29da97f260a93a4":"Umbenennen",
"db195ec9c818b8e92da142374e20d1bc":"Codierungsliste umbenennen",
"2fd93e229fe9ea754808805d3fef9c13":"Codierungsliste umbenennen",
"aa1e74d32805823f802a11b33c60c38b":"Berichtstyp",
"6ac78d97a8cb7f060e43e596e3e23535":"Anfrage storniert.",
"25deccb1980291a0fa9997109c731440":"Demo anfordern",
"9f2754d87686903b1500ce8e8a55b377":"Demo anfordern",
"92ac924f72679f897e26f3e615be8c22":"Anfrage weitergeleitet.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Informationen anfordern",
"270185007853cd779011ae0e8433f723":"Anfrage neu zugeteilt.",
"e5a059c9ba2691910321339cce989f9d":"Anfrage übermittelt.",
"77c88a231edfa7075114d91d2397e42d":"Anfragetitel",
"cee91b833c4358acf0c554f746557e71":"Anfrage abgelehnt.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Anfrage abgelehnt.",
"9e56abbbf87420eeedd625ce786515c7":"Anfragender",
"b4cb4426e44fee9580af71912a764536":"Anfragen",
"df309e888c29fc447d0309329b39ce93":"Passwort zurücksetzen",
"5daf278da19ecbaeb7b96554cb4db10f":"Verbindung jetzt erneut versuchen",
"31e9501526d2114e4a01fdbf076de097":"Position",
"6fbceb787429f6f81117a1d5a58b4610":"Position",
"473b814ca86841ef672ccf9f7dac28d5":"Position",
"f1e16d5fd643e039d865b6aec7c986f9":"Position",
"ec78345a5975bc0f8efaee5256b43c2c":"Speichern",
"2cecea57b47d8f4a45c706287ff7d64e":"Änderungen speichern",
"15774be5c338a39169917c7d32781dd0":"Speichern und weiter",
"844095b4b8f580737b8717672501048e":"Sagen Sie etwas...",
"7e66f676c2e7b04514aca3a0935530f0":"Nach Codierungsdaten suchen",
"5293ce9335ec2d176a6e838bf76404c6":"Nach Beschreibung suchen",
"efab3b0c95f263cdd0a8466329a387eb":"Nach Beschreibung suchen",
"d227b38dcbc4548c092b0836a7101506":"Nach Händlername oder Transaktions-ID suchen",
"3629383abf1d5f85efd759f718159b40":"Nach Name suchen",
"a034fcd6719228b28a6fc22d3a46ea8a":"Nach Teamname suchen",
"8ac7e0d63f343b548482d5f367f5b3d9":"Nach Teamname suchen",
"858323eb52cb45446597ee46330a0540":"Nach Inhaber der virtuellen Karte suchen",
"111bfcbff671e13de117ed5195fb6773":"Wählen",
"bcfecd59eb93e5314873d84ddf35aa58":"Wählen",
"909a4414ea573218bff2d533eaab46ea":"Enddatum auswählen",
"114b206ca1ff1c0bc52abef342059b2a":"Teamoptionen auswählen",
"81a02e424615fb41a20b8d0f13441c88":"Teams auswählen",
"b135b36495618e6692e7fbaf0a0aa83e":"Wählen Sie einen Monat",
"677e90855e164c00a28b5c4763ac67e0":"Position auswählen",
"d01570f3774738395b8ba3c64ce54c76":"Wählen Sie einen Staat",
"7dee3f06c70626881efb0cd6af6b63e1":"Wählen Sie einen Benutzer, an den die Anfrage weitergeleitet werden soll",
"4c7bb810835a1540ddc1f6501da5a51b":"Wählen Sie ein Jahr",
"3ab198d2678af2b5b32180e4b39602ba":"Genehmigenden auswählen",
"f502ba79f2bd010c5e77891812400958":"Karte auswählen",
"936027182d446e27ba209ea921ef7502":"Standard-Ablaufdatumsbereich auswählen",
"b22c893254ccf3bd34faaee511cf7ca8":"Dauer auswählen",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Wählen Sie aus Voreinstellungen für Codierungslisten oder erstellen Sie Ihre eigene neue Liste.",
"42cce5fa376b45bf290f86c684d69d96":"Anbieterfeld auswählen",
"b761773d46699b60e8d6874b85593374":"Teams auswählen",
"3a9396756942ede98d0cb6afc1032b15":"Genehmigenden auswählen",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Selbst",
"03e9d2c128af288126d94f631c5396be":"Anleitung senden",
"299a7e961804e2d649f06b5dc4362c29":"Einladung senden",
"155e7b3f7db7bf10b03b5f41de54eae1":"Nachricht senden",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Ablaufdatum festlegen",
"f89ec96a4b5bb639488ab742efa76a6a":"Neues Passwort festlegen",
"7d5377ad70d57728a45d3c0639de5544":"Einstellungen gespeichert",
"14313f11dee735f2144953e720776c28":"Abrechnungsbetrag könnte sich unterscheiden, wenn Trinkgelder oder andere Gebühren auf den Kauf angewandt werden",
"199000fe0cda0adaaa9b864aa7447cb9":"Abrechnungen",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Abrechnungen",
"6e6ac6ff3bd722e37874fb1ea7115818":"Richten Sie Ihr Passwort ein",
"9b4ecf052980e80bf5c65a415d470a43":"Teilen und steuern Sie den Zugriff auf Ihre Firmenkarte",
"ba746d78772d6be26cc508ba989becb0":"Muss kleiner oder gleich {maxRequestLimit} sein",
"9cb6c9731c94fa22733eb28489494d36":"Transaktionen ohne Quittungen anzeigen",
"7d474b60aeb7cc25b519e806fca71e12":"Melden Sie sich mit Ihren vorhandenen \n\n Tradeshift-Anmeldedaten an",
"16080cd4e8319ef3e7de21b6c19d1437":"Da stimmt etwas nicht",
"8f358a370224baf849f9652810c68db2":"Es ist ein Fehler aufgetreten",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Es ist ein Fehler aufgetreten",
"99af87c475a4d40ef732deed96f11ed2":"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Es tut uns leid! Wir haben derzeit Verbindungsprobleme - versuchen Sie es in {retryConnectionPeriod} Sekunden noch einmal.",
"84debc8e41646cd49ac2b14708ca6d73":"Leider ist beim Löschen des Codierungseintrags ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Leider ist beim Speichern des Codierungseintrags ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"689d3418ee8f996ad25e152e80de63fe":"Leider konnten wir Sie derzeit nicht abmelden.",
"b48efd4521ecd6428987d24636a4349f":"Sortieren",
"776a95003d3ec1dd58af69aedbc08579":"Sortieren",
"1b783238028c0ae085ca69ed823f4d1a":"Sortieren nach",
"be70d19bf13c935d653b2c4fe7d36224":"Name der Quellkarte",
"564f8c942e7d1bdda5251545612fa348":"Name der Quellkarte",
"aa284602c746aad4a6de6366c24221b8":"Quellkarten",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Quellkarten",
"389d471e79a43440b3278b0fc7187d9e":"Quellkarten",
"106342f689b57145817b00d6fe383f91":"Bestimmtes Enddatum",
"2a5e19387ff300509876e3bae99b7ab9":"Beschleunigen Sie Ihre Buchhaltung",
"ca34d586bbdb7f368f1009305bccbe76":"Ausgaben-Dashboard",
"4583120998b44c0a5982cf85dd938d2f":"Ausgaben im Verlauf der Zeit",
"244d724806247953233a0bbfcbf8da4c":"Ausgabenlimit",
"d39b83dd151c8df6370460a458294ccf":"Ausgegeben",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Ausgegebener Betrag",
"5de2859528e030ac1f2af115ebdf209c":"Ausgegeben { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Weiterleitung beginnen am",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Status",
"1f1fe111548158ec842761eca7425ff3":"Straße",
"31a0a748b1097a9e0aa95a811802bdd0":"Stressfreie Abstimmung",
"111b782f3f0794c2294aefb6693b2c50":"Absenden",
"013e2555920b51ad2a3d2c3dc482c471":"Anfrage absenden",
"1270cd8eb69ad64cc71e5de979423b3b":"Abonnement endet am",
"2802336ad3d8ff658257b88a93f4e50a":"Abonnement endet am",
"b28dd68454d7acbefafeefb42981fe16":"Abonnementfrequenz",
"907793bc80cf94d560e5f1a0250fdaee":"Abonnementfrequenz",
"29229e153308e4747edef1144de35a55":"Erfolgreich!",
"503eb46b80f374f39266e7a9aea8e63e":"Erfolgreich!",
"4f20e29cadadb9fbc7455bd4d80de567":"Erfolgreich!",
"601d04f10d558f436c78c10e90b44175":"Kundenservice",
"62558c185ea2c06c5472d1cd534f6e56":"Snychronisiert mit Ihrer Buchhaltungslösung",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Steuerbetrag",
"bf23b2fd889e3b12e0f0acf99f913eca":"Teamkarten",
"b90a650376782a9e43ed6be6135bd6f2":"Team-Dashboard",
"6adbec395fe1c4bf776f9fe5fa021052":"Team-Dashboard",
"6f2695eeb68501dbcfddbb4d265b846b":"Teammanager",
"3b779ade9c960ac95a670db0cc12e56b":"Teammitglieder",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Team-Einstellungen",
"dc5235e1b88320d3ba7501e4c30985a3":"Team-Einstellungen",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Team-Einstellungen",
"a2eb92bfd7502f47b3cd170bc68ac638":"Team-Einstellungen",
"f1ec84f051f19f0d0e301a709b13dc45":"Teamausgaben",
"fbacde3a262ea8696e30135e8f987a44":"Team-Wechsler",
"74b9578ea5a620f5fef102188e42c02f":"Team-Wechsler",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Teamaktionen",
"3aa978fa7ef886621285b93257d428c3":"Team-Genehmigungslimit",
"a41f9df131c221a99926de7e2138653a":"Teamdaten",
"941c7da8f256f91ba3819f991315a100":"Teamname",
"c5d04e10cc2f0b53c2bda893eae09178":"Teamname",
"504a8ba642ba37c9a743d0fabb2bd371":"Teamname",
"e768a8e1e3d6a6bf470587dad75423fe":"Teaminhaber",
"e2d824067f8bd871756688c58c382196":"Teaminhaber",
"dca232ccc849c5ff5611255eb82baf53":"Teaminhaber",
"3b61bd586225790cf132591383d15448":"Team-Wechsler",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Teams und individuelle Limits können das Firmenlimit nie übersteigen",
"4b4fe189a48d8eb06d836afa8fb6317a":"Teams aktualisiert.",
"2a17f9a8c610db29fec63fd530c29fdc":"Teams, die die Karte verwenden",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Teams, die das sehen",
"317868f3804ba4ed5d99dd9919c798ba":"Servicebedingungen",
"e8c9a9847db2ccd97524aa06f1af9d82":"Servicebedingungen und Datenschutzerklärung",
"38852078519c5c46715d8a034fc4901b":"Servicebedingungen und Datenschutzerklärung.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Vielen Dank für Ihr Interesse an Tradeshift Go. {cardLabel} Kreditkarten werden in Kürze unterstützt werden. Ein Mitglied unseres Kundenserviceteams wird Sie benachrichtigen, sobald es verfügbar ist.",
"f7047b35a069d0790a0a348a29c846a2":"Vielen Dank, dass Sie Ihrem Team von {companyName} beigetreten sind, um geschäftliche Käufe mit virtuellen Go-Karten zu tätigen.",
"133b0a70b1a956889164621380c378f9":"Die Aktion, die Sie durchführen möchten, kann nicht abgeschossen werden. In einigen Fällen hilft es, es einfach noch einmal zu versuchen.",
"18f5a1872e156f1aee84ae321780d952":"Die angefragte Betrag liegt über dem Anfragelimit des Unternehmens von {requestLimit} $. Bitte versuchen Sie es mit einem niedrigeren Anfragebetrag.",
"867e3d4d3c78990c6852fc5348706ab2":"Die angefragte Betrag liegt über dem Anfragelimit des Anbieters in Höhe von ${providerLimit}. Bitte versuchen Sie es mit einem niedrigeren Anfragebetrag.",
"570f5f4658b03dda7a910f56c86a3bd3":"Die Karte für diese Anfrage wurde gelöscht.",
"3075c56105863546e3368ffead7a146b":"Die Karte wurde gelöscht.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Die Codierungsliste, die Sie zurückziehen möchten, ist mit einer Integration verknüpft. Wenn Sie sich sicher sind, dass Sie diese aus der Integration entfernen möchten, kontaktieren Sie bitte den Kundenservice, welcher diese Aktion durchführen kann.",
"756ade8d71ec487a4b48d7c4277ea239":"Die Codierungsliste, die Sie zurückziehen möchten, ist mit einer Integration verknüpft. Sie müssen zuerst die Zuordnung entfernen, bevor Sie die Veröffentlichung dieser Codierungsliste rückgängig machen.",
"4f40335f7c018986c95458183bc0c136":"Der Link zur Aktivierung Ihres Kontos ist ungültig oder abgelaufen. Bitte kontaktieren Sie die Person, die Sie eingeladen hat, um eine neue Einladung zu bekommen.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Der Link zur Rücksetzung Ihres Passworts ist ungültig oder abgelaufen. Bitte fordern Sie erneut eine Passwortrücksetzung an, um einen neuen Link zu erhalten.",
"ded30f0e288c12125ca1a77f8d8fd600":"Der Grund wird dem Genehmigenden und dem Anfragenden angezeigt",
"f294897b38fc23dd8f5de52b54ceae22":"Die Anfrage wird abgelehnt und der Anfragende wird benachrichtigt. Möchten Sie fortfahren?",
"e8c7200dc985df0a94a9d996968b4cc0":"Die Anfrage wird abgelehnt und der Anfragende wird benachrichtigt. Möchten Sie fortfahren?",
"5f3d196a2ade3f97a2a48b773fec5861":"Die Anfrage wird abgelehnt und der Anfragende wird benachrichtigt. Möchten Sie fortfahren?",
"b04c191cd6b4f27669a4f390311f156c":"Es können keine Teaminhaber oder Manager ausgewählt werden.",
"e25045557efbad7b52997b94a1fe6817":"Es gibt keine {type} für diesen Zeitraum",
"7260690577c795e999acae63bb7c51ae":"Beim Speichern der Teameinstellungen ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"f2103024f2a9c6c0335a71c60567d6c9":"Beim Laden Ihrer Wallet ist ein Fehler aufgetreten.",
"9776cb7eab9fa0d318a8144cd17de745":"Beim Laden Ihrer Wallet ist ein Fehler aufgetreten.",
"77d28a14bc900be4360b570a82eb75a9":"Es können keine Daten angezeigt werden",
"2b6feba67722337f88c1443909fe446f":"Beim Hinzufügen des Genehmigers ist ein Fehler aufgetreten!",
"24b7212afb97c5d23faafa2ca4559095":"Beim Hinzufügen der Kreditkarte ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Beim Hinzufügen der Quellkarte zum Team ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal",
"5a8e1b922d1cee746ac6794599b10709":"Beim Löschen dieser Karte ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Beim Löschen dieser Karte ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"22361c5d4df61ad7ae9792a3baf5729f":"Beim Erstellen Ihrer Liste ist ein Fehler auftreten. Bitte versuchen Sie es noch einmal",
"098755bfddeaf0428c7feb6b69af9b6f":"Beim Ablehnen der Anfrage ist ein Fehler auftreten. Bitte versuchen Sie es noch einmal.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Beim Ablehnen der Anfrage ist ein Fehler auftreten. Bitte versuchen Sie es noch einmal.",
"7d461b6b7b54dbfa9c83243217cd1053":"Beim Löschen des Codierungseintrags ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Beim Löschen Ihrer Liste ist ein Fehler auftreten. Bitte versuchen Sie es noch einmal.",
"beb43d7800cfff47e08cd61842d953bd":"Bei der Weiterleitung der Anfrage ist ein Fehler auftreten. Bitte versuchen Sie es noch einmal.",
"f7775350f35b8365fb9803232d9b792c":"Beim Abrufen der Kartendaten ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"95abaa922c6c1648ca9c39ac4776896f":"Beim Abrufen der Nutzerdaten ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"9d7e4ead60137041ae8a845aa6cf9979":"Beim Laden des Nutzerprofils ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Beim Laden der Benutzer dieses Teams ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Beim Laden Ihrer Einstellungen ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"b2f48028c400befad5f1d57f769ef7cd":"Bei der Neuzuteilung der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Beim Entfernen der Quellkarte aus dem Team ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"19dfcd761006496c1d20351c04643e45":"Bei Löschen dieser Domain ist ist ein Problem aufgetreten. Bitte versuchen Sie es noch einmal",
"149651b5784481c68aaf4ac27bb1f907":"Beim Speichern der Codierungsliste ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Beim Speichern des Profils ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"749ddc235744b6b371668ec0bb049176":"Beim Speichern des Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Bei Speichern dieser Domain ist ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal",
"04cf86909808f430b8b1904fb422e0d3":"Beim Speichern Ihrer Abwesenheitseinstellungen ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"2eaf6ee97d984598c95e0494d72aa492":"Bei der Übermittlung der Bestellanforderung ist ein Fehler aufgetreten.",
"148f847d3beccaa67fcd5096ae3602d3":"Beim Aktualisieren des Kartennamens ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Beim Aktualisieren des Kartennamens ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"de8cc976f7af9edf189904239e6240da":"Beim Aktualisieren der Teams ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Beim Hinzufügen des Admins ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Bei der Deaktivierung Ihres Team ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
"3f686a97a1b9fcdd73a40d2005693105":"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"2f38263789163cf8ee30c8ae88852c82":"Beim Veröffentlichen Ihrer Liste ist ein Fehler aufgetreten.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Beim Entfernen des Admins ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"007a647254e1f85b6c1e789004e05df5":"Beim Speichern der Einstellungen ist ein Fehler aufgetreten",
"c3072d080b587d70f85d68ec089f028f":"Beim Speichern der Einstellungen für Ihr Team ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"608cd0e109c3e51b0425df8921e4181d":"Beim Festlegen des Anbieter-Mappings ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"177cefc68dd318ea75673326e77d8206":"Beim Rückgängigmachen der Veröffentlichung Ihrer Liste ist ein Fehler aufgetreten.",
"255d22dc4f0e338a4cf53d5b7256e695":"Beim Abrufen des Namens der Person, der Sie Genehmigungsanfragen weiterleiten, ist ein Fehler aufgetreten.",
"39ac4ec73fed8a981ad1857d989edfcb":"Dieser Betrag übersteigt das Genehmigungslimit des Unternehmens.",
"494ce3f598cbd97afe99774b5975a4bc":"Dieser Betrag übersteigt das Genehmigungslimit des Teams.",
"f2a42214e7c480c5596439becfd0b7bf":"Dieses Feld ist erforderlich.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Diese Einstellung betrifft nur die neuen Karten",
"881b7596cf985d9c246b05fcab930632":"Diese Quellkarte wurde dem Team hinzugefügt.",
"a1b1aa354d06e7815b78c6abb7448e84":"Diese Quellkarte wurde aus dem Team entfernt.",
"b348b24fc4839ee15ed0aa424689bae3":"Dieses Team muss einen Admin oder Manager haben, bevor Sie ein Mitglied einladen können.",
"188b9ed447a43916f9e323623ea3a993":"Um die Einladung zu akzeptieren",
"969de95e19a06f5ae7cec6449cbb6a27":"Summe",
"30286e57da108ee7dbc0f8fbb62d4fff":"Gesamtausgaben ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Verfolgen Sie die Ausgaben über Teams, Projekte und Abonnements hinweg.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Verfolgen Sie die Aktivität der aktiven Karten Ihres Teams, pausieren Sie Ausgaben und deaktivieren Sie Karten, die vom Team nicht mehr benötigt werden.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Tradeshift Go Passwortrücksetzung",
"5d760188301146cc7255ce18b65cb370":"Noch einmal versuchen",
"80196bc616d4a44a4997a2ea127f7b83":"Noch einmal versuchen",
"f96bbe81618b5f4a1807989eb4f69e24":"Noch einmal versuchen",
"d498d7d261b7ca56e07218d1a0dbdd49":"Uh oh! Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
"ce2e475291003852c71b01959698c496":"Nachrichten für Anfrage {title} konnten nicht abgerufen werden. Versuchen Sie es noch einmal",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Archivierung aufheben",
"d2e50be5287fd8fb436d0acbeb811864":"Einzigartiger Identifikator",
"0e2fbce967f4a209215b6f48f8eddac3":"Pausieren der Kartenerstellung aufheben",
"f2315dec5dd07802b77af3a3a5414a7d":"Bis zu 4 Jahre in der Zukunft.",
"d2f30a2f0c955271e9af123dceca501a":"Bis zu {label} in der Zukunft",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Quellkarte aktualisieren",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Quellkarte aktualisieren",
"c8f6bc61a8982158e408194e48a274d9":"Upload fehlgeschlagen. Versuchen Sie es noch einmal.",
"8a86a60cb836bdb5225cad20c5151078":"Quittung hochladen",
"e448a1ce42de0cf526200331b4ad8b23":"Benutzer",
"75edcc8fc27f41c99e28b48ff250c7f8":"Benutzerdaten",
"d1651c2afdb6c92621adbb707e4753d0":"Benutzer gespeichert.",
"5fa55883b7413526b441e999cb8089b3":"Benutzername",
"7a892b81fe2aae295f1b2d38bb67360f":"Benutzername",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Benutzer mit aktiven Karten können nicht entfernt werden. Bitte löschen Sie Karten, um diesen Benutzer zu entfernen.",
"d40fa5935f1614925cf46259c886814a":"Kartenaktivität anzeigen",
"625419857efc7e72d162c9f8204109df":"Karte in Wallet anzeigen",
"85a5e817826415651ed4991a76bb5385":"Unterhaltung anzeigen",
"09fc124dc9b8b14d6227a92bb0a4566f":"Unterhaltung anzeigen",
"d26cfc6d8f420f259f434b79593e9eb4":"Rechnung anzeigen",
"c7d0394c3206a3b74afbeabc41cc639c":"Rechnung anzeigen",
"01650aa52c5e5a22d3f38afd2c7f9411":"Rechnung anzeigen",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Rechnung anzeigen",
"b2d141897f73fc2a198ee1a7a438f89b":"Anfrage ansehen",
"02fa2f56ef72845a9dfeabea22948061":"Teamdaten anzeigen",
"2d9c55650b17dd605a1388f46c81ba38":"Virtuelle Karte",
"d0ec076e4600e854588f8da265208c0b":"Virtuelle Kartenoptionen",
"ff306d5b34ee993f9618c1f3c0209a39":"Wir konnten die Anfrage derzeit nicht archivieren. Bitte versuchen Sie es später noch einmal.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Wir konnten die Anfrage derzeit nicht stornieren. Bitte versuchen Sie es später noch einmal.",
"a813d44f6bb26c5da09671e532abe022":"Wir konnten die Archivierung der Anfrage derzeit nicht aufheben. Bitte versuchen Sie es später noch einmal.",
"d4c7310eafc0d0da89f20596a22a3abe":"Wir konnten derzeit keine Anfrage erstellen. Bitte versuchen Sie es noch einmal!",
"dca679c10accb1db481a57a0e1ba08e2":"Wir konnten derzeit keine Anfrage erstellen. Bitte versuchen Sie es noch einmal!",
"db5584a2ef29b04959bce7f93db54c56":"Wir konnten das Team derzeit nicht wechseln. Bitte versuchen Sie es später noch einmal.",
"f2af2c261fabddb01766f4f974b85a62":"Wöchentlich",
"e181ed0723a00ca7558b9d5d5258732b":"Herzlich willkommen bei Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Wir werden Sie in Kürze kontaktieren!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Welche Kreditkarte verwendet Ihr Unternehmen?",
"968d94729053e166a76ea577ce3022e2":"Geschäftliche E-Mail",
"588a075e8694ff5cc86815697e1586bc":"Arbeiten Sie wie Geschäftsteams.",
"182ae1eb5226e0646b4033883cc0900c":"Jahr",
"fe88fe7094c4ced427874b3d22326c8d":"Ja",
"9a2d4e3b22ad0567165eb0f661f952ad":"Ja, Karte löschen",
"96b88e530375237cbc08138d9fb4109c":"Ja, diese Liste entfernen",
"fd3780f468c531f2bc1b47b8748b5a66":"Sie können Buchhaltungscodes in den Unternehmenseinstellungen erstellen.",
"ab3ac6f945d9f756763d49257fd924dc":"Sie können Codierungen in den Unternehmenseinstellungen verwalten.",
"c919dae93fe780061629c255eb358140":"Die einzige Quellkarte, die mit diesem Team verbunden ist, kann nicht entfernt werden. Fügen Sie eine weitere Karte hinzu, um diese zu entfernen.",
"11de97e873b66d91a77db7a05da32202":"Sie können einen der unten genannten Teaminhaber als Firmenadmin festlegen oder einen neuen Benutzer einladen.",
"0ab790f08b2780be001b79d2ccd75c4e":"Sie haben für dieses Konto keine Buchhaltungscodes erstellt.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Sie haben keine Codierungslisten erstellt.",
"44e470218124a50c117870467530752e":"Sie haben eine falsche E-Mail-Adresse oder ein falsches Passwort eingegeben.",
"4b687d0730822fdbeea27c174e47f227":"Sie haben keine akzeptierten Genehmigungen.",
"020f03c17b79af66f335b6af868c66c0":"Sie haben keine stornierten Genehmigungen.",
"def0b76f03e7c622341a7d8e08aaaae7":"Sie haben keine abgelehnten Genehmigungen.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Sie haben keine offenen Genehmigungen.",
"7c034d6104f80a523b90bc607fc06608":"Sie haben keine virtuellen Karten in Ihrer Wallet. Anfrage erstellen",
"0aed9198d61d161468d541dbbc9f477a":"Sie haben eine Abwesenheitsweiterleitung zu {oooForwardToFullName} eingerichtet. Diese läuft bis {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Sie haben eine neue virtuelle Karte erhalten:",
"04eeaf814767c30aa41577f3b11f916a":"Sie haben vor kurzem eine Passwortrücksetzung für Ihr Go-Konto angefordert. Um den Vorgang abzuschließen:",
"09ddf249810b9af7d3e1f4e537da500d":"Sie haben versucht, auf eine Seite zuzugreifen, die nicht existiert. Bitte stellen Sie sicher, dass Sie den richtigen Link verwenden.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Sie wurden eingeladen, Ihrem Team beizutreten bei",
"02c6f3a7cf31a87f4f39f992509f8c44":"Sie wurden als Admin für Ihr Unternehmen hinzugefügt",
"cf121a14590f109dafcae138265e353b":"Sie wurden einem neuen Team hinzugefügt!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Sie wurden dem Team hinzugefügt",
"d3cef66a026f776903b342d450e5a832":"Ihre Go-Kartenanfrage wurde abgelehnt",
"003f87c21a29220e661fc302b885fd5a":"Ihr Konto wurde deaktiviert, bitte kontaktieren Sie Ihr Unternehmen oder dem Team-Administrator.",
"a52b1f0da15dce68007f524e48238143":"Ihre Bank lieferte beim Hinzufügen dieser Karte eine Fehlermeldung. Bitte kontaktieren Sie Ihre Bank, um das Problem zu lösen, und versuchen Sie es noch einmal.",
"4d883b173167297463aeac86957c26fe":"Ihre E-Mail-Adresse wurde verwendet, um ein Konto bei Tradeshift Go zu eröffnen. Um Ihre E-Mail-Adresse zu verifizieren und die Registrierung abzuschießen,",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Ihre Abwesenheitseinstellungen wurden gespeichert.",
"542f08554110a12de0c23afd78f38e0e":"Ihr Passwort wurde zurückgesetzt. Bitte melden Sie sich an.",
"49b2dcbe5d461f35c37986962b5bd07a":"Ihre Anfrage wurde abgelehnt",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Sie stehen auf der Liste!",
"32f87c5ddeaee35b9178785ef4eb9313":"PLZ",
"c6974a3d5311374c697625f2a3234bce":"ein Datum",
"f86980f5ef00b645a6e4a0b59738a954":"eine Person, die wir nicht finden konnten",
"7066f854917b39bafe0bbecbb8bd6377":"genehmigt Anfragen",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"für",
"49b171230ab9bcdd8b631c70cf397efb":"für <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"für Ihr Unternehmen",
"35ad86f0b035d8156390670d60801e7f":"für {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"für {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"von",
"c1407aa5424f339dedf67bcac597cf71":"hat festgelegt, dass die Genehmigungsanfragen in folgendem Zeitraum an Sie weitergeleitet werden:",
"67a948b8af3284a401ebefcb1b4723ab":"wartet auf Ihre Genehmigung von",
"9ca64449d64b98fef7859e22bc23c4dc":"tätigt Anfragen",
"ac850ab84428e9eb6a57e5d813de5e45":"verwaltet das Team",
"2168839a1f4c73ef5c89c9e930148e7d":"bitte ignorieren Sie diese E-Mail. Es ist keine Maßnahme notwendig",
"85431c325faab98e02daa60151465144":"schickte Ihnen diese Einladung",
"81c504fd54119e7e580eb05dedef46d0":"schickte Ihnen diese Einladung",
"d74545060b38445ef4cc49fbb8d79876":"schickte Ihnen diese Einladung.",
"2d96c53ee7ee0a54aadad19bfce0697d":"beim Abrufen Ihrer Kartenquellen ist ein Fehler aufgetreten",
"b2fda295788d579fffd3f43b7737fd63":"um Ihre Firmenkarte bei GO anzumelden, um Ihren Mitarbeitern einfachen & sicheren Zugriff auf Karten zu gewähren",
"d35bd5c5d74af765d45cef3c4e78f52a":"um geschäftliche Käufe mit virtuellen Go-Karten zu tätigen",
"8469a13ddf1cc19a4ebf26356d8ac646":"um geschäftliche Käufe mit virtuellen Go-Karten zu tätigen",
"9546989e681a7e25fbdfe7631e9ebc03":"um geschäftliche Käufe mit virtuellen Go-Karten zu tätigen.",
"6174e0a3d26409dd6e410052e2805660":"um Tradeshift Go Karten zu verwenden",
"0bee956ca9aa927e0063c36a300e29e1":"deaktivieren Sie die Abwesenheitsnotiz",
"fd2c1099fc9f3831d7f24195aaa11269":"ihre@firma.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} ist verantwortlich dafür, Anfragen von anderen Teammitgliedern zu genehmigen. Bitte teilen Sie den betroffenen Mitgliedern einen neuen Genehmiger zu, um fortzufahren.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} wurde erfolgreich${this.teamId ? '.' : ' ins Team eingeladen.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} wurde entfernt",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} stornierte die Anfrage für",
"9567a2d4cacd58fb4f096132d26c1660":"{username} ist verantwortlich dafür, Anfragen von anderen Teammitgliedern zu genehmigen. Bitte teilen Sie den betroffenen Mitgliedern einen neuen Genehmiger zu, um fortzufahren.",
};
