"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiContainerOverrides = void 0;
const ApprovalBackground = require('../../../../assets/img/landing-page/approval.png');
const RequestBackground = require('../../../../assets/img/landing-page/request.png');
const VirtualCardBackground = require('../../../../assets/img/virtual-card-no-amex.png');
function getItemStyle(ownerState, theme) {
    const defaultStyles = {
        color: theme.palette.tsGoColor.grayDark,
        margin: '5px 20px 50px 0',
        textAlign: 'left',
        width: '380px',
    };
    if (ownerState['ts-item-variant']?.toLowerCase() === 'purchase-request-form') {
        return {
            ...defaultStyles,
            backgroundImage: `url(${RequestBackground.default})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: '280px',
        };
    }
    else if (ownerState['ts-item-variant']?.toLowerCase() === 'mobile') {
        return {
            ...defaultStyles,
            display: 'none',
            [theme.breakpoints.down(1024)]: {
                display: 'block',
            },
        };
    }
    else if (ownerState['ts-item-variant']?.toLowerCase() === 'approval-item') {
        return {
            ...defaultStyles,
            backgroundImage: `url(${ApprovalBackground.default})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: '160px',
            width: '380px',
        };
    }
    else if (ownerState['ts-item-variant']?.toLowerCase() === 'review-n-approve') {
        return {
            ...defaultStyles,
            [theme.breakpoints.down(1024)]: {
                display: 'none',
            },
        };
    }
    else if (ownerState['ts-item-variant']?.toLowerCase() === 'virtual-card') {
        return {
            ...defaultStyles,
            background: `url(${VirtualCardBackground.default})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: '200px',
            width: '380px',
            marginTop: '20px',
        };
    }
    else {
        return {
            ...defaultStyles,
        };
    }
}
function getItemContainerStyle() {
    return {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        margin: '0 auto',
        maxWidth: '1000px',
        padding: '10px 20px 20px 20px',
    };
}
function getAccoutingBenefitsStyle() {
    return {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        paddingLeft: 0,
    };
}
function getSecurityBenefitsStyle(theme) {
    return {
        display: 'flex',
        justifyContent: 'space-around',
        paddingBottom: '40px',
        textAlign: 'left',
        [theme.breakpoints.down(415)]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'balance',
        },
    };
}
function getLandingFooterStyles(theme) {
    return {
        color: 'hsl(198, 20%, 59%)',
        display: 'flex',
        fontSize: theme.typography.tsFontSize.default,
        fontWeight: theme.typography.tsFontWeight.lighter,
        justifyContent: 'space-between',
        margin: '0 auto',
        maxWidth: '1000px',
        padding: '20px',
        verticalAlign: 'middle',
        backgroundColor: 'hsl(0, 0%, 100%)',
        border: '1px solid hsl(198, 20%, 83%)',
        [theme.breakpoints.down(415)]: {
            flexWrap: 'wrap',
            lineHeight: theme.tsGoUnit.tsUnitOneAndHalf,
        },
    };
}
exports.muiContainerOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            ...(ownerState['ts-variant'] === 'item' && ownerState['ts-item-variant'] && getItemStyle(ownerState, theme)),
            ...(ownerState['ts-variant'] === 'accounting-benefits' && getAccoutingBenefitsStyle()),
            ...(ownerState['ts-variant'] === 'items' && getItemContainerStyle()),
            ...(ownerState['ts-variant'] === 'security-benefits' && getSecurityBenefitsStyle(theme)),
            ...(ownerState['ts-variant'] === 'landing-footer' && getLandingFooterStyles(theme)),
        }),
    },
};
