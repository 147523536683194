const _ = require('lodash');

function AsideService() {
	const _asides = {
		addCardSource: {
			busy: false,
			title: 'Add parent card',
		},
		approvalOptions: {
			busy: false,
			title: 'Approval options',
		},
		codingLists: {
			busy: false,
			title: 'Coding lists',
		},
		confirmAction: {
			busy: false,
			title: 'Are you sure?',
		},
		createCard: {
			busy: false,
			title: 'Create card',
		},
		createCardV2: {
			busy: false,
			title: 'Create card',
		},
		defaultManager: {
			busy: false,
			title: 'Set default manager',
		},
		editProfileV2: {
			busy: false,
			title: 'Edit Profile',
		},
		inviteUser: {
			busy: false,
			title: 'Invite user',
		},
		sortOptions: {
			busy: false,
			title: 'Sort by',
		},
	};
	const service = {};

	service.open = function (key, options) {
		options = options || {};
		if (!_asides[key]) {
			throw new Error('Aside "' + key + '" does not exist');
		}
		_asides[key].show = true;
		_asides[key].model = options.model || {};
		_asides[key].title = options.title || _asides[key].title;
		_asides[key].onSuccess = options.onSuccess || _.identity;
	};

	service.busy = function (key, isBusy) {
		_asides[key].busy = isBusy;
	};

	service.close = function (key) {
		_asides[key].show = false;
	};

	service.get = function (keys) {
		return _.pickBy(_asides, function (aside, key) {
			return _.includes(keys, key);
		});
	};

	service.getAll = function () {
		return _asides;
	};

	service.closeAll = function () {
		_.forEach(_.keys(_asides), function (key) {
			_asides[key].show = false;
		});
	};

	return service;
}

module.exports = AsideService;
