const unsavedValuesCacheService = () => {
	const service = {};

	service.saveCachedValues = (id, cachedValues) => {
		service[id] = cachedValues;
	};

	service.displayCachedValues = id => {
		return service[id];
	};

	return service;
};

module.exports = unsavedValuesCacheService;
