const FileModelDirective = () => ({
	restrict: 'A',
	require: 'ngModel',
	link: ($scope, $element, $attrs, ngModelCtrl) => {
		// file select -> model
		$element.bind('change', event => {
			const value = $attrs.multiple ? event.target.files : event.target.files[0];
			ngModelCtrl.$setViewValue(value);
		});

		// model -> file select
		ngModelCtrl.$render = () => {
			// HTMLInputElement accepts a filename, which may only be programmatically set to the empty string.
			if (!ngModelCtrl.$viewValue) {
				$element.val(null);
			}
		};
	},
});

module.exports = FileModelDirective;
