"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormattedDate = void 0;
const date_fns_1 = require("date-fns");
const react_1 = require("react");
const react_use_1 = require("react-use");
const setTime_1 = require("../../../../utils/setTime");
const SIXTY_SECONDS = 60;
const computeFormattedDate = (date) => {
    const now = new Date();
    const diffSeconds = (0, date_fns_1.differenceInSeconds)(now, new Date(date));
    return diffSeconds < SIXTY_SECONDS
        ? 'a few moments ago'
        : (0, date_fns_1.formatDistanceToNowStrict)(new Date(date), { addSuffix: true });
};
const useFormattedDate = (date) => {
    const [formattedDate, setFormattedDate] = (0, react_1.useState)(() => computeFormattedDate(date));
    (0, react_use_1.useInterval)(() => {
        setFormattedDate(computeFormattedDate(date));
    }, (0, setTime_1.setTime)(1, 'minutes'));
    return formattedDate;
};
exports.useFormattedDate = useFormattedDate;
