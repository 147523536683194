const _ = require('lodash');

function CardInfoService() {
	const cardInfo = {};

	cardInfo.getRemainingValue = card => {
		const limit = card.getAmount();
		return limit - cardInfo.getSpentAmount(card);
	};

	cardInfo.getSpentAmount = card => card.getSpentAmount() || 0;

	cardInfo.getSpentPercentage = card => {
		const spent = cardInfo.getSpentAmount(card);
		const limit = card.getAmount();
		const percentage = (spent / limit) * 100;
		return _.clamp(_.round(percentage), 0, 100);
	};

	return cardInfo;
}

module.exports = CardInfoService;
