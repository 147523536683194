const _ = require('lodash');

/* @ngInject */
function TeamsService(constants, CacheFactory, HttpService, UserServiceV2, UserSettingService) {
	const CACHE_ID = constants.cache.ids.teamCache;
	const TEAM_STATE_ACTIVE = constants.teams.state.active;
	const TEAMS_BATCH_SIZE = 10;
	const teamSwitchObservables = [];
	const service = {};

	const getCache = (service.getCache = () => {
		// cache fails to work correctly if created when TeamsService is created, but it works fine with lazy loading.
		return (
			CacheFactory.get(CACHE_ID) ||
			CacheFactory.createCache(CACHE_ID, {
				deleteOnExpire: 'aggressive',
				maxAge: 10000,
			})
		);
	});

	const notifyTeamSwitchObservables = team => {
		teamSwitchObservables.forEach(observable => observable(team));
	};

	service.clearCache = data => {
		getCache().removeAll();
		return data;
	};

	service.removeTeamSwitchObservable = callback => {
		const index = teamSwitchObservables.indexOf(callback);
		teamSwitchObservables.splice(index, 1);
	};

	service.registerTeamSwitchObservable = callback => {
		if (!teamSwitchObservables.includes(callback)) {
			teamSwitchObservables.push(callback);
		}
	};

	service.setCurrentTeamById = async teamId => {
		if (service.currentTeam && service.currentTeam.id === teamId) {
			return service.currentTeam;
		}
		const team = await service.getTeam({ teamId });
		await UserSettingService.setSelectedTeamID(team?.id);

		service.currentTeam = team;
		notifyTeamSwitchObservables(team);
		return team;
	};

	service.getCurrentTeam = async ({ teams } = {}) => {
		if (service.currentTeam) {
			return new Promise(resolve =>
				setTimeout(() => {
					resolve(service.currentTeam);
				}, 50),
			);
		}
		const teamId = await UserSettingService.getSelectedTeamID();
		let team = null;
		if (!_.isEmpty(teamId)) {
			try {
				team = await service.getTeam({ teamId });
			} catch (error) {
				// if a team is not returned, let the 'team is null' behavior proceed
			}
		}
		if (!team || team.state !== TEAM_STATE_ACTIVE) {
			if (!teams) {
				teams = await service.getActiveTeams();
			}
			if (teams.length === 0) {
				return null;
			}
			service.currentTeam = team = teams[0];
			await UserSettingService.setSelectedTeamID(team.id);
		}
		return team;
	};

	service.createTeam = teamName =>
		HttpService.tradeshiftGo().path('/rest/teams').post({ teamName }).then(service.clearCache);

	service.getTeamRequestLimit = async teamId => {
		const teamLimit = await service.getTeamProperty({
			teamId,
			propertyKey: constants.teamProperties.requestLimit,
		});

		return typeof teamLimit === 'object' ? '' : Number(teamLimit);
	};

	service.getTeamProperty = ({ teamId, propertyKey }) =>
		HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/properties/${propertyKey}`).get();

	service.getTeam = async ({ teamId }) => {
		if (_.isEmpty(teamId)) {
			teamId = await UserSettingService.getSelectedTeamID();
		}
		return HttpService.tradeshiftGo().path(`/rest/teams/${teamId}`).config({ cache: getCache() }).get();
	};

	const getTeamsPage = params =>
		HttpService.tradeshiftGo().path('/rest/teams').config({ params, cache: getCache() }).get();

	// wrapper used for mocking
	service.getTeamsBatchSize = () => {
		return TEAMS_BATCH_SIZE;
	};

	/**
	 * This is a workaround for not having teams pagination in the FE,
	 * trying to split the pressure on the backend over multiple API calls
	 * to retrieve the teams page by page, instead of all at once. Hopefully it helps.
	 */
	service.getAllTeams = async ({ state } = {}) => {
		let result = [];
		let page = 0;
		let totalCount = null;
		const params = state ? { state } : {};
		params.limit = service.getTeamsBatchSize();

		while (page === 0 || page * params.limit < totalCount) {
			const teamsPage = await getTeamsPage({ ...params, offset: page * params.limit });
			result = result.concat(teamsPage);
			page = page + 1;
			if (totalCount === null) {
				totalCount = (teamsPage && teamsPage[0] && teamsPage[0].teamsCount) || 0;
			}
		}
		return result;
	};

	service.getTeamsByPage = ({ state, limit, page } = {}) => {
		const limitNumber = Number(limit);
		const pageNumber = Number(page);
		const limitValue = !isNaN(limitNumber) ? limitNumber : service.getTeamsBatchSize();
		const offset = !isNaN(pageNumber) ? limitValue * pageNumber : 0;
		const params = {
			...(state && { state }),
			...{ limit: limitValue },
			...{ offset },
		};
		return HttpService.tradeshiftGo().path('/rest/teams').config({ params }).get();
	};

	service.getTeamsAllPages = async ({ state } = {}) => {
		const teamsBatchSize = service.getTeamsBatchSize();
		const params = {
			...(state && { state }),
			limit: teamsBatchSize,
			page: 0,
		};
		const teams = await service.getTeamsByPage(params);
		if (teams && teams[0] && teams[0]?.teamsCount && teams[0].teamsCount > teamsBatchSize) {
			const lastPage = Math.ceil(teams[0].teamsCount / teamsBatchSize) - 1;
			const getTeamsPyPagePromises = [];
			for (let page = 1; page <= lastPage; page++) {
				getTeamsPyPagePromises.push(service.getTeamsByPage({ state, limit: teamsBatchSize, page }));
			}
			const otherTeamsList = await Promise.all(getTeamsPyPagePromises);
			const otherTeams = otherTeamsList.reduce((result, otherTeamsPage) => {
				result.push(...otherTeamsPage);
				return result;
			}, []);
			return [...teams, ...otherTeams];
		} else {
			return teams;
		}
	};

	service.getTeams = ({ state, limit, page } = {}) => {
		if (isNaN(limit) || isNaN(page)) {
			return service.getTeamsAllPages({ state });
		} else {
			return service.getTeamsByPage({ state, limit, page });
		}
	};

	service.getActiveTeams = () => {
		return service.getTeams({ state: TEAM_STATE_ACTIVE });
	};

	service.getTeamCodingListEntries = ({ teamId, listId }) =>
		HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/coding/lists/${listId}/entries`).get();

	service.setTeamProperty = (teamId, propertyKey, propertyValue) =>
		HttpService.tradeshiftGo()
			.path(`/rest/teams/${teamId}/properties/${propertyKey}`)
			.put({ propertyValue })
			.then(service.clearCache);

	service.removeTeamProperty = (teamId, propertyKey) =>
		HttpService.tradeshiftGo()
			.path(`/rest/teams/${teamId}/properties/${propertyKey}`)
			.delete()
			.then(service.clearCache);

	service.getTeamUsers = ({ limit, page, teamId, searchParam }) => {
		let query = '?';

		if (limit) {
			query = query + `limit=${limit}&`;
		}
		if (page) {
			query = query + `page=${page}&`;
		}
		if (searchParam) {
			query = query + `searchParam=${searchParam}`;
		}

		return HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/users${query}`).get();
	};

	service.getOooSettings = async ({ userId, teamId }) => {
		if (!userId) {
			const user = await UserServiceV2.getUser();
			userId = user.userId;
		}
		const properties = await service.getUserTeamProperties({ userId, teamId });
		const outOfOfficeKeys = Object.values(constants.userTeamProperties.outOfOffice);
		return properties.reduce((output, property) => {
			const propertyKey = Object.keys(property)[0];
			if (outOfOfficeKeys.includes(propertyKey)) {
				output[propertyKey] = property[propertyKey];
			}
			return output;
		}, {});
	};

	service.setOooSettings = async ({ settings, teamId }) => {
		const properties = Object.keys(settings).map(key => ({ [key]: settings[key] }));
		return service.setUserTeamProperties({
			properties,
			teamId,
		});
	};

	service.getUserTeamProperties = async ({ userId, teamId }) => {
		if (!userId) {
			const user = await UserServiceV2.getUser();
			userId = user.userId;
		}
		return HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/users/${userId}/properties`).get();
	};

	service.setUserTeamProperties = async ({ userId, teamId, properties }) => {
		if (!userId) {
			const user = await UserServiceV2.getUser();
			userId = user.userId;
		}
		return HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/users/${userId}/properties`).put({ properties });
	};

	service.setUserTeamProperty = (userId, teamId, propertyKey, propertyValue) =>
		HttpService.tradeshiftGo()
			.path(`/rest/teams/${teamId}/users/${userId}/properties/${propertyKey}`)
			.put({ propertyValue });

	service.getDecoratedUser = ({ teamId, userId }) =>
		HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/users/${userId}`).get();

	service.removeUserFromTeam = (teamId, userId) =>
		HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/users/${userId}`).delete().then(service.clearCache);

	service.deactivateTeam = teamId =>
		HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/deactivate`).post().then(service.clearCache);

	service.activateTeam = teamId =>
		HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/activate`).post().then(service.clearCache);

	service.updateTeamName = (teamId, teamName) =>
		HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/updateName`).put({ teamName }).then(service.clearCache);

	service.verifyViewAccess = ({ teamId, page }) => {
		return UserServiceV2.verifyTeamAccess({
			objectId: page,
			operationId: 'view',
			teamId,
		});
	};

	return service;
}

module.exports = TeamsService;
