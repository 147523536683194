const { get } = require('lodash');
const Lexico = require('@tradeshift/lexico');
/* @ngInject */
const LexicoService = (constants, i18nService, UserServiceV2) => {
	const service = {};
	let selectedLanguage = 'en';

	service.isLanguageAvailable = language => {
		return !!constants.languages[language];
	};

	service.configure = async () => {
		const { language } = await UserServiceV2.getUser();
		selectedLanguage = service.isLanguageAvailable(language) ? language : 'en';

		service.setTranslations(selectedLanguage);
		await i18nService.importLocale(selectedLanguage);
		service.getLexico = () => Lexico.forLocale(selectedLanguage);
	};

	service.configureAnonymous = async () => {
		const usersLanguage =
			navigator.language || navigator.browserLanguage || navigator.systemLanguage || navigator.userLanguage;
		if (usersLanguage === 'en-US') {
			selectedLanguage = 'en';
		} else {
			selectedLanguage = usersLanguage;
		}

		// if the user's browser's language is set to a language that
		// we do not support, default to english. The 'Merica version.
		if (!get(constants.languages, selectedLanguage)) {
			selectedLanguage = 'en';
		}
		await i18nService.importLocale(selectedLanguage || 'en');
		service.anonymousLangSetting = selectedLanguage;
		service.setTranslations(selectedLanguage || 'en');
		service.getLexico = () => Lexico.fromLocale(selectedLanguage || 'en');
	};

	service.getSelectedLanguage = () => selectedLanguage;

	service.sanitizeLang = lang => lang.replace(/-/g, '_');

	service.setTranslations = lang => {
		const translationsOpts = {};
		lang = service.sanitizeLang(lang);
		translationsOpts[lang] = require('../../translations/js/messages_' + lang);
		Lexico.setTranslations(translationsOpts);
	};

	return service;
};

module.exports = LexicoService;
