function virtualCard() {
	return {
		restrict: 'E',
		scope: {
			card: '=',
		},
		template: require('./virtualCard.html'),
		controller: 'VirtualCardController',
	};
}

module.exports = virtualCard;
