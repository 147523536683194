import { cloneDeep } from 'lodash';

const EditUserAsideComponent = {
	bindings: {
		isOpen: '<',
		onClose: '&',
		onSuccess: '&',
		onUserRemoved: '&',
		user: '<',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.editUserTitleText}}">
			<edit-user-panel
					form-options="$ctrl.formOptions"
					features="$ctrl.features"
					is-name-editable="$ctrl.isNameEditable"
					is-role-select-enabled="false"
					is-submitting="$ctrl.isSubmitting"
					on-cancel="$ctrl.onClose()"
					on-submit="$ctrl.onSubmitFeatureDisabled()"
					on-remove-user="$ctrl.confirmAside.isOpen = true"
					options-model="$ctrl.optionsModel"
					user="$ctrl.user"></edit-user-panel>
		</ts-aside>
		<go-confirm-action-aside
				is-open="$ctrl.confirmAside.isOpen"
				message="$ctrl.confirmAside.message"
				on-confirm="$ctrl.onConfirmRemoveUser()"
				on-cancel="$ctrl.confirmAside.isOpen = false"></go-confirm-action-aside>
	`,
	controller: class EditUserAsideController {
		/* @ngInject */
		constructor($q, editUserService, EventEmitter, FeatureService, LexicoService, UserService, RoleService) {
			this.$q = $q;
			this.editUserService = editUserService;
			this.EventEmitter = EventEmitter;
			this.FeatureService = FeatureService;
			this.lexico = LexicoService.getLexico();
			this.editUserTitleText = this.lexico.trc('Component title', 'Edit user');
			this.UserService = UserService;
			this.confirmAside = {
				isOpen: false,
			};
			this.SUPPORT_ROLE_ID = RoleService.getRole('support').id;
			this.TSAdminRoles = RoleService.getTSAdminRoles();
		}

		$onChanges(changes) {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}
			if (changes.user && changes.user.currentValue) {
				this.user = cloneDeep(changes.user.currentValue);
				this.confirmAside.message = this.trc(
					'Confirmation message',
					'Did you mean to remove {fullName} ({email}) from the company?',
					{
						fullName: this.user.fullName,
						email: this.user.email,
					},
				);
			}
		}

		onOpen() {
			this.isLoading = true;
			this.$q
				.all([
					this.FeatureService.getFeatures(),
					this.editUserService.getFormOptions(),
					this.editUserService.isNameEditable(this.user),
					this.UserService.isTSAdmin(),
				])
				.then(([features, formOptions, isNameEditable, isTSAdmin]) => {
					this.features = features;
					this.formOptions = formOptions;
					// Filters out the support role if the logged in user is not a TSadmin
					this.formOptions.roles = isTSAdmin ? this.TSAdminRoles : this.formOptions.roles;
					this.optionsModel = this.editUserService.getOptionsModel(
						this.formOptions,
						this.user.properties,
						this.user.roleId,
					);
					this.isNameEditable = isNameEditable;
				})
				.finally(() => {
					this.isLoading = false;
				});
		}

		onConfirmRemoveUser() {
			this.confirmAside.isOpen = false;
			this.editUserService.removeUser(this.user).then(() => {
				this.onUserRemoved(this.EventEmitter({ user: this.user }));
			});
		}

		// Disabled submit feature to fix VULN-6268, this component is used
		// only in the users support page and we should no longer support this.
		// The profile can be changed only by the users themselves, and this aside
		// also was no longer working (default managers feature was dropped when
		// Go V2 was launched, so this aside is always invalid and submit button
		// is greyed out because you can select a default manager).
		onSubmitFeatureDisabled() {
			ts.ui.Notification.warning('Submit is disabled. This feature is no longer supported.');
		}

		onSubmit({ user, optionsModel }) {
			this.isSubmitting = true;
			const editedUser = this.editUserService.assignOptionsModelToUser(optionsModel, user);
			this.editUserService
				.saveUser(editedUser)
				.then(savedUser => {
					const successMessage = this.lexico.trc('Success message', 'User saved.');
					ts.ui.Notification.success(successMessage);
					this.onSuccess(this.EventEmitter({ user: savedUser }));
				})
				.catch(() => {
					const warningMessage = this.lexico.trc(
						'Warning message',
						'There was a problem saving the user. Please try again.',
					);
					ts.ui.Notification.warning(warningMessage);
				})
				.finally(() => {
					this.isSubmitting = false;
				});
		}
	},
};

export default EditUserAsideComponent;
