const _ = require('lodash');

/* @ngInject */
function PaymentsController(
	$scope,
	$state,
	_cardSources,
	_manageHeader,
	AsideService,
	constants,
	FooterService,
	PaymentsHelper,
) {
	const FOOTER_BUTTONS = [
		{
			label: 'Add parent card',
			type: 'ts-primary',
			onclick: () => {
				AsideService.open('addCardSource', {
					model: {
						onSuccess: cardSource => {
							$scope.cardSources = _.concat($scope.cardSources, cardSource);
							$scope.table.rows = _.map($scope.cardSources, mapCardSource);
						},
					},
				});
				$scope.$digest();
			},
		},
	];

	const mapCardSource = ({ lastDigits = '', name = '', providerLabel, status = '', billingAddress, id }) => [
		name,
		{
			icon: 'ts-icon-edit',
			item: 'Button',
			// This name has to be here otherwise the event won't fire
			name: 'open-edit-card-aside',
			type: 'ts-noborder go-table-cell-icon-color',
			value: id,
			width: 50,
		},
		providerLabel,
		lastDigits,
		{
			text: constants.cardsourceStatus[status],
			type: (constants.cardsourceStatus[status] || '').toLowerCase(),
		},
		PaymentsHelper.formatBillingAddress(billingAddress),
		{
			icon: 'ts-icon-arrowright',
			item: 'Button',
			// This name has to be here otherwise the event won't fire
			name: 'goto-card-id',
			type: 'ts-noborder go-table-cell-icon-color',
			value: id,
			width: 50,
		},
	];

	(function init() {
		$scope.asides = AsideService.get(['addCardSource']);
		$scope.editSourceCardAside = {
			isOpen: false,
			onClose: () => {
				$scope.editSourceCardAside.isOpen = false;
			},
			onUpdateCardSource: ({ cardSourceId, name }) => {
				const newRows = _.cloneDeep($scope.table.rows);
				const rowIndex = _.findIndex(newRows, row => row[1].value === cardSourceId);
				newRows[rowIndex][0] = name;
				$scope.table.rows = _.cloneDeep(newRows);
			},
			selectedParentCardID: null,
		};
		$scope.cardSources = _cardSources;
		// TODO: should show a message if table is intentionally empty
		$scope.table = {
			columns: [
				{
					label: 'Card name',
					flex: 2,
				},
				{
					label: '',
					width: 75,
				},
				'Provider',
				'Last four digits',
				'Status',
				{
					label: 'Billing address',
					flex: 3,
				},
				{
					label: 'Details',
					width: 75,
				},
			],
			onButtonClick: ({ name, value }) => {
				if (name === 'goto-card-id') {
					$state.go('main.manage.cardSource', { cardSourceId: value });
				} else if (name === 'open-edit-card-aside') {
					$scope.$evalAsync(() => {
						$scope.editSourceCardAside.selectedParentCardID = value;
						$scope.editSourceCardAside.isOpen = true;
					});
				}
			},
			rows: _.map($scope.cardSources, mapCardSource),
		};
		_manageHeader.selectTab({ selectedTab: 'payments' });
		FooterService.create({ $scope, buttons: FOOTER_BUTTONS });
	})();
}

PaymentsController.resolve = {
	_cardSources: /* @ngInject */ PaymentsService => PaymentsService.getCardSources(),
};

module.exports = PaymentsController;
