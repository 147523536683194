"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledButtonLink = void 0;
const material_1 = require("@mui/material");
exports.StyledButtonLink = (0, material_1.styled)(material_1.Button)(({ theme }) => ({
    overflowWrap: 'break-word',
    margin: 0,
    color: theme.palette.tsGoColor.blue,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    userSelect: 'none',
    maxWidth: '100%',
    textAlign: 'center',
    position: 'relative',
    display: 'inline-block',
    transitionProperty: 'color, background-color, border-color',
    transitionDuration: '0.1s',
    transitionTimingFunction: 'ease',
    borderRadius: theme.tsGoUnit.tsUnitHalf,
    padding: 0,
    fontSize: theme.typography.tsFontSize.small,
    boxSizing: 'border-box',
    fontWeight: theme.typography.tsFontWeight.semiBold,
    ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? {
            fontWeight: theme.typography.tsFontWeight.default,
            fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
            textTransform: 'none',
        }
        : {}),
}));
