const _ = require('lodash');

/* @ngInject */
function RequestListsFactory($q, constants) {
	const requestLists = {
		_lists: {},
		_registry: {},
	};

	function _addRequestsToList(requestList, requests) {
		_.map(requests, function (request) {
			requestList.addRequest(requestLists._registry.storeRequest(request));
		});
	}
	function _chooseRequestList(request) {
		const listKey = request.isArchived() ? constants.requestList.keys.archive : constants.requestList.keys.active;
		return listKey;
	}
	function _loadRequestLists() {
		const listKeys = [];
		const promises = _.map(requestLists._lists, function (list) {
			listKeys.push(list.getKey());
			return list.load();
		});
		return $q.all(promises).then(function (requests) {
			_.map(requests, function (request, index) {
				const requestListKey = listKeys[index];
				const requestList = requestLists._lists[requestListKey];
				_addRequestsToList(requestList, request);
			});
			return requestLists._lists;
		});
	}

	requestLists.convertNewRequest = function (newRequest) {
		const newRequestId = constants.request.states.new;
		requestLists._registry.removeRequest(newRequestId);
		requestLists._lists[constants.requestList.keys.active].removeRequest(newRequestId);
		requestLists.addNewRequest(newRequest);
	};
	requestLists.addNewRequest = function (newRequest) {
		const request = requestLists._registry.storeRequest(newRequest);
		requestLists._lists[constants.requestList.keys.active].addNewRequest(request);
	};
	requestLists.addRequestToList = function (request) {
		const listName = _chooseRequestList(request);
		requestLists._lists[listName].addNewRequest(request);
	};
	requestLists.archiveRequest = function (request) {
		requestLists._lists[constants.requestList.keys.active].removeRequest(request.getId());
		requestLists._lists[constants.requestList.keys.archive].addNewRequest(request);
	};
	requestLists.cancelRequest = function (request) {
		requestLists._lists[constants.requestList.keys.active].removeRequest(request.getId());
		requestLists._lists[constants.requestList.keys.archive].removeRequest(request.getId());
	};
	requestLists.unarchiveRequest = function (request) {
		requestLists._lists[constants.requestList.keys.active].addNewRequest(request);
		requestLists._lists[constants.requestList.keys.archive].removeRequest(request.getId());
	};
	requestLists.getRequestList = function (requestListKey) {
		return requestLists._lists[requestListKey];
	};
	requestLists.getRequestLists = function () {
		return requestLists._lists;
	};
	requestLists.initialize = function (options) {
		requestLists._registry = options.registry;
		requestLists._lists = options.lists;

		return _loadRequestLists();
	};
	requestLists.loadMoreRequests = function (requestList) {
		return requestList.loadMore().then(function (requests) {
			_addRequestsToList(requestList, requests);
		});
	};
	return requestLists;
}

module.exports = RequestListsFactory;
