import { cloneDeep, some } from 'lodash';

const AddAdminAsideComponent = {
	bindings: {
		isOpen: '<',
		onAdminsAdded: '&',
		onAdminInvited: '&',
		onClose: '&',
		users: '<',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.addAdminText}}">
			<p ng-bind="$ctrl.addAdminInfoText"></p>
			<menu
					class="ts-user-menu"
					data-ts="Menu" >
				<li
						ng-class="{ 'ts-checked': user.selected }"
						ng-repeat="user in $ctrl.users">
					<button ng-click="$ctrl.selectUser(user)">
						<img
								alt="{{user.fullName}}"
								data-ts="UserImage"/>
						<span>{{user.fullName}}</span>
						<sub>{{user.email}}</sub>
						<i
								class="ts-icon-checked"
								ng-if="user.selected"></i>
					</button>
				</li>
			</menu>
			<menu data-ts="Buttons">
				<ts-menu-button
						button-class="ts-primary"
						is-disabled="!$ctrl.hasSelectedUser"
						label="$ctrl.addSelectedButtonText"
						on-click="$ctrl.addSelectedAdmins()"></ts-menu-button>
				<ts-menu-button
						button-class="ts-tertiary"
						label="$ctrl.inviteAdminButtonText"
						on-click="$ctrl.inviteUser()"></ts-menu-button>
				<ts-menu-button
						button-class="ts-tertiary"
						label="$ctrl.cancelButtonText"
						on-click="$ctrl.onClose()"></ts-menu-button>
			</menu>
		</ts-aside>
		<go-invite-user-aside
				users="$ctrl.users"
				is-open="$ctrl.asides.inviteUser.isOpen"
				on-close="$ctrl.onAsideClosed('inviteUser')"
				on-user-invited="$ctrl.invitedAdmin($event)"
				role="$ctrl.ROLE_COMPANY_ADMIN"
				request="$ctrl.request"></go-invite-user-aside>
	`,
	controller: class AddAdminAsideController {
		/* @ngInject */
		constructor(EventEmitter, LexicoService, RoleService, UserService, constants) {
			this.lexico = LexicoService.getLexico();
			this.addAdminText = this.lexico.trc('Aside component label', 'Add admin');
			this.addAdminInfoText = this.lexico.trc(
				'Aside info message',
				'You can promote one of the team owners listed below to be a company admin or invite a new user.',
			);
			this.addSelectedButtonText = this.lexico.trc('Button', 'Add selected');
			this.inviteAdminButtonText = this.lexico.trc('Button', 'Invite new admin user');
			this.cancelButtonText = this.lexico.trc('Button', 'Cancel');

			this.EventEmitter = EventEmitter;
			this.RoleService = RoleService;
			this.UserService = UserService;

			this.ROLE_COMPANY_ADMIN = RoleService.getRoleV2('companyAdmin');
			this.SECURITY_GROUP_TYPE_COMPANY = constants.securityGroupTypes.company;
		}

		onAsideClosed(key) {
			this.asides[key].isOpen = false;
		}

		$onChanges(changes) {
			if (changes.users && changes.users.currentValue) {
				// bound objects needs to be cloned before being modified (i.e. before this.onOpen())
				this.users = cloneDeep(changes.users.currentValue);
			}
		}

		inviteUser() {
			this.asides.inviteUser.isOpen = true;
		}

		selectUser(user) {
			user.selected = !user.selected;
			this.hasSelectedUser = some(this.users, { selected: true });
		}

		addSelectedAdmins() {
			// eslint-disable-next-line array-callback-return
			const admins = this.users.filter(user => {
				if (user.selected) {
					delete user.selected;
					user.roles.push({
						roleId: this.ROLE_COMPANY_ADMIN.id,
						securityGroupId: user.companyAccountId,
						securityGroupTypeId: this.SECURITY_GROUP_TYPE_COMPANY,
					});
					user.isNew = true;
					return user;
				}
			});
			this.onAdminsAdded(this.EventEmitter({ admins }));
			this.onClose();
		}

		invitedAdmin({ invitedUser: admin }) {
			this.onAdminInvited(this.EventEmitter({ admin }));
			this.onClose();
		}

		$onInit() {
			this.asides = {
				inviteUser: {
					isOpen: false,
				},
			};
		}
	},
};

export default AddAdminAsideComponent;
