const _ = require('lodash');
const moment = require('moment');

/* @ngInject */
function PurchaseRequestFormController(
	$q,
	$scope,
	CodingService,
	CompanyService,
	ConfigService,
	constants, // NO SONAR
	FeatureService,
	FilterCodingListsService,
	HeaderService,
	Request,
	RequestRegistry, // NO SONAR
	RequestProfilesService,
	UserService,
) {
	// NO SONAR
	const COST_CENTER_LIST_ID = 'costcentercode';
	let requestLimit;
	const DATE_FORMAT = 'YYYY-MM-DD';

	// $scope.currencyZeroValid is used in the deprecated amex purchase request profile and should be removed as part
	// of that ticket: https://tradeshift.atlassian.net/browse/TGO-442
	$scope.currencyZeroValid = constants.regexPatterns.currencyZeroValid;
	$scope.CURRENCY_ZERO_INVALID_PATTERN = constants.regexPatterns.currencyZeroInvalid;
	$scope.AMEX_PROVIDER_FIELDS_PATTERN = constants.regexPatterns.amexProviderFields;
	$scope.defaultEndDate = moment().add(2, 'years').format(DATE_FORMAT);
	$scope.maxEndDate = moment().add(5, 'years').format(DATE_FORMAT);
	$scope.minEndDate = moment().add(2, 'days').format(DATE_FORMAT);

	const filterCardTypes = (cardTypes, subscriptionEnabled, singleUseEnabled) => {
		return cardTypes.filter(({ key }) => {
			return (
				((subscriptionEnabled && key === 'recurring-use') || key !== 'recurring-use') &&
				((singleUseEnabled && key === 'single-use') || key !== 'single-use')
			);
		});
	};

	function getNormalizedPurchaseRequest(purchaseRequest) {
		const clonedPurchaseRequest = _.cloneDeep(purchaseRequest);
		const normalizedCurrencyFields = _.reduce(
			clonedPurchaseRequest.currencyFields,
			function (result, value, key) {
				result[key] = value.replace(/[$,]/g, '');
				return result;
			},
			{},
		);
		clonedPurchaseRequest.fields = _.assign({}, clonedPurchaseRequest.fields, normalizedCurrencyFields);
		return clonedPurchaseRequest;
	}

	$scope.submitRequest = function (request, purchaseRequest) {
		$scope.$emit(constants.scopeEvents.goTyping, { isTyping: true });
		const normalizedPurchaseRequest = getNormalizedPurchaseRequest(purchaseRequest);
		const amount = normalizedPurchaseRequest.fields.amount || normalizedPurchaseRequest.fields.InvoiceAmount;
		if (requestLimit !== '' && requestLimit < amount) {
			ts.ui.Notification.warning(
				"The amount requested was over the company's request limit of $" +
					requestLimit +
					'. Please try again with a lower request amount.',
			);
			$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			return;
		}
		return request
			.submit(normalizedPurchaseRequest)
			.then(function () {
				HeaderService.get().title(request.getTitle());
				$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
				request.setCardType(purchaseRequest.cardType);
			})
			.catch(function () {
				ts.ui.Notification.warning('There was a problem submitting the purchase request.');
				$scope.event.hasError = true;
				$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			});
	};

	$scope.onConfirmNumberOfPaymentsAside = ({ numberOfPayments }) => {
		$scope.purchaseRequest.fields.numberOfPayments = numberOfPayments;
		$scope.hideNumberOfPaymentsAside();
	};

	$scope.hideNumberOfPaymentsAside = () => {
		$scope.isNumberOfPaymentsAsideShown = false;
	};

	$scope.showNumberOfPaymentsAside = () => {
		$scope.isNumberOfPaymentsAsideShown = true;
	};

	$scope.isCodingListVisible = listID => {
		const glcodeList = _.get($scope, 'codingListsClone.glcode');
		const request = $scope.purchaseRequest;
		return FilterCodingListsService.isCodingListVisible($scope.features, glcodeList, listID, request);
	};

	$scope.updateListFilters = (selectedEntry, listId) => {
		if (
			$scope.features.ENABLE_FILTERED_BY_COSTCENTER &&
			!_.isNil(selectedEntry) &&
			listId === 'costcentercode' &&
			_.get($scope.codingLists, 'glcode')
		) {
			const tmpClone = _.cloneDeep($scope.codingLists);
			const filteredLists = FilterCodingListsService.filterList(tmpClone, selectedEntry);
			$scope.codingListsClone.glcode = filteredLists.glcode;
			const keys = _.remove(_.keys($scope.purchaseRequest.coding), key => key !== 'glcode');
			$scope.purchaseRequest.coding = _.pick($scope.purchaseRequest.coding, keys);
			$scope.missingFilters = _.isEmpty(filteredLists.glcode.entries);
		}
	};

	(function init() {
		$scope.features = {};
		$scope.isLoading = {
			event: true,
		};
		$scope.missingFilters = false;
		$scope.purchaseRequest = { fields: { endDate: $scope.defaultEndDate } };
		$scope.isNumberOfPaymentsAsideShown = false;
		$scope.$emit(constants.scopeEvents.goTyping, { isTyping: true });

		const request = RequestRegistry.getRequest($scope.event.requestId);
		$scope.request = !_.isEmpty(request) ? request : new Request($scope.event.data);
		$scope.request.update($scope.event.data);

		if (request.hasReachedState('submitted')) {
			$scope.isLoading.event = false;
			$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			return;
		}

		UserService.getUser()
			.then(function (user) {
				$scope.user = user;
				return $q.all([
					CodingService.getListsAndEntries({ includeEmptyLists: false }),
					FeatureService.getFeatures(),
					RequestProfilesService.getProfileForCompany({
						companyId: $scope.user.companyAccountId,
					}),
					CompanyService.getRequestLimit(),
				]);
			})
			.then(function (results) {
				$scope.codingLists = results[0];

				$scope.codingListsClone = _.cloneDeep($scope.codingLists);
				$scope.features = results[1];
				requestLimit = results[3];

				const defaultCostCenterId = _.get($scope.user.properties, constants.userPropertyKeys.costcenter);
				const hasCostCenterCodingList = !!$scope.codingListsClone[COST_CENTER_LIST_ID];
				if (defaultCostCenterId && hasCostCenterCodingList) {
					const defaultCostCenter = _.find($scope.codingListsClone[COST_CENTER_LIST_ID].entries, {
						entryId: defaultCostCenterId,
					});
					$scope.purchaseRequest.coding = {};
					$scope.purchaseRequest.coding[COST_CENTER_LIST_ID] = defaultCostCenter;
					$scope.updateListFilters($scope.purchaseRequest.coding[COST_CENTER_LIST_ID], 'costcentercode');
				}
				$scope.cardTypes = filterCardTypes(
					ConfigService.get('CARD_TYPES'),
					$scope.features.ENABLE_SUBSCRIPTIONS,
					$scope.features.ENABLE_SINGLEUSE,
				);

				$scope.purchaseRequest.cardType = 'multiple-use';

				if ($scope.features.ENABLE_SUBSCRIPTIONS) {
					$scope.subscriptionEndings = ConfigService.get('SUBSCRIPTIONS').endings;
					$scope.subscriptionFrequencies = ConfigService.get('SUBSCRIPTIONS').frequencies;

					// this code is here temporarily while we wait for the updates to card services
					// that will allow us to make subscription cards that end after X payments.
					$scope.$watch('purchaseRequest.cardType', newVal => {
						$scope.purchaseRequest.fields = $scope.purchaseRequest.fields || {};
						if (newVal === 'recurring-use') {
							_.assign($scope.purchaseRequest.fields, {
								ends: 'on-a-specific-date',
							});
						} else {
							_.assign($scope.purchaseRequest.fields, {
								ends: '',
							});
						}
					});

					// this code is being disabled until we have the ability to
					// create subscription cards that end after X number of payments
					// $scope.$watch('purchaseRequest.fields.ends', (newVal) => {
					// 	if (newVal === 'on-a-specific-date') {
					// 		const datePicker = ts.ui.DatePicker({
					// 			onselect: (value) => {
					// 				$scope.purchaseRequest.fields.endDate = value;
					// 				datePicker.close();
					// 			},
					// 			title: 'Select end date',
					// 		});
					// 		datePicker.open();
					// 	} else if (newVal === 'number-of-payments') {
					// 		$scope.showNumberOfPaymentsAside();
					// 	}
					// });
				}

				const requestProfile = results[2];
				$scope.template = _.kebabCase(requestProfile.id);
				$scope.request.setProfileId(requestProfile.id); // TODO: would be nice to have this set already
				$scope.formFields = _.reduce(
					requestProfile.fieldDefinitions,
					function (result, value) {
						result[value.id] = {
							id: value.id,
							isRequired: value.required,
							label: value.valueFormat === 'Currency' ? [value.label, '($)'].join(' ') : value.label,
							placeholder: value.description,
						};
						return result;
					},
					{},
				);
				$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			})
			.catch(function () {
				ts.ui.Notification.warning('Oh snap! There was a problem creating a request. Please try again.');
			})
			.finally(function () {
				$scope.isLoading.event = false;
				$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
			});
	})();
}

module.exports = PurchaseRequestFormController;
