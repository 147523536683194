import _ from 'lodash';

const approvalLimitValidator = require('../../../../common/validators/approvalLimitValidator');

const UserDetailsAside = {
	bindings: {
		hasCompanyApprovalLimits: '<',
		hasTeamApprovalLimits: '<',
		isOpen: '<',
		onClose: '&',
		onRemoveUser: '&',
		onSubmit: '&',
		role: '<?',
		teamId: '<?',
		userId: '<',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				is-loading="$ctrl.isLoading"
				on-close="$ctrl.close()"
				title="{{$ctrl.userDetailsTitleText}}">
			<div class="user-avatar">
				<div
						class="avatar-preview"
						id="js-avatar-preview">
					<user-image
							class="avatar-current"
							ng-if="$ctrl.user"
							size="145"
							user="$ctrl.user"></user-image>
				</div>
			</div>
			<p
					class="full-name"
					ng-bind="$ctrl.user.fullName"></p>
			<p
					class="email"
					ng-bind="$ctrl.user.email"></p>
			<ts-form
					hide-buttons="true"
					on-cancel="$ctrl.isOpen = false"
					on-submit="$ctrl.onFormSubmit()"
					submit-label="{{$ctrl.sendInvitationButtonText}}">
				<ts-form-fields>
					<ts-select
							label="{{$ctrl.roleButtonText}}"
							ng-model="$ctrl.selectedRole"
							options="$ctrl.roles"></ts-select>
					<fieldset ng-click="$ctrl.asides.selectApprover.isOpen = true">
						<label class='ts-label ts-spirit'>
							<span ng-bind="$ctrl.approverFormText"></span>
								<input
										data-ts.icon="ts-icon-select"
										ng-value="$ctrl.approver.fullName"/>
						</label>
					</fieldset>
					<ts-input
							ng-if="$ctrl.hasRequestLimit"
							info-messages="$ctrl.approvalLimitInfo"
							label="{{$ctrl.defaultLimitText}}"
							ng-model="$ctrl.requestLimit"
							single-error="true"
							is-required="false"
							validate="$ctrl.validateApprovalLimit(value)"></ts-input>
					<menu
							data-ts="Menu"
							ng-if="$ctrl.isOooAvailable">
						<li>
							<button ng-click="$ctrl.openAside('oooSettings')">
								<span ng-bind="$ctrl.outOfOfficeFormText"></span>
								<i class="ts-icon-arrowright"></i>
							</button>
						</li>
					</menu>
					<menu data-ts="Buttons">
						<li>
							<button
									class="ts-primary"
									data-ts="Button"
									ng-disabled="$ctrl.saveDisabled"
									type="submit">
								<span ng-bind="$ctrl.saveButtonText"></span>
							</button>
						</li>
						<li>
							<button
									class="ts-tertiary ts-danger"
									data-ts="Button"
									ng-click="$ctrl.removeUser()">
								<span ng-bind="$ctrl.removeFromTeamButtonText"></span>
							</button>
						</li>
						<li>
							<button
									class="ts-secondary"
									data-ts="Button"
									ng-click="$ctrl.close()">
								<span ng-bind="$ctrl.cancelButtonText"></span>
							</button>
						</li>
					</menu>
				</ts-form-fields>
			</ts-form>
		</ts-aside>
		<go-add-default-manager-aside
				is-open="$ctrl.asides.selectApprover.isOpen"
				on-approver-added="$ctrl.onApproverAdded($event)"
				on-close="$ctrl.closeAside('selectApprover')"
				team-id="$ctrl.teamId"></go-add-default-manager-aside>
		<go-team-out-of-office-aside
				is-open="$ctrl.asides.oooSettings.isOpen"
				on-close="$ctrl.closeAside('oooSettings')"
				team-id="$ctrl.teamId"></go-team-out-of-office-aside>
	`,
	controller: class UserDetailsAsideController {
		/* @ngInject */
		constructor(
			$timeout,
			constants,
			EventEmitter,
			FeatureService,
			LexicoService,
			TeamsService,
			RoleService,
			UserServiceV2,
			WalletHelper,
			WalletService,
			CompanyService,
		) {
			this.$timeout = $timeout;
			this.EventEmitter = EventEmitter;
			this.FeatureService = FeatureService;
			this.TeamsService = TeamsService;
			this.UserServiceV2 = UserServiceV2;
			this.WalletHelper = WalletHelper;
			this.WalletService = WalletService;
			this.CompanyService = CompanyService;
			this.roles = RoleService.getTeamRolesV2();
			this.isLimitInvalid = false;
			this.asides = {
				selectApprover: {
					isOpen: false,
				},
				oooSettings: {
					isOpen: false,
				},
			};
			this.hasActiveCards = false;
			this.lexico = LexicoService.getLexico();
			this.userDetailsTitleText = this.lexico.trc('Component title', 'User Details');
			this.sendInvitationButtonText = this.lexico.trc('Button', 'Send invitation');
			this.roleButtonText = this.lexico.trc('Button', 'Role');
			this.approverFormText = this.lexico.trc('Form input label', 'Approver');
			this.outOfOfficeFormText = this.lexico.trc('Form input label', 'Out of office');
			this.saveButtonText = this.lexico.trc('Button', 'Save');
			this.removeFromTeamButtonText = this.lexico.trc('Button', 'Remove from team');
			this.cancelButtonText = this.lexico.trc('Button', 'Cancel');
			this.defaultLimitText = this.lexico.tr('Approval limit');
			this.noRequestLimitTeamText = this.lexico.tr('If no value then team limit applies');
			this.noRequestLimitCompanyText = this.lexico.tr('If no value then company limit applies');
			this.invalidNumberText = this.lexico.tr('Please enter a positive integer or leave empty.');
			this.exceedsTeamLimitText = this.lexico.tr('This amount exceeds the team approval limit.');
			this.exceedsCompanyLimitText = this.lexico.tr('This amount exceeds the company approval limit.');
			this.APPROVE = constants.v2.access.operations.APPROVE;
			this.CARD_REQUEST_TO_ME = constants.v2.access.objects.CARD_REQUEST_TO_ME;
			this.requestLimitValidationText = maxRequestLimit =>
				`${this.lexico.tr('Should be less than or equal to')} ${maxRequestLimit}`;
		}

		openAside(key) {
			this.$timeout(() => {
				this.asides[key].isOpen = true;
			});
		}

		closeAside(key) {
			this.$timeout(() => {
				this.asides[key].isOpen = false;
			});
		}

		$onChanges(changes) {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}
		}

		onApproverAdded({ approver }) {
			this.approver = approver;
			this.asides.selectApprover.isOpen = false;
		}

		removeUser() {
			let warningMessage;
			if (this.hasActiveCards) {
				warningMessage = this.lexico.trc(
					'Warning message',
					"Users with active cards can't be removed. Please cancel cards to remove this user.",
				);
				ts.ui.Notification.warning(warningMessage);
			} else if (this.user.hasOpenRequests) {
				warningMessage = this.lexico.trc('Warning message', "Can't remove user with pending requests.");
				ts.ui.Notification.warning(warningMessage);
			} else if (this.user.isLastAdmin) {
				warningMessage = this.lexico.trc(
					'Warning message',
					"Can't remove this user. You need at least one team owner or manager on a team.",
				);
				ts.ui.Notification.warning(warningMessage);
			} else if (this.user.requesters.length > 0) {
				warningMessage = `${this.user.firstName} ${this.lexico.tr(
					'is responsible for approving requests from other team members. Please assign a new approver to the impacted members to proceed.',
				)}`;
				ts.ui.Notification.warning(warningMessage);
			} else {
				warningMessage = `${this.lexico.tr('Are you sure you want to remove')} ${this.user.firstName} ${this.lexico.tr(
					'from the team?',
				)}`;
				ts.ui.Dialog.warning(warningMessage, {
					onaccept: () => this.onRemoveUser(),
				});
			}
		}

		async onFormSubmit() {
			this.onSubmit(
				this.EventEmitter({
					role: this.selectedRole,
					approver: this.approver,
					requestLimit: this.requestLimit,
				}),
			);
		}

		close() {
			this.requestLimit = '';
			this.onClose();
		}

		async onOpen() {
			this.saveDisabled = false;
			this.isLoading = true;
			this.isOooAvailable = false;
			try {
				this.user = await this.TeamsService.getDecoratedUser({
					teamId: this.teamId,
					userId: this.userId,
				});

				const [canForwardApprovals, features] = await Promise.all([
					this.UserServiceV2.verifyAccess({
						operationId: this.APPROVE,
						objectId: this.CARD_REQUEST_TO_ME,
						teamId: this.teamId,
						userId: this.user.userId,
					}),
					this.FeatureService.getFeatures(),
				]);
				this.isOooAvailable = canForwardApprovals && _.get(features, 'ENABLE_OUT_OF_OFFICE_FORWARDING', false);
				this.hasActiveCards = false;
				const wallet = await this.WalletService.getUsersListForActiveTeam({
					teamId: this.teamId,
					userId: this.user.userId,
				});
				const cards = this.WalletHelper.buildCards(wallet);

				const role = _.find(this.user.roles, { securityGroupId: this.teamId });
				this.selectedRole = role ? { id: role.roleId } : {};
				this.approver = _.get(this.user, `teams[${this.teamId}].approver`);

				this.hasRequestLimit = this.selectedRole.id === 'manager' || this.selectedRole.id === 'team_admin';

				if (this.hasRequestLimit) {
					this.requestLimit = _.get(this.user, `teams[${this.teamId}].requestLimit`) || '';
				}

				this.teamRequestLimit = await this.TeamsService.getTeamRequestLimit(this.teamId);
				this.companyRequestLimit = await this.CompanyService.getRequestLimit();

				this.approvalLimitInfo = [];
				if (this.teamRequestLimit || this.companyRequestLimit) {
					this.approvalLimitInfo.push(
						this.requestLimitValidationText(this.teamRequestLimit || this.companyRequestLimit),
					);
					if (!this.requestLimit) {
						this.approvalLimitInfo.push(
							this.teamRequestLimit ? this.noRequestLimitTeamText : this.noRequestLimitCompanyText,
						);
					}
				}

				_.each(cards, async card => {
					await card.getVirtualCardInfo();
					if (card.isActive()) {
						this.hasActiveCards = true;
					}
				});
			} catch (error) {
				const warningMessage = this.lexico.trc(
					'Warning message',
					'There was a problem getting the user details. Please try again.',
				);
				ts.ui.Notification.warning(warningMessage);
				this.close();
			} finally {
				this.isLoading = false;
			}
		}

		validateApprovalLimit(limit) {
			const error = approvalLimitValidator.validateApprovalLimit(
				limit,
				this.teamRequestLimit || this.companyRequestLimit,
			);
			const isValidApprovalLimit = !error;
			this.saveDisabled = !isValidApprovalLimit;

			let errorMessage = '';
			if (error === approvalLimitValidator.errors.INVALID_FORMAT) {
				errorMessage = this.invalidNumberText;
			} else if (error === approvalLimitValidator.errors.EXCEEDED_AMOUNT) {
				errorMessage = this.teamRequestLimit ? this.exceedsTeamLimitText : this.exceedsCompanyLimitText;
			}

			return { valid: isValidApprovalLimit, errorMessage };
		}
	},
};

export default UserDetailsAside;
