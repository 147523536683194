export const validator = {
	errors: {
		INVALID_FORMAT: 'INVALID_FORMAT',
		EXCEEDED_AMOUNT: 'EXCEEDED_AMOUNT',
	},
};

export const isApprovalLimitFormatValid = limit => {
	if (limit === '') {
		return true;
	}
	if (Number(limit) === 0) {
		return false;
	}
	const positiveIntegerRegex = '^[1-9][0-9]*$';
	const positiveInteger = new RegExp(positiveIntegerRegex);
	return positiveInteger.exec(limit) !== null;
};

export const validateApprovalLimit = (approvalLimit, maxApprovalLimit) => {
	if (!isApprovalLimitFormatValid(approvalLimit)) {
		return validator.errors.INVALID_FORMAT;
	}
	if (maxApprovalLimit && Number(approvalLimit) > Number(maxApprovalLimit)) {
		return validator.errors.EXCEEDED_AMOUNT;
	}
	return '';
};
