const _ = require('lodash');

/* @ngInject */
function CompanyService($q, constants, HttpService, UserService) {
	const service = {};
	let isV2Enabled = null;

	// This doesn't just lookup the manager - also populates with user properties
	service.getDefaultManager = () => {
		return UserService.getUser().then(({ companyAccountId }) =>
			HttpService.tradeshiftGo().path(`/rest/company/${companyAccountId}/properties/defaultmanager`).get(),
		);
	};

	service.getInactiveEntries = async () => {
		const user = await UserService.getUser();
		return HttpService.tradeshiftGo().path(`/rest/company/${user.companyAccountId}/coding/lists/inactiveEntries`).get();
	};

	service.getRequestLimit = () => {
		const propertyKey = constants.companyPropertyKeys.requestLimit;
		return service
			.getProperty({ propertyKey })
			.then(requestLimit => (_.isObject(requestLimit) ? '' : _.toNumber(requestLimit)));
	};

	service.getVirtualCardExpRange = async () => {
		const propertyKey = constants.companyPropertyKeys.virtualCardExpRange;
		const expRange = await service.getProperty({ propertyKey });
		return _.toNumber(expRange);
	};

	service.getWorkflowConfig = () => {
		return UserService.getUser().then(({ companyAccountId }) =>
			HttpService.tradeshiftGo().path(`/rest/company/${companyAccountId}/workflowConfig/`).get(),
		);
	};

	service.getCompanyUsers = () => HttpService.tradeshiftGo().path('/rest/company/users').get();

	service.getProperty = ({ propertyKey }) => {
		return UserService.getUser().then(({ companyAccountId }) =>
			HttpService.tradeshiftGo().path(`/rest/company/${companyAccountId}/properties/${propertyKey}`).get(),
		);
	};

	service.getGoBranch = ({ companyId }) =>
		HttpService.tradeshiftGo().path(`/rest/company/${companyId}/go-branch`).get();

	service.storeGoBranch = ({ companyId, goBranchId }) => {
		return HttpService.tradeshiftGo().path(`/rest/company/${companyId}/go-branch`).put({ goBranchId });
	};

	service.clearGoBranch = ({ companyId }) =>
		HttpService.tradeshiftGo().path(`/rest/company/${companyId}/go-branch`).delete();

	service.setProperty = ({ propertyKey, propertyValue }) => {
		return UserService.getUser().then(({ companyAccountId }) =>
			HttpService.tradeshiftGo()
				.path(`/rest/company/${companyAccountId}/properties/${propertyKey}`)
				.put({ propertyKey, propertyValue }),
		);
	};

	service.setWorkflowConfig = ({ configId }) => {
		return UserService.getUser().then(({ companyAccountId }) =>
			HttpService.tradeshiftGo().path(`/rest/company/${companyAccountId}/workflowConfig/${configId}`).put(),
		);
	};

	service.updateCompanyName = ({ companyName }) =>
		HttpService.tradeshiftGo().path('/rest/company/companyName').put({ companyName });

	service.isV2Enabled = async () => {
		if (isV2Enabled === true) {
			return true;
		}
		const config = await service.getWorkflowConfig();
		isV2Enabled = _.get(config, 'id', '').includes('Go-V2');
		return isV2Enabled;
	};

	return service;
}

module.exports = CompanyService;
