"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columns = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
exports.columns = [
    {
        id: 'teamName',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, flex: 1, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: "Name" }) }));
        },
    },
    {
        id: 'teamNumberOfMembers',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: "Members" }) }));
        },
    },
    {
        id: 'teamNumberofVirtualCards',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: "Virtual Cards" }) }));
        },
    },
    {
        id: 'teamSourceCards',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: "Source cards" }) }));
        },
    },
    {
        id: 'teamState',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: "Status" }) }));
        },
    },
    {
        id: 'teamActions',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, direction: "row", flex: 0.5, justifyContent: "flex-end", alignItems: "flex-end", children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: "Actions" }) }));
        },
    },
];
