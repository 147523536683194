/* @ngInject */
function EventService($timeout) {
	const service = {};

	service.registerOneShotWithTimeout = ({
		$rootScope,
		eventHandler,
		eventType,
		guard,
		listenAfterTimeout = false,
		timeoutHandler,
		timeoutMs,
	}) => {
		const deregisterListener = $rootScope.$on(eventType, (event, args) => {
			if (!guard(event, args)) {
				return;
			}
			deregister();
			eventHandler(event, args);
		});
		const timeout = $timeout(() => {
			if (!listenAfterTimeout) {
				deregisterListener();
			}
			timeoutHandler();
		}, timeoutMs);

		const deregister = () => {
			deregisterListener();
			$timeout.cancel(timeout);
		};

		return deregister;
	};

	return service;
}

module.exports = EventService;
