const CancelCardAsideComponent = {
	bindings: {
		isOpen: '<',
		onCancelCard: '&',
		onClose: '&',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.cancelCardHeaderText}}">
			<ts-note
					icon="'ts-icon-warning'"
					message="$ctrl.message"></ts-note>
			<menu data-ts="Buttons">
				<ts-menu-button
						button-class="ts-primary"
						label="$ctrl.cancelCardButtonText"
						ng-click="$ctrl.onCancelCard()"></ts-menu-button>
				<ts-menu-button
						button-class="ts-secondary"
						label="$ctrl.cancelButtonText"
						on-click="$ctrl.onClose()"></ts-menu-button>
			</menu>
		</ts-aside>
	`,
	controller: class CancelCardAsideController {
		/* @ngInject */
		constructor(LexicoService) {
			this.lexico = LexicoService.getLexico();
			this.cancelCardHeaderText = this.lexico.trc('Component title', 'Cancel Card');
			this.cancelCardButtonText = this.lexico.trc('Button', 'Yes, Cancel Card');
			this.cancelButtonText = this.lexico.trc('Button', 'Cancel');
		}

		$onInit() {
			this.message = this.lexico.trc(
				'User notification',
				`Cancelling this card will not reverse any pending charges. Please look for the settlement
			records for those charges in the credit card statement sent by your card provider. Are you sure you want to
			cancel?`,
			);
		}
	},
};

export default CancelCardAsideComponent;
