const _ = require('lodash');

/* @ngInject */
function CreateRequestService($q, HttpService, RequestRegistry, TeamsService, UUIDService) {
	const service = {};
	service.createRequest = async () => {
		const { id: selectedTeamId } = await TeamsService.getCurrentTeam();
		const createdRequest = RequestRegistry.getCreatedRequest(selectedTeamId);
		if (!_.isEmpty(createdRequest)) {
			const requestId = createdRequest.getId();
			return {
				requestId,
			};
		}
		const requestId = UUIDService.v4();
		return HttpService.tradeshiftGo()
			.path(`/rest/requests/${requestId}`)
			.post()
			.then(() => ({ requestId }));
	};
	return service;
}

module.exports = CreateRequestService;
