"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCompanySetingsCardsState = exports.useTeamState = exports.useAsideState = exports.usePaymentState = void 0;
const zustandStore_1 = require("../../../../../zustandStore");
const usePaymentState = () => {
    const { cardSourceTeams, cardSourceId, card: cardSource, cards: companyCardSources, } = (0, zustandStore_1.useZustand)((state) => state.payment);
    return { cardSourceTeams, cardSourceId, companyCardSources, cardSource };
};
exports.usePaymentState = usePaymentState;
const useAsideState = () => {
    const { cardSourceDetail, selectTeams: { options: cardOptions }, } = (0, zustandStore_1.useZustand)((state) => state.aside);
    return { cardSourceDetail, cardOptions };
};
exports.useAsideState = useAsideState;
const useTeamState = () => {
    const { teamId } = (0, zustandStore_1.useZustand)((state) => state.team);
    return { teamId };
};
exports.useTeamState = useTeamState;
const useCompanySetingsCardsState = () => {
    const { setUser, setCompanyName, setCompanyId, setHeaderName, setCardSourceId, setIsAddSourceCardOpen, setIsCardSourceDetailOpen, setIsSelectTeamsOpen, setOpenAsides, setNotification, cardSourceSetErrors, company, aside, } = (0, zustandStore_1.useZustand)();
    const { cardSourceTeams, cardSourceId, companyCardSources, cardSource } = (0, exports.usePaymentState)();
    const { cardSourceDetail, cardOptions } = (0, exports.useAsideState)();
    const { teamId } = (0, exports.useTeamState)();
    return {
        setUser,
        setCompanyName,
        setCompanyId,
        setHeaderName,
        setCardSourceId,
        setIsAddSourceCardOpen,
        setIsCardSourceDetailOpen,
        setIsSelectTeamsOpen,
        setOpenAsides,
        setNotification,
        cardSourceSetErrors,
        company,
        cardSource,
        cardSourceTeams,
        cardSourceId,
        cardSourceDetail,
        cardOptions,
        companyCardSources,
        teamId,
        aside,
    };
};
exports.useCompanySetingsCardsState = useCompanySetingsCardsState;
