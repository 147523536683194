import { cloneDeep, difference, isEmpty, isNil, pull } from 'lodash';

const SelectAsideComponent = {
	bindings: {
		allowEmptySelection: '<',
		emptyMessage: '<',
		hasSelectAll: '<',
		isLoading: '<',
		isMultiSelect: '<',
		isOpen: '<',
		model: '<',
		onClose: '&',
		onSelect: '&',
		onSubmit: '&',
		options: '<',
		selectAllLabel: '@',
		submitLabel: '@',
		title: '<',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{ $ctrl.title }}">
			<p ng-if="$ctrl.options.length === 0" ng-bind="$ctrl.emptyMessage"></p>
			<menu data-ts="Menu">
				<li
						ng-class="{ 'ts-checked': $ctrl.isAllSelected() }"
						ng-if="$ctrl.isMultiSelect && $ctrl.hasSelectAll">
					<button ng-click="$ctrl.toggleSelectAll()" type="button">
						<span ng-bind="$ctrl.selectAllLabel"></span>
						<i class="ts-icon-checked" ng-show="$ctrl.isAllSelected()"></i>
					</button>
				</li>
				<li
						ng-class="{ 'ts-checked': $ctrl.isChecked(option) }"
						ng-repeat="option in $ctrl.options">
					<button ng-click="$ctrl.select(option)" type="button" disabled ng-if="option.disabled">
						<span ng-bind="option.label"></span>
						<span ng-bind="option.description"></span>
						<i class="ts-icon-checked" ng-show="$ctrl.isChecked(option)"></i>
					</button>
					<button ng-click="$ctrl.select(option)" type="button" ng-if="!option.disabled">
						<span ng-bind="option.label"></span>
						<span ng-bind="option.description"></span>
						<i class="ts-icon-checked" ng-show="$ctrl.isChecked(option)"></i>
					</button>
				</li>
			</menu>
			<menu data-ts="Buttons" ng-if="$ctrl.isMultiSelect">
				<li>
					<button
							class="ts-primary"
							data-ts="Button"
							ng-click="$ctrl.submit()"
							ng-disabled="!$ctrl.allowEmptySelection && $ctrl.hasNoneSelected()">
						<span ng-bind="$ctrl.submitLabel || $ctrl.saveButtonText"></span>
					</button>
				</li>
			</menu>
		</ts-aside>
	`,
	controller: class SelectAsideController {
		/* @ngInject */
		constructor(EventEmitter, LexicoService) {
			this.EventEmitter = EventEmitter;
			this.lexico = LexicoService.getLexico();
			this.saveButtonText = this.lexico.trc('Button', 'Save');
		}

		$onChanges(changes) {
			if (changes.model && changes.model.currentValue) {
				this.model = cloneDeep(changes.model.currentValue);
			}
			if (changes.options && changes.options.currentValue) {
				this.options = cloneDeep(changes.options.currentValue);
			}
		}

		hasNoneSelected() {
			return !isEmpty(this.model) && Array.isArray(this.model.id) && isEmpty(this.model.id);
		}

		isAllSelected() {
			if (!(this.isMultiSelect === true) || isEmpty(this.options) || isEmpty(this.model)) {
				return false;
			}
			const optionIds = this.options.map(option => option.id);
			return isEmpty(difference(optionIds, this.model.id));
		}

		isChecked(option) {
			if (!this.isMultiSelect || !this.hasSelectAll) {
				return this.isSelected(option);
			}
			return this.isSelected(option) && !this.isAllSelected();
		}

		isSelected(option) {
			if (isNil(this.model)) {
				return false;
			} else if (Array.isArray(this.model.id) && this.isMultiSelect === true) {
				return this.model.id.includes(option.id);
			} else {
				return this.model.id === option.id;
			}
		}

		select(option) {
			if (!this.isMultiSelect) {
				this.onSelect(this.EventEmitter({ option }));
				return;
			}
			if (this.isAllSelected() && this.hasSelectAll) {
				this.model.id = [option.id];
			} else if (this.isSelected(option)) {
				pull(this.model.id, option.id);
			} else {
				this.model.id.push(option.id);
			}
		}

		submit() {
			this.onSubmit(this.EventEmitter(this.model));
		}

		toggleSelectAll() {
			if (this.isAllSelected()) {
				this.model.id = [];
			} else {
				this.model.id = this.options.map(option => option.id);
			}
		}
	},
};

export default SelectAsideComponent;
