'use strict';

const ConstantsFactory = require('./ConstantsFactory');
const values = {
	v2: {
		// access needs to be in v2 namespace since access already exists in the server constants, and common constants
		// overrides server constants
		codingLists: require('../constants/presetCodingList'),
		access: require('../constants/access'),
	},
	companyPropertyKeys: require('../constants/companyPropertyKeys.js'),
	companyProperties: require('../constants/companyProperties.js'),
	domainWhitelisting: require('../constants/domainWhitelisting'),
	languages: require('../constants/languages'),
	login: require('../constants/login'),
	mediaTypes: require('../constants/mediaTypes'),
	payments: require('../constants/payments'),
	roles: require('../constants/roles'),
	securityGroupTypes: require('../constants/securityGroupTypes'),
	teamProperties: require('../constants/teamProperties'),
	teams: require('../constants/teams'),
	uiPaths: require('../constants/uiPaths'),
	userTeamProperties: require('../constants/userTeamProperties'),
	virtualCardStatus: require('../constants/virtualCardStatus'),
	settlements: require('../constants/settlements'),
};
const constants = ConstantsFactory({ values });

module.exports = constants;
