function DOMService() {
	const ngApp = document.querySelectorAll('[ng-app]')[0];
	const service = {};

	service.moveElementBelowNgApp = ({ $element }) => {
		const rootElement = $element[0];
		$element.ready(() => {
			ngApp.appendChild(rootElement);
		});
		return rootElement;
	};

	return service;
}

module.exports = DOMService;
