'use strict';

const helper = {};

helper.generateComposites = (operations, objects) => {
	return Object.entries(operations).reduce((output, [operationKey, operationId]) => {
		Object.entries(objects).forEach(([objectKey, objectId]) => {
			output[`${operationKey}_${objectKey}`] = { operationId, objectId };
		});
		return output;
	}, {});
};

module.exports = helper;
