"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hsbcRegistrationStepsInitialState = void 0;
exports.hsbcRegistrationStepsSlice = hsbcRegistrationStepsSlice;
exports.hsbcRegistrationStepsInitialState = {
    steps: {
        collection: [
            {
                index: 0,
                isCompleted: true,
            },
        ],
        currentStep: 0,
        data: {
            tempPassword: '',
        },
    },
};
function hsbcRegistrationStepsSlice(set) {
    return {
        ...exports.hsbcRegistrationStepsInitialState,
        setStepsCollection(steps) {
            set((state) => {
                state.steps.collection = steps.map((step, index) => ({
                    ...step,
                    index,
                    isCompleted: index === 0 ? true : step.isCompleted,
                }));
                return state;
            }, false, '[setStepsCollection]');
        },
        setStepsData(data) {
            set((state) => {
                state.steps.data = { ...state.steps.data, ...data };
                return state;
            }, false, '[setStepsData]');
        },
        setCurrentStep(currentStep) {
            set((state) => {
                if (state.steps.collection[currentStep]) {
                    state.steps.collection = state.steps.collection.map((step, index) => ({
                        ...step,
                        isCompleted: index <= currentStep,
                    }));
                }
                const stepToSet = state.steps.collection[currentStep];
                state.steps.currentStep = stepToSet ? stepToSet.index : 0;
                return state;
            }, false, '[setCurrentStep]');
        },
    };
}
