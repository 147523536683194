module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# av transaksjoner",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minutt",
"ce09c436b49af331a32f1152ede7dd18":"1. Etterspør finansiering",
"d2226752d3065fbad4744924b4a0ee73":"2. Gå gjennom og godkjenn",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Gå gjennom og godkjenn",
"82bab92f1c8030888550b51521266c53":"3. Betal med virtuelt kort",
"2ecd62e02e507f8e839a55eac0a04886":"En innlagt koding med ID '{entryId}' eksisterer allerede.",
"5dfd25849740fbce8147838b0d5094eb":"Et hyggelig kallenavn, f.eks. Markedsføringskort",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Et medlem av vår kundesuksessteam vil ta kontakt med deg innen kort tid.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"En person vi mislyktes i å finne",
"976b50e70e4433e2786233979d243445":"Et team med dette navnet eksisterer allerede.",
"35b61d21ac11d5fc31ebe58541263445":"Aksepter invitasjonen til å delta",
"a372cb2d4204654f52cd9170fb10d374":"Regnskapskoder",
"110fc66010b6b91cebee7a59a2067aab":"Regnskapskoder",
"db862bb04dbdc6d04e47063f33be8865":"Regnskapskoder administreres gjennom kodinglister og oppføringer i lister.",
"d6e3848e824f077396be4351eb58edd2":"Påkrevd handling – godta/avslå ny forespørsel av {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Handlinger",
"476e728ea824229f52438e543818bcdd":"Handlinger",
"3117746cb7d4ab815ec98274ec4398d5":"Handlinger",
"3ab09b66a7f16219d8135977a2a76d10":"Handlinger",
"ffafa66eaab0409a2e90aaeb43796def":"Aktiv",
"0447e0aa5d00dbdab11b5546793c4479":"Aktiv",
"8ee51d21b05466f7d962d4ff5a0b384e":"Aktive kort",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Aktive virtuelle kort",
"89c6ec05861aa914304ba35bc6744124":"Aktive kort",
"5280659bcecaf8344c57790f9962a9a2":"Aktivitet",
"8a15368ae286fa64e1cb7624cd410fc6":"Legg til kodingsliste",
"8ded7e09e86be231b1165308329ac76d":"Legg til liste",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Legg til administrator",
"695bb4bc29e2df769ddf7427ab71012a":"Legg til godkjenner",
"4a1d4516cab00b7c5ecdb150e346e5db":"Legg til godkjenner",
"56869851b27c2915f5a7fe6a33b91ac7":"Legg til kodingsliste",
"8f8691057c3994aa61f9994202680c87":"Legg til kodingsliste",
"69f7a406b79d19bf8082ed245ab29123":"Legg til kodingsliste",
"48a92ca1cc63018707be99b13196369f":"Legg til bedriftsadministrator",
"a679db9ff80d0e5769ae1ba311c7916f":"Legg til domene",
"8f5e8078be77146ba7f3be49ef948906":"Legg til oppføring",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Legg til melding",
"f8a4b14162e19995074e63135b65c84d":"Legg til nytt kort",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Legg til personer",
"c670549d0b898bb6a571eb2cd7804a6e":"Legg til kvittering",
"eb937098e77d8a6b0565ed72357dcaae":"Legg til kvittering",
"1e154f31c31b63a459f3956e380b3ef9":"Legg til valgte",
"ba24db9956f156a18b32e653552ff682":"Legg til noen personer til dette teamet",
"52bd93fb5489b25167bb541f7c6504ae":"Legg til kildekort",
"21ab3f08eb507dbc76df166eafeb393a":"La til {fullName} som en godkjenner!",
"f879c06507d3076bbf52ea4fd2df850e":"Informasjon om tilleggskort",
"21e934ae30eece4a894337701f497638":"Alle",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Alle kort",
"8fac71195e2b506e029752e4864b6a5d":"Alle medlemmer",
"f9708417df16227742e0545a8642eea7":"Alle tilgjengelige brukere har allerede blitt lagt til på dette kortet.",
"cb5abf070f8cba7982205038baab77bb":"Alle kort",
"dae7d602eec2c84dea0d80ae25e56e6d":"Alle kort",
"0b4bdfde6578668b6018d3615a1ad30f":"Alle kostnadsteder",
"97b875b81f54b4432d998a3e47d949cc":"Alle team",
"f0ae83969f7c20a1964caf5156dae966":"Alle team",
"d87455601a00282078582b36a3825b7d":"Alle team",
"24c7b7f55882ea94a525392ca8218fa0":"Alle team",
"8f860e35b64a8f447bded1e5ea66a2a8":"Alle virtuelle kort",
"e403956ecaa6fb33ec1fef5c49d71042":"Alle virtuelle kort",
"4e6f4e8f25f61d40e1eba77de94f6458":"All din finansielle informasjon er kryptert og på servere på sikre plasseringer.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Beløp",
"a4d099d0dbe3889b0995546e6ed28932":"Beløp",
"6c0ef32aae76bc31a61e2124f542bdf1":"Beløp",
"adec89c93525f1a8d1c64d856f405e27":"Beløp: høy til lav",
"18e632a170203894f40deed1d33ee552":"Beløp: lav til høy",
"a7944575cafef9126a499a1b06999fe3":"Det oppstod en feil. Prøv igjen senere.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Bruk",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Godkjenneralternativer",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Godkjenningsinnstillinger",
"492eedd88cdd7bb45f39c6594af748c4":"Godkjenningsgrense",
"437d490afc8d226f8c7d544f4e35d011":"Godkjenningsgrense ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Godkjenninger",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Godkjenninger",
"17adb7fe866e32055b1469fdb7e82795":"Godkjenningsinnstillinger",
"7125aa13db437084e1d27d3ff38f22e2":"Godkjenn",
"84df787ff5746f9dc3cf0146becef4fd":"Godkjenn forespørsel",
"7a568654d00245b0bab6c90a1b67d196":"Godkjent",
"179c3e187ad8edeb8348cff222b938bf":"Godkjent",
"ad058d17725870df9abbd4f5ec217636":"Godkjent ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Godkjent beløp",
"4d58ba5c0e03fec1556c243be106e4a3":"Godkjent av",
"7d3d697fc99f6c4641b326623743f7b7":"Godkjent { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Godkjenne",
"8c45e443fc6748190719d79a092e2528":"Godkjennere har en maksimum godkjenningsgrense",
"4fe56aa6456c108cd288a4ec8acf435f":"Arkiv",
"a9b6f93de9630064be9809130aa1e195":"Er du klar til å ta kontroll over kortbruken din?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Er du sikker på at du ønsker å slette denne forespørselen?",
"301bfc2ce058f49e0704fec3d162b5b5":"Er du sikker på at du ønsker å tildele denne forespørselen til deg selv å nytt?",
"2181faf8d9973d1f4138e401a762086e":"Er su sikker på at du ønsker å fjerne ${firstName} ${lastName} som firmaadministrator?",
"b982277f29055d0ef6e295fe2006d9e0":"Er du sikker på at du ønsker å fjerne {firstName} fra teamet?",
"456c0ccff14acb808583cd9dece857b6":"Tildelt til",
"bde315b9cf7fd55f4b65aabbbf38d462":"Minst en administrator eller teameier må være tilgjengelig for bruk utenfor kontoret.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Verifisering",
"1961ec69dd0c8b5718c831312e7f3d0f":"Automatisert kvitteringssamling",
"e39f2477cf34d726936a5b5c2b48a4ad":"Opprett automatisk korrekte avstemmingsrapporter, som inkluderer informasjon om kjøp, godkjennere, kvitteringer, kontokoder, med mer.",
"434ca07dfde0eaee14f094b7c75a6eae":"Tilgjengelig",
"eb85d8f50f2c89871b794f7d54279b22":"Tilgjengelig saldo",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Tilgjengelig { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Tilbake",
"c8258f8cf9e20f6bbb89d957de40e820":"Sikkerhet på nivå med bankene",
"d46c22a3f36dce568c07fcea4368b231":"Basisinnstillinger",
"fb481c8e517016094b4fdf618c5fa088":"Faktureringsadresse",
"79a9f821923c5a04c076902fef684281":"Faktureringsadresse",
"877a08b8347052c83aa0b689baea81e7":"Faktureringsadresse",
"97f018458e2627fac37d24846c7b8922":"Ved å fortsette med opprettelsen av kontoen din og bruk Tradeshift Go, godtar du vår",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Ved å fortsette med opprettelsen av kontoen din og bruk Tradeshift Go, godtar du vår",
"912d1864f0068c764bf4784020795d49":"NAVN PÅ KORTHOLDER",
"d53474c7fd99fbeb69481ce9d358aa09":"Kan etterspørre kort og ha en lommebok for alle kortene deres. Hvert medlem får en standard godkjenner (administrator) for forespørslene deres.",
"f30df2cab105ed3f8b4264aeb9800abd":"Kan godkjenne forespørsler og opprett kort for medlemmer som de er administrator for. Administrator har et dashbord for å spore bruken fra medlemmer som de administrerer.",
"03ed9eccc71ef59165292eac542d3050":"Kan se alle forespørsler og bruksaktivitet i teamet. Teameiere får også et teamdashbord for å spore bruke og team-lommebok hvor de kan se alle aktive kort.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Kan ikke fjerne denne brukeren. Du må ha minst en teameier eller leder på teamet.",
"a0b70642629a55ea0ab6fa3414d342e0":"Kan ikke fjerne bruker med ventede forespørsler.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Avbryt",
"5446624d3c364ac05ddf8d6663da2796":"Kanseller kort",
"53f3815c4fa3340b29efd81e627d1e6b":"Kanseller kort",
"12257ea2a871b7fab72245b5aafe6424":"Avbryt forespørsel",
"2aafb7eb4d56a516c4bb0754f0812df1":"Kanseller kort",
"caae12601b01e2325161e95107f9e332":"Kanseller kort",
"6ad1e57b1e4c7accbe166c948883077d":"Steng kortet med ett klikk dersom det er utsatt for risiko",
"f6527e49e6d5c989624507a583e23f39":"Kanseller ({count})",
"1ad3bf5121641dda511f42942af05a90":"Kansellering av dette kortet vil ikke reversere noen ventende gebyr. Vennligst se etter oppgjøret",
"aebd3826a195afad0a93050a7cc5d73d":"Kansellering av dette kortet vil ikke reversere noen ventende gebyr. Se etter oppgjørsdokumenter for kredittkortgebyrene i kunngjøringen fra kortleverandøren din.",
"a5b5136947da562f91b75daf8156f50e":"Kortdetaljer",
"9ed31ad23a1578ce480d31207decf1d3":"Kortinnstillinger",
"def15f2e007fc69886230fdb2511305c":"Kortdetaljer",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Kortdetaljer",
"67cfd260078ca32027c2fa4a4a2d29f4":"Kortholder",
"4458819ea60c68c46e551a8f5cc163cd":"Navn på kort",
"0858bcbd506fa3240b165e59d2f18aae":"Navn på kort",
"5212736dd18ee2c31f3650ad2c2c7130":"Navn på kort er oppdatert.",
"c96d62fa510c3356dc73a91d1a80e262":"Kortnummer",
"41307fee4c32aefb5383a98fe3d6e45e":"Korttype",
"3f8045c3358621dad4346a44de0c05a8":"Navn på kortholder",
"879a6b452033cf5f3a4bb3e1837a0386":"By",
"593334553ef852024e172505ec3c5f21":"Klikk her",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Koding",
"df931751d5790d74fa46707aaf7a26ae":"Koding 1",
"7ac7154bc776aff52a71b0d83b860561":"Koding 2",
"586fcf19136ae5e26795e9055b2e2995":"Koding 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Koding 4",
"379bb8e71bb090badebbb0751de86f11":"Koding 5",
"3e84d13627b3d090ab2b8470af98340a":"Koding 6",
"33b94b581f3792049992663396e51cb8":"Koding 7",
"b73fbc32e439655833ee99126dd0e55f":"Koding 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Kodingsliste",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Kodeoppføring",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Kodeoppføring slettet.",
"cada62a39ee72d790dcdbb25160f8783":"Kodeoppføring lagret.",
"f645bf50888943ee1384693e56a0f475":"Navn på kodingsliste",
"a999182acbcd77c15299f79181b630be":"Kodeliste lagret.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Kodingslister",
"d0b96596ce6baec5aabb8a0690ab30e1":"Kommentarer",
"ff9cb8842f832d21fb160b3d3784ce79":"Firma",
"715b59a64e686eb91a21114644ffe39e":"Firmaadministrator har blitt fjernet",
"8758e9a01253c097a42393fda9610715":"Firmadashbord",
"e118b41009918b326db6746856831cbc":"Firma-ID",
"4dba88b05b3fa8875128e19e88c512b3":"Firmainnstillinger",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Firmainnstillinger",
"f2ea6d33de8182d97bf2f13354af4893":"Selskapsinnstillinger: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Firmaadministratorer",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Godkjenningsgrense for bedrift",
"7e2f778da14a726b53e05926a3483f2b":"Kredittkort for bedrift",
"7313fd1db3d63858586d1c0f89251d6e":"Bedriftsnavn",
"07721e91dd10ff8831f0d24e47cc1eab":"Bedriftsnavn",
"203221610ca4d347063c037e1a732ae8":"Selskapsinnstillinger lagret.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Bekreft handling",
"d694cec09cdbc02b1ffe22642a63faa1":"Bekreft passord",
"512801312a133f29974f2259a455f39b":"Bekreft passord",
"f54c38ed0b9869d929ffc0bd5310131f":"Bekreftelse – din konto er opprettet under Team {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Kontakt firmaadministratoren din for opprettelsen av regnskapskoder.",
"7a6a4de077398e8e138aec36e3b3d305":"Fortsett med enkel pålogging (SSO)",
"aeb18b6ec219aa26471ebee632c3d53b":"Opphavsrett {date} Tradeshift Inc. Med enerett.",
"51f0d8e04f6ee626b866b37833dc2b61":"Kostnadssenter",
"e043f60a02f157d0f31a42f0db67cc87":"Kunne ikke invitere {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Kunne ikke sende meldingen din. Prøv på nytt.",
"ae28083f22a0b2fc5637614bce0976e0":"Opprett kundeliste",
"738078dc992208e05406f935995dc0c3":"Opprett kundeliste",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Opprett ny liste",
"0f5783c3921b3d7398d281644bbe5bde":"Opprett en forespørsel",
"6d62f934f2304701f88bdcfbc688de7e":"Opprett konto",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Opprett en konto",
"f6b7d0d8531ea268f988005dd2147b2b":"Opprett kort",
"1a083675d1453448e4645403bbe52dd9":"Opprett egendefinert liste",
"c10ab11bdfb001da74850ed89c45482f":"Opprett ny forespørsel",
"43a9c06398408bfe77d06ffc2c1c3b87":"Opprett ny forespørsel",
"68bf17fd3f54a89c0a84224e2f9a971c":"Opprett nytt team",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Opprett team",
"3b8e665101b9b3657f8a68c5305947b7":"Opprettet",
"40e91f2907181ffa8e62567b3a407e60":"Opprettet av",
"8929f7aacb969ef6353aba9903eb362d":"Kredittkort lagt til.",
"d86688c26e8ac5eb9facc971b6fe5155":"Valuta",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Valuta",
"500ded53f64e737a5e4337675ac9b7ce":"Egendefinert listenavn",
"b79a95f04fd829fb2e8d0313e522a94d":"Daglig",
"fb7add662cf7042355319a975fab6ae3":"Datakryptering",
"6bded249dbfafd14490eea8dd37e1f51":"Dato",
"46e908a57cb09ef947af8c4a93a09716":"Dato",
"e4213104a55eceb60e00408699438dcd":"Deaktiver team",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Deaktivering av team vil kansellere alle aktive virtuelle kort og ingen av teammedlemmer, administratorer eller eiere vil ha tilgang til teamet. Kun du eller en annen firmaadministrator kan aktivere team på nytt. Er du sikker?",
"0d0d5677bee2b99884802638e507160a":"Avslå",
"b5e3483aae9d36ce1440fe02904d6da1":"Avslå forespørsel",
"00f3db97287cb17ee9c2a131f2141a65":"Avslått",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Avslo ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Standardgodkjenner",
"b77589ac3ac2317cfc96d7013658d5c9":"Slett koding-ID: { entryId }, Oppføring: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Detaljerte utdrag og rapportering",
"5234dfc4220ccd18a8b62fe84e372248":"Detaljer",
"37929896817a6744718004374bb42fd8":"Detaljer",
"dd0a6194e8a6ae14f3e1379a1640de15":"Mente du å fjerne {fullName} ({email}) fra firmaet?",
"1a98048b34090da8b199135ccb4c1186":"Spesifikt nummer og CVC skjuler reell kortinformasjon",
"3cf132b60fe16b816ca227bbcad60537":"Ikke kanseller",
"dee57797e14ccf852aa2bc545bafdf6d":"Last ned",
"375ae8224d7d35393974775cebe8605a":"Last ned brukerliste",
"54b3bfd45a293b441d441aab1a50e644":"Last ned rapport",
"60cf3b94e5df14a504825068928fc118":"Last ned rapport-CSV",
"7341a4c73c0b688484304c37da4a6d8e":"UTLØPSDATO",
"f628f8ad44fbd6c70c99fc778407b666":"Søk enkelt i historiske overføringer og utforsk trender i ansattes forbruk på tvers av tidsperioder.",
"94bb409d034ea20697ae09d812e55365":"Rediger kundeliste",
"2974e704676cb79666bc236d8658fa97":"Rediger profil",
"9ff205f99ed4002d049f855f965ca6d8":"Rediger bruker",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-post",
"0534c00af758d42ebd8b0bd772eab074":"E-post",
"0c9aa1fbdf925065d25161eab5e51d95":"E-post",
"ea78fac68766a48b1b0f41305b7e9898":"E-post",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Ansatt etterspør finansiering fra firmakortet for å utføre kjøpet. Etterspurte finansieringer kan ha faste grense for bruk.",
"829cff16ce0b13810c914c161b2a9fba":"Aktiver alternative brukerfunksjoner",
"741387c7bd71e2b4a9601af9a6b9490e":"Aktiver automatisk videresending av godkjenningsforespørsler",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Aktiver standard utløpsdato for virtuelt kort",
"bb319710dad93e78cc6f58c615bdf210":"Aktiver hvitelisting av e-postdomene for inviterte brukere",
"4700ced24cd278c3149ffa983006035c":"Aktiver filtrering etter kostnadsted",
"3fe06ef8f551c4cb4c0375362e25521d":"Aktiver globaliseringsalternativ for separat bruker",
"2787d4bc9752272ac36b499ce7ad93ab":"Aktiver bruk av flere virtuelle kort",
"6ee44e2280c9ce5009568846cbc3dd14":"Aktiver “ikke på kontoret”",
"1941ca87053df0cebc6091ede92c18ea":"Aktiver personvisning for firmaadministratorbrukere",
"e50282c2ec7afb948d2c0b428db5d5ff":"Aktiver kvitteringsopplasting for transaksjoner",
"268694cb2172918fe60f835919bf20ec":"Aktiver kvitteringsopplasting i avtaletabell",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Aktiver godkjenning av seg selv",
"d17c7acf598b569cce8a2bb97e0bb215":"Aktiver enkelt bruk av virtuelle kort",
"cd731ca218c9e14caabefafe003cfa6d":"Aktiver abonnementer på virtuelle kort",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Avslutt videresending på",
"c1f665a814894b17d603b7052b631d90":"Registrer et nytt kort",
"6b6cea93e07e596be70f7b76801e1516":"Registrer nytt kort",
"7d6ac1ac9cba1620b61908842ebb106b":"Registrer kildekort",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Registrer kildekort",
"21a642b339c3eabf2789c0b05dcf828b":"Registrer ditt første selskapskort på GO.",
"6efa8dca4fba23a190838746879fd704":"Skriv inn en numerisk ID",
"02c3457577da9dc8dba6e6f9380a248e":"Skriv inn et unikt teamnavn",
"40feb3fd1fa529cb599aeac9607664a4":"Skriv inn ny oppføring",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Eksempel: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Utløpsmåned",
"b8bc74aeeb2de3492891c97361de107d":"Utløpsåret",
"6a0b8d521500014d99dd192d8ce8403d":"Utløpsdato",
"8cf274169a732340430fe7cc5236e0e7":"Filstørrelsen bør være mindre enn {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Fornavn",
"7458b55e967e51791d98e77458631e44":"Fornavn",
"3bbe52e71bc5c212cea77983f1a5b748":"Fornavn",
"9b49c88fa20cc006baaac15c857c8781":"For engangskjøp eller flere kjøp",
"bb7a2907073bd760f4e82097132637b1":"Glemte passord",
"26bdfaa592f3629273f13c22435b323d":"Glemt passordet?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Videresend",
"3f9fe6adc14ce5776097170653a24462":"Videresend forespørsel",
"b774e35683f5737c36bf0cf3d6785973":"Videresend forespørsel",
"628faa5054a912b1e470414f77057e6b":"Videresend forespørsel",
"852b4065e1837fdc77940b6411a6d902":"Svindelbeskyttelse",
"616551bd10d95aca439401d6a7769d96":"Fraktmengde",
"a65a230918e9c11e1ef8824745e26a3d":"Kom i gang",
"7d4c4466b7716347ce3673acaa1983f7":"Få mer fra Go",
"11415a07658cfef80eb166896d77ad98":"Vær klar for enda bedre oversikt i forbruket. Tradeshift Go vil nå også passe bedre med hvordan bedriftsteam liker å arbeide.",
"ac0ff5d14286ef74614a9c6021f08f69":"Få en oversikt over forbruket i sanntid og spor budsjettet, få innsikt og gå i dybden på enhver kjøpsaktivitet.",
"db9071ab833054307252a936e7f60e1b":"Kom i gang",
"24dc4bb00841b004031fca3a9bb9bf51":"Go-kortforespørsel kansellert av {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go melder fra til ansatte dersom det mangler noen kvitteringer som kan festes ved å svare på varslet, eller i tilfeller ved e-postkvitteringer, videresende det til Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go arbeider med kredittkortnettverket ditt for å oppnå en standard for svindelforhindring med samme garanti som du ville forventet å få gjennom de beste finansielle institusjonene.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Gode nyheter! Din kjøpsforespørsel har blitt godkjent av Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Har du noen spørsmål eller tilbakemelding? Ta kontakt på",
"9df1d3de68d663b848101df3c7b58e39":"Slik fungerer det",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Jeg er sikker",
"84074732eb8d1b4094404cd750baf949":"Dersom ingen verdi er angitt gjelder bedriftsgrensen",
"138e8bd6102a7eea70a123ff03b1cd79":"Dersom ingen verdi er angitt gjelder teamgrensen",
"e5756af4d7e5ad1236ccfbb858428421":"Dersom du ikke registrerte deg for Tradeshift Go ved bruk av denne e-postadressen",
"4f004f75f47dbfecdb6d09990596e530":"Dersom e-posten ikke er registrert enda, vil i sende deg en aktiverings-e-post med informasjon om hvordan du går frem.",
"123837f267de602fb05361b3b1197c1e":"Dersom {username} samsvarte med en eksisterende konto, sender vi deg en e-post med informasjon om hvordan du går frem. Dersom du fortsatt ikke kan se e-posten i innboksen innen noen få minutter, sjekk søppelpostfolderen.",
"45efd96cd93525a9c17222217a37d324":"Bilde- eller PDF-filformater støttet",
"fa6ae823f9ab8bf91c246efe2517956c":"Importer din QuickBooks-, NetSuite- eller Xero-kontokonfigurasjon direkte inn i Go. Eksporter de seneste transaksjonen inn i kontoløsningen din med et enkelt klikk.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inaktiv",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Inndata inneholder et ugyldig tegn.",
"62c7be09da43f8f96a60ef3967eddc2c":"Introduserer en helt ny Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Ugyldig e-postdomene.",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Inviter personer",
"3809b41b14c55c3fcf54cd813d8e21f5":"Inviter til team",
"fc90a11805961b0d7b5a5545e4d9bc05":"Inviter bruker",
"6f7f4acde92d267417f5d8a60d32400a":"Inviter flere personer",
"09ecd2ca15bb5c38dc10f653118346cd":"Inviter ny administrerende bruker",
"3f576ce504f53f972442a63a170c5418":"Inviter ny(tt) {role}",
"0215e052437ceea4e5cd562431b34ced":"Inviter personer",
"8aaf2c77df644b7e8524120f66da7d5f":"Fakturabeløp",
"1b7dc8290f1205f092cc9133e0cde385":"Fakturadato",
"1a6fb210308df8e0135e49e76aaff31e":"Fakturanummer",
"613da3d0cd586ef0360e0d93eace9508":"Utstedt den",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Det tar",
"3322b671105c65511fbab1966b7fdcf6":"Språk",
"b1d946192795a58aea6a61f4f923acb7":"Siste 4 sifre",
"b3c6b8db4ef6dcdcb451303e65548816":"Siste 4 sifre",
"0382123b9c847000fceff2484c665e39":"Siste fire sifre",
"19f7c0055e150e9e3e66e85c2993799c":"Etternavn",
"662c84bede8a7bf91ab15bcb73b48b49":"Etternavn",
"cff8fbef5aac2523344b9535bd56973c":"Etternavn",
"c3510b2184a92ff12e07a2d1b9a461e6":"La ansatte etterspørre tilgang til selskapskortet ditt uten å oppgi kortdetaljene dine. Alt, på samme tid som du opprettholder budsjetter og sporer hvert kjøp.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Grense kan overskrives på et team-godkjenningsnivå eller individuelt godkjenningsnivå",
"9bf5d56627e500cad9f7ad21c3e7966d":"Begrensing til utvalgte utgiftskategorier",
"e80a6d4232e52f6bfabee02e17403b59":"Koble et av disse kildekortene til dette teamet og registrer et nytt et.",
"803570ab9f0fba5ff35ae2749200501f":"Laster kort ...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Logg inn",
"ad96c1ca962a883652dadb845f439415":"Pålogging",
"072ca67e735adbed54acf41321370701":"Pålogging",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Pålogging",
"b246fe8836c906f1b56342378ca88895":"Utfør, godkjenn og overvåk kjøp i team som speiler din organisasjons struktur.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Administrer utligninger",
"95d672ab621e531ae1a69798e0e4b56c":"Administrer alle dine virtuelle kort på et sted.",
"96f2e88a167f476413b35aa711709e6e":"Administrer oppgjør",
"151d8e6ceec242d482fff60da8311449":"Administrator",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Administrator",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Administrator mottar hver avtale for gjennomgang og godkjenning. Bruksgrenser kan også justeres.",
"4f449985e83c1f9ca2e8f82b0707a371":"Administrator mottar hver avtale for gjennomgang og godkjenning. Bruksgrenser kan også justeres.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Administratorer",
"27f8111a955975ea6fd04f748d24cb4a":"Maksimum godkjenningsgrense",
"5b1d9cfb78c55e75b47c62435d9299ca":"Medlem",
"41e307c1874e2e82296b226e9bae4a5f":"Medlem",
"6f9612aa1582373c2c1ba4e144132100":"Medlemmer",
"590429a0fcc42af85648d0fd70601dcc":"Medlemmer",
"3f50e62c8488d83ad84f69ebda853897":"Medlemmer",
"e0fc0acda5b74b93e797756dbbba67bc":"Medlemmer",
"c7ef9eda38b7eaee1f20031387a8bde7":"Måned",
"40dd08b507752bef4e3c4b36838317d5":"Månedlig",
"d712b91f7498f212047aad4c6d0f8088":"Seneste",
"12110d95b3d7a495afa4f28ac190f63e":"Seneste",
"2f11ce3865f79ee7e5ee64d728082264":"Seneste",
"eadc93865655cffc6a9863fdda5693e1":"Må være minst 10 tegn og inneholde store og små bokstaver",
"e2d4cd7cd181c33ab180ba577e1ea325":"Må være minst 10 tegn og inneholde store og små bokstaver",
"50d0a69aa59df5201018771a06ea12e7":"Må være unik",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Må være unik.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Mine kort",
"c36602f50d35c2816f0ba8a196b70876":"Mine forespørsler",
"ff6e372aa3eb685a39a701963db35b2f":"Mine forespørsler",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Navn",
"1c7514b561a2591a914225813d30d642":"Navn",
"d621826c1ceade262589af04e655e824":"Nytt passord forespurt",
"35dc3304b61a9e69dc3a393123658837":"Nye forespørsler vil automatisk videresendes i denne tidsperioden.",
"e788124801b4727d121f60d059a2a4a7":"Neste",
"eca804f1d53dfd984ffd93e2d00fc284":"Ingen tildelt godkjenner, kontakt programadministratoren din.",
"abdf7e93f8509399a63108a2b1a25546":"Ingen aktivitet å vise",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Ingen kort i din lommebok",
"87127e1e9f5bf922d0a4f948b68697c8":"Ingen grense",
"40512efa6b75ef0c7924b1ee3070fca9":"Ingen medlemmer å vise",
"dcda493afcb2415d5ca49a17d1af6f50":"Ingen utligninger har blitt publisert enda.",
"f0efb26e623bb990532e375d5c8875ec":"Ikke tilgjengelig",
"bc0cf602d1978e814070bb552e576a32":"Ikke tilordnet",
"09e126c317429ccb52ad323c32293648":"Antall betalinger",
"75b00b969b9d3b520929f1e652637b70":"Antall betalinger",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Antall betalinger",
"bd44ba180aa356c5755fdedd32adf03c":"OBS! Det oppstod et problem under opprettelsen av en forespørsel. Prøv på nytt.",
"c396b3728dd7393aa9e5427990c132a7":"OBS! Det oppstod et problem med nedlasting av rapporten.",
"42c07764b07dea1ee7de7605721c3f5f":"OBS! Vi kunne ikke opprette et nytt team.",
"e3a385878ec94bac12a99490d0a8e85a":"OBS! Vi kunne ikke opprette kontoen din.",
"17f2dae79677017e2c2e879578c4e09a":"Oj, da! Noe gikk galt. Prøv på nytt.",
"78c135de545c1663c9768226492a44a3":"Eller bruk enkel pålogging (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Ikke på kontoret",
"5d7e45f169e2f6968dc58f43b5267353":"Ikke på kontoret",
"ee589a20c262527db970671fb2c4f6bc":"Ikke på kontoret",
"4fd0c753fce49a016b98d988a3895223":"Oversikt",
"2789c5d5c9c042858f45aadc4e0de058":"Oversikt",
"083b7090b66ebd0307a18499ac7b676e":"Siden eksisterer ikke",
"a21d205920893cf579a7655a1430fe30":"Overordnet kort",
"fd35b7ce938e404fdb65de3bb749c528":"Overordnet kort for rapport",
"e8c7d2eea7db66ecb44751091a582304":"Passord",
"bb79e316e00fd21138750b6986e44455":"Passord",
"6cae2279088580976d84119d95097cae":"Passord stemmer ikke overens",
"4baec0159d3074f84c0eebcc09ba4cd8":"Passord stemmer ikke overens",
"b676587115243921602742cb8f696a6a":"Opprett pause for kort",
"db4a26c871ef0b970b74e1f71763098b":"Sett nye virtuelle kort på pause fra å være tilgjengelig",
"33fc1e6d01b785e3a815c28ef7d56667":"Venter",
"6ea62e4811b68df48b467e8d367aac2f":"Ventende forespørsler ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Medarbeidere",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Personer i team kan ha 3 forskjellige roller",
"e2d34cb1409b9778d34c4b74ecee1df6":"Skriv inn et positivt heltall eller la det stå tomt.",
"8da247d5d736c87122404eff1e5c0c73":"Legg inn en gyldig grense.",
"65e154267ac283d963cbc7be832969d9":"Legg inn en gyldig verdi",
"28b4450d417065a69a395f567967d46c":"Forhåndsinnstill med forespurt bruksgrenser",
"7bbf151d8493170f206a23c2ecc6acd0":"Personvernerklæring",
"51602962f2d1e7515943b4a6c66562ce":"Profilbilde",
"a51a17940c70a3123da4d68bed562c40":"Profil ble lagret!",
"4d398249d01ab72d697a7e219493c637":"Leverandør",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Leverandørtilordning",
"89b1deb2ffe12a13941c362abaeab01b":"Publisert",
"d8d806d48052159e3ff3b224f3f53586":"Formål",
"089917619c78af2efb30be0bb7ce076c":"Aktiver team på nytt",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Årsak",
"e8484c78fda9f453eeb942ba4945f040":"Tildel på nytt",
"ff2fbc1172ba26776ad0982624cd2713":"Kvittering",
"588e690db177ee0c7a7cadb39cbd5efc":"Oppdater finansieringer hver uke, måned, år",
"32955937daca05b2084556f248692baa":"Registrer",
"f15bb755ba24abe6ab106637292175ec":"Gjenværende",
"579782461133a8b8cc6bd77798961d82":"Fjern kort",
"e475edc165372f8c8deb532aa85d9950":"Fjern liste",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Fjern liste",
"f9769561315b964d0b3b4887ec208977":"Fjern kort fra team",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Fjern kvittering",
"ee181cef581fc8e8217e66b5762db898":"Fjerning av en liste fjerner den fra å benyttes og sletter alle eksisterende oppføringer. Dersom du ønsker å midlertidig fjerne en liste og beholde oppføringen, kan du slå av publiseringsinnstillingen. Er du sikker på at du vil fjerne denne listen?",
"feea41d0bc1d577a07c8b6e1297abbde":"Fjerning av et kildekort fra et team vil ikke deaktivere eksisterende virtuelle kort.",
"d09483824a2e0d6194a4c3f32311e752":"Fjern et kildekort fra et team vil ikke deaktivere de eksisterende virtuelle kortene. Er du sikker på at du ønsker å fjerne dette kortet fra teamet?",
"7ca4b1bf1346e179a29da97f260a93a4":"Gi nytt navn",
"db195ec9c818b8e92da142374e20d1bc":"Gi kodingslisten nytt navn",
"2fd93e229fe9ea754808805d3fef9c13":"Gi kodingslisten nytt navn",
"aa1e74d32805823f802a11b33c60c38b":"Rapporttype",
"6ac78d97a8cb7f060e43e596e3e23535":"Forespørsel kansellert.",
"25deccb1980291a0fa9997109c731440":"Etterspør demo",
"9f2754d87686903b1500ce8e8a55b377":"Etterspør demo",
"92ac924f72679f897e26f3e615be8c22":"Forespørsler videresendt.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Etterspør informasjon",
"270185007853cd779011ae0e8433f723":"Forespørsel tildelt på nytt.",
"e5a059c9ba2691910321339cce989f9d":"Forespørsel sendt.",
"77c88a231edfa7075114d91d2397e42d":"Etterspør tittel",
"cee91b833c4358acf0c554f746557e71":"Forespørsel ble avvist",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Forespørsel ble avvist",
"9e56abbbf87420eeedd625ce786515c7":"Forespørrer",
"b4cb4426e44fee9580af71912a764536":"Forespørsler",
"df309e888c29fc447d0309329b39ce93":"Tilbakestill passord",
"5daf278da19ecbaeb7b96554cb4db10f":"Prøv forbindelsen på nytt",
"31e9501526d2114e4a01fdbf076de097":"Rolle",
"6fbceb787429f6f81117a1d5a58b4610":"Rolle",
"473b814ca86841ef672ccf9f7dac28d5":"Rolle",
"f1e16d5fd643e039d865b6aec7c986f9":"Rolle",
"ec78345a5975bc0f8efaee5256b43c2c":"Lagre",
"2cecea57b47d8f4a45c706287ff7d64e":"Lagre endringer",
"15774be5c338a39169917c7d32781dd0":"Lagre og fortsett",
"844095b4b8f580737b8717672501048e":"Si noe ...",
"7e66f676c2e7b04514aca3a0935530f0":"Søk etter kodinginformasjon",
"5293ce9335ec2d176a6e838bf76404c6":"Søk etter beskrivelse",
"efab3b0c95f263cdd0a8466329a387eb":"Søk etter beskrivelse",
"d227b38dcbc4548c092b0836a7101506":"Søk på selgernavn eller transaksjons-ID",
"3629383abf1d5f85efd759f718159b40":"Søk etter navn",
"a034fcd6719228b28a6fc22d3a46ea8a":"Søk etter teamnavn",
"8ac7e0d63f343b548482d5f367f5b3d9":"Søk etter teamnavn",
"858323eb52cb45446597ee46330a0540":"Søk etter eier av virtuelt kort",
"111bfcbff671e13de117ed5195fb6773":"Velg",
"bcfecd59eb93e5314873d84ddf35aa58":"Velg",
"909a4414ea573218bff2d533eaab46ea":"Velg sluttdato",
"114b206ca1ff1c0bc52abef342059b2a":"Velg team-alternativer",
"81a02e424615fb41a20b8d0f13441c88":"Velg team",
"b135b36495618e6692e7fbaf0a0aa83e":"Velg en måned",
"677e90855e164c00a28b5c4763ac67e0":"Velg en rolle",
"d01570f3774738395b8ba3c64ce54c76":"Velg en status",
"7dee3f06c70626881efb0cd6af6b63e1":"Velg en bruker å videresende forespørsel til",
"4c7bb810835a1540ddc1f6501da5a51b":"Velg et år",
"3ab198d2678af2b5b32180e4b39602ba":"Velg godkjenner",
"f502ba79f2bd010c5e77891812400958":"Velg kort",
"936027182d446e27ba209ea921ef7502":"Velg standard utløpsdatoområde.",
"b22c893254ccf3bd34faaee511cf7ca8":"Velg varighet",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Velg fra kodingslistens forhåndsinnstillinger eller opprett en egen ny liste.",
"42cce5fa376b45bf290f86c684d69d96":"Velg leverandørfelt",
"b761773d46699b60e8d6874b85593374":"Velg team",
"3a9396756942ede98d0cb6afc1032b15":"Velg godkjenneren deres",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Deg selv",
"03e9d2c128af288126d94f631c5396be":"Send instruksjoner",
"299a7e961804e2d649f06b5dc4362c29":"Send invitasjon",
"155e7b3f7db7bf10b03b5f41de54eae1":"Send melding",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Angi en utløpsdato",
"f89ec96a4b5bb639488ab742efa76a6a":"Angi nytt passord",
"7d5377ad70d57728a45d3c0639de5544":"Innstillinger lagret.",
"14313f11dee735f2144953e720776c28":"Utligningsbeløp kan være forskjellig dersom tips eller gebyrer er lagt til på kjøpet",
"199000fe0cda0adaaa9b864aa7447cb9":"Utligninger",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Utligninger",
"6e6ac6ff3bd722e37874fb1ea7115818":"Konfigurer passordet ditt",
"9b4ecf052980e80bf5c65a415d470a43":"Del og kontroller tilgang til selskapskortet ditt",
"ba746d78772d6be26cc508ba989becb0":"Bør være mindre enn, eller lik {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Vis transaksjoner uten kvitteringer",
"7d474b60aeb7cc25b519e806fca71e12":"Logg inn med ditt eksisterende \n\n Innloggingsinformasjon for Tradeshift",
"16080cd4e8319ef3e7de21b6c19d1437":"Det er noe som ikke stemmer",
"8f358a370224baf849f9652810c68db2":"Noe gikk galt",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Noe gikk galt",
"99af87c475a4d40ef732deed96f11ed2":"Noe gikk galt. Prøv på nytt.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Beklager! Vi har problemer med å koble til for øyeblikket – prøver på nytt om {retryConnectionPeriod} sekunder.",
"84debc8e41646cd49ac2b14708ca6d73":"Beklager, det oppstod et problem med sletting av kodeoppføringen. Prøv på nytt.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Beklager, det oppstod et problem med lagring av kodeoppføringen. Prøv på nytt.",
"689d3418ee8f996ad25e152e80de63fe":"Beklager vi kunne ikke logge deg ut på nåværende tidspunkt.",
"b48efd4521ecd6428987d24636a4349f":"Sorter",
"776a95003d3ec1dd58af69aedbc08579":"Sorter",
"1b783238028c0ae085ca69ed823f4d1a":"Sorter etter",
"be70d19bf13c935d653b2c4fe7d36224":"Navn på kildekort",
"564f8c942e7d1bdda5251545612fa348":"Navn på kildekort",
"aa284602c746aad4a6de6366c24221b8":"Kildekort",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Kildekort",
"389d471e79a43440b3278b0fc7187d9e":"Kildekort",
"106342f689b57145817b00d6fe383f91":"Spesifikk sluttdato",
"2a5e19387ff300509876e3bae99b7ab9":"Raskere regnskapsføring",
"ca34d586bbdb7f368f1009305bccbe76":"Dashbord for forbruk",
"4583120998b44c0a5982cf85dd938d2f":"Bruk over tid",
"244d724806247953233a0bbfcbf8da4c":"Bruksgrense",
"d39b83dd151c8df6370460a458294ccf":"Brukt",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Brukt beløp",
"5de2859528e030ac1f2af115ebdf209c":"Brukte { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Begynn videresending den",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Tilstand",
"1f1fe111548158ec842761eca7425ff3":"Gateadresse",
"31a0a748b1097a9e0aa95a811802bdd0":"Stressfritt oppgjør",
"111b782f3f0794c2294aefb6693b2c50":"Send inn",
"013e2555920b51ad2a3d2c3dc482c471":"Send forespørsel",
"1270cd8eb69ad64cc71e5de979423b3b":"Abonnement avsluttes",
"2802336ad3d8ff658257b88a93f4e50a":"Abonnement avsluttes",
"b28dd68454d7acbefafeefb42981fe16":"Abonnementsfrekvens",
"907793bc80cf94d560e5f1a0250fdaee":"Abonnementsfrekvens",
"29229e153308e4747edef1144de35a55":"Suksess!",
"503eb46b80f374f39266e7a9aea8e63e":"Suksess!",
"4f20e29cadadb9fbc7455bd4d80de567":"Suksess!",
"601d04f10d558f436c78c10e90b44175":"Støtte",
"62558c185ea2c06c5472d1cd534f6e56":"Synkroniser med din regnskapsløsning",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Skattebeløp",
"bf23b2fd889e3b12e0f0acf99f913eca":"Teamkort",
"b90a650376782a9e43ed6be6135bd6f2":"Team-dashbord",
"6adbec395fe1c4bf776f9fe5fa021052":"Team-dashbord",
"6f2695eeb68501dbcfddbb4d265b846b":"Team-administratorer",
"3b779ade9c960ac95a670db0cc12e56b":"Team-medlemmer",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Team-innstillinger",
"dc5235e1b88320d3ba7501e4c30985a3":"Team-innstillinger",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Team-innstillinger",
"a2eb92bfd7502f47b3cd170bc68ac638":"Team-innstillinger",
"f1ec84f051f19f0d0e301a709b13dc45":"Teamforbruk",
"fbacde3a262ea8696e30135e8f987a44":"Team-veksler",
"74b9578ea5a620f5fef102188e42c02f":"Team-veksler",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Team-handlinger",
"3aa978fa7ef886621285b93257d428c3":"Godkjenningsgrense for team",
"a41f9df131c221a99926de7e2138653a":"Team-informasjon",
"941c7da8f256f91ba3819f991315a100":"Team-navn",
"c5d04e10cc2f0b53c2bda893eae09178":"Team-navn",
"504a8ba642ba37c9a743d0fabb2bd371":"Team-navn",
"e768a8e1e3d6a6bf470587dad75423fe":"Teameier",
"e2d824067f8bd871756688c58c382196":"Team-eiere",
"dca232ccc849c5ff5611255eb82baf53":"Team-eiere",
"3b61bd586225790cf132591383d15448":"Team-veksler",
"bc05b40509f0271c12cfaaef8e5319b1":"Team",
"39e73773d07a800673ec6600422952d6":"Team",
"daabb564bb3dcfb41de69492f854d573":"Team",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Team- og individuelle grenser vil aldri overgå firmagrense",
"4b4fe189a48d8eb06d836afa8fb6317a":"Team oppdatert.",
"2a17f9a8c610db29fec63fd530c29fdc":"Team som bruker kortet",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Team som kan se dette",
"317868f3804ba4ed5d99dd9919c798ba":"Tjenestevilkår",
"e8c9a9847db2ccd97524aa06f1af9d82":"Tjenestevilkår og personvernerklæring",
"38852078519c5c46715d8a034fc4901b":"Tjenestevilkår og personvernerklæring.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Takk for din interesse i Tradeshift Go. Støtte for {cardLabel} kredittkort kommer snart. Et medlem av vårt kundesuksessteam vil gi deg beskjed så snart det er tilgjengelig.",
"f7047b35a069d0790a0a348a29c846a2":"Takk for at du blir med i teamet på {companyName} for å utføre kjøp ved bruk av Go virtuelle kort.",
"133b0a70b1a956889164621380c378f9":"Handlingen du prøver å utføre kan ikke fullføres. I noen tilfeller kan det være nok å prøve på nytt.",
"18f5a1872e156f1aee84ae321780d952":"Det forespurte beløpet var over firmaets forespørselgrense på ${requestLimit}. Prøv igjen med et lavere beløp.",
"867e3d4d3c78990c6852fc5348706ab2":"Det forespurte beløpet var over firmaets forespørselgrense på ${providerLimit}. Prøv igjen med et lavere beløp.",
"570f5f4658b03dda7a910f56c86a3bd3":"Dette kortet for denne forespørselen er kansellert.",
"3075c56105863546e3368ffead7a146b":"Kortet ble kansellert.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Kodelisten du prøver å avpublisere er knyttet til en integrasjon. Hvis du er sikker på at du vil fjerne denne fra integrasjonen, vennligst kontakt kundestøtte som kan fullføre denne handlingen.",
"756ade8d71ec487a4b48d7c4277ea239":"Kodelisten du prøver å avpublisere er knyttet til en integrasjon. Du må først fjerne kartleggingen før du avpubliserer denne kodelisten.",
"4f40335f7c018986c95458183bc0c136":"Linken for å aktivere kontoen din er ugyldig eller har utløpt. Sjekk med personen som inviterte deg for å få en ny invitasjon.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Linken for å tilbakestille passordet ditt er ugyldig eller har utløpt. Vennligst etterspør tilbakestilling på nytt igjen for å få en ny link.",
"ded30f0e288c12125ca1a77f8d8fd600":"Årsaken vi være synlig dor godkjenneren og forespørreren",
"f294897b38fc23dd8f5de52b54ceae22":"Forespørselen vil bli avslått og forespørselen vil bli varslet. Ønsker du å fortette?",
"e8c7200dc985df0a94a9d996968b4cc0":"Forespørselen vil bli avslått og forespørselen vil bli varslet. Ønsker du å fortette?",
"5f3d196a2ade3f97a2a48b773fec5861":"Forespørselen vil bli avslått og forespørselen vil bli varslet. Ønsker du å fortette?",
"b04c191cd6b4f27669a4f390311f156c":"Det er ingen team-eiere eller administratorer å velge mellom.",
"e25045557efbad7b52997b94a1fe6817":"Det er ingen {type} for denne tidsperioden",
"7260690577c795e999acae63bb7c51ae":"Det har oppstått et problem med lagring av teaminnstillinger. Prøv på nytt.",
"f2103024f2a9c6c0335a71c60567d6c9":"Det oppstod en feil under lasting av pengeboken din.",
"9776cb7eab9fa0d318a8144cd17de745":"Det oppstod en feil under lasting av pengeboken din.",
"77d28a14bc900be4360b570a82eb75a9":"Det er ingen data å vise",
"2b6feba67722337f88c1443909fe446f":"Det oppstod et problem med å legge til godkjenneren!",
"24b7212afb97c5d23faafa2ca4559095":"Det oppstod et problem med å legge til kredittkortet. Prøv på nytt.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Det oppstod et problem med å legge til kildekortet til teamet. Prøv på nytt",
"5a8e1b922d1cee746ac6794599b10709":"Det oppstod et problem med å kansellere dette kortet. Prøv på nytt.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Det oppstod et problem med å kansellere dette kortet. Prøv på nytt.",
"22361c5d4df61ad7ae9792a3baf5729f":"Det oppstod et problem under opprettelsen av listen din. Prøv på nytt",
"098755bfddeaf0428c7feb6b69af9b6f":"Det oppstod et problem med å avslå forespørselen. Prøv på nytt.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Det oppstod et problem med å avslå forespørselen. Prøv på nytt.",
"7d461b6b7b54dbfa9c83243217cd1053":"Det oppstod et problem med sletting av kodeoppføringen. Prøv på nytt.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Det oppstod et problem med sletting av listen din. Prøv på nytt.",
"beb43d7800cfff47e08cd61842d953bd":"Det oppstod et problem med å videresende forespørselen. Prøv på nytt.",
"f7775350f35b8365fb9803232d9b792c":"Det oppstod et problem med å hente kortdetaljene. Prøv på nytt.",
"95abaa922c6c1648ca9c39ac4776896f":"Det oppstod et problem med å hente brukerdetaljene. Prøv på nytt.",
"9d7e4ead60137041ae8a845aa6cf9979":"Det oppstod et problem med å laste brukerprofilen. Prøv på nytt.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Det oppstod et problem med å laste dette teamets brukere. Prøv på nytt.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Det oppstod et problem med lasting av innstillingene dine. Prøv på nytt.",
"b2f48028c400befad5f1d57f769ef7cd":"Det oppstod et problem med å tilordne forespørselen på nytt. Prøv på nytt.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Det oppstod et problem med å fjerne kildekortet fra teamet. Prøv på nytt.",
"19dfcd761006496c1d20351c04643e45":"Det oppsto et problem med å fjerne dette domenet. Prøv på nytt.",
"149651b5784481c68aaf4ac27bb1f907":"Det oppstod et problem med lagring av kodelisten. Prøv på nytt.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Det oppstod et problem med lagring av profilen. Prøv på nytt.",
"749ddc235744b6b371668ec0bb049176":"Det oppstod et problem med lagring av brukeren. Prøv på nytt.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Det oppstod et problem med å lagre dette domenet. Prøv på nytt.",
"04cf86909808f430b8b1904fb422e0d3":"Det oppstod et problem med lagring av innstillingene dine for “ute av kontoret”. Prøv på nytt.",
"2eaf6ee97d984598c95e0494d72aa492":"Det oppstod et problem med å sende kjøpsforespørselen.",
"148f847d3beccaa67fcd5096ae3602d3":"Det oppstod et problem med å oppdatere kortnavnet. Prøv på nytt.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Det oppstod et problem med å oppdatere kortnavnet. Prøv på nytt.",
"de8cc976f7af9edf189904239e6240da":"Det oppstod et problem med å oppdatere teamene. Prøv på nytt.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Det oppstod et problem med å legge til administrator. Prøv på nytt.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Det oppstod en feil under deaktivering av teamet ditt. Prøv på nytt.",
"3f686a97a1b9fcdd73a40d2005693105":"Det oppstod en feil. Vennligst prøv på nytt.",
"2f38263789163cf8ee30c8ae88852c82":"Det oppstod en feil ved publisering av listen.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Det oppstod et problem med å fjerne administrator. Prøv på nytt.",
"007a647254e1f85b6c1e789004e05df5":"Det oppstod en feil ved lagringen av innstillingene.",
"c3072d080b587d70f85d68ec089f028f":"Det oppstod en feil ved lagringen av innstillingene for teamet ditt. Prøv på nytt.",
"608cd0e109c3e51b0425df8921e4181d":"Det oppstod en feil ved innstillingene for leverandørtilordning. Prøv på nytt.",
"177cefc68dd318ea75673326e77d8206":"Det oppstod en feil ved oppheving av publisering av listen.",
"255d22dc4f0e338a4cf53d5b7256e695":"Det oppstod et problem med henting av navnet på den personen som du sender godkjenningsforespørsler til.",
"39ac4ec73fed8a981ad1857d989edfcb":"Beløpet overstiger godkjenningsgrensen for bedriften.",
"494ce3f598cbd97afe99774b5975a4bc":"Beløpet overstiger godkjenningsgrensen for teamet.",
"f2a42214e7c480c5596439becfd0b7bf":"Dette feltet er obligatorisk.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Denne innstillingen vil kun påvirke de nye kortene",
"881b7596cf985d9c246b05fcab930632":"Dette kildekortet er lagt til i teamet.",
"a1b1aa354d06e7815b78c6abb7448e84":"Dette kildekortet ble fjernet fra teamet.",
"b348b24fc4839ee15ed0aa424689bae3":"Dette teamet må ha en administrator eller leder før du kan invitere et medlem.",
"188b9ed447a43916f9e323623ea3a993":"For å akseptere invitasjonen",
"969de95e19a06f5ae7cec6449cbb6a27":"Totalt",
"30286e57da108ee7dbc0f8fbb62d4fff":"Totalt brukt i ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Spor forbruk på tvers av team, prosjekter og abonnementer.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Spor aktiviteten til ditt teams aktive kort, forbruk i pauser, og deaktivere kort som teamet ikke behøver lengre.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Tilbakestilling av Tradeshift Go-passordet",
"5d760188301146cc7255ce18b65cb370":"Prøv på nytt",
"80196bc616d4a44a4997a2ea127f7b83":"Prøv på nytt",
"f96bbe81618b5f4a1807989eb4f69e24":"Prøv på nytt",
"d498d7d261b7ca56e07218d1a0dbdd49":"Å, nei! Det oppstod en feil. Prøv på nytt.",
"ce2e475291003852c71b01959698c496":"Kan ikke motta meldinger på forespørsel  {title}, prov igjen",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Opphev arkivering",
"d2e50be5287fd8fb436d0acbeb811864":"Unik identifikator",
"0e2fbce967f4a209215b6f48f8eddac3":"Fjern pause for kort",
"f2315dec5dd07802b77af3a3a5414a7d":"Opptil 4 år frem i tid.",
"d2f30a2f0c955271e9af123dceca501a":"Opptil {label} frem i tid.",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Oppdater kildekort",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Oppdater kildekort",
"c8f6bc61a8982158e408194e48a274d9":"Opplasting mislyktes. Prøv igjen.",
"8a86a60cb836bdb5225cad20c5151078":"Last opp kvittering",
"75edcc8fc27f41c99e28b48ff250c7f8":"Brukerdetaljer",
"d1651c2afdb6c92621adbb707e4753d0":"Bruker lagret.",
"5fa55883b7413526b441e999cb8089b3":"Brukernavn",
"7a892b81fe2aae295f1b2d38bb67360f":"Brukernavn",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Brukere med aktive kort kan ikke fjernes. Vennligst kanseller kort for å fjerne denne brukeren.",
"d40fa5935f1614925cf46259c886814a":"Vis kortaktivitet",
"625419857efc7e72d162c9f8204109df":"Vis kort i pengebok",
"85a5e817826415651ed4991a76bb5385":"Vis samtale",
"09fc124dc9b8b14d6227a92bb0a4566f":"Vis samtale",
"d26cfc6d8f420f259f434b79593e9eb4":"Vis varekvittering",
"c7d0394c3206a3b74afbeabc41cc639c":"Vis varekvittering",
"01650aa52c5e5a22d3f38afd2c7f9411":"Vis varekvittering",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Vis varekvittering",
"b2d141897f73fc2a198ee1a7a438f89b":"Vis forespørsel",
"02fa2f56ef72845a9dfeabea22948061":"Se team-informasjon",
"2d9c55650b17dd605a1388f46c81ba38":"Virtuelt kort",
"d0ec076e4600e854588f8da265208c0b":"Alternativer for virtuelt kort",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Pengebok",
"5279a2220e4cfb0d8b8b357311251cbe":"Pengebok",
"b1746b5806659e3189f837d2dd54e08c":"Pengebok",
"ff306d5b34ee993f9618c1f3c0209a39":"Vi kan ikke arkivere forespørselen på dette tidspunktet. Prøv på nytt senere.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Vi kan ikke kansellere forespørselen på dette tidspunktet. Prøv på nytt senere.",
"a813d44f6bb26c5da09671e532abe022":"Vi kan ikke oppheve arkivering av forespørselen på dette tidspunktet. Prøv på nytt senere.",
"d4c7310eafc0d0da89f20596a22a3abe":"Vi har ikke klart å opprette en forespørsel på dette tidspunktet. Prøv på nytt.",
"dca679c10accb1db481a57a0e1ba08e2":"Vi har ikke klart å opprette en forespørsel på dette tidspunktet. Prøv på nytt.",
"db5584a2ef29b04959bce7f93db54c56":"Vi kan ikke oppheve bytte team på dette tidspunktet. Prøv på nytt senere.",
"f2af2c261fabddb01766f4f974b85a62":"Ukentlig",
"e181ed0723a00ca7558b9d5d5258732b":"Velkommen til Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Vi tar kontakt!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Hvilket kredittkort bruker firmaet ditt?",
"968d94729053e166a76ea577ce3022e2":"E-post arbeid",
"588a075e8694ff5cc86815697e1586bc":"Jobber på samme måte som bedriftsteamene dine jobber.",
"182ae1eb5226e0646b4033883cc0900c":"År",
"fe88fe7094c4ced427874b3d22326c8d":"Ja",
"9a2d4e3b22ad0567165eb0f661f952ad":"Ja, kanseller kort",
"96b88e530375237cbc08138d9fb4109c":"Ja, fjern fra denne listen",
"fd3780f468c531f2bc1b47b8748b5a66":"Du kan opprette regnskapskoder i firmainnstillingene.",
"ab3ac6f945d9f756763d49257fd924dc":"Du kan administrere kodinger i firmainnstillinger.",
"c919dae93fe780061629c255eb358140":"Du kan ikke fjerne det eneste kildekortet som er koblet til dette teamet. Legg til et annet kort for å kunne fjerne denne.",
"11de97e873b66d91a77db7a05da32202":"Du kan forfremme en av team-lederne i listen under til å være firmaadministrator eller invitere en ny bruker.",
"0ab790f08b2780be001b79d2ccd75c4e":"Du har ingen regnskapskoder opprettet for denne kontoen.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Du har ikke noen kodingslister opprettet.",
"44e470218124a50c117870467530752e":"Du skrev feil e-postadresse eller passord.",
"4b687d0730822fdbeea27c174e47f227":"Du har ingen godkjente godkjennelser.",
"020f03c17b79af66f335b6af868c66c0":"Du har ingen kansellerte godkjennelser.",
"def0b76f03e7c622341a7d8e08aaaae7":"Du har ingen avslåtte godkjennelser.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Du har ingen ventende godkjennelser.",
"7c034d6104f80a523b90bc607fc06608":"Du har ingen virtuelle kort i pengeboken din. Opprett forespørsel",
"0aed9198d61d161468d541dbbc9f477a":"Du har planlagt en videresending av “ute av kontoret” til {oooForwardToFullName} frem til {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Du mottok et nytt virtuelt kort:",
"04eeaf814767c30aa41577f3b11f916a":"Du etterspurte nylig tilbakestilling av passordet på Go-kontoen din. For å fullføre prosessen:",
"09ddf249810b9af7d3e1f4e537da500d":"Du prøvde å få tilgang til en side som ikke eksisterer. Husk å kontrollere at du har den korrekte koblingen.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Du er invitert til å delta i teamet ditt på",
"02c6f3a7cf31a87f4f39f992509f8c44":"Du er lagt til som administrator for firmaet ditt",
"cf121a14590f109dafcae138265e353b":"Du har blitt lagt til i et nytt team!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Du har blitt lagt til i teamet",
"d3cef66a026f776903b342d450e5a832":"Forespørselen din om Go-kort har blitt avvist",
"003f87c21a29220e661fc302b885fd5a":"Kontoen din er deaktivert, kontakt selskapet ditt eller teamadministrator",
"a52b1f0da15dce68007f524e48238143":"Banken sendte feilbeskjed da du la til dette kortet. Kontakt banken din for å løse problemet og prøv på nytt.",
"4d883b173167297463aeac86957c26fe":"E-postadressen din er benyttet for å opprette en konto på Tradeshift Go. For å verifisere e-postadressen din og fullføre registreringen",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Innstillingen din for “ute av kontoret” er lagret.",
"542f08554110a12de0c23afd78f38e0e":"Passordet er tilbakestilt. Logg inn.",
"49b2dcbe5d461f35c37986962b5bd07a":"Forespørselen din ble avvist",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Du er på listen!",
"32f87c5ddeaee35b9178785ef4eb9313":"Postkode",
"c6974a3d5311374c697625f2a3234bce":"en dato",
"f86980f5ef00b645a6e4a0b59738a954":"En person vi mislyktes i å finne",
"7066f854917b39bafe0bbecbb8bd6377":"godkjenner forespørsler",
"4a8231a77c028b619d244b3e04f9916e":"for firmaet ditt",
"a6e7b18eae6962edf08dc9c3ced705f4":"fra",
"c1407aa5424f339dedf67bcac597cf71":"har et oppsett hvor godkjenningsforespørsler blir videresendt til deg i perioden",
"67a948b8af3284a401ebefcb1b4723ab":"noen venter på din godkjennelse av",
"9ca64449d64b98fef7859e22bc23c4dc":"oppretter forespørsler",
"ac850ab84428e9eb6a57e5d813de5e45":"administrerer team",
"2168839a1f4c73ef5c89c9e930148e7d":"vennligst se bort fra denne e-posten. Ingen handling kreves",
"85431c325faab98e02daa60151465144":"Sendte deg denne invitasjonen",
"81c504fd54119e7e580eb05dedef46d0":"Sendte deg denne invitasjonen",
"d74545060b38445ef4cc49fbb8d79876":"Sendte deg denne invitasjonen.",
"2d96c53ee7ee0a54aadad19bfce0697d":"det oppstod en feil under henting av kortkildene",
"b2fda295788d579fffd3f43b7737fd63":"for å registrere selskapskortet med Go, for å gi dine ansatte enkel og sikker korttilgang",
"d35bd5c5d74af765d45cef3c4e78f52a":"for å utføre bedriftskjøp ved bruk av Go virtuelle kort",
"8469a13ddf1cc19a4ebf26356d8ac646":"for å utføre bedriftskjøp ved bruk av Go virtuelle kort",
"9546989e681a7e25fbdfe7631e9ebc03":"for å utføre bedriftskjøp ved bruk av Go virtuelle kort.",
"6174e0a3d26409dd6e410052e2805660":"for å begynne å bruke Tradeshift Go-kort",
"0bee956ca9aa927e0063c36a300e29e1":"slå av “ikke på kontoret”",
"fd2c1099fc9f3831d7f24195aaa11269":"deg@firma.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} er ansvarlig for å godkjenne forespørsler fra andre teammedlemmer. Vennligst tildel en ny godkjenner for de påvirkede medlemmene for å fortsette.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} Ble vellykket invitert ${this.teamId  ? '.' : ' til teamet.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} ble fjernet",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} Kansellerte forespørselen for",
"9567a2d4cacd58fb4f096132d26c1660":"{username} er ansvarlig for å godkjenne forespørsler fra andre teammedlemmer. Vennligst tildel en ny godkjenner for de påvirkede medlemmene for å fortsette.",
};
