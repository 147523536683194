"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestInitialState = void 0;
exports.requestSlice = requestSlice;
exports.requestInitialState = {
    request: {
        cardTypes: [],
        lists: [],
        purchaseRequest: {
            amount: 0,
            attachments: [],
            businessId: '',
            cardType: '',
            coding: {},
            currency: '',
            fields: {},
            isArchived: false,
            lastEdit: '',
            profileId: '',
            requester: { email: '', firstName: '', lastName: '', properties: {}, teamApproverEmail: '' },
            authPaddingPercentage: 0,
            requestId: '',
            state: '',
            teamId: '',
            title: '',
        },
        requests: [],
        archivedRequests: [],
        requestFields: [],
        errors: {},
        allEvents: [],
    },
};
function requestSlice(set) {
    return {
        ...exports.requestInitialState,
        setCardTypes(cardTypes) {
            set((state) => {
                state.request.cardTypes = cardTypes || [];
                return state;
            }, false, '[setCardTypes]');
        },
        setActiveLists(lists) {
            set((state) => {
                state.request.lists = lists || [];
                return state;
            }, false, '[setActiveLists]');
        },
        setPurchaseRequest(purchaseRequest) {
            set((state) => {
                state.request.purchaseRequest = { ...state.request.purchaseRequest, ...purchaseRequest };
                return state;
            }, false, '[setPurchaseRequest]');
        },
        setRequests(requests) {
            set((state) => {
                state.request.requests = requests;
                return state;
            }, false, '[setRequests]');
        },
        setArchivedRequests(archivedRequests) {
            set((state) => {
                state.request.archivedRequests = archivedRequests;
                return state;
            }, false, '[setArchivedRequests]');
        },
        setRequestFields(requestFields) {
            set((state) => {
                state.request.requestFields = requestFields;
                return state;
            }, false, '[setRequestFields]');
        },
        setRequestErrors(errors) {
            set((state) => {
                state.request.errors = errors;
                return state;
            }, false, '[setRequestErrors]');
        },
        setAllEvents(allEvents) {
            set((state) => {
                state.request.allEvents = allEvents;
                return state;
            }, false, '[setAllEvents]');
        },
    };
}
