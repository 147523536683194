"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const zustandStore_1 = require("../../../../zustandStore");
const useCardFeatures = () => {
    const team = (0, zustandStore_1.useZustand)(state => state.team.team);
    const { setTeam, setIsSaveBtnDisabled } = (0, zustandStore_1.useZustand)();
    const [openMultiUseAside, setOpenMultiUseAside] = (0, react_1.useState)(false);
    const [openSingleUseAside, setOpenSingleUseAside] = (0, react_1.useState)(false);
    const [openSubscriptionAside, setOpenSubscriptionAside] = (0, react_1.useState)(false);
    const handleCardFeatureToggle = (property, setOpen) => {
        setIsSaveBtnDisabled(false);
        if (team?.properties?.[property] === 'true') {
            setOpen(true);
        }
        else {
            if (!team) {
                return;
            }
            setTeam({
                ...team,
                properties: {
                    ...team.properties,
                    [property]: 'true',
                },
            });
        }
    };
    return {
        openMultiUseAside,
        setOpenMultiUseAside,
        openSingleUseAside,
        setOpenSingleUseAside,
        openSubscriptionAside,
        setOpenSubscriptionAside,
        handleCardFeatureToggle,
    };
};
exports.default = useCardFeatures;
