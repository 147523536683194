const _ = require('lodash');
/* @ngInject */
function DefaultManagerController($scope, AsideService, CompanyService, constants) {
	const that = this;

	function init() {
		that.managers = $scope.aside.model.managers;
		that.defaultManager = that.managers.filter(that.findDefaultManager)[0];
		that.onSuccess = $scope.aside.onSuccess;
		that.manager = that.defaultManager;
	}

	that.findDefaultManager = manager => manager.userId === $scope.aside.model.defaultManager.userId;

	that.cancel = () => {
		AsideService.closeAll();
	};

	that.submit = manager => {
		return CompanyService.setProperty({
			propertyKey: constants.companyPropertyKeys.defaultManager,
			propertyValue: manager.email,
		})
			.then(() => {
				ts.ui.Notification.success('Successfully set ' + _.escape(manager.fullName) + ' as the default manager.');
				that.onSuccess(manager);
				AsideService.closeAll();
			})
			.catch(() => {
				ts.ui.Notification.warning('Could not set ' + _.escape(manager.fullName) + ' as the default manager.');
			});
	};

	$scope.$watch('aside.show', show => {
		if (show) {
			init();
		}
	});
}

module.exports = DefaultManagerController;
