"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsTeamsLoader = void 0;
const appConstants_1 = require("../../../constants/appConstants");
const companyQuery_1 = require("../../../queries/companyQuery");
const teamQuery_1 = require("../../../queries/teamQuery");
const userQuery_1 = require("../../../queries/userQuery");
const stopAngularjsLoader_1 = require("../stopAngularjsLoader");
const settingsTeamsLoader = async ({ queryClient }) => {
    const user = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const teams = await queryClient.ensureQueryData((0, teamQuery_1.teamsQuery)({ offset: 0, limit: 10 }));
    const companyProperties = await queryClient.ensureQueryData((0, companyQuery_1.companyPropertiesQuery)(user.companyAccountId, appConstants_1.companyPropertiesConstants));
    (0, stopAngularjsLoader_1.stopAngularJsLoader)();
    return {
        user,
        teams,
        companyProperties,
    };
};
exports.settingsTeamsLoader = settingsTeamsLoader;
