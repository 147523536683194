const _ = require('lodash');

/* @ngInject */
function ApprovalRegistryFactory(Approval) {
	const approvalRegistry = {
		_approvals: {},
	};

	approvalRegistry.getApproval = function (approvalId) {
		return approvalRegistry._approvals[approvalId] || {};
	};
	approvalRegistry.getRequest = function (requestId) {
		const foundApproval = _.find(approvalRegistry._approvals, function (approval) {
			return approval.getRequestId() === requestId;
		});
		return foundApproval ? foundApproval._request : {};
	};
	approvalRegistry.getApprovalsByRequestId = function (requestId) {
		const foundApproval = _.filter(approvalRegistry._approvals, function (approval) {
			return approval.getRequestId() === requestId;
		});
		return foundApproval;
	};
	approvalRegistry.hasApprovals = function () {
		return !_.isEmpty(approvalRegistry._approvals);
	};
	approvalRegistry.removeApproval = function (approvalId) {
		delete approvalRegistry._approvals[approvalId];
	};
	approvalRegistry.storeApproval = function (approval) {
		if (!_.has(approvalRegistry._approvals, approval.id)) {
			approvalRegistry._approvals[approval.id] = new Approval(approval);
		} else {
			approvalRegistry._approvals[approval.id].update(approval);
		}
		return approvalRegistry._approvals[approval.id];
	};
	return approvalRegistry;
}

module.exports = ApprovalRegistryFactory;
