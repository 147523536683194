module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# tapahtumasta",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minuutti",
"ce09c436b49af331a32f1152ede7dd18":"1. Pyydä rahoitusta",
"d2226752d3065fbad4744924b4a0ee73":"2. Käy läpi ja hyväksy",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Käy läpi ja hyväksy",
"82bab92f1c8030888550b51521266c53":"3. Maksa virtuaalikortilla",
"2ecd62e02e507f8e839a55eac0a04886":"Koodaussyöte tunnuksella  '{entryId}' on jo olemassa.",
"5dfd25849740fbce8147838b0d5094eb":"Ystävällinen lempinimi, esim. markkinointikortti",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Asiakastiimimme jäsen ottaa sinuun pian yhteyttä.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Henkilö, jota emme löytäneet",
"976b50e70e4433e2786233979d243445":"Tämän niminen tiimi on jo olemassa.",
"35b61d21ac11d5fc31ebe58541263445":"Hyväksy kutsusi liittyä",
"a372cb2d4204654f52cd9170fb10d374":"Kirjanpitokoodit",
"110fc66010b6b91cebee7a59a2067aab":"Kirjanpitokoodit",
"db862bb04dbdc6d04e47063f33be8865":"Kirjanpitokoodeja hallitaan koodausluetteloilla ja luetteloiden syötteillä.",
"d6e3848e824f077396be4351eb58edd2":"Toiminto vaaditaan – hyväksy/hylkää pyyntö {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Toiminnat",
"476e728ea824229f52438e543818bcdd":"Toiminnat",
"3117746cb7d4ab815ec98274ec4398d5":"Toiminnat",
"3ab09b66a7f16219d8135977a2a76d10":"Toiminnat",
"ffafa66eaab0409a2e90aaeb43796def":"Aktiivinen",
"0447e0aa5d00dbdab11b5546793c4479":"Aktiivinen",
"8ee51d21b05466f7d962d4ff5a0b384e":"Aktiiviset kortit",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Aktiiviset virtuaalikortit",
"89c6ec05861aa914304ba35bc6744124":"Aktiiviset kortit",
"5280659bcecaf8344c57790f9962a9a2":"Aktiviteetti",
"8a15368ae286fa64e1cb7624cd410fc6":"Lisää koodausluettelo",
"8ded7e09e86be231b1165308329ac76d":"Lisää luettelo",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Lisää pääkäyttäjä",
"695bb4bc29e2df769ddf7427ab71012a":"Lisää hyväksyjä",
"4a1d4516cab00b7c5ecdb150e346e5db":"Lisää hyväksyjä",
"56869851b27c2915f5a7fe6a33b91ac7":"Lisää koodausluettelo",
"8f8691057c3994aa61f9994202680c87":"Lisää koodausluettelo",
"69f7a406b79d19bf8082ed245ab29123":"Lisää koodausluettelo",
"48a92ca1cc63018707be99b13196369f":"Lisää yrityksen pääkäyttäjä",
"a679db9ff80d0e5769ae1ba311c7916f":"Lisää toimialue",
"8f5e8078be77146ba7f3be49ef948906":"Lisää syöte",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Lisää viesti",
"f8a4b14162e19995074e63135b65c84d":"Lisää uusi kortti",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Lisää ihmisiä",
"c670549d0b898bb6a571eb2cd7804a6e":"Lisää kuitti",
"eb937098e77d8a6b0565ed72357dcaae":"Lisää kuitti",
"1e154f31c31b63a459f3956e380b3ef9":"Lisää valitut",
"ba24db9956f156a18b32e653552ff682":"Lisää ihmisiä tähän tiimiin",
"52bd93fb5489b25167bb541f7c6504ae":"Lisää lähdekortti",
"21ab3f08eb507dbc76df166eafeb393a":"Lisätty {fullName} hyväksyjäksi!",
"f879c06507d3076bbf52ea4fd2df850e":"Lisäkorttitiedot",
"21e934ae30eece4a894337701f497638":"Kaikki",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Kaikki kortit",
"8fac71195e2b506e029752e4864b6a5d":"Kaikki jäsenet",
"f9708417df16227742e0545a8642eea7":"Kaikki käytettävissä olevat käyttäjät on jo lisätty tähän korttiin.",
"cb5abf070f8cba7982205038baab77bb":"Kaikki kortit",
"dae7d602eec2c84dea0d80ae25e56e6d":"Kaikki kortit",
"0b4bdfde6578668b6018d3615a1ad30f":"Kaikki kustannuspaikat",
"97b875b81f54b4432d998a3e47d949cc":"Kaikki tiimit",
"f0ae83969f7c20a1964caf5156dae966":"Kaikki tiimit",
"d87455601a00282078582b36a3825b7d":"Kaikki tiimit",
"24c7b7f55882ea94a525392ca8218fa0":"Kaikki tiimit",
"8f860e35b64a8f447bded1e5ea66a2a8":"Kaikki virtuaalikortit",
"e403956ecaa6fb33ec1fef5c49d71042":"Kaikki virtuaalikortit",
"4e6f4e8f25f61d40e1eba77de94f6458":"Kaikki taloustiedot salataan ja tallennetaan palvelimille turvallisessa paikassa.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Summa",
"a4d099d0dbe3889b0995546e6ed28932":"Summa",
"6c0ef32aae76bc31a61e2124f542bdf1":"Summa",
"adec89c93525f1a8d1c64d856f405e27":"Summa: korkeasta alhaiseksi",
"18e632a170203894f40deed1d33ee552":"Summa: alhaisesta korkeaksi",
"a7944575cafef9126a499a1b06999fe3":"Tapahtui virhe, yritä uudelleen myöhemmin.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Käytä",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Hyväksyntävalinnat",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Hyväksyntäasetukset",
"492eedd88cdd7bb45f39c6594af748c4":"Hyväksyntäraja",
"437d490afc8d226f8c7d544f4e35d011":"Hyväksyntäraja ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Hyväksynnät",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Hyväksynnät",
"17adb7fe866e32055b1469fdb7e82795":"Hyväksyntäasetukset",
"7125aa13db437084e1d27d3ff38f22e2":"Hyväksy",
"84df787ff5746f9dc3cf0146becef4fd":"Hyväksy pyyntö",
"7a568654d00245b0bab6c90a1b67d196":"Hyväksytty",
"179c3e187ad8edeb8348cff222b938bf":"Hyväksytty",
"ad058d17725870df9abbd4f5ec217636":"Hyväksytty ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Hyväksytty määrä",
"4d58ba5c0e03fec1556c243be106e4a3":"Hyväksyjä",
"7d3d697fc99f6c4641b326623743f7b7":"Hyväksytty { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Hyväksyjä",
"8c45e443fc6748190719d79a092e2528":"Hyväksyjillä on enimmäishyväksyntäraja",
"4fe56aa6456c108cd288a4ec8acf435f":"Arkisto",
"a9b6f93de9630064be9809130aa1e195":"Oletko valmis ottamaan korttikulutuksesi hallintaan?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Haluatko varmasti peruuttaa tämän pyynnön?",
"301bfc2ce058f49e0704fec3d162b5b5":"Haluatko varmasti määrittää tämän pyynnön takaisin sinulle?",
"2181faf8d9973d1f4138e401a762086e":"Haluatko varmasti poistaa henkilön ${firstName} ${lastName} yrityksen pääkäyttäjänä?",
"b982277f29055d0ef6e295fe2006d9e0":"Haluatko varmasti poistaa henkilön {firstName} tiimistä?",
"456c0ccff14acb808583cd9dece857b6":"Määritetty",
"bde315b9cf7fd55f4b65aabbbf38d462":"Vähintään yhden esimiehen tai tiimin omistajan täytyy olla käytettävissä poissa toimistolta.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Todennus",
"1961ec69dd0c8b5718c831312e7f3d0f":"Automatisoitu vastaanottoilmoituksen kerääminen",
"e39f2477cf34d726936a5b5c2b48a4ad":"Luo automaattisesti täsmälliset täsmäytysraportit, joissa on tiedot ostajista, hyväksyjistä, kuittauksista, tilikoodeista ja paljon muuta.",
"eb85d8f50f2c89871b794f7d54279b22":"Käytettävissä oleva saldo",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Käytettävissä oleva { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Takaisin",
"c8258f8cf9e20f6bbb89d957de40e820":"Pankkitason tietoturva",
"d46c22a3f36dce568c07fcea4368b231":"Perusasetukset",
"fb481c8e517016094b4fdf618c5fa088":"Laskutusosoite",
"79a9f821923c5a04c076902fef684281":"Laskutusosoite",
"877a08b8347052c83aa0b689baea81e7":"Laskutusosoite",
"97f018458e2627fac37d24846c7b8922":"Jatkamalla tilisi luomista ja Tradeshift Go:n käyttöä hyväksyt",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Jatkamalla tilisi luomista ja Tradeshift Go:n käyttöä hyväksyt",
"912d1864f0068c764bf4784020795d49":"KORTINHALTIJAN NIMI",
"d53474c7fd99fbeb69481ce9d358aa09":"Voi pyytää kortteja ja lompakon kaikille korteille. Jokainen jäsen saa oletushyväksyjän (esimies) pyynnöilleen.",
"f30df2cab105ed3f8b4264aeb9800abd":"Voi hyväksyä pyyntöjä ja luoda kortteja hallitsemilleen jäsenille. Esimiehillä on koontinäyttö, josta he voivat seurata kulutusta hallitsemilleen jäsenille.",
"03ed9eccc71ef59165292eac542d3050":"Voi nähdä kaikki pyynnöt ja kulutusaktiviteetin tiimissä. Tiimin omistajat saavat myös tiimin koontinäytön seuratakseen kulutusta ja tiimin lompakkoa, jossa he voivat nähdä kaikki aktiiviset kortit.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Et voi poistaa tätä käyttäjää. Tarvitset vähintään yhden tiimin omistajan tai päällikön tiimissä.",
"a0b70642629a55ea0ab6fa3414d342e0":"Ei voi poistaa käyttäjää, jolla on odottavia pyyntöjä.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Peruuta",
"5446624d3c364ac05ddf8d6663da2796":"Peruuta kortti",
"53f3815c4fa3340b29efd81e627d1e6b":"Peruuta kortti",
"12257ea2a871b7fab72245b5aafe6424":"Peruuta pyyntö",
"2aafb7eb4d56a516c4bb0754f0812df1":"Peruuta kortti",
"caae12601b01e2325161e95107f9e332":"Peruuta kortti",
"6ad1e57b1e4c7accbe166c948883077d":"Peruuta napsautuksella, jos kortti on vaarassa",
"f6527e49e6d5c989624507a583e23f39":"Peruutettu ({count})",
"1ad3bf5121641dda511f42942af05a90":"Tämän kortin peruuttaminen ei poista odottavia veloituksia. Etsi tilitys",
"aebd3826a195afad0a93050a7cc5d73d":"Tämän kortin peruuttaminen ei poista odottavia veloituksia. Etsi tilitystietueita veloituksille kortin palveluntarjoajan lähettämästä luottokortin tiliotteesta.",
"a5b5136947da562f91b75daf8156f50e":"Kortin tiedot",
"9ed31ad23a1578ce480d31207decf1d3":"Kortin asetukset",
"def15f2e007fc69886230fdb2511305c":"Kortin tiedot",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Kortin tiedot",
"67cfd260078ca32027c2fa4a4a2d29f4":"Kortin haltija",
"4458819ea60c68c46e551a8f5cc163cd":"Kortin nimi",
"0858bcbd506fa3240b165e59d2f18aae":"Kortin nimi",
"5212736dd18ee2c31f3650ad2c2c7130":"Kortin nimi päivitetty.",
"c96d62fa510c3356dc73a91d1a80e262":"Kortin numero",
"41307fee4c32aefb5383a98fe3d6e45e":"Korttityyppi",
"3f8045c3358621dad4346a44de0c05a8":"Kortinhaltijan nimi",
"879a6b452033cf5f3a4bb3e1837a0386":"Paikkakunta",
"593334553ef852024e172505ec3c5f21":"Napsauta tästä",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Koodaus",
"df931751d5790d74fa46707aaf7a26ae":"Koodaus 1",
"7ac7154bc776aff52a71b0d83b860561":"Koodaus 2",
"586fcf19136ae5e26795e9055b2e2995":"Koodaus 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Koodaus 4",
"379bb8e71bb090badebbb0751de86f11":"Koodaus 5",
"3e84d13627b3d090ab2b8470af98340a":"Koodaus 6",
"33b94b581f3792049992663396e51cb8":"Koodaus 7",
"b73fbc32e439655833ee99126dd0e55f":"Koodaus 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Koodausluettelo",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Koodauksen syöttö",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Koodauksen syöttö poistettu.",
"cada62a39ee72d790dcdbb25160f8783":"Koodauksen syöttö tallennettu.",
"f645bf50888943ee1384693e56a0f475":"Koodausluettelon nimi",
"a999182acbcd77c15299f79181b630be":"Koodausluettelo tallennettu.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Koodausluettelot",
"d0b96596ce6baec5aabb8a0690ab30e1":"Kommentit",
"ff9cb8842f832d21fb160b3d3784ce79":"Yritys",
"715b59a64e686eb91a21114644ffe39e":"Yrityksen pääkäyttäjä on poistettu",
"8758e9a01253c097a42393fda9610715":"Yrityksen koontinäyttö",
"e118b41009918b326db6746856831cbc":"Yrityksen tunnus",
"4dba88b05b3fa8875128e19e88c512b3":"Yrityksen asetukset",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Yrityksen asetukset",
"f2ea6d33de8182d97bf2f13354af4893":"Yrityksen asetukset: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Yrityksen pääkäyttäjät",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Yrityksen hyväksynnän raja",
"7e2f778da14a726b53e05926a3483f2b":"Yrityksen luottokortti",
"7313fd1db3d63858586d1c0f89251d6e":"Yrityksen nimi",
"07721e91dd10ff8831f0d24e47cc1eab":"Yrityksen nimi",
"203221610ca4d347063c037e1a732ae8":"Yrityksen asetukset tallennettu.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Vahvista toiminto",
"d694cec09cdbc02b1ffe22642a63faa1":"Vahvista salasana",
"512801312a133f29974f2259a455f39b":"Vahvista salasana",
"f54c38ed0b9869d929ffc0bd5310131f":"Vahvistus – Tilisi on luotu kohdassa Tiimi {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Ota yhteyttä yrityksen pääkäyttäjä kirjanpitokoodien luomista varten.",
"7a6a4de077398e8e138aec36e3b3d305":"Jatka kertakirjautumisella",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Kaikki oikeudet pidätetään.",
"51f0d8e04f6ee626b866b37833dc2b61":"Kustannuspaikka",
"e043f60a02f157d0f31a42f0db67cc87":"Ei voi kutsua {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Viestiäsi ei voitu lähettää. Yritä uudelleen.",
"ae28083f22a0b2fc5637614bce0976e0":"Luo mukautettu luettelo",
"738078dc992208e05406f935995dc0c3":"Luo mukautettu luettelo",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Luo uusi luettelo",
"0f5783c3921b3d7398d281644bbe5bde":"Luo pyyntö",
"6d62f934f2304701f88bdcfbc688de7e":"Luo tili",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Luo tili",
"f6b7d0d8531ea268f988005dd2147b2b":"Luo kortti",
"1a083675d1453448e4645403bbe52dd9":"Luo mukautettu luettelo",
"c10ab11bdfb001da74850ed89c45482f":"Luo uusi pyyntö",
"43a9c06398408bfe77d06ffc2c1c3b87":"Luo uusi pyyntö",
"68bf17fd3f54a89c0a84224e2f9a971c":"Luo uusi tiimi",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Luo tiimi",
"3b8e665101b9b3657f8a68c5305947b7":"Luotu",
"40e91f2907181ffa8e62567b3a407e60":"Luotu",
"8929f7aacb969ef6353aba9903eb362d":"Luottokortti lisätty.",
"d86688c26e8ac5eb9facc971b6fe5155":"Valuutta",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Valuutta",
"500ded53f64e737a5e4337675ac9b7ce":"Mukautettu luettelon nimi",
"b79a95f04fd829fb2e8d0313e522a94d":"Päivittäin",
"fb7add662cf7042355319a975fab6ae3":"Tietojen salaus",
"6bded249dbfafd14490eea8dd37e1f51":"Päivämäärä",
"46e908a57cb09ef947af8c4a93a09716":"Päivämäärä",
"e4213104a55eceb60e00408699438dcd":"Poista tiimin aktivointi",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Tiimin aktivoinnin poisto peruuttaa kaikki aktiiviset virtuaalikortit ja kukaan tiimin jäsenistä, esimiehistä tai omistajista ei pysty toimimaan tiimissä. Vain sinä tai toinen yrityksen pääkäyttäjä voi aktivoida tiimin uudelleen. Oletko varma?",
"0d0d5677bee2b99884802638e507160a":"Hylkää",
"b5e3483aae9d36ce1440fe02904d6da1":"Hylkää pyyntö",
"00f3db97287cb17ee9c2a131f2141a65":"Hylätty",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Hylätty ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Oletushyväksyjä",
"b77589ac3ac2317cfc96d7013658d5c9":"Poista koodaustunnus: { entryId }, Merkintä: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Yksityiskohtaiset laskelmat ja raportointi",
"5234dfc4220ccd18a8b62fe84e372248":"Lisätietoja",
"37929896817a6744718004374bb42fd8":"Lisätietoja",
"dd0a6194e8a6ae14f3e1379a1640de15":"Halusitko poistaa henkilön {fullName} ({email}) yrityksestä?",
"1a98048b34090da8b199135ccb4c1186":"Erillinen numero ja CVC piilottaa oikean kortin tiedot",
"3cf132b60fe16b816ca227bbcad60537":"Älä peruuta",
"dee57797e14ccf852aa2bc545bafdf6d":"Lataa",
"375ae8224d7d35393974775cebe8605a":"Lataa käyttäjäluettelo",
"54b3bfd45a293b441d441aab1a50e644":"Lataa raportti",
"60cf3b94e5df14a504825068928fc118":"Lataa raportti CSV",
"7341a4c73c0b688484304c37da4a6d8e":"PÄÄTTYMISPÄIVÄ",
"f628f8ad44fbd6c70c99fc778407b666":"Hae helposti vanhoja tapahtumia ja tutki trendejä työntekijöiden kulutuksessa minä aikana hyvänsä.",
"94bb409d034ea20697ae09d812e55365":"Muokkaa mukautettua luetteloa",
"2974e704676cb79666bc236d8658fa97":"Muokkaa profiili",
"9ff205f99ed4002d049f855f965ca6d8":"Muokkaa käyttäjä",
"3d7a4a9f4e70f57463e9a97caadb8205":"Sähköposti",
"0534c00af758d42ebd8b0bd772eab074":"Sähköposti",
"0c9aa1fbdf925065d25161eab5e51d95":"Sähköposti",
"ea78fac68766a48b1b0f41305b7e9898":"Sähköposti",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Työntekijä pyytää varoja yrityskortilta ostoksen tekemiseen. Pyydetyillä varoilla voi olla asetettuja ostorajoituksia.",
"829cff16ce0b13810c914c161b2a9fba":"Ota vaihtoehto käyttöön käyttäjätoimintojen saamiseksi",
"741387c7bd71e2b4a9601af9a6b9490e":"Ota hyväksyntäpyyntöjen automaattinen edelleenlähetys käyttöön",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Ota oletuserääntymispäivä käyttöön virtuaalikortille",
"bb319710dad93e78cc6f58c615bdf210":"Ota käyttöön sähköpostitoimialueen lisäys sallittujen luetteloon kutsutuille käyttäjille",
"4700ced24cd278c3149ffa983006035c":"Ota käyttöön suodatus kustannuspaikan mukaan",
"3fe06ef8f551c4cb4c0375362e25521d":"Ota globalisaatiovaihtoehto käyttöön käyttäjäpuolella",
"2787d4bc9752272ac36b499ce7ad93ab":"Ota käyttöön monikäyttöiset virtuaalikortit",
"6ee44e2280c9ce5009568846cbc3dd14":"Ota poissa-toiminto käyttöön",
"1941ca87053df0cebc6091ede92c18ea":"Ota henkilönäkymä käyttöön yrityksen pääkäyttäjille",
"e50282c2ec7afb948d2c0b428db5d5ff":"Ota kuitin siirto palvelimelle käyttöön tapahtumille",
"268694cb2172918fe60f835919bf20ec":"Ota kuitin siirto palvelimelle käyttöön tilitykset-taulukossa",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Ota itsehyväksyntä käyttöön",
"d17c7acf598b569cce8a2bb97e0bb215":"Ota käyttöön kertakäyttöiset virtuaalikortit",
"cd731ca218c9e14caabefafe003cfa6d":"Ota tilausvirtuaalikortit käyttöön",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Lopeta edelleenvälitys",
"c1f665a814894b17d603b7052b631d90":"Ilmoita uusi kortti",
"6b6cea93e07e596be70f7b76801e1516":"Ilmoita uusi kortti",
"7d6ac1ac9cba1620b61908842ebb106b":"Ilmoita lähdekortti",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Ilmoita lähdekortti",
"21a642b339c3eabf2789c0b05dcf828b":"Ilmoita ensimmäinen yrityskortti GO-sovellukseen.",
"6efa8dca4fba23a190838746879fd704":"Anna numerotunnus",
"02c3457577da9dc8dba6e6f9380a248e":"Anna yksilöllinen tiiminimi",
"40feb3fd1fa529cb599aeac9607664a4":"Lisää uusi syöte",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Esimerkki: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Vanhenemiskuukausi",
"b8bc74aeeb2de3492891c97361de107d":"Vanhenemisvuosi",
"6a0b8d521500014d99dd192d8ce8403d":"Erääntymispäivä",
"8cf274169a732340430fe7cc5236e0e7":"Tiedostokoon tulee olla pienempi kuin {maxFileSize} Mt",
"58bb9c6f074e777ab1829b41acb18a81":"Etunimi",
"7458b55e967e51791d98e77458631e44":"Etunimi",
"3bbe52e71bc5c212cea77983f1a5b748":"Etunimi",
"9b49c88fa20cc006baaac15c857c8781":"Yhdelle tai useille ostoksille",
"bb7a2907073bd760f4e82097132637b1":"Unohdin salasanan",
"26bdfaa592f3629273f13c22435b323d":"Unohditko salasanan?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Välitä eteenpäin",
"3f9fe6adc14ce5776097170653a24462":"Välitä pyyntö eteenpäin",
"b774e35683f5737c36bf0cf3d6785973":"Välitä pyyntö eteenpäin",
"628faa5054a912b1e470414f77057e6b":"Välitä pyyntö eteenpäin",
"852b4065e1837fdc77940b6411a6d902":"Petossuojaus",
"616551bd10d95aca439401d6a7769d96":"Rahdin määrä",
"a65a230918e9c11e1ef8824745e26a3d":"Aloitetaan",
"7d4c4466b7716347ce3673acaa1983f7":"Enemmän Go:sta",
"11415a07658cfef80eb166896d77ad98":"Oletko valmis uuteen näkemykseen kuluttamisesta. Tradeshift Go mukautuu nyt paremmin tapaan, jolla liiketoimintatiimit haluavat tehdä töitä.",
"ac0ff5d14286ef74614a9c6021f08f69":"Hanki reaaliaikainen kulutuksen seuranta ja budjettiseuranta, saat näkemystä asioihin ja poraudut kaikkiin ostotoimintoihin.",
"db9071ab833054307252a936e7f60e1b":"Aloitetaan",
"24dc4bb00841b004031fca3a9bb9bf51":"Go-korttipyynnön peruuttanut {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go muistuttaa työtekijöiden puuttuvista kuittauksista, jotka voidaan liittää yksinkertaisesti vastaamalla ilmoitukseen tai jos kyseessä on sähköpostikuittaus, välittämällä se eteenpäin Go:hun.",
"a73603f0bc610ecea9ef9c793b802538":"Go toimii luottokorttiverkostosi kanssa ja takaa samat petossuojausstandardit, joita odotat, kun työskentelet huipputason rahoituslaitosten kanssa.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Hyviä uutisia! Ostopyyntösi on hyväksytty Tradeshift Go:ssa",
"ee397c9cb0668cc9cba62e1c1e619615":"Onko sinulla kysyttävää tai palautetta? Ota yhteyttä",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Olen varma",
"84074732eb8d1b4094404cd750baf949":"Jos arvoa ei ole, sovelletaan yritysrajaa",
"138e8bd6102a7eea70a123ff03b1cd79":"Jos arvoa ei ole, sovelletaan tiimin rajaa",
"e5756af4d7e5ad1236ccfbb858428421":"Jos et rekisteröitynyt Tradeshift Go:hun tällä sähköpostiosoitteella",
"4f004f75f47dbfecdb6d09990596e530":"Jos sähköpostiasi ei ole vielä rekisteröity, lähetämme sinulle aktivointisähköpostin, jossa on ohjeet asian hoitamiseksi.",
"123837f267de602fb05361b3b1197c1e":"Jos {username} vastaa olemassa olevaa tiliä, lähetämme sähköpostin, jossa on ohjeet asian hoitamiseksi. Jos muutaman minuutin jälkeen, et vieläkään näe sähköpostia Saapuneet-kansiossasi, tarkista roskapostikansio.",
"45efd96cd93525a9c17222217a37d324":"Tuetut kuva- tai PDF-tiedostomuodot",
"fa6ae823f9ab8bf91c246efe2517956c":"Tuo QuickBooksista, NetSuitesta tai Xero-tililtä suoraan Go:hun. Vie uusimmat tapahtumat kirjanpitoratkaisuusi yhdellä napsautuksella.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Ei aktiivinen",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Syötteessä on virheellinen merkki.",
"62c7be09da43f8f96a60ef3967eddc2c":"Esittelemme täysin uuden Tradeshift Go:n.",
"19746bcbed8b8bcb1a9b83efca722077":"Virheellinen sähköpostin toimialue",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Kutsu ihmisiä",
"3809b41b14c55c3fcf54cd813d8e21f5":"Kutsu tiimiin",
"fc90a11805961b0d7b5a5545e4d9bc05":"Kutsu  käyttäjä",
"6f7f4acde92d267417f5d8a60d32400a":"Kutsu lisää ihmisiä",
"09ecd2ca15bb5c38dc10f653118346cd":"Kutsu uusi pääkäyttäjä",
"3f576ce504f53f972442a63a170c5418":"Kutsu uusi {role}",
"0215e052437ceea4e5cd562431b34ced":"Kutsu ihmisiä",
"8aaf2c77df644b7e8524120f66da7d5f":"Laskun määrä",
"1b7dc8290f1205f092cc9133e0cde385":"Laskun päivämäärä",
"1a6fb210308df8e0135e49e76aaff31e":"Laskunumero",
"613da3d0cd586ef0360e0d93eace9508":"Kirjattu",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Se kestää",
"3322b671105c65511fbab1966b7fdcf6":"Kieli",
"b1d946192795a58aea6a61f4f923acb7":"Viimeiset 4 lukua",
"b3c6b8db4ef6dcdcb451303e65548816":"Viimeiset 4 lukua",
"0382123b9c847000fceff2484c665e39":"Viimeiset neljä numeroa",
"19f7c0055e150e9e3e66e85c2993799c":"Sukunimi",
"662c84bede8a7bf91ab15bcb73b48b49":"Sukunimi",
"cff8fbef5aac2523344b9535bd56973c":"Sukunimi",
"c3510b2184a92ff12e07a2d1b9a461e6":"Anna työntekijöiden pyytää pääsyä yrityskortillesi ilman, että sinun tarvitsee paljastaa kortin tietoja. Tämä kaikki samalla, kun valvot budjettia ja seuraat jokaista ostosta.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Raja voidaan korvata tiimi- ja yksilöhyväksyjätasolla",
"9bf5d56627e500cad9f7ad21c3e7966d":"Rajoitus tiettyihin kulukategorioihin",
"e80a6d4232e52f6bfabee02e17403b59":"Linkitä yksi näistä lähdekorteista tähän tiimiin tai ilmoita uusi.",
"803570ab9f0fba5ff35ae2749200501f":"Ladataan kortteja...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Kirjaudu sisään",
"ad96c1ca962a883652dadb845f439415":"Kirjaudu sisään",
"072ca67e735adbed54acf41321370701":"Kirjaudu sisään",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Kirjaudu sisään",
"b246fe8836c906f1b56342378ca88895":"Tee, hyväksy ja seuraa ostoja tiimeissä, jotka kuvastavat organisaatiosi rakennetta.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Hallitse tilityksiä",
"95d672ab621e531ae1a69798e0e4b56c":"Hallitse kaikkia virtuaalikortteja yhdessä paikassa.",
"96f2e88a167f476413b35aa711709e6e":"Hallitse tilityksiä",
"151d8e6ceec242d482fff60da8311449":"Päällikkö",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Päällikkö",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Päällikkö vastaanottaa jokaisen pyynnön arviointia ja hyväksyntää varten. Ostorajoituksia voi myös säätää.",
"4f449985e83c1f9ca2e8f82b0707a371":"Päällikkö vastaanottaa jokaisen pyynnön arviointia ja hyväksyntää varten. Ostorajoituksia voi myös säätää.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Päälliköt",
"27f8111a955975ea6fd04f748d24cb4a":"Hyväksynnän enimmäisraja",
"5b1d9cfb78c55e75b47c62435d9299ca":"Jäsen",
"41e307c1874e2e82296b226e9bae4a5f":"Jäsen",
"6f9612aa1582373c2c1ba4e144132100":"Jäsenet",
"590429a0fcc42af85648d0fd70601dcc":"Jäsenet",
"3f50e62c8488d83ad84f69ebda853897":"Jäsenet",
"e0fc0acda5b74b93e797756dbbba67bc":"Jäsenet",
"c7ef9eda38b7eaee1f20031387a8bde7":"Kuukausi",
"40dd08b507752bef4e3c4b36838317d5":"Kuukausittain",
"d712b91f7498f212047aad4c6d0f8088":"Viimeisin",
"12110d95b3d7a495afa4f28ac190f63e":"Viimeisin",
"2f11ce3865f79ee7e5ee64d728082264":"Viimeisin",
"eadc93865655cffc6a9863fdda5693e1":"Täytyy olla vähintään 10 merkkiä sekä isoja että pieniä kirjaimia.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Täytyy olla vähintään 10 merkkiä sekä isoja että pieniä kirjaimia.",
"50d0a69aa59df5201018771a06ea12e7":"Täytyy olla yksilöllinen",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Täytyy olla yksilöllinen.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Omat kortit",
"c36602f50d35c2816f0ba8a196b70876":"Omat pyynnöt",
"ff6e372aa3eb685a39a701963db35b2f":"Omat pyynnöt",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Nimi",
"1c7514b561a2591a914225813d30d642":"Nimi",
"d621826c1ceade262589af04e655e824":"Uutta salasanaa pyydetty",
"35dc3304b61a9e69dc3a393123658837":"Uudet pyynnöt välitetään automaattisesti edelleen tällä aikavälillä.",
"e788124801b4727d121f60d059a2a4a7":"Seuraava",
"eca804f1d53dfd984ffd93e2d00fc284":"Hyväksyjää ei määritetty, ota yhteyttä ohjelman pääkäyttäjään",
"abdf7e93f8509399a63108a2b1a25546":"Ei näytettävää aktiviteettia",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Ei kortteja lompakossasi",
"87127e1e9f5bf922d0a4f948b68697c8":"Ei rajaa",
"40512efa6b75ef0c7924b1ee3070fca9":"Ei näytettäviä jäseniä",
"dcda493afcb2415d5ca49a17d1af6f50":"Ei postitettuja tilityksiä vielä.",
"f0efb26e623bb990532e375d5c8875ec":"Ei käytettävissä",
"bc0cf602d1978e814070bb552e576a32":"Ei määritetty",
"09e126c317429ccb52ad323c32293648":"Maksujen määrä",
"75b00b969b9d3b520929f1e652637b70":"Maksujen määrä",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Maksujen määrä",
"bd44ba180aa356c5755fdedd32adf03c":"Oho! Pyynnön luomisessa tapahtui virhe. Yritä uudelleen.",
"c396b3728dd7393aa9e5427990c132a7":"Oho! Raportin lataamisessa tapahtui virhe.",
"42c07764b07dea1ee7de7605721c3f5f":"Oho! Emme pystyneet luomaan uutta tiimiä.",
"e3a385878ec94bac12a99490d0a8e85a":"Oho! Emme pystyneet luomaan tiliäsi.",
"17f2dae79677017e2c2e879578c4e09a":"Hups! Jokin meni vikaan. Yritä uudelleen!",
"78c135de545c1663c9768226492a44a3":"Tai käytä kertakirjautumista (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Poissa",
"5d7e45f169e2f6968dc58f43b5267353":"Poissa",
"ee589a20c262527db970671fb2c4f6bc":"Poissa",
"083b7090b66ebd0307a18499ac7b676e":"Sivua ei ole olemassa",
"a21d205920893cf579a7655a1430fe30":"Pääkortti",
"fd35b7ce938e404fdb65de3bb749c528":"Pääkortti raportille",
"e8c7d2eea7db66ecb44751091a582304":"Salasana",
"bb79e316e00fd21138750b6986e44455":"Salasana",
"6cae2279088580976d84119d95097cae":"Salasanat eivät täsmää",
"4baec0159d3074f84c0eebcc09ba4cd8":"Salasanat eivät täsmää",
"b676587115243921602742cb8f696a6a":"Keskeytä kortin luonti",
"db4a26c871ef0b970b74e1f71763098b":"Keskeytä uusien virtuaalisten korttien myöntäminen",
"33fc1e6d01b785e3a815c28ef7d56667":"Odota",
"6ea62e4811b68df48b467e8d367aac2f":"Odottavat pyynnöt ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Ihmiset",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Tiimin henkilöillä voi olla 3 eri roolia",
"e2d34cb1409b9778d34c4b74ecee1df6":"Anna positiivinen kokonaisluku tai jätä tyhjäksi.",
"8da247d5d736c87122404eff1e5c0c73":"Anna kelvollinen raja.",
"65e154267ac283d963cbc7be832969d9":"Anna kelvollinen arvo",
"28b4450d417065a69a395f567967d46c":"Esiasetus pyydetyillä ostorajoituksilla",
"7bbf151d8493170f206a23c2ecc6acd0":"Tietosuojakäytäntö",
"51602962f2d1e7515943b4a6c66562ce":"Profiilikuva",
"a51a17940c70a3123da4d68bed562c40":"Profiili tallennettu!",
"4d398249d01ab72d697a7e219493c637":"Palveluntarjoaja",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Toimittajan yhdistämismääritys",
"89b1deb2ffe12a13941c362abaeab01b":"Julkaistu",
"d8d806d48052159e3ff3b224f3f53586":"Tarkoitus",
"089917619c78af2efb30be0bb7ce076c":"Aktivoi tiimi uudelleen",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Syy",
"e8484c78fda9f453eeb942ba4945f040":"Sijoita uudelleen",
"ff2fbc1172ba26776ad0982624cd2713":"Kuitti",
"588e690db177ee0c7a7cadb39cbd5efc":"Päivitä varat joka viikko, kuukausi, vuosi",
"32955937daca05b2084556f248692baa":"Rekisteröinti",
"f15bb755ba24abe6ab106637292175ec":"jäljellä oleva",
"579782461133a8b8cc6bd77798961d82":"Poista kortti",
"e475edc165372f8c8deb532aa85d9950":"Poista luettelo",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Poista luettelo",
"f9769561315b964d0b3b4887ec208977":"Poista tiimistä",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Poista kuitti",
"ee181cef581fc8e8217e66b5762db898":"Luettelon poistaminen poistaa sen käytön ja poistaa kaikki olemassa olevat tapahtumat. Jos haluat poistaa listan tilapäisesti ja pitää tapahtumat, voit ottaa julkaisuasetuksen pois käytöstä. Haluatko varmasti poistaa tämän luettelon?",
"feea41d0bc1d577a07c8b6e1297abbde":"Lähdekortin poistaminen tiimistä ei poista olemassa olevia virtuaalikortteja käytöstä.",
"d09483824a2e0d6194a4c3f32311e752":"Lähdekortin poistaminen tiimistä ei poista olemassa olevia virtuaalikortteja käytöstä. Haluatko varmasti poistaa tämän kortin tästä tiimistä?",
"7ca4b1bf1346e179a29da97f260a93a4":"Nimeä uudelleen",
"db195ec9c818b8e92da142374e20d1bc":"Nimeä koodausluettelo uudelleen",
"2fd93e229fe9ea754808805d3fef9c13":"Nimeä koodausluettelo uudelleen",
"aa1e74d32805823f802a11b33c60c38b":"Raportin tyyppi",
"6ac78d97a8cb7f060e43e596e3e23535":"Pyyntö peruutettu.",
"25deccb1980291a0fa9997109c731440":"Pyydä demo",
"9f2754d87686903b1500ce8e8a55b377":"Pyydä demo",
"92ac924f72679f897e26f3e615be8c22":"Pyyntö välitetty edelleen.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Pyydä tietoja",
"270185007853cd779011ae0e8433f723":"Pyyntö määritetty uudelleen.",
"e5a059c9ba2691910321339cce989f9d":"Pyyntö lähetetty.",
"77c88a231edfa7075114d91d2397e42d":"Pyynnön otsikko",
"cee91b833c4358acf0c554f746557e71":"Pyyntö hylättiin.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Pyyntö hylättiin.",
"9e56abbbf87420eeedd625ce786515c7":"Pyyttäjä",
"b4cb4426e44fee9580af71912a764536":"Pyynnöt",
"df309e888c29fc447d0309329b39ce93":"Palauta salasana",
"5daf278da19ecbaeb7b96554cb4db10f":"Yritä yhteyttä uudelleen nyt",
"31e9501526d2114e4a01fdbf076de097":"Tehtävä",
"6fbceb787429f6f81117a1d5a58b4610":"Tehtävä",
"473b814ca86841ef672ccf9f7dac28d5":"Tehtävä",
"f1e16d5fd643e039d865b6aec7c986f9":"Tehtävä",
"ec78345a5975bc0f8efaee5256b43c2c":"Tallenna",
"2cecea57b47d8f4a45c706287ff7d64e":"Tallenna muutokset",
"15774be5c338a39169917c7d32781dd0":"Tallenna ja jatka",
"844095b4b8f580737b8717672501048e":"Sano jotain...",
"7e66f676c2e7b04514aca3a0935530f0":"Haku koodaustietojen mukaan",
"5293ce9335ec2d176a6e838bf76404c6":"Haku kuvauksen mukaan",
"efab3b0c95f263cdd0a8466329a387eb":"Haku kuvauksen mukaan",
"d227b38dcbc4548c092b0836a7101506":"Haku myyjän nimen tai tapahtumatunnuksen mukaan",
"3629383abf1d5f85efd759f718159b40":"Etsi nimen mukaan",
"a034fcd6719228b28a6fc22d3a46ea8a":"Haku tiimin nimellä",
"8ac7e0d63f343b548482d5f367f5b3d9":"Haku tiimin nimellä",
"858323eb52cb45446597ee46330a0540":"Haku virtuaalisen kortin omistajan mukaan",
"111bfcbff671e13de117ed5195fb6773":"Valitse",
"bcfecd59eb93e5314873d84ddf35aa58":"Valitse",
"909a4414ea573218bff2d533eaab46ea":"Valitse päättymispäivämäärä",
"114b206ca1ff1c0bc52abef342059b2a":"Valitse tiimivaihtoehdot",
"81a02e424615fb41a20b8d0f13441c88":"Valitse tiimit",
"b135b36495618e6692e7fbaf0a0aa83e":"Valitse kuukausi",
"677e90855e164c00a28b5c4763ac67e0":"Valitse tehtävä",
"d01570f3774738395b8ba3c64ce54c76":"Valitse osavaltio",
"7dee3f06c70626881efb0cd6af6b63e1":"Valitse käyttäjä, jolle pyyntö välitetään",
"4c7bb810835a1540ddc1f6501da5a51b":"Valitse vuosi",
"3ab198d2678af2b5b32180e4b39602ba":"Valitse hyväksyjä",
"f502ba79f2bd010c5e77891812400958":"Valitse kortti",
"936027182d446e27ba209ea921ef7502":"Valitse oletuserääntymispäivän alue",
"b22c893254ccf3bd34faaee511cf7ca8":"Valitse kesto",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Valitse esiasetukset koodausluettelosta tai luo oma uusi luettelo.",
"42cce5fa376b45bf290f86c684d69d96":"Valitse palveluntarjoajakenttä",
"b761773d46699b60e8d6874b85593374":"Valitse tiimit",
"3a9396756942ede98d0cb6afc1032b15":"Valitse niiden hyväksyjä",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Itse",
"03e9d2c128af288126d94f631c5396be":"Lähetä ohjeet",
"299a7e961804e2d649f06b5dc4362c29":"Lähetä kutsu",
"155e7b3f7db7bf10b03b5f41de54eae1":"Lähetä viesti",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Aseta erääntymispäivä",
"f89ec96a4b5bb639488ab742efa76a6a":"Aseta uusi salasana",
"7d5377ad70d57728a45d3c0639de5544":"Asetukset tallennettu",
"14313f11dee735f2144953e720776c28":"Tilitysmäärä voi olla muu, ostoon kohdistetaan vihje- tai muita maksuja",
"199000fe0cda0adaaa9b864aa7447cb9":"Tilitykset",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Tilitykset",
"6e6ac6ff3bd722e37874fb1ea7115818":"Aseta salasanasi",
"9b4ecf052980e80bf5c65a415d470a43":"Jaa ja hallitse yrityksesi kortin käyttöä",
"ba746d78772d6be26cc508ba989becb0":"Tulisi olla vähemmän kuin yhtä paljon kuin {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Näytä tapahtuman ilman kuitteja",
"7d474b60aeb7cc25b519e806fca71e12":"Kirjaudu sisään nykyisillä \n\n Tradeshift-tunnuksillasi",
"16080cd4e8319ef3e7de21b6c19d1437":"Jokin meni vikaan",
"8f358a370224baf849f9652810c68db2":"Jokin meni vikaan",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Jokin meni vikaan",
"99af87c475a4d40ef732deed96f11ed2":"Jokin meni vikaan. Yritä uudelleen.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Pahoittelut! Meillä on tällä hetkellä ongelmia yhteyden muodostamisessa – yritetään uudelleen {retryConnectionPeriod} sekunnin kuluttua.",
"84debc8e41646cd49ac2b14708ca6d73":"Pahoittelut, koodaussyötettä poistettaessa tapahtui virhe. Yritä uudelleen.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Pahoittelut, koodaussyötettä tallennettaessa tapahtui virhe. Yritä uudelleen.",
"689d3418ee8f996ad25e152e80de63fe":"Pahoittelut, emme voineet kirjata sinua ulos tällä hetkellä.",
"b48efd4521ecd6428987d24636a4349f":"Lajittele",
"776a95003d3ec1dd58af69aedbc08579":"Lajittele",
"1b783238028c0ae085ca69ed823f4d1a":"Lajittele",
"be70d19bf13c935d653b2c4fe7d36224":"Lähdekortin nimi",
"564f8c942e7d1bdda5251545612fa348":"Lähdekortin nimi",
"aa284602c746aad4a6de6366c24221b8":"Lähdekortit",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Lähdekortit",
"389d471e79a43440b3278b0fc7187d9e":"Lähdekortit",
"106342f689b57145817b00d6fe383f91":"Määrätty päättymispäivämäärä",
"2a5e19387ff300509876e3bae99b7ab9":"Nopeuta kirjanpitoasi",
"ca34d586bbdb7f368f1009305bccbe76":"Kulutuksen koontinäyttö",
"4583120998b44c0a5982cf85dd938d2f":"Kuluta ajan mittaan",
"244d724806247953233a0bbfcbf8da4c":"Kulutusraja",
"d39b83dd151c8df6370460a458294ccf":"Kulutus",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Kulutettu määrä",
"5de2859528e030ac1f2af115ebdf209c":"Kulutettu { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Aloita edelleenvälitys",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Tila",
"b089930f1f6ffac09438cc19590f8f6b":"Tila",
"f174d20592156eb4b10a7cf1144c973e":"Tila",
"ecf342482d1b6ab932c2000cd7dc38c4":"Tila",
"1f1fe111548158ec842761eca7425ff3":"Katuosoite",
"31a0a748b1097a9e0aa95a811802bdd0":"Stressitön täsmäytys",
"111b782f3f0794c2294aefb6693b2c50":"Lähetä",
"013e2555920b51ad2a3d2c3dc482c471":"Lähetä pyyntö",
"1270cd8eb69ad64cc71e5de979423b3b":"Tilaus päättyy",
"2802336ad3d8ff658257b88a93f4e50a":"Tilaus päättyy",
"b28dd68454d7acbefafeefb42981fe16":"Tilauksen toistuminen",
"907793bc80cf94d560e5f1a0250fdaee":"Tilauksen toistuminen",
"29229e153308e4747edef1144de35a55":"Onnistui!",
"503eb46b80f374f39266e7a9aea8e63e":"Onnistui!",
"4f20e29cadadb9fbc7455bd4d80de567":"Onnistui!",
"601d04f10d558f436c78c10e90b44175":"Tuki",
"62558c185ea2c06c5472d1cd534f6e56":"Voi synkronoida kirjanpitojärjestelmäratkaisusi kanssa",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Veron määrä",
"0cb5d74f7a56fca30930616feb6e2f47":"Tiimi",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"Tiimi",
"bf23b2fd889e3b12e0f0acf99f913eca":"Tiimikortit",
"b90a650376782a9e43ed6be6135bd6f2":"Tiimin koontinäyttö",
"6adbec395fe1c4bf776f9fe5fa021052":"Tiimin koontinäyttö",
"6f2695eeb68501dbcfddbb4d265b846b":"Tiimipäälliköt",
"3b779ade9c960ac95a670db0cc12e56b":"Tiimin jäsenet",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Tiimin asetukset",
"dc5235e1b88320d3ba7501e4c30985a3":"Tiimin asetukset",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Tiimin asetukset",
"a2eb92bfd7502f47b3cd170bc68ac638":"Tiimin asetukset",
"f1ec84f051f19f0d0e301a709b13dc45":"Tiimin kulutus",
"fbacde3a262ea8696e30135e8f987a44":"Tiimin vaihtaja",
"74b9578ea5a620f5fef102188e42c02f":"Tiimin vaihtaja",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Tiimin toiminnot",
"3aa978fa7ef886621285b93257d428c3":"Tiimin hyväksynnän raja",
"a41f9df131c221a99926de7e2138653a":"Tiimin tiedot",
"941c7da8f256f91ba3819f991315a100":"Tiimin nimi",
"c5d04e10cc2f0b53c2bda893eae09178":"Tiimin nimi",
"504a8ba642ba37c9a743d0fabb2bd371":"Tiimin nimi",
"e768a8e1e3d6a6bf470587dad75423fe":"Tiimin omistaja",
"e2d824067f8bd871756688c58c382196":"Tiimin omistajat",
"dca232ccc849c5ff5611255eb82baf53":"Tiimin omistajat",
"3b61bd586225790cf132591383d15448":"Tiimin vaihtaja",
"bc05b40509f0271c12cfaaef8e5319b1":"Ryhmät",
"39e73773d07a800673ec6600422952d6":"Ryhmät",
"daabb564bb3dcfb41de69492f854d573":"Ryhmät",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Tiimien ja yksilöiden rajat eivät koskaan ylitä yrityksen rajaa",
"4b4fe189a48d8eb06d836afa8fb6317a":"Tiimit päivitetty.",
"2a17f9a8c610db29fec63fd530c29fdc":"Korttia käyttävät tiimit",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Tiimit, jotka näkevät tämän",
"317868f3804ba4ed5d99dd9919c798ba":"Käyttöehdot",
"e8c9a9847db2ccd97524aa06f1af9d82":"Käyttöehdot ja tietosuojakäytäntö",
"38852078519c5c46715d8a034fc4901b":"Käyttöehdot ja tietosuojakäytäntö.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Kiitos mielenkiinnostasi Tradeshift Go:ta kohtaan. Tuki {cardLabel} -luottokorteille on tulossa pian. Asiakaspalvelutiimimme jäsen ilmoittaa sinulle, kun se on saatavissa.",
"f7047b35a069d0790a0a348a29c846a2":"Kiitos, että liityit tiimiimme {companyName} -yrityksessä tehdäksesi yritysostoksia virtuaalisilla Go-korteilla.",
"133b0a70b1a956889164621380c378f9":"Toimintoa, jota yrität suorittaa, ei voi tehdä loppuun. Joissakin tapauksessa uudelleenyrittäminen auttaa.",
"18f5a1872e156f1aee84ae321780d952":"Pyydetty summa on suurempi kuin yrityksen pyyntösumma {requestLimit} $. Yritä uudelleen alemmalla pyyntösummalla.",
"867e3d4d3c78990c6852fc5348706ab2":"Pyydetty summa oli yli tarjoajan pyyntorajan ${providerLimit}. Yritä uudestaan pienemmällä pyydetyllä summalla.",
"570f5f4658b03dda7a910f56c86a3bd3":"Kortti tälle pyynnölle on peruutettu.",
"3075c56105863546e3368ffead7a146b":"Kortti peruutettiin.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Koodausluettelo, jonka julkaisua yrität peruuttaa, on yhdistetty asetuksiin. Jos haluat varmasti poistaa sen asetuksista, ota yhteyttä tukeen sen suorittamiseksi.",
"756ade8d71ec487a4b48d7c4277ea239":"Koodausluettelo, jonka julkaisua yrität peruuttaa, on yhdistetty asetuksiin. Sinun täytyy ensin poistaa kartoitus ennen kuin voit peruuttaa tämän koodiluettelon julkaisun.",
"4f40335f7c018986c95458183bc0c136":"Linkki tilisi aktivoimiseksi on virheellinen tai vanhentunut. Pyydä sinut kutsuneelta henkilöltä toinen kutsu.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Linkki tilisi salasanasi palauttamiseksi on virheellinen tai vanhentunut. Pyydä salasanan palauttamista uudelleen saadaksesi uuden linkin.",
"ded30f0e288c12125ca1a77f8d8fd600":"Syy on näkyvissä hyväksyjälle ja pyytäjälle",
"f294897b38fc23dd8f5de52b54ceae22":"Pyyntö hylätään ja pyytäjälle ilmoitetaan asiasta. Haluatko jatkaa?",
"e8c7200dc985df0a94a9d996968b4cc0":"Pyyntö hylätään ja pyytäjälle ilmoitetaan asiasta. Haluatko jatkaa?",
"5f3d196a2ade3f97a2a48b773fec5861":"Pyyntö hylätään ja pyytäjälle ilmoitetaan asiasta. Haluatko jatkaa?",
"b04c191cd6b4f27669a4f390311f156c":"Tiimin omistajia tai päälliköitä ei ole valittavaksi.",
"e25045557efbad7b52997b94a1fe6817":"Ei ole {type} tälle ajanjaksolle",
"7260690577c795e999acae63bb7c51ae":"Tiimin asetuksia tallennettaessa tapahtui virhe. Yritä uudelleen.",
"f2103024f2a9c6c0335a71c60567d6c9":"Lompakkoa ladattaessa tapahtui virhe.",
"9776cb7eab9fa0d318a8144cd17de745":"Lompakkoa ladattaessa tapahtui virhe.",
"77d28a14bc900be4360b570a82eb75a9":"Yhtään näytettäviä tietoja ei ole",
"2b6feba67722337f88c1443909fe446f":"Hyväksyjän lisäämisessä tapahtui virhe!",
"24b7212afb97c5d23faafa2ca4559095":"Luottokortin lisäämisessä tapahtui virhe. Yritä uudelleen.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Lähdekortin lisäämisessä tiimiin tapahtui virhe. Yritä uudelleen",
"5a8e1b922d1cee746ac6794599b10709":"Tämän kortin peruuttamisessa tapahtui virhe. Yritä uudelleen.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Tämän kortin peruuttamisessa tapahtui virhe. Yritä uudelleen.",
"22361c5d4df61ad7ae9792a3baf5729f":"Luetteloasi luotaessa tapahtui virhe. Yritä uudelleen",
"098755bfddeaf0428c7feb6b69af9b6f":"Pyynnön hylkäämisessä tapahtui virhe. Yritä uudelleen.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Pyynnön hylkäämisessä tapahtui virhe. Yritä uudelleen.",
"7d461b6b7b54dbfa9c83243217cd1053":"Koodaussyötettä poistettaessa tapahtui virhe. Yritä uudelleen.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Luetteloasi poistettaessa tapahtui virhe. Yritä uudelleen.",
"beb43d7800cfff47e08cd61842d953bd":"Pyynnön välittämisessä edelleen tapahtui virhe. Yritä uudelleen.",
"f7775350f35b8365fb9803232d9b792c":"Kortin tietojen hakemisessa tapahtui virhe. Yritä uudelleen.",
"95abaa922c6c1648ca9c39ac4776896f":"Käyttäjätietojen hakemisessa tapahtui virhe. Yritä uudelleen.",
"9d7e4ead60137041ae8a845aa6cf9979":"Käyttäjäprofiilin lataamisessa tapahtui virhe. Yritä uudelleen.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Tämän tiimin käyttäjien lataamisessa tapahtui virhe. Yritä uudelleen.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Asetuksiasi ladattaessa tapahtui virhe. Yritä uudelleen.",
"b2f48028c400befad5f1d57f769ef7cd":"Pyynnön uudelleen määrittämisessä tapahtui virhe. Yritä uudelleen.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Lähdekortin poistamisessa tiimistä tapahtui virhe. Yritä uudelleen.",
"19dfcd761006496c1d20351c04643e45":"Toimialueen poistamisessa tapahtui virhe. Yritä uudelleen.",
"149651b5784481c68aaf4ac27bb1f907":"Koodausluetteloa tallennettaessa tapahtui virhe. Yritä uudelleen.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Profiilia tallennettaessa tapahtui virhe. Yritä uudelleen.",
"749ddc235744b6b371668ec0bb049176":"Käyttäjää tallennettaessa tapahtui virhe. Yritä uudelleen.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Toimialuetta tallennettaessa tapahtui virhe. Yritä uudelleen.",
"04cf86909808f430b8b1904fb422e0d3":"Tallennettaessa Poissa-asetuksiasi tapahtui virhe. Yritä uudelleen.",
"2eaf6ee97d984598c95e0494d72aa492":"Ostopyynnön lähettämisessä tapahtui virhe.",
"148f847d3beccaa67fcd5096ae3602d3":"Kortin nimen päivittämisessä tapahtui virhe. Yritä uudelleen.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Kortin nimen päivittämisessä tapahtui virhe. Yritä uudelleen.",
"de8cc976f7af9edf189904239e6240da":"Tiimien päivittämisessä tapahtui virhe. Yritä uudelleen.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Pääkäyttäjän lisäämisessä tapahtui virhe. Yritä uudelleen.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Tiimisi aktivoinnin poistossa tapahtui virhe. Yritä uudelleen.",
"3f686a97a1b9fcdd73a40d2005693105":"Tapahtui virhe. Yritä uudelleen.",
"2f38263789163cf8ee30c8ae88852c82":"Luetteloasi julkaistaessa tapahtui virhe.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Pääkäyttäjää poistettaessa tapahtui virhe. Yritä uudelleen.",
"007a647254e1f85b6c1e789004e05df5":"Asetuksia tallennettaessa tapahtui virhe.",
"c3072d080b587d70f85d68ec089f028f":"Tiimisi asetuksia tallennettaessa tapahtui virhe. Yritä uudelleen.",
"608cd0e109c3e51b0425df8921e4181d":"Palveluntarjoajan määrityksiä asetettaessa tapahtui virhe. Yritä uudelleen.",
"177cefc68dd318ea75673326e77d8206":"Luettelosi julkaisun poistossa tapahtui virhe.",
"255d22dc4f0e338a4cf53d5b7256e695":"Haettaessa henkilön nimeä, jolle olet välittämässä hyväksyntäpyynnöt, tapahtui virhe.",
"39ac4ec73fed8a981ad1857d989edfcb":"Tämä määrä ylittää yrityksen hyväksymisrajan.",
"494ce3f598cbd97afe99774b5975a4bc":"Tämä määrä ylittää tiimin hyväksymisrajan.",
"f2a42214e7c480c5596439becfd0b7bf":"Tämä kenttä vaaditaan.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Tämä asetus vaikuttaa vain uusiin kortteihin",
"881b7596cf985d9c246b05fcab930632":"Lähdekortti on lisätty tiimiin.",
"a1b1aa354d06e7815b78c6abb7448e84":"Lähdekortti on poistettu tiimistä.",
"b348b24fc4839ee15ed0aa424689bae3":"Tiimillä täytyy olla pääkäyttäjä tai päällikkö, ennen kuin voit kutsua jäseniä.",
"188b9ed447a43916f9e323623ea3a993":"Kutsun hyväksyminen",
"969de95e19a06f5ae7cec6449cbb6a27":"Yhteensä",
"30286e57da108ee7dbc0f8fbb62d4fff":"Kulutettu yhteensä ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Seuraa kulutusta tiimeissä, projekteissa ja tilauksissa.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Seuraa tiimisi aktiivisten korttien toimintaa, keskeytä kulutus ja poista korttien aktivointi, jos tiimi ei enää tarvitse niitä.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Tradeshift Go -salasanan palautus",
"5d760188301146cc7255ce18b65cb370":"Yritä uudelleen",
"80196bc616d4a44a4997a2ea127f7b83":"Yritä uudelleen",
"f96bbe81618b5f4a1807989eb4f69e24":"Yritä uudelleen",
"d498d7d261b7ca56e07218d1a0dbdd49":"Oho! Tapahtui virhe. Yritä uudelleen.",
"ce2e475291003852c71b01959698c496":"Viestejä ei pysty saamaan pyynnöstä {title}, Yritä uudelleen",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Kumoa arkistointi",
"d2e50be5287fd8fb436d0acbeb811864":"Yksilöllinen tunniste",
"0e2fbce967f4a209215b6f48f8eddac3":"Poista kortin luonnin keskeytys",
"f2315dec5dd07802b77af3a3a5414a7d":"Enintään 4 vuotta tulevaisuudessa.",
"d2f30a2f0c955271e9af123dceca501a":"Enintään {label} tulevaisuudessa",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Päivitä lähdekortti",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Päivitä lähdekortti",
"c8f6bc61a8982158e408194e48a274d9":"Siirto palvelimelle epäonnistui. Yritä uudelleen.",
"8a86a60cb836bdb5225cad20c5151078":"Siirrä vastaanottoilmoitus palvelimelle",
"e448a1ce42de0cf526200331b4ad8b23":"Käyttäjä",
"75edcc8fc27f41c99e28b48ff250c7f8":"Käyttäjätiedot",
"d1651c2afdb6c92621adbb707e4753d0":"Käyttäjä tallennettu.",
"5fa55883b7413526b441e999cb8089b3":"Käyttäjänimi",
"7a892b81fe2aae295f1b2d38bb67360f":"Käyttäjänimi",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Käyttäjiä aktiivisilla korteilla ei voi poistaa. Peruuta kortit tämän käyttäjän poistamiseksi.",
"d40fa5935f1614925cf46259c886814a":"Näytä kortin aktiviteetti",
"625419857efc7e72d162c9f8204109df":"Näytä kortti lompakossa",
"85a5e817826415651ed4991a76bb5385":"Näytä keskustelu",
"09fc124dc9b8b14d6227a92bb0a4566f":"Näytä keskustelu",
"d26cfc6d8f420f259f434b79593e9eb4":"Näytä kuitti",
"c7d0394c3206a3b74afbeabc41cc639c":"Näytä kuitti",
"01650aa52c5e5a22d3f38afd2c7f9411":"Näytä kuitti",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Näytä kuitti",
"b2d141897f73fc2a198ee1a7a438f89b":"Näytä pyyntö",
"02fa2f56ef72845a9dfeabea22948061":"Näytä tiimin tiedot",
"2d9c55650b17dd605a1388f46c81ba38":"Virtuaalikortti",
"d0ec076e4600e854588f8da265208c0b":"Virtuaalikortin asetukset",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Lompakko",
"5279a2220e4cfb0d8b8b357311251cbe":"Lompakko",
"b1746b5806659e3189f837d2dd54e08c":"Lompakko",
"ff306d5b34ee993f9618c1f3c0209a39":"Emme pysty arkistoimaan pyyntöä tällä hetkellä. Yritä uudelleen.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Emme pysty peruuttamaan pyyntöä tällä hetkellä. Yritä uudelleen.",
"a813d44f6bb26c5da09671e532abe022":"Emme pysty kumoamaan arkistointia tällä hetkellä. Yritä uudelleen.",
"d4c7310eafc0d0da89f20596a22a3abe":"Emme ole pystyneet luomaan pyyntöä tällä hetkellä. Yritä uudelleen!",
"dca679c10accb1db481a57a0e1ba08e2":"Emme ole pystyneet luomaan pyyntöä tällä hetkellä. Yritä uudelleen!",
"db5584a2ef29b04959bce7f93db54c56":"Emme pysty vaihtamaan tiimiä tällä hetkellä. Yritä uudelleen.",
"f2af2c261fabddb01766f4f974b85a62":"Viikoittain",
"e181ed0723a00ca7558b9d5d5258732b":"Tervetuloa Tradeshift Go:hun, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Otamme yhteyttä!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Mitä luottokorttia yrityksesi käyttää?",
"968d94729053e166a76ea577ce3022e2":"Työsähköposti",
"588a075e8694ff5cc86815697e1586bc":"Työtä tavalla, jolla liiketoimintasi tiimit työskentelevät.",
"182ae1eb5226e0646b4033883cc0900c":"Vuosi",
"fe88fe7094c4ced427874b3d22326c8d":"Kyllä",
"9a2d4e3b22ad0567165eb0f661f952ad":"Kyllä, poista kortti",
"96b88e530375237cbc08138d9fb4109c":"Kyllä, poista tämä luettelo",
"fd3780f468c531f2bc1b47b8748b5a66":"Voit luoda kirjanpitokoodit yrityksen asetuksissa.",
"ab3ac6f945d9f756763d49257fd924dc":"Voit hallita koodauksia yrityksen asetuksissa.",
"c919dae93fe780061629c255eb358140":"Et voi poistaa ainoaa tähän tiimiin linkitetty lähdekorttia. Lisää toinen kortti poistaaksesi tämän.",
"11de97e873b66d91a77db7a05da32202":"Voit kannattaa yhtä alla luetelluista tiimin omistajista yrityksen pääkäyttäjäksi tai kutsua uuden käyttäjän.",
"0ab790f08b2780be001b79d2ccd75c4e":"Sinulla ei ole yhtään tälle tilille luotua kirjanpitokoodia.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Sinulla ei ole yhtään luotua koodausluetteloa.",
"44e470218124a50c117870467530752e":"Olet syöttänyt virheellisen sähköpostiosoitteen tai salasanan.",
"4b687d0730822fdbeea27c174e47f227":"Sinulla ei ole hyväksyttyjä hyväksyntöjä.",
"020f03c17b79af66f335b6af868c66c0":"Sinulla ei ole peruutettuja hyväksyntöjä.",
"def0b76f03e7c622341a7d8e08aaaae7":"Sinulla ei ole hylättyjä hyväksyntöjä.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Sinulla ei ole odottavia hyväksyntöjä.",
"7c034d6104f80a523b90bc607fc06608":"Sinulla ei ole lompakossasi virtuaalikortteja. Luo pyyntö",
"0aed9198d61d161468d541dbbc9f477a":"Sinulla on Poissa-asetuksena edelleenlähetys henkilölle {oooForwardToFullName} aikataulutettuna seuraavaan päivämäärään saakka {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Olet saanut uuden virtuaalisen kortin:",
"04eeaf814767c30aa41577f3b11f916a":"Pyysit äskettäin Go-tilisi salasanan palautusta. Tee prosessi loppuun:",
"09ddf249810b9af7d3e1f4e537da500d":"Yritit päästä sivulle, jota ei ole olemassa. Varmista, että sinulla on oikea linkki.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Sinut on kutsuttu liittymään tiimiin",
"02c6f3a7cf31a87f4f39f992509f8c44":"Sinut on lisätty pääkäyttäjänä yrityksellesi",
"cf121a14590f109dafcae138265e353b":"Sinut on lisätty uuteen tiimiin!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Sinut on lisätty tiimiin",
"d3cef66a026f776903b342d450e5a832":"Go-kortin pyyntösi on hylätty",
"003f87c21a29220e661fc302b885fd5a":"Tilisi on poistettu käytöstä. Ota yhteyttä yrityksesi tai tiimisi pääkäyttäjään.",
"a52b1f0da15dce68007f524e48238143":"Pankkisi palautti virheen lisättäessä tätä korttia. Ota yhteyttä pankkiisi ongelman ratkaisemiseksi ja yritä uudelleen.",
"4d883b173167297463aeac86957c26fe":"Sähköpostiosoitettasi on käytetty tilin luomiseen Tradeshift Go:ssa. Sähköpostiosoitteesi varmistaminen ja rekisteröinnin suorittaminen loppuun",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Poissa-asetuksesi on tallennettu.",
"542f08554110a12de0c23afd78f38e0e":"Salasanasi on palautettu. Kirjaudu sisään.",
"49b2dcbe5d461f35c37986962b5bd07a":"Pyyntösi hylättiin",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Olet luettelossa!",
"32f87c5ddeaee35b9178785ef4eb9313":"Postinumero",
"c6974a3d5311374c697625f2a3234bce":"päivämäärä",
"f86980f5ef00b645a6e4a0b59738a954":"henkilö, jota emme löytäneet",
"7066f854917b39bafe0bbecbb8bd6377":"hyväksyy pyynnöt",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":":lle",
"49b171230ab9bcdd8b631c70cf397efb":"ostolle <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"yrityksellesi",
"35ad86f0b035d8156390670d60801e7f":"{purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"{purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"lähettäjä",
"c1407aa5424f339dedf67bcac597cf71":"on asettanut hyväksyntäpyynnöt välitettäväksi sinulle ajaksi",
"67a948b8af3284a401ebefcb1b4723ab":"odottaa hyväksyntääsi",
"9ca64449d64b98fef7859e22bc23c4dc":"tekee pyynnöt",
"ac850ab84428e9eb6a57e5d813de5e45":"johtaa tiimiä",
"2168839a1f4c73ef5c89c9e930148e7d":"jätä tämä sähköposti huomiotta. Mitään toimia ei tarvita",
"85431c325faab98e02daa60151465144":"lähetti sinulle tämän kutsun",
"81c504fd54119e7e580eb05dedef46d0":"lähetti sinulle tämän kutsun",
"d74545060b38445ef4cc49fbb8d79876":"lähetti sinulle tämän kutsun.",
"2d96c53ee7ee0a54aadad19bfce0697d":"haettaessa korttilähteitäsi tapahtui virhe",
"b2fda295788d579fffd3f43b7737fd63":"ilmoittaaksesi yrityskorttisi Go:hun, myöntääksesi työntekijöillesi yksinkertaisen & varman kortinkäytön",
"d35bd5c5d74af765d45cef3c4e78f52a":"tehdäksesi yritysostoja Go-virtuaalikorttia käyttämällä",
"8469a13ddf1cc19a4ebf26356d8ac646":"tehdäksesi yritysostoja Go-virtuaalikorttia käyttämällä",
"9546989e681a7e25fbdfe7631e9ebc03":"tehdäksesi yritysostoja Go-virtuaalikorttia käyttämällä.",
"6174e0a3d26409dd6e410052e2805660":"alkaaksesi käyttää Tradeshift Go -kortteja",
"0bee956ca9aa927e0063c36a300e29e1":"ottaaksesi poissa-ilmoituksen pois päältä",
"fd2c1099fc9f3831d7f24195aaa11269":"sina@yritys.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} on vastuussa pyyntöjen hyväksymisestä muilta tiimin jäseniltä. Määritä uusi hyväksyjä asianomaisille jäsenille jatkaaksesi.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} kutsuttiin ${this.teamId ? '.' : ' tiimiin.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} poistettiin",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} peruutti pyynnön",
"9567a2d4cacd58fb4f096132d26c1660":"{username} on vastuussa pyyntöjen hyväksymisestä muilta tiimin jäseniltä. Määritä uusi hyväksyjä asianomaisille jäsenille jatkaaksesi.",
};
