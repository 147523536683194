const RequestInfoAsideComponent = {
	bindings: {
		isOpen: '<',
		onClose: '&',
		request: '<',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.requestInfoText}}">
			<div class="panel-card">
				<request-card request="$ctrl.request"></request-card>
			</div>
			<ts-form
					on-cancel="$ctrl.onClose()"
					on-submit="$ctrl.viewConversation()"
					submit-label="{{$ctrl.viewConversationText}}">
			</ts-form>
		</ts-aside>
	`,
	controller: /* @ngInject */ function ($state, LexicoService) {
		this.lexico = LexicoService.getLexico();
		this.requestInfoText = this.lexico.trc('Component title', 'Request info');
		this.viewConversationText = this.lexico.trc('Button', 'View conversation');
		this.viewConversation = () => {
			$state.go('main.requests', {
				requestId: this.request.getId(),
			});
		};
	},
};

export default RequestInfoAsideComponent;
