"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePasswordLoginState = void 0;
const react_1 = require("react");
const initialState = {
    username: '',
    firstName: '',
    lastName: '',
    isFormLoading: false,
};
// we might move this into zustand store if it gets too complex
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER_NAME': {
            return { ...state, username: action.payload };
        }
        case 'SET_FIRST_NAME': {
            return { ...state, firstName: action.payload };
        }
        case 'SET_LAST_NAME': {
            return { ...state, lastName: action.payload };
        }
        case 'SET_FORM_LOADING': {
            return { ...state, isFormLoading: action.payload };
        }
        default: {
            return state;
        }
    }
};
const usePasswordLoginState = () => {
    const [state, dispatch] = (0, react_1.useReducer)(reducer, initialState);
    return [state, dispatch];
};
exports.usePasswordLoginState = usePasswordLoginState;
