/* @ngInject */
function eventPersonAdded(UserService) {
	return {
		restrict: 'E',
		template: require('./personAdded.html'),
		link: function ($scope) {
			UserService.getUser().then(function (user) {
				$scope.isAddedUser = $scope.event.author.userId === user.userId;
			});
		},
	};
}

module.exports = eventPersonAdded;
