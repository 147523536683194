"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tsGoComponents = void 0;
const Backdrop_1 = require("@mui/material/Backdrop");
const muiAlertOverrides_1 = require("./muiAlertOverrides");
const muiAlertTitleOverrides_1 = require("./muiAlertTitleOverrides");
const muiAppBarOverrides_1 = require("./muiAppBarOverrides");
const muiAvatarOverrides_1 = require("./muiAvatarOverrides");
const muiButtonOverrides_1 = require("./muiButtonOverrides");
const muiCheckboxOverrides_1 = require("./muiCheckboxOverrides");
const muiContainerOverrides_1 = require("./muiContainerOverrides");
const muiDividerOverrides_1 = require("./muiDividerOverrides");
const muiDrawerOverrides_1 = require("./muiDrawerOverrides");
const muiFormControlLabelOverrides_1 = require("./muiFormControlLabelOverrides");
const muiFormControlOverrides_1 = require("./muiFormControlOverrides");
const muiFormGroupOverrides_1 = require("./muiFormGroupOverrides");
const muiIconButtonOverrides_1 = require("./muiIconButtonOverrides");
const muiIconOverrides_1 = require("./muiIconOverrides");
const muiInputBaseOverrides_1 = require("./muiInputBaseOverrides");
const muiInputLabelOverrides_1 = require("./muiInputLabelOverrides");
const muiLinkOverrides_1 = require("./muiLinkOverrides");
const muiListItemOverrides_1 = require("./muiListItemOverrides");
const muiListOverrides_1 = require("./muiListOverrides");
const muiMenuItemOverrides_1 = require("./muiMenuItemOverrides");
const muiMenuListOverrides_1 = require("./muiMenuListOverrides");
const muiOutlinedInputOverrides_1 = require("./muiOutlinedInputOverrides");
const muiPaperOverrides_1 = require("./muiPaperOverrides");
const muiSelectOverrides_1 = require("./muiSelectOverrides");
const muiSwitchOverrides_1 = require("./muiSwitchOverrides");
const muiTablePaginationOverrides_1 = require("./muiTablePaginationOverrides");
const muiTypographyOverrides_1 = require("./muiTypographyOverrides");
exports.tsGoComponents = {
    MuiButtonBase: {
        defaultProps: {
            // The props to change the default for.
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
    },
    MuiBackdrop: {
        styleOverrides: {
            root: {
                '&[style*="opacity: 0"]': {
                    pointerEvents: 'none',
                },
            },
        },
    },
    MuiModal: {
        styleOverrides: {
            root: {
                [`&:has(> div.${Backdrop_1.backdropClasses.root}[style*="opacity: 0"])`]: {
                    pointerEvents: 'none',
                },
            },
        },
    },
    MuiPaginationItem: {
        styleOverrides: {
            root: {
                color: 'rgb(15, 21, 26) !important',
                backgroundColor: 'inherit !important',
                ['&.Mui-selected']: {
                    fontWeight: '600 !important',
                    color: 'rgb(0, 174, 255) !important',
                },
                ['&.MuiPaginationItem-previousNext:not(.Mui-disabled), &.MuiPaginationItem-firstLast:not(.Mui-disabled)']: {
                    color: 'rgb(0, 174, 255) !important',
                },
            },
        },
    },
    MuiTablePagination: muiTablePaginationOverrides_1.muiTablePaginationOverrides,
    MuiButton: muiButtonOverrides_1.muiButtonOverrides,
    MuiOutlinedInput: muiOutlinedInputOverrides_1.muiOutlinedInputOverrides,
    MuiInputBase: muiInputBaseOverrides_1.muiInputBaseOverrides,
    MuiInputLabel: muiInputLabelOverrides_1.muiInputLabelOverrides,
    MuiFormControl: muiFormControlOverrides_1.muiFormControlOverrides,
    MuiSwitch: muiSwitchOverrides_1.muiSwitchOverrides,
    MuiFormGroup: muiFormGroupOverrides_1.muiFormGroupOverrides,
    MuiFormControlLabel: muiFormControlLabelOverrides_1.muiFormControlLabelOverrides,
    MuiList: muiListOverrides_1.muiListOverrides,
    MuiListItem: muiListItemOverrides_1.muiListItemOverrides,
    MuiAlert: muiAlertOverrides_1.muiAlertOverrides,
    MuiAlertTitle: muiAlertTitleOverrides_1.muiAlertTitleOverrides,
    MuiDrawer: muiDrawerOverrides_1.muiDrawerOverrides,
    MuiTypography: muiTypographyOverrides_1.muiTypographyOverrides,
    MuiSelect: muiSelectOverrides_1.muiSelectOverrides,
    MuiCheckbox: muiCheckboxOverrides_1.muiCheckboxOverrides,
    MuiIconButton: muiIconButtonOverrides_1.muiIconButtonOverrides,
    MuiAvatar: muiAvatarOverrides_1.muiAvatarOverrides,
    MuiMenuItem: muiMenuItemOverrides_1.muiMenuItemOverrides,
    MuiSvgIcon: muiIconOverrides_1.muiIconOverrides,
    MuiAppBar: muiAppBarOverrides_1.muiAppBarOverrides,
    MuiLink: muiLinkOverrides_1.muiLinkOverrides,
    MuiDivider: muiDividerOverrides_1.muiDividerOverrides,
    MuiPaper: muiPaperOverrides_1.muiPaperOverrides,
    MuiContainer: muiContainerOverrides_1.muiContainerOverrides,
    muiMenuListOverrides: muiMenuListOverrides_1.muiMenuListOverrides,
};
