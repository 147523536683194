const _ = require('lodash');

/* @ngInject */
function ConversationController($scope, ConversationService, LexicoService) {
	$scope.conversation = {};
	$scope.lexico = LexicoService.getLexico();
	$scope.addMessageText = $scope.lexico.trc('Field input label', 'Add message');
	$scope.saySomethingText = $scope.lexico.trc('Placeholder text', 'Say something...');
	$scope.sendMessageText = $scope.lexico.trc('Button', 'Send message');

	$scope.addMessage = function (conversationId, message) {
		return ConversationService.addMessage({
			message,
			conversationId,
		})
			.then(function () {
				$scope.conversation.message = '';
			})
			.catch(function () {
				const warningMessage = $scope.lexico.trc('Warning message', 'Could not send your message, please try again.');
				ts.ui.Notification.warning(warningMessage);
			});
	};

	$scope.isValid = function (message) {
		return !_.isEmpty(_.trim(message));
	};
}

module.exports = ConversationController;
