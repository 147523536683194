"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePasswordInput = void 0;
const react_1 = require("react");
const zod_1 = require("zod");
const passwordSchema = zod_1.z
    .string()
    .regex(/^(?=.*[a-z])(?=.*[A-Z]).{10,}$/)
    .min(10);
const initialState = {
    passwordValue: '',
    isPasswordError: false,
    confirmPasswordValue: '',
    isConfirmPasswordError: false,
    isPasswordBlurred: false,
    isConfirmPasswordBlurred: false,
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_PASSWORD': {
            return { ...state, passwordValue: action.payload };
        }
        case 'SET_PASSWORD_ERROR': {
            return { ...state, isPasswordError: action.payload };
        }
        case 'SET_CONFIRM_PASSWORD': {
            return { ...state, confirmPasswordValue: action.payload };
        }
        case 'SET_CONFIRM_PASSWORD_ERROR': {
            return { ...state, isConfirmPasswordError: action.payload };
        }
        case 'SET_PASSWORD_BLURRED': {
            return { ...state, isPasswordBlurred: action.payload };
        }
        case 'SET_CONFIRM_PASSWORD_BLURRED': {
            return { ...state, isConfirmPasswordBlurred: action.payload };
        }
        case 'RESET': {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
const usePasswordInput = () => {
    const [state, dispatch] = (0, react_1.useReducer)(reducer, initialState);
    const handlePasswordValidation = () => {
        dispatch({ type: 'SET_PASSWORD_BLURRED', payload: true });
        if (state.passwordValue.length > 0) {
            const isPasswordError = !passwordSchema.safeParse(state.passwordValue).success;
            dispatch({ type: 'SET_PASSWORD_ERROR', payload: isPasswordError });
        }
        else {
            dispatch({ type: 'SET_PASSWORD_ERROR', payload: false });
        }
    };
    const handleConfirmPasswordValidation = () => {
        dispatch({ type: 'SET_CONFIRM_PASSWORD_BLURRED', payload: true });
        if (state.confirmPasswordValue.length > 0) {
            const isConfirmPasswordError = !passwordSchema.safeParse(state.confirmPasswordValue).success ||
                state.confirmPasswordValue !== state.passwordValue;
            dispatch({ type: 'SET_CONFIRM_PASSWORD_ERROR', payload: isConfirmPasswordError });
        }
        else {
            dispatch({ type: 'SET_CONFIRM_PASSWORD_ERROR', payload: false });
        }
    };
    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        if (name === 'password') {
            dispatch({ type: 'SET_PASSWORD', payload: value });
            dispatch({ type: 'SET_PASSWORD_BLURRED', payload: false });
        }
        else {
            dispatch({ type: 'SET_CONFIRM_PASSWORD', payload: value });
            dispatch({ type: 'SET_CONFIRM_PASSWORD_BLURRED', payload: false });
            const isConfirmPasswordError = !passwordSchema.safeParse(value).success || value !== state.passwordValue;
            dispatch({ type: 'SET_CONFIRM_PASSWORD_ERROR', payload: isConfirmPasswordError });
        }
    };
    const resetPasswordState = () => {
        dispatch({ type: 'RESET' });
    };
    return {
        state,
        handlePasswordChange,
        handlePasswordValidation,
        handleConfirmPasswordValidation,
        resetPasswordState,
    };
};
exports.usePasswordInput = usePasswordInput;
