'use strict';

const validator = {};

validator.validatePurchasePurpose = desc => {
	if (!desc) {
		return true;
	}
	const restrictedStrings = ['"', "'", '/', '<', '>', '&'];
	const isInvalid = restrictedStrings.find(s => desc.includes(s));
	return !isInvalid;
};

validator.getDecimalCount = num => {
	// Convert to String
	const numStr = String(num);
	// String Contains Decimal
	if (numStr.includes('.')) {
		const strArr = numStr.split('.');
		if (strArr.length > 2) {
			return strArr.length;
		}
		return strArr[1].length;
	}
	// String Does Not Contain Decimal
	return 0;
};

validator.validatePurchaseAmount = amount => {
	if (!amount) {
		return true;
	}
	return !isNaN(amount) && Number(amount) > 0 && validator.getDecimalCount(amount) <= 2;
};

module.exports = validator;
