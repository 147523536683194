"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_router_dom_1 = require("react-router-dom");
const appConstants_1 = require("../../../../constants/appConstants");
const strings_1 = require("../../../../constants/strings");
const zustandStore_1 = require("../../../../zustandStore");
const actionsButton_1 = require("../../approvals/components/actionsButton");
const ViewAllApprovalsToggle = () => {
    const initialData = (0, react_router_dom_1.useLoaderData)();
    const { viewAllApprovals, setViewAllApprovals, setActivityFilters, setSearchApprovalsValue, setSortApprovalsValue } = (0, zustandStore_1.useZustand)(state => ({
        viewAllApprovals: state.activity.viewAllApprovals,
        setViewAllApprovals: state.setViewAllApprovals,
        setActivityFilters: state.setActivityFilters,
        setSearchApprovalsValue: state.setSearchApprovalsValue,
        setSortApprovalsValue: state.setSortApprovalsValue,
    }));
    const handleToggle = (_event, checked) => {
        setViewAllApprovals(checked);
        if (checked) {
            setActivityFilters({ team: 'all', member: 'all' });
        }
        else {
            setSortApprovalsValue({ mostRecent: 'desc' });
            setSearchApprovalsValue('');
        }
    };
    return ([appConstants_1.teamAdmin].includes(initialData && 'userRole' in initialData ? initialData.userRole : '') && ((0, jsx_runtime_1.jsx)(material_1.FormGroup, { sx: { p: 0, position: 'fixed', right: '20px' }, children: (0, jsx_runtime_1.jsx)(actionsButton_1.ApprovalToggleLabel, { name: 'view-all-approvals', control: (0, jsx_runtime_1.jsx)(material_1.Switch, { color: "primary", checked: viewAllApprovals, onChange: (event, checked) => handleToggle(event, checked) }), label: strings_1.approvalsStrings.VIEW_ALL_APPROVALS_TEXT, labelPlacement: "start" }) })));
};
exports.default = ViewAllApprovalsToggle;
