const _ = require('lodash');

/* @ngInject */
function CardDetailController(
	$scope,
	$state,
	_approvers,
	_cardSource,
	_manageHeader,
	_users,
	ApproversTable,
	HeaderService,
	PaymentsService,
) {
	let _allUsers;
	const openConfirmRemoveApprover = ({ approver }) => {
		const cardSourceName = $scope.card.getCardSourceName();
		$scope.asides.confirmRemoveApprover = Object.assign({}, $scope.asides.confirmRemoveApprover, {
			approverId: approver.userId,
			isOpen: true,
			message:
				`${approver.fullName} will no longer be able to create virtual cards using '${cardSourceName}'. ` +
				'Are you sure you would like to continue?',
			onConfirm: () => {
				PaymentsService.removeApprover({
					cardSourceId: $scope.card.getCardSourceId(),
					userId: approver.userId,
				})
					.then(() => {
						_.remove($scope.approvers, approver);
						$scope.nonApprovers = getNonApprovers(_allUsers, $scope.approvers);
						$scope.table.setApprovers($scope.approvers);
						ts.ui.Notification.success(`Removed ${approver.fullName} as an approver.`);
					})
					.catch(() => {
						ts.ui.Notification.warning('There was a problem removing the approver.');
					})
					.finally(() => {
						$scope.asides.confirmRemoveApprover.isOpen = false;
					});
			},
		});
	};
	$scope.onAsideClosed = key => {
		$scope.asides[key].isOpen = false;
	};
	$scope.$on('$destroy', () => {
		_manageHeader.create();
	});
	function getNonApprovers(allUsers, approvers) {
		return _.differenceBy(allUsers, approvers, ({ userId }) => userId);
	}

	(function init() {
		$scope.approvers = _approvers;
		$scope.card = _cardSource;
		$scope.asides = {
			confirmRemoveApprover: {
				isOpen: false,
			},
		};
		_allUsers = _users;
		$scope.nonApprovers = getNonApprovers(_allUsers, $scope.approvers);
		$scope.table = ApproversTable({
			approvers: _approvers,
			openConfirmRemoveApprover,
		});
		HeaderService.create({
			title: $scope.card.getCardSourceName(),
			type: 'lite',
			buttons: [
				{
					icon: 'ts-icon-close',
					type: 'ts-secondary go-close-modal',
					onclick: () => {
						$state.go('main.manage.payments');
					},
				},
			],
		});
	})();
}

CardDetailController.resolve = {
	_approvers: /* @ngInject */ ($transition$, PaymentsService) => {
		const { cardSourceId } = $transition$.params();
		return PaymentsService.getApproversForCard({ cardSourceId });
	},
	_cardSource: /* @ngInject */ ($transition$, Card, PaymentsService) => {
		const { cardSourceId } = $transition$.params();
		return PaymentsService.getCardSource({ cardSourceId }).then(cardSource => new Card(cardSource));
	},
	_users: /* @ngInject */ UserService => UserService.getUsers({ limit: 150 }).then(result => result.users),
};

module.exports = CardDetailController;
