"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.teamsCodingLoader = void 0;
const react_router_dom_1 = require("react-router-dom");
const codingQuery_1 = require("../../../queries/codingQuery");
const companyQuery_1 = require("../../../queries/companyQuery");
const teamQuery_1 = require("../../../queries/teamQuery");
const userQuery_1 = require("../../../queries/userQuery");
const stopAngularjsLoader_1 = require("../stopAngularjsLoader");
const teamsCodingLoader = async ({ queryClient }) => {
    const user = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const companyPropertiesData = await queryClient.ensureQueryData((0, companyQuery_1.companyPropertiesQuery)(user?.companyAccountId, { cardprovider: 'tsgo-cardprovider' }));
    const isHsbcCardProvider = companyPropertiesData?.cardprovider === 'HSBC';
    if (isHsbcCardProvider) {
        // we prevent the user from accessing the page if the card provider is HSBC
        (0, stopAngularjsLoader_1.stopAngularJsLoader)();
        return (0, react_router_dom_1.redirect)('/react/error');
    }
    const teamIdQuery = (0, teamQuery_1.selectedTeamIdQuery)('selectedTeamID');
    const teamId = await queryClient.ensureQueryData(teamIdQuery);
    const codingLists = await queryClient.ensureQueryData((0, codingQuery_1.getCodingListsQuery)({ skipDisabled: true }));
    const codingEntries = await queryClient.ensureQueryData((0, codingQuery_1.getCodingListsEntriesQuery)({
        codingLists,
    }));
    (0, stopAngularjsLoader_1.stopAngularJsLoader)();
    return {
        teamId,
        codingLists,
        codingEntries,
        team: await queryClient.ensureQueryData((0, teamQuery_1.teamQuery)({ teamId: teamId?.[0] || '' })),
        teams: await queryClient.ensureQueryData((0, teamQuery_1.teamsQuery)({ state: 'active' })),
    };
};
exports.teamsCodingLoader = teamsCodingLoader;
