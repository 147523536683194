"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cardSourceInitialState = exports.getCardSource = exports.cardNameText = void 0;
exports.cardSourceSlice = cardSourceSlice;
exports.cardNameText = 'name';
const getCardSource = (user) => ({
    address: '',
    cardNumber: '',
    city: '',
    countryCode: 'US',
    email: user?.email || '',
    expirationMonth: '',
    expirationYear: '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    name: '',
    orgName: user?.companyName || '',
    regionCode: '',
    userId: user?.userId || '',
    zipCode: '',
    billingCurrency: 'USD',
});
exports.getCardSource = getCardSource;
exports.cardSourceInitialState = {
    cardSource: (0, exports.getCardSource)(),
    cardSourceErrors: null,
};
function cardSourceSlice(set) {
    return {
        ...exports.cardSourceInitialState,
        cardSourceReset(user) {
            set(state => {
                state.cardSource = (0, exports.getCardSource)(user);
                return state;
            }, false, '[cardSourceReset]');
        },
        cardSourceSetAddress(address) {
            set(state => {
                state.cardSource.address = address;
                return state;
            }, false, '[setAddress]');
        },
        cardSourceSetCardNumber(cardNumber) {
            set(state => {
                state.cardSource.cardNumber = cardNumber;
                return state;
            }, false, '[setCardNumber]');
        },
        cardSourceSetCity(city) {
            set(state => {
                state.cardSource.city = city;
                return state;
            }, false, '[setCity]');
        },
        cardSourceSetCountryCode(countryCode) {
            set(state => {
                state.cardSource.countryCode = countryCode;
                return state;
            }, false, '[setCountryCode]');
        },
        cardSourceSetEmail(email) {
            set(state => {
                state.cardSource.email = email;
                return state;
            }, false, '[setEmail]');
        },
        cardSourceSetExpirationMonth(expirationMonth) {
            set(state => {
                state.cardSource.expirationMonth = expirationMonth;
                return state;
            }, false, '[setExpirationMonth]');
        },
        cardSourceSetExpirationYear(expirationYear) {
            set(state => {
                state.cardSource.expirationYear = expirationYear;
                return state;
            }, false, '[setExpirationYear]');
        },
        cardSourceSetFirstName(firstName) {
            set(state => {
                state.cardSource.firstName = firstName;
                return state;
            }, false, '[setFirstName]');
        },
        cardSourceSetLastName(lastName) {
            set(state => {
                state.cardSource.lastName = lastName;
                return state;
            }, false, '[setLastName]');
        },
        cardSourceSetName(name) {
            set(state => {
                state.cardSource.name = name;
                return state;
            }, false, '[setName]');
        },
        cardSourceSetOrgName(orgName) {
            set(state => {
                state.cardSource.orgName = orgName;
                return state;
            }, false, '[setOrgName]');
        },
        cardSourceSetRegionCode(regionCode) {
            set(state => {
                state.cardSource.regionCode = regionCode;
                return state;
            }, false, '[setRegionCode]');
        },
        cardSourceSetUserId(userId) {
            set(state => {
                state.cardSource.userId = userId;
                return state;
            }, false, '[setUserId]');
        },
        cardSourceSetZipCode(zipCode) {
            set(state => {
                state.cardSource.zipCode = zipCode;
                return state;
            }, false, '[setZipCode]');
        },
        cardSourceSetErrors(cardSourceErrors) {
            set(state => {
                state.cardSourceErrors = cardSourceErrors;
                return state;
            }, false, '[cardSourceSetErrors]');
        },
    };
}
