"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageContainer = exports.StyledErrorTypo = exports.StyledSuccessTypo = exports.StyledListItem = exports.StyledUploadBtnLabel = exports.TypographyFieldData = exports.TypographyFieldColumn = exports.TypographyTitle = exports.TypographyText = exports.TypographySettlement = exports.TypographyValue = exports.TypographyLabel = exports.DetailItem = exports.SpendDetails = exports.CardDetails = exports.StyledContainer = exports.WalletDetailsWrappper = void 0;
const material_1 = require("@mui/material");
exports.WalletDetailsWrappper = (0, material_1.styled)(material_1.Box)(() => ({
    display: 'flex',
    flexFlow: 'row wrap',
}));
exports.StyledContainer = (0, material_1.styled)(material_1.Container)(({ theme }) => ({
    paddingTop: theme.tsGoUnit.tsUnit,
    paddingBottom: theme.tsGoUnit.tsUnit,
    overflow: 'auto',
    height: '100vh',
    backgroundColor: theme.palette.tsGoColor.grayLightest,
    maxWidth: '100% !important',
}));
exports.CardDetails = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    marginLeft: theme.tsGoUnit.tsUnit,
}));
exports.SpendDetails = (0, material_1.styled)(material_1.Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.tsGoUnit.tsUnitHalf,
    gap: theme.tsGoUnit.tsUnitHalf,
}));
exports.DetailItem = (0, material_1.styled)(material_1.Grid)(() => ({
    flexGrow: '1',
    '&:last-of-type': {
        textAlign: 'right',
    },
    '&:nth-of-type(2)': {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
    },
}));
exports.TypographyLabel = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
    fontWeight: theme.typography.tsFontWeight.bold,
    textTransform: 'uppercase',
    ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? {
            fontSize: '12px',
            fontWeight: 400,
            color: '#000000',
            textTransform: 'none',
        }
        : {}),
}));
exports.TypographyValue = (0, material_1.styled)(material_1.Typography)(ownerState => {
    const { color, theme } = ownerState;
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.xbig),
        fontWeight: theme.typography.tsFontWeight.bold,
        color: color === 'red'
            ? theme.palette.tsGoColor.red
            : color === 'green'
                ? window.cardProvider.IS_HSBC_PROVIDER_SETUP
                    ? theme.palette.hsbcColor.green
                    : theme.palette.tsGoColor.green
                : 'inherit',
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontSize: '16px',
                fontWeight: 700,
                color: color === 'red' ? theme.palette.tsGoColor.red : color === 'green' ? theme.palette.hsbcColor.green : 'black',
            }
            : {}),
    };
});
exports.TypographySettlement = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    padding: `${theme.tsGoUnit.tsUnitHalf} 0`,
    ':after': {
        display: 'table',
        clear: 'both',
        content: '""',
    },
}));
exports.TypographyText = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    float: 'right',
    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
    marginTop: theme.tsGoUnit.tsUnitHalf,
}));
exports.TypographyTitle = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    float: 'left',
    marginTop: theme.tsGoUnit.tsUnitHalf,
}));
exports.TypographyFieldColumn = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    width: '100%',
    padding: theme.tsGoUnit.tsUnitHalf,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    fontSize: window.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? theme.typography.tsFontSize.mini
        : theme.typography.pxToRem(theme.typography.tsFontSize.small),
    fontWeight: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 500 : theme.typography.tsFontWeight.semiBold,
    textTransform: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'none' : 'uppercase',
}));
exports.TypographyFieldData = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    paddingLeft: '10px',
    fontSize: theme.typography.tsFontSize.default,
}));
exports.StyledUploadBtnLabel = (0, material_1.styled)(material_1.FormLabel)(ownerState => {
    const { 'ts-variant': tsVariant, theme } = ownerState;
    return {
        background: theme.palette.tsGoColor.white,
        border: `1px solid ${theme.palette.tsGoColor.gray}`,
        borderRadius: '3px',
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        fontStyle: 'italic',
        height: theme.tsGoUnit.tsUnitOneAndHalf,
        lineHeight: theme.tsGoUnit.tsUnitOneAndHalf,
        paddingLeft: theme.tsGoUnit.tsUnitHalf,
        position: 'absolute',
        top: theme.tsGoUnit.tsUnitDouble,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 'calc(100% - 20px)',
        '&:hover': {
            cursor: 'pointer',
        },
        '&:after': {
            background: theme.palette.tsGoColor.blue,
            borderRadius: theme.tsGoUnit.tsUnitHalf,
            color: theme.palette.tsGoColor.white,
            content: '"Upload"',
            fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
            fontStyle: 'normal',
            height: theme.tsGoUnit.tsUnit,
            lineHeight: theme.tsGoUnit.tsUnit,
            padding: `0 ${theme.tsGoUnit.tsUnitHalf}`,
            position: 'absolute',
            right: theme.tsGoUnit.tsUnitQuarter,
            textTransform: 'uppercase',
            top: theme.tsGoUnit.tsUnitQuarter,
            ...(tsVariant === 'uploading' && {
                '@-webkit-keyframes move-progress-bar': {
                    '0%': {
                        backgroundPosition: '0 0',
                    },
                    '100%': {
                        backgroundPosition: `${theme.tsGoUnit.tsUnitDouble} ${theme.tsGoUnit.tsUnitDouble}`,
                    },
                },
                '@keyframes move-progress-bar': {
                    '0%': {
                        backgroundPosition: '0 0',
                    },
                    '100%': {
                        backgroundPosition: `${theme.tsGoUnit.tsUnitDouble} ${theme.tsGoUnit.tsUnitDouble}`,
                    },
                },
                background: 0,
                backgroundImage: 'linear-gradient(-45deg, hsla(198, 20%, 67%, 0.3) 25%, hsla(198, 20%, 83%, 0.3) 25%, hsla(198, 20%, 83%, 0.3) 50%, hsla(198, 20%, 67%, 0.3) 50%, hsla(198, 20%, 67%, 0.3) 75%, hsla(198, 20%, 83%, 0.3) 75%, hsla(198, 20%, 83%, 0.3))',
                animation: 'move-progress-bar 1s linear infinite',
                backgroundSize: `${theme.tsGoUnit.tsUnitDouble} ${theme.tsGoUnit.tsUnitDouble}`,
                color: 'rgba(0, 0, 0, 0)',
            }),
        },
        ...(tsVariant === 'uploading' && {
            '&:before': {
                content: '"Uploading..."',
            },
        }),
    };
});
exports.StyledListItem = (0, material_1.styled)(material_1.ListItem)(ownerState => {
    const { 'ts-variant': tsVariant, theme } = ownerState;
    return {
        paddingBottom: 0,
        marginLeft: '1px',
        display: 'list-item',
        ...(tsVariant === 'error' && {
            color: theme.palette.tsGoColor.red,
        }),
    };
});
exports.StyledSuccessTypo = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    color: theme.palette.tsGoColor.black,
    fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.mini)} !important`,
    fontWeight: theme.typography.tsFontWeight.bold,
    margin: `${theme.tsGoUnit.tsUnitHalf} 0 0 ${theme.tsGoUnit.tsUnitQuarter}`,
    position: 'relative',
    paddingLeft: '17px',
    '&:before': {
        color: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? theme.palette.hsbcColor.green : theme.palette.tsGoColor.green,
        content: '"3"',
        fontFamily: "'Tradeshifticons', fantasy",
        fontSize: theme.typography.tsFontSize.medium,
        fontWeight: theme.typography.tsFontWeight.default,
        left: '0',
        top: '13px',
        lineHeight: '0',
        position: 'absolute',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'none',
    },
}));
exports.StyledErrorTypo = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.mini)} !important`,
    fontWeight: theme.typography.tsFontWeight.bold,
    margin: `${theme.tsGoUnit.tsUnitHalf} 0 0 ${theme.tsGoUnit.tsUnitQuarter}`,
    position: 'relative',
    color: theme.palette.tsGoColor.red,
    display: 'inline-flex',
}));
exports.ImageContainer = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    backgroundColor: '#515659',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    boxShadow: `inset 0px ${theme.tsGoUnit.tsUnit} 2px -18px rgba(0, 0, 0, 0.3)`,
}));
