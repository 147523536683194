const TSAsideDirective = () => ({
	restrict: 'E',
	transclude: true,
	replace: true,
	template: `
		<aside data-ts="Aside">
			<div data-ts="Panel">
				<ng-transclude ng-show="!isLoading"></ng-transclude>
			</div>
		</aside>
	`,
	link($scope, $element, $attrs) {
		const appElement = document.querySelectorAll('[ng-app]')[0];
		const asideElement = $element[0];
		appElement.appendChild(asideElement);

		$scope.$watch($attrs.isOpen, isOpen => {
			$element.ready(() => {
				$element.attr('data-ts.open', isOpen ? 'true' : 'false');
			});
		});
		$scope.$watch($attrs.isLoading, isLoading => {
			$scope.isLoading = isLoading;
			$element.ready(() => {
				$element.attr('data-ts.busy', isLoading ? 'Loading...' : 'false');
			});
		});

		$attrs.$observe('title', () => {
			$element.attr('data-ts.title', $element.attr('title'));
		});

		$element.on(ts.ui.EVENT_ASIDE_WILL_OPEN, () => {
			$scope.$evalAsync();
		});
		$element.on(ts.ui.EVENT_ASIDE_DID_CLOSE, () => {
			$scope.$evalAsync(() => {
				$scope.$eval($attrs.onClose);
			});
		});

		$scope.$on('$destroy', () => {
			asideElement.remove();
		});
	},
});

export default TSAsideDirective;
