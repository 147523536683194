function progressBar() {
	return {
		restrict: 'E',
		scope: {
			percentage: '=',
		},
		template: require('./progressBar.html'),
	};
}

module.exports = progressBar;
