const _ = require('lodash');
const Sentry = require('@sentry/browser');

const IS_SENTRY_ENABLED = window.config && window.config.IS_SENTRY_ENABLED;
const IS_PRODUCTION = window.config && window.config.IS_PRODUCTION;
const SENTRY_PUBLIC_DSN = window.config && window.config.SENTRY_PUBLIC_DSN;
const RAVEN_CONFIG = window.config && window.config.RAVEN_CONFIG;

const errorReporter = {};

function _captureException(options) {
	let exception = options.exception;

	if (exception.status < 500) {
		return;
	}

	if (errorReporter._isIgnoredTransition(exception)) {
		return;
	}

	if (!(exception instanceof Error)) {
		// This handles when someone does:
		//   throw "something awesome";
		// We synthesize an Error here so we can extract a (rough) stack trace.
		exception = new Error(exception);
	}

	Sentry.captureException(exception, {
		extra: {
			cause: options.cause,
		},
		tags: {
			requestId: _.get(exception, 'headers[X-Tradeshift-RequestId]'),
		},
	});
}

errorReporter._isIgnoredTransition = exception => {
	// If this is a transition error that was superseded by another transition
	// or was simply ignored, don't log it in sentry
	// https://ui-router.github.io/ng1/docs/latest/enums/transition.rejecttype.html
	if (exception.toString().includes('Transition Rejection')) {
		const { type } = exception;
		const IGNORED_TRANSITION = 5;
		const SUPERSEDED_TRANSITION = 2;
		if (type === IGNORED_TRANSITION || type === SUPERSEDED_TRANSITION) {
			return true;
		}
	}
	return false;
};

errorReporter.decorateAngularServices = function ($provide) {
	if (IS_SENTRY_ENABLED) {
		$provide.decorator('$exceptionHandler', [
			'$delegate',
			function ($delegate) {
				return function (exception, cause) {
					_captureException({
						cause,
						exception,
					});
					if (!IS_PRODUCTION) {
						$delegate(exception, cause);
					}
				};
			},
		]);
		$provide.decorator('$q', [
			'$delegate',
			function ($delegate) {
				const originalCatch = $delegate.prototype.catch;
				$delegate.prototype.catch = function (callback) {
					return originalCatch.call(this, function (error) {
						if (!error.isCancelled) {
							_captureException({
								exception: error,
							});
							if (!IS_PRODUCTION) {
								console.log('$q.catch', error);
							}
						}
						return callback(error);
					});
				};
				return $delegate;
			},
		]);
	}
};

(function init() {
	if (IS_SENTRY_ENABLED) {
		Sentry.init({
			dsn: SENTRY_PUBLIC_DSN,
			...RAVEN_CONFIG,
		});
	}
})();

module.exports = errorReporter;
