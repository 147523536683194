const _ = require('lodash');

/* @ngInject */
function ApprovalListFactory($q, ApprovalService) {
	function ApprovalList(key) {
		this._key = key;
		this._items = [];
		this._itemsByTeam = [];
		this._itemsByAssignee = [];
	}

	ApprovalList.prototype._fetchItems = function () {
		const that = this;
		return ApprovalService.getApprovals(this._key).then(function (results) {
			const approvals = results.approvals;
			that._itemCount = _.size(approvals) || 0;
			return approvals;
		});
	};
	ApprovalList.prototype._getLoadedCount = function () {
		return _.size(this._items);
	};
	ApprovalList.prototype._isItemInList = function (approvalId) {
		return _.some(this._items, function (item) {
			return item.getId() === approvalId;
		});
	};

	ApprovalList.prototype.addItem = function (approval, doUnshift) {
		const approvalId = approval.getId();
		if (!this._isItemInList(approvalId)) {
			const teamId = approval.getTeamId();
			const assignees = approval._getAssignees();
			if (teamId && this._itemsByTeam[teamId] === undefined) {
				this._itemsByTeam[teamId] = [];
			}
			if (doUnshift) {
				this._items.unshift(approval);
				teamId && this._itemsByTeam[teamId].unshift(approval);
				assignees.forEach(assignee => {
					if (this._itemsByAssignee[assignee]) {
						this._itemsByAssignee[assignee].unshift(approval);
					} else {
						this._itemsByAssignee[assignee] = [approval];
					}
				});
			} else {
				this._items.push(approval);
				teamId && this._itemsByTeam[teamId].push(approval);
				assignees.forEach(assignee => {
					if (this._itemsByAssignee[assignee]) {
						this._itemsByAssignee[assignee].push(approval);
					} else {
						this._itemsByAssignee[assignee] = [approval];
					}
				});
			}
		}
	};

	ApprovalList.prototype.getByAssignee = function (assigneeId) {
		// assigneeId can be any security group ID (userId, teamId, companyId)
		return this._itemsByAssignee[assigneeId] || [];
	};
	ApprovalList.prototype.getCount = function () {
		return _.size(this._items);
	};
	ApprovalList.prototype.getCountForAssigneeAndTeam = function (userId, teamId) {
		// NO SONAR
		const teamApprovals = this._itemsByTeam[teamId] || [];
		const filteredApprovals = teamApprovals.filter(approval => approval._getAssignees().includes(userId));
		return _.size(filteredApprovals);
	};
	ApprovalList.prototype.getCountForTeam = function (teamId) {
		// NO SONAR
		if (this._itemsByTeam[teamId] === undefined) {
			return 0;
		}
		return _.size(this._itemsByTeam[teamId]);
	};
	ApprovalList.prototype.getCurrentItems = function () {
		return this._items.slice();
	};
	ApprovalList.prototype.getLabel = function () {
		const count = this.getCount();
		return ['Approvals (', count, ')'].join('');
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	ApprovalList.prototype.getLabelForUserAssigneeByTeamId = function (userId, teamId) {
		const count = this.getCountForAssigneeAndTeam(...arguments);
		return ['Approvals (', count, ')'].join('');
	};
	ApprovalList.prototype.getLabelForTeam = function (teamId) {
		const count = this.getCountForTeam(teamId);
		return ['Approvals (', count, ')'].join('');
	};
	ApprovalList.prototype.load = function () {
		return this._getLoadedCount() === 0 && !this._itemCount ? this._fetchItems() : $q;
	};
	ApprovalList.prototype.getCount = function () {
		return _.size(this._items);
	};
	ApprovalList.prototype.getItems = function () {
		return this._items;
	};
	ApprovalList.prototype.getKey = function () {
		return this._key;
	};
	ApprovalList.prototype.removeItem = function (approvalId) {
		if (!this._isItemInList(approvalId)) {
			return;
		}
		const [removedItem] = _.remove(this._items, item => {
			return item.getId() === approvalId;
		});
		if (removedItem.getTeamId()) {
			_.remove(this._itemsByTeam[removedItem.getTeamId()], item => {
				return item.getId() === approvalId;
			});
		}

		return [removedItem];
	};

	return ApprovalList;
}

module.exports = ApprovalListFactory;
