function cardInfo() {
	return {
		restrict: 'E',
		scope: {
			card: '=',
			settlements: '=',
		},
		template: require('./cardInfo.html'),
		controller: 'CardInfoController',
	};
}

module.exports = cardInfo;
