const TSModalComponent = {
	bindings: {
		buttons: '<',
		isOpen: '<',
		onClose: '&',
		title: '@',
	},
	transclude: true,
	template: `
		<dialog
				data-ts="Modal"
				data-ts.title="{{ $ctrl.title }}"
				is-loading="$ctrl.isLoading">
			<div
					class="modal-panel"
					data-ts="Panel"
					ng-transclude></div>
		</dialog>
	`,
	controller: /* @ngInject */ function ($element, $transitions) {
		let dialog;

		this.$onInit = () => {
			ts.ui.get($element.find('dialog')[0], _dialog_ => {
				dialog = _dialog_;

				$transitions.onStart({}, () => {
					if (dialog.client) {
						dialog.close();
					}
				});

				dialog.onclose = async () => {
					this.onClose();
				};

				if (this.buttons) {
					dialog.buttons(this.buttons);
				}
			});
		};

		this.$onChanges = changes => {
			if (changes.isOpen && dialog) {
				if (changes.isOpen.currentValue) {
					dialog.open();
				} else {
					dialog.close();
				}
			}

			if (changes.buttons && dialog) {
				// dialog doesn't exist until the modal is open but changes will fire before that
				dialog.buttons(changes.buttons.currentValue);
			}
		};
	},
};

export default TSModalComponent;
