/* @ngInject */
function DomainWhitelistingService(HttpService, UserService) {
	const service = {};

	service.addWhitelistedDomain = newDomain => {
		return UserService.getUser().then(({ companyAccountId }) => {
			return HttpService.tradeshiftGo()
				.path(`/rest/company/${companyAccountId}/whitelisteddomains`)
				.post({ newDomain });
		});
	};

	service.deleteWhitelistedDomain = domain => {
		return UserService.getUser().then(({ companyAccountId }) => {
			return HttpService.tradeshiftGo().path(`/rest/company/${companyAccountId}/whitelisteddomains/${domain}`).delete();
		});
	};

	service.getWhitelistedDomains = () => {
		return UserService.getUser().then(({ companyAccountId }) => {
			return HttpService.tradeshiftGo().path(`/rest/company/${companyAccountId}/whitelisteddomains/`).get();
		});
	};

	return service;
}

module.exports = DomainWhitelistingService;
