"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTeamsState = exports.useCodingListState = exports.useAsideState = exports.usePaymentState = void 0;
const zustandStore_1 = require("../../../../../zustandStore");
const usePaymentState = () => {
    const { teamCards, cards, card, cardSourceTeams } = (0, zustandStore_1.useZustand)(state => state.payment);
    return { teamCards, cards, card, cardSourceTeams };
};
exports.usePaymentState = usePaymentState;
const useAsideState = () => {
    const { cardSourceDetail, selectTeams } = (0, zustandStore_1.useZustand)(state => state.aside);
    return { cardSourceDetail, selectTeams };
};
exports.useAsideState = useAsideState;
const useCodingListState = () => {
    const { currentCodingListSelected, selectedCodingEntries } = (0, zustandStore_1.useZustand)(state => state.codingList);
    return { currentCodingListSelected, selectedCodingEntries };
};
exports.useCodingListState = useCodingListState;
const useTeamsState = () => {
    const { company, team, teamName, currentTeam, setCompanyProperty, setUser, setCompanyName, setCompanyId, setHeaderName, setTeam, setTeamName, setNotification, setInitialTeamName, setCanForwardApprovals, setTeamId, setIsCardSourceDetailOpen, setIsAddSourceCardOpen, setIsSelectCardSourceOpen, cardSourceSetErrors, setFooterButtons, setIsSelectTeamsOpen, setSelectedCodingEntries, } = (0, zustandStore_1.useZustand)(state => ({
        setUser: state.setUser,
        setCompanyName: state.setCompanyName,
        setCompanyId: state.setCompanyId,
        setHeaderName: state.setHeaderName,
        setTeam: state.setTeam,
        setTeamName: state.setTeamName,
        setNotification: state.setNotification,
        setInitialTeamName: state.setInitialTeamName,
        setCompanyProperty: state.setCompanyProperty,
        setCanForwardApprovals: state.setCanForwardApprovals,
        setTeamId: state.setTeamId,
        setIsCardSourceDetailOpen: state.setIsCardSourceDetailOpen,
        setIsAddSourceCardOpen: state.setIsAddSourceCardOpen,
        setIsSelectCardSourceOpen: state.setIsSelectCardSourceOpen,
        cardSourceSetErrors: state.cardSourceSetErrors,
        setFooterButtons: state.setFooterButtons,
        setIsSelectTeamsOpen: state.setIsSelectTeamsOpen,
        setSelectedCodingEntries: state.setSelectedCodingEntries,
        company: state.company,
        team: state.team.team,
        teamName: state.team.teamName,
        currentTeam: state.team,
    }));
    const { teamCards, cards, card, cardSourceTeams } = (0, exports.usePaymentState)();
    const { cardSourceDetail, selectTeams } = (0, exports.useAsideState)();
    const { currentCodingListSelected, selectedCodingEntries } = (0, exports.useCodingListState)();
    return {
        company,
        team,
        teamName,
        currentTeam,
        setCompanyProperty,
        setUser,
        setCompanyName,
        setCompanyId,
        setHeaderName,
        setTeam,
        setTeamName,
        setNotification,
        setInitialTeamName,
        setCanForwardApprovals,
        setTeamId,
        setIsCardSourceDetailOpen,
        setIsAddSourceCardOpen,
        setIsSelectCardSourceOpen,
        cardSourceSetErrors,
        setFooterButtons,
        setIsSelectTeamsOpen,
        setSelectedCodingEntries,
        teamCards,
        cards,
        card,
        cardSourceTeams,
        cardSourceDetail,
        selectTeams,
        currentCodingListSelected,
        selectedCodingEntries,
    };
};
exports.useTeamsState = useTeamsState;
