/* @ngInject */
function ReassignRequestService($q, ApprovalLists, ConfigService, HttpService, UserServiceV2) {
	const service = {};

	service.reassignRequest = ({ assignee, approval, previousAssignee, reason }) => {
		const requestId = approval.getRequestId();
		const taskId = approval.getId();
		const assigneeId = assignee.id || assignee.userId;
		const getAssigneePromise = UserServiceV2.getUserById(assigneeId);
		const reassignRequestPromise = HttpService.tradeshiftGo()
			.path(`/rest/requests/${requestId}/reassign/${assigneeId}`)
			.post({ previousAssignee, reason, taskId });
		return $q.all([getAssigneePromise, reassignRequestPromise]).then(([reassignedToUser, { reassignedOn }]) => {
			const TASK_OUTCOME_REASSIGNED = ConfigService.get('TASK_OUTCOMES').reassigned;
			ApprovalLists.completeApproval(approval.getId(), TASK_OUTCOME_REASSIGNED, reassignedToUser, reassignedOn);
		});
	};

	return service;
}

module.exports = ReassignRequestService;
