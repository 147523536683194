"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportLoader = void 0;
const supportQuery_1 = require("../../../queries/supportQuery");
const supportLoader = async ({ queryClient }) => {
    const supportUrlQuery = (0, supportQuery_1.getSupportUrlQuery)();
    return {
        supportUrl: await queryClient.ensureQueryData({
            queryKey: supportUrlQuery.queryKey,
            queryFn: supportUrlQuery.queryFn,
        }),
    };
};
exports.supportLoader = supportLoader;
