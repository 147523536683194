const _ = require('lodash');

const RoleServiceV2 = /* @ngInject */ (constants, RoleService, TeamsService, UserServiceV2) => {
	const service = _.clone(RoleService);
	const roles = constants.roles;
	const { companyAdmin: COMPANY_ADMIN, tradeshiftSupportAdmin: TRADESHIFT_SUPPORT_ADMIN } = roles;

	const getRoleById = id => _.find(roles, { id });

	service.getRoleForUser = (user, teamId) => {
		const currentRole = user.roles.find(role => role.securityGroupId === teamId);
		return getRoleById(currentRole.roleId);
	};

	service.getCurrentRoleForUser = async (user, teamId) => {
		if (UserServiceV2.isCompanyAdmin(user)) {
			return COMPANY_ADMIN;
		} else if (UserServiceV2.isSupportAdmin(user)) {
			return TRADESHIFT_SUPPORT_ADMIN;
		}
		if (!teamId) {
			const currentTeam = await TeamsService.getCurrentTeam();
			teamId = currentTeam.id;
		}

		return service.getRoleForUser(user, teamId);
	};

	return service;
};

module.exports = RoleServiceV2;
