import angular from 'angular';

import MainController from './MainController';
import MainHelper from './MainHelper';
import ReconnectHelper from './ReconnectHelper';

import './main.less';

export default angular
	.module('go.main', [])
	.controller('MainController', MainController)
	.service('MainHelper', MainHelper)
	.service('ReconnectHelper', ReconnectHelper).name;
