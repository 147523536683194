function eventConversationMessage() {
	return {
		restrict: 'E',
		template: require('./conversationMessage.html'),
		link: function ($scope) {
			$scope.message = $scope.event.data.body.data.message;
		},
	};
}

module.exports = eventConversationMessage;
