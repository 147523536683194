const _ = require('lodash');

function ApproversTable() {
	const columnIndexes = { ACTION: 2 };
	const factory = ({ approvers, openConfirmRemoveApprover }) => {
		this.approvers = _.cloneDeep(approvers);
		const columns = [
			{ label: 'Name', width: ts.ui.UNIT * 0.4, editable: false },
			{ label: 'Email', width: ts.ui.UNIT * 0.8, editable: false },
			{ label: 'Actions', width: ts.ui.UNIT * 0.4, editable: false },
		];
		const table = {
			columns,
			onCellClick: ({ columnIndex, rowIndex }) => {
				if (columnIndex !== columnIndexes.ACTION) {
					return;
				}
				const approver = this.approvers[rowIndex];
				openConfirmRemoveApprover({ approver });
			},
			setApprovers: newApprovers => {
				this.approvers = _.cloneDeep(newApprovers);
				initRows();
			},
		};
		const initRows = () => {
			table.rows = this.approvers.map(({ email, fullName }) => [
				fullName,
				email,
				{
					item: 'Button',
					label: 'Remove',
					name: 'remove',
					type: 'ts-micro',
				},
			]);
		};

		initRows();
		return table;
	};

	return factory;
}

module.exports = ApproversTable;
