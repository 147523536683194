"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTableData = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_query_1 = require("@tanstack/react-query");
const react_router_dom_1 = require("react-router-dom");
const companyQuery_1 = require("../../../../queries/companyQuery");
const userQuery_1 = require("../../../../queries/userQuery");
const dateUtils_1 = require("../../../../utils/dateUtils");
const zustandStore_1 = require("../../../../zustandStore");
const walletDetails_1 = require("../component/walletDetails");
const useGetTableData = () => {
    const initialData = (0, react_router_dom_1.useLoaderData)();
    const { data: currentUser } = (0, react_query_1.useQuery)({
        ...userQuery_1.userQuery,
        initialData: initialData.userData,
    });
    const { data: companyFeatures } = (0, react_query_1.useQuery)(companyQuery_1.companyFeaturesQuery);
    const { setViewReceiptsAsideSettlement, setIsViewReceiptsAsideOpen } = (0, zustandStore_1.useZustand)();
    const setlementsData = (0, zustandStore_1.useZustand)(state => state.wallet.settlements);
    if (setlementsData.length > 0) {
        return setlementsData.map(settlement => [
            {
                id: 'date',
                ui() {
                    return ((0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldData, { children: settlement.date ? (0, dateUtils_1.formatDate)(settlement.date, currentUser?.language) : 'N/A' }));
                },
            },
            {
                id: 'amount',
                ui() {
                    return ((0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldData, { sx: { textAlign: 'right', paddingRight: '10px' }, children: settlement.totalAmount || 0 }));
                },
            },
            {
                id: 'details',
                ui() {
                    return ((0, jsx_runtime_1.jsxs)(material_1.Grid, { children: [(0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldData, { children: settlement.merchant.name }), settlement.transactionReferenceNumber && ((0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldData, { children: settlement.transactionReferenceNumber }))] }));
                },
            },
            {
                id: 'settlementActions',
                ui() {
                    return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, direction: "row", justifyContent: "flex-start", children: companyFeatures?.['ENABLE_TRANSACTION_RECEIPT_UPLOAD'] && ((0, jsx_runtime_1.jsx)(material_1.Button, { "data-testid": "row-actions-btn", variant: "text", "ts-variant": "actionButton", children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "", onClick: () => {
                                    setViewReceiptsAsideSettlement(settlement);
                                    setIsViewReceiptsAsideOpen(true);
                                }, style: { fontSize: '11px', textDecoration: 'none' }, children: settlement.receiptId ? 'View Receipt' : 'Add Receipt' }) })) }));
                },
            },
        ]);
    }
    return [];
};
exports.useGetTableData = useGetTableData;
