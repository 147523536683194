"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledSearchTableCell = void 0;
const material_1 = require("@mui/material");
exports.StyledSearchTableCell = (0, material_1.styled)(material_1.TextField)(() => ({
    padding: 0,
    width: 'auto',
    input: {
        height: 'auto',
    },
}));
