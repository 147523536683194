import { cloneDeep } from 'lodash';

const TSBarComponent = {
	bindings: {
		buttons: '<',
		onButtonClick: '&',
		title: '@',
		type: '@',
	},
	template: '',
	controller: class TSBarController {
		/* @ngInject */
		constructor($timeout, EventEmitter, HeaderService) {
			this.$timeout = $timeout;
			this.EventEmitter = EventEmitter;
			this.HeaderService = HeaderService;
		}

		$onChanges(changes) {
			if (!this.header) {
				// $onChanges is called before $onInit so we need to make sure we have the element
				this.header = this.HeaderService.get();
			}

			if (changes.buttons && changes.buttons.currentValue) {
				this.buttons = cloneDeep(changes.buttons.currentValue);
				this.setButtons(this.buttons);
			}
			if (changes.title && changes.title.currentValue) {
				this.header.title(changes.title.currentValue);
			}
		}

		setButtons(buttons) {
			this.header.buttons(
				buttons.map(({ key, label, type }) => {
					const onclick = () => {
						// $timeout forces digest cycle so changes in parent method are propagated to children
						this.$timeout(() => this.onButtonClick(this.EventEmitter({ key })));
					};
					return { label, onclick, type };
				}),
			);
		}
	},
};

export default TSBarComponent;
