const _ = require('lodash');
const zustandStore = require('../zustandStore');

/* @ngInject */
export default function MainHelper(
	$q,
	$rootScope,
	$timeout,
	ApprovalLists,
	ApprovalRegistry, // NOSONAR
	ConfigService,
	constants,
	ConversationService,
	ReconnectHelper,
	RequestRegistry,
) {
	// NOSONAR
	const EVENT_TYPES = ConfigService.get('EVENTS').types;
	const VERSION = ConfigService.get('VERSION');
	const EVENTS_DELAY = constants.eventsDelayMs;
	const EVENTS_DELAY_LIMIT_COUNTER = constants.eventsDelayLimitCounter;
	const helper = {};
	let pollStreamTimeout;

	$rootScope.$on(constants.scopeEvents.loggedOut, () => {
		$timeout.cancel(pollStreamTimeout);
	});

	helper.removeApproval = approval => {
		const approvalItemId = approval.getId();
		ApprovalRegistry.removeApproval(approvalItemId);
		ApprovalLists.getAssignedApprovalList().removeItem(approvalItemId);
		ApprovalLists.getCompletedApprovalList().removeItem(approvalItemId);
	};

	helper.pollStream = ({ $scope, options, retryCount = 0 }) => {
		// if (!$scope.isPageActive()) {
		// 	pollStreamTimeout = $timeout(() => {
		// 		helper.pollStream({ $scope, options, retryCount });
		// 	}, EVENTS_DELAY);
		// 	return;
		// }

		const newRetryCount = retryCount >= EVENTS_DELAY_LIMIT_COUNTER ? EVENTS_DELAY_LIMIT_COUNTER : retryCount + 1;
		const pollStreamFn = () => helper.pollStream({ $scope, options, retryCount: newRetryCount });
		const getStream = ConversationService.getStream(options);
		const getServerVersion = ConfigService.getServerVersion();

		return $q
			.all([getStream, getServerVersion])
			.then(([stream, serverVersion]) => {
				$scope.connectionError = false;
				if (serverVersion !== VERSION) {
					ReconnectHelper.showDisconnectedDialogOnWake({ pollStreamFn });
					return;
				}
				const nextOptions = _.cloneDeep(options);
				if (!_.isEmpty(stream)) {
					const { events } = stream;
					const { user } = $scope;
					helper.processEvents({ events, user });
					const zustandAfter = zustandStore.useZustand.getState().stream.streamData.lastTimestamp;
					nextOptions.after = zustandAfter || stream.lastTimestamp;
				}
				pollStreamTimeout = $timeout(() => {
					helper.pollStream({ $scope, options: nextOptions, retryCount: 0 });
				}, EVENTS_DELAY);
			})
			.catch(error => {
				if (error.isCancelled) {
					return;
				}
				$scope.connectionError = true;
				ReconnectHelper.reconnect({ pollStreamFn, retryCount: newRetryCount });
			});
	};

	helper.processEvents = ({ events, user }) => {
		const groupedEvents = _.groupBy(events, 'requestId');
		_.forEach(groupedEvents, (eventsGroup, requestId) => {
			const request = RequestRegistry.getRequest(requestId);
			if (!_.isEmpty(request)) {
				if (_.some(eventsGroup, { type: EVENT_TYPES.purchases.requestApproved })) {
					request.setState(constants.request.states.approved);
				}
				if (_.some(eventsGroup, { type: EVENT_TYPES.purchases.requestRejected })) {
					request.setState(constants.request.states.rejected);
					$rootScope.$broadcast(EVENT_TYPES.purchases.requestRejected);
				}
				request.pushEvents(eventsGroup);
			}
			if (_.some(eventsGroup, { type: EVENT_TYPES.purchases.approvalTask })) {
				const approvalInfos = eventsGroup.filter(({ type }) => {
					return type === EVENT_TYPES.purchases.approvalTask;
				});
				approvalInfos.forEach(approvalInfo => {
					const approval = ApprovalRegistry.storeApproval(approvalInfo.data.task);
					const authorizedForApproval = user.roles.some(role => {
						return approval.isUserAssigned(role.securityGroupId);
					});
					if (authorizedForApproval) {
						ApprovalLists.getAssignedApprovalList().addItem(approval, true);
						$rootScope.$broadcast(constants.scopeEvents.approvalsUpdate);
					} else if (approval.isReassignedToSelf() && !approval.isReassignedBySelf() && !_.isEmpty(request)) {
						const deleteApprovals = ApprovalRegistry.getApprovalsByRequestId(request.getId());
						deleteApprovals.forEach(approvalItem => {
							helper.removeApproval(approvalItem);
							$rootScope.$broadcast(constants.scopeEvents.approvalRemoved, {
								requestId: approvalItem.getRequestId(),
							});
						});
					}
				});
			}
			if (_.some(eventsGroup, { type: EVENT_TYPES.purchases.requestCancelled }) && !_.isEmpty(request)) {
				const deleteRequestId = request.getId();
				const approvalItems = ApprovalRegistry.getApprovalsByRequestId(deleteRequestId);
				approvalItems.forEach(approvalItem => {
					helper.removeApproval(approvalItem);
				});
				if (!_.isEmpty(approvalItems)) {
					$rootScope.$broadcast(constants.scopeEvents.approvalRemoved, { requestId: deleteRequestId });
				}
			}
			if (_.some(eventsGroup, { type: EVENT_TYPES.purchases.virtualCardAdded })) {
				const dataEvent = _.find(eventsGroup, {
					type: EVENT_TYPES.purchases.virtualCardAdded,
				});

				if (!_.isEmpty(request)) {
					request.update(dataEvent.data.task.request);
				}

				$rootScope.$broadcast(constants.scopeEvents.newVirtualCard, dataEvent.data.task.id);
			}
			if (_.some(eventsGroup, { type: EVENT_TYPES.dataEvent })) {
				const dataEvent = _.find(eventsGroup, {
					type: EVENT_TYPES.dataEvent,
				});
				$rootScope.$broadcast(constants.scopeEvents.dataEvent, dataEvent.data.body.data);
			}
		});
	};

	return helper;
}
