const _ = require('lodash');

/* @ngInject */
export default function ReconnectHelper(
	$state,
	$window,
	$timeout,
	ConfigService,
	LexicoService,
	LoadingService,
	SessionService,
) {
	const lexico = LexicoService.getLexico();
	const RETRY_CONNECTION_PERIODS_SECONDS = [10, 15, 20, 30, 60];
	const VERSION = ConfigService.get('VERSION');

	const helper = {};
	let wakeFn = null;
	let disconnectedNotification = null;
	let _triedToReconnectCount = 0;

	// this is a hack to avoid opening a Notification while laptop is closed, which
	// exposes a bug in ts ui components
	// TODO remove this hack if associated tsui issue is resolved https://github.com/Tradeshift/tradeshift-ui/issues/590
	const processWakeFunction = () => {
		if (wakeFn) {
			const localWakeFn = wakeFn;
			wakeFn = null;
			localWakeFn();
		}
		$window.requestAnimationFrame(processWakeFunction);
	};

	helper.reconnect = ({ pollStreamFn, retryCount }) => {
		const reconnectDelay = Math.pow(2, retryCount) * 5000;
		// this timeout is needed to avoid processAction firing before laptop is closed (see coment above)
		$timeout(() => {
			// On the first retryConnection the disconnected dialog is not shown so that a momentary dropped connection
			// won't be noticed. `showDialogOnNewVersion` is set to true which causes the disconnected dialog to appear
			// even on the first retry if a different deployed version is found on the server. This is used to avoid the
			// page apparently reloading for no reason and losing form data. Successive calls to retryConnection will
			// set `showDialogOnNewVersion` to false since they are triggered by the disconnected dialog `onaccept`
			// callback.
			const showDialogOnNewVersion = true;
			helper.retryConnection({ pollStreamFn, showDialogOnNewVersion });
		}, reconnectDelay);
	};

	helper.retryConnection = ({ pollStreamFn, showDialogOnNewVersion = false }) => {
		LoadingService.startLoading();
		ConfigService.getServerVersion()
			.then(serverVersion => {
				LoadingService.stopLoading();
				_triedToReconnectCount = 0;
				if (serverVersion === 'local' || serverVersion !== VERSION) {
					if (showDialogOnNewVersion) {
						helper.showDisconnectedDialogOnWake({ pollStreamFn });
					} else {
						$window.location.reload();
					}
					return;
				}
				return pollStreamFn();
			})
			.catch(({ status } = {}) => {
				LoadingService.stopLoading();
				if (status === 401) {
					SessionService.sessionExpiredRedirect({
						redirectParams: getRedirectParams($state.params),
						redirectTo: $state.current.name,
					});
					return;
				}
				helper.showDisconnectedDialogOnWake({ pollStreamFn });
			});
	};

	const getRedirectParams = params => {
		return _.omit(params, ['#', '$inherit']);
	};

	helper.showDisconnectedDialogOnWake = ({ pollStreamFn }) => {
		wakeFn = () => {
			showDisconnectedDialog({ pollStreamFn });
		};
	};

	const showDisconnectedDialog = ({ pollStreamFn }) => {
		const retryConnectionPeriod = helper.getRetryConnectionPeriod();

		const acceptNotificationTimeout = $timeout(() => {
			_triedToReconnectCount++;
			if (disconnectedNotification) {
				disconnectedNotification.accept();
			}
		}, retryConnectionPeriod * 1000);

		const errorMessage = `${lexico.tr(
			"Sorry! We're having trouble connecting at the moment - retrying in",
		)} ${retryConnectionPeriod} ${lexico.tr('seconds')}.`;
		const retryMessage = lexico.trc('Retry Message', 'Retry connection now');
		disconnectedNotification = ts.ui.Notification.warning(errorMessage, retryMessage, {
			onaccept: () => {
				if (_.get(acceptNotificationTimeout, '$$state.status') === 0) {
					// reset the backoff counter if the user clicked 'retry connection now'
					// or allow the counter to continue if onaccept was triggered by the $timeout
					_triedToReconnectCount = 0;
				}
				$timeout.cancel(acceptNotificationTimeout);
				helper.retryConnection({ pollStreamFn });
			},
		});
	};

	helper.getRetryConnectionPeriod = ({ triedToReconnectCount = _triedToReconnectCount } = {}) => {
		const maxPeriodIndex = RETRY_CONNECTION_PERIODS_SECONDS.length - 1;
		const exceedsMaxIndex = triedToReconnectCount > maxPeriodIndex;
		const periodIndex = exceedsMaxIndex ? maxPeriodIndex : triedToReconnectCount;
		return RETRY_CONNECTION_PERIODS_SECONDS[periodIndex];
	};

	processWakeFunction();

	return helper;
}
