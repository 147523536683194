const { pendo } = require('@tradeshift/pendo');

/* @ngInject */
function MainController($scope, _user, AsideService, HeaderService, MainHelper, ConfigService) {
	const ctrl = this;
	ctrl.triedToReconnectCount = 0;
	ctrl.$scope = $scope;

	$scope.$on('$destroy', function () {
		HeaderService.reset();
	});

	$scope.isPageActive = () => document.hasFocus();

	(function init() {
		$scope.asides = AsideService.get(['editProfile', 'editProfileV2']);
		$scope.user = _user;

		/*
			If we need to use pendo for users that are not logged-in yet (with no user id and company id),
			make sure to use static strings for visitor and account ids,
			otherwise Pendo will assign random uuids and we will get charged more,
			as we are charged per number of visitor ids.
		 */
		if (ConfigService.get('IS_PENDO_ENABLED') && _user?.userId && _user?.companyAccountId) {
			pendo.initialize({
				visitor: { id: _user.userId },
				account: { id: _user.companyAccountId },
			});
		}

		HeaderService.create({
			title: 'Tradeshift Go',
			type: 'lite',
		});

		const options = { after: Date.now() };
		MainHelper.pollStream({ $scope, options, retryCount: 0 });
	})();
}

MainController.resolve = {
	configureLexico: /* @ngInject */ LexicoService => {
		return (async () => {
			await LexicoService.configure();
		})();
	},
	getFeatures: /* @ngInject */ FeatureService => {
		return (async () => {
			await FeatureService.getFeatures();
		})();
	},
	_isApprovalsLoaded: /* @ngInject */ (ApprovalListBuilder, ApprovalLists, ApprovalRegistry) => {
		return ApprovalLists.initialize({
			lists: ApprovalListBuilder.getApprovalLists(),
			registry: ApprovalRegistry,
		});
	},
	// TODO: should we make a parent state for everything to check _isLoggedIn and ts.ready()?
	_isLoggedIn: /* @ngInject */ SessionService => SessionService.status(),
	_isRequestsLoaded: /* @ngInject */ (RequestListBuilder, RequestLists, RequestRegistry) => {
		return RequestLists.initialize({
			lists: RequestListBuilder.getRequestLists(),
			registry: RequestRegistry,
		});
	},
	_ts: /* @ngInject */ $q => $q(resolve => ts.ui.ready(resolve)),
	_user: /* @ngInject */ UserService => UserService.getUser(),
};

module.exports = MainController;
