"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiDrawerOverrides = void 0;
const material_1 = require("@mui/material");
exports.muiDrawerOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                [`.${material_1.drawerClasses.paper}`]: {
                    width: theme.tsGoUnit.tsUnitWidthAside,
                    boxSizing: 'border-box',
                    form: {
                        padding: theme.tsGoUnit.tsUnit,
                    },
                },
            };
        },
    },
};
