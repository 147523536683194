const moment = require('moment');

const timeHelper = {};

/* To be mocked in tests. */
timeHelper.getCurrentMoment = () => {
	return moment();
};

module.exports = timeHelper;
