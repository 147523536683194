"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inviteRegisterSchema = exports.phoneDetailsSchema = void 0;
const zod_1 = require("zod");
const nonEmptyString = (fieldName) => zod_1.z.string().min(1, `${fieldName} must contain at least 1 character(s)`);
exports.phoneDetailsSchema = zod_1.z.object({
    phoneCountryCode: zod_1.z.string().min(1),
    phoneNumber: zod_1.z.string().min(1),
    fullPhoneNumber: zod_1.z.string().min(1),
});
exports.inviteRegisterSchema = zod_1.z.object({
    firstName: nonEmptyString('First name'),
    lastName: nonEmptyString('Last name'),
    username: zod_1.z.string().email({ message: 'Invalid email address' }),
    phoneDetails: exports.phoneDetailsSchema,
    tempPassword: zod_1.z.string().min(8, 'Temporary password must be at least 8 characters'),
});
