const SelectCardSourceAsideComponent = {
	bindings: {
		isOpen: '<',
		onCardSourceSelected: '&',
		onClose: '&',
		onEnrollCardSource: '&',
		teamCardSources: '<',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.addNewCardText}}">
			<p ng-bind="$ctrl.addCardSourceInstructionsText"></p>
			<menu data-ts="Menu">
				<li ng-repeat="option in $ctrl.options">
					<button ng-click="$ctrl.selectCard(option)" type="button">
						<span ng-bind="option.name + ' (' + option.lastDigits + ')'"></span>
					</button>
				</li>
				<li>
					<button ng-click="$ctrl.enrollCardSource()" type="button">
						<span ng-bind="$ctrl.enrollNewCardButtonText"></span>
						<i class="ts-icon-arrowright"></i>
					</button>
				</li>
			</menu>
			<menu data-ts="Buttons">
				<li>
					<button
							class="ts-secondary"
							data-ts="Button"
							ng-click="$ctrl.onClose()">
						<span ng-bind="$ctrl.cancelButtonText"></span>
					</button>
				</li>
			</menu>
		</ts-aside>
	`,
	controller: /* @ngInject */ function (EventEmitter, PaymentsService, LexicoService) {
		this.lexico = LexicoService.getLexico();
		this.addNewCardText = this.lexico.trc('Component title', 'Add new card');
		this.addCardSourceInstructionsText = this.lexico.trc(
			'User instructions',
			'Link one of these source cards to this team or enroll a new one.',
		);
		this.enrollNewCardButtonText = this.lexico.trc('Button', 'Enroll a new card');
		this.cancelButtonText = this.lexico.trc('Button', 'Cancel');
		this.$onChanges = changes => {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}
		};

		this.onOpen = async () => {
			this.isLoading = true;
			this.companyCardSources = await PaymentsService.getCardSources({ includeTeamsCount: true });
			this.options = this.companyCardSources.filter(card => !this.teamCardSources.includes(card.id));
			this.isLoading = false;
		};

		this.selectCard = async cardSource => {
			this.onCardSourceSelected(EventEmitter({ cardSource }));
		};

		this.enrollCardSource = () => {
			this.onEnrollCardSource();
		};
	},
};

export default SelectCardSourceAsideComponent;
