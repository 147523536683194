const _ = require('lodash');

/* @ngInject */
function PaymentsService(HttpService, UserService, $filter) {
	return {
		removeApprover: function (options) {
			return HttpService.tradeshiftGo()
				.path('/rest/payments/cardsources/' + options.cardSourceId + '/approvers/' + options.userId)
				.delete();
		},
		getApprovers: function () {
			return HttpService.tradeshiftGo().path('/rest/payments/approvers').get();
		},
		getApproversForCard: function (options) {
			return HttpService.tradeshiftGo()
				.path('/rest/payments/approvers')
				.params({ cardSourceId: options.cardSourceId })
				.get();
		},
		getTeamApprovers: async ({ excludeOutOfOffice, excludeSelf, teamId } = {}) => {
			const { users: approvers } = await HttpService.tradeshiftGo()
				.path(`/rest/teams/${teamId}/approvers`)
				.params({ excludeOutOfOffice })
				.get();
			if (excludeSelf) {
				const user = await UserService.getUser();
				return approvers.filter(({ userId }) => userId !== user.userId);
			}
			return approvers;
		},
		getVirtualCardToken: function (options) {
			return HttpService.tradeshiftGo().path('/rest/payments/card').params({ documentId: options.documentId }).get();
		},
		getVirtualCardInfo: function (options) {
			return HttpService.tradeshiftGo()
				.path('/rest/payments/card/info')
				.params({ documentId: options.documentId })
				.get();
		},
		cancelVirtualCardV2: options =>
			HttpService.tradeshiftGo().path(`/rest/tasks/${options.subjectId}/cards/${options.virtualCardId}`).post(),
		getCardSources: function ({ includeTeamsCount, status, teamId } = {}) {
			const params = {
				includeTeamsCount,
				status,
			};
			if (_.isNil(teamId)) {
				return HttpService.tradeshiftGo().path('/rest/payments/cardsources').params(params).get();
			}

			return HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/cardsources`).params(params).get();
		},
		getCardSource: ({ cardSourceId, includeCardHolder, teamId }) => {
			if (_.isNil(teamId)) {
				return HttpService.tradeshiftGo()
					.path(`/rest/payments/cardsources/${cardSourceId}`)
					.params({ includeCardHolder: !!includeCardHolder })
					.get();
			}

			return HttpService.tradeshiftGo()
				.path(`/rest/teams/${teamId}/cardsources/${cardSourceId}`)
				.params({ includeCardHolder: !!includeCardHolder })
				.get();
		},
		getEnrollmentToken: function () {
			return HttpService.tradeshiftGo().path('/rest/payments/enrollment').get();
		},
		getEnrollmentTokenV2: ({ teamId }) => {
			if (_.isNil(teamId)) {
				return HttpService.tradeshiftGo().path('/rest/payments/enrollment').get();
			}
			return HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/cardsources/enrollment`).get();
		},
		getSettlements: function (options) {
			return HttpService.tradeshiftGo()
				.path('/rest/payments/settlements')
				.params({
					cardSourceId: _.get(options, 'cardSourceId'),
					virtualCardId: _.get(options, 'virtualCardId'),
				})
				.get()
				.then(function (result) {
					return _.map(result.settlements, function (settlement) {
						return _.assign({}, settlement, {
							displayAmount: $filter('currency')(settlement.amount, settlement.currency),
						});
					});
				});
		},
		getSettlementsForCompany: options =>
			HttpService.tradeshiftGo()
				.path('/rest/payments/settlements/company')
				.params({
					from: _.get(options, 'from'),
					to: _.get(options, 'to'),
				})
				.get(),
		getSettlementsForTeam: options =>
			HttpService.tradeshiftGo()
				.path(`/rest/payments/settlements/team/${options.teamId}`)
				.params({
					from: _.get(options, 'from'),
					to: _.get(options, 'to'),
				})
				.get(),
		getSettlementsForTeamManager: options =>
			HttpService.tradeshiftGo()
				.path(`/rest/payments/settlements/team/${options.teamId}/manager`)
				.params({
					from: _.get(options, 'from'),
					to: _.get(options, 'to'),
				})
				.get(),
		getSettlementsInfo: (teamId, { month, year } = {}) =>
			HttpService.tradeshiftGo()
				.path('/rest/payments/settlements/info')
				.config({
					params: {
						month,
						teamId,
						year,
					},
					cache: true,
				})
				.get(),
		getTeamsForCardSource: ({ cardSourceId }) =>
			HttpService.tradeshiftGo().path(`/rest/payments/cardsources/${cardSourceId}/teams`).get(),
		enrollWithAmex: function (options) {
			return HttpService.payments()
				.path('/amex/enrollment')
				.config({
					headers: {
						Authorization: 'Bearer ' + options.enrollmentToken,
						// removes default header that makes IE work nice but not allowed in CORS
						'If-Modified-Since': undefined,
					},
				})
				.post(options.paymentInformation);
		},
		exchangeTokenForVirtualCard: function (options) {
			return HttpService.payments()
				.path('/card')
				.config({
					headers: {
						Authorization: 'Bearer ' + options.virtualCardToken,
						// removes default header that makes IE work nice but not allowed in CORS
						'If-Modified-Since': undefined,
					},
				})
				.get();
		},
		addCardSourceToTeam: (teamId, cardSourceId) => {
			return HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/cardsources/${cardSourceId}`).put();
		},
		removeCardSourceFromTeam: (teamId, cardSourceId) =>
			HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/cardsources/${cardSourceId}`).delete(),
		setCardName: ({ cardSourceId, name, teamId }) => {
			if (_.isNil(teamId)) {
				return HttpService.tradeshiftGo().path(`/rest/payments/cardsources/${cardSourceId}/name`).put({ name });
			}
			return HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/cardsources/${cardSourceId}/name`).put({ name });
		},
		getCurrencies: teamId => {
			if (teamId) {
				return HttpService.tradeshiftGo().path(`/rest/teams/${teamId}/currencies`).get();
			}
			return HttpService.tradeshiftGo().path('/rest/payments/currencies').get();
		},
	};
}

module.exports = PaymentsService;
