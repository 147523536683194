module.exports = {
"5e27725611f4098b1e7b666e25cfbea7":"操作",
"476e728ea824229f52438e543818bcdd":"操作",
"3117746cb7d4ab815ec98274ec4398d5":"操作",
"3ab09b66a7f16219d8135977a2a76d10":"操作",
"ffafa66eaab0409a2e90aaeb43796def":"當前的",
"0447e0aa5d00dbdab11b5546793c4479":"當前的",
"21e934ae30eece4a894337701f497638":"全部",
"c912f8679e78eaeb4d4edf1c5809c32b":"金額",
"a4d099d0dbe3889b0995546e6ed28932":"金額",
"6c0ef32aae76bc31a61e2124f542bdf1":"金額",
"7125aa13db437084e1d27d3ff38f22e2":"核準",
"7a568654d00245b0bab6c90a1b67d196":"已核準",
"179c3e187ad8edeb8348cff222b938bf":"已核準",
"a57dfb1ce3af2300e568400827558748":"核準者",
"4fe56aa6456c108cd288a4ec8acf435f":"存檔",
"434ca07dfde0eaee14f094b7c75a6eae":"可用",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"返回",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"取消",
"12257ea2a871b7fab72245b5aafe6424":"取消請求",
"879a6b452033cf5f3a4bb3e1837a0386":"城市",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"編碼",
"ff9cb8842f832d21fb160b3d3784ce79":"公司",
"7313fd1db3d63858586d1c0f89251d6e":"公司名稱",
"07721e91dd10ff8831f0d24e47cc1eab":"公司名稱",
"d694cec09cdbc02b1ffe22642a63faa1":"確認密碼",
"512801312a133f29974f2259a455f39b":"確認密碼",
"51f0d8e04f6ee626b866b37833dc2b61":"成本中心",
"6d62f934f2304701f88bdcfbc688de7e":"創建帳戶",
"88ee8fab2ece6a8e6bd7057e1789a25f":"創建帳戶",
"68bf17fd3f54a89c0a84224e2f9a971c":"創建新的團隊",
"d1715162ae8e9cc6ef30ec6063a3fc34":"創建團隊",
"3b8e665101b9b3657f8a68c5305947b7":"已創建",
"40e91f2907181ffa8e62567b3a407e60":"創建人",
"d86688c26e8ac5eb9facc971b6fe5155":"貨幣",
"5c1a69e23c7265e4bb1fa788f5b6e953":"貨幣",
"ef445eab224fc599e4103c9b1745a4e0":"儀表板",
"788452c3ad2b1450b6d8d116b863e635":"儀表板",
"6bded249dbfafd14490eea8dd37e1f51":"日期",
"46e908a57cb09ef947af8c4a93a09716":"日期",
"5234dfc4220ccd18a8b62fe84e372248":"詳細資料",
"37929896817a6744718004374bb42fd8":"詳細資料",
"dee57797e14ccf852aa2bc545bafdf6d":"下載",
"2974e704676cb79666bc236d8658fa97":"編輯檔案",
"9ff205f99ed4002d049f855f965ca6d8":"編輯使用者",
"3d7a4a9f4e70f57463e9a97caadb8205":"電子郵件",
"0534c00af758d42ebd8b0bd772eab074":"電子郵件",
"0c9aa1fbdf925065d25161eab5e51d95":"電子郵件",
"ea78fac68766a48b1b0f41305b7e9898":"電子郵件",
"58bb9c6f074e777ab1829b41acb18a81":"名",
"7458b55e967e51791d98e77458631e44":"名",
"3bbe52e71bc5c212cea77983f1a5b748":"名",
"26bdfaa592f3629273f13c22435b323d":"忘記密碼？",
"db9071ab833054307252a936e7f60e1b":"開始使用",
"9df1d3de68d663b848101df3c7b58e39":"它是如何工作的",
"39ca662b0e2f39d3ca8143e8dbbb584f":"閒置的",
"1b7dc8290f1205f092cc9133e0cde385":"發票日期",
"613da3d0cd586ef0360e0d93eace9508":"核發於",
"3322b671105c65511fbab1966b7fdcf6":"語言",
"19f7c0055e150e9e3e66e85c2993799c":"姓",
"662c84bede8a7bf91ab15bcb73b48b49":"姓",
"cff8fbef5aac2523344b9535bd56973c":"姓",
"f5d8fe188045fb84a41f8567ee3b2d88":"登錄",
"ad96c1ca962a883652dadb845f439415":"登錄",
"072ca67e735adbed54acf41321370701":"登錄",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"登錄",
"6f9612aa1582373c2c1ba4e144132100":"成員",
"590429a0fcc42af85648d0fd70601dcc":"成員",
"3f50e62c8488d83ad84f69ebda853897":"成員",
"e0fc0acda5b74b93e797756dbbba67bc":"成員",
"c7ef9eda38b7eaee1f20031387a8bde7":"月",
"40dd08b507752bef4e3c4b36838317d5":"每月",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"名稱",
"1c7514b561a2591a914225813d30d642":"名稱",
"d621826c1ceade262589af04e655e824":"新密碼已請求",
"e788124801b4727d121f60d059a2a4a7":"下一個",
"4fd0c753fce49a016b98d988a3895223":"概覽",
"2789c5d5c9c042858f45aadc4e0de058":"概覽",
"083b7090b66ebd0307a18499ac7b676e":"頁面不存在",
"e8c7d2eea7db66ecb44751091a582304":"密碼",
"bb79e316e00fd21138750b6986e44455":"密碼",
"6cae2279088580976d84119d95097cae":"密碼不匹配",
"4baec0159d3074f84c0eebcc09ba4cd8":"密碼不匹配",
"33fc1e6d01b785e3a815c28ef7d56667":"待定",
"f811ea5af22c0b0d2efd1a3f849c77e4":"人員",
"89b1deb2ffe12a13941c362abaeab01b":"已發佈",
"9dee2afa8cd8e7ef68a7b88e3470f169":"原因",
"e8484c78fda9f453eeb942ba4945f040":"再分配",
"32955937daca05b2084556f248692baa":"登記",
"9e56abbbf87420eeedd625ce786515c7":"申請人",
"df309e888c29fc447d0309329b39ce93":"重置密碼",
"31e9501526d2114e4a01fdbf076de097":"角色",
"6fbceb787429f6f81117a1d5a58b4610":"角色",
"473b814ca86841ef672ccf9f7dac28d5":"角色",
"f1e16d5fd643e039d865b6aec7c986f9":"角色",
"ec78345a5975bc0f8efaee5256b43c2c":"儲存",
"3629383abf1d5f85efd759f718159b40":"按名稱搜尋",
"111bfcbff671e13de117ed5195fb6773":"請選擇",
"bcfecd59eb93e5314873d84ddf35aa58":"請選擇",
"677e90855e164c00a28b5c4763ac67e0":"選擇角色",
"b761773d46699b60e8d6874b85593374":"選擇團隊",
"03e9d2c128af288126d94f631c5396be":"傳送說明",
"16080cd4e8319ef3e7de21b6c19d1437":"有些不對勁",
"b48efd4521ecd6428987d24636a4349f":"排序",
"776a95003d3ec1dd58af69aedbc08579":"排序",
"b0cb87df77ae00aa1d4e560b3a46dc50":"狀態",
"b089930f1f6ffac09438cc19590f8f6b":"狀態",
"f174d20592156eb4b10a7cf1144c973e":"狀態",
"ecf342482d1b6ab932c2000cd7dc38c4":"狀態",
"111b782f3f0794c2294aefb6693b2c50":"提交",
"601d04f10d558f436c78c10e90b44175":"支援",
"0cb5d74f7a56fca30930616feb6e2f47":"團隊",
"5c6166087aa8b5b4ea81b6bcc5a042bc":"團隊",
"bc05b40509f0271c12cfaaef8e5319b1":"團隊",
"39e73773d07a800673ec6600422952d6":"團隊",
"daabb564bb3dcfb41de69492f854d573":"團隊",
"f2a42214e7c480c5596439becfd0b7bf":"該字段為必填項",
"969de95e19a06f5ae7cec6449cbb6a27":"總計",
"e448a1ce42de0cf526200331b4ad8b23":"使用者",
"b2d141897f73fc2a198ee1a7a438f89b":"檢視請求",
"f2af2c261fabddb01766f4f974b85a62":"每週",
"fe88fe7094c4ced427874b3d22326c8d":"是",
"32f87c5ddeaee35b9178785ef4eb9313":"郵編",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"為",
"a6e7b18eae6962edf08dc9c3ced705f4":"自",
"54b3bfd45a293b441d441aab1a50e644":"下载报表",
"6a0b8d521500014d99dd192d8ce8403d":"过期日期",
"a65a230918e9c11e1ef8824745e26a3d":"开始",
"4d398249d01ab72d697a7e219493c637":"提供者",
"f15bb755ba24abe6ab106637292175ec":"剩余",
"3ab198d2678af2b5b32180e4b39602ba":"选择审批人",
"8f358a370224baf849f9652810c68db2":"出了点问题",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"出了点问题",
};
