"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiTablePaginationOverrides = void 0;
const material_1 = require("@mui/material");
exports.muiTablePaginationOverrides = {
    styleOverrides: {
        root: () => {
            return {
                [`.${material_1.tablePaginationClasses.displayedRows}`]: {
                    margin: 0,
                },
            };
        },
    },
};
