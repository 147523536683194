"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companyInitialState = void 0;
exports.companySlice = companySlice;
const defaultFeatures = {
    ENABLE_DOMAIN_WHITELISTING: false,
    ENABLE_FILTERED_BY_COSTCENTER: false,
    ENABLE_OUT_OF_OFFICE_FORWARDING: false,
    ENABLE_MULTIUSE: false,
    ENABLE_SINGLEUSE: false,
    ENABLE_SUBSCRIPTIONS: false,
    ENABLE_TRANSACTION_RECEIPT_UPLOAD: false,
    ENABLE_USER_APPROVAL_LIMITS: false,
    ENABLE_VIRTUAL_CARD_EXP_RANGE: false,
    PAUSE_VIRTUAL_CARD_CREATION: false,
    ENABLE_API_INTEGRATION: false,
    ENABLE_PROVIDER_MAPPING: false,
    ENABLE_COMPANY_PEOPLE_VIEW: false,
    ENABLE_GLOBALIZATION: false,
};
exports.companyInitialState = {
    company: {
        companyName: '',
        companyId: '',
        features: defaultFeatures,
        properties: {
            whiteListedDomains: [],
            companyApprovalLimit: '',
            defaultExpirationDate: '',
        },
        uiSettings: {
            saveButton: {
                isDisabled: true,
            },
        },
        companyErrors: {},
        companySyncState: {
            companyName: '',
            features: defaultFeatures,
            properties: {
                whiteListedDomains: [],
                companyApprovalLimit: '',
                defaultExpirationDate: '',
                showactivity: 'false',
            },
        },
    },
};
function companySlice(set) {
    return {
        ...exports.companyInitialState,
        setCompanyName(companyName) {
            set((state) => {
                state.company.companyName = companyName;
                return state;
            }, false, `[setCompanyName]-${companyName}`);
        },
        setCompanyId(companyId) {
            set((state) => {
                state.company.companyId = companyId;
                return state;
            }, false, '[setCompanyId]');
        },
        setCompanyFeatures(features) {
            set((state) => {
                state.company.features = { ...state.company.features, ...features };
                return state;
            }, false, '[setCompanyFeatures]');
        },
        setCompanyFeature({ key, value }) {
            set((state) => {
                if (state.company.features) {
                    state.company.features[key] = value;
                }
                return state;
            }, false, '[setCompanyFeature]');
        },
        setCompanyProperty({ key, value }) {
            set((state) => {
                state.company.properties[key] = key === 'whiteListedDomains' ? String(value).split(',') : value;
                return state;
            }, false, '[setCompanyProperty]');
        },
        setIsCompanySettingsSaveBtnDisabled(isDisabled) {
            set((state) => {
                state.company.uiSettings.saveButton.isDisabled = isDisabled;
                return state;
            }, false, '[setIsCompanySettingsSaveBtnDisabled]');
        },
        setCompanyErrors(companyErrors) {
            set((state) => {
                state.company.companyErrors = companyErrors;
                return state;
            }, false, '[setCompanyErrors]');
        },
        setCompanySyncState(companySyncState) {
            set((state) => {
                state.company.companySyncState = companySyncState;
                return state;
            }, false, '[setCompanySyncState]');
        },
    };
}
