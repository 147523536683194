import angular from 'angular';

import AsidesModule from './asides';
import ComponentsModule from './components';
import FiltersModule from './filters';
import ModalsModule from './modals';
import StylesModule from './styles';
import TSModule from './ts';

export default angular.module('go.common', [
	AsidesModule,
	ComponentsModule,
	FiltersModule,
	ModalsModule,
	StylesModule,
	TSModule,
]).name;
