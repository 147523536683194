const _ = require('lodash');

/* @ngInject */
function ApprovalListsFactory($q, ApprovalList, ApprovalRegistry, ConfigService, constants) {
	let _listKeys;

	(function init() {
		_listKeys = _.values(constants.tasks.states);
	})();

	const approvalLists = {
		_lists: {},
		_registry: {},
	};

	function _addApprovalsToList(approvalList, approvals) {
		_.map(approvals, function (approval) {
			approvalList.addItem(approvalLists._registry.storeApproval(approval));
		});
	}

	function _loadApprovalLists() {
		const listKeys = [];
		const promises = _.map(approvalLists._lists, function (list) {
			listKeys.push(list.getKey());
			return list.load();
		});
		return $q.all(promises).then(function (results) {
			_.forEach(results, function (result, index) {
				const approvalListKey = listKeys[index];
				const approvalList = approvalLists._lists[approvalListKey];
				_addApprovalsToList(approvalList, result);
			});
			return approvalLists._lists;
		});
	}

	approvalLists.completeApproval = function (approvalId, outcome, reassignedToUser, reassignedOn) {
		const approval = ApprovalRegistry.getApproval(approvalId);
		if (approval) {
			approvalLists._lists.assigned.removeItem(approvalId);
			approvalLists._lists.completed.addItem(approval);
			approval.markComplete();
			switch (outcome) {
				case ConfigService.get('TASK_OUTCOMES').approved:
					approval.markApproved();
					break;
				case ConfigService.get('TASK_OUTCOMES').rejected:
					approval.markRejected();
					break;
				case ConfigService.get('TASK_OUTCOMES').reassigned:
					approval.markReassigned(reassignedToUser, reassignedOn);
					break;
				default:
					break;
			}
		}
	};

	approvalLists.getApprovalLists = function () {
		return approvalLists._lists;
	};

	approvalLists.getAssignedApprovalList = function () {
		return approvalLists._lists.assigned;
	};

	approvalLists.getCompletedApprovalList = function () {
		return approvalLists._lists.completed;
	};

	approvalLists.getCurrentAssignedList = function () {
		return approvalLists._lists.assigned._items.slice();
	};

	approvalLists.getCurrentCompletedList = function () {
		return approvalLists._lists.completed._items.slice();
	};

	approvalLists.getCurrentCombinedLists = function getCurrentCombinedLists({ teamId } = {}) {
		const combinedLists = _.reduce(
			_listKeys,
			function iteratee(accumulator, key) {
				return _.union(accumulator, approvalLists._lists[key]._items.slice());
			},
			[],
		);
		const orderedApprovals = _.reverse(
			_.sortBy(combinedLists, function (approval) {
				return approval.getAssignedOn();
			}),
		);
		if (teamId) {
			return orderedApprovals.filter(approval => approval.getTeamId() === teamId);
		}
		return orderedApprovals;
	};

	approvalLists.initialize = function (options) {
		approvalLists._lists = options.lists;
		approvalLists._registry = options.registry;

		return _loadApprovalLists();
	};
	return approvalLists;
}

module.exports = ApprovalListsFactory;
