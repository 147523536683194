"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const filterSettlementsBySourceId = (settlements, { id }) => {
    if (id === 'allSourceCards') {
        return settlements;
    }
    return settlements.filter(settlement => settlement.cardSourceId === id);
};
const filterSettlementsByUploadedReceipt = (settlements, { showWithoutReceipts }) => {
    if (showWithoutReceipts === false) {
        return settlements;
    }
    return settlements.filter(settlement => !(0, lodash_1.has)(settlement, 'receiptId'));
};
const filterSettlementsByVirtualCardId = (settlements, { id }) => {
    if (id === 'allVirtualCards') {
        return settlements;
    }
    return settlements.filter(settlement => settlement.cardId === id);
};
const mapSettlementToDisplay = settlement => {
    const { cardInfo, cardId } = settlement;
    return {
        label: `${cardInfo.requestTitle} ($${cardInfo.requestAmount})`,
        requestTitle: cardInfo.requestTitle,
        owner: cardInfo.userInfo.name,
        requestAmount: cardInfo.requestAmount,
        id: cardId,
    };
};
const helpers = {
    filterSettlements: (filters, settlements) => {
        // Due to the relationship between parent card and virtual card, we have to filter by parent card
        // before we run the filter for virtual card. All the rest of the filters could be executed in any order
        const firstFilter = filterSettlementsBySourceId(settlements, filters.parentCard);
        const secondFilter = filterSettlementsByVirtualCardId(firstFilter, filters.virtualCard);
        const lastFilter = filterSettlementsByUploadedReceipt(secondFilter, filters);
        return lastFilter;
    },
    filterVirtualCardsBySource: (data, settlements) => {
        const virtualCardsList = settlements
            .filter(settlement => settlement.cardSourceId === data.id)
            .map(mapSettlementToDisplay);
        return (0, lodash_1.uniqBy)(virtualCardsList, 'id');
    },
    getSourceCards: settlements => {
        const sourceCardList = settlements.map(settlement => ({
            label: settlement.cardSource.name,
            id: settlement.cardSource.id,
        }));
        return (0, lodash_1.uniqBy)(sourceCardList, 'id');
    },
    getVirtualCards: settlements => {
        const virtualCardsList = settlements.map(mapSettlementToDisplay);
        return (0, lodash_1.uniqBy)(virtualCardsList, 'id');
    },
    generateCodingInfoRow: codingInfo => {
        // We don't want documentId listed in coding, but it comes in from fetching coding info in the backend
        const codingKeys = Object.keys(codingInfo).filter(key => key !== 'documentId');
        return codingKeys.reduce((accumulator, key, index) => {
            // Only display 3 lines of coding, then hide the rest
            if (index < 3) {
                // on local dev we have "listId" not "ID"
                return `${accumulator}${codingInfo[key].listLabel} - ${codingInfo[key].ID || codingInfo[key].listId || ''}\n`;
            }
            if (index === 3) {
                return `${accumulator}+${codingKeys.length - 3} more code${codingKeys.length - 3 <= 1 ? '' : 's'}`;
            }
            if (index > 3) {
                return accumulator;
            }
            // default implementation
            return accumulator;
        }, '');
    },
    sum: (settlements, currency) => settlements.reduce((result, settlement) => {
        result.total += settlement.amount;
        result.currency = settlement.currency;
        return result;
    }, {
        currency,
        total: 0,
    }),
};
exports.default = helpers;
