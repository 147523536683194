import { cloneDeep, isEmpty } from 'lodash';

const TSSelectComponent = {
	bindings: {
		removeEmptyOption: '@?',
		isDisabled: '@',
		isRequired: '@',
		label: '@',
		onChange: '&',
		options: '<',
		placeholder: '@',
	},
	require: {
		model: 'ngModel',
	},
	template: `
		<fieldset>
			<label>
				<span
						ng-bind="$ctrl.label"
						ng-if="$ctrl.label"></span>
				<select
						ng-change="$ctrl.onSelectChange($ctrl.value)"
						ng-model="$ctrl.value"
						ng-options="option as option.label for option in $ctrl.options track by option.id"
						ng-disabled="$ctrl.isDisabled === 'true' ? true : false"
						ng-required="$ctrl.isRequired === 'false' ? false : true"
						ng-attr-placeholder="{{$ctrl.placeholder || 'Select a ' + $ctrl.label | goSentenceCase }}">
					<option ng-if="$ctrl.removeEmptyOption === 'true' ? false : true"></option>
				</select>
			</label>
		</fieldset>
	`,
	controller: class TSSelectController {
		$onInit() {
			this.model.$render = () => {
				this.value = this.model.$viewValue;
			};
		}

		$onChanges(changes) {
			if (changes.options && changes.options.currentValue) {
				this.options = cloneDeep(changes.options.currentValue);
			}
		}

		onSelectChange(value) {
			if (!isEmpty(value)) {
				this.model.$setViewValue(value);
				this.onChange({ value });
			} else {
				this.value = this.model.$viewValue;
			}
		}
	},
};

export default TSSelectComponent;
