module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# transaktioner",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"En minut",
"ce09c436b49af331a32f1152ede7dd18":"1. Begär finansiering",
"d2226752d3065fbad4744924b4a0ee73":"2. Granska och godkänn",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Granska och godkänn",
"82bab92f1c8030888550b51521266c53":"3. Betala med virtuellt kort",
"2ecd62e02e507f8e839a55eac0a04886":"En kodningspost med ID {entryId} finns redan.",
"5dfd25849740fbce8147838b0d5094eb":"Ett smeknamn, t.ex. Marknadsföringskort",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"En medlem i vårt kundframgångsteem kontaktar dig snart.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"En person vi inte kunde slå upp",
"976b50e70e4433e2786233979d243445":"Det finns redan ett team med det här namnet.",
"35b61d21ac11d5fc31ebe58541263445":"Godkänn din inbjudan att gå med",
"a372cb2d4204654f52cd9170fb10d374":"Redovisningskoder",
"110fc66010b6b91cebee7a59a2067aab":"Redovisningskoder",
"db862bb04dbdc6d04e47063f33be8865":"Redovisningskoder hanteras genom kodningslistor och poster i listor.",
"d6e3848e824f077396be4351eb58edd2":"Åtgärd krävs, godkänn/avvisa ny begäran från {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Åtgärder",
"476e728ea824229f52438e543818bcdd":"Åtgärder",
"3117746cb7d4ab815ec98274ec4398d5":"Åtgärder",
"3ab09b66a7f16219d8135977a2a76d10":"Åtgärder",
"ffafa66eaab0409a2e90aaeb43796def":"Aktiv",
"0447e0aa5d00dbdab11b5546793c4479":"Aktiv",
"8ee51d21b05466f7d962d4ff5a0b384e":"Aktiva kort",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Aktiva virtuella kort",
"89c6ec05861aa914304ba35bc6744124":"Aktiva kort",
"5280659bcecaf8344c57790f9962a9a2":"Aktivitet",
"8a15368ae286fa64e1cb7624cd410fc6":"Lägg till kodningslista",
"8ded7e09e86be231b1165308329ac76d":"Lägg till lista",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Lägg till administratör",
"695bb4bc29e2df769ddf7427ab71012a":"Lägg till godkännare",
"4a1d4516cab00b7c5ecdb150e346e5db":"Lägg till godkännare",
"56869851b27c2915f5a7fe6a33b91ac7":"Lägg till kodningslista",
"8f8691057c3994aa61f9994202680c87":"Lägg till kodningslista",
"69f7a406b79d19bf8082ed245ab29123":"Lägg till kodningslista",
"48a92ca1cc63018707be99b13196369f":"Lägg till företagets administratör",
"a679db9ff80d0e5769ae1ba311c7916f":"Lägg till domän",
"8f5e8078be77146ba7f3be49ef948906":"Lägg till post",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Lägg till meddelande",
"f8a4b14162e19995074e63135b65c84d":"Lägg till nytt kort",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Lägg till personer",
"c670549d0b898bb6a571eb2cd7804a6e":"Lägg till kvitto",
"eb937098e77d8a6b0565ed72357dcaae":"Lägg till kvitto",
"1e154f31c31b63a459f3956e380b3ef9":"Lägg till valda",
"ba24db9956f156a18b32e653552ff682":"Lägg till personer till det här teamet",
"52bd93fb5489b25167bb541f7c6504ae":"Lägg källkort",
"21ab3f08eb507dbc76df166eafeb393a":"Lade till {fullName} som godkännare!",
"f879c06507d3076bbf52ea4fd2df850e":"Ytterligare kortinformation",
"21e934ae30eece4a894337701f497638":"Alla",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Alla kort",
"8fac71195e2b506e029752e4864b6a5d":"Alla medlemmar",
"f9708417df16227742e0545a8642eea7":"Alla tillgängliga användare är redan tillagda till det här kortet.",
"cb5abf070f8cba7982205038baab77bb":"Alla kort",
"dae7d602eec2c84dea0d80ae25e56e6d":"Alla kort",
"0b4bdfde6578668b6018d3615a1ad30f":"Alla kostnadsställen",
"97b875b81f54b4432d998a3e47d949cc":"Alla team",
"f0ae83969f7c20a1964caf5156dae966":"Alla team",
"d87455601a00282078582b36a3825b7d":"Alla team",
"24c7b7f55882ea94a525392ca8218fa0":"Alla team",
"8f860e35b64a8f447bded1e5ea66a2a8":"Alla virtuella kort",
"e403956ecaa6fb33ec1fef5c49d71042":"Alla virtuella kort",
"4e6f4e8f25f61d40e1eba77de94f6458":"All ekonomisk information krypteras och ligger på servrar på säkra platser.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Summa",
"a4d099d0dbe3889b0995546e6ed28932":"Summa",
"6c0ef32aae76bc31a61e2124f542bdf1":"Summa",
"adec89c93525f1a8d1c64d856f405e27":"Belopp: högt till lågt",
"18e632a170203894f40deed1d33ee552":"Belopp: lågt till högt",
"a7944575cafef9126a499a1b06999fe3":"Ett fel uppstod, försök igen senare.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Använd",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Godkännandealternativ",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Godkännandeinställningar",
"492eedd88cdd7bb45f39c6594af748c4":"Godkännandegräns",
"437d490afc8d226f8c7d544f4e35d011":"Godkännandegräns (USD)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Godkännanden",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Godkännanden",
"17adb7fe866e32055b1469fdb7e82795":"Godkännandeinställningar",
"7125aa13db437084e1d27d3ff38f22e2":"Godkänn",
"84df787ff5746f9dc3cf0146becef4fd":"Godkänn begäran",
"7a568654d00245b0bab6c90a1b67d196":"Godkänd",
"179c3e187ad8edeb8348cff222b938bf":"Godkänd",
"ad058d17725870df9abbd4f5ec217636":"Godkända ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Godkänt belopp",
"4d58ba5c0e03fec1556c243be106e4a3":"Godkänd av",
"7d3d697fc99f6c4641b326623743f7b7":"Godkänd { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Godkännare",
"8c45e443fc6748190719d79a092e2528":"Godkännare har en maximal godkännandegräns",
"4fe56aa6456c108cd288a4ec8acf435f":"Arkivera",
"a9b6f93de9630064be9809130aa1e195":"Klar att ta kontroll över dina kortutgifter?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Vill du ta avbryta den här förfrågan?",
"301bfc2ce058f49e0704fec3d162b5b5":"Vill du omtilldela den här förfrågan tillbaka till dig?",
"2181faf8d9973d1f4138e401a762086e":"Vill du ta bort ${firstName} ${lastName} som företagsadministratör?",
"b982277f29055d0ef6e295fe2006d9e0":"Vill du ta bort {firstName} från teamet?",
"456c0ccff14acb808583cd9dece857b6":"Tilldelad till",
"bde315b9cf7fd55f4b65aabbbf38d462":"Minst en ansvarig eller teamägare måste finnas tillgänglig för att använda frånvarande.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Autentisering",
"1961ec69dd0c8b5718c831312e7f3d0f":"Automatisk inleveransinsamling",
"e39f2477cf34d726936a5b5c2b48a4ad":"Skapa automatiskt korrekta avstämningsrapporter som innehåller information om köpare, inleveranser, kontokoder, med mera.",
"434ca07dfde0eaee14f094b7c75a6eae":"Tillgänglig",
"eb85d8f50f2c89871b794f7d54279b22":"Tillgängligt saldo",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Tillgängligt { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Tillbaka",
"c8258f8cf9e20f6bbb89d957de40e820":"Säkerhet i bankklass",
"d46c22a3f36dce568c07fcea4368b231":"Grundläggande inställningar",
"fb481c8e517016094b4fdf618c5fa088":"Faktureringsadress",
"79a9f821923c5a04c076902fef684281":"Faktureringsadress",
"877a08b8347052c83aa0b689baea81e7":"Faktureringsadress",
"97f018458e2627fac37d24846c7b8922":"Genom att fortsätta skapa konto och använda Tradeshift Go godkänner du våra",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Genom att fortsätta skapa konto och använda Tradeshift Go godkänner du våra",
"912d1864f0068c764bf4784020795d49":"KORTINNEHAVARENS NAMN",
"d53474c7fd99fbeb69481ce9d358aa09":"Får begära kort och ha plånbok till alla sina kort. Varje medlem får en standardgodkännare (ansvarig) till sina förfrågningar.",
"f30df2cab105ed3f8b4264aeb9800abd":"Får godkänna förfrågningar och skapa kort till medlemmar de hanterar. Ansvariga har en instrumentpanel för att spåra utgifter för medlemmar de hanterar.",
"03ed9eccc71ef59165292eac542d3050":"Får visa alla förfrågningar och teamets utgiftsaktiviteter. Teamägare får även en teaminstrumentpanel för att spåra utgifter och teamplånbok där de ser alla aktiva kort.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Det går inte att ta bort den här användaren. Du måste ha minst en teamägare eller ansvarig i ett team.",
"a0b70642629a55ea0ab6fa3414d342e0":"Det går inte att ta  bort användare med väntande förfrågningar.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Avbryt",
"5446624d3c364ac05ddf8d6663da2796":"Avbryt kort",
"53f3815c4fa3340b29efd81e627d1e6b":"Avbryt kort",
"12257ea2a871b7fab72245b5aafe6424":"Annullera anmodan",
"2aafb7eb4d56a516c4bb0754f0812df1":"Avbryt kort",
"caae12601b01e2325161e95107f9e332":"Avbryt kort",
"6ad1e57b1e4c7accbe166c948883077d":"Avbryt med ett klick om komprometterat",
"f6527e49e6d5c989624507a583e23f39":"Avbrutna ({count})",
"1ad3bf5121641dda511f42942af05a90":"Avbryter du det här kortet återkallas inga väntande avgifter. Sök efter kvittningen",
"aebd3826a195afad0a93050a7cc5d73d":"Avbryter du det här kortet återkallas inga väntande avgifter. Sök efter kvittningsposter för dessa ändringar i kreditkortsutdraget som skickades av kortutfärdaren.",
"a5b5136947da562f91b75daf8156f50e":"Kortinformation",
"9ed31ad23a1578ce480d31207decf1d3":"Kortinställningar",
"def15f2e007fc69886230fdb2511305c":"Kortinformation",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Kortinformation",
"67cfd260078ca32027c2fa4a4a2d29f4":"Kortinnehavare",
"4458819ea60c68c46e551a8f5cc163cd":"Kortnamn",
"0858bcbd506fa3240b165e59d2f18aae":"Kortnamn",
"5212736dd18ee2c31f3650ad2c2c7130":"Kortnamn uppdaterat.",
"c96d62fa510c3356dc73a91d1a80e262":"Kortnummer",
"41307fee4c32aefb5383a98fe3d6e45e":"Korttyp",
"3f8045c3358621dad4346a44de0c05a8":"Kortinnehavarens namn",
"879a6b452033cf5f3a4bb3e1837a0386":"Ort",
"593334553ef852024e172505ec3c5f21":"Klicka här",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Kodning",
"df931751d5790d74fa46707aaf7a26ae":"Kodning 1",
"7ac7154bc776aff52a71b0d83b860561":"Kodning 2",
"586fcf19136ae5e26795e9055b2e2995":"Kodning 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Kodning 4",
"379bb8e71bb090badebbb0751de86f11":"Kodning 5",
"3e84d13627b3d090ab2b8470af98340a":"Kodning 6",
"33b94b581f3792049992663396e51cb8":"Kodning 7",
"b73fbc32e439655833ee99126dd0e55f":"Kodning 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Kodningslista",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Kodningspost",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Kodningspost raderad.",
"cada62a39ee72d790dcdbb25160f8783":"Kodningspost sparad",
"f645bf50888943ee1384693e56a0f475":"Kodningslistans namn",
"a999182acbcd77c15299f79181b630be":"Kodningslista sparad.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Kodningslistor",
"d0b96596ce6baec5aabb8a0690ab30e1":"Kommentarer",
"ff9cb8842f832d21fb160b3d3784ce79":"Företag",
"715b59a64e686eb91a21114644ffe39e":"Företagsadministratör borttagen",
"8758e9a01253c097a42393fda9610715":"Företagets instrumentpanel",
"e118b41009918b326db6746856831cbc":"Företags-ID",
"4dba88b05b3fa8875128e19e88c512b3":"Företagsinställningar",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Företagsinställningar",
"f2ea6d33de8182d97bf2f13354af4893":"Företagsinställningar {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Företagsadministratörer",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Godkännandegräns för företag",
"7e2f778da14a726b53e05926a3483f2b":"Företagets kreditkort",
"7313fd1db3d63858586d1c0f89251d6e":"Företagsnamn",
"07721e91dd10ff8831f0d24e47cc1eab":"Företagsnamn",
"203221610ca4d347063c037e1a732ae8":"Företagsinställningar sparade.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Bekräfta åtgärd",
"d694cec09cdbc02b1ffe22642a63faa1":"Bekräfta lösenord",
"512801312a133f29974f2259a455f39b":"Bekräfta lösenord",
"f54c38ed0b9869d929ffc0bd5310131f":"Bekräftelse – ditt konto skapat under Team {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Kontakta företagets administratör för att skapa kontokoder.",
"7a6a4de077398e8e138aec36e3b3d305":"Fortsätt med SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Med ensamrätt.",
"51f0d8e04f6ee626b866b37833dc2b61":"Kostnadsställe",
"e043f60a02f157d0f31a42f0db67cc87":"Det gick inte att bjuda in {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Det gick inte att skicka ditt meddelande, försök igen.",
"ae28083f22a0b2fc5637614bce0976e0":"Skapa anpassad lista",
"738078dc992208e05406f935995dc0c3":"Skapa anpassad lista",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Skapa ny lista",
"0f5783c3921b3d7398d281644bbe5bde":"Skapa en begäran",
"6d62f934f2304701f88bdcfbc688de7e":"Skapa konto",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Skapa ett konto",
"f6b7d0d8531ea268f988005dd2147b2b":"Skapa kort",
"1a083675d1453448e4645403bbe52dd9":"Skapa anpassad lista",
"c10ab11bdfb001da74850ed89c45482f":"Skapa ny förfrågan",
"43a9c06398408bfe77d06ffc2c1c3b87":"Skapa ny förfrågan",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Skapa team",
"3b8e665101b9b3657f8a68c5305947b7":"Skapad",
"40e91f2907181ffa8e62567b3a407e60":"Skapad av",
"8929f7aacb969ef6353aba9903eb362d":"Kreditkort tillagt.",
"d86688c26e8ac5eb9facc971b6fe5155":"Valuta",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Valuta",
"500ded53f64e737a5e4337675ac9b7ce":"Anpassat listnamn",
"b79a95f04fd829fb2e8d0313e522a94d":"Dagligen",
"ef445eab224fc599e4103c9b1745a4e0":"Instrumentpanel",
"788452c3ad2b1450b6d8d116b863e635":"Instrumentpanel",
"fb7add662cf7042355319a975fab6ae3":"Datakryptering",
"6bded249dbfafd14490eea8dd37e1f51":"Datum",
"46e908a57cb09ef947af8c4a93a09716":"Datum",
"e4213104a55eceb60e00408699438dcd":"Inaktivera team",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Inaktiverar du teamet avbryts alla virtuella kort och teammedlemmar, ansvariga och ägare får inte åtkomst till teamet. Endast du eller en annan företagsadministratörer får återaktivera ett team. Är du säker?",
"0d0d5677bee2b99884802638e507160a":"Avböj",
"b5e3483aae9d36ce1440fe02904d6da1":"Avböj begäran",
"00f3db97287cb17ee9c2a131f2141a65":"Avböjde",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Avböjda ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Standardgodkännare",
"b77589ac3ac2317cfc96d7013658d5c9":"Ta bort kodnings-ID: { entryId }, post: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Detaljerade utdrag och rapporter",
"5234dfc4220ccd18a8b62fe84e372248":"Information",
"37929896817a6744718004374bb42fd8":"Information",
"dd0a6194e8a6ae14f3e1379a1640de15":"Tänkte du ta bort {fullName} ({email}) från företaget?",
"1a98048b34090da8b199135ccb4c1186":"Nummerbeteckning och CVC döljer verklig kortinformation",
"3cf132b60fe16b816ca227bbcad60537":"Avbryt inte",
"dee57797e14ccf852aa2bc545bafdf6d":"Ladda ner",
"375ae8224d7d35393974775cebe8605a":"Ladda ned användarlista",
"54b3bfd45a293b441d441aab1a50e644":"Ladda ned rapport",
"60cf3b94e5df14a504825068928fc118":"Ladda ned rapport-CSV",
"7341a4c73c0b688484304c37da4a6d8e":"FÖRFALLODATUM",
"f628f8ad44fbd6c70c99fc778407b666":"Sök enkelt historiska transaktioner och utforska trender i anställdas utgifter över valfri tidsperiod.",
"94bb409d034ea20697ae09d812e55365":"Redigera anpassad lista",
"2974e704676cb79666bc236d8658fa97":"Redigera profil",
"9ff205f99ed4002d049f855f965ca6d8":"Redigera användare",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-post",
"0534c00af758d42ebd8b0bd772eab074":"E-post",
"0c9aa1fbdf925065d25161eab5e51d95":"E-post",
"ea78fac68766a48b1b0f41305b7e9898":"E-post",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Anställd begär finansiering från företagskortet för att göra köpet. Begärda pengar kan ha inställda utgiftsgränser.",
"829cff16ce0b13810c914c161b2a9fba":"Aktivera alternativ hämta användarfunktioner",
"741387c7bd71e2b4a9601af9a6b9490e":"Aktivera automatisk vidarebefordran av godkännandebegäran",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Aktivera förvalt förfallodatum för virtuellt kort",
"bb319710dad93e78cc6f58c615bdf210":"Aktivera vitlistade e-postdomäner för inbjudna användare",
"4700ced24cd278c3149ffa983006035c":"Aktivera filtrering med kostnadsställe",
"3fe06ef8f551c4cb4c0375362e25521d":"Aktivera globaliseringsalternativ i användare vid sidan om",
"2787d4bc9752272ac36b499ce7ad93ab":"Aktivera virtuella kort med flera användningar",
"6ee44e2280c9ce5009568846cbc3dd14":"Aktivera frånvarande",
"1941ca87053df0cebc6091ede92c18ea":"Aktivera personvy för företagsadministratörsanvändare",
"e50282c2ec7afb948d2c0b428db5d5ff":"Aktivera inleveransuppladdning för transaktioner",
"268694cb2172918fe60f835919bf20ec":"Aktivera inleveransuppladdning i kvittningstabell",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Aktivera självgodkännande",
"d17c7acf598b569cce8a2bb97e0bb215":"Aktivera virtuella kort med en användning",
"cd731ca218c9e14caabefafe003cfa6d":"Aktivera virtuella kort med prenumeration",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Avsluta vidarebefordran den",
"c1f665a814894b17d603b7052b631d90":"Registrera nytt kort",
"6b6cea93e07e596be70f7b76801e1516":"Registrera nytt kort",
"7d6ac1ac9cba1620b61908842ebb106b":"Registrera källkort",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Registrera källkort",
"21a642b339c3eabf2789c0b05dcf828b":"Registrera första företagskortet på GO.",
"6efa8dca4fba23a190838746879fd704":"Ange numeriskt ID",
"02c3457577da9dc8dba6e6f9380a248e":"Ange ett unikt gruppnamn",
"40feb3fd1fa529cb599aeac9607664a4":"Ange ny post",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Exempel: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Förfallomånad",
"b8bc74aeeb2de3492891c97361de107d":"Förfalloår",
"6a0b8d521500014d99dd192d8ce8403d":"Förfallodatum",
"8cf274169a732340430fe7cc5236e0e7":"Filstorleken får inte vara mindre än {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Förnamn",
"7458b55e967e51791d98e77458631e44":"Förnamn",
"3bbe52e71bc5c212cea77983f1a5b748":"Förnamn",
"9b49c88fa20cc006baaac15c857c8781":"För ett eller flera köp",
"bb7a2907073bd760f4e82097132637b1":"Glömt lösenord",
"26bdfaa592f3629273f13c22435b323d":"Glömt ditt lösenord?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Vidarebefordra",
"3f9fe6adc14ce5776097170653a24462":"Vidarebefordra förfrågan",
"b774e35683f5737c36bf0cf3d6785973":"Vidarebefordra förfrågan",
"628faa5054a912b1e470414f77057e6b":"Vidarebefordra förfrågan",
"852b4065e1837fdc77940b6411a6d902":"Bedrägeriskydd",
"616551bd10d95aca439401d6a7769d96":"Fraktbelopp",
"a65a230918e9c11e1ef8824745e26a3d":"Kom igång",
"7d4c4466b7716347ce3673acaa1983f7":"Få ut mer av Go",
"11415a07658cfef80eb166896d77ad98":"Förbered dig på med utgiftsinsikter. Tradeshift Go passar nu bättre med hur företagsteam gillar att arbeta.",
"ac0ff5d14286ef74614a9c6021f08f69":"Få utgiftsövervakning och budgetspårning i realtid, skaffa information och öka detaljnivån i alla inköpsaktiviteter.",
"db9071ab833054307252a936e7f60e1b":"Kom igång",
"24dc4bb00841b004031fca3a9bb9bf51":"Go-kortbegäran avbruten av {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go påminner anställda om saknade kvitto som går att bifoga genom att helt enkelt svara på meddelandet, eller när det gäller ett e-postkvitto, att vidarebefordra det till Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go fungerar med ditt kreditkort för att garantera samma bedrägeriskydd som du förväntar dig hos de främsta finansiella institutionerna.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Goda nyheter! Din inköpsförfrågan har godkänts på Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Har du frågor eller synpunkter? Kontakta oss på",
"9df1d3de68d663b848101df3c7b58e39":"Så här fungerar det",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Jag är säker",
"84074732eb8d1b4094404cd750baf949":"Utan värde gäller företagsgränsen",
"138e8bd6102a7eea70a123ff03b1cd79":"Utan värde gäller teamgränsen",
"e5756af4d7e5ad1236ccfbb858428421":"Om du inte registrerade dig på Tradeshift Go med den här e-postadressen",
"4f004f75f47dbfecdb6d09990596e530":"Om din e-postadress ännu inte är registrerad skickar vi ett aktiveringsmeddelande med information om hur du går vidare.",
"123837f267de602fb05361b3b1197c1e":"Om {username} matchade ett befintligt konto skickar vi ett e-postmeddelande med information om hur du går vidare. Öppna din skräppostmapp om du inte har fått e-postmeddelandet inom några minuter.",
"45efd96cd93525a9c17222217a37d324":"Bild- och PDF-filformat stöds",
"fa6ae823f9ab8bf91c246efe2517956c":"Importera kontokonfigurationer i QuickBooks, NetSuite eller Xero direkt till Go. Exportera de senaste transaktionerna till din redovisningslösning med ett enda klick.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inaktiv",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Indata innehåller ett ogiltigt tecken.",
"62c7be09da43f8f96a60ef3967eddc2c":"Vi presenterar det helt nya Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Ogiltig e-postdomän",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Bjud in personer",
"3809b41b14c55c3fcf54cd813d8e21f5":"Bjud in till team",
"fc90a11805961b0d7b5a5545e4d9bc05":"Bjud in användare",
"6f7f4acde92d267417f5d8a60d32400a":"Bjud in fler personer",
"09ecd2ca15bb5c38dc10f653118346cd":"Bjud in ny administratörsanvändare",
"3f576ce504f53f972442a63a170c5418":"Bjud in ny {role}",
"0215e052437ceea4e5cd562431b34ced":"Bjud in personer",
"8aaf2c77df644b7e8524120f66da7d5f":"Fakturabelopp",
"1b7dc8290f1205f092cc9133e0cde385":"Utfärdandedatum",
"1a6fb210308df8e0135e49e76aaff31e":"Fakturanummer",
"613da3d0cd586ef0360e0d93eace9508":"Utfärdad den",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Det tar",
"3322b671105c65511fbab1966b7fdcf6":"Språk",
"b1d946192795a58aea6a61f4f923acb7":"Sista fyra siffrorna",
"b3c6b8db4ef6dcdcb451303e65548816":"Sista fyra siffrorna",
"0382123b9c847000fceff2484c665e39":"Fyra sista siffrorna",
"19f7c0055e150e9e3e66e85c2993799c":"Efternamn",
"662c84bede8a7bf91ab15bcb73b48b49":"Efternamn",
"cff8fbef5aac2523344b9535bd56973c":"Efternamn",
"c3510b2184a92ff12e07a2d1b9a461e6":"Ge anställda möjlighet att begära åtkomst till företagskortet utan att visa kortinformation och samtidigt tillämpa budget och spåra varje köp.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Det går att skriva över gränsen på team- och enskild godkännarnivå",
"9bf5d56627e500cad9f7ad21c3e7966d":"Begränsa till vissa utgiftskategorier",
"e80a6d4232e52f6bfabee02e17403b59":"Länk ett av dessa källkort till det här teamet eller registrera en ny.",
"803570ab9f0fba5ff35ae2749200501f":"Läser in kort ...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Logga in",
"ad96c1ca962a883652dadb845f439415":"Logga in",
"072ca67e735adbed54acf41321370701":"Logga in",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Logga in",
"b246fe8836c906f1b56342378ca88895":"Gör, godkänn och övervaka köp i team som speglar organisationens struktur.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Hantera kvittningar",
"95d672ab621e531ae1a69798e0e4b56c":"Hantera alla virtuella kort på en plats.",
"96f2e88a167f476413b35aa711709e6e":"Hantera kvittningar",
"151d8e6ceec242d482fff60da8311449":"Ansvarig",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Ansvarig",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Ansvarig får varje begäran till granskning och godkännande. Det går även att ändra utgiftsgränser.",
"4f449985e83c1f9ca2e8f82b0707a371":"Ansvarig får varje begäran till granskning och godkännande. Det går även att ändra utgiftsgränser.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Ansvariga",
"27f8111a955975ea6fd04f748d24cb4a":"Maximal godkännandegräns",
"5b1d9cfb78c55e75b47c62435d9299ca":"Medlem",
"41e307c1874e2e82296b226e9bae4a5f":"Medlem",
"6f9612aa1582373c2c1ba4e144132100":"Medlemmar",
"590429a0fcc42af85648d0fd70601dcc":"Medlemmar",
"3f50e62c8488d83ad84f69ebda853897":"Medlemmar",
"e0fc0acda5b74b93e797756dbbba67bc":"Medlemmar",
"c7ef9eda38b7eaee1f20031387a8bde7":"Månad",
"40dd08b507752bef4e3c4b36838317d5":"Månatligen",
"d712b91f7498f212047aad4c6d0f8088":"Senaste",
"12110d95b3d7a495afa4f28ac190f63e":"Senaste",
"2f11ce3865f79ee7e5ee64d728082264":"Senaste",
"eadc93865655cffc6a9863fdda5693e1":"Måste bestå av minst tio bokstäver och innehåller både versaler och gemener.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Måste bestå av minst tio bokstäver och innehåller både versaler och gemener.",
"50d0a69aa59df5201018771a06ea12e7":"Måste vara unik",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Måste vara unik.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Mina kort",
"c36602f50d35c2816f0ba8a196b70876":"Mina begäranden",
"ff6e372aa3eb685a39a701963db35b2f":"Mina begäranden",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Namn",
"1c7514b561a2591a914225813d30d642":"Namn",
"d621826c1ceade262589af04e655e824":"Begärt nytt lösenord",
"35dc3304b61a9e69dc3a393123658837":"Nya begäranden vidarebefordras automatiskt i den här tidsramen.",
"e788124801b4727d121f60d059a2a4a7":"Nästa",
"eca804f1d53dfd984ffd93e2d00fc284":"Ingen godkännare tilldelad, kontakta programadministratören",
"abdf7e93f8509399a63108a2b1a25546":"Ingen aktivitet att visa",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Inga kort i plånboken",
"87127e1e9f5bf922d0a4f948b68697c8":"Ingen gräns",
"40512efa6b75ef0c7924b1ee3070fca9":"Inga medlemmar att visa",
"dcda493afcb2415d5ca49a17d1af6f50":"Inga kvittningar ännu definierade.",
"f0efb26e623bb990532e375d5c8875ec":"Inte tillgänglig",
"bc0cf602d1978e814070bb552e576a32":"Inte mappad",
"09e126c317429ccb52ad323c32293648":"Antal betalningar",
"75b00b969b9d3b520929f1e652637b70":"Antal betalningar",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Antal betalningar",
"bd44ba180aa356c5755fdedd32adf03c":"Ojdå! Det gick inte att skapa en förfrågan. Försök igen.",
"c396b3728dd7393aa9e5427990c132a7":"Ojdå! Det gick inte att ladda ned rapporten.",
"42c07764b07dea1ee7de7605721c3f5f":"Ojdå! Det gick inte att skapa ett nytt team.",
"e3a385878ec94bac12a99490d0a8e85a":"Ojdå! Det gick inte att skapa ditt konto.",
"17f2dae79677017e2c2e879578c4e09a":"Hoppsan! Något gick fel. Försök igen!",
"78c135de545c1663c9768226492a44a3":"Eller använd enkel inloggning (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Frånvarande",
"5d7e45f169e2f6968dc58f43b5267353":"Frånvarande",
"ee589a20c262527db970671fb2c4f6bc":"Frånvarande",
"4fd0c753fce49a016b98d988a3895223":"Översikt",
"2789c5d5c9c042858f45aadc4e0de058":"Översikt",
"083b7090b66ebd0307a18499ac7b676e":"Sidan finns inte",
"a21d205920893cf579a7655a1430fe30":"Överordnat kort",
"fd35b7ce938e404fdb65de3bb749c528":"Överordnat kort till rapport",
"e8c7d2eea7db66ecb44751091a582304":"Lösenord",
"bb79e316e00fd21138750b6986e44455":"Lösenord",
"6cae2279088580976d84119d95097cae":"Lösenorden matchar inte",
"4baec0159d3074f84c0eebcc09ba4cd8":"Lösenorden matchar inte",
"b676587115243921602742cb8f696a6a":"Pausa skapa kort",
"db4a26c871ef0b970b74e1f71763098b":"Pausa att nya virtuella kort utfärdas",
"33fc1e6d01b785e3a815c28ef7d56667":"Väntande",
"6ea62e4811b68df48b467e8d367aac2f":"Väntande förfrågningar ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Personer",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Personer i team kan ha tre olika roller",
"e2d34cb1409b9778d34c4b74ecee1df6":"Ange ett positivt heltal eller lämna tom.",
"8da247d5d736c87122404eff1e5c0c73":"Ange en giltig gräns.",
"65e154267ac283d963cbc7be832969d9":"Ange ett giltigt värde",
"28b4450d417065a69a395f567967d46c":"Förinställd med begärda utgiftsgränser",
"7bbf151d8493170f206a23c2ecc6acd0":"Sekretesspolicy",
"51602962f2d1e7515943b4a6c66562ce":"Profilbild",
"a51a17940c70a3123da4d68bed562c40":"Profil sparad!",
"4d398249d01ab72d697a7e219493c637":"Leverantör",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Leverantörsmappning",
"89b1deb2ffe12a13941c362abaeab01b":"Publicerad",
"d8d806d48052159e3ff3b224f3f53586":"Syfte",
"089917619c78af2efb30be0bb7ce076c":"Återaktivera team",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Orsak",
"e8484c78fda9f453eeb942ba4945f040":"Tilldela om",
"ff2fbc1172ba26776ad0982624cd2713":"Inleverans",
"588e690db177ee0c7a7cadb39cbd5efc":"Uppdatera pengar varje vecka, månad, år",
"32955937daca05b2084556f248692baa":"Registrera",
"f15bb755ba24abe6ab106637292175ec":"Återstående",
"579782461133a8b8cc6bd77798961d82":"Ta bort kort",
"e475edc165372f8c8deb532aa85d9950":"Ta bort lista",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Ta bort lista",
"f9769561315b964d0b3b4887ec208977":"Ta bort från team",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Ta bort inleverans",
"ee181cef581fc8e8217e66b5762db898":"Tar du bort en lista går den inte att använda och raderar alla befintliga poster. Om du vill ta bort en lista tillfälligt och behålla posterna går det att stänga av publiceringsinställningen. Vill du ta bort den här listan?",
"feea41d0bc1d577a07c8b6e1297abbde":"Tar du bort ett källkort från ett team inaktiveras inte befintliga virtuella kort.",
"d09483824a2e0d6194a4c3f32311e752":"Tar du bort ett källkort från ett team inaktiverar inte befintliga virtuella kort. Vill du ta bort det här kortet från det här teamet?",
"7ca4b1bf1346e179a29da97f260a93a4":"Byt namn",
"db195ec9c818b8e92da142374e20d1bc":"Byt namn på kodningslista",
"2fd93e229fe9ea754808805d3fef9c13":"Byt namn på kodningslista",
"aa1e74d32805823f802a11b33c60c38b":"Rapporttyp",
"6ac78d97a8cb7f060e43e596e3e23535":"Begäran avbruten.",
"25deccb1980291a0fa9997109c731440":"Begär demonstration",
"9f2754d87686903b1500ce8e8a55b377":"Begär demonstration",
"92ac924f72679f897e26f3e615be8c22":"Begäran vidarebefordrad.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Begär information",
"270185007853cd779011ae0e8433f723":"Begäran omtilldelad.",
"e5a059c9ba2691910321339cce989f9d":"Begäran inskickad.",
"77c88a231edfa7075114d91d2397e42d":"Rubrik till begäran",
"cee91b833c4358acf0c554f746557e71":"Begäran nekad.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Begäran nekad.",
"9e56abbbf87420eeedd625ce786515c7":"Begärande",
"b4cb4426e44fee9580af71912a764536":"Förfrågningar",
"df309e888c29fc447d0309329b39ce93":"Återställ lösenord",
"5daf278da19ecbaeb7b96554cb4db10f":"Prova ansluta på nytt",
"31e9501526d2114e4a01fdbf076de097":"Roll",
"6fbceb787429f6f81117a1d5a58b4610":"Roll",
"473b814ca86841ef672ccf9f7dac28d5":"Roll",
"f1e16d5fd643e039d865b6aec7c986f9":"Roll",
"ec78345a5975bc0f8efaee5256b43c2c":"Spara",
"2cecea57b47d8f4a45c706287ff7d64e":"Spara ändringar",
"15774be5c338a39169917c7d32781dd0":"Spara och fortsätt",
"844095b4b8f580737b8717672501048e":"Säg något ...",
"7e66f676c2e7b04514aca3a0935530f0":"Sök med kodningsinformation",
"5293ce9335ec2d176a6e838bf76404c6":"Sök med beskrivning",
"efab3b0c95f263cdd0a8466329a387eb":"Sök med beskrivning",
"d227b38dcbc4548c092b0836a7101506":"Sök med återförsäljarnamn eller transaktions-ID",
"3629383abf1d5f85efd759f718159b40":"Sök med namn",
"a034fcd6719228b28a6fc22d3a46ea8a":"Sök med teamnamn",
"8ac7e0d63f343b548482d5f367f5b3d9":"Sök med teamnamn",
"858323eb52cb45446597ee46330a0540":"Sök med ägare av virtuellt kort",
"111bfcbff671e13de117ed5195fb6773":"Välj",
"bcfecd59eb93e5314873d84ddf35aa58":"Välj",
"909a4414ea573218bff2d533eaab46ea":"Välj slutdatum",
"114b206ca1ff1c0bc52abef342059b2a":"Välj teamalternativ",
"81a02e424615fb41a20b8d0f13441c88":"Välj team",
"b135b36495618e6692e7fbaf0a0aa83e":"Välj månad",
"677e90855e164c00a28b5c4763ac67e0":"Välj en roll",
"d01570f3774738395b8ba3c64ce54c76":"Välj tillstånd",
"7dee3f06c70626881efb0cd6af6b63e1":"Välj användare att vidarebefordra begäran till",
"4c7bb810835a1540ddc1f6501da5a51b":"Välj år",
"3ab198d2678af2b5b32180e4b39602ba":"Välj godkännare",
"f502ba79f2bd010c5e77891812400958":"Välj kort",
"936027182d446e27ba209ea921ef7502":"Välj förinställt förfallodatumintervall",
"b22c893254ccf3bd34faaee511cf7ca8":"Välj varaktighet",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Välj från förinställda kodningslistor eller skapa en egen lista.",
"42cce5fa376b45bf290f86c684d69d96":"Välj leverantörsfält",
"b761773d46699b60e8d6874b85593374":"Välj team",
"3a9396756942ede98d0cb6afc1032b15":"Välj deras godkännare",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Själv",
"03e9d2c128af288126d94f631c5396be":"Skicka instruktioner",
"299a7e961804e2d649f06b5dc4362c29":"Skicka inbjudan",
"155e7b3f7db7bf10b03b5f41de54eae1":"Skicka meddelande",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Ställ in förfallodatum",
"f89ec96a4b5bb639488ab742efa76a6a":"Ställ in nytt lösenord",
"7d5377ad70d57728a45d3c0639de5544":"Inställningar sparade",
"14313f11dee735f2144953e720776c28":"Kvittningsbeloppet kan vara ett annat om dricks eller andra avgifter läggs till i köpet",
"199000fe0cda0adaaa9b864aa7447cb9":"Kvittningar",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Kvittningar",
"6e6ac6ff3bd722e37874fb1ea7115818":"Konfigurera lösenordet",
"9b4ecf052980e80bf5c65a415d470a43":"Dela eller kontrollera åtkomst till företagskortet",
"ba746d78772d6be26cc508ba989becb0":"Måste vara mindre än eller lika med {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Visa transaktioner utan inleveranser",
"7d474b60aeb7cc25b519e806fca71e12":"Logga in med befintligt \n\n Tradeshift autentiseringsuppgifter",
"16080cd4e8319ef3e7de21b6c19d1437":"Något är inte rätt",
"8f358a370224baf849f9652810c68db2":"Något gick fel",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Något gick fel",
"99af87c475a4d40ef732deed96f11ed2":"Något gick fel. Försök igen.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Tyvärr! Det går för närvarande inte att ansluta. Försöker igen om {retryConnectionPeriod} sekunder.",
"84debc8e41646cd49ac2b14708ca6d73":"Det gick tyvärr inte att ta bort kodningsposten. Försök igen.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Det gick tyvärr inte att spara kodningsposten. Försök igen.",
"689d3418ee8f996ad25e152e80de63fe":"Det gick tyvärr inte att logga ut dig just nu.",
"b48efd4521ecd6428987d24636a4349f":"Sortera",
"776a95003d3ec1dd58af69aedbc08579":"Sortera",
"1b783238028c0ae085ca69ed823f4d1a":"Sortera med",
"be70d19bf13c935d653b2c4fe7d36224":"Källkortets namn",
"564f8c942e7d1bdda5251545612fa348":"Källkortets namn",
"aa284602c746aad4a6de6366c24221b8":"Källkort",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Källkort",
"389d471e79a43440b3278b0fc7187d9e":"Källkort",
"106342f689b57145817b00d6fe383f91":"Visst slutdatum",
"2a5e19387ff300509876e3bae99b7ab9":"Redovisa snabbare",
"ca34d586bbdb7f368f1009305bccbe76":"Instrumentpanel för utgifter",
"4583120998b44c0a5982cf85dd938d2f":"Utgift över tid",
"244d724806247953233a0bbfcbf8da4c":"Utgiftsgräns",
"d39b83dd151c8df6370460a458294ccf":"Spenderat",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Utgiftsbelopp",
"5de2859528e030ac1f2af115ebdf209c":"Spenderat { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Börja vidarebefordra på",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Status",
"1f1fe111548158ec842761eca7425ff3":"Gatuadress",
"31a0a748b1097a9e0aa95a811802bdd0":"Avstämning utan stress",
"111b782f3f0794c2294aefb6693b2c50":"Skicka in",
"013e2555920b51ad2a3d2c3dc482c471":"Skicka in förfrågan",
"1270cd8eb69ad64cc71e5de979423b3b":"Prenumeration slutar",
"2802336ad3d8ff658257b88a93f4e50a":"Prenumeration slutar",
"b28dd68454d7acbefafeefb42981fe16":"Prenumerationsfrekvens",
"907793bc80cf94d560e5f1a0250fdaee":"Prenumerationsfrekvens",
"29229e153308e4747edef1144de35a55":"Klart!",
"503eb46b80f374f39266e7a9aea8e63e":"Klart!",
"4f20e29cadadb9fbc7455bd4d80de567":"Klart!",
"62558c185ea2c06c5472d1cd534f6e56":"Synkroniserar med redovisningslösningen",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Skattebelopp",
"bf23b2fd889e3b12e0f0acf99f913eca":"Teamkort",
"b90a650376782a9e43ed6be6135bd6f2":"Teaminstrumentpanel",
"6adbec395fe1c4bf776f9fe5fa021052":"Teaminstrumentpanel",
"6f2695eeb68501dbcfddbb4d265b846b":"Teamansvariga",
"3b779ade9c960ac95a670db0cc12e56b":"Teammedlemmar",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Teaminställningar",
"dc5235e1b88320d3ba7501e4c30985a3":"Teaminställningar",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Teaminställningar",
"a2eb92bfd7502f47b3cd170bc68ac638":"Teaminställningar",
"f1ec84f051f19f0d0e301a709b13dc45":"Teamutgifter",
"fbacde3a262ea8696e30135e8f987a44":"Teamväxlare",
"74b9578ea5a620f5fef102188e42c02f":"Teamväxlare",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Teamåtgärder",
"3aa978fa7ef886621285b93257d428c3":"Godkännandegräns för team",
"a41f9df131c221a99926de7e2138653a":"Teaminformation",
"941c7da8f256f91ba3819f991315a100":"Teamnamn",
"c5d04e10cc2f0b53c2bda893eae09178":"Teamnamn",
"504a8ba642ba37c9a743d0fabb2bd371":"Teamnamn",
"e768a8e1e3d6a6bf470587dad75423fe":"Teamägare",
"e2d824067f8bd871756688c58c382196":"Teamägare",
"dca232ccc849c5ff5611255eb82baf53":"Teamägare",
"3b61bd586225790cf132591383d15448":"Teamväxlare",
"bc05b40509f0271c12cfaaef8e5319b1":"Team",
"39e73773d07a800673ec6600422952d6":"Team",
"daabb564bb3dcfb41de69492f854d573":"Team",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Team- och enskilda gränser överskrider aldrig företagets gräns",
"4b4fe189a48d8eb06d836afa8fb6317a":"Team uppdaterade.",
"2a17f9a8c610db29fec63fd530c29fdc":"Team som använder kortet",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Team som ser det här",
"317868f3804ba4ed5d99dd9919c798ba":"Användarvillkor",
"e8c9a9847db2ccd97524aa06f1af9d82":"Användarvillkor och sekretesspolicy",
"38852078519c5c46715d8a034fc4901b":"Användarvillkor och sekretesspolicy.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Tack för ditt intresse för Tradeshift Go. Stöd för {cardLabel} kreditkort kommer snart. En medlem av vårt kundframgångsteam meddelar dig när det är tillgängligt.",
"f7047b35a069d0790a0a348a29c846a2":"Tack för att du går med i vårt team på {companyName} för att göra företagsinköp med Go virtuella kort.",
"133b0a70b1a956889164621380c378f9":"Det går inte att slutföra åtgärden du försöker utföra. I en del fall hjälper det att försöka igen.",
"18f5a1872e156f1aee84ae321780d952":"Begärt belopp ligger över företagets begärandegräns på {requestLimit} USD. Försök igen med ett lägre belopp.",
"867e3d4d3c78990c6852fc5348706ab2":"Begärt belopp ligger över leverantörens begärandegräns på {providerLimit} USD. Försök igen med ett lägre belopp.",
"570f5f4658b03dda7a910f56c86a3bd3":"Kortet för den här begäran har annullerats.",
"3075c56105863546e3368ffead7a146b":"Kortet annullerades.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Kodningslistan som du försöker avpublicera är länkad till en integration. Kontakta support slutför åtgärden om du är säker på att ta bort den från integrationen.",
"756ade8d71ec487a4b48d7c4277ea239":"Kodningslistan du försöker avpublicera är länkad till en integrering. Du måste först ta bort mappningen innan du avpublicerar den här kodningslistan.",
"4f40335f7c018986c95458183bc0c136":"Länk till aktivering av ditt konto är ogiltig eller har upphört att gälla. Kontakta personen som bjöd in dig för att få en ny inbjudan.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Länk till återställning av lösenordet är ogiltig eller har upphört att gälla. Skaffa en ny länk genom att begära återställning av lösenord på nytt.",
"ded30f0e288c12125ca1a77f8d8fd600":"Orsaken visas för godkännare och begärande",
"f294897b38fc23dd8f5de52b54ceae22":"Begäran avböjs och den begärande meddelas. Vill du fortsätta?",
"e8c7200dc985df0a94a9d996968b4cc0":"Begäran avböjs och den begärande meddelas. Vill du fortsätta?",
"5f3d196a2ade3f97a2a48b773fec5861":"Begäran avböjs och den begärande meddelas. Vill du fortsätta?",
"b04c191cd6b4f27669a4f390311f156c":"Det finns inga teamägare eller teamansvariga att välja.",
"e25045557efbad7b52997b94a1fe6817":"Det finns inga {type} för den här tidsperioden",
"7260690577c795e999acae63bb7c51ae":"Det gick inte att spara teaminställningarna. Försök igen.",
"f2103024f2a9c6c0335a71c60567d6c9":"Det gick inte att läsa in din plånbok.",
"9776cb7eab9fa0d318a8144cd17de745":"Det gick inte att läsa in din plånbok.",
"77d28a14bc900be4360b570a82eb75a9":"Det finns inga data att visa",
"2b6feba67722337f88c1443909fe446f":"Det gick inte att lägga till godkännaren.",
"24b7212afb97c5d23faafa2ca4559095":"Det gick inte att lägga till kreditkortet. Försök igen.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Det gick inte att lägga till källkortet till teamet. Försök igen",
"5a8e1b922d1cee746ac6794599b10709":"Det gick inte att annullera det här kortet. Försök igen.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Det gick inte att annullera det här kortet. Försök igen.",
"22361c5d4df61ad7ae9792a3baf5729f":"Det gick inte att skapa listan. Försök igen",
"098755bfddeaf0428c7feb6b69af9b6f":"Det gick inte att avböja begäran. Försök igen.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Det gick inte att avböja begäran. Försök igen.",
"7d461b6b7b54dbfa9c83243217cd1053":"Det gick inte att ta bort kodningsposten. Försök igen.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Det gick inte att radera listan. Försök igen.",
"beb43d7800cfff47e08cd61842d953bd":"Det gick inte att vidarebefordra begäran. Försök igen.",
"f7775350f35b8365fb9803232d9b792c":"Det gick inte att hämta kortinformationen. Försök igen.",
"95abaa922c6c1648ca9c39ac4776896f":"Det gick inte att hämta användarinformationen. Försök igen.",
"9d7e4ead60137041ae8a845aa6cf9979":"Det gick inte att läsa in användarprofilen. Försök igen.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Det gick inte att läsa in det här teamets användare. Försök igen.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Det gick inte att läsa inställningarna. Försök igen.",
"b2f48028c400befad5f1d57f769ef7cd":"Det gick inte att omtilldela begäran. Försök igen.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Det gick inte att ta bort källkortet från teamet. Försök igen.",
"19dfcd761006496c1d20351c04643e45":"Det gick inte att ta bort den här domänen. Försök igen",
"149651b5784481c68aaf4ac27bb1f907":"Det gick inte att spara kodningslistan. Försök igen.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Det gick inte att spara profilen. Försök igen.",
"749ddc235744b6b371668ec0bb049176":"Det gick inte att spara användaren. Försök igen.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Det gick inte att ta spara den här domänen. Försök igen",
"04cf86909808f430b8b1904fb422e0d3":"Det gick inte att spara frånvaruinställningarna. Försök igen.",
"2eaf6ee97d984598c95e0494d72aa492":"Det gick inte att skicka in inköpsförfrågan.",
"148f847d3beccaa67fcd5096ae3602d3":"Det gick inte att uppdatera kortnamnet. Försök igen.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Det gick inte att uppdatera kortnamnet. Försök igen.",
"de8cc976f7af9edf189904239e6240da":"Det gick inte att uppdatera teamen. Försök igen.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Det gick inte att lägga till administratören. Försök igen.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Det gick inte att inaktivera teamet. Försök igen.",
"3f686a97a1b9fcdd73a40d2005693105":"Ett fel inträffade, försök igen.",
"2f38263789163cf8ee30c8ae88852c82":"Det gick inte att publicera listan.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Det gick inte att ta bort administratören. Försök igen.",
"007a647254e1f85b6c1e789004e05df5":"Det gick inte att spara inställningarna",
"c3072d080b587d70f85d68ec089f028f":"Det gick inte att spara teamets inställningar. Försök igen.",
"608cd0e109c3e51b0425df8921e4181d":"Det gick inte att ställa in leverantörsmappning. Försök igen.",
"177cefc68dd318ea75673326e77d8206":"Det gick inte att avpublicera listan.",
"255d22dc4f0e338a4cf53d5b7256e695":"Det gick inte att hämta personens namn till vilken du vidarebefordrar godkännandebegäranden.",
"39ac4ec73fed8a981ad1857d989edfcb":"Det här beloppet överskrider företagets godkännandegräns.",
"494ce3f598cbd97afe99774b5975a4bc":"Det här beloppet överskrider teamets godkännandegräns.",
"f2a42214e7c480c5596439becfd0b7bf":"Detta fält är obligatoriskt.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Den här inställningen påverkar endast nya kort.",
"881b7596cf985d9c246b05fcab930632":"Det här källkortet har lagts till i teamet.",
"a1b1aa354d06e7815b78c6abb7448e84":"Det här källkortet togs bort från teamet.",
"b348b24fc4839ee15ed0aa424689bae3":"Det här teamet måste ha en administratör eller ansvarig innan det går att bjuda in medlemmar.",
"188b9ed447a43916f9e323623ea3a993":"Godkänn inbjudan genom att",
"969de95e19a06f5ae7cec6449cbb6a27":"Totalt",
"30286e57da108ee7dbc0f8fbb62d4fff":"Summa utgifter ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Spåra utgifter för team, projekt och prenumerationer.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Spåra aktiviteter på teamets aktiva kort, pausa utgifter och inaktivera kort som teamet inte längre behöver.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Återställ lösenord till Tradeshift Go",
"5d760188301146cc7255ce18b65cb370":"Försök igen",
"80196bc616d4a44a4997a2ea127f7b83":"Försök igen",
"f96bbe81618b5f4a1807989eb4f69e24":"Försök igen",
"d498d7d261b7ca56e07218d1a0dbdd49":"Ojdå! Ett fel inträffade. Försök igen.",
"ce2e475291003852c71b01959698c496":"Det går inte att få meddelanden om begäran {title}. Försök igen",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Avarkivera",
"d2e50be5287fd8fb436d0acbeb811864":"Unik identifierare",
"0e2fbce967f4a209215b6f48f8eddac3":"Sluta pausa skapa kort",
"f2315dec5dd07802b77af3a3a5414a7d":"Upp till fyra år i framtiden.",
"d2f30a2f0c955271e9af123dceca501a":"Upp till {label} år i framtiden.",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Uppdatera källkort",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Uppdatera källkort",
"c8f6bc61a8982158e408194e48a274d9":"Det gick inte att ladda upp. Försök igen.",
"8a86a60cb836bdb5225cad20c5151078":"Ladda upp inleverans",
"e448a1ce42de0cf526200331b4ad8b23":"Användare",
"75edcc8fc27f41c99e28b48ff250c7f8":"Användarinformation",
"d1651c2afdb6c92621adbb707e4753d0":"Användare sparad.",
"5fa55883b7413526b441e999cb8089b3":"Användarnamn",
"7a892b81fe2aae295f1b2d38bb67360f":"Användarnamn",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Det går inte att ta bort användare med aktiva kort. Annullera kort för att ta bort den här användaren.",
"d40fa5935f1614925cf46259c886814a":"Visa kortaktivitet",
"625419857efc7e72d162c9f8204109df":"Visa kort i plånbok",
"85a5e817826415651ed4991a76bb5385":"Visa konversation",
"09fc124dc9b8b14d6227a92bb0a4566f":"Visa konversation",
"d26cfc6d8f420f259f434b79593e9eb4":"Visa kvitto",
"c7d0394c3206a3b74afbeabc41cc639c":"Visa kvitto",
"01650aa52c5e5a22d3f38afd2c7f9411":"Visa kvitto",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Visa kvitto",
"b2d141897f73fc2a198ee1a7a438f89b":"Visa förfrågan",
"02fa2f56ef72845a9dfeabea22948061":"Visa teaminformation",
"2d9c55650b17dd605a1388f46c81ba38":"Virtuellt kort",
"d0ec076e4600e854588f8da265208c0b":"Alternativ för virtuellt kort",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Plånbok",
"5279a2220e4cfb0d8b8b357311251cbe":"Plånbok",
"b1746b5806659e3189f837d2dd54e08c":"Plånbok",
"ff306d5b34ee993f9618c1f3c0209a39":"Det går för tillfället inte att arkivera begäran. Försök igen senare.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Det går för tillfället inte att avbryta begäran. Försök igen senare.",
"a813d44f6bb26c5da09671e532abe022":"Det går för tillfället inte att avarkivera begäran. Försök igen senare.",
"d4c7310eafc0d0da89f20596a22a3abe":"Det gick för tillfället inte att skapa en begäran. Försök igen!",
"dca679c10accb1db481a57a0e1ba08e2":"Det gick för tillfället inte att skapa en begäran. Försök igen!",
"db5584a2ef29b04959bce7f93db54c56":"Det går för tillfället inte att växla team. Försök igen senare.",
"f2af2c261fabddb01766f4f974b85a62":"Veckovis",
"e181ed0723a00ca7558b9d5d5258732b":"Välkommen till Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Vi kontaktar dig!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Vilket kreditkort använder företaget?",
"968d94729053e166a76ea577ce3022e2":"E-post till arbetet",
"588a075e8694ff5cc86815697e1586bc":"Arbeta som dina företagsteam arbetar.",
"182ae1eb5226e0646b4033883cc0900c":"År",
"fe88fe7094c4ced427874b3d22326c8d":"Ja",
"9a2d4e3b22ad0567165eb0f661f952ad":"Ja, avbryt kort",
"96b88e530375237cbc08138d9fb4109c":"Ja, ta bort den här listan",
"fd3780f468c531f2bc1b47b8748b5a66":"Det går att skapa redovisningskoder i företagsinställningarna.",
"ab3ac6f945d9f756763d49257fd924dc":"Det går att hantera kodningar i företagsinställningarna.",
"c919dae93fe780061629c255eb358140":"Det går inte att ta bort det här teamets enda länkade kort. Lägg till ett annat kort för att ta  bort det här.",
"11de97e873b66d91a77db7a05da32202":"Det går att befordra en av teamägarna till företagsadministratör eller bjuda in en ny användare.",
"0ab790f08b2780be001b79d2ccd75c4e":"Det finns inga definierade redovisningskoder skapade till det här kontot.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Du har inga skapade kodningslistor.",
"44e470218124a50c117870467530752e":"Du angav fel e-postadress eller fel lösenord.",
"4b687d0730822fdbeea27c174e47f227":"Du har inga godkända godkännanden.",
"020f03c17b79af66f335b6af868c66c0":"Du har inga avbrutna godkännanden.",
"def0b76f03e7c622341a7d8e08aaaae7":"Du har inga avböjda godkännanden.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Du har inga väntande godkännanden.",
"7c034d6104f80a523b90bc607fc06608":"Du har inga virtuella kort i plånboken. Skapa en begäran",
"0aed9198d61d161468d541dbbc9f477a":"Du har vidarebefordran vid frånvaro till {oooForwardToFullName} schemalagd tills {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Du fick ett nytt virtuellt kort:",
"04eeaf814767c30aa41577f3b11f916a":"Du begärde nyligen en lösenordsåterställning för Go-kontot. Slutför processen:",
"09ddf249810b9af7d3e1f4e537da500d":"Du försökte öppna en sida som inte finns. Kontrollera att du har rätt länk.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Du är inbjuden att gå med ditt team på",
"02c6f3a7cf31a87f4f39f992509f8c44":"Du har lagts till som administratör för ditt företag",
"cf121a14590f109dafcae138265e353b":"Du har lagts till i ett nytt team!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Du har lagts till i teamet",
"d3cef66a026f776903b342d450e5a832":"Din Go-kortbegäran har avböjts",
"003f87c21a29220e661fc302b885fd5a":"Ditt konto är inaktiverat, kontakta företaget eller teamadministratören.",
"a52b1f0da15dce68007f524e48238143":"Banken returnerade ett fel när det här kortet lades till. Kontakta banken för att lösa problemet eller försök igen.",
"4d883b173167297463aeac86957c26fe":"Din e-postadress användes till att skapa ett konto på Tradeshift Go. Verifiera e-postadressen och slutför registrering genom att",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Frånvaruinställningar sparade.",
"542f08554110a12de0c23afd78f38e0e":"Lösenordet återställt. Logga in.",
"49b2dcbe5d461f35c37986962b5bd07a":"Din begäran avböjdes",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Du finns på listan!",
"32f87c5ddeaee35b9178785ef4eb9313":"Postnummer",
"c6974a3d5311374c697625f2a3234bce":"ett datum",
"f86980f5ef00b645a6e4a0b59738a954":"en person vi inte kunde slå upp",
"7066f854917b39bafe0bbecbb8bd6377":"godkänner begäranden",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"för",
"49b171230ab9bcdd8b631c70cf397efb":"för <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"för ditt företag",
"35ad86f0b035d8156390670d60801e7f":"för {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"för {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"från",
"c1407aa5424f339dedf67bcac597cf71":"har ställt in att godkännandebegäranden skickas till dig under",
"67a948b8af3284a401ebefcb1b4723ab":"väntar på ditt godkännande av",
"9ca64449d64b98fef7859e22bc23c4dc":"gör förfrågningar",
"ac850ab84428e9eb6a57e5d813de5e45":"hanterar team",
"2168839a1f4c73ef5c89c9e930148e7d":"bortse från det här e-postmeddelandet. Ingen åtgärd krävs",
"85431c325faab98e02daa60151465144":"skickade den här inbjudan",
"81c504fd54119e7e580eb05dedef46d0":"skickade den här inbjudan",
"d74545060b38445ef4cc49fbb8d79876":"skickade den här inbjudan.",
"2d96c53ee7ee0a54aadad19bfce0697d":"det gick inte att hämta dina kortkällor",
"b2fda295788d579fffd3f43b7737fd63":"registrera företagskortet på Go för att ge anställda enkel och säker kortåtkomst",
"d35bd5c5d74af765d45cef3c4e78f52a":"att göra företagsinköp med Go virtuella kort",
"8469a13ddf1cc19a4ebf26356d8ac646":"att göra företagsinköp med Go virtuella kort",
"9546989e681a7e25fbdfe7631e9ebc03":"att göra företagsinköp med Go virtuella kort.",
"6174e0a3d26409dd6e410052e2805660":"att börja använda Tradeshift Go-kort",
"0bee956ca9aa927e0063c36a300e29e1":"stäng av frånvarande",
"fd2c1099fc9f3831d7f24195aaa11269":"namn@foretag.se",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} är ansvarig för att godkänna begäranden från andra teammedlemmar. Tilldela en ny godkännare till medlemmarna som påverkas för att fortsätta.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} bjöds in till${this.teamId ? '.' : ' till teamet.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} togs bort",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} avbröt begäran för",
"9567a2d4cacd58fb4f096132d26c1660":"{username} är ansvarig för att godkänna begäranden från andra teammedlemmar. Tilldela en ny godkännare till medlemmarna som påverkas för att fortsätta.",
};
