const CreateTeamModalComponent = {
	bindings: {
		isCompanyAdmin: '<',
		isOpen: '<',
		onClose: '&',
		refreshTeams: '&',
		teams: '<',
	},
	template: `
		<ts-modal
				buttons="$ctrl.buttons"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.createTeamText}}">
			<div class="modal-panel-navigation">
				<div class="navigation-item-container">
					<span
							ng-bind="$ctrl.teamInformationText"
							ng-class="{'active': $ctrl.step >= 0, 'underline': $ctrl.step === 0}"
							class="navigation-item"></span>
					<i
							class="ts-icon-arrowright"
							ng-class="{'active': $ctrl.step > 0 }"></i>
					<span
							ng-bind="$ctrl.addPeopleText"
							ng-class="{'active': $ctrl.step >= 1, 'underline': $ctrl.step === 1}"
							class="navigation-item"></span>
					<i
							class="ts-icon-arrowright"
							ng-class="{'active': $ctrl.step > 1 }"></i>
					<span
							ng-bind="$ctrl.codingListsText"
							ng-class="{'active': $ctrl.step >= 2, 'underline': $ctrl.step === 2}"
							class="navigation-item"></span>
				</div>
			</div>
			<div
					class="modal-panel-content content"
					ng-show="$ctrl.step === 0">
				<div>
					<team-info-modal-view
							is-current-step="$ctrl.isOpen && $ctrl.step === 0"
							is-modal-open="$ctrl.isOpen"
							on-change-buttons="$ctrl.onChangeButtons($event)"
							on-close-modal="$ctrl.onClose()"
							on-next-step="$ctrl.nextStep()"
							on-team-added="$ctrl.onTeamAdded($event)"
							teams="$ctrl.teams"></team-info-modal-view>
				</div>
			</div>
			<div
					class="modal-panel-content content"
					ng-show="$ctrl.step === 1">
				<div>
					<add-people-modal-view
							is-current-step="$ctrl.isOpen && $ctrl.step === 1"
							is-modal-open="$ctrl.isOpen"
							limit="$ctrl.limit"
							on-change-buttons="$ctrl.onChangeButtons($event)"
							on-next-step="$ctrl.nextStep()"
							on-previous-step="$ctrl.previousStep()"
							team-id="$ctrl.teamId"></add-people-modal-view>
				</div>
			</div>
			<coding-lists-modal-view
					is-company-settings-view="false"
					is-company-admin="$ctrl.isCompanyAdmin"
					is-current-step="$ctrl.isOpen && $ctrl.step === 2"
					ng-show="$ctrl.step === 2"
					on-change-buttons="$ctrl.onChangeButtons($event)"
					on-previous-step="$ctrl.previousStep()"
					on-save="$ctrl.onClose()"
					team-id="$ctrl.teamId"></coding-lists-modal-view>
			</div>
		</ts-modal>
	`,
	controller: /* @ngInject */ function ($element, $timeout, constants, DOMService, LexicoService) {
		this.lexico = LexicoService.getLexico();
		this.createTeamText = this.lexico.trc('Component title', 'Create team');
		this.teamInformationText = this.lexico.trc('Component navigation item', 'Team information');
		this.addPeopleText = this.lexico.trc('Component navigation item', 'Add people');
		this.codingListsText = this.lexico.trc('Component navigation item', 'Coding lists');
		this.$onChanges = changes => {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.step = 0;
			}
		};

		this.$onInit = () => {
			this.createTeamElement = DOMService.moveElementBelowNgApp({ $element });
			this.limit = constants.teams.pageLimit;
		};

		this.onChangeButtons = buttons => {
			$timeout(() => {
				this.buttons = buttons;
			});
		};

		this.nextStep = () => {
			if (this.step < 2) {
				$timeout(() => {
					this.step++;
				});
			}
		};

		this.onTeamAdded = ({ teamId }) => {
			this.teamId = teamId;
			return this.refreshTeams();
		};

		this.previousStep = () => {
			if (this.step > 0) {
				$timeout(() => {
					this.step--;
				});
			}
		};
	},
};

export default CreateTeamModalComponent;
