import HttpRepository from './httpRepository';

const RoleRepository = {};

RoleRepository.addRoleToUser = ({ userId, roleId, securityGroupId }) =>
	HttpRepository.tradeshiftGo().post(`/rest/users/${userId}/roles/${roleId}/securityGroup/${securityGroupId}`);

RoleRepository.updateUserRole = (userId, roleId, securityGroupId) =>
	HttpRepository.tradeshiftGo().put(`/rest/users/${userId}/roles/${roleId}/securityGroup/${securityGroupId}`);

RoleRepository.removeRoleFromUser = ({ userId, roleId, securityGroupId }) =>
	HttpRepository.tradeshiftGo().delete(`/rest/users/${userId}/roles/${roleId}/securityGroup/${securityGroupId}`);

export default RoleRepository;
