"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.headerInitialState = void 0;
exports.headerSlice = headerSlice;
exports.headerInitialState = {
    header: {
        headerName: '',
        isHeaderVisible: true,
        showCloseButtonInTeamOverviewPageHeader: false,
        isHeaderMenuOnMobileViewOpened: false,
        headerButtons: [],
        activeHeaderTab: '',
    },
};
function headerSlice(set) {
    return {
        ...exports.headerInitialState,
        setHeaderName(headerName) {
            set((state) => {
                state.header.headerName = headerName;
                return state;
            }, false, `[setHeaderName]-${headerName}`);
        },
        setHeaderButtons(headerButtons) {
            set((state) => {
                state.header.headerButtons = headerButtons;
                return state;
            }, false, '[setHeaderButtons]');
        },
        openHeaderMenuOnMobileView(isHeaderMenuOnMobileViewOpened) {
            set((state) => {
                state.header.isHeaderMenuOnMobileViewOpened = isHeaderMenuOnMobileViewOpened;
                return state;
            }, false, `[openHeaderMenuOnMobileView]-${isHeaderMenuOnMobileViewOpened}`);
        },
        setActiveHeaderTab(activeTab) {
            set((state) => {
                state.header.activeHeaderTab = activeTab;
                return state;
            }, false, `[setActiveHeaderTab]-${activeTab}`);
        },
    };
}
