"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiAlertTitleOverrides = void 0;
exports.muiAlertTitleOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
                fontWeight: theme.typography.fontWeightBold,
                marginBottom: '0',
                lineHeight: 'normal',
            };
        },
    },
};
