"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollRestoration = void 0;
const react_1 = require("react");
const useScrollRestoration = () => {
    const scrollPosition = (0, react_1.useRef)(0);
    (0, react_1.useEffect)(() => {
        const handleScroll = () => {
            scrollPosition.current = window.scrollY;
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const restoreScroll = () => {
        window.scrollTo(0, scrollPosition.current);
    };
    return { restoreScroll };
};
exports.useScrollRestoration = useScrollRestoration;
