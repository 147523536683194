const SvgProductsAndServices = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M14.2 0H6v5.8h1.2V1.2H13V5h3.8v7.8h-.6V14H18V3.8Zm0 3.8V1.7l2.1 2.1ZM13.5 7h-12A1.51 1.51 0 0 0 0 8.5v8A1.5 1.5 0 0 0 1.5 18h12a1.5 1.5 0 0 0 1.5-1.5v-8A1.51 1.51 0 0 0 13.5 7m.3 9.5a.31.31 0 0 1-.3.3h-12a.31.31 0 0 1-.3-.3v-5.3h12.6Zm0-6.5H1.2V8.5a.31.31 0 0 1 .3-.3h12a.31.31 0 0 1 .3.3ZM12 12.8H9V14h3Z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

export default SvgProductsAndServices;
