"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiListItemOverrides = void 0;
exports.muiListItemOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                paddingTop: 0,
                paddingLeft: theme.tsGoUnit.tsUnitQuarter,
                paddingBottom: theme.tsGoUnit.tsUnitHalf,
                fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
                color: theme.palette.tsGoColor.black,
            };
        },
    },
};
