const _ = require('lodash');

const errorCodes = require('../../../common/constants/errorCodes');

/* @ngInject */
function SettingsController(
	$scope,
	$timeout,
	_features,
	_manageHeader,
	_providerApiMap,
	_providerMappingConfigs,
	_requestLimit,
	_requestProfileId,
	_requestProfiles,
	_user,
	_whitelistedDomains,
	_workflowConfig,
	_workflowConfigs,
	constants,
	ConfigService,
	CompanyService,
	DomainWhitelistingService,
	FeatureService,
	HttpService,
	RequestProfilesService,
	ApprovalService,
) {
	const DEFAULT_PROFILE = 'default';
	const NEW_DOMAIN_REGEX = constants.domainWhitelisting.newDomainRegex;
	const VERSION = ConfigService.get('VERSION');

	const wipFeatureNames = [
		'ENABLE_DOMAIN_WHITELISTING',
		'ENABLE_OUT_OF_OFFICE_FORWARDING',
		'ENABLE_SINGLEUSE',
		'ENABLE_SUBSCRIPTIONS',
	];

	$scope.addWhitelistedDomain = newDomain => {
		return DomainWhitelistingService.addWhitelistedDomain(newDomain)
			.then(() => {
				$scope.newDomain = '';
				$scope.whitelistedDomains.push(newDomain);
				ts.ui.Notification.success('The domain is set to be whitelisted.');
			})
			.catch(() => {
				ts.ui.Notification.warning('There was a problem setting the domain as whitelisted. Please try again.');
			});
	};

	$scope.enrollHSBCCardSources = () =>
		HttpService.tradeshiftGo()
			.path('/rest/company/enrollment/hsbc')
			.config({
				transformResponse: (data, headersGetter, status) => status,
			})
			.get()
			.then(status => {
				if (status === 201) {
					ts.ui.Notification.success('We found and added one or more HSBC cards');
				} else {
					ts.ui.Notification.success('No new cards were found to add.');
				}
			})
			.catch(() => ts.ui.Notification.error('Something went wrong. Please check the server logs.'));

	$scope.isDomainInvalid = newDomain => {
		return !NEW_DOMAIN_REGEX.test(newDomain);
	};
	$scope.isLimitInvalid = limit => {
		const currencyZeroInvalid = new RegExp(constants.regexPatterns.currencyZeroInvalid);
		return limit !== '' && _.isNull(currencyZeroInvalid.exec(limit));
	};
	$scope.onDeleteDomainCancelled = () => {
		$scope.asides.confirmDeleteDomain.isOpen = false;
	};
	$scope.onDeleteDomainConfirmed = () => {
		const aside = $scope.asides.confirmDeleteDomain;
		const domain = aside.domain;
		DomainWhitelistingService.deleteWhitelistedDomain(domain)
			.then(() => {
				aside.isOpen = false;
				ts.ui.Notification.success(`${domain} is no longer whitelisted.`);
				$timeout(() => {
					_.pull($scope.whitelistedDomains, domain);
				}, 0);
			})
			.catch(error => {
				console.log(error);
				ts.ui.Notification.warning(`There was a problem unwhitelisting ${domain}. Please try again.`);
			});
	};
	$scope.openDeleteDomainAside = domain => {
		Object.assign($scope.asides.confirmDeleteDomain, {
			domain,
			message: `Are you sure you want to unwhitelist ${domain}?`,
			isOpen: true,
		});
	};
	$scope.saveConfiguration = function (key, value) {
		if (value === '') {
			return ConfigService.delete(key);
		}
		return ConfigService.set({ key, value });
	};
	$scope.saveFeature = function (name, value) {
		return FeatureService.saveFeature({
			name,
			value,
		});
	};
	$scope.updateLimit = function (limit) {
		return CompanyService.setProperty({
			propertyKey: constants.companyPropertyKeys.requestLimit,
			propertyValue: limit,
		})
			.then(function () {
				ts.ui.Notification.success('The company request limit has been updated.');
			})
			.catch(function () {
				ts.ui.Notification.warning('There was a problem setting the company request limit. Please try again.');
			});
	};
	$scope.updateRequestProfile = function (profileId) {
		let promise;

		if (profileId === DEFAULT_PROFILE) {
			promise = RequestProfilesService.removeProfileFromCompany({
				companyId: $scope.user.companyAccountId,
			});
		} else {
			promise = RequestProfilesService.setProfileForCompany({
				companyId: $scope.user.companyAccountId,
				profileId,
			});
		}
		return promise
			.then(function () {
				ts.ui.Notification.success('The request profile has been updated.');
			})
			.catch(function () {
				ts.ui.Notification.warning('There was a problem setting the request profile. Please try again.');
			});
	};
	$scope.updateWorkflowConfig = function ({ id: configId }) {
		return CompanyService.setWorkflowConfig({ configId })
			.then(function () {
				ts.ui.Notification.success('The workflow config has been updated.');
			})
			.catch(function () {
				ts.ui.Notification.warning('There was a problem setting the workflow config. Please try again.');
			});
	};

	$scope.getGoBranch = async companyId => {
		try {
			$scope.goBranchId = await CompanyService.getGoBranch({ companyId });
		} catch (error) {
			if (error.status === 404) {
				ts.ui.Notification.info('Go branch is not linked.');
			} else {
				ts.ui.Notification.warning('There was a problem retrieving the Go branch.');
			}
		}
	};

	$scope.storeGoBranch = async (companyId, goBranchId) => {
		try {
			await CompanyService.storeGoBranch({ companyId, goBranchId });
			ts.ui.Notification.success('Go branch has been linked.');
		} catch (error) {
			ts.ui.Notification.warning('There was a problem linking the Go branch.');
		}
	};

	$scope.clearGoBranch = async companyId => {
		try {
			await CompanyService.clearGoBranch({ companyId });
			ts.ui.Notification.success('Go branch been de-linked.');
		} catch (error) {
			ts.ui.Notification.warning('There was a problem de-linking the Go branch.');
		}
	};

	$scope.closeStuckPendingApproval = async stuckPendingApprovalId => {
		try {
			await ApprovalService.closeStuckPendingApproval(stuckPendingApprovalId);
			ts.ui.Notification.success('Approval has been canceled.');
		} catch (error) {
			let errorMessage = 'There was a problem canceling the approval.';
			if (error.code === errorCodes.ERR_NO_TASK_FOUND) {
				errorMessage = `There was a problem canceling the approval. ${error.name}`;
			}
			ts.ui.Notification.warning(errorMessage);
		}
	};

	(function init() {
		$scope.asides = {
			confirmDeleteDomain: {
				isOpen: false,
			},
		};
		$scope.newDomain = '';
		$scope.user = _user;
		$scope.features = _.filter(_features, ({ name }) => !_.includes(wipFeatureNames, name));
		$scope.wipFeatures = _.filter(_features, ({ name }) => _.includes(wipFeatureNames, name));
		$scope.providerMappingConfigs = _providerMappingConfigs;
		$scope.requestLimit = _requestLimit;
		$scope.requestProfileId = _requestProfileId;
		$scope.ProviderApiMap = _providerApiMap;
		$scope.requestProfiles = _requestProfiles;
		$scope.version = VERSION;
		$scope.whitelistedDomains = _whitelistedDomains;
		$scope.workflowConfigs = _workflowConfigs;
		if (_workflowConfig.id) {
			$scope.workflowConfig = $scope.workflowConfigs.find(config => config.id === _workflowConfig.id);
		}
		_manageHeader.selectTab({ selectedTab: 'settings' });
	})();
}

SettingsController.resolve = {
	_features: /* @ngInject */ (constants, FeatureService) => {
		return FeatureService.getFeatures().then(features => {
			return _.map(features, (isEnabled, name) => {
				return { isEnabled, name, label: constants.features[name] };
			});
		});
	},
	_providerApiMap: /* @ngInject */ (_providerMappingConfigs, ConfigService) => {
		return ConfigService.getAsync('ProviderApiMap').then(providerApiMapConfig => {
			return _providerMappingConfigs.find(o => o.key === providerApiMapConfig);
		});
	},
	_providerMappingConfigs: /* @ngInject */ ConfigService => {
		return ConfigService.getProviderMappingConfigs().then(configs => {
			configs.push({ key: '', label: 'none' });
			return configs;
		});
	},
	_requestLimit: /* @ngInject */ (constants, CompanyService) => {
		return CompanyService.getRequestLimit();
	},
	_requestProfileId: /* @ngInject */ (_user, RequestProfilesService) => {
		return RequestProfilesService.getProfileForCompany({ companyId: _user.companyAccountId }).then(
			requestProfile => requestProfile.id,
		);
	},
	_requestProfiles: /* @ngInject */ RequestProfilesService => RequestProfilesService.getProfiles(),
	_whitelistedDomains: /* @ngInject */ DomainWhitelistingService => DomainWhitelistingService.getWhitelistedDomains(),
	_workflowConfig: /* @ngInject */ CompanyService => CompanyService.getWorkflowConfig(),
	_workflowConfigs: /* @ngInject */ ConfigService => ConfigService.getWorkflowConfigs(),
};

module.exports = SettingsController;
