"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApprovalsInitialState = exports.defaultApprovalSort = exports.sortApprovalsOptions = void 0;
exports.approvalsSlice = approvalsSlice;
exports.sortApprovalsOptions = {
    mostRecent: 'desc',
    requester: 'asc',
    lowToHigh: 'asc',
    highToLow: 'desc',
};
exports.defaultApprovalSort = { mostRecent: 'desc' };
exports.ApprovalsInitialState = {
    approvals: {
        searchValue: '',
        sortValue: {
            mostRecent: 'desc',
        },
        list: {
            pending: { approvals: [], count: 0 },
            approved: { approvals: [], count: 0 },
            declined: { approvals: [], count: 0 },
            canceled: { approvals: [], count: 0 },
        },
        openForwardRequestAside: false,
        currentApprovalItem: null,
        approvalRequest: {
            cardSourceId: '',
            cardType: '',
            coding: {},
            eventId: '',
            requesterUserId: '',
            requestId: '',
            taskId: '',
            endDate: '',
        },
        showConversationButtons: true,
        showCreateCardButton: true,
        showCreateFwdButtons: true,
        showNoActiveCardInfo: false,
        viewApprovalConversationRequestId: undefined,
    },
};
function approvalsSlice(set) {
    return {
        ...exports.ApprovalsInitialState,
        setSearchApprovalsValue(searchValue) {
            set((state) => {
                state.approvals.searchValue = searchValue;
                return state;
            }, false, '[setSearchApprovalsValue]');
        },
        setSortApprovalsValue(sortValue) {
            set((state) => {
                state.approvals.sortValue = sortValue;
                return state;
            }, false, '[setSortApprovalsValue]');
        },
        setUiApprovals(list) {
            set((state) => {
                state.approvals.list = list;
                return state;
            }, false, '[setUiApprovals]');
        },
        setOpenForwardRequestAside(value) {
            set((state) => {
                state.approvals.openForwardRequestAside = value;
                return state;
            }, false, '[setOpenForwardRequestAside]');
        },
        setCurrentApproverItem(value) {
            set((state) => {
                state.approvals.currentApprovalItem = value;
                return state;
            }, false, '[setCurrentApproverItem]');
        },
        setApprovalRequest(approvalRequest) {
            set((state) => {
                state.approvals.approvalRequest = approvalRequest;
                return state;
            }, false, '[setApprovalRequest]');
        },
        setShowConversationButtons(showConversationButtons) {
            set((state) => {
                state.approvals.showConversationButtons = showConversationButtons;
                return state;
            }, false, '[setShowConversationButtons]');
        },
        shouldShowCreateCardButton(showCreateCardButton) {
            set((state) => {
                state.approvals.showCreateCardButton = showCreateCardButton;
                return state;
            }, false, '[showCreateCardButton]');
        },
        setShowCreateFwdButtons(showCreateFwdButtons) {
            set((state) => {
                state.approvals.showCreateFwdButtons = showCreateFwdButtons;
                return state;
            }, false, '[showCreateFwdButtons]');
        },
        setShowNoActiveCardInfo(showNoActiveCardInfo) {
            set((state) => {
                state.approvals.showNoActiveCardInfo = showNoActiveCardInfo;
                return state;
            }, false, '[showNoActiveCardInfo]');
        },
        setViewApprovalConversationRequestId(requestId) {
            set((state) => {
                state.approvals.viewApprovalConversationRequestId = requestId;
                return state;
            }, false, '[setViewApprovalConversationRequestId]');
        },
    };
}
