"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HsbcLoadingButton = void 0;
const lab_1 = require("@mui/lab");
const material_1 = require("@mui/material");
exports.HsbcLoadingButton = (0, material_1.styled)(lab_1.LoadingButton)(({ theme }) => ({
    width: '100%',
    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
    '&.Mui-disabled': {
        color: 'black',
    },
}));
