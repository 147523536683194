"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routingInitialState = void 0;
exports.routingSlice = routingSlice;
exports.routingInitialState = {
    routing: {
        isMainContentInControl: false,
    },
};
function routingSlice(set) {
    return {
        ...exports.routingInitialState,
        setMainContentControl(isMainContentInControl) {
            set((state) => {
                state.routing.isMainContentInControl = isMainContentInControl;
                return state;
            }, false, '[setMainContentControl]');
        },
    };
}
