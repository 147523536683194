import _ from 'lodash';

const ReceiptAsideComponent = {
	bindings: {
		isOpen: '<',
		onClose: '&',
		onReceiptAdded: '&',
		onReceiptRemoved: '&',
		settlementId: '<',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.receiptTitleText}}">
			<ts-form
					hide-buttons="!$ctrl.receiptName"
					is-submitting="$ctrl.isSubmitting"
					on-submit="$ctrl.onViewReceipt()"
					submit-label="{{$ctrl.viewReceiptFieldText}}">
				<ts-form-fields>
					<go-file-upload
							accept="image/*,application/pdf"
							file-formats-info="{{$ctrl.fileFormatsInfo}}"
							label="{{$ctrl.uploadReceiptText}}"
							max-file-size="10"
							method="POST"
							ng-model="$ctrl.receiptName"
							on-file-uploaded='$ctrl.onReceiptUploaded($event)'
							url="$ctrl.uploadUrl"
							uploaded-message="Receipt attached"></go-file-upload>
				</ts-form-fields>
				<ts-form-buttons>
					<ts-menu-button
							button-class="ts-danger"
							label="$ctrl.removeReceiptButtonText"
							on-click="$ctrl.onRemoveReceipt()"></ts-menu-button>
				</ts-form-buttons>
			</ts-form>
		</ts-aside>
		<go-view-image-modal
				filename="$ctrl.receiptName"
				is-open="$ctrl.viewReceiptModal.isOpen"
				on-close="$ctrl.viewReceiptModal.isOpen = false"
				title="{{$ctrl.viewReceiptText}}"
				url="$ctrl.receiptUrl"></go-view-image-modal>
	`,
	controller: /* @ngInject */ function (EventEmitter, LexicoService, ReceiptService) {
		this.lexico = LexicoService.getLexico();
		this.receiptTitleText = this.lexico.trc('Aside component label', 'Receipt');
		this.viewReceiptFieldText = this.lexico.trc('Form field label', 'View receipt');
		this.uploadReceiptText = this.lexico.trc('Form field label', 'Upload receipt');
		this.removeReceiptButtonText = this.lexico.trc('Button', 'Remove receipt');
		this.viewReceiptText = this.lexico.trc('Image modal label', 'View receipt');

		this.$onInit = () => {
			this.viewReceiptModal = { isOpen: false };
			this.fileFormatsInfo = this.lexico.trc('File format message', 'Image or PDF file formats supported');
		};
		this.$onChanges = changes => {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}
		};
		this.onOpen = () => {
			this.isLoading = true;
			this.uploadUrl = `/rest/payments/settlements/${this.settlementId}/receipt`;
			ReceiptService.getReceiptInfo(this.settlementId)
				.then(receipt => {
					this.receiptId = _.get(receipt, `[${this.settlementId}].id`);
					this.receiptName = _.get(receipt, `[${this.settlementId}].name`);
				})
				.finally(() => {
					this.isLoading = false;
				});
		};
		this.onReceiptUploaded = ({ response: receiptId }) => {
			this.receiptId = receiptId;
			this.onReceiptAdded(
				EventEmitter({
					receiptId,
					settlementId: this.settlementId,
				}),
			);
		};
		this.onRemoveReceipt = () => {
			ReceiptService.removeReceipt(this.settlementId).then(() => {
				this.onReceiptRemoved(
					EventEmitter({
						receiptId: this.receiptId,
						settlementId: this.settlementId,
					}),
				);
				this.receiptId = null;
				this.receiptName = null;
			});
		};
		this.onViewReceipt = () => {
			this.receiptUrl = `/rest/payments/receipts/${this.receiptId}/image`;
			this.onClose();
			this.viewReceiptModal.isOpen = true;
		};
	},
};

export default ReceiptAsideComponent;
