"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApprovalToggleLabel = exports.ActionButtons = void 0;
const material_1 = require("@mui/material");
exports.ActionButtons = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.tsGoUnit.tsUnitHalf,
    alignItems: 'baseline',
}));
exports.ApprovalToggleLabel = (0, material_1.styled)(material_1.FormControlLabel)(() => ({
    ':hover': {
        boxShadow: '0 0 0 1px transparent',
        border: '1px solid transparent',
    },
}));
