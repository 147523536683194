const _ = require('lodash');

/* @ngInject */
function PurchaseRequestController($scope, Request, RequestRegistry) {
	$scope.isLoading = true;
	const request = RequestRegistry.getRequest($scope.event.requestId);
	$scope.request = !_.isEmpty(request) ? request : new Request($scope.event.data);
	$scope.request.update($scope.event.data);
	$scope.isLoading = false;
}

module.exports = PurchaseRequestController;
