const Sentry = require('@sentry/browser');

/* @ngInject */
function ErrorService(ConfigService) {
	const ERRORS = ConfigService.get('ERRORS');
	return {
		clearContext() {
			if (ConfigService.get('IS_SENTRY_ENABLED')) {
				Sentry.getCurrentScope().setUser({});
				Sentry.getCurrentScope().setTags({});
			}
		},
		setContext(user) {
			if (ConfigService.get('IS_SENTRY_ENABLED')) {
				Sentry.getCurrentScope().setUser({
					companyAccountId: user.companyAccountId,
					companyName: user.companyName,
					email: user.email,
					roleLabel: user.roleId,
					userId: user.userId,
				});
				Sentry.getCurrentScope().setTags({
					companyAccountId: user.companyAccountId,
					userId: user.userId,
				});
			}
		},
		getErrorMessage: errorType => {
			const foundError = ERRORS.find(error => error.type === errorType);
			return foundError ? foundError.message : '';
		},
	};
}

module.exports = ErrorService;
