export default class FileUploadService {
	/* @ngInject */
	constructor(HttpService) {
		this.HttpService = HttpService;
	}

	upload({ file, url, method }) {
		const data = new FormData();
		const headers = { 'Content-Type': undefined };
		data.append('file', file);
		return this.HttpService.tradeshiftGo().path(url).config({ headers }).request({ method, data });
	}

	validateFileSize({ file, maxSizeMB }) {
		const maxSizeBytes = maxSizeMB * 1024 * 1024;
		if (file.size > maxSizeBytes) {
			ts.ui.Notification.warning(`Sorry, this file is larger the maximum size limit of ${maxSizeMB} MB.`);
			return false;
		}
		return true;
	}
}
