const _ = require('lodash');

/* @ngInject */
function CodingListsController($q, $scope, AsideService, CodingService, ConfigService) {
	const ALL_CODING_LISTS = ConfigService.get('CODING_LISTS');
	const that = this;

	function init() {
		that.onSuccess = $scope.aside.onSuccess;

		const defaultCodingListIds = ['costcentercode', 'glcode'];
		that.form = _.chain(ALL_CODING_LISTS)
			.filter(function (codingList) {
				return defaultCodingListIds.includes(codingList.id);
			})
			.map(function (codingList) {
				return {
					listId: codingList.id,
					listLabel: codingList.name,
				};
			})
			.keyBy('listId')
			.value();

		that.isLoading = {
			aside: false,
		};
	}

	that.cancel = function () {
		AsideService.closeAll();
	};
	that.submit = function (data) {
		const promises = _.map(data, function (value) {
			return CodingService.addEntry({
				listId: value.listId,
				entryId: value.entryId,
				entryLabel: value.entryLabel,
			});
		});
		return $q
			.all(promises)
			.then(function () {
				that.onSuccess();
				AsideService.close('codingLists');
			})
			.catch(function () {
				ts.ui.Notification.warning('There was a problem setting up coding. Please try again.');
			});
	};

	$scope.$watch('aside.show', function (show) {
		if (show) {
			init();
		}
	});
}

module.exports = CodingListsController;
