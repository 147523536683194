module.exports = {
"d447f4a1755a0025dddd2f736255879f":"Aantal transacties",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minuut",
"ce09c436b49af331a32f1152ede7dd18":"1. Financiering aanvragen",
"d2226752d3065fbad4744924b4a0ee73":"2. Controleren en goedkeuren",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Controleren en goedkeuren",
"82bab92f1c8030888550b51521266c53":"3. Betaal met virtuele kaart",
"2ecd62e02e507f8e839a55eac0a04886":"Een codering met ID '{entryId}' bestaat al.",
"5dfd25849740fbce8147838b0d5094eb":"Een vriendelijke bijnaam, bijv. marketingkaart",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Een lid van ons klantteam zal binnenkort contact met u opnemen.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"Iemand die we niet hebben opgezocht",
"976b50e70e4433e2786233979d243445":"Er bestaat al een team met deze naam.",
"35b61d21ac11d5fc31ebe58541263445":"Accepteer het contactverzoek",
"a372cb2d4204654f52cd9170fb10d374":"Boekhoudcodes",
"110fc66010b6b91cebee7a59a2067aab":"Boekhoudcodes",
"db862bb04dbdc6d04e47063f33be8865":"Boekhoudcodes worden beheerd door middel van codelijsten en vermeldingen in lijsten.",
"d6e3848e824f077396be4351eb58edd2":"Actie vereist - nieuwe aanvraag goedkeuren/afwijzen door {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Acties",
"476e728ea824229f52438e543818bcdd":"Acties",
"3117746cb7d4ab815ec98274ec4398d5":"Acties",
"3ab09b66a7f16219d8135977a2a76d10":"Acties",
"ffafa66eaab0409a2e90aaeb43796def":"Actief",
"0447e0aa5d00dbdab11b5546793c4479":"Actief",
"8ee51d21b05466f7d962d4ff5a0b384e":"Actieve kaarten",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Actieve virtuele kaarten",
"89c6ec05861aa914304ba35bc6744124":"Actieve kaarten",
"5280659bcecaf8344c57790f9962a9a2":"Activiteit",
"8a15368ae286fa64e1cb7624cd410fc6":"Codelijst toevoegen",
"8ded7e09e86be231b1165308329ac76d":"Lijst toevoegen",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Beheerder toevoegen",
"695bb4bc29e2df769ddf7427ab71012a":"Goedkeurder toevoegen",
"4a1d4516cab00b7c5ecdb150e346e5db":"Goedkeurder toevoegen",
"56869851b27c2915f5a7fe6a33b91ac7":"Codelijst toevoegen",
"8f8691057c3994aa61f9994202680c87":"Codelijst toevoegen",
"69f7a406b79d19bf8082ed245ab29123":"Codelijst toevoegen",
"48a92ca1cc63018707be99b13196369f":"Bedrijfsbeheerder toevoegen",
"a679db9ff80d0e5769ae1ba311c7916f":"Domein toevoegen",
"8f5e8078be77146ba7f3be49ef948906":"Vermelding toevoegen",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Bericht toevoegen",
"f8a4b14162e19995074e63135b65c84d":"Nieuwe kaart toevoegen",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Mensen toevoegen",
"c670549d0b898bb6a571eb2cd7804a6e":"Ontvangstbewijs toevoegen",
"eb937098e77d8a6b0565ed72357dcaae":"Ontvangstbewijs toevoegen",
"1e154f31c31b63a459f3956e380b3ef9":"Selectie toevoegen",
"ba24db9956f156a18b32e653552ff682":"Voeg enkele mensen toe aan dit team",
"52bd93fb5489b25167bb541f7c6504ae":"Bronkaart toevoegen",
"21ab3f08eb507dbc76df166eafeb393a":"{fullName} toegevoegd als een goedkeurder!",
"f879c06507d3076bbf52ea4fd2df850e":"Aanvullende kaartgegevens",
"21e934ae30eece4a894337701f497638":"Alle",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Alle kaarten",
"8fac71195e2b506e029752e4864b6a5d":"Alle leden",
"f9708417df16227742e0545a8642eea7":"Alle beschikbare gebruikers zijn al aan deze kaart toegevoegd.",
"cb5abf070f8cba7982205038baab77bb":"Alle kaarten",
"dae7d602eec2c84dea0d80ae25e56e6d":"Alle kaarten",
"0b4bdfde6578668b6018d3615a1ad30f":"Alle kostenplaatsen",
"97b875b81f54b4432d998a3e47d949cc":"Alle teams",
"f0ae83969f7c20a1964caf5156dae966":"Alle teams",
"d87455601a00282078582b36a3825b7d":"Alle teams",
"24c7b7f55882ea94a525392ca8218fa0":"Alle teams",
"8f860e35b64a8f447bded1e5ea66a2a8":"Alle virtuele kaarten",
"e403956ecaa6fb33ec1fef5c49d71042":"Alle virtuele kaarten",
"4e6f4e8f25f61d40e1eba77de94f6458":"Al uw financiële gegevens zijn gecodeerd en op servers op veilige locaties opgeslagen.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Bedrag",
"a4d099d0dbe3889b0995546e6ed28932":"Bedrag",
"6c0ef32aae76bc31a61e2124f542bdf1":"Bedrag",
"adec89c93525f1a8d1c64d856f405e27":"Bedrag: aflopend",
"18e632a170203894f40deed1d33ee552":"Bedrag: oplopend",
"a7944575cafef9126a499a1b06999fe3":"Er is een fout opgetreden. Probeer het later nogmaals.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Toepassen",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Goedkeuringsopties",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Goedkeuringsinstellingen",
"492eedd88cdd7bb45f39c6594af748c4":"Goedkeuringslimiet",
"437d490afc8d226f8c7d544f4e35d011":"Goedkeuringslimiet ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Goedkeuringen",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Goedkeuringen",
"17adb7fe866e32055b1469fdb7e82795":"Goedkeuringsinstellingen",
"7125aa13db437084e1d27d3ff38f22e2":"Goedkeuren",
"84df787ff5746f9dc3cf0146becef4fd":"Aanvraag goedkeuren",
"7a568654d00245b0bab6c90a1b67d196":"Goedgekeurd",
"179c3e187ad8edeb8348cff222b938bf":"Goedgekeurd",
"ad058d17725870df9abbd4f5ec217636":"Goedgekeurd ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Goedgekeurd bedrag",
"4d58ba5c0e03fec1556c243be106e4a3":"Goedgekeurd door",
"7d3d697fc99f6c4641b326623743f7b7":"Goedgekeurd { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Goedkeurder",
"8c45e443fc6748190719d79a092e2528":"Goedkeurders hebben een maximale goedkeuringslimiet",
"4fe56aa6456c108cd288a4ec8acf435f":"Archiveren",
"a9b6f93de9630064be9809130aa1e195":"Bent u klaar om de controle over uw kaartuitgaven in handen te nemen?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Wilt u deze aanvraag echt annuleren?",
"301bfc2ce058f49e0704fec3d162b5b5":"Weet u zeker dat u deze aanvraag opnieuw aan u wilt toewijzen?",
"2181faf8d9973d1f4138e401a762086e":"Weet u zeker dat u ${firstName} ${lastName} wilt verwijderen als bedrijfsbeheerder?",
"b982277f29055d0ef6e295fe2006d9e0":"Wilt u {firstName} echt uit het team verwijderen?",
"456c0ccff14acb808583cd9dece857b6":"Toegewezen aan",
"bde315b9cf7fd55f4b65aabbbf38d462":"Ten minste één manager of teameigenaar moet beschikbaar zijn om de Niet aanwezig te gebruiken.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Verificatie",
"1961ec69dd0c8b5718c831312e7f3d0f":"Geautomatiseerde inning van bonnen",
"e39f2477cf34d726936a5b5c2b48a4ad":"Maak automatisch nauwkeurige afstemmingsrapporten aan met informatie over kopers, goedkeurders, bonnen, boekhoudcodes en nog veel meer.",
"434ca07dfde0eaee14f094b7c75a6eae":"Beschikbaar",
"eb85d8f50f2c89871b794f7d54279b22":"Beschikbare balans",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Beschikbaar { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Terug",
"c8258f8cf9e20f6bbb89d957de40e820":"Bankwaardige beveiliging",
"d46c22a3f36dce568c07fcea4368b231":"Basisinstellingen",
"fb481c8e517016094b4fdf618c5fa088":"Factuuradres",
"79a9f821923c5a04c076902fef684281":"Factuuradres",
"877a08b8347052c83aa0b689baea81e7":"Factuuradres",
"97f018458e2627fac37d24846c7b8922":"Door verder te gaan met het aanmaken van uw account en Tradeshift Go te gebruiken, gaat u akkoord met onze",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Door verder te gaan met het aanmaken van uw account en Tradeshift Go te gebruiken, gaat u akkoord met onze",
"912d1864f0068c764bf4784020795d49":"NAAM KAARTHOUDER",
"d53474c7fd99fbeb69481ce9d358aa09":"Kan kaarten aanvragen en een portemonnee voor al hun kaarten hebben Elk lid krijgt een standaardgoedkeurder (manager) voor zijn aanvragen.",
"f30df2cab105ed3f8b4264aeb9800abd":"Kan aanvragen goedkeuren en kaarten maken voor de leden die zij beheren. Managers hebben een dashboard om uitgaven bij te houden voor de leden die zij beheren.",
"03ed9eccc71ef59165292eac542d3050":"Kan alle aanvragen en bestedingsactiviteit in het team bekijken Teameigenaren krijgen ook een teamdashboard om uitgaven en de teamportemonnee bij te houden waar ze alle actieve kaarten kunnen zien.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Kan deze gebruiker niet verwijderen. U hebt minimaal één teameigenaar of manager nodig in een team.",
"a0b70642629a55ea0ab6fa3414d342e0":"Kan gebruiker met openstaande aanvragen niet verwijderen.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Annuleren",
"5446624d3c364ac05ddf8d6663da2796":"Kaart annuleren",
"53f3815c4fa3340b29efd81e627d1e6b":"Kaart annuleren",
"12257ea2a871b7fab72245b5aafe6424":"Aanvraag annuleren",
"2aafb7eb4d56a516c4bb0754f0812df1":"Kaart annuleren",
"caae12601b01e2325161e95107f9e332":"Kaart annuleren",
"6ad1e57b1e4c7accbe166c948883077d":"Annuleren met een klik indien gecompromitteerd",
"f6527e49e6d5c989624507a583e23f39":"({count}) geannuleerd",
"1ad3bf5121641dda511f42942af05a90":"Als u deze kaart annuleert, worden eventuele openstaande bedragen niet teruggedraaid. Zoek naar de afrekening",
"aebd3826a195afad0a93050a7cc5d73d":"Als u deze kaart annuleert, worden eventuele openstaande bedragen niet teruggedraaid. Kijk voor de afrekeningsgegevens voor die kosten in het creditcard-afschrift dat door uw kaartaanbieder is verzonden.",
"a5b5136947da562f91b75daf8156f50e":"Kaartgegevens",
"9ed31ad23a1578ce480d31207decf1d3":"Kaartinstellingen",
"def15f2e007fc69886230fdb2511305c":"Kaartgegevens",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Kaartgegevens",
"67cfd260078ca32027c2fa4a4a2d29f4":"Kaarthouder",
"4458819ea60c68c46e551a8f5cc163cd":"Kaartnaam",
"0858bcbd506fa3240b165e59d2f18aae":"Kaartnaam",
"5212736dd18ee2c31f3650ad2c2c7130":"Kaartnaam bijgewerkt.",
"c96d62fa510c3356dc73a91d1a80e262":"Kaartnummer",
"41307fee4c32aefb5383a98fe3d6e45e":"Kaarttype",
"3f8045c3358621dad4346a44de0c05a8":"Naam kaarthouder",
"879a6b452033cf5f3a4bb3e1837a0386":"Plaats",
"593334553ef852024e172505ec3c5f21":"Klik hier",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Coderen",
"df931751d5790d74fa46707aaf7a26ae":"Codering 1",
"7ac7154bc776aff52a71b0d83b860561":"Codering 2",
"586fcf19136ae5e26795e9055b2e2995":"Codering 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Codering 4",
"379bb8e71bb090badebbb0751de86f11":"Codering 5",
"3e84d13627b3d090ab2b8470af98340a":"Codering 6",
"33b94b581f3792049992663396e51cb8":"Codering 7",
"b73fbc32e439655833ee99126dd0e55f":"Codering 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Coderingslijsten",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Coderingsvermelding",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Coderingsvermelding verwijderd.",
"cada62a39ee72d790dcdbb25160f8783":"Coderingsvermelding opgeslagen.",
"f645bf50888943ee1384693e56a0f475":"Naam coderingslijst",
"a999182acbcd77c15299f79181b630be":"Coderingslijst opgeslagen.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Coderingslijsten",
"d0b96596ce6baec5aabb8a0690ab30e1":"Opmerkingen",
"ff9cb8842f832d21fb160b3d3784ce79":"Bedrijf",
"715b59a64e686eb91a21114644ffe39e":"Bedrijfsbeheerder is verwijderd",
"8758e9a01253c097a42393fda9610715":"Bedrijfsdashboard",
"e118b41009918b326db6746856831cbc":"Bedrijfsnummer",
"4dba88b05b3fa8875128e19e88c512b3":"Bedrijfsinstellingen",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Bedrijfsinstellingen",
"f2ea6d33de8182d97bf2f13354af4893":"Bedrijfsinstellingen: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Bedrijfsbeheerders",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Goedkeuringslimiet bedrijf",
"7e2f778da14a726b53e05926a3483f2b":"Bedrijfscreditcard",
"7313fd1db3d63858586d1c0f89251d6e":"Bedrijfsnaam",
"07721e91dd10ff8831f0d24e47cc1eab":"Bedrijfsnaam",
"203221610ca4d347063c037e1a732ae8":"Bedrijfsinstellingen opslaan",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Actie bevestigen",
"d694cec09cdbc02b1ffe22642a63faa1":"Wachtwoord bevestigen",
"512801312a133f29974f2259a455f39b":"Wachtwoord bevestigen",
"f54c38ed0b9869d929ffc0bd5310131f":"Bevestiging - Uw account is aangemaakt onder Team {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Neem contact op met uw bedrijfsbeheerder voor het aanmaken van boekhoudcodes",
"7a6a4de077398e8e138aec36e3b3d305":"Ga verder met SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Alle rechten voorbehouden.",
"51f0d8e04f6ee626b866b37833dc2b61":"Kostenpost",
"e043f60a02f157d0f31a42f0db67cc87":"Kon niet uitnodigen {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Kon uw bericht niet verzenden, probeer het nogmaals.",
"ae28083f22a0b2fc5637614bce0976e0":"Aangepaste lijst aanmaken",
"738078dc992208e05406f935995dc0c3":"Aangepaste lijst aanmaken",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Nieuwe lijst aanmaken",
"0f5783c3921b3d7398d281644bbe5bde":"Aanvraag aanmaken",
"6d62f934f2304701f88bdcfbc688de7e":"Account aanmaken",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Een account aanmaken",
"f6b7d0d8531ea268f988005dd2147b2b":"Kaart aanmaken",
"1a083675d1453448e4645403bbe52dd9":"Aangepaste lijst aanmaken",
"c10ab11bdfb001da74850ed89c45482f":"Nieuwe aanvraag aanmaken",
"43a9c06398408bfe77d06ffc2c1c3b87":"Nieuwe aanvraag aanmaken",
"68bf17fd3f54a89c0a84224e2f9a971c":"Nieuw team aanmaken",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Team aanmaken",
"3b8e665101b9b3657f8a68c5305947b7":"Aangemaakt",
"40e91f2907181ffa8e62567b3a407e60":"Aangemaakt door",
"8929f7aacb969ef6353aba9903eb362d":"Creditcard toegevoegd.",
"d86688c26e8ac5eb9facc971b6fe5155":"Valuta",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Valuta",
"500ded53f64e737a5e4337675ac9b7ce":"Naam aangepaste lijst",
"b79a95f04fd829fb2e8d0313e522a94d":"Dagelijks",
"fb7add662cf7042355319a975fab6ae3":"Gegevensversleuteling",
"6bded249dbfafd14490eea8dd37e1f51":"Datum",
"46e908a57cb09ef947af8c4a93a09716":"Datum",
"e4213104a55eceb60e00408699438dcd":"Team deactiveren",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Als u een team deactiveert, worden alle actieve virtuele kaarten geannuleerd en heeft geen van de teamleden, managers of eigenaars toegang tot het team. Alleen u of een andere bedrijfsbeheerder kan een team opnieuw activeren. Wilt u dit echt?",
"0d0d5677bee2b99884802638e507160a":"Weigeren",
"b5e3483aae9d36ce1440fe02904d6da1":"Aanvraag weigeren",
"00f3db97287cb17ee9c2a131f2141a65":"Geweigerd",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Geweigerd ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Standaardgoedkeurder",
"b77589ac3ac2317cfc96d7013658d5c9":"Coderings-ID verwijderen { entryId }, Vermelding: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Gedetailleerde verklaringen en rapportage",
"dd0a6194e8a6ae14f3e1379a1640de15":"Was het uw bedoeling ({email}) uit het bedrijf te verwijderen?",
"1a98048b34090da8b199135ccb4c1186":"Onderscheidend nummer en CVC verbergt echt kaartgegevens",
"3cf132b60fe16b816ca227bbcad60537":"Niet annuleren",
"dee57797e14ccf852aa2bc545bafdf6d":"Downloaden",
"375ae8224d7d35393974775cebe8605a":"Gebruikerslijst downloaden",
"54b3bfd45a293b441d441aab1a50e644":"Rapport downloaden",
"60cf3b94e5df14a504825068928fc118":"Rapport CSV downloaden",
"7341a4c73c0b688484304c37da4a6d8e":"VERVALDATUM",
"f628f8ad44fbd6c70c99fc778407b666":"Zoek eenvoudig historische transacties en ontdek trends in personeelsuitgaven gedurende elk tijdsbereik.",
"94bb409d034ea20697ae09d812e55365":"Aangepaste lijst bewerken",
"2974e704676cb79666bc236d8658fa97":"Profiel bewerken",
"9ff205f99ed4002d049f855f965ca6d8":"Gebruiker bewerken",
"3d7a4a9f4e70f57463e9a97caadb8205":"E-mailadres",
"0534c00af758d42ebd8b0bd772eab074":"E-mailadres",
"0c9aa1fbdf925065d25161eab5e51d95":"E-mailadres",
"ea78fac68766a48b1b0f41305b7e9898":"E-mailadres",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Werknemer vraagt geld van de bedrijfskaart om de aankoop te doen. Gevraagde fondsen kunnen bestedingsbeperkingen hebben ingesteld.",
"829cff16ce0b13810c914c161b2a9fba":"Afwisselen inschakelen om gebruikersfuncties te krijgen",
"741387c7bd71e2b4a9601af9a6b9490e":"Automatisch doorsturen van goedkeuringsverzoeken inschakelen",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Standaard vervaldatum voor de virtuele kaart inschakelen",
"bb319710dad93e78cc6f58c615bdf210":"E-maildomein voor opname in whitelist voor uitgenodigde gebruikers inschakelen",
"4700ced24cd278c3149ffa983006035c":"Filteren op kostenplaats inschakelen",
"3fe06ef8f551c4cb4c0375362e25521d":"Globaliseringsoptie naast de gebruiker inschakelen",
"2787d4bc9752272ac36b499ce7ad93ab":"Virtuele kaarten voor meervoudig gebruik inschakelen",
"6ee44e2280c9ce5009568846cbc3dd14":"Niet aanwezig inschakelen",
"1941ca87053df0cebc6091ede92c18ea":"Weergave personen inschakelen voor bedrijfsbeheerder-gebruikers",
"e50282c2ec7afb948d2c0b428db5d5ff":"Bon voor transacties uploaden inschakelen",
"268694cb2172918fe60f835919bf20ec":"Bon uploaden in afrekeningentabel inschakelen",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Zelf-goedkeuring inschakelen",
"d17c7acf598b569cce8a2bb97e0bb215":"Virtuele kaarten voor eenmalig gebruik inschakelen",
"cd731ca218c9e14caabefafe003cfa6d":"Virtuele kaarten abonnement inschakelen",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Beëindig doorsturen op",
"c1f665a814894b17d603b7052b631d90":"Nieuwe kaart inschrijven",
"6b6cea93e07e596be70f7b76801e1516":"Nieuwe kaart inschrijven",
"7d6ac1ac9cba1620b61908842ebb106b":"Bronkaart inschrijven",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Bronkaart inschrijven",
"21a642b339c3eabf2789c0b05dcf828b":"Schrijf uw eerste bedrijfskaart in bij GO",
"6efa8dca4fba23a190838746879fd704":"Voer een numerieke ID in",
"02c3457577da9dc8dba6e6f9380a248e":"Voer een unieke naam voor het team in",
"40feb3fd1fa529cb599aeac9607664a4":"Nieuwe vermelding invoeren",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Voorbeeld: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Vervalmaand",
"b8bc74aeeb2de3492891c97361de107d":"Vervaljaar",
"6a0b8d521500014d99dd192d8ce8403d":"Vervaldatum",
"8cf274169a732340430fe7cc5236e0e7":"Bestandsgrootte moet kleiner zijn dan {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Voornaam",
"7458b55e967e51791d98e77458631e44":"Voornaam",
"3bbe52e71bc5c212cea77983f1a5b748":"Voornaam",
"9b49c88fa20cc006baaac15c857c8781":"Voor enkele of meerdere aankopen",
"bb7a2907073bd760f4e82097132637b1":"Wachtwoord vergeten",
"26bdfaa592f3629273f13c22435b323d":"Wachtwoord vergeten?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Doorsturen",
"3f9fe6adc14ce5776097170653a24462":"Aanvraag doorsturen",
"b774e35683f5737c36bf0cf3d6785973":"Aanvraag doorsturen",
"628faa5054a912b1e470414f77057e6b":"Aanvraag doorsturen",
"852b4065e1837fdc77940b6411a6d902":"Bescherming tegen fraude",
"616551bd10d95aca439401d6a7769d96":"Vrachtkosten",
"a65a230918e9c11e1ef8824745e26a3d":"Aan de slag",
"7d4c4466b7716347ce3673acaa1983f7":"Haal meer uit Go",
"11415a07658cfef80eb166896d77ad98":"Maak je klaar voor nog meer bestedingsinzicht. Tradeshift Go sluit nu ook beter aan bij de manier waarop zakelijke teams graag werken.",
"ac0ff5d14286ef74614a9c6021f08f69":"Ontvang realtime-uitgavencontrole en budgettracking, krijg inzicht en bekijk alle inkoopactiviteiten.",
"db9071ab833054307252a936e7f60e1b":"Aan de slag",
"24dc4bb00841b004031fca3a9bb9bf51":"Go-kaartverzoek geannuleerd door {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go herinnert werknemers aan ontbrekende bonnen, die eenvoudig kunnen worden bijgevoegd door op de melding te reageren of, in het geval van een e-mailbevestiging, deze door te sturen naar Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go werkt met uw creditcardnetwerk om dezelfde fraudepreventienormen te garanderen die u zou verwachten als wanneer u met de meest vooraanstaande financiële instellingen in zee gaat.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Goed nieuws! Uw aankoopverzoek is goedgekeurd op Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Hebt u vragen of feedback? Neem contact op via",
"9df1d3de68d663b848101df3c7b58e39":"Hoe werkt het",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Ik weet het zeker",
"84074732eb8d1b4094404cd750baf949":"Bij geen waarde geldt de bedrijfslimiet",
"138e8bd6102a7eea70a123ff03b1cd79":"Bij geen waarde geldt de teamlimiet",
"e5756af4d7e5ad1236ccfbb858428421":"Als u zich niet met dit e-mailadres hebt geregistreerd voor Tradeshift Go",
"4f004f75f47dbfecdb6d09990596e530":"Als uw e-mailadres nog niet is geregistreerd, sturen we u een activeringsmail met details over hoe u verder kunt gaan.",
"123837f267de602fb05361b3b1197c1e":"Als {username} overeenstemt met een bestaand account, sturen wij u een e-mail met informatie over wat u moet doen. Als u na enkele minuten nog geen e-mail in uw postvak hebt ontvangen, controleer dan uw spam-map.",
"45efd96cd93525a9c17222217a37d324":"Ondersteunde beeld- of PDF-bestandsindelingen",
"fa6ae823f9ab8bf91c246efe2517956c":"Importeer uw QuickBooks, NetSuite of Xero-accountinstellingen rechtstreeks in Go. Exporteer de laatste transacties met één klik in uw boekhoudoplossing.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inactief",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Invoer bevat een ongeldig teken.",
"62c7be09da43f8f96a60ef3967eddc2c":"Introductie van een geheel nieuwe Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Ongeldig e-maildomein",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Mensen uitnodigen",
"3809b41b14c55c3fcf54cd813d8e21f5":"Voor team uitnodigen",
"fc90a11805961b0d7b5a5545e4d9bc05":"Gebruiker uitnodigen",
"6f7f4acde92d267417f5d8a60d32400a":"Nodig meer mensen uit",
"09ecd2ca15bb5c38dc10f653118346cd":"Nieuwe beheerder-gebruiker uitnodigen",
"3f576ce504f53f972442a63a170c5418":"Nieuwe uitnodigen {role}",
"0215e052437ceea4e5cd562431b34ced":"Mensen uitnodigen",
"8aaf2c77df644b7e8524120f66da7d5f":"Factuurbedrag",
"1b7dc8290f1205f092cc9133e0cde385":"Factuurdatum",
"1a6fb210308df8e0135e49e76aaff31e":"Factuurnummer",
"613da3d0cd586ef0360e0d93eace9508":"Uitgegeven op",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Het duurt",
"3322b671105c65511fbab1966b7fdcf6":"Taal",
"b1d946192795a58aea6a61f4f923acb7":"Laatste 4 posities",
"b3c6b8db4ef6dcdcb451303e65548816":"Laatste 4 posities",
"0382123b9c847000fceff2484c665e39":"Laatste vier cijfers",
"19f7c0055e150e9e3e66e85c2993799c":"Achternaam",
"662c84bede8a7bf91ab15bcb73b48b49":"Achternaam",
"cff8fbef5aac2523344b9535bd56973c":"Achternaam",
"c3510b2184a92ff12e07a2d1b9a461e6":"Zorg dat medewerkers toegang kunnen vragen tot uw bedrijfskaart zonder uw kaartgegevens vrij te geven, terwijl u tegelijkertijd budgetten kunt afdwingen en alle inkopen kunt bijhouden.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Limiet kan op een team- of een afzonderlijk goedkeuringsniveau worden overschreven",
"9bf5d56627e500cad9f7ad21c3e7966d":"Limiet voor bepaalde onkostencategorieën",
"e80a6d4232e52f6bfabee02e17403b59":"Koppel een van deze bronkaarten aan dit team of schrijf een nieuwe in.",
"803570ab9f0fba5ff35ae2749200501f":"Kaarten laden...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Inloggen",
"ad96c1ca962a883652dadb845f439415":"Inloggen",
"072ca67e735adbed54acf41321370701":"Inloggen",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Inloggen",
"b246fe8836c906f1b56342378ca88895":"Aankopen doen, goedkeuren en controleren in teams die een afspiegeling van uw organisatiestructuur vormen.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Afrekeningen beheren",
"95d672ab621e531ae1a69798e0e4b56c":"Beheer al uw virtuele kaarten op één plek.",
"96f2e88a167f476413b35aa711709e6e":"Afrekeningen beheren",
"151d8e6ceec242d482fff60da8311449":"Beheerder",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Beheerder",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Manager ontvangt elk verzoek tot beoordeling en goedkeuring Bestedingsbeperkingen kunnen ook worden aangepast.",
"4f449985e83c1f9ca2e8f82b0707a371":"Manager ontvangt elk verzoek tot beoordeling en goedkeuring Bestedingsbeperkingen kunnen ook worden aangepast.",
"27f8111a955975ea6fd04f748d24cb4a":"Maximale goedkeuringslimiet",
"5b1d9cfb78c55e75b47c62435d9299ca":"Lid",
"41e307c1874e2e82296b226e9bae4a5f":"Lid",
"6f9612aa1582373c2c1ba4e144132100":"Leden",
"590429a0fcc42af85648d0fd70601dcc":"Leden",
"3f50e62c8488d83ad84f69ebda853897":"Leden",
"e0fc0acda5b74b93e797756dbbba67bc":"Leden",
"c7ef9eda38b7eaee1f20031387a8bde7":"Maand",
"40dd08b507752bef4e3c4b36838317d5":"Maandelijks",
"d712b91f7498f212047aad4c6d0f8088":"Meest recente",
"12110d95b3d7a495afa4f28ac190f63e":"Meest recente",
"2f11ce3865f79ee7e5ee64d728082264":"Meest recente",
"eadc93865655cffc6a9863fdda5693e1":"Moet ten minste 10 tekens, met hoofdletters en kleine letters bevatten.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Moet ten minste 10 tekens, met hoofdletters en kleine letters bevatten.",
"50d0a69aa59df5201018771a06ea12e7":"Moet uniek zijn",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Moet uniek zijn.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Mijn kaarten",
"c36602f50d35c2816f0ba8a196b70876":"Mijn aanvragen",
"ff6e372aa3eb685a39a701963db35b2f":"Mijn aanvragen",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Naam",
"1c7514b561a2591a914225813d30d642":"Naam",
"d621826c1ceade262589af04e655e824":"Nieuw wachtwoord aangevraagd",
"35dc3304b61a9e69dc3a393123658837":"Nieuwe aanvragen worden tijdens deze periode automatisch doorgestuurd",
"e788124801b4727d121f60d059a2a4a7":"Volgende",
"eca804f1d53dfd984ffd93e2d00fc284":"Geen goedkeurder toegewezen, neem contact op met uw programmabeheerder.",
"abdf7e93f8509399a63108a2b1a25546":"Geen activiteit om weer te geven",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Geen kaarten in uw portemonnee",
"87127e1e9f5bf922d0a4f948b68697c8":"Geen limiet",
"40512efa6b75ef0c7924b1ee3070fca9":"Geen leden om weer te geven",
"dcda493afcb2415d5ca49a17d1af6f50":"Er zijn nog geen afrekeningen geboekt.",
"f0efb26e623bb990532e375d5c8875ec":"Niet beschikbaar",
"bc0cf602d1978e814070bb552e576a32":"Niet toegewezen",
"09e126c317429ccb52ad323c32293648":"Aantal betalingen",
"75b00b969b9d3b520929f1e652637b70":"Aantal betalingen",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Aantal betalingen",
"bd44ba180aa356c5755fdedd32adf03c":"Oh nee! Er was een probleem bij het aanmaken van de aanvraag. Probeer het nogmaals.",
"c396b3728dd7393aa9e5427990c132a7":"Oh nee! Er was een probleem bij het downloaden van het rapport.",
"42c07764b07dea1ee7de7605721c3f5f":"Oh nee! We konden geen nieuw team aanmaken.",
"e3a385878ec94bac12a99490d0a8e85a":"Oh nee! We konden uw account niet aanmaken.",
"17f2dae79677017e2c2e879578c4e09a":"Oeps! Er is iets misgegaan. Probeer het nogmaals.",
"78c135de545c1663c9768226492a44a3":"Of gebruik Single Sign-On (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Niet aanwezig",
"5d7e45f169e2f6968dc58f43b5267353":"Niet aanwezig",
"ee589a20c262527db970671fb2c4f6bc":"Niet aanwezig",
"4fd0c753fce49a016b98d988a3895223":"Overzicht",
"2789c5d5c9c042858f45aadc4e0de058":"Overzicht",
"083b7090b66ebd0307a18499ac7b676e":"Pagina bestaat niet",
"a21d205920893cf579a7655a1430fe30":"Bovenliggende kaart",
"fd35b7ce938e404fdb65de3bb749c528":"Bovenliggende kaart voor rapport",
"e8c7d2eea7db66ecb44751091a582304":"Wachtwoord",
"bb79e316e00fd21138750b6986e44455":"Wachtwoord",
"6cae2279088580976d84119d95097cae":"Wachtwoorden komen niet overeen",
"4baec0159d3074f84c0eebcc09ba4cd8":"Wachtwoorden komen niet overeen",
"b676587115243921602742cb8f696a6a":"Kaart aanmaken onderbreken",
"db4a26c871ef0b970b74e1f71763098b":"Onderbreek de uitgifte van nieuwe virtuele kaarten",
"33fc1e6d01b785e3a815c28ef7d56667":"In behandeling",
"6ea62e4811b68df48b467e8d367aac2f":"Openstaande aanvragen ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Personen",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Mensen in een team kunnen 3 verschillende rollen hebben",
"e2d34cb1409b9778d34c4b74ecee1df6":"Voer een positief heel getal in of laat dit veld blanco.",
"8da247d5d736c87122404eff1e5c0c73":"Voer een geldige limiet in.",
"65e154267ac283d963cbc7be832969d9":"Voer een geldige waarde in",
"28b4450d417065a69a395f567967d46c":"Voorinstelling met gevraagde bestedingsbeperkingen",
"7bbf151d8493170f206a23c2ecc6acd0":"Privacybeleid",
"51602962f2d1e7515943b4a6c66562ce":"Profielafbeelding",
"a51a17940c70a3123da4d68bed562c40":"Profiel opgeslagen!",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Providers toewijzen",
"89b1deb2ffe12a13941c362abaeab01b":"Gepubliceerd",
"d8d806d48052159e3ff3b224f3f53586":"Doel",
"089917619c78af2efb30be0bb7ce076c":"Team reactiveren",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Reden",
"e8484c78fda9f453eeb942ba4945f040":"Opnieuw toewijzen",
"ff2fbc1172ba26776ad0982624cd2713":"Bon",
"588e690db177ee0c7a7cadb39cbd5efc":"Fondsen elke week, maand, jaar vernieuwen",
"32955937daca05b2084556f248692baa":"Registreren",
"f15bb755ba24abe6ab106637292175ec":"Resterend",
"579782461133a8b8cc6bd77798961d82":"Kaart verwijderen",
"e475edc165372f8c8deb532aa85d9950":"Lijst verwijderen",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Lijst verwijderen",
"f9769561315b964d0b3b4887ec208977":"Uit team verwijderen",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Bon verwijderen",
"ee181cef581fc8e8217e66b5762db898":"Als u een lijst verwijdert, wordt deze niet meer gebruikt en worden alle bestaande vermeldingen verwijderd. Als u tijdelijk een lijst wilt verwijderen en de vermeldingen wilt behouden, kunt u de instelling Publiceren uitschakelen. Wilt u deze lijst echt wissen?",
"feea41d0bc1d577a07c8b6e1297abbde":"Als u een bronkaart uit een team verwijdert, worden bestaande virtuele kaarten niet uitgeschakeld.",
"d09483824a2e0d6194a4c3f32311e752":"als u een bronkaart uit een team verwijdert, worden bestaande virtuele kaarten niet uitgeschakeld. Weet u zeker dat u deze kaart uit uw team wilt verwijderen?",
"7ca4b1bf1346e179a29da97f260a93a4":"Naam wijzigen",
"db195ec9c818b8e92da142374e20d1bc":"Codelijst hernoemen",
"2fd93e229fe9ea754808805d3fef9c13":"Codelijst hernoemen",
"aa1e74d32805823f802a11b33c60c38b":"Type rapport",
"6ac78d97a8cb7f060e43e596e3e23535":"Verzoek geannuleerd.",
"25deccb1980291a0fa9997109c731440":"Demo aanvragen",
"9f2754d87686903b1500ce8e8a55b377":"Demo aanvragen",
"92ac924f72679f897e26f3e615be8c22":"Verzoek doorgestuurd.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Informatie aanvragen",
"270185007853cd779011ae0e8433f723":"Verzoek opnieuw toegewezen.",
"e5a059c9ba2691910321339cce989f9d":"Aanvraag verzonden.",
"77c88a231edfa7075114d91d2397e42d":"Verzoektitel",
"cee91b833c4358acf0c554f746557e71":"Verzoek is geweigerd.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Verzoek is geweigerd.",
"9e56abbbf87420eeedd625ce786515c7":"Aanvrager",
"b4cb4426e44fee9580af71912a764536":"Aanvragen",
"df309e888c29fc447d0309329b39ce93":"Wachtwoord opnieuw instellen",
"5daf278da19ecbaeb7b96554cb4db10f":"Verbinding opnieuw proberen",
"31e9501526d2114e4a01fdbf076de097":"Rol",
"6fbceb787429f6f81117a1d5a58b4610":"Rol",
"473b814ca86841ef672ccf9f7dac28d5":"Rol",
"f1e16d5fd643e039d865b6aec7c986f9":"Rol",
"ec78345a5975bc0f8efaee5256b43c2c":"Opslaan",
"2cecea57b47d8f4a45c706287ff7d64e":"Wijzigingen opslaan",
"15774be5c338a39169917c7d32781dd0":"Opslaan en doorgaan",
"844095b4b8f580737b8717672501048e":"Zeg iets...",
"7e66f676c2e7b04514aca3a0935530f0":"Zoeken op code-informatie",
"5293ce9335ec2d176a6e838bf76404c6":"Zoeken op omschrijving",
"efab3b0c95f263cdd0a8466329a387eb":"Zoeken op omschrijving",
"d227b38dcbc4548c092b0836a7101506":"Zoeken op verkopersnaam of transactie-ID",
"3629383abf1d5f85efd759f718159b40":"Zoek op naam",
"a034fcd6719228b28a6fc22d3a46ea8a":"Zoeken op teamnaam",
"8ac7e0d63f343b548482d5f367f5b3d9":"Zoeken op teamnaam",
"858323eb52cb45446597ee46330a0540":"Zoeken op eigenaar virtuele kaart",
"111bfcbff671e13de117ed5195fb6773":"Selecteren",
"bcfecd59eb93e5314873d84ddf35aa58":"Selecteren",
"909a4414ea573218bff2d533eaab46ea":"Einddatum selecteren",
"114b206ca1ff1c0bc52abef342059b2a":"Teamopties selecteren",
"81a02e424615fb41a20b8d0f13441c88":"Teams selecteren",
"b135b36495618e6692e7fbaf0a0aa83e":"Selecteer een maand",
"677e90855e164c00a28b5c4763ac67e0":"Kies een rol",
"d01570f3774738395b8ba3c64ce54c76":"Selecteer een status",
"7dee3f06c70626881efb0cd6af6b63e1":"Selecteer een gebruiker om het verzoek door te sturen naar",
"4c7bb810835a1540ddc1f6501da5a51b":"Jaar selecteren",
"3ab198d2678af2b5b32180e4b39602ba":"Goedkeurder selecteren",
"f502ba79f2bd010c5e77891812400958":"Kaart selecteren",
"936027182d446e27ba209ea921ef7502":"Selecteer standaard vervaldatumbereik",
"b22c893254ccf3bd34faaee511cf7ca8":"Tijdsduur selecteren",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Selecteer uit vooraf ingestelde coderingslijsten maak uw eigen nieuwe lijst aan.",
"42cce5fa376b45bf290f86c684d69d96":"Providerveld selecteren",
"b761773d46699b60e8d6874b85593374":"Teams selecteren",
"3a9396756942ede98d0cb6afc1032b15":"Selecteer hun goedkeurder",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Zelf",
"03e9d2c128af288126d94f631c5396be":"Instructies sturen",
"299a7e961804e2d649f06b5dc4362c29":"Uitnodiging verzenden",
"155e7b3f7db7bf10b03b5f41de54eae1":"Bericht verzenden",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Stel een vervaldatum in",
"f89ec96a4b5bb639488ab742efa76a6a":"Nieuw wachtwoord instellen",
"7d5377ad70d57728a45d3c0639de5544":"Instellingen opgeslagen",
"14313f11dee735f2144953e720776c28":"Afrekeningsbedrag kan anders zijn als fooien of andere toeslagen op de inkoop worden toegepast",
"199000fe0cda0adaaa9b864aa7447cb9":"Afrekeningen",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Afrekeningen",
"6e6ac6ff3bd722e37874fb1ea7115818":"Stel uw wachtwoord in",
"9b4ecf052980e80bf5c65a415d470a43":"Toegang tot uw bedrijfskaart delen en beheren",
"ba746d78772d6be26cc508ba989becb0":"Moet lager zijn dan of gelijk aan {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Transacties zonder bonnen weergeven",
"7d474b60aeb7cc25b519e806fca71e12":"Aanmelden met uw bestaande \n\n Tradeshift-inloggegevens",
"16080cd4e8319ef3e7de21b6c19d1437":"Er klopt iets niet",
"8f358a370224baf849f9652810c68db2":"Er is iets misgegaan",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Er is iets misgegaan",
"99af87c475a4d40ef732deed96f11ed2":"Er is iets misgegaan. Probeer het nogmaals.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Sorry! We hebben momenteel problemen met verbinden - opnieuw proberen over {retryConnectionPeriod} seconden.",
"84debc8e41646cd49ac2b14708ca6d73":"Sorry, er was een probleem bij het verwijderen van de coderingsvermelding. Probeer het nogmaals.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Sorry, er was een probleem bij het opslaan van de coderingsvermelding. Probeer het nogmaals.",
"689d3418ee8f996ad25e152e80de63fe":"Sorry, we hebben u nu niet kunnen uitloggen.",
"b48efd4521ecd6428987d24636a4349f":"Sorteren",
"776a95003d3ec1dd58af69aedbc08579":"Sorteren",
"1b783238028c0ae085ca69ed823f4d1a":"Sorteren op",
"be70d19bf13c935d653b2c4fe7d36224":"Naam bronkaart",
"564f8c942e7d1bdda5251545612fa348":"Naam bronkaart",
"aa284602c746aad4a6de6366c24221b8":"Bronkaarten",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Bronkaarten",
"389d471e79a43440b3278b0fc7187d9e":"Bronkaarten",
"106342f689b57145817b00d6fe383f91":"Bepaalde einddatum",
"2a5e19387ff300509876e3bae99b7ab9":"Uw boekhoudproces versnellen",
"ca34d586bbdb7f368f1009305bccbe76":"Dashboard uitgeven",
"4583120998b44c0a5982cf85dd938d2f":"In loop van de tijd uitgeven",
"244d724806247953233a0bbfcbf8da4c":"Bestedingslimiet",
"d39b83dd151c8df6370460a458294ccf":"Uitgegeven",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Uitgegeven bedrag",
"5de2859528e030ac1f2af115ebdf209c":"Uitgegeven { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Doorsturen starten op",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Status",
"1f1fe111548158ec842761eca7425ff3":"Straat adres",
"31a0a748b1097a9e0aa95a811802bdd0":"Stressvrije afstemming",
"111b782f3f0794c2294aefb6693b2c50":"Verzenden",
"013e2555920b51ad2a3d2c3dc482c471":"Aanvraag verzenden",
"1270cd8eb69ad64cc71e5de979423b3b":"Abonnement eindigt",
"2802336ad3d8ff658257b88a93f4e50a":"Abonnement eindigt",
"b28dd68454d7acbefafeefb42981fe16":"Abonnementfrequentie",
"907793bc80cf94d560e5f1a0250fdaee":"Abonnementfrequentie",
"29229e153308e4747edef1144de35a55":"Succes!",
"503eb46b80f374f39266e7a9aea8e63e":"Succes!",
"4f20e29cadadb9fbc7455bd4d80de567":"Succes!",
"601d04f10d558f436c78c10e90b44175":"Ondersteuning",
"62558c185ea2c06c5472d1cd534f6e56":"Synchroniseert met uw boekhoudoplossing",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Belastingbedrag",
"bf23b2fd889e3b12e0f0acf99f913eca":"Teamkaarten",
"b90a650376782a9e43ed6be6135bd6f2":"Teamdashboard",
"6adbec395fe1c4bf776f9fe5fa021052":"Teamdashboard",
"6f2695eeb68501dbcfddbb4d265b846b":"Teammanagers",
"3b779ade9c960ac95a670db0cc12e56b":"Teamleden",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Teaminstellingen",
"dc5235e1b88320d3ba7501e4c30985a3":"Teaminstellingen",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Teaminstellingen",
"a2eb92bfd7502f47b3cd170bc68ac638":"Teaminstellingen",
"f1ec84f051f19f0d0e301a709b13dc45":"Teamuitgaven",
"fbacde3a262ea8696e30135e8f987a44":"Teamschakeloptie",
"74b9578ea5a620f5fef102188e42c02f":"Teamschakeloptie",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Teamacties",
"3aa978fa7ef886621285b93257d428c3":"Goedkeuringslimiet team",
"a41f9df131c221a99926de7e2138653a":"Teaminformatie",
"941c7da8f256f91ba3819f991315a100":"Naam team",
"c5d04e10cc2f0b53c2bda893eae09178":"Naam team",
"504a8ba642ba37c9a743d0fabb2bd371":"Naam team",
"e768a8e1e3d6a6bf470587dad75423fe":"Teameigenaar",
"e2d824067f8bd871756688c58c382196":"Teameigenaren",
"dca232ccc849c5ff5611255eb82baf53":"Teameigenaren",
"3b61bd586225790cf132591383d15448":"Teamschakeloptie",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Teams en afzonderlijke limieten kunnen de bedrijfslimiet nooit overschrijden.",
"4b4fe189a48d8eb06d836afa8fb6317a":"Teams bijgewerkt.",
"2a17f9a8c610db29fec63fd530c29fdc":"Teams die de kaart gebruiken",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Teams die dit zien",
"317868f3804ba4ed5d99dd9919c798ba":"Servicevoorwaarden",
"e8c9a9847db2ccd97524aa06f1af9d82":"Servicevoorwaarden en privacybeleid",
"38852078519c5c46715d8a034fc4901b":"Servicevoorwaarden en privacybeleid.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Bedankt voor uw interesse in Tradeshift Go. Ondersteuning voor {cardLabel} creditcards komt binnenkort. Een lid van ons klantensuccesteam zal zich melden zodra hij beschikbaar is.",
"f7047b35a069d0790a0a348a29c846a2":"Bedankt dat u zich hebt aangesloten bij {companyName} om zakelijke aankopen te doen met behulp van virtuele Go-kaarten.",
"133b0a70b1a956889164621380c378f9":"De actie die u probeert uit te voeren, kan niet worden voltooid. In sommige gevallen helpt het gewoon opnieuw te proberen.",
"18f5a1872e156f1aee84ae321780d952":"Het gevraagde bedrag overschreed de aanvraaglimiet van het bedrijf van $ {requestLimit}. Probeer het nogmaals met een lager aanvraagbedrag.",
"867e3d4d3c78990c6852fc5348706ab2":"Het gevraagde bedrag was hoger dan de aangevraagde limiet van de provider, namelijk ${providerLimit}. Probeer het opnieuw met een lager bedrag.",
"570f5f4658b03dda7a910f56c86a3bd3":"De kaart voor deze aanvraag is geannuleerd.",
"3075c56105863546e3368ffead7a146b":"De kaart is geannuleerd.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"De coderingslijst waarvan u de publicatie probeert ongedaan te maken, is gekoppeld aan een integratie. Als u zeker weet dat u dit uit de integratie wilt verwijderen, neem dan contact op met Ondersteuning die deze actie kan voltooien.",
"756ade8d71ec487a4b48d7c4277ea239":"De coderingslijst waarvan u de publicatie probeert ongedaan te maken, is gekoppeld aan een integratie. U moet eerst de toewijzing verwijderen voordat u de publicatie van deze coderingslijst ongedaan maakt.",
"4f40335f7c018986c95458183bc0c136":"De koppeling om uw account te activeren is niet geldig of is verlopen. Neem contact op met de persoon die u heeft uitgenodigd om nog een uitnodiging te ontvangen.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"De koppeling om uw wachtwoord opnieuw in te stellen, is niet geldig of is verlopen. Vraag een nieuw wachtwoord aan om een nieuwe koppeling te krijgen.",
"ded30f0e288c12125ca1a77f8d8fd600":"De reden is zichtbaar voor de goedkeurder en de aanvrager",
"f294897b38fc23dd8f5de52b54ceae22":"De aanvraag wordt afgewezen en de aanvrager wordt op de hoogte gebracht. Wilt u doorgaan?",
"e8c7200dc985df0a94a9d996968b4cc0":"De aanvraag wordt afgewezen en de aanvrager wordt op de hoogte gebracht. Wilt u doorgaan?",
"5f3d196a2ade3f97a2a48b773fec5861":"De aanvraag wordt afgewezen en de aanvrager wordt op de hoogte gebracht. Wilt u doorgaan?",
"b04c191cd6b4f27669a4f390311f156c":"Er zijn geen teameigenaren of managers om te selecteren.",
"e25045557efbad7b52997b94a1fe6817":"Er zijn geen {type} voor deze periode",
"7260690577c795e999acae63bb7c51ae":"Er is een probleem opgetreden bij het opslaan van de teaminstellingen. Probeer het nogmaals.",
"f2103024f2a9c6c0335a71c60567d6c9":"Er is een fout opgetreden bij het laden van uw portemonnee.",
"9776cb7eab9fa0d318a8144cd17de745":"Er is een fout opgetreden bij het laden van uw portemonnee.",
"77d28a14bc900be4360b570a82eb75a9":"Er zijn geen gegevens om weer te geven",
"2b6feba67722337f88c1443909fe446f":"Er was een probleem bij het toevoegen van de goedkeurder.",
"24b7212afb97c5d23faafa2ca4559095":"Er is een probleem opgetreden bij het toevoegen van de creditcard. Probeer het nogmaals.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Er is een probleem opgetreden bij het toevoegen van de bronkaart aan het team. Probeer het opnieuw",
"5a8e1b922d1cee746ac6794599b10709":"Er is een probleem opgetreden bij het annuleren van deze kaart. Probeer het nogmaals.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Er is een probleem opgetreden bij het annuleren van deze kaart. Probeer het nogmaals.",
"22361c5d4df61ad7ae9792a3baf5729f":"Er is een probleem opgetreden bij het aanmaken van uw lijst. Probeer het opnieuw",
"098755bfddeaf0428c7feb6b69af9b6f":"Er was een probleem bij het afwijzen van de aanvraag. Probeer het nogmaals.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Er was een probleem bij het afwijzen van de aanvraag. Probeer het nogmaals.",
"7d461b6b7b54dbfa9c83243217cd1053":"Er was een probleem bij het verwijderen van de coderingsvermelding. Probeer het nogmaals.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Er was een probleem bij het verwijderen van uw lijst. Probeer het nogmaals.",
"beb43d7800cfff47e08cd61842d953bd":"Er was een probleem bij het doorsturen van de aanvraag. Probeer het nogmaals.",
"f7775350f35b8365fb9803232d9b792c":"Er is een probleem opgetreden bij het ophalen van de kaartgegevens. Probeer het nogmaals.",
"95abaa922c6c1648ca9c39ac4776896f":"Er was een probleem bij het ophalen van de gebruikersgegevens. Probeer het nogmaals.",
"9d7e4ead60137041ae8a845aa6cf9979":"Er was een probleem bij het laden van het gebruikersprofiel. Probeer het nogmaals.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Er is een probleem opgetreden bij het laden van de gebruikers van dit team. Probeer het nogmaals.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Er was een probleem bij het laden van uw instellingen. Probeer het nogmaals.",
"b2f48028c400befad5f1d57f769ef7cd":"Er was een probleem bij het opnieuw toewijzen van de aanvraag. Probeer het nogmaals.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Er is een probleem opgetreden bij het verwijderen van de bronkaart uit het team. Probeer het nogmaals.",
"19dfcd761006496c1d20351c04643e45":"Bij het verwijderen van dit domein is een probleem opgetreden. Probeer het nogmaals.",
"149651b5784481c68aaf4ac27bb1f907":"Er was een probleem bij het opslaan van de coderingslijst. Probeer het nogmaals.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Er was een probleem bij het opslaan van het profiel. Probeer het nogmaals.",
"749ddc235744b6b371668ec0bb049176":"Er was een probleem bij het opslaan van de gebruiker. Probeer het nogmaals.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Bij het opslaan van dit domein is een probleem opgetreden. Probeer het nogmaals.",
"04cf86909808f430b8b1904fb422e0d3":"Er was een probleem bij het opslaan van uw afwezigheidsinstellingen. Probeer het nogmaals.",
"2eaf6ee97d984598c95e0494d72aa492":"Er was een probleem bij het verzenden van de inkoopaanvraag.",
"148f847d3beccaa67fcd5096ae3602d3":"Er is een probleem opgetreden bij het bijwerken van de kaartnaam. Probeer het nogmaals.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Er is een probleem opgetreden bij het bijwerken van de kaartnaam. Probeer het nogmaals.",
"de8cc976f7af9edf189904239e6240da":"Er is een probleem opgetreden bij het bijwerken van de teams. Probeer het nogmaals.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Bij het toevoegen van de beheerder is een fout opgetreden. Probeer het nogmaals.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Er is een fout opgetreden tijdens het deactiveren van uw team. Probeer het nogmaals.",
"3f686a97a1b9fcdd73a40d2005693105":"Er is een foutmelding opgetreden. Probeer het nogmaals.",
"2f38263789163cf8ee30c8ae88852c82":"Bij het publiceren van uw lijst is een fout opgetreden.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Bij het verwijderen van de beheerder is een fout opgetreden. Probeer het nogmaals.",
"007a647254e1f85b6c1e789004e05df5":"Er is een fout opgetreden bij het opslaan van de instellingen.",
"c3072d080b587d70f85d68ec089f028f":"Er is een fout opgetreden bij het opslaan van de instellingen voor uw team. Probeer het nogmaals.",
"608cd0e109c3e51b0425df8921e4181d":"Er is een fout opgetreden bij het instellen van de providertoewijzing. Probeer het nogmaals.",
"177cefc68dd318ea75673326e77d8206":"Bij het ongedaan maken van de publicatie van uw lijst is een fout opgetreden.",
"255d22dc4f0e338a4cf53d5b7256e695":"Er is een probleem opgetreden bij het ophalen van de naam van de persoon waarnaar u goedkeuringsverzoeken doorstuurt.",
"39ac4ec73fed8a981ad1857d989edfcb":"Bedrag overschrijdt goedkeuringslimiet bedrijf.",
"494ce3f598cbd97afe99774b5975a4bc":"Bedrag overschrijdt goedkeuringslimiet team.",
"f2a42214e7c480c5596439becfd0b7bf":"Dit veld is verplicht.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Deze instelling is alleen van invloed op de nieuwe kaarten",
"881b7596cf985d9c246b05fcab930632":"Deze bronkaart is aan het team toegevoegd.",
"a1b1aa354d06e7815b78c6abb7448e84":"Deze bronkaart is uit het team verwijderd.",
"b348b24fc4839ee15ed0aa424689bae3":"Dit team moet een beheerder of manager hebben voordat u een lid kunt uitnodigen.",
"188b9ed447a43916f9e323623ea3a993":"Om de uitnodiging te accepteren",
"969de95e19a06f5ae7cec6449cbb6a27":"Totaal",
"30286e57da108ee7dbc0f8fbb62d4fff":"Totaal uitgegeven ({ currencySymbol })",
"c1a02bee74ad2ab87b0e0114dfea6905":"Volg uitgaven tussen teams, projecten en abonnementen.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Volg de activiteit van de actieve kaarten van uw team, pauzeer uitgaven en deactiveer kaarten die het team niet meer nodig heeft.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Tradeshift Go-wachtwoord opnieuw instellen",
"5d760188301146cc7255ce18b65cb370":"Probeer het opnieuw",
"80196bc616d4a44a4997a2ea127f7b83":"Probeer het opnieuw",
"f96bbe81618b5f4a1807989eb4f69e24":"Probeer het opnieuw",
"d498d7d261b7ca56e07218d1a0dbdd49":"Oh nee! Er is een fout opgetreden. Probeer het nogmaals.",
"ce2e475291003852c71b01959698c496":"Kan geen berichten op aanvraag {title} ophalen, probeer het nogmaals",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Dearchiveren",
"d2e50be5287fd8fb436d0acbeb811864":"Unieke ID",
"0e2fbce967f4a209215b6f48f8eddac3":"Kaart aanmaken hervatten",
"f2315dec5dd07802b77af3a3a5414a7d":"Tot 4 jaar in de toekomst.",
"d2f30a2f0c955271e9af123dceca501a":"Tot {label} in de toekomst",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Bronkaart bijwerken",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Bronkaart bijwerken",
"c8f6bc61a8982158e408194e48a274d9":"Uploaden mislukt, probeer het opnieuw.",
"8a86a60cb836bdb5225cad20c5151078":"Bon uploaden",
"e448a1ce42de0cf526200331b4ad8b23":"Gebruiker",
"75edcc8fc27f41c99e28b48ff250c7f8":"Gebruikersinformatie",
"d1651c2afdb6c92621adbb707e4753d0":"Gebruiker opgeslagen.",
"5fa55883b7413526b441e999cb8089b3":"Gebruikersnaam",
"7a892b81fe2aae295f1b2d38bb67360f":"Gebruikersnaam",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Gebruikers met actieve kaarten kunnen niet worden verwijderd. Annuleer kaarten om deze gebruiker te verwijderen.",
"d40fa5935f1614925cf46259c886814a":"Kaartactiviteit bekijken",
"625419857efc7e72d162c9f8204109df":"Bekijk kaart in portemonnee",
"85a5e817826415651ed4991a76bb5385":"Bekijk gesprek",
"09fc124dc9b8b14d6227a92bb0a4566f":"Gesprek bekijken",
"d26cfc6d8f420f259f434b79593e9eb4":"Ontvangstbewijs bekijken",
"c7d0394c3206a3b74afbeabc41cc639c":"Ontvangstbewijs bekijken",
"01650aa52c5e5a22d3f38afd2c7f9411":"Ontvangstbewijs bekijken",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Ontvangstbewijs bekijken",
"b2d141897f73fc2a198ee1a7a438f89b":"Aanvraag bekijken",
"02fa2f56ef72845a9dfeabea22948061":"Teaminformatie bekijken",
"2d9c55650b17dd605a1388f46c81ba38":"Virtuele kaart",
"d0ec076e4600e854588f8da265208c0b":"Virtuele-kaartopties",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Portemonnee",
"5279a2220e4cfb0d8b8b357311251cbe":"Portemonnee",
"b1746b5806659e3189f837d2dd54e08c":"Portemonnee",
"ff306d5b34ee993f9618c1f3c0209a39":"We kunnen de aanvraag momenteel niet archiveren. Probeer het later nogmaals.",
"90272a9bb4d2048e81d7962ccfc9a97d":"We kunnen de aanvraag momenteel niet annuleren. Probeer het later nogmaals.",
"a813d44f6bb26c5da09671e532abe022":"We kunnen de aanvraag momenteel niet dearchiveren. Probeer het later nogmaals.",
"d4c7310eafc0d0da89f20596a22a3abe":"We kunnen momenteel geen aanvraag aanmaken. Probeer het nogmaals.",
"dca679c10accb1db481a57a0e1ba08e2":"We kunnen momenteel geen aanvraag aanmaken. Probeer het nogmaals.",
"db5584a2ef29b04959bce7f93db54c56":"We kunnen het team momenteel niet wisselen. Probeer het later nogmaals.",
"f2af2c261fabddb01766f4f974b85a62":"Wekelijks",
"e181ed0723a00ca7558b9d5d5258732b":"Welkom bij Tradeshift Go, {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"We houden contact!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Welke creditcard gebruikt uw bedrijf?",
"968d94729053e166a76ea577ce3022e2":"E-mail (werk):",
"588a075e8694ff5cc86815697e1586bc":"Werk net zoals uw zakelijke teams.",
"182ae1eb5226e0646b4033883cc0900c":"Jaar",
"fe88fe7094c4ced427874b3d22326c8d":"Ja",
"9a2d4e3b22ad0567165eb0f661f952ad":"Ja, kaart annuleren",
"96b88e530375237cbc08138d9fb4109c":"Ja, verwijder deze lijst",
"fd3780f468c531f2bc1b47b8748b5a66":"U kunt boekhoudcodes in bedrijfsinstellingen aanmaken",
"ab3ac6f945d9f756763d49257fd924dc":"U kunt coderingen in bedrijfsinstellingen beheren",
"c919dae93fe780061629c255eb358140":"U kunt niet de enige bronkaart verwijderen die aan dit team is gekoppeld. Voeg nog een kaart toe om deze te verwijderen.",
"11de97e873b66d91a77db7a05da32202":"U kunt een van de onderstaande teameigenaren tot bedrijfsbeheerder promoveren of een nieuwe gebruiker uitnodigen.",
"0ab790f08b2780be001b79d2ccd75c4e":"U hebt voor dit account geen aangemaakte boekhoudcodes.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"U hebt geen aangemaakte coderingslijsten.",
"44e470218124a50c117870467530752e":"U hebt een onjuist e-mailadres of wachtwoord ingevoerd.",
"4b687d0730822fdbeea27c174e47f227":"U heb geen goedgekeurde goedkeuringen.",
"020f03c17b79af66f335b6af868c66c0":"U hebt geen geannuleerde goedkeuringen.",
"def0b76f03e7c622341a7d8e08aaaae7":"U hebt geen geweigerde goedkeuringen.",
"f138fc3cef5a3fc7c6aeabc235e63273":"U heb geen onderhanden goedkeuringen.",
"7c034d6104f80a523b90bc607fc06608":"U hebt geen virtuele kaarten in uw portemonnee. Aanvraag aanmaken",
"0aed9198d61d161468d541dbbc9f477a":"U hebt afwezigheidsmelding naar {oooForwardToFullName} gepland tot {date}",
"4294c2c09a4e02bde71dc187e12a6933":"U hebt een nieuwe virtuele kaart ontvangen:",
"04eeaf814767c30aa41577f3b11f916a":"U hebt onlangs om een wachtwoordreset van uw Go-account gevraagd. Om het proces te voltooien:",
"09ddf249810b9af7d3e1f4e537da500d":"U probeerde toegang te krijgen tot een pagina die niet bestaat. Controleer of u de correcte koppeling hebt.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"U bent uitgenodigd om lid te worden van uw team op",
"02c6f3a7cf31a87f4f39f992509f8c44":"U bent toegevoegd als beheerder voor uw bedrijf",
"cf121a14590f109dafcae138265e353b":"U bent toegevoegd aan een nieuw team!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"U bent aan het team toegevoegd",
"d3cef66a026f776903b342d450e5a832":"Uw Go-kaartaanvraag is geweigerd",
"003f87c21a29220e661fc302b885fd5a":"Uw rekening is uitgeschakeld. Neem contact op met uw bedrijf of teambeheerder.",
"a52b1f0da15dce68007f524e48238143":"Uw bank heeft een fout geretourneerd tijdens het toevoegen van deze kaart. Neem contact op met uw bank om het probleem op te lossen en probeer het opnieuw.",
"4d883b173167297463aeac86957c26fe":"Uw e-mailadres is gebruikt om een account aan te maken op Tradeshift Go. Om uw e-mailadres te verifiëren en de registratie te voltooien",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Uw afwezigheidsinstellingen zijn opgeslagen.",
"542f08554110a12de0c23afd78f38e0e":"Uw wachtwoord is opnieuw ingesteld. Meld u aan.",
"49b2dcbe5d461f35c37986962b5bd07a":"Uw verzoek is geweigerd",
"4e9562a9201f2c46a7a7d631fbf82ac1":"U staat op de lijst!",
"32f87c5ddeaee35b9178785ef4eb9313":"Postcode",
"c6974a3d5311374c697625f2a3234bce":"een datum",
"f86980f5ef00b645a6e4a0b59738a954":"Iemand die we niet hebben opgezocht",
"7066f854917b39bafe0bbecbb8bd6377":"keurt verzoeken goed",
"4dc3c6316e3c80bbe3bfa16fd4c1f676":"voor",
"49b171230ab9bcdd8b631c70cf397efb":"voor <%= aankoopnaam %>",
"4a8231a77c028b619d244b3e04f9916e":"voor uw bedrijf",
"35ad86f0b035d8156390670d60801e7f":"voor {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"voor {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"van",
"c1407aa5424f339dedf67bcac597cf71":"heeft hun goedkeuringsverzoeken ingesteld om naar u door te sturen tijdens",
"67a948b8af3284a401ebefcb1b4723ab":"wacht op uw goedkeuring van",
"9ca64449d64b98fef7859e22bc23c4dc":"doet verzoeken",
"ac850ab84428e9eb6a57e5d813de5e45":"beheert team",
"2168839a1f4c73ef5c89c9e930148e7d":"negeer deze e-mail. Er is geen actie vereist",
"85431c325faab98e02daa60151465144":"heeft u deze uitnodiging gestuurd",
"81c504fd54119e7e580eb05dedef46d0":"heeft u deze uitnodiging gestuurd",
"d74545060b38445ef4cc49fbb8d79876":"heeft u deze uitnodiging gestuurd.",
"2d96c53ee7ee0a54aadad19bfce0697d":"er is een fout opgetreden bij het ophalen van uw kaartbronnen",
"b2fda295788d579fffd3f43b7737fd63":"om uw bedrijfskaart bij Go in te schrijven, om uw werknemers eenvoudige en veilige kaarttoegang te verlenen.",
"d35bd5c5d74af765d45cef3c4e78f52a":"Om zakelijke aankopen te doen met behulp van virtuele Go-kaarten",
"8469a13ddf1cc19a4ebf26356d8ac646":"Om zakelijke aankopen te doen met behulp van virtuele Go-kaarten",
"9546989e681a7e25fbdfe7631e9ebc03":"Om zakelijke aankopen te doen met behulp van virtuele Go-kaarten.",
"6174e0a3d26409dd6e410052e2805660":"om te beginnen met Tradeshift Go-kaarten te gebruiken",
"0bee956ca9aa927e0063c36a300e29e1":"Niet aanwezig uitschakelen",
"fd2c1099fc9f3831d7f24195aaa11269":"uzelf@company.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} is verantwoordelijk voor het goedkeuren van verzoeken van andere teamleden. Wijs om door te kunnen gaan een nieuwe goedkeurder toe aan de getroffen leden.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} is uitgenodigd${this.teamId ? '.' : ' voor het team.'}",
"27abab5a6de300a82727477f44662dcf":"{fname} is verwijderd",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} heeft het verzoek geannuleerd voor",
"9567a2d4cacd58fb4f096132d26c1660":"{username} is verantwoordelijk voor het goedkeuren van verzoeken van andere teamleden. Wijs om door te kunnen gaan een nieuwe goedkeurder toe aan de getroffen leden.",
};
