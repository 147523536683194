const _ = require('lodash');

/* @ngInject */
function UserService($q, CacheFactory, ConfigService, constants, ErrorService, HttpService, RoleService) {
	const service = {};
	const accessCacheId = constants.cache.ids.accessCache;
	const userCacheId = constants.cache.ids.userCache;
	const defaultCacheSettings = constants.cache.defaultSettings;
	const operations = ConfigService.get('ACCESS_IDS').operations;
	const types = ConfigService.get('ACCESS_IDS').types;
	const SUPPORT_USER_ID = constants.roles.support.id;
	let primaryUser;

	function getCache(cacheId) {
		return CacheFactory.get(cacheId) || CacheFactory.createCache(cacheId, defaultCacheSettings);
	}

	function saveAvatar({ avatar, user }) {
		const data = new FormData();
		const headers = { 'Content-Type': undefined };
		data.append('file', avatar);
		return HttpService.tradeshiftGo().path(`/rest/users/${user.userId}/avatar`).config({ headers }).put(data);
	}

	service.clearCache = () => {
		const cache = getCache(userCacheId);
		cache.removeAll();
	};

	service.getCachedUser = () => {
		let cachedUser;
		const userCache = getCache(userCacheId);
		if (userCache) {
			const userJSON = _.attempt(JSON.parse, _.get(userCache.get('/rest/user'), '[1]'));
			cachedUser = !_.isError(userJSON) ? userJSON : null;
		}
		return cachedUser;
	};

	service.getAssistant = () => {
		return {
			avatarUrl: ConfigService.get('GO_ASSISTANT_AVATAR_URL'),
			fullName: 'Go assistant',
		};
	};

	service.getSupportedOperations = () => operations;

	service.getSupportedTypes = () => types;

	service.getUser = () => {
		const cache = getCache(userCacheId);
		return HttpService.tradeshiftGo()
			.path('/rest/user')
			.config({ cache })
			.get()
			.then(user => {
				_.assign(user, {
					roleLabel: RoleService.getRoleLabel(user.roleId),
				});
				ErrorService.setContext(user);
				return user;
			});
	};

	service.getUserByEmail = email => {
		return HttpService.tradeshiftGo().path('/rest/users').params({ email }).get();
	};

	service.getUserById = userId => {
		return HttpService.tradeshiftGo()
			.path(`/rest/users/${userId}`)
			.get()
			.then(user => {
				return _.assign(user, {
					roleLabel: RoleService.getRoleLabel(user.roleId),
				});
			});
	};

	service.getUsers = ({ query, limit, page, teamId } = {}) => {
		const url = teamId ? `/rest/teams/${teamId}/users` : '/rest/users';

		return HttpService.tradeshiftGo()
			.path(url)
			.params({ query, limit, page })
			.get()
			.then(({ numPages, pageId, users, primaryUserId }) => {
				const filteredUsers = users.filter(user => user.roleId !== SUPPORT_USER_ID);
				return {
					numPages,
					pageId,
					primaryUserId,
					itemCount: filteredUsers.length,
					users: filteredUsers,
				};
			});
	};

	service.saveUser = data => {
		const promises = [HttpService.tradeshiftGo().path(`/rest/users/${data.user.userId}`).put(data.user)];
		if (!_.isUndefined(data.avatar)) {
			promises.push(saveAvatar(data));
		}
		return $q.all(promises).then(result => {
			if (data.user.userId === _.get(service.getCachedUser(), 'userId')) {
				CacheFactory.destroy(userCacheId);
			}
			return result;
		});
	};

	service.sendInvitation = user => {
		if (user.teamId) {
			return HttpService.tradeshiftGo().path(`/rest/teams/${user.teamId}/users/invite`).post(user);
		}
		return HttpService.tradeshiftGo().path('/rest/users/invite').post(user);
	};

	service.setUserProperties = function (userId, properties) {
		return HttpService.tradeshiftGo()
			.path(`/rest/users/${userId}/properties`)
			.put(properties)
			.then(result => {
				if (userId === _.get(service.getCachedUser(), 'userId')) {
					CacheFactory.destroy(userCacheId);
				}
				return result;
			});
	};

	service.setUserState = ({ username, state }) => {
		return HttpService.tradeshiftGo().path(`/rest/users/${username}/state`).put({ state });
	};

	service.verifyAccess = (operationId, typeId, groupId) => {
		const cache = getCache(accessCacheId);
		const params = groupId ? { groupId } : {};
		return HttpService.tradeshiftGo()
			.path(`/rest/accesslist/operation/${operationId}/type/${typeId}`)
			.config({ cache, params })
			.get();
	};

	service.isTSAdmin = () => {
		const groupId = '35dea17e-fdd7-46d9-88c8-c5c632ebf92b';
		return service
			.verifyAccess(operations.ADMIN_OPERATION, types.SECURITYGROUP, groupId)
			.then(hasAccess => hasAccess === true)
			.catch(() => false);
	};

	service.isAdmin = () => {
		return service
			.verifyAccess(operations.GROUP_EDIT, types.SECURITYGROUP)
			.then(hasAccess => hasAccess === true)
			.catch(() => false);
	};

	service.isFinance = () => {
		return service
			.verifyAccess(operations.MANAGE_DOCUMENT, types.SECURITYGROUP)
			.then(hasAccess => hasAccess === true)
			.catch(() => false);
	};

	service.getPrimaryUser = () => {
		if (!primaryUser) {
			return HttpService.tradeshiftGo()
				.path('/rest/users')
				.get()
				.then(users => {
					primaryUser = users.primaryUserId;
					return primaryUser;
				});
		}
		return $q.resolve(primaryUser);
	};

	service.isAdminOrFinance = () => {
		return $q.all([service.isAdmin(), service.isFinance()]).then(results => _.includes(results, true));
	};

	service.filterUsersByRole = ({ role, users }) => {
		return users.filter(user => {
			const roleId = RoleService.getRoleV2(role).id;
			const foundRole = _.find(user.roles, { roleId });
			return !_.isNil(foundRole);
		});
	};

	return service;
}

module.exports = UserService;
