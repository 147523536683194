const _ = require('lodash');

const errorCodes = require('../../../common/constants/errorCodes');

/* @ngInject */
const CodingTable = ($state, $timeout, CodingHelper, CodingService, ConfigService, LexicoService) => {
	const factory = ({
		codingListAside,
		confirmDeleteListAside,
		lists: _lists,
		providerFields: _providerFields,
		selectAside,
		showProviderFields,
	}) => {
		const lexico = LexicoService.getLexico();
		const ALL_CODING_LISTS = ConfigService.get('CODING_LISTS');
		let lists = _.cloneDeep(_lists);
		let providerFields = _.cloneDeep(_providerFields);
		const columns = [
			{ label: 'List name' },
			{ label: 'Number of codes', width: ts.ui.UNIT * 6, editable: false },
			{ label: 'Activate / edit / delete', width: ts.ui.UNIT * 4.5, editable: false },
			{ label: '', width: ts.ui.UNIT * 2, editable: false },
			{ label: '', width: ts.ui.UNIT * 2, editable: false },
		];
		if (showProviderFields) {
			columns.splice(
				2,
				0,
				{ label: 'Provider field', width: ts.ui.UNIT * 8, editable: false },
				{ label: '', width: ts.ui.UNIT * 2, editable: false },
			);
		}
		const table = {
			columns,
			onCellClick: function ({ columnIndex, rowIndex }) {
				const { listId, name } = lists[rowIndex];
				switch (columnIndex) {
					case showProviderFields ? 2 : -1:
					case showProviderFields ? 3 : -1:
						selectAside.listId = listId;
						const fieldId = _.findKey(providerFields, value => value === listId);
						selectAside.model = _.find(selectAside.options, ['id', fieldId || 'not-mapped']);
						selectAside.isOpen = true;
						break;
					case showProviderFields ? 5 : 3:
						$state.go('main.manage.codingList', { codingListId: listId });
						break;
					case showProviderFields ? 6 : 4:
						if (Object.keys(ALL_CODING_LISTS).includes(listId)) {
							return;
						}
						confirmDeleteListAside.listId = listId;
						confirmDeleteListAside.message =
							`The coding list "${name}" will be deleted. ` + 'Would you like to proceed?';
						confirmDeleteListAside.isOpen = true;
						break;
					default:
					// don't process click
				}
			},
			onCodingListSaved: ({ codingList }) => {
				const normalList = CodingHelper.normalizeCodingList(codingList);
				if (codingListAside.renameListId) {
					lists.forEach(list => {
						if (normalList.listId === list.listId) {
							list.name = normalList.name;
							list.description = normalList.description;
						}
					});
				} else {
					normalList.enabled = true;
					lists.push(normalList);
				}
				initRows();
				codingListAside.isOpen = false;
				codingListAside.lists = lists;
			},
			onConfirmDeleteList: () => {
				confirmDeleteListAside.isOpen = false;
				CodingService.deleteList({ listId: confirmDeleteListAside.listId })
					.then(() => {
						lists = lists.filter(({ listId }) => listId !== confirmDeleteListAside.listId);
						codingListAside.lists = lists;
						providerFields = CodingHelper.filterObjectByValue(providerFields, confirmDeleteListAside.listId);
						initRows();
					})
					.catch(() => {
						ts.ui.Notification.warning('There was a problem deleting the list. Please try again.');
					});
			},
			onLink: ({ value }) => {
				codingListAside.renameListId = value;
				codingListAside.isOpen = true;
			},
			onSelectProviderField: ({ option }) => {
				selectAside.model = option;
				$timeout(() => {
					selectAside.isOpen = false;
				}, 250);
				providerFields = CodingHelper.filterObjectByValue(providerFields, selectAside.listId);
				if (option.id !== 'not-mapped') {
					providerFields[option.id] = selectAside.listId;
				}
				CodingHelper.saveProviderFields(providerFields);
				initRows();
			},
			onSwitch: function ({ checked: enabled, rowIndex: listIndex }) {
				if (lists[listIndex].entries && lists[listIndex].entries.length > 0) {
					CodingHelper.toggleListEnabled({ enabled, listIndex, lists }).catch(error => {
						let warningMessage;
						if (error.code === errorCodes.ERR_PROVIDER_MAPPING) {
							warningMessage = lexico.trc(
								'Warning message',
								'The coding list you are trying ' +
									'to unpublish is linked to an integration. You need to first remove the ' +
									'mapping before unpublishing this coding list.',
							);
						} else {
							warningMessage = `${lexico.tr('There was a problem')} ${
								enabled ? lexico.tr('enabling') : lexico.tr('disabling')
							} ${lexico.tr('the list. Please try again.')}`;
						}
						ts.ui.Notification.warning(warningMessage);
						initRows();
					});
				}
			},
			showProviderFields,
		};

		const initRows = () => {
			lists = _.sortBy(lists, 'name');
			table.rows = CodingHelper.getCodingTableRows({ lists, providerFields, showProviderFields });
		};

		initRows();
		return table;
	};

	return factory;
};

module.exports = CodingTable;
