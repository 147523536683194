const TSSearchComponent = {
	bindings: {
		onSearch: '&',
		placeholder: '@',
	},
	template: '<div data-ts="Search" id="js-search" class="ts-inset"></div>',
	controller: class TSSearchController {
		/* @ngInject */
		constructor($timeout, EventEmitter) {
			this.EventEmitter = EventEmitter;
			this.$timeout = $timeout;
		}

		$onInit() {
			ts.ui.get('#js-search', element => {
				this.search = element;

				this.search.info(this.placeholder);
				this.search.onidle = this.search.onsearch = query => {
					// $timeout forces digest cycle so changes in parent method are propagated to children
					this.$timeout(() => this.onSearch(this.EventEmitter({ query })));
				};
			});
		}
	},
};

export default TSSearchComponent;
