"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetState = exports.setEnableCheckUser = exports.setTeamApprovers = exports.setCurrentApprover = exports.setIsSelfAssigned = exports.setRoleId = exports.setHasManualEdit = exports.setLastName = exports.setFirstName = exports.setPhoneNumber = exports.setIsEmailValid = exports.setEmail = exports.reducer = exports.initialState = exports.defaultApprover = void 0;
const strings_1 = require("../../../../../constants/strings");
exports.defaultApprover = { id: 'self-approval', label: strings_1.invitePeopleStrings.SELF_APPROVER_LABEL };
exports.initialState = {
    email: '',
    phoneNumber: '',
    phoneCountryCode: '',
    phoneNumberValue: '',
    isEmailValid: true,
    firstName: '',
    lastName: '',
    roleId: '',
    isSelfAssigned: false,
    currentApprover: { id: '', label: '' },
    teamApprovers: [],
    isFormLoading: false,
    enableCheckUser: false,
    hasManualEdit: {
        firstName: false,
        lastName: false,
        phoneNumberValue: false,
    },
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_EMAIL': {
            return { ...state, email: action.payload };
        }
        case 'SET_PHONE_NUMBER': {
            return {
                ...state,
                phoneNumber: action.payload.phoneNumber,
                phoneCountryCode: action.payload.phoneCountryCode,
                phoneNumberValue: action.payload.phoneNumber,
            };
        }
        case 'SET_IS_EMAIL_VALID': {
            return { ...state, isEmailValid: action.payload };
        }
        case 'SET_FIRST_NAME': {
            return { ...state, firstName: action.payload };
        }
        case 'SET_LAST_NAME': {
            return { ...state, lastName: action.payload };
        }
        case 'SET_HAS_MANUAL_EDIT': {
            return { ...state, hasManualEdit: action.payload };
        }
        case 'SET_ROLE_ID': {
            return { ...state, roleId: action.payload };
        }
        case 'SET_IS_SELF_ASSIGN': {
            return { ...state, isSelfAssigned: action.payload };
        }
        case 'SET_CURRENT_APPROVER': {
            return { ...state, currentApprover: action.payload };
        }
        case 'SET_TEAM_APPROVERS': {
            return { ...state, teamApprovers: action.payload };
        }
        case 'SET_ENABLE_CHECK_USER': {
            return { ...state, enableCheckUser: action.payload };
        }
        case 'RESET': {
            return exports.initialState;
        }
        default: {
            return state;
        }
    }
};
exports.reducer = reducer;
const setEmail = (email) => ({
    type: 'SET_EMAIL',
    payload: email,
});
exports.setEmail = setEmail;
const setIsEmailValid = (email) => ({
    type: 'SET_IS_EMAIL_VALID',
    payload: email,
});
exports.setIsEmailValid = setIsEmailValid;
const setPhoneNumber = ({ phoneNumber, phoneCountryCode, phoneNumberValue, }) => ({
    type: 'SET_PHONE_NUMBER',
    payload: { phoneNumber, phoneCountryCode, phoneNumberValue },
});
exports.setPhoneNumber = setPhoneNumber;
const setFirstName = (firstName) => ({
    type: 'SET_FIRST_NAME',
    payload: firstName,
});
exports.setFirstName = setFirstName;
const setLastName = (lastName) => ({
    type: 'SET_LAST_NAME',
    payload: lastName,
});
exports.setLastName = setLastName;
const setHasManualEdit = (hasManualEdit) => ({
    type: 'SET_HAS_MANUAL_EDIT',
    payload: hasManualEdit,
});
exports.setHasManualEdit = setHasManualEdit;
const setRoleId = (roleId) => ({
    type: 'SET_ROLE_ID',
    payload: roleId,
});
exports.setRoleId = setRoleId;
const setIsSelfAssigned = (isSelfAssigned) => ({
    type: 'SET_IS_SELF_ASSIGN',
    payload: isSelfAssigned,
});
exports.setIsSelfAssigned = setIsSelfAssigned;
const setCurrentApprover = (currentApprover) => ({
    type: 'SET_CURRENT_APPROVER',
    payload: currentApprover,
});
exports.setCurrentApprover = setCurrentApprover;
const setTeamApprovers = (teamApprovers) => ({
    type: 'SET_TEAM_APPROVERS',
    payload: teamApprovers,
});
exports.setTeamApprovers = setTeamApprovers;
const setEnableCheckUser = (enableCheckUser) => ({
    type: 'SET_ENABLE_CHECK_USER',
    payload: enableCheckUser,
});
exports.setEnableCheckUser = setEnableCheckUser;
const resetState = () => ({
    type: 'RESET',
});
exports.resetState = resetState;
