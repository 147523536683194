"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiButtonOverrides = void 0;
const material_1 = require("@mui/material");
const uiConstants_1 = require("../../constants/uiConstants");
const tsGoThemeVars_1 = require("../tsGoThemeVars");
function getButtonContainedStyle(ownerState, theme) {
    const defaultStyles = {
        borderRadius: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 0 : tsGoThemeVars_1.tsGoUnit.tsUnit,
        textDecoration: 'none !important',
        boxShadow: 'none !important',
        padding: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? '10px 16px' : '6px 16px',
        textTransform: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'none' : 'uppercase',
        fontWeight: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 400 : 600,
        fontSize: window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.typography.pxToRem(theme.typography.tsFontSize.mini)
            : theme.typography.pxToRem(theme.typography.tsFontSize.default),
    };
    if (ownerState.color === uiConstants_1.UI_BUTTON_SECONDARY) {
        return {
            ...defaultStyles,
            backgroundColor: window.cardProvider.IS_HSBC_PROVIDER_SETUP
                ? tsGoThemeVars_1.goPalette.hsbcColor.secondary
                : tsGoThemeVars_1.goPalette.tsGoColor.white,
            border: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'none' : `1px solid ${tsGoThemeVars_1.goPalette.tsGoColor.grayLight()}`,
            textTransform: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'none' : 'uppercase',
            ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
                ? {
                    color: 'black',
                    fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.mini)} !important`,
                    padding: '10px 28px',
                }
                : {}),
            '&:hover': {
                backgroundColor: window.cardProvider.IS_HSBC_PROVIDER_SETUP
                    ? tsGoThemeVars_1.goPalette.hsbcColor.secondary_hover
                    : tsGoThemeVars_1.goPalette.tsGoColor.grayLight(0.5),
            },
        };
    }
    // default primary style
    return {
        ...defaultStyles,
        backgroundColor: window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? tsGoThemeVars_1.goPalette.hsbcColor.primary
            : tsGoThemeVars_1.goPalette.tsGoColor.pink,
        color: 'white',
        '&:hover': {
            backgroundColor: window.cardProvider.IS_HSBC_PROVIDER_SETUP
                ? tsGoThemeVars_1.goPalette.hsbcColor.primary_hover
                : tsGoThemeVars_1.goPalette.tsGoColor.pinkDarker,
        },
    };
}
function getButtonOutlinedStyle(type, theme) {
    switch (type) {
        case 'warning': {
            return {
                backgroundColor: 'transparent',
                border: '1px solid hsl(0, 100%, 37%)',
                borderRadius: '20px',
                color: 'hsl(0, 100%, 37%)',
                textDecoration: 'none !important',
                width: '100%',
                textTransform: 'uppercase',
                justifyContent: 'center',
                '&:hover': {
                    border: '1px solid hsl(0, 100%, 37%)',
                    color: 'hsl(0, 100%, 37%)',
                },
            };
        }
        default: {
            return {
                border: `1px solid ${tsGoThemeVars_1.goPalette.tsGoColor.gray} !important`,
                color: tsGoThemeVars_1.goPalette.tsGoColor.black,
                textDecoration: 'none !important',
                backgroundColor: tsGoThemeVars_1.goPalette.tsGoColor.white,
                width: '100%',
                justifyContent: 'space-between',
                fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
                textTransform: 'none',
                whiteSpace: 'nowrap',
            };
        }
    }
}
function getActionButtonStyle(theme) {
    // this is the 3-dots button which usually opens an aside
    return {
        backgroundColor: 'transparent',
        color: theme.palette.tsGoColor.slateLighter,
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'none !important',
        },
    };
}
function getPagerButtonStyle(theme) {
    // this renders the pager button between the gotToFirst goToPrev
    // and goToNext and goToLast pages
    // << < [1 2 3] > >>
    return {
        backgroundColor: 'transparent',
        color: theme.palette.tsGoColor.blue,
        minWidth: 'auto',
        fontWeight: theme.typography.tsFontWeight.semiBold,
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'none !important',
            color: theme.palette.tsGoColor.blueDark,
        },
        '&:disabled': {
            color: theme.palette.tsGoColor.black,
        },
    };
}
function getTsIconButtonStyle(theme) {
    // style for default ts-icon buttons
    return {
        color: theme.palette.tsGoColor.slateLighter,
        display: 'inline-block',
        height: '30px',
        padding: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        '&:hover': {
            color: theme.palette.tsGoColor.black,
            textDecoration: 'none !important',
            backgroundColor: 'transparent',
        },
        [`.${material_1.buttonClasses.startIcon}`]: {
            fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.icon),
            marginRight: 0,
            marginLeft: 0,
            display: 'flex',
        },
    };
}
function getTsBtnAddStyle(theme) {
    return {
        color: theme.palette.tsGoColor.blue,
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
        fontWeight: theme.typography.tsFontWeight.semiBold,
        textTransform: 'uppercase',
        display: 'inline-block',
        width: '100%',
        '&:hover': {
            color: theme.palette.tsGoColor.blueDark,
            textDecoration: 'none !important',
            backgroundColor: 'transparent',
        },
    };
}
function getHamburgerStyles(theme) {
    return {
        border: '0',
        height: theme.tsGoUnit.tsUnitDouble,
        width: theme.tsGoUnit.tsUnitDouble,
        padding: `${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnitHalf} 0`,
        color: theme.palette.tsGoColor.black,
        cursor: 'pointer',
        transition: 'background-color 0.2s ease-in-out',
        minWidth: 'auto',
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    };
}
exports.muiButtonOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            ...(ownerState['ts-variant'] === 'hamburger' && getHamburgerStyles(theme)),
            ...(ownerState.variant === 'contained' && getButtonContainedStyle(ownerState, theme)),
            ...(ownerState.variant === 'outlined' && getButtonOutlinedStyle(ownerState.color, theme)),
            ...(ownerState.variant === 'text' && ownerState['ts-variant'] === 'actionButton' && getActionButtonStyle(theme)),
            ...(ownerState.variant === 'text' && ownerState['ts-variant'] === 'pagerButton' && getPagerButtonStyle(theme)),
            ...(ownerState.variant === 'text' && ownerState['ts-variant'] === 'tsIcon' && getTsIconButtonStyle(theme)),
            ...(ownerState.variant === 'text' && ownerState['ts-variant'] === 'tsBtnAdd' && getTsBtnAddStyle(theme)), // add-entry button in account code list table
        }),
    },
};
