"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiAvatarOverrides = void 0;
function getAvatarStyle(theme) {
    return {
        left: '50%',
        position: 'relative',
        transform: 'translateX(-50%)',
        backgroundColor: 'hsl(200, 21%, 92%)',
        borderRadius: '100%',
        height: '140px',
        width: '140px',
        fontSize: '56px',
        color: theme.palette.tsGoColor.black,
    };
}
function getMiniAvatarStyle() {
    return {
        left: '20px',
        position: 'absolute',
        transform: 'translateX(-50%)',
        borderRadius: '100%',
        height: '40px',
        width: '40px',
    };
}
function getSidebarAvatarStyle() {
    return {
        left: '20px',
        position: 'relative',
        transform: 'translateX(-50%)',
        borderRadius: '100%',
        height: '40px',
        width: '40px',
    };
}
exports.muiAvatarOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => {
            return {
                ...(ownerState['ts-variant'] === 'profile' && getAvatarStyle(theme)),
                ...(ownerState['ts-variant'] === 'minicircular' && getMiniAvatarStyle()),
                ...(ownerState['ts-variant'] === 'circularsidebar' && getSidebarAvatarStyle()),
            };
        },
    },
};
