const _ = require('lodash');

const MONTHS = require('../../config/Months');
const STATES = require('../../config/States');
const YEAR_RANGE = 9;

const errorUtil = require('../../../common/lib/errorUtil');

/* @ngInject */
function AddCardSourceController($scope, AsideService, constants, PaymentsService, UserService) {
	const that = this;
	$scope.cardNumberPattern = constants.regexPatterns.cardNumber;
	$scope.zipCodePattern = constants.regexPatterns.zipCodeNumber;

	function buildPaymentInformation(options) {
		return {
			address: {
				address: options.address,
				city: options.city,
				countryCode: options.countryCode,
				regionCode: options.regionCode,
				zipCode: _.toString(options.zipCode),
			},
			cardNumber: _.toString(options.cardNumber),
			email: options.email,
			expiryDate: formatExpiryDate(options.expirationMonth.value, options.expirationYear),
			firstName: options.firstName,
			lastName: options.lastName,
			name: options.name,
			orgName: options.orgName,
		};
	}

	function formatExpiryDate(month, year) {
		return _.toString(year) + _.padStart(_.toString(month), 2, '0');
	}

	function init() {
		that.onSuccess = $scope.aside.onSuccess;

		const currentYear = new Date().getFullYear();
		that.options = {
			months: MONTHS,
			states: STATES,
			years: _.range(currentYear, currentYear + YEAR_RANGE),
		};

		UserService.getUser().then(function (user) {
			that.cardSource = {
				address: '',
				cardNumber: '',
				city: '',
				countryCode: 'US',
				email: user.email,
				expirationMonth: '',
				expirationYear: '',
				firstName: user.firstName,
				lastName: user.lastName,
				name: '',
				orgName: user.companyName,
				regionCode: '',
				userId: user.userId,
				zipCode: '',
			};
			AsideService.busy('addCardSource', false);
			that.isLoading.aside = false;
		});

		that.isLoading = {
			aside: true,
		};
	}

	that.submit = function (data) {
		return PaymentsService.getEnrollmentToken()
			.then(function (result) {
				const enrollment = {
					enrollmentToken: result.token,
					paymentInformation: buildPaymentInformation(data),
				};
				return PaymentsService.enrollWithAmex(enrollment);
			})
			.then(function (cardSource) {
				ts.ui.Notification.success('Credit card added.');
				that.onSuccess(cardSource);
				AsideService.closeAll();
			})
			.catch(function (error) {
				if (errorUtil.isCardsProviderError(error)) {
					ts.ui.Notification.warning(
						'Your bank returned an error while adding this card. ' +
							'Please contact your bank to resolve and try again.',
					);
				} else {
					ts.ui.Notification.warning('There was a problem adding the credit card. Please try again.');
				}
			});
	};

	that.cancel = function () {
		AsideService.closeAll();
	};

	$scope.$watch('aside.show', function (show) {
		if (show) {
			init();
		}
	});
}

module.exports = AddCardSourceController;
