import { cloneDeep } from 'lodash';

const EditUserPanelComponent = {
	bindings: {
		user: '<',
		features: '<',
		isNameEditable: '<',
		isRoleSelectEnabled: '<',
		isSubmitting: '<',
		onCancel: '&',
		onSubmit: '&',
		onRemoveUser: '&',
		optionsModel: '<',
		formOptions: '<',
	},
	template: `
		<ts-user-image
				user="$ctrl.user"
				size="145"></ts-user-image>
		<ts-form
				is-submitting="$ctrl.isSubmitting"
				on-cancel="$ctrl.onCancel()"
				on-submit="$ctrl.onFormSubmit()"
				submit-label="{{$ctrl.saveButtonText}}">
			<ts-form-fields>
				<fieldset>
					<label>
						<span ng-bind="$ctrl.firstNameFormText"></span>
						<input ng-model="$ctrl.user.firstName" ng-readonly="!$ctrl.isNameEditable" type="text" />
					</label>
					<label>
						<span ng-bind="$ctrl.lastNameFormText"></span>
						<input ng-model="$ctrl.user.lastName" ng-readonly="!$ctrl.isNameEditable" type="text" />
					</label>
				</fieldset>
				<ts-input
						label="{{$ctrl.emailInputFieldText}}"
						ng-model="$ctrl.user.email"
						is-readonly="true"
						type="email"></ts-input>
				<ts-select
						label="{{$ctrl.roleInputFieldText}}"
						ng-model="$ctrl.optionsModel.role"
						is-disabled="{{ !$ctrl.isRoleSelectEnabled }}"
						options="$ctrl.formOptions.roles"></ts-select>
				<ts-input
						label="{{$ctrl.approvalLimitFormText}}"
						ng-if="$ctrl.features.ENABLE_USER_APPROVAL_LIMITS"
						ng-model="$ctrl.user.properties['go-virtual-card-approval-limit']"
						pattern="{{ $ctrl.currencyZeroValid }}"
						placeholder="{{$ctrl.noLimitPlaceholderText}}"
						is-required="false"></ts-input>
				<ts-select
						label="{{$ctrl.costCenterFormText}}"
						ng-model="$ctrl.optionsModel.costCenter"
						ng-if="$ctrl.formOptions.costCenters.length"
						is-required="false"
						options="$ctrl.formOptions.costCenters"></ts-select>
				<ts-select
						label="{{$ctrl.managerFormText}}"
						ng-model="$ctrl.optionsModel.manager"
						is-required="false"
						options="$ctrl.formOptions.managers"></ts-select>
			</ts-form-fields>
		</ts-form>
	`,
	controller: class EditUserPanelController {
		/* @ngInject */
		constructor(EventEmitter, LexicoService, constants) {
			this.EventEmitter = EventEmitter;
			this.constants = constants;
			this.currencyZeroValid = constants.regexPatterns.currencyZeroValid;
			this.lexico = LexicoService.getLexico();
			this.saveButtonText = this.lexico.trc('Button', 'Save');
			this.firstNameFormText = this.lexico.trc('Form input label', 'First name');
			this.lastNameFormText = this.lexico.trc('Form input label', 'Last name');
			this.emailInputFieldText = this.lexico.trc('Form input label', 'Email');
			this.roleInputFieldText = this.lexico.trc('Form input label', 'Role');
			this.approvalLimitFormText = this.lexico.trc('Form input label', 'Approval limit ($)');
			this.noLimitPlaceholderText = this.lexico.trc('Form input placeholder', 'No limit');
			this.costCenterFormText = this.lexico.trc('Form input label', 'Cost center');
			this.managerFormText = this.lexico.trc('Form input label', 'Manager');
		}

		onFormSubmit() {
			this.onSubmit(this.EventEmitter({ user: this.user, optionsModel: this.optionsModel }));
		}

		$onChanges(changes) {
			if (changes.user && changes.user.currentValue) {
				this.user = cloneDeep(changes.user.currentValue);
			}
			if (changes.formOptions && changes.formOptions.currentValue) {
				this.formOptions = cloneDeep(changes.formOptions.currentValue);
			}
			if (changes.optionsModel && changes.optionsModel.currentValue) {
				this.optionsModel = cloneDeep(changes.optionsModel.currentValue);
			}
		}
	},
};

export default EditUserPanelComponent;
