import { cloneDeep } from 'lodash';

const ForwardRequestPanelComponent = {
	bindings: {
		isSubmitting: '<',
		onCancel: '&',
		onSubmit: '&',
		formOptions: '<',
	},
	template: `
		<ts-form
				is-submitting="$ctrl.isSubmitting"
				on-cancel="$ctrl.onCancel()"
				on-submit="$ctrl.onFormSubmit()"
				submit-label="{{$ctrl.forwardButtonText}}">
			<ts-form-fields>
				<ts-select
						label="{{$ctrl.selectApproverText}}"
						ng-model="$ctrl.assignee"
						options="$ctrl.formOptions.approvers"
						placeholder="{{$ctrl.formInputPlaceholderText}}"></ts-select>
				<ts-text-area
						label="{{$ctrl.reasonFormText}}"
						ng-model="$ctrl.reason"
						is-required="false"
						placeholder="{{$ctrl.reasonFormInfoPlaceholderText }}"></ts-text-area>
			</ts-form-fields>
		</ts-form>
	`,
	controller: class ForwardRequestPanelController {
		/* @ngInject */
		constructor(EventEmitter, LexicoService) {
			this.EventEmitter = EventEmitter;
			this.lexico = LexicoService.getLexico();
			this.forwardButtonText = this.lexico.trc('Button', 'Forward');
			this.selectApproverText = this.lexico.trc('Form input label', 'Select approver');
			this.formInputPlaceholderText = this.lexico.trc(
				'Form input placeholder',
				'Select a user to forward the request to',
			);
			this.reasonFormText = this.lexico.trc('Form input label', 'Reason');
			this.reasonFormInfoPlaceholderText = this.lexico.trc(
				'Form input placeholder',
				'The reason will be visible to the approver and the requester',
			);
		}

		onFormSubmit() {
			this.onSubmit(
				this.EventEmitter({
					assignee: this.assignee,
					reason: this.reason,
				}),
			);
		}

		$onChanges(changes) {
			if (changes.formOptions && changes.formOptions.currentValue) {
				this.formOptions = cloneDeep(changes.formOptions.currentValue);
			}
		}
	},
};

export default ForwardRequestPanelComponent;
