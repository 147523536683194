const _ = require('lodash');

/* @ngInject */
function RequestRegistryFactory(constants, Request) {
	const requestRegistry = {
		_requests: {},
	};

	requestRegistry.hasRequest = function (requestId) {
		return !_.isEmpty(requestRegistry.getRequest(requestId));
	};
	requestRegistry.getRequest = function (requestId) {
		return requestRegistry._requests[requestId] || {};
	};
	requestRegistry.getCreatedRequest = function (teamId) {
		return _.find(requestRegistry._requests, function (request) {
			if (teamId) {
				return request.getState() === constants.request.states.created && request.getTeamId() === teamId;
			}
			return request.getState() === constants.request.states.created;
		});
	};
	requestRegistry.hasRequests = function () {
		return !_.isEmpty(requestRegistry._requests);
	};
	requestRegistry.removeRequest = function (requestId) {
		delete requestRegistry._requests[requestId];
	};
	requestRegistry.storeRequest = function (request) {
		const requestId = request.requestId;
		if (!requestId) {
			throw new Error('Request is missing a requestId');
		}
		if (!_.has(requestRegistry._requests, requestId)) {
			requestRegistry._requests[requestId] = new Request(request);
		}
		return requestRegistry._requests[requestId];
	};

	return requestRegistry;
}

module.exports = RequestRegistryFactory;
