const zustandStore = require('../zustandStore');

/* @ngInject */
function ConversationService($q, $rootScope, ConfigService, HttpService) {
	$rootScope.openConnections = [];

	function _buildUrl(conversationId) {
		return ['/rest', 'conversations', conversationId, 'events'].join('/');
	}

	return {
		addMessage: function (options) {
			return HttpService.tradeshiftGo().path(_buildUrl(options.conversationId)).put(options);
		},
		generateTypingEvent: function () {
			return {
				author: {
					avatarUrl: ConfigService.get('GO_ASSISTANT_AVATAR_URL'),
					fullName: 'Go Assistant',
					isGo: true,
				},
				sequenceNumber: 9999999,
				timestamp: Date.now(),
				type: 'goTyping',
			};
		},
		getEvents: function (options) {
			const config = {
				params: {
					after: options.after,
					polling: options.polling,
				},
			};
			return HttpService.tradeshiftGo().path(_buildUrl(options.conversationId), config).get();
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		getStream: function (options) {
			const deferredConnection = $q.defer();
			$rootScope.openConnections.push(deferredConnection);
			// const config = {
			// 	params: {
			// 		after: options.after,
			// 	},
			// 	timeout: deferredConnection.promise,
			// };
			// return HttpService.tradeshiftGo().path('/rest/conversations/stream').config(config).get();
			const currentStreamData = zustandStore.useZustand.getState().stream.currentStreamData;
			return Promise.resolve(structuredClone(currentStreamData));
		},
		abortAll: function () {
			$rootScope.openConnections.forEach(function (deferredConnection) {
				deferredConnection.resolve();
			});
			$rootScope.openConnections = [];
		},
	};
}

module.exports = ConversationService;
