const InviteUserToTeamAside = {
	bindings: {
		isOpen: '<',
		onClose: '&',
		role: '<?',
		onSubmit: '&',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.inviteToTeamTitleText}}">
			<ts-form
					on-cancel="$ctrl.isOpen = false"
					on-submit="$ctrl.onFormSubmit()"
					submit-label="{{$ctrl.sendInvitationButtonText}}">
				<ts-form-fields>
					<ts-input
							label="{{$ctrl.emailFormText}}"
							ng-model="$ctrl.username"></ts-input>
					<ts-input
							label="{{$ctrl.firstNameFormText}}"
							ng-model="$ctrl.firstName"></ts-input>
					<ts-input
							label="{{$ctrl.lastNameFormText}}"
							ng-model="$ctrl.lastName"></ts-input>
					<ts-select
							label="{{$ctrl.roleFormText}}"
							ng-model="$ctrl.role"
							options="$ctrl.roles"></ts-select>
				</ts-form-fields>
			</ts-form>
		</ts-aside>
	`,
	controller: class InviteUserAsideController {
		/* @ngInject */
		constructor(EventEmitter, LexicoService) {
			this.EventEmitter = EventEmitter;
			this.lexico = LexicoService.getLexico();
			this.inviteToTeamTitleText = this.lexico.trc('Component title', 'Invite To Team');
			this.sendInvitationButtonText = this.lexico.trc('Button', 'Send invitation');
			this.emailFormText = this.lexico.trc('Form input label', 'Email');
			this.firstNameFormText = this.lexico.trc('Form input label', 'First name');
			this.lastNameFormText = this.lexico.trc('Form input label', 'Last name');
			this.roleFormText = this.lexico.trc('Form input label', 'Role');
			this.roles = [
				{
					description: this.lexico.trc('Input option description', 'manages team'),
					id: 'admin',
					label: this.lexico.trc('Input option', 'Team owner'),
				},
				{
					description: this.lexico.trc('Input option description', 'approves requests'),
					id: 'manager',
					label: this.lexico.trc('Input option', 'Manager'),
				},
				{
					description: this.lexico.trc('Input option description', 'makes requests'),
					id: 'member',
					label: this.lexico.trc('Input option', 'Member'),
				},
			];
		}

		async onFormSubmit() {
			this.onSubmit(
				this.EventEmitter({
					username: this.username,
					fullName: `${this.firstName} ${this.lastName}`,
					firstName: this.firstName,
					lastName: this.lastName,
					role: this.role,
				}),
			);

			this.username = '';
			this.firstName = '';
			this.lastName = '';
			this.role = '';
		}
	},
};

export default InviteUserToTeamAside;
