/* @ngInject */
const StringService = LexicoService => {
	const lexico = LexicoService.getLexico();
	return {
		features: {
			ENABLE_DOMAIN_WHITELISTING: lexico.trc('Feature Label', 'Enable email domain whitelisting for invited users'),
			ENABLE_FILTERED_BY_COSTCENTER: lexico.trc('Feature Label', 'Enable filtering by costcenter'),
			ENABLE_GLOBALIZATION: lexico.trc('Feature Label', 'Enable globalization option in user aside'),
			ENABLE_MULTIUSE: lexico.trc('Feature Label', 'Enable multi use virtual cards'),
			ENABLE_OUT_OF_OFFICE_FORWARDING: lexico.trc('Feature Label', 'Enable automatic forwarding of approval requests'),
			ENABLE_SETTLEMENT_RECEIPT_UPLOAD: lexico.trc('Feature Label', 'Enable receipt upload in settlements table'),
			ENABLE_SINGLEUSE: lexico.trc('Feature Label', 'Enable single use virtual cards'),
			ENABLE_SUBSCRIPTIONS: lexico.trc('Feature Label', 'Enable subscription virtual cards'),
			ENABLE_TRANSACTION_RECEIPT_UPLOAD: lexico.trc('Feature Label', 'Enable receipt upload for transactions'),
			ENABLE_USER_APPROVAL_LIMITS: lexico.trc('Feature Label', 'Approvers have a maximum approval limit'),
			ENABLE_VIRTUAL_CARD_EXP_RANGE: lexico.trc('Feature Label', 'Enable maximum expiration date for virtual card'),
			PAUSE_VIRTUAL_CARD_CREATION: lexico.trc('Feature Label', 'Pause new virtual cards from being issued'),
			ENABLE_ALTERNATE_GET_USER_FUNCTIONS: lexico.trc('Feature Label', 'Enable alternate get user functions'),
			ENABLE_COMPANY_PEOPLE_VIEW: lexico.trc('Feature Label', 'Enable people view for company admin users'),
		},
		fieldDefinitions: {
			InvoiceNumber: lexico.trc('Field definition', 'InvoiceNumber'),
			InvoiceAmount: lexico.trc('Field definitions', 'Invoice amount'),
			InvoiceDate: lexico.trc('Field definition', 'Invoice date'),
			TaxAmount: lexico.trc('Field definition', 'Tax amount'),
			FreightAmount: lexico.trc('Field definition', 'Freight amount'),
			description: lexico.trc('Field definition', 'Purpose'),
			amount: lexico.trc('Field definition', 'Amount'),
			comments: lexico.trc('Field definition', 'Comments'),
			frequency: lexico.trc('Field definition', 'Subscription Frequency'),
			ends: lexico.trc('Field definition', 'Subscription Ends'),
			numberOfPayments: lexico.trc('Field definition', 'Number of payments'),
			endDate: lexico.trc('Field definition', 'Specific End Date'),
		},
	};
};

module.exports = StringService;
