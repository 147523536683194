const { cloneDeep, each, filter, get } = require('lodash');

/* @ngInject */
function FilterCodingListsService() {
	const filterCodingLists = {};

	filterCodingLists.filterList = (codingLists, selectedEntry) => {
		const listId = selectedEntry.listId;
		const codingListClone = cloneDeep(codingLists);
		each(codingListClone, list => {
			// choosing a new list entry in a given
			if (list.listId === listId) {
				return;
			}
			let hasFilteredBy = false;
			// this is where the actual filtering for each list occurs
			const filteredEntries = filter(list.entries, entry => {
				let hasFilter = false;
				each(entry.filteredBy, filterEntry => {
					// if list entry has a filter that matches the selected entry
					// then we return true in order to maintain this option in
					// the current list.
					if (selectedEntry.entryId === filterEntry.entryID && selectedEntry.listId === filterEntry.listID) {
						hasFilter = true;
					}
					hasFilteredBy = true;
				});
				return hasFilter;
			});
			if (hasFilteredBy) {
				list.entries = filteredEntries;
			}
		});
		return codingListClone;
	};

	filterCodingLists.isCodingListVisible = (features, glcodeList, listID, request) => {
		if (!features.ENABLE_FILTERED_BY_COSTCENTER || listID !== 'glcode') {
			return true;
		}
		if (get(request.coding, 'costcentercode') && get(glcodeList, 'entries').length) {
			return true;
		} else {
			return false;
		}
	};

	return filterCodingLists;
}

module.exports = FilterCodingListsService;
