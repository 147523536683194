const Cookies = require('js-cookie');

/* @ngInject */
function SessionService(
	$rootScope,
	$state,
	$transitions,
	AccountSetupService,
	CacheFactory,
	constants,
	ConversationService,
	ErrorService,
	HttpService,
	UserService,
) {
	function cleanUp() {
		CacheFactory.destroyAll();
		ConversationService.abortAll();
		ErrorService.clearContext();
		AccountSetupService.clearSetupState();
		$rootScope.$emit(constants.scopeEvents.loggedOut);
	}

	function saveRouterState({ redirectParams, redirectTo }) {
		const isHttpLocation = window.location && window.location.protocol === 'http:';
		const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
		Cookies.remove(constants.cookies.preLoginRouterState);
		Cookies.set(constants.cookies.preLoginRouterState, JSON.stringify({ redirectParams, redirectTo }), {
			sameSite: 'lax',
			secure: !isHttpLocation,
			expires: inFiveMinutes,
		});
	}

	function navigateToLoginPage({ redirectParams, redirectTo }) {
		cleanUp();
		saveRouterState({ redirectParams, redirectTo });
		if (!window.location.pathname.startsWith('/signin')) {
			// $state.go('signin.login', { redirectParams, redirectTo });
			window.location.href = '/signin/login';
		}
	}

	let redirecting = false;

	$transitions.onSuccess({}, transition => {
		if (transition.$to().name === 'signin.login') {
			redirecting = false;
		}
	});

	return {
		saveRouterState,
		status: function () {
			return HttpService.tradeshiftGo()
				.path('/auth/status')
				.get()
				.catch(function (error) {
					cleanUp();
					throw error;
				});
		},
		activate: function (data) {
			cleanUp();
			data.username = data.email; // needed until complete rename of username => email
			return HttpService.tradeshiftGo().path('/auth/activate').post(data);
		},
		confirmToken: function ({ activationToken, userId }) {
			return HttpService.tradeshiftGo()
				.path(`/auth/users/${userId}/token/${activationToken}`)
				.get()
				.then(({ companyName, firstName, lastName, username }) => {
					return {
						activationToken,
						companyName,
						email: username,
						firstName,
						lastName,
						userId,
					};
				});
		},
		isAuthenticated: async () =>
			UserService.getUser()
				.then(() => true)
				.catch(() => false),
		logout: function () {
			// clean up first in case the call to the backend fails
			cleanUp();
			return HttpService.tradeshiftGo().path('/auth/logout').post();
		},
		register: function (data) {
			return HttpService.tradeshiftGo().path('/auth/register').post(data);
		},
		login: function (data) {
			return HttpService.tradeshiftGo()
				.path('/auth/login')
				.post(data)
				.then(() => {
					CacheFactory.destroyAll();
					return UserService.getUser();
				});
		},
		forgotPassword: function (data) {
			return HttpService.tradeshiftGo().path('/auth/password/forgot').post(data);
		},
		resetPassword: function (data) {
			return HttpService.tradeshiftGo().path('/auth/password/reset').post(data);
		},
		sessionExpiredRedirect: ({ redirectFrom, redirectParams, redirectTo }) => {
			if (redirecting) {
				return;
			}
			redirecting = true;
			if (redirectFrom === '') {
				navigateToLoginPage({ redirectParams, redirectTo });
				return;
			}
			ts.ui.Notification.warning('Your session has expired.\nYou will now be redirected to the login page.', {
				onaccept: () => {
					navigateToLoginPage({ redirectParams, redirectTo });
				},
			});
		},
		checkSaml: function (options) {
			return HttpService.tradeshiftGo()
				.path('/auth/saml?email=' + encodeURIComponent(options.email))
				.get();
		},
	};
}

module.exports = SessionService;
