const SvgAccount = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M16.5 5h-15A1.5 1.5 0 0 0 0 6.5v10A1.51 1.51 0 0 0 1.5 18h15a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 16.5 5m.3 11.5a.31.31 0 0 1-.3.3h-15a.31.31 0 0 1-.3-.3v-10a.3.3 0 0 1 .3-.3h15a.3.3 0 0 1 .3.3ZM3 12h2v-2H3Zm11.8-9.4H3.2A1.2 1.2 0 0 0 2 3.8h14a1.2 1.2 0 0 0-1.2-1.2m-2-2.6H5.2A1.2 1.2 0 0 0 4 1.2h10A1.2 1.2 0 0 0 12.8 0"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

export default SvgAccount;
