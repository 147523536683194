const _ = require('lodash');

/* @ngInject */
function CardModel(constants, RequestRegistry) {
	function Card(card) {
		// Some types of cards don't have a billing address so
		// we make sure to enable it here so the UI has the properties
		// to key into
		this._card = _.defaults(card, constants.emptyBillingAddress);
		this._isVirtual = false;
		if (card.request) {
			// TODO: if refresh on wallet detail page, request will not have comments here,
			// but if view conversation then back to wallet detail, then will have comments :(
			// TODO: should we just put the request in the task?
			this._request = RequestRegistry.storeRequest(card.request);
		}
	}

	Card.prototype.formatCardNumber = function () {
		const cardNumber = this.getCardNumber();
		switch (this.getProvider()) {
			case 'AMEX':
				return [cardNumber.substring(0, 4), cardNumber.substring(4, 10), cardNumber.substring(10, 15)];
			case 'SVB':
			case 'HSBC':
				return [
					cardNumber.substring(0, 4),
					cardNumber.substring(4, 8),
					cardNumber.substring(8, 12),
					cardNumber.substring(12, 16),
				];
			default:
				return [cardNumber];
		}
	};

	Card.prototype.getAmount = function () {
		return this._request.getAmount();
	};

	Card.prototype.getApprover = function () {
		return this._request.getApprover();
	};

	Card.prototype.getAssigner = function () {
		return this._card.assigner;
	};

	Card.prototype.getCardApprover = function () {
		return this._card.request.approver;
	};

	Card.prototype.getFields = function () {
		return this._request.getFields();
	};

	Card.prototype.getBillingAddress = function () {
		return this._card.billingAddress;
	};

	Card.prototype.getAddressLine1 = function () {
		return this.getBillingAddress().addressLine1;
	};

	Card.prototype.getAddressLine2 = function () {
		return this.getBillingAddress().addressLine2;
	};

	Card.prototype.getCardHolderName = function () {
		return this._card.cardHolder.name;
	};

	Card.prototype.getCardSourceName = function () {
		return this._card.name;
	};

	Card.prototype.getCity = function () {
		return this.getBillingAddress().city;
	};

	Card.prototype.getCountryCode = function () {
		return this.getBillingAddress().countryCode;
	};

	Card.prototype.getRegion = function () {
		return this.getBillingAddress().region;
	};

	Card.prototype.getZipCode = function () {
		return this.getBillingAddress().zipCode;
	};

	Card.prototype.getVirtualCardId = function () {
		return this._card.cardId;
	};

	Card.prototype.getCardSourceId = function () {
		return this._card.id;
	};

	Card.prototype.getCardDuration = function () {
		const provider = this.getProvider();
		return _.get(constants.virtualCards, provider + '.cardDuration');
	};

	Card.prototype.getCVVNumber = function () {
		return this._card.cvvNumber || '****';
	};

	Card.prototype.getCardNumber = function () {
		const lastDigits = '***********' + (this._card.lastDigits || '*****');
		if (this.isVirtual()) {
			return this._card.cardNumber || lastDigits;
		} else {
			return lastDigits;
		}
	};

	Card.prototype.getExpirationDate = function () {
		return this._card.expirationDate || 'YYYYMM';
	};

	Card.prototype.getFormattedExpiration = function () {
		const expirationDate = this.getExpirationDate();
		return expirationDate.slice(4, 6) + '/' + expirationDate.slice(0, 4);
	};

	Card.prototype.getIssueDate = function () {
		// TODO: replace with real issueDate if we ever get one from AMEX
		return this._card.assignedOn;
	};

	Card.prototype.getLastFourDigits = function () {
		return this._card.lastDigits;
	};

	Card.prototype.getProvider = function () {
		return this._card.provider;
	};

	Card.prototype.getRequest = function () {
		return this._request;
	};

	Card.prototype.getTaskId = function () {
		return this._card.id;
	};

	Card.prototype._getAssignees = function () {
		return this._card.assignees;
	};

	Card.prototype.hasAddress = function () {
		if (_.has(this._card, 'billingAddress')) {
			return _.some(this._card.billingAddress, value => !_.isEmpty(value));
		} else {
			return false;
		}
	};

	Card.prototype._setBillingAddress = function (address) {
		if (!address) {
			this._card.billingAddress = constants.emptyBillingAddress;
		} else {
			this._card.billingAddress = address;
		}
	};

	Card.prototype._setCardId = function (cardId) {
		this._card.cardId = cardId;
	};

	Card.prototype._setCardNumber = function (cardNumber) {
		this._card.cardNumber = cardNumber;
	};

	Card.prototype._setCVVNumber = function (cvvNumber) {
		this._card.cvvNumber = cvvNumber;
	};

	Card.prototype._setExpirationDate = function (expirationDate) {
		this._card.expirationDate = expirationDate;
	};

	Card.prototype._setProvider = function (provider) {
		this._card.provider = provider;
	};

	Card.prototype._setLastDigits = function (lastDigits) {
		this._card.lastDigits = lastDigits;
	};

	Card.prototype.getDetailSref = function () {
		return 'main.walletDetail({taskId: "' + this.getTaskId() + '"})';
	};

	Card.prototype.getPurchaseCategory = function () {
		return this._request.getTitle();
	};

	Card.prototype.getRequestedBy = function () {
		return this._request.getRequesterName();
	};

	Card.prototype.getRequestId = function () {
		return this._request.getId();
	};

	Card.prototype.getTeamId = function () {
		return this._request.getTeamId();
	};

	Card.prototype.isVirtual = function () {
		return this._isVirtual;
	};

	Card.prototype.isUserAssigned = function (userId) {
		return _.includes(this._getAssignees(), userId);
	};

	Card.prototype.getStatus = function () {
		return constants.cardsourceStatus[this._card.status];
	};

	Card.prototype.getSubjectId = function () {
		return this._card.subjectId;
	};

	Card.prototype.getCurrency = function () {
		return this._card.billingCurrency || this._card.currency;
	};

	return Card;
}

module.exports = CardModel;
