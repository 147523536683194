"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.invitePeopleInitialState = exports.invitePeopleCompanyAccountId = exports.invitePeopleTeamId = exports.invitePeopleApproverId = exports.invitePeopleRoleId = exports.invitePeopleLastName = exports.invitePeopleFirstName = exports.invitePeopleUsername = void 0;
exports.invitePeopleSlice = invitePeopleSlice;
const appConstants_1 = require("../../constants/appConstants");
exports.invitePeopleUsername = 'username';
exports.invitePeopleFirstName = 'firstName';
exports.invitePeopleLastName = 'lastName';
exports.invitePeopleRoleId = 'roleId';
exports.invitePeopleApproverId = 'approverId';
exports.invitePeopleTeamId = 'teamId';
exports.invitePeopleCompanyAccountId = 'companyAccountId';
const getInvitePeopleDefaultState = (teamId = '') => ({
    username: '',
    firstName: '',
    lastName: '',
    roleId: appConstants_1.overviewConstants.COMPANY_ADMIN,
    approverId: undefined,
    teamId,
    companyAccountId: '',
    phoneNumber: '',
    phoneCountryCode: '',
    phoneNumberValue: '',
});
exports.invitePeopleInitialState = {
    invitePeople: getInvitePeopleDefaultState(),
};
function invitePeopleSlice(set) {
    return {
        ...exports.invitePeopleInitialState,
        invitePeopleReset(teamId) {
            set((state) => {
                state.invitePeople = getInvitePeopleDefaultState(teamId);
                return state;
            }, false, '[invitePeopleReset]');
        },
        invitePeopleSetUsername(username) {
            set((state) => {
                state.invitePeople.username = username;
                return state;
            }, false, '[setInvitePeopleUsername]');
        },
        invitePeopleSetPhoneNumber(payload) {
            set((state) => {
                state.invitePeople.phoneNumber = payload.phoneNumber;
                state.invitePeople.phoneCountryCode = payload.phoneCountryCode;
                state.invitePeople.phoneNumberValue = payload.phoneNumberValue;
                return state;
            }, false, '[setInvitePeoplePhoneNumber]');
        },
        invitePeopleSetFirstName(firstName) {
            set((state) => {
                state.invitePeople.firstName = firstName;
                return state;
            }, false, '[setInvitePeopleFirstName]');
        },
        invitePeopleSetLastName(lastName) {
            set((state) => {
                state.invitePeople.lastName = lastName;
                return state;
            }, false, '[setInvitePeopleLastName]');
        },
        invitePeopleSetRoleId(roleId) {
            set((state) => {
                state.invitePeople.roleId = roleId;
                return state;
            }, false, '[setInvitePeopleRoleId]');
        },
        invitePeopleSetApproverId(approverId) {
            set((state) => {
                state.invitePeople.approverId = approverId;
                return state;
            }, false, '[setInvitePeopleApproverId]');
        },
        invitePeopleSetTeamId(teamId) {
            set((state) => {
                state.invitePeople.teamId = teamId;
                return state;
            }, false, '[setInvitePeopleTeamId]');
        },
        invitePeopleSetCompanyAccountId(companyAccountId) {
            // ! check if we need
            set((state) => {
                state.invitePeople.companyAccountId = companyAccountId;
                return state;
            }, false, '[setInvitePeopleCompanyAccountId]');
        },
    };
}
