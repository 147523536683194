const _ = require('lodash');

/* @ngInject */
function HttpInterceptor($q, UUIDService) {
	return {
		request: function (config) {
			config.headers['X-Tradeshift-RequestId'] = UUIDService.v4();
			return config;
		},
		response: function (response) {
			// templates are requested without leading / - we should not override the reponse for these
			const url = response.config.url;
			const isTemplate = !(_.startsWith(url, '/') || _.startsWith(url, 'http'));
			// filename for report downloads is in the content-disposition header, so need to return the entire response
			const isAttachment = _.includes(response.headers()['content-disposition'], 'attachment;');

			if (isTemplate || isAttachment) {
				// default return value
				return response || $q.when(response);
			}
			// return the data property to simplify response handling
			return response.data || $q.when(response.data);
		},
		responseError: function (response) {
			// Response.config.timeout.$$state.status is 1 if the $http's timeout promise was resolved.
			// When cancelling open connections (on logout for example), we resolve all open promises.
			const timeoutTriggered = _.get(response, 'config.timeout.$$state.status') === 1;
			const httpError = new HttpError(response);
			if (timeoutTriggered) {
				httpError.isCancelled = true;
			}
			return $q.reject(httpError);
		},
	};
}

function HttpError(response) {
	this.name = _.get(response, 'data.name') || 'HttpError';
	this.code = _.get(response, 'data.code');
	this.errorReason = _.get(response, 'data.errorReason');
	this.message = [_.get(response, 'config.method'), _.get(response, 'config.url'), 'returned', response.status].join(
		' ',
	);
	this.status = response.status;
	this.stack = new Error().stack;
	this.responseStatusCode = response.statusText;
	this.headers = _.omit(_.get(response, 'config.headers'), 'Authorization');
}
HttpError.prototype = Object.create(Error.prototype);
HttpError.prototype.constructor = HttpError;

module.exports = HttpInterceptor;
