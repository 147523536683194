function UUIDService() {
	const uuid = {};

	// https://gist.github.com/jed/982883
	uuid.v4 = function (a) {
		return a
			? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
			: ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid.v4);
	};

	return uuid;
}

module.exports = UUIDService;
