/* @ngInject */
function ConfirmActionController($scope, AsideService) {
	const that = this;

	function init() {
		that.message = $scope.aside.model.message;
		that.onSuccess = $scope.aside.onSuccess;
	}

	that.confirm = function () {
		that.onSuccess();
		AsideService.closeAll();
	};
	that.cancel = function () {
		AsideService.close('confirmAction');
	};

	$scope.$watch('aside.show', function (show) {
		if (show) {
			init();
		}
	});
}

module.exports = ConfirmActionController;
