const _ = require('lodash');
const moment = require('moment');

/* @ngInject */
function CreateCardController(
	$q,
	$rootScope,
	$scope,
	$timeout,
	constants,
	ApprovalLists,
	AsideService, // NOSONAR
	CodingHelper,
	CodingService,
	CompanyService,
	ConfigService,
	ErrorService,
	EventService,
	FeatureService, // NOSONAR
	LexicoService,
	PaymentsService,
	RequestService,
	TeamsService,
	UUIDService,
) {
	// NOSONAR
	const that = this;

	const CARD_SOURCE_STATUS_ACTIVE = constants.payments.cardSource.status.active;
	const EVENT_TYPE_DATA_EVENT = constants.scopeEvents.dataEvent;
	const TASK_OUTCOME_APPROVED = ConfigService.get('TASK_OUTCOMES').approved;
	const DATE_FORMAT = 'YYYY-MM-DD';
	const PAUSE_VIRTUAL_CARD_CREATION = constants.teamProperties.pauseVirtualCardCreation;
	const createCardListeners = new Set();

	const decorateCodingLists = codingLists => {
		const decoratedLists = _.cloneDeep(codingLists);
		Object.entries(decoratedLists).forEach(([listId, { entries, label: listLabel }]) => {
			entries.forEach(entry => {
				Object.assign(entry, { listId, listLabel });
			});
		});
		return decoratedLists;
	};

	$scope.lexico = LexicoService.getLexico();

	$scope.setDates = async () => {
		const virtualCardExpRange = await CompanyService.getVirtualCardExpRange();
		const { ENABLE_VIRTUAL_CARD_EXP_RANGE } = await FeatureService.getFeatures();

		const endDateOffSetDays = ENABLE_VIRTUAL_CARD_EXP_RANGE ? virtualCardExpRange : constants.virtualCardDefaultExpDate;

		$scope.maxEndDate = moment().add(endDateOffSetDays, 'days').format(DATE_FORMAT);
		$scope.minEndDate = moment().add(2, 'days').format(DATE_FORMAT);
		$scope.dateMessage = 'Up to 4 years in the future.';

		if (ENABLE_VIRTUAL_CARD_EXP_RANGE) {
			const rangeMessage = constants.virtualCardExpRanges.find(range => range.id === virtualCardExpRange);
			$scope.dateMessage = `Up to ${rangeMessage.label} in the future`;
		}
	};

	const init = async () => {
		that.isLoading = { aside: true };
		AsideService.busy('createCardV2', true);
		await $scope.setDates();
		if (moment($scope.minEndDate).isSameOrAfter($scope.aside.model.approval.getRequest().getFields().endDate)) {
			$scope.aside.model.approval.getRequest().getFields().endDate = $scope.minEndDate;
		}
		that.approval = $scope.aside.model.approval;
		that.onSuccess = $scope.aside.onSuccess || (() => {}); // NO SONAR
		that.submitting = false;

		const teamId = $scope.aside.model.approval.getRequest().getTeamId();
		const [rawCodingLists, cardSources, features, isTeamCardCreationPaused] = await $q.all([
			CodingHelper.loadTeamCodingLists(teamId),
			PaymentsService.getCardSources({ status: CARD_SOURCE_STATUS_ACTIVE, teamId }),
			FeatureService.getFeatures(),
			TeamsService.getTeamProperty({ teamId, propertyKey: PAUSE_VIRTUAL_CARD_CREATION }),
		]);
		$scope.features = features;
		$scope.isTeamCardCreationPaused = isTeamCardCreationPaused === 'true';
		const mappedCodingLists = CodingService.mapTeamCodingLists(rawCodingLists);
		$scope.codingLists = decorateCodingLists(mappedCodingLists);
		const coding = _.chain(that.approval.getRequest().getCoding())
			.filter(code => $scope.codingLists[code.listId])
			.map(code => ({
				entryId: code.entryId,
				label: code.entryLabel,
				listId: code.listId,
				listLabel: code.listLabel,
			}))
			.keyBy('listId')
			.value();
		const codingLists = $scope.codingLists;
		that.options = {
			cardSources,
			codingLists,
			cardTypes: ConfigService.get('CARD_TYPES'),
		};
		that.approvedRequest = {
			cardType: that.approval.getCardTypeKey(),
			cardSource: _.size(that.options.cardSources) > 0 ? _.head(that.options.cardSources) : null,
			coding,
		};
		AsideService.busy('createCardV2', false);
		that.isLoading.aside = false;
	};

	const mapCoding = coding => {
		return _.reduce(
			coding,
			(result, value, key) => {
				result[key] = {
					ID: value.entryId,
					listId: value.listId,
					listLabel: value.listLabel,
					path: value.label,
				};
				return result;
			},
			{},
		);
	};

	const registerCreateCardListener = ({ approval, cardType, eventId }) => {
		let notificationEnabled = true;
		const deregister = EventService.registerOneShotWithTimeout({
			$rootScope,
			guard: (event, { type, id }) => type === 'createCard' && id === eventId,
			eventHandler: (event, { errorType, success }) => {
				if (success) {
					ApprovalLists.completeApproval(approval.getId(), TASK_OUTCOME_APPROVED);
					approval.setCardType(cardType);
					$rootScope.$broadcast(constants.scopeEvents.newVirtualCard);
					if (notificationEnabled) {
						ts.ui.Notification.success('The virtual card has been created.');
						AsideService.closeAll();
						that.onSuccess();
					}
				} else {
					approval.setProcessing(false);
					if (notificationEnabled) {
						that.submitting = false;
						ts.ui.Notification.warning(ErrorService.getErrorMessage(errorType));
					}
				}
			},
			eventType: EVENT_TYPE_DATA_EVENT,
			timeoutHandler: () => {
				if (notificationEnabled) {
					that.submitting = false;
					ts.ui.Notification.warning(ErrorService.getErrorMessage('createCardTimeout'), {
						onaccept: () => {
							$timeout(() => {
								AsideService.closeAll();
							});
						},
					});
				}
			},
			timeoutMs: 60000,
			listenAfterTimeout: true,
		});
		return {
			deregister,
			muteNotification: () => {
				notificationEnabled = false;
			},
		};
	};

	that.cancel = () => {
		AsideService.closeAll();
	};
	that.submit = (approvedRequest, approval) => {
		that.submitting = true;
		if (approvedRequest.cardSource.status === 'IN_PROGRESS') {
			this.submitting = false;
			ts.ui.Notification.warning(ErrorService.getErrorMessage('createCardEnrollmentIncomplete'));
			return;
		}
		const data = {
			cardSourceId: approvedRequest.cardSource.id,
			cardType: approvedRequest.cardType,
			coding: mapCoding(approvedRequest.coding),
			eventId: UUIDService.v4(),
			requesterUserId: approval.getRequestedByUserId(),
			requestId: approval.getRequest().getId(),
			taskId: approval.getId(),
			endDate: approval.getRequest().getFields().endDate,
		};
		const { cardType, eventId } = data;
		const createCardListener = registerCreateCardListener({ approval, cardType, eventId });
		createCardListeners.add(createCardListener);
		approval.setProcessing(true);
		return RequestService.approve(data).catch(error => {
			approval.setProcessing(false);
			that.submitting = false;
			createCardListener.deregister();
			let errorMessage;
			if (error.status === 403) {
				errorMessage = error.errorReason;
			} else if (error.status === 429) {
				errorMessage = $scope.lexico.trc(
					'Warning message',
					'Your company has exceeded the limit for approvals made per minute. ' +
						'Please limit approvals to less than 10 per minute and try again later.',
				);
			} else {
				errorMessage = ErrorService.getErrorMessage('createCardError');
			}
			ts.ui.Notification.warning(errorMessage);
		});
	};

	$scope.$watch('aside.show', show => {
		if (show) {
			init();
		}
	});

	$scope.$on('$destroy', () => {
		createCardListeners.forEach(createCardListener => {
			createCardListener.muteNotification();
		});
		createCardListeners.clear();
	});
}

module.exports = CreateCardController;
