/* @ngInject */
function SortOptionsController($scope, AsideService) {
	const that = this;

	function init() {
		that.sortOptions = $scope.aside.model.sortOptions;
		that.onSuccess = $scope.aside.onSuccess;
	}

	that.select = function (option) {
		that.onSuccess(option);
		AsideService.close('sortOptions');
	};
	that.cancel = function () {
		AsideService.closeAll();
	};

	$scope.$watch('aside.show', function (show) {
		if (show) {
			init();
		}
	});
}

module.exports = SortOptionsController;
