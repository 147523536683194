const DashboardBoxLoaderComponent = {
	template: `
	<div class="dashboard-box-loader-container">
		<event-go-typing class="margin-loader">
			<div class="dot one"></div>
			<div class="dot two"></div>
			<div class="dot three"></div>
		</event-go-typing>
		<small ng-bind="$ctrl.loadingText"></small>
	</div>`,
	controller: /* @ngInject */ function (LexicoService) {
		this.lexico = LexicoService.getLexico();
		this.loadingText = this.lexico.tr('Loading');
	},
};

export default DashboardBoxLoaderComponent;
