"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerActivateLoader = void 0;
const sessionRepository_1 = require("../../../repositories/sessionRepository");
const registerActivateLoader = async ({ params }) => {
    try {
        const queryResult = await sessionRepository_1.SessionRepository.confirmToken(params);
        return { data: queryResult, error: null, params, isLoading: false };
    }
    catch (error) {
        return { data: null, isLoading: false, error };
    }
};
exports.registerActivateLoader = registerActivateLoader;
