/* @ngInject */
function FeatureService(HttpService) {
	let featureRegistryList = {};
	const service = {
		featureRegistryList: {},
	};

	service.getFeatures = async () => {
		const getFeaturesList = await HttpService.tradeshiftGo().path('/rest/features').get();
		featureRegistryList = getFeaturesList;
		return getFeaturesList;
	};

	service.saveFeature = async options => {
		featureRegistryList[options.name] = options.value;
		const saveFeatureList = await HttpService.tradeshiftGo()
			.path('/rest/features/' + options.name)
			.put(options.value);
		return saveFeatureList;
	};

	service.getFeaturesRegistryList = () => {
		return featureRegistryList;
	};

	return service;
}

module.exports = FeatureService;
