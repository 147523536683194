"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.populateSelectedCodingEntryTeams = void 0;
const populateSelectedCodingEntryTeams = ({ setCurrentSelectedCodingEntryTeams, selectedCodingList, codingInactiveListEntries, teams, codingEntry, }) => {
    const selectedTeams = [];
    teams.forEach((team) => {
        const inactiveEntry = codingInactiveListEntries[selectedCodingList.id] || [];
        const hasInactiveEntry = inactiveEntry.find(entry => entry.entry_id === codingEntry.ID && entry.team_id === team.id);
        selectedTeams.push({
            teamId: team.id,
            teamName: team.name,
            state: !hasInactiveEntry,
        });
    });
    setCurrentSelectedCodingEntryTeams(selectedTeams);
};
exports.populateSelectedCodingEntryTeams = populateSelectedCodingEntryTeams;
