const _ = require('lodash');

const HeaderService = () => {
	const DEFAULT_TITLE = 'Tradeshift Go';

	const service = {};
	let _header;

	const getHeader = () => {
		if (!_header) {
			service.init();
		}
		_header.show();
		return _header;
	};

	service.init = () => {
		// in desktop - show ToolBar and hide TopBar
		// in mobile - hide ToolBar and show TopBar
		_header = ts.ui.Header;
	};

	service.create = ({ tabs, backbutton, buttons, type, title, selectedTabIndex }) => {
		const header = getHeader();
		header.backbutton(backbutton || null);

		if (tabs) {
			header.tabs(tabs);
		} else {
			header.tabs().clear();
		}

		if (buttons) {
			header.buttons(buttons);
		} else {
			header.buttons().clear();
		}

		switch (type) {
			case 'dark':
				header.color('ts-slate');
				break;
			case 'landing':
				// green used for landing so we can customize the style without affecting anything else in the app
				header.color('ts-green');
				break;
			case 'lite':
				header.color('ts-white');
				break;
			case 'restyled':
				header.color('header-restyled');
				break;
			case 'signin':
			default:
				// purple used for signin so we can customize the style without affecting anything else in the app
				header.color('ts-purple');
				break;
		}

		header.title(title || DEFAULT_TITLE);

		if (!_.isNil(selectedTabIndex)) {
			header.tabs().selectedIndex = selectedTabIndex;
		}

		return header;
	};

	service.selectTab = ({ tabIndex }) => {
		getHeader().tabs().selectedIndex = tabIndex;
	};
	service.updateLabels = labels => {
		labels.forEach((label, index) => {
			Object.assign(getHeader().tabs()[index], { label });
		});
	};

	service.updateTitle = title => {
		getHeader().title(title);
	};

	service.get = () => getHeader();

	service.hideUnderMain = () => {
		service.get().hide();
	};

	service.reset = () => {
		const header = getHeader();
		header.title(DEFAULT_TITLE);
		header.buttons().clear();
	};

	return service;
};

module.exports = HeaderService;
