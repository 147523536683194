const AddCodingListAsideComponent = {
	bindings: {
		codingLists: '<',
		isOpen: '<',
		lists: '<',
		onClose: '&',
		onCustomListSelected: '&',
		updateTableAfterListAdd: '&',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.codingListTitleText}}">
			<p ng-bind="$ctrl.codingListAsideInfoText"></p>
			<menu
					class="default-coding-lists"
					data-ts="Menu">
				<li ng-repeat="list in $ctrl.codingLists">
					<button
							ng-bind="list.name"
							ng-click="$ctrl.updateCodingListTable(list)">
					</button>
				</li>
				<li class="custom-list-btn">
					<button
							ng-bind="$ctrl.createCustomListText"
							ng-click="$ctrl.onCustomListSelected({type: 'create'})">
					</button>
					<i class="ts-icon-arrowright"></i>
				</li>
			</menu>
		</ts-aside>
	`,
	controller: class AddCodingListAsideComponentController {
		/* @ngInject */
		constructor(CodingService, CodingHelper, EventEmitter, LexicoService) {
			this.lexico = LexicoService.getLexico();
			this.codingListTitleText = this.lexico.trc('Aside component title', 'Add coding list');
			this.codingListAsideInfoText = this.lexico.trc(
				'Aside component message',
				'Select from coding list presets or create your own new list.',
			);
			this.createCustomListText = this.lexico.trc('Button', 'Create custom list');
			this.CodingHelper = CodingHelper;
			this.CodingService = CodingService;
			this.EventEmitter = EventEmitter;
			this.isLoading = false;
		}

		$onInit() {
			this.lists = this.lists || [];
		}

		async updateCodingListTable(selectedList) {
			this.isLoading = true;
			if (!this.lists.includes(selectedList)) {
				await this.CodingService.saveList(selectedList);
			}
			this.updateTableAfterListAdd(this.EventEmitter(selectedList));
			this.isLoading = false;
			this.onClose();
		}
	},
};

export default AddCodingListAsideComponent;
