const _ = require('lodash');

function NameService() {
	return {
		fullName: function (user) {
			user = user || {};
			const firstName = user.firstName || '';
			const lastName = user.lastName || '';

			if (!firstName && !lastName) {
				return _.escape(user.Username);
			}
			let fullName = firstName + ' ';
			fullName = fullName + lastName;
			return _.escape(fullName);
		},
	};
}

module.exports = NameService;
