"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.teamCardInitialState = void 0;
exports.teamCardSlice = teamCardSlice;
exports.teamCardInitialState = {
    teamCard: {
        cardFeatures: [],
        areAllCardFeaturesDisabled: false,
        cardSourceOptions: [],
    },
};
function teamCardSlice(set) {
    return {
        ...exports.teamCardInitialState,
        setTeamCardFeatures(cardFeatures) {
            set((state) => {
                if (!cardFeatures || cardFeatures.length === 0) {
                    return state;
                }
                state.teamCard.cardFeatures = cardFeatures;
                if (cardFeatures.length > 0) {
                    state.teamCard.areAllCardFeaturesDisabled = cardFeatures?.every(obj => {
                        return !obj?.isEnabled;
                    });
                }
                return state;
            }, false, '[setTeamCardFeatures]');
        },
        setTeamCardOptions(cardSourceOptions) {
            set((state) => {
                state.teamCard.cardSourceOptions = cardSourceOptions;
                return state;
            }, false, '[setTeamCardOptions]');
        },
    };
}
