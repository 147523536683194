"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSettlementListQuery = exports.useViewReceiptImage = exports.viewReceiptImage = exports.useUploadReceipt = exports.useRemoveReceiptQuery = exports.getReceiptInfoQuery = exports.walletSettlementListQuery = exports.SettlementKeys = void 0;
const react_query_1 = require("@tanstack/react-query");
const settlementRepository_1 = require("../repositories/settlementRepository");
const Root_1 = require("../router/Root");
const paymentQuery_1 = require("./paymentQuery");
exports.SettlementKeys = {
    getSettlementListForWallet: 'getSettlementListForWallet',
    getReceiptInfo: 'getReceiptInfo',
    removeReceipt: 'removeReceipt',
    viewReceipt: 'viewReceipt',
    getSettlementList: 'getSettlementList',
};
const walletSettlementListQuery = ({ virtualCardId }) => (0, react_query_1.queryOptions)({
    queryKey: [exports.SettlementKeys.getSettlementListForWallet, virtualCardId],
    queryFn: async () => {
        const initialSettlements = await settlementRepository_1.SettlementRepository.getListForWallet({ virtualCardId });
        return Promise.all(initialSettlements.settlements.map(async (settlement) => {
            const totalAmount = await Root_1.queryClient.ensureQueryData((0, paymentQuery_1.currencyAndTotalQuery)(settlement.currency, settlement.amount));
            settlement.totalAmount = totalAmount || '';
            return settlement;
        }));
    },
    enabled: Boolean(virtualCardId),
});
exports.walletSettlementListQuery = walletSettlementListQuery;
const getReceiptInfoQuery = (settlementId) => (0, react_query_1.queryOptions)({
    queryKey: [exports.SettlementKeys.getReceiptInfo, settlementId],
    queryFn: () => settlementRepository_1.SettlementRepository.getReceiptInfo(settlementId),
    enabled: Boolean(settlementId),
});
exports.getReceiptInfoQuery = getReceiptInfoQuery;
const useRemoveReceiptQuery = () => {
    const queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)({
        mutationFn: settlementRepository_1.SettlementRepository.removeReceipt,
        onSuccess: async (_data, _variables) => {
            const settlementId = _variables.settlementId;
            const virtualCardId = _variables.virtualCardId;
            await queryClient.invalidateQueries({
                queryKey: [exports.SettlementKeys.getSettlementListForWallet, virtualCardId],
            });
            await queryClient.invalidateQueries({
                queryKey: [exports.SettlementKeys.getReceiptInfo, settlementId],
            });
        },
    });
};
exports.useRemoveReceiptQuery = useRemoveReceiptQuery;
const useUploadReceipt = () => {
    const queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)({
        mutationFn: settlementRepository_1.SettlementRepository.upload,
        onSuccess: async (_data, _variables) => {
            const settlementId = _variables.settlementId;
            const virtualCardId = _variables.virtualCardId;
            await queryClient.invalidateQueries({
                queryKey: [exports.SettlementKeys.getSettlementListForWallet, virtualCardId],
            });
            await queryClient.invalidateQueries({
                queryKey: [exports.SettlementKeys.getReceiptInfo, settlementId],
            });
        },
    });
};
exports.useUploadReceipt = useUploadReceipt;
const viewReceiptImage = ({ receiptId }) => {
    return (0, react_query_1.queryOptions)({
        queryKey: [exports.SettlementKeys.viewReceipt, receiptId],
        queryFn: () => settlementRepository_1.SettlementRepository.viewReceipt({ receiptId }),
        select: (initialBlob) => {
            const objectURL = window.URL.createObjectURL(initialBlob);
            const type = initialBlob.type === 'application/pdf' ? 'pdf' : 'image';
            return { type, objectURL };
        },
        enabled: Boolean(receiptId),
    });
};
exports.viewReceiptImage = viewReceiptImage;
const useViewReceiptImage = ({ receiptId }) => {
    return (0, react_query_1.useQuery)((0, exports.viewReceiptImage)({ receiptId }));
};
exports.useViewReceiptImage = useViewReceiptImage;
const getSettlementListQuery = ({ month, year, teamId }) => ({
    queryKey: [exports.SettlementKeys.getSettlementList, month, year, teamId],
    queryFn: async () => {
        const initialSettlements = await settlementRepository_1.SettlementRepository.getSettlementList({ month, year, teamId });
        return Promise.all(initialSettlements.map(async (settlement) => {
            const totalAmount = await Root_1.queryClient.ensureQueryData((0, paymentQuery_1.currencyAndTotalQuery)(settlement.currency, settlement.amount));
            settlement.totalAmount = totalAmount || '';
            return settlement;
        }));
    },
    enabled: Boolean(month && year),
});
exports.getSettlementListQuery = getSettlementListQuery;
