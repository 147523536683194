'use strict';

const _ = require('lodash');
const constants = require('./constants');
const CARDS_ERROR_CODE_DOMAINS = constants.get('payments.errorCodeDomains');

const util = {};

util.isCardsProviderError = error => {
	if (_.isNil(error.code)) {
		return false;
	}
	const foundDomain = CARDS_ERROR_CODE_DOMAINS.find(domain => error.code.includes(domain));
	return !_.isNil(foundDomain);
};

module.exports = util;
