const _ = require('lodash');

/* @ngInject */
function ReportService(HttpService) {
	const service = {};

	service.getReport = function (options) {
		options = options || {};
		const type = _.get(options, 'type', 'requests');
		const format = _.get(options, 'format', 'summary');
		let path = '/rest/reports?format=' + format + '&type=' + type;
		if (options.virtualCardId) {
			path += '&virtualCardId=' + options.virtualCardId;
		} else if (options.cardSourceId) {
			path += '&cardSourceId=' + options.cardSourceId;
		} else if (options.teamId) {
			path += '&teamId=' + options.teamId;
		}

		if (options.createdBefore) {
			path += '&createdBefore=' + options.createdBefore;
		}

		if (options.createdAfter) {
			path += '&createdAfter=' + options.createdAfter;
		}

		return HttpService.tradeshiftGo().path(path).config({ timeout: 120000 }).get();
	};

	return service;
}

module.exports = ReportService;
