function drawer() {
	return {
		restrict: 'E',
		link: function ($scope, $element) {
			setTimeout(() => {
				const ngApp = document.querySelectorAll('[ng-app]')[0];
				const tsApp = document.querySelectorAll('[data-ts="App"]')[0];

				if ($scope.$ctrl && $scope.$ctrl.toggleCollapse && $scope.$ctrl.setCollapseVisible) {
					$element
						.find('header')
						.on('click', () => {
							$scope.$ctrl.toggleCollapse();
						})
						.on('mouseenter', () => {
							$scope.$ctrl.setCollapseVisible(true);
						})
						.on('mouseleave', () => {
							$scope.$ctrl.setCollapseVisible(false);
						});
				}

				ngApp.insertBefore($element[0], tsApp);
				$scope.$on('$destroy', function () {
					$element.remove();
				});
			}, 0);
		},
	};
}

module.exports = drawer;
