const SvgContactLiveChatActive = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M13.66 8.34a6 6 0 0 1 1.89 1.33 3.5 3.5 0 0 1 .76 1.17L18 15h-5.5V8.84a3 3 0 0 0 .5-.39l.3-.29ZM12.5 5.5V7a4.3 4.3 0 0 0 1.28-3.2c0-2.32-1.25-3.8-3.19-3.8S7.38 1.48 7.38 3.77a5.2 5.2 0 0 0 .29 1.73ZM11 15H3l-3 3V7h11Zm-3-4.75H3v1.5h5Z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
export default SvgContactLiveChatActive;
