'use strict';

const accessHelper = require('./accessHelper');

const operations = {
	READ: 'read',
	WRITE: 'write',
	APPROVE: 'approve',
	INITIALIZE: 'initialize',
	VIEW: 'view',
};
const objects = {
	CARD_REQUEST: 'card_request',
	CARD_REQUEST_TO_ME: 'card_request_to_me',
	CARD_SOURCES: 'card_sources',
	COMPANY: 'company',
	GO_BRANCH: 'go_branch',
	TEAM: 'team',
	TEAM_PROPERTIES: 'team_properties',
	COMPANY_SETTINGS: 'company_settings',
	TEAM_SETTINGS: 'team_settings',
};
const composites = accessHelper.generateComposites(operations, objects);

module.exports = { operations, objects, composites };
