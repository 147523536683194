"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.teamsOverviewLoader = void 0;
const access_1 = require("../../../../common/constants/access");
const companyQuery_1 = require("../../../queries/companyQuery");
const teamQuery_1 = require("../../../queries/teamQuery");
const userQuery_1 = require("../../../queries/userQuery");
const stopAngularjsLoader_1 = require("../stopAngularjsLoader");
const teamsOverviewLoader = async ({ queryClient }) => {
    const teamIdQuery = (0, teamQuery_1.selectedTeamIdQuery)('selectedTeamID');
    const verifyUserAccessQuery = (0, userQuery_1.getVerifyUserAccessQuery)(access_1.composites.VIEW_COMPANY_SETTINGS);
    const requestLimitQuery = (0, companyQuery_1.getRequestLimitQuery)();
    const teamId = await queryClient.ensureQueryData(teamIdQuery);
    const userData = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const hasAccess = await queryClient.ensureQueryData({
        queryKey: verifyUserAccessQuery.queryKey,
        queryFn: verifyUserAccessQuery.queryFn,
    });
    const requestLimitData = await queryClient.ensureQueryData({
        queryKey: requestLimitQuery.queryKey,
        queryFn: requestLimitQuery.queryFn,
    });
    (0, stopAngularjsLoader_1.stopAngularJsLoader)();
    return {
        teamId,
        userData,
        hasAccess,
        requestLimitData,
    };
};
exports.teamsOverviewLoader = teamsOverviewLoader;
