const Sentry = require('@sentry/browser');
const _ = require('lodash');

const TeamActionAsideComponent = {
	bindings: {
		isOpen: '<',
		onClose: '&',
		refreshTeams: '&',
		team: '<',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				is-loading="$ctrl.isLoading"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.teamActionsTitleText}}">
			<div class="teams-info">
				<div>
					<span ng-bind="$ctrl.teamNameFormText"></span>
					<p ng-bind="$ctrl.team.name"></p>
				</div>
				<div class="ts-multi">
					<div>
						<span ng-bind="$ctrl.createdFormText"></span>
						<p ng-bind="$ctrl.team.createdDate"></p>
					</div>
					<div>
						<span ng-bind="$ctrl.createdByFormText"></span>
						<p ng-bind="$ctrl.team.createdBy.fullName || $ctrl.notAvailableText"></p>
					</div>
				</div>
				<div class="ts-multi">
					<div>
						<span ng-bind="$ctrl.activeCardsFormText"></span>
						<p ng-bind="$ctrl.team.isCardLimitExceeded ? $ctrl.notAvailableText : $ctrl.team.numberOfCards"></p>
					</div>
					<div>
						<span ng-bind="$ctrl.membersFormText"></span>
						<p ng-bind="$ctrl.team.numberOfMembers"></p>
					</div>
				</div>
			</div>
			<menu data-ts="Buttons">
				<ts-menu-button
						button-class="ts-secondary"
						label="$ctrl.viewTeamDetailsButtonText"
						on-click="$ctrl.goToTeamSettings()"></ts-menu-button>
				<ts-menu-button
						button-class="ts-secondary"
						label="$ctrl.cardCreationButtonStateText"
						on-click="$ctrl.toggleCardCreation()"></ts-menu-button>
				<ts-menu-button
						button-class="ts-secondary"
						ng-if="$ctrl.team.state === 'ACTIVE'"
						label="$ctrl.deactivateTeamButtonText"
						on-click="$ctrl.openAside('deactivateTeam')"></ts-menu-button>
				<ts-menu-button
						button-class="ts-secondary"
						ng-if="$ctrl.team.state === 'INACTIVE'"
						label="$ctrl.activateTeamButtonText"
						on-click="$ctrl.activateTeam()"></ts-menu-button>
			</menu>
		</ts-aside>
		<go-confirm-action-aside
				is-open="$ctrl.asides.deactivateTeam.isOpen"
				message="$ctrl.confirmDeactivateTeamText"
				on-cancel="$ctrl.onAsideClosed('deactivateTeam')"
				on-confirm="$ctrl.deactivateTeam()"></go-confirm-action-aside>
	`,
	controller: class TeamActionsAsideController {
		/* @ngInject */
		constructor($state, constants, LexicoService, TeamsService) {
			this.$state = $state;
			this.pauseCardCreationKey = constants.teamProperties.pauseVirtualCardCreation;
			this.isLoading = false;
			this.lexico = LexicoService.getLexico();
			this.teamActionsTitleText = this.lexico.trc('Component title', 'Team actions');
			this.teamNameFormText = this.lexico.trc('Form input title', 'Team name');
			this.createdFormText = this.lexico.trc('Form input title', 'Created');
			this.createdByFormText = this.lexico.trc('Form input title', 'Created by');
			this.activeCardsFormText = this.lexico.trc('Form input title', 'Active cards');
			this.membersFormText = this.lexico.trc('Form input title', 'Members');
			this.viewTeamDetailsButtonText = this.lexico.trc('Button', 'View team details');
			this.deactivateTeamButtonText = this.lexico.trc('Button', 'De-activate team');
			this.activateTeamButtonText = this.lexico.trc('Button', 'Re-activate team');
			this.unpauseCardCreationText = this.lexico.trc('Button text', 'Unpause Card Creation');
			this.pauseCardCreationText = this.lexico.trc('Button text', 'Pause Card Creation');
			this.confirmDeactivateTeamText = this.lexico.trc(
				'User notification message',
				'Deactivating a team will cancel all active virtual cards and none of the team members, ' +
					'managers, or owners will be able to access the team. Only you or another company admin can ' +
					're-activate a team. Are you sure?',
			);
			this.notAvailableText = this.lexico.trc('Data not available', 'N/A');

			this.TeamsService = TeamsService;
		}

		onAsideClosed(key) {
			this.asides[key].isOpen = false;
		}

		openAside(key) {
			this.asides[key].isOpen = true;
		}

		refreshAndClose() {
			this.refreshTeams();
			this.onClose();
		}

		async toggleCardCreation() {
			this.isLoading = true;
			try {
				await this.TeamsService.setTeamProperty(this.team.id, this.pauseCardCreationKey, !this.isCardCreationPaused);
			} catch (e) {
				const warningMessage = this.lexico.trc(
					'Warning Message',
					'There was an error saving the settings for your team. Please Try again.',
				);
				ts.ui.Notification.warning(warningMessage);
			} finally {
				this.isLoading = false;
				this.onClose();
			}
		}

		async goToTeamSettings() {
			if (this.team.id === undefined) {
				Sentry.captureMessage(`goToTeamSettings setCurrentTeamById(${this.team.id})`, { level: 'info' });
			}
			await this.TeamsService.setCurrentTeamById(this.team.id);
			this.$state.go('main.teamSettings.overview');
			this.onClose();
		}

		async deactivateTeam() {
			try {
				await this.TeamsService.deactivateTeam(this.team.id);
				this.refreshAndClose();
				this.onAsideClosed('deactivateTeam');
			} catch (e) {
				const warningMessage = this.lexico.trc(
					'Warning Message',
					'There was an error deactivating your team. Please Try again.',
				);
				ts.ui.Notification.warning(warningMessage);
			}
		}

		async activateTeam() {
			await this.TeamsService.activateTeam(this.team.id);
			this.refreshAndClose();
		}

		async $onInit() {
			this.isLoading = true;
			const selectedTeamProperties = await this.TeamsService.getTeamProperty({
				teamId: this.team.id,
				propertyKey: this.pauseCardCreationKey,
			});

			/* Need to check if the data from the getTeamProperty is empty since the property
			only gets saved in the DB the first time the user toggles the switch. The else statement
			converts the string boolean returned from the getTeamProperty function into an actual boolean */
			if (_.isEmpty(selectedTeamProperties)) {
				this.isCardCreationPaused = false;
			} else {
				this.isCardCreationPaused = selectedTeamProperties === 'true';
			}

			this.cardCreationButtonStateText = this.isCardCreationPaused
				? this.unpauseCardCreationText
				: this.pauseCardCreationText;
			this.isLoading = false;
			this.asides = {
				deactivateTeam: {
					isOpen: false,
				},
			};
		}
	},
};

export default TeamActionAsideComponent;
