const _ = require('lodash');
const SCROLLING_DELAY = 200;

/* @ngInject */
function conversation(constants, ConversationService, LexicoService, ScrollService, UserService) {
	const GOTYPING_EVENT_ID = constants.goTypingEventId;
	return {
		restrict: 'E',
		scope: {
			request: '=',
		},
		template: require('./conversation.html'),
		controller: 'ConversationController',
		link: $scope => {
			$scope.lexico = LexicoService.getLexico();
			$scope.isUserAuthor = user => _.get($scope, 'user.userId') === user.userId;

			function getEvents(options) {
				ConversationService.getEvents(options)
					.then(result => {
						$scope.request.pushEvents(result.events);
						if ($scope.request.isNew()) {
							$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
						}
					})
					.catch(() => {
						// if there isn't a global connection error
						if (!$scope.$parent.connectionError) {
							const warningMessage = `${$scope.lexico.tr(
								'Unable to get messages on request',
							)} ${$scope.request.getTitle()}, ${$scope.lexico.tr('Try again')}`;
							ts.ui.Dialog.warning(warningMessage, {
								onaccept: function () {
									getEvents(options);
								},
							});
						}
					})
					.finally(function () {
						$scope.$emit(constants.scopeEvents.goTyping, { isTyping: false });
					});
			}

			function init() {
				if ($scope.request.isNew()) {
					$scope.$emit(constants.scopeEvents.goTyping, { isTyping: true });
				}
				UserService.getUser().then(function (userResult) {
					$scope.user = userResult;
				});
				const options = {
					after: 0,
					conversationId: $scope.request.getId(),
					polling: false,
				};
				getEvents(options);
			}
			$scope.showAddMessageForm = function (request) {
				return !request.isCreated() && !request.isNew();
			};
			$scope.$watch('request.getEvents()', function (events) {
				$scope.request.resetNewMessageCount();
				if (_.size(events) > 0) {
					const lastEventId = 'hash' + _.last(events).sequenceNumber;
					ScrollService.scroll(lastEventId, SCROLLING_DELAY, $scope);
				}
			});
			$scope.$on(constants.scopeEvents.goTyping, (event, { isTyping }) => {
				$scope.showTyping = isTyping;
				if ($scope.showTyping) {
					$scope.typingEvent = ConversationService.generateTypingEvent();
					ScrollService.scroll(GOTYPING_EVENT_ID, SCROLLING_DELAY);
				}
			});
			const unbindWatch = $scope.$watch('request.getId()', () => {
				unbindWatch();
				init();
			});
		},
	};
}

module.exports = conversation;
