/* @ngInject */
function DashboardService($state, HttpService) {
	return {
		getCompanyMetrics: () => HttpService.tradeshiftGo().path('/rest/dashboard/company').get(),
		getManagerMetrics: teamId => HttpService.tradeshiftGo().path(`/rest/dashboard/team/${teamId}/manager`).get(),
		getTeamMetrics: teamId =>
			HttpService.tradeshiftGo()
				.path(`/rest/dashboard/team/${teamId}`)
				.get()
				.catch(error => {
					if (error.status === 401) {
						return $state.go('main.wallet');
					}
					throw error;
				}),
	};
}

module.exports = DashboardService;
