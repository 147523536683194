"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCookieCategory = exports.TradeshiftCookieCategorization = exports.IgnoredCookies = exports.CookieCategory = exports.CONSENT_COOKIE_NAME = void 0;
const routerUtils_1 = require("../../router/routerUtils");
/**
 * Name of the cookie that holds consent preferences
 */
exports.CONSENT_COOKIE_NAME = 'cookie-consent';
/**
 * All existing cookie categories, that will be selectable by the end-user
 * in a Cookie Manager component, and persisted in the platform.
 *
 * Naming conventions and description by the GDPR:
 * - https://gdpr.eu/cookies/
 */
var CookieCategory;
(function (CookieCategory) {
    /**
     * Strictly necessary cookies — These cookies are essential for you to browse the website and use its features,
     * such as accessing secure areas of the site. Cookies that allow web shops to hold your items in your cart
     * while you are shopping online are an example of strictly necessary cookies.
     * These cookies will generally be first-party session cookies. While it is not required to obtain consent
     * for these cookies, what they do and why they are necessary should be explained to the user.
     */
    CookieCategory["NECESSARY"] = "NECESSARY";
    /**
     * Preferences cookies — Also known as “functionality cookies,” these cookies allow a website
     * to remember choices you have made in the past, like what language you prefer, what region you
     * would like weather reports for, or what your user name and password are so you can automatically log in.
     */
    CookieCategory["PREFERENCES"] = "PREFERENCES";
    /**
     * Statistics cookies — Also known as “performance cookies,”
     * these cookies collect information about how you use a website,
     * like which pages you visited and which links you clicked on.
     * None of this information can be used to identify you. It is all aggregated and, therefore, anonymized.
     * Their sole purpose is to improve website functions.
     * This includes cookies from third-party analytics services as long as the cookies
     * are for the exclusive use of the owner of the website visited.
     */
    CookieCategory["STATISTICS"] = "STATISTICS";
    /**
     * Marketing cookies — These cookies track your online activity to help advertisers deliver more relevant
     * advertising or to limit how many times you see an ad. These cookies can share that information with
     * other organizations or advertisers. These are persistent cookies and almost always of third-party provenance.
     */
    CookieCategory["MARKETING"] = "MARKETING";
})(CookieCategory || (exports.CookieCategory = CookieCategory = {}));
/**
 * Cookies named in this list will be ignored by cookie cutter. No TS cookies to be specified in here.
 */
exports.IgnoredCookies = [
    /^ssm_au_[a-z]+$/, // This cookies is used by Zscaler proxy and is needed to allow traffic to TS. See ZTN-3901
];
/**
 * Maps out allowed cookies names in Tradeshift to their respective categories.
 * Value can be either `string` or a `RegExp` for cookie names with dynamic values.
 */
exports.TradeshiftCookieCategorization = {
    [CookieCategory.NECESSARY]: [
        //
        // Set by the Tradeshift Plaftorm
        //
        // Cookie consent storage
        exports.CONSENT_COOKIE_NAME, // created by the Cookie Cutter library to store consent for opted-in categories
        routerUtils_1.PRE_LOGIN_ROUTER_STATE_COOKIE, // saves router state before login
        'wpe-us', // created by the Commercial website (tradeshift.com). Determines if user accepts, limited, rejects whem vising tradeshift.com
        // - Session-related
        'TSAPPID',
        'csrfToken',
        'JSESSIONID',
        'tradeshift-instance-uri',
        'oiosaml-fragment', // SSO login
        // - Punch-out/Punch-in flow — Buy
        'punchoutInfo',
        // - Incapsula DDoS protection, (https://www.imperva.com/)
        /^incap_ses_[\d_]+$/,
        /^nlbi_[\d_]+$/,
        /^visid_incap_[\d_]+$/,
        //
        // Set by marketing website (tradeshift.com)
        //
        'wpe-us', // determines if user has accepted or rejected cookies when vising tradeshift.com
        //
        // Set by support website (support.tradeshift.com)
        //
        // - Session-related
        'ts_prod_support_session',
    ],
    [CookieCategory.PREFERENCES]: [
        //
        // Set by Zendesk embeddable widget:
        //(https://support.zendesk.com/hc/en-us/articles/360022367393-Zendesk-In-Product-Cookie-Policy)
        // Enables live customer service chat via Zendesk
        //
        '__zlcmid',
    ],
    [CookieCategory.STATISTICS]: [
        // Atlassian Jira Servicedesk
        'ajs_anonymous_id',
        'ajs_group_id',
        'ajs_user_id',
        // Google analytics
        /_ga(_\w+)?/,
        '_gat_current',
        '_gid',
        // Delighted software (delighted.com) that collect NPS surveys
        // uses cookie names like `_delighted`, `_delighted_web` and etc.
        /_delighted(_\w)?/,
        //
        // Delighted use Modernizr library
        // (https://github.com/Modernizr/Modernizr) to check if browser support required features, including cookies
        // Thus we need to include cookie name that used in Modernizr while feature check
        'cookietest',
    ],
    [CookieCategory.MARKETING]: [
        // Engagio
        'ei_client_id',
        // Double Click
        '__ar_v4',
        '__gads',
        // Mixpanel
        'mp_mixpanel__c',
        /mp_[\dA-Za-z]+_mixpanel/,
        // Marketo
        '_mkto_trk', // Tracks users to marketing campaigns
        'trwv.uid',
        // Hotjar (https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information)
        '_hjid',
        // Google Analytics — ga.js (https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage)
        '__utma',
        '__utmt',
        '__utmb',
        '__utmz',
        '__utmv',
        '__cf_bm', // marketing,.tradeshift.com
    ],
};
/**
 * Gets the appropriate CookieCategory enum entry for
 * a given input as string
 *
 * @param category desired category (for example: "necessary", "Preferences", etc.)
 */
const getCookieCategory = (category) => {
    const UNKNOWN = 'UNKNOWN';
    if (!category) {
        return UNKNOWN;
    }
    switch (category.trim().toUpperCase()) {
        case CookieCategory.NECESSARY: {
            return CookieCategory.NECESSARY;
        }
        case CookieCategory.PREFERENCES: {
            return CookieCategory.PREFERENCES;
        }
        case CookieCategory.STATISTICS: {
            return CookieCategory.STATISTICS;
        }
        case CookieCategory.MARKETING: {
            return CookieCategory.MARKETING;
        }
        default: {
            return UNKNOWN;
        }
    }
};
exports.getCookieCategory = getCookieCategory;
