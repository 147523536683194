import { padStart, range, toString } from 'lodash';

import { isCardsProviderError } from '../../../../common/lib/errorUtil';
import MONTHS from '../../../config/Months';
import STATES from '../../../config/States';
const YEAR_RANGE = 9;

// TODO rename this to AddCardSourceAsideComponent
// since this is also used in company settings
const AddCardSourceAsideComponent = {
	bindings: {
		isOpen: '<',
		onCardSourceAdded: '&',
		onClose: '&',
		teamId: '<?',
	},
	template: `
		<ts-aside
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.enrollSourceCardHeaderText}}">
			<ts-form
					is-submitting="$ctrl.isSubmitting"
					on-cancel="$ctrl.onClose()"
					on-submit="$ctrl.submit($ctrl.cardSource)"
					submit-label="{{$ctrl.submitButtonText}}">
				<ts-form-fields>
					<fieldset>
						<span ng-bind="$ctrl.cardHolderFieldText"></span>
					</fieldset>
					<div class="subfields">
						<fieldset>
							<label>
								<span ng-bind="$ctrl.firstNameFieldText"></span>
								<input ng-model="$ctrl.cardSource.firstName" required type="text" />
							</label>
							<label>
								<span ng-bind="$ctrl.lastNameFieldText"></span>
								<input ng-model="$ctrl.cardSource.lastName" required type="text" />
							</label>
						</fieldset>
						<ts-input
								label="{{$ctrl.emailFieldText}}"
								ng-model="$ctrl.cardSource.email"
								required
								type="email"></ts-input>
					</div>
					<fieldset>
						<span ng-bind="$ctrl.cardDetailsFieldText"></span>
					</fieldset>
					<div class="subfields">
						<ts-input
								class="tst-cardsource-name"
								label="{{$ctrl.cardNameFiedlText}}"
								ng-model="$ctrl.cardSource.name"
								placeholder="{{$ctrl.cardPlaceHolderText}}"
								required
								type="text"></ts-input>
						<ts-input
								class="tst-cardsource-number"
								label="{{$ctrl.cardNumberFieldText}}"
								ng-model="$ctrl.cardSource.cardNumber"
								placeholder="XXXX XXXXXX XXXXX"
								required
								pattern-restrict="$ctrl.cardNumberPattern"></ts-input>
						<fieldset>
							<label class="tst-cardsource-expirationmonth">
								<span ng-bind="$ctrl.expMonthFieldText"></span>
								<select
										ng-model="$ctrl.cardSource.expirationMonth"
										ng-options="month as month.name for month in $ctrl.options.months"
										placeholder="{{$ctrl.selectMonthPlaceholderText}}"
										required>
									<option></option>
								</select>
							</label>
							<label class="tst-cardsource-expirationyear">
								<span ng-bind="$ctrl.expYearFieldText"></span>
								<select
										ng-model="$ctrl.cardSource.expirationYear"
										ng-options="year for year in $ctrl.options.years"
										placeholder="{{$ctrl.selectYearText}}"
										required>
									<option></option>
								</select>
							</label>
						</fieldset>
					</div>
					<fieldset>
						<span ng-bind="$ctrl.billingAddressFieldText"></span>
					</fieldset>
					<div class="subfields">
						<ts-input
								class="tst-cardsource-address"
								label="{{$ctrl.streetAddressFieldText}}"
								ng-model="$ctrl.cardSource.address"
								required
								type="text"></ts-input>
						<ts-input
								class="tst-cardsource-city"
								label="{{$ctrl.cityFieldText}}"
								ng-model="$ctrl.cardSource.city"
								required
								type="text"></ts-input>
						<fieldset>
							<label class="tst-cardsource-state">
								<span ng-bind="$ctrl.stateFieldText"></span>
								<select
										ng-model="$ctrl.cardSource.regionCode"
										ng-options="state.abbreviation as state.name for state in $ctrl.options.states"
										placeholder="{{$ctrl.selectStatePlaceholderText}}"
										required>
									<option></option>
								</select>
							</label>
							<label>
								<ts-input
										label="{{$ctrl.zipCodeFieldText}}"
										class="tst-cardsource-zipcode"
										ng-model="$ctrl.cardSource.zipCode"
										validate="$ctrl.validateZipcode(value)"></ts-input>
							</label>
						</fieldset>
					</div>
				</ts-form-fields>
			</ts-form>
		</ts-aside>
	`,
	controller: class AddCardSourceController {
		/* @ngInject */
		constructor(constants, EventEmitter, LexicoService, PaymentsService, UserService) {
			this.lexico = LexicoService.getLexico();
			this.enrollSourceCardHeaderText = this.lexico.trc('Component title', 'Enroll source card');
			this.submitButtonText = this.lexico.trc('Button', 'Submit');
			this.cardHolderFieldText = this.lexico.trc('Form field title', 'Card holder');
			this.firstNameFieldText = this.lexico.trc('Form input label', 'First name');
			this.lastNameFieldText = this.lexico.trc('Form input label', 'Last name');
			this.emailFieldText = this.lexico.trc('Input field label', 'Email');
			this.cardDetailsFieldText = this.lexico.trc('Form field title', 'Card details');
			this.cardNameFiedlText = this.lexico.trc('Input field label', 'Card name');
			this.cardPlaceHolderText = this.lexico.trc('Input field placeholder', 'A friendly nickname, e.g. Marketing Card');
			this.cardNumberFieldText = this.lexico.trc('Input field label', 'Card number');
			this.expMonthFieldText = this.lexico.trc('Field input label', 'Expiration Month');
			this.selectMonthPlaceholderText = this.lexico.trc('Placeholder text', 'Select a month');
			this.expYearFieldText = this.lexico.trc('Field input label', 'Expiration Year');
			this.selectYearText = this.lexico.trc('Placeholder text', 'Select a year');
			this.billingAddressFieldText = this.lexico.trc('Form field title', 'Billing address');
			this.streetAddressFieldText = this.lexico.trc('Input field label', 'Street address');
			this.cityFieldText = this.lexico.trc('Input field label', 'City');
			this.stateFieldText = this.lexico.trc('Form input label', 'State');
			this.selectStatePlaceholderText = this.lexico.trc('Placeholder text', 'Select a state');
			this.zipCodeFieldText = this.lexico.trc('Input field label', 'Zip code');

			this.EventEmitter = EventEmitter;
			this.PaymentsService = PaymentsService;
			this.UserService = UserService;
			this.cardNumberPattern = constants.regexPatterns.cardNumber;
		}

		validateZipcode(value) {
			return { valid: !Number.isNaN(Number(value)) && value.length >= 5 };
		}

		buildCardSource(formData, cardSourceInfo) {
			return {
				billingAddress: {
					addressLine1: formData.address,
					city: formData.city,
					countryCode: formData.countryCode,
					region: formData.regionCode,
					zipCode: formData.zipCode,
				},
				id: cardSourceInfo.id,
				label: [cardSourceInfo.name + ',', '····', cardSourceInfo.lastDigits].join(' '),
				lastDigits: cardSourceInfo.lastDigits,
				name: cardSourceInfo.name,
				provider: cardSourceInfo.provider,
				providerLabel: 'American Express',
				status: 'IN_PROGRESS',
			};
		}

		$onChanges(changes) {
			if (changes.isOpen && changes.isOpen.currentValue) {
				this.onOpen();
			}
		}

		formatExpiryDate(month, year) {
			return toString(year) + padStart(toString(month), 2, '0');
		}

		buildPaymentInformation(formData) {
			return {
				address: {
					address: formData.address,
					city: formData.city,
					countryCode: formData.countryCode,
					regionCode: formData.regionCode,
					zipCode: toString(formData.zipCode),
				},
				cardNumber: toString(formData.cardNumber).replace(/\s+/g, ''),
				email: formData.email,
				expiryDate: this.formatExpiryDate(formData.expirationMonth.value, formData.expirationYear),
				firstName: formData.firstName,
				lastName: formData.lastName,
				name: formData.name,
				orgName: formData.orgName,
			};
		}

		async submit(formData) {
			this.isSubmitting = true;
			const options = {
				teamId: this.teamId,
			};
			try {
				const { token } = await this.PaymentsService.getEnrollmentTokenV2(options);
				const enrollment = {
					enrollmentToken: token,
					paymentInformation: this.buildPaymentInformation(formData),
				};

				const cardSourceInfo = await this.PaymentsService.enrollWithAmex(enrollment);
				const cardSource = this.buildCardSource(formData, cardSourceInfo);
				this.onCardSourceAdded(this.EventEmitter({ cardSource }));
				this.onClose();
				const successMessage = this.lexico.trc('Success message', 'Credit card added.');
				ts.ui.Notification.success(successMessage);
			} catch (error) {
				if (isCardsProviderError(error)) {
					const warningMessage = this.lexico.trc(
						'Warning message',
						'Your bank returned an error while adding this card. Please contact your bank to resolve and try again.',
					);
					ts.ui.Notification.warning(warningMessage);
				} else {
					const warningMessage = this.lexico.trc(
						'Warning message',
						'There was a problem adding the credit card. Please try again.',
					);
					ts.ui.Notification.warning(warningMessage);
				}
			} finally {
				this.isSubmitting = false;
			}
		}

		onOpen() {
			const currentYear = new Date().getFullYear();
			this.options = {
				months: MONTHS,
				states: STATES,
				years: range(currentYear, currentYear + YEAR_RANGE),
			};

			this.cardSource = {
				address: '',
				cardNumber: '',
				city: '',
				countryCode: 'US',
				email: this.user.email,
				expirationMonth: '',
				expirationYear: '',
				firstName: this.user.firstName,
				lastName: this.user.lastName,
				name: '',
				orgName: this.user.companyName,
				regionCode: '',
				userId: this.user.userId,
				zipCode: '',
			};
		}

		async $onInit() {
			this.user = await this.UserService.getUser();
		}
	},
};

export default AddCardSourceAsideComponent;
