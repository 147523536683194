"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UI_SIDE_MENU_HEADER_COLLAPSE_BUTTON = exports.UI_SIDE_MENU_HEADER = exports.UI_SIDE_MENU_MOBILE_VIEW_OPEN = exports.UI_SIDE_MENU_OPEN = exports.UI_SIDE_MENU_COLLAPSED = exports.UI_SIDE_MENU = exports.TS_UI_APP = exports.UI_CLASS_PAGE_CARDS_TABLE = exports.UI_SVG_EMPTY_STATE = exports.TS_UI_ICON_LOGOUT = exports.TS_UI_ICON_SETTINGS = exports.TS_UI_ICON_ARROW_RIGHT = exports.TS_UI_ICON_ADD = exports.TS_UI_ICON_SELECT = exports.TS_UI_ICON_REMOVE = exports.TS_UI_BUTTON_NO_BORDER = exports.UI_CLASS_TEAM_APPROVAL_LIMIT = exports.UI_CLASS_TEAM_CARD_SETTINGS = exports.UI_CLASS_TEAM_CARD_OPTIONS = exports.UI_CLASS_TEAM_MEMBER_INFO = exports.UI_CLASS_TEAM_NAME_INPUT = exports.UI_CLASS_PURPLE_NOTE = exports.UI_CLASS_TEAM_OVERVIEW = exports.UI_CLASS_TAG = exports.UI_CLASS_TAG_LIST = exports.UI_CLASS_PAGE_SETTINGS_NO_CODING_PANEL = exports.UI_CLASS_PAGE_SETTINGS_NO_CODING_CONTENT = exports.UI_CLASS_PAGE_SETTINGS_CREATE_NEW_CUSTOM_CODING = exports.UI_CLASS_PAGE_SETTINGS_CREATE_NEW_CODING_MENU = exports.UI_CLASS_PAGE_SETTINGS_CODING_TABLE = exports.UI_CLASS_PAGE_TEAM_TABLE = exports.UI_CLASS_PAGE_SETTINGS_HEADER_BUTTON = exports.UI_CLASS_COMPANY_SETTINGS_DOMAIN_INPUT = exports.UI_CLASS_COMPANY_SETTINGS_DOMAIN = exports.UI_CLASS_PAGE_SETTINGS_OVERVIEW_RIGHT_FEATURES = exports.UI_CLASS_PAGE_SETTINGS_OVERVIEW_LEFT_FEATURES = exports.UI_CLASS_PAGE_SETTINGS_OVERVIEW_HEADER = exports.UI_CLASS_PAGE_SETTINGS_OVERVIEW = exports.UI_CLASS_PAGE_SETTINGS = exports.UI_CLASS_MAIN_CONTENT = exports.UI_CLASS_PAGE_MAIN = exports.UI_TAG_PRIMARY = exports.UI_BUTTON_MICRO = exports.UI_BUTTON_TERNARY = exports.UI_BUTTON_SECONDARY = exports.UI_BUTTON_PRIMARY = exports.UI_CLASS_ERROR = exports.UI_TITLE_LARGE = exports.UI_TITLE_MEDIUM = exports.UI_TITLE_SMALL = void 0;
exports.UI_SIDE_MENU_FOOTER_LOGOUT_BUTTON = exports.UI_SIDE_MENU_FOOTER_SETTINGS_BUTTON = exports.UI_SIDE_MENU_FOOTER_USER_BUTTONS = exports.UI_SIDE_MENU_FOOTER_USER_DETAILS = exports.UI_SIDE_MENU_FOOTER_USER_IMAGE = exports.UI_SIDE_MENU_FOOTER = exports.UI_SIDE_MENU_PANEL_OPTION = exports.UI_SIDE_MENU_PANEL = void 0;
exports.UI_TITLE_SMALL = 'h3';
exports.UI_TITLE_MEDIUM = 'h2';
exports.UI_TITLE_LARGE = 'h1';
exports.UI_CLASS_ERROR = 'ts-errors';
exports.UI_BUTTON_PRIMARY = 'primary';
exports.UI_BUTTON_SECONDARY = 'secondary';
exports.UI_BUTTON_TERNARY = 'ternary';
exports.UI_BUTTON_MICRO = 'small';
exports.UI_TAG_PRIMARY = 'ts-info';
exports.UI_CLASS_PAGE_MAIN = 'page-main';
exports.UI_CLASS_MAIN_CONTENT = 'main-content';
exports.UI_CLASS_PAGE_SETTINGS = 'page-settings';
exports.UI_CLASS_PAGE_SETTINGS_OVERVIEW = 'overview content';
exports.UI_CLASS_PAGE_SETTINGS_OVERVIEW_HEADER = 'settings-header';
exports.UI_CLASS_PAGE_SETTINGS_OVERVIEW_LEFT_FEATURES = 'left-side-features';
exports.UI_CLASS_PAGE_SETTINGS_OVERVIEW_RIGHT_FEATURES = 'right-side-features';
exports.UI_CLASS_COMPANY_SETTINGS_DOMAIN = 'domain';
exports.UI_CLASS_COMPANY_SETTINGS_DOMAIN_INPUT = 'domain-input';
exports.UI_CLASS_PAGE_SETTINGS_HEADER_BUTTON = 'user-report-header';
exports.UI_CLASS_PAGE_TEAM_TABLE = 'table-box teams';
exports.UI_CLASS_PAGE_SETTINGS_CODING_TABLE = 'table-box coding';
exports.UI_CLASS_PAGE_SETTINGS_CREATE_NEW_CODING_MENU = 'default-coding-lists';
exports.UI_CLASS_PAGE_SETTINGS_CREATE_NEW_CUSTOM_CODING = 'custom-list-btn';
exports.UI_CLASS_PAGE_SETTINGS_NO_CODING_CONTENT = 'coding content';
exports.UI_CLASS_PAGE_SETTINGS_NO_CODING_PANEL = 'empty-state';
exports.UI_CLASS_TAG_LIST = 'tags';
exports.UI_CLASS_TAG = 'tag';
exports.UI_CLASS_TEAM_OVERVIEW = 'team-overview';
exports.UI_CLASS_PURPLE_NOTE = 'purple-note';
exports.UI_CLASS_TEAM_NAME_INPUT = 'basics';
exports.UI_CLASS_TEAM_MEMBER_INFO = 'members';
exports.UI_CLASS_TEAM_CARD_OPTIONS = 'card-options';
exports.UI_CLASS_TEAM_CARD_SETTINGS = 'card-types';
exports.UI_CLASS_TEAM_APPROVAL_LIMIT = 'limit';
exports.TS_UI_BUTTON_NO_BORDER = 'ts-noborder';
exports.TS_UI_ICON_REMOVE = 'ts-icon-remove';
exports.TS_UI_ICON_SELECT = 'ts-icon-select';
exports.TS_UI_ICON_ADD = 'ts-icon-more';
exports.TS_UI_ICON_ARROW_RIGHT = 'ts-icon-arrowright';
exports.TS_UI_ICON_SETTINGS = 'ts-icon-settings';
exports.TS_UI_ICON_LOGOUT = 'ts-icon-logout';
exports.UI_SVG_EMPTY_STATE = 'empty-state-illustration';
exports.UI_CLASS_PAGE_CARDS_TABLE = 'cards';
exports.TS_UI_APP = 'ts-ui-app';
exports.UI_SIDE_MENU = 'side-menu';
exports.UI_SIDE_MENU_COLLAPSED = 'side-menu-collapsed';
exports.UI_SIDE_MENU_OPEN = 'side-menu-open';
exports.UI_SIDE_MENU_MOBILE_VIEW_OPEN = 'side-menu-mobile-view-open';
exports.UI_SIDE_MENU_HEADER = 'side-menu-header';
exports.UI_SIDE_MENU_HEADER_COLLAPSE_BUTTON = 'side-menu-header-collapse-button';
exports.UI_SIDE_MENU_PANEL = 'side-menu-panel';
exports.UI_SIDE_MENU_PANEL_OPTION = 'side-menu-panel-option';
exports.UI_SIDE_MENU_FOOTER = 'side-menu-footer';
exports.UI_SIDE_MENU_FOOTER_USER_IMAGE = 'side-menu-footer-user-image';
exports.UI_SIDE_MENU_FOOTER_USER_DETAILS = 'side-menu-footer-user-details';
exports.UI_SIDE_MENU_FOOTER_USER_BUTTONS = 'side-menu-footer-user-buttons';
exports.UI_SIDE_MENU_FOOTER_SETTINGS_BUTTON = 'side-menu-footer-settings-button';
exports.UI_SIDE_MENU_FOOTER_LOGOUT_BUTTON = 'side-menu-footer-logout-button';
