/* @ngInject */
function ApprovalOptionsController($scope, $state, AsideService) {
	const that = this;

	function init() {
		that.approval = $scope.aside.model.approval;
	}

	that.cancel = function () {
		AsideService.closeAll();
	};
	that.viewConversation = function (approval) {
		AsideService.closeAll();
		try {
			const requestId = approval.getRequestId();
			window.localStorage.setItem('conversationId', requestId);
		} catch {
			// ignore
		}
		$state.go('main.approval', {
			approvalId: approval.getId(),
		});
	};

	$scope.$watch('aside.show', function (show) {
		if (show) {
			init();
		}
	});
}

module.exports = ApprovalOptionsController;
