import angular from 'angular';

import FileUploadComponent from './file-upload.component';
import FileUploadService from './file-upload.service';

import './file-upload.less';

export default angular
	.module('go.common.components.file-upload', [])
	.component('goFileUpload', FileUploadComponent)
	.service('fileUploadService', FileUploadService).name;
