"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const zustandStore_1 = require("../../../../zustandStore");
const useAreAllCardFeaturesDisabled = () => {
    const team = (0, zustandStore_1.useZustand)(state => state.team?.team);
    const areAllCardFeaturesDisabled = (0, react_1.useMemo)(() => {
        if (!team || !team.properties) {
            return false;
        }
        const data = Object.entries(team.properties).every(([key, value]) => key === 'REQUEST_LIMIT' || (key !== 'REQUEST_LIMIT' && value === 'false'));
        return data;
    }, [team]);
    return areAllCardFeaturesDisabled;
};
exports.default = useAreAllCardFeaturesDisabled;
