"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const TableSkeletonLoader = ({ rows, columns }) => {
    const renderSkeletonCell = () => ((0, jsx_runtime_1.jsx)(material_1.TableCell, { children: (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "80%" }) }, crypto.randomUUID()));
    const renderSkeletonRow = () => ((0, jsx_runtime_1.jsx)(material_1.TableRow, { children: Array.from({ length: columns }, () => renderSkeletonCell()) }, crypto.randomUUID()));
    return ((0, jsx_runtime_1.jsx)(material_1.Box, { style: { height: '100vh' }, children: (0, jsx_runtime_1.jsx)(material_1.Paper, { elevation: 3, style: { padding: '16px' }, children: (0, jsx_runtime_1.jsxs)(material_1.Table, { children: [(0, jsx_runtime_1.jsx)(material_1.TableHead, { children: (0, jsx_runtime_1.jsx)(material_1.TableRow, { children: Array.from({ length: columns }, () => ((0, jsx_runtime_1.jsx)(material_1.TableCell, { children: (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text" }) }, crypto.randomUUID()))) }, crypto.randomUUID()) }), (0, jsx_runtime_1.jsx)(material_1.TableBody, { children: Array.from({ length: rows }, () => renderSkeletonRow()) })] }) }) }, crypto.randomUUID()));
};
exports.default = TableSkeletonLoader;
