"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stopAngularJsLoader = void 0;
const stopAngularJsLoader = () => {
    if (window.$state?.loadingService) {
        try {
            // we try to hide the angularjs loader but it is not guaranteed that
            // this works since is using html dom
            if (window.$state.loadingService.isLoading()) {
                window.$state.loadingService.stopLoading();
            }
        }
        catch {
            // do nothing
        }
    }
};
exports.stopAngularJsLoader = stopAngularJsLoader;
