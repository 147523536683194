/* @ngInject */
function validateInputController($parse) {
	const getAttrFunction = (scope, attr) => {
		const expressionHandler = $parse(attr);
		return params => expressionHandler(scope, params);
	};

	return {
		restrict: 'A',

		require: 'ngModel',
		// scope = the parent scope
		// elem = the element the directive is on
		// attr = a dictionary of attributes on the element
		// ctrl = the controller for ngModel.
		link: (scope, elem, attrs, ctrl) => {
			if (!attrs.validateInput) {
				return;
			}
			const validate = getAttrFunction(scope, attrs.validateInput);

			// add a parser that will process each time the value is
			// parsed into the model when the user updates it.
			ctrl.$parsers.unshift(value => {
				// test and set the validity after update.
				const valid = validate({ value });
				ctrl.$setValidity('validateInput', valid);

				// if it's valid, return the value to the model,
				// otherwise return undefined.
				return valid ? value : undefined;
			});

			// add a formatter that will process each time the value
			// is updated on the DOM element.
			ctrl.$formatters.unshift(value => {
				// validate.
				const valid = validate({ value });
				ctrl.$setValidity('validateInput', valid);

				// return the value or nothing will be written to the DOM.
				return value;
			});
		},
	};
}

module.exports = validateInputController;
