const SvgViewGrid = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M1 8h7V1H1Zm1.2-5.8h4.6v4.6H2.2ZM1 17h7v-7H1Zm1.2-5.8h4.6v4.6H2.2ZM10 1v7h7V1Zm5.8 5.8h-4.6V2.2h4.6ZM10 17h7v-7h-7Zm1.2-5.8h4.6v4.6h-4.6Z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

export default SvgViewGrid;
