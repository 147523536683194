const _ = require('lodash');

/* @ngInject */
function VirtualCardController($scope, $timeout, $element, UserService) {
	$scope.isCopyAllowed = false;
	$scope.copyItems = {};

	UserService.getUser()
		.then(function (user) {
			$scope.isCardOwner = $scope.card.isUserAssigned(user.userId);
			if ($scope.isCardOwner && _.isNaN(_.toNumber($scope.card.getCardNumber()))) {
				return $scope.card.getVirtualCard();
			}
		})
		.then(function () {
			$scope.copyItems = {
				number: {
					name: 'card number',
					data: $scope.card.getCardNumber(),
				},
				cvc: {
					name: 'cvc',
					data: $scope.card.getCVVNumber(),
				},
			};
			$scope.isCopyAllowed = !_.isNaN(_.toNumber($scope.card.getCardNumber()));
		});

	$scope.copySuccessful = false;

	$scope.onCopySuccess = function (copyItem) {
		$scope.copySuccessMessage = 'Copied ' + copyItem.name + '!';
		$scope.copySuccessful = true;
		$timeout(function () {
			$scope.copySuccessful = false;
		}, 1000);
	};

	$scope.setCopyItem = function (copyItemKey, $event) {
		$event.stopImmediatePropagation();
		if (!$scope.copyItems[copyItemKey]) {
			return;
		}
		$scope.copyItem = $scope.copyItems[copyItemKey];
		$scope.copyMessage = 'Click to copy ' + $scope.copyItem.name;
	};
}

module.exports = VirtualCardController;
