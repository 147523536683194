import _ from 'lodash';
import moment from 'moment';

const OutOfOfficeAsideComponent = {
	bindings: {
		isOpen: '<',
		onClose: '&',
		teamId: '<',
	},
	template: `
		<ts-aside
				is-loading="$ctrl.isLoading"
				is-open="$ctrl.isOpen"
				on-close="$ctrl.onClose()"
				title="{{$ctrl.outOfOfficeTitleText}}">
			<form data-ts="Form">
				<label>
					<span ng-bind="$ctrl.enableOutOfOfficeText"></span>
					<input
							ng-click="$ctrl.toggleOoo($event)"
							ng-disabled="$ctrl.isOooDisabledWithNoAvailableApprovers()"
							ng-model="$ctrl.isOooEnabled"
							type="checkbox">
				</label>
			</form>
			<p
					ng-bind="$ctrl.validationText1"
					ng-if="$ctrl.isOooDisabledWithNoAvailableApprovers()"></p>
			<p
					ng-bind="$ctrl.validationText2"
					ng-if="!$ctrl.isOooDisabledWithNoAvailableApprovers()"></p>
			<ts-form
				ng-if="!$ctrl.isOooDisabledWithNoAvailableApprovers()"
				is-submitting="$ctrl.isSubmitting"
				ng-if="$ctrl.isOooEnabled"
				on-submit="$ctrl.saveOooSettings($ctrl.settings)"
				submit-label="Save">
					<ts-form-fields>
						<ts-datepicker
								ng-change="$ctrl.onStartDateChange()"
								ng-model="$ctrl.settings[$ctrl.OOO_START_DATE]"
								min="$ctrl.minStartDate"
								label="{{$ctrl.forwardStartDateText}}"
								is-required="true"></ts-datepicker>
						<ts-datepicker
								min="$ctrl.minEndDate"
								ng-model="$ctrl.settings[$ctrl.OOO_END_DATE]"
								label="{{$ctrl.endForwardDateText}}"
								is-required="true"></ts-datepicker>
						<ts-select
								label="{{$ctrl.approverFormText}}"
								ng-model="$ctrl.settings[$ctrl.OOO_FORWARD_TO]"
								is-required="true"
								options="$ctrl.approvers"></ts-select>
					</ts-form-fields>
			</ts-form>
		</ts-aside>
	`,
	controller: /* @ngInject */ function ($rootScope, LexicoService, PaymentsService, TeamsService, constants) {
		const DATE_PICKER_FORMAT = constants.datePickerFormat;
		const OOO_CHANGE_EVENT = constants.approvals.oooChangeEvent;

		const OOO_ENABLED = constants.userTeamProperties.outOfOffice.oooEnabled;
		const OOO_END_DATE = (this.OOO_END_DATE = constants.userTeamProperties.outOfOffice.oooEndDate);
		const OOO_FORWARD_TO = (this.OOO_FORWARD_TO = constants.userTeamProperties.outOfOffice.oooForwardTo);
		const OOO_FORWARD_TO_EMAIL = constants.userTeamProperties.outOfOffice.oooForwardToEmail;
		const OOO_FORWARD_TO_FULL_NAME = constants.userTeamProperties.outOfOffice.oooForwardToFullName;
		const OOO_START_DATE = (this.OOO_START_DATE = constants.userTeamProperties.outOfOffice.oooStartDate);

		this.minStartDate = moment().format(DATE_PICKER_FORMAT);
		this.minEndDate = moment().add(1, 'days').format(DATE_PICKER_FORMAT);
		this.moment = moment;
		this.isSubmitting = false;
		this.lexico = LexicoService.getLexico();
		this.outOfOfficeTitleText = this.lexico.trc('Component title', 'Out of office');
		this.enableOutOfOfficeText = this.lexico.trc('Form input label', 'Enable out of office');
		this.validationText1 = this.lexico.trc(
			'Validation message',
			'At least one manager or team owner must be available to use out of office.',
		);
		this.validationText2 = this.lexico.trc(
			'Validation message',
			'New requests will be automatically forwarded during this time frame.',
		);
		this.forwardStartDateText = this.lexico.trc('Form input label', 'Start forwarding on');
		this.endForwardDateText = this.lexico.trc('Form input label', 'End forwarding on');
		this.approverFormText = this.lexico.trc('Form input label', 'Approver');

		const getDefaultSettingsModel = () => ({
			[OOO_END_DATE]: '',
			[OOO_START_DATE]: '',
			[OOO_FORWARD_TO_EMAIL]: '',
			[OOO_FORWARD_TO_FULL_NAME]: '',
			[OOO_FORWARD_TO]: '',
			[OOO_ENABLED]: false,
		});

		this.settings = getDefaultSettingsModel();

		this.$onChanges = changes => {
			if (changes.isOpen && changes.isOpen.currentValue) {
				onOpen();
			}
		};

		this.isOooDisabledWithNoAvailableApprovers = () => {
			return !this.isOooEnabled && this.approvers && this.approvers.length === 0;
		};

		const onOpen = async () => {
			this.isLoading = true;
			try {
				const [approvers, settings] = await Promise.all([
					PaymentsService.getTeamApprovers({
						teamId: this.teamId,
						excludeOutOfOffice: true,
						excludeSelf: true,
					}),
					TeamsService.getOooSettings({ teamId: this.teamId }),
				]);
				this.isOooEnabled = settings[OOO_ENABLED] === 'true';
				this.approvers = approvers.map(approver =>
					Object.assign({}, approver, {
						id: approver.userId,
						label: approver.fullName,
					}),
				);
				const oooStartDate = settings[OOO_START_DATE];
				const oooEndDate = settings[OOO_END_DATE];
				this.settings = Object.assign({}, settings, {
					[OOO_ENABLED]: settings[OOO_ENABLED] === 'true',
					[OOO_FORWARD_TO]: this.approvers.find(approver => approver.userId === settings[OOO_FORWARD_TO]),
					[OOO_START_DATE]: _.isEmpty(oooStartDate) ? '' : moment(oooStartDate).format(DATE_PICKER_FORMAT),
					[OOO_END_DATE]: _.isEmpty(oooEndDate) ? '' : moment(oooEndDate).format(DATE_PICKER_FORMAT),
				});
			} catch (error) {
				const warningMessage = this.lexico.trc(
					'Warning message',
					'There was a problem loading your settings. Please try again.',
				);
				ts.ui.Notification.warning(warningMessage);
				this.onClose();
			} finally {
				this.isLoading = false;
			}
		};

		this.onStartDateChange = () => {
			this.minEndDate = this.moment(this.settings[OOO_START_DATE]).add(1, 'days').format(DATE_PICKER_FORMAT);
		};

		this.saveOooSettings = async (updatedSettings, closeOnSave = true) => {
			this.isSubmitting = true;
			const oooStartDate = updatedSettings[OOO_START_DATE];
			const oooEndDate = updatedSettings[OOO_END_DATE];
			const settings = Object.assign({}, updatedSettings, {
				[OOO_START_DATE]: _.isEmpty(oooStartDate) ? '' : moment(oooStartDate).toISOString(),
				[OOO_END_DATE]: _.isEmpty(oooEndDate) ? '' : moment(oooEndDate).toISOString(),
			});
			if (_.isObject(settings[OOO_FORWARD_TO]) === true) {
				// This is required by the approves controller to prevent resolving the id
				// into a username on broadcasting this save
				Object.assign(settings, {
					[OOO_FORWARD_TO_FULL_NAME]: settings[OOO_FORWARD_TO].fullName,
					[OOO_FORWARD_TO_EMAIL]: settings[OOO_FORWARD_TO].email,
					[OOO_FORWARD_TO]: settings[OOO_FORWARD_TO].userId,
				});
			}
			try {
				await TeamsService.setOooSettings({
					settings,
					teamId: this.teamId,
				});
				$rootScope.$broadcast(OOO_CHANGE_EVENT, settings);
				const successMessage = this.lexico.trc('Success message', 'Your out of office settings have been saved.');
				ts.ui.Notification.success(successMessage);
				if (closeOnSave) {
					this.onClose();
				}
			} catch (error) {
				const warningMessage = this.lexico.trc(
					'Warning message',
					'There was a problem saving your out of office settings. Please try again.',
				);
				ts.ui.Notification.warning(warningMessage);
			} finally {
				this.isSubmitting = false;
			}
		};

		this.toggleOoo = event => {
			if (!event.target.checked) {
				this.settings = getDefaultSettingsModel();
				this.saveOooSettings(this.settings, false);
			} else {
				this.settings[OOO_ENABLED] = event.target.checked;
			}
		};
	},
};

export default OutOfOfficeAsideComponent;
