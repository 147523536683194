const angular = require('angular');

/* @ngInject */
function routing($stateProvider) {
	$stateProvider.state('main.support', {
		// controller: 'SupportController',
		// template: require('./support.html'),
		// resolve: SupportController.resolve,
		url: 'support',
	});
}

module.exports = angular
	.module('go.support', [])
	// .controller('SupportController', SupportController)
	// .directive('iframeOnload', require('./iframe-onload.directive'))
	// .service('SupportService', require('./support.service'))
	.config(routing).name;
