const TagComponent = {
	bindings: {
		disableRemove: '<?',
		label: '<',
		onRemove: '&?',
	},
	template: `
		<div
				class="tag"
				title="{{$ctrl.label}}">
			<span ng-bind="$ctrl.label"></span>
			<i
					class="ts-icon-close"
					ng-click="$ctrl.onRemove()"
					ng-if="!$ctrl.disableRemove"></i>
		</div>
	`,
};

export default TagComponent;
