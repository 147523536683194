const _ = require('lodash');
const moment = require('moment');
const timeHelper = require('../helpers/timeHelper');

/* @ngInject */
function VirtualCardModel($q, constants, Card, ConfigService, PaymentsService) {
	const AUTH_PADDING_PERCENTAGE = ConfigService.get('AUTH_PADDING_PERCENTAGE');

	function VirtualCard(virtualCard) {
		Card.call(this, virtualCard);
		this._isVirtual = true;
		const { card: cardInfo } = virtualCard;
		if (cardInfo) {
			this._setVirtualCardInfo(cardInfo);
		}
	}
	VirtualCard.prototype = Object.create(Card.prototype);
	VirtualCard.prototype.constructor = VirtualCard;

	VirtualCard.prototype._setBalance = function (balance) {
		this._card.balance = balance;
	};

	VirtualCard.prototype._setValidEndingOn = function (validEndingOn) {
		this._card.validEndingOn = validEndingOn;
	};

	// value is the card amount including auth padding
	VirtualCard.prototype._setValue = function (value) {
		this._card.value = value;
	};

	VirtualCard.prototype._getEndOfValidityPeriod = function () {
		if (this._card.validEndingOn) {
			const parts = this._card.validEndingOn.split('-');
			// JS expects date month where January = 0, so need to subtract 1 from month in date string
			return new Date(parts[0], Number(parts[1]) - 1, parts[2]);
		}
		if (this._card.expirationDate) {
			// using expiration date for end of validity period
			return new Date(this._card.expirationDate.slice(0, 4), this._card.expirationDate.slice(4, 6));
		}
		// TODO: if neither case above is true then return undefined, which should be refactored with card models PR
	};

	VirtualCard.prototype._setStatus = function (status) {
		if (status) {
			this._card.status = status;
		}
	};

	VirtualCard.prototype._setCardType = function (type) {
		const cardType = ConfigService.get('CARD_TYPES').find(({ cardsKey }) => cardsKey === type);
		this._card.cardType = _.get(cardType, 'key');
	};

	VirtualCard.prototype._setFrequency = function (frequency) {
		this._card.frequency = frequency || '';
	};

	VirtualCard.prototype._setDaysRemaining = function () {
		const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
		const endOfValidityPeriod = this._getEndOfValidityPeriod();
		if (!endOfValidityPeriod) {
			return;
		}
		const timeRemaining = endOfValidityPeriod.getTime() - Date.now();
		this._card.daysRemaining = Math.floor(timeRemaining / DAY_IN_MILLISECONDS);
	};

	VirtualCard.prototype.getSpentAmount = function () {
		const fieldAuthPadding = parseInt(this._request.getAuthPaddingPercentage());
		const authPaddingPercentage = isNaN(fieldAuthPadding) ? AUTH_PADDING_PERCENTAGE : fieldAuthPadding;
		if (typeof this._card.balance !== 'number') {
			return;
		}

		return (this.getAmount() * (100 + authPaddingPercentage)) / 100 - this._card.balance;
	};

	VirtualCard.prototype._setSourceCardId = function (id) {
		this._card._sourceCardId = id || '';
	};

	VirtualCard.prototype.getDaysRemaining = function () {
		return this._card.daysRemaining;
	};

	VirtualCard.prototype.getCardDataForExtension = function () {
		return {
			addressLine1: this.getAddressLine1(),
			cardHolder: this.getRequestedBy(),
			cardNumber: this.getCardNumber(),
			city: this.getCity(),
			cvv: this.getCVVNumber(),
			country: this.getCountryCode(),
			expiration: this.getFormattedExpiration(),
			region: this.getRegion(),
			zipCode: this.getZipCode(),
		};
	};

	VirtualCard.prototype.getExpirationCountdown = function () {
		let countdown;
		const daysRemaining = this.getDaysRemaining();
		if (daysRemaining > 1 && daysRemaining < 14) {
			countdown = daysRemaining + ' days left';
		} else if (daysRemaining === 1) {
			countdown = 'Expires tomorrow';
		} else if (daysRemaining === 0) {
			countdown = 'Expires today';
		} else if (daysRemaining < 0) {
			countdown = 'Expired';
		} else {
			countdown = '';
		}
		return countdown;
	};

	VirtualCard.prototype.getStatusLabel = function () {
		if (this.isExpired()) {
			return 'Expired';
		} else if (this.isCanceled()) {
			return 'Canceled';
		} else {
			return 'Active';
		}
	};

	VirtualCard.prototype.getFilterStatus = function () {
		if (
			this.getStatus() === constants.virtualCardStatus.active ||
			this.getStatus() === constants.virtualCardStatus.inProgress
		) {
			return 'Active';
		} else {
			return 'Inactive';
		}
	};

	VirtualCard.prototype.getSourceCardId = function () {
		return this._card._sourceCardId;
	};

	VirtualCard.prototype.getStatus = function () {
		return this._card.status;
	};

	VirtualCard.prototype.setStatus = function (status) {
		this._card.status = status;
	};

	VirtualCard.prototype.getVirtualCardType = function () {
		return this._card.cardType;
	};

	VirtualCard.prototype.getVirtualCardTypeLabel = function () {
		const cardType = this._card.cardType;
		let cardTypeLabel = ConfigService.get('CARD_TYPES').find(({ key }) => key === cardType);
		if (cardTypeLabel) {
			cardTypeLabel = cardTypeLabel.label;
		}
		if (cardType === 'recurring-use') {
			// Just in case there is no default frequency state to key into we add it here
			const frequencyLabel = this._card.frequency.charAt(0).toUpperCase() + this._card.frequency.slice(1).toLowerCase();
			return frequencyLabel + ' ' + cardTypeLabel;
		} else {
			return cardTypeLabel;
		}
	};

	VirtualCard.prototype.getVirtualCardStatusAndLabel = function () {
		const statusLabel = this.isExpired() || this.isCanceled() ? `${this.getStatusLabel()} - ` : '';
		return statusLabel + this.getVirtualCardTypeLabel();
	};

	VirtualCard.prototype.getVirtualCardLabelAndRenewal = function () {
		const standardLabel = this.getVirtualCardTypeLabel();
		if (this._card.cardType !== 'recurring-use') {
			return standardLabel;
		} else {
			return standardLabel + ' &bullet; Renews on ' + this.getVirtualCardRenewal();
		}
	};

	VirtualCard.prototype.getVirtualCardRenewal = function () {
		const issueDate = moment(this.getIssueDate());
		const frequency = constants.frequencies[this._card.frequency];
		const today = timeHelper.getCurrentMoment();

		// Get current difference since last replenishment period
		const differenceByFreq = today.diff(issueDate, frequency.unit) % frequency.timePeriodDifference;
		// Get remaining difference until next replenishment period
		const differenceRemaining = frequency.timePeriodDifference - differenceByFreq;
		// Get total difference to add to current date to display next replenish date
		const difference = today.diff(issueDate, frequency.unit) + differenceRemaining;
		return issueDate.add(difference, frequency.unit).format('MMM D, YYYY');
	};

	VirtualCard.prototype.getVirtualCardInfo = async function () {
		const cardInfo = await PaymentsService.getVirtualCardInfo({ documentId: this.getRequestId() });
		this._setVirtualCardInfo(cardInfo);
	};

	VirtualCard.prototype.getVirtualCard = function () {
		const that = this;
		return PaymentsService.getVirtualCardToken({
			documentId: this.getRequestId(),
		})
			.then(function ({ token: virtualCardToken }) {
				return PaymentsService.exchangeTokenForVirtualCard({
					virtualCardToken,
				});
			})
			.then(function (card) {
				that._setBalance(card.info.balance);
				that._setBillingAddress(card.info.billingAddress);
				that._setCVVNumber(card.cvv);
				that._setCardId(card.info.id);
				that._setCardNumber(card.number);
				that._setCardType(card.info.cardType);
				that._setDaysRemaining();
				that._setExpirationDate(card.expiry);
				that._setProvider(card.info.provider);
				that._setStatus(card.info.status);
				that._setValidEndingOn(card.info.validEndingOn); // format is YYYY-MM-DD
				that._setValue(card.info.value);
				that._setFrequency(card.info.frequency);
			})
			.catch(function (error) {
				if (_.get(error, 'data.code') === constants.virtualCards.AMEX.errorCodes.missingVCardDetails) {
					that.getVirtualCardInfo();
				} else {
					return $q.reject(error);
				}
			});
	};

	/*
		SVB gives card status as CANCELED

		Amex sends canceled cards with status of EXPIRED.
		so we are calculating their canceled cards
		by assuming they will still have days remaining, but will have a EXPIRED status.
	*/
	VirtualCard.prototype.isCanceled = function () {
		if (
			this.getStatus() === 'CANCELED' ||
			this.getStatus() === 'UNAVAILABLE' ||
			this.getStatus() === 'CREATION_ERROR' ||
			this.getStatus() === 'UNKNOWN'
		) {
			return true;
		}
		return !this.isExpired() && this.getStatus() === 'EXPIRED';
	};

	VirtualCard.prototype.isExpired = function () {
		return this.getDaysRemaining() < 0;
	};

	VirtualCard.prototype.isActive = function () {
		return !this.isExpired() && !this.isCanceled();
	};

	VirtualCard.prototype._setVirtualCardInfo = function (cardInfo) {
		this._setBalance(_.get(cardInfo, 'balance'));
		this._setBillingAddress(_.get(cardInfo, 'billingAddress'));
		this._setCardId(_.get(cardInfo, 'id'));
		this._setLastDigits(_.get(cardInfo, 'lastDigits'));
		this._setCardType(_.get(cardInfo, 'cardType'));
		this._setDaysRemaining();
		this._setExpirationDate(_.get(cardInfo, 'expiry'));
		this._setProvider(_.get(cardInfo, 'provider'));
		this._setStatus(_.get(cardInfo, 'status'));
		this._setValidEndingOn(_.get(cardInfo, 'validEndingOn')); // format is YYYY-MM-DD
		this._setValue(_.get(cardInfo, 'value'));
		this._setFrequency(_.get(cardInfo, 'frequency'));
		this._setSourceCardId(_.get(cardInfo, 'sourceId'));
	};

	return VirtualCard;
}

module.exports = VirtualCardModel;
