const _ = require('lodash');

/* @ngInject */
function RequestCardController($scope, $window) {
	$scope.download = function (url) {
		$window.open(url);
	};

	// needed to show the correct template for when there are requests with different profiles
	$scope.$watch('request.getProfileId()', function () {
		$scope.template = _.kebabCase($scope.request.getProfileId());
	});
}

module.exports = RequestCardController;
