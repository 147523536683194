"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.providerMappingInitialState = void 0;
exports.providerMappingSlice = providerMappingSlice;
const appConstants_1 = require("../../constants/appConstants");
const PROVIDER_MAPPING_OPTIONS = appConstants_1.codingListConstants.providerMappingOptions;
exports.providerMappingInitialState = {
    providerMapping: {
        options: PROVIDER_MAPPING_OPTIONS.map(value => ({
            id: value.toLowerCase().replace(' ', ''),
            label: value,
        })),
        currentSelectedProviderMapOption: {
            id: appConstants_1.codingListConstants.providerMappingNoneOption || '', // !! la GET NU AFISEZ CORESPONDING MAPPING
            label: 'Not Mapped',
        },
    },
};
function providerMappingSlice(set) {
    return {
        ...exports.providerMappingInitialState,
        setCurrentSelectedProviderMapOption(currentSelectedProviderMapOption) {
            set((state) => {
                state.providerMapping.currentSelectedProviderMapOption = currentSelectedProviderMapOption;
                return state;
            }, false, '[setCurrentSelectedProviderMapOption]');
        },
    };
}
