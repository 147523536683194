function userImage() {
	return {
		restrict: 'E',
		scope: {
			size: '=',
			user: '=',
		},
		template: require('./userImage.html'),
		link: function ($scope, $element) {
			const image = $element.children('.avatar');
			const imageSize = parseInt($scope.size, 10);

			function getSquare(src, size) {
				if (!src || !size) {
					return;
				}
				const filename = src.split('/').pop();
				return src.replace(/upload\/.*/, 'upload/w_' + size + ',h_' + size + ',c_fill/' + filename);
			}

			image.attr({
				alt: $scope.user.fullName,
				height: imageSize,
				src: getSquare($scope.user.avatarUrl, imageSize),
				width: imageSize,
			});
		},
	};
}

module.exports = userImage;
