module.exports = {
	errorCodeDomains: ['AMEX', 'HSBC', 'SVB'],
	cardSource: {
		status: {
			active: 'ACTIVE',
			deleted: 'DELETED',
			inProgress: 'IN_PROGRESS',
			inactive: 'INACTIVE',
			invalid: 'INVALID',
		},
	},
};
