"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTeamState = void 0;
const zustandStore_1 = require("../../../../../zustandStore");
const useTeamState = () => {
    const { setIsTeamModalOpen, setIsTeamSubmitting, setIsTeamAsideOpen, setTeam, setTeamId, setFooterButtons, setTeamName, setTeamRequestLimit, setUserSelectedTeam, setStep, setNotification, setIsInvitePeopleAsideOpen, setCurrentTeamNameOnSubmit, invitePeopleReset, setIsSaveBtnDisabled, setIsAddPeopleAsideOpen, setPageLoading, isTeamSettingsModalOpen, isTeamAsideOpen, step, teamId, areAllCardFeaturesDisabled, cardOptions, isCardCreationPaused, company, team, isTeamNameError, isTeamRequestLimitError, currentTeamName, teamName, setHasChanges, } = (0, zustandStore_1.useZustand)(({ setIsTeamModalOpen, setIsTeamSubmitting, setIsTeamAsideOpen, setTeam, setTeamId, setFooterButtons, setTeamName, setTeamRequestLimit, setUserSelectedTeam, setStep, setNotification, setIsInvitePeopleAsideOpen, setIsAddPeopleAsideOpen, setCurrentTeamNameOnSubmit, invitePeopleReset, setIsSaveBtnDisabled, setPageLoading, setHasChanges, modal: { team: { isOpen: isTeamSettingsModalOpen, step }, }, aside: { teamAside: { isOpen: isTeamAsideOpen }, addCardSource: { currentTeamName }, }, team: { teamId, teamProperty: isCardCreationPaused, team, isTeamNameError, isTeamRequestLimitError, teamName }, teamCard: { areAllCardFeaturesDisabled, cardSourceOptions: cardOptions }, company, }) => ({
        setIsTeamModalOpen,
        setIsTeamSubmitting,
        setIsTeamAsideOpen,
        setTeam,
        setTeamId,
        setFooterButtons,
        setTeamName,
        setTeamRequestLimit,
        setUserSelectedTeam,
        setStep,
        setNotification,
        setIsInvitePeopleAsideOpen,
        setCurrentTeamNameOnSubmit,
        invitePeopleReset,
        setIsSaveBtnDisabled,
        setIsAddPeopleAsideOpen,
        setPageLoading,
        isTeamSettingsModalOpen,
        isTeamAsideOpen,
        step,
        teamId,
        areAllCardFeaturesDisabled,
        cardOptions,
        isCardCreationPaused,
        company,
        team,
        isTeamNameError,
        isTeamRequestLimitError,
        currentTeamName,
        teamName,
        setHasChanges,
    }));
    return {
        setIsTeamModalOpen,
        setIsTeamSubmitting,
        setIsTeamAsideOpen,
        setTeam,
        setTeamId,
        setFooterButtons,
        setTeamName,
        setTeamRequestLimit,
        setUserSelectedTeam,
        setStep,
        setNotification,
        setIsInvitePeopleAsideOpen,
        setCurrentTeamNameOnSubmit,
        invitePeopleReset,
        setIsSaveBtnDisabled,
        setIsAddPeopleAsideOpen,
        setPageLoading,
        isTeamSettingsModalOpen,
        isTeamAsideOpen,
        step,
        teamId,
        areAllCardFeaturesDisabled,
        cardOptions,
        isCardCreationPaused,
        company,
        team,
        isTeamNameError,
        isTeamRequestLimitError,
        currentTeamName,
        teamName,
        setHasChanges,
    };
};
exports.useTeamState = useTeamState;
