"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const FormSkeletonLoader = ({ count = 1 }) => {
    const renderSkeletonInput = (width = '100%') => ((0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "rectangular", width: width, height: 30, style: { borderRadius: 4, marginBottom: '8px' } }));
    const renderSkeletonLabel = () => (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "30%", height: 30 });
    const renderFormSkeleton = () => ((0, jsx_runtime_1.jsxs)(material_1.Box, { style: { margin: '8px', padding: '16px', width: '100%' }, children: [renderSkeletonLabel(), renderSkeletonInput(), renderSkeletonLabel(), renderSkeletonInput(), renderSkeletonLabel(), renderSkeletonInput(), renderSkeletonLabel(), renderSkeletonInput(), renderSkeletonLabel(), renderSkeletonInput('50%'), renderSkeletonInput('50%'), (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "rectangular", height: 36, style: { borderRadius: 4, marginTop: '16px' } })] }, crypto.randomUUID()));
    return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, spacing: 2, width: '360px', maxWidth: '100%', children: Array.from({ length: count }, renderFormSkeleton) }));
};
exports.default = FormSkeletonLoader;
