"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customGoTheme = exports.goTheme = void 0;
const material_1 = require("@mui/material");
const utils_1 = require("@mui/utils");
const tradeshift_mui_1 = require("@tradeshift/tradeshift-mui");
const components_1 = require("./components");
const tsGoThemeVars_1 = require("./tsGoThemeVars");
const defaultMobileTSUIbreakpoint = 650; // this is the switch from desktop to mobile on angularjs
const zIndex = {
    // this commit  https://github.com/Tradeshift/tradeshift-mui/commit/0cd96e3328feebb8f96f45f553a1c15b60480d5a
    // introduces a breaking change and we can not see the select option anymore
    // here we reset the drawer component z-index to the library default value
    drawer: 1200,
};
exports.goTheme = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: defaultMobileTSUIbreakpoint + 1,
            lg: 1200,
            xl: 1536,
        },
    },
    components: components_1.tsGoComponents,
    palette: tsGoThemeVars_1.goPalette,
    typography: tsGoThemeVars_1.tsGoTypography,
    tsGoUnit: tsGoThemeVars_1.tsGoUnit,
    zIndex,
};
exports.customGoTheme = (0, material_1.createTheme)((0, utils_1.deepmerge)(tradeshift_mui_1.themeConfig, exports.goTheme));
