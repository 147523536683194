"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledMessage = void 0;
const material_1 = require("@mui/material");
exports.StyledMessage = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    position: 'relative',
    textAlign: 'left',
    zIndex: '1',
    display: 'inline-block',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: `0 ${theme?.tsGoUnit.tsUnit} ${theme?.tsGoUnit.tsUnit}`,
    color: theme?.palette.tsGoColor.black,
}));
