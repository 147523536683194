const angular = require('angular');
const ErrorController = require('./error.controller');

/* @ngInject */
function routing($stateProvider) {
	$stateProvider.state('main.error', {
		controller: 'ErrorController',
		params: { error: null },
		resolve: {
			errorPathCheck: () => {
				if (window.location.pathname.includes('signin')) {
					return null;
				} else {
					return true;
				}
			},
			...ErrorController.resolve,
		},
		template: require('./error.html'),
		url: 'error',
	});
}

module.exports = angular.module('go.error', []).controller('ErrorController', ErrorController).config(routing).name;
