const SearchComponent = {
	bindings: {
		onSearch: '&',
		placeholder: '@',
	},
	template: `
		<form data-ts="Form">
			<fieldset>
				<label>
					<input
							data-ts.icon="ts-icon-search"
							type="text"
							ng-change="$ctrl.onChange({query: $ctrl.searchValue})"
							ng-model="$ctrl.searchValue"
							ng-model-options="{ debounce: 300 }"
							placeholder="{{$ctrl.placeholder}}">
				</label>
			</fieldset>
		</form>`,
	controller: class SearchController {
		/* @ngInject */
		constructor($element, $timeout, EventEmitter) {
			this.$element = $element;
			this.$timeout = $timeout;
			this.EventEmitter = EventEmitter;
		}

		onChange({ query }) {
			this.$timeout(() => this.onSearch(this.EventEmitter({ query })));
		}
	},
};

export default SearchComponent;
