const _ = require('lodash');

/*
the one and only request object:
{
	attachments: Array,
	businessId: String,
	coding: Object,
	currency: String,
	fields: Object,
	isArchived: Boolean,
	lastEdit: Date,
	profileId: String,
	requester: {
		email: String,
		firstName: String,
		lastName: String,
		properties: Object,
	},
	requestId: UUID,
	state: String,

	// found in fields object
	amount: Number,
	title: String,

	// added in client
	events: Array,
	newMessageCount: Number,
}
*/

/* @ngInject */
function RequestModel($q, ConfigService, constants, NameService, RequestService) {
	const ARCHIVE_REQUEST_TAG = constants.request.tags.archive;
	const CARD_TYPES = ConfigService.get('CARD_TYPES');
	const MENU_ICONS = constants.request.menuIcons;
	const REQUEST_APPROVED = constants.request.states.approved;
	const REQUEST_CREATED = constants.request.states.created;
	const REQUEST_DELETED = constants.request.states.deleted;
	const REQUEST_NEW = constants.request.states.new;
	const REQUEST_REJECTED = constants.request.states.rejected;
	const REQUEST_STATUSES = constants.request.status;
	const REQUEST_SUBMITTED = constants.request.states.submitted;

	function map(request) {
		// TODO: should be able to remove this mapping and set defaults instead
		return {
			amount: request.amount,
			attachments: request.attachments,
			authPaddingPercentage: request.authPaddingPercentage,
			businessId: request.businessId,
			cardType: request.cardType,
			coding: buildCoding(request.coding),
			currency: request.currency,
			fields: request.fields,
			isArchived: request.isArchived,
			lastEdit: request.lastEdit,
			profileId: request.profileId,
			requester: {
				email: request.requester.email,
				firstName: request.requester.firstName,
				lastName: request.requester.lastName,
				properties: request.requester.properties,
				teamApproverEmail: request.requester.teamApproverEmail,
				teamProperties: request.requester.teamProperties,
			},
			requestId: request.requestId,
			state: request.state,
			teamId: request.teamId,
			title: request.title,
			virtualCardId: request.virtualCardId,
		};
	}

	function buildCoding(coding) {
		return _.chain(coding)
			.map(function (code, key) {
				return {
					listId: key,
					listLabel: code.listLabel,
					entryId: code.ID,
					entryLabel: code.path,
				};
			})
			.keyBy('listId')
			.value();
	}

	function Request(request) {
		this._request = map(request);
		this._conversation = {
			events: [],
			newMessageCount: 0,
		};
	}

	Request.prototype.getAmount = function () {
		return this._request.amount;
	};

	Request.prototype.getAuthPaddingPercentage = function () {
		return this._request.authPaddingPercentage;
	};

	Request.prototype.getApprover = function () {
		if (this._request.teamId) {
			return _.get(this._request, 'requester.teamApproverEmail', '');
		} else {
			return _.get(this._request, 'requester.properties.manager', '');
		}
	};
	Request.prototype.getAttachmentName = function () {
		return this.hasAttachment() ? _.head(this._request.attachments).name : 'Attachment';
	};
	Request.prototype.getAttachmentUrl = function () {
		if (!this.hasAttachment()) {
			return;
		}
		return [
			'/rest/requests',
			this.getId(),
			'attachments',
			encodeURIComponent(_.head(this._request.attachments).name),
		].join('/');
	};
	Request.prototype.getCardTypeKey = function () {
		const cardType = _.find(CARD_TYPES, { key: this._request.cardType });
		return cardType ? cardType.key : 'multiple-use';
	};
	Request.prototype.getCardTypeLabel = function () {
		const cardType = _.find(CARD_TYPES, { key: this._request.cardType });
		return cardType ? cardType.label : 'Multiple Use';
	};
	Request.prototype.getCoding = function () {
		return this._request.coding;
	};
	Request.prototype.getCurrency = function () {
		return this._request.currency;
	};
	Request.prototype.getFields = function () {
		return this._request.fields;
	};
	Request.prototype.getLastEdit = function () {
		return this._request.lastEdit;
	};
	// TODO: rename to getRequestId
	Request.prototype.getId = function () {
		return this._request.requestId || REQUEST_NEW;
	};
	Request.prototype.getProfileId = function () {
		return this._request.profileId;
	};
	Request.prototype.getRequester = function () {
		return this._request.requester;
	};
	Request.prototype.getRequesterName = function () {
		return NameService.fullName(this._request.requester);
	};
	Request.prototype.getState = function () {
		return _.toLower(this._request.state);
	};
	Request.prototype.getStateEnum = function () {
		return _.get(constants, 'request.states.sequenceNumber.' + this.getState());
	};
	Request.prototype.getTeamId = function () {
		return this._request.teamId;
	};
	Request.prototype.getTitle = function () {
		if (this.isNew() || this.isCreated()) {
			return 'New request';
		}
		return this._request.title;
	};
	Request.prototype.getVirtualCardId = function () {
		return this._request.virtualCardId;
	};
	Request.prototype.hasAttachment = function () {
		return !!_.head(this._request.attachments);
	};
	Request.prototype.isApproved = function () {
		return this.getState() === _.toLower(REQUEST_APPROVED);
	};
	Request.prototype.isArchived = function () {
		return this._request.isArchived;
	};
	Request.prototype.isCreated = function () {
		return this.getState() === _.toLower(REQUEST_CREATED);
	};
	Request.prototype.isNew = function () {
		return this.getState() === REQUEST_NEW;
	};
	Request.prototype.isRejected = function () {
		return this.getState() === _.toLower(REQUEST_REJECTED);
	};
	Request.prototype.isSubmitted = function () {
		return this.getState() === _.toLower(REQUEST_SUBMITTED);
	};
	Request.prototype.isUserRequester = function (email) {
		return this._request.requester.email === email;
	};
	Request.prototype.setCardType = function (cardType) {
		this._request.cardType = cardType;
	};
	Request.prototype.setFields = function (fields) {
		this._request.fields = fields;
	};
	Request.prototype.setProfileId = function (profileId) {
		this._request.profileId = profileId;
	};
	Request.prototype.setState = function (state) {
		this._request.state = state;
	};
	Request.prototype.setTitle = function (title) {
		this._request.title = title;
	};
	Request.prototype.update = function (newRequest) {
		const newRequestState = _.toLower(newRequest.state) || REQUEST_NEW;
		if (this.getStateEnum() <= _.get(constants, 'request.states.sequenceNumber.' + newRequestState)) {
			_.assign(this._request, map(newRequest), {});
		} else {
			_.assign(this._request, map(newRequest), {
				state: this._request.state,
			});
		}
	};

	// for interface
	Request.prototype.getMenuIcon = function () {
		return _.get(MENU_ICONS, _.toLower(this._request.state), MENU_ICONS.default);
	};
	Request.prototype.getMenuSref = function () {
		return `main.requests({requestId: "${this.getId()}"})`;
	};
	// TODO: rename to getStatus()
	Request.prototype.getStateLabel = function () {
		return _.get(REQUEST_STATUSES, _.toLower(this._request.state), REQUEST_STATUSES.default);
	};

	// conversation
	Request.prototype.getEvents = function (options) {
		if (options && options.includeSilentEvents === false) {
			return _.reject(this._conversation.events, 'isSilent');
		} else {
			return this._conversation.events;
		}
	};
	Request.prototype.getNewMessageCount = function () {
		return this._conversation.newMessageCount;
	};
	Request.prototype.hasNewMessages = function () {
		return this.getNewMessageCount() > 0;
	};
	Request.prototype.hasReachedState = function (state) {
		const stateSequenceNumber = _.get(constants, 'request.states.sequenceNumber.' + state);
		if (!stateSequenceNumber) {
			throw new Error('Invalid state');
		}
		return this.getStateEnum() >= stateSequenceNumber;
	};
	Request.prototype.pushEvents = function (newEvents) {
		const that = this;
		const beforeCount = that.getEvents({
			includeSilentEvents: false,
		}).length;
		const uniqueEvents = _.chain(newEvents)
			// eslint-disable-next-line array-callback-return
			.map(function (newEvent) {
				const matchedEventIndex = _.findIndex(that._conversation.events, {
					sequenceNumber: newEvent.sequenceNumber,
				});
				if (matchedEventIndex >= 0) {
					const matchedEvent = that._conversation.events[matchedEventIndex];
					if (newEvent.timestamp > matchedEvent.timestamp) {
						that._conversation.events[matchedEventIndex].data = newEvent.data;
					}
				} else {
					return newEvent;
				}
			})
			.compact()
			.value();
		if (!_.isEmpty(uniqueEvents)) {
			that._conversation.events = _.unionBy(that._conversation.events, uniqueEvents, 'sequenceNumber');
			const afterCount = that.getEvents({
				includeSilentEvents: false,
			}).length;
			that._conversation.newMessageCount = that._conversation.newMessageCount + (afterCount - beforeCount);
		}
	};
	Request.prototype.removeEvent = function (event) {
		this._conversation.events = _.reject(this._conversation.events, {
			messageId: event.messageId,
			sequenceNumber: event.sequenceNumber,
		});
	};
	Request.prototype.resetNewMessageCount = function () {
		this._conversation.newMessageCount = 0;
	};
	Request.prototype.setEvents = function (events) {
		this._conversation.events = events || [];
	};

	// actions
	Request.prototype.archive = function () {
		const that = this;
		return RequestService.addTag({
			requestId: this.getId(),
			tag: ARCHIVE_REQUEST_TAG,
		}).then(function () {
			that._request.isArchived = true;
		});
	};
	Request.prototype.canArchive = function () {
		return !this.isArchived() && _.includes([REQUEST_APPROVED, REQUEST_REJECTED], this.getState());
	};
	Request.prototype.canCancel = function () {
		return this.isSubmitted() || this.isNew() || this.isCreated();
	};
	Request.prototype.cancel = function () {
		if (!this.isNew()) {
			this._request.state = REQUEST_DELETED;
			return RequestService.cancelRequest({
				requestId: this.getId(),
			});
		} else {
			return $q.resolve();
		}
	};
	Request.prototype.canUnarchive = function () {
		return this.isArchived();
	};
	Request.prototype.submit = function (purchaseRequest) {
		const that = this;
		const options = {
			cardType: purchaseRequest.cardType,
			coding: _.chain(purchaseRequest.coding)
				.map(function (coding, key) {
					return {
						ID: coding.entryId,
						listId: key,
						path: coding.label,
					};
				})
				.keyBy('listId')
				.value(),
			currency: purchaseRequest.currency,
			fields: purchaseRequest.fields,
			profileId: that.getProfileId(),
			requestId: that.getId(),
			teamId: that.getTeamId(),
		};
		return RequestService.submitRequest(options).then(() => {
			if (options.fields.description) {
				that.setTitle(options.fields.description);
			}
			that.setState(REQUEST_SUBMITTED);
		});
	};
	Request.prototype.unarchive = function () {
		const that = this;
		return RequestService.removeTag({
			requestId: this.getId(),
			tag: ARCHIVE_REQUEST_TAG,
		}).then(function () {
			that._request.isArchived = false;
		});
	};

	return Request;
}

module.exports = RequestModel;
