/* @ngInject */
function LogoutService() {
	const service = {};
	const logoutChangeObservables = [];
	const notifyLogoutObservables = () => {
		logoutChangeObservables.forEach(observable => observable());
	};

	service.removeLogoutObservable = callback => {
		const index = logoutChangeObservables.indexOf(callback);
		logoutChangeObservables.splice(index, 1);
	};

	service.registerLogoutObservable = callback => {
		if (!logoutChangeObservables.includes(callback)) {
			logoutChangeObservables.push(callback);
		}
	};

	service.notifyLogout = () => {
		notifyLogoutObservables();
	};

	return service;
}

module.exports = LogoutService;
