"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTime = void 0;
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const setTime = (timeDuration, timeUnit) => {
    const timeSet = {
        seconds: timeDuration * SECOND,
        minutes: timeDuration * MINUTE,
        hours: timeDuration * HOUR,
    };
    return timeSet[timeUnit];
};
exports.setTime = setTime;
