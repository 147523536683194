module.exports = {
"d447f4a1755a0025dddd2f736255879f":"# transaktioner",
"3a5999cdb4fa3bc4dead57ac6b2674c7":"1 minut",
"ce09c436b49af331a32f1152ede7dd18":"1. Anmod om midler",
"d2226752d3065fbad4744924b4a0ee73":"2. Gennemse og godkend",
"39d2c8580e05852569a9fbc6995e4e4c":"2. Gennemse og godkend",
"82bab92f1c8030888550b51521266c53":"3. Betal med virtuelt kort",
"2ecd62e02e507f8e839a55eac0a04886":"Der findes allerede en kodningsindtastning med id'et '{entryId}'.",
"5dfd25849740fbce8147838b0d5094eb":"Et brugervenligt kaldenavn, f.eks. marketingkort",
"2ca4fe43a2dd93e6e693ed05ba596fa4":"Et medlem af vores kundesuccesteam vil snart kontakte dig.",
"c1b81292ce8592ad8a174b4a8a8f9d8d":"En person, som vi ikke fik slået op",
"976b50e70e4433e2786233979d243445":"Der findes allerede et team med dette navn.",
"35b61d21ac11d5fc31ebe58541263445":"Acceptér din invitation til at deltage",
"a372cb2d4204654f52cd9170fb10d374":"Regnskabskoder",
"110fc66010b6b91cebee7a59a2067aab":"Regnskabskoder",
"db862bb04dbdc6d04e47063f33be8865":"Regnskabskoder administreres gennem kodningslister og enheder på lister.",
"d6e3848e824f077396be4351eb58edd2":"Handling påkrævet - godkend/afvis ny anmodning inden {requesterFullName}",
"5e27725611f4098b1e7b666e25cfbea7":"Handlinger",
"476e728ea824229f52438e543818bcdd":"Handlinger",
"3117746cb7d4ab815ec98274ec4398d5":"Handlinger",
"3ab09b66a7f16219d8135977a2a76d10":"Handlinger",
"ffafa66eaab0409a2e90aaeb43796def":"Aktiv",
"0447e0aa5d00dbdab11b5546793c4479":"Aktiv",
"8ee51d21b05466f7d962d4ff5a0b384e":"Aktive kort",
"2e1c6ccf560b8c1ac63b489200cf45f3":"Aktive virtuelle kort",
"89c6ec05861aa914304ba35bc6744124":"Aktive kort",
"5280659bcecaf8344c57790f9962a9a2":"Aktivitet",
"8a15368ae286fa64e1cb7624cd410fc6":"Tilføj kodningsliste",
"8ded7e09e86be231b1165308329ac76d":"Tilføj liste",
"c13adf567cfd03b31bc2e6fc6de5ddd0":"Tilføj administrator",
"695bb4bc29e2df769ddf7427ab71012a":"Tilføj godkender",
"4a1d4516cab00b7c5ecdb150e346e5db":"Tilføj godkender",
"56869851b27c2915f5a7fe6a33b91ac7":"Tilføj kodningsliste",
"8f8691057c3994aa61f9994202680c87":"Tilføj kodningsliste",
"69f7a406b79d19bf8082ed245ab29123":"Tilføj kodningsliste",
"48a92ca1cc63018707be99b13196369f":"Tilføj virksomhedsadministrator",
"a679db9ff80d0e5769ae1ba311c7916f":"Tilføj domæne",
"8f5e8078be77146ba7f3be49ef948906":"Tilføj indtastning",
"b98c4ad60345f44ff3a87c6b090c5f8e":"Tilføj meddelelse",
"f8a4b14162e19995074e63135b65c84d":"Tilføj et nyt kort",
"d2c2cc8dca5fea80b476d3a1dfe2a13a":"Tilføj personer",
"c670549d0b898bb6a571eb2cd7804a6e":"Tilføj kvittering",
"eb937098e77d8a6b0565ed72357dcaae":"Tilføj kvittering",
"1e154f31c31b63a459f3956e380b3ef9":"Tilføj valgte",
"ba24db9956f156a18b32e653552ff682":"Føj nogle personer til dette team",
"52bd93fb5489b25167bb541f7c6504ae":"Tilføj kildekort",
"21ab3f08eb507dbc76df166eafeb393a":"Tilføjede {fullName} som en ny godkender!",
"f879c06507d3076bbf52ea4fd2df850e":"Yderligere kortoplysninger",
"21e934ae30eece4a894337701f497638":"Alle",
"0a8f1390036bc8e9c1fc87ccfe91e47f":"Alle kort",
"8fac71195e2b506e029752e4864b6a5d":"Alle medlemmer",
"f9708417df16227742e0545a8642eea7":"Alle tilgængelige brugere er allerede føjet til dette kort.",
"cb5abf070f8cba7982205038baab77bb":"Alle kort",
"dae7d602eec2c84dea0d80ae25e56e6d":"Alle kort",
"0b4bdfde6578668b6018d3615a1ad30f":"Alle omkostningssteder",
"97b875b81f54b4432d998a3e47d949cc":"Alle teams",
"f0ae83969f7c20a1964caf5156dae966":"Alle teams",
"d87455601a00282078582b36a3825b7d":"Alle teams",
"24c7b7f55882ea94a525392ca8218fa0":"Alle teams",
"8f860e35b64a8f447bded1e5ea66a2a8":"Alle virtuelle kort",
"e403956ecaa6fb33ec1fef5c49d71042":"Alle virtuelle kort",
"4e6f4e8f25f61d40e1eba77de94f6458":"Alle dine finansielle oplysninger er krypterede og på servere på sikre placeringer.",
"c912f8679e78eaeb4d4edf1c5809c32b":"Beløb",
"a4d099d0dbe3889b0995546e6ed28932":"Beløb",
"6c0ef32aae76bc31a61e2124f542bdf1":"Beløb",
"adec89c93525f1a8d1c64d856f405e27":"Beløb: høj til lav",
"18e632a170203894f40deed1d33ee552":"Beløb: lav til høj",
"a7944575cafef9126a499a1b06999fe3":"Der opstod en fejl, prøv igen senere.",
"e33a34e33ee7c4e908a9e8a3419c31ce":"Anvend",
"fdf29ca10be4a9ab6cddaaca1efe9aaf":"Godkendelsesmuligheder",
"9cb8c714c3ecfe6152164b0cd6bf1a60":"Godkendelsesindstillinger",
"492eedd88cdd7bb45f39c6594af748c4":"Godkendelsesgrænse",
"437d490afc8d226f8c7d544f4e35d011":"Godkendelsesgrænse ($)",
"cff5b7cc06fb05e57c69b04df6c68ec6":"Godkendelser",
"c86f5fea0eb1feb7ae3d567840dcd27f":"Godkendelser",
"17adb7fe866e32055b1469fdb7e82795":"Godkendelsesindstillinger",
"7125aa13db437084e1d27d3ff38f22e2":"Godkend",
"84df787ff5746f9dc3cf0146becef4fd":"Godkendelsesanmodning",
"7a568654d00245b0bab6c90a1b67d196":"Godkendt",
"179c3e187ad8edeb8348cff222b938bf":"Godkendt",
"ad058d17725870df9abbd4f5ec217636":"Godkendt ({count})",
"55ab98be3f0530e16d7b4c962ea0faca":"Godkendt beløb",
"4d58ba5c0e03fec1556c243be106e4a3":"Godkendt af",
"7d3d697fc99f6c4641b326623743f7b7":"Godkendt { currencySymbol }",
"a57dfb1ce3af2300e568400827558748":"Godkender",
"8c45e443fc6748190719d79a092e2528":"Godkendere har en maksimal godkendelsesgrænse",
"4fe56aa6456c108cd288a4ec8acf435f":"Arkivér",
"a9b6f93de9630064be9809130aa1e195":"Er du klar til at tage kontrol over dit kortforbrug?",
"8cf9530c91d1a2ddb22c909a3a33bf00":"Er du sikker på, at du vil annullere denne anmodning?",
"301bfc2ce058f49e0704fec3d162b5b5":"Er du sikker på, at du vil gentildele denne anmodning tilbage til dig?",
"2181faf8d9973d1f4138e401a762086e":"Er du sikker på, at du vil fjerne ${firstName} ${lastName} fra at være en virksomhedsadministrator?",
"b982277f29055d0ef6e295fe2006d9e0":"Er du sikker på, at du vil fjerne {firstName} fra teamet?",
"456c0ccff14acb808583cd9dece857b6":"Tildelt til",
"bde315b9cf7fd55f4b65aabbbf38d462":"Mindst en administrator eller teamejer skal være tilgængelig til brug uden for kontoret.",
"31b5e69b151bad3c66a01d4a3cf7bb7e":"Godkendelse",
"1961ec69dd0c8b5718c831312e7f3d0f":"Automatiseret modtagersamling",
"e39f2477cf34d726936a5b5c2b48a4ad":"Opret automatisk nøjagtige afstemningsrapporter, der inkluderer oplysninger om købere, godkendere, kvitteringer, kontokoder mm.",
"434ca07dfde0eaee14f094b7c75a6eae":"Tilgængeligt",
"eb85d8f50f2c89871b794f7d54279b22":"Tilgængelig balance",
"3d40a8d8c62eaa57a99168e5f26b5d09":"Tilgængeligt { currencySymbol }",
"1f9f8f2f7022d54f4e86fe530ff23e1e":"Tilbage",
"c8258f8cf9e20f6bbb89d957de40e820":"Sikkerhed på bankniveau",
"d46c22a3f36dce568c07fcea4368b231":"Grundlæggende indstillinger",
"fb481c8e517016094b4fdf618c5fa088":"Faktureringsadresse",
"79a9f821923c5a04c076902fef684281":"Faktureringsadresse",
"877a08b8347052c83aa0b689baea81e7":"Faktureringsadresse",
"97f018458e2627fac37d24846c7b8922":"Ved at fortsætte med at oprette din konto og bruge Tradeshift Go, accepterer du vores",
"3dd252416d7ad21a6bfb3cecc58b75c6":"Ved at fortsætte med at oprette din konto og bruge Tradeshift Go, accepterer du vores",
"912d1864f0068c764bf4784020795d49":"NAVN PÅ KORTINDEHAVER",
"d53474c7fd99fbeb69481ce9d358aa09":"Kan anmode om kort og en tegnebog til alle deres kort. Hver medlem får en standardgodkender (administrator) til deres anmodninger.",
"f30df2cab105ed3f8b4264aeb9800abd":"Kan godkende anmodninger og oprette kort for medlemmer, som de administrerer. Administratorer har et dashboard til at spore forbruget for medlemmer, som de administrerer.",
"03ed9eccc71ef59165292eac542d3050":"Kan vise alle anmodninger og al forbrugsaktivitet i teamet. Teamejere får også et teamdashboard til at spore forbruget og teamtegnebogen, hvor de kan se alle aktive kort.",
"86d491b8ebe5d1ea4f218457cac6a7ee":"Kan ikke fjerne denne bruger. Du skal have mindst en teamejer eller administrator på et team.",
"a0b70642629a55ea0ab6fa3414d342e0":"Kan ikke fjerne bruger med ventende anmodninger.",
"eecca5918ba2ea7f4b08f1f96a4cbaac":"Annuller",
"5446624d3c364ac05ddf8d6663da2796":"Annuller kort",
"53f3815c4fa3340b29efd81e627d1e6b":"Annuller kort",
"2aafb7eb4d56a516c4bb0754f0812df1":"Annuller kort",
"caae12601b01e2325161e95107f9e332":"Annuller kort",
"6ad1e57b1e4c7accbe166c948883077d":"Annuller med et klik, hvis det er kompromitteret",
"f6527e49e6d5c989624507a583e23f39":"Annulleret ({count})",
"1ad3bf5121641dda511f42942af05a90":"Annullering af dette kort vil ikke tilbageføre eventuelle ventende gebyrer. Søg efter aftalen",
"aebd3826a195afad0a93050a7cc5d73d":"Annullering af dette kort vil ikke tilbageføre eventuelle ventende gebyrer. Søg efter aftaleposterne for disse gebyrer i den kreditkortopgørelse, der blev sendt af din kortudbyder.",
"a5b5136947da562f91b75daf8156f50e":"Kortoplysninger",
"9ed31ad23a1578ce480d31207decf1d3":"Kortindstillinger",
"def15f2e007fc69886230fdb2511305c":"Kortoplysninger",
"49dec31d2ebf985c2cbd6eb2c1811b67":"Kortoplysninger",
"67cfd260078ca32027c2fa4a4a2d29f4":"Kortindehaver",
"4458819ea60c68c46e551a8f5cc163cd":"Kortnavn",
"0858bcbd506fa3240b165e59d2f18aae":"Kortnavn",
"5212736dd18ee2c31f3650ad2c2c7130":"Kortnavn blev opdateret.",
"c96d62fa510c3356dc73a91d1a80e262":"Kortnummer",
"41307fee4c32aefb5383a98fe3d6e45e":"Korttype",
"3f8045c3358621dad4346a44de0c05a8":"Navn på kortindehaver",
"879a6b452033cf5f3a4bb3e1837a0386":"By",
"593334553ef852024e172505ec3c5f21":"Klik her",
"bbc9e4deb5f013a02bd1c0a37bfaa305":"Kontering",
"df931751d5790d74fa46707aaf7a26ae":"Kodning 1",
"7ac7154bc776aff52a71b0d83b860561":"Kodning 2",
"586fcf19136ae5e26795e9055b2e2995":"Kodning 3",
"2a94a6d92c4af7beba96b97e717f5f94":"Kodning 4",
"379bb8e71bb090badebbb0751de86f11":"Kodning 5",
"3e84d13627b3d090ab2b8470af98340a":"Kodning 6",
"33b94b581f3792049992663396e51cb8":"Kodning 7",
"b73fbc32e439655833ee99126dd0e55f":"Kodning 8",
"541c697e36e1f0c5b72ddb8d06d7c994":"Kodningsliste",
"d8c8ad37f746cea62ecacbc5c882fcf7":"Kodningsoplysning",
"c1c2ad160de2b5e6a94eeda5c9df1562":"Kodningsoplysning er slettet.",
"cada62a39ee72d790dcdbb25160f8783":"Kodningsoplysning blev gemt.",
"f645bf50888943ee1384693e56a0f475":"Navn på kodningsliste",
"a999182acbcd77c15299f79181b630be":"Kodningliste blev gemt.",
"eb57b184bb76e20bfaedc92cfdd562e6":"Kodningslister",
"d0b96596ce6baec5aabb8a0690ab30e1":"Kommentarer",
"ff9cb8842f832d21fb160b3d3784ce79":"Firma",
"715b59a64e686eb91a21114644ffe39e":"Virksomhedsadministrator er fjernet",
"8758e9a01253c097a42393fda9610715":"Virksomhedsdashboard",
"e118b41009918b326db6746856831cbc":"Virksomheds-id",
"4dba88b05b3fa8875128e19e88c512b3":"Virksomhedsindstillinger",
"2296ff9c39eaac0b4c6109c7b2fb2b67":"Virksomhedsindstillinger",
"f2ea6d33de8182d97bf2f13354af4893":"Virksomhedsindstillinger: {companyName}",
"3529dcd744b1ee9c48d486d9ec462c98":"Virksomhedsadministratorer",
"fc024c8ccf5496d8a6fc1eb1086364ea":"Virksomheds godkendelsesgrænse",
"7e2f778da14a726b53e05926a3483f2b":"Virksomhedskreditkort",
"7313fd1db3d63858586d1c0f89251d6e":"Virksomhedsnavn",
"07721e91dd10ff8831f0d24e47cc1eab":"Virksomhedsnavn",
"203221610ca4d347063c037e1a732ae8":"Virksomhedsindstillinger blev gemt.",
"b88a5999d9f7ade57cfbe9e336ff46d4":"Bekræft handling",
"d694cec09cdbc02b1ffe22642a63faa1":"Bekræft adgangskode",
"512801312a133f29974f2259a455f39b":"Bekræft adgangskode",
"f54c38ed0b9869d929ffc0bd5310131f":"Bekræftelse - Din konto blev oprettet under team {companyName}",
"8ebebbcdd8c558a2def59c30d402633f":"Kontakt din virksomhedsadministrator for oprettelse af regnskabskoder.",
"7a6a4de077398e8e138aec36e3b3d305":"Fortsæt med SSO",
"aeb18b6ec219aa26471ebee632c3d53b":"Copyright {date} Tradeshift Inc. Alle rettigheder forbeholdes.",
"51f0d8e04f6ee626b866b37833dc2b61":"Omkostningssted",
"e043f60a02f157d0f31a42f0db67cc87":"Kunne ikke invitere {username}",
"4a766eae1cffb932bf2ce31634f22d56":"Kunne ikke sende din besked. Prøv igen.",
"ae28083f22a0b2fc5637614bce0976e0":"Opret tilpasset liste",
"738078dc992208e05406f935995dc0c3":"Opret tilpasset liste",
"8d8bb604fa10b960f6883e31b3f5ebb8":"Opret ny liste",
"0f5783c3921b3d7398d281644bbe5bde":"Opret en anmodning",
"6d62f934f2304701f88bdcfbc688de7e":"Opret konto",
"88ee8fab2ece6a8e6bd7057e1789a25f":"Opret en konto",
"f6b7d0d8531ea268f988005dd2147b2b":"Opret kort",
"1a083675d1453448e4645403bbe52dd9":"Opret tilpasset liste",
"c10ab11bdfb001da74850ed89c45482f":"Opret ny anmodning",
"43a9c06398408bfe77d06ffc2c1c3b87":"Opret ny anmodning",
"68bf17fd3f54a89c0a84224e2f9a971c":"Opret nyt team",
"d1715162ae8e9cc6ef30ec6063a3fc34":"Opret team",
"3b8e665101b9b3657f8a68c5305947b7":"Oprettet",
"40e91f2907181ffa8e62567b3a407e60":"Oprettet af",
"8929f7aacb969ef6353aba9903eb362d":"Kreditkort blev tilføjet",
"d86688c26e8ac5eb9facc971b6fe5155":"Valuta",
"5c1a69e23c7265e4bb1fa788f5b6e953":"Valuta",
"500ded53f64e737a5e4337675ac9b7ce":"Navn på tilpasset liste",
"b79a95f04fd829fb2e8d0313e522a94d":"Dagligt",
"fb7add662cf7042355319a975fab6ae3":"Datakryptering",
"6bded249dbfafd14490eea8dd37e1f51":"Dato",
"46e908a57cb09ef947af8c4a93a09716":"Dato",
"e4213104a55eceb60e00408699438dcd":"Deaktiver team",
"c8f8e49f0db4d998dd0a274bfd4e89db":"Deaktivering af et team vil annullere alle aktive virtuelle kort, og ingen teammedlemmer, administratorer eller ejere vil kunne få adgang til teamet. Kun du eller en anden virksomhedsadministrator kan genaktivere et team. Er du sikker?",
"0d0d5677bee2b99884802638e507160a":"Afvis",
"b5e3483aae9d36ce1440fe02904d6da1":"Afvis anmodning",
"00f3db97287cb17ee9c2a131f2141a65":"Afvist",
"74fdf2e0ad0de26fe28b0b72ba44f494":"Afvist ({count})",
"268ed9551ae9f56647eaa394e43c160b":"Standardgodkender",
"b77589ac3ac2317cfc96d7013658d5c9":"Slet kodnings-id { entryId }, indtastning: { entryPath }?",
"9dd88505aa78eb11e87fb553c5fa785d":"Detaljerede opgørelser og rapportering",
"5234dfc4220ccd18a8b62fe84e372248":"Detaljer",
"37929896817a6744718004374bb42fd8":"Detaljer",
"dd0a6194e8a6ae14f3e1379a1640de15":"Ville du fjerne {fullName} ({email}) fra virksomheden?",
"1a98048b34090da8b199135ccb4c1186":"Entydigt nummer og CVC skjuler de rigtige kortdetaljer",
"3cf132b60fe16b816ca227bbcad60537":"Annuller ikke",
"375ae8224d7d35393974775cebe8605a":"Download brugerliste",
"54b3bfd45a293b441d441aab1a50e644":"Download rapport",
"60cf3b94e5df14a504825068928fc118":"Download rapport-CSV",
"7341a4c73c0b688484304c37da4a6d8e":"UDLØBSDATO",
"f628f8ad44fbd6c70c99fc778407b666":"Søg nemt i historiske transaktioner, og udforsk tendenser i medarbejderforbrug over enhver tidsperiode.",
"94bb409d034ea20697ae09d812e55365":"Rediger tilpasset liste",
"2974e704676cb79666bc236d8658fa97":"Rediger profil",
"9ff205f99ed4002d049f855f965ca6d8":"Rediger bruger",
"3d7a4a9f4e70f57463e9a97caadb8205":"Mail",
"0534c00af758d42ebd8b0bd772eab074":"Mail",
"0c9aa1fbdf925065d25161eab5e51d95":"Mail",
"ea78fac68766a48b1b0f41305b7e9898":"Mail",
"dc6a8f782e00c1bf05c48f3a8758e26c":"Medarbejder anmoder om midler fra virksomhedskort for at foretage købet. Anmodede midler kan have angivne forbrugsbegrænsninger.",
"829cff16ce0b13810c914c161b2a9fba":"Aktiver alternative brugerfunktioner for hentning",
"741387c7bd71e2b4a9601af9a6b9490e":"Aktiver automatisk videresendelse af godkendelsesanmodninger",
"0ca0627ea6f1e19bb4ad583c0a9f7717":"Aktiver standardudløbsdato for virtuelt kort",
"bb319710dad93e78cc6f58c615bdf210":"Aktiver hvidlistning af e-maildomæne for inviterede brugere",
"4700ced24cd278c3149ffa983006035c":"Aktiver filtrering efter omkostningssted",
"3fe06ef8f551c4cb4c0375362e25521d":"Aktiver globaliseringsindstilling i bruger separat",
"2787d4bc9752272ac36b499ce7ad93ab":"Aktiver flere anvendelser af virtuelle kort",
"6ee44e2280c9ce5009568846cbc3dd14":"Aktiver Træffes ikke",
"1941ca87053df0cebc6091ede92c18ea":"Aktiver personvisning for virksomhedsadministratorbrugere",
"e50282c2ec7afb948d2c0b428db5d5ff":"Aktiver modtagerupload for transaktioner",
"268694cb2172918fe60f835919bf20ec":"Aktiver modtagerupload i afregningstabel",
"2e2b3ea02542d70fb8c8d5d966e46e79":"Aktiver egengodkendelse",
"d17c7acf598b569cce8a2bb97e0bb215":"Aktiver enkelt anvendelse af virtuelle kort",
"cd731ca218c9e14caabefafe003cfa6d":"Aktiver virtuelle kort for abonnement",
"8e42ee54685a9edfbcc17da5b95bf7bd":"Afslut videresendelse på",
"c1f665a814894b17d603b7052b631d90":"Tilmeld et nyt kort",
"6b6cea93e07e596be70f7b76801e1516":"Tilmeld nyt kort",
"7d6ac1ac9cba1620b61908842ebb106b":"Tilmeld kildekort",
"de2cb5c7c4eaa6db0c2e74a377d225d5":"Tilmeld kildekort",
"21a642b339c3eabf2789c0b05dcf828b":"Tilmeld dit første virksomhedskort til GO.",
"6efa8dca4fba23a190838746879fd704":"Angiv et numerisk id",
"02c3457577da9dc8dba6e6f9380a248e":"Angiv et entydigt teamnavn",
"40feb3fd1fa529cb599aeac9607664a4":"Angiv ny indtastning",
"8a7cb4c2e4afcaeb82731e52efdcf6cd":"Eksempel: timbuktu.com",
"97ae5e57984f05f92c25b09f5de40f9b":"Udløbsmåned",
"b8bc74aeeb2de3492891c97361de107d":"Udløbsår",
"6a0b8d521500014d99dd192d8ce8403d":"Udløbsdato",
"8cf274169a732340430fe7cc5236e0e7":"Filstørrelse skal være mindre end {maxFileSize} MB",
"58bb9c6f074e777ab1829b41acb18a81":"Fornavn",
"7458b55e967e51791d98e77458631e44":"Fornavn",
"3bbe52e71bc5c212cea77983f1a5b748":"Fornavn",
"9b49c88fa20cc006baaac15c857c8781":"Til enkelte eller flere køb",
"bb7a2907073bd760f4e82097132637b1":"Glemt adgangskode",
"26bdfaa592f3629273f13c22435b323d":"Har du glemt din adgangskode?",
"e11d8d67b14d2ccb5abdcf4250c60004":"Videresend",
"3f9fe6adc14ce5776097170653a24462":"Videresend anmodning",
"b774e35683f5737c36bf0cf3d6785973":"Videresend anmodning",
"628faa5054a912b1e470414f77057e6b":"Videresend anmodning",
"852b4065e1837fdc77940b6411a6d902":"Beskyttelse mod svindel",
"616551bd10d95aca439401d6a7769d96":"Fragtbeløb",
"a65a230918e9c11e1ef8824745e26a3d":"Kom i gang",
"7d4c4466b7716347ce3673acaa1983f7":"Få mere fra Go",
"11415a07658cfef80eb166896d77ad98":"Gør dig klar til at få endnu mere forbrugsindsigt. Tradeshift Go vil nu også passe bedre til den måde, som forretningsteams ønsker at arbejde.",
"ac0ff5d14286ef74614a9c6021f08f69":"Få overvågning af forbrug i realtid og budgetsporing, få indsigt og få vist detaljer om al købsaktivitet.",
"db9071ab833054307252a936e7f60e1b":"Kom i gang",
"24dc4bb00841b004031fca3a9bb9bf51":"Go-kortanmodning blev annulleret af {requesterFullName}",
"7e1f1607e430799fe6d8022ea5a677f4":"Go husker medarbejderne på alle manglende kvitteringer, der kan vedhæftes blot ved at svare på notifikationen, eller hvis der er tale om en e-mailkvittering, ved at videresende den til Go.",
"a73603f0bc610ecea9ef9c793b802538":"Go fungerer med dit kreditkortnetværk for at garantere de samme standarder for at forhindre svindel, som du villeforvente, når du arbejder med de fleste førende finansielle institutioner.",
"bc2a9dc3bcb628316ab99a22d24d027c":"Gode nyheder! Din købsanmodning er blevet godkendt på Tradeshift Go",
"ee397c9cb0668cc9cba62e1c1e619615":"Har du spørgsmål eller feedback? Kontakt os",
"9df1d3de68d663b848101df3c7b58e39":"Sådan fungerer det",
"61cabedff1b97e5c7f8e3cb3565fcdc6":"Jeg er sikker",
"84074732eb8d1b4094404cd750baf949":"Hvis der ingen værdi er angivet, gælder virksomhedens grænse",
"138e8bd6102a7eea70a123ff03b1cd79":"Hvis der ingen værdi angives, gælder virksomhedens grænse",
"e5756af4d7e5ad1236ccfbb858428421":"Hvis du ikke registrerede dig til Tradeshift Go ved brug af denne mailadresse",
"4f004f75f47dbfecdb6d09990596e530":"Hvis din mail endnu ikke er registreret, sender vi dig en aktiveringsmail med detaljer om, hvordan du skal fortsætte.",
"123837f267de602fb05361b3b1197c1e":"Hvis {username} matchede en eksisterende konto, sender vi en mail med detaljer om, hvordan du fortsætter. Hvis du stadig ikke kan se mailen efter et par minutter, skal du tjekke din spammappe.",
"45efd96cd93525a9c17222217a37d324":"Billed- eller PDF-filformater understøttes",
"fa6ae823f9ab8bf91c246efe2517956c":"Importer din QuickBooks-, NetSuite- eller Xero-kontoopsætning direkte i Go. Eksporter de seneste transaktioner i din kontoløsning med et enkelt klik.",
"39ca662b0e2f39d3ca8143e8dbbb584f":"Inaktiv",
"b3d8f5a96ffe1c18b9d3e9fe2e721c64":"Input indeholder et ugyldigt tegn.",
"62c7be09da43f8f96a60ef3967eddc2c":"Introduktion til en helt ny Tradeshift Go.",
"19746bcbed8b8bcb1a9b83efca722077":"Ugyldigt maildomæne",
"fc9ae1d1a1f8cb70b7deb93affac9cd9":"Inviter personer",
"3809b41b14c55c3fcf54cd813d8e21f5":"Invitation til team",
"fc90a11805961b0d7b5a5545e4d9bc05":"Inviter bruger",
"6f7f4acde92d267417f5d8a60d32400a":"Inviter flere personer",
"09ecd2ca15bb5c38dc10f653118346cd":"Inviter ny administrativ bruger",
"3f576ce504f53f972442a63a170c5418":"Inviter ny {role}",
"0215e052437ceea4e5cd562431b34ced":"Inviter personer",
"8aaf2c77df644b7e8524120f66da7d5f":"Fakturabeløb",
"1b7dc8290f1205f092cc9133e0cde385":"Udstedelsesdato",
"1a6fb210308df8e0135e49e76aaff31e":"Fakturanummer",
"613da3d0cd586ef0360e0d93eace9508":"Dato for udstedelse",
"052a2dc8b146e9cb3094d34d8d0b8fbc":"Det tager",
"3322b671105c65511fbab1966b7fdcf6":"Sprog",
"b1d946192795a58aea6a61f4f923acb7":"De sidste 4 cifre",
"b3c6b8db4ef6dcdcb451303e65548816":"De sidste 4 cifre",
"0382123b9c847000fceff2484c665e39":"De sidste fire cifre",
"19f7c0055e150e9e3e66e85c2993799c":"Efternavn",
"662c84bede8a7bf91ab15bcb73b48b49":"Efternavn",
"cff8fbef5aac2523344b9535bd56973c":"Efternavn",
"c3510b2184a92ff12e07a2d1b9a461e6":"Lad medarbejdere anmode om adgang til dit virksomhedskort uden at vise dine kortoplysninger, altsammen samtidig med at du håndhæver budgetter og sporer alle køb.",
"90c2664b0f771d6ffb1c3241fb8abe44":"Grænsen kan blive overskredet på et team- og individuelt godkenderniveau",
"9bf5d56627e500cad9f7ad21c3e7966d":"Begræns til bestemte udgiftskategorier",
"e80a6d4232e52f6bfabee02e17403b59":"Link et af disse kildekort til dette team, eller tilmeld et nyt",
"803570ab9f0fba5ff35ae2749200501f":"Indlæser kort...",
"f5d8fe188045fb84a41f8567ee3b2d88":"Log på",
"ad96c1ca962a883652dadb845f439415":"Log på",
"072ca67e735adbed54acf41321370701":"Log på",
"70eb4d4ecd1a6bd6a38cd9e642ebb4a2":"Log på",
"b246fe8836c906f1b56342378ca88895":"Foretag, godkend og overvåg køb i teams, der spejler din organisationsstruktur.",
"6df0ac75cbebc025cb101a5cbf0a584a":"Administrer afregninger",
"95d672ab621e531ae1a69798e0e4b56c":"Administrer alle dine virtuelle kort på et sted.",
"96f2e88a167f476413b35aa711709e6e":"Administrer aftaler",
"151d8e6ceec242d482fff60da8311449":"Administrator",
"5a70ccf0b32e1fc6c2093ff1c988c732":"Administrator",
"ef1b3b3627418a5e8e5cceb814a67c8d":"Administrator modtager hver anmodning om gennemgang og godkendelse. Forbrugsbegrænsninger kan også justeres.",
"4f449985e83c1f9ca2e8f82b0707a371":"Administrator modtager hver anmodning om gennemgang og godkendelse. Forbrugsbegrænsninger kan også justeres.",
"1fe7e36d9d2fca8bc8c4c9d2690578b2":"Administratorer",
"27f8111a955975ea6fd04f748d24cb4a":"Maksimal godkendelsesgrænse",
"5b1d9cfb78c55e75b47c62435d9299ca":"Medlem",
"41e307c1874e2e82296b226e9bae4a5f":"Medlem",
"6f9612aa1582373c2c1ba4e144132100":"Medlemmer",
"590429a0fcc42af85648d0fd70601dcc":"Medlemmer",
"3f50e62c8488d83ad84f69ebda853897":"Medlemmer",
"e0fc0acda5b74b93e797756dbbba67bc":"Medlemmer",
"c7ef9eda38b7eaee1f20031387a8bde7":"Måned",
"40dd08b507752bef4e3c4b36838317d5":"Månedligt",
"d712b91f7498f212047aad4c6d0f8088":"De seneste",
"12110d95b3d7a495afa4f28ac190f63e":"De seneste",
"2f11ce3865f79ee7e5ee64d728082264":"De seneste",
"eadc93865655cffc6a9863fdda5693e1":"Skal være på mindst 10 tegn bestående af både store og små bogstaver.",
"e2d4cd7cd181c33ab180ba577e1ea325":"Skal være på mindst 10 tegn bestående af både store og små bogstaver.",
"50d0a69aa59df5201018771a06ea12e7":"Skal være entydigt",
"4c7e0bf97356d81952a583fc9d7a0c1c":"Skal være entydigt.",
"2dc1d0b7db8bab8d6caca6e6044f5593":"Mine kort",
"c36602f50d35c2816f0ba8a196b70876":"Mine anmodninger",
"ff6e372aa3eb685a39a701963db35b2f":"Mine anmodninger",
"5d0b17a2a99ecc9d01b90d5f5d0da666":"Navn",
"1c7514b561a2591a914225813d30d642":"Navn",
"d621826c1ceade262589af04e655e824":"Der kræves ny adgangskode",
"35dc3304b61a9e69dc3a393123658837":"Nye anmodninger videresendes automatisk inden for denne tidsramme.",
"e788124801b4727d121f60d059a2a4a7":"Næste",
"eca804f1d53dfd984ffd93e2d00fc284":"Ingen godkender er tildelt, kontakt din programadministrator",
"abdf7e93f8509399a63108a2b1a25546":"Ingen aktivet, der skal vises",
"dbf0f00d4ae2b2456f9414c6b2c43793":"Ingen kort i din tegnebog",
"87127e1e9f5bf922d0a4f948b68697c8":"Ingen grænse",
"40512efa6b75ef0c7924b1ee3070fca9":"Der er ingen medlemmer at vise",
"dcda493afcb2415d5ca49a17d1af6f50":"Ingen afregninger er sendt endnu.",
"f0efb26e623bb990532e375d5c8875ec":"Ikke tilgængelig",
"bc0cf602d1978e814070bb552e576a32":"Ikke tilknyttet",
"09e126c317429ccb52ad323c32293648":"Antal betalinger",
"75b00b969b9d3b520929f1e652637b70":"Antal betalinger",
"3ca8508e4fa323f7eb999f2ea3b96f94":"Antal betalinger",
"bd44ba180aa356c5755fdedd32adf03c":"Åh nej! Der opstod et problem ved oprettelse af en anmodning. Prøv igen.",
"c396b3728dd7393aa9e5427990c132a7":"Åh nej! Det opstod et problem med at downloade rapporten.",
"42c07764b07dea1ee7de7605721c3f5f":"Åh nej! Vi kunne ikke oprette et nyt team.",
"e3a385878ec94bac12a99490d0a8e85a":"Åh nej! Vi kunne ikke oprette din konto.",
"17f2dae79677017e2c2e879578c4e09a":"Ups! Noget gik galt. Prøv igen!",
"78c135de545c1663c9768226492a44a3":"Eller brug Single Sign-On (SSO)",
"9c038198d567c5379b055ff5ff662e9f":"Træffes ikke",
"5d7e45f169e2f6968dc58f43b5267353":"Træffes ikke",
"ee589a20c262527db970671fb2c4f6bc":"Træffes ikke",
"4fd0c753fce49a016b98d988a3895223":"Oversigt",
"2789c5d5c9c042858f45aadc4e0de058":"Oversigt",
"083b7090b66ebd0307a18499ac7b676e":"Siden findes ikke",
"a21d205920893cf579a7655a1430fe30":"Overordnet kort",
"fd35b7ce938e404fdb65de3bb749c528":"Overordnet kort til rapport",
"e8c7d2eea7db66ecb44751091a582304":"Adgangskode",
"bb79e316e00fd21138750b6986e44455":"Adgangskode",
"6cae2279088580976d84119d95097cae":"Adgangskoder stemmer ikke overens",
"4baec0159d3074f84c0eebcc09ba4cd8":"Adgangskoder stemmer ikke overens",
"b676587115243921602742cb8f696a6a":"Sæt kortoprettelse på pause",
"db4a26c871ef0b970b74e1f71763098b":"Sæt nye virtuelle kort under udstedelse på pause",
"33fc1e6d01b785e3a815c28ef7d56667":"Afventer",
"6ea62e4811b68df48b467e8d367aac2f":"Ventende anmodninger ({count})",
"f811ea5af22c0b0d2efd1a3f849c77e4":"Personer",
"d91798f74d3344b720d1ddc0a7c8ebf6":"Personer i et team kan have 3 forskellige roller",
"e2d34cb1409b9778d34c4b74ecee1df6":"Angiv et positivt heltal, eller lad feltet stå tomt.",
"8da247d5d736c87122404eff1e5c0c73":"Indtast en gyldig grænse.",
"65e154267ac283d963cbc7be832969d9":"Indtast i en gyldig værdi",
"28b4450d417065a69a395f567967d46c":"Standard med ønskede forbrugsbegrænsninger",
"7bbf151d8493170f206a23c2ecc6acd0":"Politik for beskyttelse af personlige oplysninger",
"51602962f2d1e7515943b4a6c66562ce":"Profilbillede",
"a51a17940c70a3123da4d68bed562c40":"Profil blev gemt!",
"4d398249d01ab72d697a7e219493c637":"Udbyder",
"faa1fc0ae963cc81da7342e4cd48d3f2":"Udbydertilknytning",
"89b1deb2ffe12a13941c362abaeab01b":"Udgivet",
"d8d806d48052159e3ff3b224f3f53586":"Formål",
"089917619c78af2efb30be0bb7ce076c":"Aktiver team igen",
"9dee2afa8cd8e7ef68a7b88e3470f169":"Årsag",
"e8484c78fda9f453eeb942ba4945f040":"Flyt",
"ff2fbc1172ba26776ad0982624cd2713":"Kvittering",
"588e690db177ee0c7a7cadb39cbd5efc":"Opdater midler hver uge, måned, år",
"32955937daca05b2084556f248692baa":"Registrer",
"f15bb755ba24abe6ab106637292175ec":"Resterende",
"579782461133a8b8cc6bd77798961d82":"Fjern kort",
"e475edc165372f8c8deb532aa85d9950":"Fjern liste",
"4832a1427c3f2dfa218fbc0075d0f0e5":"Fjern liste",
"f9769561315b964d0b3b4887ec208977":"Fjern fra team",
"d2f4abbb4bbaa18daae3e19991e8c8f4":"Fjern kvittering",
"ee181cef581fc8e8217e66b5762db898":"Fjernelse af en liste fjerner den fra at blive brugt og sletter alle eksisterende oplysninger. Hvis du vil fjerne en liste midlertidigt og beholde oplysningerne, kan du deaktivere publiceringsindstillingen. Er du sikker på, at vil fjerne denne liste?",
"feea41d0bc1d577a07c8b6e1297abbde":"Fjernelse af et kildekort fra et team vil ikke deaktivere eksisterende virtuelle kort.",
"d09483824a2e0d6194a4c3f32311e752":"Fjernelse af et kildekort fra et team vil ikke deaktivere eksisterende virtuelle kort. Er du sikker på, at du vil fjerne dette kort fra dette team?",
"7ca4b1bf1346e179a29da97f260a93a4":"Omdøb",
"db195ec9c818b8e92da142374e20d1bc":"Omdøb kodningsliste",
"2fd93e229fe9ea754808805d3fef9c13":"Omdøb kodningsliste",
"aa1e74d32805823f802a11b33c60c38b":"Rapporttype",
"6ac78d97a8cb7f060e43e596e3e23535":"Anmodning blev annulleret.",
"25deccb1980291a0fa9997109c731440":"Anmod om demo",
"9f2754d87686903b1500ce8e8a55b377":"Anmod om demo",
"92ac924f72679f897e26f3e615be8c22":"Anmodning blev videresendt.",
"16fc7eb0f8d05bdb5d1f310233fd816e":"Anmod om oplysninger",
"270185007853cd779011ae0e8433f723":"Anmodning blev tildelt igen.",
"e5a059c9ba2691910321339cce989f9d":"Anmodning er sendt.",
"77c88a231edfa7075114d91d2397e42d":"Anmodningstitel",
"cee91b833c4358acf0c554f746557e71":"Anmodning blev afvist.",
"5e914c38c1e3bd2b9f4ae903f1d94108":"Anmodning blev afvist.",
"9e56abbbf87420eeedd625ce786515c7":"Anmoder",
"b4cb4426e44fee9580af71912a764536":"Anmodninger",
"df309e888c29fc447d0309329b39ce93":"Nulstil adgangskode",
"5daf278da19ecbaeb7b96554cb4db10f":"Prøv at oprette forbindelse igen",
"31e9501526d2114e4a01fdbf076de097":"Rolle",
"6fbceb787429f6f81117a1d5a58b4610":"Rolle",
"473b814ca86841ef672ccf9f7dac28d5":"Rolle",
"f1e16d5fd643e039d865b6aec7c986f9":"Rolle",
"ec78345a5975bc0f8efaee5256b43c2c":"Gem",
"2cecea57b47d8f4a45c706287ff7d64e":"Gem ændringer",
"15774be5c338a39169917c7d32781dd0":"Gem og fortsæt",
"844095b4b8f580737b8717672501048e":"Sig noget...",
"7e66f676c2e7b04514aca3a0935530f0":"Søg efter kodningsoplysninger",
"5293ce9335ec2d176a6e838bf76404c6":"Søg efter beskrivelse",
"efab3b0c95f263cdd0a8466329a387eb":"Søg efter beskrivelse",
"d227b38dcbc4548c092b0836a7101506":"Søg efter den handlendes navn eller transaktions-id",
"3629383abf1d5f85efd759f718159b40":"Søg ved hjælp af navn",
"a034fcd6719228b28a6fc22d3a46ea8a":"Søg efter teamnavn",
"8ac7e0d63f343b548482d5f367f5b3d9":"Søg efter teamnavn",
"858323eb52cb45446597ee46330a0540":"Søg efter virtuel kortejer",
"111bfcbff671e13de117ed5195fb6773":"Vælg",
"bcfecd59eb93e5314873d84ddf35aa58":"Vælg",
"909a4414ea573218bff2d533eaab46ea":"Vælg slutdato",
"114b206ca1ff1c0bc52abef342059b2a":"Vælg teamindstillinger",
"81a02e424615fb41a20b8d0f13441c88":"Vælg team",
"b135b36495618e6692e7fbaf0a0aa83e":"Vælg en måned",
"677e90855e164c00a28b5c4763ac67e0":"Vælg en rolle",
"d01570f3774738395b8ba3c64ce54c76":"Vælg en tilstand",
"7dee3f06c70626881efb0cd6af6b63e1":"Vælg en bruger, som anmodningen skal videresendes til",
"4c7bb810835a1540ddc1f6501da5a51b":"Vælg et år",
"3ab198d2678af2b5b32180e4b39602ba":"Vælg godkender",
"f502ba79f2bd010c5e77891812400958":"Vælg kort",
"936027182d446e27ba209ea921ef7502":"Vælg datointerval for standardudløb",
"b22c893254ccf3bd34faaee511cf7ca8":"Vælg varighed",
"c3b7ba9d116dd344f55b7e31d5b418c1":"Vælg fra kodelistestandarder, eller opret din egen nye liste.",
"42cce5fa376b45bf290f86c684d69d96":"Søg efter udbyderfelt",
"b761773d46699b60e8d6874b85593374":"Vælg team",
"3a9396756942ede98d0cb6afc1032b15":"Vælg deres godkender",
"b6c411b0c1ab4b3702ba2605f563ccbb":"Selv",
"03e9d2c128af288126d94f631c5396be":"Send mail",
"155e7b3f7db7bf10b03b5f41de54eae1":"Send besked",
"f91ab0044a0cba2333ebe6ea2b4e9c51":"Angiv en udløbsdato",
"f89ec96a4b5bb639488ab742efa76a6a":"Angiv ny adgangkode",
"7d5377ad70d57728a45d3c0639de5544":"Indstillinger blev gemt",
"14313f11dee735f2144953e720776c28":"Aftregningsbeløb kan være et andet, hvis tips eller andre gebyrer anvendes til købet",
"199000fe0cda0adaaa9b864aa7447cb9":"Afregninger",
"c56ffdda7f0ad4ab2dcbd19e295fdfe5":"Afregninger",
"6e6ac6ff3bd722e37874fb1ea7115818":"Opsætning af din adgangskode",
"9b4ecf052980e80bf5c65a415d470a43":"Del og kontroller adgang til dit virksomhedskort",
"ba746d78772d6be26cc508ba989becb0":"Skal være mindre end eller lig med {maxRequestLimit}",
"9cb6c9731c94fa22733eb28489494d36":"Viser transaktioner uden kvitteringer",
"7d474b60aeb7cc25b519e806fca71e12":"Log på med dine eksisterende \n\n Tradeshift-legitimationsoplysninger",
"16080cd4e8319ef3e7de21b6c19d1437":"Noget er ikke helt rigtigt",
"8f358a370224baf849f9652810c68db2":"Noget gik galt",
"4c9dd6888be2cd01bb7c1f1aa76c190d":"Noget gik galt",
"99af87c475a4d40ef732deed96f11ed2":"Noget gik galt. Prøv igen.",
"afb9fc2bb81a96cbc5cc8909c7a586aa":"Beklager! Vi har problemer med at oprette forbindelse lige nu - prøv igen om {retryConnectionPeriod} sekunder.",
"84debc8e41646cd49ac2b14708ca6d73":"Beklager, men der opstod et problem med at slette kodningsposten. Prøv igen.",
"20f55a53fff09a81537cb5d5b4cf4cf8":"Beklager, men der opstod et problem med at gemme kodningslisten. Prøv igen.",
"689d3418ee8f996ad25e152e80de63fe":"Beklager, men vi kunne ikke logge dig ud.",
"b48efd4521ecd6428987d24636a4349f":"Sortér",
"776a95003d3ec1dd58af69aedbc08579":"Sortér",
"1b783238028c0ae085ca69ed823f4d1a":"Sortér efter",
"be70d19bf13c935d653b2c4fe7d36224":"Kildekortnavn",
"564f8c942e7d1bdda5251545612fa348":"Kildekortnavn",
"aa284602c746aad4a6de6366c24221b8":"Kildekort",
"b2b8a1387a2d702ada20d8cf5e351bb3":"Kildekort",
"389d471e79a43440b3278b0fc7187d9e":"Kildekort",
"106342f689b57145817b00d6fe383f91":"Specifik slutdato",
"2a5e19387ff300509876e3bae99b7ab9":"Sæt fart på dit regnskab",
"ca34d586bbdb7f368f1009305bccbe76":"Forbrugsdashboard",
"4583120998b44c0a5982cf85dd938d2f":"Forbrug over tid",
"244d724806247953233a0bbfcbf8da4c":"Forbrugsloft",
"d39b83dd151c8df6370460a458294ccf":"Forbrugt",
"9d8693cc96a7e2c97c26f5bacec4c4d2":"Forbrugsbeløb",
"5de2859528e030ac1f2af115ebdf209c":"Forbrugt { currencySymbol }",
"241aba6072a7be8383c2ea65946a70ce":"Start videresendelse den",
"b0cb87df77ae00aa1d4e560b3a46dc50":"Tilstand",
"1f1fe111548158ec842761eca7425ff3":"Gadeadresse",
"31a0a748b1097a9e0aa95a811802bdd0":"Problemfri afstemning",
"111b782f3f0794c2294aefb6693b2c50":"Send",
"013e2555920b51ad2a3d2c3dc482c471":"Send anmodning",
"1270cd8eb69ad64cc71e5de979423b3b":"Abonnement slutter",
"2802336ad3d8ff658257b88a93f4e50a":"Abonnement slutter",
"b28dd68454d7acbefafeefb42981fe16":"Abonnementsfrekvens",
"907793bc80cf94d560e5f1a0250fdaee":"Abonnementsfrekvens",
"29229e153308e4747edef1144de35a55":"Det lykkedes!",
"503eb46b80f374f39266e7a9aea8e63e":"Det lykkedes!",
"4f20e29cadadb9fbc7455bd4d80de567":"Det lykkedes!",
"62558c185ea2c06c5472d1cd534f6e56":"Synkroniserer med din regnskabsløsning",
"544ed43fd8fd170b1f14b6304f6e1bf9":"Skattebeløb",
"bf23b2fd889e3b12e0f0acf99f913eca":"Teamkort",
"b90a650376782a9e43ed6be6135bd6f2":"Teamdashboard",
"6adbec395fe1c4bf776f9fe5fa021052":"Teamdashboard",
"6f2695eeb68501dbcfddbb4d265b846b":"Teammanagers",
"3b779ade9c960ac95a670db0cc12e56b":"Teammedlemmer",
"bac3a5a42e575d9d35e5b42d29e9bcda":"Teamindstillinger",
"dc5235e1b88320d3ba7501e4c30985a3":"Teamindstillinger",
"5d72d5fe2e51c75dba3dc93dc62428eb":"Teamindstillinger",
"a2eb92bfd7502f47b3cd170bc68ac638":"Teamindstillinger",
"f1ec84f051f19f0d0e301a709b13dc45":"Teamforbrug",
"fbacde3a262ea8696e30135e8f987a44":"Teamomskifter",
"74b9578ea5a620f5fef102188e42c02f":"Teamomskifter",
"e0f3303d791ca2952d2ac9a5bd7fcb02":"Teamhandlinger",
"3aa978fa7ef886621285b93257d428c3":"Teamets godkendelsesgrænse",
"a41f9df131c221a99926de7e2138653a":"Teamoplysninger",
"941c7da8f256f91ba3819f991315a100":"Teamnavn",
"c5d04e10cc2f0b53c2bda893eae09178":"Teamnavn",
"504a8ba642ba37c9a743d0fabb2bd371":"Teamnavn",
"e768a8e1e3d6a6bf470587dad75423fe":"Teamejer",
"e2d824067f8bd871756688c58c382196":"Teamejere",
"dca232ccc849c5ff5611255eb82baf53":"Teamejere",
"3b61bd586225790cf132591383d15448":"Teamomskifter",
"1d3a35a737ec1b74eed7ae2a2c31ab85":"Teams og individuelle grænser vil aldrig overskride virksomhedsgrænsen",
"4b4fe189a48d8eb06d836afa8fb6317a":"Teams, der blev opdateret.",
"2a17f9a8c610db29fec63fd530c29fdc":"Teams, der bruger kortet",
"ca97eb7e3a85ec593ac03c0e887b3fda":"Teams, der ser dette",
"317868f3804ba4ed5d99dd9919c798ba":"Servicevilkår",
"e8c9a9847db2ccd97524aa06f1af9d82":"Servicevilkår og politik for beskyttelse af personlige oplysninger",
"38852078519c5c46715d8a034fc4901b":"Servicevilkår og politik for beskyttelse af personlige oplysninger.",
"c7b01ce3decb069a8a4b6445627f6f8c":"Tak for din interesse i Tradeshift Go. Support af dit {cardLabel}-kreditkort kommer snart. Et medlem af vores kundesuccesteam giver dig besked, så snart det er tilgængeligt.",
"f7047b35a069d0790a0a348a29c846a2":"Tak for tilmeldingen af dit team hos {companyName}, så det bliver muligt at foretage forretningskøb ved brug af virtuelle Go-kort.",
"133b0a70b1a956889164621380c378f9":"Den handling, du forsøger at foretage, kan ikke fuldføres. I nogle situationer hjælper det at prøve igen.",
"18f5a1872e156f1aee84ae321780d952":"Antallet af anmodninger var over virksomhedens anmodningsloft på ${requestLimit}. Prøv igen med et lavere antal anmodninger.",
"867e3d4d3c78990c6852fc5348706ab2":"Antallet af anmodninger var over virksomhedens anmodningsloft på ${providerLimit}. Prøv igen med et lavere antal anmodninger.",
"570f5f4658b03dda7a910f56c86a3bd3":"Kortet for denne anmodning er blevet annulleret.",
"3075c56105863546e3368ffead7a146b":"Kortet blev annulleret.",
"c6d61bfe3a51966dbdb7e0372a80d15c":"Kodningslisten, du forsøger at trække tilbage, er knyttet til en integration. Hvis du er sikker på, at du vil fjerne denne fra integrationen, bedes du kontakte Support, der kan fuldføre denne handling.",
"756ade8d71ec487a4b48d7c4277ea239":"Kodningslisten, du forsøger at trække tilbage, er knyttet til en integration. Du skal først fjerne tilknytningen, før du trækker denne kodningsliste tilbage.",
"4f40335f7c018986c95458183bc0c136":"Linket til at aktivere din konto er ugyldigt og er udløbet. Bed den person, der inviterede dig, om at få en anden invitation.",
"79ea5615cd79eb843c9f52f1cdb96fb0":"Linket til at nulstille din adgangskode er ugyldigt eller er udløbet. Anmod om en adgangskodenulstilling igen for at få et nyt link.",
"ded30f0e288c12125ca1a77f8d8fd600":"Årsagen vil være synlig for godkender og anmoderen",
"f294897b38fc23dd8f5de52b54ceae22":"Denne anmodning vil blive afvist, og anmoderen vil få besked. Vil du fortsætte?",
"e8c7200dc985df0a94a9d996968b4cc0":"Denne anmodning vil blive afvist, og anmoderen vil få besked. Vil du fortsætte?",
"5f3d196a2ade3f97a2a48b773fec5861":"Denne anmodning vil blive afvist, og anmoderen vil få besked. Vil du fortsætte?",
"b04c191cd6b4f27669a4f390311f156c":"Der er ingen teamejere eller administratorer, der kan vælges.",
"e25045557efbad7b52997b94a1fe6817":"Der er ingen {type} for denne tidsperiode",
"7260690577c795e999acae63bb7c51ae":"Der opstod et problem med lagring af teamindstillingerne. Prøv igen.",
"f2103024f2a9c6c0335a71c60567d6c9":"Der opstod en fejl under indlæsning af din tegnebog.",
"9776cb7eab9fa0d318a8144cd17de745":"Der opstod en fejl under indlæsning af din tegnebog.",
"77d28a14bc900be4360b570a82eb75a9":"Der er ingen data at vise",
"2b6feba67722337f88c1443909fe446f":"Der var et problem med at tilføje godkenderen!",
"24b7212afb97c5d23faafa2ca4559095":"Der var et problem med at tilføje kreditkortet. Prøv igen.",
"8a828f3cc30f3d9fa0dcf6fa364f8591":"Der var et problem med at føje kildekortet til teamet. Prøv igen",
"5a8e1b922d1cee746ac6794599b10709":"Der var et problem med at annullere dette kort. Prøv igen.",
"91f1cb4d9e0c36605495a88dcf57d19b":"Der var et problem med at annullere dette kort. Prøv igen.",
"22361c5d4df61ad7ae9792a3baf5729f":"Der var et problem med at oprette din liste. Prøv igen",
"098755bfddeaf0428c7feb6b69af9b6f":"Det opstod et problem med at afvise anmodningen. Prøv igen.",
"beab1fa45ce9ef663a66ca3d962e7b2c":"Det opstod et problem med at afvise anmodningen. Prøv igen.",
"7d461b6b7b54dbfa9c83243217cd1053":"Der opstod et problem med at slette kodningsposten. Prøv igen.",
"e8dee45c0e01aa5eb4fa394d7685c17e":"Der var et problem med at slette din liste. Prøv igen.",
"beb43d7800cfff47e08cd61842d953bd":"Det opstod et problem med at videresende anmodningen. Prøv igen.",
"f7775350f35b8365fb9803232d9b792c":"Der opstod et problem med at hente kortdetaljerne. Prøv igen.",
"95abaa922c6c1648ca9c39ac4776896f":"Der opstod et problem med at hente brugerdetaljerne. Prøv igen.",
"9d7e4ead60137041ae8a845aa6cf9979":"Der opstod et problem med at indlæse brugerprofilen. Prøv igen.",
"4d0bebbdd436537acfb0fe8f5d1d3b33":"Der var et problem med indlæsning af dette teams brugere. Prøv igen.",
"3f70e7e0157e3f1f9395be31d5761f0d":"Der var et problem med indlæsning af dine indstillinger. Prøv igen.",
"b2f48028c400befad5f1d57f769ef7cd":"Det opstod et problem med at gentildele anmodningen. Prøv igen.",
"7efdb64d9c2bead884a2d5a1b0b6c759":"Der var et problem med at fjerne kildekortet fra teamet. Prøv igen.",
"19dfcd761006496c1d20351c04643e45":"Der opstod et problem med at fjerne dette domæne. Prøv igen.",
"149651b5784481c68aaf4ac27bb1f907":"Der opstod et problem med at slette kodningslisten. Prøv igen.",
"c141d505f8aea3e52fdcd81dc09c0b83":"Der opstod et problem med at gemme profilen. Prøv igen.",
"749ddc235744b6b371668ec0bb049176":"Der opstod et problem med at gemme brugeren. Prøv igen.",
"28b131c0e50b410bdfcc03c611b3d2d8":"Der opstod et problem med at gemme dette domæne. Prøv igen.",
"04cf86909808f430b8b1904fb422e0d3":"Der var et problem med at gemme dine træffes ikke-indstillinger. Prøv igen.",
"2eaf6ee97d984598c95e0494d72aa492":"Det opstod et problem med at sende indkøbsanmodningen.",
"148f847d3beccaa67fcd5096ae3602d3":"Der opstod et problem med at opdatere kortnavnet. Prøv igen.",
"4ecf92b6918afa4c7b83fe381a4790e6":"Der opstod et problem med at opdatere kortnavnet. Prøv igen.",
"de8cc976f7af9edf189904239e6240da":"Der opstod et problem med at opdatere teams. Prøv igen.",
"2bb5a45ddd48b21503e3b1e6dec8c430":"Der opstod en fejl under tilføjelse af administratoren, Prøv igen.",
"d2e00e61f73e03a0c7ed24d7d76f5ec6":"Der opstod en fejl under deaktivering af dit team. Prøv igen.",
"3f686a97a1b9fcdd73a40d2005693105":"Der opstod en fejl. Prøv igen.",
"2f38263789163cf8ee30c8ae88852c82":"Der opstod en fejl under publicering af din liste.",
"38f213d7fdf68a21bdb8e332f5a75f2e":"Der opstod en fejl under fjernelse af administratoren. Prøv igen.",
"007a647254e1f85b6c1e789004e05df5":"Der opstod en fejl under lagring af indstillingerne",
"c3072d080b587d70f85d68ec089f028f":"Der opstod en fejl under lagring af indstillingerne for dit team. Prøv igen.",
"608cd0e109c3e51b0425df8921e4181d":"Der opstod en fejl under angivelse af udbydertilknytningen. Prøv igen.",
"177cefc68dd318ea75673326e77d8206":"Der opstod en fejl under tilbagetrækning af din liste.",
"255d22dc4f0e338a4cf53d5b7256e695":"Der opstod en fejl under hentning af den persons navn, som du videresender godkendelsesanmodninger til.",
"39ac4ec73fed8a981ad1857d989edfcb":"Dette beløb overstiger virksomhedens godkendelsesgrænse.",
"494ce3f598cbd97afe99774b5975a4bc":"Dette beløb overstiger teamets godkendelsesgrænse.",
"f2a42214e7c480c5596439becfd0b7bf":"Dette felt er obligatorisk.",
"2dd5b9e9b5f80256af212924a6ac31c4":"Denne indstilling vil kun påvirke nye kort",
"881b7596cf985d9c246b05fcab930632":"Dette kildekort er blevet føjet til teamet.",
"a1b1aa354d06e7815b78c6abb7448e84":"Dette kildekort er blevet fjernet fra teamet.",
"b348b24fc4839ee15ed0aa424689bae3":"Dette team skal have en administrator eller manager, før du kan invitere et medlem.",
"188b9ed447a43916f9e323623ea3a993":"Hvis du vil acceptere invitationen",
"969de95e19a06f5ae7cec6449cbb6a27":"I alt",
"30286e57da108ee7dbc0f8fbb62d4fff":"Brugt i alt ({currencySymbol})",
"c1a02bee74ad2ab87b0e0114dfea6905":"Spor forbrug på tværs af teams, projekter og abonnementer.",
"f44337314a4c2f5db6e25fc6ecf8a1a0":"Spor aktiviteten for dit teams aktivitetskort, sæt forbruget på pause, og deaktiver kort, som teamet ikke længere har brug for.",
"5ae9b3d65fa2cb0a3aa32deba7028341":"Nulstil Tradeshift Go-adgangskode",
"5d760188301146cc7255ce18b65cb370":"Prøv igen",
"80196bc616d4a44a4997a2ea127f7b83":"Prøv igen",
"f96bbe81618b5f4a1807989eb4f69e24":"Prøv igen",
"d498d7d261b7ca56e07218d1a0dbdd49":"Ups! Der opstod en fejl. Prøv igen.",
"ce2e475291003852c71b01959698c496":"Kan ikke hente meddelelser på anmodning {title}, prøv igen",
"2b3dd42bc1791e8c2f8b93a2399cd6b8":"Fjern et dokument fra arkiv",
"d2e50be5287fd8fb436d0acbeb811864":"Entydigt id",
"0e2fbce967f4a209215b6f48f8eddac3":"Ophæv pause for kortoprettelse",
"f2315dec5dd07802b77af3a3a5414a7d":"Op til 4 år i fremtiden.",
"d2f30a2f0c955271e9af123dceca501a":"Op til {label} i fremtiden",
"2dbd9b0d1463dc25ba00eb38f9c1cafc":"Opdater kildekort",
"db030ad54ba2cdbdf0a279bc5c1c761d":"Opdater kildekort",
"c8f6bc61a8982158e408194e48a274d9":"Upload mislykkedes, prøv igen",
"8a86a60cb836bdb5225cad20c5151078":"Upload kvittering",
"e448a1ce42de0cf526200331b4ad8b23":"Bruger",
"75edcc8fc27f41c99e28b48ff250c7f8":"Brugerdetaljer",
"d1651c2afdb6c92621adbb707e4753d0":"Bruger gemte.",
"5fa55883b7413526b441e999cb8089b3":"Brugernavn",
"7a892b81fe2aae295f1b2d38bb67360f":"Brugernavn",
"fbe6f659aba3b0b0bdc49fa7a8240bbc":"Brugere med aktive kort kan ikke fjernes. Annuller kort for at fjerne denne bruger.",
"d40fa5935f1614925cf46259c886814a":"Vis kortaktivitet",
"625419857efc7e72d162c9f8204109df":"Vis kort i tegnebog",
"85a5e817826415651ed4991a76bb5385":"Vis samtale",
"09fc124dc9b8b14d6227a92bb0a4566f":"Vis samtale",
"d26cfc6d8f420f259f434b79593e9eb4":"Vis kvittering",
"c7d0394c3206a3b74afbeabc41cc639c":"Vis kvittering",
"01650aa52c5e5a22d3f38afd2c7f9411":"Vis kvittering",
"d46ebcfbb9dfe06ad3a521ba95c57be5":"Vis kvittering",
"b2d141897f73fc2a198ee1a7a438f89b":"Vis anmodning",
"02fa2f56ef72845a9dfeabea22948061":"Vis teamdetaljer",
"2d9c55650b17dd605a1388f46c81ba38":"Virtuelt kort",
"d0ec076e4600e854588f8da265208c0b":"Indstillinger for virtuelt kort",
"45124d29b7eccce9f3ecd52ae4c6ca8c":"Tegnebog",
"5279a2220e4cfb0d8b8b357311251cbe":"Tegnebog",
"b1746b5806659e3189f837d2dd54e08c":"Tegnebog",
"ff306d5b34ee993f9618c1f3c0209a39":"Vi kan ikke arkivere anmodningen på nuværende tidspunkt. Prøv igen senere.",
"90272a9bb4d2048e81d7962ccfc9a97d":"Vi kan ikke annullere anmodningen på nuværende tidspunkt. Prøv igen senere.",
"a813d44f6bb26c5da09671e532abe022":"Vi kan ikke fjerne anmodningen fra arkiv på nuværende tidspunkt. Prøv igen senere.",
"d4c7310eafc0d0da89f20596a22a3abe":"Vi kunne ikke oprette en anmodning på nuværende tidspunkt. Prøv igen!",
"dca679c10accb1db481a57a0e1ba08e2":"Vi kunne ikke oprette en anmodning på nuværende tidspunkt. Prøv igen!",
"db5584a2ef29b04959bce7f93db54c56":"Vi kan ikke skifte team på nuværende tidspunkt. Prøv igen senere.",
"f2af2c261fabddb01766f4f974b85a62":"Ugentlig",
"e181ed0723a00ca7558b9d5d5258732b":"Velkommen til Tradeshift Go! {recipientFirstName}",
"a4fe6ccb595f5beac99a0b294828b9dd":"Vi kontakter dig!",
"76b9c7afd38e52841fba94b5ead1d5cc":"Hvilket kreditkort bruger din virksomhed?",
"968d94729053e166a76ea577ce3022e2":"Arbejds-e-mail",
"588a075e8694ff5cc86815697e1586bc":"Arbejd på den samme måde som dine forretningsteams.",
"182ae1eb5226e0646b4033883cc0900c":"År",
"fe88fe7094c4ced427874b3d22326c8d":"Ja",
"9a2d4e3b22ad0567165eb0f661f952ad":"Ja, annuller kort",
"96b88e530375237cbc08138d9fb4109c":"Ja, fjern denne liste",
"fd3780f468c531f2bc1b47b8748b5a66":"Du kan oprette regnskabskoder i virksomhedsindstillinger.",
"ab3ac6f945d9f756763d49257fd924dc":"Du kan administrere koder i virksomhedsindstillinger.",
"c919dae93fe780061629c255eb358140":"Du kan ikke fjerne det eneste kildekort, der er linket til dette team. Tilføj endnu et kort for at fjerne dette.",
"11de97e873b66d91a77db7a05da32202":"Du kan forfremme en af de teamejere, der er angivet herunder, til at være en virksomhedsadministrator eller invitere en ny bruger.",
"0ab790f08b2780be001b79d2ccd75c4e":"Du har ikke oprettet nogen regnskabskoder for denne konto.",
"f63cac1a96d6cc6af3a3e3b9f7dfb862":"Du har ikke oprettet nogen kodningslister.",
"44e470218124a50c117870467530752e":"Du angav en forkert mailkadresse eller adgangskode.",
"4b687d0730822fdbeea27c174e47f227":"Du har ingen godkendte godkendelser.",
"020f03c17b79af66f335b6af868c66c0":"Du har ingen annullerede godkendelser.",
"def0b76f03e7c622341a7d8e08aaaae7":"Du har ingen afviste godkendelser.",
"f138fc3cef5a3fc7c6aeabc235e63273":"Du har ingen ventende godkendelser.",
"7c034d6104f80a523b90bc607fc06608":"Du har ingen virtuelle kort i din tegnebog. Opret en anmodning",
"0aed9198d61d161468d541dbbc9f477a":"Du har træffes ikke-videresendelse til {oooForwardToFullName} planlagt indtil {date}",
"4294c2c09a4e02bde71dc187e12a6933":"Du modtog et nyt virtuelt kort:",
"04eeaf814767c30aa41577f3b11f916a":"Du har for nyligt anmodet om en adgangskodenulstilling af din Go-konto. Sådan fuldføres processen:",
"09ddf249810b9af7d3e1f4e537da500d":"Du forsøgte at få adgang til en side, der ikke findes. Sørg for, at du har det korrekte link.",
"c7bcb11f241c8b1e830fb9c9dacbc48a":"Du er inviteret til at deltage i teamet",
"02c6f3a7cf31a87f4f39f992509f8c44":"Du er tilføjet som administrator for din virksomhed",
"cf121a14590f109dafcae138265e353b":"Du er føjet til et nyt team!",
"69fb47da3b52f8e0dfbcc6e4947f4df3":"Du er føjet til teamet",
"d3cef66a026f776903b342d450e5a832":"Din anmodning om Go-kort er blevet afvist",
"003f87c21a29220e661fc302b885fd5a":"Din konto er blevet deaktiveret. Kontakt din virksomhed eller teamadministrator.",
"a52b1f0da15dce68007f524e48238143":"Din bank returnerede en fejl under tilføjelse af dette kort. Kontakt din bank for at få problemet løst, og prøv igen.",
"4d883b173167297463aeac86957c26fe":"Din mailadresse er blevet brugt til at oprette en konto på Tradeshift Go. Hvis du vil bekræfte din e-mailadresse og fuldføre registreringen",
"3776a5b34e9c0844f7f5bff6ab1cd535":"Dine træffes ikke-indstillinger er blevet gemt.",
"542f08554110a12de0c23afd78f38e0e":"Din adgangskode er blevet nulstillet. Log på.",
"49b2dcbe5d461f35c37986962b5bd07a":"Din anmodning blev afvist",
"4e9562a9201f2c46a7a7d631fbf82ac1":"Du står på listen!",
"32f87c5ddeaee35b9178785ef4eb9313":"Postnummer",
"c6974a3d5311374c697625f2a3234bce":"en dato",
"f86980f5ef00b645a6e4a0b59738a954":"en person, som vi ikke fik slået op",
"7066f854917b39bafe0bbecbb8bd6377":"godkender anmodninger",
"49b171230ab9bcdd8b631c70cf397efb":"til <%= purchaseName %>",
"4a8231a77c028b619d244b3e04f9916e":"for din virksomhed",
"35ad86f0b035d8156390670d60801e7f":"til {purchaseName}",
"9e963b6266a5512785fbc54f15d0131e":"til {purchaseName}",
"a6e7b18eae6962edf08dc9c3ced705f4":"fra",
"c1407aa5424f339dedf67bcac597cf71":"har indstillet deres godkendelsesanmodninger til at blive videresendt til dig under",
"67a948b8af3284a401ebefcb1b4723ab":"venter på din godkendelse af",
"9ca64449d64b98fef7859e22bc23c4dc":"fremsætter anmodninger",
"ac850ab84428e9eb6a57e5d813de5e45":"administrerer team",
"2168839a1f4c73ef5c89c9e930148e7d":"se bort fra denne mail Der kræves ingen handling",
"85431c325faab98e02daa60151465144":"sendte dig denne invitation",
"81c504fd54119e7e580eb05dedef46d0":"sendte dig denne invitation",
"d74545060b38445ef4cc49fbb8d79876":"sendte dig denne invitation.",
"2d96c53ee7ee0a54aadad19bfce0697d":"der opstod en fejl under hentning af dine kortkilder",
"b2fda295788d579fffd3f43b7737fd63":"for at tilmelde dit virksomhedskort til Go for at give dine medarbejdere enkel og sikker kortadgang",
"d35bd5c5d74af765d45cef3c4e78f52a":"til at foretage forretningskøb ved brug af virtuelle Go-kort",
"8469a13ddf1cc19a4ebf26356d8ac646":"til at foretage forretningskøb ved brug af virtuelle Go-kort",
"9546989e681a7e25fbdfe7631e9ebc03":"til at foretage forretningskøb ved brug af virtuelle Go-kort.",
"6174e0a3d26409dd6e410052e2805660":"for at begynde at bruge Tradeshift Go-kort",
"0bee956ca9aa927e0063c36a300e29e1":"deaktiver træffes ikke",
"fd2c1099fc9f3831d7f24195aaa11269":"dig@virksomhed.com",
"1f61a953f46a681faf6c6891bfa52516":"{firstName} er ansvarlig for godkendelsesanmodninger fra andre teammedlemmer. Tildel en ny godkender til de berørte medlemmer for at fortsætte.",
"81041cd0921b5651568c2a35dccdd432":"{firstName} {lastName} blev inviteret${this.teamId ? '.' : ' til teamet'.}",
"27abab5a6de300a82727477f44662dcf":"{fname} blev fjernet",
"5e367737fe91161b5777e74d4cfaf27b":"{requesterFullName} annullerede anmodningen for",
"9567a2d4cacd58fb4f096132d26c1660":"{username} er ansvarlig for godkendelsesanmodninger fra andre teammedlemmer. Tildel en ny godkender til de berørte medlemmer for at fortsætte.",
};
