function cardIcon() {
	return {
		restrict: 'E',
		scope: {
			lastFourDigits: '=',
		},
		template: require('./cardIcon.html'),
	};
}

module.exports = cardIcon;
