const _ = require('lodash');

function ConstantsFactory({ values: _values = {} } = {}) {
	const values = _.cloneDeep(_values);

	const constants = {};

	constants.clone = ({ moreValues = {} } = {}) => {
		const newValues = _.merge({}, values, moreValues);
		return ConstantsFactory({ values: newValues });
	};

	constants.get = path => {
		const value = _.get(values, path);
		if (_.isUndefined(value)) {
			throw new ConstantsError(path);
		}
		return value;
	};

	constants.getValues = () => values;

	return constants;
}

function ConstantsError(path) {
	Error.captureStackTrace(this);
	this.message = ['Constant value for', path, 'does not exist'].join(' ');
	this.name = 'ConstantsError';
}

module.exports = ConstantsFactory;
