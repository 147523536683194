import angular from 'angular';

import AddPeopleModalViewComponent from './add-people/add-people-modal-view.component';
import CodingListsModalViewComponent from './coding-lists/coding-lists-modal-view.component';
import CreateTeamModalComponent from './create-team-modal.component';
import TeamInfoModalViewComponent from './team-info/team-info-modal-view.component';
import TeamInfoModalViewHelper from './team-info/team-info-modal-view.helper';

import './add-people/add-people-modal-view.less';
import './create-team-modal.less';
import './team-info/team-info-modal-view.less';

export default angular
	.module('go.common.modals.create-team', [])
	.component('addPeopleModalView', AddPeopleModalViewComponent)
	.component('goCreateTeamModal', CreateTeamModalComponent)
	.component('teamInfoModalView', TeamInfoModalViewComponent)
	.component('codingListsModalView', CodingListsModalViewComponent)
	.factory('teamInfoModalViewHelper', TeamInfoModalViewHelper).name;
