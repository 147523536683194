"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentInitialState = void 0;
exports.paymentSlice = paymentSlice;
exports.buildAmexPaymentInformation = buildAmexPaymentInformation;
exports.buildHsbcPaymentInformation = buildHsbcPaymentInformation;
exports.buildAmexCardSource = buildAmexCardSource;
exports.buildHsbcCardSource = buildHsbcCardSource;
exports.paymentInitialState = {
    payment: {
        payment: [],
        card: {},
        enroll: {},
        approvers: [],
        cards: [],
        teamCards: [],
        cardSourceTeams: [],
        cardSourceId: '',
        enrollData: {},
    },
};
function paymentSlice(set) {
    return {
        ...exports.paymentInitialState,
        setPayment(payment) {
            set((state) => {
                state.payment.payment = payment;
                return state;
            }, false, '[setPayment]');
        },
        setCardSource(cardSource) {
            set((state) => {
                state.payment.card = cardSource || state.payment.card;
                return state;
            }, false, '[setCardSource]');
        },
        updateCardSource(cardSource) {
            set((state) => {
                state.payment.payment = [...state.payment.payment, structuredClone(cardSource)];
                return state;
            }, false, '[updateCardSource]');
        },
        updateCardSourceName(name) {
            set((state) => {
                if (!state.payment.card) {
                    return state;
                }
                state.payment.card.name = name;
                return state;
            }, false, '[updateCardSourceName]');
        },
        setEnrollAmex(enroll) {
            set((state) => {
                state.payment.enroll = enroll || {};
                return state;
            }, false, '[setEnrollAmex]');
        },
        setTeamApprovers(approvers) {
            set((state) => {
                state.payment.approvers = approvers || [];
                return state;
            }, false, '[setTeamApprovers]');
        },
        setCompanyCards(cards) {
            set((state) => {
                state.payment.cards = cards || [];
                return state;
            }, false, '[setCompanyCards]');
        },
        setTeamCards(teamCards) {
            set((state) => {
                state.payment.teamCards = teamCards || [];
                return state;
            }, false, '[setTeamCards]');
        },
        setCardSourceTeams(cardSourceTeams) {
            set((state) => {
                state.payment.cardSourceTeams = cardSourceTeams || [];
                return state;
            }, false, '[setCardSourceTeams]');
        },
        setCardSourceId(cardSourceId) {
            set((state) => {
                state.payment.cardSourceId = cardSourceId || '';
                return state;
            }, false, '[setCardSourceId]');
        },
        setEnrollCardData(data) {
            set((state) => {
                state.payment.card = data;
                state.payment.payment = [...state.payment.payment, structuredClone(data)];
                return state;
            }, false, '[setEnrollCardData]');
        },
    };
}
function formatExpiryDate(month, year) {
    return year.toString() + month.toString().padStart(2, '0');
}
function buildBasePaymentInformation(formData) {
    return {
        address: {
            address: formData.address,
            city: formData.city,
            countryCode: formData.countryCode,
            regionCode: formData.regionCode,
            zipCode: formData.zipCode.toString(),
        },
        billingCurrency: formData.billingCurrency,
        cardNumber: formData.cardNumber.toString().replaceAll(/\s+/g, ''),
        email: formData.email,
        expiryDate: formatExpiryDate(Number(formData.expirationMonth), Number(formData.expirationYear)),
        firstName: formData.firstName,
        lastName: formData.lastName,
        name: formData.name,
        orgName: formData.orgName,
    };
}
function buildAmexPaymentInformation(formData) {
    const baseInfo = buildBasePaymentInformation(formData);
    return {
        ...baseInfo,
        address: {
            ...baseInfo.address,
            countryCode: 'US',
        },
        provider: 'AMEX',
        networkProvider: 'AMEX',
    };
}
function buildHsbcPaymentInformation(formData) {
    const baseInfo = buildBasePaymentInformation(formData);
    return {
        ...baseInfo,
        address: {
            ...baseInfo.address,
            countryCode: 'GB',
        },
        provider: 'HSBC',
        networkProvider: 'VISA',
    };
}
function buildBaseCardSource(formData, cardSourceInfo) {
    return {
        billingAddress: {
            addressLine1: formData.address,
            city: formData.city,
            countryCode: formData.countryCode,
            region: formData.regionCode,
            zipCode: formData.zipCode,
        },
        id: cardSourceInfo.id,
        billingCurrency: formData.billingCurrency,
        teamsCount: '0',
        label: [cardSourceInfo.name + ',', '····', cardSourceInfo.lastDigits].join(' '),
        lastDigits: cardSourceInfo.lastDigits,
        name: cardSourceInfo.name,
        provider: cardSourceInfo.provider,
        status: 'IN_PROGRESS',
    };
}
function buildAmexCardSource(formData, cardSourceInfo) {
    const baseInfo = buildBaseCardSource(formData, cardSourceInfo);
    return {
        ...baseInfo,
        providerLabel: 'American Express',
    };
}
function buildHsbcCardSource(formData, cardSourceInfo) {
    const baseInfo = buildBaseCardSource(formData, cardSourceInfo);
    return {
        ...baseInfo,
        providerLabel: 'HSBC',
    };
}
