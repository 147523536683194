"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.peopleTableInitialState = void 0;
exports.peopleTableSlice = peopleTableSlice;
exports.peopleTableInitialState = {
    peopleTable: {
        currentNameSearchValue: '',
        pager: { numPages: 0, pageId: 1 },
    },
};
function peopleTableSlice(set) {
    return {
        ...exports.peopleTableInitialState,
        setCurrentNameSearchValue(currentNameSearchValue) {
            set((state) => {
                state.peopleTable.currentNameSearchValue = currentNameSearchValue;
                return state;
            }, false, '[setCurrentNameSearchValue]');
        },
        setPeopleTablePagerPageId(pageId) {
            set((state) => {
                state.peopleTable.pager.pageId = pageId;
                return state;
            }, false, '[setPeopleTablePagerPageId]');
        },
        setPeopleTablePagerNumPages(numPages) {
            set((state) => {
                state.peopleTable.pager.numPages = numPages;
                return state;
            }, false, '[setPeopleTablePager]');
        },
    };
}
