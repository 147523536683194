"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationInitialState = void 0;
exports.notificationSlice = notificationSlice;
exports.notificationInitialState = {
    notification: {
        alerts: [],
    },
};
function notificationSlice(set) {
    return {
        ...exports.notificationInitialState,
        setNotification(alerts) {
            set((state) => {
                state.notification.alerts = alerts;
                return state;
            }, false, '[setNotification]');
        },
    };
}
