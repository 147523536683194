function requestCard() {
	return {
		restrict: 'E',
		scope: {
			request: '=',
		},
		template: require('./requestCard.html'),
		controller: 'RequestCardController',
	};
}

module.exports = requestCard;
