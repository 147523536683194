"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
// ! Please use the components from material-ui like in render() instead of this one
const ToggleSetting = ({ label = '', name = '', disabled = false, onValueChange, isChecked = false, visible = true, } = {}) => {
    return visible ? ((0, jsx_runtime_1.jsx)(material_1.FormGroup, { children: (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { name: name, control: (0, jsx_runtime_1.jsx)(material_1.Switch, { color: "primary", disabled: disabled, checked: isChecked, onChange: onValueChange }), label: label, labelPlacement: "start", style: {
                ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
                    ? {
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#000000',
                    }
                    : {}),
            } }) })) : null;
};
exports.default = ToggleSetting;
