import angular from 'angular';

import ApproverDashboardComponent from './approver-dashboard/approver-dashboard.component';
import CompanyDashboardComponent from './company-dashboard/company-dashboard.component';
import ActivityFeedBoxComponent from './components/activity-feed-box/activity-feed-box.component';
import BarChartComponent from './components/bar-charts/bar-chart.component';
import DashboardBoxLoaderComponent from './components/dashboard-box-loader/dashboard-box-loader.component';
import DashboardBoxComponent from './components/dashboard-box/dashboard-box.component';
import OverviewBoxComponent from './components/overview-box/overview-box.component';
import TeamMemberBoxComponent from './components/team-member-box/team-member-box.component';
import TeamsBoxComponent from './components/teams-box/teams-box.component';

import './approver-dashboard/approver-dashboard.less';
import './company-dashboard/company-dashboard.less';
import './components/activity-feed-box/activity-feed-box.less';
import './components/bar-charts/bar-chart.less';
import './components/dashboard-box-loader/dashboard-box-loader.less';
import './components/dashboard-box/dashboard-box.less';
import './components/overview-box/overview-box.less';
import './components/team-member-box/team-member-box.less';
import './components/teams-box/teams-box.less';

export default angular
	.module('go.dashboard', [])
	.component('activityFeedBox', ActivityFeedBoxComponent)
	.component('barChart', BarChartComponent)
	.component('companyDashboard', CompanyDashboardComponent)
	.component('dashboardBox', DashboardBoxComponent)
	.component('dashboardBoxLoader', DashboardBoxLoaderComponent)
	.component('overviewBox', OverviewBoxComponent)
	.component('approverDashboard', ApproverDashboardComponent)
	.component('teamMemberBox', TeamMemberBoxComponent)
	.component('teamsBox', TeamsBoxComponent).name;
