/* @ngInject */
function ForwardRequestHelper($q, PaymentsService) {
	const helper = {};

	helper.getFormOptions = ({ teamId, approverId }) => {
		return PaymentsService.getTeamApprovers({
			teamId,
			excludeOutOfOffice: true,
			excludeSelf: true,
		}).then(approvers => {
			const approverOptions = approvers
				.map(({ userId: id, fullName: label, email }) => ({ id, email, label }))
				.filter(({ id }) => id !== approverId);
			return { approvers: approverOptions };
		});
	};

	return helper;
}

module.exports = ForwardRequestHelper;
