"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rootLoader = void 0;
const appConstants_1 = require("../../constants/appConstants");
const rootLoader = () => {
    const reactRoutes = appConstants_1.reactMigration.migratedPages.map(page => page.reactJsRoute);
    const isReactRoute = reactRoutes.some(route => {
        if (window.location.pathname === '/') {
            return true;
        }
        return route !== '/' && window.location.pathname.includes(route);
    });
    const header = document.querySelectorAll('.ts-headerbar')[0];
    const subHeader = document.querySelectorAll('go-sub-header')[0];
    if (header) {
        header.style.display = isReactRoute ? 'none' : 'block';
    }
    if (subHeader) {
        subHeader.style.display = isReactRoute ? 'none' : 'block';
    }
    return true;
};
exports.rootLoader = rootLoader;
(0, exports.rootLoader)();
